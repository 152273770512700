import React, { useCallback } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { Policy } from 'client/app/lib/policies';
import Colors from 'common/ui/Colors';

type Props = {
  policy: Policy;
  onPick?: (id: string) => void;
  onQuickPick?: (id: string) => void;
  selected?: boolean;
};

function PolicyCard(props: Props) {
  const { policy, onPick, onQuickPick, selected } = props;
  const { id } = policy;
  const onCardClick = useCallback(() => onPick?.(id), [id, onPick]);
  const onCardDoubleClick = useCallback(() => onQuickPick?.(id), [id, onQuickPick]);

  return (
    <StyledCard
      selected={selected}
      data-test="policyCard"
      onClick={onCardClick}
      onDoubleClick={onCardDoubleClick}
    >
      <CardContent>
        <Typography gutterBottom variant="h1" data-test="policyCardTitle">
          {policy.name}
        </Typography>
        <Typography color="textSecondary">{policy.description}</Typography>
      </CardContent>
    </StyledCard>
  );
}

const StyledCard = styled(Card, {
  shouldForwardProp: propName => propName !== 'selected',
})<{
  selected?: boolean;
}>(({ selected }) => ({
  outline: selected ? `1px solid ${Colors.BLUE_50}` : undefined,
}));

export default PolicyCard;
