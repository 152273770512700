import React, { useCallback, useEffect, useMemo } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import cx from 'classnames';

import {
  addPlateOrigin,
  DEFAULT_TRANSFER,
  getAllPlatesContent,
  getMinimumPlatesSize,
  getTransfersTree,
  LiquidTransfer,
} from 'client/app/apps/cherry-picker/CherryPickApi';
import {
  SetActiveStep,
  SetCherryPick,
  useCherryPickContext,
} from 'client/app/apps/cherry-picker/CherryPickContext';
import AddPlateDialog from 'client/app/apps/cherry-picker/cp-plate-vis/AddPlateDialog';
import CherryPickPlateViz from 'client/app/apps/cherry-picker/cp-plate-vis/CherryPickPlateViz';
import { ScreenRegistry } from 'client/app/registry';
import Colors from 'common/ui/Colors';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

export type CherryPickPlateRole = 'Source' | 'Destination';

export default function CherryPickPlatesViewContainer() {
  return <CherryPickPlatesView />;
}
const CherryPickPlatesView = React.memo(function CherryPickPlatesView() {
  const classes = useStyles();
  const {
    activeStep,
    cherryPick,
    setCherryPick,
    setActiveStep,
    setPlateMinSizeByName,
    transfersInfoByPlate,
    setTransfersInfoByPlate,
    setTransfersTree,
    transfersTree,
    uniquePlateNames,
    isReadonly,
  } = useCherryPickContext();

  const sourcePlates = useMemo(() => {
    return Object.keys(transfersTree);
  }, [transfersTree]);

  // Initialise state needed to correctly render the UI.
  useEffect(() => {
    // Set the liquid contents of all plates
    const allPlatesContent = getAllPlatesContent(cherryPick);
    setTransfersInfoByPlate(allPlatesContent);
    // Initialise tree object, storing the transfers in a convenient way
    const tree = getTransfersTree(cherryPick, allPlatesContent);
    setTransfersTree(tree);
    // Set boundaries of the plates to avoid users selecting obviously wrong plate sizes
    const minSizeByName = getMinimumPlatesSize(cherryPick);
    setPlateMinSizeByName(minSizeByName);
  }, [cherryPick, setPlateMinSizeByName, setTransfersInfoByPlate, setTransfersTree]);

  const destPlatesForActiveSourcePlate = useMemo(() => {
    const allSourcePlates = Object.keys(transfersInfoByPlate);
    const selectedSourcePlate = allSourcePlates[activeStep.source];
    return Object.keys(
      transfersInfoByPlate[selectedSourcePlate]?.plateOutputs ?? {},
    ).sort();
  }, [activeStep, transfersInfoByPlate]);

  return (
    <div className={classes.container}>
      <div className={classes.rootSourceContainer}>
        <SinglePlateView
          allPlates={uniquePlateNames}
          activeStep={activeStep.source}
          setActiveStep={setActiveStep}
          plates={sourcePlates}
          setCherryPick={setCherryPick}
          isReadonly={isReadonly}
          isSource
        />
      </div>
      <div>
        <SinglePlateView
          allPlates={uniquePlateNames}
          activeStep={activeStep.destination}
          setActiveStep={setActiveStep}
          plates={destPlatesForActiveSourcePlate}
          setCherryPick={setCherryPick}
          isReadonly={isReadonly}
        />
      </div>
    </div>
  );
});

type AddPlateButtonProps = {
  plateRole: CherryPickPlateRole;
  allPlates: string[];
  setCherryPick: SetCherryPick;
  classes: WithStyles<any>['classes'];
  isReadonly: boolean;
};

const AddPlateButton = React.memo(function AddPlateButton(props: AddPlateButtonProps) {
  const { plateRole, allPlates, setCherryPick, classes, isReadonly } = props;
  const { setPlateOrigins } = useCherryPickContext();
  const [addPlateDialog, openAddPlateDialog] = useDialog(AddPlateDialog);

  const handleAddPlate = useCallback(async () => {
    const selection = await openAddPlateDialog({
      plateRole,
      allPlates,
    });
    if (!selection) {
      return;
    }

    // Users can choose to transfer to the same plate by omitting
    // the `connectedPlateName`
    const transferToSamePlate = !selection.connectedPlateName;
    if (transferToSamePlate) {
      selection.connectedPlateName = selection.newPlateName;
    }

    const newTransfer: LiquidTransfer = DEFAULT_TRANSFER;
    if (plateRole === 'Source') {
      newTransfer.sourcePlate = selection.newPlateName;
      newTransfer.destinationPlate = selection.connectedPlateName;
    } else {
      newTransfer.sourcePlate = selection.connectedPlateName;
      newTransfer.destinationPlate = selection.newPlateName;
    }

    setCherryPick(prev => [...prev, { ...newTransfer, transferOrder: prev.length + 1 }]);
    setPlateOrigins(prev => addPlateOrigin(prev, selection.newPlateName));
  }, [allPlates, openAddPlateDialog, plateRole, setCherryPick, setPlateOrigins]);

  return (
    <>
      <IconButton
        size="small"
        onClick={handleAddPlate}
        color="primary"
        className={cx({ [classes.addPlateBtn]: plateRole === 'Destination' })}
        disabled={isReadonly}
      >
        <AddIcon />
      </IconButton>
      {addPlateDialog}
    </>
  );
});

type SinglePlateViewProps = {
  // Either source or destination plates, used to populate the stepper.
  plates: string[];
  activeStep: number;
  setActiveStep: SetActiveStep;
  isSource?: boolean;
  // List of all the plates defined by users
  allPlates: string[];
  setCherryPick: SetCherryPick;
  isReadonly: boolean;
};

// This component builds the various pages (one per plate) that can be
// accessed using the stepper.
const SinglePlateView = React.memo(function SinglePlateView(props: SinglePlateViewProps) {
  const classes = useStyles();
  const {
    plates,
    activeStep,
    setActiveStep,
    isSource,
    allPlates,
    setCherryPick,
    isReadonly,
  } = props;

  const plateRole: CherryPickPlateRole = useMemo(
    () => (isSource ? 'Source' : 'Destination'),
    [isSource],
  );

  const handleSelectStep = useCallback(
    (step: number) => () => {
      logEvent('select-plate-from-stepper', ScreenRegistry.CHERRY_PICKER);
      if (isSource) {
        // Reset destination to 0 to avoid trying to access OoB index
        setActiveStep({ destination: 0, source: step });
      } else {
        setActiveStep(prev => ({ ...prev, destination: step }));
      }
    },
    [isSource, setActiveStep],
  );

  const classOverride = useMemo(
    () => ({
      horizontal: classes.stepper,
      root: classes.stepper,
      vertical: classes.stepperVertical,
    }),
    [classes],
  );

  return (
    <Stack paddingTop={5}>
      <Typography variant="h2" className={classes.plateRole}>
        {`${isSource ? 'Source' : 'Destination'}`} Plate
      </Typography>
      <div className={cx({ [classes.destPlate]: !isSource })}>
        {plates.map((plate, index) => (
          <Fade
            in={activeStep === index}
            key={`slide-${index}`}
            // Slow down the transition to make it look nicer
            {...(activeStep === index ? { timeout: 500 } : {})}
          >
            <div hidden={activeStep !== index} key={`${plate}-${index}`}>
              <CherryPickPlateViz plateRole={plateRole} plateName={plate} />
            </div>
          </Fade>
        ))}
        <Stepper
          nonLinear
          activeStep={activeStep}
          orientation={isSource ? 'horizontal' : 'vertical'}
          className={cx({
            [classes.sourceStepper]: isSource,
            [classes.stepperHorizontal]: true,
          })}
        >
          {plates.map((_, index) => (
            <Step key={index} classes={classOverride}>
              <StepButton
                onClick={handleSelectStep(index)}
                disableRipple
                classes={classOverride}
              />
            </Step>
          ))}
          <AddPlateButton
            plateRole={plateRole}
            allPlates={allPlates}
            setCherryPick={setCherryPick}
            classes={classes}
            isReadonly={isReadonly}
          />
        </Stepper>
      </div>
    </Stack>
  );
});

const useStyles = makeStylesHook({
  addPlateBtn: {
    // Center the button in the vertical stepper
    marginLeft: '-3px',
    // Adjust size so that backdrop is perfectly round
    width: '30px',
    padding: '3px',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    borderBottom: `1px ${Colors.GREY_20} solid`,
  },
  destPlate: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',

    paddingTop: '6px',
  },
  rootSourceContainer: {
    backgroundColor: Colors.GREY_10,
    paddingBottom: '1rem',
  },
  // Make the stepper compact.
  stepper: {
    padding: 0,
    margin: 0,
    '& .MuiStepLabel-root': {
      padding: 0,
    },
  },
  stepperHorizontal: {
    justifyContent: 'center',
    padding: 0,
    marginTop: '1rem',
  },
  stepperVertical: {
    marginBottom: '4px',
  },
  sourceStepper: {
    backgroundColor: Colors.GREY_10,
  },
  plateRole: {
    textIndent: '2rem',
  },
});
