import React, { useCallback, useMemo } from 'react';

import Typography from '@mui/material/Typography';
import cx from 'classnames';

import FactorEditorButton from 'client/app/components/Parameters/DOEFactorEditor/FactorEditorButton';
import ElementParameterFactorSwitch from 'client/app/components/Parameters/ElementParameterFactorSwitch';
import ParameterHeader from 'client/app/components/Parameters/ElementParameterHeader';
import { getParameterDisplayName } from 'client/app/lib/workflow/elementConfigUtils';
import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { BundleParameters, FactorItem, Parameter } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type CommonProps = {
  elementId: string;
  /** A list of DOE factors set in the current design */
  factors: FactorItem[];
  workflowName: string;
  instanceName: string;
  isReadonly?: boolean;
};

type Props = {
  className?: string;
  /** A list of parameters (information about the parameters, not values) */
  parameters: readonly Parameter[];
  factorisedParameters: BundleParameters;
} & CommonProps;

const DOEElementParameterList = ({
  className,
  parameters,
  factorisedParameters,
  elementId,
  workflowName,
  instanceName,
  factors,
  isReadonly,
}: Props) => {
  const classes = useStyles();
  const doeParameters = useMemo(
    () => parameters.filter(p => p.configuration?.isDOEable),
    [parameters],
  );

  return (
    <div className={cx(className, classes.parameters)}>
      {doeParameters.map(parameter => (
        <DOEParameter
          key={parameter.name}
          factors={factors}
          elementId={elementId}
          parameter={parameter}
          workflowName={workflowName}
          instanceName={instanceName}
          isReadonly={isReadonly}
          isFactorised={!!factorisedParameters[instanceName]?.[parameter.name]}
        />
      ))}
    </div>
  );
};

type DOEParameterProps = { parameter: Parameter; isFactorised: boolean } & CommonProps;

const DOEParameter = ({
  elementId,
  parameter,
  factors,
  workflowName,
  instanceName,
  isReadonly,
  isFactorised,
}: DOEParameterProps) => {
  const classes = useStyles();
  const isElementConfigDebugModeEnabled = useFeatureToggle(
    'ELEMENT_CONFIGURATION_DEBUG_MODE',
  );
  const dispatch = useWorkflowBuilderDispatch();
  const displayName = getParameterDisplayName(parameter, isElementConfigDebugModeEnabled);

  const toggleFactor = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: 'toggleFactor',
        payload: {
          elementName: instanceName,
          parameterName: parameter.name,
          factorised: e.target.checked,
        },
      });
    },
    [dispatch, instanceName, parameter.name],
  );

  return (
    <div>
      <ParameterHeader
        className={classes.parameterHeader}
        elementId={elementId}
        name={parameter.name}
        displayName={displayName}
        isRequired={false}
      />

      <div className={classes.parameterValue}>
        <ElementParameterFactorSwitch
          checked={isFactorised}
          onChange={toggleFactor}
          disabled={isReadonly}
        />

        {isFactorised ? (
          <FactorEditorButton
            parameter={parameter}
            workflowName={workflowName}
            instanceName={instanceName}
            factors={factors}
          />
        ) : (
          <Typography variant="caption" className={classes.notFactorised}>
            Parameter is not factorised
          </Typography>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStylesHook(({ spacing }) => ({
  parameters: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(5),
    padding: spacing(0, 2),
  },
  parameterValue: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gap: spacing(4),
  },
  notFactorised: {
    padding: spacing(3, 4),
    height: '32px',
    background: Colors.GREY_10,
    borderRadius: spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  parameterHeader: {
    marginTop: 0,
    marginBottom: spacing(2),
  },
}));

export default DOEElementParameterList;
