import { shallowEqual } from 'common/lib/data';
import { WellLocationOnDeckItem } from 'common/types/mix';
import { DeckItemWithWellsGeometry } from 'common/ui/components/simulation-details/mix/DeckLayout';

/**
 * Clicking on the labels (e.g. "A", "B", "1", "4") will select the entire
 * line (row or column).
 *
 * There are 2 cases:
 * 1. Line has no or some  selected wells. In this case, we select all wells in the line which are
 * not already selected.
 * 2. Line has all selected wells. In this case, we clear the selection of the whole line.
 *
 * @param selectedWells All wells that are currently selected
 * @param lineIndex Index of the line the user clicked onto.
 * @param geometry
 * @param rowOrCol Whether the selected line a row or a column.
 * @param deckItemId The plate id, used to fill up WellLocationOnDeckItem's `deck_item_id`
 */
export function selectLine(
  selectedWells: readonly WellLocationOnDeckItem[],
  lineIndex: number,
  rowOrCol: 'row' | 'col',
  geometry: DeckItemWithWellsGeometry,
  deckItemId: string,
): WellLocationOnDeckItem[] {
  const { wellsAlreadySelectedInThisLine, indicesToSkip } =
    getWellsAlreadySelectedInSameLine(selectedWells, lineIndex, rowOrCol);

  // Row length is equal to the number of columns, and vice versa, e.g.
  //   1 2 3 4
  // A O O O O
  // B O O O O
  // Here, row length is 4. Column length is 2.
  const lineLength = rowOrCol === 'row' ? geometry.columns : geometry.rows;

  const isFullLineSelected = wellsAlreadySelectedInThisLine.length === lineLength;
  // If the full line is selected, we need to remove the whole line from the list of selected wells
  if (isFullLineSelected) {
    const selectedWellsMinusEntireLine = selectedWells.filter(
      well =>
        !wellsAlreadySelectedInThisLine.some(selectedLoc =>
          shallowEqual(selectedLoc, well),
        ),
    );
    return selectedWellsMinusEntireLine;
  } else {
    const newSelectedWells = [...selectedWells];
    // The selected row had some or no wells selected.
    // Fill in the missing wells.
    for (let i = 0; i < lineLength; i++) {
      // Don't re-add wells already selected.
      if (indicesToSkip.includes(i)) {
        continue;
      }

      const wellToAdd =
        rowOrCol === 'row'
          ? { col: i, row: lineIndex, deck_item_id: deckItemId }
          : { col: lineIndex, row: i, deck_item_id: deckItemId };

      newSelectedWells.push(wellToAdd);
    }
    return newSelectedWells;
  }
}

/**
 * Get a list of wells already selected in a given line. Used to not
 * re-select already selected wells, and to check if a row is full.
 *
 * @param currentSelectedWells All currently selected wells
 * @param lineIndex Row or col index of the clicked label
 * @param rowOrCol Which line type (row or col) to consider
 */
function getWellsAlreadySelectedInSameLine(
  currentSelectedWells: readonly WellLocationOnDeckItem[],
  lineIndex: number,
  rowOrCol: 'row' | 'col',
): {
  wellsAlreadySelectedInThisLine: WellLocationOnDeckItem[];
  indicesToSkip: number[];
} {
  // This is used to check if a row is full, in which case
  // we filter out this list from the whole list of selected wells.
  const wellsAlreadySelectedInThisLine: WellLocationOnDeckItem[] = [];
  // If a well is already selected, we should not add it to the selection again.
  // Keeping track of just the index to make it simpler to avoid re-adding wells.
  const indicesToSkip: number[] = [];

  for (const selectedWell of currentSelectedWells) {
    const selectedWellLiesInSelectedLine = selectedWell[rowOrCol] === lineIndex;
    if (selectedWellLiesInSelectedLine) {
      wellsAlreadySelectedInThisLine.push(selectedWell);
      indicesToSkip.push(rowOrCol === 'row' ? selectedWell.col : selectedWell.row);
    }
  }

  return { wellsAlreadySelectedInThisLine, indicesToSkip };
}
