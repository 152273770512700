import { CSSProperties } from '@mui/styles';

import Colors from 'common/ui/Colors';

/** Style constants for the Simulation Details screen */
export default {
  lightBorder: `1px solid ${Colors.GREY_20}`,
  deckItemLabel: {
    alignSelf: 'flex-start',
    flex: 1,
    textAlign: 'center',
    // Display the name above the deck item
    transform: 'translate(0%, -100%)',
    paddingBottom: '1px',
    // Use a flexbox to ensure the label is centred relative to the plate
    display: 'flex',
    // There can be two parts to a label: a name and a plate description. These
    // should be wrapped so they appear on separate lines.
    flexFlow: 'row wrap',
    // Centre the label relative to the plate; this works even if the label
    // exceeds the width of the plate.
    justifyContent: 'center',
    wordBreak: 'break-word',
    lineHeight: '1.1em',
  } as CSSProperties,
  deckItemName: {
    marginBottom: '2px',
    lineHeight: '1.1em',
    // Use flex-basis to set a minimum width for the label, even if this exceeds
    // the width of the plate. This is important for skinny plates (e.g.
    // troughs) where labels would otherwise be confined to a very narrow width.
    flex: '1 0 100px',
    // Make width at least 100% to prevent name and plate appearing on same line
    minWidth: '100%',
  } as CSSProperties,
  deckItemPlate: {
    fontSize: '0.8em',
    color: Colors.PLATE_DETAIL_FOREGROUND,
    flex: '1 0 100px',
    minWidth: '100%',
  } as CSSProperties,
  panelHeader: {
    display: 'block',
    fontSize: '24px',
    marginBottom: '24px',
  } as CSSProperties,
};
