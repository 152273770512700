import React, { FunctionComponent, ReactNode } from 'react';

import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Link, useHistory } from 'react-router-dom';

import { QUERY_UI_BOOTUP } from 'client/app/api/gql/queries';
import { usePermissions } from 'client/app/components/Permissions';
import { ScreenRegistry } from 'client/app/registry';
import EmptyExperimentBanner from 'common/assets/EmptyExperimentBanner';
import { ExperimentBanner } from 'common/assets/ExperimentBanner';
import NoEntitiesBanner from 'common/assets/NoEntitiesBanner';
import { SynthaceLogo } from 'common/assets/SynthaceLogo';
import Colors from 'common/ui/Colors';

/**
 * The welcome page is displayed by the <WelcomeCheck> component when someone logs in with an access token that has no permissions granted.
 * It calls the UIBootupQuery to ensure that the user creation code in appserver has had a chance to run.
 * That code will assign the user a default role, then we get the user to click the 'start experiment' button to grab a new auth token that has the updated permissions on
 */
export const WelcomePage: FunctionComponent = () => {
  const history = useHistory();
  const { updatePermissions } = usePermissions();
  const { loading, error } = useQuery(QUERY_UI_BOOTUP);

  if (error) {
    return (
      <WelcomeBase
        image={<NoEntitiesBanner variant="blue" />}
        content={
          <Typography variant="h5">
            Your account set up has failed, please contact us at{' '}
            <Link
              to=""
              onClick={() =>
                (window.location.href = 'mailto:customersuccess@synthace.com')
              }
            >
              support@synthace.com
            </Link>
          </Typography>
        }
      />
    );
  }

  if (loading) {
    return (
      <WelcomeBase
        image={<EmptyExperimentBanner variant="small" />}
        content={
          <Typography variant="h5">
            Wait a moment, we are setting up your account
          </Typography>
        }
      />
    );
  }

  const buttonClicked = async () => {
    await updatePermissions();
    history.push(ScreenRegistry.EXPERIMENTS);
  };

  return (
    <WelcomeBase
      image={<ExperimentBanner />}
      content={
        <>
          <Typography variant="h5">Your account is all set!</Typography>
          <StyledButton
            variant="outlined"
            color="primary"
            fullWidth
            onClick={buttonClicked}
          >
            START EXPERIMENT
          </StyledButton>
        </>
      }
    />
  );
};

const WelcomeBase = ({ image, content }: { image: ReactNode; content: ReactNode }) => {
  return (
    <Container>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={3}>
          <StyledCard>
            <LogoWrapper>
              <StyledSynthaceLogo />
            </LogoWrapper>
            <StyledCardMedia>{image}</StyledCardMedia>
            <StyledCardContent>{content}</StyledCardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Container>
  );
};

// The styling for this component is intended to mimic the auth0 login flow

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
});

const Container = styled('div')({
  height: '100%',
  width: '100%',
  background:
    'linear-gradient(126.6deg, rgba(22, 112, 255, 0.3) 47.04%, rgba(151, 16, 255, 0.3) 82.93%), linear-gradient(white, white) !important;',
  display: 'grid',
});

const LogoWrapper = styled('div')({
  background: Colors.GREY_10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 2em',
  height: '88px',
});

const StyledSynthaceLogo = styled(SynthaceLogo)({
  height: '32px',
});

const StyledButton = styled(Button)({
  marginTop: '1.3em',
});

const StyledCard = styled(Card)({
  borderRadius: '8px',
  width: '300px',
});

const StyledCardMedia = styled(CardMedia)({
  marginTop: '2em',
  padding: '0 2em',
});
