import React from 'react';

import cx from 'classnames';

import { sanitizeDeckItemName } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import DeckLayout, {
  WELL_BORDER_WIDTH_PX,
} from 'common/ui/components/simulation-details/mix/DeckLayout';
import {
  getTipState,
  TipboxState,
} from 'common/ui/components/simulation-details/mix/TipboxState';
import Styles from 'common/ui/components/simulation-details/Styles';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  // We display which tips were used at the current step
  currentStep: number;
  deckLayout: DeckLayout;
  tipbox: TipboxState;
};

export default React.memo(function TipboxView(props: Props) {
  const classes = useStyles();
  const { currentStep, tipbox } = props;
  const { rows: num_rows, columns: num_columns } = tipbox;
  const tipCircles = [];

  const geometry = props.deckLayout.getCurrentGeometry(tipbox);

  for (let row = 0; row < num_rows; row++) {
    for (let col = 0; col < num_columns; col++) {
      const tipAbsolutePos = geometry.getWellRect(col, row);
      const tipClasses = [classes.tip];
      const tipState = getTipState(tipbox, col, row);
      if (!tipState) {
        tipClasses.push(classes.missingTip);
      } else {
        if (tipState.usedAtStep === null) {
          tipClasses.push(classes.availableTip);
        } else if (tipState.usedAtStep === currentStep) {
          tipClasses.push(classes.tipBeingUsedNow);
        } else if (tipState.usedAtStep < currentStep) {
          // Used in the past
          tipClasses.push(classes.missingTip);
        }
      }
      tipCircles.push(
        <div
          className={tipClasses.join(' ')}
          style={tipAbsolutePos}
          key={row * num_columns + col}
        />,
      );
    }
  }
  const lastTipboxReplacementStep =
    tipbox.tipboxReplacedAtSteps[tipbox.tipboxReplacedAtSteps.length - 1];
  const isRefilling = lastTipboxReplacementStep === currentStep;

  return (
    <div
      className={cx(classes.tipboxView, { [classes.refilling]: isRefilling })}
      style={{ backgroundColor: props.tipbox.color }}
    >
      <div className={classes.deckItemLabel}>
        <div className={classes.deckItemName}>{sanitizeDeckItemName(tipbox.name)}</div>
      </div>
      {tipCircles}
    </div>
  );
});

const useStyles = makeStylesHook({
  tipboxView: {
    position: 'relative',
    height: '100%',
    background: Colors.TIPBOX,
    border: Styles.lightBorder,
  },
  deckItemLabel: Styles.deckItemLabel,
  deckItemName: Styles.deckItemName,
  tip: {
    alignItems: 'center',
    border: `${WELL_BORDER_WIDTH_PX}px solid #e4e8e8`,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
  },
  availableTip: {
    background: Colors.TIP,
  },
  missingTip: {
    background: Colors.MISSING_TIP,
    transform: 'scale(0.75)',
    border: 'none',
  },
  tipBeingUsedNow: {
    background: Colors.CURRENT_TIP,
  },
  refilling: {
    boxShadow: `0 0 0 3px ${Colors.TIPBOX_REFILL_HIGHLIGHT}`,
  },
});
