/**
 * Returns true on local or dev environment.
 * This is useful when first launching features to employees only.
 */
export function isLocalOrDevEnvironment() {
  const host = window.location?.host;
  return (
    host === 'antha.lol:8800' ||
    host === 'localhost.antha.com:8800' ||
    host === 'antha.ninja' ||
    host === 'dev.synthace.bio'
  );
}

export type EnvName = 'localhost' | 'dev' | 'staging' | 'production' | 'anthahub';

const hostToEnvName: { [key: string]: EnvName } = {
  'antha.lol:8800': 'localhost',
  'localhost.antha.com:8800': 'localhost',

  'antha.ninja': 'dev',
  'alpha.antha.com': 'dev',
  'alpha.synthace.bio': 'dev',
  'ci.antha.com': 'dev',
  'ci.synthace.bio': 'dev',

  'antha.reviews': 'staging',

  'antha.com': 'production',
  'synthace.bio': 'production',

  // AnthaHub related host
  'localhost:5001': 'anthahub',
  'localhost:8080': 'localhost',
};

export function isLocalDev(location: Location | undefined): boolean {
  return 'localhost' === getEnvName(location);
}

/**
 * Return what is the appropriate env nickname based on the host.
 * If the host ends in <something>.synthace.bio (and isn't one of our known dev environments
 * listed in hostToEnvName that end in .antha.com) we assume that this is a production env
 * and as part of the multiple prod envs, we will return <something> as the env name.
 * Falls back to unknown if we don't know the environment.
 * @param host the url of the environment (e.g. window.location.host)
 * @returns the appropriate env nickname based on the URL
 */
export function getEnvName(location: Location | undefined): string {
  const hostURL = location?.host || '';
  const env = hostToEnvName[hostURL];
  if (env) {
    return env;
  }
  // Handle the standard naming patterns: *.antha.com and *.synthace.io
  if (hostURL.endsWith('.antha.com')) {
    return hostURL.split('.antha.com')[0];
  } else if (hostURL.endsWith('.synthace.bio')) {
    return hostURL.split('.synthace.bio')[0];
  } else if (hostURL.endsWith('.synthace.io')) {
    return hostURL.split('.synthace.io')[0];
  }
  return 'unknown';
}

export type LogoColours = 'purple' | 'orange' | 'blue' | 'multicolor';
export function getEnvColour(env: string): LogoColours {
  switch (env) {
    case 'localhost':
      return 'purple';

    case 'dev':
      return 'orange';

    case 'staging':
    case 'beta':
      return 'blue';

    case 'production':
    case 'anthahub':
    default:
      return 'multicolor';
  }
}

export function setFaviconForEnvironment() {
  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  if (favicon) {
    const env = getEnvName(window.location);
    const colour = getEnvColour(env);

    if (colour !== 'multicolor') {
      favicon.href = `/app/assets/images/synthace-favicon-${colour}.svg`;
    } else {
      favicon.href = `/app/assets/images/synthace-favicon.svg`;
    }

    favicon.type = 'image/svg+xml';
  }
}
