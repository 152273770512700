import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { QUERY_ENTITIES_COUNT } from 'client/app/api/gql/queries';

type EntitiesCountResponse = {
  data: EntitiesCount | undefined;
  loading: boolean;
};

type EntitiesCount = {
  workflows: number;
  doeWorkflows: number;
  incomingWorkflows: number;
  executions: number;
};

/**
 * Return the individual count of workflows, executions, shared workflows
 * and DOE workflows. Useful to e.g. show a "No workflows" message to users.
 */
export function useEntitiesCount(): EntitiesCountResponse {
  const { data, loading } = useQuery(QUERY_ENTITIES_COUNT);
  const entitiesCount = useMemo(() => {
    if (loading) {
      return { data: undefined, loading: true };
    }
    if (!data) {
      return { data: undefined, loading: false };
    }

    const { workflows, doeWorkflows, incomingWorkflows, executions } = data.entitiesCount;
    return {
      data: { workflows, doeWorkflows, incomingWorkflows, executions },
      loading: false,
    };
  }, [data, loading]);

  return entitiesCount;
}
