// NOTE: Values and types in this file get persisted in the database; keep
// compatibility in mind when making changes.

type AnthaCoreTaskKind = 'mix' | 'analytic' | 'qpcr' | 'run';

// 'qpcrV1' was a legacy format for coalescing the two tasks together that made up
// a QPCR task for UI purposes, before the new standard 'qpcr' task type was created.
export type SimulationTaskType = AnthaCoreTaskKind | 'qpcrV1';
export type SimulationTaskPayload = QpcrV1TaskPayload /* | AnotherTaskPayload */;

export type QpcrV1TaskPayload = {
  promptText: string;
};

export type ErrorContext =
  | SimulationElementErrorContext
  | { [key: string]: any; element?: never }
  | null;

/**
 * Antha errors will be structured in the JSON format with the keys of
 * code, message, details, message_template and context.
 */
export type SimulationError = {
  message: string;
  message_template?: string;
  message_type?: 'text' | 'markdown';
  code: string | null;
  details: string | null;
  // Antha error context can vary and we currently care about if it is an element error.
  context: ErrorContext;
};

export type FormattedSimulationError = Omit<
  SimulationError,
  'message_template' | 'context'
>;

/**
 * Details of the element instance that has caused a given simulation error,
 * provided by Antha core.
 */
type SimulationElementErrorContext = {
  [key: string]: any;
  element: {
    instance_id: string; // Doesn't map to client ID or anything relevant client-side.
    instance_name: string;
    type_name: string;
  };
};

/**
 * In January 2020, in order to get errors with more structure from antha-core
 * to the UI (T391), antha-core started passing errors as maps with a few keys
 * (including code, message and details). Because channels through which the
 * error travels along the way only accommodate a single string, the new map is
 * passed JSON-encoded inside the string.
 */
export function parseAnthaCoreError(error: string): SimulationError {
  try {
    // First try to parse the string as JSON.
    const parsed = JSON.parse(error) as SimulationError;
    return {
      message: parsed.message ?? '',
      message_type: parsed.message_type ?? 'text',
      code: parsed.code ?? null,
      details: parsed.details ?? null,
      context: parsed.context ?? null,
    };
  } catch (e) {
    // Failed to parse the input string as JSON.  We assume that the error is
    // from an old simulation result where the error itself is the message.
    return {
      message: error,
      message_type: 'text',
      code: null,
      details: null,
      context: null,
    };
  }
}
