import { useMemo } from 'react';

import { useElementInstance } from 'client/app/apps/workflow-builder/lib/useElementInstance';
import {
  useGetDeviceRunConfigs,
  useGetSelectedMainDevice,
  useParseDeviceRunConfig,
} from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { pluralize } from 'common/lib/format';
import { ElementInstance } from 'common/types/bundle';
import { DeckState } from 'common/types/mix';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';

export function useSelectedDeckConfiguration() {
  const { selectedDevice } = useGetSelectedMainDevice();
  const { loading: loadingAllRunConfigs, allRunConfigOptions } = useGetDeviceRunConfigs();

  const selectedRunConfigId = selectedDevice?.runConfigId;
  const { loading: loadingRunConfig, data: runConfigData } = useParseDeviceRunConfig(
    selectedDevice?.deviceId ?? '',
    selectedRunConfigId,
  );
  const deckState = runConfigData?.parsedRunConfig.config.layout as DeckState;

  const deckLayout = useMemo(
    () =>
      deckState
        ? new DeckLayout({
          before: deckState,
          after: deckState,
          version: '',
        })
        : null,
    [deckState],
  );

  if (loadingAllRunConfigs || loadingRunConfig) {
    return {
      loading: true,
      deckLayout,
      disableDeckPositions: true,
      plateMoveToPositions: null,
    };
  }

  const hasDeckState = !!deckState;
  const hasManyRunConfigs = allRunConfigOptions.length > 0;
  const plateMoveToPositions = new Set<string>(
    runConfigData?.parsedRunConfig.config.validAddresses.moveableLocations,
  );

  const selectedRunConfiguration = allRunConfigOptions.find(
    runConfigOption => runConfigOption.value === selectedRunConfigId,
  );

  /**
   * There are 4 possible cases of Deck Layout selection:
   * 1. There are no Run Configurations and no Deck State available
   *    (e.g. Manual - this case should not be possible as MovePlates element should not be available for selection)
   * 2. There are no Run Configurations but Deck State is valid
   *    (e.g. Gilson - deck layout set to default and parameter button should be enabled)
   * 3. There are many Run Configurations but none of them is selected (e.g. Hamilton - disabled parameter button)
   * 4. There are many Run Configurations and one of them is selected (e.g. Hamilton - enabled parameter button)
   */
  const disableDeckPositions =
    (hasManyRunConfigs && !selectedRunConfiguration) ||
    (!hasManyRunConfigs && !hasDeckState);

  return {
    loading: false,
    disableDeckPositions,
    deckLayout,
    plateMoveToPositions,
  };
}

export function useDeckPositionsParameter() {
  const dispatch = useWorkflowBuilderDispatch();
  const elementInstance = useElementInstance();

  const [parameterName, value] = useDeckPositionsValue(elementInstance);

  const update = (selectedDeckPositions: string[]) => {
    if (!elementInstance || !parameterName) return;
    dispatch({
      type: 'updateParameter',
      payload: {
        instanceName: elementInstance.name,
        parameterName,
        value: selectedDeckPositions,
      },
    });
  };

  return { value, update };
}

function useDeckPositionsValue(
  elementInstance?: ElementInstance,
): [string | null, string[] | null] {
  const parameters = useWorkflowBuilderSelector(state => state.parameters);

  return useMemo(() => {
    const elementParamConfig = elementInstance?.element.configuration?.parameters;

    if (!elementParamConfig) return [null, null];

    for (const parameterName in elementParamConfig) {
      if (isDeckPositionsParameter(parameterName, elementInstance)) {
        return [parameterName, parameters[elementInstance.name]?.[parameterName]];
      }
    }

    return [null, null];
  }, [elementInstance, parameters]);
}

export function isDeckPositionsParameter(
  parameterName: string,
  elementInstance?: ElementInstance,
) {
  return (
    elementInstance?.element.configuration?.parameters[parameterName]?.editor.type ===
    EditorType.DECK_POSITIONS
  );
}

export const getSelectPositionsButtonCopy = (selectedPositions: string[] | undefined) =>
  !selectedPositions?.length
    ? 'Select deck positions'
    : pluralize(selectedPositions.length, 'position') + ' selected';
