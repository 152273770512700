import {
  FeatureToggle,
  internalFeatureList,
  upcomingFeatureList,
} from 'common/features/featureToggles';

// Union type containing all keys from the below list of features
export type FeatureName = keyof typeof featureList;

export const featureList = { ...internalFeatureList, ...upcomingFeatureList };
const FEATURE_TOGGLE_SESSION_STORAGE_KEY = 'featuretoggles';
const FEATURE_TOGGLE_OVERRIDE_FLAG_STORAGE_KEY = 'overrideFeatureToggles';

export function getAll(): FeatureToggle[] | null {
  const rawFeatureList = window.sessionStorage.getItem(
    FEATURE_TOGGLE_SESSION_STORAGE_KEY,
  );

  // Data hasn't yet been written to the Session Storage,
  // or data was corrupted. In this case, all feature toggles
  // will be OFF.
  if (!rawFeatureList) {
    return null;
  }

  return JSON.parse(rawFeatureList);
}

/**
 * Check if a feature toggle is on.
 *
 * For use in XHR/non-React codepaths.  React components should prefer
 * `FeatureTogglesContext` in order to automatically re-render when feature
 * toggles change.
 */
export function isEnabled(name: FeatureName): boolean {
  const featureList = getAll();
  if (!featureList) {
    return false;
  }

  const feature = featureList.find(feature => feature.name === name);
  return feature?.enabled || false;
}

export function setEnabled(name: FeatureName, enabled: boolean) {
  const featureList = getAll();
  if (!featureList) {
    return;
  }

  for (const feature of featureList) {
    if (feature.name === name) {
      feature.enabled = enabled;
    }
  }

  setAll(featureList);
}

/**
 * Updates the value of  all the feature toggles passed in the argument.
 * Useful when e.g. fetching the list of features from the backend and
 * having to update the UI state.
 */
export function setAll(newFeatureList: readonly FeatureToggle[]) {
  window.sessionStorage.setItem(
    FEATURE_TOGGLE_SESSION_STORAGE_KEY,
    JSON.stringify(newFeatureList),
  );
}

/**
 * Give priority to user preference (vs backend preference)
 * on feature toggles for this browser session.
 */
export function setHasOpenedFeatureTogglesDialog() {
  window.sessionStorage.setItem(FEATURE_TOGGLE_OVERRIDE_FLAG_STORAGE_KEY, 'on');
}

/**
 * Check if the user has already opened the feature toggle
 * dialog in this browser session.
 */
export function hasOpenedFeatureTogglesDialog() {
  return window.sessionStorage.getItem(FEATURE_TOGGLE_OVERRIDE_FLAG_STORAGE_KEY) === 'on';
}
