import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ExecutionIcon = React.memo<SvgIconProps>(function ExecutionIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg fill="none">
        <circle cx="16.75" cy="16.75" r="5.5" stroke="currentColor" strokeWidth="2" />
        <path
          d="M19.75 16.75l-4.5 2.598v-5.196l4.5 2.598zM4.5 7h6v2h-6zM4.5 10.5H8v2H4.5z"
          fill="currentColor"
        />
        <mask id="a" fill="currentColor">
          <path d="M11.489 3.5a2.75 2.75 0 00-5.478 0H2a1 1 0 00-1 1v14.813a1 1 0 001 1h4v-2H3V5.5h10.5v1.25h2V4.5a1 1 0 00-1-1h-3.011zM8.75 3a.75.75 0 110 1.5.75.75 0 010-1.5z" />
        </mask>
        <path
          d="M11.489 3.5a2.75 2.75 0 00-5.478 0H2a1 1 0 00-1 1v14.813a1 1 0 001 1h4v-2H3V5.5h10.5v1.25h2V4.5a1 1 0 00-1-1h-3.011zM8.75 3a.75.75 0 110 1.5.75.75 0 010-1.5z"
          fill="currentColor"
        />
        <path
          d="M11.489 3.5l-1.992.18.164 1.82h1.828v-2zm-5.478 0v2H7.84l.164-1.82-1.992-.18zM6 20.313v2h2v-2H6zm0-2h2v-2H6v2zm-3 0H1v2h2v-2zM3 5.5v-2H1v2h2zm10.5 0h2v-2h-2v2zm0 1.25h-2v2h2v-2zm2 0v2h2v-2h-2zM9.457 3.5l1.886-.666-1.886.666zm-1.414 0l1.885.666L8.043 3.5zM8.75 3c.39 0 .713.3.747.68l3.984-.36A4.75 4.75 0 008.75-1v4zm-.747.68A.75.75 0 018.75 3v-4a4.75 4.75 0 00-4.73 4.32l3.983.36zM2 5.5h4.011v-4H2v4zm1-1a1 1 0 01-1 1v-4a3 3 0 00-3 3h4zm0 14.813V4.5h-4v14.813h4zm-1-1a1 1 0 011 1h-4a3 3 0 003 3v-4zm4 0H2v4h4v-4zm-2 0v2h4v-2H4zm-1 2h3v-4H3v4zM1 5.5v12.813h4V5.5H1zm5.629-2H3v4h3.629v-4zm4.242 0H6.63v4h4.242v-4zm2.629 0h-2.629v4H13.5v-4zm2 3.25V5.5h-4v1.25h4zm0-2h-2v4h2v-4zm-2-.25v2.25h4V4.5h-4zm1 1a1 1 0 01-1-1h4a3 3 0 00-3-3v4zm-3.011 0H14.5v-4h-3.011v4zm-.146-2.666A2.75 2.75 0 008.75 1v4a1.25 1.25 0 01-1.178-.834l3.771-1.332zM7.572 4.166A1.251 1.251 0 017.5 3.75h4a2.75 2.75 0 00-.157-.916L7.572 4.166zM7.5 3.75c0-.69.56-1.25 1.25-1.25v4a2.75 2.75 0 002.75-2.75h-4zM8.75 2.5c.69 0 1.25.56 1.25 1.25H6A2.75 2.75 0 008.75 6.5v-4zM10 3.75c0 .143-.025.284-.072.416L6.157 2.834C6.055 3.123 6 3.432 6 3.75h4zM8.75 1a2.75 2.75 0 00-2.593 1.834l3.771 1.332A1.25 1.25 0 018.75 5V1z"
          fill="currentColor"
          mask="url(#a)"
        />
      </svg>
    </SvgIcon>
  );
});
