import React, { useCallback, useEffect, useState } from 'react';

import { getDefaultPlaceholder } from 'common/elementConfiguration/parameterUtils';
import Colors from 'common/ui/Colors';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  type: string;
  multiline?: boolean;
  disableUnderline?: boolean;

  validate?: (value: string) => string;
  onChange: (newValue: string) => void;

  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
} & ParameterEditorBaseProps<string>;

const GenericInputEditor = React.memo(function GenericInputEditor(props: Props) {
  const [validationError, setValidationError] = useState('');
  const [value, setValue] = useState(props.value ?? '');

  // Building good validation that isn't annoying to use is really hard.
  // User often type bogus values on the way to valid values. For that
  // reason, we're separating the validation here from the local value
  // from the value that we send to the onChange callback.
  //
  // The reason for all this separation is that we want the user to not
  // have their typing interrupted / altered -- even if it's invalid.
  // We should validation warnings in the UI (or at least we should be).
  // Type coercing on every key stroke, for instance, is a recipe for a
  // really, really annoying UX. Instead, we only send the value out
  // when a given change passes validation. That way a user can muck
  // about any all sorts of invalid states without sending junk out.

  const maybeValidate = useCallback(
    (value: any): string => {
      if (props.validate) {
        const validationError = props.validate(value);
        setValidationError(validationError);
        return validationError;
      }
      return '';
    },
    [props],
  );

  useEffect(() => {
    setValidationError('');
    setValue(props.value ?? '');
    maybeValidate(props.value);
  }, [maybeValidate, props.value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const error = maybeValidate(value);
    setValue(value);
    if (error === '') {
      props.onChange(value);
    }
  };

  const classes = useStyles();

  return (
    <>
      <TextField
        fullWidth
        placeholder={props.placeholder ?? getDefaultPlaceholder(props.type)}
        error={validationError !== ''}
        multiline={!!props.multiline}
        value={value}
        onChange={onChange}
        onKeyUp={props.onKeyUp}
        onBlur={props.onBlur}
        disabled={props.isDisabled}
        required={props.isRequired}
        InputProps={{
          disableUnderline: props.disableUnderline,
        }}
      />
      {validationError !== '' && <em className={classes.error}>{validationError}</em>}
    </>
  );
});

const useStyles = makeStylesHook({
  error: {
    color: Colors.ERROR,
    display: 'block',
    fontSize: '11px',
    padding: '5px 0',
  },
});

export default GenericInputEditor;
