import clamp from 'lodash/clamp';

import { Dimensions } from 'common/types/Dimensions';
import { Position2d } from 'common/types/Position';
import Colors from 'common/ui/Colors';

export type WorkspaceVariant = 'dots' | 'lines';

export const ZOOM_CURSOR = 'zoom-in';
export const DRAGGING_CURSOR = 'grabbing';

// How much do we zoom when using mouse wheel.
// This divides the wheel delta.
export const WHEEL_ZOOM_DIVISOR = 500;

// How much do we multiply zoom when just click to zoom.
export const DEFAULT_RATIO = 1.5;

// Anything more than triple the initial size is overkill.
// With 3 we are able to confortably read ports' tooltips.
export const MAX_ZOOM = 3;

// The most zoomed-out the workspace can be when it's empty. Good for giving power
// users some space to work.
const MIN_ZOOM = 0.2;

// MIN_ZOOM_RATIO refers to the furthest that the user can zoom out.
// This means that the content of the long side of workspace can at smallest
// be 80% of size of the viewport's size on that side. Basically, you should
// be able to zoom out to so tiny a size that you can't meaningfully interact
// with the UI.
const MIN_ZOOM_RATIO = 0.8;

// The most zoomed in the workspace can be when showAll is run.
const SHOW_ALL_MAX_ZOOM = 1;

// A default margin to leave around the workflow if we don't have the visible area or viewport.
const SHOW_ALL_DEFAULT_MARGIN = 100;

/**
 * Given the size of the content box, and the size of the visible canvas area, calculate a zoom
 * and content box position to best fit the workflow content into available space.
 */
export function fitContentToAvailableSpace(
  visibleCanvasArea: Dimensions | undefined,
  contentBox: HTMLElement | null,
  viewport: HTMLElement | null,
  minZoom: number,
) {
  const guardRatio = 1;
  let newZoom;
  let newContentBoxPosition: Position2d;

  if (contentBox && (visibleCanvasArea || viewport)) {
    // There may or may not be a visible area provided, depending on where `Workspace` is used
    // and when `showAll` is run (in the builder, the first run after mount happens before the
    // visible area has been reported by the control overlay), so we fall back to the whole
    // viewport size if it isn't available.
    const visibleArea = visibleCanvasArea ?? {
      top: 0,
      left: 0,
      width: viewport?.offsetWidth ?? 0,
      height: viewport?.offsetHeight ?? 0,
    };

    // When the visible area is provided, it already has a small margin, so we reduce the margin
    // factor in that case to produce a more consistent position of the workflow.
    const paddingPercentage = visibleCanvasArea ? 15 : 10;

    // Pad the workflow location proportionally to the visible area size.
    const paddingX = visibleArea.width / paddingPercentage;
    const paddingY = visibleArea.height / paddingPercentage;

    const availableWidth = Math.max(1, visibleArea.width - paddingX * 2);
    const availableHeight = Math.max(1, visibleArea.height - paddingY * 2);
    const contentWidth = contentBox.offsetWidth || availableWidth;
    const contentHeight = contentBox.offsetHeight || availableHeight;

    const widthRatio = Math.min(2, availableWidth / contentWidth);
    const heightRatio = Math.min(2, availableHeight / contentHeight);

    newZoom = Math.min(widthRatio, heightRatio, SHOW_ALL_MAX_ZOOM);
    newZoom = Math.max(newZoom, minZoom);

    const actualHeight = contentHeight * newZoom;

    // The visible area might be offset somewhere inside the viewport,
    // so we need to shift the content box to its top-left corner.
    const shiftX = visibleArea.left + paddingX;

    // Centre the workflow vertically. We don't centre horizontally, as we should try to
    // leave some space to show the instances panel if it's not currently visible.
    const shiftY = visibleArea.top + (visibleArea.height - actualHeight) / 2;

    newContentBoxPosition = { x: shiftX, y: shiftY };
  } else {
    newZoom = guardRatio;
    newContentBoxPosition = {
      x: SHOW_ALL_DEFAULT_MARGIN,
      y: SHOW_ALL_DEFAULT_MARGIN,
    };
  }

  return { position: newContentBoxPosition, zoom: newZoom };
}

export function getMinZoom(
  contentBox: HTMLElement | null,
  visibleCanvasArea?: Dimensions,
  viewportDims?: Dimensions,
) {
  const cb = contentBox;
  const vp = visibleCanvasArea ?? viewportDims;

  if (!cb || !vp) {
    return 1;
  }

  // containedMin refers to the zoomed-out size at which the contentBox's
  // shortest side fits exactly within that same side of the viewport.
  // We allow zooming out to a fraction of that size.
  const containedMin = Math.min(vp.width / cb.offsetWidth, vp.height / cb.offsetHeight);

  // DOM elements can't be measured. Happens e.g. when this component is hidden.
  if (Number.isNaN(containedMin)) {
    return MIN_ZOOM;
  }

  // If the layout is smaller than the viewport, don't allow zooming out.
  return Math.min(1, Math.max(MIN_ZOOM, containedMin * MIN_ZOOM_RATIO));
}

/**
 * CSS helper to get a scalable grid background for Workspace canvas.
 *
 * Appearance variants:
 * - Line grid (squares): Simulation Preview, Deck Layout etc.
 * - Dotted grid: Builder.
 *
 * When `disabled` the background is all grey
 */
export function buildGridBackground(
  position: Position2d,
  zoom: number,
  variant: WorkspaceVariant = 'lines',
  disabled: boolean = false,
) {
  if (disabled) return Colors.GREY_20;

  switch (variant) {
    case 'dots':
      return buildGridDotsBackground(position, zoom);
    case 'lines':
    default:
      return buildGridLineBackground(position, zoom);
  }
}

/**
 * Builds CSS for dotted grid background using `radial-gradient` CSS property.
 *
 * [Read more on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/gradient/radial-gradient)
 */
function buildGridDotsBackground(position: Position2d, contentZoom: number) {
  const zoom = clamp(contentZoom, 1, MAX_ZOOM);

  const dotSize = Math.round(zoom);
  const dotSpacing = zoom * 10;

  return `
    radial-gradient(circle, ${Colors.GREY_30} ${dotSize}px, transparent ${dotSize}px)
    ${position.x}px ${position.y}px / ${dotSpacing}px ${dotSpacing}px
  `;
}

/**
 * Builds CSS for line grid background using `linear-gradient` CSS property.
 *
 * [Read more on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/gradient/linear-gradient)
 */
function buildGridLineBackground(position: Position2d, contentZoom: number) {
  const zoom = clamp(contentZoom, MIN_ZOOM, MAX_ZOOM);

  const lineSpacing = 50;
  const inner = zoom * lineSpacing - 1;
  const outer = inner + 1;

  return `
    /* Horizontal lines */
    linear-gradient(to bottom, transparent ${inner}px, ${Colors.GREY_30} ${outer}px)
    ${position.x}px ${position.y}px / ${outer}px ${outer}px,
    /* Vertical lines */
    linear-gradient(to right, transparent ${inner}px, ${Colors.GREY_30} ${outer}px)
    ${position.x}px ${position.y}px / ${outer}px ${outer}px
  `;
}
