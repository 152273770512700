import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';

import * as FilesApi from 'client/app/api/FilesApi';
import { FileMetadata } from 'client/app/api/FilesApi';
import FileListItem from 'client/app/components/FileBrowser/FileListItem';
import {
  FileBrowserFileSingleSelection,
  FileBrowserValueTree,
} from 'client/app/lib/file-browser/types';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  valueTree: FileBrowserValueTree;
  files: FileMetadata[];
  path: string;
  nextDisplayPathSegment?: string;
  onItemClick: (item: FileMetadata, path: string) => void;
  onToggleItemSelected: (path: FileBrowserFileSingleSelection) => void;
  selectMultiple: boolean;
  limit: number;
  total: number;
  showMore: () => void;
  lastPage?: boolean;
  loadingPage?: boolean;
};

const useStyles = makeStylesHook({
  pagination: {
    alignItems: 'center',
    background: Colors.GREY_10,
    border: `1px ${Colors.GREY_20} solid`,
    borderRadius: '6px',
    color: '#000',
    display: 'flex',
    justifyContent: 'center',
    margin: '6px',
    padding: '0 12px',
  },
  middot: {
    display: 'inline-block',
    padding: '6px',
  },
});

const FileList = function (props: Props) {
  const classes = useStyles();

  const { files, lastPage, limit, total, showMore, loadingPage } = props;

  const list = [];
  for (let ii = 0; ii < files.length; ii++) {
    const item = files[ii];
    let itemName = item.name;
    if (item.type === FilesApi.DIRECTORY) {
      itemName += '/';
    }

    const isSelected =
      item.type === FilesApi.DIRECTORY
        ? !!props.valueTree[itemName] // Folders are unique, so we only check existence
        : props.valueTree[itemName] === item.ftl; // we can have duplicate file name  (when using filter), so we compare ftl.
    const isInDisplayPath =
      item.type === FilesApi.DIRECTORY &&
      item.name + '/' === props.nextDisplayPathSegment;

    list.push(
      <FileListItem
        key={item.ftl}
        path={props.path}
        item={item}
        isSelected={isSelected}
        isInDisplayPath={isInDisplayPath}
        onClick={props.onItemClick}
        onToggleSelected={props.onToggleItemSelected}
        selectMultiple={props.selectMultiple}
      />,
    );
  }

  let pagination;
  if (total > limit && limit > 0 && !lastPage) {
    pagination = (
      <div className={classes.pagination}>
        <span>
          Showing 1 - {limit}
          {total < Infinity && ' of ' + total}
        </span>
        <span className={classes.middot}>&middot;</span>
        {loadingPage ? (
          <CircularProgress size="1rem" />
        ) : (
          <Link onClick={showMore} underline="hover">
            Show more
          </Link>
        )}
      </div>
    );
  }

  return (
    <>
      {list}
      {pagination}
    </>
  );
};

export default FileList;
