import React, { useCallback } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

import { QUERY_EXAMPLE_WORKFLOWS } from 'client/app/api/gql/queries';
import { ExampleWorkflow } from 'client/app/components/cards/ExampleWorkflowCard';
import DataComponent from 'client/app/components/ExampleWorkflows/ExampleWorkflowsList/ExampleWorkflowsListDataComponent';
import NoDataComponent from 'client/app/components/ExampleWorkflows/ExampleWorkflowsList/ExampleWorkflowsListNoDataComponent';
import { QueryexampleWorkflowsArgs } from 'client/app/gql';
import usePagination from 'client/app/hooks/usePagination';
import { PageInfo } from 'common/server/graphql/pagination';
import { EntityCardSkeletonList } from 'common/ui/components/EntityCard';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';
import { trackHeapEvent } from 'common/ui/useHeapTracking';

type Props = {
  scrollableContainerRef: React.RefObject<HTMLDivElement>;
  searchQuery?: string;
  tagQuery?: string;
  onExampleWorkflowDetailsClick: (exampleWorkflow: ExampleWorkflow) => void;
  logCategory: string;
};

const ExampleWorkflowsList = ({
  scrollableContainerRef,
  searchQuery,
  tagQuery,
  onExampleWorkflowDetailsClick,
  logCategory,
}: Props) => {
  const variables: QueryexampleWorkflowsArgs = {
    search: searchQuery ?? undefined,
    tagName: tagQuery ?? undefined,
  };

  const exampleWorkflowsQuery = useQuery(QUERY_EXAMPLE_WORKFLOWS, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const pageInfo = exampleWorkflowsQuery.data?.exampleWorkflows.pageInfo as
    | PageInfo
    | undefined;

  const hasNextPage = usePagination({
    entity: 'exampleWorkflows',
    pageInfo,
    fetchMore: exampleWorkflowsQuery.fetchMore,
    dependencies: [],
    scrollableRef: scrollableContainerRef,
    isInitialLoading: exampleWorkflowsQuery.loading,
    variables: {},
  });

  const handleOnExampleWorkflowDetailsClick = useCallback(
    (exampleWorkflow: ExampleWorkflow) => {
      onExampleWorkflowDetailsClick?.(exampleWorkflow);
      trackHeapEvent('example-workflow-details-click', {
        id: exampleWorkflow.id,
        workflowId: exampleWorkflow.workflowId,
        exampleWorkflowIdSourceEnv: exampleWorkflow.exampleWorkflowIdSourceEnv,
        clickedFrom: logCategory,
        tagNames: exampleWorkflow.tags?.map(tag => tag?.name).join(', '),
        tagIds: exampleWorkflow.tags?.map(tag => tag?.id).join(', '),
      });
    },
    [onExampleWorkflowDetailsClick, logCategory],
  );

  return (
    <>
      <RenderQuery
        query={exampleWorkflowsQuery}
        renderData={DataComponent}
        renderNoData={NoDataComponent}
        additionalDataProps={{
          onExampleWorkflowDetailsClick: handleOnExampleWorkflowDetailsClick,
        }}
        loadingComponent={EntityCardSkeletonList}
        additionalNoDataProps={{ searchQuery }}
        emptyCondition={data => data.exampleWorkflows.items.length === 0}
      />
      {hasNextPage && (
        <LoadingContainer>
          <CircularProgress size={24} />
        </LoadingContainer>
      )}
    </>
  );
};

const LoadingContainer = styled('div')(() => ({
  width: 'fit-content',
  margin: '0 auto',
}));

export default ExampleWorkflowsList;
