import React, { useCallback, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useSimulationClient } from 'client/app/lib/SimulationClient';
import { SimulationNotificationDetails } from 'client/app/lib/workflow/types';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type EditableSimulationNameProps = {
  simulation: SimulationNotificationDetails;
  helperText?: string;
};

export function EditableSimulationName(props: EditableSimulationNameProps) {
  const { simulation, helperText } = props;
  const classes = useStyles();

  const [editEnabled, setEditEnabled] = useState(false);
  const [simulationName, setSimulationName] = useState(simulation.name);
  const onSimulationNameChange = useTextFieldChange(setSimulationName);

  const simulationService = useSimulationClient();
  const onEditIconClick = useCallback(async () => {
    if (!editEnabled) {
      setEditEnabled(!editEnabled);
    } else if (simulation.simulationId) {
      await simulationService.updateSimulation(simulation.simulationId, simulationName);
      setEditEnabled(!editEnabled);
    } else {
      // simulationId should always be set by the time that we get to calling this edit function.
      console.error('Missing Simulation id');
      return;
    }
  }, [editEnabled, simulation.simulationId, simulationName, simulationService]);

  return (
    <div className={classes.editSimulationName}>
      {
        /* only allow editing of simulation name if we have the simulationId */
        !simulation.simulationId ? (
          <Typography variant="body2" className={classes.simulationName}>
            {simulation.name}
          </Typography>
        ) : (
          <>
            <TextField
              variant="standard"
              className={classes.textField}
              disabled={!editEnabled}
              InputProps={{ classes: { input: classes.inputProps } }}
              multiline
              onChange={onSimulationNameChange}
              type="text"
              value={simulationName}
              helperText={helperText}
            />
            <IconButton
              className={classes.iconButton}
              color="primary"
              onClick={onEditIconClick}
              size="large"
            >
              {!editEnabled ? (
                <EditIcon fontSize="small" />
              ) : (
                <CheckIcon fontSize="small" />
              )}
            </IconButton>
          </>
        )
      }
    </div>
  );
}

const useStyles = makeStylesHook({
  editSimulationName: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '12px',
  },
  iconButton: {
    padding: '4px',
  },
  inputProps: { fontSize: 14 },
  simulationName: {
    color: Colors.GREY_40,
  },
  textField: {
    width: '90%',
  },
});
