import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export default React.memo(function BranchIcon(
  props: React.PropsWithChildren<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>>,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" fill="none">
        <path
          d="M4.75 9.25a2.75 2.75 0 110 5.5 2.75 2.75 0 010-5.5zM19.25 14a2.75 2.75 0 110 5.5 2.75 2.75 0 010-5.5zM19.25 4a2.75 2.75 0 110 5.5 2.75 2.75 0 010-5.5z"
          fill="currentColor"
        />
        <path d="M7 13v-2h4.25v2H7zM17.25 16v2H13v-2h4.25z" fill="currentColor" />
        <path d="M13 18h-2V6h2v12zM17.25 6v2H13V6h4.25z" fill="currentColor" />
      </svg>
    </SvgIcon>
  );
});
