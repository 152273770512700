import React, { useRef } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

import PlateContentsEditorContents from 'client/app/components/Parameters/PlateContents/PlateContentsEditorContents';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  onClose: () => void;
} & DialogProps<null>;

export default function PlateLayoutEditorDialog({ onClose, isOpen }: Props) {
  const dialogContentRef = useRef<HTMLDivElement>(null);
  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen disableEscapeKeyDown>
      <StyledDialogContent ref={dialogContentRef}>
        <PlateContentsEditorContents containerRef={dialogContentRef} />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={onClose} variant="primary" color="primary">
          Done
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}

const StyledDialogContent = styled(DialogContent)({
  display: 'grid',
  gridTemplate: `
    'titlebar' auto
    'main' minmax(0, 1fr)
  `,
  padding: 0,
});

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  borderTop: `${Colors.GREY_30} 2px solid`,
  marginRight: theme.spacing(4),
}));
