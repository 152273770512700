import React, { useState } from 'react';

import { QueryResult } from '@apollo/client';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

import LabwareCard from 'client/app/components/DeviceLibrary/DeviceConfigurationValidation/LabwareCard';
import OneToManyLabwareMatch from 'client/app/components/DeviceLibrary/DeviceConfigurationValidation/OneToManyLabwareMatch';
import useVendorSoftwareName from 'client/app/components/DeviceLibrary/useVendorSoftwareName';
import { DeviceCommonFragment as DeviceCommon, PlateMatchesQuery } from 'client/app/gql';
import {
  HAMILTON_ANTHA_CLASS,
  TECAN_EVO_ANTHA_CLASS,
  TECAN_FLUENT_ANTHA_CLASS,
} from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import Feedback from 'common/ui/components/Feedback';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import LinearProgress from 'common/ui/components/LinearProgress';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';

type LabwareTab = 'matched' | 'no-match';

export type PlateMatchesQueryType = QueryResult<
  PlateMatchesQuery,
  {
    deviceConfig: DeviceConfigData;
    anthaHubGUID: string;
  }
>;

type Props = {
  device: DeviceCommon;
  queryResult: PlateMatchesQueryType;
};

export default function LabwareTab({
  device,
  queryResult: { data, loading, error, refetch },
}: Props) {
  const [activeTab, setActiveTab] = useState<LabwareTab>('matched');

  if (loading || !data) {
    return <StyledLinearProgress />;
  }
  if (error) {
    return <GraphQLErrorPanel error={error} onRetry={refetch} />;
  }

  if (!device.model.anthaClass) {
    console.error(`No 'anthaClass' associated with deviceID: ${device.anthaHubGUID}`);
    return <Typography>Sorry something went wrong</Typography>;
  }

  const numberOfMatches =
    data.plateMatch.oneToManyMatches.length + data.plateMatch.oneToOneMatches.length;
  const numberOfNonMatches = data.plateMatch.nonMatches.length;

  const tabsInfo: TabsInfo<LabwareTab> = [
    {
      value: 'matched',
      label: 'Matched',
      number: numberOfMatches,
    },
    { value: 'no-match', label: 'No Match', number: numberOfNonMatches },
  ];

  return (
    <StyledLabwareTabContainer>
      <Feedback
        variant="info"
        header={`${numberOfMatches} of ${
          numberOfMatches + numberOfNonMatches
        } available labware items have a match in the Synthace plate inventory are ready to use`}
        content={
          <Typography>
            Matched labware will be available to use across all configurations for this
            device.
          </Typography>
        }
      />
      <StyledTabs activeTab={activeTab} onChangeTab={setActiveTab} tabsInfo={tabsInfo} />
      {activeTab === 'matched' ? (
        <MatchedTabContent
          data={data.plateMatch}
          deviceAnthaClass={device.model.anthaClass}
        />
      ) : (
        <NoMatchTabContent
          data={data.plateMatch.nonMatches}
          deviceAnthaClass={device.model.anthaClass}
        />
      )}
    </StyledLabwareTabContainer>
  );
}

type MatchedTabContentProps = {
  deviceAnthaClass: string;
  data: Pick<PlateMatchesQuery['plateMatch'], 'oneToManyMatches' | 'oneToOneMatches'>;
};

function MatchedTabContent({ data, deviceAnthaClass }: MatchedTabContentProps) {
  const numberOfOneToOneMatches = data.oneToOneMatches.length;
  const numberOfOneToManyMatches = data.oneToManyMatches.length;

  const multipleSynthaceMatchesText = useMultipleSynthaceMatchMessage(
    deviceAnthaClass,
    numberOfOneToManyMatches,
  );

  return (
    <>
      <Feedback
        variant="success"
        content={
          <Typography>
            {numberOfOneToOneMatches} labware items have one exact match!
          </Typography>
        }
      />
      <StyledList>
        {data.oneToOneMatches.map((match, index) => (
          <LabwareCard
            key={index}
            plate={match.vendorPlate}
            matchedSynthacePlate={match.synthacePlate}
            deviceAnthaClass={deviceAnthaClass}
          />
        ))}
      </StyledList>
      {numberOfOneToManyMatches ? (
        <>
          <Feedback variant="warning" content={multipleSynthaceMatchesText} />
          <StyledList>
            {data.oneToManyMatches.map((match, index, original) => (
              <React.Fragment key={index}>
                <OneToManyLabwareMatch
                  deviceAnthaClass={deviceAnthaClass}
                  oneToManyPlateMatch={match}
                />
                {index === original.length - 1 ? null : <StyledDivider />}
              </React.Fragment>
            ))}
          </StyledList>
        </>
      ) : null}
    </>
  );
}

type NoMatchTabContentProps = {
  deviceAnthaClass: string;
  data: PlateMatchesQuery['plateMatch']['nonMatches'];
};

function NoMatchTabContent({ data, deviceAnthaClass }: NoMatchTabContentProps) {
  const numberOfNoMatches = data.length;

  const content = useNonMatchMessage(deviceAnthaClass, numberOfNoMatches);

  return (
    <>
      <Feedback variant="info-grey" content={content} />
      <StyledList>
        {data.map((nonMatch, index) => (
          <LabwareCard
            key={index}
            plate={nonMatch.vendorPlate}
            deviceAnthaClass={deviceAnthaClass}
          />
        ))}
      </StyledList>
    </>
  );
}

const useNonMatchMessage = (deviceAnthaClass: string, numberOfNoMatches: number) => {
  const text = `${numberOfNoMatches} labware items have no match in the Synthace plate inventory.`;
  const vendorSoftwareName = useVendorSoftwareName(deviceAnthaClass);
  switch (deviceAnthaClass) {
    case HAMILTON_ANTHA_CLASS:
      return (
        <Typography>
          {text} If you want to use any of these plates, find or create a corresponding
          Synthace plate definition in the Synthace plate types inventory and make sure
          that the Synthace <ItalicInlineTypography>Plate type</ItalicInlineTypography>{' '}
          field matches the labware name in {vendorSoftwareName}
        </Typography>
      );
    case TECAN_EVO_ANTHA_CLASS:
      return (
        <>
          <Typography>{text + ' If you want to use any of these plates:'}</Typography>
          <ol>
            <li>
              <Typography>
                In {vendorSoftwareName}, specify the manufacturer in the{' '}
                <ItalicInlineTypography>Vendor name</ItalicInlineTypography> field and add
                the part number preceded by # in the{' '}
                <ItalicInlineTypography>Additional Information</ItalicInlineTypography>{' '}
                field.
              </Typography>
            </li>
            <li>
              <Typography>
                Find or create a corresponding Synthace plate definition in the Synthace
                plate types inventory, and make sure that the{' '}
                <ItalicInlineTypography>Manufacturer</ItalicInlineTypography> and{' '}
                <ItalicInlineTypography>Part number</ItalicInlineTypography> match.
              </Typography>
            </li>
          </ol>
        </>
      );
    case TECAN_FLUENT_ANTHA_CLASS:
      return (
        <>
          <Typography>{text + ' If you want to use any of these plates:'}</Typography>
          <ol>
            <li>
              <Typography>
                In {vendorSoftwareName}, specify the manufacturer in the{' '}
                <ItalicInlineTypography>Vendor name</ItalicInlineTypography> field and
                fill in the <ItalicInlineTypography>Part Number</ItalicInlineTypography>{' '}
                field.
              </Typography>
            </li>
            <li>
              <Typography>
                Find or create a corresponding Synthace plate definition in the Synthace
                plate types inventory, and make sure that the{' '}
                <ItalicInlineTypography>Manufacturer</ItalicInlineTypography> and{' '}
                <ItalicInlineTypography>Part number</ItalicInlineTypography> match.
              </Typography>
            </li>
          </ol>
        </>
      );
  }
  return <Typography>{text}</Typography>;
};

const useMultipleSynthaceMatchMessage = (
  deviceAnthaClass: string,
  numberOfMultipleSynthaceMatches: number,
) => {
  const vendorSoftwareName = useVendorSoftwareName(deviceAnthaClass);
  switch (deviceAnthaClass) {
    case TECAN_EVO_ANTHA_CLASS:
      return (
        <>
          <Typography>
            {numberOfMultipleSynthaceMatches} labware items have more than one match in
            Synthace. When any of these Synthace labware entities is chosen in a workflow,
            Synthace will call the same labware definition in {vendorSoftwareName}.
          </Typography>
          <Typography>
            If this is not intentional, review the labware definitions to make sure every{' '}
            <ItalicInlineTypography>Manufacturer</ItalicInlineTypography> and{' '}
            <ItalicInlineTypography>Part number</ItalicInlineTypography> combination has a
            single match in {vendorSoftwareName} and in the Synthace plate library.
          </Typography>
        </>
      );
    case TECAN_FLUENT_ANTHA_CLASS:
      return (
        <>
          <Typography>
            {numberOfMultipleSynthaceMatches} labware items have more than one match in
            Synthace. When any of these Synthace labware entities is chosen in a workflow,
            Synthace will call the same labware definition in {vendorSoftwareName}.
          </Typography>
          <Typography>
            If this is not intentional, review the labware definitions to make sure every{' '}
            <ItalicInlineTypography>Manufacturer</ItalicInlineTypography> and{' '}
            <ItalicInlineTypography>Part number</ItalicInlineTypography> combination has a
            single match in {vendorSoftwareName} and in the Synthace plate library.
          </Typography>
        </>
      );
    default:
      // This situation cannot occur for Hamilton matching is done on plate type which must be unique within Synthace
      return (
        <Typography>
          {numberOfMultipleSynthaceMatches} labware items have more than one match in
          Synthace. When any of these Synthace labware entities is chosen in a workflow,
          Synthace will call the same labware definition from the vendor software.
        </Typography>
      );
  }
};

const ItalicInlineTypography = styled((props: Omit<TypographyProps, 'component'>) => (
  <Typography component="span" {...props} />
))({
  fontStyle: 'italic',
});

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));

const StyledLabwareTabContainer = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  width: '100%',
  gap: spacing(5),
  marginTop: spacing(6),
  padding: spacing(0, 4),
}));

const StyledTabs = styled(Tabs<LabwareTab>)({
  // This pseudo-element is here to cover the plate icons on the left of
  // LabwareCard that appear when scrolling.
  // We need a small element to hide them.
  '&::before': {
    content: "''",
    position: 'absolute',
    left: '-12px',
    width: '12px',
    height: '100%',
    backgroundColor: Colors.GREY_0,
  },
  position: 'sticky',
  top: 0,
  backgroundColor: Colors.GREY_0,
  overflow: 'visible',
  zIndex: 1,
});

const StyledList = styled('ul')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(3),
  listStyleType: 'none',
  padding: 0,
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0, 5, 0),
}));
