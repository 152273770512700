import { ExecutionStage, MetadataFile } from 'client/app/apps/execution-details/types';
import { byName } from 'common/lib/strings';

export function getMetadataFilesForStage({
  deviceExecutionMode: { mainDevice, peripheralDevices },
  tasks,
}: ExecutionStage): MetadataFile[] {
  const uniqueFiles: MetadataFile[] = [];

  tasks
    .filter(
      task =>
        mainDevice.id === task.simulationTask?.device?.id ||
        peripheralDevices.some(
          peripheralDevice => peripheralDevice.id === task.simulationTask?.device?.id,
        ),
    )
    .flatMap(task => task.metadataFiles)
    .sort((a, b) => {
      /**
       * Sort to group duplicates together side by side.
       * The filetree link for duplicates should differ only by date part.
       *
       * e.g.
       * files.antha.reviews://<uuid>/device/<uuid>/2024-03-26_15.49.38.326/A260_280_340_IR.mdfx
       * files.antha.reviews://<uuid>/device/<uuid>/2024-03-26_15.53.32.063/A260_280_340_IR.mdfx
       */
      return b.filetreeLink.localeCompare(a.filetreeLink);
    })
    .forEach((nextFile, index, sortedFiles) => {
      const prevFile = sortedFiles[index - 1];
      if (
        !prevFile ||
        prevFile.name !== nextFile.name ||
        prevFile.sizeBytes !== nextFile.sizeBytes ||
        prevFile.fileType !== nextFile.fileType ||
        prevFile.tags.length !== nextFile.tags.length
      ) {
        uniqueFiles.push(nextFile);
      }
    });

  return uniqueFiles.sort(byName);
}

export function getMetadataFileName(file: MetadataFile) {
  const tag = file.tags.find(tag => tag['config_type']);

  switch (tag?.['config_type']) {
    case 'CARRIER_CONFIG':
      // Tecan-specific: There can only be one carrier configuration for any given execution, so we
      // can safely label it "Carrier configuration" with no further details.
      return 'Carrier configuration';
    case 'LC_CONFIG':
      // Tecan-specific
      return `Liquid classes (${file.name.split('.')[0]})`;
    case 'PLATEREADER_PROTOCOL':
      // Note: It's typical for plate readers to have protocols named "foo" stored in files named
      // "foo.something".  If this isn't true for some device in the future, we should special-case
      // it.
      return file.name.split('.')[0];
    default:
      return file.name;
  }
}
