import { useEffect, useState } from 'react';

// There can be some slowness when having to suddenly load a very big list
// It can be very noticeable when showing a list in a modal.
// This hooks returns a list that starts with the first `initialLoad` elements and then grow slowly by `consecutiveLoad` until its full size.
// If `consecutiveLoad` is omitted, the list will be full at the 2nd step.

export default function useProgressiveList<T>(
  list: readonly T[],
  initialLoad = 1,
  consecutiveLoad = list.length,
) {
  const length = list.length;

  // sliceSize is the size of the section of the array we will return.
  const [sliceSize, setSliceSize] = useState(initialLoad);

  // This effect will slowly increment `sliceSize` every render (triggering a new render) until reaching list.length where it will stop being triggered.
  // The only dependency that should change is `sliceSize`, triggering a change to itself, until it reach the `list.length` (guarding us from an infinite loop).
  useEffect(() => {
    // If we update state directly in the useEffect, React will detect it as being a cascading update
    //  and will do its best (and it does it quite well) to avoid updating the dom  before applying te update, defeating the very purpose of this technique.
    // But we actually want the dom to be udpated asap so the UI feels reactive; we escape with the timeout.
    const token = window.setTimeout(() => {
      setSliceSize(sliceSize => Math.min(sliceSize + consecutiveLoad, length));
    }, 0);

    // cleanup: if the component is unmounted we do not want to still update the state.
    return () => {
      window.clearTimeout(token);
    };
  }, [setSliceSize, sliceSize, consecutiveLoad, length, initialLoad]);

  return list.slice(0, sliceSize);
}
