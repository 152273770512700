import React, { useCallback, useContext, useState } from 'react';

import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { useDownloadRemoteFileFromPath, useUpload } from 'client/app/api/filetree';
import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import ExecutionAndFileSelectionDialog from 'client/app/components/FileBrowser/ExecutionAndFileSelectionDialog';
import PathSelectorDialog from 'client/app/components/FileBrowser/PathSelectorDialog';
import SimulationAndPathSelectionDialog from 'client/app/components/FileBrowser/SimulationAndPathSelectionDialog';
import FileDisplay from 'client/app/components/Parameters/FileEditor/FileDisplay';
import SimpleDeviceSelectorDialog from 'client/app/components/SimpleDeviceSelectorDialog';
import { AppIcon } from 'client/app/icons';
import getFileObjectFromPath from 'client/app/lib/file-browser/getFileObjectFromPath';
import { DEVICE_BASE_PATH } from 'client/app/lib/file-browser/path';
import {
  FileBrowserFileSelection,
  FileBrowserFileSingleSelection,
} from 'client/app/lib/file-browser/types';
import { isFalsyOrEmptyObject } from 'common/lib/data';
import { concatURL } from 'common/lib/strings';
import {
  FileEditorValue,
  FileObject,
  FileValue,
  isDirectUploadSingleValueLegacy,
} from 'common/types/fileParameter';
import Button from 'common/ui/components/Button';
import DirectUploadItem from 'common/ui/components/DirectUploadItem';
import LinearProgress from 'common/ui/components/LinearProgress';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import DirectUploadEditor from 'common/ui/components/ParameterEditors/DirectUploadEditor';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import useUUID from 'common/ui/hooks/useUUID';
import { ExecutionIcon } from 'common/ui/icons/Execution';
import { SimulationIcon } from 'common/ui/icons/SimulationIcon';
import { TemplateFileIcon } from 'common/ui/icons/TemplateFileIcon';

type Props = {
  value: FileEditorValue;
  template?: FileObject;
  isDisabled?: boolean;
  onChange: (value: FileValue) => void;

  // Optionally specify allowed file types for the native file selector.
  // Example: ['.jpg', '.gif']
  // The use case for this is to help the user select the right files.
  // This is passed directly to the underlying HTML input element,
  // see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
  acceptFileTypes?: readonly string[];
};

export default React.memo(function AddFileButton({
  isDisabled,
  acceptFileTypes,
  onChange,
  value,
  template,
}: Props) {
  const classes = useStyles();
  const addFileButtonID = useUUID();

  const [uploadMenuAnchor, setUploadMenuAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const { screenId } = useContext(ScreenContext);
  const [pathSelectorDialog, openPathSelectorDialog] = useDialog(PathSelectorDialog);
  const [deviceSelectorDialog, openDeviceSelectorDialog] = useDialog(
    SimpleDeviceSelectorDialog,
  );
  // Display a loading indicator while uploading a file from the computer.
  const [isUploading, setIsUploading] = useState(false);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUploadMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setUploadMenuAnchorEl(null);
  };

  const onClear = useCallback(() => {
    setUploadMenuAnchorEl(null);
    onChange(null);
  }, [onChange]);

  const handleValueChange = useCallback(
    value => {
      setUploadMenuAnchorEl(null);
      onChange(value);
    },
    [onChange],
  );

  const handleUploadFromComputer = useCallback(
    value => {
      logEvent('add-file-from-computer', screenId as string);
      handleValueChange(value);
    },
    [handleValueChange, screenId],
  );

  /**
   * Responses from PathSelectorDialog are not in the format that can be saved.
   */
  const handlePathSelectorResponse = useCallback(
    (value: FileBrowserFileSelection) => {
      onChange(
        getFileObjectFromPath((value as FileBrowserFileSingleSelection).pathWithScheme),
      );
    },
    [onChange],
  );

  const handleUploadFromDevice = useCallback(async () => {
    logEvent('add-file-from-device-selection', screenId as string);
    setUploadMenuAnchorEl(null);

    const selectDeviceResult = await openDeviceSelectorDialog({
      title: `Select file from a device`,
    });

    if (selectDeviceResult) {
      const { id, name } = selectDeviceResult;
      const devicePath = concatURL(DEVICE_BASE_PATH, id);

      await openPathSelectorDialog({
        selectMultiple: false,
        value: null,
        basePath: devicePath,
        onChange: handlePathSelectorResponse,
        overrideDialogTitle: `Select file from ${name}`,
      });
    }
  }, [
    handlePathSelectorResponse,
    openDeviceSelectorDialog,
    openPathSelectorDialog,
    screenId,
  ]);

  const [simulationAndPathSelectionDialog, openSimulationAndPathSelectionDialog] =
    useDialog(SimulationAndPathSelectionDialog);
  const handleChooseSimulationAndFile = async () => {
    logEvent('add-file-from-simulations-list', screenId as string);
    const selectedFile = await openSimulationAndPathSelectionDialog({});
    onChange(selectedFile);
  };

  const [executionAndFileSelectionDialog, openExecutionAndFileSelectionDialog] =
    useDialog(ExecutionAndFileSelectionDialog);
  const handleChooseExecutionAndFile = async () => {
    logEvent('add-file-from-executions-list', screenId as string);
    const selectedFile = await openExecutionAndFileSelectionDialog({});
    onChange(selectedFile);
  };

  const upload = useUpload();
  const downloadFile = useDownloadRemoteFileFromPath();

  const handleDownloadTemplate = useCallback(async () => {
    if (!template) {
      return;
    }
    await downloadFile(template.Path, template.Name);
  }, [downloadFile, template]);

  // Scenarios
  // This component allows you to go between the button and the display of the value.
  // There are a few options of how the value can be stored:
  // 1. File that is in a legacy format of DirectUploadSingleValueLegacy.
  // 2. File that is in the filetree format.
  // 3. No value is selected so we present the "Add File" button.
  if (value && isDirectUploadSingleValueLegacy(value)) {
    // This is true for legacy default elements that have base64 files.
    return <DirectUploadItem file={value} onRemove={onClear} isDisabled={isDisabled} />;
  } else if (!isFalsyOrEmptyObject(value)) {
    return <FileDisplay isDisabled={isDisabled} value={value} onUnsetValue={onClear} />;
  } else {
    return (
      <>
        {isUploading && <LinearProgress className={classes.loadingBar} />}
        <Button
          variant="secondary"
          onClick={handleOpenMenu}
          disabled={isDisabled}
          className={cx({ [classes.hidden]: isUploading })}
        >
          Add file
        </Button>
        <Menu
          id={addFileButtonID}
          anchorEl={uploadMenuAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          open={!!uploadMenuAnchor}
          onClose={handleCloseMenu}
          className={cx({ [classes.hidden]: isUploading })}
        >
          {template && (
            <>
              <Typography variant="body2" className={classes.menuTitle}>
                DOWNLOAD
              </Typography>
              <MenuItemWithIcon
                onClick={handleDownloadTemplate}
                icon={<TemplateFileIcon />}
                text="Template"
              />
            </>
          )}
          <Typography variant="body2" className={classes.menuTitle}>
            ADD FILE
          </Typography>

          <DirectUploadEditor
            value={null} // Default to being null since there is no value.
            isDisabled={isDisabled}
            acceptFileTypes={acceptFileTypes}
            renderType="MenuItem"
            onChange={handleUploadFromComputer}
            setIsUploading={setIsUploading}
            onUpload={upload}
          />
          <MenuItemWithIcon
            onClick={handleUploadFromDevice}
            icon={<AppIcon iconId="antha:device" />}
            text="Devices"
          />
          <MenuItemWithIcon
            onClick={handleChooseSimulationAndFile}
            icon={<SimulationIcon />}
            text="Simulations"
          />
          <MenuItemWithIcon
            onClick={handleChooseExecutionAndFile}
            icon={<ExecutionIcon />}
            text="Executions"
          />
        </Menu>
        {deviceSelectorDialog}
        {pathSelectorDialog}
        {simulationAndPathSelectionDialog}
        {executionAndFileSelectionDialog}
      </>
    );
  }
});

const useStyles = makeStylesHook({
  menuTitle: {
    margin: '4px 16px',
  },
  hidden: {
    display: 'none',
  },
  loadingBar: {
    margin: '16px 0',
  },
});
