import React from 'react';

import DeleteIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import cn from 'classnames';

import { useFiltrationProtocolDesign } from 'client/app/components/Parameters/FiltrationProtocolDesign/FiltrationProtocolDesignState';
import { useLiquidCount } from 'client/app/components/Parameters/FiltrationProtocolDesign/lib/useLiquidCount';
import { LiquidGroup } from 'client/app/components/Parameters/FiltrationProtocolDesign/types';
import { pluralize } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  liquidGroup: LiquidGroup;
  selectable: boolean;
  selected: boolean;
  onClick: () => void;
  isReadonly: boolean;
};

function LiquidGroupCard({
  liquidGroup,
  selectable,
  selected,
  onClick: handleClick,
  isReadonly,
}: Props) {
  const classes = useStyles();

  const { factors, deleteLiquidGroup } = useFiltrationProtocolDesign();
  const selectedFactors = factors.filter(f => liquidGroup.factorIds.includes(f.clientId));
  const resultLiquids = useLiquidCount(selectedFactors);
  const factorsCopy = selectedFactors
    .map(f => `${f.name} (${f.type === 'subComponent' ? f.value.values : f.value})`)
    .join(', ');

  return (
    <Paper
      component="dl"
      variant="elevation"
      className={cn(classes.card, {
        [classes.selected]: selected,
        [classes.selectable]: selectable,
      })}
      onClick={handleClick}
    >
      <dt className={classes.name}>Name</dt>
      <dd className={classes.name}>
        <span className={classes.nameValue}>{liquidGroup.name}</span>
      </dd>

      <dt className={classes.liquids}>Liquids</dt>
      <dd className={classes.liquids}>{pluralize(resultLiquids.count, 'liquid')}</dd>

      <dt className={classes.factors}>Factors</dt>
      <dd className={classes.factors}>
        <span className={classes.factorValues}>{factorsCopy}</span>
      </dd>

      <div className={classes.actions}>
        <IconButton
          size="small"
          icon={<DeleteIcon color="error" />}
          disabled={!selectable || isReadonly}
          onClick={e => {
            e.stopPropagation();
            deleteLiquidGroup(liquidGroup.clientId);
          }}
        />
      </div>
    </Paper>
  );
}

export default React.memo(LiquidGroupCard);

const useStyles = makeStylesHook(({ spacing, palette, typography }) => ({
  card: {
    padding: spacing(4),
    borderRadius: spacing(2),
    border: `1px solid ${Colors.GREY_30}`,
    display: 'grid',
    gridTemplateColumns: `[name] minmax(auto, 1fr) [liquids] minmax(150px, 1fr) [factors] minmax(200px, 1fr) [actions] auto [end]`,
    gridTemplateRows: `[labels] auto [values] auto [end]`,
    rowGap: spacing(3),
    columnGap: spacing(4),
    alignItems: 'start',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14)',
    margin: 0,
    '& > dt': {
      gridRow: 'labels',
      ...typography.caption,
      color: palette.text.secondary,
    },
    '& > dd': {
      margin: 0,
      gridRow: 'values',
      ...typography.body2,
    },
  },
  selectable: {
    cursor: 'pointer',
    '&:hover': {
      background: Colors.BLUE_0,
    },
  },
  selected: {
    borderColor: palette.primary.main,
    color: palette.primary.dark,
    '& > dt': {
      color: palette.primary.dark,
    },
    '& $factorValues': {
      color: palette.primary.dark,
    },
  },
  name: {
    gridColumn: 'name',
  },
  nameValue: {
    ...typography.subtitle2,
  },
  liquids: {
    gridColumn: 'liquids',
  },
  liquidsValue: {
    ...typography.subtitle2,
  },
  factors: {
    gridColumn: 'factors',
  },
  factorValues: {
    fontStyle: 'italic',
    color: palette.text.secondary,
  },
  actions: {
    gridColumn: 'actions',
    gridRowStart: 'labels',
    gridRowEnd: 'end',
    placeSelf: 'center',
  },
}));
