import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Options from '@mui/icons-material/MoreVert';
import Pause from '@mui/icons-material/Pause';
import Play from '@mui/icons-material/PlayArrow';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { MixPreviewStep } from 'common/types/mixPreview';
import ControlButton from 'common/ui/components/simulation-details/StepSlider/components/ControlButton';

type Props = {
  steps: MixPreviewStep[];
  onStepChange: (stepCount: number, playbackTime: number) => void;
};

/**
 * Automatically step through the simulation using the estimated time of each
 * step.
 */
export default function SimulationPlaybackControl({ steps, onStepChange }: Props) {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(120);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const handlePlay = useCallback(() => setIsPlaying(true), []);
  const handlePause = useCallback(() => setIsPlaying(false), []);
  const handleOpenMenu = useCallback(() => setMenuOpen(true), []);
  const handleCloseMenu = useCallback(() => setMenuOpen(false), []);

  const menuAnchor = useRef<HTMLButtonElement>(null);
  const playTimeElapsed = useRef<number>(0);
  const simulationTime = useRef<number>(0);
  const simulationTimeTotal = useMemo<number>(
    () => steps.reduce((acc, next) => (acc += next.time_estimate || 5), 0),
    [steps],
  );

  useEffect(() => {
    let playTimer: ReturnType<typeof setInterval> | undefined;
    if (isPlaying) {
      const start = Date.now();
      // Periodically update the elapsed duration and check if we should move
      // onto the next step.
      playTimer = setInterval(() => {
        playTimeElapsed.current = ((Date.now() - start) / 1000) * playbackSpeed;
        let cumulativeTime = 0;
        const step = steps.findIndex(step => {
          const stepTime = step.time_estimate || 5;
          cumulativeTime += stepTime;
          return (
            cumulativeTime - stepTime >=
              simulationTime.current + playTimeElapsed.current ||
            cumulativeTime === simulationTimeTotal
          );
        });
        if (step < steps.length) {
          // Update the step number and time in the slider.
          onStepChange(step + 1, steps[step].cumulative_time_estimate || cumulativeTime);
        }
        // Reached the end of the simulation
        if (cumulativeTime === simulationTimeTotal) {
          playTimeElapsed.current = 0;
          simulationTime.current = 0;
          handlePause();
        }
      }, 200);
    } else {
      // Pause and save the elapsed simulation time
      simulationTime.current += playTimeElapsed.current;
    }
    // Destroy timer when the component is unmounted or state changes
    return () => {
      if (playTimer) {
        clearInterval(playTimer);
      }
    };
  }, [handlePause, isPlaying, onStepChange, playbackSpeed, simulationTimeTotal, steps]);

  return (
    <>
      {!isPlaying ? (
        <ControlButton
          icon={<Play />}
          onClick={handlePlay}
          tooltipContent={<Typography variant="caption">Play</Typography>}
        />
      ) : (
        <ControlButton
          icon={<Pause />}
          onClick={handlePause}
          tooltipContent={<Typography variant="caption">Pause</Typography>}
        />
      )}
      <ControlButton
        ref={menuAnchor}
        icon={<Options />}
        onClick={handleOpenMenu}
        tooltipContent={<Typography variant="caption">Adjust playback speed</Typography>}
      />
      <Menu open={menuOpen} anchorEl={menuAnchor.current} onClose={handleCloseMenu}>
        <Box p={1}>
          <TextField
            value={playbackSpeed}
            onChange={e => setPlaybackSpeed(Number(e.currentTarget.value))}
            type="number"
            label="Playback speed"
          />
        </Box>
      </Menu>
    </>
  );
}
