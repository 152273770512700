import React from 'react';

import { styled } from '@mui/material/styles';

import { ProtocolInstanceProvider } from 'client/app/apps/protocols/context/ProtocolInstaceProvider';
import { SimulationProvider } from 'client/app/apps/protocols/context/SimulationProvider';
import { StepsProvider } from 'client/app/apps/protocols/context/StepsProvider';
import { EditProtocolInstance } from 'client/app/apps/protocols/EditProtocolInstance';

type Props = {
  id: ProtocolInstanceId;
};

export default function EditProtocolInstanceScreen({ id }: Props) {
  return (
    <Wrapper>
      <ProtocolInstanceProvider protocolInstanceId={id}>
        <StepsProvider>
          <SimulationProvider>
            <EditProtocolInstance />
          </SimulationProvider>
        </StepsProvider>
      </ProtocolInstanceProvider>
    </Wrapper>
  );
}

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  height: '100%',
}));
