import React, { useCallback, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

import { MUTATION_UPDATE_FAVORITE_SIMULATION } from 'client/app/api/gql/mutations';
import { QUERY_WORKFLOW_BY_ID_WITH_SIMULATIONS } from 'client/app/api/gql/queries';
import { WorkflowsBySimulationsQueryVariables } from 'client/app/gql';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { useSimulationNotificationsDispatch } from 'client/app/state/SimulationNotificationsContext';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export enum FavoritedBy {
  'FAVORITED_BY_SELF',
  'FAVORITED_BY_SOMEONE_ELSE',
  'NONE',
}

type Props = {
  size: 'xsmall' | 'small' | 'medium';
  isFavoritedByCurrentUser: boolean;
  favoritedBy: readonly string[]; // List of UUIDs
  simulationId: SimulationId;
  associatedWorkflowId?: WorkflowId;
  additionalVariables?: WorkflowsBySimulationsQueryVariables;
  disabled?: boolean;
};

/**
 * While we are waiting for a response from GraphQL mutation, we can make an optimistic guess for what
 * the response for the favoritedBy will be.
 */
function optimisticToggleFavoritedBy(
  isFavoritedByCurrentUser: boolean,
  favoritedBy: readonly string[],
  userID: string,
) {
  const newFavoritedBy = [...favoritedBy];
  if (isFavoritedByCurrentUser) {
    const idx = favoritedBy.indexOf(userID);
    newFavoritedBy.splice(idx, 1);
  } else {
    newFavoritedBy.push(userID);
  }
  return newFavoritedBy;
}

export default function FavoriteStar(props: Props) {
  const { size, simulationId, associatedWorkflowId, additionalVariables, disabled } =
    props;
  const dispatch = useSimulationNotificationsDispatch();

  const status = useMemo(() => {
    if (props.isFavoritedByCurrentUser) {
      return FavoritedBy.FAVORITED_BY_SELF;
    } else if (props.favoritedBy.length > 0) {
      return FavoritedBy.FAVORITED_BY_SOMEONE_ELSE;
    }
    return FavoritedBy.NONE;
  }, [props.favoritedBy.length, props.isFavoritedByCurrentUser]);

  const userProfile = useUserProfile();
  const { currentScreenID } = useNavigation();

  const [updateFavoriteMutation] = useMutation(MUTATION_UPDATE_FAVORITE_SIMULATION);
  const handleClick = useCallback(
    async (event: React.MouseEvent) => {
      if (!userProfile) {
        return;
      }
      // Icon for handling click is sometimes under <a> tag that causes redirect
      // In order to prevent the redirect, the click event must not propagate up
      event.preventDefault();
      event.stopPropagation();
      logEvent('star-clicked', currentScreenID ?? '');

      const result = await updateFavoriteMutation({
        variables: {
          simulationID: simulationId,
          favorited: !props.isFavoritedByCurrentUser,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateFavoriteSimulation: {
            __typename: 'UpdateFavoriteSimulationOutput',
            simulation: {
              __typename: 'Simulation',
              id: simulationId,
              favoritedBy: optimisticToggleFavoritedBy(
                props.isFavoritedByCurrentUser,
                props.favoritedBy,
                userProfile.id,
              ),
              isFavoritedByCurrentUser: !props.isFavoritedByCurrentUser,
            },
          },
        },
        refetchQueries: associatedWorkflowId
          ? [
              {
                query: QUERY_WORKFLOW_BY_ID_WITH_SIMULATIONS,
                variables: {
                  workflowId: associatedWorkflowId,
                  ...additionalVariables,
                },
              },
            ]
          : undefined,
      });
      // Always update WorkflowBuilderStateContext since we have a hybrid GraphQL/WorkflowBuilderStateContext approach for simulations.
      const simulation = result.data?.updateFavoriteSimulation?.simulation;
      if (simulation) {
        dispatch({
          type: 'toggleFavoriteSimulation',
          payload: {
            simulationId,
            isFavoritedByCurrentUser: simulation.isFavoritedByCurrentUser,
            favoritedBy: simulation.favoritedBy,
          },
        });
      }
    },
    [
      additionalVariables,
      associatedWorkflowId,
      currentScreenID,
      dispatch,
      props.favoritedBy,
      props.isFavoritedByCurrentUser,
      simulationId,
      updateFavoriteMutation,
      userProfile,
    ],
  );

  // Only show the button if we have a user profile.
  return userProfile ? (
    <FavoriteStarIconButton
      size={size}
      status={status}
      onClick={handleClick}
      disabled={disabled}
    />
  ) : null;
}

type FavoriteStarIconButtonProps = {
  size: 'xsmall' | 'small' | 'medium';
  status?: FavoritedBy;
  onClick?: (event: React.MouseEvent) => Promise<void>;
  disabled?: boolean;
  className?: string;
};

export function FavoriteStarIconButton(props: FavoriteStarIconButtonProps) {
  const { size, status, onClick, disabled, className } = props;
  const classes = useStyles();

  let icon;
  let helperText;
  switch (status) {
    case FavoritedBy.FAVORITED_BY_SELF:
      icon = <StarRoundedIcon className={classes.favoritedByCurrentUser} />;
      helperText = disabled ? 'Starred' : 'Unstar';
      break;
    case FavoritedBy.FAVORITED_BY_SOMEONE_ELSE:
      icon = <StarRoundedIcon className={classes.favoritedByOthers} />;
      helperText = disabled
        ? 'Starred by somebody else.'
        : 'Starred by somebody else. Click to star it yourself.';
      break;
    case FavoritedBy.NONE:
    default:
      icon = disabled ? null : <StarBorderRoundedIcon />;
      helperText = disabled ? 'Not starred by anybody.' : 'Star';
  }

  if (!icon) {
    return null;
  }

  return (
    <Tooltip title={helperText}>
      <span>
        <IconButton
          data-heap-tracking="favorite-star"
          onClick={onClick}
          className={className}
          size={size}
          icon={icon}
          disabled={disabled}
          title=""
          aria-label={helperText}
        />
      </span>
    </Tooltip>
  );
}

const useStyles = makeStylesHook({
  favoritedByCurrentUser: {
    color: Colors.YELLOW,
  },
  favoritedByOthers: {
    color: Colors.GREY_40,
  },
});
