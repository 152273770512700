import React, { useRef, useState } from 'react';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { ClassNameMap } from '@mui/styles';

import useElementColours from 'client/app/components/ElementPlumber/colours';
import PortTooltip from 'client/app/components/ElementPlumber/PortTooltip';
import getClassNameForType from 'client/app/components/ElementPlumber/types';
import { PendingConnection } from 'client/app/components/ElementPlumber/WorkflowLayout';
import { getOppositeSide, Side } from 'client/app/lib/layout/ConnectionHelper';
import {
  ELEMENT_INSTANCE_PORT_HEIGHT,
  ELEMENT_INSTANCE_PORT_WIDTH,
} from 'client/app/lib/layout/LayoutHelper';
import { isValidConnection } from 'client/app/lib/layout/ValidConnection';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { Parameter } from 'common/types/bundle';

// Often when moving the pointer around the UI, you'll pass over the ports when
// you intend to drag or select the element instance. This often means that
// when you're trying to click the element instance, you end up clicking the
// tooltip instead. To avoid this, we'll wait a short amount of time between
// when the user hovers over the port and when we show the tooltip.
const TOOLTIP_DELAY_MS = 200;

const CLICK_SHIM_STYLE: React.CSSProperties = {
  userSelect: 'none', // prevent text selection on connect
  height: '100%',
  width: '100%',
};

type Props = {
  data: Parameter;
  displayName?: string;
  isConnected: boolean;
  canConnect: boolean;
  isConnecting: boolean;
  isDisabled: boolean;
  isModeDOE: boolean;
  isSiblingConnecting: boolean;
  isPartOfNearestConnection: boolean;
  pendingConnection: PendingConnection | null;
  top: number;
  left: number;
  side: Side;
  elementId: string;
  onConnectionStart?: (startSide: Side, startPort: Parameter) => void;
};

export default function Port({
  data,
  displayName = data.name,
  isConnected,
  canConnect,
  isConnecting,
  isDisabled,
  isModeDOE,
  isSiblingConnecting,
  isPartOfNearestConnection,
  pendingConnection,
  top,
  left,
  side,
  elementId,
  onConnectionStart,
}: Props) {
  const colourCss: ClassNameMap<string> = useElementColours();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isHoveringPort = Boolean(anchorEl);

  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const clickShimRef = useRef<HTMLDivElement | null>(null);

  const isTypeConfigConnectionSettingEnabled = useFeatureToggle(
    'TYPE_CONFIGURATION_CONNECTION_SETTINGS',
  );

  const onPointerEnter = (event: React.PointerEvent<HTMLElement>) => {
    timeoutId.current = setTimeout(() => {
      timeoutId.current = null;
      setAnchorEl(event.target as HTMLElement);
    }, TOOLTIP_DELAY_MS);
  };

  const onPointerLeave = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
    setAnchorEl(null);
  };

  const isDeactivatedDuringPendingConnection = () => {
    if (!pendingConnection?.port) {
      return false;
    }

    if (isConnecting === true) {
      return false;
    }

    if (isSiblingConnecting) {
      return true;
    }

    const port = data;
    const { port: pendingConnectionPort, side: pendingConnectionSide } =
      pendingConnection;

    const kindsAreComplementary = side === getOppositeSide(pendingConnectionSide);

    if (!kindsAreComplementary) {
      return true;
    }

    const input = pendingConnectionSide === 'input' ? pendingConnectionPort : port;
    const output = pendingConnectionSide === 'output' ? pendingConnectionPort : port;

    return !isValidConnection(input, output, isTypeConfigConnectionSettingEnabled);
  };

  const handleConnectionStart = (e: React.PointerEvent<HTMLDivElement>) => {
    if (canConnect) {
      e.stopPropagation();

      if (!onConnectionStart) {
        return;
      }

      // If the user has clicked on the tooltip, for instance, we don't want to
      // trigger the beginning of a connection.
      if (e.target !== clickShimRef.current) {
        return;
      }

      // We don't (yet?) allow the user to rewire an already existing connection
      // without first deleting the existing connection.
      if (isConnected && side === 'input') {
        return;
      }

      onConnectionStart(side, data);
    }
  };

  const className = getClassNameForType(data.type);
  const isDeactivated = isDeactivatedDuringPendingConnection();

  // Always show the tooltip for the currently-connecting port to help
  // keep the user in-context.
  //
  // Likewise, if this port is part of a pending connection (i.e. the
  // nearest connection that the user might want to complete), also
  // show the tooltip.
  const isParameterNameTooltipVisible =
    isHoveringPort || isConnecting || isPartOfNearestConnection;

  const showAsConnectable = isConnected || canConnect;

  return (
    <main onPointerEnter={onPointerEnter} onPointerLeave={onPointerLeave}>
      <ConnectionPort
        onPointerDown={handleConnectionStart}
        style={{
          top: `${top}px`,
          left: `${left}px`,
        }}
        deactivated={isDeactivated}
        connected={showAsConnectable}
        connectedInput={showAsConnectable && side === 'input'}
        connectedOutput={showAsConnectable && side === 'output'}
        disabled={isDisabled}
        className={className && !isDisabled ? colourCss[className] : undefined}
      >
        <Tooltip
          open={isParameterNameTooltipVisible}
          arrow
          title={displayName}
          placement={side === 'input' ? 'right' : 'left'}
          PopperProps={{
            sx: {
              [`& .${tooltipClasses.arrow}`]: {
                transform: 'translate(0px, 5px) !important', // fix the jumping arrow
              },
            },
          }}
        >
          <div ref={clickShimRef} style={CLICK_SHIM_STYLE} />
        </Tooltip>
      </ConnectionPort>
      {side === 'output' && !isModeDOE && (
        <PortTooltip anchorEl={anchorEl} data={data} elementId={elementId} />
      )}
    </main>
  );
}

const ConnectionPort = styled('div')<{
  connected: boolean;
  connectedInput: boolean;
  connectedOutput: boolean;
  deactivated: boolean;
  disabled: boolean;
}>(({ connected, connectedInput, connectedOutput, deactivated, disabled }) => ({
  cursor: 'pointer',
  position: 'absolute',
  height: ELEMENT_INSTANCE_PORT_HEIGHT,
  width: ELEMENT_INSTANCE_PORT_WIDTH,

  ...(connected ? styles.connected : null),
  ...(connectedInput ? styles.connectedInput : null),
  ...(connectedOutput ? styles.connectedOutput : null),
  ...(deactivated ? styles.deactivated : null),
  ...(disabled ? styles.disabled : null),
}));

const styles = {
  connected: {
    width: '15px',
  },
  connectedInput: {
    borderBottomRightRadius: '5px',
    borderTopRightRadius: '5px',
  },
  connectedOutput: {
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px',
    marginLeft: '-5px',
  },
  deactivated: {
    opacity: 0.5,
  },
  disabled: {
    background: '#aaabab',
  },
};
