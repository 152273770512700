import React, { useCallback, useState } from 'react';

import TemplateWorkflowsGridContainer from 'client/app/apps/experiments/Templates/TemplateWorkflowsGridContainer';
import Colors from 'common/ui/Colors';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import SearchField from 'common/ui/components/SearchField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

const TemplateWorkflowsDialog = (props: DialogProps<void>) => {
  const classes = useStyles();
  const [search, setQuery] = useState('');
  const { isOpen, onClose } = props;

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <ComplexActionDialog
      title="Templates"
      onClose={handleClose}
      isOpen={isOpen}
      showCloseButton
      paperClassName={classes.paper}
      content={
        <ContainerWithIntersectionBar
          filters={<SearchField label="Search" onQueryChange={setQuery} addMargin />}
          content={
            <TemplateWorkflowsGridContainer search={search} closeDialog={handleClose} />
          }
        />
      }
    />
  );
};

export default TemplateWorkflowsDialog;

const useStyles = makeStylesHook({
  paper: {
    backgroundColor: Colors.GREY_5,
  },
});
