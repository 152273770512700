import React, { useCallback, useEffect, useMemo, useReducer } from 'react';

import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuid } from 'uuid';

import {
  mapToEditorState,
  useFactorCombinations,
  useFiltrationPlateLayoutParameter,
} from 'client/app/components/Parameters/FiltrationPlateLayout/lib/parameterUtils';
import { MetaItem } from 'client/app/components/Parameters/MetaDataForm';
import { cropWellsToExistingLocations } from 'common/lib/format';
import { PlateType } from 'common/types/plateType';

export type FilterPlateEditorState = {
  isEditing: boolean;
  plateType: string | null;
  plateName: string | null;
  wellCapacity: number;
  resinBufferVolume: number;
  selectedWells: string[];
  wellRegions: WellRegion[];
  wellRegionDraft: WellRegion | undefined;
  minWellCount: number;
  error?: {
    plateName?: string;
    resinBufferVolume?: string;
    wellRegions: Record<string, { wells?: string; volume?: string }>;
  };
};

export type WellRegion = ResinCardInfo & { wells: string[] };

type ResinCardInfo = {
  id: string;
  resinName: string;
  resinVolume: string;
  resinColor: string;
  metaItems: MetaItem[];
};

type FilterPlateEditorAction =
  | { type: 'selectPlateType'; plateType: PlateType | null }
  | { type: 'changePlateName'; plateName: string }
  | { type: 'changeResinBufferVolume'; volume: number }
  | { type: 'setSelectedWells'; wells: string[] }
  | { type: 'assignWellsToNewRegion' }
  | { type: 'assignWellsToRegion'; id: string }
  | { type: 'assignWellsToDuplicateRegion'; id: string }
  | { type: 'removeWellsFromRegion'; id: string }
  | { type: 'addResinCard'; payload: ResinCardInfo }
  | { type: 'editResinCard'; id: string }
  | { type: 'removeResinCard'; id: string }
  | { type: 'cancelEditing' }
  | { type: 'setMinWellCount'; wellCount: number };

const initState: FilterPlateEditorState = {
  isEditing: false,
  plateType: null,
  plateName: null,
  wellCapacity: 0,
  resinBufferVolume: 0,
  minWellCount: 0,
  selectedWells: [],
  wellRegions: [],
  wellRegionDraft: undefined,
};

type FilterPlateEditorStateContext = {
  state: FilterPlateEditorState;
  selectPlateType: (plateType: PlateType | null) => void;
  changePlateName: (name: string) => void;
  changeResinBufferVolume: (volume: number) => void;
  setSelectedWells: (wells: string[]) => void;
  assignWellsToNewRegion: () => void;
  assignWellsToRegion: (id: string) => void;
  assignWellsToDuplicateRegion: (id: string) => void;
  removeWellsFromRegion: (id: string) => void;
  addResinCard: (data: ResinCardInfo) => void;
  editResinCard: (id: string) => void;
  removeResinCard: (id: string) => void;
  cancelEditing: () => void;
};

const FilterPlateEditorContext = React.createContext<FilterPlateEditorStateContext>({
  state: initState,
  selectPlateType: () => {
    throw new Error('selectPlateType() is undefined');
  },
  changePlateName: () => {
    throw new Error('changePlateName() is undefined');
  },
  changeResinBufferVolume: () => {
    throw new Error('changeResinBufferVolume() is undefined');
  },
  setSelectedWells: () => {
    throw new Error('setSelectedWells() is undefined');
  },
  assignWellsToNewRegion: () => {
    throw new Error('assignWellsToNewRegion() is undefined');
  },
  assignWellsToRegion: () => {
    throw new Error('assignWellsToRegion() is undefined');
  },
  assignWellsToDuplicateRegion: () => {
    throw new Error('assignWellsToDuplicateRegion() is undefined');
  },
  removeWellsFromRegion: () => {
    throw new Error('removeWellsFromRegion() is undefined');
  },
  addResinCard: () => {
    throw new Error('addResinCard() is undefined');
  },
  editResinCard: () => {
    throw new Error('editResinCard() is undefined');
  },
  removeResinCard: () => {
    throw new Error('removeResinCard() is undefined');
  },
  cancelEditing: () => {
    throw new Error('cancelEditing() is undefined');
  },
});

export function useFilterPlateEditorState() {
  return React.useContext(FilterPlateEditorContext);
}

export default function FilterPlateEditorStateProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const parameter = useFiltrationPlateLayoutParameter();
  const { wellCount } = useFactorCombinations();
  const [state, dispatch] = useReducer(
    filterPlateEditorReducer,
    mapToEditorState(parameter.value, wellCount),
  );

  useEffect(() => {
    dispatch({ type: 'setMinWellCount', wellCount });
  }, [wellCount]);

  const selectPlateType = useCallback((plateType: PlateType | null) => {
    dispatch({ type: 'selectPlateType', plateType });
  }, []);
  const changePlateName = useCallback((plateName: string) => {
    dispatch({ type: 'changePlateName', plateName });
  }, []);
  const changeResinBufferVolume = useCallback((volume: number) => {
    dispatch({ type: 'changeResinBufferVolume', volume });
  }, []);
  const setSelectedWells = useCallback((wells: string[]) => {
    dispatch({ type: 'setSelectedWells', wells });
  }, []);
  const assignWellsToNewRegion = useCallback(() => {
    dispatch({ type: 'assignWellsToNewRegion' });
  }, []);
  const assignWellsToRegion = useCallback((id: string) => {
    dispatch({ type: 'assignWellsToRegion', id });
  }, []);
  const assignWellsToDuplicateRegion = useCallback((id: string) => {
    dispatch({ type: 'assignWellsToDuplicateRegion', id });
  }, []);
  const removeWellsFromRegion = useCallback((id: string) => {
    dispatch({ type: 'removeWellsFromRegion', id });
  }, []);
  const addResinCard = useCallback((data: ResinCardInfo) => {
    dispatch({ type: 'addResinCard', payload: data });
  }, []);
  const editResinCard = useCallback((id: string) => {
    dispatch({ type: 'editResinCard', id });
  }, []);
  const removeResinCard = useCallback((id: string) => {
    dispatch({ type: 'removeResinCard', id });
  }, []);
  const cancelEditing = useCallback(() => {
    dispatch({ type: 'cancelEditing' });
  }, []);

  return (
    <FilterPlateEditorContext.Provider
      value={useMemo(
        () => ({
          state,
          selectPlateType,
          changePlateName,
          changeResinBufferVolume,
          setSelectedWells,
          assignWellsToNewRegion,
          assignWellsToRegion,
          assignWellsToDuplicateRegion,
          removeWellsFromRegion,
          addResinCard,
          editResinCard,
          removeResinCard,
          cancelEditing,
        }),
        [
          addResinCard,
          assignWellsToDuplicateRegion,
          assignWellsToNewRegion,
          assignWellsToRegion,
          cancelEditing,
          changePlateName,
          changeResinBufferVolume,
          editResinCard,
          removeResinCard,
          removeWellsFromRegion,
          selectPlateType,
          setSelectedWells,
          state,
        ],
      )}
    >
      {children}
    </FilterPlateEditorContext.Provider>
  );
}

export function filterPlateEditorReducer(
  state: FilterPlateEditorState,
  action: FilterPlateEditorAction,
): FilterPlateEditorState {
  return produce(state, draft => {
    switch (action.type) {
      case 'selectPlateType': {
        const plateType = action.plateType;

        if (plateType) {
          draft.plateType = plateType.type;
          draft.wellCapacity = plateType.wellShape.volumeOverrideUl;
          draft.selectedWells = cropWellsToExistingLocations(
            state.selectedWells,
            plateType,
          );
          cropWellRegionsToPlate(draft, plateType);
          removeWellRegionsWithNoWellsLeft(draft);
        } else {
          draft.plateType = null;
          draft.wellRegions = [];
          draft.selectedWells = [];
        }
        /**
         * We stop editing when user selects new plate type.
         */
        draft.isEditing = false;
        draft.wellRegionDraft = undefined;
        draft.error = getStateError(draft);
        break;
      }
      case 'changePlateName': {
        draft.plateName = action.plateName;
        draft.error = getStateError(draft);
        break;
      }
      case 'changeResinBufferVolume': {
        draft.resinBufferVolume = action.volume;
        draft.error = getStateError(draft);
        break;
      }
      case 'setSelectedWells': {
        draft.selectedWells = action.wells;
        break;
      }
      case 'assignWellsToNewRegion': {
        draft.isEditing = true;
        draft.wellRegionDraft = undefined;
        break;
      }
      case 'assignWellsToRegion': {
        removeSelectedWellsFromExistingRegions(draft, state.selectedWells);
        addSelectedWellsToRegionWithId(draft, state.selectedWells, action.id);
        removeWellRegionsWithNoWellsLeft(draft);
        /**
         * After user have assigned wells he/she is about to make a new selection.
         * So we unselect processed wells.
         */
        draft.selectedWells = [];
        draft.error = getStateError(draft);
        break;
      }
      case 'assignWellsToDuplicateRegion': {
        removeSelectedWellsFromExistingRegions(draft, state.selectedWells);
        removeWellRegionsWithNoWellsLeft(draft);

        const originalWellRegion = state.wellRegions.find(r => r.id === action.id);
        const newWellRegion = {
          ...originalWellRegion!,
          id: uuid(),
          wells: state.selectedWells,
        };
        draft.wellRegions.push(newWellRegion);
        /**
         * We want users to start editing the duplicated region.
         */
        draft.wellRegionDraft = newWellRegion;
        draft.isEditing = true;
        /**
         * After user have assigned wells he/she is about to make a new selection.
         * So we unselect processed wells.
         */
        draft.selectedWells = [];
        draft.error = getStateError(draft);
        break;
      }
      case 'removeWellsFromRegion': {
        removeSelectedWellsFromRegionWithId(draft, state.selectedWells, action.id);
        removeWellRegionsWithNoWellsLeft(draft);
        /**
         * After user have removed wells he/she is about to make a new selection.
         * So we unselect processed wells.
         */
        draft.selectedWells = [];
        draft.error = getStateError(draft);
        break;
      }
      case 'addResinCard': {
        /**
         * wellRegionDraft is the resin being edited in the card
         * if !wellRegionDraft then it means we are adding a new resin (well region)
         */
        if (state.wellRegionDraft) {
          const idx = state.wellRegions.findIndex(
            region => region.id === state.wellRegionDraft!.id,
          );
          // Edit existing well region
          draft.wellRegions[idx] = {
            ...state.wellRegionDraft,
            ...action.payload,
            id: state.wellRegionDraft.id,
          };
        } else {
          removeSelectedWellsFromExistingRegions(draft, state.selectedWells);
          removeWellRegionsWithNoWellsLeft(draft);

          // Add a new well region
          draft.wellRegions.push({
            ...action.payload,
            wells: [...draft.selectedWells],
          });
        }

        /**
         * After the resin has been added we clean up the draft,
         * unselect processed wells and close the resin form editor.
         */
        draft.wellRegionDraft = undefined;
        draft.isEditing = false;
        draft.selectedWells = [];
        draft.error = getStateError(draft);
        break;
      }
      case 'editResinCard': {
        draft.isEditing = true;
        draft.wellRegionDraft = state.wellRegions.find(region => region.id === action.id);
        draft.selectedWells = draft.wellRegionDraft?.wells ?? [];
        break;
      }
      case 'removeResinCard': {
        const idx = state.wellRegions.findIndex(region => region.id === action.id);
        draft.wellRegions.splice(idx, 1);
        draft.error = getStateError(draft);
        break;
      }
      case 'cancelEditing': {
        draft.wellRegionDraft = undefined;
        draft.isEditing = false;
        draft.selectedWells = [];
        break;
      }
      case 'setMinWellCount': {
        draft.minWellCount = action.wellCount;
        draft.error = getStateError(draft);
        break;
      }
    }
  });
}

function removeSelectedWellsFromExistingRegions(
  draft: WritableDraft<FilterPlateEditorState>,
  selectedWells: string[],
) {
  for (const wellRegion of draft.wellRegions) {
    wellRegion.wells = wellRegion.wells.filter(well => !selectedWells.includes(well));
  }
}

function addSelectedWellsToRegionWithId(
  draft: WritableDraft<FilterPlateEditorState>,
  selectedWells: string[],
  wellRegionId: string,
) {
  const wellRegion = draft.wellRegions.find(r => r.id === wellRegionId);

  if (!wellRegion) throw new Error('Well region not found.');

  const wellsOfThisRegion = wellRegion.wells;
  wellRegion.wells = Array.from(new Set([...wellsOfThisRegion, ...selectedWells]));
}

function removeSelectedWellsFromRegionWithId(
  draft: WritableDraft<FilterPlateEditorState>,
  selectedWells: string[],
  wellRegionId: string,
) {
  const wellRegion = draft.wellRegions.find(r => r.id === wellRegionId);

  if (!wellRegion) throw new Error('Well region not found.');

  wellRegion.wells = wellRegion.wells.filter(well => !selectedWells.includes(well));
}

function removeWellRegionsWithNoWellsLeft(draft: WritableDraft<FilterPlateEditorState>) {
  draft.wellRegions = draft.wellRegions.filter(region => region.wells.length > 0);
}

function cropWellRegionsToPlate(
  draft: WritableDraft<FilterPlateEditorState>,
  plateType: PlateType,
) {
  draft.wellRegions = draft.wellRegions.map(region => ({
    ...region,
    wells: cropWellsToExistingLocations(region.wells, plateType),
  }));
}

function getStateError(state: FilterPlateEditorState): FilterPlateEditorState['error'] {
  const plateName = state.plateName === '' ? 'Plate name cannot be empty' : undefined;
  const resinBufferVolume = getResinBufferVolumeError(state);
  const wellRegions = getWellRegionErrors(state);

  return !plateName && !resinBufferVolume && isEmpty(wellRegions)
    ? undefined
    : {
        plateName,
        resinBufferVolume,
        wellRegions,
      };
}

function getResinBufferVolumeError(state: FilterPlateEditorState): string | undefined {
  let resinBufferVolume: string | undefined;

  if (state.resinBufferVolume <= 0) {
    resinBufferVolume = 'This volume must be greater than zero';
  } else if (state.resinBufferVolume > state.wellCapacity) {
    resinBufferVolume = `This volume cannot exceed plate well capacity: ${state.wellCapacity}ul`;
  } else {
    resinBufferVolume = undefined;
  }

  return resinBufferVolume;
}

function getWellRegionErrors(state: FilterPlateEditorState) {
  const wellRegions: Record<string, { wells?: string; volume?: string }> = {};

  for (const wellRegion of state.wellRegions) {
    const volume =
      +wellRegion.resinVolume > state.resinBufferVolume
        ? `Resin volume cannot be greater than the total volume: ${state.resinBufferVolume}ul`
        : undefined;
    const wells =
      wellRegion.wells.length < state.minWellCount
        ? 'Insufficient wells. Please select more and assign them to this resin'
        : undefined;

    if (volume || wells) {
      wellRegions[wellRegion.id] = { volume, wells };
    } else {
      delete wellRegions[wellRegion.id];
    }
  }

  return wellRegions;
}
