import React, { useMemo } from 'react';

import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import StageRegion from 'common/ui/components/simulation-details/StageRegion/Region';

type Props = {
  currentStageIndex: number;
  deckLayout: DeckLayout;
  onStageChange: (stage: number) => void;
};

export default function StageRegionList({
  currentStageIndex,
  deckLayout,
  onStageChange,
}: Props) {
  const regions = useMemo(() => deckLayout.getAllStageRegions(), [deckLayout]);
  return (
    <>
      {regions.length > 1
        ? regions.map((stageRegion, stageIndex) => (
            <StageRegion
              key={stageIndex}
              index={stageIndex}
              region={stageRegion}
              isActive={currentStageIndex === stageIndex}
              isFirstStage={stageIndex === 0}
              isLastStage={stageIndex === regions.length - 1}
              onClick={onStageChange}
            />
          ))
        : null}
    </>
  );
}
