import React, { ReactNode, useEffect } from 'react';

import { styled } from '@mui/material/styles';

import NavigationSidepanel from 'client/app/components/NavigationSidepanel';
import setBrowserTabTitle from 'client/app/lib/setBrowserTabTitle';

type ExperimentsNavigationProps = {
  children: ReactNode;
  title: string;
};

export function ExperimentsNavigation({ children, title }: ExperimentsNavigationProps) {
  useEffect(() => setBrowserTabTitle(title), [title]);

  return (
    <NavigationSidepanel>
      <MainArea>{children}</MainArea>
    </NavigationSidepanel>
  );
}

const MainArea = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: '100%',
}));
