import React, { useMemo, useRef, useState } from 'react';

import MuiPrint from '@mui/icons-material/Print';
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { DispenseChecklist, PlateInfo } from 'client/app/api/ChecklistApi';
import { usePlateType } from 'client/app/api/PlateTypesApi';
import PlatePreview from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/components/PlatePreview';
import { useExecutionStage } from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/ExecutionStageContext';
import {
  trackPreviewPlate,
  trackPrintPlates,
} from 'client/app/apps/execution-details/ExecuteTab/metrics/executionDetailsMetrics';
import Colors from 'common/ui/Colors';
import Print from 'common/ui/components/Print';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import { usePopover } from 'common/ui/hooks/usePopover';

type Props = {
  checklist: DispenseChecklist;
  printSection?: React.ReactNode;
};

export default function TaskChecklist({ checklist, printSection }: Props) {
  const stage = useExecutionStage();
  const liquidColors = useMemo(() => LiquidColors.createAvoidingAllColorCollisions(), []);

  const print = usePrint();

  return (
    <>
      <ChecklistContainer>
        <GridHeaderTypography variant="caption" sx={{ gridColumnStart: 2 }}>
          Input plates
        </GridHeaderTypography>
        <GridHeaderTypography variant="caption">Output plates</GridHeaderTypography>
        <GridHeaderTypography variant="caption">Execution file</GridHeaderTypography>
        {checklist.dispense_list_steps.map(step => (
          <GridRow key={step.dispense_list}>
            <ChecklistCounter />
            <SourcePlatesCell hasMultiplePlates={step.source_plates.length > 1}>
              {step.source_plates.map(plate => (
                <PlateNameWithTooltip
                  key={plate.name}
                  plate={plate}
                  liquidColors={liquidColors}
                />
              ))}
            </SourcePlatesCell>
            <DestinationPlatesCell hasMultiplePlates={step.destination_plates.length > 1}>
              {step.destination_plates.map(plate => (
                <PlateNameWithTooltip
                  key={plate.name}
                  plate={plate}
                  liquidColors={liquidColors}
                />
              ))}
            </DestinationPlatesCell>
            <ExecutionFileCell variant="body2">{step.dispense_list}</ExecutionFileCell>
          </GridRow>
        ))}
        {printSection && (
          <PrintSection>
            <Print
              trigger={() => (
                <PrintButton
                  variant="text"
                  endIcon={<PrintIcon />}
                  disabled={print.buttonDisabled}
                >
                  <Typography variant="body1" color="textPrimary">
                    Print table and plate previews
                  </Typography>
                </PrintButton>
              )}
              content={() => print.content}
              onBeforeGetContent={print.showContent}
              onBeforePrint={() => {
                if (stage) trackPrintPlates(stage);
              }}
              onAfterPrint={print.hideContent}
            />
          </PrintSection>
        )}
      </ChecklistContainer>
      <div ref={print.ref} style={{ display: 'none' }}>
        {printSection}
      </div>
    </>
  );
}

function usePrint() {
  const [printButtonDisabled, setPrintButtonDisabled] = useState<boolean>(true);

  const printScreenRef = useRef<HTMLElement | null>(null);
  const ref = (ref: HTMLDivElement | null) => {
    printScreenRef.current = ref;
    setPrintButtonDisabled(!ref);
  };

  const showContent = () => {
    if (printScreenRef.current) {
      printScreenRef.current.style.display = 'block';
    }
  };
  const hideContent = () => {
    if (printScreenRef.current) {
      printScreenRef.current.style.display = 'none';
    }
  };

  return {
    content: printScreenRef.current,
    buttonDisabled: printButtonDisabled,
    ref,
    showContent,
    hideContent,
  };
}

function PlateNameWithTooltip({
  plate,
  liquidColors,
}: {
  plate: PlateInfo;
  liquidColors: LiquidColors;
}) {
  const stage = useExecutionStage();
  const { plateType, loading } = usePlateType(plate.plate_type);

  const popover = usePopover();

  return (
    <>
      <PlateNameTypography
        variant="body2"
        color={popover.isPopoverOpen ? 'primary' : 'textPrimary'}
        onClick={event => {
          popover.onShowPopover(event);
          if (stage) trackPreviewPlate(stage);
        }}
      >
        {plate.name}
      </PlateNameTypography>
      <Popper
        open={popover.isPopoverOpen}
        anchorEl={popover.popoverAnchorElement}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        ]}
      >
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={popover.onHidePopover}>
          <PaperContent>
            {loading ? (
              <Loader />
            ) : !plateType ? (
              <Typography variant="body1" p={5}>
                No plate type available
              </Typography>
            ) : (
              <PlatePreview
                plateType={plateType}
                wellContents={plate.well_contents}
                liquidColors={liquidColors}
              />
            )}
          </PaperContent>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

function ChecklistCounter() {
  return (
    <ChecklistCounterCell>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="11.5" fill="white" stroke={Colors.GREY_30} />
      </svg>
    </ChecklistCounterCell>
  );
}

//#region Styles

const ChecklistContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns:
    '[step-number] auto [input-plates] 1fr [output-plates] 1fr [execution-file] 3fr',
  gap: theme.spacing(4, 3),
  counterReset: 'checklist',
}));

const GridHeaderTypography = styled(Typography)({
  color: Colors.TEXT_SECONDARY,
});

const PlateNameTypography = styled(Typography)(({ theme }) => ({
  textDecorationLine: 'underline',
  textDecorationStyle: 'dashed',
  textDecorationColor: Colors.TEXT_DISABLED,
  width: 'fit-content',

  cursor: 'pointer',
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(2),
  },
}));

const PaperContent = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(2),
}));

const Loader = styled(CircularProgress)({
  width: 30,
  height: 30,
  margin: 100,
});

const GridRow = styled('div')({
  display: 'grid',
  gridColumn: '1 / 5',
  gridTemplateColumns: 'subgrid',
  gridTemplateRows: 'auto 1fr',
});

const ChecklistCounterCell = styled('div')({
  display: 'grid',
  position: 'relative',
  '&::before': {
    counterIncrement: 'checklist',
    content: 'counter(checklist)',
    height: 24,
    width: 24,
    position: 'absolute',
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',

    color: Colors.TEXT_SECONDARY,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '0.1px',
  },
});

const PlatesCell = styled('div', {
  shouldForwardProp: prop => prop !== 'hasMultiplePlates',
})<{ hasMultiplePlates: boolean }>(({ hasMultiplePlates }) => ({
  gridRow: hasMultiplePlates ? '1 / 3' : undefined,
  alignSelf: hasMultiplePlates ? undefined : 'center',
  cursor: 'default',
}));

const SourcePlatesCell = styled(PlatesCell)<{ hasMultiplePlates: boolean }>(
  ({ hasMultiplePlates }) => ({
    gridColumn: hasMultiplePlates ? '2 / 3' : undefined,
  }),
);

const DestinationPlatesCell = styled(PlatesCell)<{ hasMultiplePlates: boolean }>(
  ({ hasMultiplePlates }) => ({
    gridColumn: hasMultiplePlates ? '3 / 4' : undefined,
  }),
);

const ExecutionFileCell = styled(TypographyWithTooltip)({
  alignSelf: 'center',
});

const PrintSection = styled('section')(({ theme }) => ({
  gridColumn: '1 / -1',

  borderTop: `1px solid ${theme.palette.divider}`,
  marginTop: theme.spacing(2),
  paddingTop: theme.spacing(3),
}));

const PrintIcon = styled(MuiPrint)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: 16,
  height: 16,
}));

const PrintButton = styled(MuiButton)({
  textTransform: 'none',
});

//#endregion
