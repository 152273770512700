import React, { MouseEventHandler, useMemo } from 'react';

import DeleteIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import cn from 'classnames';

import { useFiltrationProtocolDesign } from 'client/app/components/Parameters/FiltrationProtocolDesign/FiltrationProtocolDesignState';
import {
  Factor,
  FACTOR_TYPE,
} from 'client/app/components/Parameters/FiltrationProtocolDesign/types';
import { pluralize } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export function FactorCard({
  factor,
  selectable,
  selected,
  onClick,
  isReadonly,
}: {
  factor: Factor;
  selectable?: boolean;
  selected?: boolean;
  onClick?: MouseEventHandler;
  isReadonly: boolean;
}) {
  const classes = useStyles();
  const { deleteFactor } = useFiltrationProtocolDesign();

  const values = useMemo(() => {
    switch (factor.type) {
      case 'categorical':
      case 'numeric': {
        const result = factor.value.join(', ');
        return result;
      }
      case 'subComponent': {
        const result = factor.value.values
          .map(v => `${v}${factor.value.unit}`)
          .join(`, `);
        return result;
      }
    }
  }, [factor.type, factor.value]);

  const levelCount = useMemo(() => {
    return factor.type === 'subComponent'
      ? factor.value.names.length * factor.value.values.length
      : factor.value.length;
  }, [factor]);

  return (
    <Paper
      component="dl"
      variant="elevation"
      className={cn(classes.factorCard, {
        [classes.selected]: selected,
        [classes.selectable]: selectable,
      })}
      onClick={onClick}
    >
      <dt className={classes.name}>Name</dt>
      <dd className={classes.name}>
        <span className={classes.nameValue}>{factor.name}</span>
      </dd>

      <dt className={classes.type}>Type</dt>
      <dd className={classes.type}>{FACTOR_TYPE[factor.type]}</dd>

      <dt className={classes.levels}>Levels</dt>
      <dd className={classes.levels}>
        {pluralize(levelCount, 'Level')}{' '}
        <span className={classes.levelValues}>({values})</span>
      </dd>

      <div className={classes.actions}>
        <IconButton
          size="small"
          icon={<DeleteIcon color="error" />}
          disabled={!selectable || isReadonly}
          onClick={e => {
            e.stopPropagation();
            deleteFactor(factor.clientId);
          }}
        />
      </div>
    </Paper>
  );
}

const useStyles = makeStylesHook(({ spacing, palette, typography }) => ({
  factorCard: {
    padding: spacing(4),
    borderRadius: spacing(2),
    border: `1px solid ${Colors.GREY_30}`,
    display: 'grid',
    gridTemplateColumns: `[name] minmax(auto, 1fr) [type] minmax(150px, 1fr) [levels] minmax(200px, 1fr) [actions] auto [end]`,
    gridTemplateRows: `[labels] auto [values] auto [end]`,
    rowGap: spacing(3),
    columnGap: spacing(4),
    alignItems: 'start',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14)',
    margin: 0,
    '& > dt': {
      gridRow: 'labels',
      ...typography.caption,
      color: palette.text.secondary,
    },
    '& > dd': {
      margin: 0,
      gridRow: 'values',
      ...typography.body2,
    },
  },
  selectable: {
    cursor: 'pointer',
    '&:hover': {
      background: Colors.BLUE_0,
    },
  },
  selected: {
    borderColor: palette.primary.main,
    color: palette.primary.dark,
    '& > dt': {
      color: palette.primary.dark,
    },
    '& $levelValues': {
      color: palette.primary.dark,
    },
  },
  name: {
    gridColumn: 'name',
  },
  nameValue: {
    ...typography.subtitle2,
  },
  type: {
    gridColumn: 'type',
  },
  levels: {
    gridColumn: 'levels',
  },
  actions: {
    gridColumn: 'actions',
    gridRowStart: 'labels',
    gridRowEnd: 'end',
    placeSelf: 'center',
  },
  levelValues: {
    fontStyle: 'italic',
    color: palette.text.secondary,
  },
}));
