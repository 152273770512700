import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DataAnalysisIcon = React.memo<SvgIconProps>(function DataAnalysisIcon(props) {
  return (
    <SvgIcon viewBox="-1 -3 24 24" {...props}>
      <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 14H4V7h2v7Zm4 0H8V4h2v10Zm4 0h-2v-4h2v4Zm2 2H2V2h14v14Zm2-16H0v18h18V0Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});

export default DataAnalysisIcon;
