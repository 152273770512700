import { assert } from 'common/lib/assertions';
import { filterObject, mapObject } from 'common/object';
import {
  PlateContentsMatrix,
  WellContents,
  WellLocationOnDeckItem,
} from 'common/types/mix';
import { isStamping } from 'common/ui/components/simulation-details/mix/edge.helpers';
import {
  DeckItemState,
  DeckState,
  isLiquidMovementEdge,
  MixState,
} from 'common/ui/components/simulation-details/mix/MixState';
import { SimulationStateGraph } from 'common/ui/components/simulation-details/mix/SimulationStateGraph';

// Filter accumulated state before displaying it to user.
// Returns a view of the mix graph for specific wells, liquid handling
// policies etc.
export function filterState(
  mixState: MixState,
  filter: {
    // If set, only show edges from this step
    edgesFromStepOnly: number | null;
    // If set, filter by contents of the well
    content: string;
    // If set, filter edges by liquid handling policy
    policy: string;
    // Filter state based on selected wells
    selectedWells: readonly WellLocationOnDeckItem[];
    stateGraph?: SimulationStateGraph;
  },
): MixState {
  let filteredState = mixState;
  if (filter.edgesFromStepOnly) {
    filteredState = filterLastEdgesOnly(filteredState, filter.edgesFromStepOnly);
  }
  if (filter.content) {
    filteredState = filterWellsByContent(filteredState, filter.content);
  }
  if (filter.policy) {
    filteredState = filterEdgesByPolicy(filteredState, filter.policy);
  }
  if (filter.selectedWells.length > 0) {
    assert(
      filter.stateGraph !== undefined,
      'Must provide a state graph when using a filter',
    );
    // If a well is selected, always show edges that (transitively) affect
    // that well
    filteredState = filterEdgesBySubgraph(
      filteredState,
      filter.selectedWells,
      filter.stateGraph,
    );
  } else if (filter.content) {
    assert(
      filter.stateGraph !== undefined,
      'Must provide a state graph when using a filter',
    );
    // If we're only showing a subset of wells, only show the edges that
    // (transitively) affect those wells
    filteredState = filterEdgesBySubgraph(
      filteredState,
      getNonEmptyWellLocations(filteredState.deck),
      filter.stateGraph,
    );
  } else {
    // No well is selected and no filter applied
    filteredState = filterEdgesForStamping(filteredState);
  }
  return filteredState;
}

// Given the final state of the job, find steps that affected the selected
// wells
export function getStepsAffectingWells(
  endState: MixState,
  endStateGraph: SimulationStateGraph,
  selectedWells: readonly WellLocationOnDeckItem[],
): readonly number[] {
  const filteredState = filterEdgesBySubgraph(endState, selectedWells, endStateGraph);
  const steps = filteredState.edges.map(edge => edge.stepNumber);
  // The same step can appear multiple times if there's a multi-channel
  // transfer at that step. De-duplicate so that we return a sequence of
  // unique steps. NOTE: The sequence is not sorted, this is OK.
  const uniqueSteps = [...new Set(steps)];
  return uniqueSteps;
}

// Helpers

function getNonEmptyWellLocations(deck: DeckState): readonly WellLocationOnDeckItem[] {
  const nonEmptyWellLocations: WellLocationOnDeckItem[] = [];
  for (const item of deck.items) {
    if (item.kind === 'plate') {
      for (const [colIdx, column] of Object.entries(item.contents || {})) {
        for (const rowIdx of Object.keys(column)) {
          nonEmptyWellLocations.push({
            deck_item_id: item.id,
            row: Number(rowIdx),
            col: Number(colIdx),
          });
        }
      }
    }
  }
  return nonEmptyWellLocations;
}

function filterLastEdgesOnly(
  mixState: MixState,
  edgesFromStepOnly: number | null,
): MixState {
  return {
    ...mixState,
    edges: mixState.edges.filter(edge => edge.stepNumber === edgesFromStepOnly),
  };
}

function filterEdgesByPolicy(mixState: MixState, policyFilter: string): MixState {
  if (!policyFilter) {
    return mixState;
  }
  const policyFilterLowerCase = policyFilter.toLowerCase();
  return {
    ...mixState,
    edges: mixState.edges.filter(
      edge =>
        isLiquidMovementEdge(edge) &&
        edge.action.policy &&
        edge.action.policy.toLowerCase().includes(policyFilterLowerCase),
    ),
  };
}

function filterEdgesBySubgraph(
  mixState: MixState,
  selectedWells: readonly WellLocationOnDeckItem[],
  endStateGraph: SimulationStateGraph,
) {
  const subgraph = endStateGraph.getSubgraphForLocations(selectedWells);
  return {
    ...mixState,
    edges: mixState.edges.filter(edge => {
      switch (edge.type) {
        case 'filtration':
          return subgraph.containsEdge(
            edge.action.tipDestination.loc,
            edge.action.liquidDestination.loc,
          );
        case 'liquid_dispense':
        case 'liquid_transfer':
          return subgraph.containsEdge(
            edge.action.from.loc,
            edge.action.tipDestination.loc,
          );
        default:
          return false;
      }
    }),
  };
}

/**
 * The stamping action occurs when 96 or 384 adapters are used for multi-channelling.
 * In this case we want to show just a single stamping edge rather than all edges
 * connecting all affected wells (over 300 arrows on top of each other look nasty).
 */
function filterEdgesForStamping(mixState: MixState) {
  const stampingEdge = mixState.edges.find(isStamping);
  if (stampingEdge) {
    return {
      ...mixState,
      edges: [stampingEdge],
    };
  }
  return mixState;
}

function filterWellsByContent(mixState: MixState, contentFilter: string): MixState {
  if (!contentFilter) {
    return mixState;
  }
  return {
    ...mixState,
    deck: {
      ...mixState.deck,
      items: mixState.deck.items.map(item =>
        filterDeckItemByWellContent(item, contentFilter),
      ),
    },
  };
}

function filterDeckItemByWellContent(
  deckItem: DeckItemState,
  contentFilter: string,
): DeckItemState {
  if (deckItem.kind !== 'plate') {
    // E.g. a tipbox - leave it as is.
    return deckItem;
  }
  const plate = deckItem;
  const contentFilterLowerCase = contentFilter.toLocaleLowerCase();
  const filteredContents = mapObject(plate.contents ?? {}, (_, col) =>
    filterObject(col, (_, cont) => wellContentsMatch(cont, contentFilterLowerCase)),
  ) as PlateContentsMatrix;
  return {
    ...plate,
    contents: filteredContents,
  };
}

function wellContentsMatch(wellContents: WellContents, contentFilterLowerCase: string) {
  // Does name match?
  if (wellContents.name?.toLowerCase().includes(contentFilterLowerCase)) {
    return true;
  }
  // Name didn't match:
  //
  // `components` is the deprecated array, used in older simulations.
  // `sub_liquids` is the latest format produced by Core Antha.
  const subLiquids = wellContents.components ?? wellContents.sub_liquids ?? [];
  return subLiquids.some(l => l.name.toLowerCase().includes(contentFilterLowerCase));
}
