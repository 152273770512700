import React, { useCallback } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { ScreenRegistry } from 'client/app/registry';
import Button from 'common/ui/components/Button';
import UIBox from 'common/ui/components/UIBox';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  /**
   * The user hasn't specified any input plates in the workflow config.
   * For a liquid handling workflow, this often means the simulation will fail.
   */
  problem: 'configHasNoInputPlates' | 'validationErrors';
} & DialogProps<'resolve' | 'simulate' | null>;

/**
 * When the user tries to simulate without filling in their workflow config properly,
 * we show this dialog.
 * We do this because we are pretty sure there's no point in starting the simulation.
 * The user should fix their workflow config first.
 */
const InvalidWorkflowConfigDialog = (props: Props) => {
  const simulateAnyway = useCallback(() => {
    logEvent('dialog-confirm-simulate-anyway', ScreenRegistry.WORKFLOW);
    props.onClose('simulate');
  }, [props]);

  const resolveProblem = useCallback(() => {
    logEvent(
      `dialog-cancel-simulate-${
        props.problem === 'configHasNoInputPlates' ? 'edit-config' : 'show-errors'
      }`,
      ScreenRegistry.WORKFLOW,
    );
    props.onClose('resolve');
  }, [props]);

  const cancel = useCallback(() => {
    logEvent('dialog-cancel-simulate', ScreenRegistry.WORKFLOW);
    props.onClose(null);
  }, [props]);

  let btnLabel = '';
  let dialogTitle = '';
  let message = '';

  if (props.problem === 'configHasNoInputPlates') {
    dialogTitle = 'Please select input plates';
    btnLabel = 'Select input plates first';
    message =
      'Your workflow configuration does not include any input plates. This often means your simulation will fail.';
  } else {
    dialogTitle = 'Simulate';
    btnLabel = 'Show errors';
    message =
      'Your workflow has configuration errors. Are you sure you want to simulate?';
  }

  return (
    <Dialog open={props.isOpen} onClose={cancel}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <UIBox padding="bm">
          <DialogContentText>{message}</DialogContentText>
        </UIBox>
      </DialogContent>
      <DialogActions data-test="noPlatesSimulationOptions">
        <Button variant="tertiary" onClick={cancel}>
          Cancel
        </Button>
        <Button variant="tertiary" onClick={simulateAnyway}>
          Simulate anyway
        </Button>
        <Button variant="tertiary" color="primary" onClick={resolveProblem}>
          {btnLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvalidWorkflowConfigDialog;
