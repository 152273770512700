import React, { useCallback, useState } from 'react';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useDownloadFile } from 'client/app/hooks/useDownloadFile';
import { FactorsExportFormat } from 'common/types/fileParameter';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export default function ExportDesignFactors({ workflowId }: { workflowId: string }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const download = useDownloadFile();

  const exportFactors = useCallback(
    async (format: FactorsExportFormat) => {
      setAnchorEl(null);
      void download(`/web/v2/workflow/${workflowId}/exportFactors/${format}`);
    },
    [download, workflowId],
  );

  return (
    <div>
      <Button
        variant="secondary"
        color="primary"
        onClick={e => setAnchorEl(e.currentTarget)}
        endIcon={anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
      >
        Export Design Factors
      </Button>
      <Menu
        classes={{ paper: classes.exportMenuPaper }}
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => exportFactors('csv')}>CSV Format</MenuItem>
        <MenuItem onClick={() => exportFactors('xlsx')}>XLSX Format</MenuItem>
        <MenuItem onClick={() => exportFactors('jsl')}>JMP/JSL Format</MenuItem>
      </Menu>
    </div>
  );
}

const useStyles = makeStylesHook(({ spacing }) => ({
  exportMenuPaper: {
    marginTop: spacing(2),
  },
}));
