import React from 'react';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useOutputPreviewTable } from 'client/app/apps/workflow-builder/output-preview/outputPreviewUtils';
import { FilterMatrix, Liquid } from 'common/types/bundle';
import Colors from 'common/ui/Colors';

type OutputPreviewTableProps = {
  outputLiquids: (Liquid | FilterMatrix)[];
  plateName: string | undefined;
};

export default function OutputPreviewTable(props: OutputPreviewTableProps) {
  const { outputLiquids, plateName } = props;
  const { rows, columnHeaders } = useOutputPreviewTable(outputLiquids, plateName);
  return (
    <StyledTableContainer>
      <Table stickyHeader>
        <TableHead>
          {columnHeaders.map((headerRow, rowIdx) => {
            return (
              <TableRow key={`header-${rowIdx}`}>
                {headerRow.map(columnHeader => {
                  return (
                    <TableCell key={columnHeader.id} colSpan={columnHeader.colSpan ?? 0}>
                      {columnHeader.content}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody>
          {rows?.map(row => {
            return (
              <TableRow key={row.id}>
                {row.cells?.map(cell => (
                  <TableCell key={cell.id}>{cell.content}</TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}

const StyledTableContainer = styled(TableContainer)(({ theme: { spacing } }) => ({
  gridArea: 'content',
  border: `1px solid ${Colors.GREY_30}`,
  borderRadius: spacing(2),
}));
