import React, { useCallback, useContext, useMemo } from 'react';

import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import Box from '@mui/material/Box';

import Panel from 'client/app/apps/workflow-builder/panels/Panel';
import { SimulationPart } from 'client/app/apps/workflow-builder/panels/simulations/useSimulations';
import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import useRequestHelpForSimulation from 'client/app/hooks/useRequestHelpForSimulation';
import { simulationRoutes } from 'client/app/lib/nav/actions';
import { formatSimulationError } from 'client/app/lib/workflow/format';
import { ScreenRegistry } from 'client/app/registry';
import { ElementConfigurationSpec } from 'common/types/elementConfiguration';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import RouteButton from 'common/ui/components/navigation/RouteButton';
import SimulationError from 'common/ui/components/simulation-details/SimulationError';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { TalkToUsIcon } from 'common/ui/icons/TalkToUs';

type SimulationErrorPanelProps = {
  simulation: SimulationPart;
  onClose: () => void;
  className: string;
  elementConfigs?: Record<string, ElementConfigurationSpec | null>;
};

/**
 * SimulationErrorPanel wraps our common Panel component and will display
 * all errors for the given simulation in the panel.
 */
export default React.memo(function SimulationErrorPanel(
  props: SimulationErrorPanelProps,
) {
  const { simulation, onClose, className, elementConfigs } = props;

  const errors = useMemo(() => {
    const errors = simulation?.errors?.map(error => {
      return formatSimulationError(error, elementConfigs);
    });
    return errors;
  }, [elementConfigs, simulation?.errors]);

  const { screenId } = useContext(ScreenContext);
  const { requestHelpDialog, handleRequestHelp } = useRequestHelpForSimulation(
    screenId!, // We should always have a screenId when we are rendering simulation error panel.
    simulation.id,
    simulation.name,
  );

  return (
    <>
      <Panel
        title="Error details"
        className={className}
        onClose={onClose}
        fullWidth
        panelContent="SimulationError"
        panelActions={
          <PanelActions
            handleRequestHelp={handleRequestHelp}
            screenId={screenId as string}
            simulationId={simulation.id}
          />
        }
      >
        <Box p={3}>
          {errors && (
            <SimulationError
              errors={errors}
              triggeredAt={new Date(simulation.startedAt)}
            />
          )}
        </Box>
      </Panel>
      {requestHelpDialog}
    </>
  );
});

type PanelActionsProps = {
  handleRequestHelp: () => void;
  screenId: string;
  simulationId: string;
};

function PanelActions(props: PanelActionsProps) {
  const classes = useStyles();
  const { handleRequestHelp, screenId, simulationId } = props;

  const handleRequestHelpFromErrorPanel = useCallback(() => {
    logEvent('request-help', screenId, 'error-panel');
    void handleRequestHelp();
  }, [handleRequestHelp, screenId]);

  return (
    <div className={classes.buttonsContainer}>
      <RouteButton
        className={classes.button}
        variant="outlined"
        size="medium"
        color="inherit"
        label="Open Simulation"
        route={simulationRoutes.openInSimulationDetails}
        routeParam={{
          simulationId,
        }}
        endIcon={<OpenInNewOutlinedIcon />}
        openInNewTab
        logEventCategory={ScreenRegistry.WORKFLOW}
      />
      <Button
        variant="secondary"
        size="medium"
        onClick={handleRequestHelpFromErrorPanel}
        endIcon={<TalkToUsIcon />}
      >
        Request help
      </Button>
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  wide: {
    width: '444px',
  },
  button: {
    borderColor: Colors.GREY_30,
  },
  buttonsContainer: {
    display: 'flex',
    gap: theme.spacing(3),
  },
}));
