import { useMemo } from 'react';
import React from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import UIErrorBox from 'client/app/components/UIErrorBox';
import { reportError } from 'client/app/lib/errors';
import { formatMeasurementObj } from 'common/lib/format';
import { Measurement } from 'common/types/mix';
import { Step } from 'common/types/steps';

type Props = { step: Step };

type Row = {
  id: string;
  well: string;
  plateName: string;
  dilutee: string;
  diluteeConcentration: Measurement;
  diluteeVolume: Measurement;
  finalConcentration: Measurement;
  finalVolume: Measurement;
  diluent: string;
  diluentVolume: Measurement;
};

const EMPTY_VOLUME: Measurement = { value: 0, unit: 'ul' };
const EMPTY_CONCENTRATION: Measurement = { value: 0, unit: '' };
const EMDASH = '\u2014';

function useRows(step: Step): [Row[], null] | [null, string] {
  return useMemo(() => {
    const rowsByWell = new Map<string, Row>();

    if (step.layers.length === 1) {
      // This is dilution in place. Liquids to dilute are already in the wells, then diluents are added.
      for (const [plateName, plate] of Object.entries(step.layers[0].plates)) {
        for (const [well, entry] of Object.entries(plate.wells)) {
          const id = `${plateName}-${well}`;

          if (
            !entry.initial?.concentration ||
            !entry.final.concentration ||
            !entry.added
          ) {
            const error = `Invalid well was provided for in-place dilution: ${JSON.stringify(
              step,
            )}`;
            reportError(new Error(error));

            return [null, error];
          }

          rowsByWell.set(well, {
            id,
            well,
            plateName,
            dilutee: entry.initial.name ?? EMDASH,
            diluteeVolume: entry.initial.volume,
            diluteeConcentration: entry.initial.concentration,
            finalConcentration: entry.final.concentration,
            finalVolume: entry.final.volume,
            diluent: entry.added.name,
            diluentVolume: entry.added.volume,
          });
        }
      }
    } else if (step.layers.length === 2) {
      // This is dilution to a new location. Dilulents are added first, then liquids to dilute.
      // First layer is the diluents.
      for (const [plateName, plate] of Object.entries(step.layers[0].plates)) {
        for (const [well, entry] of Object.entries(plate.wells)) {
          const id = `${plateName}-${well}`;

          if (!entry.added) {
            const error = `Invalid well was provided for dilution to new location: ${JSON.stringify(
              step,
            )}`;
            reportError(new Error(error));

            return [null, error];
          }

          rowsByWell.set(id, {
            id,
            well,
            plateName,
            dilutee: EMDASH,
            diluteeVolume: EMPTY_VOLUME,
            diluteeConcentration: EMPTY_CONCENTRATION,
            finalConcentration: EMPTY_CONCENTRATION,
            finalVolume: entry.added.volume, // sometimes no dilutee will be added. So set now and override if needed
            diluent: entry.added.name,
            diluentVolume: entry.added.volume,
          });
        }
      }

      // Second layer is the liquids to mix
      for (const [plateName, plate] of Object.entries(step.layers[1].plates)) {
        for (const [well, entry] of Object.entries(plate.wells)) {
          const id = `${plateName}-${well}`;

          if (!entry?.added?.concentration || !entry.final.concentration) {
            const error = `Invalid well was provided for dilution to new location: ${JSON.stringify(
              step,
            )}`;
            reportError(new Error(error));

            return [null, error];
          }

          let row = rowsByWell.get(id);
          if (!row) {
            // it's a dilutee only addition
            row = {
              id,
              well,
              plateName,
              diluent: EMDASH,
              diluentVolume: EMPTY_VOLUME,
            } as Row;
            rowsByWell.set(id, row);
          }

          row.dilutee = entry.added.name;
          row.diluteeVolume = entry.added.volume;
          row.diluteeConcentration = entry.added.concentration;
          row.finalConcentration = entry.final.concentration;
          row.finalVolume = entry.final.volume;
        }
      }
    } else {
      // Shouldn't happen.
      const error = `Step for dilution table had more than two layers: ${JSON.stringify(
        step,
      )}`;
      reportError(new Error(error));
      return [null, error];
    }

    const naturalSort = new Intl.Collator(undefined, { numeric: true });

    const rows = Array.from(rowsByWell.values()).sort((a, b) => {
      return (
        naturalSort.compare(a.plateName, b.plateName) ||
        naturalSort.compare(a.well, b.well)
      );
    });

    return [rows, null];
  }, [step]);
}

export default function DilutionTable({ step }: Props) {
  const [rows, error] = useRows(step);
  const isMultiplePlates = Object.keys(step.layers[0].plates).length > 1;

  if (error || !rows) {
    return (
      <UIErrorBox>An error was encountered while creating the dilution table.</UIErrorBox>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Well</TableCell>
            {isMultiplePlates && <TableCell align="right">Plate Name</TableCell>}
            <TableCell align="right">Dilutee</TableCell>
            <TableCell align="right">Dilutee Concentration</TableCell>
            <TableCell align="right">Dilutee Volume</TableCell>
            <TableCell align="right">Diluent</TableCell>
            <TableCell align="right">Diluent Volume</TableCell>
            <TableCell align="right">Final Volume</TableCell>
            <TableCell align="right">Final Concentration</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <StyledRow key={row.id}>
              <TableCell>{row.well}</TableCell>
              {isMultiplePlates && <TableCell align="right">{row.plateName}</TableCell>}
              <TableCell align="right">{row.dilutee}</TableCell>
              <TableCell align="right">
                {formatMeasurementObj(row.diluteeConcentration)}
              </TableCell>
              <TableCell align="right">
                {formatMeasurementObj(row.diluteeVolume)}
              </TableCell>
              <TableCell align="right">{row.diluent}</TableCell>
              <TableCell align="right">
                {formatMeasurementObj(row.diluentVolume)}
              </TableCell>
              <TableCell align="right">{formatMeasurementObj(row.finalVolume)}</TableCell>
              <TableCell align="right">
                {formatMeasurementObj(row.finalConcentration)}
              </TableCell>
            </StyledRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const StyledRow = styled(TableRow)({
  '&:last-child td, &:last-child th': { border: 0 },
});
