import { useMemo } from 'react';

import { LabwareInfoSection } from 'client/app/components/Labware/types';
import splitFullPlateName from 'client/app/components/Parameters/PlateType/splitFullPlateName';
import { getPlateInfo } from 'client/app/components/PlatePrep/helpers';
import tipNamesByBoxName from 'client/app/lib/tipNamesByBoxName';
import { getResinName } from 'common/lib/chromatography';
import { groupBy } from 'common/lib/data';
import { formatMeasurementObj, pluralizeWord } from 'common/lib/format';
import { Deck, DeckItem, FilterMatrix, Plate } from 'common/types/mix';
import { PlateType } from 'common/types/plateType';
import { getHumanReadablePlateLabel } from 'common/ui/components/simulation-details/data-utils/PlatesDataUtils';
import {
  getAllWellContentsOnPlate,
  getDeckItemStates,
} from 'common/ui/components/simulation-details/mix/deckContents';

const EMPTY_ARRAY = [] as const;

/**
 * Labware information extracted from SimulationDetails
 */
export default function useFormattedLabware(
  deck: Deck | undefined,
  plateTypes: readonly PlateType[] = EMPTY_ARRAY,
  tipUsage: readonly TipUsage[] = EMPTY_ARRAY,
): LabwareInfoSection[] {
  return useMemo(() => {
    if (!deck) return [];

    const deckItems = getDeckItemStates(deck);

    const plates = deckItems.filter(di => di.kind === 'plate') as Plate[];
    const tipwastes = deckItems.filter(di => di.kind === 'tipwaste');

    // Group robocolumns by resin and volume
    const roboColumnsCounts = plates
      .flatMap(plate => getAllWellContentsOnPlate(plate))
      .filter(
        (wellContents): wellContents is FilterMatrix =>
          wellContents.kind === 'filter_matrix_summary',
      )
      .reduce((counts, wellContents) => {
        const label =
          getResinName(wellContents) +
          ' ' +
          formatMeasurementObj(wellContents.total_volume);
        counts.set(label, (counts.get(label) || 0) + 1);
        return counts;
      }, new Map<string, number>());

    const groupedPlateTypes = groupBy(plateTypes, 'type');
    function formatPlateToolTipContent(type: string) {
      const plateName = splitFullPlateName(type)?.[0];
      return groupedPlateTypes[plateName]
        ? getPlateInfo(groupedPlateTypes[plateName][0])
        : undefined;
    }

    function formatSection(groupedByType: { [type: string]: DeckItem[] }) {
      return Object.keys(groupedByType)
        .sort()
        .map(type => ({
          primaryLabel: type,
          // Just one label: the count of plates / tipwaste
          secondaryLabels: [groupedByType[type].length.toString()],
          tooltipContent:
            groupedByType[type][0].kind === 'plate'
              ? formatPlateToolTipContent(type)
              : undefined,
        }));
    }

    // Replace the backend concatenated type of the plate type with
    // a human-readable label
    function renamePlatesToHumanReadable(platesByType: { [type: string]: DeckItem[] }) {
      const withRenamedKeys = {} as { [type: string]: DeckItem[] };
      for (const [type, plateList] of Object.entries(platesByType)) {
        withRenamedKeys[getHumanReadablePlateLabel(type, plateTypes)] = plateList;
      }
      return withRenamedKeys;
    }

    const formattedLabware: LabwareInfoSection[] = [
      {
        sectionHeader: 'Plates',
        sectionItems: formatSection(renamePlatesToHumanReadable(groupBy(plates, 'type'))),
      },
      {
        sectionHeader: 'Tips',
        sectionItems: tipUsage.map(usage => {
          const label = usage.tipType?.shortDescription ?? getLegacyLabel(usage.name);
          return {
            primaryLabel: label,
            secondaryLabels: [
              `${usage.tips.toString()}
              (${usage.boxes}x ${pluralizeWord(usage.boxes, 'box', 'boxes')})`,
            ],
            tertiaryLabels: usage.tipType
              ? [formatMeasurementObj(usage.tipType.maxVolume)]
              : undefined,
          };
        }),
      },
      {
        sectionHeader: 'Tipwaste',
        sectionItems: formatSection(groupBy(tipwastes, 'type')),
      },
      {
        sectionHeader: 'Chromatography Columns',
        sectionItems: [...roboColumnsCounts].map(([label, count]) => ({
          primaryLabel: label,
          secondaryLabels: [count.toString()],
        })),
      },
    ];

    // keep only sections with items
    return formattedLabware.filter(
      labwareSection => labwareSection.sectionItems.length > 0,
    );
  }, [deck, plateTypes, tipUsage]);
}

function getLegacyLabel(tipboxName: string): string {
  return tipNamesByBoxName.get(tipboxName) ?? 'unknown tip type';
}
