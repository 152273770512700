import React, { ReactNode, useState } from 'react';

import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';

import stopPropagation from 'common/lib/stopPropagation';

const VERTICAL_OFFSET = 20;

type Props = {
  id: string;
  open: boolean;
  anchorEl: HTMLElement | null;
  content: ReactNode | null;
};

export default function KeyPointPopper({ id, open, anchorEl, content }: Props) {
  const [arrowEl, setArrowEl] = useState<HTMLElement | null>(null);

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="top"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, VERTICAL_OFFSET],
          },
        },
        {
          name: 'arrow',
          options: {
            element: arrowEl,
          },
        },
      ]}
      style={{ zIndex: 2 }}
    >
      <PaperContent elevation={8} onPointerDown={stopPropagation}>
        {content}
      </PaperContent>
      <PopperArrow ref={setArrowEl} data-popper-arrow />
    </Popper>
  );
}

const PopperArrow = styled('div')(({ theme }) => ({
  '&:after': {
    content: '""',

    position: 'absolute',
    marginLeft: -VERTICAL_OFFSET / 2 + 3,

    borderWidth: VERTICAL_OFFSET / 2 - 3,
    borderStyle: 'solid',
    borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
  },
}));

const PaperContent = styled(Paper)(({ theme }) => ({
  borderRadius: '5px',
  padding: theme.spacing(3),
}));
