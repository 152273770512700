import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import { QUERY_WORKFLOW_BY_ID } from 'client/app/api/gql/queries';
import NavigateBackBanner from 'client/app/components/NavigateBackBanner';
import VisserverIFrame from 'client/app/components/VisserverIFrame';
import { doeTemplateRoutes } from 'client/app/lib/nav/actions';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import LinearProgress from 'common/ui/components/LinearProgress';

type Props = {
  /**
   * Typically a design is generated from a single workflow. However we don't provide a
   * workflowId if the design is associated with zero or more than one workflow.
   */
  workflowId?: WorkflowId;
  /**
   * The design tool persists designs in filetree. Provide a filetree URL to open an
   * existing design.
   */
  filetreeURL?: string;
};

export default function DOEDesignToolScreen(props: Props) {
  return <DOEDesignTool {...props} />;
}

function DOEDesignTool({ workflowId, filetreeURL }: Props) {
  const [visserverLoading, setVisserverLoading] = useState<boolean>(true);
  const [workflowLoading, setWorkflowLoading] = useState<boolean>(false);

  const isDOEAlphaEnabled = useFeatureToggle('DOE_ALPHA');

  const params: Record<string, string> = {
    method: isDOEAlphaEnabled ? 'design' : 'upload',
  };
  if (workflowId) {
    params.workflow_id = workflowId;
  }
  if (filetreeURL) {
    params.filetree_url = filetreeURL;
  }

  const loading = workflowLoading || visserverLoading;

  return (
    <>
      {loading && <LinearProgress />}
      {workflowId && (
        <BackToWorkflow
          workflowId={workflowId}
          onWorkflowLoadingChange={setWorkflowLoading}
        />
      )}
      <VisserverIFrame
        view="doe"
        params={params}
        onReady={() => setVisserverLoading(false)}
        onReload={() => setVisserverLoading(true)}
      />
    </>
  );
}

function BackToWorkflow({
  workflowId,
  onWorkflowLoadingChange: onWorkflowLoad,
}: {
  workflowId: WorkflowId;
  onWorkflowLoadingChange: (loading: boolean) => void;
}) {
  const { loading, data } = useQuery(QUERY_WORKFLOW_BY_ID, {
    variables: { id: workflowId },
  });

  useEffect(() => onWorkflowLoad(loading), [loading, onWorkflowLoad]);

  if (loading || !data?.workflow) {
    return null;
  }

  return (
    <NavigateBackBanner
      route={doeTemplateRoutes.edit}
      routeParam={{ workflowId }}
      text={`DOE: ${data.workflow.name}`}
    />
  );
}
