import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import { QUERY_WORK_TREE_APPLICATION } from 'client/app/apps/work-tree/queries';
import VisserverIFrame from 'client/app/components/VisserverIFrame';
import { VisViewRouteProps } from 'client/app/lib/nav/actions';
import setBrowserTabTitle from 'client/app/lib/setBrowserTabTitle';
import LinearProgress from 'common/ui/components/LinearProgress';

type Props = VisViewRouteProps & {
  params: URLSearchParams;
};

/**
 * Displays a visserver visualization in an iframe, with a header detailing
 * simulation details.
 */
export default function Visualization({ visserverApp, params }: Props) {
  const [visserverLoading, setVisserverLoading] = useState<boolean>(true);

  const methodId = params.get('method_id') as MethodId;

  const { data } = useQuery(QUERY_WORK_TREE_APPLICATION, {
    variables: { methodId },
    skip: !methodId,
  });

  useEffect(() => {
    const title =
      data?.workTreeApplication?.applicationDisplayName ?? 'Visualization view';
    setBrowserTabTitle(title);
  }, [data?.workTreeApplication?.applicationDisplayName]);

  return (
    <>
      {visserverLoading && <LinearProgress />}
      <VisserverIFrame
        view={visserverApp}
        params={params}
        onReady={() => setVisserverLoading(false)}
        onReload={() => setVisserverLoading(true)}
      />
    </>
  );
}
