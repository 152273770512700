import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { MUTATION_MARK_EXECUTION_STAGE_AS_COMPLETED } from 'client/app/api/gql/mutations';
import { QUERY_EXECUTION } from 'client/app/api/gql/queries';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export default function useMarkExecutionStageComplete() {
  const [markExecutionStageAsCompleted, { loading }] = useMutation(
    MUTATION_MARK_EXECUTION_STAGE_AS_COMPLETED,
  );
  const { showError } = useSnackbarManager();

  const markExecutionStageCompleteCallback = useCallback(
    async (executionId: ExecutionId, executionStageId: ExecutionStageId) => {
      await markExecutionStageAsCompleted({
        variables: {
          executionStageId,
        },
        // The overall execution status may change as a result of this mutation,
        // so we need to refetch it
        refetchQueries: [
          {
            query: QUERY_EXECUTION,
            variables: { id: executionId },
          },
        ],
        onError(error) {
          showError(error.message);
        },
      });
    },
    [showError, markExecutionStageAsCompleted],
  );

  return [markExecutionStageCompleteCallback, { markingAsComplete: loading }] as const;
}
