import { setColumnsBooleanField } from 'common/rules/setColumnsBooleanField';
import {
  SpreadsheetConfiguration,
  SpreadsheetConfigurationAction,
  SpreadsheetConfigurationCondition,
} from 'common/types/spreadsheet';
import { DataTable } from 'common/types/spreadsheetEditor';

export type ColumnState = {
  isRequired: boolean;
};

const DEFAULT_COLUMN_STATE: Readonly<ColumnState> = Object.freeze({
  isRequired: false,
});

export type SpreadsheetStateContextProps = {
  configuration: SpreadsheetConfiguration;
  data: DataTable | DataTable[] | undefined;
};

export type SpreadsheetState = {
  sheets: { [sheetName: string]: SheetState };
};

export type SheetState = { columns: { [columnName: string]: ColumnState } };

/**
 * Evaluates all rules.
 *
 * Note that action is executed even when condition is not met. This is because some actions do
 * opposite effect when the condition is not met (for example requiring/not requiring a column).
 */
export function evaluate(configuration: SpreadsheetConfiguration): SpreadsheetState {
  let result: SpreadsheetState = { sheets: {} };

  for (const { name: sheetName, columns } of configuration.sheets) {
    const sheetState: SheetState = { columns: {} };
    for (const { name: columnName } of columns) {
      sheetState.columns[columnName] = { ...DEFAULT_COLUMN_STATE };
    }
    result.sheets[sheetName] = sheetState;
  }

  for (const { condition, actions } of configuration.rules ?? []) {
    const isConditionMet = evaluateCondition(condition);
    for (const action of actions) {
      result = runAction(action, isConditionMet, result);
    }
  }

  return result;
}

function evaluateCondition(condition: SpreadsheetConfigurationCondition): boolean {
  switch (condition.type) {
    case 'const-true':
      return true;
  }
}

function runAction(
  action: SpreadsheetConfigurationAction,
  isConditionMet: boolean,
  result: SpreadsheetState,
): SpreadsheetState {
  switch (action.type) {
    case 'set-columns-required':
      return setColumnsBooleanField(action, isConditionMet, result);
  }
}
