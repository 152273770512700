import { FieldMergeFunction } from '@apollo/client';

type SimulationRef = { __ref: string };
type Simulations = {
  items: SimulationRef[];
};

/**
 * Apollo cache field policy for `simulationsForWorkflow`
 * This ensures we do not have duplicates when fetching more pages.
 *
 * Duplicates can appear when we simulate;
 * indeed new simulations are prepended to the first page,
 * pushing already existing (and maybe fetched) simulations to the next page, where they would be fetched again.
 *
 * This `merge` function will append the existing and incoming items in the same array, and then filter duplicates.
 *
 */
export const simulationsForWorkflow: {
  merge: FieldMergeFunction<Simulations, Simulations>;
} = {
  merge(existing, incoming) {
    if (!existing) {
      return incoming;
    }
    const existingMap: { [key: string]: boolean } = {};
    const mergedItems = [...incoming.items, ...existing.items].filter(item => {
      if (existingMap[item.__ref]) {
        return false;
      }
      existingMap[item.__ref] = true;
      return true;
    });
    return { ...incoming, items: mergedItems };
  },
};
