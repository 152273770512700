import React from 'react';

import { ErrorAction } from 'common/types/mix';
import ErrorListBox from 'common/ui/components/ErrorListBox';
import zIndex from 'common/ui/components/simulation-details/mix/zIndex';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  error: ErrorAction;
};

export default function PreviewError(props: Props) {
  const classes = useStyles();
  const { error } = props;
  return (
    <div className={classes.failedSimulationErrors}>
      <ErrorListBox
        heading="The simulation failed with the following errors:"
        errors={[error.message]}
      />
    </div>
  );
}

const useStyles = makeStylesHook({
  failedSimulationErrors: {
    margin: '32px',
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'center',
    pointerEvents: 'none', // Keep the deck interactive while prompt is shown
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: zIndex.prompt,
  },
});
