import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import { isOptimisticResponseId } from 'client/app/apps/experiments/optimisticResponse';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/**
 * For most users, the mutation will take less than 500ms.
 * During this time the content will be visible to the user and have the appearance of being
 * interactive (though it is covered by a transparent box to prevent interaction). If after
 * 500ms the mutation has not completed, the component will be faded to indicate to the user
 * the content is still saving. The delay before fading content means that in the common case
 * when the mutation is fast, we avoid 'flashing' of content and achieve very nice UX.
 */
const FADE_AFTER_MS = 500;

type Props = {
  /**
   * Whatever UI we want to display inside the wrapper.
   */
  children: React.ReactNode;
  /**
   * The id of the object we display inside the wrapper.
   */
  objectId: string;
};

/**
 * Designed to help with implementing optimistic mutations.
 * An optimistic mutation works as follows. Say, for example, we are adding
 * a new comment in a list of comments:
 * - We create the comment client-side and render the UI with the comment inside
 *   this wrapper.
 * - We send a GraphQL mutation to the server to create the comment in the database.
 * - The wrapper visually indicates the child object is "being created" and prevents
 *   clicks, to prevent the user from trying to interact with the comment, for example
 *   edit or delete it.
 * - The GraphQL mutation request succeeds. We display the child object as normal
 *   and allow clicks.
 */
export default function OptimisticMutationWrapper({ children, objectId }: Props) {
  const classes = useStyles();
  // This variable is set to false for a brief period after this component is first
  // rendered, and then it gets automatically set to true.
  const [mutationIsTakingALongTime, setMutationIsTakingALongTime] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setMutationIsTakingALongTime(true), FADE_AFTER_MS);
    return () => clearTimeout(timeout);
  }, []);
  if (isOptimisticResponseId(objectId)) {
    // This is an object which is being created and doesn't exist in the database yet.
    // TODO Find a way to make it impossible to focus elements in this div by pressing
    //      Tab on the keyboard.
    return (
      <div
        className={cx(classes.readonlyBox, {
          [classes.semiTransparent]: mutationIsTakingALongTime,
        })}
      >
        {children}
        <div className={classes.coverAndPreventClicks} />
      </div>
    );
  } else {
    // This is a normal object that exists in the database. Render it normally.
    return <>{children}</>;
  }
}

const useStyles = makeStylesHook({
  readonlyBox: {
    position: 'relative',
  },
  semiTransparent: {
    opacity: 0.5,
  },
  // Covers the whole ReadonlyWrapper and prevents clicks inside the wrapper.
  coverAndPreventClicks: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
});
