import { ParameterStateRuleResult as RuleResult } from 'client/app/lib/rules/elementConfiguration/evaluateParameterState';
import {
  AddParameterMessageAction,
  SetBooleanFieldAction,
} from 'common/types/elementConfiguration';

export function addParameterMessage(
  action: AddParameterMessageAction,
  isConditionMet: boolean,
  result: RuleResult,
): RuleResult {
  if (!isConditionMet) {
    return result;
  }
  const currentState = result.parameterStateDict[action.parameterName];
  const currentList =
    (action.type === 'add-parameter-error'
      ? currentState?.errorMessages
      : currentState?.warningMessages) ?? [];
  const updatedList = [
    ...currentList,
    action.type === 'add-parameter-error' ? action.errorMessage : action.message,
  ];
  result.parameterStateDict[action.parameterName] = {
    ...currentState,
    errorMessages: action.type === 'add-parameter-error' ? updatedList : currentList,
    warningMessages: action.type === 'add-parameter-warning' ? updatedList : currentList,
  };
  return result;
}

export function setParametersBooleanField(
  action: SetBooleanFieldAction,
  isConditionMet: boolean,
  result: RuleResult,
): RuleResult {
  const shouldExecute = isConditionMet || action.revertIfConditionNotMet;
  if (!shouldExecute) {
    return result;
  }

  const value = getValue(action);
  const field = getField(action);

  const fieldValue = isConditionMet ? value : !value;
  for (const parameterName of action.parameterNames) {
    result.parameterStateDict[parameterName] = {
      ...result.parameterStateDict[parameterName],
      [field]: fieldValue,
    };
  }
  return result;
}

function getValue(action: SetBooleanFieldAction) {
  switch (action.type) {
    case 'set-parameters-enabled':
    case 'set-parameters-required':
    case 'set-parameters-visible':
      return true;
    default:
      return false;
  }
}

function getField(action: SetBooleanFieldAction) {
  switch (action.type) {
    case 'set-parameters-enabled':
    case 'set-parameters-not-enabled':
      return 'isEnabled';
    case 'set-parameters-visible':
    case 'set-parameters-not-visible':
      return 'isVisible';
    case 'set-parameters-required':
    case 'set-parameters-not-required':
      return 'isRequired';
  }
}
