import React, { useContext } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditingIcon from '@mui/icons-material/EditOutlined';
import ViewingIcon from '@mui/icons-material/VisibilityOutlined';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';

import {
  BLOCK_GUTTER_WIDTH,
  ExperimentDetailContext,
} from 'client/app/apps/experiments/ExperimentDetailScreen';
import ExperimentViewMenu from 'client/app/apps/experiments/ExperimentViewMenu';
import { ContentType, ExperimentQuery } from 'client/app/gql';
import { IntercomTourIDs } from 'common/lib/intercom';
import { IntercomTarget } from 'common/lib/IntercomTarget';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { usePopover } from 'common/ui/hooks/usePopover';

export const EXPERIMENT_REPORT_TOOLBAR_HEIGHT = 81;

type Props = {
  isExample: boolean;
};

export function ExperimentReportToolbar({
  experiment,
}: {
  experiment: ExperimentQuery['experiment'];
}) {
  const classes = useStyles();

  return (
    <div className={classes.toolbar}>
      <EditModeDropdown isExample={experiment.contentSource === ContentType.EXAMPLE} />
      <Divider className={classes.divider} orientation="vertical" />
      <ExperimentViewMenu experimentId={experiment.id} currentView="report" />
    </div>
  );
}

function EditModeDropdown({ isExample }: Props) {
  const classes = useStyles();

  const { isEditing, setIsEditing } = useContext(ExperimentDetailContext);

  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();

  const onSelectMode = (newMode: 'viewing' | 'editing') => {
    setIsEditing(newMode === 'editing');
    onHidePopover();
  };

  return (
    <>
      <IntercomTarget tour={IntercomTourIDs.EXPERIMENT} name="view-edit" decorate>
        <Tooltip
          title={
            isExample
              ? 'This experiment is an example and cannot be edited'
              : isEditing
              ? 'Switch to Viewing to prevent changes'
              : 'Switch to Editing to update the experiment'
          }
        >
          <Button
            variant="tertiary"
            onClick={onShowPopover}
            startIcon={isEditing ? <EditingIcon /> : <ViewingIcon />}
            endIcon={<ArrowDropDownIcon />}
            className={classes.dropdownButton}
          >
            <Typography>{isEditing ? 'Editing' : 'Viewing'}</Typography>
          </Button>
        </Tooltip>
      </IntercomTarget>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={popoverAnchorElement}
        keepMounted
        open={isPopoverOpen}
        onClose={onHidePopover}
        PaperProps={{ square: false }}
      >
        <MenuItemWithIcon
          icon={<ViewingIcon />}
          text="Viewing"
          onClick={() => onSelectMode('viewing')}
        />
        <MenuItemWithIcon
          icon={<EditingIcon />}
          text="Editing"
          onClick={() => onSelectMode('editing')}
          disabled={isExample}
        />
      </Menu>
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  dropdownButton: {
    textTransform: 'none',
    color: theme.palette.text.primary,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    position: 'sticky',
    top: 0,
    zIndex: 1,
    height: EXPERIMENT_REPORT_TOOLBAR_HEIGHT,
    borderBottom: `1px solid ${Colors.GREY_30}`,
    margin: theme.spacing(0, `${BLOCK_GUTTER_WIDTH}px`, 8, `${BLOCK_GUTTER_WIDTH}px`),
    background: Colors.GREY_5,
  },
  divider: {
    height: '24px',
  },
}));
