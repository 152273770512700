import {
  getSelectedMainDevice,
  useGetDeviceCommonForWorkflow,
} from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import {
  State,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { Stage } from 'common/types/bundle';

/**
 * Returns the id of the main device for the stage to which the currently selected element/workflow settings belongs to.
 * If no execution mode has been set, returns `undefined`.
 * @param isWorkflowSettingsPanel Is the workflow settings panel being accessed?
 */
export default function useStageConfiguredDevice(isWorkflowSettingsPanel: boolean) {
  const configuredDevices = useStageConfiguredDevices(isWorkflowSettingsPanel) ?? [];
  const devices = useGetDeviceCommonForWorkflow(configuredDevices);
  const mainDevice = getSelectedMainDevice(configuredDevices, devices);

  return mainDevice.selectedDevice?.deviceId;
}

function useStageConfiguredDevices(isWorkflowSettingsPanel: boolean) {
  return useWorkflowBuilderSelector(state => {
    if (isWorkflowSettingsPanel) {
      // If this is accessed via the workflow settings panel, we can use `state.selectedStageId` as this refers to the stage
      // to which this panel belongs
      const selectedStage = state.stages.find(
        stage => stage.id === state.selectedStageId,
      );
      return getConfiguredDevicesForStage(selectedStage, state);
    }
    // Otherwise (if this is accessed via an element), we must use the selected objects as `state.selectedStageId` will not refer to
    // the stage of the selected element
    if (state.selectedObjectIds.length !== 1) {
      return;
    }

    const selectedStage = state.stages.find(stage =>
      stage.elementIds.includes(state.selectedObjectIds[0]),
    );

    return getConfiguredDevicesForStage(selectedStage, state);
  });
}

function getConfiguredDevicesForStage(stage: Stage | undefined, state: State) {
  if (!stage) {
    return;
  }
  return state.config.configuredDevices?.filter(device =>
    stage.configuredDevices.includes(device.id),
  );
}
