import React, { useEffect, useRef, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import Colors from 'common/ui/Colors';
import { useEnterKeyPress } from 'common/ui/hooks/useEnterKeyPress';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  name: string;
  onSave: (newName: string) => void;
};

export default function NameEditor({ name, onSave }: Props) {
  const [value, setValue] = useState(name);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = useTextFieldChange(newValue => setValue(newValue));
  const saveNewName = () => onSave(value);
  const handleEnter = useEnterKeyPress(saveNewName);

  useEffect(() => {
    inputRef.current?.focus();
    inputRef.current?.select();
  }, []);

  return (
    <ClickAwayListener onClickAway={saveNewName}>
      <Editor
        data-heap-tracking="breadcrumbs-name-editor-input"
        variant="outlined"
        inputRef={inputRef}
        value={value}
        onChange={handleChange}
        onKeyDown={handleEnter}
        onBlur={saveNewName}
      />
    </ClickAwayListener>
  );
}

const Editor = styled(TextField)`
  flex-grow: 1;

  & .MuiOutlinedInput-root {
    &.MuiInputBase-formControl {
      fieldset {
        border-color: transparent;
      }
      input {
        flex-grow: 1;
      }
      input::selection {
        color: ${Colors.WHITE};
        background-color: ${props => props.theme.palette.primary.main};
      }
    }
  }
`;
