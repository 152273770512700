import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const InventoryIcon = React.memo<SvgIconProps>(function InventoryIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg fill="none" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.507 4h3.715C19.2 4 20 4.9 20 6v14c0 1.1-.8 2-1.778 2H5.778c-.125 0-.24-.01-.356-.03a1.738 1.738 0 01-.898-.55A2.153 2.153 0 014 20V6c0-.28.053-.54.142-.77.09-.24.222-.46.382-.64.24-.27.552-.47.898-.55.116-.03.231-.04.356-.04h3.715C9.867 2.84 10.844 2 12 2c1.156 0 2.133.84 2.507 2zm-1.85.438A.661.661 0 0012 3.78a.661.661 0 00-.656.656c0 .36.297.657.656.657a.661.661 0 00.656-.657zM6 20V6h12v14H6zm10-2v-2H8v2h8zm0-6v2H8v-2h8zm0-2V8H8v2h8z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
