import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { QueryResult } from '@apollo/client';
import { useApolloClient, useMutation, useQuery } from '@apollo/client/react/hooks';
import produce, { castDraft } from 'immer';
import { v4 as uuid } from 'uuid';

import { MUTATION_SIMULATE_WORKFLOW } from 'client/app/api/gql/mutations';
import {
  QUERY_SIMULATION_CARD_POLL,
  QUERY_SIMULATION_COUNTS_BY_WORKFLOW_ID,
  QUERY_SIMULATIONS_FOR_WORKFLOW,
} from 'client/app/api/gql/queries';
import {
  ArrayElement,
  FavoritedByFilterEnum,
  simulationsForWorkflowQuery,
  simulationsForWorkflowQueryVariables,
} from 'client/app/gql';
import { DateRange } from 'common/ui/components/FilterChip/FilterChipWithDateRange';

export const FINAL_SIMULATION_STATUSES = ['COMPLETED', 'FAILED', 'UNKNOWN'];

export type SimulationForWorkflow = Exclude<
  ArrayElement<simulationsForWorkflowQuery['simulationsForWorkflow']['items']>,
  null
>;

export type SimulationPart = Omit<SimulationForWorkflow, 'simulationSeriesSiblings'>;

export function isSimulationPlaceholder(
  simulation: SimulationForWorkflow | SimulationPlaceholder,
): simulation is SimulationPlaceholder {
  return 'isPlaceholder' in simulation;
}

/* Placeholder is used to show a simulation card before we actually get the response back from
appserver about the simulation */
export type SimulationPlaceholder = {
  id: SimulationId;
  isPlaceholder: boolean;
} & Partial<SimulationForWorkflow>;

export const SIMULATION_PLACEHOLDER_ID = 'PLACEHOLDER_';

type Props = {
  workflowId: WorkflowId;
  searchQuery: string;
  favorited: boolean;
  filterDateRange: DateRange;
  filterSuccessfulSimulations: boolean;
};

/**
 * This hook:
 *  - queries for the simulations associated with the workflow.
 *  - does the mutation for simulating the workflow
 *  - Keeps tracks of all of the simulations including placeholder ones which will get updated when we
 * get a response from apollo.
 */
function useSimulationsWithPlaceholders(props: Props) {
  const {
    workflowId,
    searchQuery,
    filterSuccessfulSimulations,
    favorited,
    filterDateRange,
  } = props;
  const [placeholders, setPlaceholders] = useState<SimulationPlaceholder[]>([]);

  const queryResult = useQuery(QUERY_SIMULATIONS_FOR_WORKFLOW, {
    variables: {
      workflowId,
      search: searchQuery,
      favorited: favorited
        ? FavoritedByFilterEnum.FAVORITED_BY_ANYONE
        : FavoritedByFilterEnum.ALL,
      filterStartDate: filterDateRange.startDate
        ? filterDateRange.startDate.format('YYYY-MM-DD')
        : undefined,
      filterEndDate: filterDateRange.endDate
        ? filterDateRange.endDate.format('YYYY-MM-DD')
        : undefined,
      filterSimulationStatus: filterSuccessfulSimulations ? 'COMPLETED' : undefined,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [simulateWorkflow, { loading: isSimulating }] = useMutation(
    MUTATION_SIMULATE_WORKFLOW,
    {
      refetchQueries: [
        {
          query: QUERY_SIMULATION_COUNTS_BY_WORKFLOW_ID,
          variables: {
            id: workflowId,
          },
        },
      ],
    },
  );

  const appserverSimulations = useMemo(
    () => queryResult.data?.simulationsForWorkflow.items ?? [],
    [queryResult.data?.simulationsForWorkflow.items],
  );

  // Clear placeholders if we change workflow
  useEffect(() => {
    setPlaceholders([]);
  }, [workflowId]);

  const apollo = useApolloClient();
  /**
   * Callback that:
   * 1. Adds a placeholder(s) for each time the simulate button was pressed.
   * 2. Make the actual request to appserver.
   */
  const simulateWorkflowWithTracking = useCallback(
    async (
      workflowId: WorkflowId,
      workflowVersion: number,
      workflowName: string,
      shouldApplyDesign?: boolean,
    ) => {
      const id = (SIMULATION_PLACEHOLDER_ID + uuid()) as SimulationId;
      setPlaceholders(placeholders => {
        return [
          {
            id,
            isPlaceholder: true,
            name: workflowName,
            startedAt: new Date().toString(),
            status: 'QUEUED' as SimulationStatus,
            __typename: 'Simulation',
          },
          ...placeholders,
        ];
      });

      const simulationResponse = await simulateWorkflow({
        variables: {
          workflowId: workflowId,
          workflowVersion: workflowVersion,
          shouldApplyDesign: shouldApplyDesign,
        },
      });

      /** Remove the placeholder, and update the cache with the actual simulation from appserver. */
      setPlaceholders(
        produce(draft => {
          const idx = draft.findIndex(p => p.id === id);
          draft.splice(idx);
        }),
      );
      const newSimulation = simulationResponse.data?.simulateWorkflow.simulation;

      if (newSimulation) {
        const variables = {
          workflowId,
          search: '',
          favorited: FavoritedByFilterEnum.ALL,
        };
        const cachedSimulations = apollo.readQuery<
          simulationsForWorkflowQuery,
          simulationsForWorkflowQueryVariables
        >({
          query: QUERY_SIMULATIONS_FOR_WORKFLOW,
          variables,
        });

        const newSimulationsCache = produce(cachedSimulations, draft => {
          if (!draft) {
            return;
          }
          const items = draft.simulationsForWorkflow.items;
          if (items.findIndex(item => item.id === newSimulation.id) < 0) {
            items.unshift(castDraft(newSimulation));
          }
        });
        apollo.writeQuery({
          query: QUERY_SIMULATIONS_FOR_WORKFLOW,
          variables,
          data: newSimulationsCache,
        });
      }
    },
    [apollo, simulateWorkflow],
  );

  /**
   * Don't show any placeholders if there is a filter present.
   * Otherwise, show placeholders that do not overlap with the ones in appserverSimulations.
   */
  const simulationsWithPlaceholders = useMemo(() => {
    const filteredPlaceholders =
      searchQuery || favorited || filterSuccessfulSimulations
        ? []
        : placeholders.filter(
            placeholder =>
              placeholder.id.startsWith(SIMULATION_PLACEHOLDER_ID) ||
              !appserverSimulations.some(simulation => simulation.id === placeholder.id),
          );
    return [...filteredPlaceholders, ...appserverSimulations];
  }, [
    appserverSimulations,
    favorited,
    filterSuccessfulSimulations,
    placeholders,
    searchQuery,
  ]);

  return useMemo(() => {
    return {
      result: {
        ...queryResult,
        data: {
          simulationsForWorkflow: {
            ...queryResult.data?.simulationsForWorkflow,
            items: simulationsWithPlaceholders,
          },
        },
      } as unknown as QueryResult<simulationsForWorkflowQuery>,
      resultWithoutPlaceholder: queryResult,
      simulateWorkflowWithTracking,
      isSimulating,
    };
  }, [
    isSimulating,
    queryResult,
    simulateWorkflowWithTracking,
    simulationsWithPlaceholders,
  ]);
}

export type PollingErrors = { [id: string]: string | undefined };
function usePollingErrors() {
  const [pollingErrors, setPollingErrors] = useState<PollingErrors>({});
  const setPollingError = useCallback(
    (id: SimulationId, error: string | undefined) => {
      if (error === undefined) {
        setPollingErrors(
          produce(draft => {
            delete draft[id];
          }),
        );
      } else {
        setPollingErrors(
          produce(draft => {
            draft[id] = error;
          }),
        );
      }
    },
    [setPollingErrors],
  );
  return { pollingErrors, setPollingError };
}

/**
 *
 * This hook does what  useSimulationsWithPlaceholders does and returns an additional react component that polls all simulations not in a final state.
 * @see useSimulationsWithPlaceholders
 */
export default function useSimulations(props: Props) {
  const simulationsWithPlaceholders = useSimulationsWithPlaceholders(props);
  const { pollingErrors, setPollingError } = usePollingErrors();
  const simulationsWithoutPlaceholders =
    simulationsWithPlaceholders.resultWithoutPlaceholder;
  const simulationsPoller = (
    <SimulationsPoller
      simulationsWithoutPlaceholders={simulationsWithoutPlaceholders}
      setPollingError={setPollingError}
    />
  );
  return { ...simulationsWithPlaceholders, pollingErrors, simulationsPoller };
}

type SimulationsPollerProps = {
  simulationsWithoutPlaceholders: QueryResult<
    simulationsForWorkflowQuery,
    simulationsForWorkflowQueryVariables
  >;
  setPollingError: (id: SimulationId, error: string | undefined) => void;
};

/*
 * This components renders nothing to the DOM.
 * Its only purpose is to poll every non-completed and non-placeholder simulations to get the latest statuses.
 */
function SimulationsPoller(props: SimulationsPollerProps) {
  const sims =
    props.simulationsWithoutPlaceholders.data?.simulationsForWorkflow.items ?? [];
  const { setPollingError } = props;
  return (
    <>
      {sims.map(simulation => (
        <SimulationPoller
          key={simulation.id}
          simulation={simulation}
          setPollingError={setPollingError}
        />
      ))}
    </>
  );
}

function simulationFinished(simulation: SimulationForWorkflow) {
  return [simulation, ...(simulation.simulationSeriesSiblings ?? [])].every(sim =>
    FINAL_SIMULATION_STATUSES.includes(sim?.status ?? 'RUNNING'),
  );
}

const SIMULATION_POLL_INTERVAL_MS = 3000;
function SimulationPoller({
  simulation,
  setPollingError,
}: {
  simulation: SimulationForWorkflow;
  setPollingError: (id: SimulationId, error: string | undefined) => void;
}) {
  const [stopPolling, setStopPolling] = useState(simulationFinished(simulation));

  useQuery(QUERY_SIMULATION_CARD_POLL, {
    variables: { id: simulation.id },
    pollInterval: stopPolling ? 0 : SIMULATION_POLL_INTERVAL_MS,
    skip: stopPolling,
    // notifyOnNetworkfStatusChange is needed since onCompleted on polling does get retriggered.
    // https://github.com/apollographql/apollo-client/issues/5531
    notifyOnNetworkStatusChange: true,
    onError: e => setPollingError(simulation.id, e.message),
    onCompleted: ({ simulation }) => {
      // clear any polling error for that sim
      setPollingError(simulation.id, undefined);
      /*
       * Keep polling until we reach a final state.
       */
      const shouldStopPolling = simulationFinished(simulation);
      setStopPolling(shouldStopPolling);
    },
  });

  return null;
}
