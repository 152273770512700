import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const WorkflowIcon = React.memo<SvgIconProps>(function WorkflowIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" fill="none">
        <rect x="2.75" y="5.5" width="8.25" height="8.25" rx="1" fill="currentColor" />
        <rect x="15.75" y="5.5" width="5.5" height="5.5" rx="1" fill="currentColor" />
        <path d="M11 11h2.397v4.75h2.353" stroke="currentColor" />
        <rect x="15.75" y="13" width="5.5" height="5.5" rx="1" fill="currentColor" />
        <path d="M11 8.25h4.75" stroke="currentColor" />
      </svg>
    </SvgIcon>
  );
});
