import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const TrackActiveContext = createContext<{
  activeId: string;
  register: (el: HTMLElement | null) => void;
  showById: (id: string) => void;
}>({ activeId: '', register: () => {}, showById: () => {} });

/**
 * This is based on the approach used here: https://github.com/mdn/yari/blob/main/client/src/document/hooks.ts
 * With some modifications to handle dynamically registered elements.
 */
export function TrackActiveProvider({
  children,
  rootId,
}: {
  children: ReactNode;
  rootId?: string;
}) {
  const observer = useRef<IntersectionObserver>();
  const [activeId, setActiveId] = useState<string>('');

  const currentlyVisible = useRef<Set<Element>>(new Set());

  useEffect(() => {
    observer.current = new IntersectionObserver(
      entries => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            currentlyVisible.current.add(entry.target);
          } else {
            currentlyVisible.current.delete(entry.target);
          }
        }

        // This is a precaution to clear out elements that no longer existing the DOM.
        // It shouldn't happen in production, but can happen in dev with hot-loading.
        currentlyVisible.current = new Set(
          [...currentlyVisible.current.values()].filter(n => document.contains(n)),
        );

        const visible = [...currentlyVisible.current.values()].sort((a, b) => {
          const pos = a.compareDocumentPosition(b);
          return pos & Node.DOCUMENT_POSITION_PRECEDING ? 1 : -1;
        });

        setActiveId(visible[0]?.id ?? '');
      },
      {
        root: rootId ? document.getElementById(rootId) : undefined,
        threshold: [0, 1],
      },
    );

    return () => observer.current?.disconnect();
  }, [rootId]);

  const register = useCallback((element: HTMLElement | null) => {
    if (element) {
      observer.current?.observe(element);
    }
  }, []);

  const showById = useCallback((id: string) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const value = useMemo(
    () => ({
      activeId,
      register,
      showById,
    }),
    [activeId, register, showById],
  );

  return (
    <TrackActiveContext.Provider value={value}>{children}</TrackActiveContext.Provider>
  );
}

export const useTrackActive = () => useContext(TrackActiveContext);
