import { graphql } from 'client/app/gql';

export const MUTATION_CREATE_BLOCK = graphql(/* GraphQL */ `
  mutation CreateBlock(
    $applicationName: String!
    $applicationVersion: String!
    $inputResultIds: [ResultId!]!
    $experimentId: ExperimentId!
  ) {
    createBlock(
      input: {
        applicationName: $applicationName
        applicationVersion: $applicationVersion
        inputResultIds: $inputResultIds
        experimentId: $experimentId
      }
    ) {
      ...WorkTreeBlockFragment
    }
  }
`);

export const MUTATION_SET_HIDDEN_BLOCKS = graphql(/* GraphQL */ `
  mutation SetHiddenBlocks($experimentId: ExperimentId!, $blockIds: [BlockId!]!) {
    setHiddenBlocks(input: { experimentId: $experimentId, blockIds: $blockIds }) {
      ...WorkTreeFragment
    }
  }
`);

export const MUTATION_UPDATE_WORK_TREE_STATE = graphql(/* GraphQL */ `
  mutation UpdateWorkTreeState($experimentID: ExperimentId!, $showFailed: Boolean!) {
    updateWorkTreeState(input: { experimentID: $experimentID, showFailed: $showFailed }) {
      experimentID
      showFailed
    }
  }
`);

export const MUTATION_CREATE_METHOD = graphql(/* GraphQL */ `
  mutation CreateMethod(
    $applicationName: String!
    $applicationVersion: String!
    $inputs: [ResultPortType!]!
    $issuedFrom: String!
  ) {
    worktreeCreateMethod(
      input: {
        applicationName: $applicationName
        applicationVersion: $applicationVersion
        inputs: $inputs
        issuedFrom: $issuedFrom
      }
    ) {
      id
      href
    }
  }
`);

export const MUTATION_EXCHANGE__TO_RESULTS = graphql(/* GraphQL */ `
  mutation ExhangeToResults($inputs: [ResultPrototypeType!]!) {
    exchangeToResults(input: { inputs: $inputs }) {
      id
      resultType
    }
  }
`);
