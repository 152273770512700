// We are interested in the table which is closest to the top, as that
// will determine which plates are being visualised. The same table will
// also need to be scrolled to top if users interact with the plate visualisation.
export function getTopmostTable(
  container: HTMLDivElement,
  allTables: HTMLTableElement[],
) {
  // Finding the topmost table is not hard:
  // The container scrollTop tells us how much the whole container
  // has been scrolled. If this number is greater than the height of the first
  // table, it means the second table is now the topmost and so forth.
  const howMuchHasBeenScrolled = container.scrollTop;
  for (const [idx, table] of Object.entries(allTables)) {
    // The first table will have 0 offsetTop and X height.
    // The second table will have X offsetTop and Y height.
    // The third table will have X + Y offsetTop and Z height, etc.
    const tableHeight = table.offsetHeight + table.offsetTop;
    // Èach table has 2 headers, account for this by removing the height
    // of one of them.
    const tableRowHeight = table.rows[0].offsetHeight;

    const isTableAtTheTop = howMuchHasBeenScrolled <= tableHeight - tableRowHeight;
    if (isTableAtTheTop) {
      const newTopmostTable = allTables[Number(idx)];
      return newTopmostTable;
    }
  }
  // If no topmostTable was found, return the first one in the list.
  return allTables[0];
}

/** The active table is determined by which source and destination plate are
 *  selected.
 */
export function scrollActiveTableToTop(
  selectedSourceIdx: number,
  selectedDestIdx: number,
  topmostTable: HTMLTableElement | null,
  allTables: HTMLTableElement[],
) {
  for (const [idx, table] of Object.entries(allTables)) {
    const tableSourceIdx = Number(table.dataset.sourceidx!);
    const tableDestIdx = Number(table.dataset.destidx!);

    if (tableSourceIdx === selectedSourceIdx && tableDestIdx === selectedDestIdx) {
      const _table = allTables[Number(idx)];
      if (table !== topmostTable) {
        _table.scrollIntoView();
      }
    }
  }
  return topmostTable;
}
