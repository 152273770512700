import React from 'react';

import Paper from '@mui/material/Paper';

import { formatDuration } from 'common/lib/format';
import { stringToMarkdown } from 'common/lib/markdown';
import { PromptAction } from 'common/types/mix';
import { MarkdownPreview } from 'common/ui/components/MarkdownPreview';
import zIndex from 'common/ui/components/simulation-details/mix/zIndex';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  prompt: PromptAction;
};

export default function Prompt(props: Props) {
  const classes = useStyles();
  const { prompt } = props;
  return (
    <div className={classes.containerFillsParent}>
      <Paper className={classes.prompt}>
        {prompt.duration_seconds && prompt.duration_seconds > 0 && (
          <div className={classes.duration}>
            {'Duration: ' + formatDuration(prompt.duration_seconds)}
          </div>
        )}
        <MarkdownPreview
          className={classes.message}
          markdown={stringToMarkdown(prompt.message)}
        />
      </Paper>
    </div>
  );
}

const useStyles = makeStylesHook({
  containerFillsParent: {
    alignItems: 'flex-start',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    pointerEvents: 'none', // Keep the deck interactive while prompt is shown
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: zIndex.prompt,
  },
  duration: {
    alignSelf: 'flex-end',
    marginBottom: '16px',
  },
  prompt: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '32px',
    maxWidth: '500px',
    padding: '16px',
  },
  message: {
    '& > *:first-child': {
      margin: 0,
    },
  },
});
