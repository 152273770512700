import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import cx from 'classnames';
import debounce from 'lodash/debounce';

import * as WorkflowsApi from 'client/app/api/WorkflowsApi';
import CherryPickerActionsHeader from 'client/app/apps/cherry-picker/CherryPickActionsHeader';
import {
  CherryPickElementSchema,
  DEFAULT_TRANSFER,
  loadCherryPickFromBundle,
  loadCherryPickFromTransferData,
} from 'client/app/apps/cherry-picker/CherryPickApi';
import CherryPickAutoSave from 'client/app/apps/cherry-picker/CherryPickAutosave';
import CherryPickBottomBar from 'client/app/apps/cherry-picker/CherryPickBottomBar';
import { useCherryPickContext } from 'client/app/apps/cherry-picker/CherryPickContext';
import CherryPickSimulationBar from 'client/app/apps/cherry-picker/CherryPickSimulationBar';
import CherryPickTopBar from 'client/app/apps/cherry-picker/cp-file-upload/CherryPickTopBar';
import CherryPickPlatesView from 'client/app/apps/cherry-picker/cp-plate-vis/CherryPickPlatesView';
import CherryPickSettings from 'client/app/apps/cherry-picker/cp-settings/CherryPickSettings';
import CherryPickTable from 'client/app/apps/cherry-picker/cp-table/CherryPickTable';
import { InvalidPicklistError } from 'client/app/apps/cherry-picker/errorsHelper';
import {
  getTopmostTable,
  scrollActiveTableToTop,
} from 'client/app/apps/cherry-picker/layoutHelper';
import { ReadonlyBar } from 'client/app/components/ReadonlyBar';
import CollapsibleNotificationManager from 'client/app/components/SimulationNotification/CollapsibleNotificationManager';
import {
  ContentType,
  MigrateWorkflowMutation,
  WorkflowEditModeEnum,
  WorkflowSourceEnum,
} from 'client/app/gql';
import { ScreenRegistry } from 'client/app/registry';
import SimulationNotificationsContextProvider from 'client/app/state/SimulationNotificationsContext';
import { EditorType } from 'common/types/bundle';
import LinearProgress from 'common/ui/components/LinearProgress';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

enum CherryPickerTabs {
  LOGS,
  SETTINGS,
}
const TABS_INFO: TabsInfo<CherryPickerTabs> = [
  { value: CherryPickerTabs.LOGS, label: 'Picklist' },
  { value: CherryPickerTabs.SETTINGS, label: 'Settings' },
];

const SCROLL_DEBOUNCE_MS = 50;

export type ParameterMode = {
  // Allow users to click "Cancel" and close the dialog.
  onCancel: (dialogOpen: boolean) => void;
  // JSON value of the `Transfer` parameter. Used to fill the UI.
  // Can be undefined, i.e. empty parameter value
  transferData?: CherryPickElementSchema;
  onUpdateTransferData: (transferData: CherryPickElementSchema) => void;
};

type Props = {
  /**
   * We are reusing the same UI for both the App and the parameter
   * editor. The latter hides some parts that don't make sense.
   * The parameterMode needs different properties to be rendered,
   * but to render the normal UI you don't need any of these. Thus,
   * we group all the properties in one, `parameterMode`
   */
  parameterMode?: ParameterMode;
  // The workflow id used to edit a specific Cherry Picker stored in the DB.
  id?: WorkflowId;
};

export default function CherryPickContainer(props: Props) {
  const { parameterMode, id } = props;
  return (
    <SimulationNotificationsContextProvider>
      <CherryPick parameterMode={parameterMode} id={id} />
    </SimulationNotificationsContextProvider>
  );
}

type CherryPickProps = {
  parameterMode?: ParameterMode;
  id?: WorkflowId;
};

function CherryPick(props: CherryPickProps) {
  const classes = useStyles();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { parameterMode, id } = props;
  const {
    setCherryPick,
    setPlatesByName,
    allPlatesByType,
    cherryPick,
    setActiveStep,
    activeStep,
    setPlateOrigins,
    setBundleConfig,
    setWorkflowName,
    setIsReadonly,
    isReadonly,
    isInitialLoading,
  } = useCherryPickContext();
  const snackbarManager = useSnackbarManager();
  // Full workflow fetched from the DB
  const [loadedWorkflow, setLoadedWorkflow] = useState<
    MigrateWorkflowMutation['migrateWorkflow']['workflow'] | null
  >(null);

  const handleTabChange = useCallback((activeTabIndex: number) => {
    logEvent('switch-tab', ScreenRegistry.CHERRY_PICKER, TABS_INFO[activeTabIndex].label);
    setActiveTabIndex(activeTabIndex);
  }, []);

  useEffect(() => {
    if (id && !loadedWorkflow) {
      return;
    }
    // A bundle is only saved if the `TransferData` matches its
    // JSON schema. This means that users need to specify plate types
    // in order for the Workflow to be saved. T2038
    // In the case of a newly created workflow (or in case of `parameterMode`)
    // we will set the Cherry Picker to a dummy transfer, so that the UI
    // can be rendered correctly.
    setCherryPick([DEFAULT_TRANSFER]);
  }, [id, loadedWorkflow, setCherryPick]);

  const migrateWorkflow = WorkflowsApi.useMigrateWorkflow();
  // If we have an /:id in the URL, fetch said workflow.
  useEffect(() => {
    let isOutdateRequest = false;

    if (id) {
      (async () => {
        try {
          const workflow = await migrateWorkflow(id);
          if (!isOutdateRequest) {
            setLoadedWorkflow(workflow);
            setWorkflowName(workflow.name);
            setIsReadonly(
              workflow.editMode !== WorkflowEditModeEnum.ENABLED_LATEST_OWNED_BY_ME,
            );
          }
        } catch (err) {
          snackbarManager.showError(err.message);
        }
      })();
    }

    return () => {
      isOutdateRequest = true;
      setLoadedWorkflow(null);
    };
  }, [
    id,
    migrateWorkflow,
    parameterMode,
    setIsReadonly,
    setWorkflowName,
    snackbarManager,
  ]);

  // Fill in the UI with data from the fetched workflow
  useEffect(() => {
    if (!loadedWorkflow) {
      return;
    }

    try {
      loadCherryPickFromBundle(
        loadedWorkflow.workflow,
        setBundleConfig,
        setCherryPick,
        setWorkflowName,
        setPlatesByName,
        setPlateOrigins,
        allPlatesByType,
      );
    } catch (error) {
      if (error instanceof InvalidPicklistError) {
        // This is expected to happen when the Cherry Picker UI
        // is opened in parameter mode, or it's a brand new Workflow
        // which hasn't been edited yet (i.e. doesn't have `TransferData`)
        // In this case, useEffect will run and set the Cherry Pick to
        // the DEFAULT_TRANSFER
      } else {
        // If it's an error we're not expecting, throw.
        throw Error(error);
      }
    }
  }, [
    allPlatesByType,
    loadedWorkflow,
    setBundleConfig,
    setCherryPick,
    setPlateOrigins,
    setPlatesByName,
    setWorkflowName,
    snackbarManager,
  ]);

  useEffect(() => {
    if (parameterMode?.transferData) {
      loadCherryPickFromTransferData(parameterMode.transferData, {
        setCherryPick,
        setPlatesByName,
        allPlatesByType,
        setPlateOrigins,
      });
    }
  }, [setCherryPick, setPlatesByName, allPlatesByType, parameterMode, setPlateOrigins]);

  const tableContainerRef = useRef<HTMLDivElement>(null);

  // Add extra margin at the bottom of the last table so that it can be
  // fully scrolled to the top
  useLayoutEffect(() => {
    const { current } = tableContainerRef;

    if (!current) {
      return;
    }

    const containerHeight = current.offsetHeight;
    const allTables = current.children;
    const lastTable = current.lastChild as HTMLTableElement | null;
    // When users add a mew plate (hence a new table), we don't want
    // the previous `lastTable` to retain the margin. Thus, we
    // reset the margin of all tables but the last one.
    for (const table of allTables) {
      if (table !== lastTable) {
        (table as HTMLTableElement).style.marginBottom = '0';
      }
    }
    if (lastTable) {
      const marginNeeded = containerHeight - lastTable.offsetHeight;
      lastTable.style.marginBottom = `${marginNeeded}px`;
    }
  });

  // Connect table to plates visualization: scrolling the table will
  // update the page (source plate) and dropdown selection (dest plate).
  // Likewise, selecting a different plate should scroll the table to the
  // right place. I.e. table and plates should always match.
  useLayoutEffect(() => {
    const { current } = tableContainerRef;

    if (!current) {
      return;
    }

    // Each table holds specific "Source to Dest" transfers.
    const allTables = current.children as unknown as HTMLTableElement[];

    // Make sure that the active table is scrolled to the top.
    // Active table depends on selected source and destination.
    const currentTopmostTable = getTopmostTable(current, allTables);
    const selectedSourcePlateIdx = activeStep.source;
    const selectedDestPlateIdx = activeStep.destination;
    scrollActiveTableToTop(
      selectedSourcePlateIdx,
      selectedDestPlateIdx,
      currentTopmostTable,
      allTables,
    );

    const _setTopmostTableOnScroll = debounce(() => {
      const topmostTable = getTopmostTable(current, allTables);
      if (!topmostTable) {
        return;
      }
      // Get data needed to correctly update context
      const sourceIdx = topmostTable.dataset.sourceidx;
      const destIdx = Number(topmostTable.dataset.destidx);

      setActiveStep({ source: Number(sourceIdx), destination: destIdx });
    }, SCROLL_DEBOUNCE_MS);

    current.addEventListener('scroll', _setTopmostTableOnScroll);

    return () => {
      current.removeEventListener('scroll', _setTopmostTableOnScroll);
    };
  });
  const handleCopyWorkflow = WorkflowsApi.useCopyWorkflowAndNavigate(
    loadedWorkflow?.id,
    loadedWorkflow?.version,
    EditorType.CHERRY_PICKER,
  );

  const elementSetID = loadedWorkflow?.workflow.elementSetId;

  // In the CherryPicker App we have 3 rows:
  // PlateVisualization, CherryPickTable and BottomBar.
  // However, using the CherryPicker in the parameter editor, or in read only workflows, we have 4 rows:
  // TopBar (with Kebab Menu) OR ReadOnly Bar, PlateVisualization, CherryPickTable and BottomBar
  const showAdjustedGridLayout = !!parameterMode || isReadonly;

  return (
    <>
      {!isReadonly && <CherryPickAutoSave loadedWorkflow={loadedWorkflow} />}
      {!parameterMode && !loadedWorkflow && <LinearProgress />}
      {!isInitialLoading && cherryPick.length > 0 && (
        <div
          className={cx(classes.container, {
            [classes.adjustedGridLayout]: showAdjustedGridLayout,
          })}
        >
          {loadedWorkflow && isReadonly ? (
            <ReadonlyBar
              authorName={loadedWorkflow.createdBy.displayName}
              workflowId={loadedWorkflow.id}
              editMode={loadedWorkflow.editMode}
              onCopyWorkflow={handleCopyWorkflow}
              currentEditorType={WorkflowSourceEnum.CHERRY_PICKER}
              workflowEditorType={loadedWorkflow.source}
              workflowContentSource={ContentType.USER_GENERATED}
              parentWorkflowID={loadedWorkflow.parentWorkflowID ?? null}
            />
          ) : (
            parameterMode && <CherryPickTopBar />
          )}
          <CherryPickPlatesView />
          <div className={classes.scrollable}>
            <TabContainer>
              <Tabs
                activeTab={activeTabIndex}
                onChangeTab={handleTabChange}
                tabsInfo={TABS_INFO}
              />
              {!parameterMode && loadedWorkflow && (
                <CherryPickerActionsHeader workflowId={loadedWorkflow.id} />
              )}
            </TabContainer>
            <div
              hidden={activeTabIndex !== 0}
              className={classes.tableContainer}
              ref={tableContainerRef}
            >
              <CherryPickTable />
            </div>
            <div hidden={activeTabIndex !== 1}>
              <CherryPickSettings parameterMode={parameterMode} />
            </div>
          </div>
          {!parameterMode ? (
            <Paper>
              <CherryPickSimulationBar />
            </Paper>
          ) : (
            <Paper>
              <CherryPickBottomBar parameterMode={parameterMode} />
            </Paper>
          )}
        </div>
      )}
      {elementSetID && loadedWorkflow && (
        <CollapsibleNotificationManager
          elementSetID={elementSetID}
          workflowId={loadedWorkflow.id}
        />
      )}
    </>
  );
}

const useStyles = makeStylesHook({
  container: {
    display: 'grid',
    gridTemplateRows: '4fr 10fr 1fr',
    height: '100%',
    overflow: 'hidden',
  },
  adjustedGridLayout: {
    gridTemplateRows: '1fr 4fr 10fr 1fr',
  },
  scrollable: {
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    contain: 'strict',
    height: '100%',
    overflow: 'auto',
  },
  // Make the notifications be on top of the Cherry Pick table
  notificationsContainer: {
    '& > div': {
      zIndex: 2,
    },
  },
});

const TabContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
