export type CSVColumn<Data> = {
  title: string;
  getValue: (data: Data) => string;
};

export function toCSV<Data>(
  columnConfig: CSVColumn<Data>[],
  rows: readonly Data[],
): string {
  let output = columnConfig.map(c => `"${c.title}"`).join(',') + '\n';
  for (const row of rows) {
    output += columnConfig.map(c => `"${c.getValue(row)}"`).join(',') + '\n';
  }
  return output;
}
