import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import clamp from 'lodash/clamp';
import { mergeRefs } from 'react-merge-refs';
import useResizeObserver from 'use-resize-observer';

import {
  getParallelTransferStageNameForStep,
  hasParallelTransferStage,
  isParallelTransfer,
} from 'common/lib/mix';
import { MixPreviewStep, ParallelTransferStep } from 'common/types/mixPreview';
import Colors from 'common/ui/Colors';
import { useSliderDrag } from 'common/ui/components/simulation-details/StepSlider/components/StepSliderBar';
import {
  KeyPointItem,
  ParallelTransferStageBreakpoint,
  Slider,
  SliderCursor,
  SliderCursorInner,
  SliderProgress,
} from 'common/ui/components/simulation-details/StepSlider/components/styles';
import {
  getKeyPointColor,
  KEY_POINT_WIDTH,
  SLIDER_CURSOR_WIDTH,
} from 'common/ui/components/simulation-details/StepSlider/helpers';
import { KeyPoint } from 'common/ui/components/simulation-details/StepSlider/types';
import useThrottle from 'common/ui/hooks/useThrottle';

type Props = {
  appliedSteps: number;
  steps: MixPreviewStep[];
  onStepChange: (stepCount: number) => void;
};

export default function ParallelTransferStageSlider({
  appliedSteps: appliedStepsOnMainSlider,
  steps: mainSliderSteps,
  onStepChange: onMainSliderStepChange,
}: Props) {
  const { steps, appliedSteps, onStepChange } = useRelevantSteps(
    appliedStepsOnMainSlider,
    mainSliderSteps,
    onMainSliderStepChange,
  );

  const slider = useSliderUpdates(steps, onStepChange);
  const parallelTransferStages = useParallelTransferStages(steps, slider.width);

  const { isDragging, ...pointerEvents } = useSliderDrag(slider.handlers);

  const { setPositionTo } = slider.handlers;
  useLayoutEffect(() => {
    /**
     * This effect is necessary to anchor the slider cursor to some specific step position
     * and prevent cursor from positioning in-between simulation steps
     */
    if (!isDragging) {
      setPositionTo(appliedSteps);
    }
  }, [appliedSteps, isDragging, setPositionTo]);

  const highlightColor = parallelTransferStages.keyPoints.some(
    keyPoint => keyPoint.step === appliedSteps,
  )
    ? Colors.KEY_POINT_DEFAULT
    : undefined;

  const stepIndex = Math.min(Math.max(0, appliedSteps), steps.length - 1);
  const stageNameRef = useCurrentParallelTransferStageName(stepIndex, steps);
  const stepWidth = slider.width / steps.length;
  const progress = stepIndex * stepWidth - stepWidth;

  return (
    <Container>
      <Slider
        id="parallel-transfer-slider"
        ref={slider.ref}
        dragging={isDragging}
        {...pointerEvents}
      >
        <StageLabel
          style={{
            left: progress < slider.width / 2 ? slider.position : 'unset',
            right:
              progress > slider.width / 2
                ? slider.width - slider.position - stepWidth / 2
                : 'unset',
          }}
        >
          {stageNameRef.current}
        </StageLabel>
        {parallelTransferStages.breakpoints}
        {parallelTransferStages.keyPoints.map(keyPoint => (
          <KeyPointItem
            key={keyPoint.step}
            style={{
              left: (keyPoint.step * slider.width) / steps.length + KEY_POINT_WIDTH / 2,
              backgroundColor: getKeyPointColor(keyPoint),
            }}
          />
        ))}
        <SliderCursor highlightColor={highlightColor} style={{ left: slider.position }}>
          <SliderCursorInner />
        </SliderCursor>
        <SliderProgress style={{ width: slider.position }} />
      </Slider>
    </Container>
  );
}

function useRelevantSteps(
  mainSliderStepCursor: number,
  mainSliderSteps: MixPreviewStep[],
  onMainSliderStepChange: (step: number) => void,
) {
  return useMemo(() => {
    const firstStepOfFirstParallelTransferStage = mainSliderSteps.findIndex(
      hasParallelTransferStage,
    );
    const lastStepOfLastParallelTransferStage =
      mainSliderSteps.length -
      [...mainSliderSteps].reverse().findIndex(hasParallelTransferStage);

    const steps = mainSliderSteps.slice(
      Math.max(0, firstStepOfFirstParallelTransferStage),
      Math.min(mainSliderSteps.length, lastStepOfLastParallelTransferStage),
    );
    const appliedSteps = mainSliderStepCursor - firstStepOfFirstParallelTransferStage;

    const onStepChange = (stepCount: number) => {
      onMainSliderStepChange(stepCount + firstStepOfFirstParallelTransferStage);
    };

    return { steps, appliedSteps, onStepChange };
  }, [mainSliderStepCursor, onMainSliderStepChange, mainSliderSteps]);
}

function useParallelTransferStages(steps: MixPreviewStep[], sliderWidth: number) {
  return useMemo(() => {
    const stepWidth = sliderWidth / steps.length;

    const breakpoints: JSX.Element[] = [];
    const keyPoints: KeyPoint[] = [];

    let prevParallelTransferStageName: string | null = null;

    for (let index = 0; index < steps.length; ++index) {
      const step = steps[index];

      if (!isParallelTransfer(step)) continue;

      const parallelTransferStageName = getParallelTransferStageNameForStep(step);

      if (parallelTransferStageName) {
        keyPoints.push({ step: index + 1, kind: 'stage' });

        if (parallelTransferStageName !== prevParallelTransferStageName) {
          prevParallelTransferStageName = parallelTransferStageName;
          breakpoints.push(
            <ParallelTransferStageBreakpoint key={index} position={index * stepWidth} />,
          );
        }
      }
    }

    return { breakpoints, keyPoints };
  }, [sliderWidth, steps]);
}

function useCurrentParallelTransferStageName(stepIndex: number, steps: MixPreviewStep[]) {
  const stageNameRef = useRef<string | undefined>(
    getParallelTransferStageNameForStep(
      steps.find(isParallelTransfer) as ParallelTransferStep,
    ),
  );

  let step: MixPreviewStep;

  if (stepIndex > 0) {
    let index = stepIndex;
    step = steps[index];

    while (!isParallelTransfer(step)) {
      index -= 1;
      step = steps[index];
    }
  } else {
    step = steps.find(isParallelTransfer) as ParallelTransferStep;
  }

  stageNameRef.current = getParallelTransferStageNameForStep(step);
  return stageNameRef;
}

export function useSliderUpdates(
  steps: MixPreviewStep[],
  onStepChange: Props['onStepChange'],
) {
  const [position, setPosition] = useState(0);

  const elementRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  const { ref: observerRef } = useResizeObserver({
    onResize: size => size.width && setWidth(size.width),
  });
  const mergedRef = mergeRefs([observerRef, elementRef]);

  const maxStep = steps.length;
  const stepWidth = width / maxStep;

  const updateDragPosition = (pageX: number) => {
    const sliderElement = elementRef.current;

    if (!sliderElement) return;

    const slider = sliderElement.getBoundingClientRect();
    const dragPos = clamp(
      pageX - slider.x - SLIDER_CURSOR_WIDTH / 2,
      0,
      width + KEY_POINT_WIDTH,
    );
    setPosition(dragPos);
  };

  const setCursorToDragPosition = (position: number) => {
    const newStep = clamp(Math.round(position / stepWidth), 0, maxStep);
    onStepChange(newStep);
  };
  const setPositionTo = useCallback(
    (appliedSteps: number) => {
      setPosition(clamp(appliedSteps * stepWidth, 0, width + KEY_POINT_WIDTH));
    },
    [stepWidth, width],
  );

  const updateCursorOnDrag = useThrottle(() => setCursorToDragPosition(position), 100);
  const updateCursorOnWheel = (deltaSign: -1 | 1) => {
    const newPosition = clamp(position + deltaSign * stepWidth, 0, width);
    setCursorToDragPosition(newPosition);
  };

  return {
    ref: mergedRef,
    position,
    width,
    handlers: {
      setPositionTo,
      updateCursorOnDrag,
      updateCursorOnWheel,
      updateDragPosition,
    },
  };
}

const Container = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',

  padding: theme.spacing(5, 7),
  borderRadius: theme.spacing(2),

  width: '80vw',
  height: 64,
  userSelect: 'none',

  '& #parallel-transfer-slider': {
    position: 'relative',
    width: '100%',
  },
}));

const StageLabel = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: -28,
  fontWeight: 600,
  whiteSpace: 'nowrap',

  background: Colors.WHITE,
  padding: theme.spacing(2),

  '&:hover': {
    zIndex: 1,
  },
}));
