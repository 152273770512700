import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Popover from 'common/ui/components/Popover';
import { type PositionGroupRect } from 'common/ui/components/simulation-details/mix/DeckLayout';

const HEADING_HEIGHT = 52;

type Props = {
  group: PositionGroupRect;
};

export default function PositionGroupView({ group }: Props) {
  return (
    <>
      <Heading
        style={{
          top: group.absolutePosInDeckPixels.top - HEADING_HEIGHT,
          left: group.absolutePosInDeckPixels.left,
          width: group.absolutePosInDeckPixels.width,
          backgroundColor: group.headingColour,
        }}
      >
        <Typography variant="h2">{group.label}</Typography>
        {group.description && (
          <Popover
            title={
              <Typography variant="caption" fontWeight={400} color="textPrimary">
                {group.description}
              </Typography>
            }
            placement="bottom"
          >
            <Info />
          </Popover>
        )}
      </Heading>
      <Container
        style={{
          ...group.absolutePosInDeckPixels,
          border: `1px solid ${group.headingColour}`,
        }}
      />
      ;
    </>
  );
}

const Container = styled('main')(({ theme }) => ({
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  borderBottomLeftRadius: theme.spacing(2),
  borderBottomRightRadius: theme.spacing(2),
}));

const Heading = styled('header')(({ theme }) => ({
  position: 'absolute',
  height: HEADING_HEIGHT,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderTopLeftRadius: theme.spacing(2),
  borderTopRightRadius: theme.spacing(2),
}));

const Info = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.background.paper,
  height: 16,
  width: 16,
  marginLeft: theme.spacing(3),
}));
