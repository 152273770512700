import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const LiquidPolicyIcon = React.memo<SvgIconProps>(function LiquidPolicyIcon(
  props,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg fill="none" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.827 7.546v.004h.006v10.555l4.167 2.2 4.167-2.2V7.55h.005v-.003h1.963V6.58c.02.115.032.233.032.354v.612h1.45v1.722h-1.45v8.836a2 2 0 01-1.067 1.768l-4.166 2.2a2 2 0 01-1.868 0l-4.166-2.2a2 2 0 01-1.067-1.768V9.268h-1.45V7.546h3.444z"
          fill="currentColor"
        />
        <path
          d="M9.25 11.75h5.5v5.534L12 18.75l-2.75-1.466V11.75zM14.25 6.23A2.26 2.26 0 0112 8.5a2.26 2.26 0 01-2.25-2.27C9.75 4.976 12 1.5 12 1.5s2.25 3.476 2.25 4.73z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
