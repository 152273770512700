import React, { PropsWithChildren } from 'react';

import cx from 'classnames';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type DimensionSize = 's' | 'm' | 'l' | 'vs' | 'vm' | 'vl' | 'bs' | 'bm' | 'bl';

// Example:
// <UIBox margin="s" padding="vm">
// This means small margin around all sides, and medium padding vertically.
// Valid values for padding and margin are:
// - All around: s, m, l
// - Vertical: vs, vm, vl
// - Bottom only: bs, bm, bl
export default function UIBox({
  className,
  children,
  padding,
  margin,
  ...otherProps
}: PropsWithChildren<{
  className?: string | null;
  padding?: DimensionSize;
  margin?: DimensionSize;
}>) {
  const paddingClasses = usePaddingStyles();
  const paddingClass = padding ? paddingClasses[padding] : null;

  const marginClasses = useMarginStyles();
  const marginClass = margin ? marginClasses[margin] : null;

  return (
    <div className={cx(paddingClass, marginClass, className)} {...otherProps}>
      {children}
    </div>
  );
}

const usePaddingStyles = makeStylesHook({
  s: { padding: '2px' },
  m: { padding: '8px' },
  l: { padding: '16px' },
  vs: { padding: '2px 0' },
  vm: { padding: '8px 0' },
  vl: { padding: '16px 0' },
  bs: { padding: '0 0 2px 0' },
  bm: { padding: '0 0 8px 0' },
  bl: { padding: '0 0 16px 0' },
});

const useMarginStyles = makeStylesHook({
  s: { margin: '2px' },
  m: { margin: '8px' },
  l: { margin: '16px' },
  vs: { margin: '2px 0' },
  vm: { margin: '8px 0' },
  vl: { margin: '16px 0' },
  bs: { margin: '0 0 2px 0' },
  bm: { margin: '0 0 8px 0' },
  bl: { margin: '0 0 16px 0' },
});
