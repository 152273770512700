import React from 'react';

import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/**
 * This bar is shown when an experiment is not editable (e.g. it was created via the Example Gallery).
 * It shows some information and allows users to copy the workflow with one click.
 */
export const ExperimentReadonlyBar = React.memo(() => {
  const classes = useStyles();

  return (
    <div className={classes.bar}>
      <Typography variant="h6" color="textPrimary">
        This is an example created by Synthace, so editing is disabled. You can edit
        experiments you or your team create.
      </Typography>
    </div>
  );
});

const useStyles = makeStylesHook(theme => ({
  bar: {
    alignItems: 'center',
    padding: theme.spacing(3),
    background: Colors.INFO_LIGHT,
    borderBottom: `1px ${Colors.GREY_30} solid`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
}));
