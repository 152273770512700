import {
  BY_COLUMN_96_QUADRANT_IN_384_WELLS,
  BY_ROW_96_QUADRANT_IN_384_WELLS,
} from 'client/app/components/Parameters/PlateContents/lib/wells384Patterns';
import {
  formatWellPosition,
  getColumnNumberFromWellPosition,
  getRowNumberFromWellPosition,
} from 'common/lib/format';

export type WellIterationPattern =
  | 'As Selected'
  | 'Typewriter'
  | 'Snake'
  | '96 well quadrant'
  | '4 well grid';
export type WellIterationOrder = 'As Selected' | 'By Row' | 'By Column';

// We sometimes need to access these parameters specifically to handle well patterns and orders.
// TODO - Could possible be moved to getPlateContentParams to be part of plate content params in future.
export const WELL_ITERATION_PATTERN_PARAMETER_NAME = 'WellPattern';
export const WELL_ITERATION_ORDER_PARAMETER_NAME = 'WellIteration';

/**
 *
 * Return the wells that should be selected based on the pattern and order specified.
 */
export function generateWellsBasedOnPattern(
  wells: string[],
  pattern: WellIterationPattern,
  order: WellIterationOrder,
  numberOfWellsOnPlate: number,
): string[] {
  switch (pattern) {
    case 'Typewriter':
      return generateWellsBasedOnTypewriter(wells, order);
    case 'Snake':
      return generateWellsBasedOnSnake(wells, order);
    case '96 well quadrant':
      if (numberOfWellsOnPlate !== 384 || wells.length !== 384) {
        throw new Error(`${pattern} requires all wells selected on a 384-well plate.`);
      }
      return generateWellsIn96QuadrantFor384WellPlate(order);
    case '4 well grid':
      if (!areWellsInContiguousBlock(wells)) {
        throw new Error(`${pattern} requires wells to be in a contiguous block.`);
      }
      return generateWellsIn4WellGrid(wells, order);
    case 'As Selected':
    default:
      return wells;
  }
}

type WellLocation = { row: number; col: number };

/**
 * Generate a new set of wells in a typewriter format.
 *
 * If By Row, it goes left to right from the first row and then repeats in the following rows.
 * 1 -> 2 -> 3 -> 4
 * 5 -> 6 -> 7 -> 8
 * 9 -> 10 -> 11 -> 12
 *
 * If By Column, the order goes down a column and then repeats in the following columns.
 * 1 - 5 - 9
 * 2 - 6 - 10
 * 3 - 7 - 11
 * 4 - 8 - 12
 */
function generateWellsBasedOnTypewriter(wells: string[], order: WellIterationOrder) {
  // We cannot rely on built in alphabetical sort because A2 would be ordered before A10.
  // For By Row, if you are in the same row, sort ascending by column first, then row.
  const sortFunction =
    order === 'By Row'
      ? (a: WellLocation, b: WellLocation) =>
          a.row === b.row ? a.col - b.col : a.row - b.row
      : (a: WellLocation, b: WellLocation) =>
          a.col === b.col ? a.row - b.row : a.col - b.col;
  return wells
    .map(wellLocation => ({
      col: getColumnNumberFromWellPosition(wellLocation),
      row: getRowNumberFromWellPosition(wellLocation),
    }))
    .sort(sortFunction)
    .map(location => formatWellPosition(location.row, location.col));
}

/**
 * Generate a new set of wells in a snake format.
 *
 * The snake pattern will always start from the top-left most well in the given wells.
 * The pattern will then run left to right from this well and continue
 * to alternate between right to left and left to right.
 *
 * If columns or rows are skipped, the snake pattern will continue at the next non-empty row or column.
 *
 * Rows and Columns are counted from 0.
 *
 * If By Row:
 *
 * 1 -> 2 -> 3 -> 4
 * 8 <- 7 <- 6 <- 5
 * 9 -> 10 -> 11 -> 12
 *
 *
 * If By Column:
 *
 * 1 - 8 - 9
 * 2 - 7 - 10
 * 3 - 6 - 11
 * 4 - 5 - 12
 */
function generateWellsBasedOnSnake(wells: string[], order: WellIterationOrder) {
  const wellsWithCoordinates = wells.map(wellLocation => ({
    col: getColumnNumberFromWellPosition(wellLocation),
    row: getRowNumberFromWellPosition(wellLocation),
  }));

  const rowOrColumnNumbers =
    order === 'By Row'
      ? wellsWithCoordinates.map(well => well.row)
      : wellsWithCoordinates.map(well => well.col);
  const uniqueRowOrColumnNumbers = [...new Set(rowOrColumnNumbers)].sort((a, b) => a - b);

  // We want to establish a mapping of the unique row/col numbers and the comparator
  // function to use for these. For even rows, we want to sort the numbers from low to high, for
  // odd rows, high to low.
  const comparatorFunctionMap = new Map<number, (a: number, b: number) => number>();
  uniqueRowOrColumnNumbers.forEach((num, idx) => {
    comparatorFunctionMap.set(num, (a: number, b: number) => {
      // Check if idx is even or odd.
      return idx % 2 === 0 ? a - b : b - a;
    });
  });

  const sortFunction =
    order === 'By Row'
      ? (a: WellLocation, b: WellLocation) => {
          const comparator = comparatorFunctionMap.get(a.row)!;
          return a.row === b.row ? comparator(a.col, b.col) : a.row - b.row;
        }
      : (a: WellLocation, b: WellLocation) => {
          const comparator = comparatorFunctionMap.get(a.col)!;
          return a.col === b.col ? comparator(a.row, b.row) : a.col - b.col;
        };

  return wellsWithCoordinates
    .sort(sortFunction)
    .map(location => formatWellPosition(location.row, location.col));
}

/**
 * Generates 96 well quadrants on a 384 well plate. This means that a set of 96-wells
 * are effectively "stamped out" onto a 384-well plate 4 times, each time offsetting by one well.
 *
 * The quadrants will begin at wells:
 * A1, B1, A2, B2 - for WellIterationOrder By Column
 * A1, A2, B1, B2 - for WellIterationOrder By Row
 *
 * Below are examples of a top-left subsection of a 384 well plate to demonstrate how
 * this pattern is applied. There are 384 well on a plate, with 16 rows and 24 columns
 *
 * If By Row:
 *
 * 1   - 97  - 2   - 98
 * 193 - 289 - 194 - 290
 * 13  - 109 - 14  - 110
 * 205 - 301 - 206 - 302
 *
 *
 * If By Column:
 *
 * 1  - 193  - 9   - 201
 * 97 - 289  - 105 - 297
 * 2  - 194  - 10  - 202
 * 98 - 290  - 106 - 298
 */
function generateWellsIn96QuadrantFor384WellPlate(order: WellIterationOrder) {
  return order === 'By Row'
    ? BY_ROW_96_QUADRANT_IN_384_WELLS
    : BY_COLUMN_96_QUADRANT_IN_384_WELLS;
}

/**
 * Returns true if the given wells array are in a single contiguous block:
 * X - well selected in wells array
 * 0 - well not selected in wells array
 *
 *    1 2 3 4
 * A  O X X X
 * B  O X X X
 * C  O X X X
 * D  O O O O
 *
 * But not if there are missing wells in the block:
 *
 *    1 2 3 4
 * A  O X X X
 * B  O X 0 X
 * C  O X X X
 * D  O O O O
 */
export function areWellsInContiguousBlock(wells: string[]): boolean {
  const { minRow, minCol, maxRow, maxCol } = getMinAndMaxColsAndRows(wells);

  // The rows and columns count from 0, we we need to adjust this by 1
  const wellSelectionWidth = maxCol - minCol + 1;
  const wellSelectionHeight = maxRow - minRow + 1;

  return wellSelectionWidth * wellSelectionHeight === wells.length;
}

/**
 *
 * Generates wells in a grid of well quadrants (i.e. 4x4 wells). The well selection must be
 * of even height and width in a contiguous block, otherwise we return the wells unmodified.
 *
 * If By Row:
 *
 * 1  -> 2  - 5  -> 6
 * 3  -> 4  - 7  -> 8
 * 9  -> 10 - 13 -> 14
 * 11 -> 12 - 15 -> 16
 *
 * If By Column:
 *
 * 1 - 3 - 9  - 11
 * 2 - 4 - 10 - 12
 * 5 - 7 - 13 - 15
 * 6 - 8 - 14 - 16
 */
function generateWellsIn4WellGrid(wells: string[], order: WellIterationOrder): string[] {
  const { minRow, minCol, maxRow, maxCol } = getMinAndMaxColsAndRows(wells);

  // The rows and columns count from 0, we we need to adjust this by 1
  const wellSelectionWidth = maxCol - minCol + 1;
  const wellSelectionHeight = maxRow - minRow + 1;

  if (wellSelectionWidth % 2 !== 0 || wellSelectionHeight % 2 !== 0) {
    throw new Error('4 well grid requires an even number of rows and columns selected.');
  }

  const numGrids = (wellSelectionWidth * wellSelectionHeight) / 4;

  const newWells = [];
  const byRow = order === 'By Row';

  let currCol = minCol;
  let currRow = minRow;

  const offsetsPerQuadrant: {
    [quadrant: number]: { rowOffset: number; colOffset: number };
  } = {
    1: { rowOffset: 0, colOffset: 0 },
    2: { rowOffset: byRow ? 0 : 1, colOffset: byRow ? 1 : 0 },
    3: { rowOffset: byRow ? 1 : 0, colOffset: byRow ? 0 : 1 },
    4: { rowOffset: 1, colOffset: 1 },
  };

  for (let gridNumber = 1; gridNumber <= numGrids; gridNumber++) {
    for (let quadrant = 1; quadrant <= 4; quadrant++) {
      const offsets = offsetsPerQuadrant[quadrant];
      newWells.push(
        formatWellPosition(currRow + offsets.rowOffset, currCol + offsets.colOffset),
      );
    }
    // We adjust the current row or col. If we get to the end, then we move to the next row/col
    if (byRow) {
      currCol += 2;
      if (currCol > maxCol) {
        currCol = minCol;
        currRow += 2;
      }
    } else {
      currRow += 2;
      if (currRow > maxRow) {
        currRow = minRow;
        currCol += 2;
      }
    }
  }

  return newWells;
}

/**
 * Returns the minimum and maximum row and column number for the given set of wells.
 * Row and column number are counted from 0.
 */
function getMinAndMaxColsAndRows(wells: string[]): {
  minRow: number;
  maxRow: number;
  minCol: number;
  maxCol: number;
} {
  const wellsWithCoordinates = wells.map(wellLocation => ({
    col: getColumnNumberFromWellPosition(wellLocation),
    row: getRowNumberFromWellPosition(wellLocation),
  }));

  const rows = wellsWithCoordinates.map(well => well.row);
  const cols = wellsWithCoordinates.map(well => well.col);

  return {
    minRow: Math.min(...rows),
    minCol: Math.min(...cols),
    maxRow: Math.max(...rows),
    maxCol: Math.max(...cols),
  };
}
