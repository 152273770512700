import React, {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

export { ManualInterventionProvider as default, useManualInterventions };

const ManualInterventionContext = createContext<{
  showAll: boolean;
  toggle: () => void;
}>({
  showAll: false,
  toggle: () => {
    throw new Error('Not implemented');
  },
});

const useManualInterventions = () => useContext(ManualInterventionContext);

type Props = {
  children: ReactNode | ReactNode[];
};

function ManualInterventionProvider({ children }: Props) {
  const [showAll, setShowAll] = useState(false);
  const toggle = useCallback(() => {
    setShowAll(flag => !flag);
  }, []);
  return (
    <ManualInterventionContext.Provider
      value={{
        showAll,
        toggle,
      }}
    >
      {children}
    </ManualInterventionContext.Provider>
  );
}
