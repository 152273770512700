import React, { forwardRef, useState } from 'react';

import Delete from '@mui/icons-material/Delete';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { PanelContent } from 'client/app/apps/workflow-builder/panels/Panel';
import { TIMELINE_CONTAINER_STAGES_GAP } from 'client/app/apps/workflow-builder/TimeLine';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import stopPropagation from 'common/lib/stopPropagation';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import Keys from 'common/ui/lib/keyboard';

type StageCardProps = {
  id: string;
  name: string;
  index: number;
  deviceImageURL: string;
  deviceType: string;
  selected: boolean;
  onSelect: (id: string | undefined) => void;
  disabled?: boolean;
  isOnlyStage: boolean;
};

export const STAGE_CARD_WIDTH = 334;

function StageCard(props: StageCardProps, ref: React.Ref<HTMLDivElement>) {
  const {
    id,
    name,
    index,
    deviceImageURL,
    deviceType,
    selected,
    onSelect,
    disabled,
    isOnlyStage,
  } = props;

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const handleContainerClick = () => {
    onSelect(id);
  };

  const handleContainerKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === Keys.ENTER) {
      handleContainerClick();
    }
  };

  const handleStopPropagation = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (selected) {
      event.stopPropagation();
    }
  };

  const { activePanel, selectedStageId, mode } = useWorkflowBuilderSelector(
    state => state,
  );
  const dispatch = useWorkflowBuilderDispatch();

  const isSettingsForStageOpen =
    selectedStageId === id && activePanel === 'WorkflowSettings';

  const handleOpenSettingsPanel = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleStopPropagation(event);
    const payload: PanelContent = isSettingsForStageOpen ? undefined : 'WorkflowSettings';
    dispatch({ type: 'setActivePanel', payload: payload });
  };

  return (
    <Container
      id={id}
      ref={ref}
      onClick={handleContainerClick}
      onKeyDown={handleContainerKeyDown}
      selected={selected}
      role="button"
      tabIndex={0}
    >
      <TouchRipple />
      <Index selected={selected}>
        <Typography>{index + 1}</Typography>
      </Index>
      <Content>
        <Info>
          <DeviceImage
            src={deviceImageURL}
            alt={`Image of the ${deviceType} device assigned to this stage`}
          />
          <StageName variant="subtitle2">{name}</StageName>
          <DeviceType variant="caption">{deviceType}</DeviceType>
        </Info>
        <Controls>
          <StyledIconButton
            size="xsmall"
            icon={
              <SettingsOutlinedIcon
                color={isSettingsForStageOpen ? 'primary' : 'inherit'}
              />
            }
            onClick={handleOpenSettingsPanel}
          />
          <StyledIconButton
            size="xsmall"
            icon={<KeyboardArrowDownSharpIcon />}
            onClick={e => {
              e.stopPropagation();
              setMenuAnchorEl(e.currentTarget);
            }}
            onMouseDown={handleStopPropagation}
            disabled={disabled}
          />
          <Menu
            open={menuOpen}
            anchorEl={menuAnchorEl}
            onClose={() => {
              setMenuAnchorEl(null);
            }}
            onClick={stopPropagation}
          >
            <MenuItemWithIcon
              icon={<Delete />}
              text="Delete"
              onClick={() => {
                dispatch({ type: 'deleteStage', payload: id });
              }}
              disabled={disabled || isOnlyStage || mode === 'DOE'}
            />
          </Menu>
        </Controls>
      </Content>
    </Container>
  );
}

export default forwardRef<HTMLDivElement, StageCardProps>(StageCard);

const Container = styled('div', {
  shouldForwardProp: propName => propName !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  width: '280px',
  height: '52px',
  display: 'grid',
  gridTemplateColumns: '[index] 34px [content] 246px',
  boxShadow: selected ? 'none' : theme.shadows[1],
  borderRadius: '4px',
  outline: selected ? `1px solid ${theme.palette.primary.main}` : 'none',
  '&:hover': {
    outline: `1px solid ${
      selected ? theme.palette.primary.main : theme.palette.primary.light
    }`,
    cursor: 'pointer',
  },
  '&:focus': {
    outline: `1px solid ${
      selected ? theme.palette.primary.main : theme.palette.primary.light
    }`,
  },
  scrollMargin: `${TIMELINE_CONTAINER_STAGES_GAP}px`, // Matches the gap in TimeLine to ensure we grab entries as they pass through IntersectionObserver
  '&:first-of-type': {
    marginLeft: theme.spacing(5),
  },
  '&:last-of-type': {
    marginRight: theme.spacing(6),
  },
}));

const Index = styled('div', {
  shouldForwardProp: propName => propName !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  gridColumn: 'index',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px',
  backgroundColor: selected ? theme.palette.primary.main : Colors.GREY_20,
  color: selected ? Colors.GREY_0 : theme.palette.text.secondary,
}));

const Content = styled('div')(({ theme }) => ({
  gridColumn: 'content',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: Colors.GREY_0,
  borderTopRightRadius: '4px',
  borderBottomRightRadius: '4px',
  padding: theme.spacing(3, 5),
}));

const Info = styled('div')({
  display: 'grid',
  justifyItems: 'start',
  alignItems: 'center',
  gridTemplateAreas: "'deviceImage stageName' 'deviceImage deviceType'",
});

const Controls = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const DeviceImage = styled('img')(({ theme }) => ({
  gridArea: 'deviceImage',
  marginRight: theme.spacing(3),
  width: '30px',
  maxHeight: '30px',
}));

const StageName = styled(TypographyWithTooltip)(({ theme }) => ({
  gridArea: 'stageName',
  color: theme.palette.text.primary,
  maxWidth: '120px', // Allows overflow to trigger tooltip
}));

const DeviceType = styled(TypographyWithTooltip)(({ theme }) => ({
  gridArea: 'deviceType',
  color: theme.palette.text.secondary,
  maxWidth: '120px', // Allows overflow to trigger tooltip
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
