import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const NewExperimentsIcon = React.memo<SvgIconProps>(function ExperimentsIcon(
  props,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.9939 13C12.9939 13.5523 12.5462 14 11.9939 14C11.4416 14 10.9939 13.5523 10.9939 13C10.9939 12.4477 11.4416 12 11.9939 12C12.5462 12 12.9939 12.4477 12.9939 13Z"
          fill="currentColor"
        />
        <path
          d="M9.9939 17C10.5462 17 10.9939 16.5523 10.9939 16C10.9939 15.4477 10.5462 15 9.9939 15C9.44161 15 8.9939 15.4477 8.9939 16C8.9939 16.5523 9.44161 17 9.9939 17Z"
          fill="currentColor"
        />
        <path
          d="M14.9939 18C14.9939 18.5523 14.5462 19 13.9939 19C13.4416 19 12.9939 18.5523 12.9939 18C12.9939 17.4477 13.4416 17 13.9939 17C14.5462 17 14.9939 17.4477 14.9939 18Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9941 2V4H15.8721V11.215L19.6704 17.3378L19.6977 17.3989C20.6063 19.4242 19.2971 22 16.9355 22H7.0523C4.69077 22 3.3815 19.4242 4.2901 17.3989L4.31747 17.3378L8.11575 11.215V4H6.99414V2H16.9941ZM10.1158 4V7H13.8721V4H10.1158ZM10.1158 9V11.785L6.09185 18.2715C5.72582 19.1768 6.39218 20 7.0523 20H16.9355C17.5956 20 18.262 19.1768 17.896 18.2715L13.8721 11.785V9H10.1158Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
