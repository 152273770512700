import { useEffect } from 'react';

import { useQuery } from '@apollo/client';

import { QUERY_EXPERIMENT } from 'client/app/apps/experiments/gql/queries';
import { QUERY_EXPERIMENT_WORK_TREE } from 'client/app/apps/work-tree/queries';
import { ExperimentQuery, WorkTreeBlockFragmentFragment } from 'client/app/gql';

const WORKTREE_POLL_INTERVAL_MS = 20000;

type WorkTreeHookResult =
  | { status: 'loading' }
  | { status: 'error'; error: unknown }
  | {
      status: 'success' | 'reloading';
      blocks: readonly WorkTreeBlockFragmentFragment[];
      experiment: ExperimentQuery['experiment'];
      refetchWorkTree: () => Promise<void>;
    };

/**
 * Prepares a tree of entities (workflows, data, analyses, etc) from a start entity.
 */
export function useWorkTree(experimentId: ExperimentId): WorkTreeHookResult {
  const {
    data,
    loading: workTreeLoading,
    error: workTreeError,
    refetch,
    startPolling,
    stopPolling,
  } = useQuery(QUERY_EXPERIMENT_WORK_TREE, {
    variables: {
      experimentId,
    },
    pollInterval: WORKTREE_POLL_INTERVAL_MS,
    // The worktree may have changed while the user was navigated away from it (e.g. a
    // workflow in the worktree was simulated)
    fetchPolicy: 'cache-and-network',
  });

  const handleRefetchWorkTree = async () => {
    await refetch();
  };

  const {
    data: experimentData,
    loading: experimentLoading,
    error: experimentError,
  } = useQuery(QUERY_EXPERIMENT, {
    variables: { id: experimentId },
  });

  useEffect(() => {
    // To prevent overloading the server when a user has lots of experiment map tabs open,
    // we only poll when the tab is visible.
    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        void refetch();
        startPolling(WORKTREE_POLL_INTERVAL_MS);
      } else {
        stopPolling();
      }
    };
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
  }, [refetch, startPolling, stopPolling]);

  const error = workTreeError ?? experimentError;

  if (error) {
    return { status: 'error', error };
  } else if (!experimentData || !data?.experimentWorkTree) {
    return { status: 'loading' };
  } else {
    return {
      status: workTreeLoading || experimentLoading ? 'reloading' : 'success',
      blocks: data.experimentWorkTree.blocks,
      experiment: experimentData.experiment,
      refetchWorkTree: handleRefetchWorkTree,
    };
  }
}
