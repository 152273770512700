import React from 'react';

import { styled } from '@mui/material/styles';

import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import ExampleWorkflowCard, {
  ExampleWorkflow,
} from 'client/app/components/cards/ExampleWorkflowCard/ExampleWorkflowCard';
import { ExampleWorkflowsQuery } from 'client/app/gql';

type Props = {
  data: ExampleWorkflowsQuery;
  onExampleWorkflowDetailsClick?: (exampleWorkflow: ExampleWorkflow) => void;
};

const ExamplesWorkflowsListDataComponent = ({
  data,
  onExampleWorkflowDetailsClick,
}: Props) => {
  const exampleWorkflows = data.exampleWorkflows.items || [];

  return (
    <List>
      {exampleWorkflows.map(exampleWorkflow => (
        <ExampleWorkflowCard
          key={exampleWorkflow.id}
          exampleWorkflow={exampleWorkflow}
          onDetailsClick={() => onExampleWorkflowDetailsClick?.(exampleWorkflow)}
        />
      ))}
    </List>
  );
};

const List = styled('div')(({ theme }) => ({
  ...experimentsStyles(theme).list,
}));

export default ExamplesWorkflowsListDataComponent;
