import React from 'react';

import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import ActiveDot from 'client/app/components/nav/breadcrumbs/components/ActiveDot';
import {
  type ExperimentEntityItem,
  NavNodeSubItem,
} from 'client/app/components/nav/breadcrumbs/types';
import { useUserProfile } from 'client/app/hooks/useUserProfile';

type Props = {
  isActive: boolean;
  item: NavNodeSubItem;
  onClick: React.MouseEventHandler<HTMLElement>;
  onAuxClick: React.MouseEventHandler<HTMLElement>;
};

export default function ExperimentEntityItem({
  item,
  isActive,
  onClick,
  onAuxClick,
}: Props) {
  const experiment = item as ExperimentEntityItem;

  const userProfile = useUserProfile();

  const belongsToThisUser = experiment.createdBy.id === userProfile?.id;
  const UserAvatar = belongsToThisUser ? CurrentUserAvatar : OtherUserAvatar;
  const userInitials =
    experiment.createdBy.firstName[0] + experiment.createdBy.lastName[0];

  return (
    <ExperimentItemContainer
      data-heap-tracking="breadcrumbs-experiment-entity-item"
      onClick={onClick}
      onAuxClick={onAuxClick}
    >
      <UserAvatar>
        <Typography variant="caption">{userInitials}</Typography>
      </UserAvatar>
      <ExperimentName variant="subtitle2" color="textPrimary" title={experiment.name}>
        {experiment.name}
      </ExperimentName>
      {isActive && <ActiveDot />}
    </ExperimentItemContainer>
  );
}

const ExperimentItemContainer = styled(MenuItem)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '24px 1fr 13px',
  columnGap: theme.spacing(3),

  minWidth: 286,
  padding: '5px 12px',
  cursor: 'pointer',

  '&:first-of-type': {
    paddingTop: theme.spacing(3),
  },
  '&:last-child': {
    paddingBottom: theme.spacing(4),
  },

  '& .MuiAvatar-root': {
    height: '24px',
    width: '24px',
  },
}));

const ExperimentName = styled(Typography)(() => ({
  textOverflow: 'ellipsis',
  maxWidth: 250,
  overflowX: 'hidden',
}));

const CurrentUserAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.background.paper,
}));

const OtherUserAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.primary.dark,
  border: `1px solid ${theme.palette.primary.dark}`,
}));
