import React, { useMemo } from 'react';

import AutocompleteWithParameterValues from 'client/app/components/Parameters/AutocompleteWithParameterValues';
import { STDLIB_DEFAULT_KEY } from 'client/app/state/AutocompleteParameterValuesContext';
import { AutocompleteAdditionalProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import Autocomplete from 'common/ui/filaments/Autocomplete';

type Props = ParameterEditorBaseProps<string> &
  Omit<AutocompleteAdditionalProps, 'editor'> & {
    /** The type of parameters existing values should be taken from - this can be from an override. */
    anthaType: string;
    onChange: (newValue?: string) => void;
    onBlur?: () => void;
    disableUnderline?: boolean;
  };

/**
 * An Autocomplete component that sources options from values already provided
 * to parameters of the same antha type within the Workflow Builder.
 *
 * Consumes AutocompleteParameterValuesContext so needs to be nested under
 * AutocompleteParameterValuesContextProvider.
 */

export default function AutocompleteEditor(props: Props) {
  const filteredOptionValues = useMemo(
    () => removeDefaultValues(props.staticOptions, props.provideDefaultKey),
    [props.provideDefaultKey, props.staticOptions],
  );

  const filteredOptions = useMemo(
    () => filteredOptionValues.map(value => ({ label: value, value })),
    [filteredOptionValues],
  );

  if (props.useDynamicOptions) {
    return (
      <AutocompleteWithParameterValues
        anthaType={props.anthaTypeOverride ?? props.anthaType}
        additionalSourceTypes={props.additionalSourceTypes}
        additionalOptions={filteredOptionValues}
        valueLabel={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        acceptCustomValues={props.canAcceptCustomValues}
        isDisabled={props.isDisabled}
        disableClearable={props.disableClearable}
        placeholder={props.placeholder}
        disableUnderline={props.disableUnderline}
        hasError={props.hasError}
      />
    );
  }
  return (
    <Autocomplete
      valueLabel={props.value}
      options={filteredOptions}
      onChange={props.onChange}
      onBlur={props.onBlur}
      acceptCustomValues={props.canAcceptCustomValues}
      isDisabled={props.isDisabled}
      disableClearable={props.disableClearable}
      placeholder={props.placeholder}
      disableUnderline={props.disableUnderline}
      hasError={props.hasError}
    />
  );
}

/**
 * In maps, the elements can allow input of a 'default' key which corresponds
 * to the value of 'maps.DefaultKey' in the elements stdlib. There's no one specific
 * map value type that this is limited to, so we either add or remove this here.
 * This can be disabled in the element config tool by unchecking provideDefaultKey.
 */
function removeDefaultValues(options: string[], provideDefaultKey: boolean): string[] {
  const filteredOptions = provideDefaultKey
    ? [...options.filter(opt => opt !== STDLIB_DEFAULT_KEY), STDLIB_DEFAULT_KEY] // make sure there are no duplicates, so remove before appending
    : options.filter(opt => opt !== STDLIB_DEFAULT_KEY);
  return filteredOptions;
}
