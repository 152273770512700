import { ContentSourceType } from 'common/types/contentSource';
import {
  ContainerType,
  PlateType,
  WellBottomShapeEnum,
  WellShapeEnum,
} from 'common/types/plateType';

export const SBS_STANDARD_WIDTH = 127.76;
export const SBS_STANDARD_LENGTH = 85.48;
export const SBS_STANDARD_HEIGHT = 14.35;

export function generateSimple9wellTemplate(): PlateType {
  return {
    name: 'label that will be seen everywhere in the system',
    description: 'additional description of a plate',
    columns: 3,
    rows: 3,
    dimension: {
      x: SBS_STANDARD_WIDTH,
      y: SBS_STANDARD_LENGTH,
      z: SBS_STANDARD_HEIGHT,
    },
    accessory: '',
    catalogNumber: '',
    catalogUrl: '',
    color: '',
    type: 'customPlate',
    format: '9well',
    id: '',
    manufacturer: 'user-defined plate type',
    wellOffset: {
      x: 15,
      y: 15,
    },
    usage: 'electrophoresis',
    wellBottomOffset: 1,
    wellStart: {
      x: 15,
      y: 15,
      z: 3.35,
    },
    defaultResidualVolume: 1,
    editable: true,
    version: 1,
    wellShape: {
      type: WellShapeEnum.CYLINDER,
      bottomType: WellBottomShapeEnum.FLAT_BOTTOM,
      dimensionMm: {
        x: 10,
        y: 10,
        z: 11,
      },
      volumeOverrideUl: 500,
    },
    containerType: ContainerType.WELL,
    contentSource: ContentSourceType.USER_GENERATED,
  };
}
