import { formHelperTextClasses } from '@mui/material/FormHelperText';
import { formLabelClasses } from '@mui/material/FormLabel';
import { inputBaseClasses } from '@mui/material/InputBase';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import { svgIconClasses } from '@mui/material/SvgIcon';

import Colors from 'common/ui/Colors';
import TextField from 'common/ui/filaments/TextField';

export default styled(TextField)(({ theme }) => ({
  [`& .${inputBaseClasses.root}`]: {
    backgroundColor: Colors.WHITE,
    padding: theme.spacing(3, 4),

    [`&.${inputBaseClasses.error}`]: {
      borderColor: theme.palette.error.main,
    },
  },

  [`& .${formHelperTextClasses.error}`]: {
    color: theme.palette.error.main,
  },

  [`& .${formLabelClasses.root}`]: {
    top: theme.spacing(3),
    color: Colors.DARK_TEXT_SECONDARY,
    letterSpacing: '0.4px',

    [`&.${formLabelClasses.focused}`]: {
      [`&.${inputLabelClasses.animated}`]: {
        color: theme.palette.primary.main,
      },
    },
  },

  [`& .${svgIconClasses.root}`]: {
    color: theme.palette.text.primary,
    fontSize: '20px',
  },
}));
