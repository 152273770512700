import { useFetchGraphQLDefaultElementSet } from 'client/app/api/ElementsApi';
import { useCreateAndLinkWorkflowToExperiment } from 'client/app/apps/experiments/useCreateAndLinkWorkflowToExperiment';
import { useMergeElementBundle } from 'client/app/apps/workflow-builder/lib/workflowUtils';
import { EditorType, ServerSideElements } from 'common/types/bundle';

/**
 * Creates a new workflow in the given experiment populated with the given elements.
 *
 * The configurations for the elements will be fetched, and the workflow will be
 * populated with the default parameter values contained in those configurations (with
 * and parameter values specified in elements to be preferred).
 *
 * The element instance name will be renamed according to our standard sequential
 * naming.
 */
export const useCreateAndLinkWorkflowWithElementsToExperiment = () => {
  const fetchGraphQLDefaultElementSet = useFetchGraphQLDefaultElementSet();
  const createWorkflowInExperiment = useCreateAndLinkWorkflowToExperiment();
  const mergeElementBundle = useMergeElementBundle();

  const createWorkflowWithElementsInExperiment = async (
    experimentId: ExperimentId,
    source: EditorType,
    elements: ServerSideElements,
  ): Promise<WorkflowId | undefined> => {
    // Get elements from server so we can get the configurations.
    // There is no element set ID with a new workflow, so we can just grab the default
    // element set for the org.
    const { elements: defaultElements } = await fetchGraphQLDefaultElementSet();

    const updatedElements = await mergeElementBundle(elements, defaultElements);

    return createWorkflowInExperiment(experimentId, source, updatedElements);
  };

  return createWorkflowWithElementsInExperiment;
};
