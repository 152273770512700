import Big from 'big.js';

/**
 * Calculate absorbance given a transmittance value provided in decimalised form.
 * Result provided to 9dp with half-even rounding.
 */
export function calculateAbsorbance(transmittanceDecimal: Big): string {
  // Absorbance = 2 - Log10(Transmittance %)
  const subtractionComponent = Math.log10(Big(transmittanceDecimal).toNumber());
  // Handle possible Infinity return where trasmittanceDecimal === 0
  if (!Number.isFinite(subtractionComponent)) {
    return 'Infinity';
  }
  // Use 9dp with half-even rounding method
  return Big(2).minus(Big(subtractionComponent)).round(9, 2).toString();
}

/**
 * Calculate transmittance given an absorbance value provided in decimalised form.
 * Result provided to 9dp with half-even rounding.
 */
export function calculateTransmittance(absorbanceDecimal: Big): string {
  // Transmittance = 10^(2-Absorbance)
  const exponent = Big(2).minus(absorbanceDecimal).toNumber();
  // Use 9dp with half-even rounding method
  return Big(Math.pow(10, exponent)).round(9, 2).toString();
}
