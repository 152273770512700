/**
 * Collection of programatically-triggered intercom tours in the Antha UI.
 *
 * How to create one:
 * 1. Create a tour in the app.Intercom.com
 * 2. In the "Show your tour automatically", add some rules so that the tour is shown when a link
 *    is visited. For instance, if I want a tour to be triggered when an element is added, I can
 *    use the `Where contains /#/workflow/` which will trigger the tour once we are in the Builder.
 *    To avoid the tour being shown multiple times, add a `<event track ID> count has any value` rule.
 * 3. Wrap target components in the IntercomTarget component to add a stable references to HTML
 *    elements to point at during the tour.
 */
export enum IntercomTourIDs {
  ELISA_SPREADSHEET = 223574,
  BUILDER = 227845,
  EXPERIMENTS_HOME_PAGE = 251379,
  SIMULATION_OPTIONS = 233103,
  EXPERIMENT = 20325604,
}
