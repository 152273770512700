import React, { useCallback, useMemo } from 'react';

import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import DNADialog from 'client/app/components/Parameters/DNA/DNADialog';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import SelectFromDialogButton from 'common/ui/components/SelectFromDialogButton';

export type DNA = {
  single_stranded: boolean;
  double_stranded: boolean;
  linear: boolean;
  plasmid: boolean;
  nm: string;
  seq: string;
};

type Props = {
  value: DNA;
  onChange: (value: any) => void;
  isDisabled?: boolean;
} & ParameterEditorBaseProps<DNA>;

const dialogDefaults = {
  single_stranded: false,
  double_stranded: true,
  linear: true,
  plasmid: false,
  nm: '',
  seq: '',
};

export default function DNAParameter(props: Props) {
  const { onChange, value, isDisabled } = props;

  const handleChange = useCallback(
    (newVal: DNA | undefined | typeof CANCEL_CHOICE) => {
      if (newVal === CANCEL_CHOICE) {
        return;
      }
      onChange(newVal);
    },
    [onChange],
  );

  const dialogProps = useMemo(
    () => ({
      value: { ...dialogDefaults, ...value },
    }),
    [value],
  );

  return (
    <SelectFromDialogButton
      value={value}
      selectedValueLabel={value?.nm}
      dialog={DNADialog}
      dialogProps={dialogProps}
      placeholder="Add a DNA sequence"
      onChange={handleChange}
      isDisabled={isDisabled}
    />
  );
}
