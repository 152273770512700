import React, { useMemo, useState } from 'react';

import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

export const splitMultilineStringByLine = (input: string) => {
  const arr = input.trim().split('\n');
  if (arr.length === 1 && arr[0] === '') {
    return [];
  }
  return arr.map(option => option.trim());
};

/**
 * Helper hook for using a multi Textfield as a string array editor,
 * where each line holds a string in the array.
 */
export default function useMultilineStringArrayChange(
  initialValuesArray: string[],
): [string[], string, (e: React.ChangeEvent<HTMLInputElement>) => void] {
  const [valuesString, setValuesString] = useState(initialValuesArray.join('\n'));

  const onChangeValues = useTextFieldChange((newValue: string) => {
    setValuesString(newValue);
  });

  const valuesArray = useMemo(
    () => splitMultilineStringByLine(valuesString),
    [valuesString],
  );
  return [valuesArray, valuesString, onChangeValues];
}
