import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import LabwareSection from 'client/app/components/Labware/LabwareSection';
import { LabwareInfoSection } from 'client/app/components/Labware/types';

type Props = {
  labware: LabwareInfoSection[];
};

export default function Labware({ labware }: Props) {
  return labware.length > 0 ? (
    <Stack gap={5}>
      {labware.map(labwareSection => (
        <LabwareSection key={labwareSection.sectionHeader} section={labwareSection} />
      ))}
    </Stack>
  ) : (
    <Typography variant="body1" textAlign="center">
      No labware to display
    </Typography>
  );
}
