import { useMemo } from 'react';

import { getDeviceModelLabel, GraphQLDevice } from 'client/app/api/deviceFromGraphql';
import { byName } from 'common/lib/strings';
import { getGenericDeviceTypeFromAnthaClass } from 'common/types/bundleConfigUtils';
import { ContentSourceType } from 'common/types/contentSource';
import { getHumanReadableSource } from 'common/ui/components/DeviceCard/DeviceCard';

function filterDevices(
  devicesArray: readonly GraphQLDevice[],
  filterQuery: string,
  filterModel: string,
  filterSource: string,
  filterType: string,
) {
  const lowerCaseQuery = filterQuery ? filterQuery.toLowerCase() : '';
  return devicesArray.filter(device => {
    if (device.model.anthaLangDeviceClass === 'Manual') return false;
    const { name, datastoreID, contentSource } = device;
    const humanReadabaleContentSource = getHumanReadableSource(
      contentSource as unknown as ContentSourceType,
    ).humanReadableName;
    if (!name) return false;

    const type = getGenericDeviceTypeFromAnthaClass(device.model.anthaLangDeviceClass);

    const fullModelName = getDeviceModelLabel(device);
    return (
      (name.toLowerCase().includes(lowerCaseQuery) ||
        fullModelName.toLowerCase().includes(lowerCaseQuery) ||
        datastoreID?.toLowerCase().startsWith(lowerCaseQuery)) &&
      (!filterModel || device.model.id === filterModel) &&
      (!filterSource || filterSource.includes(humanReadabaleContentSource)) &&
      (!filterType || filterType.includes(type))
    );
  });
}

export function useFilteredDevices(
  devices: readonly GraphQLDevice[],
  {
    filterQuery,
    filterModel,
    filterSource,
    filterType,
  }: {
    filterQuery: string;
    filterModel: string;
    filterSource: string;
    filterType: string;
  },
) {
  const sortedArrayOfDevices = useMemo(() => {
    return [...devices].sort(byName);
  }, [devices]);
  const filteredArrayOfDevices = useMemo(() => {
    return (
      sortedArrayOfDevices &&
      filterDevices(
        sortedArrayOfDevices,
        filterQuery,
        filterModel,
        filterSource,
        filterType,
      )
    );
  }, [filterModel, filterQuery, filterSource, filterType, sortedArrayOfDevices]);
  return filteredArrayOfDevices;
}
