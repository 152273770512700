import React, { useCallback, useState } from 'react';

import { BasePanel } from 'client/app/apps/workflow-builder/panels/Panel';
import FactorsTab from 'client/app/components/Parameters/FiltrationProtocolDesign/FactorsTab';
import {
  FiltrationProtocolDesignProvider,
  useFiltrationProtocolDesign,
} from 'client/app/components/Parameters/FiltrationProtocolDesign/FiltrationProtocolDesignState';
import {
  mapFactorsToElementParameter,
  mapLiquidGroupsToElementParameter,
} from 'client/app/components/Parameters/FiltrationProtocolDesign/lib/parameterUtils';
import useFiltrationDesignParameter from 'client/app/components/Parameters/FiltrationProtocolDesign/lib/useFiltrationDesignParameter';
import LiquidsTab from 'client/app/components/Parameters/FiltrationProtocolDesign/LiquidsTab';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  className?: string;
  onClose: () => void;
  isReadonly: boolean;
};

type TabType = 'Factors' | 'Liquids';

const TABS: TabsInfo<TabType> = [
  { label: 'Factors', value: 'Factors' },
  { label: 'Liquids', value: 'Liquids' },
];

export function BaseFilterPlateDesignPanel({ className, onClose, isReadonly }: Props) {
  const [activeTab, setActiveTab] = useState<TabType>('Factors');
  const classes = useStyles();
  const { setValue } = useFiltrationDesignParameter();
  const state = useFiltrationProtocolDesign();

  const applyChanges = useCallback(() => {
    setValue({
      factors: mapFactorsToElementParameter(state.factors),
      liquidGroups: mapLiquidGroupsToElementParameter(state.liquidGroups, state.factors),
    });
    onClose();
  }, [onClose, setValue, state.factors, state.liquidGroups]);

  return (
    <BasePanel
      title="Filtration Protocol Design"
      onClose={applyChanges}
      onCloseVariant="done"
      fullWidth
      panelContent="FiltrationProtocolDesign"
      className={className}
    >
      <div className={classes.container}>
        <Tabs tabsInfo={TABS} activeTab={activeTab} onChangeTab={setActiveTab} />
        <div
          hidden={activeTab === 'Factors'}
          style={{ display: activeTab === 'Factors' ? 'contents' : 'none' }}
        >
          <FactorsTab isReadonly={isReadonly} />
        </div>
        <div
          hidden={activeTab === 'Liquids'}
          style={{ display: activeTab === 'Liquids' ? 'contents' : 'none' }}
        >
          <LiquidsTab isReadonly={isReadonly} />
        </div>
      </div>
    </BasePanel>
  );
}

const useStyles = makeStylesHook({
  container: {
    flex: 1,
    display: 'grid',
    gridTemplate: 'auto minmax(0, 1fr) / minmax(0, 1fr)',
    placeItems: 'stretch',
  },
});

export default function FilterPlateDesignPanel(props: Props) {
  return (
    <FiltrationProtocolDesignProvider>
      <BaseFilterPlateDesignPanel {...props} />
    </FiltrationProtocolDesignProvider>
  );
}
