type Method<MethodID> = {
  id: MethodID;
  parentRevisionId: MethodID | null;
  dependencies: readonly MethodID[];
};

export function getWorkTreeMethodsAndDescendants<MethodID extends string>(
  allNodes: readonly Method<MethodID>[],
  hiddenMethodIds: MethodID[],
): MethodID[] {
  const nodesByDependency = new Map<string, Method<MethodID>[]>();
  const nodesByParent = new Map<string, Method<MethodID>[]>();

  for (const node of allNodes) {
    for (const dep of node.dependencies) {
      nodesByDependency.set(dep, (nodesByDependency.get(dep) || []).concat(node));
    }
    if (node.parentRevisionId) {
      nodesByParent.set(
        node.parentRevisionId,
        (nodesByParent.get(node.parentRevisionId) || []).concat(node),
      );
    }
  }

  const descendantMethodIds: MethodID[] = [];

  const queue: MethodID[] = [...hiddenMethodIds];
  const seen = new Set<MethodID>();
  while (queue.length > 0) {
    const id = queue.shift()!;
    // Just in case we have a cyclical graph
    if (seen.has(id)) {
      continue;
    }
    const directDescendantIds = (nodesByDependency.get(id) ?? []).map(node => node.id);
    queue.push(...directDescendantIds);
    descendantMethodIds.push(...directDescendantIds);

    const childrenIds = (nodesByParent.get(id) ?? []).map(node => node.id);
    queue.push(...childrenIds);
    descendantMethodIds.push(...childrenIds);

    seen.add(id);
  }

  return [...hiddenMethodIds, ...descendantMethodIds];
}
