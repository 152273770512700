import React, { useCallback, useMemo } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  formatMeasurementObj,
  formatWellPosition,
  sanitizeDeckItemName,
} from 'common/lib/format';
import Colors from 'common/ui/Colors';
import PlateLayout, {
  Props as PlateLayoutProps,
} from 'common/ui/components/PlateLayout/PlateLayout';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import {
  TOOLTIP_FONTSIZE,
  WellTooltipTitleProps,
} from 'common/ui/components/simulation-details/mix/WellTooltip';
import Styles from 'common/ui/components/simulation-details/Styles';

export type MixPlateProps = {
  deckLayout: DeckLayout;
  humanReadablePlateLabel: string;
  /**
   * Text to display over the top of the plate.  If supplied, this will obscure
   * a portion of the plate, so it should only be used in scenarios where seeing
   * the whole plate isn't necessary.
   */
  overlayText?: string;
  hideLabel?: boolean;
  /**
   * If the plate is in the hotel, do not display it's contents. Instead, just show it's
   * label.
   */
  isInHotel?: boolean;
  /**
   * Called when the user clicks anywhere inside the plate
   */
  onPlatePointerUp?: (plate: string) => void;
} & Omit<PlateLayoutProps, 'geometry'>;

/**
 * Shows a 2d grid of wells with a title and summary. Used within execution
 * preview.
 */
function MixPlate(props: MixPlateProps) {
  const {
    plate,
    humanReadablePlateLabel,
    overlayText,
    deckLayout,
    onPlatePointerUp,
    hideLabel,
    isInHotel,
    TooltipTitle,
    ...plateLayoutProps
  } = props;
  /**
   * Since tubes are considered as a 1 well plates they should be handled separately.
   * Tubes should not have overlaping plate labels while being placed in a rack.
   * e.g. Falcon tubes rack
   */
  const showLabels = !(hideLabel || isTube(plate));
  const TooltipComponent = makeTooltip(plate, TooltipTitle);

  const handlePointerUp = useCallback(
    () => onPlatePointerUp?.(plate.name),
    [onPlatePointerUp, plate.name],
  );

  const geometry = useMemo(
    () => deckLayout.getCurrentGeometry(plate),
    [deckLayout, plate],
  );

  return (
    <Container style={{ backgroundColor: plate.color }} onPointerUp={handlePointerUp}>
      {overlayText && (
        <OverlayTextContainer>
          <OverlayText>{overlayText}</OverlayText>
        </OverlayTextContainer>
      )}
      {showLabels && (
        <DeckItemLabel isInHotel={isInHotel}>
          <DeckItemName>{sanitizeDeckItemName(plate.name)}</DeckItemName>
          <DeckItemPlate>{humanReadablePlateLabel}</DeckItemPlate>
        </DeckItemLabel>
      )}
      {!isInHotel && (
        <PlateContainer>
          <PlateLayout
            {...plateLayoutProps}
            geometry={geometry}
            plate={plate}
            TooltipTitle={TooltipComponent}
          />
        </PlateContainer>
      )}
    </Container>
  );
}

export default React.memo(MixPlate);

function isTube(plate: PlateState) {
  const is1x1Plate = plate.description.includes('1x1');
  const isTubeWellType = ['circle', 'cylinder'].includes(plate.well_type);
  return isTubeWellType && is1x1Plate;
}

function makeTooltip(
  plate: PlateState,
  TooltipTitle?: React.FunctionComponent<WellTooltipTitleProps>,
): React.FunctionComponent<WellTooltipTitleProps> | undefined {
  const TubeTooltip = ({
    wellContents,
    wellLocationOnDeckItem,
  }: WellTooltipTitleProps) => (
    <TooltipTable>
      {plate.rows > 1 && (
        <TooltipHeadline>
          Tube at {formatWellPosition(wellLocationOnDeckItem)}
        </TooltipHeadline>
      )}
      <dl>
        <dt>Plate Name:</dt>
        <dd>{plate.name}</dd>
        {wellContents?.name && (
          <>
            <dt>Liquid Name:</dt>
            <dd>{wellContents.name}</dd>
          </>
        )}
        {wellContents?.total_volume?.value && wellContents.total_volume.value > 0 && (
          <>
            <dt>Liquid Volume:</dt>
            <dd>{formatMeasurementObj(wellContents.total_volume)}</dd>
          </>
        )}
        {wellContents?.type && (
          <>
            <dt>Liquid Type:</dt>
            <dd>{wellContents.type}</dd>
          </>
        )}
      </dl>
    </TooltipTable>
  );
  const PlateTooltip = TooltipTitle;
  return isTube(plate) ? TubeTooltip : PlateTooltip;
}

const Container = styled('div')({
  position: 'relative',
  height: '100%',
  backgroundColor: 'white',
  display: 'grid',
  // First row  is the label, second is the plate.
  // Label takes 0 so that it doesn't shift the plate down
  gridTemplateRows: '0 100%',
  transition: 'box-shadow 1s',
  borderRadius: '4px',
});

const DeckItemLabel = styled('div')<{ isInHotel?: boolean }>(({ isInHotel, theme }) => ({
  ...Styles.deckItemLabel,
  ...(isInHotel
    ? {
        // Don't show the label above the plate
        transform: 'none',
        padding: theme.spacing(2),
      }
    : null),
}));

const DeckItemName = styled('div')(Styles.deckItemName);
const DeckItemPlate = styled('div')(Styles.deckItemPlate);

const OverlayTextContainer = styled('div')({
  position: 'absolute',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const OverlayText = styled('span')({
  fontSize: '300%',
  fontWeight: 'bold',
  background: Colors.GREY_20,
  borderRadius: '30px',
  padding: '10px',
  wordBreak: 'break-all',
  textAlign: 'center',
});

const PlateContainer = styled('svg')({
  // Ensure plate is on second row, even if label above is not present
  gridRow: 2,
  width: '100%',
  height: '100%',
});

const TooltipHeadline = styled(Typography)(({ theme }) => ({
  fontSize: TOOLTIP_FONTSIZE,
  fontWeight: 700,
  lineHeight: theme.spacing(5),
  letterSpacing: '0.4px',
}));

const TooltipTable = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(2),

  fontSize: TOOLTIP_FONTSIZE,
  fontWeight: 400,
  lineHeight: theme.spacing(5),
  letterSpacing: '0.4px',

  '& dl': {
    display: 'grid',
    gap: theme.spacing(3, 2),
    margin: theme.spacing(3, 0, 0, 0),
    color: Colors.TEXT_PRIMARY,

    '& dt': {
      gridColumn: 1,
    },
    '& dd': {
      margin: 0,
      gridColumn: 2,
      wordBreak: 'break-all',
    },
  },
}));
