import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { v4 as uuid } from 'uuid';

import { useElementInstance } from 'client/app/apps/workflow-builder/lib/useElementInstance';
import ElementParameterHelpIcon from 'client/app/components/Parameters/ElementParameterHelpIcon';
import {
  CARD_WIDTH,
  SMALL_LAPTOP_RESOLUTION,
} from 'client/app/components/Parameters/FiltrationPlateLayout/components/ResinCards';
import { useFilterPlateEditorState } from 'client/app/components/Parameters/FiltrationPlateLayout/lib/editorState';
import { useFiltrationPlateLayoutParameter } from 'client/app/components/Parameters/FiltrationPlateLayout/lib/parameterUtils';
import useResinAssignForm, {
  getResinColor,
} from 'client/app/components/Parameters/FiltrationPlateLayout/lib/resinFormUtils';
import MetaDataForm from 'client/app/components/Parameters/MetaDataForm';
import Button from 'common/ui/components/Button';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  liquidColors: LiquidColors;
  isReadonly: boolean;
};

export default function ResinAssignForm({ liquidColors, isReadonly }: Props) {
  const elementInstance = useElementInstance();
  const { name: parameterName } = useFiltrationPlateLayoutParameter();

  const {
    state: { wellRegionDraft, resinBufferVolume },
    addResinCard,
    cancelEditing,
  } = useFilterPlateEditorState();

  const form = useResinAssignForm(wellRegionDraft, resinBufferVolume);

  const [resinColor, setResinColor] = useState<string>(() =>
    wellRegionDraft
      ? wellRegionDraft.resinColor
      : getResinColor(form.state, liquidColors),
  );
  useEffect(() => {
    if (form.state.dirty) {
      setResinColor(getResinColor(form.state, liquidColors));
    }
  }, [form.state, liquidColors]);

  const classes = useStyles({ resinColor });

  if (!elementInstance || !parameterName) return null;

  const handleSubmit = () => {
    if (form.state.error) {
      form.showError(true);
    } else {
      addResinCard({
        id: uuid(),
        resinName: form.state.resinName,
        resinVolume: form.state.resinVolume,
        metaItems: form.state.metaItems,
        resinColor,
      });
    }
  };

  return (
    <Paper className={classes.card}>
      <header>
        <div className={classes.resinColor} />
        <Typography variant="subtitle2">{form.state.resinName || 'New Resin'}</Typography>
      </header>
      <main>
        <div className={classes.fieldset}>
          <div className={classes.inputLabel}>
            <Typography variant="caption" className={classes.required}>
              Resin Name
            </Typography>
            <ElementParameterHelpIcon
              elementId={elementInstance.element.id}
              name={parameterName}
            />
          </div>
          <TextField
            className={classes.inputField}
            value={form.state.resinName}
            onChange={form.updateName}
            onBlur={() => form.validate()}
            placeholder="Resin name..."
            error={form.state.showError && !!form.state.error?.resinName}
            helperText={form.state.showError && form.state.error?.resinName}
            disabled={isReadonly}
          />
        </div>

        <div className={classes.fieldset}>
          <div className={classes.inputLabel}>
            <Typography variant="caption" className={classes.required}>
              Resin Volume
            </Typography>
            <ElementParameterHelpIcon
              elementId={elementInstance.element.id}
              name={parameterName}
            />
          </div>
          <div className={classes.volumeInput}>
            <TextField
              type="number"
              className={classes.inputField}
              value={form.state.resinVolume}
              onChange={form.updateVolume}
              onBlur={() => form.validate()}
              inputProps={{ className: classes.noSpinNumberInput, min: 0 }}
              placeholder="Volume..."
              error={form.state.showError && !!form.state.error?.resinVolume}
              helperText={form.state.showError && form.state.error?.resinVolume}
              disabled={isReadonly}
            />
            <Typography variant="body2" className={classes.volumeUnit}>
              ul
            </Typography>
          </div>
        </div>

        <div className={classes.fieldset}>
          <div className={classes.inputLabel}>
            <Typography variant="caption">Metadata</Typography>
            <ElementParameterHelpIcon
              elementId={elementInstance.element.id}
              name={parameterName}
            />
          </div>
          <MetaDataForm
            metaItems={form.state.metaItems}
            error={form.state.error?.meta}
            showError={form.state.showError}
            updateMeta={form.updateMeta}
            validate={form.validate}
            isReadonly={isReadonly}
          />
        </div>
      </main>
      <footer>
        <Button variant="secondary" onClick={cancelEditing}>
          Cancel
        </Button>
        <Button
          variant="secondary"
          color="primary"
          onClick={handleSubmit}
          disabled={isReadonly}
        >
          {wellRegionDraft ? 'Save' : 'Add'}
        </Button>
      </footer>
    </Paper>
  );
}

const useStyles = makeStylesHook<string, { resinColor: string }>(
  ({ palette, spacing, breakpoints }) => ({
    card: {
      border: `1px solid ${palette.divider}`,
      borderRadius: '6px',
      padding: spacing(4),
      boxShadow: 'none',
      [breakpoints.up(SMALL_LAPTOP_RESOLUTION)]: {
        width: CARD_WIDTH,
      },

      '& > header': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: spacing(5),
      },
      '& > main': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      },
      '& > footer': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    resinColor: ({ resinColor }) => ({
      flexShrink: 0,
      background: resinColor,
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      marginRight: spacing(4),
    }),
    fieldset: {
      marginBottom: spacing(5),
    },
    required: {
      '&::after': {
        content: "'*'",
        color: palette.error.main,
      },
    },
    inputLabel: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    inputField: {
      marginTop: spacing(2),
      width: '100%',
    },
    volumeInput: {
      display: 'flex',
      alignItems: 'baseline',
    },
    volumeUnit: {
      marginLeft: spacing(3),
    },
    noSpinNumberInput: {
      textAlign: 'right',
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
      },
    },
  }),
);
