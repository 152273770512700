import React, { useLayoutEffect, useRef, useState } from 'react';

import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { mergeRefs } from 'react-merge-refs';
import useResizeObserver from 'use-resize-observer';

import { SynthacePlate, VendorPlate } from 'client/app/gql';
import { HAMILTON_ANTHA_CLASS } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import useDebounce from 'common/ui/hooks/useDebounce';
import { usePopover } from 'common/ui/hooks/usePopover';
import useThrottle from 'common/ui/hooks/useThrottle';
import { PlateIcon } from 'common/ui/icons/Plate';

type LabwareCardProps = {
  plate: VendorPlate | SynthacePlate;
  matchedSynthacePlate?: SynthacePlate;
  deviceAnthaClass: string;
};

function isSynthacePlate(plate: VendorPlate | SynthacePlate): plate is SynthacePlate {
  return 'plateType' in plate;
}

function isHamiltonPlate(deviceAnthaClass: string) {
  return deviceAnthaClass === HAMILTON_ANTHA_CLASS;
}

export default function LabwareCard(props: LabwareCardProps) {
  const { deviceAnthaClass, plate, matchedSynthacePlate } = props;
  const isPlateSynthacePlate = isSynthacePlate(plate);
  const isPlateHamiltonPlate = !isPlateSynthacePlate && isHamiltonPlate(deviceAnthaClass);

  return (
    <li>
      <LabwareCardBase
        labwareName={plate.labwareName}
        categoryOrPlateType={isPlateSynthacePlate ? plate.plateType : plate.category}
        manufacturer={plate.manufacturer}
        partNumbers={isPlateSynthacePlate ? [plate.partNumber] : plate.partNumbers}
        isSynthacePlate={isPlateSynthacePlate}
        isHamiltonPlate={isPlateHamiltonPlate}
      />
      {matchedSynthacePlate && (
        <LabwareCardBase
          labwareName={matchedSynthacePlate.labwareName}
          categoryOrPlateType={matchedSynthacePlate.plateType}
          manufacturer={matchedSynthacePlate.manufacturer}
          partNumbers={[matchedSynthacePlate.partNumber]}
          isSynthacePlate
        />
      )}
    </li>
  );
}

type LabwareCardBaseProps = {
  labwareName: string;
  categoryOrPlateType: string | null;
  manufacturer: string | null;
  partNumbers: string[];
  isSynthacePlate?: boolean;
  isHamiltonPlate?: boolean;
};

const PART_MARGIN = 8;
const SHOW_MORE_LABEL_WIDTH = 64;

export function LabwareCardBase(props: LabwareCardBaseProps) {
  const {
    labwareName,
    categoryOrPlateType,
    manufacturer,
    partNumbers,
    isSynthacePlate = false,
    isHamiltonPlate = false,
  } = props;

  return (
    <StyledLabwareCardBase>
      <PlateIconContainer
        isSynthacePlate={isSynthacePlate}
        className="plateIconContainer"
      >
        {isSynthacePlate && <StyledPlateIcon />}
      </PlateIconContainer>
      <StyledLabwareCardContent className="labwareCard" isSynthacePlate={isSynthacePlate}>
        <StyledDescriptionTerm gridColumn="name">
          <CaptionTypography variant="caption"> Labware name</CaptionTypography>
        </StyledDescriptionTerm>
        <StyledDescriptionName gridColumn="name">
          <TypographyWithTooltip variant="subtitle2">{labwareName}</TypographyWithTooltip>
        </StyledDescriptionName>
        {/* Hamilton plates only contain `LabwareName` information, so hide all other fields */}
        {isHamiltonPlate ? null : (
          <>
            <StyledDescriptionTerm gridColumn="type">
              <CaptionTypography variant="caption">
                {isSynthacePlate ? 'Plate Type' : 'Category'}
              </CaptionTypography>
            </StyledDescriptionTerm>
            <StyledDescriptionName gridColumn="type">
              <TypographyWithTooltip variant="subtitle2">
                {categoryOrPlateType}
              </TypographyWithTooltip>
            </StyledDescriptionName>
            <StyledDescriptionTerm gridColumn="manufacturer">
              <CaptionTypography variant="caption">Manufacturer</CaptionTypography>
            </StyledDescriptionTerm>
            <StyledDescriptionName gridColumn="manufacturer">
              <TypographyWithTooltip variant="subtitle2">
                {manufacturer}
              </TypographyWithTooltip>
            </StyledDescriptionName>
            <StyledDescriptionTerm gridColumn="partNumber">
              <CaptionTypography variant="caption">Part numbers</CaptionTypography>
            </StyledDescriptionTerm>
            <StyledDescriptionName gridColumn="partNumber">
              {/* If there are fewer than 2 part numbers, there is no need for the expensive calculation of whether to show a "Show more button"  */}
              {partNumbers.length === 0 ? null : partNumbers.length === 1 ? (
                <PartNumber className="part">
                  <Typography>{partNumbers[0]}</Typography>
                </PartNumber>
              ) : (
                <PartNumbers partNumbers={partNumbers} />
              )}
            </StyledDescriptionName>
          </>
        )}
      </StyledLabwareCardContent>
    </StyledLabwareCardBase>
  );
}

type PartNumbersProps = {
  partNumbers: string[];
};

const PartNumbers = ({ partNumbers }: PartNumbersProps) => {
  const [numbersToShow, setNumbersToShow] = useState(partNumbers.length);
  const partsContainerRef = useRef<HTMLDivElement>(null);
  const moreToShow = partNumbers.length - numbersToShow;

  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();

  const rerenderPartsThrottle = useThrottle(() => {
    /**
     * The +1 is here just to make the setState value different from the initial.
     * Because setting state to the same value would not trigger a re-render
     * and consequently the layout effect below.
     */
    setNumbersToShow(partNumbers.length + 1);
  }, 100);

  const rerenderParts = useDebounce(() => {
    /**
     * This callback initiates a resize of an element within the ResizeObserver resize loop.
     * This leads to undelivered notifications error in ResizeObserver.
     *
     * To prevent this error we debounce the callback with a 0 sec timeout making sure
     * it is run after all microtasks scheduled by the ResizeObserver.
     * https://github.com/juggle/resize-observer/blob/v3/src/utils/queueMicroTask.ts
     *
     * For more reference please visit:
     * https://github.com/juggle/resize-observer#resize-loop-detection
     */
    onHidePopover();
    rerenderPartsThrottle();
  }, 0);

  const observer = useResizeObserver({
    onResize: rerenderParts,
  });
  const mergedRef = mergeRefs([observer.ref, partsContainerRef]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    const container = partsContainerRef.current;
    if (container) {
      const partsContainer = container.getBoundingClientRect();
      const parts = container.getElementsByClassName('part');

      let partsTotalWidth = 0;
      let count = 0;
      let i = 1;

      for (const part of parts) {
        // If this is the last part, and it fits, we don't need to worry about saving space for the 'more' label.
        const isLast = i === partNumbers.length;

        const partRect = part.getBoundingClientRect();
        const partWidth = partRect.width + PART_MARGIN;

        if (
          partsTotalWidth + partWidth >
          partsContainer.width - (isLast ? 0 : SHOW_MORE_LABEL_WIDTH)
        )
          break;

        partsTotalWidth += partWidth;
        count += 1;
        i++;
      }
      setNumbersToShow(count);
    }
  });

  return (
    <PartNumberContainer ref={mergedRef}>
      {partNumbers.slice(0, numbersToShow).map((num, idx) => {
        return (
          <PartNumber key={idx} className="part">
            <Typography>{num}</Typography>
          </PartNumber>
        );
      })}
      {moreToShow > 0 && (
        <ShowMoreButton
          maxWidth={moreToShow !== partNumbers.length}
          variant="tertiary"
          size="small"
          onClick={onShowPopover}
        >
          <Typography variant="caption">
            {moreToShow === partNumbers.length ? `+ Show all` : `+${moreToShow} more`}
          </Typography>
        </ShowMoreButton>
      )}
      <StyledMenu
        anchorEl={popoverAnchorElement}
        keepMounted
        open={isPopoverOpen}
        onClose={onHidePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{ square: false }}
      >
        {partNumbers.slice(numbersToShow).map((num, idx) => {
          return (
            <PartNumber key={idx} className="part">
              <Typography>{num}</Typography>
            </PartNumber>
          );
        })}
      </StyledMenu>
    </PartNumberContainer>
  );
};

const StyledLabwareCardBase = styled('div')(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: '[icon] 16px [content] 1fr',
  boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
  border: `1px solid ${Colors.GREY_30}`,
  borderRadius: spacing(2),
  '& .labwareCard': {
    borderRadius: spacing(0, 2, 2, 0),
  },
  '& .plateIconContainer': {
    borderRadius: spacing(2, 0, 0, 2),
  },
  '&:last-child:not(:only-child)': {
    borderRadius: spacing(0, 0, 2, 2),
    borderTop: 'none',
    '& .labwareCard': {
      borderRadius: spacing(0, 0, 2, 0),
    },
    '& .plateIconContainer': {
      borderRadius: spacing(0, 0, 0, 2),
    },
  },
  '&:first-of-type:not(:only-child)': {
    borderRadius: spacing(2, 2, 0, 0),
    '& .labwareCard': {
      borderRadius: spacing(0, 2, 0, 0),
    },
    '& .plateIconContainer': {
      borderRadius: spacing(2, 0, 0, 0),
    },
  },
}));

const StyledLabwareCardContent = styled('dl', {
  shouldForwardProp: propName => propName !== 'isSynthacePlate',
})<{
  isSynthacePlate: boolean;
}>(({ isSynthacePlate, theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateRows: '24px 24px',
  gridTemplateColumns:
    '[name] minmax(150px, 2fr) [type] minmax(100px, 1fr) [manufacturer] minmax(100px, 1fr) [partNumber] minmax(180px, 1fr)',
  gridAutoFlow: 'column',
  gridColumnGap: spacing(7),
  margin: spacing(0),
  padding: spacing(4, 6),
  backgroundColor: isSynthacePlate ? Colors.GREY_0 : Colors.GREY_10,
  overflow: 'hidden',
}));

const StyledDescriptionTerm = styled('dt', {
  shouldForwardProp: propName => propName !== 'gridColumn',
})<{ gridColumn: string }>(({ gridColumn, theme: { spacing } }) => ({
  gridColumn: gridColumn,
  marginRight: spacing(6),
}));

const StyledDescriptionName = styled('dd', {
  shouldForwardProp: propName => propName !== 'gridColumn',
})<{ gridColumn: string }>(({ gridColumn }) => ({
  display: 'flex',
  alignItems: 'center',
  gridColumn: gridColumn,
  margin: 0,
}));

const PlateIconContainer = styled('div', {
  shouldForwardProp: propName => propName !== 'isSynthacePlate',
})<{
  isSynthacePlate: boolean;
}>(({ isSynthacePlate }) => ({
  width: '16px',
  backgroundColor: isSynthacePlate ? Colors.BLUE_0 : Colors.GREY_10,
}));

const StyledPlateIcon = styled(PlateIcon)(({ theme: { spacing, palette } }) => ({
  position: 'relative',
  left: '-10px',
  top: '15px',
  height: '36px',
  width: '36px',
  padding: '6px',
  borderRadius: spacing(2),
  backgroundColor: palette.primary.dark,
  color: Colors.GREY_0,
  boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.20)',
}));

const CaptionTypography = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.text.secondary,
}));
const PartNumberContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: `${PART_MARGIN}px`,
  width: '100%',
});

const PartNumber = styled('div')(({ theme: { spacing } }) => ({
  borderRadius: '20px',
  padding: spacing(2, 5),
  backgroundColor: Colors.BLUE_0,
  border: `1px solid ${Colors.BLUE_10}`,
  whiteSpace: 'nowrap',
}));

const ShowMoreButton = styled(Button, {
  shouldForwardProp: propName => propName !== 'maxWidth',
})<{ maxWidth: boolean }>(({ maxWidth }) => ({
  fontSize: '10px',
  maxWidth: maxWidth ? `${SHOW_MORE_LABEL_WIDTH}px` : '',
}));

const StyledMenu = styled(Menu)(({ theme: { spacing } }) => ({
  '& .MuiMenu-list': {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(3),
    padding: spacing(4),
  },
}));
