import React from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export default React.memo(function ComputationResult() {
  const classes = useStyles();

  return (
    <Paper className={classes.box}>
      <Typography variant="h1" className={classes.boxHeader}>
        Results of the data analysis
      </Typography>
      <div className={classes.boxContent}>
        <Typography component="p">
          This screen shows the results of the data analysis workflow.
        </Typography>
      </div>
    </Paper>
  );
});

const useStyles = makeStylesHook({
  box: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    margin: '32px',
    minWidth: '400px',
    maxWidth: '750px',
  },
  boxHeader: {
    color: '#fff',
    backgroundColor: Colors.SIMULATION_DETAILS_APP,
    padding: '8px 16px',
  },
  boxContent: {
    padding: '16px',
  },
});
