// Make sure zIndex for various parts of the Simulation Details UI doesn't clash
// and is in correct order.

export default {
  // Render tip wastes first. This way they are rendered below everything else.
  // This is important in cases when plates are physically above tip wastes when in a
  // plate reader for example. See e.g. final steps of
  // https://antha.ninja/#/simulation-details/8e1fffed-5104-47a2-9cf2-5000a07e8414/preview
  tipwaste: 0,
  emptyDeckPositionOutline: 1,
  tipbox: 2,
  plate: 3,
  lid: 4,
  cap: 4, // Assuming that there should not have both lid and cap at a position.
  edgesSvgOverlay: 5,
  prompt: 6,
  workspaceControls: 7,
  // Simulation stage stacking context
  stageLine: 1,
  stageBackground: -1,
  stageNumber: 2,
};
