import React, { useEffect, useMemo, useRef } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PlayIcon from '@mui/icons-material/PlayArrow';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import FactorList, {
  FactorListItem,
  FactorListMutualExclusionItem,
} from 'client/app/components/DOEBuilder/components/FactorList';
import { ADD_FACTOR_TOOLTIP } from 'client/app/components/DOEBuilder/components/Factors';
import { FactorParameterInfo } from 'client/app/components/DOEFactorForm/types';
import { FactorItem } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import InlineHelp from 'common/ui/components/InlineHelp/InlineHelp';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { ElementsListIcon } from 'common/ui/icons/ElementsListIcon';

type FactorGroupProps = {
  elementName: string;
  parameterName: string;
  factors: FactorItem[];
  parameterInfo: FactorParameterInfo;
  active: boolean;
  isReadonly: boolean;
  addItem: () => void;
  editFactor: (factorId: string) => void;
  editMutualExclusion: (name: string, factorId?: string) => void;
};

export function FactorGroup({
  elementName,
  parameterName,
  factors,
  parameterInfo,
  active,
  isReadonly,
  addItem,
  editFactor,
  editMutualExclusion,
}: FactorGroupProps) {
  const classes = useStyles();

  const ref = useRef<HTMLLIElement>(null);

  const factorItems = useFactorListItems(factors);

  useEffect(() => {
    if (active) {
      ref.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  }, [active]);

  return (
    <li className={cx(classes.factorGroupListItem, { active })} ref={ref}>
      <section className={classes.factorGroupTitle}>
        <ElementsListIcon className={classes.elementsIcon} />
        <Typography variant="subtitle2">{elementName}</Typography>
        <PlayIcon className={classes.playIcon} />
        <Typography variant="subtitle2">{parameterName}</Typography>
      </section>
      <section className={classes.factorGroupSubTitle}>
        {factors.length > 0 ? (
          <FactorList
            items={factorItems}
            editFactor={editFactor}
            editMutualExclusion={editMutualExclusion}
            isReadonly={isReadonly}
          />
        ) : (
          <Typography variant="body2">
            There is no factor defined for this element parameter.
          </Typography>
        )}
      </section>
      <section className={classes.buttons}>
        <Tooltip title={ADD_FACTOR_TOOLTIP}>
          <Button
            color="primary"
            variant={active ? 'primary' : 'secondary'}
            startIcon={<AddIcon />}
            onClick={() => addItem()}
            disabled={
              isReadonly || (!parameterInfo.allowMultipleFactors && factors.length > 0)
            }
          >
            Add
          </Button>
        </Tooltip>
      </section>
    </li>
  );
}

function useFactorListItems(factors: FactorItem[]): FactorListItem[] {
  return useMemo<FactorListItem[]>(() => {
    const result: FactorListItem[] = [];
    const groups = new Map<string, FactorListMutualExclusionItem>();

    // Try to be careful to retain the order of factors in the result.
    for (const factor of factors) {
      if (factor.mutualExclusionGroup === undefined) {
        result.push({
          type: 'factor',
          factor,
        });
      } else {
        const group = groups.get(factor.mutualExclusionGroup);

        if (group) {
          group.factors.push(factor);
        } else {
          const group: FactorListMutualExclusionItem = {
            type: 'mutualExclusion',
            factors: [factor],
            name: factor.mutualExclusionGroup,
          };
          groups.set(factor.mutualExclusionGroup, group);
          result.push(group);
        }
      }
    }

    return result;
  }, [factors]);
}

type CustomFactorsGroupProps = {
  customFactors: FactorItem[];
  isReadonly: boolean;
  editFactor: (factorId: string) => void;
  addItem: () => void;
  editMutualExclusion: (name: string, factorId?: string) => void;
};

export const CustomFactorsGroup = ({
  customFactors,
  isReadonly,
  editFactor,
  addItem,
  editMutualExclusion,
}: CustomFactorsGroupProps) => {
  const classes = useStyles();
  const factorItems = useFactorListItems(customFactors);

  return (
    <li className={classes.factorGroupListItem}>
      <section className={classes.factorGroupTitle}>
        <Typography variant="subtitle2">Custom Factors</Typography>
        <InlineHelp heading="Custom Factors">
          Custom factors are those that do not directly map to a parameter in your
          workflow, for example the day of the week or the pH of a buffer, but that you
          still want to consider as part of your design.
        </InlineHelp>
      </section>
      <section className={classes.factorGroupSubTitle}>
        {customFactors.length > 0 ? (
          <FactorList
            items={factorItems}
            editFactor={factorId => editFactor(factorId)}
            isReadonly={isReadonly}
            editMutualExclusion={editMutualExclusion}
          />
        ) : (
          <Typography variant="body2">There are no custom factors defined.</Typography>
        )}
      </section>
      <section className={classes.buttons}>
        <Tooltip title={ADD_FACTOR_TOOLTIP}>
          <Button
            color="primary"
            variant="secondary"
            startIcon={<AddIcon />}
            onClick={() => addItem()}
            disabled={isReadonly}
          >
            Add
          </Button>
        </Tooltip>
      </section>
    </li>
  );
};

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  factorGroupListItem: {
    display: 'grid',
    gridTemplateAreas: "'title buttons' 'subtitle subtitle'",
    rowGap: spacing(5),
    padding: spacing(5, 6, 6),

    backgroundColor: Colors.GREY_5,

    borderRadius: '6px',
    border: `1px solid ${Colors.GREY_20}`,

    '&.active': {
      background: Colors.BLUE_0,
      border: `2px solid ${Colors.BLUE_20}`,
    },

    '& .levels': {
      alignSelf: 'center',
    },
  },
  factorGroupTitle: {
    gridArea: 'title',
    display: 'flex',
    gap: spacing(3),
    alignItems: 'center',
    '.active &': {
      color: palette.primary.dark,
    },
  },
  factorGroupSubTitle: {
    gridArea: 'subtitle',
    color: palette.text.secondary,
  },
  elementsIcon: {
    fontSize: '13px',
  },
  playIcon: {
    fontSize: '10px',
    margin: `0 ${spacing(1)}px`,
    color: Colors.GREY_40,
    '.active &': {
      color: Colors.BLUE_20,
    },
  },
  buttons: {
    gridAre: 'buttons',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: spacing(4),
  },
}));
