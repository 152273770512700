import React, { useCallback, useMemo } from 'react';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import cx from 'classnames';

import { indexBy } from 'common/lib/data';
import { Option } from 'common/types/Option';
import Colors from 'common/ui/Colors';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type { Option } from 'common/types/Option';
type Props<T> = {
  /** This should be equivalent to the label of the value you want to select. */
  valueLabel: string;
  options: Option<T>[];
  onChange: (value?: T) => void;
  placeholderValue?: T;
  className?: string;
  disableUnderline?: boolean;
  renderLabel?: (option: Option<T>) => React.ReactNode;
  renderValue?: (value: any) => React.ReactNode;
  helperText?: string;
  label?: string;
  size?: 'small' | 'medium';
  variant?: SelectProps['variant'];
  margin?: SelectProps['margin'];
} & Omit<ParameterEditorBaseProps<T>, 'value'>;

export default function Dropdown<T>(props: Props<T>) {
  const classes = useStyles();
  const labelOptionMap = useMemo(() => indexBy(props.options, 'label'), [props.options]);

  const onChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const newValue = event.target.value;
      if (newValue === props.placeholder) {
        props.onChange(props.placeholderValue);
        return;
      }
      const option = labelOptionMap[newValue];
      props.onChange(option.value);
    },
    [labelOptionMap, props],
  );

  return (
    <FormControl
      className={classes.formControl}
      error={props.hasError}
      variant={props.variant ?? 'outlined'}
      size={props.size ?? 'small'}
      disabled={props.isDisabled}
      margin={props.margin}
    >
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <Select
        // We use the label as the value and map the label to the value on change so that
        // we can support complex objects. MUI only supports string and number values.
        value={(props.valueLabel || props.placeholder) ?? ''}
        disabled={props.isDisabled}
        onChange={onChange}
        displayEmpty
        fullWidth
        margin={props.margin}
        className={props.className}
        classes={{
          icon: classes.icon,
          select: cx({
            [classes.secondaryTextColor]: !props.valueLabel && props.placeholder,
          }),
        }}
        // Workaround for https://github.com/mui/material-ui/issues/22462
        {...(props.variant && props.variant !== 'outlined'
          ? { disableUnderline: props.disableUnderline }
          : {})}
        error={props.hasError}
        renderValue={props.renderValue}
        label={props.label}
      >
        {props.placeholder && (
          <MenuItem
            className={classes.secondaryTextColor}
            value={props.placeholder}
            disabled={props.isRequired}
          >
            {props.placeholder}
          </MenuItem>
        )}
        {props.options.map(option => (
          <MenuItem key={option.label} value={option.label}>
            {props.renderLabel ? props.renderLabel(option) : option.label}
          </MenuItem>
        ))}
      </Select>
      {props.helperText && (
        <FormHelperText className={classes.formHelperText}>
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

const useStyles = makeStylesHook(theme => ({
  secondaryTextColor: {
    color: Colors.TEXT_SECONDARY,
  },
  formHelperText: {
    marginLeft: 0,
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: '100%',
  },
  icon: {
    color: Colors.TEXT_PRIMARY,
    top: 'auto', // By default, MUI-Select will adjust the top to just below centre, but we want it centered.
  },
}));
