import { useContext, useEffect, useState } from 'react';

import { EarlyTokenContext } from 'client/app/apps/authentication/components/EarlyToken';
import { wellKnownURLParams } from 'client/app/apps/authentication/urlParams';
import { clearAuth0Config, getAuth0Config, NAMESPACE } from 'common/ui/lib/auth0';
import { type Auth0Config as Auth0ConfigBase } from 'common/ui/lib/auth0';

const AUTH0_CLIENT_DATA_LOCAL_STORAGE_KEY = `${NAMESPACE}.client`;

// See antha-com/appserver/src/lib/middleware/wellKnown.ts
type Auth0Config = Omit<Auth0ConfigBase, 'uiClientId' | 'serverClientId'>;

export type Auth0ClientData = {
  connection: string;
  clientId: string;
  cached: boolean;
  version?: number;
};

export const emptyClientData: Auth0ClientData = {
  connection: '',
  clientId: '',
  cached: false,
  version: 1,
};

export function useAuth0ClientData({ url }: { url?: URL } = {}): {
  clientData: Auth0ClientData;
  setClientData: React.Dispatch<React.SetStateAction<Auth0ClientData>>;
  storeClientData: () => void;
  clearClientData: () => void;
} {
  const getDefaultClientData = (): Auth0ClientData => {
    const clientDataFromURLResult:
      | { type: 'client_data'; clientData: Auth0ClientData }
      | { type: 'no_client_data'; code: string } = (function () {
      if (!url) {
        return { type: 'no_client_data', code: 'url_undefined' };
      }

      if (url.searchParams.get('error')) {
        return { type: 'client_data', clientData: emptyClientData };
      }

      // This is a redirection after a login attempt.
      // The 'state_cid' and 'state_conn' parameters are added in the 'redirect_uri'
      // set in the 'authorizationParams' of the 'Auth0Provider'.
      const clientIdFromURL = url.searchParams.get(wellKnownURLParams.clientId);
      const connectionFromURL =
        // Make sure the connection override takes precedence if set.
        url.searchParams.get(wellKnownURLParams.connectionOverride) ??
        url.searchParams.get(wellKnownURLParams.connection);

      if (clientIdFromURL && connectionFromURL) {
        const clientData = {
          clientId: clientIdFromURL,
          connection: connectionFromURL,
          cached: false,
          version: 1,
        };
        localStorage.setItem(
          AUTH0_CLIENT_DATA_LOCAL_STORAGE_KEY,
          JSON.stringify({ ...clientData, cached: true }),
        );
        return { type: 'client_data', clientData };
      }

      return { type: 'no_client_data', code: 'client_id_undefined' };
    })();

    if (clientDataFromURLResult.type === 'client_data') {
      return clientDataFromURLResult.clientData;
    }

    const clientDataFromLocalStorage = localStorage.getItem(
      AUTH0_CLIENT_DATA_LOCAL_STORAGE_KEY,
    );
    if (clientDataFromLocalStorage) {
      const clientData = JSON.parse(clientDataFromLocalStorage);
      if (!('version' in clientData)) {
        const clientDataV1 = { ...clientData, cached: true, version: 1 };
        localStorage.setItem(
          AUTH0_CLIENT_DATA_LOCAL_STORAGE_KEY,
          JSON.stringify(clientDataV1),
        );
        return clientDataV1;
      }

      return clientData;
    }

    return emptyClientData;
  };

  const [clientData, setClientData] = useState<Auth0ClientData>(getDefaultClientData());
  // WARNING: Don't be tempted to use the following code:
  // ```
  // const [clientData, setClientData] = useStateWithLocalStorage<ClientData>(
  //   AUTH0_CLIENT_DATA_LOCAL_STORAGE_KEY,
  //   newUniversalLoginEnabled ? getDefaultClientId() : emptyClientData,
  // );
  // ```
  // because it will introduce the following behaviour: users won't see the
  // organization page if they navigate back to that page but will be redirected
  // to Auth0 right away. We want to store that information only _after_ a
  // successful login. And that's why the 'onLoginSuccess' callback exists.
  const storeClientData = () => {
    // Save the client ID only at this point and NOT when it's resolved from the
    // org name. This makes it possible for users to navigate back to the organization page
    // without being redirected to the authentication screen.
    localStorage.setItem(AUTH0_CLIENT_DATA_LOCAL_STORAGE_KEY, JSON.stringify(clientData));
  };

  const clearClientData = () => {
    localStorage.removeItem(AUTH0_CLIENT_DATA_LOCAL_STORAGE_KEY);
    setClientData(emptyClientData);
  };

  return {
    clientData,
    setClientData,
    storeClientData,
    clearClientData,
  };
}

export function useAuth0Config(): {
  auth0Config: Auth0Config | undefined | 'fetch_auth0_config_error';
  clearAuth0Config: () => void;
} {
  const [auth0Config, setAuth0Config] = useState<
    undefined | Auth0Config | 'fetch_auth0_config_error'
  >();
  useEffect(() => {
    let mounted = true;
    (async () => {
      try {
        let config = await getAuth0Config<Auth0Config>();
        if (config?.version !== 1) {
          // The cached Auth0 config is old.
          // Clear the cache to force a network call to get an update version.
          config = await getAuth0Config<Auth0Config>({
            ignoreCache: true,
          });
        }
        if (mounted) {
          setAuth0Config(config);
        }
      } catch (_) {
        if (mounted) {
          setAuth0Config('fetch_auth0_config_error');
        }
      }
    })();
    return () => {
      mounted = false;
    };
  }, []);

  return {
    auth0Config,
    clearAuth0Config,
  };
}

export function useEarlyTokenContext() {
  const { earlyGetAccessTokenSilently } = useContext(EarlyTokenContext);
  return { earlyGetAccessTokenSilently };
}

export { getAuth0Config } from 'common/ui/lib/auth0';
