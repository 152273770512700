import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { GraphQLDevice } from 'client/app/api/deviceFromGraphql';
import DeviceLibrary from 'client/app/components/DeviceLibrary/DeviceLibrary';
import { useDevices } from 'client/app/components/DeviceLibrary/useDevices';
import { DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import { Device, DeviceRunConfig } from 'common/types/device';
import Colors from 'common/ui/Colors';
import ConfigSelector, {
  DeviceConfigSelection,
} from 'common/ui/components/ConfigSelector';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import DialogActions from 'common/ui/components/Dialog/DialogActions';
import SelectionActions from 'common/ui/components/Dialog/SelectionActions';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  preselectedDevices: DeviceCommon[];
  preSelectedDeviceConfigs?: DeviceConfigSelection;
  isEnabledShowManualDevices?: boolean;
  // Override certain attributes for uploading a file.
} & DialogProps<{
  selectedDevices: GraphQLDevice[];
  selectedConfigs: DeviceConfigSelection;
} | null>;

export default function DeviceSelectorDialog(props: Props) {
  const classes = useStyles();
  const {
    onClose,
    preselectedDevices,
    preSelectedDeviceConfigs,
    isOpen,
    isEnabledShowManualDevices,
  } = props;
  const { loading, error, data, refetch } = useDevices();
  const devices = useMemo(() => data?.devices ?? [], [data]);
  const [selectedDevices, setSelectedDevices] = useState<DeviceCommon[]>(
    preselectedDevices ?? [],
  );
  const [selectedConfigs, setSelectedConfigs] = useState<DeviceConfigSelection>(
    preSelectedDeviceConfigs ?? {},
  );
  const selectedDeviceIds = useMemo(
    () => selectedDevices.map(d => d.id),
    [selectedDevices],
  );
  const [deviceIdWithoutSelectedConfig, setDeviceIDWithoutSelectedConfig] = useState('');
  const handleClose = useCallback(() => {
    onClose(null);
  }, [onClose]);

  // Correctly restore the selected devices when re-opening the dialog.
  useEffect(() => {
    setSelectedDevices(preselectedDevices);
  }, [isOpen, preselectedDevices]);

  const handleConfirm = useCallback(() => {
    onClose({ selectedDevices, selectedConfigs });
  }, [onClose, selectedDevices, selectedConfigs]);

  const onSelect = (id: string) => {
    const device = devices.find(d => d.id === id) as DeviceCommon;
    if (deviceIdWithoutSelectedConfig !== id) {
      setDeviceIDWithoutSelectedConfig('');
    }
    setSelectedDevices(sd => {
      const idx = sd.findIndex(d => d.id === id);
      if (idx >= 0) {
        const newSd = [...sd];
        newSd.splice(idx, 1);
        return newSd;
      }

      //  if the selected device needs a config, fail and setDeviceIDWithoutSelectedConfig
      if (id && device.runConfigSummaries.length > 1 && !selectedConfigs[id]) {
        setDeviceIDWithoutSelectedConfig(id);
        return sd;
      }

      // if the selected device has only one possible config, select that config immediately
      if (id && device.runConfigSummaries.length === 1) {
        setSelectedConfigs(sc => ({
          ...sc,
          [id]: device.runConfigSummaries[0].id,
        }));
      }
      return [...sd, device];
    });
  };

  const handleSelectConfig = useCallback(
    (deviceId: string) => (deviceConfig?: DeviceRunConfig) => {
      // Prevent users from selecting the default message ("Please select a config")
      if (!deviceConfig) {
        return;
      }
      // User selected a config, thus remove the error
      setDeviceIDWithoutSelectedConfig('');
      setSelectedConfigs(sc => ({ ...sc, [deviceId]: deviceConfig.id }));

      setSelectedDevices(sd => {
        // If we have already selected the device and are only change config,
        // do not add to selection
        if (sd.some(d => d.id === deviceId)) {
          return sd;
        }
        return [...sd, devices.find(d => d.id === deviceId) as DeviceCommon];
      });
    },
    [devices],
  );

  const deviceActions = useCallback(
    (device: Device) => {
      return (
        <ConfigSelector
          device={device}
          selectedConfigs={selectedConfigs}
          handleSelectConfig={handleSelectConfig}
          hasConfigError={deviceIdWithoutSelectedConfig === device.id}
        />
      );
    },
    [handleSelectConfig, selectedConfigs, deviceIdWithoutSelectedConfig],
  );

  const handleClear = useCallback(() => {
    setSelectedConfigs({});
    setSelectedDevices([]);
    setDeviceIDWithoutSelectedConfig('');
  }, []);

  if (error) {
    return <GraphQLErrorPanel error={error} onRetry={refetch} />;
  }

  const target = isEnabledShowManualDevices ? 'execution mode' : 'devices';
  return (
    <ComplexActionDialog
      title={`Select ${target}`}
      isOpen={isOpen}
      onClose={handleClose}
      showCloseButton
      paperClassName={classes.paper}
      fullHeight
      content={
        <DeviceLibrary
          isLoading={loading}
          onSelect={onSelect}
          devices={devices}
          renderActions={deviceActions}
          selectedDeviceIds={selectedDeviceIds}
          selectedConfigs={selectedConfigs}
          showSelectionStatus
          showManualDeviceRelatedCards={isEnabledShowManualDevices}
          smallCard
          dialog
        />
      }
      dialogActions={
        <DialogActions>
          <SelectionActions
            selectedItems={selectedDevices.length}
            handleClear={handleClear}
            handleConfirm={handleConfirm}
            itemLabel={target}
            primaryActionLabel="Save"
          />
        </DialogActions>
      }
    />
  );
}

const useStyles = makeStylesHook({
  paper: {
    backgroundColor: Colors.GREY_5,
  },
});
