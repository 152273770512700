import idx from 'idx';

import { PlateType } from 'common/types/plateType';

export const FieldsGetter: {
  [field in PlateFormFields]: (plate: PlateType) => unknown;
} = {
  name: (plate: PlateType) => idx(plate, _ => _.name),
  description: (plate: PlateType) => idx(plate, _ => _.description),
  manufacturer: (plate: PlateType) => idx(plate, _ => _.manufacturer),
  partNr: (plate: PlateType) => idx(plate, _ => _.catalogNumber),
  type: (plate: PlateType) => idx(plate, _ => _.type),
  rows: (plate: PlateType) => idx(plate, _ => _.rows),
  columns: (plate: PlateType) => idx(plate, _ => _.columns),
  defaultResidualVolume: (plate: PlateType) => idx(plate, _ => _.defaultResidualVolume),
  volume: (plate: PlateType) => idx(plate, _ => _.wellShape.volumeOverrideUl),
  shape: (plate: PlateType) => idx(plate, _ => _.wellShape.type),
  bottomShape: (plate: PlateType) => idx(plate, _ => _.wellShape.bottomType),
  sepX: (plate: PlateType) => idx(plate, _ => _.wellOffset.x),
  sepY: (plate: PlateType) => idx(plate, _ => _.wellOffset.y),
  plateX: (plate: PlateType) => idx(plate, _ => _.dimension.x),
  plateY: (plate: PlateType) => idx(plate, _ => _.dimension.y),
  plateZ: (plate: PlateType) => idx(plate, _ => _.dimension.z),
  wellX: (plate: PlateType) => idx(plate, _ => _.wellShape.dimensionMm.x),
  wellY: (plate: PlateType) => idx(plate, _ => _.wellShape.dimensionMm.y),
  wellZ: (plate: PlateType) => idx(plate, _ => _.wellShape.dimensionMm.z),
  wellBottomThickness: (plate: PlateType) => idx(plate, _ => _.wellBottomOffset),
  startX: (plate: PlateType) => idx(plate, _ => _.wellStart.x),
  startY: (plate: PlateType) => idx(plate, _ => _.wellStart.y),
  startZ: (plate: PlateType) => idx(plate, _ => _.wellStart.z),
  containerType: (plate: PlateType) => idx(plate, _ => _.containerType),
} as const;

export type PlateFormFields =
  | 'name'
  | 'description'
  | 'manufacturer'
  | 'partNr'
  | 'type'
  | 'rows'
  | 'columns'
  | 'defaultResidualVolume'
  | 'volume'
  | 'shape'
  | 'bottomShape'
  | 'sepX'
  | 'sepY'
  | 'plateX'
  | 'plateY'
  | 'plateZ'
  | 'wellX'
  | 'wellY'
  | 'wellZ'
  | 'wellBottomThickness'
  | 'startX'
  | 'startY'
  | 'startZ'
  | 'containerType';
