import React, { useCallback, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MenuIcon from '@mui/icons-material/MoreVertOutlined';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import ResinAssignForm from 'client/app/components/Parameters/FiltrationPlateLayout/components/ResinAssignForm';
import {
  useFilterPlateEditorState,
  WellRegion,
} from 'client/app/components/Parameters/FiltrationPlateLayout/lib/editorState';
import { useReadonly } from 'client/app/components/Parameters/FiltrationPlateLayout/lib/parameterUtils';
import Button from 'common/ui/components/Button';
import IconButton from 'common/ui/components/IconButton';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { AddSelectedWellsIcon } from 'common/ui/icons/AddSelectedWellsIcon';
import AssignToDuplicateWellsIcon from 'common/ui/icons/AssignToDuplicateWellsIcon';
import { RemoveSelectedWellsIcon } from 'common/ui/icons/RemoveSelectedWellsIcon';

type Props = {
  liquidColors: LiquidColors;
};

export default function ResinCards({ liquidColors }: Props) {
  const classes = useStyle();

  const {
    state: { selectedWells, isEditing, wellRegions },
    assignWellsToNewRegion,
  } = useFilterPlateEditorState();
  const isReadonly = useReadonly();
  const isDisabled = selectedWells.length === 0;

  return (
    <>
      {isEditing ? (
        <Box className={classes.outerContainer}>
          <ResinAssignForm liquidColors={liquidColors} isReadonly={isReadonly} />
        </Box>
      ) : (
        <Box className={classes.outerContainer}>
          <Box className={classes.container}>
            {wellRegions.map(region => (
              <ResinCardItem
                key={region.id}
                wellRegion={region}
                isReadonly={isReadonly}
              />
            ))}
          </Box>
          <Button
            color="primary"
            className={classes.assignResinBtn}
            variant="secondary"
            disabled={isDisabled || isReadonly}
            onClick={assignWellsToNewRegion}
            startIcon={<AddIcon fontSize="small" />}
          >
            Assign to new resin
          </Button>
        </Box>
      )}
    </>
  );
}

type ResinCardItemProps = {
  wellRegion: WellRegion;
  isReadonly: boolean;
};

function ResinCardItem({ wellRegion, isReadonly }: ResinCardItemProps) {
  const {
    state: { selectedWells, minWellCount, error: stateError },
    editResinCard,
    removeResinCard,
    assignWellsToRegion,
    assignWellsToDuplicateRegion,
    removeWellsFromRegion,
  } = useFilterPlateEditorState();

  const classes = useStyle({
    resinColor: wellRegion.resinColor,
    areWellsValid: wellRegion.wells.length >= minWellCount,
  });

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuItem = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);
  const handleCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const hasWellsToAdd = selectedWells.some(well => !wellRegion.wells.includes(well));
  const hasWellsToRemove = selectedWells.some(well => wellRegion.wells.includes(well));
  const error = stateError?.wellRegions[wellRegion.id];

  return (
    <Paper className={classes.card}>
      <header>
        <div className={classes.resinColor} />
        <Typography variant="subtitle2">{wellRegion.resinName}</Typography>
        <Box display="flex" justifyContent="flex-end" flexGrow={1}>
          <IconButton
            size="small"
            icon={<MenuIcon />}
            onClick={handleMenuItem}
            title="More Actions"
          />
          <Menu
            anchorEl={menuAnchorEl}
            keepMounted
            open={!!menuAnchorEl}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItemWithIcon
              onClick={() => editResinCard(wellRegion.id)}
              icon={<EditOutlinedIcon />}
              text={isReadonly ? 'View' : 'Edit'}
            />
            {isReadonly ? null : (
              <MenuItemWithIcon
                onClick={() => removeResinCard(wellRegion.id)}
                icon={<DeleteIcon />}
                text="Delete"
              />
            )}
          </Menu>
        </Box>
      </header>
      <main>
        <section>
          <Box display="flex" alignItems="baseline" gap="4px">
            <Typography color="textSecondary" variant="caption">
              Wells:
            </Typography>
            <Typography className={classes.wellValidation} variant="body1">
              {wellRegion.wells.length} / {minWellCount}
            </Typography>
          </Box>
          {error?.wells && (
            <Typography variant="caption" color="error" className={classes.cardError}>
              {error.wells}
            </Typography>
          )}
        </section>
        <section>
          <Box display="flex" alignItems="baseline" gap="4px">
            <Typography color="textSecondary" variant="caption">
              Resin Volume:
            </Typography>
            <Typography color={error?.volume ? 'error' : 'textPrimary'} variant="body1">
              {wellRegion.resinVolume}ul
            </Typography>
          </Box>
          {error?.volume && (
            <Typography variant="caption" color="error" className={classes.cardError}>
              {error.volume}
            </Typography>
          )}
        </section>
        {wellRegion.metaItems.map(item => (
          <section key={item.id}>
            <Box display="flex" alignItems="baseline" gap="4px">
              <Typography color="textSecondary" variant="caption">
                {item.key}:
              </Typography>
              <Typography color="textPrimary" variant="body1">
                {item.value}
              </Typography>
            </Box>
          </section>
        ))}
      </main>
      {selectedWells.length > 0 && (
        <footer>
          {hasWellsToAdd && (
            <Button
              variant="secondary"
              startIcon={<AddSelectedWellsIcon />}
              onClick={() => assignWellsToRegion(wellRegion.id)}
            >
              Add selected wells
            </Button>
          )}
          <Button
            variant="secondary"
            startIcon={<AssignToDuplicateWellsIcon />}
            onClick={() => assignWellsToDuplicateRegion(wellRegion.id)}
          >
            Assign to duplicate
          </Button>
          {hasWellsToRemove && (
            <Button
              variant="secondary"
              startIcon={<RemoveSelectedWellsIcon />}
              onClick={() => removeWellsFromRegion(wellRegion.id)}
            >
              Remove selected wells
            </Button>
          )}
        </footer>
      )}
    </Paper>
  );
}

export const SMALL_LAPTOP_RESOLUTION = 1100;
export const CARD_WIDTH = 260;
const SCROLL_WIDTH = 20;

const useStyle = makeStylesHook<string, { resinColor: string; areWellsValid: boolean }>(
  ({ palette, spacing, breakpoints }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      [breakpoints.down(SMALL_LAPTOP_RESOLUTION)]: {
        flex: 2,
      },
      [breakpoints.up(SMALL_LAPTOP_RESOLUTION)]: {
        minWidth: CARD_WIDTH + SCROLL_WIDTH,
      },
      overflowY: 'auto',
    },
    outerContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: spacing(4),
      paddingRight: spacing(2),
      [breakpoints.down(SMALL_LAPTOP_RESOLUTION)]: {
        flex: 2,
      },
      [breakpoints.up(SMALL_LAPTOP_RESOLUTION)]: {
        minWidth: CARD_WIDTH + SCROLL_WIDTH,
      },
    },
    assignResinBtn: {
      textTransform: 'uppercase',
      [breakpoints.up(SMALL_LAPTOP_RESOLUTION)]: {
        width: CARD_WIDTH,
      },
    },
    card: {
      [breakpoints.up(SMALL_LAPTOP_RESOLUTION)]: {
        width: CARD_WIDTH,
      },
      border: `1px solid ${palette.divider}`,
      borderRadius: '6px',
      padding: spacing(4),
      marginBottom: spacing(4),
      boxShadow: 'none',

      '& > header': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: spacing(5),
      },
      '& > main': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        '& > section:not(:last-child)': {
          marginBottom: spacing(2),
        },
      },
      '& > footer': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'streatch',
        gap: spacing(3),
        paddingTop: spacing(3),
      },
    },
    resinColor: ({ resinColor }) => ({
      flexShrink: 0,
      background: resinColor,
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      marginRight: spacing(4),
    }),
    wellValidation: ({ areWellsValid }) => ({
      color: areWellsValid ? palette.success.main : palette.error.main,
    }),
    cardError: {
      display: 'block',
      marginBottom: spacing(2),
    },
  }),
);
