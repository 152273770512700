import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SvgDatasetSettingsNoResults from 'client/app/apps/experiments/dataset-settings/SvgDatasetSettingsNoResults';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = { title: string; subtitle: string };

export default function DatasetSettingsError({ title, subtitle }: Props) {
  const classes = useStyles();
  return (
    <Box mt={5} ml={2} mb={6} className={classes.box}>
      <div>
        <Typography paragraph variant="subtitle2" className={classes.title}>
          {title}
        </Typography>
        <Typography>{subtitle}</Typography>
      </div>
      <SvgDatasetSettingsNoResults className={classes.svg} />
    </Box>
  );
}

const useStyles = makeStylesHook(theme => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },

  svg: {
    flexGrow: 1,
  },
}));
