import React, { useState } from 'react';

import Divider from '@mui/material/Divider';
import cx from 'classnames';

import CreateExecutionButton from 'client/app/apps/simulation-details/CreateExecutionButton';
import { hasExecution } from 'client/app/apps/simulation-details/dataUtils';
import SimulationHeaderDropdown from 'client/app/apps/simulation-details/SimulationHeaderDropdown';
import FavoriteStar from 'client/app/components/FavoriteStar';
import {
  SimulationOrExecutionStatusesEnum,
  SimulationQuery,
  WorkflowEditModeEnum,
} from 'client/app/gql';
import useIsDOEAllowedForSimulation from 'client/app/hooks/useIsDOEAllowedForSimulation';
import { formatShortTransitiveSimulationStatus } from 'client/app/lib/formatTransitiveSimulationStatus';
import getWorkflowPropsBySource from 'client/app/lib/workflow/getWorkflowPropsBySource';
import { IntercomTourIDs } from 'common/lib/intercom';
import LinearProgress from 'common/ui/components/LinearProgress';
import RouteButton from 'common/ui/components/navigation/RouteButton';
import CommonHeader, {
  CommonHeaderInfo,
} from 'common/ui/components/simulation-details/CommonHeader';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  simulation: SimulationQuery['simulation'];
};

const SimulationDetailsHeader = React.memo(({ simulation }: Props) => {
  const classes = useStyles();
  // Convert to a format the CommonHeader understands.
  // The CommonHeader is used in AnthaHub, too.
  const simulationHeaderInfo: CommonHeaderInfo = {
    name: 'Simulation details',
    submitterName: simulation.user.displayName,
    dateCreated: new Date(simulation.startedAt),
    dateFinished: simulation.finishedAt ? new Date(simulation.finishedAt) : undefined,
  };

  const DOESimulationType = useIsDOEAllowedForSimulation(
    simulation.workflow.workflow,
    simulation.source,
  );

  // Useful when the UI controls inside the header kick off requests
  const [headerLoading, setHeaderLoading] = useState<boolean>(false);

  const favoriteStarIcon = (
    <FavoriteStar
      isFavoritedByCurrentUser={simulation.isFavoritedByCurrentUser ?? false}
      favoritedBy={simulation.favoritedBy ?? []}
      simulationId={simulation.id}
      size="small"
    />
  );

  const { route: workflowRoute } = getWorkflowPropsBySource(simulation.workflow.source);

  const simulationWithExecution = hasExecution(simulation);

  const hasTasksToBeExecuted = simulation.tasks ? simulation.tasks.length > 0 : false;

  const isEditableWorkflow =
    simulation.editableWorkflow?.version === simulation.workflow.version &&
    simulation.editableWorkflow?.editMode ===
      WorkflowEditModeEnum.ENABLED_LATEST_OWNED_BY_ME;

  const simulationStatus = formatShortTransitiveSimulationStatus(
    simulationWithExecution
      ? SimulationOrExecutionStatusesEnum.SIMULATION_SUCCESS
      : simulation.transitiveStatus,
  );

  return (
    <>
      {headerLoading && <LinearProgress className={classes.topLinearProgress} />}
      <CommonHeader
        headerInfo={simulationHeaderInfo}
        status={simulationStatus}
        simulationSeriesPart={simulation.simulationSeriesPart}
        estimatedTimeSeconds={simulation.timeEstimateSeconds || null}
        createdAtAction="Created"
        startAdornment={favoriteStarIcon}
        endAdornment={
          <div className={classes.buttonsBox}>
            <div
              data-intercom-target={`${IntercomTourIDs.SIMULATION_OPTIONS}-view-snapshot`}
            >
              <RouteButton
                data-heap-tracking="return-to-workflow-button"
                label={isEditableWorkflow ? 'Continue editing' : 'View snapshot'}
                route={workflowRoute}
                routeParam={{
                  workflowId: isEditableWorkflow
                    ? simulation.editableWorkflow!.id
                    : simulation.workflow.id,
                }}
              />
            </div>
            <Divider
              orientation="vertical"
              className={cx(classes.verticalDivider, classes.firstDivider)}
              flexItem
            />
            <SimulationHeaderDropdown
              simulation={simulation}
              onLoadingChange={setHeaderLoading}
              showCreateDOEButton={
                simulation.status === 'COMPLETED' && DOESimulationType === 'legacy'
              }
            />
            <Divider
              orientation="vertical"
              className={cx(classes.verticalDivider, classes.secondDivider)}
              flexItem
            />
            {hasTasksToBeExecuted ? (
              <CreateExecutionButton
                simulationId={simulation.id}
                hasStartedExecution={simulation.execution !== null}
              />
            ) : null}
          </div>
        }
      />
    </>
  );
});

const useStyles = makeStylesHook(theme => ({
  buttonsBox: {
    display: 'flex',
    // Center buttons vertically
    alignItems: 'center',
    // Place the button on the right
    justifyContent: 'flex-end',
  },
  verticalDivider: {
    // Center vertically
    alignSelf: 'center',
    height: '24px',
  },
  firstDivider: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(5),
  },
  secondDivider: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
  },
  topLinearProgress: {
    // Display the progress bar at the top of the header,
    // don't push content down.
    position: 'absolute',
    width: '100%',
  },
}));

export default SimulationDetailsHeader;
