import React, { useCallback, useEffect } from 'react';

import { useElementInstance } from 'client/app/apps/workflow-builder/lib/useElementInstance';
import { PanelWithoutScroll } from 'client/app/apps/workflow-builder/panels/Panel';
import FiltrationDesignInfo from 'client/app/components/Parameters/FiltrationPlateLayout/components/FiltrationDesignInfo';
import HeadlineParametersRow from 'client/app/components/Parameters/FiltrationPlateLayout/components/HeadlineParametersRow';
import WellRegionSelection from 'client/app/components/Parameters/FiltrationPlateLayout/components/WellRegionSelection';
import EditorStateProvider, {
  useFilterPlateEditorState,
} from 'client/app/components/Parameters/FiltrationPlateLayout/lib/editorState';
import {
  mapFromEditorState,
  useFiltrationPlateLayoutParameter,
} from 'client/app/components/Parameters/FiltrationPlateLayout/lib/parameterUtils';

type Props = {
  className: string;
  onClose: () => void;
};

function FiltrationPlateLayoutEditorPanel({ className, onClose }: Props) {
  const elementInstance = useElementInstance();
  const { setValue: setParamValue } = useFiltrationPlateLayoutParameter();
  const { state } = useFilterPlateEditorState();

  const handleClose = useCallback(() => {
    setParamValue(mapFromEditorState(state));
    onClose();
  }, [onClose, setParamValue, state]);

  useEffect(() => {
    /**
     * If the instance panel has been closed separately we close this panel too.
     */
    if (!elementInstance) {
      onClose();
    }
  }, [elementInstance, onClose]);

  return (
    <PanelWithoutScroll
      title="Filter Plate Layout Editor"
      onClose={handleClose}
      onCloseVariant="done"
      panelContent="FilterPlateEditor"
      fullWidth
      className={className}
    >
      <HeadlineParametersRow />
      <FiltrationDesignInfo />
      <WellRegionSelection />
    </PanelWithoutScroll>
  );
}

export default function EditorPanelWithState(props: Props) {
  return (
    <EditorStateProvider>
      <FiltrationPlateLayoutEditorPanel {...props} />
    </EditorStateProvider>
  );
}
