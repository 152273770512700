import React from 'react';

import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';

export type Props = MuiCheckboxProps;

/**
 * A checkbox component which is currently a wrapper around the MUI checkbox.
 * Right now, the only attribute that we are applying is to default the color="primary". This is
 * subject to change as we decide on what colors/styling we want.
 *
 * Documentation: https://material-ui.com/api/checkbox/
 *
 * */
export default function Checkbox(props: Props) {
  return <MuiCheckbox color="primary" {...props} />;
}
