import React, { PropsWithChildren, useCallback, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper, { PopperProps } from '@mui/material/Popper';

import IconButton, { IconButtonProps } from 'common/ui/components/IconButton';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/**
 * Renders the given icon and reveals a popper with the given content on click.
 * Poppers allow for proper handling of Click Away, unlike Popovers.
 */
export default function IconButtonWithPopper({
  iconButtonProps,
  content,
  onClick,
  overrideClassName,
}: {
  iconButtonProps: Omit<IconButtonProps, 'onClick'>;
  content: React.ReactNode;
  onClick?: () => void;
  overrideClassName?: string;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleLeave = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
      onClick?.();
    },
    [anchorEl, onClick],
  );

  return (
    <div className={overrideClassName}>
      <IconButton {...iconButtonProps} onClick={onClick ? handleClick : undefined} />
      <AdditionalInfoPopper anchorEl={anchorEl} open={open} handleLeave={handleLeave}>
        {content}
      </AdditionalInfoPopper>
    </div>
  );
}

type AdditionalInfoPopperProps = {
  handleLeave: () => void;
} & PropsWithChildren<Pick<PopperProps, 'anchorEl' | 'open' | 'children'>>;

function AdditionalInfoPopper({
  anchorEl,
  open,
  children,
  handleLeave,
}: AdditionalInfoPopperProps) {
  const classes = useStyles();

  return (
    <Popper
      anchorEl={anchorEl}
      placement="bottom-start"
      open={open}
      className={classes.popperContainer}
    >
      <ClickAwayListener onClickAway={handleLeave}>
        <Paper className={classes.popper} elevation={4}>
          <div className={classes.popperContent}>{children}</div>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
}

const useStyles = makeStylesHook(theme => ({
  popper: {
    borderRadius: '8px',
    width: '248px',
  },
  popperContainer: {
    zIndex: theme.zIndex.tooltip,
  },
  popperContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(3),
  },
}));
