import React, { PropsWithChildren, useCallback, useState } from 'react';

import ArrowIcon from '@mui/icons-material/ArrowDropUpOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper, { PopperProps } from '@mui/material/Popper';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type DeckLayoutInitialPopperProps = PropsWithChildren<
  Pick<PopperProps, 'anchorEl' | 'open'>
>;

/**
 * Popper when the deck options hasn't been selected yet. Tells the user to pick a deck layout.
 */
export default React.memo(function DeckLayoutInitialPopper({
  anchorEl,
  open,
}: DeckLayoutInitialPopperProps) {
  const classes = useStyles();
  const [isPopperOpen, setIsPopperOpen] = useState(open);
  const isAnchorElSet = Boolean(anchorEl);

  const handleClosePopper = useCallback(() => {
    setIsPopperOpen(false);
  }, []);

  return isAnchorElSet ? (
    <Popper
      anchorEl={anchorEl}
      placement="bottom-start"
      open={isPopperOpen}
      className={classes.popper}
    >
      <span className={classes.arrow}>
        <ArrowIcon fontSize="large" />
      </span>
      <ClickAwayListener onClickAway={handleClosePopper}>
        <Paper className={classes.paper}>
          <IconButton icon={<CloseIcon />} size="xsmall" onClick={handleClosePopper} />
          <Typography
            variant="caption"
            color="textPrimary"
            className={classes.typography}
          >
            To start, select the deck layout that you want to use.
          </Typography>
        </Paper>
      </ClickAwayListener>
    </Popper>
  ) : null;
});

const useStyles = makeStylesHook(theme => ({
  arrow: {
    '&>svg': {
      fill: theme.palette.background.paper,
      stroke: Colors.GREY_100,
    },
    zIndex: theme.zIndex.tooltip,
  },
  popper: {
    marginTop: -theme.spacing(3),
    zIndex: theme.zIndex.tooltip,
  },
  paper: {
    alignItems: 'flex-end',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: -theme.spacing(5),
    padding: theme.spacing(3),
    width: '204px',
  },
  typography: {
    margin: theme.spacing(3, 0),
  },
}));
