import React, { useContext, useMemo } from 'react';

import cx from 'classnames';

import Colors from 'common/ui/Colors';
import {
  isLoop,
  isStamping,
} from 'common/ui/components/simulation-details/mix/edge.helpers';
import { QuadraticBezierCurve } from 'common/ui/components/simulation-details/mix/edgeRouting';
import { ArrowHeadContext } from 'common/ui/components/simulation-details/mix/EdgesSvgOverlay';
import { Edge } from 'common/ui/components/simulation-details/mix/MixState';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export const ADJACENT_WELL_AVG_LENGTH_SQUARED = 1000;

const NO_MOVEMENT_ARC_RADIUS = 5;

type Props = {
  edge: Edge;
  curve: QuadraticBezierCurve;
  arrowAtEnd: boolean;
  pathLengthSquared: number;
};

/**
 * An arrow from one place on the deck to another. For move_plate instructions this will
 * be the center of one deck position to another. Otherwise the edge will be from the
 * center of a well on a plate to another well (possibly on a different plate).
 */
export default function EdgeSvg({ edge, curve, arrowAtEnd, pathLengthSquared }: Props) {
  const classes = useStyles();

  const getArrowURL = useContext(ArrowHeadContext);

  // We can use the path length to determine the right fontSize for the label,
  // so that short paths (adjacent wells) won't have any text cropped
  const isShortPath = pathLengthSquared < ADJACENT_WELL_AVG_LENGTH_SQUARED;

  const svgPathDef = useMemo(() => {
    // If the tip has aspirated from and dispensed to the same location, then show a
    // near-complete arc with an arrow at the end.
    if (isLoop(curve)) {
      return [
        // Move slightly to the left of the liquid transfer start
        `M ${curve.start.x - 2} ${curve.start.y}`,
        // Add a tiny line to set the angle of the arrowhead. Without this, the arrowhead
        // will be the angle of the start the arc, which obscures half the arrowhead.
        `l -0.0012 0.001`,
        // Render an arc of equal radii. The other parameters are
        `a ${NO_MOVEMENT_ARC_RADIUS} ${NO_MOVEMENT_ARC_RADIUS}`,
        '0', // Angle of ellipse (irrelevant since this is a circle)
        '1', // Show the large arc of the circle, not the small
        '0', // Anticlockwise (this means the circle is underneath the start point)
        `4 0`, // End coord, 4 pixels to the right of we started
      ].join(' ');
    }
    return `M ${curve.start.x} ${curve.start.y} Q ${curve.control.x} ${curve.control.y} ${curve.end.x} ${curve.end.y}`;
  }, [curve]);

  return isStamping(edge) && isLoop(curve) ? (
    <StampingInSameWellEdge curve={curve} />
  ) : (
    <path
      className={cx({
        [classes.moveLabwareEdge]: edge.type === 'move_plate',
        [classes.liquidTransferEdge]: edge.type === 'liquid_transfer',
        [classes.liquidDispenseEdge]: edge.type === 'liquid_dispense',
        [classes.filtrationEdge]: edge.type === 'filtration',
        [classes.stampingEdge]: edge.type === 'stamping',
      })}
      fill="none"
      d={svgPathDef}
      // "Normal" case - edge is pointing right. Put arrow at the end.
      markerEnd={arrowAtEnd ? getArrowURL(edge, isShortPath, false) : undefined}
      // Edge is pointing left. Draw the edge from left to right, but
      // put the arrow at its start.
      markerStart={arrowAtEnd ? undefined : getArrowURL(edge, isShortPath, true)}
    />
  );
}

const StampingInSameWellEdge = ({ curve }: { curve: QuadraticBezierCurve }) => (
  <>
    <path
      transform={`translate(${curve.center.x},${curve.center.y - 55})`}
      d="M2.14081 64.5549L1.98932 70.2415L7.13254 67.6243L2.14081 64.5549ZM85.5578 60.6988C72.3938 95.884 23.755 99.0249 4.75268 68.2485L3.88626 68.7436C23.3388 100.249 73.0632 96.9968 86.5113 61.0521L85.5578 60.6988Z"
      fill={Colors.MIX_PREVIEW_EDGE}
    />
    <path
      transform={`translate(${curve.center.x},${curve.center.y - 55})`}
      d="M85.3198 28.1073L85.6616 22.4248L80.4368 24.8754L85.3198 28.1073ZM1.81512 29.3213C16.185 -5.43004 64.9065 -7.02653 82.8371 24.3329L83.7191 23.8658C65.3615 -8.24032 15.5533 -6.5614 0.874963 28.9358L1.81512 29.3213Z"
      fill={Colors.MIX_PREVIEW_EDGE}
    />
  </>
);

const useStyles = makeStylesHook({
  liquidTransferEdge: {
    stroke: Colors.MIX_PREVIEW_EDGE,
    strokeWidth: 1,
  },
  moveLabwareEdge: {
    stroke: Colors.MIX_PREVIEW_EDGE,
    strokeWidth: 3,
  },
  liquidDispenseEdge: {
    stroke: Colors.MIX_PREVIEW_EDGE,
    strokeWidth: 1,
    // Show liquid dispenses (which are part of multidispense transfers) as
    // dashed arrows.
    strokeDasharray: '8 4',
  },
  filtrationEdge: {
    stroke: Colors.MIX_PREVIEW_EDGE,
    strokeWidth: 2,
    // Show filtration (ie liquid dropping out of a robocolumn) as dotted
    // arrows.
    strokeDasharray: '2 4',
  },
  stampingEdge: {
    stroke: Colors.MIX_PREVIEW_EDGE,
    strokeWidth: 1,
  },
});
