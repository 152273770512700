import React from 'react';

import { highlightStringMatches } from 'client/app/lib/workflow/format';

type Props = {
  text: string;
  query: RegExp | null;
};

export default function QueryHighlighter(props: Props) {
  const { query, text } = props;

  if (!query) {
    return <>{text}</>;
  }

  return <>{highlightStringMatches(text, query)}</>;
}
