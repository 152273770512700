import React, { useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';

import VisserverIFrame from 'client/app/components/VisserverIFrame';
import LinearProgress from 'common/ui/components/LinearProgress';

/**
 * Launches the response analysis tool in an iframe. This is used to analyze results from
 * multiple executions.
 *
 * Response analysis is accessed via a link from within visserver visualizations. The URL
 * query params should be passed through to the response analysis tool:
 * https://<antha-env>/#/response-analysis?design_file=<file-tree-url>&data_file=<file-tree-url>
 *
 * Once we settle on query params to use within visserver, we might only want to pass some
 * query parameters (e.g. just design_file and data_file).
 */
export default function ResponseAnalysisApp() {
  const location = useLocation();

  const [visserverLoading, setVisserverLoading] = useState<boolean>(true);

  // Pass the query params to the iframe, e.g. design_file and data_file params
  const params = useMemo<Record<string, string> | undefined>(() => {
    const params: Record<string, string> = {};
    const urlParams = new URLSearchParams(location.search);
    for (const [key, val] of urlParams) {
      params[key] = val;
    }
    return params;
  }, [location.search]);

  return (
    <>
      {visserverLoading && <LinearProgress />}
      <VisserverIFrame
        view="tabular"
        params={params}
        onReady={() => setVisserverLoading(false)}
        onReload={() => setVisserverLoading(true)}
      />
    </>
  );
}
