import React, { PropsWithChildren } from 'react';

import { styled } from '@mui/material/styles';

import CurrentEntityProvider from 'client/app/components/nav/breadcrumbs/BreadcrumbsEntityContext';
import useCurrentScreenEntity from 'client/app/components/nav/breadcrumbs/useCurrentScreenEntity';
import PlatformHeader from 'client/app/components/nav/PlatformHeader';
import LinearProgress from 'common/ui/components/LinearProgress';

export default function Screen({ children }: PropsWithChildren<{}>) {
  return (
    <CurrentEntityProvider currentEntity={useCurrentScreenEntity()}>
      <ScreenContainer>
        <PlatformHeader />
        {children}
      </ScreenContainer>
    </CurrentEntityProvider>
  );
}

export function LoadingScreenWithTopMainNav({ children }: PropsWithChildren<{}>) {
  return (
    <Screen>
      <LinearProgress />
      {children}
    </Screen>
  );
}

const ScreenContainer = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  backgroundColor: theme.palette.background.default,
}));
