import React from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import {
  activeStyle,
  clickableStyle,
  hoverStyle,
} from 'client/app/apps/experiments/commonExperimentsStyles';
import DatasetSettingsDialog from 'client/app/apps/experiments/dataset-settings/DatasetSettingsDialog';
import {
  DatasetDerivationInput,
  isBioreactorDataset,
} from 'client/app/apps/experiments/dataset-settings/isBioreactorDataset';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { formatDateTime } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import IconButton from 'common/ui/components/IconButton';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import MissingSamplesIcon from 'common/ui/icons/MissingSamplesIcon';

type Props = {
  derivationId: DatasetDerivationId;
  dataset: DatasetDerivationInput;
  onRemoveDataset?: () => void;
  isReadonly?: boolean;
  className?: string;
};

export function DatasetListItem({
  derivationId,
  dataset,
  onRemoveDataset,
  isReadonly,
  className,
}: Props) {
  const classes = useStyles();
  const { name, createdAt, device } = dataset;

  const createdDateTime = formatDateTime(new Date(createdAt));

  const [datasetSettingsDialog, openDatasetSettingsDialog] =
    useDialog(DatasetSettingsDialog);
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);

  const handleRemoveDatasetListItem = async (event: React.MouseEvent) => {
    event.stopPropagation();
    const confirmed = await openConfirmationDialog({
      object: 'dataset',
      action: 'remove',
      isActionDestructive: true,
    });
    if (!confirmed) {
      return;
    }
    onRemoveDataset?.();
  };

  const onViewSettings = async () => {
    await openDatasetSettingsDialog({ derivationId, dataset, isReadonly });
  };

  const isNextGen = useFeatureToggle('NEXT_GEN_BIOPROCESS_BLOCK');

  return (
    <>
      <ListItem
        className={cx(
          classes.listItemLayout,
          classes.listItem,
          classes.clickable,
          className,
          {
            [classes.listItemLegacy]: !isNextGen,
          },
        )}
        onClick={onViewSettings}
      >
        <Typography variant="body1" noWrap className={classes.name}>
          {name}
        </Typography>
        {isNextGen && (
          <div className={classes.samples}>
            <Typography variant="body1" noWrap component="span">
              {dataset.samples.length}
            </Typography>
            {dataset.samples.length === 0 && (
              <div className={classes.missingSamplesIcon}>
                <Tooltip
                  title={
                    isBioreactorDataset(dataset)
                      ? 'No samples have been defined on this dataset'
                      : 'No samples have been matched to this dataset'
                  }
                >
                  <MissingSamplesIcon className={classes.noSamplesIcon} />
                </Tooltip>
              </div>
            )}
          </div>
        )}
        <Typography variant="body1" noWrap>
          {device?.name ?? 'Stitched'}
        </Typography>
        <Typography variant="body1" noWrap>
          {createdDateTime}
        </Typography>

        {!isReadonly && onRemoveDataset && (
          <IconButton
            onClick={handleRemoveDatasetListItem}
            icon={<ClearIcon />}
            size={isNextGen ? 'small' : undefined}
          />
        )}
      </ListItem>
      {confirmationDialog}
      {datasetSettingsDialog}
    </>
  );
}

export function DatasetListHeader() {
  const classes = useStyles();
  return (
    <div className={cx(classes.listItemLayout, classes.listHeader)}>
      <Typography variant="overline" noWrap>
        Name
      </Typography>
      <Typography variant="overline" noWrap className={classes.samplesHeader}>
        Samples
      </Typography>
      <Typography variant="overline" noWrap>
        Device
      </Typography>
      <Typography variant="overline" noWrap>
        Date created
      </Typography>
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  clickable: {
    ...clickableStyle.style,
    ...hoverStyle(theme).background,
    '&:active': {
      ...activeStyle.style,
    },
  },
  listItemLayout: {
    display: 'grid',
    gridTemplateColumns:
      '[dataset-name] 3fr [samples] 150px [device] 1fr [date] 1fr [overflow] 40px',
    gridColumnGap: theme.spacing(5),
  },
  listHeader: {
    marginBottom: theme.spacing(4),
  },
  listItem: {
    border: `1px solid ${Colors.GREY_30}`,
    gridRowGap: theme.spacing(3),
    height: '40px',
    borderRadius: '4px',
    padding: 0,
  },
  listItemLegacy: {
    gridTemplateColumns:
      '[dataset-name] 3fr [device] 1fr [date] 1fr [overflow] min-content',
    height: '56px',
    borderRadius: '8px',
    padding: theme.spacing(0, 4),
  },
  name: {
    paddingLeft: theme.spacing(3),
  },
  samples: {
    alignItems: 'center',
    textAlign: 'center',
  },
  missingSamplesIcon: {
    // Prevent the icon from offsetting the count, so it remains center with the header
    display: 'inline-block',
    width: 0,
    verticalAlign: 'middle',
    textIndent: theme.spacing(4),
  },
  samplesHeader: {
    textAlign: 'center',
  },
  noSamplesIcon: {
    fontSize: '14px',
    justifySelf: 'center',
  },
}));
