import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';

import Button from 'common/ui/components/Button';
import IconButton from 'common/ui/components/IconButton';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type Item = {
  id: string;
  value: string;
};

export default function ItemList({
  value,
  onChange,
  disabled,
  renderItemEditor,
}: {
  value: Item[];
  onChange: (value: Item[]) => void;
  disabled: boolean;
  renderItemEditor: (
    item: Item,
    autoFocus: boolean,
    onPressEnter: (e: React.KeyboardEvent) => void,
  ) => JSX.Element;
}) {
  const classes = useStyles();
  const [enableAutofocus, setEnableAutofocus] = useState(false);

  const addItem = () => {
    onChange([...value, { id: uuid(), value: '' }]);
    setEnableAutofocus(true);
  };

  return (
    <div className={classes.container}>
      <ol className={classes.list}>
        {value.map((item, index) => (
          <li className={cx(classes.item, { readonly: disabled })} key={item.id}>
            <div className={classes.counter}>
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.count}
              >
                {index + 1}
              </Typography>
              <IconButton
                icon={<CloseIcon />}
                className={classes.delete}
                size="xsmall"
                onClick={() => {
                  onChange(value.filter(l => l !== item));
                }}
                disabled={disabled}
              />
            </div>
            <div className={classes.value}>
              {renderItemEditor(
                item,
                enableAutofocus && index === value.length - 1,
                e => {
                  if (e.key === 'Enter') {
                    if (index === value.length - 1) {
                      addItem();
                    }
                    e.preventDefault();
                  }
                },
              )}
            </div>
          </li>
        ))}
      </ol>
      <Button
        variant="tertiary"
        color="primary"
        startIcon={<AddIcon />}
        onClick={addItem}
        disabled={disabled}
      >
        Add New Item
      </Button>
    </div>
  );
}

const useStyles = makeStylesHook(({ spacing, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: spacing(3),
  },
  list: {
    display: 'grid',
    gridTemplate: 'auto / auto minmax(0, 1fr)',
    listStyleType: 'none',
    rowGap: spacing(3),
    placeItems: 'center',
    padding: 0,
    margin: 0,
  },
  item: {
    display: 'contents',
    '&:not(.readonly)': {
      '&:hover $delete': {
        visibility: 'visible',
      },
      '&:hover $count': {
        visibility: 'hidden',
      },
    },
  },
  value: {
    justifySelf: 'stretch',
    gridColumn: 2,
    '& > *': {
      width: '100%',
    },
  },
  counter: {
    display: 'grid',
    gridTemplate: 'auto / auto',
    placeItems: 'center',
    paddingRight: spacing(2),
  },
  count: {
    gridArea: '1 / 1',
  },
  delete: {
    gridArea: '1 / 1',
    visibility: 'hidden',
    color: palette.error.dark,
  },
}));
