import React from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Description from '@mui/icons-material/DescriptionOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import {
  MarkAsCompleteButton,
  ReadInstructionsButton,
} from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/buttons/button-styles';
import { ExecutionStatusEnum } from 'client/app/gql';

type Props = {
  status: ExecutionStatusEnum | null;
  simulationId: SimulationId;
  onMarkAsComplete: () => Promise<void>;
  markingAsComplete: boolean;
};

const CIRCULAR_PROGRESS_CONTAINER_SIZE = 20;

export function ManualButtonSet({
  status,
  simulationId,
  onMarkAsComplete,
  markingAsComplete,
}: Props) {
  const instructionsURL = `/#/simulation-details/${simulationId}/instructions`;
  const isExecutionSuccessful = status === ExecutionStatusEnum.EXECUTION_SUCCESS;
  const disabled = isExecutionSuccessful || markingAsComplete;

  const markAsCompleteButton = (
    <MarkAsCompleteButton
      color="secondary"
      size="medium"
      variant="extended"
      disabled={disabled}
      onClick={onMarkAsComplete}
    >
      {markingAsComplete ? (
        <ScaledCircularProgress size={CIRCULAR_PROGRESS_CONTAINER_SIZE} color="inherit" />
      ) : (
        <CheckCircleOutlineIcon fontSize="small" />
      )}
      Mark as complete
    </MarkAsCompleteButton>
  );

  return (
    <>
      <ReadInstructionsButton
        component="a"
        variant="outlined"
        color="primary"
        startIcon={<Description fontSize="small" />}
        href={instructionsURL}
        target="_blank"
        rel="noreferrer"
      >
        Read instructions
      </ReadInstructionsButton>
      {isExecutionSuccessful ? (
        <Tooltip title="The stage is already marked as complete">
          <span>{markAsCompleteButton}</span>
        </Tooltip>
      ) : (
        markAsCompleteButton
      )}
    </>
  );
}

// Scale the circular progress svg so that it is the same size as the <CheckCircleOutlineIcon/> (svg of 16.6833 x 16.6833)
const scale = (16.6833 / CIRCULAR_PROGRESS_CONTAINER_SIZE).toFixed(2);

const ScaledCircularProgress = styled(CircularProgress)({ scale });
