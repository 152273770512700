import { Option } from 'common/ui/components/FilterChip/FilterChipWithCheckbox';
import { trackHeapEvent } from 'common/ui/useHeapTracking';

const PLATE_LIBRARY_EVENTS = {
  FILTER: 'plate-library-filter',
} as const;

type PlateTypeLocation = 'plate-type-inventory' | 'workflow-builder';
type PlateTypeFilter =
  | 'wellNumber'
  | 'wellBottomShape'
  | 'wellVolumeRange'
  | 'plateCreator';

type PlateLibraryFilterEventData = {
  location: PlateTypeLocation;
  filter: PlateTypeFilter;
  /** Comma separated values associated with `filter` */
  filterValue: string;
};

export function trackPlateLibraryFilter(
  plateTypeFilter: PlateTypeFilter,
  options: Option<unknown>[],
  location: PlateTypeLocation,
) {
  const eventData: PlateLibraryFilterEventData = {
    location,
    filter: plateTypeFilter,
    filterValue: formatFilterOptions(options),
  };
  trackHeapEvent(PLATE_LIBRARY_EVENTS.FILTER, eventData);
}

function formatFilterOptions(options: Option<unknown>[]) {
  return options
    .filter(x => x.selected)
    .map(x => x.label)
    .sort()
    .join(',');
}
