import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default React.memo(function CherryPickerIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" fill="none">
        <path
          d="M12.192 15.163c.916-1.789 3.794-2.622 5.583-1.706 1.788.916 2.38 3.261 1.465 5.05-.64 1.249-2.848 3.005-5.147 1.746-1.762-.965-2.93-3.083-1.901-5.09zM4.033 14.123c.916-1.788 2.896-2.42 4.685-1.504 1.65 1.044 3.278 3.036 1.933 5.024-.64 1.25-2.848 3.006-5.147 1.747-1.762-.965-2.5-3.26-1.471-5.267z"
          fill="currentColor"
        />
        <path
          d="M13.194 4.08c-.62 1.874-1.332 6.373.785 9.375M12.681 6.656c-.324 1.962-1.654 5.569-4.97 7.24"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <path
          d="M21.155 9.556s-7.985.339-7.013-5.058c.859-.698 1.971-.886 3.603-.208 2.595 1.26 3.41 5.266 3.41 5.266z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
