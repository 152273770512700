import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import FactorLevelEditor, {
  FactorLevelEditorProps,
} from 'client/app/components/DOEFactorForm/components/FactorLevelEditor';
import { FactorItem } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const CHIP_CSS_CLASS = 'singleSourceLevelChip';
const CHIP_GAP = 6;
const SELECT_ARROW_WIDTH = 20;
const SHOW_MORE_LABEL_WIDTH = 60;

export default function DerivedLevelEditor({
  deriveFrom: sourceFactor,
  sourceFactorValueMap,
  onMultiselectChange,
  ...props
}: FactorLevelEditorProps & {
  deriveFrom: FactorItem;
  sourceFactorValueMap: Record<string, string>;
  onMultiselectChange: (selected: string[]) => void;
}) {
  const classes = useStyles();

  const getMultiselectValueFromMapping = useCallback(
    () =>
      sourceFactor.values.filter(level => sourceFactorValueMap[level] === props.value),
    [props.value, sourceFactor.values, sourceFactorValueMap],
  );
  const [maxChipsToDisplay, setMaxChipsToDisplay] = useState(Number.MAX_SAFE_INTEGER);
  const [selectedLevels, setSelectedLevels] = useState<string[]>(
    getMultiselectValueFromMapping,
  );
  useEffect(() => {
    /**
     * Multiselect values must reflect the state of level mapping at all times
     */
    setSelectedLevels(getMultiselectValueFromMapping);
  }, [getMultiselectValueFromMapping]);

  const multiselectRef = useRef<HTMLElement | undefined>();
  useLayoutEffect(() => {
    /**
     * This effect limits the amount of source level chips displayed
     * to avoid exceeding the multiselect boundary.
     */
    const container = multiselectRef.current;

    if (container) {
      const box = container.getBoundingClientRect();
      const chips = container.getElementsByClassName(CHIP_CSS_CLASS);

      let chipTotalWidth = 0;
      let count = 0;
      let i = 1;

      for (const chip of chips) {
        // If this is the last chip, and it fits, we don't need to worry about saving space for the more label.
        const isLast = i === selectedLevels.length;

        const chipRect = chip.getBoundingClientRect();
        const chipWidth = chipRect.width + CHIP_GAP;

        if (
          chipTotalWidth +
            chipWidth +
            SELECT_ARROW_WIDTH +
            (isLast ? 0 : SHOW_MORE_LABEL_WIDTH) >
          box.width
        ) {
          break;
        }

        chipTotalWidth += chipWidth;
        count += 1;
        i++;
      }

      setMaxChipsToDisplay(count);
    }
  }, [selectedLevels.length]);

  const options = getMultiselectOptions(
    props.value,
    sourceFactor.values,
    sourceFactorValueMap,
  );

  return (
    <>
      <FactorLevelEditor {...props} />
      <section className={classes.levelsToAssign}>
        <Typography variant="caption">
          When
          <span className={classes.sourceFactorName}> {sourceFactor.displayName} </span>
          Is
        </Typography>
        <FormControl>
          <Select
            ref={ref => (multiselectRef.current = ref as HTMLElement)}
            value={selectedLevels}
            onChange={e => {
              const selectedLevels = e.target.value as string[];
              setSelectedLevels(selectedLevels);
              setMaxChipsToDisplay(Number.MAX_SAFE_INTEGER);
              onMultiselectChange(selectedLevels);
            }}
            multiple
            displayEmpty
            variant="outlined"
            className={classes.multiselect}
            placeholder="Select levels to map..."
            disabled={!props.value || options.length === 0}
            MenuProps={{
              anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
            }}
            renderValue={value => {
              const selected = value;
              return selected.length > 0 ? (
                <Box className={classes.sourceLevelChips}>
                  {selected.slice(0, maxChipsToDisplay).map(value => (
                    <Chip
                      key={value}
                      className={CHIP_CSS_CLASS}
                      label={getLabelCopy(value, sourceFactor.unit)}
                    />
                  ))}
                  {selected.length > maxChipsToDisplay && (
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      className={classes.placeholder}
                    >
                      +{selected.length - maxChipsToDisplay} more
                    </Typography>
                  )}
                </Box>
              ) : (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.placeholder}
                >
                  Select levels...
                </Typography>
              );
            }}
          >
            {options.map((level, idx) => (
              <MenuItem key={idx} value={level} className={classes.sourceLevelItem}>
                {getLabelCopy(level, sourceFactor.unit)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </section>
    </>
  );
}

const getLabelCopy = (levelValue: string, unit?: string) =>
  unit ? `${levelValue} ${unit}` : levelValue;

function getMultiselectOptions(
  value: string,
  sourceValues: string[],
  sourceFactorValueMap: Record<string, string>,
) {
  const unmappedLevels: string[] = [];
  const mappedToThisDerivedLevel: string[] = [];

  sourceValues.forEach(levelValue => {
    if (!Reflect.has(sourceFactorValueMap, levelValue)) {
      unmappedLevels.push(levelValue);
    } else if (sourceFactorValueMap[levelValue] === value) {
      mappedToThisDerivedLevel.push(levelValue);
    }
  });

  return [...unmappedLevels, ...mappedToThisDerivedLevel].sort();
}

const useStyles = makeStylesHook(({ spacing }) => ({
  levelsToAssign: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(4, 0, 0, 5),
    gap: '6px',
  },
  sourceFactorName: {
    fontWeight: 700,
  },
  multiselect: {
    '&.Mui-disabled': {
      opacity: 0.5,
    },
    '& > .MuiSelect-outlined': {
      display: 'flex',
      padding: '6px 8px',
      minHeight: spacing(7),
      backgroundColor: Colors.WHITE,
    },
    '& .MuiChip-root': {
      backgroundColor: Colors.BLUE_5,
    },
  },
  sourceLevelItem: {
    '&[aria-selected="true"]': {
      backgroundColor: Colors.BLUE_5,
    },
  },
  placeholder: {
    alignSelf: 'center',
    marginLeft: spacing(2),
  },
  sourceLevelChips: {
    display: 'flex',
    width: '100%',
    gap: CHIP_GAP,
  },
}));
