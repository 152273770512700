import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';

import * as FilesApi from 'client/app/api/FilesApi';
import { FileMetadata } from 'client/app/api/FilesApi';
import FileList from 'client/app/components/FileBrowser/FileList';
import getPathObject from 'client/app/components/FileBrowser/getPathObject';
import TimeFilterPicker from 'client/app/components/FileBrowser/TimeFilterPicker';
import UIErrorBox from 'client/app/components/UIErrorBox';
import {
  FileBrowserFileSingleSelection,
  FileBrowserValueTree,
} from 'client/app/lib/file-browser/types';
import Colors from 'common/ui/Colors';
import LinearProgress from 'common/ui/components/LinearProgress';

// This is decided at the microservice layer
const FILETREE_PAGE_SIZE = 100;

const useStyles = makeStyles({
  segment: {
    alignItems: 'stretch',
    borderRight: `1px ${Colors.GREY_30} solid`,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    justifyContent: 'space-between',
    minWidth: '300px',
    maxWidth: '30%',
  },
  segmentInner: {
    overflowY: 'auto',
    paddingBottom: '20px',
  },

  contextMenu: {
    background: '#fff',
    borderTop: `1px ${Colors.GREY_30} solid`,
    color: '#000',
    width: '100%',
  },

  controlBar: {
    alignItems: 'center',
    background: Colors.GREY_10,
    borderBottom: `1px ${Colors.GREY_30} solid`,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },

  contextMenuInner: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
    transition: 'height 0.25s, opacity 0.25s',
    width: '100%',
  },
  contextMenuOpen: {
    height: 'auto',
    opacity: 1,
  },
  fileCount: {
    padding: '12px',
  },
  menuIcon: {
    marginRight: '12px',
  },
  filter: {
    padding: '12px 24px 12px 24px',
  },
});

type Props = {
  className?: string;
  valueTree?: FileBrowserValueTree;
  nextDisplayPathSegment?: string;
  path: string;
  onItemClick: (item: FileMetadata, path: string) => void;
  onToggleItemSelected: (path: FileBrowserFileSingleSelection) => void;
  setSelection: (paths: FileBrowserFileSingleSelection[] | null) => void;
  onDataLoaded: () => void;
  selectMultiple: boolean;
  showContextMenuBar: boolean;
};

type TimeFilter = {
  start: Date;
  end: Date;
};

type Options = {
  path: string;
  timeFilter: TimeFilter | null;
  cursor: string | null;
};

function useFetchFiles() {
  const fetchOrSearch = FilesApi.useFetchOrSearch();
  return useCallback(
    async function fetchFiles(options: Options) {
      const { path, timeFilter, cursor } = options;
      // It's really annoying that we can't do file requests that include
      // trailing slashes. It's tempting to go and fix this at the micro-
      // service layer, but I expect that would require way more work than
      // value.
      let p = path;
      if (p.endsWith('/')) {
        p = p.substr(0, p.length - 1);
      }

      // If we have any of the search filters activated, add the appropriate query
      // string params in.
      let query = {};
      if (timeFilter) {
        const { start, end } = timeFilter;
        query = {
          ...query,
          start: start.toISOString(),
          end: end.toISOString(),
        };
      }

      if (cursor) {
        query = { ...query, cursor };
      }

      const { resp, nextCursor } = await fetchOrSearch(p, query);
      if (!resp) {
        throw new Error('Request failed');
      }

      let files: FileMetadata[] = [];
      if (resp?.listing?.path_details) {
        files = resp.listing.path_details as FileMetadata[];
      }

      return { files, nextCursor };
    },
    [fetchOrSearch],
  );
}

const FileSegment = function (props: React.PropsWithChildren<Props>) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [files, setFiles] = useState<FileMetadata[]>([]);
  const pathBeingLoadedRef = useRef<string | null>(null);
  const [pathBeingLoaded, setPathBeingLoaded] = useState<string | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleContextMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen, setMenuOpen]);

  // Filters
  // Allow for the ability to filter by time.
  const [isTimeFilterShown, setTimeFilterShown] = useState(false);
  // Toggle the time filter on/off and clear the filter
  // if time filter was previously set and timeFilter is no longer shown.
  const onToggleTimeFilter = useCallback(() => {
    if (isTimeFilterShown) {
      setTimeFilter(null);
    }
    setTimeFilterShown(!isTimeFilterShown);
  }, [isTimeFilterShown, setTimeFilterShown]);

  // Set the actual value of the time filter.
  const [timeFilter, setTimeFilter] = useState<TimeFilter | null>(null);
  const updateTimeFilter = useCallback(
    (start: Date, end: Date) => {
      setTimeFilter({
        start,
        end,
      });
    },
    [setTimeFilter],
  );

  const { path, onDataLoaded, setSelection } = props;
  const selectAll = useCallback(() => {
    const selection: FileBrowserFileSingleSelection[] = [];
    for (let ii = 0; ii < files.length; ii++) {
      const f = files[ii];
      if (f.type === FilesApi.FILE) {
        selection.push(getPathObject(path, f));
      }
    }
    setSelection(selection);
  }, [setSelection, files, path]);

  // Pagination
  const [cursor, setCursor] = useState('');
  const [lastPage, setLastPage] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const cursorBeingFetch = useRef('');
  const fetchFiles = useFetchFiles();
  const nextPage = useCallback(async () => {
    try {
      pathBeingLoadedRef.current = path;
      const options = {
        path,
        timeFilter,
        cursor,
      };
      if (!cursor) {
        // We do not want infinite looping pagination
        // The cursor is '' in 2 cases:
        // - we haven't fetch anything yet (in that case we should not call nextPage)
        // - we got the last page
        return;
      }
      if (cursorBeingFetch.current) {
        // already fetching a new page
        return;
      }
      cursorBeingFetch.current = cursor;
      setLoadingPage(true);
      let { files, nextCursor } = await fetchFiles(options);
      cursorBeingFetch.current = '';

      // what we fetch was for another path
      if (pathBeingLoadedRef.current !== path) {
        files = [];
        nextCursor = '';
      } else if (!nextCursor || files.length < FILETREE_PAGE_SIZE) {
        // we reach last page when there is no nextCursor, or when the results list is smaller than page size
        setLastPage(true);
      }
      setLoadingPage(false);
      pathBeingLoadedRef.current = null;
      setPathBeingLoaded(null);
      setFiles(prevFiles => [...prevFiles, ...files]);
      setCursor(nextCursor ?? '');
      setErrorMessage(null);
    } catch (e) {
      cursorBeingFetch.current = '';
      // If the pathBeingLoaded has changed, it probably means that the user
      // changed their mind and loaded a different path while this network
      // request was still being resolved. In that case, we should throw away
      // this error message so that whatever newer network request is in
      // flight won't be interfered with.
      if (pathBeingLoadedRef.current === path) {
        setErrorMessage('Fetch failed: ' + e.message);
        pathBeingLoadedRef.current = null;
      }
    }
  }, [path, timeFilter, cursor, fetchFiles]);

  const showMore = useCallback(() => nextPage(), [nextPage]);

  const page = useMemo(() => {
    return files;
  }, [files]);

  // Make sure to load on mount and any time the path changes.
  useEffect(() => {
    // Remove the stale file list first so that the user isn't staring at
    // old files while waiting for new ones to load.
    setFiles([]);
    // When we first load, we don't know if it is last page or not.
    setLastPage(false);
    // When we first load, we  are not loading a new page.
    setLoadingPage(false);

    if (!path) {
      return;
    }

    pathBeingLoadedRef.current = path;
    setPathBeingLoaded(path);

    // When the user changes which path they want to load several times quickly
    // or when there is a path specified in the URL and the app mutates the
    // desired basePath quickly after the initial render, we can have multiple
    // network requests in flight. We only care about the most recent one, so
    // the checks below ensure that if we get state-mutating events, that they
    // match up with the most recently requested path.
    const options = {
      path,
      timeFilter,
      cursor: '', // always start a first page here
    };
    (async () => {
      try {
        let { files, nextCursor } = await fetchFiles(options);

        // what we fetch was for another path
        if (pathBeingLoadedRef.current !== path) {
          files = [];
          nextCursor = '';
        } else if (!nextCursor || files.length < FILETREE_PAGE_SIZE) {
          // we reach last page when there is no nextCursor, or when the results list is smaller than page size
          setLastPage(true);
        }
        pathBeingLoadedRef.current = null;
        setPathBeingLoaded(null);
        setFiles(files);
        setCursor(nextCursor ?? '');
        setErrorMessage(null);
        onDataLoaded();
      } catch (e) {
        // If the pathBeingLoaded has changed, it probably means that the user
        // changed their mind and loaded a different path while this network
        // request was still being resolved. In that case, we should throw away
        // this error message so that whatever newer network request is in
        // flight won't be interfered with.
        if (pathBeingLoadedRef.current === path) {
          setErrorMessage('No files found.');
          pathBeingLoadedRef.current = null;
          setPathBeingLoaded(null);
          setFiles([]);
          setLoadingPage(false);
        }
      }
    })();
  }, [path, onDataLoaded, setErrorMessage, setFiles, timeFilter, fetchFiles]);

  const classes = useStyles();
  const valueTree = props.valueTree || {};
  const { nextDisplayPathSegment } = props;
  return (
    <div className={cx(classes.segment, props.className)}>
      {errorMessage && <UIErrorBox>{errorMessage}</UIErrorBox>}
      <div className={classes.segmentInner}>
        {(pathBeingLoaded !== null || loadingPage) && <LinearProgress />}
        <FileList
          valueTree={valueTree}
          files={page}
          onItemClick={props.onItemClick}
          onToggleItemSelected={props.onToggleItemSelected}
          path={props.path}
          selectMultiple={props.selectMultiple}
          nextDisplayPathSegment={nextDisplayPathSegment}
          limit={page.length}
          showMore={showMore}
          total={Infinity}
          lastPage={lastPage}
          loadingPage={loadingPage}
        />
      </div>

      {props.showContextMenuBar && !errorMessage && (
        <div className={classes.contextMenu}>
          <div className={classes.controlBar}>
            <IconButton onClick={toggleContextMenu} size="large">
              {!menuOpen ? <FilterListIcon /> : <CloseIcon />}
            </IconButton>
          </div>
          <div
            className={cx({
              [classes.contextMenuInner]: true,
              [classes.contextMenuOpen]: menuOpen,
            })}
          >
            <MenuList>
              {props.selectMultiple && (
                <MenuItem onClick={selectAll}>
                  <CheckBox className={classes.menuIcon} /> Select all {files.length}{' '}
                  files
                </MenuItem>
              )}
              <MenuItem onClick={onToggleTimeFilter}>
                {isTimeFilterShown ? (
                  <CheckBox className={classes.menuIcon} />
                ) : (
                  <CheckBoxOutlineBlank className={classes.menuIcon} />
                )}
                Filter by date
              </MenuItem>
              {isTimeFilterShown && (
                <div className={classes.filter}>
                  <TimeFilterPicker onChange={updateTimeFilter} />
                </div>
              )}
            </MenuList>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileSegment;
