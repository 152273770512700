import React, { useCallback, useMemo, useRef, useState } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import {
  QUERY_DEVICES_FOR_DATASETS_FILTER,
  QUERY_LATEST_DATASETS,
} from 'client/app/apps/experiments/gql/queries';
import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import DatasetCard from 'client/app/components/cards/DatasetCard';
import { DatasetsQueryVariables } from 'client/app/gql';
import usePagination from 'client/app/hooks/usePagination';
import { ScreenRegistry } from 'client/app/registry';
import { unique } from 'common/lib/data';
import { PageInfo } from 'common/server/graphql/pagination';
import { circularLoadingContainer } from 'common/ui/commonStyles';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import FilterChipWithAutocomplete from 'common/ui/components/FilterChip/FilterChipWithAutocomplete';
import FilterChipWithDateRange, {
  DateRange,
  getDateFromTo,
  getDateRange,
} from 'common/ui/components/FilterChip/FilterChipWithDateRange';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import SearchField from 'common/ui/components/SearchField';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type DatasetsListProps = {
  onClickDataset?: (datasetId: DatasetId) => void;
  /**
   * When adding new datasets to an existing list of some sort, it may be
   * desirable to prevent the user from selecting an existing selection again.
   * */
  excludedDatasetIds?: DatasetId[];
  selectedDatasetIds?: Set<DatasetId>;
  /**
   * Filter datasets to just those generated by devices of the given category, e.g. just
   * datasets generated by 'Bio Reactors' devices.
   *
   * Takes precedence over `notDeviceCategory`.
   */
  deviceCategory?: string;
  /**
   * Filter datasets to those that are _not_ generated by devices of the given category.
   * This is used for bioprocessing, where we count all non bioreactor datasets as
   * 'analytical' datasets.
   *
   * This has no effect if `deviceCategory` is provided.
   */
  notDeviceCategory?: string;
};

export function DatasetsList(props: DatasetsListProps) {
  const scrollableRef = useRef<HTMLDivElement>(null);

  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [dateFrom, setDateFrom] = useState<string | undefined>(undefined);
  const [dateTo, setDateTo] = useState<string | undefined>(undefined);
  const [deviceId, setDeviceId] = useState<string | undefined>(undefined);

  const datasetFiltersProps: DatasetFiltersProps = {
    searchQuery,
    setSearchQuery,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    deviceId,
    setDeviceId,
  };

  return (
    <DatasetListBase {...datasetFiltersProps} {...props} scrollableRef={scrollableRef} />
  );
}

type DatasetFiltersProps = {
  searchQuery?: string;
  setSearchQuery: (value?: string) => void;
  dateFrom?: string;
  setDateFrom: (value?: string) => void;
  dateTo?: string;
  setDateTo: (value?: string) => void;
  deviceId?: string;
  setDeviceId: (value?: string) => void;
};

type DatasetListProps = {
  scrollableRef: React.RefObject<HTMLDivElement>;
} & DatasetsListProps &
  DatasetFiltersProps;

function DatasetListBase({
  scrollableRef,
  searchQuery,
  onClickDataset,
  setSearchQuery,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  deviceId,
  setDeviceId,
  excludedDatasetIds,
  selectedDatasetIds,
  deviceCategory,
  notDeviceCategory,
}: DatasetListProps) {
  const classes = useStyles();

  const variables: DatasetsQueryVariables = {
    search: searchQuery,
    filterStartDate: dateFrom,
    filterEndDate: dateTo,
    filterDeviceId: deviceId,
    filterDeviceCategory: deviceCategory,
    filterNotDeviceCategory: notDeviceCategory,
  };

  const {
    loading: isLoadingDatasets,
    error: datasetsError,
    data: datasetsData,
    refetch,
    fetchMore,
  } = useQuery(QUERY_LATEST_DATASETS, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const datasetsFromQuery = datasetsData?.datasets?.items || [];

  const pageInfo = datasetsData?.datasets.pageInfo as PageInfo | undefined;
  const hasNextPage = usePagination({
    entity: 'datasets',
    pageInfo,
    fetchMore,
    dependencies: [searchQuery],
    scrollableRef,
    isInitialLoading: isLoadingDatasets,
    variables,
  });

  const { data: devicesData } = useQuery(QUERY_DEVICES_FOR_DATASETS_FILTER, {
    variables: { deviceCategory, notDeviceCategory },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const deviceOptions = useMemo(() => {
    const devices = devicesData?.devices ? [...devicesData.devices] : [];
    return (
      unique(devices, d => d.name)
        // Filtering for devices that can actually upload data for now.
        //
        // Eventually this filtering could be moved to the server-side. Given the
        // number of devices tends to be limited and nested queries + conditions
        // based on a relation in TypeORM aren't straightforward, it's being done
        // client-side.
        .filter(device => device.model.isUploadSupported)
        .map(device => ({ label: device.name, value: device.id }))
    );
  }, [devicesData?.devices]);

  const filterDateRange = useMemo(
    () => getDateRange(dateFrom, dateTo),
    [dateFrom, dateTo],
  );

  const setFilterDateRange = useCallback(
    (newValue: DateRange) => {
      logEvent('filter-datasets-by-date', ScreenRegistry.EXPERIMENTS);
      const { dateFrom, dateTo } = getDateFromTo(newValue);
      setDateFrom(dateFrom);
      setDateTo(dateTo);
    },
    [setDateFrom, setDateTo],
  );

  if (datasetsError) {
    return <GraphQLErrorPanel error={datasetsError} onRetry={refetch} />;
  }

  const datasets = datasetsFromQuery.filter(
    dataset => !excludedDatasetIds?.includes(dataset.datasetId),
  );

  return (
    <ContainerWithIntersectionBar
      scrollableRef={scrollableRef}
      headerLeftContent={
        <>
          <FilterChipWithDateRange
            heading="Filter by Date Range"
            defaultChipLabel="Date Range"
            filterValue={filterDateRange}
            onFilter={setFilterDateRange}
          />
          <FilterChipWithAutocomplete
            heading="Filter by Device"
            defaultChipLabel="Device"
            dropdownOptions={deviceOptions}
            filterValue={deviceId}
            onFilter={setDeviceId}
          />
        </>
      }
      headerRightContent={
        <SearchField
          label="Search"
          defaultValue={searchQuery}
          onQueryChange={setSearchQuery}
        />
      }
      content={
        <div className={classes.list}>
          {isLoadingDatasets ? (
            <CircularProgress size={24} />
          ) : (
            <>
              {datasets.length > 0 &&
                datasets.map(dataset => (
                  <DatasetCard
                    key={dataset.datasetId}
                    dataset={dataset}
                    isSelected={selectedDatasetIds?.has(dataset.datasetId)}
                    onClick={() => onClickDataset?.(dataset.datasetId)}
                  />
                ))}
              {datasets.length === 0 && (
                <NoEntitiesMessage
                  entityName="datasets"
                  messageType={MessageType.NO_FILTER_RESULTS}
                  searchQuery={searchQuery}
                />
              )}
              {hasNextPage && (
                <div className={classes.circularLoadingContainer}>
                  <CircularProgress size={24} />
                </div>
              )}
            </>
          )}
        </div>
      }
      dense
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  ...circularLoadingContainer,
  list: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
}));
