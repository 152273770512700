import React, { useCallback, useState } from 'react';

import cx from 'classnames';

import ExecutionsDialog from 'client/app/apps/experiments/ExecutionsDialog';
import { ClickedExecution } from 'client/app/apps/experiments/ExecutionsList';
import ExecutionFileSelectorDialog from 'client/app/components/FileBrowser/ExecutionFileSelectorDialog';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import { FileObject } from 'common/types/fileParameter';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

// We render two contents for this dialog, which will determine what we show
// in the surrounding dialog container (e.g. ArrowBackIcon, or Select button).
enum DialogPages {
  EXECUTIONS_LIST_DIALOG,
  FILE_SELECTOR_DIALOG,
}

type Props = DialogProps<FileObject | null>;

/**
 * ExecutionAndFileSelectionDialog provides a dialog container in which the user
 * can navigate back and forth between a list of all executions, and (upon selecting
 * an execution) the files from that execution.
 * Selecting a file will return that file in the onClose prop that is handled by
 * the useDialog hook and can be processed by the caller.
 */
export default React.memo(function ExecutionAndFileSelectionDialog({
  isOpen,
  onClose,
}: Props) {
  const classes = useStyles();

  const [currentDialogContent, setCurrentDialogContent] = useState<DialogPages>(
    DialogPages.EXECUTIONS_LIST_DIALOG,
  );
  const [selectedFile, setSelectedFile] = useState<FileObject | null>(null);
  const [selectedExecution, setSelectedExecution] = useState<ExecutionId | undefined>();

  const onSelectExecutionFromDialog = useCallback(
    (clickedExecution: ClickedExecution | typeof CANCEL_CHOICE) => {
      if (!clickedExecution || clickedExecution === CANCEL_CHOICE) {
        return;
      }

      setSelectedExecution(clickedExecution.executionId);
      setCurrentDialogContent(DialogPages.FILE_SELECTOR_DIALOG);
    },
    [],
  );

  const onCancel = useCallback(() => {
    onClose(null);
  }, [onClose]);

  const onBackButtonClick = useCallback(() => {
    setCurrentDialogContent(DialogPages.EXECUTIONS_LIST_DIALOG);
    setSelectedFile(null);
  }, []);

  const onConfirmSelectionClick = useCallback(
    (value: FileObject | null) => {
      onClose(value);
    },
    [onClose],
  );

  const showFileSelectionPage =
    currentDialogContent === DialogPages.FILE_SELECTOR_DIALOG && selectedExecution;

  return (
    <>
      <ExecutionsDialog
        onClose={onCancel}
        isOpen={isOpen}
        dialogClassName={cx({
          [classes.hideDialogPage]: showFileSelectionPage,
        })}
        onClickExecutionRow={onSelectExecutionFromDialog}
      />
      {selectedExecution && (
        <ExecutionFileSelectorDialog
          value={selectedFile}
          executionId={selectedExecution}
          isOpen={isOpen}
          onClose={onCancel}
          onChange={onConfirmSelectionClick}
          onBackClick={onBackButtonClick}
          dialogClassName={cx({
            [classes.hideDialogPage]: !showFileSelectionPage,
          })}
        />
      )}
    </>
  );
});

const useStyles = makeStylesHook({
  hideDialogPage: {
    display: 'none',
  },
});
