import React from 'react';

import Typography from '@mui/material/Typography';

import { useFilterPlateEditorState } from 'client/app/components/Parameters/FiltrationPlateLayout/lib/editorState';
import { useFactorCombinations } from 'client/app/components/Parameters/FiltrationPlateLayout/lib/parameterUtils';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export default function FiltrationDesignInfo() {
  const classes = useStyles();

  const {
    state: { plateType },
  } = useFilterPlateEditorState();
  const { factorCombinationCount, replicateCount, wellCount } = useFactorCombinations();

  return plateType && wellCount > 0 ? (
    <Typography className={classes.info} variant="body1">
      Your workflow has<span className={classes.bold}> {factorCombinationCount} </span>
      factorial combinations and<span className={classes.bold}> {replicateCount} </span>
      replicates. Therefore, each resin will require
      <span className={classes.bold}> {wellCount} </span>wells.
    </Typography>
  ) : null;
}

const useStyles = makeStylesHook(({ spacing }) => ({
  info: {
    width: 'fit-content',
    background: Colors.BLUE_0,
    padding: spacing(3),
    margin: spacing(3, 4),
    borderRadius: spacing(2),
  },
  bold: {
    fontWeight: 700,
  },
}));
