import React, { useCallback } from 'react';

import { ArrayElement, draftWorkflowsQuery } from 'client/app/gql';
import getWorkflowPropsBySource from 'client/app/lib/workflow/getWorkflowPropsBySource';
import { EntityCard } from 'common/ui/components/EntityCard';
import EntityCardMenu from 'common/ui/components/EntityCardMenu';

type DraftWorkflowCardProps = {
  draftWorkflow: ArrayElement<draftWorkflowsQuery['draftWorkflows']['items']>;
  onDeleteDraft: (draftId: WorkflowId, name: string) => void;
};

export default function DraftWorkflowCard({
  draftWorkflow,
  onDeleteDraft,
}: DraftWorkflowCardProps) {
  const {
    id,
    name,
    source,
    lastModifiedAt,
    createdBy: { displayName: author },
  } = draftWorkflow;

  const { EditorIcon, route } = getWorkflowPropsBySource(source);
  const path = route.getPath({ workflowId: id });

  const nameColumn = { label: 'Draft name', value: name };
  const authorColumn = { label: 'Author', value: author };
  const dateColumn = { label: 'Last modified', value: new Date(lastModifiedAt) };

  const handleClickDelete = useCallback(
    (e: React.MouseEvent) => {
      // Icon for delete is under <a> tag that causes redirect.
      // In order to prevent the redirect, the click event must not propagate up
      e.preventDefault();
      e.stopPropagation();
      onDeleteDraft(id, name);
    },
    [id, name, onDeleteDraft],
  );

  const interaction = { to: path };

  return (
    <EntityCard
      icon={<EditorIcon fontSize="small" />}
      nameColumn={nameColumn}
      authorColumn={authorColumn}
      dateColumn={dateColumn}
      interaction={interaction}
      rightSlot={
        <EntityCardMenu menu={[{ label: 'Delete', onClick: handleClickDelete }]} />
      }
    />
  );
}
