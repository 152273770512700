import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DeckOptionsIcon = React.memo<SvgIconProps>(function DeckOptionsIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2137 9.2835C20.0458 8.0054 21 6.29265 21 5.15914C21 3.41439 19.6569 2 18 2C16.3431 2 15 3.41439 15 5.15914C15 6.29265 15.9542 8.00539 16.7863 9.2835C17.4296 10.2717 18 11 18 11C18 11 18.31 10.6041 18.7308 10C18.8807 9.78476 19.0447 9.5431 19.2137 9.2835ZM18 7.4503C18.2701 6.99517 18.515 6.53784 18.6994 6.11693C18.9488 5.54787 19 5.23977 19 5.15914C19 4.4203 18.4561 4 18 4C17.5439 4 17 4.4203 17 5.15914C17 5.23977 17.0512 5.54787 17.3006 6.11693C17.485 6.53784 17.7299 6.99517 18 7.4503ZM12 4V6H4V18H20V12H22V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6C2 4.89543 2.89543 4 4 4L12 4Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
