import React from 'react';

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { usePlatesByType } from 'client/app/api/PlateTypesApi';
import { FiltrationPlateLayoutValue } from 'client/app/components/Parameters/FiltrationPlateLayout/lib/parameterUtils';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { pluralize } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  value?: FiltrationPlateLayoutValue;
};

export default function FiltrationPlateLayoutParameter({ value }: Props) {
  const classes = useStyles();
  const dispatch = useWorkflowBuilderDispatch();
  const additionalPanel = useWorkflowBuilderSelector(state => state.additionalPanel);
  const [_, isLoading] = usePlatesByType();

  const handleClick = () => {
    if (!isLoading) {
      dispatch({
        type: 'setAdditionalPanel',
        payload: 'FilterPlateEditor',
      });
    }
  };

  return (
    <Button
      className={cx(classes.plateLayoutBtn, {
        [classes.active]: additionalPanel === 'FilterPlateEditor',
      })}
      variant="secondary"
      onClick={handleClick}
      startIcon={<ChevronLeftOutlinedIcon />}
    >
      {isLoading ? (
        <CircularProgress className={classes.progress} size={16} />
      ) : (
        <Typography className={classes.buttonText} variant="body2">
          {buildCopy(value)}
        </Typography>
      )}
    </Button>
  );
}

function buildCopy(value?: FiltrationPlateLayoutValue) {
  if (isEmpty(value)) return 'EDIT PLATE LAYOUT';

  const wellRegionsCount = Object.keys(value.wellRegions).length;

  return wellRegionsCount > 0
    ? `${value.plateType}, ${pluralize(wellRegionsCount, 'region')}`
    : value.plateType;
}

const useStyles = makeStylesHook(({ palette }) => ({
  plateLayoutBtn: {
    width: '100%',
    justifyContent: 'center',
  },
  active: {
    background: Colors.BLUE_5,
    borderColor: palette.primary.main,
    color: palette.primary.main,
  },
  buttonText: {
    lineHeight: '16px',
    textTransform: 'none',
  },
  errorText: {
    color: palette.error.main,
  },
  progress: {
    color: 'inherit',
    margin: 'auto',
  },
}));
