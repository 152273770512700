import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { isDefined } from 'common/lib/data';
import { mapObject } from 'common/object';
import { TableColumn } from 'common/types/spreadsheetEditor';
import Button from 'common/ui/components/Button';
import {
  ExtraColumnsBySheet,
  getAutomappedHeaders,
  getHeadersFromColumns,
  hasParsedMoreSheetsThanExpected,
  MappedHeaders,
  MappedHeadersBySheet,
} from 'common/ui/components/Dialog/headersMappingHelper';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

const DROPDOWN_WIDTH = '248px';
const PLACEHOLDER = 'Column name or action';
const DISCARD_COLUMN = 'Discard column';
const ADD_COLUMN = 'Add column';
const ALL_AVAILABLE_ACTIONS = [ADD_COLUMN, DISCARD_COLUMN];

type Sheet = {
  /** Columns parsed from the file users uploaded. */
  parsedColumns: TableColumn[];
  /** Required columns to match the schema */
  expectedColumns: TableColumn[];
  /** Optional additional columns that the schema allows */
  optionalColumns?: TableColumn[];
};
export type HeadersMappingDialogProps = {
  sheets: Sheet[];
  /** If true, remove the "Actions" group of options from the dropdown. */
  hideActions?: boolean;
} & DialogProps<{
  mappedHeadersBySheet: MappedHeadersBySheet;
  extraColumnsBySheet?: ExtraColumnsBySheet;
} | null>;

export default function HeadersMappingDialog(props: HeadersMappingDialogProps) {
  const { sheets, isOpen, onClose, hideActions } = props;
  const classes = useStyles();

  const [mappedHeadersBySheet, setMappedHeadersBySheet] = useState<MappedHeadersBySheet>(
    () => sheets.map((_, sheetIndex) => ({ [sheetIndex]: {} } as MappedHeaders)),
  );
  const [totalDistanceBySheet, setTotalDistanceBySheet] =
    useState<{
      [sheetIndex: number]: number;
    } | null>(null);

  const [expectedHeadersBySheet, parsedHeadersBySheet, allAvailableHeadersBySheet] =
    useMemo(() => {
      const expectedHeadersBySheet = sheets.map(({ expectedColumns }) =>
        getHeadersFromColumns(expectedColumns),
      );
      const parsedHeadersBySheet = sheets.map(({ parsedColumns }) =>
        getHeadersFromColumns(parsedColumns),
      );
      const optionalHeadersBySheet = sheets.map(({ optionalColumns }) =>
        getHeadersFromColumns(optionalColumns || []),
      );
      const allAvailableHeadersBySheet = sheets.map((_, sheetIndex) => [
        ...expectedHeadersBySheet[sheetIndex],
        ...(optionalHeadersBySheet[sheetIndex] || {}),
      ]);

      return [expectedHeadersBySheet, parsedHeadersBySheet, allAvailableHeadersBySheet];
    }, [sheets]);

  const handleCancel = useCallback(() => onClose(null), [onClose]);
  const handleClose = useCallback(() => {
    const filteredMappedHeadersBySheet = mapObject(
      mappedHeadersBySheet,
      (_, mappedHeaders) =>
        Object.entries(mappedHeaders).reduce(
          (newMappedHeaders, [parsedHeader, mappedHeader]) => {
            if (mappedHeader === DISCARD_COLUMN) {
              return newMappedHeaders;
            }
            if (mappedHeader === ADD_COLUMN) {
              return { ...newMappedHeaders, [parsedHeader]: parsedHeader };
            }
            return { ...newMappedHeaders, [parsedHeader]: mappedHeader };
          },
          {},
        ),
    );

    const extraColumnsBySheet: ExtraColumnsBySheet = mapObject(
      mappedHeadersBySheet,
      (sheetIndex: number, mappedHeaders) =>
        Object.entries(mappedHeaders)
          .filter(([_, mappedHeader]) => mappedHeader === ADD_COLUMN)
          .map(([parsedHeader, _]) => {
            const columnIndex = sheets[sheetIndex].parsedColumns.findIndex(
              column => column.name === parsedHeader,
            );
            return {
              name: parsedHeader,
              type: sheets[sheetIndex].parsedColumns[columnIndex].type,
            };
          }),
    );

    onClose({
      mappedHeadersBySheet: filteredMappedHeadersBySheet,
      extraColumnsBySheet,
    });
  }, [mappedHeadersBySheet, onClose, sheets]);

  useEffect(() => {
    for (const sheet in sheets) {
      const [automappedHeaders, initialTotalDistance] = getAutomappedHeaders(
        parsedHeadersBySheet[sheet],
        allAvailableHeadersBySheet[sheet],
      );

      setMappedHeadersBySheet(prev => ({
        ...prev,
        [sheet]: automappedHeaders,
      }));
      setTotalDistanceBySheet(prev => ({
        ...prev,
        [sheet]: initialTotalDistance,
      }));
    }
  }, [allAvailableHeadersBySheet, parsedHeadersBySheet, sheets]);

  // Automatically close the dialog if all the headers are a perfect match.
  useEffect(() => {
    if (!mappedHeadersBySheet || !totalDistanceBySheet) {
      return;
    }

    const allHeadersMatchPerfectly = Object.values(totalDistanceBySheet).every(
      totalDistance => totalDistance === 0,
    );
    if (
      allHeadersMatchPerfectly &&
      hideActions &&
      !hasParsedMoreSheetsThanExpected(parsedHeadersBySheet, expectedHeadersBySheet)
    ) {
      handleClose();
    }
  }, [
    expectedHeadersBySheet,
    handleClose,
    hideActions,
    mappedHeadersBySheet,
    parsedHeadersBySheet,
    totalDistanceBySheet,
  ]);

  const hasSelectedAllExpectedHeaders = useMemo(() => {
    for (const sheet in sheets) {
      const selectedHeaders = Object.keys(mappedHeadersBySheet[sheet]);
      const enoughSelected =
        selectedHeaders.length >= expectedHeadersBySheet[sheet].length;
      if (!enoughSelected) {
        return false;
      }

      const uniqueSelectedHeaders = new Set(
        selectedHeaders.map(
          selectedHeader => mappedHeadersBySheet[sheet][selectedHeader],
        ),
      );
      const allExpectedHeadersSelected = expectedHeadersBySheet[sheet].every(
        expectedHeader => uniqueSelectedHeaders.has(expectedHeader),
      );
      if (!allExpectedHeadersSelected) {
        return false;
      }
    }
    return true;
  }, [expectedHeadersBySheet, mappedHeadersBySheet, sheets]);

  return (
    <Dialog open={isOpen} onClose={handleCancel} fullWidth maxWidth="sm">
      <DialogTitle>Map Headers</DialogTitle>
      <DialogContent>
        {sheets.map((sheet, sheetIndex) => (
          <React.Fragment key={`table-container-${sheetIndex}`}>
            {sheets.length > 1 && (
              <Typography variant="body2" className={classes.sheetTitle}>
                Sheet {sheetIndex + 1}
              </Typography>
            )}
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>File Header</TableCell>
                  <TableCell>Column</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sheet.parsedColumns.map(parsedColumn => (
                  <HeaderMappingRow
                    allAvailableHeaders={allAvailableHeadersBySheet[sheetIndex]}
                    expectedHeaders={expectedHeadersBySheet[sheetIndex]}
                    key={`${sheetIndex}-${parsedColumn.name}`}
                    mappedHeaders={mappedHeadersBySheet[sheetIndex]}
                    parsedHeader={parsedColumn.name}
                    sheetIndex={sheetIndex}
                    setMappedHeadersBySheet={setMappedHeadersBySheet}
                    showActions={!hideActions}
                  />
                ))}
              </TableBody>
            </Table>
          </React.Fragment>
        ))}
      </DialogContent>
      <DialogActions>
        <Tooltip
          title={
            hasSelectedAllExpectedHeaders
              ? 'Confirm selection'
              : 'Please select all required headers'
          }
        >
          <div>
            <Button
              onClick={handleClose}
              variant="tertiary"
              color="primary"
              disabled={!hasSelectedAllExpectedHeaders}
            >
              Done
            </Button>
          </div>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}

type HeaderMappingRowProps = {
  allAvailableHeaders: string[];
  showActions: boolean;
  mappedHeaders: MappedHeaders;
  expectedHeaders: string[];
  parsedHeader: string;
  sheetIndex: number;
  setMappedHeadersBySheet: React.Dispatch<React.SetStateAction<MappedHeadersBySheet>>;
};

const HeaderMappingRow = React.memo(function HeaderMappingRow(
  props: HeaderMappingRowProps,
) {
  const {
    allAvailableHeaders,
    expectedHeaders,
    showActions,
    parsedHeader,
    mappedHeaders,
    sheetIndex,
    setMappedHeadersBySheet,
  } = props;
  const classes = useStyles();

  const handleSelectOption = useCallback(
    (event: SelectChangeEvent<string>) => {
      const selectedHeader = event.target.value;
      const previousSelectedHeader: string | undefined = mappedHeaders[parsedHeader];

      // If users trying to select the same option, do nothing.
      if (selectedHeader === previousSelectedHeader) {
        return;
      }

      // Update dropdown selection
      setMappedHeadersBySheet(prev => {
        // Users can select the dropdown option with value "undefined".
        // If so, delete the mapping.
        if (!selectedHeader) {
          const { [parsedHeader]: _, ...newHeaders } = prev[sheetIndex];
          return { ...prev, [sheetIndex]: { ...newHeaders } };
        }

        const newMappedHeaders = {
          ...prev,
          [sheetIndex]: {
            ...prev[sheetIndex],
            [parsedHeader]: selectedHeader,
          },
        };

        // Users selected a new value for the dropdown. Check if this
        // value was selected in another dropdown.
        const previousMappedHeader = Object.entries(mappedHeaders).find(
          ([parsedHeader, expectedHeader]) =>
            expectedHeader === selectedHeader && isDefined(parsedHeader),
        );

        const isActionSelected = ALL_AVAILABLE_ACTIONS.includes(selectedHeader);
        if (!previousMappedHeader || isActionSelected) {
          return newMappedHeaders;
        } else {
          // If the user is selecting a dropdown option which was already
          // selected in another dropdown, swap the values.
          const previousParsedHeader = previousMappedHeader[0];
          return {
            ...prev,
            [sheetIndex]: {
              ...prev[sheetIndex],
              [previousParsedHeader]: previousSelectedHeader,
              [parsedHeader]: selectedHeader,
            },
          };
        }
      });
    },
    [mappedHeaders, parsedHeader, setMappedHeadersBySheet, sheetIndex],
  );

  // If an auto-mapped header is not found, we default to adding that
  // column rather than discarding it.
  useEffect(() => {
    if (showActions && !mappedHeaders[parsedHeader]) {
      setMappedHeadersBySheet(prev => ({
        ...prev,
        [sheetIndex]: {
          ...prev[sheetIndex],
          [parsedHeader]: ADD_COLUMN,
        },
      }));
    }
  }, [mappedHeaders, parsedHeader, setMappedHeadersBySheet, sheetIndex, showActions]);

  const [sortedOptions, actions] = useMemo(() => {
    const allOptionsAvailable = [...allAvailableHeaders];
    return [
      allOptionsAvailable.sort().map(option => ({ label: option, value: option })),
      ALL_AVAILABLE_ACTIONS.map(option => ({ label: option, value: option })),
    ];
  }, [allAvailableHeaders]);

  const defaultValue = useMemo(() => {
    if (!mappedHeaders[parsedHeader]) {
      return showActions ? ADD_COLUMN : PLACEHOLDER;
    }

    return mappedHeaders[parsedHeader];
  }, [mappedHeaders, parsedHeader, showActions]);

  // Expected headers are marked as required by modifying the label's formatting.
  const formatLabel = useCallback(
    (label: string) => {
      const optionIsRequired = expectedHeaders.includes(label);
      const optionNotSelected = !Object.values(mappedHeaders).includes(label);
      if (optionIsRequired && optionNotSelected) {
        return `${label}*`;
      }
      return label;
    },
    [expectedHeaders, mappedHeaders],
  );

  return (
    <TableRow>
      <TableCell>{parsedHeader}</TableCell>
      <TableCell>
        <InputLabel shrink>Matches:</InputLabel>
        <Select
          autoWidth
          value={defaultValue}
          onChange={handleSelectOption}
          displayEmpty
          fullWidth
          disableUnderline
          MenuProps={{
            PaperProps: { className: classes.dropdownContainer },
            MenuListProps: { className: classes.dropdownList },
          }}
        >
          {!showActions && (
            <MenuItem value={PLACEHOLDER}>
              <em>{PLACEHOLDER}</em>
            </MenuItem>
          )}
          {showActions && (
            <ListSubheader className={classes.actionSubheader}>
              <Typography variant="subtitle2" color="textPrimary">
                Assign column name
              </Typography>
            </ListSubheader>
          )}
          {sortedOptions.map(option => (
            <MenuItem key={option.label} value={option.label}>
              {formatLabel(option.label)}
            </MenuItem>
          ))}
          {showActions && (
            <ListSubheader className={classes.actionSubheader}>
              <Typography variant="subtitle2" color="textPrimary">
                Column actions
              </Typography>
            </ListSubheader>
          )}
          {showActions &&
            actions.map(option => (
              <MenuItem key={option.label} value={option.label}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
      </TableCell>
    </TableRow>
  );
});

const useStyles = makeStylesHook(theme => ({
  sheetTitle: {
    marginTop: theme.spacing(6),
  },
  actionSubheader: {
    margin: theme.spacing(6, 0, 3, 0),
  },
  dropdownContainer: {
    width: DROPDOWN_WIDTH,
    borderRadius: '8px',
  },
  dropdownList: {
    padding: 0,
    margin: theme.spacing(6, 0, 5, 0),
  },
}));
