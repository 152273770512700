import { useCallback, useMemo } from 'react';

import { History } from 'history';
import { useHistory, useLocation } from 'react-router-dom';

import { parseScreenID } from 'common/ui/components/navigation/parseScreenID';
import { NavigationRoute, ScreenID } from 'common/ui/navigation';

export type NavigationMethod = 'push' | 'replace';
type NavigationOptions = {
  method: NavigationMethod;
  keepURLSearchParams: boolean;
  /**
   * New URL search parameters. Will be appended to the end of existing URL search parameters.
   */
  additionalURLSearchParams: URLSearchParams;
};

type Navigation = {
  /**
   * Navigates to given route. This is for cases to manually navigate to a page, e.g. in response to
   * async event.
   *
   * @param route: Route to navigate to
   * @param routeParam:
   * Route parameters, usually object like {worflowId: 'abc'}. The type is defined at the route declaration
   * which guarantees that all parameters are passed to the route.
   *
   * @param method:
   * Value `push` will add the URL to the browser history, allowing to navigate back.
   * Value `replace` will replace current URL, often useful to throw away current URL with side-effects.
   */
  navigate: <T>(
    route: NavigationRoute<T>,
    routeParam: T,
    options?: Partial<NavigationOptions>,
  ) => void;

  /**
   * Opens route in new tab. Based on browser settings this may open new window, new tab, or get blocked.
   */
  openInNewTab: <T>(route: NavigationRoute<T>, routeParam: T) => void;

  /**
   * Returns absolute URL when relative URL is not sufficient. Typically when URL is to be shared outside
   * the app, e.g. in an email or third party app like phabricator.
   */
  getAbsoluteURL: <T>(route: NavigationRoute<T>, routeParam: T) => string;

  /**
   * Returns path for given screenID. For example for screenID 'worfklow' this
   * will return "/workflow". Only to be used for top-level navigation, you most likely don't need this.
   */
  getScreenPath: (screenID: ScreenID) => string;

  /**
   * Returns current displayed screen. The logic how to parse screenID from location lives in parseScreenID.
   */
  currentScreenID: ScreenID;
};

/**
 * How to use `useNavigation` hook:
 *
 * Goal: Render link to a route, for example <a href="/workflow/123-abc">Workflow</a>.
 *
 *   // You don't need useNavigation() for this. Assuming there is yourRoute: NavigationRoute<T>
 *   // available, just do:
 *
 *   import { Link } from 'react-router-dom'; // important: not Link from @mui
 *   ...
 *   return <Link to={yourRoute.getPath(yourRouteParams)};
 *
 *
 * Goal: Render a button that opens route on click.
 *
 *   <RouteButton route={yourRoute} routeParam={yourRouteParams} />
 *
 * Note: There is also RouteIconButton

 *
 * Goal: Manually navigate to a route.
 *
 *   const { navigate } = useNavigation();
 *   navigate(yourRoute, yourRouteParams);

 *
 * Goal: Open route in new tab/window
 *
 *   // note that new tab vs. new window is defined by browser settings and we have no control over that
 *   const { navigate } = useNavigation();
 *   navigation.openInNewTab(yourRoute, yourRouteParams);
 *
 * For cases where we need "full" URL, there is method navigation.getAbsoluteURL, but it's
 * expected to be used when the URL is to be shared outside our app. In fact, all places that currently use it
 * are convertible to route.getPath except for one - in SimulationCard, where it's used for link in email.
 */
export function useNavigation(): Navigation {
  const history: History = useHistory();

  const getAbsoluteURL = useCallback(
    <T>(route: NavigationRoute<T>, routeParam: T) => {
      const url = getURL(history, route, routeParam);

      // The result of calling `getURL()` changes depending on the router-type.
      // The `HashRouter` includes the full origin, but `BrowserRouter` doesn't.
      if (!url.startsWith('http')) {
        return `${window.location.origin}${url}`;
      }

      return url;
    },
    [history],
  );

  const navigate = useCallback(
    <T>(
      route: NavigationRoute<T>,
      routeParam: T,
      options?: Partial<NavigationOptions>,
    ) => {
      const {
        method = 'push',
        keepURLSearchParams = false,
        additionalURLSearchParams = undefined,
      } = options ?? {};

      let newURL = route.getPath(routeParam);
      if (keepURLSearchParams) {
        newURL += history.location.search;
      }

      if (additionalURLSearchParams) {
        const hasExistingParams = newURL.includes('?');
        newURL += `${
          hasExistingParams ? '&' : '?'
        }${additionalURLSearchParams.toString()}`;
      }

      if (method === 'push') {
        history.push(newURL);
      } else {
        history.replace(newURL);
      }
    },
    [history],
  );

  const openInNewTab = useCallback(
    <T>(route: NavigationRoute<T>, routeParam: T) =>
      window.open(getURL(history, route, routeParam)),
    [history],
  );

  const getScreenPath = useCallback((screenID: ScreenID) => `/${screenID ?? ''}`, []);

  const location = useLocation();
  const currentScreenID = useMemo(() => parseScreenID(location), [location]);

  return useMemo(
    () => ({
      getAbsoluteURL,
      currentScreenID,
      getScreenPath,
      navigate,
      openInNewTab,
    }),
    [getAbsoluteURL, currentScreenID, getScreenPath, navigate, openInNewTab],
  );
}

/**
 * Computes the URL. Please do not use externally, it's just helper for
 * the hook methods. See useNavigation documentation for how to navigate around the app.
 */
function getURL<T>(history: History, route: NavigationRoute<T>, routeParams: T): string {
  return history.createHref({ pathname: route.getPath(routeParams) });
}
