import React, {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Dimensions } from 'common/types/Dimensions';
import { Position2d } from 'common/types/Position';
import {
  MouseMode,
  MouseModeControlContext,
} from 'common/ui/components/Workspace/MouseModeControl';
import useStateWithRef from 'common/ui/hooks/useStateWithRef';
import { Point2D } from 'common/ui/lib/ClickRecognizer';

type Options = {
  /**
   * Display the shim and capture pointer down as soon as we are in selection mode.
   *
   * If false, the shim won't be displayed until onPointerDown is called. The call site
   * is responsible to attach onPointerDown as an `poinderdown` handler to an element.
   * Because by default onPointerDown is handling the shim's 'pointerdown' event,
   * therefore if the shim is not visible before the user clicking, it can handle the user clicking;
   * we need another element to do so.
   */
  displayShimInSelectionMode?: boolean;
  cursor?: CSSProperties['cursor'];
};

export function useSelectionWithControlledMode(
  /**
   * dimensions of the target element listening to the pointer event
   */
  dimensions: Dimensions,
  /**
   * Callback handling completed selection. It gets the selection dimensions.
   * If it optionally returns `true` we will stay inSelectionMode
   */
  onSelection: (selection: Dimensions) => void | boolean,
  inSelectionMode: boolean,
  setInSelectionMode: React.Dispatch<React.SetStateAction<boolean>>,
  options: Options = {},
  /**
   * Callback to hook into mouse movement action and do something e.g. highlighting elements
   */
  onMove?: (dimensions: Dimensions) => void,
) {
  const { displayShimInSelectionMode, cursor = 'crosshair' } = options;
  /**
   * Using refs rather than state for the consts below because
   * these are used inside async event listener callbacks, which would
   * be triggered with out-of-date state.
   */
  const inSelectionModeRef = useRef(inSelectionMode);
  useEffect(() => {
    inSelectionModeRef.current = inSelectionMode;
  }, [inSelectionMode, inSelectionModeRef]);
  const [isSelecting, setIsSelecting, isSelectingRef] = useStateWithRef(false);
  const selectionPos = useRef<Point2D>({ x: 0, y: 0 });
  const selectionStartPos = useRef<Point2D>({ x: 0, y: 0 });
  const shimNodeRef = useRef<HTMLDivElement | null>(null);

  const [selectionDimensions, setSelectionDimensions] = useState<Dimensions>({
    height: 0,
    left: 0,
    top: 0,
    width: 0,
  });

  const getSelectionDimensions = useCallback(() => {
    return getDimensions(selectionStartPos.current, selectionPos.current);
  }, []);

  const onPointerMove = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      if (!isSelectingRef.current) {
        return;
      }
      e.stopPropagation();
      selectionPos.current = {
        x: e.nativeEvent.offsetX + dimensions.left,
        y: e.nativeEvent.offsetY + dimensions.top,
      };
      setSelectionDimensions(getSelectionDimensions());
      onMove?.(getSelectionDimensions());
    },
    [dimensions.left, dimensions.top, getSelectionDimensions, isSelectingRef, onMove],
  );

  const onPointerUp = useCallback(
    (e: PointerEvent) => {
      if (isSelectingRef.current) {
        e.stopPropagation();
        setIsSelecting(false);
        const shouldStayInSelectionMode = onSelection(getSelectionDimensions());
        setInSelectionMode(!!shouldStayInSelectionMode);
        return;
      }
    },
    [
      getSelectionDimensions,
      isSelectingRef,
      onSelection,
      setInSelectionMode,
      setIsSelecting,
    ],
  );

  // many things can go stale between pointer down and pointer up
  // so we use a ref for the handler.
  const onPointerUpRef = useRef(onPointerUp);
  useEffect(() => {
    onPointerUpRef.current = onPointerUp;
  }, [onPointerUp]);

  const handlePointerUpThroughRef = useCallback((args: PointerEvent) => {
    onPointerUpRef.current(args);
    window.removeEventListener('pointerup', handlePointerUpThroughRef);
  }, []);

  const onPointerDown = useCallback(
    (e: React.PointerEvent<HTMLElement | SVGElement>) => {
      if (inSelectionModeRef.current) {
        setSelectionDimensions(prev => ({ ...prev, width: 0, height: 0 }));
        const p = {
          x: e.nativeEvent.offsetX + dimensions.left,
          y: e.nativeEvent.offsetY + dimensions.top,
        };
        setIsSelecting(true);
        selectionPos.current = p;
        selectionStartPos.current = p;
        e.stopPropagation();
      }

      window.addEventListener('pointerup', handlePointerUpThroughRef);
    },
    [
      dimensions.left,
      dimensions.top,
      handlePointerUpThroughRef,
      inSelectionModeRef,
      setIsSelecting,
    ],
  );

  const onBlur = useCallback(() => {
    // If window loses focus (e.g. browser print dialog opened) and cmd/meta is released,
    // the browser will not receive a keyup event for cmd/meta. This method cancels the
    // selection mode on window blur. Without this fix, the browser can get stuck in
    // selection mode when browser loses focus. For clarity on the states, when
    // inSelectionMode is true the user is able to drag a selection (ie holding meta/cmd).
    // isSelecting is true when the user has initiated such a selection (ie. holding
    // meta/cmd *and* dragging).
    if (inSelectionModeRef.current) {
      setInSelectionMode(false);
      setIsSelecting(false);
    }
  }, [inSelectionModeRef, setInSelectionMode, setIsSelecting]);

  // The shim node should lay on top of everything else.
  // It's used to intercept mouse events during visual selection.
  // Without it, calculating the workspace-local x and y coordinates is difficult.
  // Likewise, without it, everything has to know about what is on the DOM
  // to prevent pointer events from firing during dragging.
  const shimNode = useMemo(() => {
    const displayShim = isSelecting || (displayShimInSelectionMode && inSelectionMode);
    return (
      <div
        ref={shimNodeRef}
        onPointerDown={displayShimInSelectionMode ? onPointerDown : undefined}
        onPointerMove={onPointerMove}
        style={{
          cursor: inSelectionMode ? cursor : 'auto',
          display: displayShim ? 'block' : 'none',
          position: 'absolute',
          zIndex: 3,
          ...dimensions,
        }}
      />
    );
  }, [
    cursor,
    dimensions,
    displayShimInSelectionMode,
    inSelectionMode,
    isSelecting,
    onPointerDown,
    onPointerMove,
  ]);

  useEffect(() => {
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, [onBlur]);

  return {
    /**
     * Element to catch the pointer events
     */
    shimNode,
    /**
     * Dimensions of the ongoing selection
     */
    selectionDimensions,
    /**
     * In selection mode, pointer down will start selecting.
     */
    inSelectionMode,
    setInSelectionMode,
    /**
     * When selectig (isSelcting === true), pointer movement should be captured by the shim
     *  and the selectionDimensions updated accordingly
     */
    isSelecting,
    onPointerDown,
  };
}

export function useSelectionWithMouseControlContext(
  /**
   * dimensions of the target element listening to the pointer event
   */
  dimensions: Dimensions,
  /**
   * Callback handling completed selection. It gets the selection dimensions.
   */
  onSelection: (selection: Dimensions) => void,
  requiredMouseMode: MouseMode,

  options: Options = {},
  /**
   * Callback to hook into mouse movement and do something e.g. highlighting elements
   */
  onMove?: (selection: Dimensions) => void,
) {
  const { mode, setMode } = useContext(MouseModeControlContext);
  const inSelectionMode = mode === requiredMouseMode;
  const setInSelectionMode = useCallback(
    (valOrFn: boolean | ((val: boolean) => boolean)) => {
      if (typeof valOrFn === 'function') {
        setMode(mode =>
          valOrFn(mode === requiredMouseMode) ? requiredMouseMode : 'pan',
        );
        return;
      }
      setMode(valOrFn ? requiredMouseMode : 'pan');
    },
    [requiredMouseMode, setMode],
  );
  return useSelectionWithControlledMode(
    dimensions,
    onSelection,
    inSelectionMode,
    setInSelectionMode,
    options,
    onMove,
  );
}

function getDimensions(startPosition: Position2d, endPosition: Position2d) {
  const minX = Math.min(startPosition.x, endPosition.x);
  const maxX = Math.max(startPosition.x, endPosition.x);
  const minY = Math.min(startPosition.y, endPosition.y);
  const maxY = Math.max(startPosition.y, endPosition.y);
  return {
    height: maxY - minY,
    left: minX,
    top: minY,
    width: maxX - minX,
  };
}
