import { FetchResult } from '@apollo/client';

// Unified client-side error handling for GraphQL mutations
export function getResultOrThrow<T, V>(
  // Result of an Apollo client call,
  // e.g. `await getApolloClient().mutate()` or `await getApolloClient().fetch()`
  apolloResult: FetchResult<T>,
  // Custom prefix for error messages. For example: 'Create workflow'.
  message: string,
  // Function that extracts the return value from the GraphQL result.
  // For example: `data => data.createWorkflow && data.createWorkflow.id`
  getter: (data: T) => V | null,
): V {
  if (apolloResult.errors) {
    throw new Error(`${message}: ${JSON.stringify(apolloResult.errors)}`);
  }
  if (!apolloResult.data) {
    throw new Error(`${message}: Empty response.`);
  }
  const res = getter(apolloResult.data);
  if (!res) {
    throw new Error(`${message}: ${JSON.stringify(apolloResult.data)}`);
  }
  return res;
}
