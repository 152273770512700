import React from 'react';

import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturb from '@mui/icons-material/DoNotDisturb';
import Info from '@mui/icons-material/InfoOutlined';
import Warning from '@mui/icons-material/Warning';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { svgIconClasses } from '@mui/material/SvgIcon';
import Typography, { typographyClasses } from '@mui/material/Typography';

import { LabwareInfoSection } from 'client/app/components/Labware/types';
import Colors from 'common/ui/Colors';
import Tooltip from 'common/ui/components/Tooltip';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type Props = {
  section: LabwareInfoSection;
  onClick?: (id: string | undefined) => void;
};

export default function Plates({
  section: { sectionHeader, sectionItems },
  onClick,
}: Props) {
  return sectionItems?.length > 0 ? (
    <Stack gap={4}>
      {sectionHeader && (
        <Typography variant="caption" fontWeight={600} color="textPrimary">
          {sectionHeader}
        </Typography>
      )}
      <Stack gap={4}>
        {sectionItems.map((item, index) => {
          const hasSecondaryLabels = item.secondaryLabels.length > 0;

          const tooltipContent = item.tooltipContent && (
            <Tooltip title={item.tooltipContent}>
              <Info />
            </Tooltip>
          );

          return (
            <PlateRow
              key={index}
              onClick={() => onClick?.(item.id)}
              clickable={item.clickable}
              selected={item.selected}
            >
              {item.compatibility ? (
                <CompatabilityIcon compatibility={item.compatibility} />
              ) : null}
              <TypographyWithTooltip variant="body1">
                {item.primaryLabel}
              </TypographyWithTooltip>
              {hasSecondaryLabels && tooltipContent}
              <SecondaryLabels>
                {hasSecondaryLabels ? (
                  <>
                    {item.secondaryLabels.map((label, idx) => (
                      <Typography key={idx} variant="body1">
                        {label}
                      </Typography>
                    ))}
                  </>
                ) : (
                  tooltipContent
                )}
              </SecondaryLabels>
            </PlateRow>
          );
        })}
      </Stack>
    </Stack>
  ) : null;
}

const CompatabilityIcon = ({
  compatibility,
}: {
  compatibility: Exclude<
    LabwareInfoSection['sectionItems'][number]['compatibility'],
    undefined
  >;
}) => {
  let tooltipMessage: string;
  let icon: React.ReactElement;

  switch (compatibility.value) {
    case 'compatible':
      tooltipMessage = 'Compatible';
      icon = <CheckCircleOutline color="success" />;
      break;
    case 'incompatible':
      tooltipMessage = 'Incompatible';
      icon = <DoNotDisturb color="error" />;
      break;
    case 'incompatibleWithAccessibleDevice':
      tooltipMessage = compatibility.accessibleDeviceCompatibilityMessage!;
      icon = <Warning color="warning" />;
      break;
  }

  return <Tooltip title={tooltipMessage}>{icon}</Tooltip>;
};

const PlateRow = styled(Stack, {
  shouldForwardProp: prop => prop !== 'clickable' && prop !== 'selected',
})<{
  clickable?: boolean;
  selected?: boolean;
}>(({ theme, clickable = false, selected = false }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(3),

  padding: theme.spacing(5, 6),
  backgroundColor: selected ? Colors.GREY_20 : Colors.WHITE,
  border: `1px solid ${theme.palette.divider}`,

  [`& .${typographyClasses.body1}`]: {
    color: theme.palette.text.primary,
  },
  [`& .${svgIconClasses.root}`]: {
    fontSize: '16px',
    [`&:last-child`]: {
      color: theme.palette.text.primary,
    },
  },

  cursor: clickable ? 'pointer' : 'initial',
  '&:hover': {
    backgroundColor: clickable ? Colors.GREY_20 : Colors.WHITE,
  },
}));

const SecondaryLabels = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(3),
}));
