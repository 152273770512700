import React, { useContext, useMemo } from 'react';

import { AutocompleteParameterValuesContext } from 'client/app/state/AutocompleteParameterValuesContext';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import Dropdown, { Option } from 'common/ui/filaments/Dropdown';

type Props = {
  anthaType: string;
  onChange: (value?: string) => void;
  disableUnderline?: boolean;
  /**
   * Options to offer in addition to those in the Parameter values.
   * These will be displayed even if they aren't used anywhere in the workflow.
   * */
  additionalOptions?: Option<string>[];
} & ParameterEditorBaseProps<string>;

const sortOptionsByLabel = (a: Option<string>, b: Option<string>) =>
  a.label.localeCompare(b.label);
/**
 * An Autocomplete component that sources options from values already provided
 * to parameters of the same antha type within the Workflow Builder.
 *
 * Consumes AutocompleteParameterValuesContext so needs to be nested under
 * AutocompleteParameterValuesContextProvider.
 */

export default function DropdownWithParameterValues(props: Props) {
  const autocompleteContext = useContext(AutocompleteParameterValuesContext);

  const options = useMemo(() => {
    const dynamicOptions = autocompleteContext
      .optionsForType(props.anthaType)
      .map(value => ({ label: value, value }));
    if (!props.additionalOptions || props.additionalOptions.length === 0) {
      return dynamicOptions;
    }

    const uniqueLabels: string[] = [];
    const uniqueOptions = [...props.additionalOptions, ...dynamicOptions].filter(
      option => {
        const isUnique = !uniqueLabels.includes(option.label);
        uniqueLabels.push(option.label);
        return isUnique;
      },
    );

    return uniqueOptions.sort(sortOptionsByLabel);
  }, [autocompleteContext, props.additionalOptions, props.anthaType]);

  const valueLabel = options.find(option => option.value === props.value)?.label ?? '';

  return (
    <Dropdown
      valueLabel={valueLabel}
      options={options}
      onChange={props.onChange}
      isDisabled={props.isDisabled}
      hasError={props.hasError}
      isRequired={props.isRequired}
      disableUnderline={props.disableUnderline}
    />
  );
}
