import React, { createContext, useContext } from 'react';

import { ExecutionStage } from 'client/app/apps/execution-details/types';

const ExecutionStageContext = createContext<ExecutionStage | null>(null);

type Props = {
  stage: ExecutionStage;
  children: React.ReactNode | React.ReactNode[];
};

export default function ExecutionStageProvider({ stage, children }: Props) {
  return (
    <ExecutionStageContext.Provider value={stage}>
      {children}
    </ExecutionStageContext.Provider>
  );
}

export const useExecutionStage = () => useContext(ExecutionStageContext);
