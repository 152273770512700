import React from 'react';

import { Option } from 'common/types/Option';
import Toggle, { ToggleButton } from 'common/ui/components/Toggle/Toggle';

type Props = {
  value: string;
  options: Option<string>[];
  onChange: (value: string) => void;
  disabled?: boolean;
};

export default function ToggleButtonsEditor({
  value,
  options,
  onChange,
  disabled,
}: Props) {
  return (
    <Toggle
      value={value}
      onChange={(_, value) => onChange(value)}
      exclusive
      disabled={disabled}
    >
      {options.map(option => (
        <ToggleButton key={option.value} value={option.value}>
          {option.label}
        </ToggleButton>
      ))}
    </Toggle>
  );
}
