import { useMemo } from 'react';

import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { FactorItem } from 'common/types/bundle';

export default function useDerivableFactors(editingFactor: FactorItem | undefined) {
  const factors = useWorkflowBuilderSelector(state => state.factors);
  const factorisedParameters = useWorkflowBuilderSelector(
    state => state.factorisedParameters,
  );

  return useMemo(() => {
    return factors
      ?.filter(
        /**
         * Cannot derive from factor which is being edited.
         * It could be created before as numeric Design factor and should not be on the list
         * of factors to derive from.
         *
         * Also cannot derive from a derived factor. Otherwise, there is a risk of undesired recursion.
         *
         * Also we don't allow to derive from a factor that was toggled off on the DOE instance panel.
         */
        f => {
          const factorisedElementName = f.path?.[1];
          const factorisedParameterName = f.path?.[2];
          const isFactorisedParameter =
            !!factorisedElementName && !!factorisedParameterName;
          const isToggledOn =
            isFactorisedParameter &&
            factorisedParameters[factorisedElementName][factorisedParameterName] === true;
          // Mutual exclusion under the hood is a derived factor. Cannot derive from a derived factor.
          const isPartOfMutualExclusionGroup = f.mutualExclusionGroup != null;

          return (
            f.id !== editingFactor?.id &&
            f.variableTypeName !== 'derived' &&
            !f.derivingExpression &&
            !f.sourceFactor &&
            (!isFactorisedParameter || isToggledOn) &&
            !isPartOfMutualExclusionGroup
          );
        },
      )
      .sort((a, b) => (a.displayName < b.displayName ? -1 : 1));
  }, [editingFactor?.id, factorisedParameters, factors]);
}
