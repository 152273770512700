import assert from 'assert';
import { v4 as uuid } from 'uuid';

/**
 * Decodes standard base64 with error-checking.  Returns null if the input is
 * malformed.
 */
export function decodeBase64(data: string): Buffer | null {
  // Regex from
  // https://stackoverflow.com/questions/475074/regex-to-parse-or-validate-base64-data
  const regex = '^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$';
  if (!new RegExp(regex).test(data)) {
    return null;
  }
  return Buffer.from(data, 'base64');
}

export function encodeBase64(s: string): string {
  return Buffer.from(s).toString('base64');
}

const uuidRegex = /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i;
/**
 * Checks if a string looks like a UUID.
 */
export function isUUID(value: string): boolean {
  // we could end by calling this function for an undefined value, as we happen to use data coming from antha-core in some situations
  // therefore they are parsed at run time (ie: javascript), and not enforced by any type check.
  // so it might look counter intuitive from a typescript perspective, but we still have to check that value is defined before using it
  if (!value) {
    return false;
  }

  return value.length === 36 && uuidRegex.test(value);
}

/**
 * Generates a random UUID but with a provided prefix. Use carefully - using a fixed
 * long prefix increases the chance of collisions.
 */
export function uuidWithPrefix(prefix: string): string {
  const result = prefix + uuid().substring(prefix.length);
  assert(
    isUUID(result),
    `UUID generated by uuidWithPrefix was invalid: '${result}'.  Is the prefix parameter valid?`,
  );
  return result;
}

/**
 * At runtime, checks if a string looks like a UUID.
 *
 * At compile-time, TypeScript assumes that if the check passes the parameter is
 * of type UUIDType, and not otherwise.  This allows constructs like:
 *
 * ```
 * if (isUUIDInfer<MyUUIDType>(str)) {
 *   // TS assumes `str` is of type MyUUIDType in this block
 * } else {
 *   // TS assumes *not* MyUUIDType in this block
 * }
 * ```
 */
export function isUUIDInfer<UUIDType extends string>(value: string): value is UUIDType {
  return uuidRegex.test(value);
}

export function stripTrailingSlashes(str: string): string {
  if (!str.endsWith('/')) {
    // Fast path when no changes needed
    return str;
  }
  return str.replace(/\/+$/, '');
}

/**
 * Don't compose URLs like this: `${myLink}/some/path/suffix`.
 * The URL might or might not end with a slash, and it will cause bugs like D5993.
 * Instead, do: concatURL(myLink, 'some/path/suffix');
 */
export function concatURL(url: string, pathToAppend: string) {
  if (pathToAppend.startsWith('/')) {
    pathToAppend = pathToAppend.substring(1);
  }
  return `${stripTrailingSlashes(url)}/${pathToAppend}`;
}

/**
 * Returns the final portion of a slash-delimited path or URL.  It first removes all trailing
 * slashes and then by removes anything before and including the final remaining slash, the same as
 * the basename Unix utility.
 */
export function basename(path: string) {
  path = stripTrailingSlashes(path);
  if (path.includes('/')) {
    return path.substring(path.lastIndexOf('/') + 1);
  } else {
    return path.substring(path.lastIndexOf('\\') + 1);
  }
}

const domainLabelRegex = /^[a-zA-Z0-9-]{1,63}$/;

/**
 * Returns true if the argument is a valid fully-qualified domain name, false
 * otherwise.
 *
 * See
 * https://stackoverflow.com/questions/11809631/fully-qualified-domain-name-validation/20204811#20204811
 * for lots of discussion about valid domain name formats.
 *
 * We originally used the regexp from that SO post, but it relies on negative
 * lookahead assertions, which are not supported by all browsers. It was also
 * somewhat unreadable. :)
 */
export function isFQDN(domain: string): boolean {
  // Max length is 253 characters, see https://devblogs.microsoft.com/oldnewthing/?p=7873
  if (domain.length > 253) {
    return false;
  }

  const labels = domain.split('.');
  if (labels.length < 2) {
    return false;
  }

  for (const label of labels) {
    if (!domainLabelRegex.test(label)) {
      return false;
    }

    // Labels can't start or end with hyphens
    //
    // NB: we could encode this logic into domainLabelRegex, but it makes the
    // regex much more complicated for little gain. So let's keep things simple.
    if (label.startsWith('-') || label.endsWith('-')) {
      return false;
    }
  }

  return true;
}

export function lowerCaseExceptFirstLetter(string: string): string {
  // avoid trying to get chars on undefined/empty strings
  if (string?.length === 0) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

/**
 * Sorts alphanumeric strings correctly. Case-insensitive.
 * E.g. `sample1, sample2, sample11`, rather than `sample1, sample11, sample2`
 */
export function alphanumericCompare(a: string, b: string) {
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
}

type AnyObjectWithName = {
  name: string;
};

export function byName(obj1: AnyObjectWithName, obj2: AnyObjectWithName) {
  return alphanumericCompare(obj1.name, obj2.name);
}
