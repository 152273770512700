export type ParsedConfigFile = {
  anthaDeviceConfig: DeviceConfigSummary;
  instanceConfig: DeviceConfigData;
  runConfigs: DeviceRunConfigData[];
};

const CONFIGURATION_FILE_SCHEMA_TYPE = 'AnthaDeviceConfig';

/**
 * Performs simple validation of device configuration file to make sure that the rest UI
 * can process it.
 * We don't want to pull a jsonschema validator into UI, so full validation
 * should be done in the backend.
 *
 * See https://paper.dropbox.com/doc/Device-configuration-instance-vs-run-configs-Aw3SaZjvbxgaM8XA31EZN
 * for schema definition and example documents.
 *
 * TODO: Move validation to the backend (T282)
 */
export function parseConfigurationFile(data: string): ParsedConfigFile {
  let file: any;
  try {
    file = JSON.parse(data);
  } catch (e) {
    throw new Error('Configuration file has to be a valid JSON document.');
  }
  if (typeof file !== 'object') {
    throw new Error('Configuration file has to be an object.');
  }
  if (file.SchemaType !== CONFIGURATION_FILE_SCHEMA_TYPE) {
    throw new Error(
      `Incorrect schema type for AnthaDeviceConfig - Expecting "${CONFIGURATION_FILE_SCHEMA_TYPE}" Got: "${file.SchemaType}".`,
    );
  }

  if (file.SchemaVersion !== '1') {
    throw new Error(`Incorrect schema version "${file.SchemaVersion}.`);
  }

  if (!file.AnthaDeviceConfig || typeof file.AnthaDeviceConfig !== 'object') {
    throw new Error(
      'Configuration file has to contains "AnthaDeviceConfig" field that is an object.',
    );
  }

  if (!file.InstanceConfig || typeof file.InstanceConfig !== 'object') {
    throw new Error(
      'Configuration file has to contains "InstanceConfig" field that is an object.',
    );
  }

  // RunConfigs field is optional
  if (file.RunConfigs) {
    if (!Array.isArray(file.RunConfigs)) {
      throw new Error('RunConfigs field has to be an array.');
    }
    const usedNames = new Set<string>();
    for (const runConfig of file.RunConfigs) {
      if (typeof runConfig !== 'object') {
        throw new Error('Each entry of RunConfigs array has to be an object.');
      }
      if (!runConfig.ConfigLabel || typeof runConfig.ConfigLabel !== 'string') {
        throw new Error(
          'Each RunConfig entry has to contain a string "ConfigLabel" field.',
        );
      }
      if (usedNames.has(runConfig.ConfigLabel)) {
        throw new Error('Run config labels have to be unique.');
      }
      usedNames.add(runConfig.ConfigLabel);
    }
  }

  return {
    anthaDeviceConfig: file.AnthaDeviceConfig,
    // Instance config has similar schema to the overall file, but withouth the run configs.
    // We can be specific here and extract only the fields we really need.
    instanceConfig: {
      SchemaVersion: file.SchemaVersion,
      SchemaType: file.SchemaType,
      AnthaDeviceConfig: file.AnthaDeviceConfig,
      InstanceConfig: file.InstanceConfig,
    } as DeviceConfigData,
    runConfigs: (file.RunConfigs || []) as DeviceRunConfigData[],
  };
}
