import React from 'react';

import { useQuery } from '@apollo/client';
import LinearProgress from '@mui/material/LinearProgress';
import { matchPath, useLocation } from 'react-router-dom';

import { QUERY_EXECUTION } from 'client/app/api/gql/queries';
import Content from 'client/app/apps/execution-details/Content';
import Header from 'client/app/apps/execution-details/Header';
import UIErrorBox from 'client/app/components/UIErrorBox';
import { executionRoutes } from 'client/app/lib/nav/actions';
import { isUUIDInfer } from 'common/lib/strings';

export default function ExecutionDetailsScreen() {
  const location = useLocation();

  const match = matchPath<{ executionId: ExecutionId }>(location.pathname, {
    path: executionRoutes.executionDetailsSubscreen.pathTemplate,
    exact: true,
  });

  const executionId: ExecutionId | undefined = match?.params.executionId;

  const { data, loading, error, refetch } = useQuery(QUERY_EXECUTION, {
    variables: {
      id: executionId as ExecutionId,
      withTasks: true,
      withVisualisations: true,
    },
    skip: !executionId,
  });

  if (!executionId || !isUUIDInfer<ExecutionId>(executionId)) {
    return <UIErrorBox>Sorry we could not find this Execution</UIErrorBox>;
  }
  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <UIErrorBox>{error.message}</UIErrorBox>;
  }
  if (!data) {
    return <UIErrorBox>Sorry we could not find this Execution</UIErrorBox>;
  }

  return (
    <>
      <Header execution={data.execution} />
      <Content execution={data.execution} onRefresh={refetch} />;
    </>
  );
}
