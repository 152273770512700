import { WellCoord } from 'common/types/bundle';
import { WellLocationOnDeckItem } from 'common/types/mix';
import { WellSortMode } from 'common/types/plateAssignments';
import { PlateType } from 'common/types/plateType';

type Availability = { available: boolean };

export function mapPlateWells<T>(
  plate: PlateType,
  sortMode: WellSortMode,
  value: (col: number, row: number) => T,
  filter?: (col: number, row: number) => boolean,
) {
  const result: T[] = [];
  if (sortMode === WellSortMode.BY_ROW) {
    for (let row = 0; row < plate.rows; row++) {
      for (let col = 0; col < plate.columns; col++) {
        if (!filter || filter(col, row)) {
          result.push(value(col, row));
        }
      }
    }
  } else if (sortMode === WellSortMode.BY_COLUMN) {
    for (let col = 0; col < plate.columns; col++) {
      for (let row = 0; row < plate.rows; row++) {
        if (!filter || filter(col, row)) {
          result.push(value(col, row));
        }
      }
    }
  }

  return result;
}

/**
 * Sorts a given array of wells by the given well sort mode. Will not
 * modify the input array.
 *
 * @param wells Array of wells to re-order.
 * @param sortMode Well sort mode
 * @returns New array of sorted wells
 */
export function reorderWellLocationOnDeckItem(
  wells: WellLocationOnDeckItem[],
  sortMode: WellSortMode = WellSortMode.BY_ROW,
): WellLocationOnDeckItem[] {
  return [
    ...wells.sort((a: WellLocationOnDeckItem, b: WellLocationOnDeckItem) => {
      return sortMode === WellSortMode.BY_ROW
        ? a.row - b.row || a.col - b.col
        : a.col - b.col || a.row - b.row;
    }),
  ];
}

/**
 * Sorts a given array of wells by the given well sort mode. Will not
 * modify the input array.
 *
 * @param wells Array of wells to re-order.
 * @param sortMode Well sort mode
 * @returns New array of sorted wells
 */
export function reorderWellCoord(
  wells: WellCoord[],
  sortMode: WellSortMode = WellSortMode.BY_ROW,
) {
  return [
    ...wells.sort((a: WellCoord, b: WellCoord) => {
      return sortMode === WellSortMode.BY_ROW
        ? a.y - b.y || a.x - b.x
        : a.x - b.x || a.y - b.y;
    }),
  ];
}

/**
 * A helper class for storing and modifying the availability of wells in a plate.
 */
export class WellAvailability extends Array<Availability> {
  private plate: PlateType;
  sortMode: WellSortMode;
  #available: WellLocationOnDeckItem[] | undefined;
  #unavailable: WellLocationOnDeckItem[] | undefined;

  constructor(plate: PlateType, sortMode: WellSortMode, defaultValue: boolean = true) {
    super(
      ...mapPlateWells(plate, sortMode, () => ({
        available: defaultValue,
      })),
    );
    this.plate = plate;
    this.sortMode = sortMode;
  }

  setSortMode(sortMode: WellSortMode) {
    this.sortMode = sortMode;
    if (this.#available) {
      this.#available = reorderWellLocationOnDeckItem(this.#available, sortMode);
    }
    if (this.#unavailable) {
      this.#unavailable = reorderWellLocationOnDeckItem(this.#unavailable, sortMode);
    }
  }

  wellAt(col: number, row: number): Availability {
    return this[row * this.plate.columns + col];
  }

  withAvailability(availableWells: readonly WellLocationOnDeckItem[]) {
    const result = new WellAvailability(this.plate, this.sortMode, false);
    availableWells.forEach(({ col, row }) => {
      const well = result.wellAt(col, row);
      if (well) {
        well.available = true;
      }
    });
    return result;
  }

  withPlate(plate: PlateType) {
    const result = new WellAvailability(plate, this.sortMode, true);
    this.unavailable.forEach(({ col, row }) => {
      const well = result.wellAt(col, row);
      if (well) {
        well.available = false;
      }
    });
    return result;
  }

  get available() {
    if (!this.#available) {
      this.#available = mapPlateWells(
        this.plate,
        this.sortMode,
        (col, row) => ({ col, row, deck_item_id: this.plate.id }),
        (col, row) => this.wellAt(col, row).available,
      );
    }

    return this.#available;
  }

  get unavailable() {
    if (!this.#unavailable) {
      this.#unavailable = mapPlateWells(
        this.plate,
        this.sortMode,
        (col, row) => ({ col, row, deck_item_id: this.plate.id }),
        (col, row) => !this.wellAt(col, row).available,
      );
    }

    return this.#unavailable;
  }
}
