import React, { useCallback, useMemo, useState } from 'react';

import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import AccessibleDeviceSetupTabDeviceSpecificProps, {
  KNOWN_DEVICE_SPECIFIC_PROPS,
} from 'client/app/components/DeviceLibrary/AccessibleDeviceSetupTabDeviceSpecificProps';
import AccessibleDeviceSetupTabOrientation from 'client/app/components/DeviceLibrary/AccessibleDeviceSetupTabOrientation';
import { AccessibleDeviceSetup } from 'common/types/device';
import Colors from 'common/ui/Colors';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  /**
   * Data displayed in the dialog.
   * This dialog is a controlled component. The state is managed in the parent component.
   */
  accessibleDeviceSetup: AccessibleDeviceSetup;
  /**
   * If true, all parts of this UI are disabled and read-only.
   */
  isDisabled?: boolean;
  /**
   * Fired when the user edits any part of the `AccessibleDeviceSetup`.
   */
  onAccessibleDeviceSetupChange: (accessibleDeviceSetup: AccessibleDeviceSetup) => void;
};

enum DialogTab {
  LOCATION,
  ORIENTATION,
  ADDITIONAL_DETAILS,
}

export function AccessibleDeviceSetupDialogContent({
  accessibleDeviceSetup,
  isDisabled,
  onAccessibleDeviceSetupChange,
}: Props) {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState<DialogTab>(DialogTab.LOCATION);

  const handleUpdateAddress = useTextFieldChange((address: string) => {
    onAccessibleDeviceSetupChange({ ...accessibleDeviceSetup, address });
  });

  const tabsInfo: TabsInfo<DialogTab> = useMemo(() => {
    return [
      { label: 'Location', value: DialogTab.LOCATION },
      { label: 'Orientation', value: DialogTab.ORIENTATION },
      { label: 'Additional details', value: DialogTab.ADDITIONAL_DETAILS },
    ];
  }, []);

  const handleUpdateOrientation = useCallback(
    (orientation: AccessibleDeviceSetup['orientation']) =>
      onAccessibleDeviceSetupChange({
        ...accessibleDeviceSetup,
        orientation,
      }),
    [accessibleDeviceSetup, onAccessibleDeviceSetupChange],
  );

  const handleUpdateAllowRotatePlate = useCallback(
    (allowRotated: boolean) =>
      onAccessibleDeviceSetupChange({
        ...accessibleDeviceSetup,
        allowRotated,
      }),
    [accessibleDeviceSetup, onAccessibleDeviceSetupChange],
  );

  const handleUpdateDeviceSpecificProps = useCallback(
    (deviceSpecific: AccessibleDeviceSetup['deviceSpecific']) =>
      onAccessibleDeviceSetupChange({
        ...accessibleDeviceSetup,
        deviceSpecific,
      }),
    [accessibleDeviceSetup, onAccessibleDeviceSetupChange],
  );

  const tabContent = useMemo(() => {
    switch (selectedTab) {
      case DialogTab.LOCATION:
        return (
          <div className={classes.locationTab}>
            <InputLabel shrink>Location on deck</InputLabel>
            <TextField
              variant="standard"
              fullWidth
              value={accessibleDeviceSetup.address}
              disabled={isDisabled}
              onChange={handleUpdateAddress}
            />
          </div>
        );
      case DialogTab.ORIENTATION:
        return (
          <AccessibleDeviceSetupTabOrientation
            orientation={accessibleDeviceSetup.orientation}
            allowRotatePlate={accessibleDeviceSetup.allowRotated}
            disabled={isDisabled}
            onOrientationChange={handleUpdateOrientation}
            onAllowRotatePlateChange={handleUpdateAllowRotatePlate}
          />
        );
      case DialogTab.ADDITIONAL_DETAILS:
        return (
          <AccessibleDeviceSetupTabDeviceSpecificProps
            deviceSpecificProps={accessibleDeviceSetup.deviceSpecific}
            disabled={isDisabled}
            onDeviceSpecificPropsChange={handleUpdateDeviceSpecificProps}
          />
        );
      default:
        return null;
    }
  }, [
    accessibleDeviceSetup,
    isDisabled,
    selectedTab,
    classes.locationTab,
    handleUpdateAddress,
    handleUpdateAllowRotatePlate,
    handleUpdateDeviceSpecificProps,
    handleUpdateOrientation,
  ]);

  return (
    <div className={classes.content}>
      <SummaryColumn accessibleDeviceSetup={accessibleDeviceSetup} />
      <div className={classes.tabbedEditor}>
        <Tabs
          activeTab={selectedTab}
          onChangeTab={setSelectedTab}
          tabsInfo={tabsInfo}
          centered
        />
        <div className={classes.tabContent}>{tabContent}</div>
      </div>
    </div>
  );
}

function SummaryColumn({
  accessibleDeviceSetup,
}: {
  accessibleDeviceSetup: AccessibleDeviceSetup;
}) {
  const classes = useStyles();
  const { retractDistance, liftUpHeight } = accessibleDeviceSetup.deviceSpecific;
  return (
    <div className={classes.summaryColumn}>
      <Typography variant="subtitle1">Location</Typography>
      <Typography className={classes.summaryColumnValue}>
        {accessibleDeviceSetup.address}
      </Typography>
      <Typography variant="subtitle1">Orientation</Typography>
      <Typography className={cx(classes.summaryColumnValue, classes.capitalize)}>
        {accessibleDeviceSetup.orientation}
      </Typography>
      {(retractDistance?.trim() || liftUpHeight?.trim()) && (
        <>
          <Typography className={classes.summaryColumnBoldLabel}>
            Additional details
          </Typography>
          <DeviceSpecificPropSummary
            propLabel={KNOWN_DEVICE_SPECIFIC_PROPS.retractDistance}
            propValue={retractDistance}
          />
          <DeviceSpecificPropSummary
            propLabel={KNOWN_DEVICE_SPECIFIC_PROPS.liftUpHeight}
            propValue={liftUpHeight}
          />
        </>
      )}
    </div>
  );
}

function DeviceSpecificPropSummary({
  propLabel,
  propValue,
}: {
  propLabel: string;
  propValue: string;
}) {
  const classes = useStyles();
  if (!propValue?.trim()) {
    return null;
  }
  return (
    <Typography className={classes.summaryColumnDeviceSpecificProp}>
      {`${propLabel}: ${propValue}`}
    </Typography>
  );
}

const useStyles = makeStylesHook(theme => ({
  content: {
    display: 'flex',
  },
  summaryColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '260px',
  },
  summaryColumnBoldLabel: {
    fontWeight: 'bold',
  },
  summaryColumnValue: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(6),
  },
  summaryColumnDeviceSpecificProp: {
    background: Colors.BLUE_5,
    padding: theme.spacing(4),
    marginTop: theme.spacing(3),
  },
  tabbedEditor: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginLeft: theme.spacing(6),
  },
  tabContent: {
    display: 'flex',
    flex: 1,
    marginTop: theme.spacing(6),
    border: `1px solid ${Colors.GREY_30}`,
    minHeight: '360px',
    padding: theme.spacing(6),
  },
  locationTab: {
    width: '300px',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
}));
