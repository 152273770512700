import React from 'react';

import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/**
 * A Menu Item component that currently stylizes how we want the icon and text to be spaced.
 * If there are more customization that we want to do, we can easily change only this one component
 * for the menu item with an icon.
 *
 * Documentation: https://material-ui.com/api/menu-item/
 *
 */
type Props = {
  icon: JSX.Element;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  selected?: boolean;
  tooltipTitle?: string;
  tooltipPlacement?: 'left' | 'right'; // Tooltips are usually best placed adjacent to a MenuItem
};
const MenuItemWithIcon = React.forwardRef<HTMLLIElement, Props>(function MenuItemWithIcon(
  props: Props,
  ref,
) {
  const classes = useStyles();
  const {
    icon,
    text,
    onClick,
    disabled,
    selected,
    tooltipTitle,
    tooltipPlacement = 'right',
  } = props;

  return (
    <Tooltip title={tooltipTitle || ''} placement={tooltipPlacement}>
      {/** Wrapped in <span> to allow Tooltip to still show if in disabled state */}
      <span>
        <MenuItem
          className={classes.menuItem}
          onClick={onClick}
          ref={ref}
          disabled={disabled}
          selected={selected}
        >
          <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon>
          <Typography variant="subtitle2">{text}</Typography>
        </MenuItem>
      </span>
    </Tooltip>
  );
});

const useStyles = makeStylesHook({
  listIcon: {
    color: 'inherit',
    // Need to reset the minWidth since ListItemIcon has a default minWidth.
    minWidth: 'auto',
  },
  menuItem: {
    '&.Mui-selected': {
      backgroundColor: Colors.GREY_30,
      '&:hover': {
        backgroundColor: Colors.GREY_30,
      },
    },
  },
});

export default MenuItemWithIcon;
