import React from 'react';

import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { DataVisualisation } from 'client/app/gql';
import { visualizationRoutes } from 'client/app/lib/nav/actions';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';

export const ANALYSE_CHROMATOGRAPHY_VISUALISATION_NAME = 'Chromatography results';

type Props = {
  simulationId: SimulationId;
  visualisation: Pick<DataVisualisation, 'id' | 'path' | 'name'>;
};

export default function VisualiseResultsButton({ simulationId, visualisation }: Props) {
  const navigation = useNavigation();

  const handleNavigate = () => {
    navigation.navigate(visualizationRoutes.base, {
      id: visualisation.id,
      visserverApp: visualisation.path,
      simulationID: simulationId,
    });
  };

  let btnCopy: string;

  if (visualisation.name === ANALYSE_CHROMATOGRAPHY_VISUALISATION_NAME) {
    btnCopy = 'Analyse Chromatography Result';
  } else {
    const name = visualisation.name.trim();
    btnCopy = name === '' ? 'Visualize Results' : name;
  }

  return (
    <Fab color="secondary" size="medium" variant="extended" onClick={handleNavigate}>
      <>
        <StyledDataSetIcon />
        <Typography variant="button">{btnCopy}</Typography>
      </>
    </Fab>
  );
}

const StyledDataSetIcon = styled(InsertChartOutlinedIcon)(({ theme: { spacing } }) => ({
  marginRight: spacing(3),
}));
