import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const SimulationIcon = React.memo<SvgIconProps>(function SimulationIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.71 8.086a2.75 2.75 0 012.75-2.75h5.6v1.5h-5.6c-.69 0-1.25.56-1.25 1.25v4c0 .69.56 1.25 1.25 1.25h6v1.5h-6a2.75 2.75 0 01-2.75-2.75v-4z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M15.704 4.586a2 2 0 11-2.83 2.828 2 2 0 012.83-2.828zM15.704 12.586a2 2 0 11-2.829 2.828 2 2 0 012.829-2.828z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M19.29 12.086a2.75 2.75 0 00-2.75-2.75h-5.6v1.5h5.6c.69 0 1.25.56 1.25 1.25v4c0 .69-.56 1.25-1.25 1.25h-6v1.5h6a2.75 2.75 0 002.75-2.75v-4z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M8.297 8.586a2 2 0 102.828 2.828 2 2 0 00-2.828-2.828zM8.297 16.586a2 2 0 102.828 2.828 2 2 0 00-2.828-2.828z"
        />
      </svg>
    </SvgIcon>
  );
});
