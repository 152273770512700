import { useMemo } from 'react';

import some from 'lodash/some';

import { ServerSideBundle } from 'common/types/bundle';

export const ROBOCOLUMNS_ELEMENT = 'Define_RoboColumn_Plate';

export default function useIsRobocolumnsSimulation(
  workflowBundle?: ServerSideBundle,
): boolean {
  return useMemo(
    () =>
      some(
        workflowBundle?.Elements.Instances,
        instance => instance.TypeName === ROBOCOLUMNS_ELEMENT,
      ),
    [workflowBundle],
  );
}
