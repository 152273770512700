import React, { ReactElement } from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { StageDetails } from 'common/types/mixPreview';
import Colors from 'common/ui/Colors';

type Props = {
  details?: StageDetails;
  showStageName: boolean;
  children: ReactElement;
};

export default function StageDetailsTooltip({ details, showStageName, children }: Props) {
  /**
   * Show no tooltip if there are no details
   */
  if (!details) return children;

  const stageNameContent = showStageName ? (
    <Stack gap={2}>
      <Typography variant="body1" color="textPrimary">
        Stage
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {details.name}
      </Typography>
    </Stack>
  ) : null;

  const showManualCopy = details.device.class === 'Manual';

  const deviceContent = (
    <Stack gap={3}>
      <Typography variant="body1" color="textPrimary">
        Execution Mode
      </Typography>

      <Stack direction="row" gap={3}>
        <DeviceImg
          src={details.device.imageUrl ?? '/app/cdn/assets/devices/Void.png'}
          alt={`${details.device.label} picture`}
        />
        <Stack gap={2} overflow="hidden">
          <Typography
            variant="body2"
            color={showManualCopy ? 'textSecondary' : 'textPrimary'}
          >
            {showManualCopy
              ? 'This stage was configured for manual execution.'
              : details.device.label}
          </Typography>
          {!showManualCopy && (
            <Typography variant="body2" color="textSecondary">
              {details.device.name}
            </Typography>
          )}
          {details.device.runConfig && (
            <Typography variant="caption" fontStyle="italic">
              {details.device.runConfig}
            </Typography>
          )}
        </Stack>
      </Stack>

      {details.device.accessibleDevices.length > 0 && (
        <Typography variant="caption" textTransform="uppercase">
          Accessible devices
        </Typography>
      )}
      {details.device.accessibleDevices.map(accessibleDevice => (
        <Stack key={accessibleDevice.id} direction="row" gap={3}>
          <DeviceImg
            src={accessibleDevice.imageUrl ?? '/app/cdn/assets/devices/Void.png'}
            alt={`${accessibleDevice.label} picture`}
          />
          <Stack gap={2}>
            <Typography variant="body2" color="textPrimary">
              {accessibleDevice.label}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {accessibleDevice.name}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );

  return (
    <Tooltip
      title={
        <Stack gap={5}>
          {stageNameContent}
          {deviceContent}
        </Stack>
      }
      placement="top-start"
      PopperProps={{
        sx: TooltipPopperStyle,
      }}
      sx={TooltipStyle}
    >
      {children}
    </Tooltip>
  );
}

const TooltipStyle = { px: 3, py: 1 };

const TooltipPopperStyle = {
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 5,
    backgroundColor: Colors.GREY_0,
    color: Colors.TEXT_SECONDARY,
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  },
};

const DeviceImg = styled('img')(() => ({
  width: 35,
  height: 35,
}));
