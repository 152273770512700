import React, { useCallback, useMemo } from 'react';

import MUIAutocomplete from '@mui/material/Autocomplete';
import cx from 'classnames';

import { indexBy } from 'common/lib/data';
import { Option } from 'common/types/Option';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import Keys from 'common/ui/lib/keyboard';

export type { Option } from 'common/types/Option';

type Props<T> = {
  valueLabel?: string;
  options: Option<T>[];
  onChange: (value?: T) => void;
  onBlur?: () => void;
  placeholderValue?: T;
  /**
   * Allow the user to provide values outside of the options list.
   * This can only be used with string options and the label and value of
   * the custom option will be equal to what the user enters.
   * */
  acceptCustomValues?: boolean;
  disableClearable?: boolean;
  label?: string;
  fullWidth?: boolean;
  disableUnderline?: boolean;
  className?: string;
} & Omit<ParameterEditorBaseProps<T>, 'value'>;

export default function Autocomplete<T>(props: Props<T>) {
  const classes = useStyles();
  const labelOptionMap = useMemo(() => indexBy(props.options, 'label'), [props.options]);
  const optionLabels = useMemo(() => Object.keys(labelOptionMap), [labelOptionMap]);
  const { acceptCustomValues } = props;

  if (
    acceptCustomValues &&
    props.options[0] &&
    typeof props.options[0]?.value !== 'string'
  ) {
    throw new Error(
      'Autocomplete error: You cannot set canAcceptCustomValues to true and provide non-string options simultaneously. Either use string values or disable canAcceptCustomValues.',
    );
  }

  const onChange = useCallback(
    (_event: React.ChangeEvent<{}>, newValueLabel: string | null) => {
      // Given this is being passed to onInputChange (see comments below), it's quite
      // likely that half typed values will be passed into this function. If the user
      // shouldn't be able to input custom values, this means we need to filter out
      // invalid values before they're passed to props.onChange.
      if (!acceptCustomValues && newValueLabel && !labelOptionMap[newValueLabel]) {
        return;
      }

      if (!newValueLabel) {
        props.onChange(props.placeholderValue);
        return;
      }

      // If we're dealing with a case where we want to accept custom values, the custom
      // values should be strings, so we'll accept what the user has entered as the new
      // value.
      if (newValueLabel && acceptCustomValues && !labelOptionMap[newValueLabel]) {
        props.onChange(newValueLabel as unknown as T);
        return;
      }

      const option = labelOptionMap[newValueLabel];
      props.onChange(option.value);
    },
    [acceptCustomValues, labelOptionMap, props],
  );

  const handlePressEnter = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== Keys.ENTER) {
      return;
    }

    e.currentTarget.blur();
  }, []);

  return (
    <MUIAutocomplete
      value={props.valueLabel ?? ''}
      freeSolo={props.acceptCustomValues}
      options={optionLabels}
      autoSelect={false}
      // onChange can't be used without running into one of two issues, which are:
      // 1) When autoSelect is true: If the user hovers an option without selecting
      // and blurs, that hovered option will be selected anyway.
      // 2) When autoSelect is false: When the user types in a value when freeSolo is
      // enabled, blurring doesn't trigger onChange - the user needs to press Enter.
      // The most practical workaround is to use onInputValue which changes with
      // every keystroke.
      // Related GitHub issue: https://github.com/mui-org/material-ui/issues/20602
      onInputChange={onChange}
      onBlur={props.onBlur}
      disabled={props.isDisabled}
      disableClearable={props.disableClearable}
      className={cx(props.className, { [classes.fullWidth]: props.fullWidth })}
      renderInput={params => (
        <TextField
          {...params}
          error={props.hasError}
          placeholder={props.placeholder}
          label={props.label}
          required={props.isRequired}
          inputProps={{
            ...params.inputProps,
            // Chrome does not support autoComplete="off". Setting autocomplete
            // value to any other string will prevent Chrome from autofilling
            // inputs with personal information the browser saves. (T4858)
            autoComplete: 'new-password',
            onKeyDown: handlePressEnter,
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: props.disableUnderline,
          }}
        />
      )}
    />
  );
}

const useStyles = makeStylesHook({
  fullWidth: {
    width: '100%',
  },
});
