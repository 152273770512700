import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import { getOutputVisualisationTypeFromParameterType } from 'client/app/components/ElementPlumber/ElementOutputs/helpers';
import { InstanceParameter } from 'client/app/components/Parameters/ElementParameterGroupList';
import { useFilterPlateContentParameters } from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import { ParameterStateContext } from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { ElementInstance, Parameter, ParameterValue } from 'common/types/bundle';
import { getElementId, getElementParameterName, Schema } from 'common/types/schema';
import Colors from 'common/ui/Colors';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type Props = {
  workflowSchema: Schema;
  updateInstance: () => void;
};

export const InputStep = ({ workflowSchema, updateInstance }: Props) => {
  const dispatch = useWorkflowBuilderDispatch();
  const { selectedStep } = useStepsContext();

  // TODO: I will address the need for ! in subsequent PR
  // For now it is enough for the parent to check https://github.com/Synthace/antha-platform/blob/master/antha-com/client/app/apps/protocols/EditProtocolInstance.tsx#L133
  const inputs = useMemo(
    () =>
      workflowSchema.inputs!.filter(input =>
        selectedStep.inputs.map(input => input.id).includes(input.id),
      ),
    [selectedStep.inputs, workflowSchema],
  );

  const outputs = useMemo(
    () =>
      workflowSchema.outputs!.filter(output =>
        selectedStep.outputs.map(output => output.id).includes(output.id),
      ),
    [selectedStep.outputs, workflowSchema],
  );

  const {
    elementInstances,
    parameters,
    config: workflowConfig,
    outputPreviewProps,
  } = useWorkflowBuilderSelector(state => state);

  const handleSetOutputPreview = useCallback(() => {
    if (outputs.length === 0) {
      return;
    }
    const firstOutput = outputs[0];
    const elementInstanceId = getElementId(firstOutput.path);
    const parameterName = getElementParameterName(firstOutput.path);

    dispatch({
      type: 'openOutputPreview',
      payload: {
        selectedElementId: elementInstanceId,
        selectedOutputParameterName: parameterName,
        outputType: getOutputVisualisationTypeFromParameterType(firstOutput.typeName),
        entityView: outputPreviewProps.entityView ?? 'plate', // TODO: consider encoding some state in URL-params
      },
    });
  }, [dispatch, outputPreviewProps.entityView, outputs]);

  useEffect(() => {
    if (outputs.length > 0) {
      handleSetOutputPreview();
    }
  }, [handleSetOutputPreview, outputs.length]);

  // This value is a map of each protocol input id to the parent element
  // that the input refers to.
  // This is used to pass down to render ParameterEditor component.
  const protocolElementsByInputId = useMemo(() => {
    const ret = new Map<string, ElementInstance>();
    inputs.forEach(input => {
      const elementInstanceId = getElementId(input.path);
      const element = elementInstances.find(ei => ei.Id === elementInstanceId);
      if (element) {
        ret.set(input.id, element);
      }
    });
    return ret;
  }, [elementInstances, inputs]);

  // This value is a map of each protocol input id to a Parameter
  // type. The Parameter is constructed using information from the
  // workflowSchema, such as the display description.
  // This is used to pass down to render to ParameterEditor component.
  const protocolParametersByInputId = useMemo(() => {
    const ret = new Map<string, Parameter>();
    inputs.forEach(input => {
      const parameterName = getElementParameterName(input.path);
      const stepInput = selectedStep.inputs.find(stepInput => stepInput.id === input.id);
      if (parameterName && stepInput) {
        const param: Parameter = {
          name: parameterName,
          description: stepInput.displayDescription,
          type: input.typeName,
          configuration: {
            editor: stepInput.configuration,
            isVisible: true,
            displayDescription: stepInput.displayDescription, // TODO - Is this the right display description?
            displayName: stepInput.displayName,
          },
        };
        ret.set(input.id, param);
      }
    });
    return ret;
  }, [inputs, selectedStep.inputs]);

  // We filter out some parameters that are managed by the PlateContentsEditor component.
  const { plateContentParams, plateParameterFilter } = useFilterPlateContentParameters([
    ...protocolParametersByInputId.values(),
  ]);

  const { getStateForParameter } = useContext(ParameterStateContext);

  const handleParameterChange = useCallback(
    (paramName: string, value: ParameterValue, instanceName?: string) => {
      dispatch({
        type: 'updateParameter',
        payload: {
          instanceName: instanceName ?? '',
          parameterName: paramName,
          value: value,
        },
      });

      updateInstance();
    },
    [dispatch, updateInstance],
  );

  return (
    <Wrapper>
      <InputStepName variant="h4">{selectedStep.displayName}</InputStepName>
      <ContainerWithIntersectionBar
        dense
        noHeader
        content={
          <InputContentWrapper>
            {selectedStep.inputs
              .map(input => input.id)
              .filter(inputId =>
                plateParameterFilter(protocolParametersByInputId.get(inputId)),
              )
              .map(inputId => {
                const element = protocolElementsByInputId.get(inputId);
                const parameter = protocolParametersByInputId.get(inputId);
                return element && parameter ? (
                  <InstanceParameter
                    key={inputId}
                    instanceName={element.name}
                    elementId={element.Id}
                    paramState={getStateForParameter(element.name, parameter.name)}
                    showValidation
                    onChange={handleParameterChange}
                    parameter={parameter}
                    workflowConfig={workflowConfig}
                    defaultParameters={{}}
                    parameterValueDict={parameters[element.name]}
                    paramValue={parameters[element.name][parameter.name]}
                    plateContentParams={plateContentParams}
                  />
                ) : null;
              })}
          </InputContentWrapper>
        }
      />
    </Wrapper>
  );
};

export function InputStepSkeleton() {
  const { selectedStep } = useStepsContext();
  return (
    <Wrapper>
      <InputStepName variant="h4">{selectedStep.displayName}</InputStepName>
      <InputContentWrapper>
        <Skeleton sx={{ mb: 3 }} variant="rounded" width="100%" height={20} />
        <Skeleton sx={{ mb: 5 }} variant="rounded" width="100%" height={50} />
        <Skeleton sx={{ mb: 3 }} variant="rounded" width="100%" height={20} />
        <Skeleton variant="rounded" width="100%" height={50} />
      </InputContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '376px',
  maxWidth: '376px',
  padding: theme.spacing(5),
  gap: theme.spacing(5),
  borderRadius: theme.spacing(3, 0, 0, 3),
  border: `1px solid ${Colors.GREY_30}`,
  backgroundColor: 'white',
}));

const InputStepName = styled(TypographyWithTooltip)(() => ({
  fontWeight: 600,
}));

const InputContentWrapper = styled('div')({
  // InstanceParameter component renders some map buttons in an absolute position
  // with a negative left value, which we need to account for here to ensure they
  // are displayed correctly.
  marginLeft: '8px',
});
