import React from 'react';

import { Route, withRouter } from 'react-router';

import CherryPickContainer from 'client/app/apps/cherry-picker/CherryPickLayout';

const ROUTE_START = '/cherry-picker';
const ROUTE_EDIT = `${ROUTE_START}/:id`;

function CherryPickerApp() {
  return (
    <>
      <Route path={ROUTE_START} exact render={() => <CherryPickContainer />} />
      <Route
        path={ROUTE_EDIT}
        exact
        render={route => <CherryPickContainer id={route.match.params.id as WorkflowId} />}
      />
    </>
  );
}

export default withRouter(CherryPickerApp);
