import React, { useCallback } from 'react';

import LinkOffIcon from '@mui/icons-material/LinkOff';
import makeStyles from '@mui/styles/makeStyles';

import { useDownloadRemoteFileFromPath } from 'client/app/api/filetree';
import { FileObject } from 'common/types/fileParameter';
import { FILE_UPLOAD_ERROR_SUFFIX } from 'common/types/filetree';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';

type Props = {
  value: FileObject;
};

const useStyles = makeStyles({
  brokenLink: {
    alignItems: 'center',
    color: Colors.RED,
    display: 'flex',
    wordBreak: 'break-word',
  },
  fileLink: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  filenameText: {
    wordBreak: 'break-word',
    textAlign: 'left',
    textTransform: 'none',
  },
  linkIcon: {
    margin: '8px',
  },
});

const SingleFileDisplay = function (props: Props) {
  const classes = useStyles();
  const {
    value: { Name: name, Path: filepath },
  } = props;

  const downloadRemoteFileFromPath = useDownloadRemoteFileFromPath();
  const onClick = useCallback(
    async () => downloadRemoteFileFromPath(filepath, name),
    [downloadRemoteFileFromPath, filepath, name],
  );

  let displayValue = null;
  if (name.endsWith(FILE_UPLOAD_ERROR_SUFFIX)) {
    displayValue = (
      <div className={classes.brokenLink}>
        <LinkOffIcon className={classes.linkIcon} />
        {name}
      </div>
    );
  } else {
    displayValue = (
      <Button
        component="a"
        variant="tertiary"
        title="Click to download"
        className={classes.filenameText}
        onClick={onClick}
      >
        {name}
      </Button>
    );
  }

  return <div className={classes.fileLink}>{displayValue}</div>;
};

export default SingleFileDisplay;
