import React from 'react';

import Typography from '@mui/material/Typography';

import Banner404 from 'common/assets/Banner404';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export default function Screen404() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h5" gutterBottom>
        We&apos;re sorry. We couldn&apos;t find the page you were looking for.
      </Typography>
      <Typography variant="caption">
        The page might have been removed or the link has changed.
      </Typography>
      <Banner404 />
    </div>
  );
}

const useStyles = makeStylesHook({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
