import { Execution } from 'client/app/apps/execution-details/types';
import { SimulationOrExecutionStatusesEnum } from 'client/app/gql';
import useIsDOEAllowedForSimulation from 'client/app/hooks/useIsDOEAllowedForSimulation';
import useManualOrDispenser from 'client/app/hooks/useManualOrDispenser';

export default function useShowStructureDataButton(simulation: Execution['simulation']) {
  /**
   * TODO: we should not use simulation object here as it incorporates a lot of
   * unnecessary payload being transfered through the network.
   * Instead we should figure out what parts of the simulation are used and for what
   * purposes and create dedicated GrapqhQL fields serving those purposes
   */
  const DOESimulationType = useIsDOEAllowedForSimulation(
    simulation.workflow.workflow,
    simulation.source,
  );
  const { isDispenserOrManual } = useManualOrDispenser(simulation.workflow.workflow);
  return (
    DOESimulationType === 'new' &&
    (isDispenserOrManual
      ? simulation.transitiveStatus ===
        SimulationOrExecutionStatusesEnum.EXECUTION_SUCCESS
      : true)
  );
}
