import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export const ReleaseQualityPrototypeIcon = React.memo(
  function ReleaseQualityPrototypeIcon(props) {
    return (
      <SvgIcon viewBox="0 0 24 24" {...props}>
        <svg width="24" height="24" fill="none">
          <path
            d="M9.17 6l2 2H20v10H4V6h5.17zM10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"
            fill="currentColor"
          />
          <path
            d="M11.195 14.07V16h-1.37v-5.688h2.269c.435 0 .819.081 1.152.243.336.159.595.386.777.683.185.295.278.63.278 1.004 0 .555-.2.999-.598 1.332-.396.33-.94.496-1.633.496h-.875zm0-1.058h.899c.265 0 .467-.067.605-.2.14-.132.211-.32.211-.562 0-.266-.072-.478-.215-.637-.143-.159-.338-.24-.586-.242h-.914v1.64z"
            fill="currentColor"
          />
        </svg>
      </SvgIcon>
    );
  },
);
