import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { getResultOrThrow } from 'client/app/api/apolloClient';
import { useCopyLink } from 'client/app/api/filetree';
import {
  MUTATION_CREATE_UPLOAD,
  MUTATION_PROCESS_UPLOAD_SINGLE_CREATE_LABWARE_HISTORY,
} from 'client/app/api/gql/mutations';
import { concatURL } from 'common/lib/strings';
import { parseFiletreeLink, sanitizeFilenameForFiletree } from 'common/types/filetree';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export type FileUploadData = {
  labwareId: LabwareId;
  link: FiletreeLink;
  filename: string;
  deviceId?: DeviceId;
  parser?: string;
};

export default function useUploadLabwareFile(executionId: ExecutionId) {
  const { showError } = useSnackbarManager();

  // Mutations for performing data processing
  const [createUpload] = useMutation(MUTATION_CREATE_UPLOAD);
  const [processUpload] = useMutation(
    MUTATION_PROCESS_UPLOAD_SINGLE_CREATE_LABWARE_HISTORY,
  );
  const copyLink = useCopyLink();

  // Do processing takes the incoming file and either a device or a parser, creates a DataUpload,
  // requests processing for that file, and starts polling for the results.
  return useCallback(
    async ({ labwareId, link, filename, deviceId, parser }: FileUploadData) => {
      try {
        const uploadResult = await createUpload({
          variables: {
            executionId,
            deviceId,
            parser,
          },
        });
        // TODO: The file that was provided may have already been parsed (if it came from a device),
        // but in the current scheme we needlessly parse it again.  As an optimization, we could look
        // for existing DataFiles or ParsedDataFiles that are for this file and just create new
        // LabwareDatasets for them.
        const dataUpload = getResultOrThrow(
          uploadResult,
          'Create upload',
          data => data.createUpload,
        );
        const copyResult = await copyLink(
          link,
          concatURL(
            parseFiletreeLink(dataUpload.directoryFiletreeLink).path,
            sanitizeFilenameForFiletree(filename),
          ) as FiletreePath,
          'Manual upload',
        );
        await processUpload({
          variables: {
            executionId,
            labwareId,
            filetreeLink: copyResult.ftl,
            uploadId: dataUpload.id,
          },
        });
        return dataUpload.id;
      } catch (error) {
        showError(error.message);
        throw error;
      }
    },
    [copyLink, createUpload, executionId, processUpload, showError],
  );
}
