import { EditorType, WorkflowEditMode } from 'common/types/bundle';

/**
 * Is it RO in the Builder?
 * @param editMode
 * @param source
 */
export default function isWorkflowReadonly(
  editMode: WorkflowEditMode,
  source: EditorType,
) {
  return (
    !(editMode === WorkflowEditMode.ENABLED_LATEST_OWNED_BY_ME || editMode === WorkflowEditMode.ENABLED_LATEST_EXAMPLE_GALLERY) ||
    EditorType.FORM_EDITOR === source
  );
}
