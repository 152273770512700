import React, { Fragment, ReactNode, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { useFeatureToggle } from 'common/features/useFeatureToggle';
import {
  formatMeasurementObj,
  formatPipettingHeight,
  formatVolumeWithStdDev,
  formatWellPosition,
} from 'common/lib/format';
import { byName } from 'common/lib/strings';
import { Factors } from 'common/types/bundle';
import {
  BlowoutInfo,
  DataTag,
  DOEDesignRun,
  Measurement,
  MixInfo,
  PipettingOptions,
  Rule,
  WellContents,
  WellLocationOnDeckItem,
} from 'common/types/mix';
import Colors from 'common/ui/Colors';
import { SMART_WORD_BREAK_STYLE } from 'common/ui/commonStyles';
import { formatTagValue } from 'common/ui/components/simulation-details/mix/helpers';
import {
  DeckItemState,
  MoveLiquidEdge,
} from 'common/ui/components/simulation-details/mix/MixState';
import {
  formatConsequencePropertyName,
  formatConsequenceValue,
  getRulesWithOverrides,
  RuleWithOverrides,
} from 'common/ui/components/simulation-details/mix/rules';
import Switch from 'common/ui/components/Switch';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

// Info about an edge (i.e. a liquid transfer), plus the contents of the well
// we transfer from.
// It is useful for users to see the source well contents at a glance.
export type ExtendedEdgeInfo = {
  edge: MoveLiquidEdge;
  sourceLocation: WellLocationOnDeckItem;
  targetLocation: WellLocationOnDeckItem;
  sourceWellContents: WellContents | null;
  hover: boolean;
};

// Info about a well to display in the UI
export type WellInfo = {
  loc: WellLocationOnDeckItem;
  // Null if the well has no liquid in it
  contents: WellContents | null;
};

export type DeckItemInfo = {
  deckPositionName: string;
  deckItems: DeckItemState[];
};

type Props = {
  edgeInfo: ExtendedEdgeInfo | null;
  wellInfo: WellInfo | null;
  deckItemInfo?: DeckItemInfo;
  /**
   * Rule information is needed for transfers
   */
  rules?: { [id: string]: Rule };
  showWellProvenanceToggle?: boolean;
  showWellProvenance?: boolean;
  onShowWellProvenanceChange?: (filterByWells: boolean) => void;
  factors?: Factors;
};

export default React.memo(function RightPanel({
  edgeInfo,
  wellInfo,
  deckItemInfo,
  rules,
  showWellProvenanceToggle,
  showWellProvenance,
  onShowWellProvenanceChange,
  factors,
}: Props) {
  const classes = useStyles();
  return (
    <div className={classes.panel} onPointerUp={stopPropagation}>
      {deckItemInfo && <DeckItemPanelSection deckItemInfo={deckItemInfo} />}
      {edgeInfo && <EdgePanelSection edgeInfo={edgeInfo} rules={rules} />}
      {wellInfo && (
        <WellPanelSection
          wellInfo={wellInfo}
          showWellProvenanceToggle={showWellProvenanceToggle}
          showWellProvenance={showWellProvenance}
          onShowWellProvenanceChange={onShowWellProvenanceChange}
          factors={factors}
        />
      )}
    </div>
  );
});

type DeckItemPanelSectionProps = {
  deckItemInfo: DeckItemInfo;
};

const DECK_ITEM_TYPE_LABELS: Record<DeckItemState['kind'], string> = {
  cap: 'Cap',
  lid: 'Lid',
  plate: 'Plate',
  tipbox: 'Tip box',
  tipwaste: 'Tip waste',
};

function DeckItemPanelSection({ deckItemInfo }: DeckItemPanelSectionProps) {
  const { deckPositionName, deckItems } = deckItemInfo;

  if (deckItems.length === 0) {
    return (
      <Section title="Empty deck position">
        <Definition label="Deck position" value={deckPositionName} />
      </Section>
    );
  }

  return (
    <>
      {deckItems.map(deckItem => (
        <Section key={deckItem.name} title={DECK_ITEM_TYPE_LABELS[deckItem.kind]}>
          <Definition label="Deck position" value={deckPositionName} />
          <Definition label="Name" value={deckItem.name} />
          {deckItem.manufacturer && (
            <Definition label="Manufacturer" value={deckItem.manufacturer} />
          )}
          {deckItem.type && <Definition label="Type" value={deckItem.type} />}
          {deckItem.description && (
            <Definition label="Description" value={deckItem.description} />
          )}
        </Section>
      ))}
    </>
  );
}

type EdgeProps = {
  edgeInfo: ExtendedEdgeInfo;
  rules?: { [id: string]: Rule };
};
/**
 * Displays info about an edge
 */
function EdgePanelSection({ rules, edgeInfo }: EdgeProps) {
  const classes = useStyles();
  const { edge, sourceLocation, sourceWellContents } = edgeInfo;
  const { action } = edge;

  const isLiquidTransfer = edge.type === 'liquid_transfer';
  const isLiquidDispense = edge.type === 'liquid_dispense';
  const isStamping = edge.type === 'stamping';
  const isTipAction = edge.type !== 'filtration';
  const isMultiDispense = isTipAction && action.multiDispenseCount > 1;

  const showFromSection = isLiquidTransfer && !edge.stamping;
  // Do not show information about aspirate if this was just a dispense
  const showAspiration = isLiquidTransfer || isStamping;

  const transferRules = useMemo(
    () => getRulesWithOverrides(action.rule_ids ?? [], rules ?? {}),
    [action.rule_ids, rules],
  );

  // If not specified, remaining volume is 0
  const volumeInTip = useMemo(
    () =>
      formatVolumeWithStdDev(
        action.liquidDestination.volume_in_tip || { value: 0, unit: 'ul' },
      ),
    [action],
  );

  return (
    <Section title={getTransferSectionTitle(edgeInfo)}>
      <Definition label="Policy" value={action.policy} />

      {showFromSection && (
        <Subsection
          // Prefix with 'From' to indicate this info pertains to the source well, not the
          // target well.
          title={`From ${formatWellPosition(sourceLocation)}`}
        >
          <WellInfoSection
            wellInfo={{ loc: sourceLocation, contents: sourceWellContents }}
          />
        </Subsection>
      )}

      {isMultiDispense && (
        <Subsection title="Part of multidispense">
          <div>
            Dispense {action.multiDispenseIndex + 1} of {action.multiDispenseCount}
          </div>
          <div>{volumeInTip} remaining in tip</div>
        </Subsection>
      )}

      {isStamping && (
        <>
          <Definition label="Source" value={edge.source.name} />
          <Definition label="Destination" value={edge.destination.name} />
          <Definition
            label="Liquid dispense volume per tip"
            value={formatMeasurementObj(action.volume)}
          />
          <Definition label="Number of tips used" value={edge.channelCount} />
          <Divider sx={{ mx: -4 }} />
        </>
      )}
      {(isLiquidTransfer || isLiquidDispense) && edge.stamping && (
        <>
          <Definition label="Source" value={edge.stamping.sourceName} />
          <Definition label="Destination" value={edge.stamping.destinationName} />
          <Definition
            label="Liquid dispense volume per tip"
            value={formatMeasurementObj(action.volume)}
          />
          <Definition label="Number of tips used" value={edge.stamping.channelCount} />
          <Divider sx={{ mx: -4 }} />
        </>
      )}

      {showAspiration && (
        <Subsection title="Aspirate">
          <PipettingInfoSection options={action.asp} />
          {action.asp.mixing && (
            <MixingInfoSection header="Premix" mixInfo={action.asp.mixing} />
          )}
        </Subsection>
      )}

      {isTipAction && (
        <Subsection title="Dispense">
          <PipettingInfoSection options={action.dsp} touchoff={action.dsp.touchoff} />
          {action.dsp.mixing && (
            <MixingInfoSection header="Postmix" mixInfo={action.dsp.mixing} />
          )}
          <BlowoutInfoSection header="Blowout" blowoutInfo={action.dsp.blowout} />
        </Subsection>
      )}

      {transferRules.length > 0 && (
        <details className={classes.rules}>
          <summary>
            <Typography variant="caption">Matched Rules</Typography>
          </summary>
          <RulesInfoSection rules={transferRules} />
        </details>
      )}
    </Section>
  );
}

function getTransferSectionTitle(edgeInfo: ExtendedEdgeInfo): string {
  const volume = formatVolumeWithStdDev(edgeInfo.edge.action.volume);
  const sourceWell = formatWellPosition(edgeInfo.sourceLocation);
  const targetWell = formatWellPosition(edgeInfo.targetLocation);
  switch (edgeInfo.edge.type) {
    case 'liquid_dispense': {
      const baseInfo = `Dispense ${volume} to ${targetWell}`;
      return edgeInfo.edge.stamping ? `${baseInfo} (Stamping)` : baseInfo;
    }
    case 'liquid_transfer': {
      const baseInfo = `Transfer ${volume} ${sourceWell} → ${targetWell}`;
      return edgeInfo.edge.stamping ? `${baseInfo} (Stamping)` : baseInfo;
    }

    case 'filtration':
      return `Column filtration ${volume} to ${targetWell}`;
    case 'stamping':
      return `Stamping - ${edgeInfo.edge.channelCount} x ${formatMeasurementObj(
        edgeInfo.edge.action.volume,
      )}`;
  }
}

function isBlowout(options: PipettingOptions | BlowoutInfo): options is BlowoutInfo {
  return !('liquid_level_follow' in options);
}

type PipettingInfoSectionProps = {
  options: PipettingOptions | BlowoutInfo;
  touchoff?: boolean;
};

function PipettingInfoSection({ options, touchoff }: PipettingInfoSectionProps) {
  const liquidLevelSettings = isBlowout(options)
    ? undefined
    : [
        'Detect ' + (options.liquid_level_detect ? 'On' : 'Off'),
        'Follow ' + (options.liquid_level_follow ? 'On' : 'Off'),
      ];

  return (
    <>
      {options.height && (
        <Definition label="Position" value={formatPipettingHeight(options.height)} />
      )}
      {!isBlowout(options) && options.zone && (
        <Definition label="Well Zone" value={options.zone} />
      )}
      {touchoff === true && <div>Touchoff</div>}
      {options.flow_rate && (
        <Definition label="Flow rate" value={formatMeasurementObj(options.flow_rate)} />
      )}
      {!isBlowout(options) && options.device_liquid_class_name && (
        <Definition
          label="Device Liquid Class"
          value={options.device_liquid_class_name}
        />
      )}
      {liquidLevelSettings && (
        <Definition
          label="Liquid Level Settings"
          value={liquidLevelSettings.join(', ')}
        />
      )}
    </>
  );
}

type MixingInfoProps = {
  header: string;
  mixInfo: MixInfo | null;
};

function MixingInfoSection({ header, mixInfo }: MixingInfoProps) {
  if (!mixInfo) {
    return null;
  }
  return (
    <Subsection
      title={`${header}: ${formatMeasurementObj(mixInfo.volume)} x${mixInfo.cycles}`}
    >
      <PipettingInfoSection options={mixInfo} />
    </Subsection>
  );
}

type BlowoutInfoProps = {
  header: string;
  blowoutInfo: BlowoutInfo | null;
};

function BlowoutInfoSection({ header, blowoutInfo }: BlowoutInfoProps) {
  if (!blowoutInfo) {
    return null;
  }
  return (
    <Subsection title={header}>
      <Definition label="Volume" value={formatMeasurementObj(blowoutInfo.volume)} />
      <PipettingInfoSection options={blowoutInfo} />
    </Subsection>
  );
}

type RulesInfoSectionProps = {
  rules: RuleWithOverrides[];
};

function RulesInfoSection({ rules }: RulesInfoSectionProps) {
  const classes = useStyles();
  return (
    <>
      {rules.map(({ name, source, consequences }) => (
        <Subsection key={name} title={`${name} (${source} rule)`}>
          {consequences.map(({ property, value, overridden }) => (
            <Definition
              key={property}
              label={formatConsequencePropertyName(property)}
              value={
                <>
                  <span className={cx({ [classes.ruleOverridden]: overridden })}>
                    {formatConsequenceValue(value)}
                  </span>
                  {overridden && ' (overridden)'}
                </>
              }
            />
          ))}
        </Subsection>
      ))}
    </>
  );
}

type WellPanelSectionProps = {
  wellInfo: WellInfo;
  /**
   * Show a toggle that lets the user see all transfers involving this well up to the
   * current step.
   */
  showWellProvenanceToggle?: boolean;
  showWellProvenance?: boolean;
  onShowWellProvenanceChange?: (filterByWells: boolean) => void;
  factors?: Factors;
};

/**
 * Displays info about a well
 */
function WellPanelSection({
  wellInfo,
  showWellProvenanceToggle,
  showWellProvenance,
  onShowWellProvenanceChange,
  factors,
}: WellPanelSectionProps) {
  const { contents, loc } = wellInfo;
  if (!contents) {
    return null;
  }

  return (
    <Section title={formatWellPosition(loc)}>
      {showWellProvenanceToggle && (
        <Tooltip title="When toggled, show all transfers involving this well up to the current step.">
          <FormControlLabel
            control={
              <Switch
                checked={showWellProvenance}
                onChange={e => onShowWellProvenanceChange?.(e.target.checked)}
              />
            }
            label="Show well provenance"
          />
        </Tooltip>
      )}
      <WellInfoSection wellInfo={wellInfo} factors={factors} />
    </Section>
  );
}

type WellInfoSectionProps = {
  wellInfo: WellInfo;
  factors?: Factors;
};

function WellInfoSection({ wellInfo, factors }: WellInfoSectionProps) {
  const enableDOE = useFeatureToggle('NEW_DOE');

  const { contents } = wellInfo;
  if (!contents) {
    return null;
  }

  // check if the well contents has a (deprecated) components field and show
  // the names for consistency with previous behaviour
  if (contents.components) {
    const components = [...(contents.components || [])];
    components.sort(byName);

    return (
      <>
        <Definition
          label="Well Contents"
          value={`${
            contents.total_volume && formatMeasurementObj(contents.total_volume)
          } ${contents.name || ''}`}
        />
        <Definition
          label="Contents"
          value={
            <>
              {components.map(component => (
                <div key={component.name}>{component.name}</div>
              ))}
            </>
          }
        />
      </>
    );
  }

  const subLiquids = [...(contents.sub_liquids || [])];
  subLiquids.sort(byName);

  // solutes are already sorted. Sorting them purely by name can cause issues
  // since the same name may exist multiple times with incompatible concentration
  // units (e.g. "NaCl" might appear with "1mM" and also "1 mg/L", but we can't
  // simply sum the concentrations and must show both)
  const solutes = contents.solutes || [];

  const tags = contents.tags || [];

  return (
    <>
      <Definition
        label={
          contents.kind === 'filter_matrix_summary'
            ? 'Filter Matrix Type'
            : 'Well Contents'
        }
        value={`${contents.total_volume && formatMeasurementObj(contents.total_volume)} ${
          contents.name || ''
        }`}
      />
      {contents.type && <Definition label="Liquid Type" value={contents.type} />}
      {enableDOE && factors && contents.designFactors && (
        <Definition
          label="Design Factors"
          value={
            <LevelsTable
              factors={factors}
              runData={contents.designFactors.values}
              run={contents.designFactors.run}
            />
          }
        />
      )}
      {subLiquids.length > 0 && (
        <Definition
          label="Source Liquids"
          value={
            <LiquidTable
              table={subLiquids.map(component => ({
                name: component.name,
                value: component.volume,
              }))}
            />
          }
        />
      )}
      {solutes.length > 0 && (
        <Definition
          label="Final Concentrations"
          value={
            <LiquidTable
              table={solutes.map(solute => ({
                name: solute.name,
                value: solute.concentration,
              }))}
            />
          }
        />
      )}
      {tags.length > 0 && (
        <Subsection title="Metadata">
          {tags.map(tag => (
            <Definition
              key={getTagKey(tag)}
              label={tag.label}
              value={formatTagValue(tag)}
            />
          ))}
        </Subsection>
      )}
    </>
  );
}

type SectionProps = {
  /**
   * Should be Title Case (a convention set by the tag names output by antha)
   */
  title: string;
  children: ReactNode;
};

function Section({ title, children }: SectionProps) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <Typography variant="subtitle1" className={classes.sectionTitle}>
        {title}
      </Typography>
      <div className={classes.sectionContent}>{children}</div>
    </div>
  );
}

function Subsection({ title, children }: SectionProps) {
  const classes = useStyles();
  return (
    <div className={classes.subsection}>
      <Typography variant="subtitle2" className={classes.subsectionTitle}>
        {title}
      </Typography>
      {children}
    </div>
  );
}

type KeyValueProps = {
  /**
   * Should be Title Case (a convention set by the tag names output by antha)
   */
  label: ReactNode;
  value: ReactNode;
};

function Definition({ label, value }: KeyValueProps) {
  const classes = useStyles();
  return (
    <>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.definitionLabel}
      >
        {label}
      </Typography>
      <Typography
        variant="body2"
        // Typography's default <p> component doesn't allow child divs. Use div instead
        component="div"
        className={classes.definitionValue}
      >
        {value}
      </Typography>
    </>
  );
}

type LiquidTableProps = {
  table: { name: string; value: Measurement }[];
};

/**
 * Tabulate a list of liquids, with volume/concentration in the left column and liquid
 * name on the right.
 */
function LiquidTable({ table }: LiquidTableProps) {
  const classes = useStyles();
  return (
    <div className={classes.liquidTable}>
      {table.map(({ name, value }) => (
        <Fragment key={name}>
          <div className={classes.liquidTableVol}>{formatMeasurementObj(value)}</div>
          <div className={classes.liquidTableName}>{name}</div>
        </Fragment>
      ))}
    </div>
  );
}

function LevelsTable({
  factors,
  runData,
  run,
}: {
  factors: Factors;
  runData: DOEDesignRun;
  run: number;
}) {
  const classes = useStyles();
  return (
    <div className={classes.liquidTable}>
      <div className={classes.liquidTableVol}>Run</div>
      <div className={classes.liquidTableName}>{run}</div>
      {factors.map(factor => (
        <Fragment key={factor.id}>
          <div className={classes.liquidTableVol}>{factor.displayName}</div>
          <div className={classes.liquidTableName}>{runData[factor.id]}</div>
        </Fragment>
      ))}
    </div>
  );
}

function stopPropagation(event: React.MouseEvent) {
  // The parent screen handles background clicks in order to unselect a well.
  // When someone clicks inside the panel, they might be selecting text to
  // copy & paste.
  // We want the well to stay selected.
  event.stopPropagation();
}

/**
 * Tag labels can be reused, so they are insufficient for react keys. Uniqueness is
 * guaranteed across label and value pair.
 */
function getTagKey(tag: DataTag): string {
  return [tag.label, formatTagValue(tag)].join('-');
}

const useStyles = makeStylesHook(theme => ({
  panel: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 0,
    flexGrow: 1,
    overflowX: 'hidden',
    overflowY: 'auto',
    wordWrap: 'break-word',
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  section: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${Colors.GREY_30}`,
    },
  },
  sectionTitle: {
    borderBottom: `1px solid ${Colors.GREY_30}`,
    padding: theme.spacing(3, 4),
    background: Colors.GREY_10,
  },
  sectionContent: {
    padding: theme.spacing(4, 4, 0, 4),
  },
  definitionLabel: {
    display: 'block',
    margin: theme.spacing(2, 0, 1, 0),
    '&:first-child': {
      marginTop: 0,
    },
  },
  definitionValue: {
    // long_liquid_class_names can be long; ensure they wrap
    ...SMART_WORD_BREAK_STYLE,
    marginBottom: theme.spacing(4),
  },
  subsection: {
    marginTop: theme.spacing(3),
  },
  subsectionTitle: {
    margin: theme.spacing(5, 0, 3, 0),
  },
  liquidTable: {
    display: 'grid',
    // Left column (volume or concentration) should be as small as it can be. Right column
    // (liquid name) should take up the remainder of the space
    gridTemplateColumns: '3fr 4fr',
    gap: theme.spacing(3, 2),
  },
  liquidTableVol: {
    gridColumn: 1,
    textAlign: 'right',
  },
  liquidTableName: {
    gridColumn: 2,
    marginLeft: theme.spacing(3),
  },
  rules: {
    marginTop: theme.spacing(4),
    '& > summary': {
      cursor: 'pointer',
    },
    '&:not([open]) > summary': {
      marginBottom: theme.spacing(4),
    },
  },
  ruleOverridden: {
    textDecoration: 'line-through',
  },
}));
