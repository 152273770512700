import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { MUTATION_CREATE_EXECUTION } from 'client/app/api/gql/mutations';
import { executionRoutes } from 'client/app/lib/nav/actions';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export default function useCreateExecution(simulationId: SimulationId) {
  const syntheticDataEnabled = useFeatureToggle('SYNTHETIC_DATA');
  const { showError } = useSnackbarManager();
  const [createExecution, { loading }] = useMutation(MUTATION_CREATE_EXECUTION);
  const { navigate } = useNavigation();

  const createExecutionCallback = useCallback(async () => {
    await createExecution({
      variables: {
        simulationId,
        createWithSyntheticData: syntheticDataEnabled,
      },
      onCompleted({ createExecution: execution }) {
        if (execution) {
          navigate(executionRoutes.openInExecutionDetails, {
            executionId: execution.id,
          });
        } else {
          showError('Failed to create execution');
        }
      },
      onError(error) {
        showError(error.message);
      },
    });
  }, [simulationId, syntheticDataEnabled, showError, createExecution, navigate]);

  return [createExecutionCallback, { loading }] as const;
}
