import React, { useCallback, useMemo, useState } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  DECK_LOCATION_CSS_CLASS,
  getSmallDeckPositionTooltipStyles,
  getValidPositionsForLabwareType,
  LayoutOptions,
  SMALL_DECK_POSITION_SIZE,
  useDeckPositionTooltip,
} from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import { DeckPositionChip } from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/DeckPositionChip';
import {
  areLabwareTypesEqual,
  getLabwareForPosition,
} from 'client/app/state/LabwarePreference';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { Dimensions } from 'common/types/Dimensions';
import { Position2d } from 'common/types/Position';
import Colors from 'common/ui/Colors';
import Popover from 'common/ui/components/Popover';
import { DeckPositionRect } from 'common/ui/components/simulation-details/mix/DeckLayout';
import { getPosFromEvent, isDrag } from 'common/ui/lib/ClickRecognizer';

/**
 * DeckPositionWithPreferences renders the position that a labware can be placed along with info
 * regarding whether or not the position is a place where labware preference has been added.
 * This differs from the DeckPositionView where this allows for interactions for labware preferences.
 */

type DeckPositionWithPreferencesProps = {
  deckPosition: DeckPositionRect;
  validLayoutOptions: LayoutOptions;
  isReadonly: boolean;
};

export default React.memo(function DeckPositionWithPreferences({
  deckPosition: { deckPositionName, absolutePosInDeckPixels },
  validLayoutOptions,
  isReadonly,
}: DeckPositionWithPreferencesProps) {
  const dispatch = useWorkflowBuilderDispatch();

  const selectedLabwareType = useWorkflowBuilderSelector(
    state => state.labwarePreferenceType,
  );
  const labwareForPosition = useWorkflowBuilderSelector(state =>
    getLabwareForPosition(state, deckPositionName),
  );
  const labwarePreferencesAddedOrder = useWorkflowBuilderSelector(
    state => state.labwarePreferencesAddedOrder,
  );
  const addedOrder = useMemo(
    () =>
      /**
       * Put the labware chip corresponding to the selected labware type on top
       * and then sort the remaining ones alphabetically
       */
      Array.from(labwarePreferencesAddedOrder[deckPositionName] ?? [])
        .sort()
        .sort(labwareType =>
          areLabwareTypesEqual(labwareType, selectedLabwareType) ? -1 : 1,
        ),
    [deckPositionName, labwarePreferencesAddedOrder, selectedLabwareType],
  );

  const addPosition = useCallback(() => {
    if (selectedLabwareType) {
      dispatch({
        type: 'addLabwarePreference',
        payload: {
          labwareType: selectedLabwareType,
          position: deckPositionName,
        },
      });
    }
  }, [deckPositionName, dispatch, selectedLabwareType]);

  const removePosition = useCallback(() => {
    if (selectedLabwareType) {
      dispatch({
        type: 'removeLabwarePreference',
        payload: {
          labwareType: selectedLabwareType,
          position: deckPositionName,
        },
      });
    }
  }, [deckPositionName, dispatch, selectedLabwareType]);

  const isSelectedForCurrentLabware = useMemo(() => {
    if (!selectedLabwareType) {
      return false;
    }
    return (
      labwareForPosition.filter(labware =>
        areLabwareTypesEqual(labware, selectedLabwareType),
      ).length > 0
    );
  }, [labwareForPosition, selectedLabwareType]);

  const validPositionsForLabware = useMemo(() => {
    return selectedLabwareType
      ? getValidPositionsForLabwareType(selectedLabwareType, validLayoutOptions)
      : [];
  }, [selectedLabwareType, validLayoutOptions]);

  const isDeckPositionSelectable =
    !isReadonly && validPositionsForLabware.includes(deckPositionName);

  const [cursorPosition, setCursorPosition] = useState<Position2d | null>(null);
  const setCurrentCursorPosition = (event: React.PointerEvent) => {
    if (isDeckPositionSelectable) {
      setCursorPosition(getPosFromEvent(event));
    }
  };
  const handleSelection = (event: React.PointerEvent) => {
    // We only want to trigger the addition/removal if the user has clicked
    // and not if they are dragging the workspace canvas.
    if (!isDeckPositionSelectable || isDrag(cursorPosition, event)) return;

    if (isSelectedForCurrentLabware) {
      removePosition();
    } else {
      addPosition();
    }
  };

  const chipList = (
    <ChipList>
      {isDeckPositionSelectable &&
        selectedLabwareType &&
        !isSelectedForCurrentLabware && (
          <DeckPositionChip labwareType={selectedLabwareType} state="placeholder" />
        )}
      {addedOrder.map(labware => (
        <DeckPositionChip
          key={`${deckPositionName}_${labware}`}
          labwareType={labware}
          deckPositionName={deckPositionName}
          state={
            areLabwareTypesEqual(selectedLabwareType, labware) ? 'active' : 'inactive'
          }
        />
      ))}
    </ChipList>
  );

  const positionLabel = (
    <PositionLabel variant="h5" noWrap title={deckPositionName}>
      {deckPositionName}
    </PositionLabel>
  );

  const checkIcon = isSelectedForCurrentLabware && (
    <CheckIcon color="success" positionDimensions={absolutePosInDeckPixels} />
  );

  const isSmallDeckPosition =
    Math.min(absolutePosInDeckPixels.width, absolutePosInDeckPixels.height) <=
    SMALL_DECK_POSITION_SIZE;

  const tooltipHandle = useDeckPositionTooltip();

  const deckLocationProps = {
    className: DECK_LOCATION_CSS_CLASS,
    style: absolutePosInDeckPixels,
    selectable: isDeckPositionSelectable,
    selected: isSelectedForCurrentLabware,
    wasSelected: addedOrder.length > 0,
  };

  return isSmallDeckPosition ? (
    <Popover
      open={tooltipHandle.open}
      placement="bottom-start"
      componentsProps={{
        tooltip: {
          sx: getSmallDeckPositionTooltipStyles(isDeckPositionSelectable),
        },
      }}
      title={
        <>
          {chipList}
          {positionLabel}
        </>
      }
    >
      <DeckLocation
        key={deckPositionName}
        {...deckLocationProps}
        onPointerDown={(event: React.PointerEvent) => {
          setCurrentCursorPosition(event);
          tooltipHandle.hide();
          tooltipHandle.lock();
        }}
        onPointerUp={(event: React.PointerEvent) => {
          handleSelection(event);
          tooltipHandle.unlock();
        }}
        onMouseEnter={tooltipHandle.show}
        onMouseLeave={tooltipHandle.hide}
        onWheel={tooltipHandle.hideThrottled}
      >
        <IconContainer>{checkIcon}</IconContainer>
      </DeckLocation>
    </Popover>
  ) : (
    <DeckLocation
      key={deckPositionName}
      {...deckLocationProps}
      onPointerDown={setCurrentCursorPosition}
      onPointerUp={handleSelection}
    >
      <ChipListContainer>{chipList}</ChipListContainer>
      <IconContainer>{checkIcon}</IconContainer>
      <PositionLabelContainer>{positionLabel}</PositionLabelContainer>
    </DeckLocation>
  );
});

// #region Styles

const DeckLocation = styled('main', {
  shouldForwardProp: prop =>
    !(['selectable', 'selected', 'wasSelected'] as PropertyKey[]).includes(prop),
})<{
  selectable?: boolean;
  selected?: boolean;
  wasSelected?: boolean;
}>(({ selectable, selected, wasSelected }) => {
  const selectableStyle = selectable
    ? {
        backgroundColor: Colors.GREY_0,
        '&:hover': {
          cursor: 'pointer',
          border: `2px solid ${Colors.BLUE_50}`,
          backgroundColor: Colors.BLUE_0,
        },
      }
    : selected
    ? {
        backgroundColor: Colors.GREY_0,
      }
    : {
        background: `linear-gradient(to top left,
             ${Colors.GREY_5} 0%,
             ${Colors.GREY_5} calc(50% - 0.8px),
             ${Colors.GREY_30} 50%,
             ${Colors.GREY_5} calc(50% + 0.8px),
             ${Colors.GREY_5} 100%)`,
      };

  const selectedStyle = selected
    ? {
        border: `2px solid ${Colors.BLUE_50}`,
      }
    : null;

  const selectablePositionNotSelectedBeforeStyle =
    selectable && !wasSelected && !selected
      ? {
          border: `2px dashed ${Colors.GREY_30}`,
        }
      : null;

  return {
    position: 'absolute',
    backgroundColor: Colors.GREY_5,
    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '4px',

    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr',

    '&:hover': {
      zIndex: 1,
    },

    ...selectableStyle,
    ...selectedStyle,
    ...selectablePositionNotSelectedBeforeStyle,
  };
});

const ChipList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const ChipListContainer = styled('section')(({ theme }) => ({
  gridRow: 1,
  alignSelf: 'start',
  padding: theme.spacing(5, 0, 0, 5),
}));

const PositionLabel = styled(Typography)(({ theme }) => {
  return {
    maxWidth: 'fit-content',
    padding: theme.spacing(2, 3),

    backgroundColor: Colors.GREY_20,
    color: theme.palette.text.secondary,

    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '4px',
    textOverflow: 'ellipsis',
  };
});

const PositionLabelContainer = styled('section')(({ theme }) => ({
  gridRow: 3,
  alignSelf: 'end',
  overflow: 'hidden',
  padding: theme.spacing(0, 0, 5, 5),
}));

const CheckIcon = styled(CheckCircleOutlineIcon, {
  shouldForwardProp: prop => prop !== 'positionDimensions',
})<{ positionDimensions: Dimensions }>(
  ({ theme, positionDimensions: { width, height } }) => {
    const minDimension = Math.min(width, height);
    return {
      alignSelf: 'center',
      fontSize:
        minDimension > SMALL_DECK_POSITION_SIZE
          ? 44
          : minDimension > SMALL_DECK_POSITION_SIZE / 2
          ? 32
          : 16,
      [`[class*=${DECK_LOCATION_CSS_CLASS}]:hover &`]: {
        color: theme.palette.primary.main,
      },
    };
  },
);

const IconContainer = styled('section')({
  gridRow: '2',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

// #endregion
