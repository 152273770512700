import { useCallback, useEffect, useState } from 'react';

import {
  ELEMENT_INSTANCE_WIDTH,
  STAGE_PADDING,
} from 'client/app/lib/layout/LayoutHelper';
import { ScreenRegistry } from 'client/app/registry';
import { Stage } from 'common/types/bundle';
import { Position2d } from 'common/types/Position';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

/**
 * Does the magic needed to convert the drag and drop coordinates to
 * the Element Plumber coordinates, so that dropping elements matches exactly
 * the users' mouse position.
 */
export function computeDragAndDropLandingPosition(
  droppedAtPosition: Position2d,
  zoom: number,
  workspacePosition: Position2d,
  workflowLayoutRef: React.MutableRefObject<HTMLDivElement | null>,
  stages?: Stage[],
) {
  const normaliseByZoom = ({ x, y }: Position2d) => ({
    x: x / zoom,
    y: y / zoom,
  });
  const normalisedDroppedAtPosition = normaliseByZoom(droppedAtPosition);

  // Workspace position is already normalised by zoom.
  const workspaceDistanceFromTopLeftCornerOfParent = workspacePosition;

  const elementPlumberPositionOnScreen =
    workflowLayoutRef.current?.getBoundingClientRect();
  const nomalisedElementPlumberPosition = elementPlumberPositionOnScreen
    ? normaliseByZoom(elementPlumberPositionOnScreen)
    : { x: 0, y: 0 };

  let x =
    normalisedDroppedAtPosition.x -
    nomalisedElementPlumberPosition.x +
    workspaceDistanceFromTopLeftCornerOfParent.x;

  // If the drop position would cause the element to overlap a stage boundary,
  // we push into the closest stage.
  if (stages) {
    const overlapping = stages.find(
      stage =>
        stage.meta.x !== undefined &&
        x < stage.meta.x &&
        x + ELEMENT_INSTANCE_WIDTH > stage.meta.x,
    );

    if (overlapping) {
      const leftPortion = (overlapping.meta.x ?? 0) - x;
      const rightPortion = ELEMENT_INSTANCE_WIDTH + x - (overlapping.meta.x ?? 0);
      if (leftPortion > rightPortion) {
        x -= rightPortion + STAGE_PADDING;
      } else {
        x += leftPortion + STAGE_PADDING;
      }
    }
  }

  return {
    x,
    y:
      normalisedDroppedAtPosition.y -
      nomalisedElementPlumberPosition.y +
      workspaceDistanceFromTopLeftCornerOfParent.y,
  };
}

export type onDragAndDropElementCallback = (
  e: React.DragEvent<HTMLDivElement>,
  elementId: string,
) => void;
/**
 * Enables adding elements to a workflow by drag and dropping them.
 */
export function useDragAndDrop(
  workflowLayoutRef: HTMLDivElement | null,
  isReadonly: boolean,
  handlePlaceElement: (elementId: string, droppedAtPosition?: Position2d) => void,
) {
  // To add an element instance, we need its id. Keep track of it as soon as
  // we start dragging an element from the sidebar.
  const [currentlyDraggedElementId, setCurrentlyDraggedElementId] = useState<
    string | undefined
  >();
  const handleDragElement = useCallback(
    (e: React.DragEvent<HTMLDivElement>, elementId: string) => {
      setCurrentlyDraggedElementId(elementId);
    },
    [],
  );

  // Dropping an element onto the Workspace will add an element instance.
  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();

      if (isReadonly || !currentlyDraggedElementId) {
        // Prevent drop
        return;
      }
      logEvent('drag-element-to-workflow-builder', ScreenRegistry.WORKFLOW);
      handlePlaceElement(currentlyDraggedElementId, { x: e.x, y: e.y });
    },
    [currentlyDraggedElementId, handlePlaceElement, isReadonly],
  );

  useEffect(() => {
    const currWorkflowLayoutRef = workflowLayoutRef;
    if (currWorkflowLayoutRef) {
      currWorkflowLayoutRef.addEventListener('drop', handleDrop);
    }

    return () => {
      if (currWorkflowLayoutRef) {
        currWorkflowLayoutRef.removeEventListener('drop', handleDrop);
      }
    };
  }, [handleDragElement, handleDrop, workflowLayoutRef]);

  return handleDragElement;
}
