import { ServerSideElements } from 'common/types/bundle';

// A pre-configured element for Define_Liquids_And_Plates.
export function defineLiquidsAndPlatesElement(): ServerSideElements {
  return {
    Instances: {
      ['Define Liquids And Plates']: {
        TypeName: 'Define_Liquids_And_Plates',
        Parameters: { DefineLiquidsFrom: 'Simulation' },
        Id: '',
        Meta: { x: 0, y: 0 },
      },
    },
    InstancesConnections: [],
  };
}
