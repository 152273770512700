import { StockConcentration } from 'client/app/apps/execution-details/types';
import { Reagent } from 'client/app/gql';
import { formatMeasurementObj, pluralizeWord, roundUp } from 'common/lib/format';
import { byName } from 'common/lib/strings';
import {
  divide,
  greaterThan,
  greaterThanEqual,
  isCompatible,
  lessThan,
} from 'common/lib/units';
import { Measurement } from 'common/types/mix';

export default {
  getDescription,
  getDiluentVolume,
  getConcentration,
  getTargetConcentration,
  getRequiredStockVolumes,
  getDefaultStockConcentrations,
  getRequiredSoluteVolumes,
  // Stock view helpers
  getTotalVolumes,
  getStockErrors,
  getStockWarnings,
};

function getDescription(reagent: Reagent): string {
  if (reagent.solutes.length === 0) {
    return '';
  }
  if (reagent.solutes.length === 1 && reagent.solutes[0].name === reagent.name) {
    return formatMeasurementObj(reagent.solutes[0].concentration, false);
  }
  if (reagent.solutes.length === 1) {
    const s = reagent.solutes[0];
    return `${formatMeasurementObj(s.concentration, false)} ${s.name}`;
  }
  return `${reagent.solutes.length} ${pluralizeWord(
    reagent.solutes.length,
    'component',
  )}`;
}

function getDiluentVolume(
  reagent: Reagent,
  requiredSoluteVolumes: Map<StockConcentration, Measurement>,
): number {
  const volumeFromStocks = [...requiredSoluteVolumes.keys()].reduce((v, curr) => {
    return v + requiredSoluteVolumes.get(curr)!.value;
  }, 0.0);
  return reagent.volumeUl - volumeFromStocks;
}

function getConcentration(
  stocks: StockConcentration[],
  name: string,
  unit: string,
): StockConcentration | undefined {
  return stocks.find(el => el.name === name && isCompatible(el.concentration.unit, unit));
}

function getTargetConcentration(
  reagent: Reagent,
  stock: StockConcentration,
): Measurement {
  return (
    reagent.solutes.find(
      s =>
        s.name === stock.name &&
        isCompatible(s.concentration.unit, stock.concentration.unit),
    )?.concentration || { value: 0.0, unit: '' }
  );
}

/**
 * returns a map whose keys are the required stocks and values are the volume of
 * that stock
 */
function getRequiredStockVolumes(
  stockConcentrations: StockConcentration[],
  reagent: Reagent,
): Map<StockConcentration, Measurement> {
  return reagent.solutes.reduce(function (map, solute) {
    const stock = getConcentration(
      stockConcentrations,
      solute.name,
      solute.concentration.unit,
    )!;
    const required = {
      value: reagent.volumeUl * divide(solute.concentration, stock.concentration),
      unit: 'ul',
    };
    return map.set(stock, required);
  }, new Map<StockConcentration, Measurement>());
}

function getDefaultStockConcentrations(reagents: Reagent[]): StockConcentration[] {
  // we calculate initial stock concentrations by calculating the stock
  // concentrations for each reagent assuming each stock takes up an equal
  // volume. Then we find the maximum required concentration of each stock
  // across all reagents.
  // This gives us a set of stock concentrations that are guaranteed to be
  // able to make all reagents.
  const concentrations: StockConcentration[] = [];
  reagents.forEach(r => {
    r.solutes.forEach(s => {
      const requiredConc = {
        value: roundUp(s.concentration.value * r.solutes.length),
        unit: s.concentration.unit,
      };

      const current = getConcentration(concentrations, s.name, s.concentration.unit);
      if (current === undefined) {
        concentrations.push({ name: s.name, concentration: requiredConc });
      } else if (lessThan(current.concentration, requiredConc)) {
        current.concentration = requiredConc;
      }
    });
  });

  concentrations.sort(byName);

  return concentrations;
}

/**
 * returns a map whose keys are the required solutes and values are the required
 * volumes given the available stocks
 */
function getRequiredSoluteVolumes(
  stockConcentrations: StockConcentration[],
  reagent: Reagent,
): Map<StockConcentration, Measurement> {
  return reagent.solutes.reduce(function (map, solute) {
    const stock = getConcentration(
      stockConcentrations,
      solute.name,
      solute.concentration.unit,
    )!;
    const required = {
      value: reagent.volumeUl * divide(solute.concentration, stock.concentration),
      unit: 'ul',
    };
    return map.set(solute, required);
  }, new Map<StockConcentration, Measurement>());
}

//#region Stock view helpers

function getTotalVolumes(reagents: Reagent[], stockConcentrations: StockConcentration[]) {
  return reagents.reduce((totals, reagent) => {
    getRequiredStockVolumes(stockConcentrations, reagent).forEach((value, stock) => {
      const volume = totals.get(stock) ?? { value: 0, unit: 'ul' };
      volume.value += value.value;
      totals.set(stock, volume);
    });
    return totals;
  }, new Map<StockConcentration, Measurement>());
}

function getStockErrors(stockConcentrations: StockConcentration[], reagents: Reagent[]) {
  // there are two ways that stock concentrations can be valid:
  // 1. The concentration of the stock is less than the concentration required
  //    by one or more reagents.
  //    This is an obvious error with only one way to fix it (increse the
  //    affected stock concentration) so highlighted
  return stockConcentrations.reduce((errs, stock) => {
    const minimum = reagents.reduce(
      (max, reagent) => {
        return reagent.solutes.reduce((max, solute) => {
          if (
            solute.name !== stock.name ||
            !isCompatible(solute.concentration.unit, stock.concentration.unit) ||
            greaterThanEqual(max, solute.concentration)
          ) {
            return max;
          }
          return solute.concentration;
        }, max);
      },
      { value: 0, unit: '' },
    );
    if (greaterThan(minimum, stock.concentration)) {
      errs.set(stock, 'Higher concentration required');
    }
    return errs;
  }, new Map<StockConcentration, string>());
}

function getStockWarnings(
  stockErrors: Map<StockConcentration, string>,
  reagents: Reagent[],
  stockConcentrations: StockConcentration[],
) {
  // 2. All stocks are more concentrated than the required reagents, but
  //    at least one reagent where the sum of required volumes for each stock
  //    exceeds the volume of the reagent.
  //    Such an issue can be fixed by increasing the concentration of any of
  //    the affected stocks, or all of them in combination. Since this is a
  //    more subtle error, we only show them if there's no type 1 errors and
  //    we use a less agressive visual style.
  return stockErrors.size > 0
    ? new Map<StockConcentration, string>()
    : reagents.reduce((errs, reagent) => {
        const requiredVolumes = getRequiredStockVolumes(stockConcentrations, reagent);
        // TODO: convert vol to ul rather than assume
        const stocksVolume = [...requiredVolumes.values()].reduce(
          (total, vol) => total + vol.value,
          0,
        );
        if (stocksVolume > reagent.volumeUl) {
          requiredVolumes.forEach((value, key) => {
            errs.set(key, 'Higher concentration required');
          });
        }
        return errs;
      }, new Map<StockConcentration, string>());
}

//#endregion
