import React from 'react';

import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { SimulationIcon } from 'common/ui/icons/SimulationIcon';

type Props = {
  allSimulationCount: number | undefined;
  starredSimulationCount: number | undefined;
  loading?: boolean;
  className?: string;
};

// Min height for the container and skeleton. This is equivalent to the height of the Icons.
export const SIMULATION_COUNT_INFO_CONTAINER_HEIGHT = 24;

export default React.memo(function SimulationCountInfo(props: Props) {
  const classes = useStyles();
  const { allSimulationCount, starredSimulationCount, loading, className } = props;
  const userHasFavourites = starredSimulationCount && starredSimulationCount > 0;

  return (
    <div className={cx(classes.count, className)}>
      {loading ? (
        // Arbritrary width for the skeleton, but make the height match the minHeight
        // of classes.count so it does not re-adjust the other content when loading
        <Skeleton width="75px" height={SIMULATION_COUNT_INFO_CONTAINER_HEIGHT} />
      ) : (
        <>
          <Typography variant="body2" color="textPrimary">
            {starredSimulationCount ?? 0}
          </Typography>
          {userHasFavourites ? (
            <StarRoundedIcon fontSize="small" className={classes.icon} />
          ) : (
            <StarBorderRoundedIcon fontSize="small" className={classes.icon} />
          )}
          <Typography variant="body2" color="textPrimary">
            / {allSimulationCount ?? 0}
          </Typography>
          <SimulationIcon fontSize="small" className={classes.icon} />
        </>
      )}
    </div>
  );
});

const useStyles = makeStylesHook({
  count: {
    display: 'flex',
    alignItems: 'center',
    minHeight: SIMULATION_COUNT_INFO_CONTAINER_HEIGHT,
  },
  icon: {
    color: Colors.TEXT_DISABLED,
    display: 'flex',
    justifyContent: 'center',
  },
});
