import { useEffect, useState } from 'react';

import { useLoadAllCached } from 'client/app/api/PlateTypesApi';
import { PlateType } from 'common/types/plateType';

export default function usePlateTypes() {
  // So that we can show human-readable plate names in Mix Graph and Plate Prep.
  const [plateTypes, setPlateTypes] = useState<readonly PlateType[]>([]);

  const loadPlatesAllCached = useLoadAllCached();

  // Plate types for Mix Preview
  useEffect(() => {
    let isOutdated = false;

    loadPlatesAllCached()
      .then(plateTypes => !isOutdated && setPlateTypes(plateTypes))
      .catch(e => console.error(e));

    return function cleanup() {
      isOutdated = true;
    };
  }, [loadPlatesAllCached]);

  return plateTypes;
}
