import React from 'react';

import { useQuery } from '@apollo/client';

import {
  QUERY_INCOMING_METHODS,
  QUERY_INCOMING_WORKFLOWS,
} from 'client/app/api/gql/queries';
import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import IncomingMethodCard from 'client/app/components/cards/IncomingMethodCard';
import IncomingWorkflowCard from 'client/app/components/cards/IncomingWorkflowCard';
import {
  ArrayElement,
  IncomingMethodsQuery,
  IncomingWorkflowsQuery,
} from 'client/app/gql';
import { EntityCardSkeletonList } from 'common/ui/components/EntityCard';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type SharedMethod = ArrayElement<IncomingMethodsQuery['sharedMethods']>;
type SharedWorkflow = ArrayElement<IncomingWorkflowsQuery['sharedWorkflows']>;

export function IncomingList(props: { searchQuery: string }) {
  const { searchQuery } = props;
  const classes = useStyles();

  const {
    loading: methodsLoading,
    error: methodsError,
    data: methodsData,
    refetch: methodsRefetch,
  } = useQuery(QUERY_INCOMING_METHODS, {
    variables: { search: searchQuery },
    fetchPolicy: 'network-only',
  });

  const {
    loading: workflowsLoading,
    error: workflowsError,
    data: workflowsData,
    refetch: workflowsRefetch,
  } = useQuery(QUERY_INCOMING_WORKFLOWS, {
    variables: { search: searchQuery },
    fetchPolicy: 'network-only',
  });

  const handleRefetch = async () => {
    await Promise.all([methodsRefetch(), workflowsRefetch()]);
  };

  if (workflowsError) {
    return <GraphQLErrorPanel error={workflowsError} onRetry={handleRefetch} />;
  }

  if (methodsError) {
    return <GraphQLErrorPanel error={methodsError} onRetry={handleRefetch} />;
  }

  if (workflowsLoading || methodsLoading) {
    return <EntityCardSkeletonList />;
  }

  const noMethods = !methodsData || methodsData.sharedMethods.length === 0;
  const noWorkflows = !workflowsData || workflowsData.sharedWorkflows.length === 0;

  if (noMethods && noWorkflows) {
    return (
      <NoEntitiesMessage
        entityName="incoming methods or workflows"
        messageType={MessageType.NO_FILTER_RESULTS}
        searchQuery={searchQuery}
      />
    );
  }

  const orderedData: (SharedMethod | SharedWorkflow)[] = [];
  if (methodsData) {
    orderedData.push(...methodsData.sharedMethods);
  }
  if (workflowsData) {
    orderedData.push(...workflowsData.sharedWorkflows);
  }

  orderedData.sort((a, b) =>
    new Date(a.createdAt).valueOf() > new Date(b.createdAt).valueOf() ? -1 : 1,
  );

  return (
    <div className={classes.list}>
      {orderedData.map(item =>
        item.__typename === 'SharedWorkflow' ? (
          <IncomingWorkflowCard key={item.id} sharedWorkflow={item} />
        ) : (
          <IncomingMethodCard key={item.id} sharedMethod={item} />
        ),
      )}
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  ...experimentsStyles(theme),
}));
