import entries from 'lodash/entries';
import uniq from 'lodash/uniq';

import { Deck, DeckPosition, DeckState, RichAnnotationType } from 'common/types/mix';

/**
 * Hotels will be shrunk down so that they do not make the workspace really tall. The
 * shelves within the hotel, where plates sit, are a constant size as defined below.
 * The plates are shrunk down to fit within the shelf. Plate contents are not shown.
 */
const HOTEL_SHELF_HEIGHT_MM = 12;
/**
 * The size of the gap between each hotel shelf.
 */
const HOTEL_SHELF_GAP_MM = 4;

/**
 * Plates can be stored within 'hotels', which are vertical storage units mounted at the
 * rear of the liquid handler deck. Since we don't visualize a vertical/Z dimension in the
 * preview, antha rearranges the plates in layout.json such that they are stacked on the Y
 * axis. However, this means the hotels extremely tall in preview, making it difficult for
 * the user to see the entire deck.
 *
 * This function shrinks each shelf within the hotel to a small rectangle. The plate will
 * be displayed within this rectangle with just its title (the plate contents will not be
 * displayed).
 */
export function collapseHotels(deck: Deck): Deck {
  return {
    ...deck,
    before: collapseHotelsOfState(deck.before),
    after: collapseHotelsOfState(deck.after),
  };
}

type DeckItemPosition = DeckPosition & {
  deckPositionName: string;
};

function collapseHotelsOfState(deckState: DeckState): DeckState {
  const itemPositions: DeckItemPosition[] = Object.entries(deckState.positions).map(
    ([deckPositionName, item]) => ({
      ...item,
      deckPositionName,
      position: { ...item.position },
      size: { ...item.size },
    }),
  );

  const hotels = itemPositions.filter(item => item.label?.includes('Hotel'));

  for (const hotel of hotels) {
    const shelves = itemPositions
      .filter(
        item =>
          // Hotel shelves are identified with 'Hotel' in the deckPositionName
          item.deckPositionName.includes('Hotel') &&
          // Exclude hotels (they can also have deckPositionName containing 'Hotel')
          !item.label?.includes('Hotel') &&
          // Shelves belonging to this hotel will be positioned within the hotel geometry
          item.position.x_mm >= hotel.position.x_mm &&
          item.position.x_mm < hotel.position.x_mm + hotel.size.x_mm,
      )
      // Sort the shelves from top to bottom; this is the order we will reposition them
      .sort((shelfA, shelfB) => shelfA.position.y_mm - shelfB.position.y_mm);

    const verticalPositions = uniq(shelves.map(shelf => shelf.position.y_mm)).sort(
      (a, b) => a - b,
    );

    const oldHotelHeight = hotel.size.y_mm;
    const newHotelHeight =
      verticalPositions.length * (HOTEL_SHELF_HEIGHT_MM + HOTEL_SHELF_GAP_MM);

    hotel.position.y_mm += oldHotelHeight - newHotelHeight;
    hotel.size.y_mm = newHotelHeight;

    for (const shelf of shelves) {
      const verticalPosition = verticalPositions.indexOf(shelf.position.y_mm);
      shelf.position.y_mm =
        hotel.position.y_mm +
        verticalPosition * (HOTEL_SHELF_HEIGHT_MM + HOTEL_SHELF_GAP_MM);
      shelf.size.y_mm = HOTEL_SHELF_HEIGHT_MM;
    }
  }

  const deckTop = Math.min(...itemPositions.map(({ position }) => position.y_mm));

  const newState: DeckState = {
    positions: {},
  };
  for (const { deckPositionName, ...item } of itemPositions) {
    // Move everything on the deck up so that the top most item is at Y 0. Without this, the
    // initial preview scale will be too far out.
    item.position.y_mm -= deckTop;
    newState.positions[deckPositionName] = item;
  }
  // If present we must also move any rich annotations to match.
  entries(deckState.rich_annotations).forEach(([k, v]) => {
    switch (v.annotation_type) {
      case RichAnnotationType.GRID:
        v.grid_annotation.position.y_mm -= deckTop;
        break;
      case RichAnnotationType.EXTRA_DECK:
        v.extra_deck_annotation.position.y_mm -= deckTop;
        break;
      case RichAnnotationType.GROUP:
        v.positions_group_annotation.position.y_mm -= deckTop;
        break;
      case RichAnnotationType.STAGE:
      default:
        // do nothing
        break;
    }
    if (!newState.rich_annotations) {
      newState.rich_annotations = {};
    }
    newState.rich_annotations[k] = v;
  });

  return newState;
}
