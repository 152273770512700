import React, { useCallback, useState } from 'react';

import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import DialogContentText from '@mui/material/DialogContentText';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { useCherryPickContext } from 'client/app/apps/cherry-picker/CherryPickContext';
import { PolicyByLiquid } from 'client/app/apps/cherry-picker/cp-settings/CherryPickSettings';
import PolicyParameter from 'client/app/components/Parameters/Policy/PolicyParameter';
import { ScreenRegistry } from 'client/app/registry';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

export default function PolicySettings() {
  const { uniqueLiquidNames, setCherryPick, isReadonly } = useCherryPickContext();

  const [policyByLiquid, setPolicyByLiquid] = useState<PolicyByLiquid>({});

  const onChangePolicyByLiquid = useCallback(
    (newPolicyByLiquid: PolicyByLiquid) => {
      // Update local state to display the correct information in `PolicySettings`
      setPolicyByLiquid(newPolicyByLiquid);

      // Update "Global" (Context) state
      setCherryPick(prev => {
        return prev.map(prevTransfer => {
          const liquid = prevTransfer.liquid;
          const newPolicy = newPolicyByLiquid[liquid];

          if (!newPolicy) {
            return prevTransfer;
          }

          return { ...prevTransfer, policy: newPolicyByLiquid[liquid] };
        });
      });
    },
    [setCherryPick],
  );

  return (
    <Paper>
      <CardHeader title="Select policy" />
      <CardContent>
        <DialogContentText gutterBottom>
          You can select a policy that will be applied to all the transfers of a specific
          liquid
        </DialogContentText>
        {uniqueLiquidNames.length > 0 ? (
          <Table>
            <TableBody>
              {uniqueLiquidNames.map(liquidName => (
                <PolicyRow
                  key={liquidName}
                  liquidName={liquidName}
                  selectedPolicy={policyByLiquid}
                  onChangePolicyByLiquid={onChangePolicyByLiquid}
                  isReadonly={isReadonly}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography variant="body1">
            No liquids specified in this CherryPick.
          </Typography>
        )}
      </CardContent>
    </Paper>
  );
}

type RowProps = {
  liquidName: string;
  selectedPolicy: PolicyByLiquid;
  onChangePolicyByLiquid: (policy: PolicyByLiquid) => void;
  isReadonly: boolean;
};

const PolicyRow = React.memo((props: RowProps) => {
  const { liquidName, onChangePolicyByLiquid, selectedPolicy, isReadonly } = props;

  const handleUpdatePolicy = useCallback(
    (policy: string | undefined) => {
      logEvent('settings-change-policy', ScreenRegistry.CHERRY_PICKER);
      // Unselecting a policy in here (settings) won't affect the cherrypick policies by row.
      // This is not what users would expect. To avoid confusion, the easiest/quickest solution
      // for now is to prevent users from unselecting a policy.
      if (!policy) {
        return;
      }

      const newPolicy = { ...selectedPolicy, [liquidName]: policy };
      onChangePolicyByLiquid(newPolicy);
    },
    [liquidName, onChangePolicyByLiquid, selectedPolicy],
  );

  return (
    <TableRow>
      <TableCell>{liquidName}</TableCell>
      <TableCell>
        <PolicyParameter
          onChange={handleUpdatePolicy}
          value={selectedPolicy[liquidName]}
          isDisabled={isReadonly}
        />
      </TableCell>
    </TableRow>
  );
});
