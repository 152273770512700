import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { NoVisibilityIcon } from 'client/app/components/ElementPlumber/ElementOutputs/components/styles';

type Props = { label: string };

export default function NoPreview({ label }: Props) {
  return (
    <Stack direction="row" gap={3} paddingX={3} alignItems="center">
      <NoVisibilityIcon />
      <Typography variant="body2" color="textSecondary" whiteSpace="nowrap">
        {label}
      </Typography>
    </Stack>
  );
}
