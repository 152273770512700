import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import cx from 'classnames';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import DOEIcon from 'common/ui/icons/DOEIcon';

type Props = {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
};

export default function ElementParameterFactorSwitch({
  checked,
  onChange: handleChange,
  className,
  disabled,
}: Props) {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={cx(classes.toggleDOE, className)}
      control={<Switch onChange={handleChange} checked={checked} disabled={disabled} />}
      label={
        <DOEIcon style={{ color: checked ? Colors.BACKGROUND : Colors.TEXT_PRIMARY }} />
      }
    />
  );
}

const useStyles = makeStylesHook(({ palette }) => ({
  toggleDOE: {
    position: 'relative',
    margin: 0,
    '& [class*=MuiSwitch-root]': {
      padding: '1px 0',
      height: '22px',
      width: '42px',
    },

    '& [class*=MuiFormControlLabel-label]': {
      position: 'absolute',
      top: '4px',
      right: '5px',
    },
    '& [class*=MuiSwitch-track]': {
      height: '20px',
      width: '42px',
      borderRadius: '12px',
      background: Colors.GREY_20,
      opacity: 1,
    },
    '& [class*=MuiSwitch-switchBase]': {
      top: '5px',
      left: '4px',
      height: '12px',
      width: '12px',
      padding: 0,

      background: palette.text.primary,
      color: palette.text.primary,

      '& [class*=MuiSwitch-thumb]': {
        height: '12px',
        display: 'none',
      },

      '&.Mui-checked': {
        top: '0px',
        left: '0px',
        height: '22px',
        width: '22px',

        background: palette.secondary.main,
        color: palette.background.default,
      },
      '&.Mui-checked + [class*=MuiSwitch-track]': {
        opacity: 1,
        background: Colors.GREY_20,
      },
    },
    '& [class*=MuiSvgIcon]': {
      fontSize: '13px',
    },
  },
}));
