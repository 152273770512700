import React from 'react';

import { useMutation } from '@apollo/client';
import SchoolIcon from '@mui/icons-material/School';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { MUTATION_DELETE_EXPERIMENT } from 'client/app/apps/experiments/gql/mutations';
import { ArrayElement, ExperimentsQuery } from 'client/app/gql';
import { experimentsRoutes, workTreeRoutes } from 'client/app/lib/nav/actions';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import { EntityCard } from 'common/ui/components/EntityCard';
import EntityCardAction from 'common/ui/components/EntityCardAction';
import EntityCardMenu from 'common/ui/components/EntityCardMenu';
import IconButton from 'common/ui/components/IconButton';
import useDialog from 'common/ui/hooks/useDialog';
import { ExampleWorkflowIcon } from 'common/ui/icons';
import { ExperimentMapIcon } from 'common/ui/icons/ExperimentMapIcon';
import { NewExperimentsIcon } from 'common/ui/icons/NewExperimentsIcon';
import { cacheEvict } from 'common/utils';

type Experiment = ArrayElement<ExperimentsQuery['experiments']['items']>;

export function ExperimentCard({
  experiment,
  isLink,
  onClick,
  allowDelete,
  isDisabled,
  isSelected,
  variant = 'default',
  isEnabledQuickStartActivation = false,
}: {
  experiment: Experiment;
  isLink?: boolean;
  onClick?: (experimentId: ExperimentId) => void;
  allowDelete?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  variant?: 'default' | 'example';
  isEnabledQuickStartActivation?: boolean; // TODO - Remove this once QUICK_START_ACTIVATION toggle removed
}) {
  const {
    id,
    name,
    lastModifiedAt,
    createdBy: { displayName: author },
  } = experiment;

  const link = isLink ? experimentsRoutes.detail.getPath({ id }) : undefined;

  const workTreeLink = isLink
    ? workTreeRoutes.workTreeExperiment.getPath({ experimentId: id })
    : undefined;

  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);

  const [deleteExperiment] = useMutation(MUTATION_DELETE_EXPERIMENT);

  const handleDelete = async () => {
    const isDeleteConfirmed = await openConfirmationDialog({
      action: 'delete',
      isActionDestructive: true,
      object: 'experiment',
      specificObject: name,
    });
    if (isDeleteConfirmed) {
      await deleteExperiment({
        variables: { id },
        optimisticResponse: { __typename: 'Mutation', deleteExperiment: id },
        update: cache => cacheEvict({ id, __typename: 'Experiment' }, cache),
      });
    }
  };

  const nameColumn = { label: 'Experiment name', value: name };
  const authorColumn = { label: 'Author', value: author };
  const dateColumn = { label: 'Last modified', value: new Date(lastModifiedAt) };

  const interaction = {
    to: link,
    onClick: onClick ? () => onClick(id) : undefined,
  };

  const menu =
    isLink && variant !== 'example'
      ? [{ label: 'Delete', onClick: handleDelete }]
      : undefined;

  let action: JSX.Element | null = null;
  if (variant !== 'example' || !isEnabledQuickStartActivation) {
    action =
      isDisabled || !workTreeLink ? (
        <Button
          size="xsmall"
          icon={<ExperimentMapIcon fontSize="small" />}
          disabled={isDisabled}
          onClick={() => onClick?.(experiment.id)}
        />
      ) : (
        <Link data-heap-tracking="entity-card-WORK_TREE" to={workTreeLink}>
          <Button
            size="xsmall"
            icon={<ExperimentMapIcon fontSize="small" />}
            disabled={isDisabled}
          />
        </Link>
      );
  }

  return (
    <>
      <EntityCard
        heapTrackingLabel="EXPERIMENT"
        icon={
          variant === 'example' ? (
            isEnabledQuickStartActivation ? (
              <SchoolIcon />
            ) : (
              <ExampleWorkflowIcon />
            )
          ) : (
            <NewExperimentsIcon fontSize="small" />
          )
        }
        nameColumn={nameColumn}
        authorColumn={authorColumn}
        dateColumn={dateColumn}
        interaction={interaction}
        isDisabled={isDisabled}
        isSelected={isSelected}
        rightSlot={
          <>
            {isLink && action && (
              <EntityCardAction tooltipText="Go to map" action={action} />
            )}
            {menu && <EntityCardMenu menu={menu} disabled={!allowDelete} />}
          </>
        }
      />
      {confirmationDialog}
    </>
  );
}

const Button = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
