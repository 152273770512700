import React, { useContext, useEffect, useMemo, useState } from 'react';

import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { FeatureToggleKind } from 'client/app/gql';
import { FeatureTogglesContext } from 'common/features/FeatureTogglesContext';
import { featureList, FeatureName, isEnabled } from 'common/features/featureTogglesForUI';
import { indexBy } from 'common/lib/data';
import { mapObject } from 'common/object';

type Props = {
  isVisible: boolean;
  onChange: (changesRequireReload: boolean) => void;
  isInternalTooling: boolean;
} & WithStyles<typeof styles>;

type AllTogglesStates = Record<FeatureName, boolean>;
function getAllTogglesStates(): AllTogglesStates {
  return mapObject(featureList, (name, _) => isEnabled(name)) as AllTogglesStates;
}

function FeatureTogglesList(props: Props) {
  const featureToggles = useContext(FeatureTogglesContext);
  const featureList = featureToggles.loadFromSession();
  const [featureNames, currentFeaturesByName] = useMemo(() => {
    if (!featureList) {
      return [null, null];
    }
    const currentFeatureList = props.isInternalTooling
      ? featureList.filter(feature => feature.kind === FeatureToggleKind.INTERNAL)
      : featureList.filter(feature => feature.kind === FeatureToggleKind.UPCOMING);

    const currentFeaturesByName = indexBy(currentFeatureList, 'name');
    const featureNames = Object.keys(currentFeaturesByName).sort() as FeatureName[];

    return [featureNames, currentFeaturesByName];
  }, [featureList, props.isInternalTooling]);

  // Capture the initial states of all toggles ...
  const [initialStates, setInitialStates] = useState<AllTogglesStates>(
    getAllTogglesStates(),
  );
  // And update whenever the list is shown (e.g. the dialog opens)
  useEffect(() => {
    if (props.isVisible) {
      setInitialStates(getAllTogglesStates());
    }
  }, [props.isVisible]);

  const listItems = useMemo(() => {
    if (!featureNames || !currentFeaturesByName) {
      return null;
    }

    return featureNames.map(featureName => {
      const isEnabled = featureToggles.isEnabled(featureName);
      const feature = currentFeaturesByName[featureName];
      return (
        <ListItem
          button
          key={`feature-${featureName}`}
          onClick={() => {
            featureToggles.setEnabled(featureName, !isEnabled);
            const changesRequireReload = featureNames.some(
              name =>
                currentFeaturesByName[name].requiresReload &&
                featureToggles.isEnabled(name) !== initialStates[name],
            );
            props.onChange(changesRequireReload);
          }}
        >
          <ListItemIcon>
            {isEnabled ? (
              <ToggleOnIcon className={props.classes.onIcon} />
            ) : (
              <ToggleOffIcon className={props.classes.offIcon} />
            )}
          </ListItemIcon>
          <ListItemText primary={featureName} secondary={feature.description} />
        </ListItem>
      );
    });
  }, [currentFeaturesByName, featureNames, featureToggles, initialStates, props]);

  if (!listItems || listItems.length === 0) {
    return (
      <Typography variant="body2">
        Could not load feature toggles. Please reload the page.
      </Typography>
    );
  }

  return <List>{listItems}</List>;
}

const styles = createStyles({
  onIcon: {
    color: '#3caf1a',
  },
  offIcon: {
    color: '#d7412d',
  },
});

export default withStyles(styles)(FeatureTogglesList);
