import { useCallback, useEffect, useRef } from 'react';

import useThrottle from 'common/ui/hooks/useThrottle';

// Returns true if we are less than one full height away from reaching the bottom edge.
// If the current `clientHeight` is 0, we assume the element is hidden and return false.
function hasScrolledCloseToBottom(scrollingEl?: HTMLDivElement) {
  if (!scrollingEl) {
    return false;
  }

  /*
      ---------     -                     -
      .       .     ▲                     ▲
      .       .     | scrollTop           |
      .       .     ▼                     |
      ---------     -                     |
      |visible|     ▲                     |   scrollHeight
      |       |     |  clientHeight       |
      |       |     ▼                     |
      ---------     -                     |
      .       .     ▲                     |
      .       .     |  ?(x < clientHeight)|
      .       .     ▼                     ▼
      ---------     -                     -

  */
  const oneFullSize = scrollingEl.clientHeight;
  return (
    oneFullSize > 0 && scrollingEl.scrollTop + 2 * oneFullSize >= scrollingEl.scrollHeight
  );
}

type Props = {
  isLoading: boolean;
  canFetchMore: boolean;
  nextPage: () => void;
  throttleLimit?: number;
  dependencies?: any[];
};
type PropsWithRef = Props & { elRef: React.RefObject<HTMLDivElement> };

/**
 * Calls `nextPage` every time we scroll close to the bottom of an element.
 * The returned ref should be passed to the element that will scroll.
 * An optional `dependencies` array is used to re-fetch more data when
 * your filtering conditions changed, for example.
 */
export function useInfiniteScroll(props: Props) {
  const elRef = useRef<HTMLDivElement>(null);
  useInfiniteScrollWithRef({ ...props, elRef });
  return elRef;
}

/**
 * Calls `nextPage` every time we scroll close to the bottom of an element.
 * The `elRef` should be the `ref` you set on the element that will scroll.
 * An optional `dependencies` array is used to re-fetch more data when
 * your filtering conditions changed, for example.
 */
export function useInfiniteScrollWithRef({
  isLoading,
  canFetchMore,
  nextPage,
  throttleLimit = 150,
  dependencies = [],
  elRef,
}: PropsWithRef) {
  const maybeFetchMore = useCallback(() => {
    if (
      canFetchMore &&
      !isLoading &&
      elRef.current &&
      hasScrolledCloseToBottom(elRef.current)
    ) {
      nextPage();
    }
  }, [canFetchMore, elRef, isLoading, nextPage]);

  const throttledOnScroll = useThrottle(
    useCallback(() => {
      maybeFetchMore();
    }, [maybeFetchMore]),
    throttleLimit,
    // When scrolling fast, we could reach the bottom of the element after the last edge,
    // without firing another scroll event. That way we would never fetch the next page.
    // To make sure we always fetch the next page when getting close to the bottom,
    // we trigger on leading edge to feel responsive, but also one more time on the
    // trailing edge just in case.
    /*
     a, b, c and d are scroll events
     `|` are throttle edges (tick)
     Let's say at a, b, c we would not want to fetch. but at d* we would like.
      |        |        |
      a    b    c   d*

      trailing (bad: it does not feel immediate):
               b         d*
      leading (bad, d* is omitted):
      a         c
      trailing+leading
      a         c          d*
    */
    { trailing: true, leading: true },
  );

  useEffect(() => {
    // we keep a reference so we are able to do the cleanup
    const thisEl = elRef.current;
    if (thisEl) {
      thisEl.addEventListener('scroll', throttledOnScroll);
    }
    return () => {
      if (thisEl) {
        thisEl.removeEventListener('scroll', throttledOnScroll);
      }
    };
  }, [elRef, throttledOnScroll]);

  useEffect(
    () => {
      if (!isLoading) {
        maybeFetchMore();
      }
    },

    // We want dynamic dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, maybeFetchMore, ...dependencies],
  );

  return elRef;
}
