import { getFirstValue } from 'common/object';
import { MoveLabwareAction, MoveLabwareEffect } from 'common/types/mix';
import { MixPreviewStep } from 'common/types/mixPreview';
import {
  DeckItemState,
  MoveLabwareEdge,
} from 'common/ui/components/simulation-details/mix/MixState';

/** Physically move plates on deck to new locations. */
export default function moveLabware(
  deckItems: readonly DeckItemState[],
  moveLabwareEffects: readonly MoveLabwareEffect[],
  currentStep: number,
): {
  updatedDeckItems: readonly DeckItemState[];
  edges: MoveLabwareEdge[];
} {
  const updatedDeckItems = [...deckItems];
  const edges: MoveLabwareEdge[] = [];

  const fromDeckPositionNames = new Set<string>();
  const toDeckPositionNames = new Set<string>();
  const deckItemTypes = new Set<DeckItemState['kind']>();

  for (const effect of moveLabwareEffects) {
    const indexOfDeckItemToUpdate = updatedDeckItems.findIndex(
      deckItem => deckItem.name === effect.plate_id,
    );

    if (indexOfDeckItemToUpdate === -1) {
      // This shouldn't be possible but might occur if actions.json is wrong.
      console.warn(
        'move_plate action is trying to move a non existent deck item: ' +
          effect.plate_id,
      );
      continue;
    }

    const itemBeforeUpdate = updatedDeckItems[indexOfDeckItemToUpdate];

    fromDeckPositionNames.add(itemBeforeUpdate.currentDeckPositionName);
    toDeckPositionNames.add(effect.move_to);
    deckItemTypes.add(itemBeforeUpdate.kind);

    updatedDeckItems[indexOfDeckItemToUpdate] = {
      ...itemBeforeUpdate,
      currentDeckPositionName: effect.move_to,
      currentRotationDegrees: effect.rotation,
    };
  }

  if (fromDeckPositionNames.size !== 1 || toDeckPositionNames.size !== 1) {
    console.warn('Expected exactly one source and destination for move_plate action');
  }

  // Create an edge (arrow in the preview) to describe labware movement
  edges.push({
    type: 'move_plate',
    stepNumber: currentStep,
    fromDeckPositionName: getFirstValue(fromDeckPositionNames)!,
    toDeckPositionName: getFirstValue(toDeckPositionNames)!,
    deckItemTypes: [...deckItemTypes],
  });

  return { updatedDeckItems, edges };
}

export function isLabwareMovement(step: MixPreviewStep): step is MoveLabwareAction {
  return step.kind === 'move_plate';
}
