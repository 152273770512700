import React, { RefObject, useCallback, useRef, useState } from 'react';

import { Position2d } from 'common/types/Position';
import useThrottle from 'common/ui/hooks/useThrottle';
import { isLeftMouseClick, isLeftMousePressed } from 'common/ui/lib/ClickRecognizer';

export type DraggableProps = {
  // ReactRef reference to a DOM element to contain movement of the draggable component
  boundaryRef?: RefObject<HTMLElement>;
};

function clearSelection() {
  // Prevent text selection while we're dragging things around
  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
  }
}

export default function useDraggable<E extends HTMLElement>({
  onDragStart,
  onDrag,
  onDrop,
  boundaryRef,
}: {
  onDragStart?: (e: React.PointerEvent<E>) => void;
  onDrag?: (delta: Position2d) => void;
  onDrop?: (delta: Position2d) => void;
  boundaryRef?: RefObject<HTMLElement>;
}) {
  const [isDragging, setIsDragging] = useState(false);

  const start = useRef<Position2d>({ x: 0, y: 0 });
  const delta = useRef<Position2d>({ x: 0, y: 0 });

  const onPointerMove = useThrottle(
    useCallback(
      (e: React.PointerEvent<E>) => {
        if (!isDragging) {
          return;
        }

        // In case pointer capture failed somehow, check if the user still has the mouse
        // button held down, and stop dragging if not.
        if (isLeftMouseClick(e)) {
          e.currentTarget.releasePointerCapture(e.pointerId);
          setIsDragging(false);
          return;
        }

        if (boundaryRef) {
          // Pause dragging while not over the viewport
          const target = document.elementFromPoint(e.clientX, e.clientY);
          if (!boundaryRef.current?.contains(target as Element)) {
            return;
          }
        }

        e.preventDefault();
        e.stopPropagation();

        clearSelection();

        const newDelta = {
          x: e.clientX - start.current.x,
          y: e.clientY - start.current.y,
        };

        delta.current = newDelta;

        onDrag?.(newDelta);
      },
      [boundaryRef, isDragging, onDrag],
    ),
    50,
    { leading: true },
  );

  const onPointerUp = useCallback(() => {
    if (isDragging) {
      setIsDragging(false);
      onDrop?.(delta.current);
    }
  }, [isDragging, onDrop]);

  const onPointerDown = useCallback(
    (e: React.PointerEvent<E>) => {
      // Only start dragging if the user is pressing the left mouse buton.
      if (!isLeftMousePressed(e)) {
        return;
      }

      e.stopPropagation();

      // Redirect all pointer events to this element. This capture should be released
      // automatically when the pointer up event is fired.
      e.currentTarget.setPointerCapture(e.pointerId);

      clearSelection();

      setIsDragging(true);
      delta.current = { x: 0, y: 0 };
      start.current = {
        x: e.clientX,
        y: e.clientY,
      };

      onDragStart?.(e);
    },
    [onDragStart],
  );

  return {
    isDragging,
    onPointerDown,
    onPointerMove,
    onPointerUp,
  };
}
