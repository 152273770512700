export enum SharedEntityType {
  WORK_TREE = 'WORK_TREE',
  EXPERIMENT = 'EXPERIMENT',
  WORKFLOW = 'WORKFLOW',
  PROTOCOL_INSTANCE = 'PROTOCOL_INSTANCE',
  CHERRY_PICKER = 'CHERRY_PICKER',
  FORM = 'FORM',
  SIMULATION = 'SIMULATION',
  EXECUTION = 'EXECUTION',
  DATASET_DERIVATION = 'DATASET_DERIVATION', // Bioprocessing
  VISUALISATION = 'VISUALISATION', // old data apps (DataVisualisation) robocolumns, elisa, etc.
  VIS = 'VIS', // All methods. Prepare data, Response Analysis, etc.
}
