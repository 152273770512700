import { useCallback } from 'react';

import { useDownloadJSON, useList, useSearch } from 'client/app/api/filetree';
import { ListResponse } from 'common/types/filetree';

export const FILE = 'FILE';
export const DIRECTORY = 'DIRECTORY';

// Not the actual file (bytes), but a description of the file.
export type FileMetadata = {
  children: FileMetadata[]; // Can be non-empty if this is a directory
  contentType: string;
  modified: string; // ISO 8601 date
  name: string;
  size: number;
  tags: string[];
  type: 'FILE' | 'DIRECTORY';
  ftl: string;
  // appserver attempts to add title to filetree info for devices and jobs
  title?: string;
};

type PathDetails = { type: string };

// Not the actual file (bytes), but a description of the file.
// Sadly, the signed URLs generated by Cloud Storage (Google's S3)
// are short lived and have to be requested on demand just when needed.
// That's why this response type is different from the FileMetadata above.
export type FileMetadataWithUrl = {
  contentType: string;
  listing: {
    path_details: PathDetails[];
  };
  modified: string; // ISO 8601 date
  name: string;
  // Short-lived URL that can be used to download the file
  signed_url: string;
  size: number;
  tags: string[];
  type: 'FILE' | 'DIRECTORY';
  // If this comes from filetree we have a filetree path
  ftl?: string;
};

type SearchQuery = {
  start?: string;
  end?: string;
  [key: string]: any | undefined;
};

/**
 * Checks whether the query contains filters for search  (currently date range).
 * It is used to know whether we use the filetree /search endpoint instead of /list
 */
function isSearchQuery(query: SearchQuery = {}) {
  const { start, end } = query;
  return start && end;
}
export function useFetchOrSearch() {
  const list = useList();
  const search = useSearch();
  return useCallback(
    (path: string, query?: SearchQuery) => {
      if (query && isSearchQuery(query)) {
        return filetreeSearchForBrowser(search, path, query);
      }
      return filetreeListForBrowser(list, path, query);
    },
    [list, search],
  );
}

export function useFetchFileStructure() {
  const list = useList();
  return useCallback(
    async (
      path: string,
    ): Promise<{
      resp: FileMetadata[];
      nextCursor?: string;
    }> => {
      const { resp, nextCursor } = await filetreeListForBrowser(list, path);
      if (resp?.listing?.path_details) {
        const results = resp.listing.path_details as FileMetadata[];
        // We only support FILE and DIRECTORY. That's what the API returns.
        // If the API ever returns other types in the future, we can add support
        // for rendering those too.
        return {
          resp: results.filter(f => f.type === FILE || f.type === DIRECTORY),
          nextCursor,
        };
      } else {
        throw Error(`Cannot fetch files at path ${path}`);
      }
    },
    [list],
  );
}

export function useFetchSingleFile() {
  const list = useList();
  return useCallback(
    async (path: string): Promise<{ nextCursor?: string; resp: FileMetadataWithUrl }> => {
      return filetreeListForBrowser(list, path);
    },
    [list],
  );
}

// Fetch a file from filetree and parse its JSON contents.
export const useFetchJsonFileContents = useDownloadJSON;

/** Internal top level directories to be excluded from the FileBrowser view */
const EXCLUDED_TOP_LEVEL_DIRECTORIES = [
  'parameters',
  'parameterDefaults',
  'parameterTemplates',
];

/**
 *
 * We transform the response format to match what FileBrowser and co. expect.
 *  - type must be upper case to satisfy existing code comparison with `FILE` | `DIRECTORY`
 *    (FilesApi.DIRECTORY, FilesApi.FILE)
 *  - the target details must not be nested, but at first level of object
 *  - children are in listing.path_details
 */
function massageFiletreeResponse(path: string, resp: ListResponse) {
  // Empty path means we're at the top level of the file tree
  const isTopLevel = !path;
  const pathDetails = (
    isTopLevel
      ? resp.items.filter(item => !EXCLUDED_TOP_LEVEL_DIRECTORIES.includes(item.name))
      : resp.items
  ).map((item: { type: string }) => ({
    ...item,
    type: item.type.toUpperCase(),
  }));
  return {
    resp: {
      ...resp.linkDetails,
      listing: {
        path_details: pathDetails,
      },
      type: resp.linkDetails.type.toUpperCase(),
      size: resp.linkDetails.sizeBytes,
      modified: resp.linkDetails.creationTime,
      signed_url: '',
      tags: [],
    },
    nextCursor: resp.nextCursor,
  } as {
    nextCursor?: string;
    resp: FileMetadataWithUrl;
  };
}

/**
 * Use the Filetree API (list) to fetch contents for the file browser UI.
 */
async function filetreeListForBrowser(
  list: ReturnType<typeof useList>,
  path: string,
  query: SearchQuery = {},
) {
  const { cursor } = query;
  path = (path ?? '/').replace(/^\//, '');
  const resp = await list(path, cursor);
  return massageFiletreeResponse(path, resp);
}

/**
 * Use the Filetree API (search) to fetch search results for the file browser UI.
 */

async function filetreeSearchForBrowser(
  search: ReturnType<typeof useSearch>,
  path: string,
  query: SearchQuery,
) {
  const { cursor, start, end, type: contentType } = query;
  path = (path ?? '/').replace(/^\//, '');
  const options = {
    cursor,
    ...(cursor ? {} : { order: 'newest' }), // if we have a cursor, order is forbidden
    ...(start ? { from: start } : {}),
    ...(end ? { until: end } : {}),
    ...(contentType ? { contentType } : {}),
  };
  const resp = await search(path, options);
  return massageFiletreeResponse(path, resp);
}
