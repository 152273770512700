import React, { useCallback, useContext, useMemo, useState } from 'react';

import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import {
  DOEParameterConversion,
  getDOEParameterConversion,
} from 'client/app/components/Parameters/doeTemplateUtils';
import ElementParameterGroupHeader from 'client/app/components/Parameters/ElementParameterGroupHeader';
import ParameterHeader from 'client/app/components/Parameters/ElementParameterHeader';
import ParameterEditor, {
  ParameterEditorHelperText,
} from 'client/app/components/Parameters/ParameterEditor';
import {
  PlateContentParams,
  useFilterPlateContentParameters,
} from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import { PLATE_BASED_MIXING_PARAMETER } from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import {
  ParameterState,
  ParameterStateRuleResult,
} from 'client/app/lib/rules/elementConfiguration/evaluateParameterState';
import { ParameterStateContext } from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import { getParameterDisplayName } from 'client/app/lib/workflow/elementConfigUtils';
import { ConnectionMaskDict } from 'client/app/lib/workflow/types';
import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import {
  AdditionalEditorProps,
  ArrayAdditionalProps,
} from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import {
  getDefaultEditorForAnthaType,
  sanitiseParameterValue,
} from 'common/elementConfiguration/parameterUtils';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { groupBy } from 'common/lib/data';
import {
  Parameter,
  ParameterValue,
  ParameterValueDict,
  WorkflowConfig,
} from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import ConnectionOnlyEditor from 'common/ui/components/ParameterEditors/ConnectionOnlyEditor';
import Switch from 'common/ui/components/Switch';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type ParamChangeCallback = (
  paramName: string,
  value: ParameterValueDict,
  instanceName?: string,
) => void;

/**
 * Props needed at the InstanceParameter level that need to be passed in at the
 * ElementParameterGroupList level. Used by every component in this file.
 */
type GeneralProps = {
  onChange: ParamChangeCallback;
  onPendingChange?: ParamChangeCallback;
  elementId: string;
  DOETemplateMode?: boolean;
  /** Required by the PlateReaderProtocol editors. */
  workflowId?: string;
  /** Required by Table UI */
  workflowName?: string;
  workflowConfig: WorkflowConfig;
  instanceName: string;
  /** Used to determine which parameters have values provided through connections */
  connectionMaskDict?: ConnectionMaskDict;
  isDisabled?: boolean;
  defaultParameters: ParameterValueDict;
};

type Props = GeneralProps & {
  /** A list of parameters (information about the parameters, not values) */
  parameters: readonly Parameter[];
  /** A map of parameter names to to their values */
  parameterValueDict: ParameterValueDict;
  /** Evaluation results from the Element Configuration rule engine */
  ParameterStateRuleResult?: ParameterStateRuleResult;
  showValidation?: boolean;
};

function isParameterSet(parameterValue: ParameterValue) {
  return parameterValue !== null && parameterValue !== undefined;
}

export default function ElementParameterGroupList(props: Props) {
  const { areAllGroupParametersHidden } = useContext(ParameterStateContext);

  const groupedParams = useMemo(
    () =>
      Object.entries(
        groupBy(
          props.parameters
            // Remove any deprecated parameters that don't have values set.
            // We don't want them to factor into the logic behind whether groups
            // are hidden or not.
            .filter(
              parameter =>
                !(parameter.configuration?.isVisible === false) ||
                isParameterSet(props.parameterValueDict[parameter.name]),
            )
            .map(parameter => ({
              ...parameter,
              groupName: parameter.groupName ?? '',
            })),
          'groupName',
        ),
      ),
    [props.parameterValueDict, props.parameters],
  );

  const areParametersUngrouped = groupedParams.length === 1 && groupedParams[0][0] === '';

  return (
    <>
      {groupedParams
        .filter(
          ([groupName, _]) =>
            areParametersUngrouped ||
            !areAllGroupParametersHidden(props.instanceName, groupName),
        )
        .map(([groupName, parametersInGroup]) => {
          const onlyGroup = groupedParams.length === 1;
          const groupProps = {
            ...props,
            groupName,
            parameters: parametersInGroup,
            onlyGroup,
          };
          return (
            <ElementParameterGroup
              workflowId={props.workflowId}
              workflowName={props.workflowName}
              key={`param-group-${groupName}`}
              {...groupProps}
              isDisabled={props.isDisabled}
              DOETemplateMode={props.DOETemplateMode}
            />
          );
        })}
    </>
  );
}

type ElementParameterGroupProps = Props & {
  groupName: string;
  onlyGroup: boolean;
};

const ElementParameterGroup = React.memo(function ElementParameterGroup(
  props: ElementParameterGroupProps,
) {
  const classes = useStyles();
  const description = props.parameters[0]?.groupDescription;
  return (
    <>
      <ElementParameterGroupHeader name={props.groupName} onlyGroup={props.onlyGroup} />
      <div className={cx({ [classes.paramGroupInputs]: !!props.groupName })}>
        {description && (
          <div className={classes.parameterGroupDescription}>{description}</div>
        )}
        <ElementParameterList {...props} parameters={props.parameters} />
      </div>
    </>
  );
});

type ElementParameterListProps = Props;

export const ElementParameterList = React.memo(function ElementParameterList(
  props: ElementParameterListProps,
) {
  const { parameters, parameterValueDict, ...instanceParameterProps } = props;
  const { getStateForParameter } = useContext(ParameterStateContext);

  // We filter out some parameters that are managed by the plate contents editor
  const { plateContentParams, plateParameterFilter } = useFilterPlateContentParameters(
    [...props.parameters],
    props.DOETemplateMode,
  );

  return (
    <>
      {parameters.filter(plateParameterFilter).map(parameter => (
        <HideableInstanceParameter
          key={parameter.name}
          parameter={parameter}
          // This is important: never pass valueDict that contains values for all parameters to
          // the component for a single parameter. That will force all parameter editors to rerender
          // when only single parameter value changes
          paramValue={parameterValueDict[parameter.name]}
          paramState={getStateForParameter(props.instanceName, parameter.name)}
          parameterValueDict={parameterValueDict}
          plateContentParams={plateContentParams}
          {...instanceParameterProps}
        />
      ))}
    </>
  );
});

type InstanceParameterProps = GeneralProps &
  Pick<Props, 'parameterValueDict' | 'showValidation'> & {
    parameter: Parameter;
    paramValue: ParameterValue;
    /** Properties of the parameter calculated by the rules engine. */
    paramState: ParameterState | undefined;
    workflowConfig: WorkflowConfig;
    plateContentParams?: PlateContentParams;
  };

const HideableInstanceParameter = React.memo(function HideableInstanceParameter(
  props: InstanceParameterProps,
) {
  const classes = useStyles();
  const { paramState, parameter } = props;

  const isPlateBasedMixing = useFeatureToggle('PLATE_BASED_MIXING');
  let isVisible = paramState?.isVisible ?? true;
  if (parameter.name === PLATE_BASED_MIXING_PARAMETER) {
    isVisible = isVisible && isPlateBasedMixing;
  }

  return (
    <Collapse in={isVisible}>
      <div className={classes.parameterContainer} key={`param-${parameter.name}`}>
        <InstanceParameter {...props} />
      </div>
    </Collapse>
  );
});

export function InstanceParameter({
  elementId,
  paramState,
  onChange,
  instanceName,
  onPendingChange,
  DOETemplateMode,
  parameter,
  paramValue,
  showValidation,
  ...props
}: InstanceParameterProps) {
  const classes = useStyles();

  const isElementConfigDebugModeEnabled = useFeatureToggle(
    'ELEMENT_CONFIGURATION_DEBUG_MODE',
  );
  const isEnabledElementParameterValidation = useFeatureToggle('PARAMETER_VALIDATION');

  /**
   * When props.DOETemplateMode is true, there is a possibility to use
   * a different editor for certain parameter types. Eligible types are
   * identified via getDOEParameterConversion which returns a
   * DOEParameterConversion object, if it the parameter is eligible for
   * DOE. Otherwise it returns undefined. The object holds methods to
   * perform the required type conversions.
   *
   * WARNING:
   * This is used by the DOE Template functionality for DOE Alpha
   */
  let doeConversion: DOEParameterConversion | undefined;
  if (DOETemplateMode) {
    // Leave undefined if not in DOE Template Mode
    doeConversion = getDOEParameterConversion(parameter.type, parameter.name);
  }

  /**
   * Where a parameter is eligible for DOE, a parameter-specific
   * boolean state doeEditing determines whether DOE editing is
   * enabled. Typically there are many eligible parameters in a
   * workflow and the user will only want to enable editing for some of
   * them. A switch allows the setting to be toggled. The initial value
   * is true if DOE-specific settings are detected in the parameter
   * value.
   */
  const [doeEditing, setDOEEditing] = useState(
    () => !!doeConversion?.hasMarkup(paramValue),
  );

  const anthaType =
    doeEditing && doeConversion ? doeConversion.alternativeParameterType : parameter.type;
  const value =
    doeEditing && doeConversion ? doeConversion.convert(paramValue) : paramValue;

  /**
   * WARNING:
   * This is used by the DOE Template functionality for DOE Alpha
   */
  const onChangeWithSanitise = useCallback(
    newParamValue => {
      const sanitisedNewValue = sanitiseParameterValue(newParamValue);
      const newValue =
        doeEditing && doeConversion
          ? doeConversion.restore(paramValue, sanitisedNewValue)
          : sanitisedNewValue;
      onChange(parameter.name, newValue, instanceName);
    },
    [doeConversion, doeEditing, instanceName, onChange, paramValue, parameter.name],
  );

  /**
   * WARNING:
   * This is used by the DOE Template functionality for DOE Alpha
   */
  const onPendingChangeWithSanitise = useCallback(
    newParamValue => {
      const sanitisedNewValue = sanitiseParameterValue(newParamValue);
      const newValue =
        doeEditing && doeConversion
          ? doeConversion.restore(paramValue, sanitisedNewValue)
          : sanitisedNewValue;
      onPendingChange?.(parameter.name, newValue, instanceName);
    },
    [
      doeConversion,
      doeEditing,
      instanceName,
      onPendingChange,
      paramValue,
      parameter.name,
    ],
  );

  // The value of the parameter is stored here when toggling on DOE, so that
  // when toggling it back off the original value can be restored. TODO: the
  // original value should be persisted in PostgreSQL so that if a user exits
  // and re-enters this DOE template, they can still restore the original values
  // (T3406).
  const [originalValue, setOriginalValue] = useState();

  /**
   * WARNING:
   * This is used by the DOE Template functionality for DOE Alpha
   */
  const onParameterDOESwitch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let newParamValue;
      if (e.target.checked) {
        // The old value will be erased when toggling DOE on, so store the value
        // so it can be restored if the user toggles it back off.
        setOriginalValue(paramValue);
        newParamValue = doeConversion?.default_(true);
      } else {
        // Use the original value, if there is one. Otherwise use the default
        // value for this type.
        newParamValue = originalValue || doeConversion?.default_(false);
      }
      onChange(parameter.name, newParamValue, instanceName);
      setDOEEditing(e.target.checked);
    },
    [doeConversion, paramValue, onChange, parameter.name, originalValue, instanceName],
  );

  const displayName = getParameterDisplayName(parameter, isElementConfigDebugModeEnabled);

  // If DOE Editing, then we should ignore the type set by element
  // configuration. The type should always be Array.
  const editorType = !doeEditing
    ? parameter.configuration?.editor.type
    : getDefaultEditorForAnthaType(anthaType);

  const placeholder = parameter.configuration?.editor.placeholder;

  // If an output from another element instance provides this parameter with a value
  // already, this will be a string label showing which output port that is.
  const outputProvidingValue = props.connectionMaskDict?.[parameter.name];

  const editorDisabled = paramState?.isEnabled === false || props.isDisabled;

  const editorProps = useEditorProps(
    parameter,
    props.plateContentParams,
    instanceName,
    props.parameterValueDict,
  );

  let helperText: ParameterEditorHelperText | undefined = undefined;
  if (paramState?.errorMessages?.[0]) {
    helperText = { type: 'error', message: paramState.errorMessages[0] };
  } else if (paramState?.warningMessages?.[0]) {
    helperText = { type: 'warning', message: paramState.warningMessages[0] };
  }

  return (
    <>
      <div
        className={cx({
          [classes.DOETemplateMode]: doeConversion,
        })}
      >
        <ParameterHeader
          elementId={elementId}
          name={parameter.name}
          displayName={displayName}
          isRequired={paramState?.isRequired}
          isValid={
            isEnabledElementParameterValidation && showValidation
              ? paramState?.isValid
              : true
          }
        />
        {parameter.configuration?.shortDescription && (
          <Typography variant="caption" className={classes.description}>
            {parameter.configuration?.shortDescription}
          </Typography>
        )}
      </div>
      {doeConversion && (
        <FormControlLabel
          control={
            <Switch
              checked={doeEditing}
              onChange={onParameterDOESwitch}
              color="primary"
            />
          }
          label=""
        />
      )}
      {!outputProvidingValue ? (
        <ParameterEditor
          isDisabled={editorDisabled}
          anthaType={anthaType}
          parameter={parameter}
          value={value}
          workflowId={props.workflowId}
          workflowName={props.workflowName}
          onChange={onChangeWithSanitise}
          onPendingChange={onPendingChangeWithSanitise}
          instanceName={instanceName}
          editorType={editorType}
          editorProps={editorProps ?? undefined}
          workflowConfig={props.workflowConfig}
          placeholder={placeholder}
          helperText={helperText}
        />
      ) : (
        <ConnectionOnlyEditor isDisabled value={outputProvidingValue} />
      )}
    </>
  );
}

/**
 * Returns the editor props for the given parameter.
 *
 * We handle two use cases specific to EditorType.PLATE_CONTENTS_SUMMARY and
 * EditorType.PLATE_LAYOUT_LAYERS, where we have to build some additional
 * custom props to deal with the functionality of these, and those props are
 * not supplied by element configurations.
 *
 */
function useEditorProps(
  parameter: Parameter,
  plateContentParams: PlateContentParams | undefined,
  instanceName: string,
  parameterValueDict: ParameterValueDict,
): AdditionalEditorProps | null | undefined {
  const dispatch = useWorkflowBuilderDispatch();

  const handleDeletePlateContentParams = useCallback(
    (index: number) => {
      if (!plateContentParams) {
        return;
      }
      const allPlateNames = plateContentParams?.plateNameParam
        ? parameterValueDict[plateContentParams?.plateNameParam?.name]
        : [];
      if (allPlateNames[index]) {
        dispatch({
          type: 'deletePlateContentsEditorParameters',
          payload: {
            instanceName,
            plateContentParams,
            selectedPlateName: allPlateNames[index],
          },
        });
      }
    },
    [dispatch, instanceName, parameterValueDict, plateContentParams],
  );

  // For EditorType.PLATE_CONTENTS_SUMMARY we pass down a custom handler
  // that controls the deletion of plate entries, as well as passing confirmDelete as true.
  // We know we are dealing with the correct parameter by using the plateContentParams
  // props and checking the type.
  if (parameter.type === plateContentParams?.plateNameParam?.type) {
    return {
      editor: EditorType.ARRAY,
      itemEditor: {
        type: EditorType.PLATE_CONTENTS_SUMMARY,
      },
      onItemDelete: handleDeletePlateContentParams,
      confirmDeletion: true,
    } as ArrayAdditionalProps;
  }

  // For EditorType.PLATE_LAYOUT_LAYERS we want to set confirmDelete as true.
  if (
    parameter.configuration?.editor.type === EditorType.ARRAY &&
    parameter.configuration.editor.additionalProps
  ) {
    const arrayAdditionalProps = parameter.configuration.editor
      .additionalProps as ArrayAdditionalProps;
    if (arrayAdditionalProps.itemEditor?.type === EditorType.PLATE_LAYOUT_LAYERS) {
      return {
        ...arrayAdditionalProps,
        confirmDeletion: true,
      };
    }
  }

  return parameter.configuration?.editor.additionalProps;
}

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  parameterContainer: {
    marginTop: '12px',
  },
  paramGroupInputs: {
    borderLeft: `2px solid ${palette.info.dark}`,
    paddingLeft: '8px',
  },
  DOETemplateMode: {
    backgroundColor: Colors.LIGHT_BLUE,
  },
  description: {
    display: 'block',
    paddingBottom: spacing(2),
    color: Colors.TEXT_SECONDARY,
  },
  parameterGroupDescription: {
    backgroundColor: Colors.BLUE_5,
    color: palette.text.primary,

    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,

    borderRadius: '4px',
    padding: spacing(2, 3),
    marginTop: spacing(2),
  },
}));
