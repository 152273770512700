import React, { useCallback, useMemo } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import { TextFieldProps } from '@mui/material/TextField';

import { getFactorName } from 'client/app/components/DOEBuilder/factorUtils';
import { FactorItem } from 'common/types/bundle';
import TextField from 'common/ui/filaments/TextField';

type Props = {
  value: FactorItem['sourceFactor'];
  factors: FactorItem[];
  isReadonly: boolean;
  errorMsg?: string;
  onChange: (sourceFactor: FactorItem['sourceFactor'], levelsToMapCount: number) => void;
};

export default function FactorDeriveSelect({
  value: sourceFactor,
  factors,
  isReadonly,
  errorMsg,
  onChange: updateSourceFactor,
}: Props) {
  const sourceFactorOptions = useMemo(
    () => factors.map(f => ({ label: getFactorName(f), value: f.id })) ?? [],
    [factors],
  );
  const selectedOption = sourceFactorOptions.find(
    option => option.value === sourceFactor?.id,
  );
  const handleChange = useCallback(
    (_, option) => {
      const sourceFactorItem = factors.find(f => f.id === option.value);
      if (!sourceFactorItem) {
        throw new Error(
          `[FactorDeriveSelect]: could not find factor with ID:${option.value}`,
        );
      }
      updateSourceFactor(
        {
          id: option.value,
          valueMap: {}, // reset level mapping if user selected a different factor to derive from
        },
        sourceFactorItem.values.length,
      );
    },
    [factors, updateSourceFactor],
  );

  return (
    <Autocomplete
      autoSelect
      disableClearable
      disabled={isReadonly}
      options={sourceFactorOptions}
      getOptionLabel={(option: { label: string }) => option.label}
      value={selectedOption}
      onChange={handleChange}
      renderInput={(params: TextFieldProps) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            placeholder: 'Select factor',
          }}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errorMsg}
          helperText={errorMsg}
        />
      )}
    />
  );
}
