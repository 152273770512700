import React, { useCallback } from 'react';

import { useQuery } from '@apollo/client';

import { GraphQLDevice } from 'client/app/api/deviceFromGraphql';
import { QUERY_DEVICES_SELECTABLE_AS_ACCESSIBLE } from 'client/app/api/gql/queries';
import DeviceLibrary from 'client/app/components/DeviceLibrary/DeviceLibrary';
import { DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  title: string;
} & DialogProps<GraphQLDevice | null>;

/**
 * Allow to pick one accessible device, directly on click.
 * No device configuration, no confirm button.
 */
export default function AccessibleDeviceSelectDialog(props: Props) {
  const { onClose, title, isOpen } = props;
  const { loading, error, data, refetch } = useQuery(
    QUERY_DEVICES_SELECTABLE_AS_ACCESSIBLE,
  );
  const devices = data?.devicesSelectableAsAccessible ?? [];

  const handleCancel = useCallback(() => {
    onClose(null);
  }, [onClose]);

  const handleSelectDevice = (id: string) => {
    const device = devices.find(d => d.id === id) as DeviceCommon;
    if (device) {
      onClose(device);
    }
  };

  if (error) {
    return <GraphQLErrorPanel error={error} onRetry={refetch} />;
  }
  return (
    <ComplexActionDialog
      title={title ?? 'Select Devices'}
      isOpen={isOpen}
      onClose={handleCancel}
      showCloseButton
      fullHeight
      content={
        <DeviceLibrary
          isLoading={loading}
          onSelect={handleSelectDevice}
          devices={devices}
          selectedDeviceIds={[]}
          smallCard
          dialog
        />
      }
    />
  );
}
