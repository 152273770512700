import React from 'react';

import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import Dropdown, { Option } from 'common/ui/filaments/Dropdown';

// The list here needs to be kept in sync with the AnthaStandardLibrary
// Package doe/doe.go. Look for `type MergeOption`
//
// For grep-ability, here are some references doe.mergeOption and doeMergeOption.

const allOptions: Option<string>[] = [
  {
    label: 'UsePositionOfLastFactorInFactorList (Default)',
    value: 'UsePositionOfLastFactorInFactorList',
  },
  {
    label: 'MoveToFront',
    value: 'MoveToFront',
  },
  {
    label: 'MoveToBack',
    value: 'MoveToBack',
  },
];

type Props = {
  onChange: (value?: string) => void;
} & ParameterEditorBaseProps<string>;

const DOEMergeOptionDropdownEditor = function (props: Props) {
  return (
    <Dropdown
      valueLabel={props.value ?? 'UsePositionOfLastFactorInFactorList'}
      options={allOptions}
      onChange={props.onChange}
      isDisabled={props.isDisabled}
      isRequired={props.isRequired}
    />
  );
};

export default DOEMergeOptionDropdownEditor;
