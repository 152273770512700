import React, { useCallback, useContext, useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { usePlatesByType } from 'client/app/api/PlateTypesApi';
import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import ResinCards, {
  SMALL_LAPTOP_RESOLUTION,
} from 'client/app/components/Parameters/FiltrationPlateLayout/components/ResinCards';
import { useFilterPlateEditorState } from 'client/app/components/Parameters/FiltrationPlateLayout/lib/editorState';
import { useReadonly } from 'client/app/components/Parameters/FiltrationPlateLayout/lib/parameterUtils';
import toWellLocationsOnDeckItem from 'client/app/components/Parameters/WellSelector/toWellLocationsOnDeckItem';
import WellSelector from 'client/app/components/WellSelector/WellSelector';
import {
  formatWellPosition,
  getColumnNumberFromWellPosition,
  getRowNumberFromWellPosition,
} from 'common/lib/format';
import { WellContents } from 'common/types/mix';
import { PlateType } from 'common/types/plateType';
import Button from 'common/ui/components/Button';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { getDeckItemStates } from 'common/ui/components/simulation-details/mix/deckContents';
import { getLayoutForWellSelector } from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import { WellLabelContent } from 'common/ui/components/simulation-details/mix/WellLabel';
import makeWellSelector from 'common/ui/components/simulation-details/PlateTransform';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const NO_WELLS_SELECTED: string[] = [];

export default function WellRegionSelection() {
  const { screenId } = useContext(ScreenContext);
  const classes = useStyles();
  const {
    state: { isEditing, selectedWells },
    setSelectedWells,
  } = useFilterPlateEditorState();

  const { plate, plateState, deckLayout, liquidColors } = usePlateConfiguration();
  const { handleSelectAllClick } = useSelectAll(plate);
  const isReadonly = useReadonly();

  const getContentLabel = useCallback(
    (_well: string, contents?: WellContents) =>
      ({
        type: 'ContentLabel',
        heading: contents ? `${(contents as FilterPlateWellContents).selectionNum}` : ``,
      } as WellLabelContent),
    [],
  );

  if (!plate) return null;

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <section className={classes.selectWellsRow}>
        <Typography className={classes.wellSelectionCopy} variant="body1">
          {selectedWells.length === 0 ? (
            'Select wells below to assign resin, or select all'
          ) : (
            <>
              <span className={classes.selectWellsCount}>{selectedWells.length}</span>
              {selectedWells.length === 1 ? 'well' : 'wells'} selected for assignment
            </>
          )}
        </Typography>
      </section>
      <section className={classes.mainContentBox}>
        <div className={classes.wellSelection}>
          <Button
            className={classes.selectAllBtn}
            variant="secondary"
            onClick={handleSelectAllClick}
            disabled={isEditing || isReadonly}
          >
            {selectedWells.length > 0 ? 'Clear Selection' : 'Select All'}
          </Button>
          <WellSelector
            plate={plateState}
            deckLayout={deckLayout}
            liquidColors={liquidColors}
            googleAnalyticsCategory={screenId as string}
            isDisabled={isEditing || isReadonly}
            wellSelectionProps={{
              selectedWells: toWellLocationsOnDeckItem(selectedWells, plateState),
              onSelectWells: newSelectedWells =>
                setSelectedWells(newSelectedWells.map(formatWellPosition)),
            }}
            getContentLabel={getContentLabel}
            showContentLabels
          />
        </div>
        <ResinCards liquidColors={liquidColors} />
      </section>
    </Box>
  );
}

type FilterPlateWellContents = WellContents & { selectionNum: number };

type FilterPlateContentsMatrix = {
  [column: number]: { [row: number]: FilterPlateWellContents };
};

function usePlateConfiguration() {
  const {
    state: { plateType, wellRegions },
  } = useFilterPlateEditorState();
  const [platesByType] = usePlatesByType();

  return useMemo(() => {
    const plate = platesByType[plateType as string];

    if (!plate) return { plate: null };

    const contents: FilterPlateContentsMatrix = {};
    for (const region of wellRegions) {
      region.wells.forEach((wellLocation, index) => {
        const col = getColumnNumberFromWellPosition(wellLocation);
        const row = getRowNumberFromWellPosition(wellLocation);
        if (!contents[col]) {
          contents[col] = {};
        }
        contents[col][row] = {
          kind: 'filtration_plate_matrix_summary',
          id: region.id,
          name: region.resinName,
          color: region.resinColor,
          total_volume: {
            value: +region.resinVolume,
            unit: 'ul',
          },
          tags: region.metaItems.map(metaItem => ({
            label: metaItem.key,
            value_string: metaItem.value,
          })),
          selectionNum: index + 1,
        };
      });
    }
    const plateState: PlateState = { ...makeWellSelector(plate), contents };
    const deckLayout = getLayoutForWellSelector(plateState);
    const liquidColors = LiquidColors.createUsingColorGraph(
      getDeckItemStates(deckLayout.deck),
    );

    return { plate, plateState, deckLayout, liquidColors };
  }, [plateType, platesByType, wellRegions]);
}

function useSelectAll(plate: PlateType | null) {
  const {
    state: { selectedWells },
    setSelectedWells,
  } = useFilterPlateEditorState();

  const handleSelectAllClick = () => {
    if (!plate) return;

    let newWellRegion: string[];

    if (selectedWells.length > 0) {
      newWellRegion = NO_WELLS_SELECTED;
    } else {
      const allWells = [];
      for (let row = 0; row < plate.rows; row++) {
        for (let column = 0; column < plate.columns; column++) {
          allWells.push(formatWellPosition(row, column));
        }
      }
      newWellRegion = allWells;
    }

    setSelectedWells(newWellRegion);
  };
  return { handleSelectAllClick };
}

const useStyles = makeStylesHook(({ spacing, breakpoints }) => ({
  selectWellsRow: {
    padding: spacing(4),
  },
  selectWellsCount: {
    fontWeight: 700,
    marginRight: spacing(2),
  },
  selectAllBtn: {
    padding: spacing(1, 3),
    marginBottom: spacing(3),
    width: '130px', // fixed width to make sure button is not jumping around when text changes
  },
  wellSelectionCopy: {
    userSelect: 'none',
  },
  mainContentBox: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: spacing(3),
    height: '1px', // this is necessary for the child overflow to scroll and not grow
    paddingBottom: '40px',
  },
  wellSelection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [breakpoints.down(SMALL_LAPTOP_RESOLUTION)]: {
      flex: 3,
    },
  },
}));
