import { useState } from 'react';

import { v4 as uuid } from 'uuid';

/**
 * Helper hook to do a lazy initialization of a UUID for a particular component
 * to guarantee that an ID is only created once.
 *
 * Sample usage: const uuid = useUUID();
 */
export default function useUUID() {
  const [id] = useState(() => uuid());
  return id;
}
