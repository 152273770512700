import React from 'react';

import Menu, { MenuProps } from '@mui/material/Menu';

type MenuWithArrowProps = {
  /**
   * Used to move the arrow to the right, in order to
   * align it with pixel precision.
   */
  arrowLeftOffsetPx?: number;
  arrowRightOffsetPx?: number;
};

/** Small wrapper around MUI Menu which adds an arrow to the pop up menu. */
export default function MenuWithArrow({
  arrowLeftOffsetPx,
  arrowRightOffsetPx,
  children,
  ...muiProps
}: MenuProps & MenuWithArrowProps) {
  return (
    <Menu
      sx={{ marginTop: `${ICON_HEIGHT_PX}px` }}
      PaperProps={{
        sx: {
          overflow: 'visible',
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 2,
            left: arrowLeftOffsetPx,
            right: arrowRightOffsetPx,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      {...muiProps}
    >
      {children}
    </Menu>
  );
}

// The actual height of the icon (including no margin/padding)
const ICON_HEIGHT_PX = 5;
