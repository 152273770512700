import React from 'react';
import { memo, useMemo } from 'react';

import { styled } from '@mui/material/styles';

import { GroupsByStep } from 'client/app/apps/simulation-details/instructions/types';
import { Step } from 'common/types/steps';
import Colors from 'common/ui/Colors';
import { useTrackActive } from 'common/ui/components/TrackActive';

type StageNode = {
  key: number;
  type: 'stage';
  name: string;
  stageId: number;
  children: (GroupNode | StepNode | ElementNode | RepeatNode)[];
};

type GroupNode = {
  key: number;
  type: 'group';
  name: string;
  groupId: number;
  children: (StepNode | ElementNode | RepeatNode)[];
};

type StepNode = { key: number; type: 'step'; name: string; step: Step };

type ElementNode = {
  key: number;
  type: 'element';
  name: string;
  elementId: number;
  children: (StepNode | RepeatNode)[];
};

type RepeatNode = {
  key: number;
  id: number;
  type: 'repeat';
  name: string;
  children: StepNode[];
};

type TOCNode = StageNode | GroupNode | ElementNode | RepeatNode | StepNode;

type Props = { className?: string; steps: Step[]; groups: GroupsByStep };

export const TableOfContents = memo(function TableOfContents({
  steps,
  groups,
  className,
}: Props) {
  const grouped = useMemo(() => {
    const result: TOCNode[] = [];
    let key = 1;

    let currentStage: StageNode | null = null;
    let currentGroup: GroupNode | null = null;
    let currentElement: ElementNode | null = null;
    let currentRepeat: RepeatNode | null = null;
    for (const step of steps) {
      const stage = groups[step.id]?.stage;
      const group = groups[step.id]?.group;
      const element = groups[step.id]?.element;
      const repeat = groups[step.id]?.repeat;

      if (!currentStage || currentStage.stageId !== stage?.id) {
        if (stage) {
          currentStage = {
            key: key++,
            type: 'stage',
            stageId: stage.id,
            name: stage.name,
            children: [],
          };

          result.push(currentStage);
        } else currentStage = null;
      }

      if (!currentGroup || currentGroup.groupId !== group?.id) {
        if (group) {
          currentGroup = {
            key: key++,
            type: 'group',
            groupId: group.id,
            name: group.name,
            children: [],
          };

          if (currentStage) {
            currentStage.children.push(currentGroup);
          } else {
            result.push(currentGroup);
          }
        } else currentGroup = null;
      }

      if (!currentElement || currentElement.elementId !== element?.id) {
        if (element) {
          currentElement = {
            key: key++,
            type: 'element',
            elementId: element.id,
            name: element.name,
            children: [],
          };

          if (currentGroup) {
            currentGroup.children.push(currentElement);
          } else if (currentStage) {
            currentStage.children.push(currentElement);
          } else {
            result.push(currentElement);
          }
        }
      }

      if (!currentRepeat || currentRepeat.id !== repeat?.id) {
        if (repeat) {
          currentRepeat = {
            key: key++,
            type: 'repeat',
            id: repeat.id,
            name: repeat.name,
            children: [],
          };

          if (currentElement) {
            currentElement.children.push(currentRepeat);
          } else if (currentGroup) {
            currentGroup.children.push(currentRepeat);
          } else if (currentStage) {
            currentStage.children.push(currentRepeat);
          } else {
            result.push(currentRepeat);
          }
        } else currentRepeat = null;
      }

      const stepNode: StepNode = {
        key: key++,
        type: 'step',
        name: step.name,
        step,
      };

      (
        currentRepeat?.children ??
        currentElement?.children ??
        currentGroup?.children ??
        currentStage?.children ??
        result
      ).push(stepNode);
    }

    return result;
  }, [groups, steps]);

  return (
    <ContentsWrapper className={className}>
      <TOCItems>
        {grouped.map(node => (
          <TOCNode node={node} key={node.key} />
        ))}
      </TOCItems>
    </ContentsWrapper>
  );
});

function TOCNode({ node }: { node: TOCNode }) {
  const { activeId, showById } = useTrackActive();

  switch (node.type) {
    case 'element': {
      if (node.children.length === 1) {
        const child = node.children[0];
        return <TOCNode node={child} key={child.key} />;
      }
      return (
        <NodeListItem>
          <NodeName>{node.name}</NodeName>
          <NodeList>
            {node.children.map(child => (
              <TOCNode node={child} key={child.key} />
            ))}
          </NodeList>
        </NodeListItem>
      );
    }
    case 'step':
      return (
        <StepListItem
          key={node.key}
          active={node.step.id === activeId}
          className={node.step.id === activeId ? 'active-step' : undefined}
          onClick={() => {
            if (node.step.id) {
              showById(node.step.id);
            }
          }}
        >
          <StepName>{node.name}</StepName>
        </StepListItem>
      );

    default:
      return (
        <NodeListItem>
          <NodeName>{node.name}</NodeName>
          <NodeList>
            {node.children.map(child => (
              <TOCNode node={child} key={child.key} />
            ))}
          </NodeList>
        </NodeListItem>
      );
  }
}

const ContentsWrapper = styled('nav')(({ theme: { typography, spacing } }) => ({
  position: 'relative',
  overflow: 'auto',
  padding: spacing(6, 4, 6, 6),
  ...typography.body1,
}));

const NodeList = styled('ol')({
  padding: 0,
  margin: 0,
  listStylePosition: 'inside',
});

const TOCItems = styled('ol')({
  padding: 0,
  margin: 0,
  width: '15vw',
  display: 'flex',
  flexDirection: 'column',
  counterReset: 'toc',
});

const NodeListItem = styled('li')(({ theme: { spacing, palette } }) => ({
  padding: spacing(3, 2, 3, 4),
  color: palette.text.primary,
  borderLeft: `4px solid ${Colors.BLUE_5}`,
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(3),
  '&:has(.active-step)': {
    borderLeft: `4px solid ${Colors.BLUE_10}`,
  },
}));

const StepListItem = styled(NodeListItem, {
  shouldForwardProp: propName => propName !== 'active',
})<{ active: boolean }>(({ theme: { palette }, active }) => ({
  ...(active
    ? {
        background: Colors.BLUE_5,
        color: palette.text.primary,
        borderLeft: `4px solid ${Colors.BLUE_10}`,
      }
    : {
        color: palette.text.secondary,
        cursor: 'pointer',
        borderLeft: `4px solid ${Colors.BLUE_5}`,
      }),
  '&:hover': {
    color: palette.text.primary,
    borderLeftColor: Colors.BLUE_20,
  },
}));

const StepName = styled('div')(({ theme: { spacing } }) => ({
  listStyleType: 'none',
  counterIncrement: 'toc',
  '&::before': {
    content: `counter(toc) "."`,
    marginRight: spacing(2),
  },
}));

const NodeName = styled('div')(({ theme: { typography, palette } }) => ({
  ...typography.subtitle2,
  color: palette.text.primary,
  textWrap: 'balance',
}));
