import React, { useContext, useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { QUERY_WORK_TREE_APPLICATIONS } from 'client/app/apps/work-tree/queries';
import { WorkTreeModeContext } from 'client/app/apps/work-tree/WorkTreeModeContext';
import {
  ArrayElement,
  ExperimentWorkTreeQuery,
  WorkTreeApplicationName,
  WorkTreeApplicationsQuery,
  WorkTreeResultType,
} from 'client/app/gql';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { pluralizeWord } from 'common/lib/format';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import NestedMenuItem, {
  generateNestedMenu,
  NestedMenuItemType,
} from 'common/ui/components/NestedMenuItem/NestedMenuItem';
import { DataSetIcon } from 'common/ui/icons/DataSetIcon';

type Block = ArrayElement<
  NonNullable<ExperimentWorkTreeQuery['experimentWorkTree']>['blocks']
>;

type DataEntitiesMenuItemsProps = {
  visibleBlocks: readonly Block[];
  onHidePopover: () => void;
  isParentMenuOpen: boolean;
};

export default function DataEntitiesMenuItems(props: DataEntitiesMenuItemsProps) {
  const { visibleBlocks, onHidePopover, isParentMenuOpen } = props;

  const context = useContext(WorkTreeModeContext);

  type InputResultRequirements = WorkTreeApplication['inputResultRequirements'];

  const handleClickItem = (
    applicationName: WorkTreeApplicationName,
    version: string,
    inputResultRequirements: InputResultRequirements,
  ) => {
    onHidePopover();
    context.setModeCreatingBlock({
      applicationName,
      applicationVersion: version,
      inputResultRequirements,
    });
  };

  const availableApplicationsQuery = useQuery(QUERY_WORK_TREE_APPLICATIONS);
  let availableApplications = availableApplicationsQuery.data?.workTreeApplications || [];

  const featureToggles = {
    EXPERIMENT_MAP_BIOPROCESS: useFeatureToggle('EXPERIMENT_MAP_BIOPROCESS'),
    EXPERIMENT_MAP_LIQUID_GRAPH: useFeatureToggle('EXPERIMENT_MAP_LIQUID_GRAPH'),
    EXPERIMENT_MAP_NOTEBOOK: useFeatureToggle('EXPERIMENT_MAP_NOTEBOOK'),
    EXPERIMENT_MAP_ROBOCOLUMNS: useFeatureToggle('EXPERIMENT_MAP_ROBOCOLUMNS'),
    EXPERIMENT_MAP_WORKBOOK: useFeatureToggle('EXPERIMENT_MAP_WORKBOOK'),
    NEW_DOE: useFeatureToggle('NEW_DOE'),
  };

  // filter options that are not disabled via feature toggles
  availableApplications = availableApplications.filter(item => {
    switch (item.name) {
      case WorkTreeApplicationName.bp_bioprocess:
        return featureToggles['EXPERIMENT_MAP_BIOPROCESS'];
      case WorkTreeApplicationName.doe_analyse_responses:
      case WorkTreeApplicationName.doe_prepare_data:
        return featureToggles['NEW_DOE'];
      case WorkTreeApplicationName.lg_export:
      case WorkTreeApplicationName.lg_link_plates:
      case WorkTreeApplicationName.lg_load_bioprocess:
      case WorkTreeApplicationName.lg_load_execution:
        return featureToggles['EXPERIMENT_MAP_LIQUID_GRAPH'];
      case WorkTreeApplicationName.notebook:
        return featureToggles['EXPERIMENT_MAP_NOTEBOOK'];
      case WorkTreeApplicationName.rbc_analyse:
      case WorkTreeApplicationName.rbc_cherry_pick:
      case WorkTreeApplicationName.rbc_view:
        return featureToggles['EXPERIMENT_MAP_ROBOCOLUMNS'];
      case WorkTreeApplicationName.workbook:
        return featureToggles['EXPERIMENT_MAP_WORKBOOK'];
      default:
        return true;
    }
  });

  return (
    <NestedDataEntitiesMenuItemsContainer
      availableApplications={availableApplications}
      visibleBlocks={visibleBlocks}
      handleClickItem={handleClickItem}
      isParentMenuOpen={isParentMenuOpen}
    />
  );
}

type WorkTreeApplication = ArrayElement<
  WorkTreeApplicationsQuery['workTreeApplications']
>;

type InputResultRequirements = WorkTreeApplication['inputResultRequirements'];

type NestedDataEntitiesMenuItemsContainerProps = {
  availableApplications: readonly WorkTreeApplication[];
  visibleBlocks: readonly Block[];
  handleClickItem: (
    applicationName: WorkTreeApplicationName,
    version: string,
    inputResultRequirements: InputResultRequirements,
  ) => void;
  isParentMenuOpen: boolean;
};

function NestedDataEntitiesMenuItemsContainer(
  props: NestedDataEntitiesMenuItemsContainerProps,
) {
  const { availableApplications, visibleBlocks, handleClickItem, isParentMenuOpen } =
    props;
  const applicationErrors = getInapplicableApplications(
    availableApplications,
    visibleBlocks,
  );
  const nestedMenuItems = useMemo(() => {
    return generateNestedMenu([...availableApplications]);
  }, [availableApplications]);

  // Re-ordering for the front end.
  // We will show any top-level items first, alphabetically.
  // Then groups, alphabetically
  nestedMenuItems.sort((a, b) => {
    return (a.folder ?? '').localeCompare(b.folder ?? '');
  });

  return (
    <NestedDataEntitiesMenuItems
      items={nestedMenuItems}
      handleClickItem={handleClickItem}
      applicationErrors={applicationErrors}
      isParentMenuOpen={isParentMenuOpen}
    />
  );
}

type NestedDataEntitiesMenuItemsProps = Pick<
  NestedDataEntitiesMenuItemsContainerProps,
  'handleClickItem' | 'isParentMenuOpen'
> & {
  items: NestedMenuItemType<WorkTreeApplication>[];
  applicationErrors: Map<WorkTreeApplicationName, string | undefined>;
};

function NestedDataEntitiesMenuItems(props: NestedDataEntitiesMenuItemsProps) {
  const { items, applicationErrors, isParentMenuOpen, handleClickItem } = props;

  if (!items) {
    return null;
  }
  return (
    <>
      {items.map((item, idx) => {
        if (item.children) {
          return (
            <NestedMenuItem
              key={idx}
              isParentMenuOpen={isParentMenuOpen}
              label={item.folder || ''}
              leftIcon={<DataSetIcon />}
            >
              <NestedDataEntitiesMenuItems
                items={item.children}
                applicationErrors={applicationErrors}
                handleClickItem={handleClickItem}
                isParentMenuOpen={isParentMenuOpen}
              />
            </NestedMenuItem>
          );
        }

        if (!item.item) {
          return null;
        }

        const error = applicationErrors.get(item.item?.name);
        const itemName = item.item.name;
        const itemVersion = item.item.version;
        const itemInputRequirements = item.item.inputResultRequirements;

        return (
          <MenuItemWithIcon
            key={idx}
            icon={<DataSetIcon />}
            text={item.item?.applicationDisplayName || ''}
            disabled={!!error}
            onClick={() => handleClickItem(itemName, itemVersion, itemInputRequirements)}
            tooltipTitle={error ?? item.item?.description}
          />
        );
      })}
    </>
  );
}

/**
 * Checks if the given list of blocks satisfies the requirements of any of the
 * availableApplications. If they do not, we add an error message to the returned
 * map for that block name.
 */
function getInapplicableApplications(
  availableApplications: readonly WorkTreeApplication[],
  blocks: readonly Block[],
): Map<WorkTreeApplicationName, string | undefined> {
  return new Map(
    availableApplications.map(app => [
      app.name,
      checkApplicationInapplicable(app, blocks),
    ]),
  );
}

function checkApplicationInapplicable(
  application: WorkTreeApplication,
  blocks: readonly Block[],
): string | undefined {
  const existingBlocks = blocks.filter(
    block => block.result?.type === application.inputResultRequirements.resultType,
  );
  const visibleBlocksVersions = blocks
    .filter(block => !!block.data && block.data.versionData.length > 0)
    .flatMap(block => block.data?.versionData)
    .filter(
      versionBlock =>
        versionBlock?.result?.type === application.inputResultRequirements.resultType,
    );

  const minItems = application.inputResultRequirements.minItems;

  if (existingBlocks.length + visibleBlocksVersions.length < minItems) {
    return `Minimum of ${minItems} '${resultTypeCopy(
      application.inputResultRequirements.resultType,
    )}' ${pluralizeWord(minItems, 'block')} required to create '${
      application.applicationDisplayName
    }' block`;
  }
  return undefined;
}

/**
 * Return a readable copy of the WorkTreeResultType for presentation in the UI.
 */
function resultTypeCopy(resultType: WorkTreeResultType): string {
  switch (resultType) {
    case WorkTreeResultType.DOE_TEMPLATE:
      return 'DOE template';
    case WorkTreeResultType.UPLOADED_DATA:
      return 'Uploaded data';
    case WorkTreeResultType.PREPARED_DATA:
      return 'Prepared data';
    case WorkTreeResultType.RBC_STRUCTURED_DATA:
      return 'Robocolumns structured data';
    case WorkTreeResultType.LG_VIEW:
      return 'Liquid graph view';
    default: {
      const resultTypeName = resultType.toLowerCase();
      return resultTypeName.charAt(0).toUpperCase() + resultTypeName.slice(1);
    }
  }
}
