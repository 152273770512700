import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const AnnotateIcon = React.memo<SvgIconProps>(function AnnotateIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V12H18V20H6V4H12V2Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 1.5H17V4.5H14V6.5H17V9.5H19V6.5H22V4.5H19V1.5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
