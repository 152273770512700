import React from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import BreadcrumbsMenu from 'client/app/components/nav/breadcrumbs/components/BreadcrumbsMenu';
import { BreadcrumbsEntityDropdownProps } from 'client/app/components/nav/breadcrumbs/useBreadcrumbsEntity';
import stopPropagation from 'common/lib/stopPropagation';
import { usePopover } from 'common/ui/hooks/usePopover';

export default function ItemsDropdown(props: BreadcrumbsEntityDropdownProps) {
  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    stopPropagation(event);
    onShowPopover(event);
  };
  const handleAnyMenuClick = (event: React.MouseEvent) => {
    stopPropagation(event);
    onHidePopover();
  };

  if (!props.entity.EntityItem || props.node.items.length <= 1) {
    return null;
  }

  return (
    <>
      <ArrowIconButton
        data-heap-tracking="breadcrumbs-items-dropdown-button"
        onClick={handleClick}
      >
        <ArrowDropDownIcon />
      </ArrowIconButton>
      <BreadcrumbsMenu
        data-heap-tracking="breadcrumbs-items-dropdown-menu"
        anchorEl={popoverAnchorElement}
        open={isPopoverOpen}
        onClose={onHidePopover}
        onClick={handleAnyMenuClick}
        onDoubleClick={stopPropagation}
      >
        <NodeItems {...props} />
      </BreadcrumbsMenu>
    </>
  );
}

export function NodeItems({
  node,
  entity: { EntityItem, EntityIcon, navigateToEntity, openInNewTab, currentSubItem },
}: BreadcrumbsEntityDropdownProps) {
  if (!EntityItem) {
    return null;
  }

  return (
    <>
      {node.items.map(item => (
        <EntityItem
          key={item.id}
          item={item}
          icon={<EntityIcon />}
          onClick={() => navigateToEntity(item)}
          onAuxClick={() => openInNewTab(item)}
          isActive={item.id === currentSubItem?.id}
        />
      ))}
    </>
  );
}

const ArrowIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  color: theme.palette.text.primary,
}));
