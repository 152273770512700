import produce from 'immer';

import { EditorType } from 'common/elementConfiguration/EditorType';
import { ParameterRegistry } from 'common/elementConfiguration/ParameterRegistry';
import { getObjectFriendlyName, trimBeforeLastFullStop } from 'common/lib/format';
import { TypeConfigurationSpec, TypeName } from 'common/types/typeConfiguration';

const WELL_LOCATIONS_TYPES = [
  'github.com/Synthace/antha/stdlib/platepreferences.WellLocations',
  '[]github.com/Synthace/antha/stdlib/schemas/aliases.WellLocation',
];
/**
 * Returns a list of EditorTypes that are compatible with the given antha type.
 */
export function getEditorChoicesFromAnthaType(
  typeName: string,
  typeConfiguration?: TypeConfigurationSpec,
) {
  if (typeConfiguration) {
    return typeConfiguration.editorTypeOptions;
  }
  //TODO - Remove the ParameterRegistry once all type configs are in master
  const choices = ParameterRegistry[typeName];
  if (choices) {
    return choices;
  }
  const compoundType = getCompoundEditorTypeFromAnthaType(typeName);
  if (compoundType === EditorType.PLATE_CONTENTS) {
    return [EditorType.PLATE_CONTENTS, EditorType.MAP, EditorType.CONNECTION_ONLY];
  }
  return compoundType ? [compoundType, EditorType.CONNECTION_ONLY] : [];
}

/**
 * Returns the default EditorType for the given antha type.
 */
export function getDefaultEditorForAnthaType(
  typeName: string,
  typeConfiguration?: TypeConfigurationSpec,
) {
  return (
    //TODO - Remove the ParameterRegistry once all type configs are in master
    typeConfiguration?.defaultEditorConfiguration.type ??
    ParameterRegistry[typeName]?.[0] ??
    getCompoundEditorTypeFromAnthaType(typeName)
  );
}

export function isMapType(typeName: string) {
  return mapAliases[typeName] || typeName.startsWith('map[');
}

export function isArrayType(typeName: string) {
  return !!getArrayTypeFromAliasedAnthaType(typeName) || typeName.startsWith('[');
}

export function isCompoundType(typeName: string) {
  return !!getCompoundEditorTypeFromAnthaType(typeName);
}

/**
 * Returns the EditorType for the given antha type if it is a compound type (i.e. a map of
 * something or an array of something else).
 */
export function getCompoundEditorTypeFromAnthaType(typeName: string) {
  if (isMapType(typeName)) {
    // If the map values are an array of well locations, then the default editor should be
    // the plate contents editor.
    const isPlateContents = WELL_LOCATIONS_TYPES.includes(
      getValueTypeFromAnthaType(typeName),
    );
    // The plate contents editor can also be used when the well location map is nested,
    // e.g. map[PlateName]map[Something]WellLocations.
    const isNestedPlateContents =
      getCompoundEditorTypeFromAnthaType(getValueTypeFromAnthaType(typeName)) ===
      EditorType.PLATE_CONTENTS;
    if (isPlateContents || isNestedPlateContents) {
      return EditorType.PLATE_CONTENTS;
    }
    return EditorType.MAP;
  }

  if (isArrayType(typeName)) {
    return EditorType.ARRAY;
  }

  return undefined;
}

/** Returns a map of map alias types mapped to their true key and value types. */
export const mapAliases: {
  [overriddenTypeName: string]: [string, string];
} = {
  'github.com/Synthace/antha/antha/anthalib/wtype.Plates': [
    'github.com/Synthace/antha/stdlib/platepreferences.PlateName',
    '*github.com/Synthace/antha/antha/anthalib/wtype.Plate',
  ],
  'github.com/Synthace/antha/stdlib/platepreferences.PlateSpecificWellLocations': [
    'github.com/Synthace/antha/stdlib/platepreferences.PlateName',
    'github.com/Synthace/antha/stdlib/platepreferences.WellLocations',
  ],
  /** Note:
   This next entry is the Legacy format of PlateSpecificWellLocations;
   it still needs to be supported in the case of users openining old simulations prior to
  migration of elements from .an to .go.
  */
  'Synthace/stdlib/platepreferences.PlateSpecificWellLocations': [
    'github.com/Synthace/antha/stdlib/platepreferences.PlateName',
    'github.com/Synthace/antha/stdlib/platepreferences.WellLocations',
  ],
};

/**
 * There are two configurations of array types within Antha:
 * 1) arrays with a set length and 2) arrays of arbitrary length.
 * e.g. [2]string and []string
 *
 * It's also entirely possible to arrays of arrays.
 *  e.g. [][2]github.com/antha-lang/antha/antha/anthalib/wtype.DNASequence
 *
 * In this function we want to strip off the first array declaration at the
 * start of the type name. If it's an array of arrays, that will be handled
 * within the recursion.
 * */
export function getArrayTypeFromAnthaType(typeName: string): string {
  return getArrayTypeFromAliasedAnthaType(typeName) ?? typeName.replace(/^\[\d*\]/, '');
}

function getArrayTypeFromAliasedAnthaType(typeName: string) {
  if (typeName === 'github.com/Synthace/antha/antha/anthalib/wtype.Liquids') {
    return '*github.com/Synthace/antha/antha/anthalib/wtype.Liquid';
  }
  if (typeName === 'github.com/Synthace/antha/antha/anthalib/wtype.Plates') {
    return '*github.com/Synthace/antha/antha/anthalib/wtype.Plate';
  }
  return undefined;
}

// Grab all characters between [ and ]
const ANTHA_TYPE_FROM_MAP_KEY_REGEX = /^map\[([^\]]+)\]/;

/**
 * A map in Go theoretically can have any suitable key type. So, we need
 * to extract the key type from the type declaration in order to show the
 * correct editor for key values.
 *
 *   e.g. map[string]int => string
 *        map[github.com/antha-lang/antha/antha/anthalib/liquids.Name]int =>
 *            github.com/antha-lang/antha/antha/anthalib/liquids.Name
 */
export function getKeyTypeFromAnthaType(typeName: string): string {
  if (mapAliases[typeName]) {
    return mapAliases[typeName][0];
  }
  const match = typeName.match(ANTHA_TYPE_FROM_MAP_KEY_REGEX);
  if (!match) {
    throw new Error('Could not derive key type for map type ' + typeName);
  }

  return match[1];
}

/**
 * Maps in Go/Antha can have arbitrary value types. This means maps can have
 * maps or arrays as values, too.
 *
 *   e.g. map[string]int
 *        map[github.com/antha-lang/antha/antha/anthalib/liquids.Name]int
 *        map[string]map[string]int
 *        map[string][]github.com/antha-lang/antha/antha/anthalib/wtype.Plate
 *
 * In this function we want to strip off the left-most map declaration at the
 * start of the type name. The implementation of ParameterEditor will handle
 * recursively creating maps of maps. All this component needs to care about
 * is the key type and value type of the current map.
 */
export function getValueTypeFromAnthaType(typeName: string): string {
  if (mapAliases[typeName]) {
    return mapAliases[typeName][1];
  }
  return typeName.replace(ANTHA_TYPE_FROM_MAP_KEY_REGEX, '');
}

/** Dictionary of special case types with custom messages to be used as placeholder text
instead of the friendly form of the type name. */
const placeholderOverrides: {
  [typeName: string]: string;
} = {
  string: 'text...',
  '[]string': 'multiple lines of text...',
  int: 'integer...',
  float64: 'number...',
};

/** If a type is not in the list of typeBasedPlaceHolders then a string or empty then we'll try to extract a friendly representation of the type name.
Make strings such as element and parameter type names more human-readable:
 - removes prefix of a path prior to the last "."
 - replace underscores with spaces
For example "Synthace/stdlib/platepreferences.PlateName" would be returned as "Plate Name" */
export function getDefaultPlaceholder(typeName: string) {
  if (!placeholderOverrides[typeName]) {
    return getObjectFriendlyName(trimBeforeLastFullStop(typeName)) + '...';
  }
  return placeholderOverrides[typeName];
}

/** Resets empty strings to undefined, including those within compound types. */
export function sanitiseParameterValue(value: any): any {
  if (value === '') {
    return undefined;
  }

  return produce(value, (draft: any) => {
    if (draft instanceof Array) {
      draft.map(i => sanitiseParameterValue(i));
    } else if (draft instanceof Object) {
      Object.keys(draft).forEach(key => {
        draft[key] = sanitiseParameterValue(value[key]);
      });
    }
  });
}

/** Extract all non-compound types contained within the given antha type name. */
export function getConstituentBaseTypes(typeName: string): string[] {
  if (!isCompoundType(typeName)) {
    return [typeName];
  }
  if (isMapType(typeName)) {
    const key = getKeyTypeFromAnthaType(typeName);
    const value = getValueTypeFromAnthaType(typeName);
    const keyConstituentTypes = getConstituentBaseTypes(key);
    const valueConstituentTypes = getConstituentBaseTypes(value);
    return Array.from(new Set([...keyConstituentTypes, ...valueConstituentTypes]));
  }
  return getConstituentBaseTypes(getArrayTypeFromAnthaType(typeName));
}

/**
 * Checks if typeName is a valid compound type, and that its base types are
 * present in the given allTypeNames. We throw an error if the typeName cannot
 * be validated as a compound type.
 */
export function validateTypeNameForCompoundConfig(
  typeName: TypeName,
  allTypeNames: TypeName[],
) {
  if (typeName === '') {
    throw new Error('No type specified');
  }
  // getConstituentBaseTypes itself throws an error if typeName includes invalid map syntax.
  const baseTypes = getConstituentBaseTypes(typeName);

  // getConstituentBaseTypes returns the original type if no compound type is found
  // so if that's the case, typeName it must not be a valid compound type.
  if (baseTypes.length === 1 && baseTypes[0] === typeName) {
    throw new Error('This is not a compound type');
  }

  const missingTypes: string[] = [];
  baseTypes.forEach(type => {
    if (!allTypeNames.includes(type)) {
      missingTypes.push(type);
    }
  });

  if (missingTypes.length > 0) {
    throw new Error(
      `The following base types do not have existing type configs: ${missingTypes.join(
        ', ',
      )}. These are created automatically for types pushed to an Antha branch as part of an element, so make sure this is done first.`,
    );
  }
}
