import MuiVisibilityIcon from '@mui/icons-material/Visibility';
import MuiVisibilityOff from '@mui/icons-material/VisibilityOff';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { svgIconClasses } from '@mui/material/SvgIcon';
import MuiTableCell from '@mui/material/TableCell';

import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';

export const VisibilityIcon = styled(MuiVisibilityIcon, {
  shouldForwardProp: prop => prop !== 'color',
})<{
  color?: 'primary' | 'secondary' | 'error';
}>(({ theme, color = 'primary' }) => ({
  fontSize: 14,
  color:
    color === 'primary'
      ? theme.palette.text.primary
      : color === 'secondary'
      ? theme.palette.text.secondary
      : color === 'error'
      ? theme.palette.error.dark
      : undefined,
}));

export const NoVisibilityIcon = styled(MuiVisibilityOff, {
  shouldForwardProp: prop => prop !== 'color',
})<{
  color?: 'primary' | 'secondary' | 'error';
}>(({ theme, color = 'secondary' }) => ({
  fontSize: 14,
  color:
    color === 'primary'
      ? theme.palette.text.primary
      : color === 'secondary'
      ? theme.palette.text.secondary
      : color === 'error'
      ? theme.palette.error.dark
      : undefined,
}));

export const BlueChip = styled('button')<{ active?: boolean }>(({ theme, active }) => ({
  outline: 'none',
  border: 'none',

  borderRadius: '12px',
  whiteSpace: 'nowrap',
  cursor: 'pointer',

  padding: theme.spacing(1, 3),

  backgroundColor: active ? Colors.BLUE_80 : Colors.BLUE_5,
  color: active
    ? theme.palette.getContrastText(Colors.BLUE_80)
    : theme.palette.text.primary,

  '&:hover': {
    backgroundColor: Colors.BLUE_80,
    color: theme.palette.getContrastText(Colors.BLUE_80),
  },
}));

export const ChipRow = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
  width: '100%',
  maxHeight: '20px',
}));

export const ExpandButton = styled(IconButton)(({ theme }) => ({
  marginLeft: 'auto',
  color: theme.palette.text.primary,
  [`& > .${svgIconClasses.root}`]: {
    height: 16,
    width: 16,
  },
}));

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: theme.spacing(3, 3, 0),
  border: 'none',
}));
