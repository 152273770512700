import { ParameterValue, ParameterValueDict } from 'common/types/bundle';
/**
 * DOETemplateMode requires an alternative editor for certain types of
 * element parameter. Functions here identify when an alternative is
 * needed, based on the original parameter type, and returns an object
 * holding the alternative type. This is the type we want the editor to
 * have. It can be used to instantiate the appropriate ParameterEditor.
 * The object also holds conversion routines which essentially convert
 * data in a non-lossy way between the original and alternative types.
 *
 * Some examples:
 *
 * 1. Single maps.
 * Original type: map[MixtureName]Volume
 * Alternative type: []Volume
 * Here the original workflow allows a 'volume' to be specified
 * independently for different mixtures. In a DOE we want to generate
 * 96 (say) mixtures selecting one of two (say) different volumes:
 * 100ul and 200ul. So for the alternative editor, we don't want the
 * user to be entering MixtureName 96 times. We want them to enter
 * two volumes, and for the DOE Design Tool to work out the rest.
 *
 * 2. Double maps
 * Original type: map[MixtureName]map[MixComponent]Concentration
 * Alternative type: map[MixComponent][]Concentration
 * Here the original workflow allows 'components' to be specified
 * independently for different mixtures. In a DOE we want to generate
 * 96 (say) mixtures varying concentrations of three (say) mix
 * components: e.g. 1X and 2X for mix component A; 0.5X, 0.75X and
 * 1.0X for mix component B, etc. So for the alternative editor, we
 * want a map of mix component name to an array of concentration
 * values.  The user enters the mix component names and the list of
 * concentrations that the DOE should explore, independently for each
 * mix component.
 *
 * 3. Scalars
 * Original type: Temperature
 * Alternative type: []Temperature
 * Not all parameters that we might want to target are mapped.
 * Temperature is something often varied in a DOE but it is 'hard to
 * change' (because e.g. when you put a plate in the incubator, all
 * the samples on the plate get the same temperature; you can't vary
 * the temperature from sample to sample in the way you can with other
 * factors such as mix components; those are therefore 'easy to
 * change') so is usually set up as a single, scalar element
 * parameter. The DOE Design Tool can split designs onto different
 * plates or workflows, however. So here we want the alternative
 * editor to allow multiple values to be entered.
 */

/**
 * A structure to hold details of the alternative type and conversion
 * methods.
 */
export type DOEParameterConversion = {
  alternativeParameterType: string;
  // Convert original representation (ParameterValue or ParameterValueDict)
  // to alternative (ParameterValueDict or ParameterValue))
  convert: (
    value: ParameterValueDict | ParameterValue,
  ) => ParameterValueDict | ParameterValue;
  // Restore the original representation. Not all values need to be accessed
  // for DOE. These may be included via the oldValue input.
  restore: (
    oldValue: ParameterValueDict | ParameterValue,
    value: ParameterValueDict,
  ) => ParameterValueDict | ParameterValue;
  // If parameter value is templated (contains DOE markup).
  hasMarkup: (oldValue: ParameterValue) => boolean;
  // Default data to set to when DOE editing is toggled
  default_: (editing: boolean) => ParameterValue;
};

/**
 * A function which examines the parameterType and returns either
 * undefined if it's not supported for DOE, or an instance of the
 * object above with details.
 */
export function getDOEParameterConversion(
  parameterType: string,
  parameterName: string,
): DOEParameterConversion | undefined {
  // All mapped parameters are supported in DOE Template Mode. The type of
  // editor needed depends on the depth of the nested maps.
  const matches = parameterType.match(/map\[[^\]]+\]/g);

  // The leafType is the type of the lowest level map value. This is used
  // for defining the type of the alternative editor. The type of editor
  // may also depend on this.
  if (matches && parameterType.match(/^map\[[^\]]+\]map\[[^\]]+\]/)) {
    const leafType = parameterType.slice(matches[0].length + matches[1].length);
    return doubleMap(matches, leafType);
  }

  if (matches && parameterType.match(/^map\[[^\]]+\]/)) {
    const leafType = parameterType.slice(matches[0].length);
    return singleMap(leafType);
  }

  if (
    !parameterType.match(/^map/) &&
    (parameterType.endsWith('Temperature') ||
      (parameterType.endsWith('Time') && parameterName === 'Duration') ||
      (parameterType.endsWith('int') && parameterName === 'RotationsPerMinute'))
  ) {
    return scalarParameter(parameterType);
  }
  // If none of these criteria are met, return undefined. This should
  // be interpreted to mean: no conversion should be applied, or in
  // other words, the parameter is ineligible for DOE.

  return;
}

/**
 * A char used for marking up DOEable parameters in the DOE template
 * workflow, recognised by the DOE Design Tool.
 */
const DOE_MARK = '*';
const DOE_TAG_REGEX = /^\*+\s*\w+/; // One or more *, maybe some spaces, then some text ('tag')
const DOE_NO_TAG_REGEX = /^\*+$/; // One or more *, nothing after
const DOE_REGEX = /^\*+\s*/; // One or more *, then maybe some spaces

/**
 * A constant string with special meaning in many Antha elements.
 */
const MAP_DEFAULT = 'default';
const MAP_DEFAULT_REGEX = /^default\s*$/i;

/**
 * Single-level maps, the simplest case. In this case the alternative
 * editor is a simple array of leafType. This allows the user to enter
 * multiple values for exploration in the DOE. The values are restored
 * to the original using a system of prefixed, unique keys that are
 * intepreted by the DOE Design Tool.
 */
function singleMap(leafType: string): DOEParameterConversion {
  function matchKeys(value: ParameterValueDict): string[] {
    return Object.keys(value || {}).filter(key => DOE_NO_TAG_REGEX.test(key));
  }
  return {
    alternativeParameterType: '[]' + leafType,
    convert: function (value: ParameterValueDict) {
      return Object.values(matchKeys(value)).map(key => value[key]);
    },
    restore: function (oldValue: ParameterValueDict, value: ParameterValueDict) {
      const newValue: ParameterValueDict = { ...oldValue };
      Object.values(matchKeys(oldValue)).forEach(key => {
        delete newValue[key];
      });
      let key = DOE_MARK;
      Object.values(value || {}).forEach(val => {
        newValue[key] = val;
        key += DOE_MARK;
      });
      return newValue;
    },
    hasMarkup: function (oldValue: ParameterValue) {
      return matchKeys(oldValue).length > 0;
    },
    default_: function (editing: boolean) {
      return editing ? { [DOE_MARK]: null } : {};
    },
  };
}

/**
 * Double-level maps. The alternative editor replaces the double map
 * with a structure of type MixRules which holds four alternative
 * subtypes of data, corresponding to different specification cases.
 */
function doubleMap(matches: string[], leafType: string): DOEParameterConversion {
  const conversions: { [id: string]: DOEParameterConversion } = {
    // Type descriptions provided below with each function
    mapConstant: doubleMapConstantType(matches, leafType),
    mapSingleConstant: doubleMapSingleConstantType(matches, leafType),
    mapSingleVariable: doubleMapSingleVariableType(matches, leafType),
    mapMultiComponent: doubleMapMultiComponentType(matches, leafType),
  };
  return {
    alternativeParameterType: 'github.com/Synthace/antha/stdlib/doems.MixRules',
    convert: function (value: ParameterValueDict) {
      const newValue: ParameterValueDict = {};
      Object.entries(conversions).forEach(([key, conversion]) => {
        newValue[key] = {
          anthaType: conversion.alternativeParameterType,
          value: conversion.convert(value),
        };
      });
      return newValue;
    },
    restore: function (oldValue: ParameterValueDict, value: ParameterValueDict) {
      let newValue: ParameterValueDict = {};
      Object.entries(conversions).forEach(([key, conversion]) => {
        newValue = {
          ...conversion.restore(newValue, value[key]['value'] || {}),
        };
      });
      return newValue;
    },
    hasMarkup: function (oldValue: ParameterValue) {
      return Object.values(conversions).some(conversion =>
        conversion.hasMarkup(oldValue),
      );
    },
    default_: function (editing: boolean) {
      let newValue: ParameterValueDict = {};
      Object.values(conversions).forEach(conversion => {
        newValue = { ...newValue, ...conversion.default_(editing) };
      });
      return newValue;
    },
  };
}

/**
 * Double-level map, constant type. Alternative type is a simple
 * map. Each of the inner map key defines a factor with a fixed value.
 */
function doubleMapConstantType(
  matches: string[],
  leafType: string,
): DOEParameterConversion {
  function matchKeys(value: ParameterValueDict): string[][] {
    const keys: string[][] = [];
    Object.keys(value || {})
      .filter(key1 => MAP_DEFAULT_REGEX.test(key1))
      .forEach(key1 => {
        Object.keys(value[key1] || {})
          .filter(key2 => !DOE_REGEX.test(key2))
          .forEach(key2 => {
            keys.push([key1, key2]);
          });
      });
    return keys;
  }
  return {
    alternativeParameterType: matches[1] + leafType,
    convert: function (value: ParameterValueDict) {
      const newValue: ParameterValueDict = {};
      Object.values(matchKeys(value)).forEach(([key1, key2]) => {
        newValue[key2] = value[key1][key2];
      });
      return newValue;
    },
    restore: function (oldValue: ParameterValueDict, value: ParameterValueDict) {
      const newValue: ParameterValueDict = { ...oldValue };
      Object.values(matchKeys(oldValue)).forEach(([key1, key2]) => {
        delete newValue[key1][key2];
      });
      if (!(MAP_DEFAULT in newValue)) {
        newValue[MAP_DEFAULT] = {};
      }
      newValue[MAP_DEFAULT] = { ...newValue[MAP_DEFAULT], ...value };
      return newValue;
    },
    hasMarkup: function (oldValue: ParameterValue) {
      return MAP_DEFAULT in (oldValue || {});
    },
    default_: function (editing: boolean) {
      return editing ? { [MAP_DEFAULT]: null } : {};
    },
  };
}

/**
 * Double-level map, single component of constant type. Each key of
 * the inner map defines a new DOE factor. For factor has an array of
 * values, which are the allowed values for the DOE.
 */
function doubleMapSingleConstantType(
  matches: string[],
  leafType: string,
): DOEParameterConversion {
  function matchKeys(value: ParameterValueDict): string[][] {
    const keys: string[][] = [];
    Object.keys(value || {})
      .filter(key1 => MAP_DEFAULT_REGEX.test(key1))
      .forEach(key1 => {
        Object.keys(value[key1] || {})
          .filter(key2 => DOE_TAG_REGEX.test(key2))
          .forEach(key2 => {
            keys.push([key1, key2]);
          });
      });
    return keys;
  }
  return {
    alternativeParameterType: matches[1] + '[]' + leafType,
    convert: function (value: ParameterValueDict) {
      const newValue: ParameterValueDict = {};
      Object.values(matchKeys(value)).forEach(([key1, key2]) => {
        const key = key2.replace(DOE_REGEX, '');
        if (!(key in newValue)) {
          newValue[key] = [];
        }
        newValue[key].push(value[key1][key2]);
      });
      return newValue;
    },
    restore: function (oldValue: ParameterValueDict, value: ParameterValueDict) {
      const newValue: ParameterValueDict = { ...oldValue };
      Object.values(matchKeys(oldValue)).forEach(([key1, key2]) => {
        delete newValue[key1][key2];
      });
      if (!(MAP_DEFAULT in newValue)) {
        newValue[MAP_DEFAULT] = {};
      }
      Object.keys(value).forEach(key2 => {
        let key = DOE_MARK + ' ' + key2;
        newValue[MAP_DEFAULT][key] = null; // Ensure key exists
        Object.values(value[key2] || {}).forEach(val => {
          newValue[MAP_DEFAULT][key] = val;
          key = DOE_MARK + key;
        });
      });
      return newValue;
    },
    hasMarkup: function (oldValue: ParameterValue) {
      return matchKeys(oldValue).length > 0;
    },
    default_: function (_editing: boolean) {
      return {};
    },
  };
}

/**
 * Double-level map, single component of variable type. Each outer key
 * defines a separate DOE factor. The inner map defines alternative
 * alternative component/value pairs the factor.
 */
function doubleMapSingleVariableType(
  matches: string[],
  leafType: string,
): DOEParameterConversion {
  function matchKeys(value: ParameterValueDict): string[] {
    return Object.keys(value || {}).filter(key1 => DOE_TAG_REGEX.test(key1));
  }
  return {
    alternativeParameterType: matches[0] + matches[1] + leafType,
    convert: function (value: ParameterValueDict) {
      const newValue: ParameterValueDict = {};
      Object.values(matchKeys(value)).forEach(key => {
        newValue[key.replace(DOE_REGEX, '')] = value[key];
      });
      return newValue;
    },
    restore: function (oldValue: ParameterValueDict, value: ParameterValueDict) {
      const newValue: ParameterValueDict = { ...oldValue };
      Object.values(matchKeys(oldValue)).forEach(key => {
        delete newValue[key];
      });
      Object.keys(value).forEach(key => {
        newValue[DOE_MARK + ' ' + key] = value[key] || {};
      });
      return newValue;
    },
    hasMarkup: function (oldValue: ParameterValue) {
      return matchKeys(oldValue).length > 0;
    },
    default_: function (_editing: boolean) {
      return {};
    },
  };
}

/**
 * Double-level map, single multicomponent. This defines a single DOE
 * factor. The values are alternative maps of component-name/value.
 */
function doubleMapMultiComponentType(
  matches: string[],
  leafType: string,
): DOEParameterConversion {
  function matchKeys(value: ParameterValueDict): string[] {
    return Object.keys(value || {}).filter(key1 => DOE_NO_TAG_REGEX.test(key1));
  }
  return {
    alternativeParameterType: '[]' + matches[1] + leafType,
    convert: function (value: ParameterValueDict) {
      return Object.values(matchKeys(value)).map((key: string) => value[key]);
    },
    restore: function (oldValue: ParameterValueDict, value: ParameterValue) {
      const newValue: ParameterValueDict = { ...oldValue };
      Object.values(matchKeys(oldValue)).forEach(key => {
        delete newValue[key];
      });
      let key = DOE_MARK;
      Object.values(value).forEach(val => {
        newValue[key] = val;
        key += DOE_MARK;
      });
      return newValue;
    },
    hasMarkup: function (oldValue: ParameterValue) {
      return matchKeys(oldValue).length > 0;
    },
    default_: function (_editing: boolean) {
      return {};
    },
  };
}

/**
 * Scalar parameters. The parameter is replaced by an array of
 * leafType. This allows the user to enter multiple values for
 * exploration in the DOE. The values are restored into the scalar as
 * a prefixed CSV list.
 */
function scalarParameter(leafType: string): DOEParameterConversion {
  return {
    alternativeParameterType: '[]' + leafType,
    convert: function (value: ParameterValue): ParameterValueDict {
      if (typeof value == 'string') {
        return (value || '').replace(DOE_REGEX, '').split(',');
      }
      return [value];
    },
    restore: function (oldValue: ParameterValue, value: ParameterValueDict) {
      return DOE_MARK + ' ' + (value || []).join(',').replace(/\s+/, '');
    },
    hasMarkup: function (oldValue: ParameterValue) {
      return DOE_REGEX.test(oldValue);
    },
    default_: function (editing: boolean) {
      return editing ? DOE_MARK : null;
    },
  };
}
