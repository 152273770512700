import React, { useCallback, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import { useDeletePlateType } from 'client/app/api/PlateTypesApi';
import PlateConstructorDialog from 'client/app/apps/plate-constructor/PlateConstructorDialog';
import { PlateFormAction } from 'client/app/apps/plate-constructor/PlateForm';
import DeviceFilter from 'client/app/apps/plate-library/DeviceFilter';
import { trackPlateLibraryFilter } from 'client/app/apps/plate-library/PlateLibraryMetrics';
import useStageConfiguredDevice from 'client/app/apps/plate-library/useStageConfiguredDevice';
import { getContentSourceFilterOptions } from 'client/app/components/DeviceLibrary/types';
import PlatesGrid, {
  WellNumberEnum,
  WellVolumeRangeEnum,
} from 'client/app/components/Parameters/PlateType/PlatesGrid';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { ContentSourceType } from 'common/types/contentSource';
import { WellBottomShapeEnum } from 'common/types/plateType';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import { useDialogManager } from 'common/ui/components/DialogManager';
import Fab from 'common/ui/components/Fab';
import FilterChipWithCheckbox from 'common/ui/components/FilterChip/FilterChipWithCheckbox';
import { Option } from 'common/ui/components/FilterChip/FilterChipWithCheckbox';
import SearchField from 'common/ui/components/SearchField';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import useDialog from 'common/ui/hooks/useDialog';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

const DEFAULT_WELL_NUMBER_OPTIONS: Option<WellNumberEnum>[] = [
  { value: WellNumberEnum.WELL96, label: '96 well', selected: false },
  { value: WellNumberEnum.WELL384, label: '384 well', selected: false },
  { value: WellNumberEnum.WELL1536, label: '1536 well', selected: false },
  { value: WellNumberEnum.OTHER, label: 'Other', selected: false },
];

const DEFAULT_WELL_BOTTOM_SHAPE_OPTIONS: Option<WellBottomShapeEnum>[] = [
  { value: WellBottomShapeEnum.U_BOTTOM, label: 'U bottom', selected: false },
  { value: WellBottomShapeEnum.V_BOTTOM, label: 'V bottom', selected: false },
  { value: WellBottomShapeEnum.FLAT_BOTTOM, label: 'Flat bottom', selected: false },
];

const DEFAULT_WELL_VOLUME_RANGE_OPTIONS: Option<WellVolumeRangeEnum>[] = [
  { value: WellVolumeRangeEnum.VOLUME0_100UL, label: '0-100μl', selected: false },
  { value: WellVolumeRangeEnum.VOLUME100_500UL, label: '100-500μl', selected: false },
  { value: WellVolumeRangeEnum.VOLUME500_1000UL, label: '500-1000μl', selected: false },
  { value: WellVolumeRangeEnum.VOLUME1_2ML, label: '1-2ml', selected: false },
  { value: WellVolumeRangeEnum.VOLUME_GREATER_THAN_2ML, label: '>2ml', selected: false },
];

type Props = {
  children?: React.ReactChild;
  onSelect?: (plate: string) => void;
  onDoubleSelect?: (plate: string) => void;
  selectedPlate?: string;
  dialog?: boolean;
  /** Is this being opened from the workflow settings panel of the workflow builder? Defaults to false */
  isWorkflowSettingsPanel?: boolean;
};
export default function PlateLibrary(props: Props) {
  const {
    onSelect,
    onDoubleSelect,
    selectedPlate,
    isWorkflowSettingsPanel = false,
  } = props;
  const isPlateFilteringByDeviceEnabled = useFeatureToggle('PLATE_FILTERING_BY_DEVICE');
  const prePopulatedDeviceId = useStageConfiguredDevice(isWorkflowSettingsPanel);

  const [query, setQuery] = useState('');
  const [filterSource, setFilterSource] = useState('');
  const [deviceIds, setDeviceIds] = useState<string[]>(
    isPlateFilteringByDeviceEnabled && prePopulatedDeviceId ? [prePopulatedDeviceId] : [],
  );
  const [wellNumberFilter, setWellNumberFilter] = useState<Option<WellNumberEnum>[]>(
    DEFAULT_WELL_NUMBER_OPTIONS,
  );
  const [wellBottomShapeFilter, setWellBottomShapeFilter] = useState<
    Option<WellBottomShapeEnum>[]
  >(DEFAULT_WELL_BOTTOM_SHAPE_OPTIONS);
  const [wellVolumeRangeFilter, setWellVolumeRangeFilter] = useState<
    Option<WellVolumeRangeEnum>[]
  >(DEFAULT_WELL_VOLUME_RANGE_OPTIONS);

  const onChangeFilterQuery = useCallback((query: string) => {
    setQuery(query);
  }, []);

  const onChangeFilterSource = useCallback((source: string[]) => {
    const stringSource = source.toString();
    setFilterSource(stringSource);
  }, []);

  const location = props.dialog ? 'workflow-builder' : 'plate-type-inventory';

  const onChangeWellNumberFilter = (val: Option<WellNumberEnum>[]) => {
    setWellNumberFilter(val);
    trackPlateLibraryFilter('wellNumber', val, location);
  };

  const onChangeWellBottomShapeFilter = (val: Option<WellBottomShapeEnum>[]) => {
    setWellBottomShapeFilter(val);
    trackPlateLibraryFilter('wellBottomShape', val, location);
  };

  const onChangeWellVolumeRangeFilter = (val: Option<WellVolumeRangeEnum>[]) => {
    setWellVolumeRangeFilter(val);
    trackPlateLibraryFilter('wellVolumeRange', val, location);
  };

  const [contentSourceTypes, setContentSourceTypes] = useStateWithURLParams({
    paramName: 'type',
    paramType: 'string[]',
    defaultValue: [],
  });

  const onFilterContentSource = (newValue: Option<ContentSourceType>[]) => {
    const newSources = newValue
      .filter(option => option.selected)
      .map(option => option.label);
    setContentSourceTypes(newSources);
    onChangeFilterSource(newSources);
    trackPlateLibraryFilter('plateCreator', newValue, location);
  };

  const snackbarManager = useSnackbarManager();
  const dialogManager = useDialogManager();
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);

  const onSavePlate = useCallback(
    (plateName: string) => {
      snackbarManager.showSuccess(`"${plateName}" was saved successfully.`);
    },
    [snackbarManager],
  );

  const onPlateConstructor = useCallback(
    (action: PlateFormAction, selectedPlateId?: string) => {
      (async () => {
        await dialogManager.openDialogPromise(
          'PLATE_CONSTRUCTOR',
          PlateConstructorDialog,
          {
            isOpen: true,
            onSavePlate,
            selectedPlateId,
            action,
          },
        );
      })();
    },
    [dialogManager, onSavePlate],
  );

  const onCopyPlateType = useCallback(
    (selectedPlateId: string) =>
      onPlateConstructor(PlateFormAction.CREATE, selectedPlateId),
    [onPlateConstructor],
  );

  const onUpdatePlateType = useCallback(
    (selectedPlateId: string) =>
      onPlateConstructor(PlateFormAction.UPDATE, selectedPlateId),
    [onPlateConstructor],
  );

  const onFabClick = useCallback(
    () => onPlateConstructor(PlateFormAction.CREATE),
    [onPlateConstructor],
  );

  const deletePlateType = useDeletePlateType();
  const onDeletePlateType = useCallback(
    (plateName: string, plateType: string) => {
      (async () => {
        const deleteConfirmed = await openConfirmationDialog({
          action: 'delete',
          isActionDestructive: true,
          object: 'plate type',
          specificObject: `${plateName}`,
        });
        if (!deleteConfirmed) {
          return;
        }

        await deletePlateType(plateType);
      })();
    },
    [openConfirmationDialog, deletePlateType],
  );

  return (
    <ContainerWithIntersectionBar
      headerLeftContent={
        <>
          <FilterChipWithCheckbox
            heading="Filter by Creator"
            defaultChipLabel="Plate Creator"
            filterValue={getContentSourceFilterOptions(contentSourceTypes)}
            onFilter={onFilterContentSource}
          />
          {isPlateFilteringByDeviceEnabled ? (
            <DeviceFilter selectedIds={deviceIds} onChange={setDeviceIds} />
          ) : null}
          <FilterChipWithCheckbox
            heading="Filter by well number"
            defaultChipLabel="Well number"
            filterValue={wellNumberFilter}
            onFilter={onChangeWellNumberFilter}
          />
          <FilterChipWithCheckbox
            heading="Filter by well bottom shape"
            defaultChipLabel="Well bottom shape"
            filterValue={wellBottomShapeFilter}
            onFilter={onChangeWellBottomShapeFilter}
          />
          <FilterChipWithCheckbox
            heading="Filter by total well volume"
            defaultChipLabel="Well volume"
            filterValue={wellVolumeRangeFilter}
            onFilter={onChangeWellVolumeRangeFilter}
          />
        </>
      }
      headerRightContent={
        <SearchField
          placeholder="Search"
          onQueryChange={onChangeFilterQuery}
          autoFocus={!!props.dialog}
          addMargin
        />
      }
      content={
        <>
          <PlatesGrid
            onSelect={onSelect}
            onDoubleSelect={onDoubleSelect}
            query={query}
            filterSource={filterSource}
            wellBottomShapeFilter={wellBottomShapeFilter}
            wellNumberFilter={wellNumberFilter}
            wellVolumeRangeFilter={wellVolumeRangeFilter}
            selectedPlate={selectedPlate}
            deviceIds={deviceIds as DeviceId[]}
            plateMode={isWorkflowSettingsPanel ? 'input' : 'both'}
            onDeletePlateType={!props.dialog ? onDeletePlateType : undefined}
            onCopyPlateType={!props.dialog ? onCopyPlateType : undefined}
            onUpdatePlateType={!props.dialog ? onUpdatePlateType : undefined}
          />
          {!props.dialog && <Fab icon={<AddIcon />} onClick={onFabClick} />}
          {props.children}
          {confirmationDialog}
        </>
      }
    />
  );
}
