/*
 * Prior to SYN-996 (Aug 2022), the TipUsage type didn't contain the actual
 * name of the tip (which we want to display to the user), it only had the name
 * of the tip box.
 * Fortunately, these old simulations only had a fixed set of tip types
 * available, so we keep a fixed mapping from tipbox name to tip name for
 * those boxes
 */
export default new Map([
  ['DL10 Tip Rack (PIPETMAX 8x20)', 'Gilson 20ul tips'],
  ['D200 Tip Rack (PIPETMAX 8x200)', 'Gilson 200ul tips'],
  ['D200 Tip Rack (PIPETMAX 8x20)', 'Gilson 200ul tips for low volume'],
  ['DFL10 Tip Rack (PIPETMAX 8x20)', 'Gilson 10ul filter tips'],
  ['DF30 Tip Rack (PIPETMAX 8x20)', 'Gilson 30ul filter tips'],
  ['DF200 Tip Rack (PIPETMAX 8x200)', 'Gilson 200ul filter tips'],
  ['DF200 Tip Rack (PIPETMAX 8x200)', 'Gilson 200ul filter tips for low volume'],
  ['CyBio50Tipbox', 'CyBio 50ul tips'],
  ['CyBio250Tipbox', 'CyBio 250ul tips'],
  ['CyBio1000Tipbox', 'CyBio 1000ul tips'],
  ['DiTi 10uL LiHa', 'Tecan 10ul tips'],
  ['DiTi 50uL LiHa', 'Tecan 50ul tips'],
  ['DiTi 200uL LiHa', 'Tecan 200ul tips'],
  ['DiTi 1000uL LiHa', 'Tecan 1000ul tips'],
  ['DiTi Wide Bore 1000uL LiHa', 'Tecan 1000ul Wide Bore tips'],
  ['DiTi 10uL LiHa SBS', 'Tecan 10ul tips in SBS box'],
  ['DiTi 50uL LiHa SBS', 'Tecan 50ul tips in SBS box'],
  ['DiTi 200uL LiHa SBS', 'Tecan 200ul tips in SBS box'],
  ['DiTi 1000uL LiHa SBS', 'Tecan 1000ul tips in SBS box'],
  ['DiTi 200uL MCA96', 'Tecan 200ul tips for MCA96 in ANSI/SLAS format box'],
  ['DiTi 50uL MCA96', 'Tecan 50ul tips for MCA96 in ANSI/SLAS format box'],
  ['DiTi 350uL LiHa SBS', 'Tecan 350ul tips in ANSI/SLAS format box'],
  ['TecanFixed', 'Tecan Fixed Tips'],
  ['Hx50 Tipbox', 'Hamilton 50ul Tips'],
  ['Hx300 Tipbox', 'Hamilton 300ul Tips'],
  ['Hx1000 Tipbox', 'Hamilton 1000ul Tips'],
  ['Hx50F Tipbox', 'Hamilton 50ul Filtered Tips'],
  ['Hx300F Tipbox', 'Hamilton 300ul Filtered Tips'],
  ['Hx1000F Tipbox', 'Hamilton 1000ul Filtered Tips'],
]);
