import { useEarlyTokenContext } from 'client/app/apps/authentication/hooks';
import { reportError } from 'client/app/lib/errors';
import { usePartialCallback } from 'common/ui/hooks/usePartialCallback';

/*
 * Some minimal protect to avoid fetch being overridden and allow bad things like leaking authorization header.
 * This mostly protect from people copypasting script that promise wonders, but may not protect against bad 3rd party script that we load before this module.
 */
const safeFetch = globalThis.fetch;

/**
 * If you need to do an xhr, please get the approriate function using the corresponding hook.
 * Example: const getJSON = useGetJSON();
 * This ensures your request is properly authenticated.
 *
 * Authenticated calls need to pass the jwt access token in the headers.
 * We get it from Auth0Provider through context, therefore we need hooks.
 */

function useGetAccessToken() {
  const { earlyGetAccessTokenSilently } = useEarlyTokenContext();
  return earlyGetAccessTokenSilently;
}

type FetchOptions = {
  queryParams?: { [paramName: string]: any };
  body?: any;
  // HTTP headers.  (Overriding RequestInit's heterogeneous type definition to
  // be more sensible.)
  headers?: Record<string, string>;
} & Omit<RequestInit, 'queryParams' | 'body' | 'headers'>;

async function anthaFetch(
  getAccessToken: () => Promise<string>,
  url: string,
  options: FetchOptions = {},
) {
  const bearerToken = await getAccessToken();
  // Pass credentials to all Antha API endpoints.
  // Don't pass credentials when fetching from external services, like Google
  // Cloud Storage. In fact, passing credentials can make those request fail.
  options.credentials = 'same-origin';
  let urlWithQueryString = url;
  if (options.queryParams) {
    const qs = Object.entries(options.queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');
    urlWithQueryString = `${url}?${qs}`;
  }
  options.headers = {
    ...options.headers,
    authorization: `bearer ${bearerToken}`,
  };
  let response;
  try {
    response = await safeFetch(urlWithQueryString, options);
  } catch (e) {
    reportError(e);
    throw new Error(
      `Something went wrong. Please check your network connection.
       Could not reach the server at ${url}`,
    );
  }

  if (!response.ok) {
    let bodyText: string;
    try {
      bodyText = await response.text();
    } catch (_) {
      throw new ApiError(url, '');
    }
    throw new ApiError(url, bodyText);
  }
  return response;
}

function get(
  getAccessToken: () => Promise<string>,
  url: string,
  options: FetchOptions = {},
) {
  options.method = 'GET';
  return anthaFetch(getAccessToken, url, options);
}
export function useGet() {
  const getAccessToken = useGetAccessToken();
  return usePartialCallback(getAccessToken, get);
}

function getJSON(
  getAccessToken: () => Promise<string>,
  url: string,
  options: FetchOptions = {},
) {
  return get(getAccessToken, url, options).then(response => response.json());
}
export function useGetJSON() {
  const getAccessToken = useGetAccessToken();
  return usePartialCallback(getAccessToken, getJSON);
}

function post(
  getAccessToken: () => Promise<string>,
  url: string,
  options: FetchOptions = {},
) {
  options.method = 'POST';
  if (options.body && typeof options.body !== 'string') {
    options.body = JSON.stringify(options.body);
  }
  return anthaFetch(getAccessToken, url, options);
}

// post ArrayBuffer for zip files etc.
// E.g., xhr.postRaw({ body: reader.result as ArrayBuffer})
function postRaw(
  getAccessToken: () => Promise<string>,
  url: string,
  options: FetchOptions = {},
) {
  options.method = 'POST';
  return anthaFetch(getAccessToken, url, options);
}
export function usePostRaw() {
  const getAccessToken = useGetAccessToken();
  return usePartialCallback(getAccessToken, postRaw);
}

function postJSON(
  getAccessToken: () => Promise<string>,
  url: string,
  options?: FetchOptions,
) {
  return post(getAccessToken, url, addContentTypeJSON(options)).then(response =>
    response.json(),
  );
}
export function usePostJSON() {
  const getAccessToken = useGetAccessToken();
  return usePartialCallback(getAccessToken, postJSON);
}

function addContentTypeJSON(options?: FetchOptions): FetchOptions {
  return {
    ...options,
    headers: { ...options?.headers, 'Content-Type': 'application/json' },
  };
}

/**
 * An error returned by a microservice HTTP API.
 *
 * Example JSON response from a microservice endpoint:
 * HTTP 500
 * {error: "task predecessor 64N1Q0DVMA6ARE0H0RK890GSAQ not done", code: 2}
 */
class ApiError extends Error {
  // Error code from the microservice API. Public so that we can match on it.
  apiErrorCode: number;
  // Error message from the microservice API
  private apiErrorMessage: string;

  constructor(url: string, private readonly responseBody: string) {
    // We intentionally don't include details like the response body here,
    // because this message is shown in the UI.
    super();
    this.name = this.constructor.name;
    let parsedBody;
    try {
      parsedBody = JSON.parse(responseBody);
      this.apiErrorCode = parsedBody.code;
      this.apiErrorMessage = parsedBody.error;
      this.message = `${parsedBody.error}`;
    } catch (_) {
      // Looks like the response body is not JSON. This shouldn't normally happen,
      // but in case it does, we handle this in getApiErrorMessage().
      this.message = `Something went wrong and the returned message could not be parsed properly. The request URL was: ${url}`;
      this.apiErrorCode = 0;
      this.apiErrorMessage = '';
    }
  }

  // API error message. If unavailable, returns the whole generic response body.
  getApiErrorMessage(): string {
    if (this.apiErrorMessage) {
      return this.apiErrorMessage;
    }
    return this.responseBody;
  }
}
