import React, { useMemo } from 'react';

import { PlateMinSizeByName } from 'client/app/apps/cherry-picker/CherryPickApi';
import {
  AllPlatesByType,
  SetPlatesByName,
} from 'client/app/apps/cherry-picker/CherryPickContext';
import TypeSelector from 'client/app/apps/cherry-picker/cp-plate-vis/PlateTypeSelector';
import WellSelector from 'client/app/components/WellSelector/WellSelector';
import { ScreenRegistry } from 'client/app/registry';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { getDeckItemStates } from 'common/ui/components/simulation-details/mix/deckContents';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';

type PlateOrTypeSelectorProps = {
  plateType: PlateState | undefined;
  setPlatesByName: SetPlatesByName;
  selectedPlateName: string;
  plates: AllPlatesByType;
  plateMinSizeByName: PlateMinSizeByName;
  deckLayout: DeckLayout;
  isReadonly: boolean;
};

/**
 *  Render either a dummy plate, or a plate visualization if users selected a Plate
 */
export default React.memo(function PlateOrTypeSelector(props: PlateOrTypeSelectorProps) {
  const {
    plateMinSizeByName,
    plates,
    plateType,
    selectedPlateName,
    setPlatesByName,
    deckLayout,
    isReadonly,
  } = props;

  const liquidColors = useMemo(
    () => LiquidColors.createUsingColorGraph(getDeckItemStates(deckLayout.deck)),
    [deckLayout],
  );

  if (deckLayout && plateType) {
    return (
      <WellSelector
        deckLayout={deckLayout}
        plate={plateType}
        liquidColors={liquidColors}
        googleAnalyticsCategory={ScreenRegistry.CHERRY_PICKER}
      />
    );
  } else {
    return (
      <TypeSelector
        setPlatesByName={setPlatesByName}
        plateName={selectedPlateName}
        plates={plates}
        plateMinSizeByName={plateMinSizeByName}
        isDisabled={isReadonly}
      />
    );
  }
});
