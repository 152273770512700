import { useCallback } from 'react';

/**
 * Create callbacks for card click and double click.
 * It usefully takes care of hiding the fact that they are optional.
 * It takes an id, 2 optional functions and returns 2 memoized callbacks (guaranteed to exist)
 */
export function useCardClick(
  id: string,
  onSelect?: (id: string) => void,
  onDoubleSelect?: (id: string) => void,
) {
  const onClick = useCallback(() => {
    onSelect?.(id);
  }, [id, onSelect]);
  const onDoubleClick = useCallback(() => onDoubleSelect?.(id), [id, onDoubleSelect]);
  return { onClick, onDoubleClick };
}
