import React, { useCallback, useMemo } from 'react';

import {
  evaluateParameterState,
  ParameterState,
} from 'client/app/lib/rules/elementConfiguration/evaluateParameterState';
import { indexBy } from 'common/lib/data';
import { BundleParameters, Connection, ElementInstance } from 'common/types/bundle';

type ParameterStateContext = {
  getStateForParameter: (
    elementInstanceName: string,
    parameterName: string,
  ) => ParameterState | undefined;
  areAllGroupParametersHidden: (
    elementInstanceName: string,
    groupName: string,
  ) => boolean;
  getElementInstanceNamesWithParameterErrors: () => string[];
};

function getStateForParameter(_: string, __: string) {
  // This shouldn't be called without a provider.
  // TODO: Throw error instead.
  return undefined;
}

function areAllGroupParametersHidden(_: string, __: string) {
  return false;
}

function getElementInstanceNamesWithParameterErrors() {
  return [];
}

const DEFAULT_CONTEXT: ParameterStateContext = {
  getStateForParameter,
  areAllGroupParametersHidden,
  getElementInstanceNamesWithParameterErrors,
};

/**
 * Evaluation engine for element rules specified in the element configuration tool.
 * Given information from the workflow, it calculates state for each parameter of each
 * element instance in the workflow. This specifies things like whether parameters are
 * hidden or disabled or the like based on evaluation of the rules.
 * */
export const ParameterStateContext =
  React.createContext<ParameterStateContext>(DEFAULT_CONTEXT);

type Props = {
  elementInstances: ElementInstance[];
  parameters: BundleParameters;
  connections: Connection[];
  children: React.ReactNode;
};

type AllParameterState = {
  [elementInstanceName: string]: {
    [parameterName: string]: ParameterState;
  };
};

function calculateParameterState(
  elementInstances: ElementInstance[],
  connections: Connection[],
  parameters: BundleParameters,
): AllParameterState {
  const stateMap: AllParameterState = {};

  // Evaluate the parameter state for each element instance and add it to stateMap.
  for (const elementInstance of elementInstances) {
    const parametersForInstance = parameters[elementInstance.name];

    const ruleContext = {
      connections,
      elementInstance,
      parameterValues: parametersForInstance,
    };

    const rules = elementInstance.element?.configuration?.rules ?? [];
    const { parameterStateDict } = evaluateParameterState(ruleContext, rules);
    stateMap[elementInstance.name] = parameterStateDict;
  }

  return stateMap;
}

export default function ParameterStateContextProvider(props: Props) {
  const stateMap = useMemo(
    () =>
      calculateParameterState(
        props.elementInstances,
        props.connections,
        props.parameters,
      ),
    [props.connections, props.elementInstances, props.parameters],
  );

  const elementInstancesByName = useMemo(
    () => indexBy(props.elementInstances, 'name'),
    [props.elementInstances],
  );

  const getElementInstanceNamesWithParameterErrors = useCallback(() => {
    const elementInstancesWithErrors = Object.entries(stateMap)
      .map(([name, parameterState]) => ({
        name,
        parameterStates: Object.values(parameterState),
      }))
      .filter(({ parameterStates }) =>
        parameterStates.some(
          state => state?.errorMessages?.length > 0 && state.isVisible,
        ),
      )
      .map(({ name }) => name);

    return elementInstancesWithErrors;
  }, [stateMap]);

  const getStateForParameter = useCallback(
    (elementInstanceName: string, parameterName: string) => {
      return stateMap[elementInstanceName]?.[parameterName];
    },
    [stateMap],
  );

  const areAllGroupParametersHidden = useCallback(
    (elementInstanceName: string, groupName: string) => {
      const elementInstance = elementInstancesByName[elementInstanceName];
      // The groupName property itself takes from the config group name if there is one
      // before defaulting to the one from the element so we don't have to worry about
      // that here.
      const parametersInGroup = elementInstance?.element.inputs.filter(
        input => input.groupName === groupName,
      );
      return parametersInGroup?.every(
        parameter => stateMap[elementInstanceName]?.[parameter.name]?.isVisible === false,
      );
    },
    [elementInstancesByName, stateMap],
  );

  const context = useMemo(() => {
    return {
      getStateForParameter,
      areAllGroupParametersHidden,
      getElementInstanceNamesWithParameterErrors,
    };
  }, [
    getStateForParameter,
    areAllGroupParametersHidden,
    getElementInstanceNamesWithParameterErrors,
  ]);

  return (
    <ParameterStateContext.Provider value={context}>
      {props.children}
    </ParameterStateContext.Provider>
  );
}
