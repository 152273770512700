import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuid } from 'uuid';

import useUnitOptions from 'client/app/components/Parameters/FiltrationProtocolDesign/lib/useUnitOptions';
import { SubComponentItem } from 'client/app/components/Parameters/FiltrationProtocolDesign/types';
import Button from 'common/ui/components/Button';
import Dropdown from 'common/ui/filaments/Dropdown';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  subComponents: SubComponentItem[];
  showErrors: boolean;
  errors?: Record<string, { name?: string; value?: string; unit?: string }>;
  updateSubComponents: (items: SubComponentItem[]) => void;
  isReadonly: boolean;
};

export default function SubComponentsForm({
  subComponents,
  showErrors,
  errors,
  updateSubComponents,
  isReadonly,
}: Props) {
  const classes = useStyles();

  const addSubComponent = () => {
    updateSubComponents([
      ...subComponents,
      { id: uuid(), name: '', concentration: { value: 0, unit: 'mM' } },
    ]);
  };
  const removeSubComponent = (id: string) => {
    const idx = subComponents.findIndex(item => item.id === id);
    updateSubComponents([
      ...subComponents.slice(0, idx),
      ...subComponents.slice(idx + 1),
    ]);
  };
  const updateSubComponent = (
    id: string,
    subComponentBatch: Partial<SubComponentItem>,
  ) => {
    const idx = subComponents.findIndex(item => item.id === id);
    updateSubComponents([
      ...subComponents.slice(0, idx),
      {
        ...subComponents[idx],
        ...subComponentBatch,
      },
      ...subComponents.slice(idx + 1),
    ]);
  };

  return (
    <main className={classes.subComponents}>
      {subComponents.map(subComponent => (
        <SubComponent
          key={subComponent.id}
          subComponent={subComponent}
          error={errors?.[subComponent.id]}
          showError={showErrors}
          update={updateSubComponent}
          remove={removeSubComponent}
          isReadonly={isReadonly}
        />
      ))}
      <Button
        startIcon={<AddIcon />}
        variant="tertiary"
        color="primary"
        disabled={!isEmpty(errors) || isReadonly}
        onClick={addSubComponent}
      >
        Add new item
      </Button>
    </main>
  );
}

type SubComponentProps = {
  subComponent: SubComponentItem;
  error?: { name?: string; value?: string; unit?: string };
  showError: boolean;
  update: (id: string, batch: Partial<SubComponentItem>) => void;
  remove: (id: string) => void;
  isReadonly: boolean;
};

function SubComponent({
  subComponent,
  error,
  showError,
  update,
  remove,
  isReadonly,
}: SubComponentProps) {
  const classes = useStyles();

  const unitOptions = useUnitOptions();

  const updateName = useTextFieldChange((newName: string) => {
    update(subComponent.id, { name: newName });
  });
  const updateValue = useTextFieldChange((newConcentration: string) => {
    update(subComponent.id, {
      concentration: {
        ...subComponent.concentration,
        value: +newConcentration,
      },
    });
  });
  const updateUnit = (unit: string = '') => {
    update(subComponent.id, {
      concentration: {
        ...subComponent.concentration,
        unit,
      },
    });
  };

  return (
    <div className={cx(classes.subComponent, { readonly: isReadonly })}>
      <ClearIcon
        color="error"
        className="remove"
        onClick={() => remove(subComponent.id)}
      />
      <main className={classes.subComponentInner}>
        <div className={classes.fieldset}>
          <Typography variant="body2">Sub-component Name</Typography>
          <TextField
            value={subComponent.name}
            onChange={updateName}
            placeholder="Please input sub-component..."
            error={showError && !!error?.name}
            helperText={showError && error?.name}
            disabled={isReadonly}
          />
        </div>
        <section className={classes.concentration}>
          <div className={classes.fieldset}>
            <Typography variant="body2">Value</Typography>
            <TextField
              type="number"
              value={subComponent.concentration.value}
              onChange={updateValue}
              inputProps={{ className: classes.noSpinNumberInput, min: 0 }}
              error={showError && !!error?.value}
              helperText={showError && error?.value}
              disabled={isReadonly}
            />
          </div>
          <div className={classes.fieldset}>
            <Typography variant="body2">Unit</Typography>
            <Dropdown
              valueLabel={subComponent.concentration.unit}
              placeholder="Select unit..."
              onChange={updateUnit}
              options={unitOptions}
              hasError={showError && !!error?.unit}
              helperText={showError ? error?.unit : undefined}
              isDisabled={isReadonly}
            />
          </div>
        </section>
      </main>
    </div>
  );
}

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  subComponents: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: spacing(2),
    paddingTop: spacing(2),
    gap: spacing(3),
  },
  subComponent: {
    position: 'relative',
    '& > .remove': {
      position: 'absolute',
      top: '50px',
      left: spacing(-1),
      cursor: 'pointer',

      display: 'none',
    },
    '&:hover:not(.readonly) > .remove': {
      display: 'initial',
    },
  },
  subComponentInner: {
    display: 'flex',
    flexDirection: 'column',
    margin: spacing(0, 0, 3, 6),
    paddingLeft: spacing(4),
    borderLeft: `1px solid ${palette.divider}`,
  },
  fieldset: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: spacing(2),
  },
  concentration: {
    display: 'flex',
    gap: spacing(3),
    marginTop: spacing(3),
  },
  noSpinNumberInput: {
    textAlign: 'right',
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
  },
}));
