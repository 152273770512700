/**
 * Convert an old antha.com URL to the new synthace.io domain
 */
export function getSynthaceDomainURL(current: URL): URL | null {
  const result = new URL(current.toString()); // copy to avoid modifying the argument
  const hostname = current.hostname;
  if (hostname === 'antha.ninja') {
    result.hostname = 'dev.synthace.bio';
  } else if (hostname === 'antha.reviews') {
    result.hostname = 'staging.synthace.bio';
  } else if (hostname.endsWith('antha.com')) {
    result.hostname = hostname.replace(/antha\.com$/, 'synthace.bio');
  } else {
    // there isn't any corresponding *.synthace.io URL
    return null;
  }
  return result;
}
