import { useMemo } from 'react';

import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';

/**
 * Get the single selected element instance, if there is one,
 * to display in the element instance panel.
 */
export function useElementInstance() {
  const selectedObjectIds = useWorkflowBuilderSelector(state => state.selectedObjectIds);
  const elementInstances = useWorkflowBuilderSelector(state => state.elementInstances);

  return useMemo(() => {
    if (selectedObjectIds.length === 1) {
      // The selected object id might be for a connection or a group, so we need to find
      // the corresponding element instance, if any.
      const [id] = selectedObjectIds;
      return elementInstances.find(ei => ei.Id === id);
    }

    return undefined;
  }, [elementInstances, selectedObjectIds]);
}
