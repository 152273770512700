import { styled } from '@mui/material/styles';
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Colors from 'common/ui/Colors';

export const ToggleButton = styled(MuiToggleButton)(
  ({ theme: { typography, palette, spacing } }) => ({
    color: palette.text.primary,
    ...typography.body1,
    textTransform: 'none',
    padding: spacing(3, 4),
    flex: 1,
    '&.Mui-disabled': {
      color: palette.text.disabled,
    },
  }),
);

const Toggle = styled(ToggleButtonGroup)(({ theme: { palette, typography } }) => ({
  backgroundColor: palette.background.paper,
  borderColor: Colors.GREY_30,
  boxShadow: `0px 1px 1px 0px rgba(0, 0, 0, 0.1)`,

  // We need to define this within the parent to ensure it has the necessary
  // specificity to override the baseline Mui styles.
  [`${ToggleButton}.MuiToggleButtonGroup-grouped.Mui-selected`]: {
    color: palette.primary.dark,
    backgroundColor: Colors.BLUE_0,
    border: `1px solid ${Colors.BLUE_20}`,
    ...typography.subtitle2,
    '&:hover': {
      backgroundColor: Colors.BLUE_5,
    },
  },
}));

export default Toggle;
