import {
  cherryPickDataFromParsedCsv,
  getDataFromCsv,
  LiquidTransfer,
  optionalCsvHeaders,
  requiredCsvHeaders,
} from 'client/app/apps/cherry-picker/CherryPickApi';
import {
  DEFAULT_ACTIVE_STEP,
  SetActiveStep,
  SetFileName,
  SetPlatesByName,
} from 'client/app/apps/cherry-picker/CherryPickContext';
import { ScreenRegistry } from 'client/app/registry';
import getFileBytes from 'common/lib/getFileBytes';
import { TableColumn } from 'common/types/spreadsheetEditor';
import { HeadersMappingDialogProps } from 'common/ui/components/Dialog/HeadersMappingDialog';
import {
  ExtraColumnsBySheet,
  MappedHeadersBySheet,
} from 'common/ui/components/Dialog/headersMappingHelper';
import { SnackbarManager } from 'common/ui/components/SnackbarManager';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

type HeadersMappingDialogResult = {
  mappedHeadersBySheet: MappedHeadersBySheet;
  extraColumnsBySheet?: ExtraColumnsBySheet | undefined;
} | null;

export type SetLiquidTransfers = React.Dispatch<React.SetStateAction<LiquidTransfer[]>>;
export async function handleUploadCherryPickFile(
  file: File,
  fileInputRef: HTMLInputElement | null,
  snackbar: SnackbarManager,
  openHeadersMappingDialog: (
    props: Pick<HeadersMappingDialogProps, 'sheets' | 'hideActions'>,
  ) => Promise<HeadersMappingDialogResult>,
  onUploadCherryPick: SetLiquidTransfers,
  setFilename: SetFileName,
  setPlatesByName: SetPlatesByName,
  setActiveStep: SetActiveStep,
) {
  if (!fileInputRef?.files) {
    return;
  }
  // Reset the value so users can upload the same file twice
  fileInputRef.value = '';

  const csvData = await getFileBytes(file);
  // Try parsing the CSV and turn it into a format the UI understands
  const [parsedCsv, passedStrictChecks] = getDataFromCsv(csvData);
  const csvHasEnoughRows = parsedCsv.length > 0;
  if (!csvHasEnoughRows) {
    logEvent('uploaded-empty-file', ScreenRegistry.CHERRY_PICKER);
    snackbar.showError(
      `Uploaded file "${file.name}" is empty. Please add at least one transfer to use the Cherry Picker.`,
    );
    return;
  }

  let cherryPick: LiquidTransfer[] = [];
  if (passedStrictChecks) {
    logEvent('uploaded-csv-matching-template', ScreenRegistry.CHERRY_PICKER);
    // The csv is in a sane format and we can safely parse it.
    cherryPick = cherryPickDataFromParsedCsv(parsedCsv);
  } else {
    logEvent('uploaded-csv-not-matching-template', ScreenRegistry.CHERRY_PICKER);
    // Otherwise we need the users to map their headers to the ones
    // the UI knows about.
    const parsedHeaders = Object.keys(parsedCsv[0]);
    const parsedColumns = getColumnsFromHeaders(parsedHeaders);
    const expectedColumns = getColumnsFromHeaders(requiredCsvHeaders);
    const optionalColumns = getColumnsFromHeaders(optionalCsvHeaders);

    const sheetsToRemap = [{ expectedColumns, parsedColumns, optionalColumns }];

    const { mappedHeadersBySheet } =
      (await openHeadersMappingDialog({
        sheets: sheetsToRemap,
        hideActions: true,
      })) ?? {};

    if (!mappedHeadersBySheet) {
      snackbar.showError(`Upload cancelled`);
      logEvent('upload-cancelled', ScreenRegistry.CHERRY_PICKER);
      return;
    }

    // Cherry Picker only accepts csv with one sheet.
    // Thus, take the first one.
    const mappedHeaders = mappedHeadersBySheet[0];

    const mappedCsv = [];
    for (const row of parsedCsv) {
      const mappedRow = {} as { [expectedHeader: string]: string };
      for (const parsedHeader of parsedHeaders) {
        mappedRow[mappedHeaders[parsedHeader]] = row[parsedHeader];
      }

      mappedCsv.push(mappedRow);
    }

    cherryPick = cherryPickDataFromParsedCsv(mappedCsv);
  }

  onUploadCherryPick(cherryPick);
  setFilename(file.name);
  // Reset the dict to avoid the newly uploaded CherryPick to already have
  // plate types selected.
  setPlatesByName({});
  setActiveStep(DEFAULT_ACTIVE_STEP);
}

/**
 * Convert headers to typed columns, so we can use the HeadersMapper
 * dialog.
 */
function getColumnsFromHeaders(headers: string[]): TableColumn[] {
  // The column `type` doesn't matter, as the Cherry Picker does NOT
  // validate column types.
  return headers.map(header => ({ name: header, type: 'string' }));
}
