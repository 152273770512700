import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ExperimentReportIcon = React.memo<SvgIconProps>(
  function ExperimentReportIcon(props) {
    return (
      <SvgIcon viewBox="0 0 24 24" {...props}>
        <svg width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="13.378" height="4.913" rx="1.189" fill="currentColor" />
          <rect y="7" width="22" height="8.122" rx="1.189" fill="currentColor" />
          <rect y="17" width="19" height="5" rx="1.189" fill="currentColor" />
        </svg>
      </SvgIcon>
    );
  },
);
