import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import DeviceIntercomTooltip from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/components/DeviceIntercomTooltip';
import { SimpleDevice } from 'common/types/device';

type Props = {
  device: SimpleDevice;
  showManualCopy?: boolean;
};

export default function DeviceSection({ device, showManualCopy }: Props) {
  const deviceImgSrc = device.imageUrl ?? '/app/cdn/assets/devices/Void.png';

  return (
    <Stack direction="row" alignItems="center" gap={3}>
      <DeviceThumbnail src={deviceImgSrc} />
      <Stack overflow="hidden">
        <Box display="flex" alignItems="center">
          <ExecutionModeLabel variant="h5" color="black" title={device.name}>
            {showManualCopy
              ? 'This simulation was configured for a manual execution.'
              : device.model}
          </ExecutionModeLabel>
          <DeviceIntercomTooltip anthaLangDeviceClass={device.anthaLangDeviceClass} />
        </Box>
        <ExecutionModeLabel variant="caption" color="textSecondary" title={device.id}>
          {showManualCopy
            ? 'See how to run it step by step by downloading the instructions.'
            : device.name}
        </ExecutionModeLabel>
      </Stack>
    </Stack>
  );
}

//#region Styles

const DeviceThumbnail = styled('img')({
  width: '28px',
  height: '28px',
});

const ExecutionModeLabel = styled(Typography)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

//#endregion
