import { StyleRules } from '@mui/styles';

import fonts from 'common/ui/fontFaces';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const makeGlobalStylesHook = (override?: StyleRules) =>
  makeStylesHook({
    '@global': {
      ...fonts,
      'html, body, .render-container': {
        margin: 0,
        padding: 0,
        height: '100%',
        width: '100%',
        fontFamily: '"Roboto", "Noto", sans-serif',
        fontSize: '14px',
        fontWeight: 300,
        backgroundAttachment: 'fixed',
      },

      html: {
        backgroundColor: '#757575',
      },
      body: {
        overflow: 'hidden',
      },
      '*': {
        boxSizing: 'border-box',
      },
      ...override,
    },
  });

export default makeGlobalStylesHook;
