import React, { useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ShareIcon from '@mui/icons-material/Share';
import MuiButton from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { entityNameMap } from 'client/app/components/nav/breadcrumbs/useBreadcrumbsEntity';
import RecipientsAutocomplete, {
  RecipientOption,
} from 'client/app/components/ShareLink/RecipientsAutocomplete';
import { BreadcrumbsEntityEnum } from 'client/app/gql';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import Feedback from 'common/ui/components/Feedback';
import IconButton from 'common/ui/components/IconButton';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

type Props = {
  onClose: () => void;
  entityType: BreadcrumbsEntityEnum;
  entityName: string;
  // TODO: Use the sharing service to append a uuid to this.
  entityLink: string;
};

export default function ShareLinkDialog({
  onClose,
  entityName,
  entityType,
  entityLink,
}: Props) {
  const [recipients, setRecipients] = useState<RecipientOption[]>([]);
  const entityTypeName = entityNameMap[entityType];
  const snackbar = useSnackbarManager();

  const mailToLink = useMemo(() => {
    return `mailto:${recipients.map(r => r.email).join(',')}?subject=${encodeURI(
      `View my Synthace ${entityTypeName}: ${entityName}`,
    )}&body=${encodeURI(
      `Hi, I’d like to share this Synthace ${entityTypeName} with you. Follow the link below to view it on Synthace.

${entityName}

${entityLink}

Synthace is a digital experiment platform for designing, automating, and analyzing experiments in a single place, then building and analyzing result data.

You will need a Synthace account to view this ${entityTypeName}. Don’t have an account yet? Contact us at customersuccess@synthace.com, and we’ll add you to the team.`,
    )}`;
  }, [entityLink, entityName, entityTypeName, recipients]);

  return (
    <Dialog open onClose={onClose} maxWidth="sm">
      <Title>
        <ShareIcon />
        <span>
          Sharing {entityTypeName}: <b>{entityName}</b>
        </span>
        <CloseButton
          icon={<CloseIcon />}
          onClick={onClose}
          color="inherit"
          size="small"
        />
      </Title>
      <Content>
        <LicenseWarning
          variant="info"
          content={
            <Typography variant="body1">
              Users will need a Synthace account to view shared contents.
            </Typography>
          }
        />
        <EmailTitle variant="h5">Send a link via email</EmailTitle>

        <Recipients value={recipients} onChange={setRecipients} />

        <EmailButton
          component="a"
          variant="contained"
          color="primary"
          startIcon={<MailOutlineIcon />}
          href={mailToLink}
          target="_blank"
          disabled={recipients.length === 0}
          data-heap-tracking="share-link-email"
          data-heap-shared-entity-type={entityType}
        >
          Next
        </EmailButton>

        <CopyLinkTitle variant="h5">Copy a shareable link</CopyLinkTitle>

        <CopyLinkValue>{entityLink}</CopyLinkValue>

        <CopyLinkButton
          variant="primary"
          color="primary"
          startIcon={<LinkIcon />}
          onClick={async () => {
            await navigator.clipboard.writeText(entityLink);
            snackbar.showSuccess('Copied to clipboard');
          }}
          data-heap-tracking="share-link-clipboard"
          data-heap-shared-entity-type={entityType}
        >
          Copy Link
        </CopyLinkButton>
      </Content>
    </Dialog>
  );
}

const Title = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
  borderBottom: `1px solid ${Colors.GREY_20}`,
  marginBottom: theme.spacing(6),
}));

const Content = styled(DialogContent)(({ theme }) => ({
  display: 'grid',
  gridTemplate: `
    'warning warning' auto
    'emailTitle emailTitle' auto
    'recipients emailButton' auto
    'copyTitle copyTitle' auto
    'copyValue copyButton' auto / minmax(0, 1fr) auto
  `,
  gap: theme.spacing(4),
  width: 600,
  paddingBottom: theme.spacing(6),
  alignItems: 'start',
}));

const EmailTitle = styled(Typography)({
  gridArea: 'emailTitle',
});

const Recipients = styled(RecipientsAutocomplete)({
  gridArea: 'recipients',
});

const EmailButton = styled(MuiButton<'a'>)({
  gridArea: 'emailButton',
});

const CopyLinkTitle = styled(Typography)(({ theme }) => ({
  gridArea: 'copyTitle',
  marginTop: theme.spacing(5),
}));

const CopyLinkValue = styled('output')(({ theme }) => ({
  gridArea: 'copyValue',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3, 4),
  border: `1px solid ${Colors.GREY_30}`,
  borderRadius: 4,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  ...theme.typography.body1,
}));

const CopyLinkButton = styled(Button)({
  gridArea: 'copyButton',
});

const CloseButton = styled(IconButton)({
  marginLeft: 'auto',
});

const LicenseWarning = styled(Feedback)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  gridArea: 'warning',
}));
