import { computeDragAndDropLandingPosition } from 'client/app/apps/workflow-builder/lib/dragAndDropElementsHelper';
import {
  ELEMENT_INSTANCE_WIDTH,
  STAGE_PADDING,
} from 'client/app/lib/layout/LayoutHelper';
import { Stage } from 'common/types/bundle';
import { Dimensions } from 'common/types/Dimensions';
import { Position2d } from 'common/types/Position';
import { arePointsClose } from 'common/ui/lib/position';

/**
 * How far do we have to move the workspace to break current elements cascade.
 *
 * When user add an element it adds it near top left of visible workspace, and then does cascade
 * We keep the same cascade unless the workspace has been drag far enough.
 */
const MAX_DISTANCE = 150;

/**
 * The maximum number of elements we want to have in a cascade.
 */
const MAX_ELEMENTS_IN_CASCADE = 8;

/**
 * This is used by the Workflow Builder to know where to add a new element so it makes a nice cascade when many elements are added after each other.
 * It tracks the position of the underlying Workspace (with onPositionChange) to know where and if it needs to start a new cascade.
 *
 * Warning: It was not mean to be very reusable and is its own module to not pollute too much the already giant WorkflowBuilder.tsx.
 */
export default class ElementsCascadeTracker {
  private latestElementPosition = { x: 0, y: 0 };
  private index = 0;
  private zoom = 1;
  private width = 1;
  private height = 1;

  /** Current position of the workspace, we use it to know where to position new element
   * This is not used in render, so there is no need to put it in state.
   */
  private workspacePosition: Position2d = { x: 0, y: 0 };

  public getWorkspacePosition(): Position2d {
    return this.workspacePosition;
  }

  /**
   * Determine if a target position lies outside  the currently visible canvas area.
   */
  public isOffscreen({ x, y }: Position2d, visibleArea?: Dimensions) {
    const area: Dimensions = {
      left: this.workspacePosition.x,
      top: this.workspacePosition.y,
      width: this.width,
      height: this.height,
    };

    if (visibleArea) {
      area.left += visibleArea.left / this.zoom;
      area.top += visibleArea.top / this.zoom;
      area.width = visibleArea.width / this.zoom;
      area.height = visibleArea.height / this.zoom;
    }

    if (
      x < area.left ||
      x > area.left + area.width ||
      y < area.top ||
      y > area.top + area.height
    ) {
      return true;
    }

    return false;
  }

  public onPositionChange = (
    position: Position2d,
    zoom: number,
    width: number,
    height: number,
  ) => {
    const { x, y } = position;
    // The position from workspace represent where it has been dragged after zoom.
    // We need to take the negative because
    // the position from workspace are opposed.
    // i.e. When you drag workspace left, the workspace is moved right.
    // Then we also need to scale back by dividing by zoom.
    this.workspacePosition = { x: -x / zoom, y: -y / zoom };
    this.zoom = zoom;
    this.width = width / zoom;
    this.height = height / zoom;
  };

  /**
   * For multi-stage workflows, determine the target stage to insert a
   * new element into. This is the most central stage on the screen.
   * If there is stage boundary at the exact centre of the screen, we
   * use the right-most, stage.
   */
  getTargetStage(stages?: Stage[], selectedStageId?: string) {
    if (!stages) {
      return undefined;
    }

    if (selectedStageId) {
      return stages.find(s => s.id === selectedStageId);
    }

    const midPoint = this.getWorkspacePosition().x + this.width / 2;

    const centerStages = stages.filter((stage, index) => {
      const next = stages[index + 1];
      return (
        (stage.meta.x === undefined || stage.meta.x <= midPoint) &&
        (next?.meta.x === undefined || next.meta.x >= midPoint)
      );
    });

    return centerStages[centerStages.length - 1];
  }

  public getPositionAndIndexForNewElement(
    workflowLayoutRef: React.MutableRefObject<HTMLDivElement | null>,
    droppedAtPosition?: Position2d,
    stages?: Stage[],
    selectedStageId?: string,
  ) {
    const currentPosition = {
      x: this.workspacePosition.x,
      y: this.workspacePosition.y,
    };
    if (!arePointsClose(currentPosition, this.latestElementPosition, MAX_DISTANCE)) {
      // Workspace has been move too far, reset index and cascade start position.
      this.index = 0;
      this.latestElementPosition = currentPosition;
    }
    const zoomAndIndexToReturn = { zoom: this.zoom, index: this.index };
    if (droppedAtPosition) {
      const targetPosition = computeDragAndDropLandingPosition(
        droppedAtPosition,
        this.zoom,
        this.workspacePosition,
        workflowLayoutRef,
        stages,
      );
      return {
        targetPosition,
        ...zoomAndIndexToReturn,
      };
    } else {
      this.index++;

      // Initial offsets for starting the cascade.
      let initialXPositionOffset = 500;
      const initialYPositionOffset = 50;

      let maxXPosition: number | undefined = undefined;

      // We ensure the inserted element remains with the boundaries of the target stage in the canvas.
      if (stages) {
        const targetStage = this.getTargetStage(stages, selectedStageId);

        if (targetStage) {
          const nextStage = stages[stages.findIndex(s => s.id === targetStage.id) + 1];

          initialXPositionOffset = Math.max(
            targetStage.meta.x ??
              (nextStage?.meta.x === undefined ? 0 : nextStage?.meta.x - 320),
            this.workspacePosition.x + 320,
          );

          if (nextStage) {
            maxXPosition =
              (nextStage.meta.x ?? 0) - (ELEMENT_INSTANCE_WIDTH + STAGE_PADDING);
          }
        }
      }

      // Returns the position of the element in the current cascade.
      const elementPlacementInCascade = this.index % MAX_ELEMENTS_IN_CASCADE;

      // Calculates the positional offsets for the x and y relative to the
      // elements' current position in the cascade.
      // We always want y offset to be greater than x.
      const offsetXForNextElement = 25 * elementPlacementInCascade;
      const offsetYForNextElement = 50 * elementPlacementInCascade;

      // Once we get to the end of a cascade, we need to adjust the x position
      // to prevent direct overlapping.
      const offsetXForNextCascade = 50 * Math.floor(this.index / MAX_ELEMENTS_IN_CASCADE);

      const adjustedX =
        initialXPositionOffset + offsetXForNextElement + offsetXForNextCascade;

      const finalX = stages
        ? maxXPosition === undefined
          ? adjustedX
          : Math.min(adjustedX, maxXPosition)
        : this.latestElementPosition.x +
          (initialXPositionOffset + offsetXForNextElement + offsetXForNextCascade) /
            this.zoom;

      const adjustedPosition = {
        x: finalX,
        y: Math.floor(
          this.latestElementPosition.y +
            (initialYPositionOffset + offsetYForNextElement) / this.zoom,
        ),
      };
      return {
        targetPosition: adjustedPosition,
        ...zoomAndIndexToReturn,
      };
    }
  }
}
