import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AssignToDuplicateWellsIcon = React.memo<SvgIconProps>(
  function AssignToDuplicateWellsIcon(props) {
    return (
      <SvgIcon viewBox="0 -1 21 21" {...props}>
        <path
          d="M13.833 4.667h-1.666v2.5h-2.5v1.666h2.5v2.5h1.666v-2.5h2.5V7.167h-2.5v-2.5ZM2.167 8a5.825 5.825 0 0 1 3.341-5.267v-1.8C2.6 1.967.5 4.742.5 8s2.1 6.033 5.008 7.067v-1.8A5.825 5.825 0 0 1 2.167 8ZM13 .5C8.867.5 5.5 3.867 5.5 8s3.367 7.5 7.5 7.5 7.5-3.367 7.5-7.5S17.133.5 13 .5Zm0 13.333A5.84 5.84 0 0 1 7.167 8 5.84 5.84 0 0 1 13 2.167 5.84 5.84 0 0 1 18.833 8 5.84 5.84 0 0 1 13 13.833Z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
        />
      </SvgIcon>
    );
  },
);

export default AssignToDuplicateWellsIcon;
