// A file path contains some very key information in it. Info necessary
// to correctly render the file tree. Specifically, if the path ends
// with a "/" character, we know we've got a directory and not a file.
// This is important because attempting to fetch a file path as though
// it's a directory path means we'll break the UI because a file doesn't
// have any children to list.
export default function getSegmentsFromPath(path: string): string[] {
  const segments = [];

  // For all purposes of rendering anything in the UI, we need to treat
  // fake top-level directories like /device/ and /job/ as just extra
  // characters at the start of the first actually-accessible directory.
  // So, whenever we split the path into segments, we'll also do a basePath
  // extraction.
  // When migration to filetree is done, we should be able to simplify this a lot,
  // as there should be no more fake top-level directories.
  let p = path;
  const basePath = '/';
  segments.push(basePath);
  p = p.replace(basePath, '');

  let start = 0;
  // Find all the directory segments and add them to the segment list
  // with their trailing slashes.
  for (let ii = 0; ii < p.length; ii++) {
    const c = p[ii];
    if (c === '/' && ii > 0) {
      // don't split on the leading slash
      segments.push(p.substr(start, ii - start + 1));
      start = ii + 1;
    }
  }

  // If there was a file at the end of the path (i.e. it didn't end with a
  // trailing slash), then add that on, too. This will not have a trailing
  // slash, which is important.
  if (start < p.length - 1) {
    segments.push(p.substr(start));
  }

  return segments;
}
