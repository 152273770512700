import React, { useCallback } from 'react';

import ParameterEditor from 'client/app/components/Parameters/ParameterEditor';
import ConfigParameterHeader from 'client/app/components/WorkflowSettings/ConfigParameterHeader';
import { sanitiseParameterValue } from 'common/elementConfiguration/parameterUtils';
import { Parameter, ParameterValueDict } from 'common/types/bundle';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  parameters: Parameter[];
  parameterValueDict: ParameterValueDict;
  onChange: (paramName: string, value: ParameterValueDict) => void;
  isDisabled?: boolean;
};

export function ConfigParameterList(props: Props) {
  return (
    <>
      {props.parameters.map((parameter: Parameter) => {
        const value = props.parameterValueDict[parameter.name];
        return (
          <ConfigParameter
            key={parameter.name}
            parameter={parameter}
            value={value}
            onChange={props.onChange}
            isDisabled={props.isDisabled}
          />
        );
      })}
    </>
  );
}

type ConfigParameterProps = {
  parameter: Parameter & { displayName?: string }; // Used for PW config section;
  value: any;
  onChange: (paramName: string, value: ParameterValueDict) => void;
  isDisabled?: boolean;
};

function ConfigParameter(props: ConfigParameterProps) {
  const classes = useStyles();
  const { parameter, onChange } = props;
  const sanitisedOnChange = useCallback(
    (value: any) => {
      onChange(parameter.name, sanitiseParameterValue(value));
    },
    [onChange, parameter.name],
  );
  return (
    <div className={classes.parameter}>
      <ConfigParameterHeader
        name={parameter.name}
        displayName={parameter.displayName ?? parameter.configuration?.displayName}
        type={parameter.type}
        description={parameter.description}
      />
      <ParameterEditor
        anthaType={parameter.type}
        value={props.value}
        onChange={sanitisedOnChange}
        isDisabled={props.isDisabled}
        editorProps={parameter.configuration?.editor.additionalProps ?? undefined}
      />
    </div>
  );
}

const useStyles = makeStylesHook({
  parameter: {
    marginTop: '8px',
  },
});
