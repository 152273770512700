import XRegExp from 'xregexp';

import { isUUID } from 'common/lib/strings';
import { OpaqueAlias } from 'common/types/OpaqueAlias';

/**
 * A FiletreeLink looks like this:
 * ```
 * files.antha.com://orgID/path/to/some/file
 * |____scheme______| org  |_____path______|
 * ```
 */
export type FiletreeLink = string;

/**
 * FiletreePath is the path component of a FiletreeLink, e.g.: path/to/some/file.  It does NOT
 * include an orgID as the first path component.  FiletreePaths in practice sometimes have leading
 * or trailing slashes, but such paths aren't accepted by Filetree itself.
 */
export type FiletreePath = OpaqueAlias<string, 'FiletreePath'>;

export type ParsedFiletreeLink = {
  /** URI scheme, e.g. files.antha.com */
  scheme: string;
  /** orgId UUID */
  orgId: string;
  /**
   * filetreePath is the path component of a FiletreeLink, e.g.: path/to/some/file
   * see documentation for FiletreePath type
   */
  path: FiletreePath;
};

export function pathBeginsWithUUID(orgPlusPath: string): boolean {
  const j = orgPlusPath.indexOf('/');
  if (j < 0) {
    return false;
  }

  const orgID = orgPlusPath.substring(0, j);

  if (!isUUID(orgID)) {
    return false;
  }

  return true;
}

export function separateOrgFromPath(orgPlusPath: string): {
  orgID: string;
  path: FiletreePath;
} {
  const j = orgPlusPath.indexOf('/');
  if (j < 0) {
    throw new Error(`Input is not a legal filetree link`);
  }

  const orgID = orgPlusPath.substring(0, j);

  if (!isUUID(orgID)) {
    throw new Error(
      `Input is not a legal filetree link, first path component is not UUID`,
    );
  }

  return { orgID, path: orgPlusPath.substr(j + 1) as FiletreePath };
}

export function parseFiletreeLink(ftl: FiletreeLink): ParsedFiletreeLink {
  const SEPARATOR = '://';
  const i = ftl.indexOf(SEPARATOR);
  if (i <= 0) {
    throw new Error(`Input is not a legal filetree link: ${ftl}`);
  }

  const scheme = ftl.substr(0, i);

  const orgPlusPath = ftl.substr(i + SEPARATOR.length);

  try {
    const parts = separateOrgFromPath(orgPlusPath);
    return {
      scheme: scheme,
      orgId: parts.orgID,
      path: parts.path,
    };
  } catch (e) {
    const err: Error = e;
    throw new Error(`${err.message}: ${ftl}`);
  }
}

/*
 * Certain characters are not valid in filetree path name.
 * We are going to sanitize for invalid characters before uploading.
 * Note: \pL is XRegExp syntax for any Unicode letter.
 */
export function sanitizeFilenameForFiletree(filename: string): string {
  // Filetree rejects filenames with surrounding whitepsace, trim
  filename = filename.trim();
  const allowedChars = XRegExp('[^\\pL0-9 ._-]');
  return XRegExp.replace(filename, allowedChars, '_', 'all');
}

export type FiletreeTag = {
  [key: string]: string;
};

export type UploadUrlBody = {
  orgID: string;
  /* whether you are requesting an org-less upload url, orgID must be empty if true */
  global?: boolean;
  contentType?: string;
  /*
   * If passed as "true", will make Filetree service generate a GCS Signed URL for resumable uploads
   * i.e. first request to that URL must be POST and contain "x-goog-resumable" = "start" in the header.
   */
  resumable?: boolean;
};

export type UploadUrlResponse = {
  url: string;
  /** opaque ID used by filetree, need to be used in the index call too */
  uploadID: string;
};

export type UploadInput = {
  file: File;
  metadata: UploadMetadata;
};

export type UploadMetadata = {
  /**  path to index the file at */
  indexPath: FiletreePath;
};

/** Payload client needs to send to appserver to index a file in filetree */
export type ClientIndexBody = {
  // Organisation id. Required for requests using system auth tokens.
  orgID?: string;
  uploadID: string;
  indexPath: FiletreePath;
  sizeBytes: number;
  fileType?: string;
  sha256?: string;
  tags?: FiletreeTag[];
  generatorSystem?: string;
  global?: boolean;
};

export type IndexResponse = {
  /**  path the file was indexed at */
  ftl: FiletreeLink;
};

export type GetURLResponse = {
  url: string;
};

export type GetURLsResponseElement = {
  link: FiletreeLink;
  // The element will contain a url if the filetree service was able to generate
  // a URL for this link. Otherwise url will be undefined and error will contain
  // information on the error.
  url?: string;
  error?: string;
};

export type GetURLsResponse = {
  results: GetURLsResponseElement[];
};

export type GetURLsBody = {
  links: FiletreeLink[];
};

/** single item from filetree's list endpoint
 */
export type ItemListDetails = {
  name: string;
  title?: string; // appserver may add a title for job and device folders
  ftl: FiletreeLink;
  creationTime: string;
  type: 'directory' | 'file';
  sizeBytes?: number;
  contentType?: string;
  sha256?: string;
  tags?: FiletreeTag[];
  ownerUserID?: string;
};

/** filetree's list response */
export type ListResponse = {
  nextCursor?: string;
  items: ItemListDetails[];
  linkDetails: ItemListDetails;
};

export type SearchBody = {
  /* cursor to paginate a query */
  cursor?: string;
  /* order of the links, default to newest */
  order?: string;
  contentType?: string;
  fileType?: string;
  /* inclusive, RFC 3339 format */
  from?: string;
  /* inclusive, RFC 3339 format */
  until?: string;
  /**
   * Tag in the form of a single key object {key: value}
   *  all tags must be present for a link to be returned
   */
  tags?: FiletreeTag[];
  orgID: string;
  /* whether to search files that have no org, set to true when orgId is empty */
  global?: boolean;
  /* directory to search within*/
  path: FiletreePath;
};

export type UISearchBody = Omit<SearchBody, 'orgID'>;
export type UISearchBodyNoPath = Omit<UISearchBody, 'path'>;

export type TagBody = {
  orgID: string;
  /* whether the path being tagged is "global", set to true when orgId should be empty */
  global?: boolean;
  path: FiletreePath;
  /**
   * The list of tags to add to the given path in filetree.  Tags are arbitrary key-value pairs,
   * represented as an object with a single key.
   */
  toAdd?: FiletreeTag[];
  /**
   * The list of tags to remove from the given path in filetree.  Tags are arbitrary key-value
   * pairs, represented as an object with a single key.
   */
  toRemove?: FiletreeTag[];
};

export type CopyLinkBody = {
  /* org ID of the organisation in which the new link will be created */
  orgId: string;
  /* whether to make the created link "global", set to true when orgId is empty */
  global?: boolean;
  /* path at which the file will be accessible */
  indexPath: FiletreePath;
  /* the source filetree link, that must point to an existing file */
  sourceLink: FiletreeLink;
  /* the operation that triggered the copy operation */
  generatorSystem: string;
  /* optional detail on the user that copied the link */
  ownerUserID?: string;
};

export const FILE_UPLOAD_ERROR_SUFFIX =
  ' was not properly uploaded. Please reupload this file.';

export const FILE_DOWNLOAD_NAME_PARAMETER = 'filename';
