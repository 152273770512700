export type Policy = {
  id: string;
  name: string;
  description: string;
};

const Policies: Policy[] = [
  {
    description:
      'Designed for buffers used in miniature chromatography columns. No tip reuse permitted in order to restore dilutor airgaps. Aspirate from liquid level and dispense at well top. Wait two seconds after dispense.',
    id: 'ChromatographyBuffer',
    name: 'ChromatographyBuffer',
  },
  {
    description:
      'Designed for cell cultures. Tips will not be reused to minimise any risk of cross contamination and 2 pre-mixes will be performed prior to aspirating.',
    id: 'culture',
    name: 'Culture',
  },
  {
    description:
      'Default mix Policy. Blowout performed, no touch off, no mixing, tip reuse permitted for the same solution.',
    id: 'default',
    name: 'Default',
  },
  {
    description:
      'Designed for solutions containing detergents. Gentle aspiration and dispense and a tip reuse limit of 8 to reduce problem of foam build up inside the tips.',
    id: 'detergent',
    name: 'Detergent',
  },
  {
    description:
      'Dispense solution above the liquid to facilitate tip reuse but sacrifice pipetting accuracy at low volumes. No post-mix. No multi channel',
    id: 'DispenseAboveLiquid',
    name: 'DispenseAboveLiquid',
  },
  {
    description:
      'Dispense solution above the liquid to facilitate tip reuse but sacrifice pipetting accuracy at low volumes. No post Mix. Allows multi-channel pipetting.',
    id: 'DispenseAboveLiquidMulti',
    name: 'DispenseAboveLiquidMulti',
  },
  {
    description:
      'Designed for DNA samples. No tip reuse is permitted, no blowout and no post-mixing.',
    id: 'dna',
    name: 'DNA',
  },
  {
    description:
      'Designed for mixing DNA with cells. 2 gentle post-mixes are performed. No tip reuse is permitted, no blowout.',
    id: 'dna_cells_mix',
    name: 'DNACellsMix',
  },
  {
    description:
      'Designed for mixing DNA with cells. 2 gentle post-mixes are performed. No tip reuse is permitted, no blowout. Allows multi-channel pipetting.',
    id: 'dna_cells_mix_multi',
    name: 'DNACellsMixMulti',
  },
  {
    description:
      'Designed for DNA samples but with 5 post-mixes of 10ul. No tip reuse is permitted, no blowout, no multichannel.',
    id: 'dna_mix',
    name: 'DNAMix',
  },
  {
    description:
      'Designed for DNA samples but with 5 post-mixes of 10ul. No tip reuse is permitted, no blowout. Allows multi-channel pipetting.',
    id: 'dna_mix_multi',
    name: 'DNAMixMulti',
  },
  {
    description:
      'Designed for viscous samples, in particular enzymes stored in glycerol. 3 gentle post-mixes of 20ul will be performed. Tips will not be reused in order to increase accuracy.',
    id: 'glycerol',
    name: 'Glycerol',
  },
  {
    description:
      'Designed for loading a sample onto an agarose gel. Very slow dispense rate, no tip reuse and no blowout.',
    id: 'load',
    name: 'Load',
  },
  {
    description:
      'Designed for loading water into agarose gel wells so permits tip reuse. Very slow dispense rate and no blowout.',
    id: 'loadwater',
    name: 'LoadWater',
  },
  {
    description: '10 post-mixes of the sample being transferred. No tip reuse permitted.',
    id: 'MegaMix',
    name: 'MegaMix',
  },
  {
    description:
      '3 pre-mixes and 3 post-mixes of the sample being transferred.  No tip reuse permitted.',
    id: 'NeedToMix',
    name: 'NeedToMix',
  },
  {
    description:
      'Designed for plating out cultures onto agar plates. Dispense will be performed at the well bottom and no blowout will be performed (to minimise risk of cross contamination)',
    id: 'plateout',
    name: 'PlateOut',
  },
  {
    description: '3 post-mixes of the sample being transferred.  No tip reuse permitted.',
    id: 'PostMix',
    name: 'PostMix',
  },
  {
    description: '3 pre-mixes of the sample being transferred.  No tip reuse permitted.',
    id: 'PreMix',
    name: 'PreMix',
  },
  {
    description:
      '3 post-mixes of the sample being transferred. Volume is adjusted based upon the volume of liquid in the destination well.  No tip reuse permitted.',
    id: 'SmartMix',
    name: 'SmartMix',
  },
  {
    description: 'Designed for handling solvents. No post-mixes are performed',
    id: 'solvent',
    name: 'Solvent',
  },
  {
    description:
      'Default policy designed for pipetting water, permitting multi-channel use. Includes a blowout step for added accuracy and no post-mixing.',
    id: 'water',
    name: 'Water',
  },
];

export default Policies;
