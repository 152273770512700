import React, { useCallback, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import * as filetreeClient from 'client/app/api/filetree';
import {
  QUERY_SIMULATION_NAME,
  QUERY_SIMULATION_NAME_BY_LEGACY_JOB_ID,
} from 'client/app/api/gql/queries';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import SimulationsDialog from 'client/app/components/Parameters/SimulationsDialog/SimulationsDialog';
import { ArrayElement, SimulationsForDialogQuery } from 'client/app/gql';
import getFileObjectFromPath from 'client/app/lib/file-browser/getFileObjectFromPath';
import { concatURL, isUUID } from 'common/lib/strings';
import { FileObject } from 'common/types/fileParameter';
import { ListResponse, parseFiletreeLink } from 'common/types/filetree';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import SelectFromDialogButton from 'common/ui/components/SelectFromDialogButton';
import { SimulationIcon } from 'common/ui/icons/SimulationIcon';

type SimulationForDialog = ArrayElement<
  SimulationsForDialogQuery['simulations']['items']
>;

type SimulationDetails = {
  JobID: string;
  WorkflowFile: FileObject;
  // Non-liquid handling workflows do not have actions/layout files.
  ActionsFile: FileObject | null;
  LayoutFile: FileObject | null;
  ElementOutputFiles: FileObject[];
};

type Props = {
  onChange: (simulationDetails?: SimulationDetails) => void;
} & ParameterEditorBaseProps<SimulationDetails>;

const DIALOG_PROPS = {};

async function getElementOutputFiles(
  linkToExpandedFolder: string,
  list: (filePath: string, cursor?: string) => Promise<ListResponse>,
) {
  // Output files are found in:
  // /expanded/<simulation specific folders>/<element output folder>/<files we need>
  const expandedFolder = await list(linkToExpandedFolder);

  const allFilesInExpandedFolder = await Promise.all(
    // Get all simulation specific folders.
    expandedFolder.items.map(async folder => {
      const simulationFolder = await list(folder.ftl);
      // Assume there is only one elementOutputFolder (Determined this empirically).
      const elementOutputFolder = await list(simulationFolder.items[0].ftl);

      // Get all files from the element output folder for the specific simulation.
      return elementOutputFolder.items.map(item => getFileObjectFromPath(item.ftl));
    }),
  );

  return allFilesInExpandedFolder.flat();
}

export default function SimulationParameter(props: Props) {
  const { value: simulationDetails, onChange, isDisabled } = props;

  const list = filetreeClient.useList();

  const [simulationName, setSimulationName] = useState<string>(
    'Loading simulation info...',
  );
  /**
   * Some legacy elements like "Get Simulation Details for Job" store the
   * Legacy Job ID as value for their parameters. The UI needs to show the name
   * of the simulation and we need to fetch it, using the Legacy Job ID.
   * TODO Drop `fetchSimulationByLegacyJobId` and the relative useEffect
   * once soon to be deprecated elements which use legacy Job IDs will be removed. (T2792)
   */
  const [getSimulationName, { data, loading, error }] = useLazyQuery(
    QUERY_SIMULATION_NAME,
    {
      variables: {
        id: (simulationDetails?.JobID ?? '') as SimulationId,
      },
    },
  );

  const [
    getLegacySimulationName,
    { data: legacyData, loading: legacyLoading, error: legacyError },
  ] = useLazyQuery(QUERY_SIMULATION_NAME_BY_LEGACY_JOB_ID, {
    variables: {
      id: simulationDetails?.JobID ?? '',
    },
  });
  useEffect(() => {
    if (isUUID(simulationDetails?.JobID ?? '')) {
      void getSimulationName();
    } else {
      void getLegacySimulationName();
    }
  }, [getLegacySimulationName, getSimulationName, simulationDetails]);

  // Fetch the simulation name and display it once ready.
  useEffect(() => {
    if (!loading && !error && data?.simulation.name) {
      setSimulationName(data.simulation.name);
    }
    if (!legacyLoading && !legacyError && legacyData?.simulationByLegacyJobId?.name) {
      setSimulationName(legacyData.simulationByLegacyJobId.name ?? 'Loading job info...');
    }
  }, [data, error, legacyData, legacyError, legacyLoading, loading]);

  const handleChange = useCallback(
    async (simulation: SimulationForDialog | typeof CANCEL_CHOICE | undefined) => {
      if (simulation === CANCEL_CHOICE) {
        return;
      }
      if (!simulation) {
        setSimulationName('');
        onChange(undefined);
        return;
      }
      const pathToWorkflowFile = concatURL(
        simulation.filetreeLink,
        'simulation/workflow/workflow.json',
      );
      const workflow = getFileObjectFromPath(pathToWorkflowFile);

      let actions = null;
      let layout = null;
      if (simulation.actionsLayoutFiletreeLink) {
        actions = getFileObjectFromPath(
          `${simulation.actionsLayoutFiletreeLink}/actions.json`,
        );
        layout = getFileObjectFromPath(
          `${simulation.actionsLayoutFiletreeLink}/layout.json`,
        );
      }

      let outputFiles: FileObject[] = [];
      try {
        const filetreePath = parseFiletreeLink(simulation.filetreeLink).path;
        outputFiles = await getElementOutputFiles(`${filetreePath}/expanded`, list);
      } catch (error) {
        // The simulation has no output files, that's okay.
        return;
      } finally {
        onChange({
          ElementOutputFiles: outputFiles,
          ActionsFile: actions,
          LayoutFile: layout,
          WorkflowFile: workflow,
          JobID: simulation.legacyJobId || simulation.id,
        });
      }
    },
    [list, onChange],
  );

  return (
    <SelectFromDialogButton
      value={simulationDetails?.JobID}
      selectedValueLabel={simulationName}
      dialog={SimulationsDialog}
      dialogProps={DIALOG_PROPS}
      placeholder="Select a simulation"
      onChange={handleChange}
      isDisabled={isDisabled}
      icon={<SimulationIcon />}
    />
  );
}
