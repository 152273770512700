import React, { useMemo } from 'react';

import RoboColumnLayoutDialog from 'client/app/components/Parameters/RoboColumnLayout/RoboColumnLayoutDialog';
import { AppIcon } from 'client/app/icons';
import { pluralize } from 'common/lib/format';
import { RoboColumnContent } from 'common/types/robocolumns';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import SelectFromDialogButton from 'common/ui/components/SelectFromDialogButton';

type Props = {
  onChange: (newValue?: Record<string, RoboColumnContent>) => void;
} & ParameterEditorBaseProps<Record<string, RoboColumnContent>>;

/**
 * Opens a dialog to configure the location and contents of robocolumns on a
 * plate.
 */
export default function RoboColumnLayoutEditor({ value, onChange, isDisabled }: Props) {
  // Summary text for a robocolumn layout, e.g. "6 Robocolumns".
  const label = useMemo(
    () => (value ? pluralize(Object.keys(value).length, 'RoboColumn') : undefined),
    [value],
  );

  const dialogProps = useMemo(() => ({ value, isDisabled }), [isDisabled, value]);

  const placeholder = useMemo(
    () => (isDisabled ? 'None Configured' : 'Configure Layout'),
    [isDisabled],
  );

  return (
    <SelectFromDialogButton
      selectedValueLabel={label}
      value={value}
      icon={<AppIcon iconId="antha:plate" />}
      dialog={RoboColumnLayoutDialog}
      dialogProps={dialogProps}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
}
