import React, { useCallback, useEffect, useRef, useState } from 'react';

import TextField from '@mui/material/TextField';

import Button from 'common/ui/components/Button';
import UIBox from 'common/ui/components/UIBox';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  onChange: (start: Date, end: Date) => void;
};

function getStringForDate(d: Date): string {
  const yyyy = d.getFullYear();
  let mm = (d.getMonth() + 1).toString();
  if (mm.length === 1) {
    mm = '0' + mm;
  }
  let dd = d.getDate().toString();
  if (dd.length === 1) {
    dd = '0' + dd;
  }
  return `${yyyy}-${mm}-${dd}`;
}

function getDateForString(ds: string): Date {
  const [yyyy, mm, dd] = ds.split('-');
  const d = new Date();
  d.setFullYear(parseInt(yyyy, 10));
  d.setMonth(parseInt(mm, 10) - 1);
  d.setDate(parseInt(dd, 10));
  return d;
}

const useStyles = makeStylesHook({
  controls: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
});

const TimeFilterPicker = function (props: Props) {
  const classes = useStyles();
  const startRef = useRef<HTMLInputElement | null>(null);
  const endRef = useRef<HTMLInputElement | null>(null);

  const defaultStart = new Date();
  const WEEK_DELTA = 1000 * 60 * 60 * 24 * 7;
  defaultStart.setTime(Date.now() - WEEK_DELTA);
  const defaultEnd = new Date();

  const [start, setStart] = useState(getStringForDate(defaultStart));
  const [end, setEnd] = useState(getStringForDate(defaultEnd));

  useEffect(() => {
    setStart(start);
    setEnd(end);
  }, [setStart, setEnd, start, end]);

  const handleStartDateChange = useTextFieldChange(setStart);

  const handleEndDateChange = useTextFieldChange(setEnd);

  const update = useCallback(() => {
    if (!startRef.current || !endRef.current) {
      return;
    }
    const startDate = getDateForString(startRef.current.value);
    const endDate = getDateForString(endRef.current.value);
    props.onChange(startDate, endDate);
  }, [props]);

  return (
    <div>
      <UIBox padding="vm">
        <TextField
          variant="standard"
          fullWidth
          inputRef={startRef}
          label="From"
          type="date"
          value={start}
          onChange={handleStartDateChange}
        />
      </UIBox>
      <UIBox padding="vm">
        <TextField
          variant="standard"
          fullWidth
          inputRef={endRef}
          label="To"
          type="date"
          value={end}
          onChange={handleEndDateChange}
        />
      </UIBox>
      <div className={classes.controls}>
        <Button variant="tertiary" size="small" onClick={update}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default TimeFilterPicker;
