import { useCallback } from 'react';

import { ApolloCache, useMutation } from '@apollo/client';

import { MUTATION_RENAME_BREADCRUMBS_ENTITY } from 'client/app/api/gql/mutations';
import { QUERY_BREADCRUMBS } from 'client/app/api/gql/queries';
import { BreadcrumbsEntityEnum } from 'client/app/gql';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export default function useRenameEntity() {
  const { showError } = useSnackbarManager();

  const [renameMutation] = useMutation(MUTATION_RENAME_BREADCRUMBS_ENTITY);
  return useCallback(
    (
        id: string,
        entity: BreadcrumbsEntityEnum,
        onRenameSuccess?: (newName: string) => void,
      ) =>
      async (newName: string) => {
        try {
          const { errors } = await renameMutation({
            variables: {
              id,
              entity,
              newName,
            },
            update: renameEntityInCache(id, entity, newName),
          });
          if (!errors) {
            onRenameSuccess?.(newName);
          } else {
            errors.forEach(error => {
              showError(error.message);
            });
          }
        } catch {
          showError(`Could not rename ${entity}`);
        }
      },
    [renameMutation, showError],
  );
}

export function useRenameMethodVisapp() {
  const { showError } = useSnackbarManager();

  const [renameMutation] = useMutation(MUTATION_RENAME_BREADCRUMBS_ENTITY);
  return useCallback(
    async (newName: string) => {
      try {
        /**
         * We need to use the method_id in the window.location because it can be different
         * from the entity id in Breadcrumbs.
         */
        const urlParams = window.location.hash.includes('?')
          ? window.location.hash.split('?')[1]
          : '';
        const queryParams = new URLSearchParams(urlParams);
        const methodId = queryParams.get('method_id');

        if (!methodId) {
          showError('Could not find method_id in URL');
          return;
        }

        const { errors } = await renameMutation({
          variables: {
            id: methodId,
            entity: BreadcrumbsEntityEnum.VIS,
            newName,
          },
          update: renameEntityInCache(methodId, BreadcrumbsEntityEnum.VIS, newName),
        });
        if (errors) {
          errors.forEach(error => {
            showError(error.message);
          });
        } else {
          /**
           * We need to notify visserver that we have updated a method name so it can
           * correctly update the names displayed.
           */
          const iframe = document.getElementById(
            'visserver-iframe',
          ) as HTMLIFrameElement | null;
          if (iframe) {
            iframe.contentWindow?.postMessage({ name: 'methodUpdated', payload: {} });
          }
        }
      } catch {
        showError(`Could not rename ${BreadcrumbsEntityEnum.VIS}`);
      }
    },
    [renameMutation, showError],
  );
}

function renameEntityInCache(id: string, entity: BreadcrumbsEntityEnum, newName: string) {
  return function update(cache: ApolloCache<any>) {
    cache.updateQuery(
      {
        query: QUERY_BREADCRUMBS,
        variables: { id, entity },
      },
      data =>
        !data
          ? data
          : {
              ...data,
              breadcrumbs: data.breadcrumbs.map(crumb =>
                crumb.entity === entity
                  ? {
                      ...crumb,
                      items: crumb.items.map(item =>
                        item.id === id ? { ...item, name: newName } : item,
                      ),
                    }
                  : crumb,
              ),
            },
    );
  };
}
