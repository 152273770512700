import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';

import { UserProfileType } from 'client/app/hooks/useUserProfile';
import { getEnvName, isLocalDev } from 'common/ui/lib/envs';

// Start different error handlers
export function startErrorHandler({
  service,
  commitSHA,
  location,
}: {
  service: string;
  version: string;
  commitSHA: string;
  location: Location;
}) {
  Sentry.init({
    dsn: isLocalDev(location)
      ? // https://synthace.sentry.io/settings/projects/synthace-client-localdev/
        'https://31a691741357434c99325b39e3d37ecc@o371134.ingest.us.sentry.io/5182882'
      : // https://synthace.sentry.io/projects/antha-platform/
        'https://815405b1f2fd4a52975143e33897478a@o371134.ingest.sentry.io/5216857',
    debug: isLocalDev(location),
    // Releases are global per organization so we need to tag the service (antha-platform)
    release: `${service}@${commitSHA}`,
    // Disable breadcrumbs to avoid collecting personal users' data.
    maxBreadcrumbs: 0,
    // Enable for all envs that is not localhost unless Sentry is enabled manually.
    // We are passing along unknown hosts because they might be envs that we will be interested in.
    enabled: !isLocalDev(location),
    environment: getEnvName(location),

    integrations: [
      // Log all console errors
      new CaptureConsole({ levels: ['error'] }),
    ],

    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications.',
      'ResizeObserver loop limit exceeded.',
    ],
  });
}

export function setUser(profile: UserProfileType) {
  Sentry.configureScope(function (scope) {
    scope.setUser({ email: profile.email, id: profile.id });
    scope.setTag('org_human_id', profile.organisationHumanIdentifier);
  });
}

export function reportError(error: Error) {
  console.error(error);
  Sentry.captureException(error);
}
