import React, { createContext, FC, useContext } from 'react';

import { useUpdateProtocolInstance } from 'client/app/apps/protocols/api/ProtocolsAPI';
import { useGetDataAndInitialiseState } from 'client/app/apps/protocols/useGetData';
import UIErrorBox from 'client/app/components/UIErrorBox';
import { ProtocolInstanceQuery } from 'client/app/gql';
import ParameterStateContextProvider from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { WorkflowConfig } from 'common/types/bundle';
import { ProtocolStep } from 'common/types/Protocol';
import { Schema } from 'common/types/schema';
import LinearProgress from 'common/ui/components/LinearProgress';

type ProtocolInstanceContextType = {
  protocolInstance: NonNullable<ProtocolInstanceQuery['protocolInstance']['instance']>;
  protocol: NonNullable<
    ProtocolInstanceQuery['protocolInstance']['instance']['protocol']
  >;
  steps: ProtocolStep[];
  workflowConfig: WorkflowConfig;
  workflowSchema: Schema | undefined;
  hasInputsAndOutputs: boolean;
  updatingProtocolInstanceLoading: boolean;
  parametersLoading: boolean;
  updateProtocolInstance: (
    protocolInstance: NonNullable<ProtocolInstanceQuery['protocolInstance']['instance']>,
  ) => Promise<void>;
};

export const ProtocolInstanceContext = createContext<
  ProtocolInstanceContextType | undefined
>(undefined);

type ProtocolInstanceProviderProps = {
  protocolInstanceId: ProtocolInstanceId;
};

export const useProtocolInstanceContext = () => {
  const context = useContext(ProtocolInstanceContext);

  if (context === undefined) {
    throw new Error(
      'useProtocolInstanceContext must be used within a ProtocolInstanceProvider',
    );
  }

  return context;
};

export const ProtocolInstanceProvider: FC<ProtocolInstanceProviderProps> = ({
  protocolInstanceId,
  children,
}) => {
  const result = useGetDataAndInitialiseState(protocolInstanceId);

  const elementInstances = useWorkflowBuilderSelector(state => state.elementInstances);
  const parameters = useWorkflowBuilderSelector(state => state.parameters);
  const { config: workflowConfig } = useWorkflowBuilderSelector(state => state);

  const {
    handleUpdateProtocolInstance: updateProtocolInstance,
    loading: updatingProtocolInstanceLoading,
  } = useUpdateProtocolInstance();

  if (result.status === 'error') {
    return (
      <UIErrorBox>
        Sorry we could not load the protocol instance: {result.error.message}
      </UIErrorBox>
    );
  }

  if (result.status === 'protocol-instance-loading') return <LinearProgress />;

  const protocolInstance = result.protocolInstance.instance;
  const workflowSchema = protocolInstance.protocol.workflow.workflow.Schema;

  const hasInputsAndOutputs = !!(workflowSchema?.inputs && workflowSchema?.outputs);
  const protocol = protocolInstance.protocol;
  const steps = protocol.protocol.steps;
  const parametersLoading = result.status === 'parameters-loading';

  const state = {
    protocolInstance,
    protocol,
    workflowSchema,
    workflowConfig,
    steps,
    hasInputsAndOutputs,
    updatingProtocolInstanceLoading,
    parametersLoading,
    updateProtocolInstance,
  };

  return (
    <ParameterStateContextProvider
      parameters={parameters}
      elementInstances={elementInstances}
      connections={[]} // Uneccessary for Protocols app.
    >
      <ProtocolInstanceContext.Provider value={state}>
        {children}
      </ProtocolInstanceContext.Provider>
    </ParameterStateContextProvider>
  );
};
