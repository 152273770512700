import React, { forwardRef, useMemo } from 'react';

import HelpIcon from '@mui/icons-material/HelpOutline';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import {
  getReleaseQualityLabel,
  INSTANCE_PARAMETERS_LABEL,
} from 'client/app/apps/workflow-builder/lib/ReleaseQualityIndicator';
import AnnotationButton from 'client/app/components/ElementPlumber/AnnotationButton';
import StatusBar from 'client/app/components/ElementPlumber/ElementInstanceStatus';
import Port from 'client/app/components/ElementPlumber/Port';
import { PendingConnection } from 'client/app/components/ElementPlumber/WorkflowLayout';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import {
  ConnectablePortMap,
  ConnectionStartCallback,
  getConnectedPortsForInstance,
  getOppositeSide,
  isPortConnectable,
  Side,
} from 'client/app/lib/layout/ConnectionHelper';
import {
  ELEMENT_INSTANCE_HEADER_HEIGHT,
  ELEMENT_INSTANCE_HEADER_MARGIN,
  ELEMENT_INSTANCE_PORT_HEIGHT_WITH_MARGIN,
  getPortLocalPosition,
} from 'client/app/lib/layout/LayoutHelper';
import { permittedTypeBasedConnections } from 'client/app/lib/layout/ValidConnection';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { isDefined } from 'common/lib/data';
import doNothing from 'common/lib/doNothing';
import stopPropagation from 'common/lib/stopPropagation';
import {
  Connection,
  ElementInstance,
  ElementInstanceStatus,
  Parameter,
  Terminus,
} from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useContextMenu, { ContextMenuOption } from 'common/ui/hooks/useContextMenu';
import BranchIcon from 'common/ui/icons/BranchIcon';
import DOEIcon from 'common/ui/icons/DOEIcon';

type Props = {
  elementInstance: ElementInstance;
  allConnections: Connection[];
  portMap: ConnectablePortMap;
  pendingConnection: PendingConnection | null;
  nearestConnection: Connection | null;
  isSelected: boolean;
  isDisabled: boolean;
  isEligibleForDOE: boolean;
  isLoading: boolean;
  hasError: boolean;
  isReadOnly: boolean;
  showStatus: boolean;
  status?: ElementInstanceStatus;
  onInfoIconClick: () => void;
  onConnectionStart?: ConnectionStartCallback;
  onDragStart?: (e: React.PointerEvent<HTMLElement>) => void;
  onDrag?: (e: React.PointerEvent<HTMLElement>) => void;
  OnDragEnd?: (e: React.PointerEvent<HTMLElement>) => void;
  onAnnotate?: (annotation: string | undefined) => void;
  onDelete?: () => void;
};

const MIN_INSTANCE_WHITESPACE = 40;

const ElementInstanceBody = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const contextMenuOptions: ContextMenuOption[] = useMemo(
    () =>
      props.onDelete
        ? [
            {
              label: 'Delete',
              action: props.onDelete,
            },
          ]
        : [],
    [props.onDelete],
  );

  const classes = useStyles();
  // Release quality is only shown to Synthace employees.
  const userProfile = useUserProfile();

  const isTypeConfigConnectionSettingEnabled = useFeatureToggle(
    'TYPE_CONFIGURATION_CONNECTION_SETTINGS',
  );
  const isEnabledDOE = useFeatureToggle('NEW_DOE');
  const { isUsedForDOE, isBuilderInModeDOE } = useCheckForDOE(props.elementInstance.name);

  const renderPort = (
    portDetails: Parameter,
    displayName: string | undefined,
    side: Side,
    index: number,
    isConnected: boolean,
    onConnectionStart?: (startSide: Side, startPort: Parameter) => void,
  ) => {
    const { pendingConnection, elementInstance, portMap, nearestConnection } = props;

    const isConnecting =
      pendingConnection?.elementInstance === elementInstance &&
      pendingConnection?.port === portDetails;

    const isSiblingConnecting =
      pendingConnection?.elementInstance === elementInstance && !isConnecting;

    const oppositeSide = getOppositeSide(side);

    // Ports that can be part of a connection are visually differentiated,
    // irrespective of whether all of the other ports it could connect to
    // are currently being used as part of an existing connection.

    let elementInstancesWithConnectablePorts = new Set();

    if (isTypeConfigConnectionSettingEnabled) {
      // We find instances with ports that can connect in one go rather than splitting them into
      // arrays of matching and non-matching types.
      const allowedTypes = [
        portDetails.type,
        ...(portDetails.configuration?.connections?.allowedTypes ?? []),
      ];
      if (side === 'input') {
        elementInstancesWithConnectablePorts = new Set(
          allowedTypes
            ?.map(allowedType => portMap[oppositeSide][allowedType])
            .filter(isDefined)
            .flatMap(elementInstanceSet => Array.from(elementInstanceSet)) ?? [],
        );
      }
      if (side === 'output') {
        // The port map entry for a type already contains element instances with ports that can connect on the
        // basis of a type config allowedTypes setting as well as ones that match on the basis of them sharing
        // the same type, so this call is more-straightfoward.
        elementInstancesWithConnectablePorts =
          portMap[oppositeSide][portDetails.type] ?? new Set();
      }
    } else {
      // Chris' (tweaked) method finds element instances with ports that have a matching type first, then
      // ports that can connect with different types, then merges them.

      // One port can connect to another of the same type if it is on the opposite
      // side of a different element instance. So first, find which instances have
      // matching ports, if any.
      const elementInstancesWithPortsOfMatchingType = Array.from(
        portMap[oppositeSide][portDetails.type] ?? [],
      );

      // Some ports may also be connected with inputs and outputs with non-matching types;
      // so we'll check for those here.
      const elementInstancesWithPortsOfConnectableAsymmetricType =
        elementInstancesWithAsymmetricConnectionPorts(portDetails, portMap, side);

      elementInstancesWithConnectablePorts = new Set([
        ...elementInstancesWithPortsOfMatchingType,
        ...elementInstancesWithPortsOfConnectableAsymmetricType,
      ]);
    }

    // If there are any instances with matching ports, check whether there's at
    // least one that isn't the one we're trying to connect from.

    const canConnect =
      elementInstancesWithConnectablePorts.size > 1 ||
      (elementInstancesWithConnectablePorts.size === 1 &&
        !elementInstancesWithConnectablePorts.has(props.elementInstance.Id));

    const isTerminusInNearestConnection = (terminus?: Terminus) =>
      terminus?.ElementInstance === elementInstance.name &&
      terminus?.ParameterName === portDetails.name;

    const isPartOfNearestConnection =
      side === 'input'
        ? isTerminusInNearestConnection(nearestConnection?.Target)
        : isTerminusInNearestConnection(nearestConnection?.Source);

    const portLocation = getPortLocalPosition(side, index);

    return (
      <Port
        key={`${portDetails.name}-${portDetails.type}-${side}`}
        data={portDetails}
        displayName={displayName}
        top={portLocation.y}
        left={portLocation.x}
        side={side}
        isConnecting={isConnecting}
        isSiblingConnecting={isSiblingConnecting}
        isPartOfNearestConnection={isPartOfNearestConnection}
        isConnected={isConnected}
        isDisabled={props.isDisabled}
        isModeDOE={isBuilderInModeDOE}
        canConnect={canConnect}
        pendingConnection={props.pendingConnection}
        onConnectionStart={onConnectionStart}
        elementId={elementInstance.Id}
      />
    );
  };

  const { elementInstance } = props;
  const { element } = elementInstance;
  const onConnectionStart = props.onConnectionStart?.bind(null, elementInstance);
  let tallestSideLength = 0;
  let renderedPorts: JSX.Element[] = [];

  const isElementFromBranch = useWorkflowBuilderSelector(
    state => !state.elementSet?.isRelease,
  );

  const connectedPorts = getConnectedPortsForInstance(
    elementInstance,
    props.allConnections,
  );

  if (element.inputs?.length) {
    const is = element.inputs
      .filter(input => connectedPorts.inputs.has(input.name) || isPortConnectable(input))
      .map((input, index) => {
        const connected = connectedPorts.inputs.has(input.name);
        const displayName = input.configuration?.displayName;
        return renderPort(
          input,
          displayName,
          'input',
          index,
          connected,
          onConnectionStart,
        );
      });
    tallestSideLength = is.length;
    renderedPorts = renderedPorts.concat(is);
  }

  if (element.outputs?.length) {
    const os = element.outputs
      .filter(
        output => connectedPorts.outputs.has(output.name) || isPortConnectable(output),
      )
      .map((output, index) => {
        const isConnected = connectedPorts.outputs.has(output.name);
        const displayName = output.configuration?.displayName;
        return renderPort(
          output,
          displayName,
          'output',
          index,
          isConnected,
          onConnectionStart,
        );
      });
    tallestSideLength = Math.max(tallestSideLength, os.length);
    renderedPorts = renderedPorts.concat(os);
  }

  const bodyHeight = Math.max(
    MIN_INSTANCE_WHITESPACE,
    ELEMENT_INSTANCE_PORT_HEIGHT_WITH_MARGIN * tallestSideLength,
  );
  const totalElementHeight =
    bodyHeight + ELEMENT_INSTANCE_HEADER_HEIGHT + 2 * ELEMENT_INSTANCE_HEADER_MARGIN;

  const [contextMenu, openContextMenu] = useContextMenu(contextMenuOptions);
  const handleOpenContextMenu = useMemo(() => {
    return props.isReadOnly ? doNothing : openContextMenu;
  }, [openContextMenu, props.isReadOnly]);

  const isReleaseQualityEnabled = useFeatureToggle('SHOW_ELEMENT_RELEASE_QUALITY');
  const releaseQualityHeading = isReleaseQualityEnabled
    ? getReleaseQualityLabel(
        element.releaseQuality as ReleaseQualityEnum,
        userProfile?.isSynthaceEmployee ?? false,
      )
    : null;

  return (
    <>
      <Paper
        ref={ref}
        onPointerDown={props.onDragStart}
        onPointerMove={props.onDrag}
        onPointerUp={props.OnDragEnd}
        onContextMenu={handleOpenContextMenu}
        style={{ height: `${totalElementHeight}px` }}
        className={cx(classes.instanceBody, {
          [classes.halfOpacity]: props.pendingConnection,
        })}
      >
        <div
          className={cx(classes.instanceHeader, {
            [classes.selectedInstanceHeader]: props.isSelected,
            [classes.errorInstanceHeader]: props.hasError,
          })}
        >
          <div className={classes.headerInfo}>
            {isReleaseQualityEnabled &&
              userProfile?.isSynthaceEmployee &&
              isElementFromBranch && (
                <BranchIcon
                  className={cx(classes.headerIcon, classes.releaseQualityLabel)}
                />
              )}
            {releaseQualityHeading &&
            releaseQualityHeading !== INSTANCE_PARAMETERS_LABEL ? (
              <Typography variant="caption" className={classes.releaseQualityLabel}>
                {releaseQualityHeading}
              </Typography>
            ) : null}
          </div>

          {/** Stop propagation so that clicking these icons won't select the element */}
          <div className={classes.headerInfo} onPointerDown={stopPropagation}>
            {props.onAnnotate && (
              <AnnotationButton
                elementInstance={elementInstance}
                onAnnotate={props.onAnnotate}
              />
            )}
            <div className={classes.iconShim} onPointerDown={props.onInfoIconClick}>
              <HelpIcon className={classes.headerIcon} />
            </div>
          </div>
        </div>

        {isEnabledDOE && props.isEligibleForDOE && (
          <div
            className={classes.instanceWhitespace}
            style={{
              height: `${totalElementHeight - ELEMENT_INSTANCE_HEADER_HEIGHT}px`,
            }}
          >
            <span
              className={cx(classes.iconDOE, {
                [classes.usedForDOE]: isUsedForDOE,
              })}
            >
              <DOEIcon />
            </span>
          </div>
        )}
      </Paper>
      <StatusBar
        showStatus={props.showStatus}
        status={isBuilderInModeDOE ? 'neutral' : props.status}
        isLoading={props.isLoading}
      />
      {contextMenu}
      {renderedPorts}
    </>
  );
});

export default React.memo(ElementInstanceBody);

const useStyles = makeStylesHook(({ spacing, palette }) => ({
  instanceBody: {
    borderRadius: '6px',
    background: Colors.WHITE,
  },
  instanceHeader: {
    background: Colors.GREY_80,
    color: Colors.WHITE,
    height: ELEMENT_INSTANCE_HEADER_HEIGHT,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '6px 6px 0 0',
  },
  instanceWhitespace: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedInstanceHeader: {
    background: Colors.BLUE_80,
    boxShadow: `0 -0.5px 0 0.5px ${Colors.BLUE_80}`,
  },
  errorInstanceHeader: {
    background: Colors.ERROR_MAIN,
    boxShadow: `0 -0.5px 0 0.5px ${Colors.ERROR_MAIN}`,
  },
  iconShim: {
    cursor: 'pointer',
    display: 'flex',
    marginRight: spacing(2),
  },
  headerIcon: {
    height: '14px',
    width: '14px',
  },
  headerInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  halfOpacity: {
    opacity: 0.5,
  },
  releaseQualityLabel: {
    marginLeft: spacing(2),
  },
  iconDOE: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: '32px',
    height: '32px',

    border: `1px dashed ${Colors.GREY_40}`,
    borderRadius: '50%',
    color: palette.text.secondary,

    fontSize: '14px',
    '& svg': {
      fontSize: 'inherit',
    },
  },
  usedForDOE: {
    width: '28px',
    height: '28px',

    border: `1px solid ${palette.secondary.dark}`,
    color: palette.secondary.dark,
  },
}));

function useCheckForDOE(elementInstanceName: string) {
  const workflowBuilderMode = useWorkflowBuilderSelector(state => state.mode);
  const factors = useWorkflowBuilderSelector(state => state.factors);
  const isUsedForDOE = useMemo(
    () =>
      factors
        ? factors.some(
            factor => factor.path?.[1] === elementInstanceName && factor.included,
          )
        : false,
    [elementInstanceName, factors],
  );
  return {
    isBuilderInModeDOE: workflowBuilderMode === 'DOE',
    isUsedForDOE,
  };
}

/**
 * If the connection is not a direct type match then there are some white listed
 * exceptions which may be wired together.
 * This function checks whether two types are compatible based on some whitelisted
 * pairings which are permitted.
 * Note: the element config phase 3 should replace this functionality of policing
 * directional asymmetrical type connections.
 */
function elementInstancesWithAsymmetricConnectionPorts(
  parameter: Parameter,
  portMap: ConnectablePortMap,
  side: Side,
): string[] {
  const oppositeSide = getOppositeSide(side);

  // example type: github.com/Synthace/antha/antha/anthalib/wtype.Plates
  // if a complement to parameter is in the outputs then check if parameter is an input
  if (side === 'output') {
    const result = Object.entries(permittedTypeBasedConnections)
      .filter(([_, allowedOutputTypes]) => allowedOutputTypes.includes(parameter.type))
      .flatMap(([inputType, _]) => Array.from(portMap[oppositeSide][inputType] ?? []));
    return result;
  }

  // check if parameter itself is a whitelisted type present in this side,
  // and check opposite side for complementary types (i.e. as an output of another element)
  const allowedNonMatchingOutputTypes = permittedTypeBasedConnections[parameter.type];
  if (!allowedNonMatchingOutputTypes) {
    return [];
  }
  const result = allowedNonMatchingOutputTypes.flatMap(outputType => {
    const arr = Array.from(portMap[oppositeSide][outputType] ?? []);
    return arr;
  });
  return result;
}
