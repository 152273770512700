import { ParameterStateRuleContext as RuleContext } from 'client/app/lib/rules/elementConfiguration/evaluateParameterState';
import { Connection, ElementInstance } from 'common/types/bundle';
import {
  AreParametersConnectedCondition,
  AreParametersSetCondition,
  isParameterValueType,
  ParameterValueCompareCondition,
} from 'common/types/elementConfiguration';

export function compareParameterValue(
  context: RuleContext,
  condition: ParameterValueCompareCondition,
): boolean {
  const { parameterName, operator, value } = condition;
  const parameterValue = context.parameterValues?.[parameterName];

  const targetValue = isParameterValueType(value)
    ? context.parameterValues?.[value.parameterName]
    : value.constValue;

  switch (operator) {
    case 'equals':
      return parameterValue === targetValue;
    case 'not-equals':
      return parameterValue !== targetValue;
    case 'greater':
      return parameterValue > targetValue;
    case 'greater-or-equal':
      return parameterValue >= targetValue;
    case 'less':
      return parameterValue > targetValue;
    case 'less-or-equal':
      return parameterValue <= targetValue;
  }
}

export function areParametersSet(
  context: RuleContext,
  condition: AreParametersSetCondition,
): boolean {
  return condition.parameterNames.every(parameterName => {
    const value = context.parameterValues?.[parameterName];
    return value !== undefined && value !== null && value !== false && value !== '';
  });
}

export function areParametersConnected(
  context: RuleContext,
  condition: AreParametersConnectedCondition,
): boolean {
  return condition.parameterNames.every(parameterName =>
    isParameterConnected(parameterName, context.connections, context.elementInstance),
  );
}

function isParameterConnected(
  parameterName: string,
  // TODO: improve evaluation speed by building connections map by element instance name
  // or by filtering out only connections for the evaluated element instance
  connections: Connection[],
  elementInstance: ElementInstance,
) {
  return connections.some(
    connection =>
      (connection.Source.ElementInstance === elementInstance.name &&
        connection.Source.ParameterName === parameterName) ||
      (connection.Target.ElementInstance === elementInstance.name &&
        connection.Target.ParameterName === parameterName),
  );
}
