import React from 'react';

import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { AccessibleDeviceSetup } from 'common/types/device';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

/**
 * The object `AccessibleDeviceSetup` in theory stores a totally free-form
 * dictionary of key-value pairs. We could have a generic UI which allows
 * the user to add custom key-value pairs. Ideally the keys allowed in the
 * UI would be based on the type of device.
 *
 * In practice as of Jan 2021, the `AccessibleDeviceSetup` is only used
 * for Hamilton liquid handlers, and we know that Hamilton liquid handlers
 * support these exact props below. The UI is therefore hardcoded to allow
 * editing these exact props. It is simpler to implement, and better UX than
 * a generic key-value pair editor.
 */
export const KNOWN_DEVICE_SPECIFIC_PROPS: { [propName: string]: string } = {
  retractDistance: 'Retract distance',
  liftUpHeight: 'Lift up height',
};

type Props = {
  deviceSpecificProps: AccessibleDeviceSetup['deviceSpecific'];
  disabled?: boolean;
  onDeviceSpecificPropsChange: (
    deviceSpecificProps: AccessibleDeviceSetup['deviceSpecific'],
  ) => void;
};

export default function AccessibleDeviceSetupTabDeviceSpecificProps({
  deviceSpecificProps,
  onDeviceSpecificPropsChange,
  disabled,
}: Props) {
  const classes = useStyles();

  const handleUpdateRetractDistance = useTextFieldChange((retractDistance: string) => {
    onDeviceSpecificPropsChange({ ...deviceSpecificProps, retractDistance });
  });

  const handleUpdateLiftUpHeight = useTextFieldChange((liftUpHeight: string) => {
    onDeviceSpecificPropsChange({ ...deviceSpecificProps, liftUpHeight });
  });

  return (
    <div className={classes.tabDeviceSpecific}>
      <Typography className={classes.tabTitle}>
        Additional details for reaching the device
      </Typography>
      <div className={classes.inputs}>
        <div className={classes.input}>
          <InputLabel shrink>{KNOWN_DEVICE_SPECIFIC_PROPS.retractDistance}</InputLabel>
          <TextField
            variant="standard"
            fullWidth
            value={deviceSpecificProps.retractDistance}
            disabled={disabled}
            placeholder="Example: 20mm"
            onChange={handleUpdateRetractDistance}
          />
        </div>
        <div className={classes.input}>
          <InputLabel shrink>{KNOWN_DEVICE_SPECIFIC_PROPS.liftUpHeight}</InputLabel>
          <TextField
            variant="standard"
            fullWidth
            value={deviceSpecificProps.liftUpHeight}
            disabled={disabled}
            placeholder="Example: 20mm"
            onChange={handleUpdateLiftUpHeight}
          />
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  tabDeviceSpecific: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  tabTitle: {
    marginBottom: theme.spacing(6),
  },
  inputs: {
    display: 'flex',
  },
  input: {
    marginRight: theme.spacing(6),
  },
}));
