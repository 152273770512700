import { useEffect, useMemo, useRef } from 'react';

// Can't find a way to import this from a sub-package
// eslint-disable-next-line no-restricted-imports
import { DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';

type Callback<T extends any[]> = (...args: T) => any;

/**
 * A debounced callback has no return value because the callback is deferred by a timeout.
 */
type DebouncedCallback<T extends any[]> = DebouncedFunc<(...args: T) => void>;

/**
 * Debounce a callback such that it runs after x ms. Use like:
 *
 * ```
 * const doSomething = useCallback(() => {
 *   frequentOperation();
 * }, [frequentOperation]);
 * const debouncedDoSomething = useDebounce(doSomething, 1000);
 * ```
 *
 * We cannot simply do `useCallback(debounce(...), [deps...])` because the debounce will
 * not get cancelled when the component unmounts.
 */
export default function useDebounce<T extends any[]>(
  callback: Callback<T>,
  delay: number = 0,
): DebouncedCallback<T> {
  // Keep the callback in a reference accessible by the debounced function
  const savedCallback = useRef<Callback<T>>(callback);
  // Reference to the debounced function. The initial noop value will be immediately
  // replaced by the useEffect below.
  const debouncedCallback = useRef<DebouncedCallback<T>>();

  // Each time the callback changes, update the callback ref.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // On initialisation and each time the delay changes, create the debounced function.
  useEffect(() => {
    const debounced = debounce((...args: T) => savedCallback.current(...args), delay);
    debouncedCallback.current = debounced;
    // When the debounced function is replaced or the component is unmounted, the current
    // debounce should be cancelled.
    return () => debounced.cancel();
  }, [delay]);

  // When invoked, call the debounced version of the callback
  return useMemo(() => {
    const result = Object.assign(
      (...args: T) => {
        return debouncedCallback.current?.(...args);
      },
      {
        cancel: () => debouncedCallback.current?.cancel(),
        flush: () => debouncedCallback.current?.flush(),
      },
    );
    return result;
  }, []);
}
