import React from 'react';

import { Route, RouteComponentProps } from 'react-router-dom';

import VisView from 'client/app/apps/Visualization/VisView';
import { visualizationRoutes, VisViewRouteProps } from 'client/app/lib/nav/actions';

/**
 * Launches visserver in an iframe
 */
export default function VisViewApp() {
  return (
    <Route
      exact
      path={visualizationRoutes.visView.pathTemplate}
      render={(props: RouteComponentProps<VisViewRouteProps>) => (
        <VisView
          visserverApp={props.match.params.visserverApp}
          params={new URLSearchParams(props.location.search)}
        />
      )}
    />
  );
}
