import React, { useMemo } from 'react';

import { groupBy } from 'common/lib/data';
import { DeckItem } from 'common/types/mix';
import { MixPreviewStep } from 'common/types/mixPreview';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import Carrier, { isCarrier } from 'common/ui/components/simulation-details/mix/Carrier';
import { getDeckItemStates } from 'common/ui/components/simulation-details/mix/deckContents';
import DeckLayout, {
  DeckPositionRect,
} from 'common/ui/components/simulation-details/mix/DeckLayout';
import DeckPositionView from 'common/ui/components/simulation-details/mix/DeckPositionView';
import ExtraDeckAnnotationView, {
  isExtraDeckAnnotationType,
} from 'common/ui/components/simulation-details/mix/ExtraDeckAnnotation';
import { MixState } from 'common/ui/components/simulation-details/mix/MixState';
import { PlateSettings } from 'common/ui/components/simulation-details/mix/MixView';
import PositionGroupView from 'common/ui/components/simulation-details/mix/PositionGroupView';

type Props = {
  deckLayout: DeckLayout;
  deckPositions: DeckPositionRect[];
  filteredState: MixState;
  steps?: MixPreviewStep[];
  highlightedDeckPositionName?: string;
  highlightedPlate?: string;
  plateSettings: Omit<PlateSettings, 'liquidColors'> & {
    liquidColorsOverride?: LiquidColors;
  };
  onDeckItemPointerUp?: (deckPositionName: string, item?: DeckItem) => void;
};

export default React.memo(function MixPositions({
  deckLayout,
  deckPositions,
  filteredState,
  steps,
  highlightedDeckPositionName,
  highlightedPlate,
  plateSettings,
  onDeckItemPointerUp,
}: Props) {
  const deckPositionGroups = deckLayout.getAllPositionGroups();
  // Deck positions used in the current step may be highlighted, such as tip cleaner
  const highlightedDeckPositions = new Set(
    highlightedDeckPositionName
      ? [highlightedDeckPositionName]
      : filteredState.highlightedDeckPositionNames,
  );

  const deckItemsByLocation = groupBy(
    filteredState.deck.items,
    'currentDeckPositionName',
  );

  const liquidColors = useMemo(
    () =>
      plateSettings.liquidColorsOverride ||
      LiquidColors.createUsingColorGraph(getDeckItemStates(deckLayout.deck), steps),
    [deckLayout.deck, plateSettings.liquidColorsOverride, steps],
  );

  return (
    <>
      {deckPositionGroups.map((deckPositionGroup, idx) => (
        <PositionGroupView key={idx} group={deckPositionGroup} />
      ))}
      {deckPositions.map(deckPosition => {
        if (isCarrier(deckPosition)) {
          return (
            <Carrier
              key={deckPosition.deckPositionName}
              deckPosition={deckPosition}
              highlight={highlightedDeckPositions.has(deckPosition.deckPositionName)}
            />
          );
        } else if (isExtraDeckAnnotationType(deckPosition)) {
          return (
            <ExtraDeckAnnotationView
              key={deckPosition.deckPositionName}
              deckPosition={deckPosition}
              highlight={highlightedDeckPositions.has(deckPosition.deckPositionName)}
            />
          );
        }
        const deckItems = deckItemsByLocation[deckPosition.deckPositionName];
        const isHighlighted =
          highlightedDeckPositions.has(deckPosition.deckPositionName) ||
          deckItems?.some(item => item.name === highlightedPlate);
        return (
          <DeckPositionView
            key={deckPosition.deckPositionName}
            deckPosition={deckPosition}
            deckItems={deckItems}
            deckLayout={deckLayout}
            currentStep={filteredState.stepIndex}
            isHighlighted={isHighlighted}
            onClick={onDeckItemPointerUp}
            plateSettings={{ ...plateSettings, liquidColors }}
          />
        );
      })}
    </>
  );
});
