import React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

import Button from 'common/ui/components/Button';

type Props = {
  title: string;
  message: string;
  onClose: () => void;
} & Pick<DialogProps, 'maxWidth'>;

// A simple dialog with a message and a Close button
export default function InfoDialog(props: Props) {
  const { message, title, maxWidth = 'xs', onClose } = props;
  return (
    <Dialog
      open
      maxWidth={maxWidth}
      fullWidth={false}
      // Needed for ESC key to close
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <StyledDialogContent>{message}</StyledDialogContent>
      <DialogActions>
        <Button variant="tertiary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  color: theme.palette.text.primary,
  whiteSpace: 'pre-line',
}));
