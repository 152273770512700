import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const TemplateWorkflowIcon = React.memo<SvgIconProps>(
  function TemplateWorkflowIcon(props) {
    return (
      <SvgIcon viewBox="0 0 24 24" {...props}>
        <svg width="24" height="24" fill="none">
          <path
            d="M4 6a1 1 0 00-1 1v14a1 1 0 001 1h12a1 1 0 001-1V7a1 1 0 00-1-1H4z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path fill="currentColor" d="M6 9h8v4H6z" />
          <path fill="currentColor" d="M11 9h3v4h-3zM11 19h3v-4h-3zM6 19h3v-4H6z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 17V3a2 2 0 00-2-2H10v2h10v14h2z"
            fill="currentColor"
          />
        </svg>
      </SvgIcon>
    );
  },
);
