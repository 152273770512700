import React, { useState } from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { FeatureTogglesFallback } from 'client/app/components/FeatureToggles/FeatureTogglesFallback';
import FeatureTogglesList from 'client/app/components/FeatureToggles/FeatureTogglesList';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

function FeatureTogglesApp() {
  const [needReload, setNeedReload] = useState(false);
  const classes = useStyles();
  const currentUser = useUserProfile();
  // Because the page doesn't automatically reload like the dialog, pretend the
  // list is visible the whole time so that it captures the status of feature
  // toggles once.
  const listVisible = true;
  return (
    <div className={classes.scrollable}>
      <Paper className={classes.mainContent}>
        {currentUser?.email.includes('synthace.com') ? (
          <>
            <Typography variant="h1" gutterBottom>
              Internal Tooling
            </Typography>
            <FeatureTogglesList
              isVisible={listVisible}
              onChange={setNeedReload}
              isInternalTooling
            />
            <Typography variant="h1" gutterBottom>
              Upcoming Features
            </Typography>
            <FeatureTogglesList
              isVisible={listVisible}
              onChange={setNeedReload}
              isInternalTooling={false}
            />

            {needReload && (
              <Typography variant="caption" align="center">
                Reload Antha to apply changes.
              </Typography>
            )}
          </>
        ) : (
          <FeatureTogglesFallback />
        )}
      </Paper>
    </div>
  );
}

const useStyles = makeStylesHook({
  scrollable: {
    overflow: 'auto',
  },
  mainContent: {
    margin: '50px auto',
    padding: '50px',
    width: '500px',
  },
});

export default FeatureTogglesApp;
