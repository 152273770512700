import React, { useCallback, useContext, useMemo } from 'react';

import { indexBy } from 'common/lib/data';
import FilterChip, {
  FilterChipWithPopoverProps,
} from 'common/ui/components/FilterChip/FilterChip';
import { FilterChipContext } from 'common/ui/components/FilterChip/FilterChipContext';
import Autocomplete, { Option } from 'common/ui/filaments/Autocomplete';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = FilterChipWithPopoverProps<string | undefined> & {
  dropdownOptions: Option<string>[];
  placeholder?: string;
};

/**
 * A FilterChip that has an autocomplete as its popover content.
 */
export default React.memo(function FilterChipWithAutocomplete(props: Props) {
  const {
    className,
    defaultChipLabel,
    dropdownOptions,
    filterValue,
    heading,
    isDisabled,
    onFilter,
    placeholder,
    size,
  } = props;
  const labelOptionMap = useMemo(
    () => indexBy(dropdownOptions, 'value'),
    [dropdownOptions],
  );
  const filterLabel = filterValue ? labelOptionMap[filterValue]?.label : undefined;

  // What happens when you clear the chip.
  const handleDelete = useCallback(() => {
    onFilter(undefined);
  }, [onFilter]);

  return (
    <FilterChip
      heading={heading}
      chipLabel={filterLabel ?? defaultChipLabel}
      filterValue={filterValue}
      className={className}
      onDelete={handleDelete}
      isActive={filterValue ? true : false}
      isDisabled={isDisabled}
      size={size}
      popoverContent={
        <PopoverContent
          filterLabel={filterLabel}
          filterValue={filterValue}
          dropdownOptions={dropdownOptions}
          onFilter={onFilter}
          placeholder={placeholder}
        />
      }
    />
  );
});

type PopoverProps = Pick<
  Props,
  'dropdownOptions' | 'onFilter' | 'placeholder' | 'filterValue'
> & {
  filterLabel?: string;
};

const PopoverContent = ({
  dropdownOptions,
  onFilter,
  placeholder,
  filterLabel,
  filterValue,
}: PopoverProps) => {
  const classes = useStyles();
  const { onClose } = useContext(FilterChipContext);
  const onChange = (value: string | undefined) => {
    if (value !== filterValue) {
      onFilter(value);
      onClose();
    }
  };

  return (
    <div className={classes.options}>
      <Autocomplete
        options={dropdownOptions}
        valueLabel={filterLabel ?? ''}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
      />
    </div>
  );
};

const useStyles = makeStylesHook(theme => ({
  options: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '276px',
    paddingTop: theme.spacing(3),
  },
}));
