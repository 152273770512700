import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';
import { GridAnnotationRect } from 'common/ui/components/simulation-details/mix/DeckLayout';

type Props = { gridPosition: GridAnnotationRect };

const StyledBox = styled(Box)({
  borderLeft: `1px dashed ${Colors.GREY_40}`,
  borderRight: `1px dashed ${Colors.GREY_40}`,
  borderTop: `2px solid ${Colors.GREY_40}`,
  borderBottom: `2px solid ${Colors.GREY_40}`,
  pointerEvents: 'none',
  position: 'absolute',
  display: 'flex',
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  alignSelf: 'flex-start',
  flex: 1,
  textAlign: 'center',
  padding: theme.spacing(4, 1, 4),
  margin: '0 -2px 0 -2px',
  borderRadius: '8px 8px 0 0',
}));

export default function GridSection({ gridPosition }: Props) {
  return (
    <StyledBox
      style={{
        left: gridPosition.absolutePosInDeckPixels.left,
        top: gridPosition.absolutePosInDeckPixels.top,
        width: gridPosition.absolutePosInDeckPixels.width,
        height: gridPosition.absolutePosInDeckPixels.height,
        background: gridPosition.offDeck ? `${Colors.GREY_10}` : `${Colors.GREY_20}`,
        borderLeft: gridPosition.left ? `2px solid ${Colors.GREY_40}` : ``,
        borderRight: gridPosition.right ? `2px solid ${Colors.GREY_40}` : ``,
      }}
    >
      <StyledTypography
        color="textPrimary"
        style={{
          transform: `translate(0, ${
            gridPosition.absolutePosInDeckPixels.height - 60
          }px)`,
          fontWeight: gridPosition.important ? `bold` : ``,
          fontSize: gridPosition.important ? `24px` : `20px`,
        }}
      >
        {gridPosition.label}
      </StyledTypography>
    </StyledBox>
  );
}
