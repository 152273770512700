import React from 'react';

import { alpha } from '@mui/material/styles';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import cx from 'classnames';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/**
 * This is a stylized version of the MUI TextField. It accepts the same props
 * and should be used whenever an input field is required.
 */
export default function TextField(props: TextFieldProps) {
  const classes = useStyles();
  const variant = props.variant ?? 'outlined';
  const size = props.size ?? 'small';

  // Workaround for https://github.com/mui/material-ui/issues/22462
  // variant "outline" does not support the `disableUnderline` prop
  if (
    variant === 'outlined' &&
    props.InputProps &&
    'disableUnderline' in props.InputProps
  ) {
    delete props.InputProps.disableUnderline;
  }

  return (
    <MuiTextField
      {...props}
      variant={variant}
      size={size}
      classes={{
        ...props.classes,
        root: cx(props?.classes?.['root'], classes.textFieldRoot),
      }}
      FormHelperTextProps={{
        ...props.FormHelperTextProps,
        classes: {
          ...props.FormHelperTextProps?.classes,
          root: cx(
            props.FormHelperTextProps?.classes?.['root'],
            classes.formHelperTextRoot,
          ),
        },
      }}
      InputProps={{
        ...props.InputProps,
        ...(variant === 'filled' ? { disableUnderline: true } : {}),
        classes: {
          ...props.InputProps?.classes,
          input: cx(props.InputProps?.classes?.['input'], classes.input, {
            [classes.translateY]: !props.label && variant === 'filled',
          }),
        },
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
        classes: {
          ...props.InputLabelProps?.classes,
          root: cx(props.InputLabelProps?.classes?.['root'], classes.inputLabeRoot),
        },
      }}
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  formHelperTextRoot: {
    color: theme.palette.text.secondary,
    marginLeft: 0,
    marginTop: theme.spacing(3),
  },
  input: {
    '&::placeholder': {
      color: theme.palette.text.secondary,
      opacity: 1,
    },
  },
  inputLabeRoot: {
    color: theme.palette.text.secondary,
    '&.Mui-focused': {
      color: Colors.BLUE_50,
    },
  },
  textFieldRoot: {
    // Styling the MUITextField is not straightforward and the accepted approach is to use MUI global
    // class names to target the desired variants and states.
    // See https://v4.mui.com/components/text-fields/#customized-inputs
    // Because the use of nested ThemeProviders results in MUI class names being appended with a unique number,
    // we can't rely on targetting the global class names specifically. Instead, we need to use the attribute
    // selector (*=) to check if classes contain the global class name. This is an accepted safe way
    // of dealing with this.
    // See https://stackoverflow.com/questions/60721323/how-reliable-are-mui-global-class-names-in-jss/60722943#60722943
    // In MUI V5, it is likely we will no longer need the attritbute selector, and can just reference
    // the class names specifically (see answer in SO link above).
    //
    // standard variant style overrides:
    '& [class*="MuiInput-underline"][class*="Mui-focused"]::after': {
      // We need this override in cases where there is an error, but the input is focused
      borderBottomColor: Colors.BLUE_60,
    },
    '& [class*="MuiInput-underline"]::before': {
      // Overrides the input border bottom in default state
      borderBottomColor: alpha(theme.palette.text.primary, 0.42),
    },
    // outlined variant style overrides:
    '& [class*="MuiOutlinedInput-root"]': {
      // Overrides the border in default state
      '& fieldset': {
        borderColor: Colors.GREY_30,
      },
    },
    '& [class*="MuiOutlinedInput-root"][class*="Mui-disabled"]': {
      // Overrides the border in disabled state
      '& fieldset': {
        borderColor: Colors.GREY_30,
      },
    },
    '& [class*="MuiOutlinedInput-root"][class*="Mui-focused"]': {
      // Overrides the border in focused state
      '& fieldset': {
        borderColor: Colors.BLUE_60,
      },
    },
    // filled variant style overrides:
    '& [class*="MuiFilledInput-root"]': {
      // Overrides the background and border radius in default state
      backgroundColor: Colors.GREY_10,
      borderRadius: '4px',
    },
  },
  translateY: {
    // This centres the input of the filled variant in the case of no label. It's an arbritrary
    // value that centres on the `medium` size, but the `small` size is adjusted proportionately
    // by the MUI in-built styles, so this can apply to `small` also.
    transform: 'translateY(-7px)',
  },
}));
