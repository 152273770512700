import React from 'react';

import TableCell, { TableCellProps } from '@mui/material/TableCell';
import cx from 'classnames';

import {
  CELL_BORDER,
  HEADER_CELL_BACKGROUND,
  HEADER_CELL_RADIUS,
} from 'common/ui/components/Table';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export function TableHeaderCell({ children, className, ...props }: TableCellProps) {
  const classes = useStyles();
  return (
    <TableCell {...props} className={cx(classes.cell, className)}>
      <div className={classes.cellInner}>{children}</div>
    </TableCell>
  );
}

const useStyles = makeStylesHook(theme => ({
  cell: {
    padding: theme.spacing(0, 2, 0, 2),
    border: 'none',
  },
  cellInner: {
    backgroundColor: HEADER_CELL_BACKGROUND,
    // Use shadow so text isn't offset by 1px
    boxShadow: `inset 0 0 0 1px ${CELL_BORDER}`,
    borderRadius: `${HEADER_CELL_RADIUS} ${HEADER_CELL_RADIUS} 0 0`,
    padding: theme.spacing(3),
    ...theme.typography.body2,
  },
}));
