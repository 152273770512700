import React, { useCallback, useMemo, useRef, useState } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import { QUERY_EXECUTIONS } from 'client/app/api/gql/queries';
import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import ListPageHeader from 'client/app/apps/experiments/ListPageHeader';
import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import { useUserList } from 'client/app/apps/experiments/useUserList';
import DeviceFilter from 'client/app/apps/plate-library/DeviceFilter';
import { ExecutionCard } from 'client/app/components/cards/ExecutionCard';
import {
  ExecutionsQuery,
  ExecutionsQueryVariables,
  ExecutionStatusEnum,
} from 'client/app/gql';
import usePagination from 'client/app/hooks/usePagination';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { formatShortTransitiveSimulationStatus } from 'client/app/lib/formatTransitiveSimulationStatus';
import { ScreenRegistry } from 'client/app/registry';
import { PageInfo } from 'common/server/graphql/pagination';
import { circularLoadingContainer } from 'common/ui/commonStyles';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import { EntityCardSkeletonList } from 'common/ui/components/EntityCard';
import FilterChipWithAutocomplete from 'common/ui/components/FilterChip/FilterChipWithAutocomplete';
import FilterChipWithCheckbox, {
  Option as FilterChipOption,
} from 'common/ui/components/FilterChip/FilterChipWithCheckbox';
import FilterChipWithDateRange, {
  DateRange,
  getDateFromTo,
  getDateRange,
} from 'common/ui/components/FilterChip/FilterChipWithDateRange';
import FilterChipWithSwitch from 'common/ui/components/FilterChip/FilterChipWithSwitch';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';
import SearchField from 'common/ui/components/SearchField';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

/** We return all this to the parent for convenience. */
export type ClickedExecution = {
  executionId: ExecutionId;
  simulationId: SimulationId;
};

type Props = {
  /**
   * - If specified each execution row will become clickable and invoke this
   *   callback when clicked. We don't automatically navigate anywhere.
   * - If not specified, each execution row will navigate to the Results screen
   *   when clicked.
   */
  onExecutionRowClick?: (clickedExecution: ClickedExecution) => void;
  disabledExecutionIds?: ExecutionId[];
};

type ExecutionFiltersProps = {
  searchQuery?: string;
  setSearchQuery: (value?: string) => void;
  filterUserId?: string;
  setFilterUserId: (value?: string) => void;
  dateFrom?: string;
  setDateFrom: (value?: string) => void;
  dateTo?: string;
  setDateTo: (value?: string) => void;
  filterStatuses?: string[];
  setFilterStatuses: (value?: string[]) => void;
  filterDeviceIds?: string[];
  setFilterDeviceIds: (value?: string[]) => void;
  filterHasData?: boolean;
  setFilterHasData: (value?: boolean) => void;
};

export function ExecutionListWithURLState(props: Props) {
  const [searchQuery, setSearchQuery] = useStateWithURLParams({
    paramName: 'search',
    paramType: 'string',
    defaultValue: '',
  });
  const currentUserId = useUserProfile()?.id;
  const [filterUserId, setFilterUserId] = useStateWithURLParams({
    paramName: 'user',
    paramType: 'string',
    defaultValue: currentUserId,
    emptyValue: 'all',
  });
  const [dateFrom, setDateFrom] = useStateWithURLParams({
    paramName: 'dateFrom',
    paramType: 'string',
    defaultValue: '',
  });
  const [dateTo, setDateTo] = useStateWithURLParams({
    paramName: 'dateTo',
    paramType: 'string',
    defaultValue: '',
  });
  const [filterStatuses, setFilterStatuses] = useStateWithURLParams({
    paramName: 'status',
    paramType: 'string[]',
    defaultValue: [],
  });
  const [filterDeviceIds, setFilterDeviceIds] = useStateWithURLParams({
    paramName: 'device',
    paramType: 'string[]',
    defaultValue: [],
  });
  const [filterHasData, setFilterHasData] = useStateWithURLParams({
    paramName: 'hasData',
    paramType: 'boolean',
    defaultValue: false,
  });

  const executionFiltersProps = {
    searchQuery,
    setSearchQuery,
    filterUserId,
    setFilterUserId,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    filterStatuses,
    setFilterStatuses,
    filterDeviceIds,
    setFilterDeviceIds,
    filterHasData,
    setFilterHasData,
  };
  return (
    <ExecutionListBase
      {...executionFiltersProps}
      onExecutionRowClick={props.onExecutionRowClick}
      disabledExecutionIds={props.disabledExecutionIds}
    />
  );
}

export default function ExecutionList(props: Props) {
  const [searchQuery, setSearchQuery] = useState<string | undefined>('');
  const currentUserId = useUserProfile()?.id;
  const [filterUserId, setFilterUserId] = useState<string | undefined>(currentUserId);
  const [dateFrom, setDateFrom] = useState<string | undefined>('');
  const [dateTo, setDateTo] = useState<string | undefined>('');
  const [filterStatuses, setFilterStatuses] = useState<string[] | undefined>([]);
  const [filterDeviceIds, setFilterDeviceIds] = useState<string[] | undefined>([]);
  const [filterHasData, setFilterHasData] = useState<boolean | undefined>();

  const executionFiltersProps = {
    searchQuery,
    setSearchQuery,
    filterUserId,
    setFilterUserId,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    filterStatuses,
    setFilterStatuses,
    filterDeviceIds,
    setFilterDeviceIds,
    filterHasData,
    setFilterHasData,
  };
  return (
    <ExecutionListBase
      {...executionFiltersProps}
      onExecutionRowClick={props.onExecutionRowClick}
      disabledExecutionIds={props.disabledExecutionIds}
    />
  );
}

/**
 * A filterable list of Executions.
 */
function ExecutionListBase({
  searchQuery,
  setSearchQuery,
  filterUserId,
  setFilterUserId,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  filterStatuses,
  setFilterStatuses,
  onExecutionRowClick,
  disabledExecutionIds,
  filterDeviceIds,
  setFilterDeviceIds,
  filterHasData,
  setFilterHasData,
}: Props & ExecutionFiltersProps) {
  const scrollableRef = useRef<HTMLDivElement>(null);

  const usersDropdownOptions = useUserList();

  const onFilterUser = useCallback(
    (id?: string) => {
      logEvent('filter-executions-by-user-id', ScreenRegistry.EXPERIMENTS);
      setFilterUserId(id);
      // Reset the selected devices, as they may not be valid for the selected user.
      setFilterDeviceIds([]);
    },
    [setFilterDeviceIds, setFilterUserId],
  );

  const filterDateRange = useMemo(
    () => getDateRange(dateFrom, dateTo),
    [dateFrom, dateTo],
  );
  const onFilterByDateRange = useCallback(
    (newValue: DateRange) => {
      logEvent('filter-executions-by-date', ScreenRegistry.EXPERIMENTS);
      const { dateFrom, dateTo } = getDateFromTo(newValue);
      setDateFrom(dateFrom);
      setDateTo(dateTo);
    },
    [setDateFrom, setDateTo],
  );

  const onFilterByStatuses = useCallback(
    (newValue: FilterChipOption<ExecutionStatusEnum>[]) => {
      logEvent('filter-executions-by-statuses', ScreenRegistry.EXPERIMENTS);
      const newStatuses = newValue
        .filter(option => option.selected)
        .map(option => option.label);
      setFilterStatuses(newStatuses);
    },
    [setFilterStatuses],
  );
  // Get a list of the statuses to filter by.
  const statusFilterList = getExecutionStatusFiltersOptions()
    .filter(status => filterStatuses?.includes(status.label))
    .map(status => status.value);

  const onFilterDeviceId = useCallback(
    (id: string[]) => {
      logEvent('filter-executions-by-device-id', ScreenRegistry.EXPERIMENTS);
      setFilterDeviceIds(id);
    },
    [setFilterDeviceIds],
  );

  const onFilterHasData = useCallback(
    (value?: boolean) => {
      logEvent('filter-executions-by-data', ScreenRegistry.EXPERIMENTS);
      setFilterHasData(value);
    },
    [setFilterHasData],
  );

  return (
    <>
      <ListPageHeader
        left={
          <>
            <FilterChipWithAutocomplete
              heading="Filter by Author"
              defaultChipLabel="Author"
              dropdownOptions={usersDropdownOptions}
              filterValue={filterUserId}
              onFilter={onFilterUser}
            />
            <FilterChipWithDateRange
              heading="Filter by Date Range"
              defaultChipLabel="Date Range"
              filterValue={filterDateRange}
              onFilter={onFilterByDateRange}
            />
            <FilterChipWithCheckbox
              heading="Filter by Status"
              defaultChipLabel="Status"
              filterValue={getSelectedExecutionStatusFiltersOptions(filterStatuses ?? [])}
              onFilter={onFilterByStatuses}
            />
            <FilterChipWithSwitch
              heading="Filter by Data"
              activeChipLabel="Has Data"
              inactiveChipLabel="Data"
              filterValue={!!filterHasData}
              onFilter={onFilterHasData}
            />
            <DeviceFilter
              selectedIds={filterDeviceIds ?? []}
              onChange={onFilterDeviceId}
              showManualDevice
              withExecutionsByUserId={filterUserId}
            />
          </>
        }
        right={
          <SearchField
            label="Search"
            defaultValue={searchQuery}
            onQueryChange={setSearchQuery}
          />
        }
      />
      <ContainerWithIntersectionBar
        scrollableRef={scrollableRef}
        noHeader
        content={
          <ExecutionRows
            filterUserId={filterUserId}
            filterDeviceIds={filterDeviceIds}
            filterDateRange={filterDateRange}
            filterStatuses={statusFilterList}
            filterHasData={filterHasData}
            scrollableRef={scrollableRef}
            searchQuery={searchQuery}
            onExecutionRowClick={onExecutionRowClick}
            disabledExecutionIds={disabledExecutionIds}
          />
        }
        dense
      />
    </>
  );
}

type ExecutionRowsProps = {
  scrollableRef: React.RefObject<HTMLDivElement>;
  filterDateRange: DateRange;
  filterStatuses: ExecutionStatusEnum[];
  filterUserId?: string;
  filterDeviceIds?: string[];
  filterHasData?: boolean;
  searchQuery?: string;
  onExecutionRowClick?: (clickedExecution: ClickedExecution) => void;
  disabledExecutionIds?: ExecutionId[];
};

/**
 * Fetches the Executions and renders ExecutionRows.
 */
function ExecutionRows(props: ExecutionRowsProps) {
  const {
    filterUserId,
    filterDateRange,
    filterStatuses,
    filterDeviceIds,
    filterHasData,
    scrollableRef,
    searchQuery,
    onExecutionRowClick,
    disabledExecutionIds,
  } = props;

  const variables: ExecutionsQueryVariables = {
    userId: filterUserId,
    filterStartDate: filterDateRange.startDate
      ? filterDateRange.startDate.format('YYYY-MM-DD')
      : undefined,
    filterEndDate: filterDateRange.endDate
      ? filterDateRange.endDate.format('YYYY-MM-DD')
      : undefined,
    searchQuery: searchQuery,
    statusToFilter: filterStatuses ?? undefined,
    deviceIds: filterDeviceIds,
    hasDatasets: filterHasData,
  };

  const executionsQuery = useQuery(QUERY_EXECUTIONS, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const pageInfo = executionsQuery.data?.executions.pageInfo as PageInfo | undefined;
  const dependencies = [filterUserId, filterDateRange, searchQuery, filterStatuses];
  const hasNextPage = usePagination({
    entity: 'executions',
    pageInfo,
    fetchMore: executionsQuery.fetchMore,
    dependencies,
    scrollableRef,
    isInitialLoading: executionsQuery.loading,
    variables,
  });

  return (
    <RenderQuery
      query={executionsQuery}
      renderData={ExecutionsListDataComponent}
      renderNoData={ExecutionsListNoDataComponent}
      loadingComponent={EntityCardSkeletonList}
      additionalDataProps={{
        onExecutionRowClick,
        disabledExecutionIds,
        hasNextPage,
      }}
      additionalNoDataProps={{
        searchQuery,
      }}
      emptyCondition={data => data.executions.items.length === 0}
    />
  );
}

type ExecutionsListDataComponentProps = Pick<
  Props,
  'disabledExecutionIds' | 'onExecutionRowClick'
> & {
  data: ExecutionsQuery;
  hasNextPage: boolean;
};

function ExecutionsListDataComponent(props: ExecutionsListDataComponentProps) {
  const { data, hasNextPage, onExecutionRowClick, disabledExecutionIds } = props;
  const classes = useStyles();
  return (
    <div className={classes.list}>
      {data.executions.items.map(execution => {
        const showDatasetIcon = 'hasDatasets' in execution;
        const hasDatasets = showDatasetIcon && execution.hasDatasets;
        return (
          <ExecutionCard
            key={execution.id}
            execution={execution}
            showDatasetIcon={showDatasetIcon}
            hasDatasets={hasDatasets}
            onClick={
              onExecutionRowClick
                ? () =>
                    onExecutionRowClick({
                      executionId: execution.id,
                      simulationId: execution.simulation.id,
                    })
                : undefined
            }
            isLink={!onExecutionRowClick}
            isDisabled={disabledExecutionIds?.includes(execution.id)}
          />
        );
      })}
      {hasNextPage && (
        <div className={classes.circularLoadingContainer}>
          <CircularProgress size={24} />
        </div>
      )}
    </div>
  );
}

type ExecutionsListNoDataComponentProps = { searchQuery?: string };

function ExecutionsListNoDataComponent(props: ExecutionsListNoDataComponentProps) {
  const { searchQuery } = props;
  return (
    <NoEntitiesMessage
      entityName="executions"
      messageType={MessageType.NO_FILTER_RESULTS}
      searchQuery={searchQuery}
    />
  );
}

function getExecutionStatusFiltersOptions(): FilterChipOption<ExecutionStatusEnum>[] {
  return Object.values(ExecutionStatusEnum).map(status => ({
    value: status,
    label: formatShortTransitiveSimulationStatus(status),
    selected: false,
  }));
}

function getSelectedExecutionStatusFiltersOptions(selectedTypes: string[]) {
  return Object.values(ExecutionStatusEnum).map(status => {
    const label = formatShortTransitiveSimulationStatus(status);
    return { value: status, label, selected: selectedTypes.includes(label) };
  });
}

const useStyles = makeStylesHook(theme => ({
  ...experimentsStyles(theme),
  ...circularLoadingContainer,
}));
