import { TypographyOptions } from '@mui/material/styles/createTypography';

const BOLD = 700;
const MEDIUM = 500;
const NORMAL = 400;

export const anthaTypography: TypographyOptions = {
  h1: { fontSize: 22, lineHeight: '26px' },
  h2: { fontSize: 19, fontWeight: MEDIUM, lineHeight: '24px' },
  h3: { fontSize: 17, lineHeight: '22px' },
  h4: { fontSize: 16, lineHeight: '20px' },
  h5: { fontSize: 15, fontWeight: MEDIUM, lineHeight: '20px' },
  h6: { fontSize: 15, fontWeight: NORMAL, lineHeight: '20px' },
  subtitle1: { fontSize: 14, fontWeight: BOLD, lineHeight: '18px' },
  subtitle2: { fontSize: 14, fontWeight: MEDIUM, lineHeight: '18px' },
  body1: { fontSize: 14, lineHeight: '18px' },
  body2: { fontSize: 13, lineHeight: '16px' },
  button: { fontSize: 12, fontWeight: MEDIUM, lineHeight: '21px' },
  caption: { fontSize: 12, lineHeight: '16px' },
  // Letter Spacing is 0.5px = 0.035em with 14px as the base size.
  overline: {
    fontSize: 13,
    letterSpacing: '0.03571428571em',
    textTransform: 'unset',
    lineHeight: '14px',
    fontWeight: 300,
  },
  fontWeightRegular: 400,
  htmlFontSize: 14,
};
