import { useMemo } from 'react';

import produce from 'immer';

import { getDropTargetGroup } from 'client/app/lib/layout/GroupHelper';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { Connection, ElementInstance, Group } from 'common/types/bundle';
import { Position2d } from 'common/types/Position';

/**
 * Find the element groups which is the drop target for the current drag selection.
 */
export function useElementGroupDropTarget(
  elementInstances: ElementInstance[],
  dragDelta: Position2d | null,
  groups: Group[],
  connections: Connection[],
  selectedObjectIds: string[],
) {
  const draggedObjectId = useWorkflowBuilderSelector(state => state.draggedObjectId);

  const draggedElement = useMemo(() => {
    const draggedElement = elementInstances.find(ei => ei.Id === draggedObjectId);

    if (draggedElement) {
      /**
       * If the dragged element is part of a selected group, it's effectively the group
       * that's being dragged, so don't look for a drop target.
       */
      const group = groups.find(group => group.elementIds.includes(draggedElement?.Id));
      if (group && selectedObjectIds.includes(group.id)) {
        return null;
      }
    }

    return draggedElement;
  }, [draggedObjectId, elementInstances, groups, selectedObjectIds]);

  return useMemo(() => {
    if (!draggedElement || !dragDelta) {
      return null;
    }

    const shiftedElement = produce(draggedElement, draft => {
      draft.Meta.x += dragDelta.x;
      draft.Meta.y += dragDelta.y;
    });

    return getDropTargetGroup(shiftedElement, groups, connections)?.id ?? null;
  }, [connections, dragDelta, draggedElement, groups]);
}
