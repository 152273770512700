import React, { useEffect, useMemo, useRef, useState } from 'react';

import Chip from '@mui/material/Chip';
import HelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Typography from '@mui/material/Typography';
import DOMPurify from 'dompurify';
import entries from 'lodash/entries';

import { getFactorName } from 'client/app/components/DOEBuilder/factorUtils';
import { FactorItem } from 'common/types/bundle';
import Colors from 'common/ui/Colors';

type Props = {
  value: string | undefined;
  factors: FactorItem[];
  errorMsg?: string;
  isReadonly: boolean;
  onChange: (derivingExpression: string) => void;
};

export default function FactorDerivingExpression({
  value,
  factors,
  errorMsg,
  isReadonly,
  onChange: updateDerivingExpression,
}: Props) {
  const [variablesMap, factorsMap] = useFactorVariableMapping(factors);
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const [textValue, setTextValue] = useState<string>(() =>
    getReadableFunctionExpression(value, factorsMap),
  );
  const handleBlur = () => {
    let result = textValue;

    for (const [variable, factor] of variablesMap) {
      result = result.replaceAll(variable, `{${factor.id}}`);
    }

    result = DOMPurify.sanitize(result);
    result = result.trim();

    updateDerivingExpression(result);
  };

  const expressionTextFieldRef = useRef<HTMLTextAreaElement | undefined>();
  const addVariable = (factorName: string) => {
    const input = expressionTextFieldRef.current;
    if (input) {
      const insertValue = `{${factorName}}`;
      const lastCursorPosition = expressionTextFieldRef.current?.selectionStart;

      if (lastCursorPosition) {
        // paste when cursor in in between or at the end of the expression
        setTextValue(
          s => s.slice(0, lastCursorPosition) + insertValue + s.slice(lastCursorPosition),
        );
        setCursorPosition(lastCursorPosition + insertValue.length);
      } else if (lastCursorPosition === 0) {
        // paste when cursor is at the beginning of the expression
        setTextValue(s => insertValue + s);
        setCursorPosition(insertValue.length);
      } else {
        // paste when there is no cursor (the text field is not focused)
        setTextValue(s => s + insertValue);
        setCursorPosition(textValue.length + insertValue.length);
      }
    }
  };

  useEffect(() => {
    const input = expressionTextFieldRef.current;
    if (input && cursorPosition != null) {
      input.focus();
      input.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [cursorPosition]);

  return (
    <>
      <TextArea
        value={textValue}
        onChangeCapture={e => setTextValue(e.currentTarget.value)}
        onBlur={handleBlur}
        placeholder="Please, type your expression here..."
        error={!!errorMsg}
        disabled={isReadonly}
        ref={(ref: HTMLTextAreaElement) => (expressionTextFieldRef.current = ref)}
      />
      {errorMsg && <ErrorText>{errorMsg}</ErrorText>}
      <Container>
        <Typography variant="caption" color="textSecondary">
          Click on a factor to add it to the Equation
        </Typography>
        <Variables>
          {entries(variablesMap).map(([_, factor]) => (
            <VariableChip
              key={factor.id}
              label={getFactorName(factor)}
              onClick={() => !isReadonly && addVariable(getFactorName(factor))}
            />
          ))}
        </Variables>
      </Container>
    </>
  );
}

/**
 * Here we map variables to factor IDs to rely on direct variable mapping.
 *
 * {Glycerol} -> Glycerol UUID
 *
 * {Enzyme} -> Enzyme UUID
 *
 * etc.
 */
function useFactorVariableMapping(
  factors: FactorItem[],
): [Map<string, FactorItem>, Map<string, string>] {
  return useMemo(() => {
    const variablesMap = new Map<string, FactorItem>();
    const factorsMap = new Map<string, string>();

    factors.forEach(factor => {
      const variableName = `{${getFactorName(factor)}}`;
      variablesMap.set(variableName, factor);
      factorsMap.set(factor.id, variableName);
    });

    return [variablesMap, factorsMap];
  }, [factors]);
}

function getReadableFunctionExpression(
  derivingExpression: string = '',
  factorsMap: Map<string, string>,
) {
  let result = derivingExpression;
  for (const [factorId, variable] of factorsMap) {
    result = result.replaceAll(`{${factorId}}`, variable);
  }
  return result;
}

const Container = styled('main')(({ theme: { spacing } }) => ({
  background: Colors.BLUE_0,
  border: `1px solid ${Colors.BLUE_5}`,
  borderRadius: spacing(3),
  padding: spacing(4, 5),
  marginTop: spacing(5),
}));

const Variables = styled('section')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: spacing(3),
  margin: spacing(4, 0, 0),
}));

const VariableChip = styled(Chip)({
  fontWeight: 400,
  backgroundColor: Colors.BLUE_5,
  border: `1px solid ${Colors.BLUE_5}`,

  '&:hover': {
    cursor: 'pointer',
    border: `1px solid ${Colors.BLUE_10}`,
    backgroundColor: Colors.BLUE_5,
  },
});

const TextArea = styled(TextareaAutosize, {
  shouldForwardProp: propName => propName !== 'error',
})<{ error: boolean }>(({ theme, error }) => ({
  ...theme.typography.body1,
  minHeight: 150,
  resize: 'none',
  overflow: 'hidden',

  padding: theme.spacing(3),

  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 6,
  borderColor: error ? theme.palette.error.main : theme.palette.divider,
}));

const ErrorText = styled(HelperText)(({ theme }) => ({
  color: theme.palette.error.main,
}));
