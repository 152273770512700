import React, { useCallback, useEffect } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';

import LegacySimulationCard from 'client/app/components/SimulationNotification/LegacySimulationCard';
import { SimulationNotificationDetails } from 'client/app/lib/workflow/types';
import {
  useSimulationNotifications,
  useSimulationNotificationsDispatch,
} from 'client/app/state/SimulationNotificationsContext';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { SimulationIcon } from 'common/ui/icons/SimulationIcon';

type Props = {
  elementSetID: string;
  workflowId: string;
};

/**
 * Allow for the ability to collapse the notifications.
 */
function CollapsibleNotificationManager(props: Props) {
  const { elementSetID, workflowId } = props;
  const classes = useStyles();
  const simulationNotifications = useSimulationNotifications(workflowId);

  const dispatch = useSimulationNotificationsDispatch();

  // Allow for the toggling for the notification manager.
  const [expanded, setExpanded] = React.useState(true);

  // If there has been a change to number of simulations, the notification manager should open up.
  // This is intentionally not connected to changes in simulation state because it changes often.
  useEffect(() => {
    setExpanded(true);
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  /** Remove a particular simulation from the list. */
  const onDismissNotification = useCallback(
    (simulationToDismiss: SimulationNotificationDetails) => {
      dispatch({
        type: 'removeSimulationNotification',
        payload: simulationToDismiss.requestID,
      });
    },
    [dispatch],
  );

  if (simulationNotifications.length > 0) {
    return (
      <div className={classes.container}>
        {expanded && (
          <div className={classes.scrollableContainer}>
            <div className={classes.flexContainer}>
              {simulationNotifications.map(simulation => (
                <LegacySimulationCard
                  elementSetID={elementSetID}
                  key={simulation.requestID}
                  simulation={simulation}
                  onDismissNotification={onDismissNotification}
                />
              ))}
            </div>
          </div>
        )}
        <IconButton
          onClick={handleExpandClick}
          className={classes.iconButton}
          title={
            expanded ? 'Collapse simulation notifications' : 'View ongoing simulations'
          }
          size="large"
        >
          {expanded ? (
            <ExpandMoreIcon className={classes.iconImage} />
          ) : (
            <SimulationIcon className={classes.iconImage} />
          )}
        </IconButton>
      </div>
    );
  }

  return null;
}

const useStyles = makeStylesHook({
  container: {
    position: 'absolute',
    bottom: 0,
    left: '14px',
  },
  iconButton: {
    backgroundColor: Colors.BLACK,
    '&:hover': {
      backgroundColor: Colors.INDIGO,
    },
    boxShadow: `1px 1px 5px ${Colors.NAV_DARK}`,
    margin: '1rem 0',
    padding: '8px',
  },
  iconImage: {
    color: Colors.WHITE,
    height: '30px',
    width: '30px',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    flexShrink: 0,
  },
  scrollableContainer: {
    boxShadow: `1px 1px 5px ${Colors.NAV_DARK}`,
    maxHeight: '450px',
    overflow: 'auto',
    width: '375px',
  },
});

export default CollapsibleNotificationManager;
