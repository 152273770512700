import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export default React.memo(function DefaultCursorIcon(
  props: React.PropsWithChildren<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>>,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.83677 3.7348c-.04838-.43332.44234-.71664.79342-.45808L18.9341 12.3382c.3376.2487.2376.7775-.1675.8857l-5.8679 1.5667a.49991.49991 0 00-.2249.1298l-4.29072 4.2984c-.29625.2968-.80425.119-.85078-.2978L5.83677 3.7348z"
          fill="currentColor"
        />
        <path
          d="M8.58724 9.58267c-.13807-.23915-.05614-.54494.18301-.68301l.86602-.5c.23915-.13807.54493-.05614.68303.18301l6.5 11.25833c.1381.2391.0561.5449-.183.683l-.8661.5c-.2391.1381-.5449.0561-.683-.183L8.58724 9.58267z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
