import React, { useCallback } from 'react';

import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import GenericInputEditor from 'common/ui/components/ParameterEditors/GenericInputEditor';

type Props = {
  type: string;
  onChange: (newValue?: number) => void;
  disableUnderline?: boolean;
} & ParameterEditorBaseProps<number>;

const validate = (numString: string | null) => {
  if (numString === null || numString === '') {
    return '';
  }

  const num = parseInt(numString, 10);
  if (!numString.match(/^(-)?\d*$/) || isNaN(num)) {
    return 'Invalid integer value';
  }

  if (num > Number.MAX_SAFE_INTEGER) {
    return `Numbers greater than ${Number.MAX_SAFE_INTEGER} may cause issues in the web UI`;
  }

  if (num < Number.MIN_SAFE_INTEGER) {
    return `Numbers less than ${Number.MIN_SAFE_INTEGER} may cause issues in the web UI`;
  }

  return '';
};

const IntegerEditor = React.memo(function IntegerEditor(props: Props) {
  const onChange = useCallback(
    (value: string) => {
      const num = parseInt(value, 10);
      props.onChange(isNaN(num) ? undefined : num);
    },
    [props],
  );

  return (
    <GenericInputEditor
      type={props.type}
      value={props.value?.toString(10) ?? ''}
      onChange={onChange}
      validate={validate}
      isDisabled={props.isDisabled}
      isRequired={props.isRequired}
      placeholder={props.placeholder}
      disableUnderline={props.disableUnderline}
    />
  );
});

export default IntegerEditor;
