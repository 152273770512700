import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

import { useProtocolInstanceContext } from 'client/app/apps/protocols/context/ProtocolInstaceProvider';
import { EditProtocolInstanceHeader } from 'client/app/apps/protocols/EditProtocolInstanceHeader';
import { InputStep, InputStepSkeleton } from 'client/app/apps/protocols/InputStep';
import { InputStepList } from 'client/app/apps/protocols/InputStepList';
import { useComplexParameterEditorDialogManager } from 'client/app/apps/protocols/lib/utils';
import {
  OutputStepPreview,
  OutputStepPreviewSkeleton,
} from 'client/app/apps/protocols/OutputStepPreview';
import ProtocolInfo from 'client/app/apps/protocols/ProtocolInfo';
import { useCurrentEntity } from 'client/app/components/nav/breadcrumbs/BreadcrumbsEntityContext';
import useEntityConflictErrorDialog from 'client/app/hooks/useEntityConflictErrorDialog';
import { ErrorCodes } from 'common/types/errorCodes';
import Colors from 'common/ui/Colors';
import useDebounce from 'common/ui/hooks/useDebounce';

const DEBOUNCED_AUTOSAVE_INTERVAL_MS = 1000;

export const EditProtocolInstance = () => {
  const {
    protocolInstance,
    workflowSchema,
    hasInputsAndOutputs,
    parametersLoading,
    updateProtocolInstance,
  } = useProtocolInstanceContext();

  const { handleCheckConflictError, conflictDialog } = useEntityConflictErrorDialog();

  const debouncedSaveInstance = useDebounce(async () => {
    try {
      await updateProtocolInstance(protocolInstance);
    } catch (error) {
      await handleCheckConflictError(
        error,
        'protocol',
        ErrorCodes.PROTOCOL_INSTANCE_EDIT_CONFLICT,
      );
    }
  }, DEBOUNCED_AUTOSAVE_INTERVAL_MS);

  const dialogs = useComplexParameterEditorDialogManager();

  const { showInfo } = useCurrentEntity();
  // Because the `showInfo` state is lifted, it isn't reset when the component changes.
  // So we store and compare against the initial value to ensure we default to not showing
  const [initialShowInfo] = useState(showInfo);

  if (!hasInputsAndOutputs || !workflowSchema) {
    // TODO - Handle this state better in the UI.
    return <p>No inputs or outputs found</p>;
  }

  return (
    <>
      <EditProtocolInstanceHeader />
      <Wrapper>
        <InputStepList />
        <InputsAndOutputsWrapper>
          {parametersLoading ? (
            <>
              <InputStepSkeleton />
              <OutputStepPreviewSkeleton />
            </>
          ) : (
            <>
              <InputStep
                workflowSchema={workflowSchema}
                updateInstance={debouncedSaveInstance}
              />
              <OutputStepPreview />
            </>
          )}
        </InputsAndOutputsWrapper>
        {dialogs}
        {conflictDialog}
        {showInfo !== initialShowInfo && (
          <ProtocolInfo protocol={protocolInstance.protocol} />
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplate: `
      "list inputsAndOutputs inputsAndOutputs" minmax(400px, 1200px)
      / auto 1fr 1fr`,
  padding: theme.spacing(8),
  gap: theme.spacing(7),
  height: '100%',
  overflow: 'auto',
  backgroundColor: Colors.GREY_10,
  position: 'relative',
}));

const InputsAndOutputsWrapper = styled('div')({
  gridArea: 'inputsAndOutputs',
  display: 'flex',
  overflowX: 'auto',
  zIndex: 1,
});
