export const DATA_REPO_SCHEME = 'datarepo://';

/**
 * Downloads a string present in memory, as a file.
 * Does not fetch a remote file.
 */
export function downloadTextFile(
  data: string,
  filename: string,
  mimetype: string = 'text/plain',
) {
  const blob = new Blob([data], {
    type: mimetype,
  });
  return downloadBlob(blob, filename);
}

/**
 * Downloads a Base64-encoded (often binary) file present in memory.
 * This is often used with Excel files, in places where we don't use Filetree.
 * @param base64EncodedData Base64 encoded binary file.
 * @param filename Save to local filesystem as this name.
 */
export function downloadBase64EncodedFile(base64EncodedData: string, filename: string) {
  const byteArray = Uint8Array.from(atob(base64EncodedData), c => c.charCodeAt(0));
  const blob = new Blob([byteArray], {
    type: 'application/octet-stream',
  });
  return downloadBlob(blob, filename);
}

/**
 * Initiates a user download for the blob.
 * The browser already has the content (in the blob), and no fetch is necessary.
 */
export function downloadBlob(blob: Blob, filename: string) {
  const url = window.URL.createObjectURL(blob);
  downloadFileFromUrl(url, filename);
  window.URL.revokeObjectURL(url);
}

/** Initiates a user download for content at `url`. */
export function downloadFileFromUrl(url: string, filename?: string) {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  if (filename) {
    link.setAttribute('download', filename);
  }
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}
