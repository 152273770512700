import { useCallback } from 'react';

import Keys from 'common/ui/lib/keyboard';

export function useEnterKeyPress(fn: () => void) {
  return useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === Keys.ENTER) {
        fn();
      }
    },
    [fn],
  );
}
