import { ExecutionStatusEnum, SimulationOrExecutionStatusesEnum } from 'client/app/gql';
import { switchFail } from 'common/assert';

/**
 * Formats the status for UI.
 */
function formatTransitiveSimulationStatus(
  status: SimulationOrExecutionStatusesEnum | ExecutionStatusEnum,
  shorten?: boolean,
) {
  switch (status) {
    case SimulationOrExecutionStatusesEnum.SIMULATION_FAILED:
      return shorten ? 'Failed' : 'Simulation failed';
    case SimulationOrExecutionStatusesEnum.SIMULATION_SUCCESS:
      return shorten ? 'Succeeded' : 'Simulation succeeded';
    case SimulationOrExecutionStatusesEnum.EXECUTION_READY:
      return 'Ready to run';
    case SimulationOrExecutionStatusesEnum.EXECUTION_SCHEDULED:
      return shorten ? 'Scheduled' : 'Execution scheduled';
    case SimulationOrExecutionStatusesEnum.EXECUTION_FAILED:
      return shorten ? 'Failed' : 'Execution failed';
    case SimulationOrExecutionStatusesEnum.EXECUTION_SUCCESS:
      return shorten ? 'Succeeded' : 'Execution succeeded';
    case SimulationOrExecutionStatusesEnum.EXECUTION_IN_PROGRESS:
      return shorten ? 'Running' : 'Execution running';
    case SimulationOrExecutionStatusesEnum.EXECUTION_COMPLETED:
      // Copy updated from `completed` to `incomplete`.
      // EXECUTION_COMPLETED could also be updated to EXECUTION_INCOMPLETE.
      // See https://phabricator.synthace.us/T4553.
      return shorten ? 'Incomplete' : 'Execution incomplete';
    default:
      // In case we get bogus data from the db, show something in the UI
      // rather than crash.
      return 'Simulation';
  }
}

export function formatShortTransitiveSimulationStatus(
  status: SimulationOrExecutionStatusesEnum | ExecutionStatusEnum,
) {
  return formatTransitiveSimulationStatus(status, true);
}

/*
 * Returns the content needed to explain what each status means.
 * For now, only support the different execution statuses.
 */
export function getExecutionStatusHelperText(executionStatus: ExecutionStatusEnum) {
  switch (executionStatus) {
    case ExecutionStatusEnum.EXECUTION_READY:
      return 'Execution ready, no tasks have been started';
    case ExecutionStatusEnum.EXECUTION_SCHEDULED:
      return 'Execution sent to lab, all tasks ready to be run';
    case ExecutionStatusEnum.EXECUTION_IN_PROGRESS:
      return 'Execution running, some tasks finished, others ready to be run';
    case ExecutionStatusEnum.EXECUTION_COMPLETED:
      return 'Execution finished, some tasks succeeded, others failed';
    case ExecutionStatusEnum.EXECUTION_FAILED:
      return 'Execution finished, all tasks failed';
    case ExecutionStatusEnum.EXECUTION_SUCCESS:
      return 'Execution finished, all tasks succeeded';
    default:
      return switchFail(executionStatus);
  }
}
