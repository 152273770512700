import React, { useCallback, useEffect, useState } from 'react';

import * as WorkflowsApi from 'client/app/api/WorkflowsApi';
import UIErrorBox from 'client/app/components/UIErrorBox';
import { templateWorkflowRoutes } from 'client/app/lib/nav/actions';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';

type Props = {
  simulationId: SimulationId;
};

/**
 * When navigated to, creates a template workflow based on a simulation. Once the
 * template workflow has been successfully created in Postgres, "redirects" to that
 * new template workflow.
 */
export default function TemplateWorkflowCreateFromSimulation({ simulationId }: Props) {
  const [error, setError] = useState<Error | null>(null);
  const { navigate } = useNavigation();

  const createTemplateWorkflowFromSimulation =
    WorkflowsApi.useCreateTemplateWorkflowFromSimulation();
  const createTemplateWorkflowAndShowError = useCallback(
    async (simulationId: SimulationId) => {
      const templateWorkflowID = await createTemplateWorkflowFromSimulation(simulationId);
      navigate(
        templateWorkflowRoutes.editTemplate,
        { id: templateWorkflowID },
        { method: 'replace' },
      );
    },
    [createTemplateWorkflowFromSimulation, navigate],
  );

  useEffect(() => {
    createTemplateWorkflowAndShowError(simulationId).catch(setError);
  }, [createTemplateWorkflowAndShowError, simulationId]);

  return (
    <>
      {error && <UIErrorBox>Could not create the template: {error.message}</UIErrorBox>}
    </>
  );
}
