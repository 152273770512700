const CHUNK_SIZE = 0x8000;

export default function getFileBytes(filePointer: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = e => {
      reject(e);
    };
    reader.onload = () => {
      if (!reader.result) {
        return;
      }
      const chars = new Uint8Array(reader.result as ArrayBuffer);
      const { length } = chars;
      let index = 0;
      const result = [];
      let slice: Uint8Array;

      while (index < length) {
        slice = chars.subarray(index, Math.min(index + CHUNK_SIZE, length));
        result.push(String.fromCharCode.apply(null, [...slice]));
        index += CHUNK_SIZE;
      }

      resolve(result.join(''));
    };
    reader.readAsArrayBuffer(filePointer);
  });
}

export function getFileArrayBuffer(filePointer: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = e => {
      reject(e);
    };
    reader.onload = () => {
      if (!reader.result) {
        return;
      }

      resolve(reader.result as ArrayBuffer);
    };
    reader.readAsArrayBuffer(filePointer);
  });
}

export function getFileText(filePointer: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = e => {
      reject(e);
    };

    reader.onload = () => {
      if (!reader.result || typeof reader.result !== 'string') {
        return;
      }
      resolve(reader.result);
    };

    // This handles Byte-Order-Mark so we don't have to deal with it.
    reader.readAsText(filePointer);
  });
}
