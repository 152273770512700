// For now this is hardcoded. In future we'll want to look up the name of a column in the

import { DatasetWithSettingsQuery } from 'client/app/gql';

// dataset with type `observation.time`, though this isn't certain yet.
const TIMESTAMP_COLUMN_NAME = 'Timestamp';

export type BioreactorDatasetColumns = {
  bioreactorColumnName: string;
  bioreactorColumnValues: readonly string[];
  timestampColumnName: string;
};

/**
 * For a given dataset, get the name of the column that contains bioreactor names
 * (indicated by type labware.name), the values of that column, and the name of the column
 * that contains timestamps.
 *
 * Returns undefined if there is no labware.name column or tag.
 */
export function useBioreactorDatasetColumns(
  dataset: DatasetWithSettingsQuery['dataset'],
): BioreactorDatasetColumns | undefined {
  const bioreactorColumn = dataset.sampleMatchingOptions.find(
    option => option.columnType === 'labware.name',
  );

  if (!bioreactorColumn) {
    return;
  }

  return {
    bioreactorColumnName: bioreactorColumn.columnName,
    bioreactorColumnValues: bioreactorColumn.columnValues,
    timestampColumnName: TIMESTAMP_COLUMN_NAME,
  };
}
