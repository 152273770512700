import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const TipBoxIcon = React.memo<SvgIconProps>(function TipBoxIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 11L6.875 22h-.75L5 11v-1H2V4h3V2h3v2h2.5V2h3v2H16V2h3v2h3v6h-3v1l-1.125 11h-.75L16 11v-1h-2.5v1l-1.125 11h-.75L10.5 11v-1H8v1zm12-3V6H4v2h16z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
