import { ScreenRegistry } from 'client/app/registry';
import { logPageView } from 'common/ui/GoogleAnalyticsUtils';

window.addEventListener('hashchange', () => {
  // Examples:
  //   #/experiments/drafts
  //   0   1           2
  //   #/simulation-details/<id>
  //   0   1                 2
  //   #/simulation-details/<id>/preview
  //   0   1                 2    3
  const parts = window.location.hash.split('/');
  const appName = parts[1] || window.location.hash;
  const screenName = parts[2] || appName;
  if (appName === ScreenRegistry.SIMULATION_DETAILS) {
    _logSimulationDetailsPageView(parts);
  } else {
    logPageView(appName, screenName);
  }
});

// Page views of Simulation Details need to be logged differently.
// The URL format is a bit different from other apps.
function _logSimulationDetailsPageView(parts: string[]) {
  if (parts[3]) {
    // #/simulation-details/<id>/<screen>
    logPageView(ScreenRegistry.SIMULATION_DETAILS, parts[3]);
  } else {
    // #/simulation-details/<id>
    logPageView(ScreenRegistry.SIMULATION_DETAILS, 'overview');
  }
}
