import { roundNumber } from 'common/lib/format';
import { DataTag } from 'common/types/mix';

// formatTagValue for display purposes. Do not use for file exports
export function formatTagValue({ value_float, value_string }: DataTag): string {
  if (typeof value_string === 'string') {
    return value_string;
  }
  if (typeof value_float === 'number') {
    return Number.isInteger(value_float) && value_float > 0
      ? String(value_float)
      : roundNumber(value_float);
  }
  // A tag should always have a value_string or value_float, but the schema technically
  // allows for neither so just return empty in this case.
  return '';
}
