import { ArrayElement, ExperimentQuery } from 'client/app/gql';

/**
 * Different categories of block have different padding
 */
export type BlockCategory = 'title' | 'link' | 'other';

type BlockConfig = {
  name: string;
  /**
   * Used to determine spacing above and below the block
   */
  category: BlockCategory;
};

type ExperimentBlockName = ArrayElement<
  ExperimentQuery['experiment']['blocks']
>['__typename'];

export const blockRegistry: Record<ExperimentBlockName, BlockConfig> = {
  TitleBlock: {
    name: 'heading',
    category: 'title',
  },
  TextBlock: {
    name: 'text',
    category: 'other',
  },
  SimulationBlock: {
    name: 'simulation link',
    category: 'link',
  },
  WorkflowBlock: {
    name: 'workflow link',
    category: 'link',
  },
  DatasetDerivationBlock: {
    name: 'dataset derivation link',
    category: 'link',
  },
  DatasetBlock: {
    name: '',
    category: 'title',
  },
};
