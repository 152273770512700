import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material/';

export default function DeletedSimulationIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" width="24" height="24" {...props}>
      <path
        d="M10 3l2 1 3-2 1 2-12 7-1-2 3-2V6l4-3zM6 20l2 2h8l2-2V10H6v10zm2-8h8v8H8v-8z"
        fill="currentColor"
      />
      <path d="M12 10H9l4-4 4 4h-3l-1-1-1 1zM18 11z" fill="currentColor" />
    </SvgIcon>
  );
}
