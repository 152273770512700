import { CSSProperties, useContext, useEffect, useState } from 'react';

import Elk, { ElkExtendedEdge, ElkNode } from 'elkjs/lib/elk.bundled.js';
import { Edge, Node, Position } from 'react-flow-renderer';

import {
  NODE_HEIGHT,
  NODE_WIDTH,
  NODE_X_GAP,
  NODE_Y_GAP,
} from 'client/app/apps/work-tree/workTreeDimensions';
import {
  SelectedVersion,
  WorkTreeModeContext,
} from 'client/app/apps/work-tree/WorkTreeModeContext';
import { ArrayElement, ExperimentWorkTreeQuery } from 'client/app/gql';

type WorkTreeLayoutHookResult =
  | { status: 'success'; workTree: PositionedWorkTree }
  | { status: 'loading' }
  | { status: 'error'; error: unknown };

type Block = ArrayElement<
  NonNullable<ExperimentWorkTreeQuery['experimentWorkTree']>['blocks']
>;

export type PositionedWorkTree = {
  nodes: Node<Block>[];
  edges: Edge<EdgeData>[];
};

export type EdgeData = { path: { x: number; y: number }[] };

export const EDGE_END_ARROW_MARKER_ID = 'worktree-end-arrow';

const EDGE_TYPE_DEPENDENCY = 'dependency';
const EDGE_TYPE_REVISION = 'revision';
const EDGE_TYPE_VERSION = 'version';

export function useWorkTreeLayout(blocks: readonly Block[]): WorkTreeLayoutHookResult {
  const [workTree, setWorkTree] = useState<PositionedWorkTree>();
  const [layoutError, setLayoutError] = useState<unknown>();
  const context = useContext(WorkTreeModeContext);

  useEffect(() => {
    setLayoutError(undefined);
    void layoutWorkTree(blocks, context.selectedVersion)
      .then(setWorkTree)
      .catch(setLayoutError);
  }, [blocks, context.selectedVersion]);

  if (layoutError) {
    return { status: 'error', error: layoutError };
  }

  if (!workTree) {
    return { status: 'loading' };
  }
  return { status: 'success', workTree };
}

async function layoutWorkTree(
  blocks: readonly Block[],
  selectedRootVersion?: SelectedVersion,
): Promise<PositionedWorkTree> {
  const graph = await blocksToElkGraph([...blocks]);
  const flow = elkGraphToReactFlow(graph, blocks, selectedRootVersion);

  let updatedFlow: PositionedWorkTree = { nodes: [], edges: [] };

  if (selectedRootVersion?.versionId) {
    const relativePositionedBlock = flow.nodes.find(
      block => block.id === selectedRootVersion.parentBlockId,
    );

    if (relativePositionedBlock) {
      const versionBlock = relativePositionedBlock?.data?.data?.versionData.find(
        versionBlock => versionBlock.id === selectedRootVersion.versionId,
      );

      const dependantsOfVersion: Block[] = [];
      blocks.forEach(childBlock => {
        if (versionBlock?.dependants.includes(childBlock.id)) {
          dependantsOfVersion.push(childBlock);
        }
      });
      updatedFlow = await relativePositioningGraph(
        [...dependantsOfVersion],
        relativePositionedBlock,
      );
    }
  }

  // Sort nodes based on y-position from bottom-up to ensure that
  // in the DOM, elements are stacked such that higher y-position
  // elements will always overlap lower y-position elements
  // We sort the basic flow.nodes first, and then append sorted
  // updatedFlow.nodes, to ensure updatedFlow appears on top.
  flow.nodes.sort((a, b) => {
    return a.position.y > b.position.y ? -1 : 1;
  });
  updatedFlow.nodes.sort((a, b) => {
    return a.position.y > b.position.y ? -1 : 1;
  });
  const nodes = [...flow.nodes, ...updatedFlow.nodes];

  return {
    nodes,
    edges: [...flow.edges, ...updatedFlow.edges],
  };
}

async function blocksToElkGraph(blocks: Block[]): Promise<ElkNode> {
  const nodes: ElkNode[] = [];
  const edges: ElkExtendedEdge[] = [];

  const versionedBlocks = blocks.filter(block => !!block.data?.versionData);
  const versionedBlockIds = new Set<BlockId>(
    versionedBlocks.map(versionedBlock => versionedBlock.id),
  );
  // We need to show (by default) only the version nodes that are connected
  // to entities
  const rootVersionNodes = versionedBlocks.filter(versionedBlock => {
    return (
      versionedBlock.dependencies.filter(dep => versionedBlockIds.has(dep)).length === 0
    );
  });

  const blocksWithoutVersionedBlocks = blocks.filter(
    block =>
      !block.data?.versionData &&
      block.dependencies.filter(id => versionedBlockIds.has(id)).length === 0,
  );

  const latestDownstreamBlocks: Block[] = [];
  blocks.forEach(block => {
    if (block.data?.versionData && block.data.versionData.length > 0) {
      // We are grabbing the latestVersion (most recent createdAt) and we will
      // use this to grab any dependants from this version which will be shown
      // in the initial Map layout by default.
      const latestVersion = block.data.versionData
        .filter(versionBlock => versionBlock.dependants.length > 0)
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))[0];

      if (latestVersion?.dependants.length > 0) {
        latestVersion.dependants.forEach(dependant => {
          const latestVersionDependantBlock = blocks.find(
            block => block.id === dependant,
          );
          if (latestVersionDependantBlock) {
            latestDownstreamBlocks.push(latestVersionDependantBlock);
          }
        });
      }
    }
  });

  const allNodes = [
    ...blocksWithoutVersionedBlocks,
    ...rootVersionNodes,
    ...latestDownstreamBlocks,
  ]
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    .map((block): ElkNode => ({ id: block.id, width: NODE_WIDTH, height: NODE_HEIGHT }));
  nodes.push(...allNodes);
  const dependencyEdges = [
    ...blocksWithoutVersionedBlocks,
    ...rootVersionNodes,
    ...latestDownstreamBlocks,
  ]
    .filter(targetBlock => targetBlock.dependencies?.length > 0)
    .flatMap(targetBlock =>
      targetBlock.dependencies?.map(
        (sourceBlockId): ElkExtendedEdge => ({
          id: `${EDGE_TYPE_DEPENDENCY}:${targetBlock.id}-${sourceBlockId}`,
          sources: [sourceBlockId],
          targets: [targetBlock.id],
        }),
      ),
    );

  edges.push(...dependencyEdges);

  const elk = new Elk({
    defaultLayoutOptions: {
      algorithm: 'layered',
      'elk.spacing.nodeNode': String(NODE_Y_GAP),
      'elk.spacing.edgeNode': '80',
      'elk.spacing.componentComponent': String(NODE_Y_GAP),
      'elk.layered.spacing.nodeNodeBetweenLayers': String(NODE_X_GAP),
      'elk.layered.nodePlacement.favorStraightEdges': 'false',
      // "Splines" routing means that each connection comprises a path with one (possibly
      // more in edge cases) bend point. We do not render this as a curve as it was
      // intended, instead preferring the straight lines from the nodes and bend points.
      'elk.edgeRouting': 'SPLINES',
    },
  });

  return elk.layout({
    id: 'root',
    children: nodes,
    edges: edges,
  });
}

/**
 * Given a relativeBlock, that already has a position specified, this function
 * will run the elkjs layout algorithm on that block and the given blocksToReposition.
 * The positions of the blocksToReposition nodes (and their edges) will then be
 * adjusted relative to the relativeBlock and those repositioned nodes and edges returned.
 *
 * @param blocksToReposition Blocks (that should have relativeBlock as a dependency) to repositon
 * @param relativeBlock Block to which blocksToReposition will be repositioned against
 * @returns PositionedWorkTree containing the repositioned nodes and edges
 */
async function relativePositioningGraph(
  blocksToReposition: Block[],
  relativeBlock: Node<Block>,
): Promise<PositionedWorkTree> {
  if (!relativeBlock || blocksToReposition.length === 0) {
    return { nodes: [], edges: [] };
  }
  // We include the relative block for purposes of the layout algorithm later
  const nodes = [...blocksToReposition, relativeBlock.data]
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    .map((block): ElkNode => ({ id: block.id, width: NODE_WIDTH, height: NODE_HEIGHT }));

  const dependencyEdges = blocksToReposition
    .filter(targetBlock => targetBlock.dependencies?.length > 0)
    .flatMap(targetBlock =>
      targetBlock.dependencies?.map(
        (sourceBlockId): ElkExtendedEdge => ({
          id: `${EDGE_TYPE_VERSION}:${targetBlock.id}-${sourceBlockId}`,
          sources: [sourceBlockId],
          targets: [targetBlock.id],
        }),
      ),
    );

  const elk = new Elk({
    defaultLayoutOptions: {
      algorithm: 'layered',
      'elk.spacing.nodeNode': String(NODE_Y_GAP),
      'elk.spacing.edgeNode': '80',
      'elk.spacing.componentComponent': String(NODE_Y_GAP),
      'elk.layered.spacing.nodeNodeBetweenLayers': String(NODE_X_GAP),
      'elk.layered.nodePlacement.favorStraightEdges': 'false',
      // "Splines" routing means that each connection comprises a path with one (possibly
      // more in edge cases) bend point. We do not render this as a curve as it was
      // intended, instead preferring the straight lines from the nodes and bend points.
      'elk.edgeRouting': 'SPLINES',
    },
  });

  const graph = await elk.layout({
    id: 'root',
    children: nodes,
    edges: [...dependencyEdges],
  });

  const rePositionedRelativeBlock = graph.children?.find(
    node => node.id === relativeBlock.id,
  );
  if (!rePositionedRelativeBlock || !graph.children || !graph.edges) {
    return { nodes: [], edges: [] };
  }

  const blockById = new Map(blocksToReposition.map(block => [block.id, block]));

  const rePositionedRelativeBlockX = rePositionedRelativeBlock.x ?? 0;
  const rePositionedRelativeBlockY = rePositionedRelativeBlock.y ?? 0;

  const blockXOffset = relativeBlock.position.x - rePositionedRelativeBlockX;
  const blockYOffset = relativeBlock.position.y - rePositionedRelativeBlockY;

  // We don't need to return the relativeBlock here, because we only want to
  // return the blocksToReposition and their positions and edges
  const nodesWithoutRelativeBlock =
    graph.children?.filter(node => node.id !== relativeBlock.id) ?? [];

  return {
    nodes: nodesWithoutRelativeBlock.map((node): Node<Block> => {
      const block = blockById.get(node.id as BlockId);
      if (!block) {
        throw new Error(`Missing block for node with id ${node.id}`);
      }
      return {
        id: node.id,
        type: 'entity',
        data: block,
        style: { width: NODE_WIDTH },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        selectable: true,
        draggable: false,
        position: {
          x: node?.x ? node.x + blockXOffset : 0,
          y: node?.y ? node.y + blockYOffset : 0,
        },
      };
    }),
    edges: graph?.edges.map((edge): Edge<EdgeData> => {
      const path = (edge.sections ?? [])
        .map(edgeSection => {
          return {
            startPoint: {
              x: edgeSection.startPoint.x + blockXOffset,
              y: edgeSection.startPoint.y + blockYOffset,
            },
            bendPoints: edgeSection.bendPoints?.map(bp => {
              return {
                x: bp.x + blockXOffset,
                y: bp.y + blockYOffset,
              };
            }),
            endPoint: {
              x: edgeSection.endPoint.x + blockXOffset,
              y: edgeSection.endPoint.y + blockYOffset,
            },
          };
        })
        .flatMap(section => [
          section.startPoint,
          ...(section.bendPoints ?? []),
          section.endPoint,
        ]);

      return {
        id: edge.id,
        source: edge.sources[0],
        target: edge.targets[0],
        style: reactFlowTypeFromElkEdgeId(edge.id),
        markerEnd: EDGE_END_ARROW_MARKER_ID,
        data: { path },
      };
    }),
  };
}

function elkGraphToReactFlow(
  graph: ElkNode,
  blocks: readonly Block[],
  selectedRootVersion?: SelectedVersion,
): PositionedWorkTree {
  if (!graph.children || !graph.edges) {
    throw new Error('Elk generated no graph children or edges');
  }

  const blockById = new Map(blocks.map(block => [block.id, block]));

  // If the user is in the state where they are viewing versions
  // we should only be showing blocks that are related to that
  // version. This filters out unrelated blocks.
  const selectedVersionBlock = selectedRootVersion
    ? blockById.get(selectedRootVersion?.parentBlockId)
    : undefined;
  const versionDependants =
    selectedVersionBlock?.data?.versionData.flatMap(
      versionBlock => versionBlock.dependants,
    ) ?? [];
  const filteredNodes = graph.children.filter(
    node => !versionDependants?.includes(node.id as BlockId),
  );

  return {
    nodes: filteredNodes.map((node): Node<Block> => {
      const block = blockById.get(node.id as BlockId);
      if (!block) {
        throw new Error(`Missing block for node with id ${node.id}`);
      }
      return {
        id: node.id,
        type: 'entity',
        data: block,
        style: { width: NODE_WIDTH },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        draggable: false,
        position: { x: node.x ?? 0, y: node.y ?? 0 },
      };
    }),
    edges: graph.edges.map((edge): Edge<EdgeData> => {
      const path = (edge.sections ?? []).flatMap(section => [
        section.startPoint,
        ...(section.bendPoints ?? []),
        section.endPoint,
      ]);

      return {
        id: edge.id,
        source: edge.sources[0],
        target: edge.targets[0],
        style: reactFlowTypeFromElkEdgeId(edge.id),
        markerEnd: EDGE_END_ARROW_MARKER_ID,
        data: { path },
      };
    }),
  };
}
function reactFlowTypeFromElkEdgeId(edgeId: string): CSSProperties {
  if (edgeId.startsWith(EDGE_TYPE_DEPENDENCY) || edgeId.startsWith(EDGE_TYPE_VERSION)) {
    return {};
  } else if (edgeId.startsWith(EDGE_TYPE_REVISION)) {
    return {
      marker: 'none',
      strokeDasharray: '6,6',
      strokeWidth: '2',
    };
  } else {
    throw new Error(`Unexpected edge ID: ${edgeId}`);
  }
}
