import { ApolloClient } from '@apollo/client';
import { useApolloClient } from '@apollo/client';

import { getResultOrThrow } from 'client/app/api/apolloClient';
import { MUTATION_CREATE_DOE_TEMPLATE } from 'client/app/api/gql/mutations';
import { usePartialCallback } from 'common/ui/hooks/usePartialCallback';

/**
 * Create a DOE Template from a simulation ID. This is an experimental form of a
 * workflow, where parameters can be configured with possible values to be used
 * within the design tool.
 */
async function createDOETemplate(
  apollo: ApolloClient<object>,
  simulationId: SimulationId,
): Promise<WorkflowId> {
  const createDOETemplateResult = await apollo.mutate({
    mutation: MUTATION_CREATE_DOE_TEMPLATE,
    variables: {
      simulationId,
    },
  });
  return getResultOrThrow(
    createDOETemplateResult,
    'Create DOE Template',
    data => data.createDOETemplate.id,
  );
}

export function useCreateDOETemplate() {
  const apollo = useApolloClient();
  return usePartialCallback(apollo, createDOETemplate);
}
