import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export const ReleaseQualityBetaIcon = React.memo(function ReleaseQualityBetaIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" fill="none">
        <path
          d="M9.17 6l2 2H20v10H4V6h5.17zM10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"
          fill="currentColor"
        />
        <path
          d="M9.848 16v-5.688h2.043c.731 0 1.287.135 1.668.403.382.268.574.657.574 1.168 0 .294-.068.545-.203.754a1.183 1.183 0 01-.598.46c.297.079.525.225.684.438.159.214.238.474.238.781 0 .558-.177.977-.531 1.258-.352.279-.874.42-1.567.426H9.848zm1.37-2.418v1.363h.9c.247 0 .437-.056.57-.168.132-.114.199-.274.199-.48 0-.474-.236-.712-.707-.715h-.961zm0-.898h.716c.3-.003.513-.058.64-.164.128-.107.192-.265.192-.473 0-.24-.07-.412-.207-.516-.138-.107-.361-.16-.668-.16h-.672v1.313z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
