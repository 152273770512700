import React from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { PlateInfo } from 'client/app/api/ChecklistApi';
import {
  flattenPlateContents,
  getWellName,
} from 'client/app/components/PlatePrep/helpers';
import { formatMeasurementObj, formatWellPosition } from 'common/lib/format';
import { WellContents, WellLocationOnDeckItem } from 'common/types/mix';
import { PlateType } from 'common/types/plateType';
import Colors from 'common/ui/Colors';
import PlateLayout from 'common/ui/components/PlateLayout/PlateLayout';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { getLayoutForWellSelectorWithPlateDimensions } from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import makeWellSelector from 'common/ui/components/simulation-details/PlateTransform';

const MANY_ROWS = 12;
const MANY_WELLS = 500;

type Props = {
  plateInfo: PlateInfo;
  plateTypeData: PlateType | undefined;
  liquidColors: LiquidColors;
};

export default function PlateSection({ plateInfo, plateTypeData, liquidColors }: Props) {
  if (!plateTypeData) {
    throw new Error(`No plate type data: ${plateInfo.plate_type}`);
  }

  const plateState = {
    ...makeWellSelector(plateTypeData),
    contents: plateInfo.well_contents,
  };
  const plateContents = flattenPlateContents(plateState);

  const deckLayout = getLayoutForWellSelectorWithPlateDimensions(plateState);
  const geometry = deckLayout.getCurrentGeometry(plateState);
  const { width: plateWidth, height: plateHeight } = geometry.getDimensions();

  const allColumns = getLiquidColumns(plateContents, plateState);
  const columnsCount = Math.max(Math.floor(MANY_ROWS / plateState.rows), 1);
  const plateSize = plateState.rows * plateState.columns;

  const hasLiquids = allColumns.flat().length > 0;
  const noLiquids = !hasLiquids;

  return (
    <Grid>
      <PlateDetails>
        <Stack direction="row" gap={2}>
          <Typography variant="body1" fontWeight={500} fontSize={11}>
            Name:
          </Typography>
          <Typography variant="body1" fontSize={11}>
            {plateInfo.name}
          </Typography>
        </Stack>
        <Stack direction="row" gap={2}>
          <Typography variant="body1" fontWeight={500} fontSize={11}>
            Type:
          </Typography>
          <Typography variant="body1" fontSize={11}>
            {plateState.type}
          </Typography>
        </Stack>
        <Stack direction="row" gap={2}>
          <Typography variant="body1" fontWeight={500} fontSize={11}>
            Manufacturer:
          </Typography>
          <Typography variant="body1" fontSize={11}>
            {plateState.manufacturer}
          </Typography>
        </Stack>
      </PlateDetails>

      {hasLiquids && (
        <PlatePreview viewBox={`0 0 ${plateWidth} ${plateHeight}`}>
          <PlateLayout
            plate={plateState}
            geometry={geometry}
            liquidColors={liquidColors}
            showContentLabels
          />
        </PlatePreview>
      )}

      {noLiquids ? (
        <LiquidsAside>
          <LiquidsHeadline variant="body1">Liquids</LiquidsHeadline>
          <Typography variant="caption" fontStyle="italic" fontSize={8}>
            None
          </Typography>
        </LiquidsAside>
      ) : plateSize < MANY_WELLS ? (
        <>
          <LiquidsAside>
            <LiquidsHeadline variant="body1">Liquids</LiquidsHeadline>
            <LiquidGroup
              liquidColumns={allColumns.slice(0, columnsCount)}
              liquidColors={liquidColors}
            />
          </LiquidsAside>
          <LiquidsSection>
            <LiquidGroup
              liquidColumns={allColumns.slice(columnsCount)}
              liquidColors={liquidColors}
            />
          </LiquidsSection>
        </>
      ) : (
        <LiquidsSection>
          <LiquidsHeadline variant="body1">Liquids</LiquidsHeadline>
          <LiquidGroup liquidColumns={allColumns} liquidColors={liquidColors} />
        </LiquidsSection>
      )}
    </Grid>
  );
}

type LiquidGroupProps = {
  liquidColumns: WellContentsWithPosition[][];
  liquidColors: LiquidColors;
};

const LiquidGroup = ({ liquidColumns, liquidColors }: LiquidGroupProps) => (
  <>
    {liquidColumns.map((group, groupIdx) => (
      <LiquidList key={groupIdx}>
        {group.map(({ loc, wellContents }, idx) => (
          <LiquidItem key={idx}>
            <PositionCircle color={liquidColors.getColorForWell(wellContents)}>
              {formatWellPosition(loc)}
            </PositionCircle>
            <LiquidName variant="body1">{getWellName(wellContents)}</LiquidName>
            {wellContents.total_volume && (
              <Typography
                variant="body1"
                color="textPrimary"
                fontWeight={500}
                fontSize={8}
              >
                {formatMeasurementObj(wellContents.total_volume)}
              </Typography>
            )}
          </LiquidItem>
        ))}
      </LiquidList>
    ))}
  </>
);

type WellContentsWithPosition = {
  loc: WellLocationOnDeckItem;
  wellContents: WellContents;
};

function getLiquidColumns(
  plateContents: WellContentsWithPosition[] | undefined,
  plateState: PlateState,
): WellContentsWithPosition[][] {
  const columns = [];
  if (Array.isArray(plateContents)) {
    const buffer = [...plateContents];

    const groupSize = plateState.rows;
    columns.push(buffer.splice(0, groupSize));

    if (groupSize < plateState.rows) {
      columns.push(buffer.splice(0, plateState.rows - groupSize));
    }

    while (buffer.length > 0) {
      columns.push(buffer.splice(0, plateState.rows));
    }
  }
  return columns;
}

//#region Styles

const Grid = styled('main')(({ theme }) => ({
  display: 'grid',
  columnGap: theme.spacing(6),
  rowGap: theme.spacing(6),
  gridTemplateAreas: `
    "details preview"
    "liquids-aside preview"
    ". preview"
    "liquids-section liquids-section"
  `,
  gridTemplateColumns: '1fr 1fr',
}));

const PlateDetails = styled('section')(({ theme }) => ({
  gridArea: 'details',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}));

const PlatePreview = styled('svg')({
  gridArea: 'preview',
  maxHeight: '15cm',

  '@media print': {
    pageBreakInside: 'avoid',
  },
});

const LiquidsAside = styled('aside')(({ theme }) => ({
  gridArea: 'liquids-aside',

  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(5),
}));

const LiquidsSection = styled('section')(({ theme }) => ({
  gridArea: 'liquids-section',

  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(9),
}));

const PositionCircle = styled('div', { shouldForwardProp: prop => prop !== 'color' })<{
  color: string;
}>(({ theme, color }) => ({
  alignSelf: 'center',
  textAlign: 'center',

  border: `1px solid ${Colors.GREY_40}`,
  borderRadius: 32,

  fontSize: 8,
  padding: 6,
  minWidth: 24,

  color: theme.palette.getContrastText(color),
  backgroundColor: color,
}));

const LiquidName = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  maxWidth: 100,
  fontSize: 8,
}));

const LiquidList = styled('ul')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'repeat(4, auto)',
  gridTemplateColumns: 'repeat(auto-fit, minmax(1px, max-content))',
  gridAutoFlow: 'column',
  gap: theme.spacing(5),

  margin: 0,
  padding: 0,
  listStyle: 'none',

  '@media print': {
    pageBreakInside: 'avoid',
  },
}));

const LiquidItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

const LiquidsHeadline = styled(Typography)({
  fontWeight: 500,
  fontSize: 11,
  flexBasis: '100%',

  '@media print': {
    pageBreakAfter: 'avoid',
  },
});

//#endregion
