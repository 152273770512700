import React, { useCallback, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { ColumnMetadataType } from 'common/types/spreadsheetEditor';
import Button from 'common/ui/components/Button';
import Dropdown from 'common/ui/filaments/Dropdown';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

export type MetadataPrefixOption = Exclude<
  ColumnMetadataType,
  'solute_concentration_unit'
>;

const OPTIONS: { label: string; value: MetadataPrefixOption }[] = [
  { label: 'Tag', value: 'tag' },
  { label: 'Subcomponent', value: 'solute_concentration' },
];
type ColumnInfo = { name: string; prefix: MetadataPrefixOption };
type AddColumnDialogProps = {
  existingColumns: string[];
} & Partial<ColumnInfo>;
/**
 * Helper dialog to add columns to a Table UI instance.
 */
export default function AddColumnDialog(
  props: DialogProps<ColumnInfo | null> & AddColumnDialogProps,
) {
  const classes = useStyles();
  const { isOpen, onClose, existingColumns } = props;

  const [name, setName] = useState(props.name ?? '');
  const handleChangeName = useTextFieldChange(setName);

  const [prefix, setPrefix] = useState<MetadataPrefixOption | undefined>(props.prefix);
  const handleChangePrefix = useCallback((newPrefix?: MetadataPrefixOption) => {
    if (!newPrefix) {
      return;
    }
    setPrefix(newPrefix);
  }, []);

  // Reset dialog values when opening it.
  useEffect(() => {
    setName(props.name ?? '');
    setPrefix(props.prefix);
  }, [isOpen, props.name, props.prefix]);

  const handleOK = () => {
    if (!prefix) {
      return;
    }

    onClose({ name, prefix });
  };

  const handleCancel = () => {
    onClose(null);
  };

  const maybeDuplicateColumn = existingColumns.find(
    column => `${prefix}:${name}` === column,
  );
  const isEditingColumn = !!props.name;
  const hasDuplicatedColumn = !!maybeDuplicateColumn && !isEditingColumn;
  const duplicateColumnError = () => {
    if (!hasDuplicatedColumn) {
      return null;
    }
    if (prefix === 'tag') {
      return `"${name}" tag already exists.`;
    } else {
      return `Subcomponent "${name}" already exists.`;
    }
  };

  const getLabel = (prefix?: string) => {
    if (prefix === 'solute_concentration') {
      return 'Subcomponent name';
    }
    if (prefix === 'tag') {
      return 'Metadata';
    }

    return '';
  };
  const emptyName = !name.trim();
  const valueLabel = OPTIONS.find(option => option.value === prefix)?.label;

  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={handleCancel}>
      <DialogTitle>Add Subcomponent or Tag</DialogTitle>
      <DialogContent className={classes.content}>
        <Dropdown
          placeholder="Please select an option"
          valueLabel={valueLabel ?? ''}
          options={OPTIONS}
          onChange={handleChangePrefix}
          className={classes.textfield}
          // Prevent editing the prefix. This simplifies the logic with
          // the `solute_concentration` and `unit` pair of columns.
          // TABLE-47 aims to make merge columns into one: it'll then
          // be easy to edit a column type.
          isDisabled={!!props.prefix}
        />
        <TextField
          fullWidth
          value={name}
          label={getLabel(prefix)}
          onChange={handleChangeName}
          className={classes.textfield}
          error={hasDuplicatedColumn}
          helperText={duplicateColumnError()}
          disabled={!prefix}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="tertiary"
          onClick={handleOK}
          color="primary"
          disabled={hasDuplicatedColumn || !prefix || emptyName}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStylesHook(theme => ({
  content: {
    color: '#000',
    whiteSpace: 'pre-line',
    width: '380px',
  },
  textfield: {
    marginBottom: theme.spacing(6),
  },
}));
