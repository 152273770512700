import React, { useContext } from 'react';

import Add from '@mui/icons-material/Add';
import Visibility from '@mui/icons-material/VisibilityOutlined';

import CommentContext from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/components/CommentContext';
import Button from 'common/ui/components/Button';

type Props = {
  comment: string | null;
  orderNum: number;
  executionStageId: string;
};

export default function CommentButton({ comment, orderNum, executionStageId }: Props) {
  const { openDialogue } = useContext(CommentContext);

  let commentActionCopy: string;
  let commentActionIcon: JSX.Element;
  const commentAction = async () => {
    await openDialogue({
      existingComment: comment,
      orderNum: orderNum,
      executionStageId: executionStageId,
      readonly: !!comment,
    });
  };

  if (comment) {
    commentActionCopy = 'View comment';
    commentActionIcon = <Visibility fontSize="small" />;
  } else {
    commentActionCopy = 'Add comment';
    commentActionIcon = <Add fontSize="small" />;
  }

  return (
    <Button
      variant="tertiary"
      color="primary"
      startIcon={commentActionIcon}
      onClick={commentAction}
    >
      {commentActionCopy}
    </Button>
  );
}
