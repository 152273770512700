import { useEffect } from 'react';

import { UserProfileType } from 'client/app/hooks/useUserProfile';

declare global {
  // Normally we want to avoid the declaration merging that happens with
  // interfaces in TypeScript but here we actually want to use it to
  // augment the global window variable.
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    Intercom: ((...args: any) => void) & {
      q: any[];
      c: (args: any) => void;
    };
  }
}

export default function useIntercomService(
  userProfile: UserProfileType,
  isIntercomWidgetVisible: boolean,
  // ID of element that serves as Intercom launcher
  customLauncherId: string,
) {
  useEffect(() => {
    if (
      userProfile?.intercomUserId &&
      userProfile?.intercomAppId &&
      userProfile?.intercomToken
    ) {
      initIntercom({ userProfile, customLauncherId });
    }
    if (userProfile.intercomToken === '' || userProfile.intercomAppId === '') {
      console.error('User profile does not have the proper token to start Intercom.');
    }
  }, [userProfile, customLauncherId]);

  useEffect(() => {
    if (isIntercomWidgetVisible) {
      showIntercomWidget();
    } else {
      hideIntercomWidget();
    }
  });
}

// Load Intercom into a script and initialise it
function initIntercom({
  userProfile,
  customLauncherId,
}: {
  userProfile: UserProfileType;
  customLauncherId: string;
}) {
  const name = `${userProfile.firstName} ${userProfile.lastName}`;

  _loadIntercom(userProfile.intercomAppId);

  window.Intercom('boot', {
    app_id: userProfile.intercomAppId,
    user_id: userProfile.intercomUserId,
    user_hash: userProfile.intercomToken,
    name,
    email: userProfile.email,
    custom_launcher_selector: '#' + customLauncherId,
    hide_default_launcher: true,
    company: {
      company_id: userProfile.organisationHumanIdentifier,
      name: userProfile.organisationName,
    },
  });

  // Wait for the iframe to become ready (max 30 seconds)
  const timeout = setTimeout(() => clearInterval(interval), 30000);
  const interval = setInterval(() => {
    const intercomWidget = document.querySelector('.intercom-launcher-frame');

    if (intercomWidget) {
      const intercomContainer = document.querySelector(
        '#intercom-container',
      ) as HTMLElement;
      if (intercomContainer) {
        intercomContainer.style.height = '15px';
        intercomContainer.style.width = '15px';
        intercomContainer.style.background = 'rgb(51, 51, 51)';
        intercomContainer.style.borderRadius = '50%';
        intercomContainer.style.display = 'none';
      }

      clearInterval(interval);
      clearTimeout(timeout);
    }
  }, 100);
}

function showIntercomWidget() {
  const intercomWidget = document.getElementById('intercom-container');
  if (intercomWidget) {
    intercomWidget.style.display = 'block';
  }
}

function hideIntercomWidget() {
  const intercomWidget = document.getElementById('intercom-container');
  if (intercomWidget) {
    intercomWidget.style.display = 'none';
  }
}

/**
 * Load intercom into a script tag and add to document
 *
 * @param {String} intercomAppId ID to link Intercom with app
 */
function _loadIntercom(intercomAppId: string) {
  // Only initialise if Intercom isn't already initialised
  if (typeof window.Intercom !== 'function') {
    // Bootstrapping callback code
    // This is the way Intercom bootstraps their init,
    // so we don't have to wait for the JS to load insert
    // before we can use Intercom interface
    // const i = (args) => { i.c(...args); };
    const i = Object.assign(
      (...args: any) => {
        i.c(args); // eslint-disable-line prefer-rest-params
      },
      {
        q: [],
        c: function (args: any) {
          i.q.push(args);
        },
      } as { q: any[]; c: (args: any) => void },
    );
    window.Intercom = i;
    // End - Bootstrapping callback code

    const load = () => {
      const s = window.document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${intercomAppId}`;
      const x = window.document.getElementsByTagName('head')[0];
      x.appendChild(s);
    };

    // Normally we would do this on body load or etc, but since we have a full app page
    // this event isn't reliable on refreshes; It is however a given that our document is
    // ready at this moment and script tags should be there, therefor just run the load
    // script since it has an async tag anyway, so no negative impact on end-user.
    load();
  }
}
