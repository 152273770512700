import { useMemo } from 'react';

import { Factor } from 'client/app/components/Parameters/FiltrationProtocolDesign/types';
import { pluralize } from 'common/lib/format';

const MULTIPLY_SYMBOL = '\u00D7';

export function useLiquidCount(factors: Factor[]) {
  const result = useMemo(
    () =>
      factors.length === 0
        ? null
        : factors.reduce(
            (result, nextFactor) => {
              const multiplicator =
                nextFactor.type === 'subComponent'
                  ? nextFactor.value.names.length * nextFactor.value.values.length
                  : nextFactor.value.length;

              let equationPart: string;
              if (result.equation.length === 0) {
                equationPart = multiplicator.toString();
              } else {
                equationPart = ` ${MULTIPLY_SYMBOL} ${multiplicator}`;
              }

              return {
                equation: result.equation + equationPart,
                count: result.count * multiplicator,
              };
            },
            { equation: '', count: 1 },
          ),
    [factors],
  );

  const equationString = !result
    ? '1 liquid'
    : result.equation.includes(MULTIPLY_SYMBOL)
    ? `${result.equation} = ${pluralize(result.count, 'liquid')}`
    : pluralize(result.count, 'liquid');
  const count = result?.count ?? 1;

  return { equationString, count };
}
