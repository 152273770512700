import memo from 'lodash/memoize';
import { v4 as uuid } from 'uuid';

import {
  ConfiguredDevice,
  ConfiguredDeviceDeviceId,
  ConfiguredDeviceId,
  DeviceType,
  hasDeck,
  WorkflowConfig,
} from 'common/types/bundle';
import { getAnthaDeviceTypeByDeviceClass } from 'common/types/bundleTransforms';
import { Device, SimpleDevice } from 'common/types/device';

/**
 * When config is set, we need to tweak some fields which should be applied to
 * configuredDevices rather that to the global config.
 */
export function updateConfigAfterSet(
  config: WorkflowConfig,
  deviceIds?: string[],
): WorkflowConfig {
  const { tipTypes, inputPlateTypes } = config.global;

  return {
    ...config,
    configuredDevices: config.configuredDevices?.map(cd => {
      if (deviceIds && !deviceIds.includes(cd.id)) {
        return cd;
      }
      if (!hasDeck(cd.type)) {
        return cd;
      }
      return {
        ...cd,
        tipTypes,
        inputPlateTypes,
      };
    }),
  };
}

/**
 * Remove an accessible device from the workflow config.
 * Useful when the user toggles the accessible device to OFF.
 */
export function removeAccessibleDeviceByConfiguredDeviceID(
  configuredDevices: ConfiguredDevice[],
  accessibleDeviceIdToRemove: ConfiguredDeviceId,
): ConfiguredDevice[] {
  return configuredDevices
    .filter(cd => cd.id !== accessibleDeviceIdToRemove)
    .map(cd => {
      return {
        ...cd,
        accessibleDeviceConfigurationIds: cd.accessibleDeviceConfigurationIds?.filter(
          id => id !== accessibleDeviceIdToRemove,
        ),
      };
    });
}

export function removeAccessibleDeviceByDeviceId(
  configuredDevices: ConfiguredDevice[],
  accessibleDeviceDeviceIdToRemove: ConfiguredDeviceDeviceId,
) {
  const id = configuredDevices.find(
    cd => cd.deviceId === accessibleDeviceDeviceIdToRemove,
  )?.id;

  return id
    ? removeAccessibleDeviceByConfiguredDeviceID(configuredDevices, id)
    : configuredDevices;
}

export function addAccessibleDevice(
  configuredDevices: ConfiguredDevice[],
  accessibleDeviceToAdd: SimpleDevice,
  parentDeviceId: string,
): ConfiguredDevice[] {
  const newConfiguredAccessibleDeviceId = uuid() as ConfiguredDeviceId;
  const newConfiguredDevices = configuredDevices.map(cd => {
    if (cd.deviceId !== parentDeviceId) {
      return cd;
    }
    return {
      ...cd,
      accessibleDeviceConfigurationIds: [
        ...new Set(cd.accessibleDeviceConfigurationIds).add(
          newConfiguredAccessibleDeviceId,
        ),
      ],
    };
  });

  newConfiguredDevices.push({
    id: newConfiguredAccessibleDeviceId,
    deviceId: accessibleDeviceToAdd.id as ConfiguredDeviceDeviceId,
    type: getAnthaDeviceTypeByDeviceClass(accessibleDeviceToAdd.anthaLangDeviceClass),
    // As of Jun 2020, accessible devices are always plate washers
    // and plate readers which have no run configs.
    // This could change in the future.
    runConfigId: undefined,
  });

  return newConfiguredDevices;
}

export function configHasNoDevices(workflowConfig: WorkflowConfig) {
  return (workflowConfig.configuredDevices ?? []).length === 0;
}

export function configHasNoInputPlates(workflowConfig: WorkflowConfig) {
  const configHasInputPlates = workflowConfig.configuredDevices?.some(cd => {
    return (cd.inputPlateTypes || []).length > 0;
  });
  return !configHasInputPlates;
}

/**
 * Dispensers behave differently than other liquid handlers
 * (e.g. they do not have a deck options config or the ability to be sent to the lab.)
 *
 */
export const DISPENSERS: DeviceType[] = [
  'Formulatrix',
  'Tempest',
  'TTP',
  'Labcyte',
  'CertusFlex',
  'Echo650',
  'Echo655',
  'GilsonPipettePilot',
];

export const PERIPHERALS: DeviceType[] = [
  'PlateReader',
  'PlateWasher',
  'ShakerIncubator',
];

export type GenericDeviceType =
  | 'DataOnly'
  | 'Manual'
  | 'LiquidHandler'
  | 'Dispenser'
  | 'PlateReader'
  | 'PlateWasher'
  | 'ShakerIncubator'
  | 'Unknown';

export function getGenericDeviceType(deviceType: DeviceType): GenericDeviceType {
  switch (deviceType) {
    case 'PlateReader':
    case 'PlateWasher':
    case 'ShakerIncubator':
    case 'DataOnly':
    case 'Manual':
    case 'Unknown':
      return deviceType;
    default:
      return DISPENSERS.includes(deviceType) ? 'Dispenser' : 'LiquidHandler';
  }
}

export function getGenericDeviceTypeFromAnthaClass(anthaClass: string) {
  return getGenericDeviceType(getAnthaDeviceTypeByDeviceClass(anthaClass));
}

export function isDispenserDevice(device: ConfiguredDevice) {
  return getGenericDeviceType(device.type) === 'Dispenser';
}

export function isManualDevice(device: ConfiguredDevice) {
  return device.type === 'Manual';
}

export function isDeviceThatCanPerformLiquidHandling(device: ConfiguredDevice) {
  const type = getGenericDeviceType(device.type);
  return type === 'LiquidHandler' || type === 'Dispenser' || type === 'Manual';
}

export function hasDispenserDevice(config: WorkflowConfig) {
  return !!config.configuredDevices?.some(isDispenserDevice);
}

export const hasPeripheralDeviceOnly = memo(function hasPeripheralDeviceOnly(
  config: WorkflowConfig,
) {
  return (
    config.configuredDevices?.length === 1 &&
    !!PERIPHERALS.includes(config.configuredDevices?.[0].type)
  );
});

export const hasManualDevice = memo(function hasManualDevice(config: WorkflowConfig) {
  return !!config.configuredDevices?.some(isManualDevice);
});

export function hasLiquidHandler(config: WorkflowConfig) {
  return !!config.configuredDevices?.some(
    device => getGenericDeviceType(device.type) === 'LiquidHandler',
  );
}

export function hasDispenserOrManualDevice(config: WorkflowConfig) {
  return hasDispenserDevice(config) || hasManualDevice(config);
}

export const isDataOnly = memo(function isDataOnly(config: WorkflowConfig) {
  const noDeviceRequired = config.global.requiresDevice === false;
  return noDeviceRequired && (config.configuredDevices ?? []).length === 0;
});

export function getManualDevice(devices: Device[]): Device | undefined {
  return devices.find(d => d.anthaLangDeviceClass === 'Manual');
}
