import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ConcentrationIcon = React.memo<SvgIconProps>(function EditIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.72852 12.3203V12.6963C8.72852 12.96 8.78711 13.2025 8.9043 13.4238C9.02474 13.6452 9.20215 13.8226 9.43652 13.9561C9.6709 14.0895 9.96549 14.1562 10.3203 14.1562C10.6719 14.1562 10.9632 14.0895 11.1943 13.9561C11.4287 13.8226 11.6029 13.6452 11.7168 13.4238C11.834 13.2025 11.8926 12.96 11.8926 12.6963V12.3203C11.8926 12.0501 11.834 11.8044 11.7168 11.583C11.6029 11.3617 11.4287 11.1842 11.1943 11.0508C10.96 10.9173 10.6637 10.8506 10.3057 10.8506C9.95736 10.8506 9.66602 10.9173 9.43164 11.0508C9.19727 11.1842 9.02148 11.3617 8.9043 11.583C8.78711 11.8044 8.72852 12.0501 8.72852 12.3203ZM9.7832 12.6963V12.3203C9.7832 12.2161 9.80273 12.1201 9.8418 12.0322C9.88086 11.9443 9.93945 11.8743 10.0176 11.8223C10.0957 11.7669 10.1917 11.7393 10.3057 11.7393C10.4261 11.7393 10.5254 11.7669 10.6035 11.8223C10.6816 11.8743 10.7386 11.9443 10.7744 12.0322C10.8135 12.1201 10.833 12.2161 10.833 12.3203V12.6963C10.833 12.7939 10.8135 12.8867 10.7744 12.9746C10.7386 13.0625 10.6833 13.1341 10.6084 13.1895C10.5335 13.2415 10.4359 13.2676 10.3154 13.2676C10.1982 13.2676 10.099 13.2415 10.0176 13.1895C9.93945 13.1341 9.88086 13.0625 9.8418 12.9746C9.80273 12.8867 9.7832 12.7939 9.7832 12.6963Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1025 16.3193V16.6953C12.1025 16.9622 12.1611 17.2064 12.2783 17.4277C12.3988 17.6491 12.5778 17.8265 12.8154 17.96C13.0531 18.0934 13.3493 18.1602 13.7041 18.1602C14.0557 18.1602 14.347 18.0934 14.5781 17.96C14.8092 17.8265 14.9818 17.6491 15.0957 17.4277C15.2129 17.2064 15.2715 16.9622 15.2715 16.6953V16.3193C15.2715 16.0524 15.2129 15.8083 15.0957 15.5869C14.9818 15.3656 14.8076 15.1882 14.5732 15.0547C14.3421 14.9212 14.0475 14.8545 13.6895 14.8545C13.3411 14.8545 13.0482 14.9212 12.8105 15.0547C12.5762 15.1882 12.3988 15.3656 12.2783 15.5869C12.1611 15.8083 12.1025 16.0524 12.1025 16.3193ZM13.1621 16.6953V16.3193C13.1621 16.2184 13.1816 16.124 13.2207 16.0361C13.2598 15.9482 13.3184 15.8783 13.3965 15.8262C13.4746 15.7708 13.5723 15.7432 13.6895 15.7432C13.8066 15.7432 13.9043 15.7708 13.9824 15.8262C14.0605 15.8783 14.1191 15.9482 14.1582 16.0361C14.1973 16.124 14.2168 16.2184 14.2168 16.3193V16.6953C14.2168 16.7962 14.2038 16.8906 14.1777 16.9785C14.1549 17.0664 14.1061 17.138 14.0312 17.1934C13.9596 17.2454 13.849 17.2715 13.6992 17.2715C13.6016 17.2715 13.512 17.2454 13.4307 17.1934C13.3493 17.138 13.2842 17.0664 13.2354 16.9785C13.1865 16.8906 13.1621 16.7962 13.1621 16.6953Z"
        fill="currentColor"
      />
      <path
        d="M10.6279 17.5303L14.0996 11.9736C14.0996 11.9736 14.2031 11.6797 13.8828 11.5156C13.5625 11.3516 13.333 11.5879 13.333 11.5879L9.86133 17.1445C9.86133 17.1445 9.74219 17.4297 10.0859 17.6328C10.4297 17.8359 10.6279 17.5303 10.6279 17.5303Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.87076 5.06247C10.1828 4.63882 10.4883 4.22408 10.7795 3.82012C11.2301 3.1949 11.6465 2.59549 12 2.02881C12.3535 2.59549 12.7699 3.1949 13.2205 3.82012C13.5117 4.22407 13.8172 4.63879 14.1292 5.06241C16.3922 8.13473 19 11.6753 19 14.9709C19 18.8369 15.866 21.9709 12 21.9709C8.13401 21.9709 5 18.8369 5 14.9709C5 11.6753 7.6078 8.13478 9.87076 5.06247ZM12 19.9709C9.23858 19.9709 7 17.7324 7 14.9709C7 13.3809 7.88157 11.4742 9.33054 9.27617C10.007 8.25002 10.7495 7.24181 11.4917 6.23421L11.5725 6.12448C11.7149 5.93113 11.8578 5.73697 12 5.54262C12.1422 5.73697 12.2851 5.93113 12.4275 6.12448L12.5083 6.23419C13.2504 7.24178 13.993 8.25003 14.6695 9.27617C16.1184 11.4742 17 13.3809 17 14.9709C17 17.7324 14.7614 19.9709 12 19.9709Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
