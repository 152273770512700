import React, { useState } from 'react';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';

export function FoldedSection({
  label,
  onExpand,
  children,
}: {
  label: string;
  onExpand: () => void;
  children?: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);

  function onClick(prevIsOpen: boolean) {
    if (!prevIsOpen) {
      onExpand();
    }
    return !prevIsOpen;
  }

  return (
    <Section onClick={() => setOpen(onClick)}>
      <SectionLabel>{label}</SectionLabel>
      <Chevron open={open} />
      {open && (
        <SectionContent onClick={e => e.stopPropagation()}>{children}</SectionContent>
      )}
    </Section>
  );
}

//#region Styles

export const ResourcesContainer = styled('aside')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  padding: theme.spacing(6, 4, 6, 6),
  backgroundColor: Colors.GREY_10,
  borderLeft: `1px solid ${theme.palette.divider}`,

  minWidth: 405,
}));

export const FoldedSections = styled('main')(({ theme }) => ({
  flexGrow: 1,
  height: 0,
  overflowY: 'auto',
  scrollbarGutter: 'stable',

  marginTop: theme.spacing(6),
}));

const Section = styled('section')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',

  padding: theme.spacing(5, 3, 5, 0),

  cursor: 'pointer',
  userSelect: 'none',

  borderTop: `1px solid ${theme.palette.divider}`,

  '&:last-of-type': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const Chevron = styled(ChevronRightOutlinedIcon, {
  shouldForwardProp: prop => prop !== 'open',
})<{ open: boolean }>(({ open }) => ({
  transition: 'transform 100ms linear',
  transform: open ? 'rotate(90deg)' : 'unset',
}));

const SectionLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: '0.1px',
}));

const SectionContent = styled(Stack)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(3),
  paddingTop: theme.spacing(5),

  cursor: 'default',
  userSelect: 'text',

  gap: theme.spacing(5),
}));

//#endregion
