import { PlateType, WellShapeEnum } from 'common/types/plateType';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';

export const DUMMY_POSITION = 'position_1';

/**
 * Take a legacy representation of a plate, from the legacy inventory
 * microservice, and turn it into a modern plate representation.
 * This is used to take plates stored in WorkflowBuilderStateContext
 * and transform them into plates that are usable in the Simulation
 * Details UI code.
 */
export default function makeWellSelector(plate: PlateType): PlateState {
  return {
    id: plate.id,
    name: plate.name,
    type: plate.type,
    manufacturer: plate.manufacturer,
    color: plate.color,
    description: plate.description,
    kind: 'plate',
    currentDeckPositionName: DUMMY_POSITION,
    currentRotationDegrees: 0,
    rows: plate.rows,
    columns: plate.columns,
    well_type:
      plate.wellShape.type === WellShapeEnum.CIRCLE ||
      plate.wellShape.type === WellShapeEnum.CYLINDER
        ? 'cylinder'
        : 'cuboid',
    residual_volume: { value: 1, unit: 'ul' },
    dimensions: {
      x_mm: plate.dimension.x,
      y_mm: plate.dimension.y,
      z_mm: plate.dimension.z,
    },
    well_dimensions: {
      x_mm: plate.wellShape.dimensionMm.x,
      y_mm: plate.wellShape.dimensionMm.y,
      z_mm: plate.wellShape.dimensionMm.z,
    },
    well_offset: {
      x_mm: plate.wellOffset.x,
      y_mm: plate.wellOffset.y,
    },
    well_start: {
      x_mm: plate.wellStart.x,
      y_mm: plate.wellStart.y,
      z_mm: plate.wellStart.z,
    },
  };
}
