import { useCallback } from 'react';

import * as filetreeClient from 'client/app/api/filetree';
import { extractPlates } from 'client/app/apps/simulation-details/dataUtils';
import { TreeEntry } from 'client/app/apps/simulation-details/overview/TreeEntryList';
import { SimulationQuery } from 'client/app/gql';
import { CSVColumn, toCSV } from 'client/app/lib/csv';
import { WorkflowElementFile, WorkflowFile } from 'client/app/lib/workflow/types';
import { assert } from 'common/lib/assertions';
import { isDefined } from 'common/lib/data';
import { downloadTextFile } from 'common/lib/download';
import { formatWellPosition, roundNumber } from 'common/lib/format';
import { byName, concatURL } from 'common/lib/strings';
import { Deck, Plate, WellContents } from 'common/types/mix';
import { SIMULATION_DETAILS_OVERVIEW_TAB_ID } from 'common/ui/AnalyticsConstants';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

export function fetchOutputPlateFiles(
  simulationId: SimulationId,
  deck: Deck,
): Promise<readonly TreeEntry[]> {
  const plates = extractPlates(deck);
  return Promise.resolve(
    plates
      .sort(byName)
      .map(p =>
        fileEntry(p.plateFileName, () =>
          downloadPlateFile(
            simulationId,
            p.plate,
            p.plateFileName,
            'download-output-plate-file-from-sidebar',
          ),
        ),
      ),
  );
}

type WellContentsWithLocation = {
  row: number;
  col: number;
  well: WellContents;
};

export function downloadPlateFile(
  simulationId: SimulationId,
  plate: Plate,
  plateFileName: string,
  analyticsEventAction: string,
) {
  logEvent(analyticsEventAction, SIMULATION_DETAILS_OVERVIEW_TAB_ID, plateFileName);

  const contents = Object.entries(plate.contents ?? {}).flatMap(([col, rows]) =>
    Object.entries(rows).map(([row, well]) => ({
      row: parseInt(row, 10),
      col: parseInt(col, 10),
      well,
    })),
  );

  // We assume that a plate can only have a liquid_summary or
  // filter_matrix_summary (or something else), but not both
  const liquidContents = contents.filter(x => x.well.kind === 'liquid_summary');
  if (liquidContents.length) {
    assert(liquidContents.length === contents.length, 'Not all contents are liquids');
    downloadPlateFileLiquids(simulationId, plate, plateFileName, liquidContents);
    return;
  }

  const filterMatrixContents = contents.filter(
    x => x.well.kind === 'filter_matrix_summary',
  );
  if (filterMatrixContents.length) {
    assert(
      filterMatrixContents.length === contents.length,
      'Not all contents are filter matrices',
    );
    downloadPlateFileFilterMatrix(
      simulationId,
      plate,
      plateFileName,
      filterMatrixContents,
    );
    return;
  }
}

function downloadPlateFileLiquids(
  simulationId: SimulationId,
  plate: Plate,
  plateFileName: string,
  contents: WellContentsWithLocation[],
) {
  let unnamedLiquidCount = 1;
  const columns: CSVColumn<WellContentsWithLocation>[] = [
    { title: 'Simulation ID', getValue: _ => simulationId },
    { title: 'Plate Name', getValue: _ => plate.name },
    { title: 'Plate Type', getValue: _ => plate.type },
    { title: 'Location', getValue: c => formatWellPosition(c.row, c.col) },
    { title: 'Row', getValue: c => `${c.row + 1}` },
    { title: 'Column', getValue: c => `${c.col + 1}` },
    {
      title: 'Liquid Name',
      getValue: c =>
        c.well.name && c.well.name !== ''
          ? c.well.name
          : `Unnamed Liquid ${unnamedLiquidCount++}`,
    },
    { title: 'Liquid Type', getValue: c => c.well.type ?? '' },
    { title: 'Volume', getValue: c => roundNumber(c.well.total_volume?.value ?? 0) },
    { title: 'Volume Unit', getValue: c => c.well.total_volume?.unit ?? '' },
  ];

  addTagColumns(columns, contents);

  for (const solute of [
    ...new Set(contents.flatMap(c => (c.well.solutes ?? []).map(s => s.name))),
  ].sort()) {
    columns.push({
      title: `solute_concentration:${solute}`,
      getValue: c => {
        const soluteValue = c.well.solutes?.find(s => s.name === solute);
        if (soluteValue) {
          return `${soluteValue.concentration.value}`;
        }
        return '';
      },
    });
    columns.push({
      title: `solute_concentration_unit:${solute}`,
      getValue: c => {
        const soluteValue = c.well.solutes?.find(s => s.name === solute);
        if (soluteValue) {
          return `${soluteValue.concentration.unit}`;
        }
        return '';
      },
    });
  }

  const liquidsCSV = toCSV(columns, contents);
  downloadTextFile(liquidsCSV, plateFileName, 'text/csv');
}

function downloadPlateFileFilterMatrix(
  simulationId: SimulationId,
  plate: Plate,
  plateFileName: string,
  contents: WellContentsWithLocation[],
) {
  const columns: CSVColumn<WellContentsWithLocation>[] = [
    { title: 'Simulation ID', getValue: _ => simulationId },
    { title: 'Plate Name', getValue: _ => plate.name },
    { title: 'Plate Type', getValue: _ => plate.type },
    { title: 'Location', getValue: c => formatWellPosition(c.row, c.col) },
    { title: 'Row', getValue: c => `${c.row + 1}` },
    { title: 'Column', getValue: c => `${c.col + 1}` },
    {
      title: 'Filter Matrix Name',
      getValue: c => (c.well.name && c.well.name !== '' ? c.well.name : ``),
    },
    { title: 'Volume', getValue: c => roundNumber(c.well.total_volume?.value ?? 0) },
    { title: 'Volume Unit', getValue: c => c.well.total_volume?.unit ?? '' },
  ];

  addTagColumns(columns, contents);
  const liquidsCSV = toCSV(columns, contents);
  downloadTextFile(liquidsCSV, plateFileName, 'text/csv');
}

function addTagColumns(
  columns: CSVColumn<WellContentsWithLocation>[],
  contents: WellContentsWithLocation[],
) {
  // tagsLabel is a special label that denotes multiple values that mean the
  // same thing - a way for user to describe grouping of liquids
  const tagsLabel = 'Tags';

  columns.push({
    title: `${tagsLabel}`,
    getValue: c => {
      return [
        ...new Set(
          (c.well.tags ?? []).filter(t => t.label === tagsLabel).map(t => t.value_string),
        ),
      ].join(' | '); // this delimiter is recognised by elements
    },
  });

  for (const tag of [
    ...new Set(
      contents.flatMap(c =>
        (c.well.tags ?? []).filter(t => t.label !== tagsLabel).map(t => t.label),
      ),
    ),
  ].sort()) {
    columns.push({
      title: `tag:${tag}`,
      getValue: c => {
        const dataTag = c.well.tags?.find(t => t.label === tag);
        if (dataTag) {
          return dataTag.value_string ?? `${dataTag.value_float}`;
        }
        return '';
      },
    });
  }
}

export function useFetchElementOutputFiles() {
  const fetchElementOutputs = useFetchElementOutputs();
  const downloadElementOutputFile = useDownloadElementOutputFile();
  return useCallback(
    async function fetchElementOutputFiles(
      simulationFiletreeLink: FiletreeLink,
    ): Promise<readonly TreeEntry[]> {
      return (await fetchElementOutputs(simulationFiletreeLink)).map(el => ({
        type: 'directory',
        name: el.name,
        entries: el.groups.map(group => ({
          type: 'directory',
          name: group.name,
          entries: group.files.map(file => {
            return fileEntry(file.name, () => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              downloadElementOutputFile(simulationFiletreeLink, file);
            });
          }),
        })),
      }));
    },
    [downloadElementOutputFile, fetchElementOutputs],
  );
}

function useDownloadElementOutputFile() {
  const downloadRemoteFileFromPath = filetreeClient.useDownloadRemoteFileFromPath();
  return useCallback(
    function downloadElementOutputFile(
      simulationFiletreeLink: FiletreeLink,
      file: ElementOutputFile,
    ) {
      return downloadRemoteFileFromPath(
        concatURL(simulationFiletreeLink, `/simulation/data/${file.path}`),
        file.name,
      );
    },
    [downloadRemoteFileFromPath],
  );
}

type ElementOutputFile = {
  name: string;
  path: string;
};

type ElementOutputGroup = {
  name: string;
  files: readonly ElementOutputFile[];
};

type ElementOutputs = {
  name: string;
  groups: readonly ElementOutputGroup[];
};

function useFetchElementOutputs() {
  const downloadJSON = filetreeClient.useDownloadJSON();
  return useCallback(
    async function fetchElementOutputs(
      simulationFiletreeLink: FiletreeLink,
    ): Promise<readonly ElementOutputs[]> {
      const workflowFile: WorkflowFile = await downloadJSON(
        `${simulationFiletreeLink}/simulation/workflow/workflow.json`,
      );
      const elementInstances = workflowFile.Simulation.Elements.Instances;
      return (
        Object.values(elementInstances)
          // Hide all "artificial" elements that were created on the fly during the
          // simulation. These are not real elements that the user defined in the
          // workflow.
          .filter(instance => !instance.ParentId)
          // Only show elements that actually produced some files.
          // This makes it easier for the user to find the files they're looking for
          // in the tree.
          .filter(instance => instance.Files && Object.keys(instance.Files).length > 0)
          .map(instance => ({
            name: instance.Name,
            groups: Object.entries(instance.Files || {}).map(
              ([elementParamName, paramFiles]) => {
                return {
                  name: elementParamName,
                  files: cleanupParamFiles(paramFiles)
                    .filter(file => file.IsOutput)
                    .map(f => ({
                      name: f.Name,
                      path: f.Path,
                    })),
                };
              },
            ),
          }))
      );
    },
    [downloadJSON],
  );
}

// Given the files for an element instance parameter in a simulation, return only the
// non-null files.
function cleanupParamFiles(
  // The schema is a bit weird: the value we get from the backend is either a single
  // value or a list. And the value itself, or individual values in the list, can be null.
  // See https://github.com/Synthace/antha/blob/master/workflow/schemas/workflow.schema.json
  paramFilesValue: WorkflowElementFile | null | readonly (WorkflowElementFile | null)[],
): readonly WorkflowElementFile[] {
  if (!paramFilesValue) {
    return [];
  }
  if (Array.isArray(paramFilesValue)) {
    return (paramFilesValue as readonly (WorkflowElementFile | null)[]).filter(isDefined);
  } else {
    return [paramFilesValue as WorkflowElementFile];
  }
}

/**
 * Files for debugging the simulation, shown to Synthace employees only.
 */
export function useGetDebuggingFiles() {
  const downloadRemoteFileFromPath = filetreeClient.useDownloadRemoteFileFromPath();
  return useCallback(
    function getDebuggingFiles(simulation: SimulationQuery['simulation']): TreeEntry[] {
      const simulationInputFiletreePath = simulation.internalSimulationInputPath;
      if (simulationInputFiletreePath) {
        const fileName = `composer_input_${simulation.name}_${simulation.id}.tar.gz`;
        return [
          {
            type: 'file',
            name: 'composer_input.tar.gz',
            onDownload: () =>
              downloadRemoteFileFromPath(simulationInputFiletreePath, fileName),
          },
        ];
      }
      return [];
    },
    [downloadRemoteFileFromPath],
  );
}

// Helpers

function fileEntry(name: string, onDownload: () => void): TreeEntry {
  return fileEntryWithSubtitle(name, undefined, onDownload);
}

function fileEntryWithSubtitle(
  name: string,
  subtitle: string | undefined,
  onDownload: () => void,
): TreeEntry {
  return {
    type: 'file',
    name,
    subtitle,
    onDownload,
  };
}
