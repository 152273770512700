import React, { useCallback, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';

import { LiquidTransfer } from 'client/app/apps/cherry-picker/CherryPickApi';
import {
  SetCherryPick,
  useCherryPickContext,
} from 'client/app/apps/cherry-picker/CherryPickContext';
import { EditableTransferRow } from 'client/app/apps/cherry-picker/cp-table/CherryPickEditableRow';
import { ScreenRegistry } from 'client/app/registry';
import { getLastOrFail } from 'common/lib/data';
import Colors from 'common/ui/Colors';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const TRANSFERS_ORDER_TOOLTIP_CAPTION = 'Order in which the transfers will be executed';

export default function CherryPickTableContainer() {
  const { cherryPick, setCherryPick, transfersTree, isReadonly } = useCherryPickContext();

  return (
    <>
      {transfersTree &&
        Object.keys(transfersTree).map((sourcePlate, sourceIdx) =>
          Object.keys(transfersTree[sourcePlate]).map((destPlate, destIdx) => (
            <CherryPickTable
              liquidTransfers={transfersTree[sourcePlate]?.[destPlate] ?? cherryPick}
              allTransfers={cherryPick}
              updateCherryPick={setCherryPick}
              stickyHeaderLabel={`${sourcePlate} → ${destPlate}`}
              key={`${sourcePlate}-${destPlate}`}
              sourceIdx={sourceIdx}
              destIdx={destIdx}
              isReadonly={isReadonly}
            />
          )),
        )}
    </>
  );
}
type Props = {
  liquidTransfers: LiquidTransfer[];
  allTransfers: LiquidTransfer[];
  updateCherryPick: SetCherryPick;
  stickyHeaderLabel?: string;
  sourceIdx: number;
  destIdx: number;
  isReadonly: boolean;
};

const CherryPickTable = React.memo(function CherryPickTable({
  allTransfers,
  liquidTransfers,
  updateCherryPick,
  stickyHeaderLabel,
  sourceIdx,
  destIdx,
  isReadonly,
}: Props) {
  const classes = useStyles();
  // Store keys for each row in a separate array. This is needed because we cannot
  // rely on the position/index of the row (as they can be shuffled around), nor we
  // can rely on any property of the row/transfer itself, as they can be edited.
  const [rowKeys, setRowKeys] = useState(() => {
    const rowKeys = [];
    for (let i = 0; i < liquidTransfers.length; i++) {
      rowKeys.push(uuid());
    }
    return rowKeys;
  });

  const handleAddNewRow = useCallback(() => {
    if (isReadonly) {
      return;
    }
    logEvent('add-new-transfer-row', ScreenRegistry.CHERRY_PICKER);
    // Retrieve the order number of the last of *all* transfers
    const lastRowOrderNumber = getLastOrFail(allTransfers).transferOrder;
    // As a base for the new row, use the last one that has been added
    const newRowToAdd = {
      ...getLastOrFail(liquidTransfers),
      transferOrder: lastRowOrderNumber + 1,
    };
    // Create a new UUID for the newer row
    setRowKeys(prev => [...prev, uuid()]);
    updateCherryPick([...allTransfers, newRowToAdd]);
  }, [allTransfers, isReadonly, liquidTransfers, updateCherryPick]);

  return (
    <Table size="small" data-sourceidx={sourceIdx} data-destidx={destIdx}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={8} className={classes.destPlateHeader}>
            <Typography variant="h4" sx={{ paddingY: 2, fontWeight: 500 }}>
              {stickyHeaderLabel || 'All transfers'}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <Tooltip title={TRANSFERS_ORDER_TOOLTIP_CAPTION}>
            <TableCell>#</TableCell>
          </Tooltip>
          <TableCell className={classes.mediumCell}>Source Volume</TableCell>
          <TableCell className={classes.mediumCell}>Source Well</TableCell>
          <TableCell className={classes.largeCell}>Liquid</TableCell>
          <TableCell className={classes.largeCell}>Policy</TableCell>
          <TableCell className={classes.mediumCell}>Transfer Volume</TableCell>
          <TableCell className={classes.mediumCell}>Destination Well</TableCell>
          {/* Empty colum where the fading "Delete" icon sits.*/}
          <TableCell className={classes.smallCell} />
        </TableRow>
      </TableHead>
      <TableBody>
        {liquidTransfers.map((transfer, idx) => (
          <EditableTransferRow
            key={rowKeys[idx]}
            rowIdx={idx}
            allTransfers={allTransfers}
            liquidTransfer={transfer}
            handleUpdateCherryPick={updateCherryPick}
            setRowKeys={setRowKeys}
            isReadonly={isReadonly}
          />
        ))}
        <TableRow
          hover={!isReadonly}
          className={cx({ [classes.addTransferRow]: !isReadonly })}
        >
          <TableCell colSpan={8} align="center" onClick={handleAddNewRow}>
            <Typography>Add transfer</Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
});

const useStyles = makeStylesHook({
  addTransferRow: {
    cursor: 'pointer',
  },
  destPlateHeader: {
    position: 'sticky',
    top: 0,
    left: 0,
    backgroundColor: Colors.GREY_10,
    zIndex: 2,
    textIndent: '1rem',
  },
  smallCell: {
    minWidth: '80px',
  },
  mediumCell: {
    width: '16rem',
  },
  largeCell: {
    width: '19rem',
  },
});
