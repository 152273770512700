import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export default React.memo(function ZoomInIcon(
  props: React.PropsWithChildren<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>>,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.469 14.469A5.918 5.918 0 106.099 6.1a5.918 5.918 0 008.37 8.369zm1.673 1.673A8.284 8.284 0 104.426 4.426a8.284 8.284 0 0011.716 11.716z"
          fill="currentColor"
        />
        <path
          d="M16.142 17.816l1.674-1.674L22 20.326 20.326 22l-4.184-4.184zM11.289 6.78h-2v2.5h-2.5v2h2.5v2.5h2v-2.5h2.5v-2h-2.5v-2.5z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
