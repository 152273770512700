import React, { ReactNode, useContext, useEffect, useRef } from 'react';

import { useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Close';
import { Theme, useTheme } from '@mui/material/styles';
import cx from 'classnames';

import {
  BlockCategory,
  blockRegistry,
} from 'client/app/apps/experiments/blocks/blockRegistry';
import {
  BLOCK_GUTTER_WIDTH as BLOCK_GUTTER_WIDTH,
  ExperimentDetailContext,
} from 'client/app/apps/experiments/ExperimentDetailScreen';
import { EXPERIMENT_REPORT_TOOLBAR_HEIGHT } from 'client/app/apps/experiments/ExperimentReportToolbar';
import { MUTATION_REMOVE_EXPERIMENT_BLOCK } from 'client/app/apps/experiments/gql/mutations';
import { QUERY_EXPERIMENT } from 'client/app/apps/experiments/gql/queries';
import { ExperimentBlockFragmentFragment } from 'client/app/gql';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import { DragHandleProps } from 'common/ui/components/DragDrop/DraggableList';
import IconButton from 'common/ui/components/IconButton';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import { cacheEvict } from 'common/utils';

/**
 * Gap between block (outer key) and block below it (inner key), measured in spacing
 * ladder units.
 */
const GAP_BETWEEN_BLOCKS: Record<BlockCategory, Record<BlockCategory, number>> = {
  title: { title: 5, link: 5, other: 5 },
  link: { title: 8, link: 4, other: 8 },
  other: { title: 8, link: 8, other: 8 },
};

function getGapBetweenBlocks(
  theme: Theme,
  block1?: ExperimentBlockFragmentFragment,
  block2?: ExperimentBlockFragmentFragment,
) {
  if (!block1 || !block2) {
    return 0;
  }
  const category1 = blockRegistry[block1.__typename].category;
  const category2 = blockRegistry[block2.__typename].category;
  return parseInt(theme.spacing(GAP_BETWEEN_BLOCKS[category1][category2]), 10);
}

type Props = {
  experimentId: ExperimentId;
  block: ExperimentBlockFragmentFragment;
  previousBlock?: ExperimentBlockFragmentFragment;
  nextBlock?: ExperimentBlockFragmentFragment;
  dragProps: DragHandleProps;
  children?: ReactNode;
};

/**
 * Container for block content that handles common behaviours of blocks, such as spacing
 * between blocks and deletion.
 */
export default function ExperimentBlockWrapper({
  experimentId,
  block,
  previousBlock,
  nextBlock,
  dragProps,
  children,
}: Props) {
  const classes = useStyles();

  const { isEditing, blockToScrollToId, setBlockToScrollToId } = useContext(
    ExperimentDetailContext,
  );

  const [removeModule] = useMutation(MUTATION_REMOVE_EXPERIMENT_BLOCK);

  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const handleRemove = async () => {
    const isConfirmed = await openConfirmationDialog({
      action: 'remove',
      isActionDestructive: true,
      object: blockRegistry[block.__typename].name,
    });
    if (isConfirmed) {
      void removeModule({
        variables: { id: block.id },
        optimisticResponse: { __typename: 'Mutation', removeExperimentBlock: true },
        update: cache => cacheEvict(block, cache),
        refetchQueries: [{ query: QUERY_EXPERIMENT, variables: { id: experimentId } }],
      });
    }
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (blockToScrollToId === block.id) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
      // Unset the blockToScrollToId flag so scrolling to a given block can work more than
      // once in a row.
      setBlockToScrollToId(undefined);
    }
  }, [block, blockToScrollToId, setBlockToScrollToId]);

  const theme = useTheme();

  // Distribute the gap between this block and the next such that the add block button
  // is nicely spaced within the gap between blocks.
  const paddingTop = getGapBetweenBlocks(theme, previousBlock, block) / 2;
  const paddingBottom = getGapBetweenBlocks(theme, block, nextBlock) / 2;

  return (
    <div ref={ref} className={classes.block} style={{ paddingTop, paddingBottom }}>
      {isEditing && <span className={classes.icon}>{dragProps.dragIcon}</span>}
      <div
        className={cx(classes.blockBody, {
          [classes.draggingBlock]: dragProps.isDragOverlay,
        })}
      >
        {children}
      </div>
      {isEditing && (
        <span className={classes.icon}>
          <Tooltip title="Remove block">
            <IconButton icon={<DeleteIcon />} onClick={() => handleRemove()} />
          </Tooltip>
        </span>
      )}
      {confirmationDialog}
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  block: {
    display: 'grid',
    gridTemplateColumns: `[drag-handle] ${BLOCK_GUTTER_WIDTH}px [card] auto [delete-icon] ${BLOCK_GUTTER_WIDTH}px`,
    alignItems: 'center',
    boxShadow: 'none',
    background: 'none',
    // Clicking on a block in the sidebar causes the block to scroll into view. Setting
    // scrollMarginTop means it will scroll a little bit above the block for a more
    // comfortable layout.
    scrollMarginTop: `${EXPERIMENT_REPORT_TOOLBAR_HEIGHT + theme.spacing(4)}px`,
  },
  icon: {
    justifySelf: 'center',
    opacity: 0,
    '$block:hover &': {
      opacity: 1,
    },
  },
  blockBody: {
    flexGrow: 1,
    gridColumn: 'card',
  },
  draggingBlock: {
    background: theme.palette.grey[50],
    // Prevent hover styles (e.g. background) on dragged list item
    pointerEvents: 'none',
    // Apply a shadow and then a blue outline. Use a 1px shadow instead of a border
    // because it doesn't increase the size of the element.
    boxShadow: `0px 2px 3px 1px rgba(0, 0, 0, 0.2), 0 0 0 1px ${theme.palette.primary.main}`,
    borderRadius: '4px',
  },
}));
