import React, { useEffect, useState } from 'react';

import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import {
  A_TO_C_DURATION,
  C_TO_D_DURATION,
} from 'client/app/apps/workflow-builder/panels/simulations/deleteAnimation';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import DeletedSimulationIcon from 'common/ui/icons/DeletedSimulationIcon';

export const DELETED_SIMULATION_CARD_HEIGHT = '56px';

export default function DeletedSimulationCard() {
  const classes = useStyles();
  const [show, setShow] = useState(true);
  useEffect(() => {
    // The DeletedSimulationCard is shown temporarily before fading out.
    // We use this timeout to trigger its exit animation.
    // It's important we clear up the timer if the component unmounts before
    // the fade exit animation.
    const timer = setTimeout(() => {
      setShow(false);
    }, A_TO_C_DURATION);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Fade in={show} timeout={C_TO_D_DURATION}>
      <Paper variant="outlined" className={cx(classes.card, classes.deletedCard)}>
        <DeletedSimulationIcon />
        <Typography className={classes.text} variant="h5">
          Simulation Deleted
        </Typography>
      </Paper>
    </Fade>
  );
}

const useStyles = makeStylesHook(theme => ({
  card: {
    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '4px',
    padding: theme.spacing(3),
  },
  deletedCard: {
    display: 'flex',
    minHeight: DELETED_SIMULATION_CARD_HEIGHT,
    padding: theme.spacing(0, 9),
    alignItems: 'center',
    color: theme.palette.info.dark,
  },
  text: {
    marginLeft: theme.spacing(4),
  },
}));
