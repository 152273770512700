import React, { useMemo } from 'react';

import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import GridSection from 'common/ui/components/simulation-details/mix/GridSection';

type Props = { deckLayout: DeckLayout | null; gridVisible: boolean };

export default React.memo(function MixGrid({ deckLayout, gridVisible }: Props) {
  const rendered = useMemo(
    () =>
      deckLayout?.getAllGridPositions().map(gridPosition => {
        return <GridSection key={gridPosition.label} gridPosition={gridPosition} />;
      }),
    [deckLayout],
  );
  return gridVisible ? <>{rendered}</> : null;
});
