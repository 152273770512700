import React from 'react';

import AutocompleteWithParameterValues from 'client/app/components/Parameters/AutocompleteWithParameterValues';
import Liquids from 'common/lib/liquids';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';

type Props = {
  value: string;
  onChange: (value?: string) => void;
} & ParameterEditorBaseProps<string>;

// TODO: Remove this component and replicate this with the element
// configuration in Phase 3 when can configure editors for types.

export default function LiquidAutocompleteEditor(props: Props) {
  return (
    <AutocompleteWithParameterValues
      anthaType="*github.com/Synthace/antha/antha/anthalib/wtype.Liquid"
      placeholder={props.placeholder}
      valueLabel={props.value}
      additionalOptions={Liquids}
      onChange={props.onChange}
      isDisabled={props.isDisabled}
      isRequired={props.isRequired}
      acceptCustomValues={false}
    />
  );
}
