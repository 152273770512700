import React, { useCallback } from 'react';

import { ObservableQuery } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

import { useInfiniteScrollWithRef } from 'client/app/hooks/useInfiniteScrolling';
import { PageInfo } from 'common/server/graphql/pagination';

type PaginationProps<
  TData extends { [K in PropertyName]: { items: unknown } },
  PropertyName extends keyof TData = keyof TData,
> = {
  entity: PropertyName;
  pageInfo?: PageInfo;
  fetchMore: ObservableQuery<TData>['fetchMore'];
  dependencies: any[];
  scrollableRef: React.RefObject<HTMLDivElement>;
  isInitialLoading: boolean;
  variables: object;
};

/**
 * usePagination calls fetchMore on the particular entity and determines if there is a next page
 * to be fetched when the user is in a particular scrollable content.
 *
 * This returns whether or not we have the next page to query and if we are still loading the content.
 * The caller can show a loading spinner while we fetch more data for this particular entity.
 */
export default function usePagination<
  TData extends { [K in PropertyName]: { items: any } },
  PropertyName extends keyof TData = keyof TData,
>(props: PaginationProps<TData, PropertyName>) {
  const { entity, pageInfo, fetchMore, scrollableRef, isInitialLoading, variables } =
    props;
  const after = pageInfo?.endCursor;
  const hasNextPage = pageInfo?.hasNextPage || false;

  // Using a ref to store a mutable value. Using React state is not sufficient,
  // likely because setting state is async.
  const lastCursorFetched = React.useRef<string | undefined>(undefined);

  // Reset the state if we fired a new query with different parameters.
  // This allows fetching the same page in case it comes from the same query
  // but with different parameters (e.g. filtering parameters).
  if (isInitialLoading) {
    lastCursorFetched.current = undefined;
  }

  const currVariables = React.useRef<object | undefined>(undefined);
  currVariables.current = variables;

  const nextPage = useCallback(async () => {
    if (after === lastCursorFetched.current) {
      // Prevent multiple fetches of the exact same page.
      // useInfiniteScrolling calls nextPage() multiple times quickly
      // when the user scrolls near the end of the list.
      return;
    }
    lastCursorFetched.current = after;
    const origVariables = variables;
    await fetchMore({
      variables: {
        after,
      },
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        // It's possible the user changed the search criteria and received new results
        // before the fetchMore response is received. In this case we're dealing with a
        // response that has results pertaining to the previous criteria and therefore
        // should not be appended to the new results cache.
        //
        // This also avoids a crash if the server returned an error for the new search and
        // deleted the cache; attempting to append would cause a crash.
        //
        // We compare the input variables, except 'after' which changes for each page
        // requested and doesn't pertain to the user's search criteria.
        if (
          !isEqual(omit(origVariables, 'after'), omit(currVariables.current, 'after'))
        ) {
          return previousQueryResult;
        }

        if (!fetchMoreResult) {
          return previousQueryResult;
        }

        // Return combination of already fetched and newly fetched entities
        return {
          ...fetchMoreResult,
          [entity]: {
            ...fetchMoreResult[entity],
            items: [
              ...previousQueryResult[entity].items,
              ...fetchMoreResult[entity].items,
            ],
          },
        };
      },
    });
  }, [after, entity, fetchMore, variables]);

  useInfiniteScrollWithRef({
    canFetchMore: hasNextPage,
    nextPage,
    isLoading: isInitialLoading,
    elRef: scrollableRef,
    dependencies: props.dependencies,
  });

  return hasNextPage;
}
