import React from 'react';

import { styled } from '@mui/material/styles';

import { WorkflowSummaryFragmentFragment } from 'client/app/gql';
import getWorkflowPropsBySource from 'client/app/lib/workflow/getWorkflowPropsBySource';
import { EntityCard } from 'common/ui/components/EntityCard';

type WorkflowCardProps = {
  workflow: WorkflowSummaryFragmentFragment;
  isLink?: boolean;
  isDisabled?: boolean;
  addStatusPlaceholder?: boolean;
  onClick?: () => void;
};
/**
 * Displays a link to a workflow in the Experiments UI.
 */
export function WorkflowCard({
  workflow,
  isLink = false,
  isDisabled = false,
  addStatusPlaceholder = false,
  onClick,
}: WorkflowCardProps) {
  const {
    id,
    name,
    source,
    lastModifiedAt,
    createdBy: { displayName: author },
  } = workflow;
  const { route, EditorIcon } = getWorkflowPropsBySource(source);

  const path = isLink ? route.getPath({ workflowId: id }) : undefined;

  const nameColumn = { label: 'Workflow name', value: name };
  const authorColumn = { label: 'Author', value: author };
  const dateColumn = { label: 'Last modified', value: new Date(lastModifiedAt) };

  const interaction = { to: path, onClick };

  return (
    <EntityCard
      icon={<EditorIcon fontSize="small" />}
      nameColumn={nameColumn}
      authorColumn={authorColumn}
      dateColumn={dateColumn}
      interaction={interaction}
      isDisabled={isDisabled}
      rightSlot={addStatusPlaceholder && <StatusPlaceholder />}
    />
  );
}

const StatusPlaceholder = styled('div')(({ theme }) => ({
  width: '92px',
  padding: theme.spacing(5, 6),
}));
