import React, { useMemo } from 'react';

import MuiChip from '@mui/material/Chip';
import { CSSObject, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { DECK_LOCATION_CSS_CLASS } from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import {
  formatLabwareTypeName,
  getLabwareIcon,
  getLabwareIndexForPosition,
  LabwareType,
} from 'client/app/state/LabwarePreference';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import Colors from 'common/ui/Colors';

const ACTIVE_INDEX_WIDTH = 30;

// States for the chip:
// Active - Deck Position has already been selected. You can deselect this chip position.
// Inactive - Deck Position for a different labware
// Placeholder - Indicator for a position that a labware can be added.
type DeckPositionChipProps = {
  state: 'active' | 'inactive' | 'placeholder';
  /** Used to format the correct label on the chips, and grab the preference index. */
  labwareType: LabwareType;
  /**
   * Used to grab the preference index along with the labwareType.
   * Only needed for the active and inactive chip. */
  deckPositionName?: string;
};

export const DeckPositionChip = React.memo(function DeckPositionChipBase({
  deckPositionName,
  labwareType,
  state,
}: DeckPositionChipProps) {
  const index = useWorkflowBuilderSelector(state =>
    getLabwareIndexForPosition(state, labwareType, deckPositionName),
  );
  const labwareTypeName = useMemo(
    () => formatLabwareTypeName(labwareType),
    [labwareType],
  );
  const labwareIcon = useMemo(() => getLabwareIcon(labwareType), [labwareType]);
  const hasLabwareIndex = index >= 0;

  return (
    <Chip
      state={state}
      avatar={labwareIcon}
      label={
        hasLabwareIndex ? (
          <>
            <LabwareLabel
              variant="subtitle2"
              sx={{ marginRight: `${ACTIVE_INDEX_WIDTH + 5}px` }}
            >
              {labwareTypeName}
            </LabwareLabel>
            <Typography className="labwareIndex" variant="subtitle2">
              #{index + 1}
            </Typography>
          </>
        ) : (
          <LabwareLabel variant="subtitle2">{labwareTypeName}</LabwareLabel>
        )
      }
    />
  );
});

const Chip = styled(MuiChip, {
  shouldForwardProp: prop => prop !== 'state',
})<{
  state: DeckPositionChipProps['state'];
}>(({ theme, state }) => {
  let chipRootStyle: CSSObject;

  switch (state) {
    case 'active':
      chipRootStyle = {
        color: Colors.GREY_20,
        backgroundColor: Colors.GREY_70,
        border: 'none',

        [`[class*=${DECK_LOCATION_CSS_CLASS}]:hover &`]: {
          backgroundColor: Colors.GREY_60,
          borderColor: Colors.GREY_60,
          zIndex: 1,

          '& .labwareIndex': {
            backgroundColor: Colors.BLUE_50,
          },
        },
        '& .labwareIndex': {
          position: 'absolute',
          right: 0,

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: ACTIVE_INDEX_WIDTH,
          padding: theme.spacing(0, 3),

          borderTopRightRadius: '16px',
          borderBottomRightRadius: '16px',

          backgroundColor: Colors.BLUE_80,
        },
      };
      break;
    case 'placeholder':
      chipRootStyle = {
        color: theme.palette.text.primary,
        backgroundColor: Colors.GREY_5,
        borderColor: Colors.GREY_30,
        [`[class*=${DECK_LOCATION_CSS_CLASS}]:hover &`]: {
          borderColor: Colors.BLUE_50,
          zIndex: 1,
        },
      };
      break;
    case 'inactive':
    default:
      chipRootStyle = {
        color: theme.palette.text.disabled,
        backgroundColor: Colors.GREY_5,
        borderColor: Colors.GREY_30,
      };
      break;
  }

  return {
    '&.MuiChip-root': {
      position: 'relative',
      border: '1px solid',
      maxWidth: 'fit-content',

      [`[class*=${DECK_LOCATION_CSS_CLASS}]:hover &`]: {
        zIndex: 1,
      },
      '&:last-of-type': {
        marginBottom: theme.spacing(4),
      },

      '& .labwareIndex': {
        position: 'absolute',
        right: 0,

        fontWeight: 400,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: ACTIVE_INDEX_WIDTH,

        borderLeft: `1px solid ${Colors.GREY_20}`,
        borderTopRightRadius: '16px',
        borderBottomRightRadius: '16px',
      },

      ...chipRootStyle,
    },
    '& .MuiChip-label': {
      display: 'flex',
      alignItems: 'stretch',
      height: '100%',
      padding: 0,
    },
    '& .MuiSvgIcon-root': {
      color: 'inherit',
      marginRight: theme.spacing(1),
    },
  };
});

const LabwareLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  fontWeight: 400,
}));
