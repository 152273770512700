import React from 'react';

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { getExtension, getType } from 'mime';

import CsvIcon from 'common/ui/icons/CsvIcon';
import XlsxIcon from 'common/ui/icons/XlsxIcon';
import XmlIcon from 'common/ui/icons/XmlIcon';

type Props = { fileName: string };

export default function FileExtensionIcon({ fileName }: Props) {
  const fileType = getType(fileName);

  if (!fileType) return <InsertDriveFileOutlinedIcon />;

  const fileExtension = getExtension(fileType);

  switch (fileExtension) {
    case 'csv':
      return <CsvIcon />;
    case 'xml':
      return <XmlIcon />;
    case 'xlsx':
      return <XlsxIcon />;
    default:
      return <InsertDriveFileOutlinedIcon />;
  }
}
