import { CSSProperties, StyleRules } from '@mui/styles';

import Colors from 'common/ui/Colors';

// Break text on words if possible. If there are no spaces, still break the
// text.
// Tested in Chrome and Firefox.
// See https://stackoverflow.com/a/45977981
export const SMART_WORD_BREAK_STYLE: CSSProperties = {
  // This prop is needed in Firefox.
  // @ts-ignore 'anywhere' is supported, see
  // https://developer.mozilla.org/en-US/docs/Web/CSS/overflow-wrap
  overflowWrap: 'anywhere',
  whiteSpace: 'pre-wrap',
  // This prop is needed in Chrome.
  wordBreak: 'break-word',
};

// There is inconsistent gutter size for ListItem and TextField as seen here:
// https://github.com/mui-org/material-ui/issues/14751.
// This is an attempt to make the styling more consistent.
export const gutterPadding: CSSProperties = {
  paddingLeft: '16px',
  paddingRight: '16px',
};

export const scrollableContent: CSSProperties = {
  height: 'calc(100vh - 64px)',
  overflowY: 'auto',
};

const zebraRowsRules = {
  fullRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: Colors.GREY_10,
    },
    // Add some space around the text to make it prettier.
    '& th': { padding: '25px' },
    ...SMART_WORD_BREAK_STYLE,
  },
};
// Use this in <TableRow> for a zebra effect (better readability)
export const zebraRowsStyles = zebraRowsRules as StyleRules<
  {},
  keyof typeof zebraRowsRules
>;

const zebraRowsNoPaddingRules = {
  fullRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: Colors.GREY_10,
    },
  },
};
export const zebraRowsNoPaddingStyles = zebraRowsNoPaddingRules as StyleRules<
  {},
  keyof typeof zebraRowsNoPaddingRules
>;

const simulationTableRowsRules = {
  ...zebraRowsNoPaddingRules,
  fullRow: {
    ...zebraRowsNoPaddingRules.fullRow,
    '& td, & th': {
      borderBottom: 'none',
      padding: '8px',
    },
    '& td:first-of-type': {
      paddingLeft: '24px',
    },
    '& th': {
      paddingLeft: '24px',
    },
    '& button': {
      padding: '8px',
      visibility: 'hidden',
    },
    '&:hover button': {
      visibility: 'visible',
    },
  },
};
export const simulationTableRowsStyles = simulationTableRowsRules as StyleRules<
  {},
  keyof typeof simulationTableRowsRules
>;

export const circularLoadingContainer = {
  circularLoadingContainer: {
    // Place the loading circle in the center of its row
    width: 'fit-content',
    margin: '0 auto',
  },
};

const simulationsTableRules = {
  head: {
    '& th': {
      padding: '12px 8px',
    },
    '& th:first-of-type': {
      paddingLeft: '24px',
    },
  },
  table: {
    tableLayout: 'fixed',
  },
  simulationName: {
    color: Colors.BLUE,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  circularLoadingIcon: {
    margin: '20px 0',
    color: Colors.BLUE,
  },
  infoRow: {
    fontSize: '1.2em',
    textAlign: 'center',
    borderBottom: 'none',
  },
  actionsColumn: {
    textAlign: 'center',
    // Enough room for two icons and scrollbar compensation
    width: '116px',
  },
  clickable: {
    cursor: 'pointer',
  },
  status: {
    width: 'calc(8ch + 16px)',
  },
  userName: {
    width: 'calc(20ch + 16px)',
  },
  date: {
    width: 'calc(30ch + 16px)',
  },
  ...simulationTableRowsRules,
  ...circularLoadingContainer,

  // Want to override the button rules that is the default for simulation table rows.
  favorite: {
    width: 'calc(4ch + 24px)',
    '& button': {
      padding: '4px',
      visibility: 'visible',
    },
  },
};
export const simulationsTableStyles = simulationsTableRules as StyleRules<
  {},
  keyof typeof simulationsTableRules
>;

/**
 * Center text in MUI components, e.g. TextField.
 * Usage: inputProps={centerText}
 */
export const centerText: StyleRules = { style: { textAlign: 'center' } };

/**
 * Common style for the "X" icon used to e.g. clear the value of a parameter
 * Usage:
 *   removeIcon: {
 *   ...removeIcon.style,
 * }
 * */
export const removeIcon: StyleRules = {
  style: {
    color: Colors.GREY_30,
    '&:hover': {
      color: Colors.RED,
    },
    margin: 'auto 0 auto',
  },
};

/**
 * Style for link containing more complex content or some @mui component
 * that doesn't look great when under <a> tag. This is the style for the <a> tag
 * to make our life easier.
 */
export const linkNoStyle: StyleRules = {
  style: {
    color: 'inherit',
    textDecoration: 'none',
  },
};

export const loadingWithinDialog: StyleRules = {
  circularLoadingFullDialog: {
    backgroundColor: Colors.GREY_5,
    display: 'flex',
    height: '85vh',
    justifyContent: 'center',
    margin: '0 auto',
    paddingTop: '24px',
    width: '100%',
  },
  linearProgress: {
    backgroundColor: Colors.GREY_5,
    height: '85vh',
    padding: 0,
    width: '100%',
  },
};
