import { useCallback } from 'react';

import Helpers from 'client/app/apps/execution-details/ExecuteTab/Reagents/helpers';
import { StockConcentration } from 'client/app/apps/execution-details/types';
import { Reagent } from 'client/app/gql';
import { downloadTextFile } from 'common/lib/download';
import { formatMeasurementObj, roundNumber } from 'common/lib/format';

export function useDownloadReagents(filenamePrefix: string) {
  return useCallback(
    (stockConcentrations: StockConcentration[], sortedReagents: Reagent[]) => {
      /* example file:
       *
       * "InputLiquid", "Description", "Total Volume (ul)", "Diluent Volume (ul)", "Stocks to Add (ul)"
       *            "",            "",                "",                  "",     "Stock1 name (400.00 mMol/l)", "",                     "Stock2 name (200.00 mMol/l)", ""
       *            "",            "",                "",                  "",     "To Add (ul)",                 "Target Concentration", "To Add (ul)",                 "Target Concentration"
       *    "Buffer 1", "description",        "41724.00",              "0.00",     "",                            "",                     "41724.00",                    "200.0 mMol/l"
       *    "Buffer 2", "description",         "4202.00",           "2101.00",     "2101.00",                     "200.00 mMol/l",        "",                            ""
       *
       */
      const data = [
        [
          'InputLiquid',
          'Description',
          'Total Volume (ul)',
          'Diluent Volume (ul)',
          'Stocks to Add (ul)',
        ],
        ['', '', '', ''].concat(
          stockConcentrations.flatMap(stock => [
            `${stock.name} (${formatMeasurementObj(stock.concentration, false)})`,
            '',
          ]),
        ),
        ['', '', '', ''].concat(
          stockConcentrations.flatMap(_ => ['To Add (ul)', 'Target Concentration']),
        ),
      ].concat(
        sortedReagents.map(reagent => {
          const volumes = Helpers.getRequiredStockVolumes(stockConcentrations, reagent);
          const diluent = Helpers.getDiluentVolume(reagent, volumes);
          return [
            reagent.name,
            Helpers.getDescription(reagent),
            roundNumber(reagent.volumeUl),
            roundNumber(diluent),
          ].concat(
            stockConcentrations.flatMap(stock => {
              if (volumes.has(stock)) {
                const target = Helpers.getTargetConcentration(reagent, stock);
                const volume = volumes.get(stock)!;
                if (volume.unit === 'ul') {
                  return [roundNumber(volume.value), formatMeasurementObj(target, false)];
                }
                // non-breaking spaces don't render correctly on some platforms
                return [
                  formatMeasurementObj(volume, false),
                  formatMeasurementObj(target, false),
                ];
              }
              return ['', ''];
            }),
          );
        }),
      );

      const reagentsCSV = data.reduce(
        (output, row) => output + row.map(v => JSON.stringify(v)).join(',') + '\n',
        '',
      );

      downloadTextFile(
        reagentsCSV,
        `${filenamePrefix.replaceAll(' ', '-')}-full-design.csv`,
        'text/csv',
      );
    },
    [filenamePrefix],
  );
}
