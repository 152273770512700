import React, { useCallback } from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { TemplateWorkflowConfig } from 'common/types/bundle';
import {
  DraggableList,
  DragHandleProps,
} from 'common/ui/components/DragDrop/DraggableList';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  configParameters: TemplateWorkflowConfig[];
  onConfigParametersChange: (configParameters: TemplateWorkflowConfig[]) => void;
};

function getIdFromTemplateWorkflowConfig(config: TemplateWorkflowConfig) {
  return config.PropertyName;
}

/**
 * Renders list of config parameters that can be reordered
 */
export function TemplateWorkflowConfigParameterList({
  configParameters,
  onConfigParametersChange,
}: Props) {
  const onOrderChanged = useCallback(
    (parameters: TemplateWorkflowConfig[]) => onConfigParametersChange(parameters),
    [onConfigParametersChange],
  );

  const renderConfigCard = useCallback(
    (parameter: TemplateWorkflowConfig, dragHandleProps: DragHandleProps) => (
      <ConfigParameterCard parameter={parameter} dragHandleProps={dragHandleProps} />
    ),
    [],
  );

  if (configParameters.length === 0) {
    return (
      <Typography gutterBottom>
        <em>No parameters have been selected to be configurable.</em>
      </Typography>
    );
  }

  return (
    <DraggableList
      renderItem={renderConfigCard}
      getIdFromItem={getIdFromTemplateWorkflowConfig}
      items={configParameters}
      onChangeOrder={onOrderChanged}
    />
  );
}

function ConfigParameterCard({
  dragHandleProps,
  parameter,
}: {
  dragHandleProps: DragHandleProps;
  parameter: TemplateWorkflowConfig;
}) {
  const classes = useStyles();

  // when displayName is overridden, include the original name in the title
  const title =
    parameter.PropertyName === parameter.DisplayName
      ? parameter.DisplayName
      : `${parameter.DisplayName} (${parameter.PropertyName})`;

  return (
    <Card className={cx(classes.card)}>
      <CardHeader avatar={dragHandleProps.dragIcon} title={title} />
    </Card>
  );
}

const useStyles = makeStylesHook({
  card: {
    maxWidth: '100%',
    marginBottom: '1rem',
  },
});
