import { GraphQLNonNull, GraphQLObjectType, GraphQLString } from 'graphql';

import { graphql } from 'common/server/graphql';
import { Page, PageInfo } from 'common/server/graphql/pagination';

export const PageInfoSchema = new GraphQLObjectType<PageInfo>({
  name: 'PageInfo',
  fields: () => ({
    endCursor: {
      type: GraphQLString,
      resolve(pageInfo: PageInfo) {
        return pageInfo.endCursor;
      },
    },
    hasNextPage: {
      type: graphql.NotNullBoolean,
      resolve(pageInfo: PageInfo) {
        return pageInfo.hasNextPage;
      },
    },
    startCursor: {
      type: GraphQLString,
      resolve(pageInfo: PageInfo) {
        return pageInfo.startCursor;
      },
    },
    hasPreviousPage: {
      type: graphql.NotNullBoolean,
      resolve(pageInfo: PageInfo) {
        return pageInfo.hasPreviousPage;
      },
    },
  }),
});

export default function paginatedList<Item>(
  name: string,
  itemSchema: GraphQLObjectType<Item>,
) {
  return new GraphQLNonNull(
    new GraphQLObjectType<Page<Item>>({
      name,
      fields: () => ({
        pageInfo: {
          type: new GraphQLNonNull(PageInfoSchema),
          resolve(page: Page<Item>): PageInfo {
            return page.pageInfo;
          },
        },
        items: {
          type: graphql.List(itemSchema),
          resolve(page: Page<Item>): readonly Item[] {
            return page.items;
          },
        },
        totalCount: {
          type: graphql.NotNullInteger,
          async resolve(page: Page<Item>): Promise<number> {
            return page.getTotalCount();
          },
        },
      }),
    }),
  );
}
