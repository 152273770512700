import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const UngroupIcon = React.memo<SvgIconProps>(function UngroupIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M1 11.15h1.68v1.67H1v-1.67Zm0-3.34h1.68v1.67H1V7.81Zm0 6.68h1.68v1.68c-.92 0-1.68-.76-1.68-1.68ZM4.52 1.12H6.2V2.8H4.52V1.12ZM1 4.47h1.68v1.67H1V4.47Zm1.68-3.35V2.8H1c0-.91.76-1.67 1.68-1.67Zm11.93 3.35h1.68v1.67H14.6V4.47ZM4.52 14.49H6.2v1.68H4.52v-1.68ZM14.61 1.12c.92 0 1.68.76 1.68 1.67H14.6V1.12Zm-3.37 0h1.69V2.8h-1.69V1.12Z"
      />
      <path
        fill="currentColor"
        d="M6.2 14.5H4.52v1.67H6.2v-1.68Zm3.36 0H7.88v1.67h1.68v-1.68ZM6.2 1.12H4.52V2.8H6.2V1.12ZM2.68 4.47H1v1.67h1.68V4.47Zm0-1.67V1.11A1.7 1.7 0 0 0 1 2.8h1.68Zm8.41 13.37h1.68v-1.68h-1.68v1.68Zm3.51-3.35h1.69v-1.67H14.6v1.67Zm0 1.68v1.67a1.7 1.7 0 0 0 1.69-1.68H14.6ZM9.56 1.12H7.88V2.8h1.68V1.12Zm6.73 6.68H14.6v1.68h1.68V7.81Z"
      />
      <path
        fill="currentColor"
        d="M7.71 17.86H9.4v1.67H7.71v-1.67Zm0-3.34H9.4v1.67H7.71v-1.67Zm0 6.69H9.4v1.67c-.92 0-1.68-.76-1.68-1.67Zm3.52-13.38h1.68v1.68h-1.68V7.83Zm-3.52 3.35H9.4v1.67H7.71v-1.67ZM9.4 7.83v1.68H7.71c0-.92.76-1.68 1.68-1.68Zm11.93 3.35H23v1.67h-1.68v-1.67ZM11.23 21.2h1.68v1.67h-1.68V21.2ZM21.32 7.83c.92 0 1.68.76 1.68 1.68h-1.68V7.83Zm-3.36 0h1.68v1.68h-1.68V7.83Z"
      />
      <path
        fill="currentColor"
        d="M12.91 21.2h-1.68v1.68h1.68V21.2Zm3.36 0H14.6v1.68h1.68V21.2ZM12.91 7.83h-1.68v1.68h1.68V7.83ZM9.4 11.18H7.7v1.67h1.7v-1.67Zm0-1.68V7.84a1.7 1.7 0 0 0-1.69 1.68H9.4Zm8.4 13.38h1.68V21.2H17.8v1.67Zm3.52-3.35H23v-1.67h-1.68v1.67Zm0 1.67v1.68c.92 0 1.68-.76 1.68-1.67h-1.68ZM16.27 7.83H14.6v1.68h1.68V7.83ZM23 14.52h-1.68v1.67H23v-1.67Z"
      />
    </SvgIcon>
  );
});
