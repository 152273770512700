import React, { useCallback, useEffect, useState } from 'react';

import LinkIcon from '@mui/icons-material/Link';
import LinkIconOff from '@mui/icons-material/LinkOff';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

import {
  FieldsGetter,
  PlateFormFields,
} from 'client/app/apps/plate-constructor/PlateFormFields';
import { PlateFormTextField } from 'client/app/apps/plate-constructor/PlateFormTextField';
import { lowerCaseExceptFirstLetter } from 'common/lib/strings';
import {
  ContainerType,
  FormPlateType,
  PlateType,
  WellBottomShapeEnum,
  wellBottomShapeFromString,
  WellShapeEnum,
  wellShapeFromString,
} from 'common/types/plateType';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export enum PlateFormAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

type Errors = { [key in PlateFormFields]?: boolean };

function validateAll(
  plate: FormPlateType,
  plateTypes: Set<string>,
  action: PlateFormAction,
): Errors {
  return Object.entries(FieldsGetter).reduce((errors, [field, getter]) => {
    errors[field as PlateFormFields] = validate(
      field as PlateFormFields,
      `${getter(plate as PlateType)}`,
      plateTypes,
      action,
    );
    return errors;
  }, {} as Errors);
}

function getUpdatedErrors(
  id: PlateFormFields,
  value: string,
  plateTypes: Set<string>,
  errors: Errors,
  action: PlateFormAction,
) {
  return { ...errors, [id]: validate(id, value, plateTypes, action) };
}

function validate(
  id: PlateFormFields,
  value: string,
  plateTypes: Set<string>,
  action: PlateFormAction,
): boolean {
  const trimmedValue = value.trim();
  const hasTrailingSpaces = trimmedValue !== value;

  switch (id) {
    // These cannot be empty
    case 'name':
    case 'manufacturer':
    case 'partNr':
      return !trimmedValue || hasTrailingSpaces;

    // description is optional
    case 'description':
      return false;

    case 'type': {
      // for creation process this has to be unique, otherwise we do not need any check as this cannot be changed by the user
      const alreadyExists =
        action === PlateFormAction.CREATE && plateTypes.has(trimmedValue);
      return alreadyExists || !trimmedValue || hasTrailingSpaces;
    }

    // Those must be greater than 1
    case 'rows':
    case 'columns':
      return Number.isNaN(parseInt(value, 10)) || Number(value) < 1;

    // Those must be positive
    case 'startX':
    case 'startY':
    case 'startZ':
    case 'wellX':
    case 'wellY':
    case 'wellZ':
      return Number.isNaN(parseInt(value, 10)) || Number(value) < 0;

    // Strictly positive
    case 'sepX':
    case 'sepY':
    case 'wellBottomThickness':
    case 'plateX':
    case 'plateY':
    case 'plateZ':
    case 'defaultResidualVolume':
    case 'volume':
      return Number.isNaN(parseInt(value, 10)) || Number(value) <= 0;

    // No need to validate those, they come from a dropdown
    case 'shape':
    case 'bottomShape':
    case 'containerType':
      return false;
  }
}

function plateReducer(
  id: PlateFormFields,
  value: string,
  heightLocked?: boolean,
): (plate: FormPlateType) => FormPlateType {
  return (plate: FormPlateType) => {
    switch (id) {
      case 'name':
      case 'description':
      case 'type':
      case 'manufacturer':
      case 'containerType':
        return {
          ...plate,
          [id]: value,
        };
      case 'rows':
      case 'columns':
        return {
          ...plate,
          [id]: Number(value),
        };
      case 'shape': {
        return {
          ...plate,
          wellShape: {
            ...plate.wellShape,
            type: wellShapeFromString(value),
          },
        };
      }
      case 'sepX': {
        return {
          ...plate,
          wellOffset: {
            ...plate.wellOffset,
            x: Number(value),
          },
        };
      }
      case 'sepY': {
        return {
          ...plate,
          wellOffset: {
            ...plate.wellOffset,
            y: Number(value),
          },
        };
      }
      case 'plateX': {
        return {
          ...plate,
          dimension: {
            ...plate.dimension,
            x: Number(value),
          },
        };
      }
      case 'plateY': {
        return {
          ...plate,
          dimension: {
            ...plate.dimension,
            y: Number(value),
          },
        };
      }
      case 'plateZ': {
        if (heightLocked) {
          const plateHeight =
            (Number(plate.wellShape.dimensionMm.z) || 0) +
            (Number(plate.wellStart.z) || 0);
          return {
            ...plate,
            dimension: {
              ...plate.dimension,
              z: plateHeight,
            },
          };
        }
        return {
          ...plate,
          dimension: {
            ...plate.dimension,
            z: Number(value),
          },
        };
      }
      case 'startX': {
        return {
          ...plate,
          wellStart: {
            ...plate.wellStart,
            x: Number(value),
          },
        };
      }
      case 'startY': {
        return {
          ...plate,
          wellStart: {
            ...plate.wellStart,
            y: Number(value),
          },
        };
      }
      case 'startZ': {
        const startZ = Number(value);
        const plateHeight = heightLocked
          ? startZ + Number(plate.wellShape.dimensionMm.z)
          : plate.dimension.z;
        return {
          ...plate,
          dimension: {
            ...plate.dimension,
            z: plateHeight,
          },
          wellStart: {
            ...plate.wellStart,
            z: startZ,
          },
        };
      }
      case 'wellX': {
        return {
          ...plate,
          wellShape: {
            ...plate.wellShape,
            dimensionMm: {
              ...plate.wellShape.dimensionMm,
              x: Number(value),
            },
          },
        };
      }
      case 'wellY': {
        return {
          ...plate,
          wellShape: {
            ...plate.wellShape,
            dimensionMm: {
              ...plate.wellShape.dimensionMm,
              y: Number(value),
            },
          },
        };
      }
      case 'wellZ': {
        const wellZ = Number(value);
        const plateHeight = heightLocked
          ? wellZ + Number(plate.wellStart.z)
          : plate.wellShape.dimensionMm.z;
        return {
          ...plate,
          dimension: {
            ...plate.dimension,
            z: plateHeight,
          },
          wellShape: {
            ...plate.wellShape,
            dimensionMm: {
              ...plate.wellShape.dimensionMm,
              z: wellZ,
            },
          },
        };
      }
      case 'partNr':
        return {
          ...plate,
          catalogNumber: value,
        };
      case 'volume':
        return {
          ...plate,

          wellShape: {
            ...plate.wellShape,
            volumeOverrideUl: Number(value),
          },
        };
      case 'defaultResidualVolume':
        return {
          ...plate,
          defaultResidualVolume: Number(value),
        };
      case 'bottomShape': {
        return {
          ...plate,
          wellShape: {
            ...plate.wellShape,
            bottomType: wellBottomShapeFromString(value),
          },
        };
      }
      case 'wellBottomThickness':
        return {
          ...plate,
          wellBottomOffset: Number(value),
        };
    }
  };
}

type Props = {
  plate: FormPlateType;
  setPlate: React.Dispatch<React.SetStateAction<FormPlateType>>;
  setSelectedField: React.Dispatch<React.SetStateAction<PlateFormFields | undefined>>;
  // all the existing platetypes'types.
  // they must be unique, therefore the `type` field will error if user try to enter an already existing type.
  plateTypes: Set<string>;
  disabled?: boolean;
  onValidationUpdate: (hasErrors: boolean) => void;
  action: PlateFormAction;
};

export default function PlateForm(props: Props) {
  const {
    setPlate,
    plate,
    setSelectedField,
    plateTypes,
    disabled,
    onValidationUpdate,
    action,
  } = props;
  const classes = useStyles();

  // TODO: should we always default to true?
  // What about when we edit an existing plate?
  const [heightLocked, setHeightLocked] = useState(true);
  const toggleHeightLock = useCallback(() => {
    setHeightLocked(lock => {
      const newLock = !lock;
      // When locking the height we need to update it.
      if (newLock) {
        setPlate(plateReducer('plateZ', '', newLock));
      }
      return newLock;
    });
  }, [setPlate]);

  const [errors, setErrors] = useState<Errors>(validateAll(plate, plateTypes, action));

  // when the plateTypes list from the store changes, we need to revalidate
  useEffect(() => {
    setErrors(validateAll(plate, plateTypes, action));
  }, [setErrors, plate, plateTypes, action]);

  const onSelect = useCallback(
    (id?: PlateFormFields) => {
      setSelectedField(id);
    },
    [setSelectedField],
  );

  const onFieldUpdate = useCallback(
    (id: PlateFormFields, value: string) => {
      const newErrors = getUpdatedErrors(id, value, plateTypes, errors, action);
      setErrors(newErrors);
      const hasErrors = Object.values(newErrors).some(error => error);
      onValidationUpdate(hasErrors);
    },
    [plateTypes, errors, action, onValidationUpdate],
  );

  const update = useCallback(
    (id: PlateFormFields, value: string) => {
      onFieldUpdate(id, value);
      setPlate(plateReducer(id, value, heightLocked));
    },
    [heightLocked, onFieldUpdate, setPlate],
  );
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const id = event.target.id as PlateFormFields;
      update(id, value);
    },
    [update],
  );
  const onChangeSelect = useCallback(
    (id: PlateFormFields) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      update(id, value);
    },
    [update],
  );

  return (
    <div className={classes.formGrid}>
      <Typography className={classes.fullRow} variant="h2">
        Metadata
      </Typography>
      <PlateFormTextField
        className={classes.fullRow}
        required
        type="string"
        id="name"
        label="Plate Name"
        value={plate.name}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.name}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.halfRow}
        required
        type="string"
        id="type"
        label="Plate type (must be unique)"
        value={plate.type}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.type}
        disabled={action !== PlateFormAction.CREATE}
      />
      <PlateFormTextField
        className={classes.halfRow}
        type="string"
        id="description"
        label="Plate Description"
        value={plate.description}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.description}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.halfRow}
        required
        type="string"
        id="manufacturer"
        label="Manufacturer"
        value={plate.manufacturer}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.manufacturer}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.halfRow}
        required
        type="string"
        id="partNr"
        label="Part Number"
        value={plate.catalogNumber}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.partNr}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.halfRow}
        required
        select
        type="string"
        id="containerType"
        label="Container type"
        value={plate.containerType}
        onChange={onChangeSelect('containerType')}
        onSelectField={onSelect}
        error={errors.containerType}
        disabled={disabled}
      >
        <MenuItem key="WELL" value={ContainerType.WELL}>
          Well
        </MenuItem>
        <MenuItem key="COLUMN" value={ContainerType.COLUMN}>
          Column
        </MenuItem>
      </PlateFormTextField>
      <Typography className={classes.fullRow} variant="h2">
        {lowerCaseExceptFirstLetter(plate.containerType)} Grid
      </Typography>
      <PlateFormTextField
        className={classes.halfRow}
        required
        type="number"
        id="rows"
        label="Rows"
        value={plate.rows}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.rows}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.halfRow}
        required
        type="number"
        id="columns"
        label="Columns"
        value={plate.columns}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.columns}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.halfRow}
        required
        type="number"
        id="sepX"
        label="Horizontal separation (mm)"
        value={plate.wellOffset.x}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.sepX}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.halfRow}
        required
        type="number"
        id="sepY"
        label="Vertical separation (mm)"
        value={plate.wellOffset.y}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.sepY}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.halfRow}
        required
        type="number"
        id="startX"
        label="Horizontal grid offset (mm)"
        value={plate.wellStart.x}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.startX}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.halfRow}
        required
        type="number"
        id="startY"
        label="Vertical grid offset (mm)"
        value={plate.wellStart.y}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.startY}
        disabled={disabled}
      />
      <Typography className={classes.fullRow} variant="h2">
        {lowerCaseExceptFirstLetter(plate.containerType)} Dimensions
      </Typography>
      <PlateFormTextField
        className={classes.thirdRow}
        required
        type="number"
        id="wellX"
        label="Width X (mm)"
        value={plate.wellShape.dimensionMm.x}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.wellX}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.thirdRow}
        required
        type="number"
        id="wellY"
        label="Width Y (mm)"
        value={plate.wellShape.dimensionMm.y}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.wellY}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.thirdRow}
        required
        type="number"
        id="wellZ"
        label="Depth (mm)"
        value={plate.wellShape.dimensionMm.z}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.wellZ}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.halfRow}
        required
        inputProps={{ step: 0.1 }}
        type="number"
        id="wellBottomThickness"
        label="Bottom Thickness (mm)"
        value={plate.wellBottomOffset}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.wellBottomThickness}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.halfRow}
        required
        type="number"
        id="startZ"
        label="Height grid offset (mm)"
        value={plate.wellStart.z}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.startZ}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.halfRow}
        required
        type="number"
        id="volume"
        label={'Volume (\u00b5l)'}
        value={plate.wellShape.volumeOverrideUl}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.volume}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.halfRow}
        required
        type="number"
        id="defaultResidualVolume"
        label={'Residual volume (\u00b5l)'}
        value={plate.defaultResidualVolume}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.defaultResidualVolume}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.halfRow}
        required
        select
        type="string"
        id="shape"
        label="Shape"
        value={plate.wellShape.type}
        onChange={onChangeSelect('shape')}
        onSelectField={onSelect}
        error={errors.shape}
        disabled={disabled}
      >
        <MenuItem key="square" value={WellShapeEnum.SQUARE}>
          Square
        </MenuItem>
        <MenuItem key="circle" value={WellShapeEnum.CIRCLE}>
          Circle
        </MenuItem>
        <MenuItem key="cylinder" value={WellShapeEnum.CYLINDER}>
          Cylinder
        </MenuItem>
        <MenuItem key="box" value={WellShapeEnum.BOX}>
          Box
        </MenuItem>
        <MenuItem key="trapezoid" value={WellShapeEnum.TRAPEZOID}>
          Trapezoid
        </MenuItem>
      </PlateFormTextField>
      <PlateFormTextField
        className={classes.halfRow}
        required
        select
        type="string"
        id="bottomShape"
        label="Bottom shape"
        value={plate.wellShape.bottomType}
        onChange={onChangeSelect('bottomShape')}
        onSelectField={onSelect}
        error={errors.bottomShape}
        disabled={disabled}
      >
        <MenuItem key="FLAT_BOTTOM" value={WellBottomShapeEnum.FLAT_BOTTOM}>
          FLAT_BOTTOM
        </MenuItem>
        <MenuItem key="V_BOTTOM" value={WellBottomShapeEnum.V_BOTTOM}>
          V_BOTTOM
        </MenuItem>
        <MenuItem key="U_BOTTOM" value={WellBottomShapeEnum.U_BOTTOM}>
          U_BOTTOM
        </MenuItem>
      </PlateFormTextField>
      <Typography className={classes.fullRow} variant="h2">
        Plate
      </Typography>
      <PlateFormTextField
        className={classes.thirdRow}
        required
        type="number"
        id="plateX"
        label="Plate Width (mm)"
        value={plate.dimension.x}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.plateX}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.thirdRow}
        required
        type="number"
        id="plateY"
        label="Plate Length (mm)"
        value={plate.dimension.y}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.plateY}
        disabled={disabled}
      />
      <PlateFormTextField
        className={classes.thirdRow}
        required
        type="number"
        id="plateZ"
        label="Plate Height (mm)"
        value={plate.dimension.z}
        onChange={onChange}
        onSelectField={onSelect}
        error={errors.plateZ}
        disabled={disabled || heightLocked}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SvgIcon onClick={toggleHeightLock}>
                {heightLocked ? <LinkIcon /> : <LinkIconOff />}
              </SvgIcon>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

const useStyles = makeStylesHook({
  buttons: {
    height: '2rem',
  },
  formGrid: {
    height: 'calc(100% - 3rem)',
    marginTop: '10px',
    display: 'grid',
    gridGap: '5px',
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
  fullRow: {
    gridColumn: 'span 6',
  },
  halfRow: {
    gridColumn: 'span 3',
  },
  thirdRow: {
    gridColumn: 'span 2',
  },
  hidden: { display: 'none' },
});
