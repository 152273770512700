import React, { useCallback } from 'react';

import { fromColumnVolume, toColumnVolume } from 'common/lib/chromatography';
import { Measurement } from 'common/types/mix';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import MeasurementEditor from 'common/ui/components/ParameterEditors/MeasurementEditor';

const CV_UNIT = 'CV';
const UL_UNIT = 'ul';

type Props = {
  onChange: (measurement?: Measurement) => void;
  /**
   * The RoboColumn volume is needed when converting between ul and CV
   */
  robocolumnVolume?: Measurement;
} & ParameterEditorBaseProps<Measurement>;

/**
 * Allows inputting a load volume in CV (column volume) or ul. When switching
 * the unit, the value will be converted.
 *
 * This is used for specifying load volume in chromatography actions.
 */
export default function ColumnVolumeEditor({
  value: prevMeasurement,
  onChange,
  isDisabled,
  robocolumnVolume,
}: Props) {
  const handleChange = useCallback(
    (newMeasurement?: Measurement) => {
      // If the unit was changed, then convert the value
      if (newMeasurement?.unit === UL_UNIT && prevMeasurement?.unit === CV_UNIT) {
        if (prevMeasurement.value && robocolumnVolume) {
          onChange(fromColumnVolume(prevMeasurement, robocolumnVolume));
        }
      } else if (newMeasurement?.unit === CV_UNIT && prevMeasurement?.unit === UL_UNIT) {
        if (prevMeasurement && robocolumnVolume) {
          onChange(toColumnVolume(prevMeasurement, robocolumnVolume));
        }
      } else {
        onChange(newMeasurement);
      }
    },
    [onChange, prevMeasurement, robocolumnVolume],
  );

  return (
    <MeasurementEditor
      validUnits={robocolumnVolume ? [UL_UNIT, CV_UNIT] : [CV_UNIT]}
      defaultUnit={CV_UNIT}
      value={prevMeasurement}
      onChange={handleChange}
      isDisabled={isDisabled}
      isRequired
    />
  );
}
