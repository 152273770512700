import React from 'react';

import MuiCard from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Reagents from 'client/app/apps/execution-details/ExecuteTab/Reagents/Reagents';
import { useDownloadReagents } from 'client/app/apps/execution-details/ExecuteTab/Reagents/useDownloadReagents';
import ExecutionModeCard from 'client/app/apps/simulation-details/overview/ExecutionModeCard';
import Labware from 'client/app/components/Labware/Labware';
import useFormattedLabware from 'client/app/components/Labware/useFormattedLabware';
import { Simulation } from 'client/app/gql';
import { Deck } from 'common/types/mix';
import { PlateType } from 'common/types/plateType';

type Props = {
  deck: Deck | undefined;
  plateTypes: readonly PlateType[];
  simulation: Simulation;
};

export default function ResourcesOverview({ deck, plateTypes, simulation }: Props) {
  const formattedLabware = useFormattedLabware(deck, plateTypes, simulation.tipUsage);
  const downloadReagents = useDownloadReagents(simulation.name);

  return (
    <ResourcesOverviewPanel>
      <Typography variant="h1" fontWeight={400} color="textPrimary">
        Resources overview
      </Typography>
      <Stack direction="row" gap={5} alignItems="flex-start">
        <Stack direction="column" gap={5} alignItems="flex-start" flex={1}>
          <ExecutionModeCard simulation={simulation} />
          <Card>
            <Typography variant="h2" marginBottom={6}>
              Labware
            </Typography>
            <Labware labware={formattedLabware} />
          </Card>
        </Stack>
        <Stack direction="column" alignItems="flex-start" flex={1.5}>
          <ReagentsListCard>
            <Typography variant="h2" marginBottom={6}>
              Reagents
            </Typography>
            <Reagents reagents={simulation.reagents} onDownload={downloadReagents} />
          </ReagentsListCard>
        </Stack>
      </Stack>
    </ResourcesOverviewPanel>
  );
}

const ResourcesOverviewPanel = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(7),
  gap: theme.spacing(6),
}));

const Card = styled(MuiCard)(({ theme }) => ({
  flex: 1,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(6),
  margin: 0,
  minHeight: 220,
  maxWidth: 'unset',
  minWidth: 350,
}));

const ReagentsListCard = styled(Card)({
  minWidth: 450,
});
