import React from 'react';

import Typography from '@mui/material/Typography';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export default function VersionInfo() {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  return (
    <div className={classes.root}>
      <Typography align="center" variant="caption">
        &copy; {currentYear} Synthace Ltd.
      </Typography>
    </div>
  );
}
const useStyles = makeStylesHook(theme => ({
  root: {
    textAlign: 'center',
    margin: theme.spacing(2, 0),
    whiteSpace: 'normal',
  },
}));
