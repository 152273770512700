import React from 'react';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

const AnthaIconURL = 'app/assets/images/data-repo/antha.png';
const CSVIconURL = 'app/assets/images/data-repo/csv.png';
const FASTAIconURL = 'app/assets/images/data-repo/fasta.png';
const GIFIconURL = 'app/assets/images/data-repo/jpg.png';
const GBKIconURL = 'app/assets/images/data-repo/gbk.png';
const HTMIconURL = 'app/assets/images/data-repo/htm.png';
const INIIconURL = 'app/assets/images/data-repo/ini.png';
const JMPIconURL = 'app/assets/images/data-repo/jmp.png';
const JPGIconURL = 'app/assets/images/data-repo/jpg.png';
const JSONIconURL = 'app/assets/images/data-repo/json.png';
const LogIconURL = 'app/assets/images/data-repo/log.png';
const ParquetIconURL = 'app/assets/images/data-repo/parquet.png';
const PDFIconURL = 'app/assets/images/data-repo/pdf.png';
const PNGIconURL = 'app/assets/images/data-repo/png.png';
const ProtobufIconURL = 'app/assets/images/data-repo/pb.png';
const SQLiteIconURL = 'app/assets/images/data-repo/sqlite.png';
const SPCIconURL = 'app/assets/images/data-repo/spc.png';
const TXTIconURL = 'app/assets/images/data-repo/txt.png';
const UnknownTypeIconURL = 'app/assets/images/data-repo/unknown.png';
const XLSIconURL = 'app/assets/images/data-repo/xls.png';
const XMLIconURL = 'app/assets/images/data-repo/xml.png';
const ZipIconURL = 'app/assets/images/data-repo/zip.png';

// It should be good enough to just rely on the content type,
// however the backend in many cases does not return the content
// type, even when we ought to know the content type. So, we'll
// fallback on file extension matching, like the cavepeople.

const typeToURLMap: { [fileType: string]: string } = {
  an: AnthaIconURL,
  csv: CSVIconURL,
  fasta: FASTAIconURL,
  gb: GBKIconURL,
  gbk: GBKIconURL,
  genbank: GBKIconURL,
  gif: GIFIconURL,
  htm: HTMIconURL,
  html: HTMIconURL,
  ini: INIIconURL,
  jmp: JMPIconURL,

  jpeg: JPGIconURL,
  jpg: JPGIconURL,
  'image/jpeg': JPGIconURL,
  'image/jpg': JPGIconURL,

  json: JSONIconURL,
  log: LogIconURL,

  parquet: ParquetIconURL,
  'application/parquet': ParquetIconURL,

  pb: ProtobufIconURL,
  pdf: PDFIconURL,
  png: PNGIconURL,

  sqlite: SQLiteIconURL,

  spc: SPCIconURL,
  'chemical/x-galactic-spc': SPCIconURL,

  txt: TXTIconURL,

  xls: XLSIconURL,
  xlsx: XLSIconURL,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': XLSIconURL,

  xml: XMLIconURL,
  'text/xml': XMLIconURL,

  zip: ZipIconURL,
};

type FileLikeThing = {
  name: string;
  contentType?: string;
};

type Props = {
  file: FileLikeThing;
} & WithStyles<typeof styles>;

const FileIcon = function (props: Props) {
  const { classes, file } = props;

  let src;
  if (file.contentType && file.contentType !== '') {
    // In some cases, we include some parameters in the contentType, which
    // is technically allowable in the mimetype specification
    // (https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types).
    // So, we have to carve out the type/subtype value from string.
    //
    //   Example: `text/xml; charset=utf-8`
    const parts = file.contentType.split(';');
    src = typeToURLMap[(parts[0] || '').trim()];
  }

  if (!src) {
    const extResult = file.name.match(/\.([a-zA-Z0-4]+)$/);
    if (extResult) {
      const ext = extResult[1].toLowerCase();
      src = typeToURLMap[ext];
    }
  }

  if (!src) {
    src = UnknownTypeIconURL;
  }

  return <img className={classes.img} src={src} />;
};

const styles = createStyles({
  img: {
    display: 'block',
    flexGrow: 0,
    flexShrink: 0,
    height: '24px',
    marginRight: '6px',
    width: '24px',
  },
});

export default withStyles(styles)(FileIcon);
