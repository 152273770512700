import React, { useEffect, useRef, useState } from 'react';

import Typography, { TypographyProps } from '@mui/material/Typography';

import Tooltip from 'common/ui/components/Tooltip';

type Props = Omit<TypographyProps, 'noWrap' | 'ref' | 'onMouseOver'>;

/**
 * Wraps a Typography component in a Tooltip. The Tooltip title is shown on mouse over only if
 * the Typography text is ellipsized.
 */
export default React.memo(function TypographyWithTooltip(props: Props) {
  const { children } = props;
  const [overflowActive, setOverflowActive] = useState(false);

  const typographyRef = useRef<HTMLDivElement>(null);

  const updateOverflowActive = () => {
    if (typographyRef.current) {
      setOverflowActive(
        typographyRef?.current?.scrollWidth > typographyRef?.current?.clientWidth,
      );
    }
  };

  useEffect(() => {
    updateOverflowActive();
    window.addEventListener('resize', updateOverflowActive);
    return () => window.removeEventListener('resize', updateOverflowActive);
  }, []);

  const typography = (
    <Typography
      {...props}
      noWrap
      onMouseOver={updateOverflowActive}
      ref={typographyRef}
    />
  );

  // Conditionally use the wrapping Tooltip when necessary as this improves performance of the component's initial mount
  return overflowActive ? (
    <Tooltip title={<>{children}</>}>{typography}</Tooltip>
  ) : (
    typography
  );
});
