import React from 'react';

import { ReleaseQualityEnum } from 'client/app/gql';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { ReleaseQualityBetaIcon } from 'client/app/icons/ReleaseQualityBeta';
import { ReleaseQualityClientSpecificIcon } from 'client/app/icons/ReleaseQualityClientSpecific';
import { ReleaseQualityPrototypeIcon } from 'client/app/icons/ReleaseQualityPrototype';
import { ReleaseQualityUnknownIcon } from 'client/app/icons/ReleaseQualityUnknown';
import { switchFail } from 'common/assert';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { ReleaseQuality } from 'common/types/bundle';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export const INSTANCE_PARAMETERS_LABEL = 'Instance Parameters';
/**
 * If the user is Synthace employee, give them a visual indicator of the release quality of element.
 */
export function getReleaseQualityLabel(
  releaseQuality: ReleaseQualityEnum | ReleaseQuality,
  isSynthaceEmployee: boolean,
) {
  if (isSynthaceEmployee) {
    switch (releaseQuality) {
      case ReleaseQualityEnum.PROTOTYPE:
        return 'Prototype';
      case ReleaseQualityEnum.BETA:
        return 'Beta';
      case ReleaseQualityEnum.PRODUCT:
        return INSTANCE_PARAMETERS_LABEL;
      case ReleaseQualityEnum.CLIENT_SPECIFIC:
        return 'For Client';
      case ReleaseQualityEnum.UNKNOWN:
        return 'Unknown';
    }
  }
  return INSTANCE_PARAMETERS_LABEL;
}

type ReleaseQualityProps = {
  releaseQuality: ReleaseQualityEnum;
};

export function ReleaseQualityIndicator(props: ReleaseQualityProps) {
  const userProfile = useUserProfile();
  const isReleaseQualityEnabled = useFeatureToggle('SHOW_ELEMENT_RELEASE_QUALITY');
  const isSynthaceEmployee = userProfile?.isSynthaceEmployee ?? false;

  const { releaseQuality } = props;
  const classes = useStyles();
  let releaseQualityIcon;
  let releaseQualityHelpText = '';

  if (!isSynthaceEmployee || !isReleaseQualityEnabled) {
    return null;
  }

  switch (releaseQuality) {
    case ReleaseQualityEnum.BETA:
      releaseQualityIcon = <ReleaseQualityBetaIcon />;
      releaseQualityHelpText = 'Beta';
      break;
    case ReleaseQualityEnum.PRODUCT:
      // Don't do anything if this is a Product Element
      break;
    case ReleaseQualityEnum.PROTOTYPE:
      releaseQualityIcon = <ReleaseQualityPrototypeIcon />;
      releaseQualityHelpText = 'Prototype';
      break;
    case ReleaseQualityEnum.CLIENT_SPECIFIC:
      releaseQualityIcon = <ReleaseQualityClientSpecificIcon />;
      releaseQualityHelpText = 'Client Specific';
      break;
    case ReleaseQualityEnum.UNKNOWN:
      releaseQualityIcon = <ReleaseQualityUnknownIcon />;
      releaseQualityHelpText = 'Unknown';
      break;
    default:
      switchFail(releaseQuality);
  }

  if (!releaseQualityIcon) {
    return null;
  }

  return (
    <Tooltip title={releaseQualityHelpText}>
      <div className={classes.iconStyle}> {releaseQualityIcon}</div>
    </Tooltip>
  );
}

const useStyles = makeStylesHook({
  iconStyle: {
    height: '24px',
  },
});
