import { FactorItem, FactorPath, Factors } from 'common/types/bundle';

export function matchFactorPath(f: FactorItem, pathToMatch: FactorPath) {
  const [workflowName, instanceName, parameterName, parameterMapKey] = pathToMatch;

  return f.path
    ? f.path[0] === workflowName &&
        f.path[1] === instanceName &&
        f.path[2] === parameterName &&
        (f.path[3] === parameterMapKey || parameterMapKey === undefined)
    : false;
}

export function countActiveFactors(factors: Factors) {
  const seen = new Set<string | undefined>();
  return factors?.reduce((count: number, factor: FactorItem) => {
    if (
      !factor.included ||
      (factor.mutualExclusionGroup && seen.has(factor.mutualExclusionGroup))
    ) {
      return count;
    }
    seen.add(factor.mutualExclusionGroup);
    return (count += 1);
  }, 0);
}
