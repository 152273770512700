import { useContext } from 'react';

import { FeatureTogglesContext } from 'common/features/FeatureTogglesContext';
import { FeatureName } from 'common/features/featureTogglesForUI';

/**
 * Shortcut for using toggle features in components. Returns boolean expressing
 * whether given feature is enabled or disabled.
 *
 * Usage:
 *
 * const isMyFeatureEnabled = useFeatureToggle('MY_FEATURE');
 */
export function useFeatureToggle(name: FeatureName): boolean {
  const featureToggles = useContext(FeatureTogglesContext);
  return featureToggles.isEnabled(name);
}
