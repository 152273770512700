import React from 'react';

import Grid from '@mui/material/Grid';

import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import Switch from 'common/ui/components/Switch';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  labels?: { offLabel?: string; onLabel?: string };
  onChange: (newValue: boolean | null | undefined) => void;
} & ParameterEditorBaseProps<boolean | null>;

const ToggleEditor = React.memo(function ToggleEditor(props: Props) {
  const classes = useStyles();
  const { value, isDisabled, labels, onChange } = props;

  const onClick = () => {
    onChange(!props.value);
  };

  return (
    <Grid component="label" container alignItems="center" spacing={3}>
      {labels && <Grid item>{labels?.offLabel ?? 'Off'} </Grid>}
      <Grid item>
        <Switch
          onChange={onClick}
          disabled={isDisabled}
          checked={value ? true : false}
          className={classes.toggle}
          color="primary"
        />
      </Grid>
      {labels && <Grid item>{labels?.onLabel ?? 'On'}</Grid>}
    </Grid>
  );
});

const useStyles = makeStylesHook({
  toggle: {
    justifyContent: 'flex-start',
  },
});

export default ToggleEditor;
