import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

function XmlIcon(
  props: React.PropsWithChildren<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>>,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2H4c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H16c1.1 0 2-.9 2-2v-1h-2v1H4V4h7v5h5v1h2V8l-6-6Z"
        fill="#FF9800"
      />
      <path
        d="m7.492 13.547.93-1.89h1.566l-1.59 2.82 1.633 2.867H8.45l-.957-1.926-.957 1.926H4.957l1.629-2.867L5 11.657h1.563l.93 1.89ZM12.681 11.656l1.27 3.957 1.265-3.957h1.805v5.688h-1.375v-1.328l.133-2.72-1.375 4.048h-.906l-1.38-4.051.134 2.723v1.328H10.88v-5.688h1.801ZM19.667 16.29h2.387v1.054h-3.758v-5.688h1.371v4.633Z"
        fill="#FF9800"
      />
    </SvgIcon>
  );
}

export default React.memo(XmlIcon);
