import { useMemo } from 'react';

import some from 'lodash/some';

import { SimulationSourceEnum } from 'client/app/gql';
import { ROBOCOLUMNS_ELEMENT } from 'client/app/hooks/useIsRobocolumnsSimulation';
import { ServerSideBundle } from 'common/types/bundle';

/**
 * We currently deal with two types of DOE workflows.
 *
 * Newly created DOE workflows via the DOE app built in 2023, when simulated, will have
 * the simulation source set as SimulationSourceEnum.DOE_DESIGN.
 *
 * For legacy DOE workflows (which included legacy Robocolumn workflows)
 * we check the workflow for the presence of certain elements.
 *
 * For newly created DOE workflows, we will render UI that allows the user
 * to structure data on the Experiment Map. For legacy DOE workflows, we dissallow
 * this, and maintain the legacy UI that allows users to visualise their results
 * and launch DOE in the legacy apps.
 *
 * See SYN-1282 and SYN-1367 for tracking integrating legacy apps into the Experiment Map.
 */
const DOE_ALPHA_ELEMENT = 'Enable_Integrated_DOE';
const DOE_ELEMENTS = [ROBOCOLUMNS_ELEMENT, DOE_ALPHA_ELEMENT];

type DOESimulationType = 'legacy' | 'new' | 'none';

export default function useIsDOEAllowedForSimulation(
  workflowBundle?: ServerSideBundle,
  source?: SimulationSourceEnum,
): DOESimulationType {
  return useMemo(() => {
    if (source === SimulationSourceEnum.DOE_DESIGN) {
      return 'new';
    } else if (
      some(workflowBundle?.Elements.Instances, instance =>
        DOE_ELEMENTS.includes(instance.TypeName),
      )
    ) {
      return 'legacy';
    } else {
      return 'none';
    }
  }, [source, workflowBundle]);
}
