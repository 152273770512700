/**
 * Map of Antha system parameter type names to UI parameter types (colours)
 * @type {Object}
 */
const PARAMETER_TYPE_MAP: { [typeName: string]: string } = {
  DNASequence: 'antha-stdlib',
  'ParsePlate.PlateName': 'golang-string',
  'Pubchem.Molecule': 'antha-stdlib',
  bool: 'golang-bool',
  'data.Table': 'antha-table',
  'doe.Run': 'antha-stdlib',
  'enzymes.RestrictionSites': 'antha-stdlib',
  error: 'golang-error',
  float64: 'golang-float64',
  int: 'golang-int',
  'oligos.Primer': 'antha-stdlib',
  string: 'golang-string',
  unit8: 'golang-unit8',
};

function getHighLevelCssClassName(typeName: string): string {
  if (typeName.startsWith('wtype.')) {
    return 'antha-primitive';
  }

  if (typeName.startsWith('wunit.')) {
    return 'antha-measure';
  }

  return PARAMETER_TYPE_MAP[typeName];
}

export default getHighLevelCssClassName;
