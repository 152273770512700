import { computeTotalScore, NAME_SEARCH_WEIGHT } from 'client/app/lib/smartSearch';
import { Markdown } from 'common/lib/markdown';
import { Element } from 'common/types/bundle';

export type ElementView = {
  element: Element;
  shortDescription: Markdown;
  normalisedName: string;
};

export function filterAndRankElements(
  elements: ElementView[],
  query: RegExp | null,
  searchTermCount: number,
  tagFilter: string[],
) {
  elements = elements.filter(ev => !ev.element.isDeprecated);

  if (tagFilter.length > 0) {
    elements = elements.filter(ev => ev.element.tags.some(t => tagFilter.includes(t)));
  }

  if (!query) {
    return elements;
  }

  // Calculate query match score for each element and order them by the score
  return elements
    .map((ev): [number, ElementView] => {
      const {
        normalisedName,
        shortDescription,
        element: { tags },
      } = ev;
      // For descriptions we ignore the length factor,
      // as it will have a very small weight either way
      const totalScore = computeTotalScore(
        [normalisedName],
        [shortDescription, ...tags],
        NAME_SEARCH_WEIGHT,
        query,
        searchTermCount,
      );
      return [totalScore, ev];
    })
    .filter(([score]) => score > 0)
    .sort(([scoreA, elementA], [scoreB, elementB]) => {
      if (scoreA > scoreB) return -1;
      else if (scoreA < scoreB) return 1;
      else {
        return elementA.normalisedName.localeCompare(elementB.normalisedName);
      }
    })
    .map(([, element]) => element);
}
