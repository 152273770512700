import React, { FormEvent, HTMLAttributes, ReactNode, useCallback } from 'react';

import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import FormatListNumbered from '@mui/icons-material/FormatListNumbered';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import cn from 'classnames';

import { ParameterHeader } from 'client/app/components/Parameters/ElementParameterHeader';
import { useFiltrationProtocolDesign } from 'client/app/components/Parameters/FiltrationProtocolDesign/FiltrationProtocolDesignState';
import useFactorForm, {
  mapStateToFactor,
} from 'client/app/components/Parameters/FiltrationProtocolDesign/lib/useFactorForm';
import useUnitOptions from 'client/app/components/Parameters/FiltrationProtocolDesign/lib/useUnitOptions';
import {
  Factor,
  FactorType,
} from 'client/app/components/Parameters/FiltrationProtocolDesign/types';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import ItemList from 'common/ui/components/ItemList/ItemList';
import Switch from 'common/ui/components/Switch';
import Dropdown from 'common/ui/filaments/Dropdown';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { ConcentrationIcon } from 'common/ui/icons/Concentration';

const TYPE_NAME: Record<FactorType, string> = {
  categorical: 'Categorical',
  numeric: 'Numerical',
  subComponent: 'Concentration',
};

function FactorTypeButton({
  name,
  description,
  icon,
  ...props
}: {
  name: string;
  description: string;
  icon: ReactNode;
} & HTMLAttributes<HTMLButtonElement>) {
  const classes = useStyles();

  return (
    <button type="button" className={classes.factorTypeButton} {...props}>
      {icon}
      <Typography variant="subtitle2">{name}</Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        className={classes.factorTypeDescription}
      >
        {description}
      </Typography>
    </button>
  );
}

function ChooseFactor({ onChosen }: { onChosen: (type: FactorType) => void }) {
  const classes = useStyles();

  return (
    <div className={classes.chooseFactor}>
      <Typography variant="body2">Choose the factor type...</Typography>
      <FactorTypeButton
        icon={<ConcentrationIcon color="primary" />}
        name="Concentration"
        description="Factorise the names and concentration values of liquid sub-components."
        onClick={() => onChosen('subComponent')}
      />
      <FactorTypeButton
        icon={<FormatListBulleted color="primary" />}
        name="Categorical"
        description="A factor consisting of several user-defined categories. The category will be assigned as metadata to generated liquids."
        onClick={() => onChosen('categorical')}
      />
      <FactorTypeButton
        icon={<FormatListNumbered color="primary" />}
        name="Numerical"
        description="A factor consisting of several numerical values. The value will be assigned as metadata to generated liquids."
        onClick={() => onChosen('numeric')}
      />
    </div>
  );
}

export default function FactorForm({
  factor,
  otherFactors,
  className,
  onClose,
  isReadonly,
}: {
  factor?: Factor;
  className?: string;
  onClose: () => void;
  otherFactors: string[];
  isReadonly: boolean;
}) {
  const classes = useStyles();
  const { addFactor, updateFactor } = useFiltrationProtocolDesign();
  const {
    state,
    updateType,
    updateName,
    updateLevels,
    updateNames,
    updateUnit,
    showErrors,
    updateMetdataLabel,
    toggleMetadataLabelOverride,
    toggleDefaultFactorNameOverride,
  } = useFactorForm(factor, otherFactors);
  const unitOptions = useUnitOptions();

  const errors = state.showErrors ? state.errors : {};

  const saveFactor = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (state.errors) {
        showErrors();
      } else {
        const factor = mapStateToFactor(state);

        if (factor) {
          if (state.isNew) {
            addFactor(factor);
          } else {
            updateFactor(state.clientId, factor);
          }
        }

        onClose();
      }
    },
    [addFactor, onClose, showErrors, state, updateFactor],
  );

  return (
    <form className={cn(classes.container, className)} onSubmit={saveFactor}>
      <header>
        <Typography component="h5">
          {state.isNew ? `New` : isReadonly ? `View` : `Edit`}
          {state.type ? ` ${TYPE_NAME[state.type]}` : ''} Factor
        </Typography>
      </header>
      <main>
        {state.type ? (
          <>
            {state.type === 'subComponent' ? (
              <>
                <div className={classes.field}>
                  <ParameterHeader displayName="Factor Name" isRequired />
                  {state.overrideDefaultFactorName ? (
                    <TextField
                      value={state.factorName}
                      onChange={e => updateName(e.target.value)}
                      placeholder="Factor name"
                      error={!!errors?.name}
                      helperText={errors?.name}
                      disabled={isReadonly}
                    />
                  ) : (
                    <Typography
                      component="output"
                      variant="body1"
                      className={classes.readout}
                      color={state.factorName === '' ? 'textSecondary' : 'textPrimary'}
                    >
                      {state.factorName === ''
                        ? 'Please define sub-components...'
                        : state.factorName}
                    </Typography>
                  )}
                  <label className={classes.overrideSwitch}>
                    <Switch
                      color="primary"
                      size="small"
                      checked={state.overrideDefaultFactorName}
                      onChange={e => toggleDefaultFactorNameOverride(e.target.checked)}
                      disabled={isReadonly}
                    />
                    <Typography variant="body2">Override default</Typography>
                  </label>
                </div>

                <div className={classes.field}>
                  <ParameterHeader displayName="Sub-component Names" isRequired />
                  <ItemList
                    value={state.subComponentNames}
                    onChange={updateNames}
                    disabled={isReadonly}
                    renderItemEditor={item => (
                      <TextField
                        value={item.value}
                        onChange={e =>
                          updateNames(
                            state.subComponentNames.map(l =>
                              l.id === item.id ? { id: l.id, value: e.target.value } : l,
                            ),
                          )
                        }
                        placeholder="Sub-component Name"
                        error={!!errors?.subComponentNamesValues?.[item.id]}
                        helperText={errors?.subComponentNamesValues?.[item.id]}
                        disabled={isReadonly}
                      />
                    )}
                  />
                  {!!errors?.subComponentNames && (
                    <FormHelperText error>{errors?.subComponentNames}</FormHelperText>
                  )}
                </div>
                <div className={classes.field}>
                  <ParameterHeader displayName="Unit" isRequired />
                  <Dropdown<string>
                    options={unitOptions}
                    valueLabel={state.unit ?? ''}
                    onChange={updateUnit}
                    placeholder="Select unit..."
                    hasError={!!errors?.unit}
                    helperText={errors?.unit}
                    isDisabled={isReadonly}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={classes.field}>
                  <ParameterHeader displayName="Factor Name" isRequired />
                  <TextField
                    value={state.factorName}
                    onChange={e => updateName(e.target.value)}
                    placeholder="Factor name"
                    error={!!errors?.name}
                    helperText={errors?.name}
                    disabled={isReadonly}
                  />
                </div>
                <div className={classes.field}>
                  <ParameterHeader displayName="Metadata Label" isRequired={false} />
                  {state.overrideMetadataLabel ? (
                    <TextField
                      value={state.metadataLabel}
                      onChange={e => updateMetdataLabel(e.target.value)}
                      placeholder="Metadata Label"
                      error={!!errors?.name}
                      helperText={errors?.name}
                      disabled={isReadonly}
                    />
                  ) : (
                    <Typography
                      component="output"
                      variant="body1"
                      className={classes.readout}
                      color={state.factorName === '' ? 'textSecondary' : 'textPrimary'}
                    >
                      {state.factorName === ''
                        ? 'Defaults to factor name'
                        : state.factorName}
                    </Typography>
                  )}
                  <label className={classes.overrideSwitch}>
                    <Switch
                      color="primary"
                      size="small"
                      checked={state.overrideMetadataLabel}
                      onChange={e => toggleMetadataLabelOverride(e.target.checked)}
                      disabled={isReadonly}
                    />
                    <Typography variant="body2">Override default</Typography>
                  </label>
                </div>
              </>
            )}
            <div className={classes.field}>
              <ParameterHeader displayName="Levels" isRequired />
              <ItemList
                value={state.levels}
                onChange={updateLevels}
                disabled={isReadonly}
                renderItemEditor={item => (
                  <TextField
                    value={item.value}
                    onChange={e =>
                      updateLevels(
                        state.levels.map(l =>
                          l.id === item.id ? { id: l.id, value: e.target.value } : l,
                        ),
                      )
                    }
                    placeholder="Level Value"
                    error={!!errors?.levelValues?.[item.id]}
                    helperText={errors?.levelValues?.[item.id]}
                    disabled={isReadonly}
                  />
                )}
              />
              {!!errors?.levels && (
                <FormHelperText error>{errors?.levels}</FormHelperText>
              )}
            </div>
          </>
        ) : (
          <ChooseFactor onChosen={updateType} />
        )}
      </main>
      <footer>
        <Button type="button" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="secondary"
          color="primary"
          disabled={!state.type || isReadonly}
        >
          {state.isNew ? 'Add' : 'Save'} Factor
        </Button>
      </footer>
    </form>
  );
}

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',

    '& > header': {
      padding: spacing(6),
      color: palette.primary.dark,
    },
    '& > main': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      gap: spacing(5),
      // this is necessary for overflow to scroll while the real size is defined by flexGrow
      height: '1px',
      overflowY: 'auto',
      padding: spacing(0, 6, 6),
    },
    '& > footer': {
      padding: spacing(5, 6),
      display: 'flex',
      gap: spacing(4),
      justifyContent: 'flex-end',
      borderTop: `1px solid ${palette.grey[300]}`,
    },
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
  },
  chooseFactor: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(4),
  },
  factorTypeButton: {
    border: `1px solid ${palette.grey['300']}`,
    padding: spacing(5),
    display: 'grid',
    gridTemplate: `'icon name' auto 'desc desc' auto / auto 1fr`,
    gap: spacing(3),
    borderRadius: spacing(2),
    background: 'transparent',
    cursor: 'pointer',
    textAlign: 'left',
    '&:hover': {
      background: Colors.BLUE_0,
    },
    alignItems: 'center',
  },
  factorTypeDescription: {
    gridArea: 'desc',
  },
  readout: {
    border: `1px solid ${palette.grey['400']}`,
    background: palette.grey['100'],
    padding: '9.5px 13px',
    borderRadius: '4px',
    lineHeight: '1.1876em',
  },
  overrideSwitch: {
    marginTop: spacing(3),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));
