import { useMemo } from 'react';

import { ServerSideBundle } from 'common/types/bundle';
import { hasDispenserDevice, hasManualDevice } from 'common/types/bundleConfigUtils';
import { ensureV3Config } from 'common/types/bundleTransforms';

export default function useManualOrDispenser(workflowBundle: ServerSideBundle) {
  return useMemo(() => {
    const workfowV3 = ensureV3Config(workflowBundle);
    const isManual = hasManualDevice(workfowV3.Config);
    const isDispenser = hasDispenserDevice(workfowV3.Config);

    return {
      isManual,
      isDispenser,
      isDispenserOrManual: isDispenser || isManual,
    };
  }, [workflowBundle]);
}
