import React, { useCallback, useState } from 'react';

import TableIcon from '@mui/icons-material/TableChartOutlined';
import Menu from '@mui/material/Menu';
import cx from 'classnames';

import SettingsPanelButton from 'client/app/apps/workflow-builder/panels/workflow-settings/SettingsPanelButton';
import * as xhr from 'client/app/lib/xhr';
import { downloadBase64EncodedFile } from 'common/lib/download';
import getFileBytes from 'common/lib/getFileBytes';
import { DirectUploadSingleValueLegacy } from 'common/types/fileParameter';
import { UploadInput } from 'common/types/filetree';
import DirectUploadItem from 'common/ui/components/DirectUploadItem';
import LinearProgress from 'common/ui/components/LinearProgress';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import DirectUploadEditor from 'common/ui/components/ParameterEditors/DirectUploadEditor';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useUUID from 'common/ui/hooks/useUUID';
import { TemplateFileIcon } from 'common/ui/icons/TemplateFileIcon';

const MIGRATE_ENDPOINT = '/web/migrate-policy';
const DEFAULT_POLICY_FILENAME = 'liquidHandling.xlsx';
const ACCEPTED_FILE_TYPES = ['.xls', '.xlsx'];

type Props = {
  isDisabled?: boolean;
  fileName?: string;
  file?: string;
  onChange: (newValue?: DirectUploadSingleValueLegacy) => void;
};

export default React.memo(function LHPolicyUploadEditor({
  isDisabled,
  onChange,
  file,
  fileName,
}: Props) {
  const classes = useStyles();
  const addFileButtonID = useUUID();
  const [uploadMenuAnchor, setUploadMenuAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  // Display a loading indicator while uploading a file from the computer.
  const [isUploading, setIsUploading] = useState(false);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUploadMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setUploadMenuAnchorEl(null);
  };

  const onClear = useCallback(() => {
    setUploadMenuAnchorEl(null);
    onChange(undefined);
  }, [onChange]);

  const handleUploadFromComputer = useCallback(
    value => {
      setUploadMenuAnchorEl(null);
      onChange(value);
    },
    [onChange],
  );

  const handleUpload = useCallback(async ({ file }: UploadInput) => {
    const contents = await getFileBytes(file);
    return {
      name: file.name || DEFAULT_POLICY_FILENAME,
      bytes: { bytes: btoa(contents) },
    };
  }, []);

  const download = usePolicyMigrateAndDownload();

  const value = file
    ? {
        name: fileName || DEFAULT_POLICY_FILENAME,
        bytes: { bytes: file },
      }
    : undefined;

  const handleDownloadTemplate = async () => {
    await download();
  };

  const handleDownloadFile = async () => {
    await download(value);
  };

  if (isDisabled && !file) {
    return <span>None selected</span>;
  }

  if (value) {
    return (
      <DirectUploadItem
        file={value}
        onDownload={handleDownloadFile}
        onRemove={onClear}
        isDisabled={isDisabled}
      />
    );
  } else {
    return (
      <>
        {isUploading && <LinearProgress className={classes.loadingBar} />}
        <SettingsPanelButton
          icon={<TableIcon />}
          onClick={handleOpenMenu}
          fullWidth
          disabled={isDisabled}
          selected={!!uploadMenuAnchor}
        >
          CustomPolicies.xlsx
        </SettingsPanelButton>

        <Menu
          id={addFileButtonID}
          anchorEl={uploadMenuAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          open={!!uploadMenuAnchor}
          onClose={handleCloseMenu}
          className={cx({ [classes.hidden]: isUploading })}
        >
          <MenuItemWithIcon
            onClick={handleDownloadTemplate}
            icon={<TemplateFileIcon />}
            text="Download Template"
          />
          <DirectUploadEditor
            value={null} // This is only displayed when there's no value
            isDisabled={isDisabled}
            acceptFileTypes={ACCEPTED_FILE_TYPES}
            renderType="MenuItem"
            onChange={handleUploadFromComputer}
            setIsUploading={setIsUploading}
            onUpload={handleUpload}
          />
        </Menu>
      </>
    );
  }
});

const useStyles = makeStylesHook({
  menuTitle: {
    margin: '4px 16px',
  },
  hidden: {
    display: 'none',
  },
  loadingBar: {
    margin: '16px 0',
  },
});

function usePolicyMigrateAndDownload() {
  const postJSON = xhr.usePostJSON();
  return useCallback(
    async (file?: DirectUploadSingleValueLegacy) => {
      const migratePolicyResponse: { file: string } = await postJSON(MIGRATE_ENDPOINT, {
        body: { file: file?.bytes.bytes ?? '' },
      });

      downloadBase64EncodedFile(
        migratePolicyResponse.file,
        file?.name || DEFAULT_POLICY_FILENAME,
      );
    },
    [postJSON],
  );
}
