import React, { useContext } from 'react';

import { styled } from '@mui/material/styles';

import ComputationResult from 'client/app/apps/simulation-details/overview/ComputationResult';
import { isComputationalSimulation } from 'client/app/apps/simulation-details/overview/isComputationalSimulation';
import FilesSidebar, {
  FilesSidebarSection,
} from 'client/app/apps/simulation-details/overview/LeftSidebar';
import ResourcesOverview from 'client/app/apps/simulation-details/overview/ResourcesOverview';
import SimulationErrorScreen from 'client/app/apps/simulation-details/overview/SimulationErrorScreen';
import { Simulation, SimulationOrExecutionStatusesEnum } from 'client/app/gql';
import { Deck } from 'common/types/mix';
import { PlateType } from 'common/types/plateType';
import { RouteHiddenContext } from 'common/ui/lib/router/RouteHiddenContext';

type Props = {
  simulation: Simulation;
  deck?: Deck;
  plateTypes: readonly PlateType[];
};

/** Sections to show in files sidebar if this is a liquid handling Simulation in the multi-device journey. */
const FILES_SIDEBAR_SECTIONS_LIQUID_HANDLING_MULTI_DEVICE = new Set<FilesSidebarSection>([
  'output_plates',
  'element_outputs',
  'reagents',
  'debugging',
]);
/** Sections to show in files sidebar if this is a computational Simulation. */
const FILES_SIDEBAR_SECTIONS_COMPUTATIONAL = new Set<FilesSidebarSection>([
  'element_outputs',
  'debugging',
]);

function ResourcesScreen({ simulation, deck, plateTypes }: Props) {
  const hiddenContext = useContext(RouteHiddenContext);
  if (hiddenContext.hidden) {
    // Don't unnecessarily render the large React tree when
    // we are on a different tab of the Simulation Details.
    return null;
  }

  let content: JSX.Element;

  /**
   * Users might be able to visit a simulation which was not
   *  finalised yet (T2950). Show them a meaningful error page.
   */
  if (simulation.transitiveStatus === SimulationOrExecutionStatusesEnum.UNINTERESTING) {
    content = (
      <SimulationErrorScreen
        heading="This simulation is not available at the moment."
        messageList={['Please refresh the page']}
        simulation={simulation}
      />
    );
  } else if (
    simulation.transitiveStatus === SimulationOrExecutionStatusesEnum.SIMULATION_FAILED
  ) {
    content = <SimulationErrorScreen simulation={simulation} />;
  } else {
    const isComputational = isComputationalSimulation(simulation.tasks);

    let overviewScreenContents: React.ReactNode;
    if (isComputational) {
      overviewScreenContents = <ComputationResult />;
    } else {
      overviewScreenContents = (
        <ResourcesOverview deck={deck} plateTypes={plateTypes} simulation={simulation} />
      );
    }

    content = (
      <>
        <FilesSidebar
          simulation={simulation}
          deck={deck}
          sections={
            isComputational
              ? FILES_SIDEBAR_SECTIONS_COMPUTATIONAL
              : FILES_SIDEBAR_SECTIONS_LIQUID_HANDLING_MULTI_DEVICE
          }
        />
        {overviewScreenContents}
      </>
    );
  }

  return <Screen>{content}</Screen>;
}

export default ResourcesScreen;

const Screen = styled('div')({
  display: 'grid',
  justifyContent: 'flex-start',
  gridTemplateColumns: 'auto 1fr',
  flex: 1,
  overflowY: 'scroll',
});
