import React from 'react';

import { styled } from '@mui/material/styles';

import { DispenseChecklist } from 'client/app/api/ChecklistApi';
import Colors from 'common/ui/Colors';

type Props = {
  dispenseChecklist: DispenseChecklist;
};

export default function ChecklistTable({ dispenseChecklist: checklist }: Props) {
  return (
    <Grid>
      <HeadRow>
        <HeadCell>Input plates</HeadCell>
        <HeadCell>Output plates</HeadCell>
        <HeadCell>Execution file</HeadCell>
      </HeadRow>
      {checklist?.dispense_list_steps.map((step, stepIndex) => (
        <Row key={stepIndex}>
          <Cell>
            <List>
              {step.source_plates.map((sourcePlate, plateIndex) => (
                <ListItem key={plateIndex}>{sourcePlate.name}</ListItem>
              ))}
            </List>
          </Cell>
          <Cell>
            <List>
              {step.destination_plates.map((destinationPlate, plateIndex) => (
                <ListItem key={plateIndex}>{destinationPlate.name}</ListItem>
              ))}
            </List>
          </Cell>
          <Cell>{step.dispense_list}</Cell>
        </Row>
      ))}
    </Grid>
  );
}

//#region Styles

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  paddingLeft: theme.spacing(7),

  rowGap: theme.spacing(5),

  counterReset: 'checklist',
}));

const Row = styled('div')(({ theme }) => ({
  display: 'inline-grid',
  gridTemplateColumns: 'repeat(2, minmax(100px, 1fr)) 2fr',
  columnGap: theme.spacing(3),

  position: 'relative',

  '&:before': {
    counterIncrement: 'checklist',
    content: 'counter(checklist)',

    position: 'absolute',
    top: -5,
    left: -32,
    height: 24,
    width: 24,

    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',

    backgroundColor: Colors.GREY_10,
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 500,

    borderRadius: '50%',
  },
}));

const HeadRow = styled(Row)({
  '&:before': {
    content: 'none',
  },
});

const Cell = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 12,
  fontWeight: 400,

  overflow: 'hidden',
  wordBreak: 'break-word',
}));

const HeadCell = styled(Cell)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const List = styled('dl')({
  margin: 0,
});

const ListItem = styled('dd')({
  margin: 0,
});

//#endregion
