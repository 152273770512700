import React, { ReactNode } from 'react';

import cx from 'classnames';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  className?: string;
  actions: ReactNode;
  children: ReactNode;
  onSubmit?: () => void;
  classes?: Partial<ReturnType<typeof useStyles>>;
};

const DOEForm = ({ className, onSubmit, actions, children, ...props }: Props) => {
  const classes = useStyles(props);

  return (
    <form
      className={cx(classes.form, className)}
      onSubmit={e => {
        e.preventDefault();
        onSubmit?.();
      }}
    >
      <div className={classes.bodyWrapper}>
        <div className={classes.body}>{children}</div>
      </div>
      <div className={classes.footer}>{actions}</div>
    </form>
  );
};

export default DOEForm;

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    boxShadow: '-1px 0px 4px 0px rgba(0, 0, 0, 0.05)',
    borderLeft: `1px solid ${palette.grey[200]}`,
    color: palette.text.primary,
    background: Colors.GREY_0,
  },
  bodyWrapper: {
    flex: 1,
    overflowY: 'auto',
    scrollbarGutter: 'stable',
  },
  body: {
    padding: spacing(6),
  },
  footer: {
    display: 'flex',
    padding: spacing(5),
    borderTop: `1px solid ${palette.grey[200]}`,
    gap: spacing(4),
    justifyContent: 'end',
  },
}));
