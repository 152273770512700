import { useQuery } from '@apollo/client';
import { useQueryParam } from 'use-query-params';

import {
  QUERY_EXPERIMENTS_INFO,
  QUERY_LATEST_EXPERIMENT_WORKTREE_FOR_SIMULATION,
} from 'client/app/apps/experiments/gql/queries';
import { EXPERIMENT_SOURCE_PARAM } from 'client/app/components/nav/CreateNewExperimentButton';
import { SimulationQuery } from 'client/app/gql';

/**
 * If the user has navigated from an experiment (i.e. there is an experiment query param in the URL)
 * then this hook will return the name of that experiment.
 *
 * If there is no experiment query param in the URL, we fall back to the given simulation and return
 * the name of the last modified experiment that includes that simulation
 *
 * If no experiments are found, we return undefined.
 */
export default function useLatestExperimentName(
  simulation: SimulationQuery['simulation'],
) {
  const [sourceExperimentId] = useQueryParam<ExperimentId | undefined>(
    EXPERIMENT_SOURCE_PARAM,
  );
  const {
    data: experimentInfo,
    loading: experimentInfoLoading,
    error: experimentInfoError,
  } = useQuery(QUERY_EXPERIMENTS_INFO, {
    variables: sourceExperimentId ? { experimentIds: [sourceExperimentId] } : {},
    skip: !sourceExperimentId,
  });

  const {
    data: latestExperiment,
    loading: latestExperimentLoading,
    error: latestExperimentError,
  } = useQuery(QUERY_LATEST_EXPERIMENT_WORKTREE_FOR_SIMULATION, {
    variables: { simulationId: simulation.id },
    skip: !!sourceExperimentId,
  });

  return {
    experimentName: sourceExperimentId
      ? experimentInfo?.experiments.items[0].name
      : latestExperiment?.latestExperimentWorktreeForSimulation?.name,
    loading: experimentInfoLoading || latestExperimentLoading,
    error: experimentInfoError || latestExperimentError,
  };
}
