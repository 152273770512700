import { useMutation, useQuery } from '@apollo/client';

import { getResultOrThrow } from 'client/app/api/apolloClient';
import {
  CREATE_PROTOCOL_INSTANCE,
  UPDATE_PROTOCOL_INSTANCE,
} from 'client/app/api/gql/mutations';
import { QUERY_PROTOCOL_INSTANCE } from 'client/app/api/gql/queries';
import { CreateProtocolInstanceMutation, ProtocolInstanceQuery } from 'client/app/gql';
import { protocolsRoutes } from 'client/app/lib/nav/actions';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { ParameterValue } from 'common/types/bundle';
import { getElementId, getElementParameterName } from 'common/types/schema';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';

/**
 * Queries a protocol instance.
 *
 * @returns Protocol instance and loading state.
 */
export function useQueryProtocolInstance(protocolInstanceId: ProtocolInstanceId) {
  const { data, loading, error } = useQuery(QUERY_PROTOCOL_INSTANCE, {
    variables: { id: protocolInstanceId },
  });
  return { data, loading, error };
}

/**
 * Creates a new protocol instance from an existing protocol.
 *
 * @returns Handler for creating protocol instance and loading state.
 */
export function useCreateProtocolInstance() {
  const [createProtocolInstance, { loading }] = useMutation(CREATE_PROTOCOL_INSTANCE);
  const handleCreateProtocolInstance = async (
    protocolId: ProtocolId,
    protocolVersion: ProtocolVersion,
  ): Promise<CreateProtocolInstanceMutation['createProtocolInstance']> => {
    const updateResult = await createProtocolInstance({
      variables: {
        input: {
          protocolId: protocolId,
          protocolVersion: protocolVersion,
        },
      },
    });

    return getResultOrThrow(
      updateResult,
      'Create protocol instance',
      data => data.createProtocolInstance,
    );
  };

  return { handleCreateProtocolInstance, loading };
}

/**
 * Creates a new protocol instance from an existing protocol and navigates
 * to the editing route for that new protocol instance.
 *
 * @returns Handler for creating protocol instance and loading state.
 */
export function useCreateProtocolInstanceAndNavigate() {
  const { handleCreateProtocolInstance, loading } = useCreateProtocolInstance();
  const { navigate } = useNavigation();
  const handleCreateProtocolInstanceAndNavigate = async (
    protocolId: ProtocolId,
    protocolVersion: ProtocolVersion,
  ) => {
    const updateResult = await handleCreateProtocolInstance(protocolId, protocolVersion);
    if (updateResult?.protocolInstance) {
      navigate(protocolsRoutes.editProtocolInstance, {
        id: updateResult.protocolInstance.id,
      });
    }
  };

  return { handleCreateProtocolInstanceAndNavigate, loading };
}

/**
 * Updates the protocol instance with the most recent parameters.
 * Uses WorkflowBuilderStateContext to retrieve parameters.
 *
 * @returns Handler for updating protocol instance and loading state.
 */
export function useUpdateProtocolInstance() {
  const dispatch = useWorkflowBuilderDispatch();
  const parameters = useWorkflowBuilderSelector(state => state.parameters);
  const elementInstances = useWorkflowBuilderSelector(state => state.elementInstances);

  const [updateProtocolInstance, { loading }] = useMutation(UPDATE_PROTOCOL_INSTANCE);
  const handleUpdateProtocolInstance = async (
    protocolInstance: NonNullable<ProtocolInstanceQuery['protocolInstance']['instance']>,
  ) => {
    const updatedProtocolParams: { [inputId: string]: ParameterValue } = {};
    const workflowSchema = protocolInstance.protocol.workflow.workflow.Schema;

    workflowSchema?.inputs?.forEach(input => {
      const elementInstanceId = getElementId(input.path);
      const parameterName = getElementParameterName(input.path);
      const elementInstanceName = elementInstances.find(
        elementInstance => elementInstance.Id === elementInstanceId,
      )?.name;
      if (elementInstanceName && parameterName) {
        const paramValue = parameters[elementInstanceName][parameterName];
        updatedProtocolParams[input.id] = paramValue;
      }
    });

    const updateResult = await updateProtocolInstance({
      variables: {
        input: {
          id: protocolInstance.id,
          name: protocolInstance.name,
          editVersion: protocolInstance.editVersion,
          params: updatedProtocolParams,
        },
      },
    });

    const updatedProtocolInstance = getResultOrThrow(
      updateResult,
      'Update protocol instance',
      data => data.updateProtocolInstance,
    );

    const elementContextMap = updatedProtocolInstance.elementContextMap;
    const elementContextError = updatedProtocolInstance.elementContextError;

    if (elementContextMap) {
      dispatch({ type: 'updateElementsWithContexts', payload: elementContextMap });
    }
    if (elementContextError) {
      dispatch({ type: 'setElementContextError', payload: elementContextError });
    }
  };

  return { handleUpdateProtocolInstance, loading };
}
