import { WellLocationOnDeckItem } from 'common/types/mix';
import {
  isLiquidMovementEdge,
  MixState,
} from 'common/ui/components/simulation-details/mix/MixState';

/**
 * This graph is used to determine what edges to show when a well is selected. The graph
 * includes all edges up to the current step.
 */
type GraphVertex = {
  location: WellLocationOnDeckItem;
  /**
   * Going from this vertex to another vertex (standard representation of a directed
   * graph). Used for walking edges forwards transitively (see algorithm below). Using a
   * Set to avoid storing duplicate edges (these are common in real workflows).
   */
  outgoingEdges: Set<GraphVertex>;
  /**
   * Incoming from other vertices into this vertex. Used for walking edges backwards
   * transitively (see algorithm below). Using a Set to avoid storing duplicate edges
   * (these are common in real workflows).
   */
  incomingEdges: Set<GraphVertex>;
};

/**
 * Creates a SimulationStateGraph populated with all liquid transfers up to the current
 * step in the simulation.
 */
export function buildSimulationStateGraph(state: MixState) {
  const verticesByLocation: Map<string, GraphVertex> = new Map();
  for (const edge of state.edges) {
    // We only wish to show liquid handling edges
    if (!isLiquidMovementEdge(edge)) {
      continue;
    }
    const fromVertex = getVertex(edge.action.from.loc, verticesByLocation);
    const tipDestVertex = getVertex(edge.action.tipDestination.loc, verticesByLocation);
    fromVertex.outgoingEdges.add(tipDestVertex);
    tipDestVertex.incomingEdges.add(fromVertex);

    // If liquid is dispensed into a RoboColumn, then the tip destination (the RoboColumn)
    // will be different to the liquid destination (the well that liquids drips from the
    // RoboColumn into).
    if (
      hashWellLocation(edge.action.tipDestination.loc) !==
      hashWellLocation(edge.action.liquidDestination.loc)
    ) {
      const liqDestVertex = getVertex(
        edge.action.liquidDestination.loc,
        verticesByLocation,
      );
      tipDestVertex.outgoingEdges.add(liqDestVertex);
      liqDestVertex.incomingEdges.add(tipDestVertex);
    }
  }
  return new SimulationStateGraph(verticesByLocation);
}

function getVertex(
  loc: WellLocationOnDeckItem,
  verticesByLocation: Map<string, GraphVertex>,
) {
  const locHash = hashWellLocation(loc);
  const existingVertex = verticesByLocation.get(locHash);
  if (existingVertex) {
    return existingVertex;
  }
  // Vertex for this location (well) doesn't exist yet, create a new one
  const newVertex: GraphVertex = {
    location: loc,
    incomingEdges: new Set(),
    outgoingEdges: new Set(),
  };
  verticesByLocation.set(locHash, newVertex);
  return newVertex;
}

// For looking up a vertex based on position
function hashWellLocation(loc: WellLocationOnDeckItem) {
  return `${loc.deck_item_id}:${loc.row}:${loc.col}`;
}

type GraphWalkDirection = 'forward' | 'back';

// Very lightweight way to store an edge. The edge representation in MixState
// is much more full-featured and tailored for being displayed in the UI.
// This edge is just a helper data structure for the algorithm below.
type SimpleEdge = {
  from: WellLocationOnDeckItem;
  to: WellLocationOnDeckItem;
};

/**
 * Used to compute all liquid transfers (edges) through some given wells
 * (WellLocationOnDecks). Should be initialised with a set of vertices and edges that
 * describes all liquid transfers in the simulation up to the current step the user is
 * viewing.
 */
export class SimulationStateGraph {
  // verticesByLocation is a field initialised in the constructor
  constructor(private verticesByLocation: Map<string, GraphVertex>) {}

  /**
   * Given a list of selected wells, return all edges that transitively flow through any
   * of those wells.
   */
  getSubgraphForLocations(locations: readonly WellLocationOnDeckItem[]): EdgeSet {
    const subgraphEdges: SimpleEdge[] = [];
    for (const loc of locations) {
      const vertex = this.verticesByLocation.get(hashWellLocation(loc));
      if (!vertex) {
        // This well never gets touched by the liquid handler. Just skip it.
        continue;
      }
      // Transitive subgraph starting at this well and following outgoing edges
      this.fillSubgraphForSingleLocationRecursive(
        vertex.location,
        new Set<GraphVertex>(),
        subgraphEdges,
        'forward',
      );
      // Transitive subgraph starting at this well and following incoming edges
      this.fillSubgraphForSingleLocationRecursive(
        vertex.location,
        new Set<GraphVertex>(),
        subgraphEdges,
        'back',
      );
    }
    // The caller only cares about well locations so only return those.
    // The caller shouldn't have to know anything about the GraphVertex type,
    // which is an implementation detail inside this file.
    return new EdgeSet(subgraphEdges);
  }

  /**
   * Given a single selected well, return all edges that transitively flow through that
   * well.
   */
  private fillSubgraphForSingleLocationRecursive(
    loc: WellLocationOnDeckItem,
    visited: Set<GraphVertex>,
    subgraphEdges: SimpleEdge[],
    direction: GraphWalkDirection,
  ) {
    const vertex = this.verticesByLocation.get(hashWellLocation(loc));
    if (!vertex) {
      throw new Error('Unknown location: ' + hashWellLocation(loc));
    }
    // We're visiting this vertex - include it in the subgraph
    visited.add(vertex);
    // Follow edges in one direction
    const neighbouringVertices =
      direction === 'forward' ? vertex.outgoingEdges : vertex.incomingEdges;
    for (const outVertex of neighbouringVertices) {
      if (direction === 'forward') {
        subgraphEdges.push({ from: vertex.location, to: outVertex.location });
      } else {
        // Store the edge with the original direction.
        // The fact we're walking the graph backwards against the edge
        // direction is a separate concern that doesn't affect the actual edge.
        subgraphEdges.push({ from: outVertex.location, to: vertex.location });
      }
      if (visited.has(outVertex)) {
        // Neighbor already visited. This means we found a loop.
        continue;
      }
      this.fillSubgraphForSingleLocationRecursive(
        outVertex.location,
        visited,
        subgraphEdges,
        direction,
      );
    }
  }
}

// A collection of directed edges, stored to allow O(1) lookup for the query
// "is an edge in the graph"?
class EdgeSet {
  // Use a Set for fast lookups
  private hashedEdges: Set<String>;

  constructor(edges: SimpleEdge[]) {
    this.hashedEdges = new Set(edges.map(e => this.hashEdge(e.from, e.to)));
  }

  private hashEdge(from: WellLocationOnDeckItem, to: WellLocationOnDeckItem): string {
    return `${hashWellLocation(from)}-${hashWellLocation(to)}`;
  }

  // Check if an edge is present in the graph.
  // The order of arguments matters. It can happen that the edge A->B is in
  // the graph, and the edge B->A is not.
  containsEdge(locationFrom: WellLocationOnDeckItem, locationTo: WellLocationOnDeckItem) {
    return this.hashedEdges.has(this.hashEdge(locationFrom, locationTo));
  }
}
