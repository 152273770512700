import React from 'react';

import { QueryResult } from '@apollo/client';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import Feedback from 'client/app/components/DeviceLibrary/DeviceConfigurationValidation/DeckLayout/Feedback';
import ValidationDeckLayout from 'client/app/components/DeviceLibrary/DeviceConfigurationValidation/DeckLayout/ValidationDeckLayout';
import {
  ParsedDeckLayoutConfigListQuery,
  ParsedDeckLayoutConfigListQueryVariables,
} from 'client/app/gql';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';

export type ParsedDeckLayoutConfigListQueryType = QueryResult<
  ParsedDeckLayoutConfigListQuery,
  ParsedDeckLayoutConfigListQueryVariables
>;

type Props = {
  queryResult: ParsedDeckLayoutConfigListQueryType;
};

export default function DeckLayoutTab({
  queryResult: { data, loading, error, refetch },
}: Props) {
  const layouts = data?.parsedDeckLayoutConfigList;

  if (loading || !layouts) {
    return <Loading />;
  }
  if (error) {
    return <GraphQLErrorPanel error={error} onRetry={refetch} />;
  }

  return (
    <Container>
      <Feedback deckLayoutConfigList={layouts} />
      {layouts.length > 0 && <ValidationDeckLayout deckLayoutConfigList={layouts} />}
    </Container>
  );
}

const Container = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(6),
  gap: theme.spacing(6),
  width: '100%',
  height: '100%',
  overflow: 'auto',
}));

const Loading = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));
