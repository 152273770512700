import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import LayersIcon from '@mui/icons-material/Layers';
import LockIcon from '@mui/icons-material/Lock';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Liquid from 'client/app/components/Parameters/PlateLayout/Liquid';
import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import {
  formatLayerAutomaticName,
  useDeleteLayerOrLiquid,
} from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import {
  LiquidAssignment,
  PlateAssignmentMode,
  PlateLayer,
} from 'common/types/plateAssignments';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import {
  DraggableList,
  DragHandleProps,
} from 'common/ui/components/DragDrop/DraggableList';
import IconButton from 'common/ui/components/IconButton';
import Tooltip from 'common/ui/components/Tooltip';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type LayerProps = {
  layer: PlateLayer;
  dragProps: DragHandleProps;
};

export default function Layer(props: LayerProps) {
  const { layer, dragProps } = props;

  const {
    isReadonly: isGlobalReadonly,
    selectedLiquidOrLayerId,
    setFocusedLayerId,
    focusedLayerId,
    plateLayers,
    setSelectedLiquidOrLayerId,
    reorderLiquidAssignments,
    addNewLiquidAssignment,
    selectedWells,
    plateAssignment,
  } = usePlateLayoutEditorContext();

  const { handleDelete, confirmDeleteDialog } = useDeleteLayerOrLiquid();
  const layerName = formatLayerAutomaticName(layer.id, plateLayers);
  const isOnlyLayer = plateLayers.length === 1;

  const handleDeleteLayer = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await handleDelete(layer.id, 'layer');
  };

  const [isHovering, setIsHovering] = useState(false);

  const isLocked = layer.isReadOnly || isGlobalReadonly;

  const enableHoverFunctionality = isHovering && !isLocked;

  const focused = focusedLayerId === layer.id;

  const selected = selectedLiquidOrLayerId === layer.id;

  const handleReorderLiquids = (reorderedLiquidAssignments: LiquidAssignment[]) => {
    reorderLiquidAssignments(layer.id, reorderedLiquidAssignments);
  };

  return (
    <ListWrapper onClick={() => setFocusedLayerId(layer.id)}>
      <StyledListItemButton
        dense
        focused={focused}
        selected={selected}
        isHovering={enableHoverFunctionality}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={() => focused && setSelectedLiquidOrLayerId(layer.id)}
      >
        {!isLocked && (
          <StyledDragIcon {...dragProps.attributes} {...dragProps.listeners}>
            {dragProps.dragIcon}
          </StyledDragIcon>
        )}
        <StyledLayersIcon selected={selected} focused={focused} />
        <TypographyWithTooltip variant={selected || focused ? 'subtitle2' : 'body1'}>
          {layerName}
        </TypographyWithTooltip>
        <StyledEndIconsWrapper>
          {enableHoverFunctionality ? (
            isOnlyLayer ? (
              <Tooltip title="At least one layer must be defined">
                <span>
                  <IconButton
                    onClick={handleDeleteLayer}
                    icon={<CloseIcon />}
                    size="xsmall"
                    disabled
                    color="error"
                  />
                </span>
              </Tooltip>
            ) : (
              <IconButton
                onClick={handleDeleteLayer}
                icon={<CloseIcon color="error" />}
                size="xsmall"
                color="error"
              />
            )
          ) : isLocked ? (
            <StyledLockIcon />
          ) : null}
        </StyledEndIconsWrapper>
      </StyledListItemButton>

      <StyledList disablePadding>
        {layer.liquids.length === 0 && !focused && (
          <EmptyListText
            showBorder={focused}
            onClick={() => setFocusedLayerId(layer.id)}
            allowClick={selectedLiquidOrLayerId !== layer.id}
            variant="body2"
          >
            Empty Layer
          </EmptyListText>
        )}
        {layer.isReadOnly ? (
          layer.liquids.map(liquid => (
            <Liquid
              layerId={layer.id}
              key={liquid.wellSetID}
              liquidAssignment={liquid}
              parentFocused={focused}
              isReadonly={isLocked}
              dragProps={dragProps}
            />
          ))
        ) : (
          <DraggableList
            items={layer.liquids}
            getIdFromItem={liquid => liquid.wellSetID}
            onChangeOrder={handleReorderLiquids}
            renderItem={(liquid, dragProps) => (
              <Liquid
                layerId={layer.id}
                key={liquid.wellSetID}
                liquidAssignment={liquid}
                parentFocused={focused}
                isReadonly={isLocked}
                dragProps={dragProps}
              />
            )}
          />
        )}
        {focused && (
          <AddLiquidWrapper>
            <Tooltip
              title={
                plateAssignment.assignmentMode === PlateAssignmentMode.DESCRIPTIVE &&
                selectedWells.length === 0
                  ? 'Select wells to add a liquid'
                  : ''
              }
              placement="bottom"
            >
              <AddLiquidTooltipWrapper>
                <Button
                  onClick={() => addNewLiquidAssignment()}
                  variant="tertiary"
                  color="primary"
                  size="small"
                  startIcon={<AddIcon />}
                  disabled={
                    isLocked ||
                    (plateAssignment.assignmentMode === PlateAssignmentMode.DESCRIPTIVE &&
                      selectedWells.length === 0)
                  }
                >
                  Add Liquid
                </Button>
              </AddLiquidTooltipWrapper>
            </Tooltip>
          </AddLiquidWrapper>
        )}
      </StyledList>
      {confirmDeleteDialog}
    </ListWrapper>
  );
}

const ListWrapper = styled('div')({
  '&:hover': {
    background: Colors.GREY_10,
    cursor: 'pointer',
  },
});

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: propName =>
    propName !== 'focused' && propName !== 'selected' && propName !== 'isHovering',
})<{ focused: boolean; selected: boolean; isHovering: boolean }>(
  ({ theme: { palette }, focused, selected, isHovering }) => ({
    backgroundColor: selected ? Colors.BLUE_5 : 'default',
    '&:hover': {
      backgroundColor: selected ? Colors.BLUE_5 : 'transparent',
    },
    padding: isHovering ? '5px 12px 5px 12px' : '8px 12px 8px 12px',
    gap: '6px',
    boxShadow: focused || selected ? `inset 3px 0 0 0 ${palette.primary.main}` : 'none',
    '& .MuiTypography-root': {
      color: selected ? palette.primary.main : palette.text.primary,
      marginTop: 0,
      marginBottom: 0,
    },
  }),
);

const StyledEndIconsWrapper = styled('div')({
  display: 'flex',
  marginLeft: 'auto',
  alignItems: 'center',
});

const StyledList = styled(List)({
  display: 'flex',
  flexDirection: 'column',
});

const StyledDragIcon = styled('span')(({ theme }) => ({
  '& > svg': {
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
}));

const StyledLayersIcon = styled(LayersIcon, {
  shouldForwardProp: prop => prop !== 'selected' && prop !== 'focused',
})<{ selected: boolean; focused: boolean }>(({ theme, selected, focused }) => ({
  color: selected
    ? theme.palette.primary.main
    : focused
    ? theme.palette.text.secondary
    : theme.palette.grey[400],
  fontSize: '16px',
  marginLeft: '-2px',
}));

const EmptyListText = styled(Typography, {
  shouldForwardProp: propName => propName !== 'showBorder' && propName !== 'allowClick',
})<{ showBorder: boolean; allowClick: boolean }>(
  ({ theme: { palette }, showBorder, allowClick }) => ({
    color: palette.text.disabled,
    padding: '6px 16px 6px 48px;',
    boxShadow: showBorder ? `inset 3px 0 0 0 ${palette.primary.main}` : 'none',
    marginTop: 0,
    marginBottom: 0,
    cursor: allowClick ? 'pointer' : 'default',
  }),
);

const StyledLockIcon = styled(LockIcon)({
  fontSize: '16px',
});

const AddLiquidWrapper = styled('div')(({ theme: { palette } }) => ({
  boxShadow: `inset 3px 0 0 0 ${palette.primary.main}`,
  display: 'grid',
  placeItems: 'stretch',
}));

const AddLiquidTooltipWrapper = styled('div')({
  display: 'grid',
  placeItems: 'stretch',
});
