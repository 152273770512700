import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export default React.memo(function GenericDeviceParserIcon(
  props: React.PropsWithChildren<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>>,
) {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5317 14C20.5317 15.2426 19.5244 16.25 18.2817 16.25C17.0391 16.25 16.0317 15.2426 16.0317 14C16.0317 12.7573 17.0391 11.75 18.2817 11.75C19.5244 11.75 20.5317 12.7573 20.5317 14ZM22.5317 14C22.5317 16.3472 20.6289 18.25 18.2817 18.25C17.3049 18.25 16.405 17.9204 15.6873 17.3664L15.5321 17.5215L15.5321 18.2286L14.1207 16.8172L14.825 16.8144L14.825 16.8144L14.9725 16.667C14.3841 15.9377 14.0317 15.01 14.0317 14C14.0317 11.6528 15.9345 9.74997 18.2817 9.74997C20.6289 9.74997 22.5317 11.6528 22.5317 14ZM15.5321 18.2286L14.1179 16.8144L10.2288 20.7035L11.643 22.1177L15.5321 18.2286Z"
          fill="currentColor"
        />
        <rect x="6" y="11" width="2" height="5" fill="currentColor" />
        <rect x="10" y="8" width="2" height="8" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 6H14V10.5492C14.5338 9.88772 15.2177 9.35247 16 8.99523V6Z"
          fill="currentColor"
        />
        <path
          d="M7.5 19H5C3.89543 19 3 18.1046 3 17V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V7V8.14286"
          stroke="currentColor"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
});
