import React, { useContext } from 'react';

import { AppPermission } from 'common/auth/permissions';
import {
  createPermissionsProvider,
  DEFAULT_CONTEXT,
  PermissionsContext,
} from 'common/ui/PermissionsProvider';

const PermissionContext = React.createContext<PermissionsContext<AppPermission>>(
  DEFAULT_CONTEXT as PermissionsContext<AppPermission>,
);
PermissionContext.displayName = 'PermissionContext';

export function usePermissions() {
  return useContext(PermissionContext);
}

export const PermissionsProvider = createPermissionsProvider(PermissionContext);
