import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import BasePlateContentsEditorWellGroupList, {
  BasePlateContentsEditorWellGroupListProps,
} from 'client/app/components/Parameters/PlateContents/BasePlateContentsEditorWellGroupList';
import { WellParametersProps } from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import toWellLocationsOnDeckItem from 'client/app/components/Parameters/WellSelector/toWellLocationsOnDeckItem';
import WellSelector, {
  WellSelectionProps,
} from 'client/app/components/WellSelector/WellSelector';
import {
  formatWellPosition,
  getColumnNumberFromWellPosition,
  getRowNumberFromWellPosition,
} from 'common/lib/format';
import { WellContents, WellLocationOnDeckItem } from 'common/types/mix';
import { PlateType } from 'common/types/plateType';
import Button from 'common/ui/components/Button';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { getLayoutForWellSelector } from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import makeWellSelector from 'common/ui/components/simulation-details/PlateTransform';
import Dropdown, { Option } from 'common/ui/filaments/Dropdown';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

export type PlateOption = {
  label: string;
  plate: PlateType;
};

type Props<T> = {
  value: Record<string, T> | undefined;
  dialogTitle: string;
  /**
   * Text to show at the top of the dialog
   */
  helpText: string;
  /**
   * Plates user can pick from in the dropdown
   */
  availablePlates?: PlateOption[];
  selectedPlate: PlateOption;
  /**
   * Content to appear below the plate, e.g. to allow picking how wells should
   * be colored.
   */
  contentUnderPlate?: JSX.Element;
  /**
   * Used to determine well colour and volume text displayed within the
   * visualised plate.
   */
  generateWellInfo: (wellContents: T) => WellContents;
  /**
   * Callback which generates the component on the right hand side of the
   * dialog, which should contain fields for modifying currently selected wells.
   */
  wellParameters: (params: WellParametersProps<T>) => JSX.Element;
  showWellLabels?: boolean;
  liquidColors: LiquidColors;
  /**
   * Fires when user changes plate in the dropdown
   */
  onPlateChange?: (option: PlateOption) => void;
  disabledWells?: string[];
} & DialogProps<Record<string, T> | undefined> &
  Pick<
    BasePlateContentsEditorWellGroupListProps<T>,
    'groupID' | 'groupProps' | 'generateWellContents'
  >;

/**
 * A dialog which lets a user select wells of a plate and specify details about
 * those wells. This dialog is customizable, in that the component that allows
 * modification of the selected wells can be provided by the wellParameters prop.
 * For examples, see ChromatographyActionsDialog and RoboColumnLayoutDialog which
 * provide custom components for this.
 */
export default function BasePlateContentsEditorDialog<T>({
  dialogTitle,
  helpText,
  wellParameters,
  isOpen,
  onClose,
  value,
  availablePlates,
  selectedPlate,
  onPlateChange,
  showWellLabels,
  contentUnderPlate,
  liquidColors,
  disabledWells,
  isDisabled,
  groupID,
  groupProps,
  generateWellContents,
  generateWellInfo,
}: Props<T>) {
  const classes = useStyles();
  const { screenId } = useContext(ScreenContext);
  const [selectedWells, setSelectedWells] = useState<string[]>([]);

  // Work on a copy of the value. This will be committed when the dialog is
  // closed. It's also helpful to use a Map rather than object, so we make that
  // conversion.
  const [contentsByWell, setContentsByWell] = useState<Map<string, T>>(new Map());

  // Reset the selection when dialog is closed/opened or when the layout changes
  useEffect(() => setSelectedWells([]), [isOpen, contentsByWell]);

  // Update the plate contents if it is changed from outside the dialog (ie
  // pressing the clear button in the parameter pane).
  useEffect(() => setContentsByWell(new Map(Object.entries(value || {}))), [value]);

  // Create a dummy plate to render, within which we populate the contents
  const plate = useMemo<PlateState>(() => {
    const plate = makeWellSelector(selectedPlate.plate);
    plate.contents = {};
    for (const [well, contents] of contentsByWell) {
      const row = getRowNumberFromWellPosition(well);
      const col = getColumnNumberFromWellPosition(well);
      if (!plate.contents[col]) {
        plate.contents[col] = {};
      }
      plate.contents[col][row] = generateWellInfo(contents);
    }
    return plate;
  }, [selectedPlate, contentsByWell, generateWellInfo]);

  const handleClearSelection = useCallback(() => setSelectedWells([]), []);

  const handleSetSelectedWells = useCallback(
    (newSelectedWells: readonly WellLocationOnDeckItem[] | undefined) => {
      if (!newSelectedWells) {
        return;
      }
      // Take a list of DeckPosition and turn it into a string[], which is what
      // the parameter editor for well positions accepts.
      setSelectedWells(newSelectedWells.map(formatWellPosition));
    },
    [],
  );
  // Return the layout, unless no wells have been configured (in which case
  // return undefined)
  const handleClose = useCallback(() => {
    if (!contentsByWell.size) {
      onClose(undefined);
      return;
    }
    // Convert Map to an object ready for putting into workflow json
    const newValue: Record<string, T> = {};
    for (const [wellLocation, actions] of contentsByWell) {
      newValue[wellLocation] = actions;
    }
    onClose(newValue);
  }, [contentsByWell, onClose]);

  const handlePlateChange = useCallback(
    (value?: PlateType) => {
      const option = availablePlates?.find(o => o.plate === value);
      if (!option) {
        return;
      }
      onPlateChange?.(option);
    },
    [onPlateChange, availablePlates],
  );

  const plateDropdownOptions = useMemo<Option<PlateType>[] | undefined>(
    () => availablePlates?.map(({ label, plate }) => ({ label, value: plate })),
    [availablePlates],
  );

  const wellSelectionProps = useMemo<WellSelectionProps>(
    () => ({
      selectedWells: toWellLocationsOnDeckItem(selectedWells, plate),
      onSelectWells: handleSetSelectedWells,
      disabledWells: toWellLocationsOnDeckItem(disabledWells || [], plate),
    }),
    [disabledWells, handleSetSelectedWells, plate, selectedWells],
  );

  // Convert the plate to a deck layout suitable for the WellSelector
  const deckLayout = useMemo(() => getLayoutForWellSelector(plate), [plate]);

  // Only show help if the user has not yet set up any wells
  const showHelp = useMemo(
    () => selectedWells.length === 0 && contentsByWell.size === 0,
    [contentsByWell, selectedWells.length],
  );

  // Override the Paper style within the Dialog to set a fixed width
  const dialogClasses = useMemo(() => ({ paper: classes.paper }), [classes.paper]);
  return (
    <Dialog open={isOpen} maxWidth="md" onClose={handleClose} classes={dialogClasses}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={5} className={classes.dialogContentGrid}>
          <Grid item className={classes.plateContainer}>
            {plateDropdownOptions && (
              <Box mt={2} mr={2}>
                <Dropdown
                  valueLabel={selectedPlate.label}
                  options={plateDropdownOptions}
                  onChange={handlePlateChange}
                  isDisabled={isDisabled}
                />
              </Box>
            )}
            <Box mt={2}>
              <Button
                variant="tertiary"
                onClick={handleClearSelection}
                disabled={selectedWells.length === 0}
              >
                Clear Selection
              </Button>
            </Box>
            <Box mt={2}>
              <WellSelector
                wellSelectionProps={wellSelectionProps}
                deckLayout={deckLayout}
                plate={plate}
                liquidColors={liquidColors}
                googleAnalyticsCategory={screenId as string}
                showContentLabels={showWellLabels}
              />
            </Box>
            <Box mt={2}>{contentUnderPlate}</Box>
          </Grid>
          <Grid item xs className={classes.plateSummary}>
            {showHelp ? (
              <Typography>{helpText}</Typography>
            ) : (
              <BasePlateContentsEditorWellGroupList
                contentsByWell={contentsByWell}
                selectedWells={selectedWells}
                groupID={groupID}
                groupProps={groupProps}
                isDisabled={isDisabled}
                generateWellContents={generateWellContents}
                wellParameters={wellParameters}
                onChange={setContentsByWell}
                onSelectionChange={setSelectedWells}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" color="primary" onClick={handleClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStylesHook({
  paper: {
    // Manually define a width for the dialog to fit the plate
    width: '800px',
    // Should be tall enough such that the dialog does not resize when the well
    // parameters show
    height: '640px',
  },
  dialogContentGrid: { height: '100%' },
  // The plate is a static size (just under 400px), so fix the width of the
  // left side of the dialog.
  plateContainer: { width: '400px', height: '100%' },
  plateSummary: { height: '100%' },
});
