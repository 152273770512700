import React, { useContext, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import { removeCommonPrefixes } from 'client/app/lib/antha-lang/identifiers';
import { renderParameterDescription } from 'client/app/lib/workflow/format';
import { Markdown } from 'common/lib/markdown';
import Colors from 'common/ui/Colors';
import { MarkdownPreview } from 'common/ui/components/MarkdownPreview';
import UIBox from 'common/ui/components/UIBox';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  name: string;
  displayName?: string;
  type: string;
  description: string;
  markdownDescription?: Markdown;
};

export default function ConfigParameterHeader(props: Props) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const { screenId } = useContext(ScreenContext);

  const { name, displayName, description, markdownDescription } = props;
  const type = removeCommonPrefixes(props.type);

  const expand = () => {
    logEvent('expand-settings-header', screenId as string, name);
    setIsExpanded(true);
  };

  const collapse = () => {
    logEvent('collapse-settings-header', screenId as string, name);
    setIsExpanded(false);
  };

  return (
    <>
      <div className={classes.label} onClick={isExpanded ? collapse : expand}>
        <div className={classes.paramName}>{displayName ?? name}</div>
        <div className={classes.expandIcon}>
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
      </div>
      {isExpanded && (
        <div className={classes.paramDescription}>
          <UIBox padding="m" margin="bm">
            <UIBox padding="bm">
              <em className={classes.paramType}>{type}</em>
            </UIBox>
            {markdownDescription ? (
              <MarkdownPreview
                className={classes.paramDescriptionText}
                markdown={markdownDescription}
              />
            ) : (
              <>{renderParameterDescription(description)}</>
            )}
          </UIBox>
        </div>
      )}
    </>
  );
}

const useStyles = makeStylesHook({
  expandIcon: {
    color: Colors.GREY_40,
    position: 'absolute',
    right: '0',
    top: 'calc(-0.5em + 2px)',
  },
  label: {
    cursor: 'pointer',
    position: 'relative',
  },
  paramDescription: {
    background: Colors.GREY_10,
    borderTop: `1px ${Colors.GREY_30} solid`,
    fontSize: '12px',
  },
  paramDescriptionText: {
    fontSize: '12px',
    '& p:first-child': {
      marginTop: '0',
    },
    '& p:last-child': {
      marginBottom: '0',
    },
  },
  paramName: {
    fontSize: '12px',
    fontWeight: 'normal',
    wordWrap: 'break-word',
    maxWidth: '80%',
    padding: '4px 0',
  },
  paramType: {
    color: Colors.TEAL,
    wordWrap: 'break-word',
  },
});
