import React from 'react';

import cx from 'classnames';

import { RESIZE_CURSOR_AREA } from 'client/app/components/ElementGroup/constants';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  position: { left: string; top: string; width: string; height: string };
  isSelected: boolean;
  onPointerDown: Function;
  zIndex?: number;
};

export default React.memo(function ElementGroupBackground({
  position,
  isSelected,
  onPointerDown: startResizing,
  zIndex,
}: Props) {
  const styles = useStyles();
  return (
    <>
      <div className={styles.background} style={position} />
      {isSelected && (
        <div className={styles.resizers} style={{ ...position, zIndex }}>
          <div
            className={cx(styles.north, styles.resizer)}
            onPointerDown={event => startResizing(event, 'n')}
          />
          <div
            className={cx(styles.south, styles.resizer)}
            onPointerDown={event => startResizing(event, 's')}
          />
          <div
            className={cx(styles.west, styles.resizer)}
            onPointerDown={event => startResizing(event, 'w')}
          />
          <div
            className={cx(styles.east, styles.resizer)}
            onPointerDown={event => startResizing(event, 'e')}
          />
          <div
            className={cx(styles.northEast, styles.resizer)}
            onPointerDown={event => startResizing(event, 'ne')}
          />
          <div
            className={cx(styles.northWest, styles.resizer)}
            onPointerDown={event => startResizing(event, 'nw')}
          />
          <div
            className={cx(styles.southWest, styles.resizer)}
            onPointerDown={event => startResizing(event, 'sw')}
          />
          <div
            className={cx(styles.southEast, styles.resizer)}
            onPointerDown={event => startResizing(event, 'se')}
          />
        </div>
      )}
    </>
  );
});

const useStyles = makeStylesHook(() => ({
  background: {
    position: 'absolute',
    background: `rgba(0,0,0,.03)`,
    zIndex: -1,
  },
  resizers: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'transparent',
    pointerEvents: 'none',
  },
  resizer: {
    pointerEvents: 'all',
  },
  north: {
    position: 'absolute',
    background: 'transparent',
    cursor: 'ns-resize',
    top: `-${RESIZE_CURSOR_AREA}px`,
    height: `${RESIZE_CURSOR_AREA}px`,
    width: '100%',
  },
  south: {
    position: 'absolute',
    background: 'transparent',
    cursor: 'ns-resize',
    bottom: `-${RESIZE_CURSOR_AREA}px`,
    height: `${RESIZE_CURSOR_AREA}px`,
    width: '100%',
  },
  west: {
    position: 'absolute',
    background: 'transparent',
    cursor: 'ew-resize',
    left: `-${RESIZE_CURSOR_AREA}px`,
    width: `${RESIZE_CURSOR_AREA}px`,
    height: '100%',
  },
  east: {
    position: 'absolute',
    background: 'transparent',
    cursor: 'ew-resize',
    right: `-${RESIZE_CURSOR_AREA}px`,
    width: `${RESIZE_CURSOR_AREA}px`,
    height: '100%',
  },
  northEast: {
    position: 'absolute',
    background: 'transparent',
    cursor: 'nesw-resize',
    top: `-${RESIZE_CURSOR_AREA}px`,
    right: `-${RESIZE_CURSOR_AREA}px`,
    width: `${RESIZE_CURSOR_AREA * 2}px`,
    height: `${RESIZE_CURSOR_AREA * 2}px`,
  },
  southEast: {
    position: 'absolute',
    background: 'transparent',
    cursor: 'nwse-resize',
    bottom: `-${RESIZE_CURSOR_AREA}px`,
    right: `-${RESIZE_CURSOR_AREA}px`,
    width: `${RESIZE_CURSOR_AREA * 2}px`,
    height: `${RESIZE_CURSOR_AREA * 2}px`,
  },
  northWest: {
    position: 'absolute',
    background: 'transparent',
    cursor: 'nwse-resize',
    top: `-${RESIZE_CURSOR_AREA}px`,
    left: `-${RESIZE_CURSOR_AREA}px`,
    width: `${RESIZE_CURSOR_AREA * 2}px`,
    height: `${RESIZE_CURSOR_AREA * 2}px`,
  },
  southWest: {
    position: 'absolute',
    background: 'transparent',
    cursor: 'nesw-resize',
    bottom: `-${RESIZE_CURSOR_AREA}px`,
    left: `-${RESIZE_CURSOR_AREA}px`,
    width: `${RESIZE_CURSOR_AREA * 2}px`,
    height: `${RESIZE_CURSOR_AREA * 2}px`,
  },
}));
