import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export default React.memo(function CrossHairIcon(
  props: React.PropsWithChildren<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>>,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 3h-2v8H3v2h8v8h2v-8h8v-2h-8V3zm0 8h-2v2h2v-2z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
