import React from 'react';

import { ArrayElement, DOEWorkflowsQuery } from 'client/app/gql';
import { doeTemplateRoutes } from 'client/app/lib/nav/actions';
import { EntityCard } from 'common/ui/components/EntityCard';
import DOETemplateIcon from 'common/ui/icons/DOETemplateIcon';

type DOETemplateCardProps = {
  doe: ArrayElement<DOEWorkflowsQuery['workflows']['items']>;
};

export default function DOETemplateCard({ doe }: DOETemplateCardProps) {
  const {
    id,
    name,
    lastModifiedAt,
    createdBy: { displayName: author },
  } = doe;

  const path = doeTemplateRoutes.edit.getPath({
    workflowId: id,
  });

  const nameColumn = { label: 'DOE name', value: name };
  const authorColumn = { label: 'Author', value: author };
  const dateColumn = { label: 'Last modified', value: new Date(lastModifiedAt) };

  const interaction = { to: path };

  return (
    <EntityCard
      icon={<DOETemplateIcon fontSize="small" />}
      nameColumn={nameColumn}
      authorColumn={authorColumn}
      dateColumn={dateColumn}
      interaction={interaction}
    />
  );
}
