import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';

import { FactorParameterInfo } from 'client/app/components/DOEFactorForm/types';
import AutocompleteEditor from 'client/app/components/Parameters/AutocompleteEditor';
import { AutocompleteAdditionalProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import TextField from 'common/ui/filaments/TextField';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

export type SimpleMutualExclusionLevelDetail = {
  id: string;
  factorValue: string;
  unit?: string;
};

type SimpleMutualExclusionFactorLevelEditorProps = {
  value: string;
  details: SimpleMutualExclusionLevelDetail | undefined;
  autoFocus: boolean;
  onChange: (value: string | undefined) => void;
  onLevelDetailChange: (
    property: 'factorValue' | 'unit',
    value: string | undefined,
  ) => void;
  onPressEnter?: (e: React.KeyboardEvent) => void;
  parameterInfo: FactorParameterInfo | undefined;
  disabled: boolean;
  error: boolean;
  helperText: string | undefined;
  factorValueError: boolean;
  factorValueHelperText: string | undefined;
  unitError: boolean;
  unitHelperText: string | undefined;
  unitOptions: string[] | null;
};

export default function SimpleMutualExclusionFactorLevelEditor({
  value,
  details,
  autoFocus,
  onChange,
  onLevelDetailChange,
  onPressEnter,
  parameterInfo,
  disabled,
  error,
  helperText,
  factorValueError,
  factorValueHelperText,
  unitError,
  unitHelperText,
  unitOptions,
}: SimpleMutualExclusionFactorLevelEditorProps) {
  const handleTextChange = useTextFieldChange(onChange);
  const handleFactorValueChange = (value: string | undefined) =>
    onLevelDetailChange('factorValue', value);
  const handleTextFactorValueChange = useTextFieldChange(handleFactorValueChange);
  const handleUnitChange = (value: string | undefined) =>
    onLevelDetailChange('unit', value);
  const handleTextUnitChange = useTextFieldChange(handleUnitChange);

  let defaultUnit: string = '';

  if (parameterInfo?.valueEditor?.additionalProps?.editor === EditorType.MEASUREMENT) {
    defaultUnit = parameterInfo?.valueEditor?.additionalProps.defaultUnit ?? '';
  }

  const additionalProps = parameterInfo?.keyEditor
    ?.additionalProps as AutocompleteAdditionalProps;
  return (
    <FactorDetails>
      <Name>
        {parameterInfo?.keyEditor?.type === EditorType.AUTOCOMPLETE ? (
          <>
            <AutocompleteEditor
              anthaType={parameterInfo?.valueType ?? parameterInfo?.keyType ?? ''}
              value={value}
              onChange={onChange}
              isDisabled={disabled}
              disableClearable
              placeholder="Level Value"
              useDynamicOptions={additionalProps.useDynamicOptions}
              staticOptions={additionalProps.staticOptions}
              anthaTypeOverride={additionalProps.anthaTypeOverride}
              additionalSourceTypes={additionalProps.additionalSourceTypes}
              canAcceptCustomValues={additionalProps.canAcceptCustomValues}
              provideDefaultKey={false}
              hasError={error}
            />
            {error && <FormHelperText error>{helperText}</FormHelperText>}
          </>
        ) : (
          <NameTextField
            value={value}
            autoFocus={autoFocus}
            onChange={handleTextChange}
            onKeyDown={onPressEnter}
            placeholder="Level Value"
            disabled={disabled}
            error={error}
            helperText={helperText}
          />
        )}
      </Name>
      <Quantity>
        <QuantityTextField
          value={details?.factorValue}
          onChange={handleTextFactorValueChange}
          placeholder="Quantity"
          disabled={disabled}
          error={factorValueError}
          helperText={factorValueHelperText}
        />

        {unitOptions ? (
          <>
            <UnitAutocomplete
              autoSelect
              disableClearable
              disabled={disabled}
              options={unitOptions}
              value={
                details?.unit ? details.unit : defaultUnit ? defaultUnit : unitOptions[0]
              }
              onChange={(_, value) => handleUnitChange(value)}
              placeholder="Unit"
              renderInput={params => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {unitError && <FormHelperText error>{unitHelperText}</FormHelperText>}
          </>
        ) : (
          <UnitTextField
            value={details?.unit ? details.unit : defaultUnit}
            onChange={handleTextUnitChange}
            placeholder="Unit"
            disabled={disabled}
            error={unitError}
            helperText={unitHelperText}
          />
        )}
      </Quantity>
    </FactorDetails>
  );
}

const FactorDetails = styled('div')(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.divider}`,
  paddingLeft: '12px',
}));

const Name = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const NameTextField = styled(TextField)({
  width: '100%',
}) as typeof TextField;

const Quantity = styled('div')({
  marginLeft: '8px',
  maxWidth: 'calc(100 - 8px)',
  display: 'flex',
});

const QuantityTextField = styled(TextField)(({ theme }) => ({
  flex: 3,
  marginRight: theme.spacing(2),
})) as typeof TextField;

const unitStyle = { flex: 1 };

const UnitAutocomplete = styled(Autocomplete)(unitStyle) as typeof Autocomplete;

const UnitTextField = styled(TextField)(unitStyle) as typeof TextField;
