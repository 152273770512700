import { MeasurementAdditionalProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { validateMeasurement } from 'common/lib/format';
import { ParameterValue } from 'common/types/bundle';
import { ParameterConfigurationSpec } from 'common/types/elementConfiguration';

/**
 * Used to build valid Parameter values when:
 * - creating an element instance in Workflow Builder
 * - building Workflow state bundle
 */
export function buildParameterValue(
  value: ParameterValue,
  config: ParameterConfigurationSpec | undefined | null,
): ParameterValue {
  if (!config) return value;

  switch (config.editor.type) {
    /**
     * Handle validation of other parameter types in new cases
     */
    case EditorType.MEASUREMENT:
      return buildMeasurementParameterValue(value, config);

    default:
      return value;
  }
}

/**
 * Builds a valid Element Parameter value for Measurement:
 * valid Measurement value should always have a unit so
 * the default unit has to be considered if there is no unit provided
 */
export function buildMeasurementParameterValue(
  value: ParameterValue,
  config: ParameterConfigurationSpec,
) {
  if (validateMeasurement(value)) return value;

  const additionalProps = config.editor.additionalProps as MeasurementAdditionalProps;
  const defaultUnit: string | undefined = additionalProps.defaultUnit;

  return value && !isNaN(+value) && defaultUnit ? value + defaultUnit : value;
}
