import { Device } from 'common/types/device';

type MinimalDevice = Pick<Device, 'name' | 'model' | 'id'>;

export default function filterDevices<TDevice extends MinimalDevice>(
  devicesArray: readonly TDevice[],
  filterQuery: string,
) {
  const lowerCaseQuery = filterQuery.toLowerCase();
  return devicesArray.filter(device => {
    const { name, model, id } = device;
    if (!name || !model || !id) {
      return false;
    }
    return (
      name.toLowerCase().includes(lowerCaseQuery) ||
      model.toLowerCase().includes(lowerCaseQuery) ||
      id.toLowerCase().startsWith(lowerCaseQuery)
    );
  });
}
