import React, { useRef, useState } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { QUERY_EXECUTIONS } from 'client/app/api/gql/queries';
import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import { ClickedExecution } from 'client/app/apps/experiments/ExecutionsList';
import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import useLatestExperimentName from 'client/app/apps/experiments/useLatestExperimentName';
import { ExecutionCard } from 'client/app/components/cards/ExecutionCard';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import { QueryexecutionsArgs } from 'client/app/gql';
import { SimulationQuery } from 'client/app/gql';
import usePagination from 'client/app/hooks/usePagination';
import { ScreenRegistry } from 'client/app/registry';
import useLaunchPrepareData from 'client/app/stories/work-tree/useLaunchPrepareData';
import { PageInfo } from 'common/server/graphql/pagination';
import { circularLoadingContainer } from 'common/ui/commonStyles';
import Button from 'common/ui/components/Button';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import DialogActions from 'common/ui/components/Dialog/DialogActions';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

type DOEExecutionSelectionDialogProps = {
  currentSimulation: SimulationQuery['simulation'];
} & DialogProps<ClickedExecution | typeof CANCEL_CHOICE>;

/**
 * Render a list of Executions in a dialog from the same DOE design.
 * Allows multi-select of those and then will generate a prepare_data
 * method with the executions as dependencies.
 */
export function DOEExecutionSelectionDialog(props: DOEExecutionSelectionDialogProps) {
  const classes = useStyles();
  const { isOpen, onClose, disableRestoreFocus, currentSimulation } = props;

  const scrollableRef = useRef<HTMLDivElement>(null);

  const { experimentName, loading: experimentNameLoading } = useLatestExperimentName(
    props.currentSimulation,
  );

  // Store the currentSimulation info so we can initialise with this state on mount
  // and then reset to this on close.
  const initialExecutions = [
    ...(currentSimulation.execution
      ? [
          {
            executionId: currentSimulation.execution.id,
            simulationId: currentSimulation.id,
          },
        ]
      : []),
  ];

  const [selectedExecutions, setSelectedExecutions] =
    useState<ClickedExecution[]>(initialExecutions);

  const onClickExecution = (clickedExecution: ClickedExecution) =>
    setSelectedExecutions(prev => {
      const alreadySelected = prev.some(
        selectedExecution =>
          selectedExecution.executionId === clickedExecution.executionId,
      );
      return alreadySelected
        ? prev.filter(
            selectedExecutions =>
              selectedExecutions.executionId !== clickedExecution.executionId,
          )
        : [...prev, clickedExecution];
    });

  const snackbarManager = useSnackbarManager();

  const { launchPrepareData, loading } = useLaunchPrepareData();

  const onConfirm = async () => {
    const { error } = await launchPrepareData(
      selectedExecutions,
      ScreenRegistry.EXECUTION_DETAILS,
    );
    if (error) {
      snackbarManager.showError(error.message);
    }
  };

  const onCancel = () => {
    setSelectedExecutions(initialExecutions);
    onClose(CANCEL_CHOICE);
  };

  return (
    <ComplexActionDialog
      title={experimentNameLoading ? '' : experimentName || 'Executions'}
      onClose={onCancel}
      isOpen={isOpen}
      showCloseButton
      fullHeight
      disableRestoreFocus={disableRestoreFocus}
      content={
        <div className={classes.dialogContent}>
          <ContainerWithIntersectionBar
            scrollableRef={scrollableRef}
            headerLeftContent={
              <div className={classes.containerUpperLeftContent}>
                <Typography color="textPrimary" variant="h2">
                  Executions from the same DOE design
                </Typography>
                <Typography color="textPrimary">
                  Select executions below to prepare DOE data
                </Typography>
              </div>
            }
            content={
              <DOEExecutionSelectionRows
                scrollableRef={scrollableRef}
                onExecutionRowClick={onClickExecution}
                highlightedExecutions={selectedExecutions}
                currentExecutionId={currentSimulation.execution?.id}
              />
            }
            dense
            size="large"
          />
        </div>
      }
      dialogActions={
        <DialogActions>
          <Button variant="tertiary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="tertiary"
            color="primary"
            onClick={onConfirm}
            disabled={loading || selectedExecutions.length === 0}
          >
            {loading ? <CircularProgress size={24} /> : 'Select'}
          </Button>
        </DialogActions>
      }
    />
  );
}

type DOEExecutionSelectionRowsProps = {
  scrollableRef: React.RefObject<HTMLDivElement>;
  onExecutionRowClick: (clickedExecution: ClickedExecution) => void;
  highlightedExecutions: ClickedExecution[];
  currentExecutionId?: ExecutionId;
};

function DOEExecutionSelectionRows(props: DOEExecutionSelectionRowsProps) {
  const classes = useStyles();
  const {
    scrollableRef,
    onExecutionRowClick,
    highlightedExecutions,
    currentExecutionId,
  } = props;

  const variables: QueryexecutionsArgs = { kinOf: currentExecutionId };
  const {
    loading: isInitialLoading,
    error,
    data,
    refetch,
    fetchMore,
  } = useQuery(QUERY_EXECUTIONS, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const executions = data?.executions?.items || [];
  const pageInfo = data?.executions.pageInfo as PageInfo | undefined;

  const hasNextPage = usePagination({
    entity: 'executions',
    pageInfo,
    fetchMore,
    dependencies: [],
    scrollableRef,
    isInitialLoading,
    variables,
  });

  if (error) {
    return <GraphQLErrorPanel error={error} onRetry={refetch} />;
  }

  if (isInitialLoading) {
    return <CircularProgress />;
  }

  if (executions.length === 0) {
    return (
      <NoEntitiesMessage
        entityName="executions"
        messageType={MessageType.NO_FILTER_RESULTS}
      />
    );
  }

  return (
    <div className={classes.list}>
      {executions.map(execution => {
        const showDatasetIcon = 'hasDatasets' in execution;
        const hasDatasets = showDatasetIcon && execution.hasDatasets;
        return (
          <ExecutionCard
            key={execution.id}
            execution={execution}
            showDatasetIcon={showDatasetIcon}
            hasDatasets={hasDatasets}
            onClick={() =>
              onExecutionRowClick({
                executionId: execution.id,
                simulationId: execution.simulation.id,
              })
            }
            isSelected={highlightedExecutions.some(
              clickedExecution => clickedExecution.executionId === execution.id,
            )}
            isDisabled={!execution.hasDatasets}
          />
        );
      })}
      {hasNextPage && (
        <div className={classes.circularLoadingContainer}>
          <CircularProgress size={24} />
        </div>
      )}
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  ...circularLoadingContainer,
  ...experimentsStyles(theme),
  dialogContent: {
    display: 'flex',
    height: 0,
    flexGrow: 1,
    padding: theme.spacing(5),
    // Make the ExecutionsList fill the dialog
    alignItems: 'stretch',
    justifyContent: 'stretch',
  },
  containerUpperLeftContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));
