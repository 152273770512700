import React, { useMemo, useState } from 'react';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import MuiButton from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { FilterOptionsState } from '@mui/material/useAutocomplete';
import cx from 'classnames';

import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

// When using the default auto transition, the value for the autocomplete appeared in the
// input of the popover on exit, and we don't want that. We set the styles here so it
// exits with no animation. For the other values, these were determined inspecting the
// opacity transition of the component in dev tools, and are approximations. See the MUI
// Grow component implementation for more details.
const POPOVER_TRANSITION_DURATIONS = { appear: 232, enter: 232, exit: 0 };

type ChipPickerProps = {
  options: string[];
  placeholder: string;
  searchPlaceholder?: string;
  listHelperText?: string;
  value?: string;
  disabled?: boolean;
  allowCustom?: boolean;
  onPick?: (value: string | undefined) => void;
  onSearchChange?: (searchTerm: string | undefined) => void;
};

/**
 * Allows picking a value from a list and presenting it as a chip.
 * TODO: this will allow creation, deletion, and renaming existing values.
 */
export function ChipPicker(props: ChipPickerProps) {
  const { value, placeholder, disabled, ...otherProps } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const isEnabledWithValue = Boolean(value) && !disabled;

  return (
    <>
      <MuiButton
        classes={{
          root: cx(classes.chip, {
            [classes.chipWithValue]: isEnabledWithValue,
            [classes.chipWithoutValue]: !isEnabledWithValue,
          }),
          endIcon: cx(classes.chipEndIcon, {
            [classes.chipEndIconWithValue]: isEnabledWithValue,
            [classes.chipEndIconWithoutValue]: !isEnabledWithValue,
          }),
          iconSizeMedium: classes.chipIconSizeMedium,
        }}
        variant="text"
        endIcon={isOpen ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
        onClick={e => setAnchorEl(e.currentTarget)}
        disabled={disabled}
        color="inherit"
      >
        <span className={classes.chipLabelEllipsis}>{value ?? placeholder}</span>
      </MuiButton>
      {anchorEl && (
        <ChipPickerMenu
          {...otherProps}
          value={value}
          isOpen={isOpen}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
        />
      )}
    </>
  );
}

type ChipPickerOption = {
  value: string;
  isNew: boolean;
};

type ChipPickerMenuProps = Omit<ChipPickerProps, 'placeholder' | 'disabled'> & {
  isOpen: boolean;
  anchorEl: HTMLElement;
  onClose: () => void;
};

// Inside the ChipPicker is an autocomplete. Configure it to search within obj.value as
// the user types.
const muiAutocompleteFilter = createFilterOptions<ChipPickerOption>({
  stringify: opt => opt.value,
});

function ChipPickerMenu({
  isOpen,
  onClose,
  anchorEl,
  options,
  value,
  searchPlaceholder,
  listHelperText,
  allowCustom,
  onPick,
  onSearchChange,
}: ChipPickerMenuProps) {
  const classes = useStyles();

  // By default, if an option is selected in a MUI Autocomplete, then the value of that option
  // is shown in the inputBase of the Autocomplete (see the demos on the MUI website, for example).
  // However, we do not want that - we only ever want to show the value that the user types in (i.e.
  // the inputValue state below, which is what the user types in to search for a labware, or create a new one).
  // To do this, we must control this inputValue separately from the MUI Autocomplete.
  const [inputValue, setInputValue] = React.useState<string>();

  const onBlur = () => {
    // If the Autocomplete is blurred, the input will be removed.
    // This is annoying as it resets the filtering for the list, and impedes
    // interacting with the popover of a named plate item.
    // So, if a blur event happens, we instead set the value to the existing state.
    setInputValue(inputValue);
    onSearchChange?.(inputValue);
  };

  const handleChange = (option: ChipPickerOption) => {
    if (option.value === value) {
      onPick?.(undefined);
    } else {
      onPick?.(option.value);
    }
    onClose();
  };

  const optionObjs = useMemo<ChipPickerOption[]>(() => {
    // Move selected option to the top.
    const sorted =
      value === undefined
        ? options
        : [value, ...options.filter(option => option !== value)];
    return sorted.map(option => ({ value: option, isNew: false }));
  }, [options, value]);

  const filterOptions = (
    options: ChipPickerOption[],
    state: FilterOptionsState<ChipPickerOption>,
  ) => {
    const filtered = muiAutocompleteFilter(options, state);

    // Show a 'Create' button if allowCustom is true and the user has searched for an
    // option that does not exist. To ensure it is selectable by keyboard we render it as
    // an option within the autocomplete menu rather than a separate component.
    const isNewValue =
      allowCustom &&
      state.inputValue !== '' &&
      !options.some(opt => opt.value === state.inputValue);

    return isNewValue
      ? [{ value: state.inputValue, isNew: true }, ...filtered]
      : filtered;
  };

  return (
    <Popover
      classes={{ paper: classes.popover }}
      PaperProps={{ square: false }}
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      transitionDuration={POPOVER_TRANSITION_DURATIONS}
    >
      <Autocomplete
        classes={{
          listbox: classes.autocompleteList,
          option: classes.autocompleteOptionContainer,
          paper: classes.autocompletePaper,
        }}
        PopperComponent={StyledAutocompletePopper}
        open
        disablePortal // The autocomplete menu should be inline within the popover, not as a separate portal
        inputValue={inputValue}
        renderInput={params => (
          <>
            <InputBase
              classes={{ root: classes.search, input: classes.searchInput }}
              fullWidth
              autoFocus
              inputProps={params.inputProps}
              placeholder={searchPlaceholder ?? 'Search...'}
              ref={params.InputProps.ref}
            />
            {listHelperText && (
              <Typography variant="caption" className={classes.listHelperText}>
                {listHelperText}
              </Typography>
            )}
          </>
        )}
        renderOption={(props, option) =>
          option.isNew ? (
            <NewOption value={option.value} listItemProps={props} />
          ) : (
            <ExistingOption
              value={option.value}
              isSelected={option.value === value}
              listItemProps={props}
            />
          )
        }
        options={optionObjs}
        filterOptions={filterOptions}
        getOptionLabel={option => option.value}
        onChange={(_, option) => option && handleChange(option)}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
          onSearchChange?.(newInputValue);
        }}
        onBlur={onBlur}
      />
    </Popover>
  );
}

function ExistingOption({
  value,
  isSelected,
  listItemProps,
}: {
  value: string;
  isSelected?: boolean;
  listItemProps: React.HTMLAttributes<HTMLLIElement>;
}) {
  const classes = useStyles();
  return (
    <li
      {...listItemProps}
      className={cx(classes.option, { [classes.optionSelected]: isSelected })}
    >
      <Typography
        className={cx(classes.optionLabel, { [classes.optionSelectedText]: isSelected })}
        color="textPrimary"
        variant="subtitle2"
        noWrap
      >
        {value}
      </Typography>
    </li>
  );
}

function NewOption({
  value,
  listItemProps,
}: {
  value: string;
  listItemProps: React.HTMLAttributes<HTMLLIElement>;
}) {
  const classes = useStyles();
  return (
    <li {...listItemProps} className={classes.newOption}>
      <Button className={classes.newOptionButton} size="small" variant="secondary">
        Create
      </Button>
      <Typography color="textPrimary" variant="body2" className={classes.newOptionValue}>
        {value}
      </Typography>
    </li>
  );
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  minWidth: '278px',
  paddingTop: theme.spacing(3),
  '&.MuiAutocomplete-popperDisablePortal': {
    position: 'relative',
  },
}));

const useStyles = makeStylesHook(theme => ({
  chip: {
    borderRadius: '16px',
    textTransform: 'none',
    height: '32px',
    display: 'flex',
    maxWidth: '100%',
    padding: theme.spacing(0, 2, 0, 5),
    alignSelf: 'stretch',
    ...theme.typography.body2,
  },
  chipWithoutValue: {
    backgroundColor: Colors.WHITE,
    boxShadow: `inset 0 0 0 1px ${Colors.GREY_30}`, // use shadow instead of border to prevent shifting content
    '&:hover': {
      backgroundColor: Colors.WHITE,
    },
  },
  chipWithValue: {
    backgroundColor: Colors.GREY_70,
    color: Colors.PRIMARY_CONTRAST,
    '&:hover': {
      backgroundColor: Colors.GREY_80,
    },
  },
  chipLabelEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  chipEndIcon: {
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2, 0, 0),
    marginLeft: theme.spacing(4),
  },
  chipEndIconWithoutValue: {
    boxShadow: `-1px 0 0 0 ${Colors.GREY_30}`, // use shadow instead of border to prevent shifting content
  },
  chipEndIconWithValue: {
    color: Colors.PRIMARY_CONTRAST,
    backgroundColor: Colors.PRIMARY_DARK,
    borderRadius: '0 16px 16px 0',
  },
  chipIconSizeMedium: {
    '& > :first-child': {
      fontSize: '24px',
    },
  },
  popover: {
    borderRadius: '8px',
  },
  search: {
    padding: theme.spacing(5, 3),
  },
  searchInput: {
    backgroundColor: Colors.GREY_10,
    height: '24px',
    padding: theme.spacing(0, 3),
  },
  listHelperText: {
    padding: theme.spacing(0, 3),
  },
  autocompletePaper: {
    boxShadow: 'none',
    margin: 0,
  },
  autocompleteList: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 3, 4, 3),
    gap: theme.spacing(2),
  },
  autocompleteOptionContainer: {
    padding: 0,
    '&.Mui-focused': {
      backgroundColor: 'transparent',
    },
  },
  option: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    minHeight: '32px',
    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '16px',
    '&.Mui-focused': {
      backgroundColor: Colors.ACTION_PRIMARY_MAIN_HOVER,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  optionLabel: {
    padding: theme.spacing(0, 4),
  },
  optionSelected: {
    backgroundColor: Colors.BLUE_80,
    '&:hover': {
      backgroundColor: Colors.BLUE_80,
    },
    '&.Mui-focused': {
      backgroundColor: Colors.BLUE_80,
    },
  },
  optionSelectedText: {
    color: Colors.PRIMARY_CONTRAST,
    '&.Mui-focused': {
      color: Colors.TEXT_PRIMARY,
    },
  },
  newOption: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    gap: '10px',
    '&.Mui-focused': {
      backgroundColor: Colors.WHITE,
    },
  },
  newOptionButton: {
    height: '24px',
    minWidth: 'min-content',
    // MUI default transition is slow and feels unresponsive when selecting via keyboard
    transition: 'border 0.1s',
    '&.Mui-focused': {
      borderColor: Colors.BLUE_50,
    },
  },
  newOptionValue: {
    quotes: 'auto',
    '&:before': { content: 'open-quote' },
    '&:after': { content: 'close-quote' },
  },
}));
