import React, { useCallback, useMemo, useState } from 'react';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { ElementInstance } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export function useElementSetElement(elementInstance: ElementInstance) {
  const elementSet = useWorkflowBuilderSelector(state => state.elementSet);

  // We want the GraphQL element as it has more info than the pointer.
  return useMemo(
    () => elementSet?.elements.find(element => element.id === elementInstance.element.id),
    [elementInstance.element.id, elementSet?.elements],
  );
}

export function useElementNamePopover(elementDisplayName: string) {
  /* Show a popover for the element name so that you can still read if it is too long */
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const classes = useStyles();
  const onMouseEnterTitle = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (event.currentTarget.offsetWidth < event.currentTarget.scrollWidth) {
        setAnchorEl(event.currentTarget);
      }
    },
    [],
  );

  const onMouseLeaveTitle = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const popoverOpen = Boolean(anchorEl);

  const elementNamePopover = (
    <Popover
      className={classes.popover}
      classes={{
        paper: classes.popoverPaper,
      }}
      anchorEl={anchorEl}
      open={popoverOpen}
      onClose={onMouseLeaveTitle}
      elevation={4}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Typography variant="subtitle1" color="textPrimary">
        {elementDisplayName}
      </Typography>
    </Popover>
  );

  return {
    onMouseEnterTitle,
    onMouseLeaveTitle,
    elementNamePopover,
  };
}

const useStyles = makeStylesHook(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Colors.WHITE,
    borderRadius: '4px',
    padding: theme.spacing(3, 5),
    maxWidth: '200px',
  },
}));
