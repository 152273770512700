import { Auth0Config } from 'common/types/auth0';
import fetchWithRetries from 'common/ui/lib/fetch';

export const NAMESPACE = 'synthace.auth0';
const AUTH0_CONFIG_LOCAL_STORAGE_KEY = `${NAMESPACE}.config`;

const CACHE_TTL_MILLISECONDS = 60 * 60 * 1000;

type CachedAuth0Config<Auth0ConfigT> = {
  cachedAt: number;
  config: Auth0ConfigT;
};

export function clearAuth0Config() {
  localStorage.removeItem(AUTH0_CONFIG_LOCAL_STORAGE_KEY);
}

function getAuth0ConfigLocalStorageCompat() {
  return localStorage.getItem(AUTH0_CONFIG_LOCAL_STORAGE_KEY);
}

export async function getAuth0Config<Auth0ConfigT = Auth0Config>({
  ignoreCache = false,
}: {
  ignoreCache?: boolean;
} = {}): Promise<Auth0ConfigT> {
  if (!ignoreCache) {
    // Check to see if we have cached config in local storage.
    const cachedJSON = getAuth0ConfigLocalStorageCompat();
    if (cachedJSON) {
      const cachedConfig = JSON.parse(cachedJSON) as CachedAuth0Config<Auth0ConfigT>;
      // If there's cached config and it was cached within the last
      // CACHE_TTL_MILLISECONDS, use that.
      if (Date.now() - cachedConfig.cachedAt <= CACHE_TTL_MILLISECONDS) {
        return cachedConfig.config;
      }
    }
  }

  const response = await fetchWithRetries({ url: '/.well-known/auth0config.json' });
  const config: Auth0ConfigT = await response.json();
  localStorage.setItem(
    AUTH0_CONFIG_LOCAL_STORAGE_KEY,
    JSON.stringify({ config, cachedAt: Date.now() }),
  );
  return config;
}

export { type Auth0Config } from 'common/types/auth0';
