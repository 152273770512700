import React from 'react';

import Typography from '@mui/material/Typography';

import LabwareCard from 'client/app/components/DeviceLibrary/DeviceConfigurationValidation/LabwareCard';
import useVendorSoftwareName from 'client/app/components/DeviceLibrary/useVendorSoftwareName';
import { OneToManyPlateMatch } from 'client/app/gql';

type Props = {
  oneToManyPlateMatch: OneToManyPlateMatch;
  deviceAnthaClass: string;
};

const OneToManyLabwareMatch = ({ oneToManyPlateMatch, deviceAnthaClass }: Props) => {
  const vendorSoftwareName = useVendorSoftwareName(deviceAnthaClass);
  return (
    <>
      <Typography variant="h5">Labware in {vendorSoftwareName}</Typography>
      <LabwareCard
        plate={oneToManyPlateMatch.vendorPlate}
        deviceAnthaClass={deviceAnthaClass}
      />
      <Typography variant="h5">
        {oneToManyPlateMatch.synthacePlates.length} matches in Synthace
      </Typography>
      {oneToManyPlateMatch.synthacePlates.map((synthacePlate, index) => (
        <LabwareCard
          key={index}
          plate={synthacePlate}
          deviceAnthaClass={deviceAnthaClass}
        />
      ))}
    </>
  );
};

export default OneToManyLabwareMatch;
