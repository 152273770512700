import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ExperimentMapIcon = React.memo<SvgIconProps>(function ExperimentMapIcon(
  props,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.241.096a1.694 1.694 0 0 0-1.145.007L1.107 2.297A1.685 1.685 0 0 0 0 3.88v16.23c0 1.184 1.193 1.999 2.3 1.57l5.383-2.08 6.18 2.295c.39.146.823.141 1.211-.012l5.858-2.313A1.685 1.685 0 0 0 22 18.004V1.887A1.69 1.69 0 0 0 19.717.309L14.446 2.28 8.241.096ZM6.339 2.841 2.316 4.315v14.882l4.023-1.556v-14.8Zm2.316 14.654 4.857 1.804V4.4L8.655 2.69v14.805ZM15.828 4.23V19.1l3.856-1.522V2.788L15.828 4.23Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
