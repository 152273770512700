import Big from 'big.js';

// Taken from https://github.com/Bowserinator/Periodic-Table-JSON/blob/master/PeriodicTableJSON.json
const ATOMIC_WEIGHTS = {
  H: new Big(1.008),
  He: new Big(4.0026022),
  Li: new Big(6.94),
  Be: new Big(9.01218315),
  B: new Big(10.81),
  C: new Big(12.011),
  N: new Big(14.007),
  O: new Big(15.999),
  F: new Big(18.9984031636),
  Ne: new Big(20.17976),
  Na: new Big(22.989769282),
  Mg: new Big(24.305),
  Al: new Big(26.98153857),
  Si: new Big(28.085),
  P: new Big(30.9737619985),
  S: new Big(32.06),
  Cl: new Big(35.45),
  Ar: new Big(39.9481),
  K: new Big(39.09831),
  Ca: new Big(40.0784),
  Sc: new Big(44.9559085),
  Ti: new Big(47.8671),
  V: new Big(50.94151),
  Cr: new Big(51.99616),
  Mn: new Big(54.9380443),
  Fe: new Big(55.8452),
  Co: new Big(58.9331944),
  Ni: new Big(58.69344),
  Cu: new Big(63.5463),
  Zn: new Big(65.382),
  Ga: new Big(69.7231),
  Ge: new Big(72.6308),
  As: new Big(74.9215956),
  Se: new Big(78.9718),
  Br: new Big(79.904),
  Kr: new Big(83.7982),
  Rb: new Big(85.46783),
  Sr: new Big(87.621),
  Y: new Big(88.905842),
  Zr: new Big(91.2242),
  Nb: new Big(92.906372),
  Mo: new Big(95.951),
  Tc: new Big(98),
  Ru: new Big(101.072),
  Rh: new Big(102.905502),
  Pd: new Big(106.421),
  Ag: new Big(107.86822),
  Cd: new Big(112.4144),
  In: new Big(114.8181),
  Sn: new Big(118.7107),
  Sb: new Big(121.7601),
  Te: new Big(127.603),
  I: new Big(126.904473),
  Xe: new Big(131.2936),
  Cs: new Big(132.905451966),
  Ba: new Big(137.3277),
  La: new Big(138.905477),
  Ce: new Big(140.1161),
  Pr: new Big(140.907662),
  Nd: new Big(144.2423),
  Pm: new Big(145),
  Sm: new Big(150.362),
  Eu: new Big(151.9641),
  Gd: new Big(157.253),
  Tb: new Big(158.925352),
  Dy: new Big(162.5001),
  Ho: new Big(164.930332),
  Er: new Big(167.2593),
  Tm: new Big(168.934222),
  Yb: new Big(173.0451),
  Lu: new Big(174.96681),
  Hf: new Big(178.492),
  Ta: new Big(180.947882),
  W: new Big(183.841),
  Re: new Big(186.2071),
  Os: new Big(190.233),
  Ir: new Big(192.2173),
  Pt: new Big(195.0849),
  Au: new Big(196.9665695),
  Hg: new Big(200.5923),
  Tl: new Big(204.38),
  Pb: new Big(207.21),
  Bi: new Big(208.980401),
  Po: new Big(209),
  At: new Big(210),
  Rn: new Big(222),
  Fr: new Big(223),
  Ra: new Big(226),
  Ac: new Big(227),
  Th: new Big(232.03774),
  Pa: new Big(231.035882),
  U: new Big(238.028913),
  Np: new Big(237),
  Pu: new Big(244),
  Am: new Big(243),
  Cm: new Big(247),
  Bk: new Big(247),
  Cf: new Big(251),
  Es: new Big(252),
  Fm: new Big(257),
  Md: new Big(258),
  No: new Big(259),
  Lr: new Big(266),
  Rf: new Big(267),
  Db: new Big(268),
  Sg: new Big(269),
  Bh: new Big(270),
  Hs: new Big(269),
  Mt: new Big(278),
  Ds: new Big(281),
  Rg: new Big(282),
  Cn: new Big(285),
  Nh: new Big(286),
  Fl: new Big(289),
  Mc: new Big(289),
  Lv: new Big(293),
  Ts: new Big(294),
  Og: new Big(294),
};

const VALID_COMPOUND_FORMULA_REGEX =
  /^((H|He|Li|Be|B|C|N|O|F|Ne|Na|Mg|Al|Si|P|S|Cl|Ar|K|Ca|Sc|Ti|V|Cr|Mn|Fe|Co|Ni|Cu|Zn|Ga|Ge|As|Se|Br|Kr|Rb|Sr|Y|Zr|Nb|Mo|Tc|Ru|Rh|Pd|Ag|Cd|In|Sn|Sb|Te|I|Xe|Cs|Ba|La|Ce|Pr|Nd|Pm|Sm|Eu|Gd|Tb|Dy|Ho|Er|Tm|Yb|Lu|Hf|Ta|W|Re|Os|Ir|Pt|Au|Hg|Tl|Pb|Bi|Po|At|Rn|Fr|Ra|Ac|Th|Pa|U|Np|Pu|Am|Cm|Bk|Cf|Es|Fm|Md|No|Lr|Rf|Db|Sg|Bh|Hs|Mt|Ds|Rg|Cn|Nh|Fl|Mc|Lv|Ts|Og)[0-9]*)+(\.[0-9]+((H|He|Li|Be|B|C|N|O|F|Ne|Na|Mg|Al|Si|P|S|Cl|Ar|K|Ca|Sc|Ti|V|Cr|Mn|Fe|Co|Ni|Cu|Zn|Ga|Ge|As|Se|Br|Kr|Rb|Sr|Y|Zr|Nb|Mo|Tc|Ru|Rh|Pd|Ag|Cd|In|Sn|Sb|Te|I|Xe|Cs|Ba|La|Ce|Pr|Nd|Pm|Sm|Eu|Gd|Tb|Dy|Ho|Er|Tm|Yb|Lu|Hf|Ta|W|Re|Os|Ir|Pt|Au|Hg|Tl|Pb|Bi|Po|At|Rn|Fr|Ra|Ac|Th|Pa|U|Np|Pu|Am|Cm|Bk|Cf|Es|Fm|Md|No|Lr|Rf|Db|Sg|Bh|Hs|Mt|Ds|Rg|Cn|Nh|Fl|Mc|Lv|Ts|Og)[0-9]*)+)?$/;

const FIRST_ELEMENT_AND_COUNT_REGEX = /^([A-Z][a-z]?)([0-9]*)/;
const HYDRATION_REGEX = /^([-0-9]+)(.*)$/;

export function validateAndCalculateMolecularWeight(formula: string) {
  if (!VALID_COMPOUND_FORMULA_REGEX.test(formula)) {
    throw new Error(`invalid compound formula: ${formula}`);
  }

  const [mainFormula, hydrationFormula] = formula.split('.');

  return calculateMolecularWeight(mainFormula).add(
    calculateHydrationWeight(hydrationFormula),
  );
}

function calculateMolecularWeight(formula: string) {
  let result = new Big(0);

  while (formula) {
    const nextElement = FIRST_ELEMENT_AND_COUNT_REGEX.exec(formula);
    if (nextElement === null) {
      throw new Error(`unexpected error while parsing formula substring: ${formula}`);
    }
    const [elementAndCount, element, count] = nextElement;
    const weight = ATOMIC_WEIGHTS[element as keyof typeof ATOMIC_WEIGHTS];

    result = result.add(weight.mul(Number(count || 1)));
    // Remove this element (and the number representing how many atoms of this element) from the beginning of the formula string
    formula = formula.substring(elementAndCount.length);
  }

  return result;
}

function calculateHydrationWeight(hydrationFormula: string | undefined) {
  if (!hydrationFormula) {
    return new Big(0);
  }
  const matches = HYDRATION_REGEX.exec(hydrationFormula);

  if (!matches) {
    return new Big(0);
  }

  const [_, numberOfHydrationMolecules, hydrationMolecule] = matches;

  const hydrationMoleculeWeight = calculateMolecularWeight(hydrationMolecule);

  return hydrationMoleculeWeight.mul(Number(numberOfHydrationMolecules));
}
