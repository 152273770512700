import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';

import Button from 'common/ui/components/Button';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  metaItems: MetaItem[];
  showError: boolean;
  error?: string;
  updateMeta: (metaItems: MetaItem[]) => void;
  validate?: () => void;
  isReadonly: boolean;
};

export type MetaItem = { id: string; key: string; value: string };

export default function MetaDataForm({
  metaItems: items,
  showError,
  error,
  updateMeta,
  validate,
  isReadonly,
}: Props) {
  const classes = useStyles();

  const addItem = () => {
    updateMeta([
      ...items,
      {
        id: uuid(),
        key: '',
        value: '',
      },
    ]);
    validate?.();
  };
  const removeItem = (id: string) => {
    const idx = items.findIndex(item => item.id === id);
    updateMeta([...items.slice(0, idx), ...items.slice(idx + 1)]);
    validate?.();
  };
  const updateItem = (id: string, prop: 'key' | 'value', propValue: string) => {
    const idx = items.findIndex(item => item.id === id);
    const updatedItem = {
      ...items[idx],
      [prop]: propValue,
    };
    updateMeta([...items.slice(0, idx), updatedItem, ...items.slice(idx + 1)]);
    validate?.();
  };

  const hasItemsWithError = items.some(item => !item.key || !item.value);

  return (
    <div className={classes.metaData}>
      {items.map(item => (
        <div key={item.id} className={cx(classes.metaItem, { readonly: isReadonly })}>
          <ClearIcon
            color="error"
            className="remove"
            onClick={() => removeItem(item.id)}
          />
          <div className={classes.metaItemInner}>
            <div className={classes.metaNameGroup}>
              <Typography variant="body2">Name</Typography>
              <TextField
                name="metaName"
                className={classes.inputField}
                value={item.key}
                onChange={e => updateItem(item.id, 'key', e.target.value)}
                placeholder="Please, input name..."
                disabled={isReadonly}
              />
            </div>
            <div className={classes.metaValueGroup}>
              <Typography variant="body2">Value</Typography>
              <TextField
                name="metaValue"
                className={classes.inputField}
                value={item.value}
                onChange={e => updateItem(item.id, 'value', e.target.value)}
                placeholder="Please, input value..."
                disabled={isReadonly}
              />
            </div>
          </div>
        </div>
      ))}
      {showError && error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
      <Button
        color="primary"
        variant="tertiary"
        startIcon={<AddIcon />}
        onClick={addItem}
        disabled={hasItemsWithError || isReadonly}
      >
        Add Entry
      </Button>
    </div>
  );
}

const useStyles = makeStylesHook<string, { resinColor: string }>(
  ({ palette, spacing }) => ({
    metaData: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      marginTop: spacing(2),
      paddingTop: spacing(2),
    },
    metaItem: {
      position: 'relative',
      '& > .remove': {
        position: 'absolute',
        top: '50px',
        left: spacing(-1),
        cursor: 'pointer',

        display: 'none',
      },
      '&:hover:not(.readonly) > .remove': {
        display: 'initial',
      },
    },
    metaItemInner: {
      margin: spacing(0, 0, 3, 6),
      paddingLeft: spacing(4),
      borderLeft: `1px solid ${palette.divider}`,
    },
    metaNameGroup: {
      marginBottom: spacing(3),
    },
    metaValueGroup: {
      marginLeft: spacing(4),
    },
    metaInputField: {
      marginTop: spacing(2),
      width: '100%',
    },
  }),
);
