import React from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { svgIconClasses } from '@mui/material/SvgIcon';
import Typography, { typographyClasses } from '@mui/material/Typography';

import { LabwareInfoSection } from 'client/app/components/Labware/types';
import Colors from 'common/ui/Colors';

type Props = {
  section: LabwareInfoSection;
};

export default function Tipwaste({ section: { sectionHeader, sectionItems } }: Props) {
  return sectionItems?.length > 0 ? (
    <Stack gap={4}>
      <Typography variant="caption" fontWeight={600} color="textPrimary">
        {sectionHeader}
      </Typography>
      <Stack gap={4}>
        {sectionItems.map((item, index) => (
          <TipwasteRow key={index}>
            <Typography variant="body1">{item.primaryLabel}</Typography>
            <Typography variant="body1" flexGrow={1} textAlign="right">
              {item.secondaryLabels}
            </Typography>
          </TipwasteRow>
        ))}
      </Stack>
    </Stack>
  ) : null;
}

const TipwasteRow = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(3),

  padding: theme.spacing(5, 6),
  backgroundColor: Colors.WHITE,
  border: `1px solid ${theme.palette.divider}`,

  [`& .${typographyClasses.body1}`]: {
    color: theme.palette.text.primary,
  },
  [`& .${svgIconClasses.root}`]: {
    color: theme.palette.text.primary,
    fontSize: '16px',
  },
}));
