import { useQuery } from '@apollo/client';

import { QUERY_ELEMENT_SET_WITH_ELEMENTS } from 'client/app/api/gql/queries';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export function useElements(elementSetID: string | undefined) {
  const snackbarManager = useSnackbarManager();
  const result = useQuery(QUERY_ELEMENT_SET_WITH_ELEMENTS, {
    variables: { id: elementSetID ?? '' },
    skip: !elementSetID,
    onError: error => {
      snackbarManager.showError(`Error loading elements: ${error.message}`);
    },
  });

  // just a helper field for convenience, plus include entire QueryResult so the
  // caller has the metadata about query result available if needed
  const elements = result.data?.elementSet?.elements;
  return { ...result, elements };
}
