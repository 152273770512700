import { shallowEqual } from 'common/lib/data';
import { WellLocationOnDeckItem } from 'common/types/mix';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import { isMultiSelectClick } from 'common/ui/lib/mouse';

type ComputedSelection = {
  /**
   * Computed list of wells after users' action.
   */
  newSelectedWells: readonly WellLocationOnDeckItem[];
  /**
   * Whether the users clicked on a well which was already selected or not.
   */
  alreadySelected: boolean;
};

/**
 * Given the list of wells that are currently selected and the well
 * which has just been clicked, compute the new list of selected wells.
 */
export default function computeSelectedWells(
  currentlySelectedWells: readonly WellLocationOnDeckItem[],
  clickedLoc: WellLocationOnDeckItem,
  e: React.PointerEvent,
  googleAnalyticsCategory: string,
  // In some cases (e.g. the Well Selector) we don't want users to hold
  // shift to select multiple wells.
  isMultiSelectWithoutShift: boolean = false,
): ComputedSelection {
  const alreadySelected = currentlySelectedWells.some(selectedLoc =>
    shallowEqual(selectedLoc, clickedLoc),
  );
  // Multiselection means that multiple wells can be selected concurrently.
  if (isMultiSelectClick(e) || isMultiSelectWithoutShift) {
    let newSelectedWells = [...currentlySelectedWells];
    if (alreadySelected) {
      // Remove it
      logEvent('deselect-well', googleAnalyticsCategory);
      newSelectedWells = currentlySelectedWells.filter(
        selectedLoc => !shallowEqual(selectedLoc, clickedLoc),
      );
    } else {
      logEvent('select-additional-well', googleAnalyticsCategory);
      newSelectedWells = [...currentlySelectedWells, clickedLoc];
    }
    return { newSelectedWells, alreadySelected };
  } else {
    // *No* multiselection means that no more than one well can be selected at one time.
    if (alreadySelected && currentlySelectedWells.length === 1) {
      // Special case - clicking the only selected well unselect it.
      // We could remove this special case, but it makes deselecting a bit
      // easier and improves the UX.
      logEvent('deselect-well', googleAnalyticsCategory);
      return { newSelectedWells: [], alreadySelected };
    } else {
      // Common case - select just this single well
      logEvent('select-single-well', googleAnalyticsCategory);
      return { newSelectedWells: [clickedLoc], alreadySelected };
    }
  }
}
