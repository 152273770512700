import React from 'react';

import AccessTime from '@mui/icons-material/AccessTime';
import Download from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';

import {
  DownloadInstructionsButton,
  ScheduleButton,
} from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/buttons/button-styles';

type Props = {
  isScheduled: boolean;
  showDownloadInstructions: boolean;
  onSchedule: () => Promise<void>;
  onDownloadInstructions: () => Promise<void>;
  scheduling: boolean;
};

export function LiquidHandlerButtonSet({
  isScheduled,
  showDownloadInstructions,
  onSchedule,
  onDownloadInstructions,
  scheduling,
}: Props) {
  const disabled = isScheduled || scheduling;
  const button = (
    <ScheduleButton
      color="secondary"
      size="medium"
      variant="extended"
      disabled={disabled}
      onClick={onSchedule}
    >
      <AccessTime fontSize="small" />
      Schedule
    </ScheduleButton>
  );

  return (
    <>
      {showDownloadInstructions ? (
        <DownloadInstructionsButton
          variant="secondary"
          color="inherit"
          startIcon={<Download fontSize="small" />}
          onClick={onDownloadInstructions}
        >
          Download instructions
        </DownloadInstructionsButton>
      ) : null}
      {isScheduled ? (
        <Tooltip title="The stage is already scheduled">
          <span>{button}</span>
        </Tooltip>
      ) : (
        button
      )}
    </>
  );
}
