import { useCallback, useEffect, useReducer } from 'react';

import produce from 'immer';
import isEmpty from 'lodash/isEmpty';

import {
  Factor,
  LiquidGroup,
  SubComponentItem,
} from 'client/app/components/Parameters/FiltrationProtocolDesign/types';
import { MetaItem } from 'client/app/components/Parameters/MetaDataForm';

type LiquidGroupFormState = {
  name?: string;
  selectedFactorIds: string[];
  subComponents: SubComponentItem[];
  metaData: MetaItem[];
  dirty: boolean;
  showErrors: boolean;
  errors?: Errors;
};

type Errors = {
  name?: string;
  subComponents: Record<string, { name?: string; value?: string; unit?: string }>;
  meta?: string;
};

type LiquidGroupFormAction =
  | {
      type: 'updateName';
      name: string;
    }
  | {
      type: 'toggleFactor';
      factor: Factor;
    }
  | {
      type: 'updateSubComponents';
      subComponents: SubComponentItem[];
    }
  | {
      type: 'updateMeta';
      metaItems: MetaItem[];
    }
  | {
      type: 'showErrors';
    }
  | {
      type: 'setToValue';
      value: LiquidGroup | undefined;
    };

function getInitialState(selectedLiquidGroup: LiquidGroup | undefined) {
  return {
    name: selectedLiquidGroup?.name,
    selectedFactorIds: selectedLiquidGroup?.factorIds ?? [],
    subComponents: selectedLiquidGroup?.constants.subComponents ?? [],
    metaData: selectedLiquidGroup?.constants.metaData ?? [],
    showErrors: false,
    dirty: false,
  };
}

export default function useLiquidGroupForm(
  selectedLiquidGroup: LiquidGroup | undefined,
  factors: Factor[],
) {
  const [state, dispatch] = useReducer(
    liquidGroupFormReducer,
    getInitialState(selectedLiquidGroup),
  );

  useEffect(() => {
    dispatch({
      type: 'setToValue',
      value: selectedLiquidGroup,
    });
  }, [selectedLiquidGroup]);

  const updateName = useCallback((name: string) => {
    dispatch({ type: 'updateName', name });
  }, []);
  const toggleFactor = useCallback((factor: Factor) => {
    dispatch({ type: 'toggleFactor', factor });
  }, []);
  const updateSubComponents = useCallback((subComponents: SubComponentItem[]) => {
    dispatch({ type: 'updateSubComponents', subComponents });
  }, []);
  const updateMeta = useCallback((metaItems: MetaItem[]) => {
    dispatch({ type: 'updateMeta', metaItems });
  }, []);
  const showErrors = useCallback(() => {
    dispatch({ type: 'showErrors' });
  }, []);

  return {
    state,
    selectedFactors: factors.filter(f => state.selectedFactorIds.includes(f.clientId)),
    updateName,
    toggleFactor,
    updateSubComponents,
    updateMeta,
    showErrors,
  };
}

function liquidGroupFormReducer(
  state: LiquidGroupFormState,
  action: LiquidGroupFormAction,
): LiquidGroupFormState {
  if (action.type === 'setToValue') {
    return getInitialState(action.value);
  }

  return produce(state, draft => {
    switch (action.type) {
      case 'updateName': {
        draft.name = action.name;
        draft.dirty = true;
        break;
      }
      case 'toggleFactor': {
        const idx = state.selectedFactorIds.indexOf(action.factor.clientId);
        if (idx > -1) {
          draft.selectedFactorIds.splice(idx, 1);
        } else {
          draft.selectedFactorIds.push(action.factor.clientId);
        }
        draft.dirty = true;
        break;
      }
      case 'updateSubComponents': {
        draft.subComponents = action.subComponents;
        draft.dirty = true;
        break;
      }
      case 'updateMeta': {
        draft.metaData = action.metaItems;
        draft.dirty = true;
        break;
      }
      case 'showErrors': {
        draft.showErrors = true;
        break;
      }
      default:
        break;
    }

    const errors = getErrors(draft);

    draft.errors = errors;

    if (!errors) {
      draft.showErrors = false;
    }
  });
}

function getErrors(state: LiquidGroupFormState): Errors | undefined {
  let name: Errors['name'],
    subComponents: Errors['subComponents'] = {},
    meta: Errors['meta'];

  if (!state.name) {
    name = 'Liquid name cannot be empty';
  }
  if (state.subComponents.length > 0) {
    subComponents = getSubComponentErrors(state);
  }
  if (state.metaData.some(item => !item.key || !item.value)) {
    meta = 'Meta item cannot have an empty name or value.';
  }

  return !name && isEmpty(subComponents) && !meta
    ? undefined
    : {
        name,
        subComponents,
        meta,
      };
}

function getSubComponentErrors(state: LiquidGroupFormState): Errors['subComponents'] {
  const errors = {} as Errors['subComponents'];

  for (const subComponent of state.subComponents) {
    const subComponentError = {} as Errors['subComponents'][string];

    if (!subComponent.name) {
      subComponentError.name = 'Sub-component name cannot be empty';
    } else if (subComponent.concentration.value <= 0) {
      subComponentError.value = 'Concentration value should be greater than zero';
    } else if (!subComponent.concentration.unit) {
      subComponentError.unit = 'Please select concentration unit';
    } else {
      delete errors[subComponent.id];
      continue;
    }

    errors[subComponent.id] = subComponentError;
  }

  return errors;
}
