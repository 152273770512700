import { isDefined } from 'common/lib/data';
import { filterObject } from 'common/object';
import {
  ConfiguredDevice,
  ConfiguredDeviceDeviceId,
  ConfiguredDeviceId,
  DeviceType,
  Element,
  Factors,
  hasDeck,
  PlannerConfigFile,
  PlannerConfiguredDevice,
  ServerSideBundle,
  ServerSideElementInstance,
  WorkflowConfig,
} from 'common/types/bundle';
import { removeElementsFromSchema } from 'common/types/schema';

/**
 * Config options Antha Core still expects, and which we don't want
 * to be editable in the UI.
 * (There are no known use cases where a user would want to change these.)
 */
export const DEFAULT_VALUES_OF_DEPRECATED_CONFIG_OPTIONS = {
  plateIds: [],
  maxPlates: 20,
  maxWells: 2000,
  modelEvaporation: false,
  optimizeSplitChains: true,
  outputPlateTypes: [] as string[],
  outputSort: false,
  executionPlannerVersion: 'ep2',
  residualVolumeWeight: 1.0,
  driverSpecificWashPreferences: [] as string[],
};

/**
 * This is a workaround of the fact that the 'DeviecClass' strings that we
 * store in postgres don't exactly match the 'DeviceType's that are expected
 * by antha core.
 *
 * It'd be nice to fix this one day so that the database and core line up
 */
const classSubstrings: Record<DeviceType, string> = {
  GilsonPipetMax: 'GilsonPipetMax',
  Tecan: 'TecanLiquidHandler',
  TecanFluent: 'TecanFluent',
  Hamilton: 'HamiltonMicrolab',
  QPCR: 'QPCR',
  CyBio: 'CyBio',
  Labcyte: 'Labcyte',
  TTP: 'TTP',
  Formulatrix: 'Formulatrix',
  Tempest: 'Tempest',
  ShakerIncubator: 'ShakerIncubator',
  PlateReader: 'PlateReader',
  PlateWasher: 'PlateWasher',
  DeCapper: 'DeCapper',
  OpentronsOT2: 'OpentronsOT2',
  CertusFlex: 'CertusFlex',
  Manual: 'Manual',
  GilsonPipettePilot: 'GilsonPipettePilot',
  DataOnly: 'Data-only',
  Echo650: 'Echo650',
  Echo655: 'Echo655',
  Unknown: 'Unknown',
};

/**
 * Crude way to match a device from Postgres (where we store the device class)
 * to one of the fixed keys on the workflow config.
 *
 * TODO(egor): this code should die when we refactor/remove antha_lang_device_class.
 */
export function deviceClassMatchesAnthaDeviceType(
  /** e.g. 'HamiltonMicrolabSTARlet' */
  dbDeviceClass: string,
  /** e.g. 'Hamilton' */
  anthaDeviceType: DeviceType,
) {
  // This is obviously not ideal, we should store the string 'Hamilton' in the db.
  return dbDeviceClass
    .toLowerCase()
    .includes(classSubstrings[anthaDeviceType].toLowerCase());
}

export function getDeviceClassByAnthaDeviceType(workflowConfigKey: DeviceType) {
  return classSubstrings[workflowConfigKey];
}

export function getAnthaDeviceTypeByDeviceClass(
  dbDeviceClass: string = 'Manual',
): DeviceType {
  // This is a hardcoded workaround for a typo in some production devices.
  if (dbDeviceClass.includes('LabCyte')) {
    return 'Labcyte';
  }

  for (const k in classSubstrings) {
    const deviceType = k as DeviceType;

    if (dbDeviceClass.includes(classSubstrings[deviceType])) {
      return deviceType;
    }
  }
  return 'Unknown';
}

/**
 * Removes element instances for which predicate returns true from bundle,
 * including connections and other references to the removed elements.
 * Returns a new object, does not modify bundle in place
 */
export function removeElements(
  bundle: ServerSideBundle,
  predicate: (instanceName: string, instance: ServerSideElementInstance) => boolean,
): ServerSideBundle {
  const instancesToRemove = Object.entries(bundle.Elements.Instances).filter(
    ([instanceName, instance]) => predicate(instanceName, instance),
  );
  const instanceNamesToRemove = new Set(
    instancesToRemove.map(([instanceName]) => instanceName),
  );
  const instanceIdsToRemove = new Set(
    instancesToRemove.map(([_, instance]) => instance.Id),
  );

  const instances = filterObject(
    bundle.Elements.Instances,
    instanceName => !instanceNamesToRemove.has(instanceName),
  );

  const connections = bundle.Elements.InstancesConnections.filter(
    connection =>
      !instanceNamesToRemove.has(connection.Source.ElementInstance) &&
      !instanceNamesToRemove.has(connection.Target.ElementInstance),
  );

  const factors: Factors | undefined = bundle.Factors?.filter(
    factor =>
      !(
        factor.path &&
        factor.path.length >= 2 &&
        instanceNamesToRemove.has(factor.path[1])
      ),
  );

  const groups = bundle.Groups?.map(g => ({
    ...g,
    elementIds: g.elementIds.filter(id => !instanceIdsToRemove.has(id)),
  }));

  const stages = bundle.Stages?.map(s => ({
    ...s,
    elementIds: s.elementIds.filter(id => !instanceIdsToRemove.has(id)),
  }));

  const schema = bundle.Schema
    ? removeElementsFromSchema(bundle.Schema, instanceIdsToRemove)
    : undefined;

  return {
    ...bundle,
    Elements: {
      Instances: instances,
      InstancesConnections: connections,
    },
    Groups: groups,
    Stages: stages,
    Factors: factors,
    Schema: schema,
  };
}

export function getMissingElementTypeNames(
  typeNames: string[],
  availableElements: readonly Pick<Element, 'name'>[],
): Set<string> {
  const availableElementNames = new Set(availableElements.map(element => element.name));

  if (availableElementNames.size === 0) {
    return new Set();
  }

  return new Set(
    typeNames.filter(elementName => !availableElementNames.has(elementName)),
  );
}

/**
 * Reads workflow and ensures that it is compatible with the v3 workflow format.
 * No change is made if the workflow is already in v3.
 *
 * Note: if the input is not v3, then the SchemaVersion of the resulting workflow
 *       is malformed, and the resulting workflow should not be saved.
 *       This is because any element migrations which should have been applied
 *       will not have been. In order to migrate the workflow properly, see
 *       the core 'MigrateWorkflow' endpoint.
 *       This function is intended for use when performing a full migration is
 *       not required (e.g. snapshots) or not feasible (e.g. tests).
 */
export function ensureV3Config(wf: ServerSideBundle): ServerSideBundle {
  if (wf.SchemaVersion.startsWith('3.')) {
    return wf;
  } else if (wf.SchemaVersion.startsWith('2.')) {
    const oldConfig = wf.Config as unknown;
    const newConfig = migrateV2ConfigToV3(oldConfig as WorkflowConfigV2);
    return {
      ...wf,
      Config: newConfig,
      // this workflow is _not_ 3.0 as we haven't applied any element migrations.
      // Setting 3._ since it is now compatible with code designed to read
      // v3 workflows.
      SchemaVersion: '3._',
    };
  }
  throw new Error(`unknown workflow SchemaVersion ${wf.SchemaVersion}`);
}

function migrateV2ConfigToV3(original: WorkflowConfigV2): WorkflowConfig {
  const { GlobalMixer: globalV2, ...devicesV2 } = original;

  // v3's global config is just the v2 one minus some properties
  const {
    driverSpecificInputPreferences: dsip,
    driverSpecificOutputPreferences: dsop,
    driverSpecificPlatePreferences: dspp,
    driverSpecificTipPreferences: dstp,
    driverSpecificTipWastePreferences: dstwp,
    driverSpecificTemporaryLocations: dstl,
    ...globalV3
  } = globalV2;

  const configuredDevices: ConfiguredDevice[] = [];
  const configIdByDeviceId = new Map<ConfiguredDeviceDeviceId, ConfiguredDeviceId>();
  Object.entries(devicesV2).forEach(([dType, perDeviceTypeConfig]) => {
    if (!perDeviceTypeConfig.Devices) {
      return;
    }
    Object.entries(perDeviceTypeConfig.Devices).forEach(([dId, perDeviceConfig]) => {
      const configId = `${configuredDevices.length}` as ConfiguredDeviceId;
      const configuredDevice: ConfiguredDevice = {
        id: configId,
        deviceId: dId as ConfiguredDeviceDeviceId,
        type: dType as DeviceType,
        model: perDeviceConfig.Model,
      };
      configIdByDeviceId.set(dId as ConfiguredDeviceDeviceId, configId);

      if (perDeviceConfig.runConfigId) {
        configuredDevice.runConfigId = perDeviceConfig.runConfigId;
        configuredDevice.runConfigVersion = perDeviceConfig.runConfigVersion;
      }

      // if the config came from the planner, it might actually
      // be a PlannerConfiguredDevice which has an extra field.
      // It's a little awkward because this convertion logic is
      // essentially handling two very similar formats.
      const withConfigFiles = perDeviceConfig as PerDeviceConfigV2 & {
        ConfigFiles?: PlannerConfigFile[];
      };
      if (withConfigFiles.ConfigFiles) {
        (configuredDevice as PlannerConfiguredDevice).configFiles =
          withConfigFiles.ConfigFiles;
      }

      // note: we'll convert device ids to config ids later
      const adi = perDeviceConfig.accessibleDeviceIds;
      if (adi && adi.length > 0) {
        configuredDevice.accessibleDeviceConfigurationIds = adi as ConfiguredDeviceId[];
      }

      // only devices with a deck need layout, plate or tip types
      if (hasDeck(configuredDevice.type)) {
        configuredDevice.layoutPreferences = {
          tipboxes: dstp,
          inputs: dsip,
          outputs: dsop,
          plates: dspp,
          tipwastes: dstwp,
          temporaryLocations: dstl,
        };

        const ipt = perDeviceConfig.inputPlateTypes;
        if (ipt && ipt.length > 0) {
          configuredDevice.inputPlateTypes = ipt;
        }

        const tt = perDeviceConfig.tipTypes;
        if (tt && tt.length > 0) {
          configuredDevice.tipTypes = tt;
        }
      }

      configuredDevices.push(configuredDevice);
    });
  });

  // v2 stores accessible devices by device id, but v3 stores them by config
  // id, as the same device may exist multiple times in v3
  configuredDevices.map(cd => {
    if (cd.accessibleDeviceConfigurationIds) {
      const adi = cd.accessibleDeviceConfigurationIds?.map(dId =>
        configIdByDeviceId.get(dId as unknown as ConfiguredDeviceDeviceId),
      );
      // Sometimes workflows exist where a device has an accessible device id
      // which isn't in the workflow.
      // We could kick up a fuss here, but it seems better to just drop the
      // accessible device as it no longer exists.
      cd.accessibleDeviceConfigurationIds = adi.filter(isDefined);
    }
    return cd;
  });

  return {
    global: globalV3,
    configuredDevices: configuredDevices,
  };
}

/**
 * Config of a workflow, v2 format.
 */
type WorkflowConfigV2 = {
  /**
   * global settings which affect the whole workflow
   */
  GlobalMixer: GlobalMixerConfigV2;
} & {
  /**
   * configs split by device type
   */
  [deviceType in keyof DeviceType]: PerDeviceTypeConfigV2;
};

type GlobalMixerConfigV2 = {
  allocateInputsVersion: number;
  ignorePhysicalSimulation: boolean;
  useDriverTipTracking: boolean;
  useTipboxAutofill: boolean;
  liquidHandlingPolicyXlsxJmpFile?: string;
  liquidHandlingPolicyXlsxJmpFileName?: string;

  balancingStrategy: 'empty' | 'manual volume' | 'automated volume';
  balancingTolerance: number;
  isCentrifugeEnabled: boolean;

  requiresDevice?: boolean;

  inputPlateTypes?: string[];

  tipTypes?: string[];

  driverSpecificInputPreferences: string[];
  driverSpecificOutputPreferences: string[];
  driverSpecificPlatePreferences: {
    [plateNamePrefix: string]: string[];
  };
  driverSpecificTipPreferences: string[];
  driverSpecificTipWastePreferences: string[];
  driverSpecificTemporaryLocations: string[];
};

/**
 * Groups configs for devices of the same type together.
 * For example, if the workflow uses multiple liquid handlers of class 'Tecan',
 * those liquid handlers would be grouped here.
 */
type PerDeviceTypeConfigV2 = {
  Devices: {
    [deviceId: string]: PerDeviceConfigV2;
  };
};

/**
 * Workflow config for a specific single device.
 * For example, if a workflow uses a qPCR machine and a Tecan liquid handler,
 * there would be one of these for the qPCR machine, and one for the Tecan.
 */
type PerDeviceConfigV2 = {
  /**
   * Null if the device has no run configs (e.g. Gilson).
   * We want to make this null, not optional, to make it explicit when
   * a device has no run config.
   */
  runConfigId: string | null;
  runConfigVersion?: number;
  /** Example: ['pcrplate_skirted_riser18'] */
  inputPlateTypes?: string[];
  Model?: string;
  /** Example: ['Tecan5000'] */
  tipTypes?: string[];
  /**
   * Devices accessible by this device.
   * Design doc:
   * https://paper.dropbox.com/doc/Accessible-Devices-design-doc--A2drRA_43op5RoYK_Wv_8ncsAg-yZO18LmdKs6QXp6mFeI41
   */
  accessibleDeviceIds?: string[];
};
