import React from 'react';

import CanvasStage from 'client/app/components/ElementPlumber/CanvasStage';
import {
  ELEMENT_INSTANCE_WIDTH,
  STAGE_PADDING,
} from 'client/app/lib/layout/LayoutHelper';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { Stage } from 'common/types/bundle';

type Props = {
  isReadonly: boolean;
  stages: Stage[];
  selectedStageId?: string;
  allowInteraction?: boolean;
};

export default function CanvasStages({
  isReadonly,
  stages,
  selectedStageId,
  allowInteraction = true,
}: Props) {
  const mode = useWorkflowBuilderSelector(state => state.mode);

  return (
    <>
      {stages.map((stage, i) => {
        const prev = stages[i - 1];
        const next = stages[i + 1];

        return (
          <CanvasStage
            key={stage.id}
            stage={stage}
            nextStage={next}
            number={i + 1}
            minX={
              prev?.meta.x === undefined || i === 1
                ? undefined
                : prev.meta.x + (ELEMENT_INSTANCE_WIDTH + STAGE_PADDING * 3)
            }
            maxX={
              next?.meta.x === undefined
                ? undefined
                : next.meta.x - (ELEMENT_INSTANCE_WIDTH + STAGE_PADDING * 3)
            }
            isSelected={stage.id === selectedStageId}
            isReadonly={isReadonly || mode === 'DOE'}
            allowInteraction={allowInteraction}
          />
        );
      })}
    </>
  );
}
