import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const TalkToUsIcon = React.memo<SvgIconProps>(function TalkToUsIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg fill="none" viewBox="0 0 24 24">
        <path
          d="M7.5 19h-.212l-.194.086L4 20.461V4a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1H7.5z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path fill="currentColor" d="M7 6h10v2H7zM7 10h10v2H7zM7 14h10v2H7z" />
      </svg>
    </SvgIcon>
  );
});
