import React, { useRef, useState } from 'react';

import Stack from '@mui/material/Stack';

import { useFeatureToggle } from 'common/features/useFeatureToggle';
import stopPropagation from 'common/lib/stopPropagation';
import { useManualInterventions } from 'common/ui/components/simulation-details/mix/ManualInterventions/ManualInterventionContext';
import KeyPointPopper from 'common/ui/components/simulation-details/StepSlider/components/KeyPointPopper';
import {
  KeyPointItem,
  ManualInterventionDarkIcon,
  PromptInfoStandaloneIcon,
} from 'common/ui/components/simulation-details/StepSlider/components/styles';
import { getKeyPointColor } from 'common/ui/components/simulation-details/StepSlider/helpers';
import { KeyPoint } from 'common/ui/components/simulation-details/StepSlider/types';

type Props = {
  id: string;
  keyPoint: KeyPoint;
  position: number;
  shouldMerge: boolean;
  isActive: boolean;
  popper: {
    content: React.ReactNode;
    forceOpen: boolean;
  };
  onClick: () => void;
};

export default function SingleKeyPoint({
  id,
  keyPoint: keyPointRaw,
  position,
  shouldMerge,
  isActive,
  popper,
  onClick,
}: Props) {
  const isEnabledManualInterventions = useFeatureToggle('MANUAL_INTERVENTION_HIGHLIGHT');

  const keyPoint = useKeyPointProperties(
    keyPointRaw,
    isActive,
    popper.forceOpen,
    onClick,
  );
  const StandaloneIconComponent = keyPoint.isManualIntervention
    ? ManualInterventionDarkIcon
    : PromptInfoStandaloneIcon;

  return (
    <>
      <KeyPointItem
        ref={keyPoint.ref}
        style={{
          left: position,
          backgroundColor: getKeyPointColor(keyPointRaw),
        }}
        shouldMerge={shouldMerge}
        {...keyPoint.pointerEvents}
      />
      {isEnabledManualInterventions && (
        <KeyPointPopper
          id={id}
          open={keyPoint.popper.open}
          anchorEl={keyPoint.popper.anchorEl}
          content={
            popper.content ?? (
              <Stack px={2}>
                <StandaloneIconComponent />
              </Stack>
            )
          }
        />
      )}
    </>
  );
}

function useKeyPointProperties(
  keyPoint: KeyPoint,
  isActive: boolean,
  forcePopperOpen: boolean,
  onClick: () => void,
) {
  const manualInterventions = useManualInterventions();
  const isPromptAction = keyPoint.kind === 'prompt';
  const isManualIntervention = !!keyPoint.isManualIntervention;

  const [isHovered, setIsHovered] = useState(false);

  const anchorRef = useRef<HTMLElement | null>(null);
  const popperOpen =
    isPromptAction &&
    !!anchorRef.current &&
    ((isManualIntervention && manualInterventions.showAll && forcePopperOpen) ||
      (isManualIntervention && !manualInterventions.showAll && (isActive || isHovered)) ||
      (!isManualIntervention && (isActive || isHovered)));

  const duration = keyPoint.popper?.duration;
  const message = keyPoint.popper?.message;

  return {
    isHovered,
    isManualIntervention,
    ref: (ref: HTMLElement | null) => (anchorRef.current = ref),
    pointerEvents: {
      onPointerDown(event: React.PointerEvent) {
        stopPropagation(event);
        onClick();
      },
      onPointerEnter() {
        if (isPromptAction) {
          setIsHovered(true);
        }
      },
      onPointerLeave() {
        if (isPromptAction) {
          setIsHovered(false);
        }
      },
    },
    popper: {
      anchorEl: anchorRef.current,
      open: popperOpen,
      duration,
      message,
    },
  };
}
