import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';

import { ALL_CONFIG_PROPERTIES } from 'client/app/lib/workflow/workflowConfigProperties';
import { indexBy } from 'common/lib/data';
import { Parameter, TemplateWorkflowConfig } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import Checkbox from 'common/ui/components/Checkbox';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = DialogProps<TemplateWorkflowConfig[] | null> & {
  configParameters: TemplateWorkflowConfig[];
};

/**
 * Dialog for selecting config parameters for the template workflow.
 */
export function TemplateWorkflowConfigParameterListDialog(props: Props) {
  const { isOpen, onClose } = props;
  const classes = useStyles();

  const [parameters, setParameters] = useState(() => props.configParameters);
  useEffect(() => {
    if (isOpen) {
      setParameters(props.configParameters);
    }
  }, [isOpen, props.configParameters]);

  const onCancel = useCallback(() => onClose(null), [onClose]);
  const onClickConfirm = useCallback(() => onClose(parameters), [parameters, onClose]);

  const onParameterChange = useCallback(
    (propertyName: string, parameter: TemplateWorkflowConfig | null) => {
      if (parameter) {
        setParameters(parameters => upsert(parameters, parameter));
      } else {
        setParameters(parameters =>
          parameters.filter(p => p.PropertyName !== propertyName),
        );
      }
    },
    [],
  );

  const parametersByName = useMemo(
    () => indexBy(parameters, 'PropertyName'),
    [parameters],
  );

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md">
      <DialogTitle>Choose editable configuration parameters</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Table size="small" className={classes.tableRoot}>
          <TableHead>
            <TableRow>
              <TableCell>Parameter</TableCell>
              <TableCell>Displayed name</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ALL_CONFIG_PROPERTIES.map(configParameter => (
              <ParameterTableRow
                key={configParameter.name}
                configParameter={configParameter}
                parameter={parametersByName[configParameter.name]}
                onChange={onParameterChange}
              />
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="tertiary" color="primary" onClick={onClickConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ParameterTableRow({
  configParameter,
  parameter,
  onChange,
}: {
  configParameter: Parameter;
  parameter?: TemplateWorkflowConfig;
  onChange: (parameterName: string, parameter: TemplateWorkflowConfig | null) => void;
}) {
  const isSelected = !!parameter;
  const classes = useStyles();
  const [autoFocus, setAutoFocus] = useState(false);
  const onRowClick = useCallback(() => {
    if (!isSelected) {
      setAutoFocus(true);
    }
    onChange(
      configParameter.name,
      !isSelected
        ? {
            DisplayName: configParameter.name,
            PropertyName: configParameter.name,
          }
        : null,
    );
  }, [isSelected, onChange, configParameter.name]);

  const onDisplayNameChange = useTextFieldChange(
    useCallback(
      (DisplayName: string) =>
        parameter &&
        onChange(parameter.PropertyName, {
          DisplayName,
          PropertyName: parameter.PropertyName,
        }),
      [onChange, parameter],
    ),
  );

  const onTextFieldClick = useCallback(
    (event: React.MouseEvent) => event.stopPropagation(),
    [],
  );

  return (
    <TableRow onClick={onRowClick} className={classes.row}>
      <TableCell>
        <FormControlLabel
          control={<Checkbox checked={isSelected} />}
          label={configParameter.name}
        />
      </TableCell>
      <TableCell>
        {isSelected && (
          <TextField
            variant="standard"
            autoFocus={autoFocus}
            fullWidth
            value={parameter?.DisplayName ?? ''}
            onChange={onDisplayNameChange}
            onClick={onTextFieldClick}
          />
        )}
      </TableCell>
      <TableCell>{configParameter.description}</TableCell>
    </TableRow>
  );
}

const useStyles = makeStylesHook({
  dialogContent: {
    maxHeight: '80vh',
  },
  table: {
    paddingTop: '1rem',
    height: '50%',
    overflow: 'auto',
  },
  tableRoot: {
    tableLayout: 'fixed',
    marginBottom: '2rem',
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.GREY_20,
    },
  },
});

function upsert(
  parameters: TemplateWorkflowConfig[],
  parameter: TemplateWorkflowConfig,
): TemplateWorkflowConfig[] {
  const index = parameters.findIndex(p => p.PropertyName === parameter.PropertyName);
  if (index > -1) {
    const newParameters = [...parameters];
    newParameters[index] = parameter;
    return newParameters;
  } else {
    return [...parameters, parameter];
  }
}
