import { useCallback } from 'react';

import RequestHelpDialog from 'client/app/components/SimulationNotification/RequestHelpDialog';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import useDialog from 'common/ui/hooks/useDialog';

export default function useRequestHelpForSimulation(
  screen: string,
  simulationId?: string,
  simulationName?: string,
) {
  const [requestHelpDialog, openRequestHelpDialog] = useDialog(RequestHelpDialog);
  const userProfile = useUserProfile();
  const handleRequestHelp = useCallback(() => {
    logEvent('report-issue', screen);
    if (!userProfile || !simulationId || !simulationName) {
      return;
    }
    return openRequestHelpDialog({
      simulationName,
      simulationId,
      userProfile,
    });
  }, [openRequestHelpDialog, screen, simulationId, simulationName, userProfile]);

  return { requestHelpDialog, handleRequestHelp };
}
