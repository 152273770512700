import React, { useContext } from 'react';

import { defineLiquidsAndPlatesElement } from 'client/app/apps/experiments/createElementsInWorkflowUtils';
import { useCreateAndLinkWorkflowToExperiment } from 'client/app/apps/experiments/useCreateAndLinkWorkflowToExperiment';
import { useCreateAndLinkWorkflowWithElementsToExperiment } from 'client/app/apps/experiments/useCreateAndLinkWorkflowWithElementsToExperiment';
import { useWaitForWorkTreeNode } from 'client/app/apps/work-tree/useWaitForWorkTreeNode';
import { PositionedWorkTree } from 'client/app/apps/work-tree/useWorkTreeLayout';
import { WorkTreeModeContext } from 'client/app/apps/work-tree/WorkTreeModeContext';
import { EditorType, ServerSideElements } from 'common/types/bundle';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import CherryPickerIcon from 'common/ui/icons/CherryPickerIcon';
import { WorkflowIcon } from 'common/ui/icons/Workflow';

type WorkflowEntitiesMenuItemsProps = {
  experimentId: ExperimentId;
  workTree: PositionedWorkTree;
  onHidePopover: () => void;
};

export default React.forwardRef(function WorkflowEntitiesMenuItems(
  props: WorkflowEntitiesMenuItemsProps,
  ref: React.Ref<HTMLLIElement>,
) {
  const { experimentId, workTree, onHidePopover } = props;
  const context = useContext(WorkTreeModeContext);

  const { waitForNodeWithResultId } = useWaitForWorkTreeNode(workTree);

  const createWorkflowInExperiment = useCreateAndLinkWorkflowToExperiment();
  const createWorkflowWithElementsInExperiment =
    useCreateAndLinkWorkflowWithElementsToExperiment();

  const handleCreateWorkflow = async (
    source: EditorType,
    elements?: ServerSideElements,
  ) => {
    onHidePopover();
    context.setShowLoadingState(true);
    const id = elements
      ? await createWorkflowWithElementsInExperiment(experimentId, source, elements)
      : await createWorkflowInExperiment(experimentId, source);
    if (!id) {
      context.setShowLoadingState(false);
      return;
    }
    waitForNodeWithResultId(id as unknown as ResultId, node => {
      context.focusOnNode(node);
      context.setShowLoadingState(false);
    });
  };

  return (
    <>
      <MenuItemWithIcon
        text="New workflow"
        icon={<WorkflowIcon />}
        onClick={() => handleCreateWorkflow(EditorType.WORKFLOW_EDITOR)}
        tooltipTitle="Create a new workflow via the Builder"
        ref={ref}
      />
      <MenuItemWithIcon
        text="New workflow with existing plates"
        icon={<WorkflowIcon />}
        onClick={() =>
          handleCreateWorkflow(
            EditorType.WORKFLOW_EDITOR,
            defineLiquidsAndPlatesElement(),
          )
        }
        tooltipTitle="Create a new workflow via the Builder with plates from a previous simulation"
        ref={ref}
      />
      <MenuItemWithIcon
        text="New cherry picker"
        icon={<CherryPickerIcon />}
        onClick={() => handleCreateWorkflow(EditorType.CHERRY_PICKER)}
        tooltipTitle="Create a new workflow via the Cherry Picker"
        ref={ref}
      />
    </>
  );
});
