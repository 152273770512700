import { WorkflowSourceEnum } from 'client/app/gql';
import { TemplateWorkflowIcon, WorkflowIcon } from 'client/app/icons';
import { doeTemplateRoutes, workflowRoutes } from 'client/app/lib/nav/actions';
import { EditorType } from 'common/types/bundle';
import CherryPickerIcon from 'common/ui/icons/CherryPickerIcon';
import DOEIcon from 'common/ui/icons/DOEIcon';

/**
 * Given the source of the workflow, return the right props (icon, route and display name).
 */
export default function getWorkflowPropsBySource(
  source: EditorType | WorkflowSourceEnum,
) {
  switch (source as unknown as WorkflowSourceEnum) {
    case WorkflowSourceEnum.CHERRY_PICKER:
      return {
        EditorIcon: CherryPickerIcon,
        route: workflowRoutes.openInCherryPicker,
        humanReadableName: 'Cherry Picker',
      };

    case WorkflowSourceEnum.DOE_TEMPLATE_EDITOR:
      return {
        EditorIcon: DOEIcon,
        route: doeTemplateRoutes.edit,
        humanReadableName: 'DOE Template Editor',
      };
    case WorkflowSourceEnum.FORM_EDITOR:
      return {
        EditorIcon: TemplateWorkflowIcon,
        route: workflowRoutes.editForm,
        humanReadableName: 'Form',
      };

    case WorkflowSourceEnum.WORKFLOW_EDITOR:
    default:
      return {
        EditorIcon: WorkflowIcon,
        route: workflowRoutes.openInWorkflowBuilder,
        humanReadableName: 'Builder',
      };
  }
}
