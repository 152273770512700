import React from 'react';

import ButtonBase, { buttonBaseClasses, ButtonBaseProps } from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';

import Tooltip from 'common/ui/components/Tooltip';

type Props = React.PropsWithChildren<ButtonBaseProps> & {
  icon?: JSX.Element;
  tooltipContent?: JSX.Element | string;
};

export default function ControlButton({
  children,
  tooltipContent,
  icon,
  ...props
}: Props) {
  const btnContent = (
    <StyledButton {...props}>
      {icon}
      {children}
    </StyledButton>
  );
  return props.disabled ? (
    btnContent
  ) : (
    <Tooltip title={tooltipContent}>{btnContent}</Tooltip>
  );
}

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),

  padding: theme.spacing(0, 3),
  color: theme.palette.text.primary,

  [`&.${buttonBaseClasses.root}:hover`]: {
    backgroundColor: theme.palette.grey['300'],
  },
  [`&.${buttonBaseClasses.disabled}`]: {
    color: theme.palette.grey['400'],
  },
}));
