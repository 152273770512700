import React, { useState } from 'react';

import CachedIcon from '@mui/icons-material/Cached';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import MuiTooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { formatVolumeWithStdDev, formatWellPosition } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import { QuadraticBezierCurve } from 'common/ui/components/simulation-details/mix/edgeRouting';
import { ADJACENT_WELL_AVG_LENGTH_SQUARED } from 'common/ui/components/simulation-details/mix/EdgeSvg';
import {
  DeckItemState,
  Edge,
} from 'common/ui/components/simulation-details/mix/MixState';
import { TOOLTIP_FONTSIZE } from 'common/ui/components/simulation-details/mix/WellTooltip';
import StampingIcon from 'common/ui/icons/StampingIcon';

/**
 * Amount of padding, in pixels, to add to the left and right of the edge label.
 */
const LABEL_X_PADDING = 8;
const LABEL_Y_PADDING = 3;
/**
 * Font size in pixels.
 */
const LABEL_FONT_SIZE = 12;
/**
 * For very short edges, the font size should be smaller.
 */
const LABEL_FONT_SIZE_SMALL = 8;
/**
 * If the label is too big to fit on the edge, then it will be shifted above the
 * label by this amount (in pixels).
 */
const OVERSIZED_LABEL_OFFSET = 3;

type Props = {
  deckItemDict: Map<string, DeckItemState>;
} & EdgeProps &
  EventHandlers;

type EdgeProps = {
  edge: Edge;
  curve: QuadraticBezierCurve;
  pathLengthSquared: number;
  isSelected?: boolean;
};

type EventHandlers = {
  onClick?: (edge: Edge) => void;
  onMouseEnter?: (edge: Edge) => void;
  onMouseLeave?: () => void;
};

/**
 * An arrow from one deck position to another, with a label describing the
 * action.
 */
export default function EdgeLabel(props: Props) {
  switch (props.edge.type) {
    /**
     * Here we want to handle Stamping action label separately as it includes an icon.
     * If there would be more labels with custom structure they should be handled
     * as separate case here as well.
     */
    case 'stamping':
      return <StampingEdgeLabel {...props} />;
    default:
      return <RegularEdgeLabel {...props} />;
  }
}

function StampingEdgeLabel({
  edge,
  curve,
  pathLengthSquared,
  deckItemDict,
  isSelected,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: Props) {
  const { label, eventHandlers, textRef } = useEdgeLabelCommon({
    edge,
    pathLengthSquared,
    onClick,
    onMouseEnter,
    onMouseLeave,
  });

  return (
    <Tooltip arrow title={<EdgeTooltipTitle edge={edge} deckItemDict={deckItemDict} />}>
      <g transform={`translate(${curve.center.x},${curve.center.y})`}>
        <LabelRect
          isSelected={isSelected}
          x={-label.width / 2 - 10}
          y={label.y}
          height={label.height}
          width={label.width + 6}
          rx={label.height / 4}
          ry={label.height / 4}
          {...eventHandlers}
          // Used to identify the click target in the MixScreen
          data-edge="true"
        />
        <LabelText
          ref={textRef}
          y={label.y + label.height / 2}
          dy={1}
          fontSize={label.fontSize}
        >
          {label.text}
        </LabelText>
        <foreignObject
          x={-label.width / 2 - 4}
          y={label.y + 3}
          height={label.height}
          width={label.width}
        >
          <StampingEdgeIcon edge={edge} />
        </foreignObject>
      </g>
    </Tooltip>
  );
}

function StampingEdgeIcon({ edge }: { edge: Edge }) {
  if (edge.type !== 'stamping') return null;

  return edge.source.deckPositionName === edge.destination.deckPositionName ? (
    <CachedIcon sx={{ fontSize: 10, mt: -2, mb: 2 }} />
  ) : (
    <StampingIcon />
  );
}

function RegularEdgeLabel({
  edge,
  curve,
  pathLengthSquared,
  deckItemDict,
  isSelected,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: Props) {
  const { label, eventHandlers, textRef } = useEdgeLabelCommon({
    edge,
    pathLengthSquared,
    onClick,
    onMouseEnter,
    onMouseLeave,
  });

  return (
    <Tooltip arrow title={<EdgeTooltipTitle edge={edge} deckItemDict={deckItemDict} />}>
      <g
        transform={`translate(${curve.center.x},${curve.center.y}) rotate(${curve.angle})`}
      >
        <LabelRect
          isSelected={isSelected}
          x={-label.width / 2}
          y={label.y}
          height={label.height}
          width={label.width}
          rx={label.height / 4}
          ry={label.height / 4}
          {...eventHandlers}
          // Used to identify the click target in the MixScreen
          data-edge="true"
        />
        <LabelText
          ref={textRef}
          y={label.y + label.height / 2}
          dy={1}
          fontSize={label.fontSize}
        >
          {label.text}
        </LabelText>
      </g>
    </Tooltip>
  );
}

function useEdgeLabelCommon({
  edge,
  pathLengthSquared,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: Pick<
  Props,
  'edge' | 'pathLengthSquared' | 'onClick' | 'onMouseEnter' | 'onMouseLeave'
>) {
  // We can use the path length to determine the right fontSize for the label,
  // so that short paths (adjacent wells) won't have any text cropped
  const isShortPath = pathLengthSquared < ADJACENT_WELL_AVG_LENGTH_SQUARED;
  const computedFontSize = isShortPath ? LABEL_FONT_SIZE_SMALL : LABEL_FONT_SIZE;
  // Add padding to top and bottom
  const labelHeight = computedFontSize + LABEL_Y_PADDING * 2;
  const pathLength = Math.sqrt(pathLengthSquared);

  const handleMouseEnter = () => onMouseEnter?.(edge);
  const handleClick = () => onClick?.(edge);

  const labelText = formatEdgeLabel(edge);

  // The background rect will be sized based on the text width
  const [labelWidth, setLabelWidth] = useState<number>(0);
  // Usually labelY will be 0, which means it is on top of the arrow path.
  // However, if path is short then the label should be offset above the path.
  const [labelY, setLabelY] = useState<number>(0);

  // When the <text> element is mounted, get the width of the text and check if
  // oversized.
  const textRef = (textEl: SVGTextElement | null) => {
    if (textEl) {
      // Add padding each side
      const labelWidth = textEl.getBBox().width + LABEL_X_PADDING * 2;
      setLabelWidth(labelWidth);
      if (labelWidth > pathLength) {
        // If the label is larger than the path, then offset it above the path.
        setLabelY(-labelHeight - OVERSIZED_LABEL_OFFSET);
      } else {
        // Offset the label by half the label height, such that it's centered
        // on the path.
        setLabelY(-labelHeight / 2);
      }
    }
  };

  return {
    textRef,
    label: {
      fontSize: computedFontSize,
      text: labelText,
      width: labelWidth,
      height: labelHeight,
      y: labelY,
    },
    eventHandlers: {
      onClick: handleClick,
      onMouseEnter: handleMouseEnter,
      onMouseLeave,
    },
  };
}

type EdgeTooltipTitleProps = {
  edge: Edge;
  deckItemDict: Map<string, DeckItemState>;
};

function EdgeTooltipTitle({ edge, deckItemDict }: EdgeTooltipTitleProps) {
  let content: JSX.Element;
  switch (edge.type) {
    case 'liquid_transfer':
    case 'liquid_dispense': {
      const liquidDestination = edge.action.liquidDestination;
      const tipLocation = edge.action.tipDestination.loc;
      const sourceDeckItem = deckItemDict.get(edge.action.from.loc.deck_item_id);
      const destDeckItem = deckItemDict.get(tipLocation.deck_item_id);

      content = (
        <TooltipTable>
          <TooltipHeadline>Liquid Transfer</TooltipHeadline>
          <dl>
            <dt>Source:</dt>
            <dd>
              {sourceDeckItem?.name} ({formatWellPosition(edge.from)})
            </dd>
            <dt>Destination:</dt>
            <dd>
              {destDeckItem?.name} ({formatWellPosition(tipLocation)})
            </dd>
            {edge.action.multiDispenseCount > 1 && (
              <>
                <dt>Multi-dispense:</dt>
                <dd>
                  {edge.action.multiDispenseIndex + 1} of {edge.action.multiDispenseCount}
                </dd>
              </>
            )}
            <dt>Dispense Volume:</dt>
            <dd>{formatVolumeWithStdDev(edge.action.volume)}</dd>
            {liquidDestination.volume_in_tip !== undefined && (
              <>
                <dt>Volume remaining in tip:</dt>
                <dd>{formatVolumeWithStdDev(liquidDestination.volume_in_tip)}</dd>
              </>
            )}
            <dt>Policy:</dt>
            <dd>{edge.action.policy}</dd>
            {edge.action.asp.device_liquid_class_name && (
              <>
                <dt>Aspiration Liquid Class:</dt>
                <dd>{edge.action.asp.device_liquid_class_name}</dd>
              </>
            )}
            {edge.action.dsp.device_liquid_class_name && (
              <>
                <dt>Dispense Liquid Class:</dt>
                <dd>{edge.action.dsp.device_liquid_class_name}</dd>
              </>
            )}
          </dl>
        </TooltipTable>
      );
      break;
    }
    case 'filtration': {
      const liquidDestination = edge.action.liquidDestination;
      const destDeckItem = deckItemDict.get(liquidDestination.loc.deck_item_id);
      content = (
        <TooltipTable>
          <TooltipHeadline>Liquid Filtration</TooltipHeadline>
          <dl>
            <dt>Destination:</dt>
            <dd>
              {destDeckItem?.name} ({formatWellPosition(edge.to)})
            </dd>
            <dt>Volume:</dt>
            <dd>{formatVolumeWithStdDev(edge.action.volume)}</dd>
          </dl>
        </TooltipTable>
      );
      break;
    }
    case 'move_plate':
      content = (
        <>
          Move {edge.deckItemTypes.join(' and ')} from {edge.fromDeckPositionName} to{' '}
          {edge.toDeckPositionName}
        </>
      );
      break;
    case 'stamping': {
      const aspirateLiquidClass = edge.action.asp.device_liquid_class_name;
      const dispenseLiquidClass = edge.action.dsp.device_liquid_class_name;
      const dispenseVolume = formatVolumeWithStdDev(edge.action.volume);
      const liquidDestination = edge.action.liquidDestination;

      let headlineText: string = 'Plate Stamping';
      if (edge.source.deckPositionName === edge.destination.deckPositionName) {
        headlineText = 'Stamping in the Same Well';
      }

      content = (
        <TooltipTable>
          <TooltipHeadline>{headlineText}</TooltipHeadline>
          <dl>
            <dt>Dispense volume per tip:</dt>
            <dd>{dispenseVolume}</dd>
            {liquidDestination.volume_in_tip &&
              liquidDestination.volume_in_tip.value > 0 && (
                <>
                  <dt>Volume remaining in tip:</dt>
                  <dd>{formatVolumeWithStdDev(liquidDestination.volume_in_tip)}</dd>
                </>
              )}
            <dt>Number of tips used:</dt>
            <dd>{edge.channelCount}</dd>
            <dt>Policy:</dt>
            <dd>{edge.action.policy}</dd>
            {aspirateLiquidClass && (
              <>
                <dt>Aspiration Liquid Class:</dt>
                <dd>{aspirateLiquidClass}</dd>
              </>
            )}
            {dispenseLiquidClass && (
              <>
                <dt>Dispense Liquid Class:</dt>
                <dd>{dispenseLiquidClass}</dd>
              </>
            )}
          </dl>
        </TooltipTable>
      );
      break;
    }
    default:
      throw new Error('Unsupported edge type');
  }
  return <TooltipTitleContainer elevation={5}>{content}</TooltipTitleContainer>;
}

function formatEdgeLabel(edge: Edge): React.ReactNode {
  switch (edge.type) {
    case 'liquid_transfer':
    case 'liquid_dispense': {
      // For multi dispense steps where liquid remains in the tip, show state of
      // the tip, e.g. "50 ul (100 ul remaining in tip)".
      const volumeInTip = edge.action.liquidDestination.volume_in_tip;
      const remainingVolume =
        volumeInTip && volumeInTip.value > 0
          ? `(${formatVolumeWithStdDev(volumeInTip)} remaining in tip)`
          : '';
      return `${formatVolumeWithStdDev(edge.action.volume)} ${remainingVolume}`;
    }
    case 'filtration':
      // The outflow of a robocolumn is approximate; an unknown amount of liquid
      // may have been lost or gained during filtration.
      return '~' + formatVolumeWithStdDev(edge.action.volume);
    case 'move_plate':
      return `Moving ${edge.deckItemTypes.join(' and ')}`;
    case 'stamping': {
      // For multi dispense steps where liquid remains in the tip, show state of
      // the tip, e.g. "50 ul (100 ul remaining in tip)".
      const volumeInTip = edge.action.liquidDestination.volume_in_tip;
      const remainingVolume =
        volumeInTip && volumeInTip.value > 0
          ? `(${formatVolumeWithStdDev(volumeInTip)} remaining in tip)`
          : '';
      return (
        <>
          <tspan fontWeight="bold">{edge.channelCount} x </tspan>
          <tspan>
            {formatVolumeWithStdDev(edge.action.volume)} {remainingVolume}
          </tspan>
        </>
      );
    }
  }
}

const LabelText = styled('text')({
  fill: Colors.MIX_PREVIEW_LABEL,
  textAnchor: 'middle',
  dominantBaseline: 'middle',
  fontWeight: 'normal',
});

const LabelRect = styled('rect', { shouldForwardProp: prop => prop !== 'isSelected' })<{
  isSelected?: boolean;
}>(({ isSelected = false }) => ({
  fill: 'white',
  stroke: Colors.MIX_PREVIEW_EDGE,
  strokeWidth: 0.5,
  pointerEvents: 'all',

  ...(isSelected
    ? {
        strokeWidth: 3,
        stroke: Colors.LIQUID_TRANSFER_WELL_BORDER,
      }
    : null),
}));

const TooltipTitleContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
}));

const TooltipHeadline = styled(Typography)(({ theme }) => ({
  fontSize: TOOLTIP_FONTSIZE,
  fontWeight: 700,
  lineHeight: theme.spacing(5),
  letterSpacing: '0.4px',
}));

const TooltipTable = styled('div')(({ theme }) => ({
  fontSize: TOOLTIP_FONTSIZE,
  fontWeight: 400,
  lineHeight: theme.spacing(5),
  letterSpacing: '0.4px',

  '& dl': {
    display: 'grid',
    gap: theme.spacing(3, 2),
    margin: theme.spacing(3, 0, 0, 0),
  },

  '& dt': {
    gridColumn: 1,
  },
  '& dd': {
    margin: 0,
    gridColumn: 2,
    wordBreak: 'break-all',
  },
}));

const Tooltip = styled((props: TooltipProps) => (
  <MuiTooltip
    {...props}
    PopperProps={{
      sx: {
        [`& .${tooltipClasses.arrow}`]: {
          color: Colors.WHITE,
          width: 20,
          height: 12,
          '&:before': {
            boxShadow: `0px 0px 3px ${Colors.GREY_40}`,
          },
        },
        [`& .${tooltipClasses.tooltip}`]: {
          background: 'transparent',
          color: Colors.TEXT_PRIMARY,
          maxWidth: 350,
        },
      },
    }}
  />
))({});
