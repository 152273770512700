import React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { formatDuration } from 'common/lib/format';
import {
  getParallelTransferStageNameForStep,
  getPreviewTotalDuration,
  isParallelTransfer,
} from 'common/lib/mix';
import { MixPreviewStep } from 'common/types/mixPreview';
import { DeckItemState } from 'common/ui/components/simulation-details/mix/MixState';
import zIndex from 'common/ui/components/simulation-details/mix/zIndex';
import { getStepKind } from 'common/ui/components/simulation-details/StepSlider/helpers';
import { Props as SliderProps } from 'common/ui/components/simulation-details/StepSlider/StepSlider';

type Props = Pick<SliderProps, 'appliedSteps' | 'currentStage' | 'stages'> & {
  timeElapsed: number;
  deckItems: readonly DeckItemState[];
};

export default function StepLabel({
  appliedSteps,
  currentStage,
  stages,
  timeElapsed,
  deckItems,
}: Props) {
  let step: MixPreviewStep | undefined;
  // Skip the zeroth step, which is reserved for viewing the simulation before
  // any steps have completed.
  if (appliedSteps > 0) {
    // 1 corresponds to first step (steps[0]).
    step = stages[currentStage][appliedSteps - 1];
  }

  // For some liquid handlers, we have a cumulative time estimate to get to this step.
  const duration = formatDuration(timeElapsed);
  // If available, compute the total time the execution will take
  const totalDuration = formatDuration(getPreviewTotalDuration(stages));

  // If this is a transfer, grab the stage name. The channel we pick the stage name from
  // is arbitrary because the planner does not currently support concurrent transfers for
  // different stages (e.g channel 1 transfer for a wash stage and channel 2 transfer for
  // an elute stage).

  if (!step) {
    return (
      <Container>
        {stages.length > 1 && (
          <LabelElement variant="subtitle1">Stage {currentStage + 1}</LabelElement>
        )}
        <LabelElement variant="subtitle1">Start</LabelElement>
        {totalDuration && (
          <LabelElement variant="subtitle1">
            <TimeIcon />
            <span>0h0m0s / {totalDuration}</span>
          </LabelElement>
        )}
      </Container>
    );
  }

  const stepContent = (
    <>
      {stages.length > 1 && (
        <LabelElement variant="subtitle1">Stage {currentStage + 1}</LabelElement>
      )}
      <LabelElement variant="subtitle1">Step {appliedSteps}</LabelElement>
      <LabelElement variant="subtitle1">{getStepKind(step, deckItems)}</LabelElement>
    </>
  );

  let parallelTransferStageContent: JSX.Element | null = null;

  const parallelTransferStage = isParallelTransfer(step)
    ? getParallelTransferStageNameForStep(step)
    : undefined;

  if (parallelTransferStage) {
    parallelTransferStageContent = (
      <LabelElement variant="subtitle1">
        Parallel transfer stage: {parallelTransferStage}
      </LabelElement>
    );
  }

  let durationContent: JSX.Element | null = null;

  if (duration && totalDuration) {
    durationContent = (
      <LabelElement variant="subtitle1">
        <TimeIcon />
        <span>
          {duration} / {totalDuration}
        </span>
      </LabelElement>
    );
  } else if (duration) {
    durationContent = (
      <LabelElement variant="subtitle1">
        <TimeIcon />
        <span>{duration}</span>
      </LabelElement>
    );
  }

  return (
    <Container>
      {stepContent}
      {parallelTransferStageContent}
      {durationContent}
    </Container>
  );
}

const Container = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  left: theme.spacing(3),
  zIndex: zIndex.workspaceControls,

  borderRadius: theme.spacing(2),

  display: 'flex',
  alignItems: 'stretch',

  padding: theme.spacing(3),
  width: 'max-content',
  maxWidth: 'unset',

  boxShadow:
    '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
}));

const LabelElement = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),

  color: theme.palette.text.primary,
  fontWeight: 500,
  whiteSpace: 'nowrap',

  borderRight: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(0, 3),

  '&:last-of-type': {
    borderRight: 'none',
  },
}));

const TimeIcon = styled(AccessTimeIcon)({
  fontSize: '1.2em',
  alignSelf: 'baseline',
});
