import React, { useMemo } from 'react';

import { useMutation } from '@apollo/client';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  MUTATION_DELETE_FILES_FROM_UPLOAD,
  MUTATION_REPROCESS_UPLOAD,
} from 'client/app/api/gql/mutations';
import { Execution } from 'client/app/apps/execution-details/types';
import { useDataUploadPolling } from 'client/app/apps/simulation-details/overview/results/hooks';
import { DataFileState, DataUploadState } from 'client/app/gql';
import { pluralizeWord } from 'common/lib/format';
import { basename } from 'common/lib/strings';

const MAX_ERROR_CHARS = 300;

type Props = {
  execution: Execution;
  onRefresh: () => Promise<unknown>;
};

export default function DataProcessingAlert({ execution, onRefresh }: Props) {
  const refreshing = useUploadingProgress(execution, onRefresh);

  const { errors, dismissing, retrying, retryErrors, deleteErroringFiles } =
    useRetryDismiss(execution);

  const loader = (
    <Stack px={0} py={5} gap={5}>
      <Typography variant="h5" fontWeight={400} textAlign="center">
        Processing data, please wait...
      </Typography>
      <LinearProgress />
    </Stack>
  );

  const failedUploadsCount = useMemo(
    () =>
      errors.reduce((count, nextError) => count + nextError.filesWithErrors.length, 0),
    [errors],
  );

  const errorContent = (
    <Alert
      severity="error"
      variant="standard"
      action={
        <Stack direction="row" gap={3} alignSelf="center" pr={4}>
          <Button variant="outlined" color="inherit" onClick={retryErrors}>
            Retry
          </Button>
          <Button variant="outlined" color="inherit" onClick={deleteErroringFiles}>
            Remove {pluralizeWord(failedUploadsCount, 'file')}
          </Button>
        </Stack>
      }
    >
      <AlertTitle>Data processing errors</AlertTitle>

      {errors.flatMap(u =>
        u.filesWithErrors.map(f => (
          <Stack direction="row" gap={2} key={f.originFiletreeLink}>
            <Typography variant="body1" component="span">
              Error processing &quot;{basename(f.originFiletreeLink)}&quot;:
            </Typography>
            {f.error && f.error.length < MAX_ERROR_CHARS ? (
              <Typography variant="body1" component="span">
                {f.error}
              </Typography>
            ) : f.state === DataFileState.IGNORED ? (
              <Typography variant="body1" component="span">
                The type of this file is not supported and the file could not be processed
              </Typography>
            ) : (
              <Typography variant="body1" component="span">
                An unknown error occurred during processing
              </Typography>
            )}
          </Stack>
        )),
      )}
    </Alert>
  );

  return refreshing || dismissing || retrying
    ? loader
    : errors.length > 0
    ? errorContent
    : null;
}

function useUploadingProgress(execution: Execution, onRefresh: () => Promise<unknown>) {
  const uploadsInProgress = execution.uploads.filter(
    u => u.state === DataUploadState.PENDING || u.state === DataUploadState.PROCESSING,
  );

  useDataUploadPolling(uploadsInProgress[0]?.id, onRefresh);

  return uploadsInProgress.length > 0;
}

function useRetryDismiss(execution: Execution) {
  const errors = useUploadErrors(execution);

  const [reprocessUpload, { loading: retrying }] = useMutation(MUTATION_REPROCESS_UPLOAD);
  const retryErrors = async () => {
    await Promise.all(
      errors.map(u =>
        reprocessUpload({
          variables: { uploadId: u.id },
          // We expect this call will just update the state to PROCESSING, so return it
          // optimistically so the UI can immediately show the loading bar associated with
          // PROCESSING uploads.
          optimisticResponse: {
            __typename: 'Mutation',
            reprocessUpload: {
              __typename: 'DataUpload',
              id: u.id,
              state: DataUploadState.PROCESSING,
            },
          },
        }),
      ),
    );
  };

  const [deleteFilesFromUpload, { loading: dismissing }] = useMutation(
    MUTATION_DELETE_FILES_FROM_UPLOAD,
  );
  const deleteErroringFiles = async () => {
    await Promise.all(
      errors.map(u =>
        deleteFilesFromUpload({
          variables: {
            uploadId: u.id,
            fileIds: u.filesWithErrors.map(f => f.id),
          },
        }),
      ),
    );
  };

  return {
    errors,
    dismissing,
    retrying,
    retryErrors,
    deleteErroringFiles,
  };
}

function useUploadErrors(execution: Execution) {
  return useMemo(
    () =>
      execution.uploads
        .filter(u => u.state === DataUploadState.COMPLETE)
        .map(u => ({
          id: u.id,
          filesWithErrors: u.dataFiles.items.filter(
            df => df.state !== DataFileState.COMPLETE,
          ),
        }))
        .filter(u => u.filesWithErrors.length > 0),
    [execution.uploads],
  );
}
