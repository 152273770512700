import React from 'react';

import { useQuery } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';

import { QUERY_USERS_ROLES } from 'client/app/api/gql/queries';
import TextField from 'common/ui/filaments/TextField';

export type RecipientOption = { displayName?: string; email: string };

export default function RecipientsAutocomplete({
  className,
  value,
  onChange,
}: {
  className?: string;
  value: RecipientOption[];
  onChange: (value: RecipientOption[]) => void;
}) {
  const { data: users } = useQuery(QUERY_USERS_ROLES);

  return (
    <Autocomplete
      className={className}
      multiple
      freeSolo
      options={users?.users ?? []}
      value={value}
      onChange={(_event, newValue, reason) => {
        switch (reason) {
          case 'createOption': {
            const addedValue = newValue.at(-1);
            if (typeof addedValue === 'string') {
              onChange([...value, { email: addedValue }]);
            }
            break;
          }
          default: {
            onChange(newValue.filter((v): v is RecipientOption => typeof v !== 'string'));
            break;
          }
        }
      }}
      renderInput={params => (
        <TextField {...params} label="Recipients" placeholder="Add names or emails..." />
      )}
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.displayName ?? option.email
      }
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              key={key}
              size="small"
              label={option.displayName ?? option.email}
              color="primary"
              {...tagProps}
            />
          );
        })
      }
    />
  );
}
