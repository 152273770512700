import React, { useState } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  calculateAbsorbance,
  calculateTransmittance,
} from 'client/app/apps/standalone-tools/absorbance-transmittance-converter/calculation';
import { validateAndConvertToDecimal } from 'client/app/apps/standalone-tools/utils';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import Toggle, { ToggleButton } from 'common/ui/components/Toggle/Toggle';
import TextField from 'common/ui/filaments/TextField';

export default function AbsorbanceTransmittanceConverter() {
  const [mode, setMode] = useState<'Absorbance' | 'Transmittance'>('Absorbance');

  const [absorbance, setAbsorbance] = useState<string | undefined>('');
  const [transmittance, setTransmittance] = useState<string | undefined>('');
  const [result, setResultState] = useState<string | undefined>('');
  const [isErrored, setIsErrored] = useState(false);

  const isTransmittance = mode === 'Transmittance';

  function calculate() {
    const value = isTransmittance ? transmittance : absorbance;
    const decimalValue = validateAndConvertToDecimal(value ?? '');

    if (decimalValue !== null) {
      const result = isTransmittance
        ? calculateAbsorbance(decimalValue)
        : calculateTransmittance(decimalValue);
      setIsErrored(false);
      setResultState(result);
    } else {
      setIsErrored(true);
      setResultState('');
    }
  }

  const handleSwitchChange = (event: React.MouseEvent<HTMLElement>, value: any) => {
    setMode(value);
    setAbsorbance('');
    setTransmittance('');
    setResultState('');
    setIsErrored(false);
  };

  return (
    <>
      <InputsSection>
        <Typography>
          The Absorbance/Transmittance converter can be used to determine light
          transmittance from an optical density reading and vice versa. Absorbance values
          are given in arbitrary units (AU) and transmittance values are provided as a
          percentage. The converter provides values half-even rounded to 9 decimal places.
        </Typography>
        <TargetToggle value={mode} onChange={handleSwitchChange} exclusive>
          <ToggleButton value="Absorbance">Absorbance</ToggleButton>
          <ToggleButton value="Transmittance">Transmittance</ToggleButton>
        </TargetToggle>
        <FieldsContainer>
          {isTransmittance ? (
            <>
              <Label>Transmittance</Label>
              <StyledTextField
                value={transmittance}
                onChange={e => {
                  setTransmittance(e.target.value);
                }}
                error={isErrored}
                helperText={
                  isErrored
                    ? 'The transmittance value must be a non-negative, numeric value'
                    : ' '
                }
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </>
          ) : (
            <>
              <Label>Absorbance</Label>
              <StyledTextField
                value={absorbance}
                onChange={e => {
                  setAbsorbance(e.target.value);
                }}
                error={isErrored}
                helperText={
                  isErrored
                    ? 'The absorbance value must be a non-negative, numeric value'
                    : ' '
                }
                InputProps={{
                  endAdornment: <InputAdornment position="end">AU</InputAdornment>,
                }}
              />
            </>
          )}
        </FieldsContainer>
      </InputsSection>
      <ResultSection>
        <Button
          variant="primary"
          color="primary"
          onClick={calculate}
          data-heap-tracking="standalone-tools-absorbance-transmittance-calculator-calculate"
        >
          Calculate
        </Button>
        <StyledTextField
          value={result}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isTransmittance ? 'AU' : '%'}
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                {isTransmittance ? 'Absorbance' : 'Transmittance'}
              </InputAdornment>
            ),
            sx: {
              background: Colors.WHITE,
            },
          }}
          contentEditable={false}
        />
      </ResultSection>
    </>
  );
}

const FieldsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '4fr',
  rowGap: theme.spacing(3),
}));

const Label = styled(Typography)({
  gridColumn: 'span 2',
});

const InputsSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(6, 6, 3, 6),
}));

const ResultSection = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: theme.spacing(4),
  background: Colors.GREY_10,
  borderTop: `1px solid ${Colors.GREY_20}`,
  padding: theme.spacing(6),
}));

const StyledTextField = styled(TextField)({
  '& input': {
    textAlign: 'right',
  },
});

const TargetToggle = styled(Toggle)(({ theme }) => ({
  margin: theme.spacing(6, 0),
}));
