import React, { useState } from 'react';

import { ScreenRegistry } from 'client/app/registry';
import SearchField, { SearchFieldLogProps } from 'common/ui/components/SearchField';

const FILTER_DEBOUNCE_TIME_MS = 150;

type Props = {
  onElementFilterChange: (query: string) => void;
};

export default React.memo(function ElementFilter(props: Props) {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchQueryChanged = (query: string) => {
    setSearchQuery(query);
    props.onElementFilterChange(query);
  };
  const searchFieldLogProps: SearchFieldLogProps = {
    logAction: 'search-elements-list',
    logCategory: ScreenRegistry.WORKFLOW,
  };

  return (
    <SearchField
      label="Search"
      defaultValue={searchQuery}
      fullWidth
      onQueryChange={handleSearchQueryChanged}
      debounceMs={FILTER_DEBOUNCE_TIME_MS}
      searchFieldLogProps={searchFieldLogProps}
      autoFocus
    />
  );
});
