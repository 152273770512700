import { ReactNode } from 'react';
import React from 'react';

import { styled } from '@mui/material/styles';

type Props = {
  left?: ReactNode;
  right?: ReactNode;
};

export default function ListPageHeader({ left, right }: Props) {
  return (
    <Header>
      <LeftSection>{left}</LeftSection>
      <RightSection>{right}</RightSection>
    </Header>
  );
}

const Header = styled('header')(({ theme }) => ({
  display: 'grid',
  gridTemplate: 'auto / 1fr max-content',
  marginBottom: theme.spacing(5),
  gap: theme.spacing(5),
  alignItems: 'end',
}));

const LeftSection = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(5),
  alignItems: 'center',
}));

const RightSection = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(5),
  alignItems: 'end',
}));
