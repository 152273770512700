import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import TrashIcon from '@mui/icons-material/DeleteOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

import { DatasetSettingsContext } from 'client/app/apps/experiments/dataset-settings/DatasetSettingsContext';
import DatasetSettingsError from 'client/app/apps/experiments/dataset-settings/DatasetSettingsError';
import {
  convertMomentToMomentTimezone,
  useFormatTimeStamp,
} from 'client/app/apps/experiments/dataset-settings/datasetSettingsUtils';
import DateTimePicker from 'client/app/apps/experiments/dataset-settings/DateTimePicker';
import { useBioreactorDatasetColumns } from 'client/app/apps/experiments/dataset-settings/useBioreactorDatasetColumns';
import {
  MUTATION_CREATE_DATASET_EVENT,
  MUTATION_REMOVE_DATASET_EVENT,
} from 'client/app/apps/experiments/gql/mutations';
import { QUERY_DATASET_EVENTS } from 'client/app/apps/experiments/gql/queries';
import { ArrayElement, DatasetWithSettingsQuery } from 'client/app/gql';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import Checkbox from 'common/ui/components/Checkbox';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import IconButton from 'common/ui/components/IconButton';
import { TableHeaderCell } from 'common/ui/components/TableHeaderCell';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useCheckboxChange from 'common/ui/hooks/useCheckboxChange';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  timezone: string;
};

export function DatasetSettingsEventsTab({ timezone }: Props) {
  const { dataset } = useContext(DatasetSettingsContext);
  const classes = useStyles();
  const bioreactorDatasetColumns = useBioreactorDatasetColumns(dataset);

  const [selectedBioreactor, setSelectedBioreactor] = useState<string | undefined>(
    bioreactorDatasetColumns?.bioreactorColumnValues[0],
  );

  if (!bioreactorDatasetColumns) {
    return (
      <DatasetSettingsError
        title="No bioreactors"
        subtitle="Please enter bioreactor names on the Details tab."
      />
    );
  }

  return (
    <div className={classes.container}>
      <EventBioreactorList
        bioreactors={bioreactorDatasetColumns.bioreactorColumnValues}
        selectedBioreactor={selectedBioreactor}
        setSelectedBioreactor={setSelectedBioreactor}
      />
      {selectedBioreactor && (
        <EventTable
          rowKey={{ [bioreactorDatasetColumns.bioreactorColumnName]: selectedBioreactor }}
          bioreactorName={selectedBioreactor}
          timezone={timezone}
        />
      )}
    </div>
  );
}

function EventBioreactorList({
  bioreactors,
  selectedBioreactor,
  setSelectedBioreactor,
}: {
  bioreactors: readonly string[];
  selectedBioreactor?: string;
  setSelectedBioreactor: (bioreactor: string) => void;
}) {
  const classes = useStyles();
  return (
    <MenuList autoFocus dense className={classes.bioreactorList}>
      {bioreactors.map(bioreactorName => (
        <Tooltip title={bioreactorName} key={bioreactorName}>
          <MenuItem
            selected={selectedBioreactor === bioreactorName}
            autoFocus={selectedBioreactor === bioreactorName}
            onFocus={() => setSelectedBioreactor(bioreactorName)} // use focus, not click, so that up/down keys change selection
          >
            {bioreactorName}
          </MenuItem>
        </Tooltip>
      ))}
    </MenuList>
  );
}

const DUPLICATE_STOP = 'There is already a STOP event';
const DUPLICATE_START = 'There is already a START event';
const EMPTY = 'The event name cannot be empty';
type Invalidity = false | typeof DUPLICATE_STOP | typeof DUPLICATE_START | typeof EMPTY;

function EventTable({
  bioreactorName,
  rowKey,
  timezone,
}: Props & { bioreactorName: string; rowKey: Record<string, string> }) {
  const { dataset, isReadonly } = useContext(DatasetSettingsContext);
  const classes = useStyles();

  const [showNewEventRow, setShowNewEvent] = useState<boolean>(false);

  const events = dataset.events.filter(event => isEqual(event.rowKey, rowKey));

  const isEventNameInvalid = useCallback(
    (rawName: string): Invalidity => {
      const name = rawName.trim().toUpperCase();
      const hasAlreadyStartEvent = events?.some(e => e.name === 'START');
      const hasAlreadyStopEvent = events?.some(e => e.name === 'STOP');
      if (name === '') {
        return EMPTY;
      }
      if (hasAlreadyStopEvent && name === 'STOP') {
        return DUPLICATE_STOP;
      }
      if (hasAlreadyStartEvent && name === 'START') {
        return DUPLICATE_START;
      }
      return false;
    },
    [events],
  );

  const nameSuggestions = useMemo(() => {
    return (dataset.eventOptions?.names ?? []).filter(name => !isEventNameInvalid(name));
  }, [dataset.eventOptions?.names, isEventNameInvalid]);

  const [createDatasetEvent, { error: eventCreationError, loading: isCreatingEvent }] =
    useMutation(MUTATION_CREATE_DATASET_EVENT);

  const handleNewEvent = async (newEvent: NewEvent) => {
    setShowNewEvent(false);
    await createDatasetEvent({
      variables: {
        datasetId: dataset.datasetId,
        name: newEvent.name,
        rowKeyJson: JSON.stringify(newEvent.rowKey),
        occurredAt: newEvent.occurredAt,
        payloadJson: JSON.stringify(newEvent.payload),
      },
      refetchQueries: [
        { query: QUERY_DATASET_EVENTS, variables: { id: dataset.datasetId } },
      ],
    });
  };

  const timeZeroEvent = useRef<HTMLTableRowElement>(null);
  const jumpToTimeZero = () => {
    timeZeroEvent.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className={classes.eventsTablePane}>
      <div className={classes.eventsTableHeader}>
        <Typography
          className={classes.bioreactorName}
          variant="h3"
          color="textPrimary"
          paragraph
        >
          Bioreactor: {bioreactorName}
        </Typography>
        <Button
          variant="tertiary"
          disabled={!timeZeroEvent.current}
          onClick={jumpToTimeZero}
        >
          Go to Time Zero
        </Button>
      </div>
      <div className={classes.eventsTableContainer}>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableHeaderCell className={classes.nameCol}>Event name</TableHeaderCell>
              <TableHeaderCell className={classes.descriptionCol}>
                Description
              </TableHeaderCell>
              <TableHeaderCell className={classes.zeroPointCol} align="center">
                Time zero
              </TableHeaderCell>
              <TableHeaderCell className={classes.timestampCol} colSpan={2}>
                Timestamp
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events?.map(event => (
              <EventRow
                key={event.id}
                event={event}
                datasetId={dataset.datasetId}
                isReadonly={isReadonly}
                ref={event.payload?.zero_point ? timeZeroEvent : null}
              />
            ))}
            {events?.length === 0 && !showNewEventRow && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography color="textSecondary">No events</Typography>
                </TableCell>
              </TableRow>
            )}
            {showNewEventRow && (
              <NewEventRow
                rowKey={rowKey}
                nameSuggestions={nameSuggestions}
                onCancel={() => setShowNewEvent(false)}
                onSave={handleNewEvent}
                timezone={timezone}
                // Only one event can be set as zero point
                allowZeroPoint={events?.every(event => !event.payload?.zero_point)}
                isEventNameInvalid={isEventNameInvalid}
              />
            )}
          </TableBody>
        </Table>
        {isCreatingEvent && <CircularProgress />}
        {eventCreationError && <GraphQLErrorPanel error={eventCreationError} />}
        {!showNewEventRow && !isReadonly && (
          <Button
            className={classes.addEventButton}
            variant="secondary"
            color="primary"
            size="small"
            onClick={() => setShowNewEvent(true)}
          >
            Add Event
          </Button>
        )}
      </div>
    </div>
  );
}

type Event = ArrayElement<DatasetWithSettingsQuery['dataset']['events']>;

type EventRowProps = {
  event: Event;
  datasetId: DatasetId;
  isReadonly?: boolean;
};

const EventRow = React.forwardRef<HTMLTableRowElement, EventRowProps>(function EventRow(
  { event, datasetId, isReadonly },
  ref,
) {
  const [removeDatasetEvent, { loading: isDeleting }] = useMutation(
    MUTATION_REMOVE_DATASET_EVENT,
  );

  const handleRemove = async () => {
    await removeDatasetEvent({
      variables: { id: event.id },
      refetchQueries: [{ query: QUERY_DATASET_EVENTS, variables: { id: datasetId } }],
    });
  };

  const formatTimeStamp = useFormatTimeStamp();

  return (
    <TableRow ref={ref}>
      <TableCell>{event.name}</TableCell>
      <TableCell>{event.payload?.description}</TableCell>
      <TableCell padding="checkbox" align="center">
        <Checkbox checked={event.payload?.zero_point ?? false} disabled />
      </TableCell>
      <TableCell>{formatTimeStamp(event.occurredAt)}</TableCell>
      <TableCell align="right">
        {!isReadonly && (
          <>
            {isDeleting ? (
              <CircularProgress size={21} />
            ) : (
              <IconButton
                icon={<TrashIcon />}
                size="xsmall"
                disabled={isReadonly}
                onClick={handleRemove}
              />
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
});

type NewEvent = Omit<
  ArrayElement<DatasetWithSettingsQuery['dataset']['events']>,
  '__typename' | 'id'
>;

type NewEventRowProps = {
  rowKey: Record<string, string>;
  nameSuggestions: string[];
  onSave: (newTag: NewEvent) => void;
  onCancel: () => void;
  timezone: string;
  allowZeroPoint?: boolean;
  isEventNameInvalid: (name: string) => Invalidity;
};

function NewEventRow({
  rowKey,
  nameSuggestions,
  onSave,
  onCancel,
  timezone,
  allowZeroPoint,
  isEventNameInvalid,
}: NewEventRowProps) {
  const classes = useStyles();

  const [timestamp, setTimestamp] = useState<moment.Moment>(moment());

  const [newEvent, setNewEvent] = useState<NewEvent>({
    name: '',
    occurredAt: convertMomentToMomentTimezone(timestamp, timezone).format(),
    rowKey,
    payload: {},
  });

  const handleNameChange = (name: string) => {
    setNewEvent(event => ({ ...event, name }));
  };

  const handleDescriptionChange = useTextFieldChange(description =>
    setNewEvent(event => ({
      ...event,
      payload: { ...event.payload, description },
    })),
  );

  const handleTimestampChange = useCallback(
    (value: moment.Moment | null) => {
      if (!value) {
        return;
      }
      setTimestamp(value);
      setNewEvent(event => ({
        ...event,
        occurredAt: convertMomentToMomentTimezone(value, timezone).format(),
      }));
    },
    [timezone],
  );

  const handleZeroPointChange = useCheckboxChange(isZeroPoint =>
    setNewEvent(event => ({
      ...event,
      payload: { ...event.payload, zero_point: isZeroPoint },
    })),
  );

  const invalidEvent = useMemo(
    () => isEventNameInvalid(newEvent.name),
    [newEvent.name, isEventNameInvalid],
  );
  const eventValidityLabel = invalidEvent ?? '';
  return (
    <>
      <TableRow className={classes.newEventRow}>
        <TableCell>
          <Autocomplete
            freeSolo
            options={nameSuggestions}
            value={newEvent.name}
            onInputChange={(_, value) => value && handleNameChange(value)}
            renderInput={params => (
              <TextField {...params} variant="outlined" margin="dense" fullWidth />
            )}
            disableClearable
          />
        </TableCell>
        <TableCell>
          <TextField
            value={newEvent.payload?.description ?? ''}
            onInput={handleDescriptionChange}
            margin="dense"
            fullWidth
            variant="outlined"
          />
        </TableCell>
        <TableCell
          padding="checkbox"
          align="center"
          className={cx(classes.checkbox, classes.centeredCheckbox)}
        >
          {!allowZeroPoint ? (
            <Tooltip title="Only one event can be marked time zero">
              <div>
                <Checkbox checked={false} disabled />
              </div>
            </Tooltip>
          ) : (
            <Checkbox
              checked={newEvent.payload?.zero_point ?? false}
              onChange={handleZeroPointChange}
            />
          )}
        </TableCell>
        <TableCell colSpan={2}>
          <DateTimePicker value={timestamp} onChange={handleTimestampChange} />
        </TableCell>
      </TableRow>
      <TableRow className={classes.newEventRow}>
        <TableCell colSpan={5} align="right">
          <Button variant="secondary" size="small" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Tooltip title={eventValidityLabel}>
            <span>
              <Button
                disabled={!!invalidEvent}
                onClick={() => onSave(newEvent)}
                variant="secondary"
                color="primary"
                size="small"
                className={classes.saveButton}
              >
                Save
              </Button>
            </span>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
    height: '100%',
  },
  bioreactorList: {
    overflow: 'auto',
    borderRight: `1px solid ${Colors.GREY_20}`,
  },
  eventsTablePane: {
    margin: theme.spacing(2, 0, 0, 4),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  eventsTableContainer: {
    overflow: 'auto',
    flex: '1 1',
    paddingBottom: theme.spacing(7),
  },
  eventsTableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  table: {
    '& td': { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) },
  },
  bioreactorName: {
    // Horizontally align with the first table column
    paddingLeft: theme.spacing(3),
  },
  nameCol: { width: '160px' },
  descriptionCol: { width: '200px' },
  timestampCol: { width: '100px' },
  zeroPointCol: { width: '90px' },
  deleteCol: { width: '60px' },
  newEventRow: {
    verticalAlign: 'top',
    '& td': {
      borderBottom: 'none',
      paddingTop: theme.spacing(5),
      paddingBottom: 0,
    },
  },
  saveButton: { marginLeft: theme.spacing(3) },
  // We want to align the checkbox with the text of the neighbour outlined TextField.
  // We use a hardcoded fine tuned value. This is bad. But I know no other way.
  centeredCheckbox: {
    '&$checkbox': { paddingTop: theme.spacing(4) },
  },
  checkbox: {
    // increase specificity
  },
  addEventButton: { marginTop: theme.spacing(6) },
}));
