import { useMutation } from '@apollo/client';

import { MUTATION_CREATE_AND_LINK_WORKFLOW } from 'client/app/apps/experiments/gql/mutations';
import { QUERY_EXPERIMENT } from 'client/app/apps/experiments/gql/queries';
import { QUERY_EXPERIMENT_WORK_TREE } from 'client/app/apps/work-tree/queries';
import { WorkflowSourceEnum } from 'client/app/gql';
import { EditorType, ServerSideElements } from 'common/types/bundle';

/**
 * Creates a new (default empty) workflow in the given experiment.
 */
export const useCreateAndLinkWorkflowToExperiment = () => {
  const [createWorkflowInExperimentMutation] = useMutation(
    MUTATION_CREATE_AND_LINK_WORKFLOW,
  );

  const createWorkflowInExperiment = async (
    experimentId: ExperimentId,
    source: EditorType,
    elements?: ServerSideElements,
  ): Promise<WorkflowId | undefined> => {
    const { data } = await createWorkflowInExperimentMutation({
      variables: {
        experimentID: experimentId,
        source: WorkflowSourceEnum[source],
        elements: elements,
      },
      refetchQueries: [
        {
          query: QUERY_EXPERIMENT,
          variables: { id: experimentId },
        },
        {
          query: QUERY_EXPERIMENT_WORK_TREE,
          variables: { experimentId },
        },
      ],
    });
    return data?.createWorkflowInExperiment?.id;
  };

  return createWorkflowInExperiment;
};
