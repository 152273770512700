// This is not an exhaustive list of keys. For that, checkout the massive list
// here: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
const Keys = {
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',

  BACKSPACE: 'Backspace',
  CONTROL: 'Control',
  DELETE: 'Delete',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  META: 'Meta',
  TAB: 'Tab',

  A: 'a',
  G: 'g',
  H: 'h',
  Z: 'z',
  R: 'r',

  ZERO: '0',
  ONE: '1',
  NINE: '9',

  BANG: '!',
  QUESTION_MARK: '?',
  OPEN_PARENTHESIS: '(',
};

export default Keys;
