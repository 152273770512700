import React, { useEffect, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import Factors from 'client/app/components/DOEBuilder/components/Factors';
import { countActiveFactors } from 'client/app/components/Parameters/doeUtils';
import Stepper, { Step } from 'client/app/components/Stepper';
import VisserverIFrame from 'client/app/components/VisserverIFrame';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  workflowId: WorkflowId;
  className?: string;
  isEditingParameterFactor: boolean;
  shouldLoadIframe: boolean;
  isReadonly: boolean;
  onClose: () => void;
};

type DOEStep = 'factors' | 'design';

export default function DOEBuilderPanel({
  workflowId,
  className,
  isEditingParameterFactor,
  shouldLoadIframe,
  isReadonly,
  onClose: handleClose,
}: Props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState<DOEStep>(
    isEditingParameterFactor ? 'factors' : 'design',
  );
  const [isLoading, setLoading] = useState(true);

  const content = useMemo(() => {
    switch (activeStep) {
      case 'factors':
        return <Factors workflowId={workflowId} isReadonly={isReadonly} />;
      case 'design':
      default:
        return (
          <>
            {isLoading && <Loader />}
            {shouldLoadIframe && (
              <VisserverIFrame
                view="doe_designer"
                params={{
                  workflow_id: workflowId,
                }}
                onReady={() => setLoading(false)}
                onReload={() => setLoading(true)}
                shouldNotResize
              />
            )}
          </>
        );
    }
  }, [activeStep, isLoading, isReadonly, shouldLoadIframe, workflowId]);

  return (
    <Grow in unmountOnExit>
      <Paper className={cx(className, classes.panel)} elevation={4}>
        <header>
          <Typography variant="h5" color="textPrimary">
            DOE Design
          </Typography>
          <DOEStepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isReadonly={isReadonly}
          />
          <CloseIcon className="close" onClick={handleClose} />
        </header>
        <main>{content}</main>
      </Paper>
    </Grow>
  );
}

type DOEStepperProps = {
  activeStep: DOEStep;
  setActiveStep: (step: DOEStep) => void;
  isReadonly: boolean;
};

function DOEStepper({ activeStep, setActiveStep, isReadonly }: DOEStepperProps) {
  const factors = useWorkflowBuilderSelector(state => state.factors);
  const factorsCount = factors ? countActiveFactors(factors) : 0;

  useEffect(() => {
    if (factorsCount === 0) {
      setActiveStep('factors');
    }
  }, [factorsCount, setActiveStep]);

  const steps = useMemo(() => {
    const res: Step<DOEStep>[] = [
      {
        value: 'factors',
        label: 'Factors',
        onSelectStep: () => setActiveStep('factors'),
        number: factorsCount,
      },
    ];
    // Until the Design app can restore it's state and show a read only
    // mode, don't let the user navigate here
    if (!isReadonly) {
      res.push({
        value: 'design',
        label: 'Calculate design',
        onSelectStep: () => setActiveStep('design'),
        disabled: factorsCount === 0,
      });
    }
    return res;
  }, [factorsCount, isReadonly, setActiveStep]);

  return <Stepper<DOEStep> activeStepValue={activeStep} steps={steps} />;
}

const Loader = () => (
  <Box display="flex" width="100%" height="100%" padding="20px">
    <CircularProgress size={40} />
  </Box>
);

const useStyles = makeStylesHook(({ spacing }) => ({
  panel: {
    backgroundColor: Colors.WHITE,
    borderRadius: spacing(3),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

    '& header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(3, 5),
      borderBottom: `1px solid ${Colors.GREY_20}`,

      '& > .close': {
        color: Colors.GREY_50,
        cursor: 'pointer',
      },
    },
    '& main': {
      overflow: 'hidden',
      flexGrow: 1,
    },
  },
}));
