import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import { accessibleDeviceFromGraphQL } from 'client/app/api/deviceFromGraphql';
import {
  MUTATION_ADD_ACCESSIBLE_DEVICE,
  MUTATION_REMOVE_ACCESSIBLE_DEVICE,
} from 'client/app/api/gql/mutations';
import AccessibleDeviceSelectDialog from 'client/app/components/DeviceLibrary/AccessibleDeviceSelectDialog';
import AccessibleDeviceSetupDialog from 'client/app/components/DeviceLibrary/AccessibleDeviceSetupDialog';
import { ArrayElement, DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import Button from 'common/ui/components/Button';
import DeviceListItem from 'common/ui/components/DeviceListItem';
import LinearProgress from 'common/ui/components/LinearProgress';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import { EditIcon } from 'common/ui/icons/Edit';

type Props = {
  device: DeviceCommon;
};

export default function AccessibleDevicesPanel({ device }: Props) {
  const classes = useStyles();

  const [deviceSelectDialog, openDeviceSelectDialog] = useDialog(
    AccessibleDeviceSelectDialog,
  );

  const [accessibleDeviceSetupDialog, openAccessibleDeviceSetupDialog] = useDialog(
    AccessibleDeviceSetupDialog,
  );

  const snackbarManager = useSnackbarManager();

  const [addAccessibleDeviceMutation, { loading: isAddingDevice }] = useMutation(
    MUTATION_ADD_ACCESSIBLE_DEVICE,
    {
      onError: e => snackbarManager.showError(e.message),
    },
  );

  const [removeAccessibleDeviceMutation, { loading: isRemovingDevice }] = useMutation(
    MUTATION_REMOVE_ACCESSIBLE_DEVICE,
    {
      onError: e => snackbarManager.showError(e.message),
    },
  );

  const handleAddDeviceClick = useCallback(async () => {
    const selectDeviceResult = await openDeviceSelectDialog({
      title: `Select a device accessible by ${device.name}`,
    });
    if (selectDeviceResult) {
      // No need to manually update UI state once this mutation finishes.
      // The mutation returns all the fields of the device so Apollo automatically
      // updates its cache and triggers a re-render of the UI, showing the list of
      // devices including the newly added device. This is quite magical.
      await addAccessibleDeviceMutation({
        variables: {
          parentDeviceId: device.id,
          accessibleDeviceId: selectDeviceResult.id,
        },
      });
    }
  }, [openDeviceSelectDialog, device.name, device.id, addAccessibleDeviceMutation]);

  const handleRemoveDeviceClick = useCallback(
    async (accessibleDeviceId: DeviceId) => {
      await removeAccessibleDeviceMutation({
        variables: {
          parentDeviceId: device.id,
          accessibleDeviceId,
        },
      });
    },
    [device.id, removeAccessibleDeviceMutation],
  );

  type AccessibleDevice = ArrayElement<DeviceCommon['accessibleDevices']>;

  const handleEditAccessibleDevice = useCallback(
    (accessibleDevice: AccessibleDevice) => {
      void openAccessibleDeviceSetupDialog({
        parentDeviceId: device.id,
        accessibleDeviceId: accessibleDevice.id,
        accessibleDeviceName: accessibleDevice.name,
      });
    },
    [device.id, openAccessibleDeviceSetupDialog],
  );

  const isEditAccessibleDeviceSetupEnabled = useFeatureToggle('ACCESSIBLE_DEVICE_SETUP');

  return (
    <>
      {(isAddingDevice || isRemovingDevice) && <LinearProgress />}
      <Box p={6}>
        <Typography>
          Accessible devices are devices physically attached to {device.name} in the lab.
          They are connected to the same AnthaHub as {device.name}. {device.name} together
          with these devices can execute workflows in a fully automated way.
        </Typography>
        <List>
          {device.accessibleDevices.map(accessibleDevice => (
            <div key={accessibleDevice.id} className={classes.deviceListItem}>
              <DeviceListItem device={accessibleDeviceFromGraphQL(accessibleDevice)} />
              {isEditAccessibleDeviceSetupEnabled && (
                <IconButton
                  onClick={() => handleEditAccessibleDevice(accessibleDevice)}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              )}
              <IconButton
                onClick={() => handleRemoveDeviceClick(accessibleDevice.id)}
                size="large"
              >
                <ClearIcon />
              </IconButton>
            </div>
          ))}
        </List>
        <Button
          variant="secondary"
          className={classes.addButton}
          onClick={handleAddDeviceClick}
        >
          Add an accessible device
        </Button>
      </Box>
      {deviceSelectDialog}
      {accessibleDeviceSetupDialog}
    </>
  );
}

const useStyles = makeStylesHook({
  addButton: {
    marginTop: '16px',
  },
  deviceListItem: {
    display: 'flex',
    alignItems: 'center',
  },
});
