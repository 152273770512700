import React from 'react';

import InfoIcon from '@mui/icons-material/Info';

import {
  DeviceIcon,
  FileIcon,
  LiquidPolicyIcon,
  PlateIcon,
  TemplateWorkflowIcon,
  WorkflowIcon,
} from 'client/app/icons';
import { switchFail } from 'common/assert';
import CherryPickerIcon from 'common/ui/icons/CherryPickerIcon';
import CloudUploadIcon from 'common/ui/icons/CloudUpload';
import { ExecutionIcon } from 'common/ui/icons/Execution';
import SettingsIcon from 'common/ui/icons/Settings';
import { AnthaIcons } from 'common/ui/navigation';

export default function AppIcon(props: { iconId: AnthaIcons }) {
  switch (props.iconId) {
    case 'antha:workflow':
      return <WorkflowIcon />;
    case 'antha:policy':
      return <LiquidPolicyIcon />;
    case 'icons:info-outline':
      return <InfoIcon />;
    case 'antha:plate':
      return <PlateIcon />;
    case 'antha:device':
      return <DeviceIcon />;
    case 'antha:template-workflow':
      return <TemplateWorkflowIcon />;
    case 'antha:file-browser':
      return <FileIcon />;
    case 'antha:settings':
      return <SettingsIcon />;
    case 'antha:file-manager':
      return <CloudUploadIcon />;
    case 'antha:cherry-picker':
      return <CherryPickerIcon />;
    case 'antha:execution':
      return <ExecutionIcon />;
    default:
      return switchFail(props.iconId);
  }
}
