import { useCallback, useState } from 'react';

export function useDeviceFilterBar() {
  const [filterQuery, setFilterQuery] = useState('');
  const [filterModel, setFilterModel] = useState('');
  const [filterSource, setFilterSource] = useState('');
  const [filterType, setFilterType] = useState('');

  const onChangeFilterQuery = useCallback((query: string) => {
    setFilterQuery(query);
  }, []);

  const onChangeFilterModel = useCallback((model: string) => {
    setFilterModel(model);
  }, []);

  const onChangeFilterSource = useCallback((source: string[]) => {
    const stringSource = source.toString();
    setFilterSource(stringSource);
  }, []);

  const onChangeFilterType = useCallback((type: string[]) => {
    const stringType = type.toString();
    setFilterType(stringType);
  }, []);

  return {
    filterQuery,
    filterModel,
    filterSource,
    filterType,
    onChangeFilterQuery,
    onChangeFilterModel,
    onChangeFilterSource,
    onChangeFilterType,
  };
}

export type UseDeviceFilterBarType = ReturnType<typeof useDeviceFilterBar>;
