import React, { useMemo, useState } from 'react';

import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LayersIcon from '@mui/icons-material/Layers';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import keys from 'lodash/keys';

import { ParameterHeader } from 'client/app/components/Parameters/ElementParameterHeader';
import CollapsibleEditWellsButton from 'client/app/components/Parameters/PlateLayout/CollapsibleEditWellsButton';
import CollapsibleParameter from 'client/app/components/Parameters/PlateLayout/CollapsibleParameter';
import Help from 'client/app/components/Parameters/PlateLayout/Help';
import LiquidIdentifierSelect from 'client/app/components/Parameters/PlateLayout/LiquidIdentifierSelect';
import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import {
  formatLayerAutomaticName,
  getLiquidNameForColor,
  useInputLiquidNamesAndGroups,
} from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import { Liquid } from 'common/types/bundle';
import { Measurement } from 'common/types/mix';
import { Option } from 'common/types/Option';
import {
  LiquidAssignment,
  LiquidSortMode,
  PlateAssignmentMode,
  PlateLayer,
  SortOrder,
  WellSortMode,
} from 'common/types/plateAssignments';
import IconButton from 'common/ui/components/IconButton';
import GenericInputEditor from 'common/ui/components/ParameterEditors/GenericInputEditor';
import MeasurementEditor from 'common/ui/components/ParameterEditors/MeasurementEditor';
import { getSensibleMeasurementUnits } from 'common/ui/components/ParameterEditors/unitRegistry';
import Toggle, { ToggleButton } from 'common/ui/components/Toggle/Toggle';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import Dropdown from 'common/ui/filaments/Dropdown';

export default function Details() {
  const { plateLayers, selectedLiquidOrLayerId: selectedLayerOrLiquidAssignmentId } =
    usePlateLayoutEditorContext();

  const [layer, liquid] = useMemo(() => {
    if (!selectedLayerOrLiquidAssignmentId) {
      return [undefined, undefined];
    }

    for (const layer of plateLayers) {
      if (layer.id === selectedLayerOrLiquidAssignmentId) return [layer, undefined];

      for (const liquid of layer.liquids) {
        if (liquid.wellSetID === selectedLayerOrLiquidAssignmentId) {
          return [layer, liquid];
        }
      }
    }

    return [undefined, undefined];
  }, [plateLayers, selectedLayerOrLiquidAssignmentId]);

  if (!layer) {
    return null;
  }

  if (!liquid) {
    return <LayerDetails layer={layer} />;
  }

  return <LiquidDetails liquid={liquid} layer={layer} />;
}

type LayerDetailsProps = {
  layer: PlateLayer;
};

function LayerDetails(props: LayerDetailsProps) {
  const { layer } = props;
  const { isReadonly, setPlateLayerName, setSelectedLiquidOrLayerId, plateLayers } =
    usePlateLayoutEditorContext();

  const handleSetPlateLayerName = (newValue: string | undefined) => {
    setPlateLayerName(layer.id, newValue ?? '');
  };

  const layerName = formatLayerAutomaticName(layer.id, plateLayers);

  return (
    <Wrapper>
      <StyledHeader>
        <StyledIconButton
          onClick={() => setSelectedLiquidOrLayerId(undefined)}
          size="xsmall"
          icon={<ArrowBack />}
        />
        <StyledLayersIcon />
        <TypographyWithTooltip variant="subtitle2">{layerName}</TypographyWithTooltip>
      </StyledHeader>
      <InnerWrapper>
        <div>
          <CollapsibleParameter in>
            <ParameterHeader displayName="Layer Descriptor" help={Help.layerNameHelp} />
            <GenericInputEditor
              type=""
              onChange={handleSetPlateLayerName}
              value={layer.name}
              isDisabled={isReadonly}
              placeholder="Descriptor"
            />
          </CollapsibleParameter>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
}

type LiquidDetailsProps = {
  liquid: LiquidAssignment;
  layer: PlateLayer;
};

function LiquidDetails(props: LiquidDetailsProps) {
  const { liquid, layer } = props;
  const {
    isReadonly: isGlobalReadonly,
    setLiquidIdentifier,
    setLiquidVolumeOrConcentration,
    setSelectedLiquidOrLayerId,
    setWellSetSortMode,
    liquidColors,
    selectedWells,
    addSelectedWellsToSelectedLiquid,
    removeSelectedWellsFromSelectedLiquid,
    setLiquidSortOrder,
    plateAssignment,
    setHighlightedLiquidId,
    setLiquidSortSubComponent,
    inputLiquids,
    plateLayers,
  } = usePlateLayoutEditorContext();

  const isPartOfGroup = !!liquid.liquidGroup;

  const isReadonly = useMemo(
    () =>
      isGlobalReadonly ||
      plateLayers.some(layer => layer.liquids.includes(liquid) && !!layer.isReadOnly),
    [isGlobalReadonly, liquid, plateLayers],
  );

  const wellSet = layer.wellSets.find(wellSet => wellSet.id === liquid.wellSetID);

  // We use these liquids and units to populate the parameter editors below
  // TODO - Use the 'type' here to render out icons in autocomplete for name or group
  const inputLiquidNamesAndGroups = useInputLiquidNamesAndGroups(inputLiquids);
  const volumeUnits = getSensibleMeasurementUnits('Volume');
  const concentrationUnits = getSensibleMeasurementUnits('Concentration');

  const color = liquidColors.getColorFromLiquidString(getLiquidNameForColor(liquid));

  const liquidVolumeOrConcentration =
    'concentration' in liquid.target ? liquid.target.concentration : liquid.target.volume;

  const [liquidIdentifierError, setLiquidIdentifierError] = useState<string | undefined>(
    undefined,
  );
  const [liquidVolumeOrConcentrationError, setLiquidVolumeOrConcentrationError] =
    useState<string | undefined>(undefined);

  const handleSetLiquidIdentifier = (newValue: string | undefined) => {
    setLiquidIdentifierError(undefined);
    if (newValue === '' || newValue === undefined) {
      setLiquidIdentifierError('Liquid identifier cannot be empty');
      return;
    }
    const isNewValueAnExistingValue = inputLiquidNamesAndGroups.find(
      nameOrGroup => nameOrGroup.name === newValue,
    );

    const shouldSetThisPartOfAGroup =
      isNewValueAnExistingValue?.type !== 'name' &&
      isNewValueAnExistingValue?.type === 'group';

    setLiquidIdentifier(liquid.wellSetID, newValue ?? '', shouldSetThisPartOfAGroup);
  };

  const handleSetVolumeOrConcentration = (newMeasurement?: Measurement) => {
    // If the user clears the value, we set it to zero and ask them to enter a
    // value. We do this because the rest of this editor expects a measurement to
    // always be defined
    const measurement = newMeasurement ?? {
      value: 0,
      unit: liquidVolumeOrConcentration.unit,
    };
    const isVolume = volumeUnits.includes(measurement.unit);
    setLiquidVolumeOrConcentration(
      liquid.wellSetID,
      isVolume ? { volume: measurement } : { concentration: measurement },
    );
    setLiquidVolumeOrConcentrationError(
      !measurement.value
        ? 'Liquid volume or concentration cannot be zero'
        : !measurement.unit
        ? 'Liquid volume or concentration unit is required'
        : undefined,
    );
  };

  const [canAddWells, canRemoveWells] = useMemo(() => {
    if (selectedWells.length && !layer.isReadOnly) {
      const wellSet = layer.wellSets.find(ws => ws.id === liquid.wellSetID);
      let canAdd = false;
      let canRemove = false;

      for (const well of selectedWells) {
        const isInLiquid = !!wellSet?.wells.some(
          value => value.x === well.col && value.y === well.row,
        );

        canAdd = canAdd || !isInLiquid;
        canRemove = canRemove || isInLiquid;

        if (canAdd && canRemove) {
          // Early return if we know we have to show both buttons.
          break;
        }
      }

      return [canAdd, canRemove];
    }

    return [false, false];
  }, [layer.isReadOnly, layer.wellSets, liquid.wellSetID, selectedWells]);

  const subComponentOptions = useSubComponentOptions(liquid, inputLiquids);

  const liquidSortModeOptions = [
    {
      label: 'None (Original Order)',
      value: { sortOrder: SortOrder.NONE, sortBy: LiquidSortMode.NONE },
    },
    {
      label: 'Source Conc. (Low to High)',
      value: { sortOrder: SortOrder.ASCENDING, sortBy: LiquidSortMode.CONCENTRATION },
    },
    {
      label: 'Source Conc. (High to Low)',
      value: { sortOrder: SortOrder.DESCENDING, sortBy: LiquidSortMode.CONCENTRATION },
    },
  ];

  return (
    <>
      <Wrapper>
        <StyledHeader>
          <StyledIconButton
            onClick={() => {
              setSelectedLiquidOrLayerId(undefined);
              setHighlightedLiquidId(undefined);
            }}
            size="xsmall"
            icon={<ArrowBack />}
          />
          <TypographyWithTooltip variant="subtitle2">
            {liquid.liquidName ?? liquid.liquidGroup}
          </TypographyWithTooltip>
          <LiquidColor sx={{ backgroundColor: color }} />
        </StyledHeader>
        <InnerWrapper>
          <div>
            <CollapsibleParameter in>
              <ParameterHeader
                displayName="Liquid Identifier"
                isRequired
                help={Help.liquidNameHelp}
              />
              <LiquidIdentifierSelect
                value={liquid}
                options={inputLiquidNamesAndGroups}
                onChange={value => handleSetLiquidIdentifier(value?.name)}
                isDisabled={isReadonly}
              />
              <ErrorText variant="caption">{liquidIdentifierError}</ErrorText>
            </CollapsibleParameter>
            <CollapsibleParameter in>
              <ParameterHeader
                displayName="Volume or Concentration"
                isRequired
                help={Help.liquidTargetHelp}
              />
              <MeasurementEditor
                onChange={handleSetVolumeOrConcentration}
                validUnits={[...volumeUnits, ...concentrationUnits]}
                defaultUnit="mM"
                value={liquidVolumeOrConcentration}
                isDisabled={isReadonly}
                placeholder="Volume or Concentration"
              />
              <ErrorText variant="caption">{liquidVolumeOrConcentrationError}</ErrorText>
            </CollapsibleParameter>
            {wellSet && (
              <CollapsibleParameter
                in={
                  isPartOfGroup &&
                  plateAssignment.assignmentMode === PlateAssignmentMode.DESCRIPTIVE
                }
              >
                <ParameterHeader
                  displayName="Layout"
                  isRequired
                  help={Help.wellSetSortByHelp}
                />
                <Toggle
                  value={wellSet.sortBy}
                  onChange={(_, value) =>
                    setWellSetSortMode(wellSet.id, value as WellSortMode)
                  }
                  exclusive
                  disabled={isReadonly}
                >
                  <ToggleButton value={WellSortMode.BY_ROW}>
                    <ArrowForwardIcon />
                  </ToggleButton>
                  <ToggleButton value={WellSortMode.BY_COLUMN}>
                    <ArrowDownwardIcon />
                  </ToggleButton>
                </Toggle>
              </CollapsibleParameter>
            )}
            {wellSet && (
              <>
                <CollapsibleParameter
                  in={
                    isPartOfGroup &&
                    plateAssignment.assignmentMode === PlateAssignmentMode.DESCRIPTIVE
                  }
                >
                  <ParameterHeader
                    displayName="Re-order group by"
                    isRequired
                    help={Help.liquidGroupSortHelp}
                  />
                  <Dropdown
                    options={liquidSortModeOptions}
                    valueLabel={
                      liquidSortModeOptions.find(
                        option =>
                          option.value.sortOrder === liquid.sortOrder &&
                          option.value.sortBy === liquid.sortBy,
                      )?.label ?? ''
                    }
                    onChange={value =>
                      value &&
                      setLiquidSortOrder(wellSet.id, value.sortBy, value.sortOrder)
                    }
                    isDisabled={isReadonly}
                    isRequired
                  />
                </CollapsibleParameter>
                <CollapsibleParameter in={isPartOfGroup && !!subComponentOptions}>
                  <ParameterHeader
                    displayName="Sub-Component of Interest"
                    help={Help.liquidGroupSubComponentNameHelp}
                  />
                  <Dropdown
                    options={subComponentOptions ?? []}
                    valueLabel={liquid.subComponentName ?? ''}
                    onChange={value => setLiquidSortSubComponent(liquid.wellSetID, value)}
                    isDisabled={isReadonly}
                    placeholder="Sub-Component..."
                  />
                </CollapsibleParameter>
              </>
            )}
          </div>
        </InnerWrapper>
      </Wrapper>
      <CollapsibleEditWellsButton
        visible={canAddWells}
        mode="add"
        onClick={() => addSelectedWellsToSelectedLiquid()}
        disabled={isReadonly}
      />
      <CollapsibleEditWellsButton
        visible={canRemoveWells}
        mode="remove"
        onClick={() => removeSelectedWellsFromSelectedLiquid()}
        disabled={isReadonly}
      />
    </>
  );
}

function useSubComponentOptions(
  liquid: LiquidAssignment,
  inputLiquids: Liquid[],
): Option<string>[] | undefined {
  return useMemo(() => {
    if (inputLiquids && liquid.liquidGroup) {
      const subComponents: Record<string, number> = {};
      const liquidsInGroup = inputLiquids.filter(item =>
        item.groups?.includes(liquid.liquidGroup ?? ''),
      );

      liquidsInGroup
        .flatMap(liquid => keys(liquid.subComponents))
        .forEach(subComponent => {
          subComponents[subComponent] = (subComponents[subComponent] ?? 0) + 1;
        });

      const options = Object.entries(subComponents)
        .filter(([, count]) => count === liquidsInGroup.length)
        .map(([label]) => ({ label, value: label }));

      return options.length ? options : undefined;
    }

    return undefined;
  }, [inputLiquids, liquid.liquidGroup]);
}

const InnerWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
  overflowY: 'auto',
}));

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflowX: 'hidden',
}));

const StyledHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
}));

const StyledLayersIcon = styled(LayersIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '16px',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const LiquidColor = styled('div')({
  minWidth: '16px',
  minHeight: '16px',
  borderRadius: '50%',
});

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));
