import React from 'react';

import ExecutionsList, {
  ClickedExecution,
} from 'client/app/apps/experiments/ExecutionsList';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

type ExecutionsDialogProps = {
  dialogClassName?: string;
  // Override default click behaviour
  onClickExecutionRow?: (
    clickedExecution: ClickedExecution | typeof CANCEL_CHOICE,
  ) => void;
  disabledExecutionIds?: ExecutionId[];
} & DialogProps<ClickedExecution | typeof CANCEL_CHOICE>;
/**
 * Render a list of Executions in a dialog, allowing the user to pick one Execution.
 */
export default function ExecutionsDialog(props: ExecutionsDialogProps) {
  const {
    isOpen,
    onClose,
    dialogClassName,
    onClickExecutionRow,
    disableRestoreFocus,
    disabledExecutionIds,
  } = props;

  const onSelectExecution = onClickExecutionRow
    ? onClickExecutionRow
    : (clickedExecution: ClickedExecution) => onClose(clickedExecution);
  const onCancel = () => onClose(CANCEL_CHOICE);

  const classes = useStyles();

  return (
    <ComplexActionDialog
      title="Executions"
      onClose={onCancel}
      isOpen={isOpen}
      showCloseButton
      fullHeight
      className={dialogClassName}
      disableRestoreFocus={disableRestoreFocus}
      content={
        <div className={classes.dialogContent}>
          <ExecutionsList
            onExecutionRowClick={onSelectExecution}
            disabledExecutionIds={disabledExecutionIds}
          />
        </div>
      }
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: 0,
    flexGrow: 1,
    padding: theme.spacing(5),
    // Make the ExecutionsList fill the dialog
    alignItems: 'stretch',
    justifyContent: 'stretch',
  },
}));
