import React, { useState } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Big from 'big.js';

import { calculateDensity } from 'client/app/apps/standalone-tools/density-calculator/calculations';
import { validateAndConvertToDecimal } from 'client/app/apps/standalone-tools/utils';
import { getUnit } from 'common/lib/units';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import TextField from 'common/ui/filaments/TextField';

const VOLUME_UNIT_OPTIONS = [getUnit('ul'), getUnit('ml'), getUnit('l')];

const MASS_UNIT_OPTIONS = [getUnit('mg'), getUnit('g')];

export default function DensityCalculator() {
  const [mass, setMass] = useState<string>('');
  const [massUnitCoefficient, setMassUnitCoefficient] = useState<number>(1);
  const [isMassErrored, setIsMassErrored] = useState(false);

  const [volume, setVolume] = useState<string>('');
  const [volumeUnitCoefficient, setVolumeUnitCoefficient] = useState<number>(1);
  const [isVolumeErrored, setIsVolumeErrored] = useState(false);

  const [density, setDensity] = useState<Big | null>(null);

  function calculate() {
    const volumeDecimal = validateAndConvertToDecimal(volume);
    const massDecimal = validateAndConvertToDecimal(mass);

    const isVolumeValid = volumeDecimal !== null && !volumeDecimal.eq(0);
    const isMassValid = massDecimal !== null;

    setIsVolumeErrored(!isVolumeValid);
    setIsMassErrored(!isMassValid);

    if (isVolumeValid && isMassValid) {
      const result = calculateDensity(
        volumeDecimal,
        volumeUnitCoefficient,
        massDecimal,
        massUnitCoefficient,
      );

      setDensity(result);
    } else {
      setDensity(null);
      return;
    }
  }

  return (
    <>
      <InputsSection>
        <Description>
          This tool allows for the calculation of density by inputting mass and volume
          values.
        </Description>
        <FieldsContainer>
          <Label>Mass</Label>
          <StyledTextField
            label="Mass"
            inputMode="decimal"
            value={mass}
            onChange={e => {
              setMass(e.target.value);
            }}
            error={isMassErrored}
            helperText={
              isMassErrored ? 'The mass must be a non-negative, numeric value' : ' '
            }
          />
          <StyledTextField
            label="unit"
            select
            value={massUnitCoefficient}
            onChange={e => {
              const numericValue = Number(e.target.value);
              if (Number.isNaN(numericValue)) {
                return;
              }
              setMassUnitCoefficient(numericValue);
            }}
          >
            {MASS_UNIT_OPTIONS.map(option => (
              <MenuItem key={option.label} value={option.coefficient}>
                {option.label}
              </MenuItem>
            ))}
          </StyledTextField>
          <Label>Volume</Label>
          <StyledTextField
            label="Volume"
            inputMode="decimal"
            value={volume}
            onChange={e => {
              setVolume(e.target.value);
            }}
            error={isVolumeErrored}
            helperText={
              isVolumeErrored ? 'The volume must be a positive, numeric value' : ' '
            }
          />
          <StyledTextField
            label="unit"
            select
            value={volumeUnitCoefficient}
            onChange={e => {
              const numericValue = Number(e.target.value);
              if (Number.isNaN(numericValue)) {
                return;
              }
              setVolumeUnitCoefficient(numericValue);
            }}
          >
            {VOLUME_UNIT_OPTIONS.map(option => (
              <MenuItem key={option.label} value={option.coefficient}>
                {option.label}
              </MenuItem>
            ))}
          </StyledTextField>
        </FieldsContainer>
      </InputsSection>
      <ResultSection>
        <Button
          variant="primary"
          color="primary"
          onClick={calculate}
          data-heap-tracking="standalone-tools-density-calculator-calculate"
        >
          Calculate
        </Button>
        <StyledTextField
          value={density ?? ''}
          InputProps={{
            sx: {
              background: Colors.WHITE,
            },
            endAdornment: (
              <InputAdornment position="end">
                <Typography>
                  g/cm<SuperScript>3</SuperScript>
                </Typography>
              </InputAdornment>
            ),
            startAdornment: <InputAdornment position="start">Density</InputAdornment>,
          }}
          contentEditable={false}
        />
      </ResultSection>
    </>
  );
}

const Description = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const InputsSection = styled('div')(({ theme }) => ({
  padding: theme.spacing(6, 6, 4, 6),
}));

const FieldsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '4fr 1fr',
  gap: theme.spacing(3),
}));

const Label = styled(Typography)({
  gridColumn: 'span 2',
});

const ResultSection = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: theme.spacing(4),
  padding: theme.spacing(6),
  borderTop: `1px solid ${Colors.GREY_30}`,
  background: Colors.GREY_10,
}));

const StyledTextField = styled(TextField)({
  '& input': {
    textAlign: 'right',
  },
});

const SuperScript = styled('sup')({
  position: 'absolute',
  top: 4,
});
