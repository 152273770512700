import React, { useCallback, useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import DialogActions from '@mui/material/DialogActions';

import { MUTATION_UPDATE_ACCESSIBLE_DEVICE } from 'client/app/api/gql/mutations';
import { QUERY_ACCESSIBLE_DEVICE } from 'client/app/api/gql/queries';
import { AccessibleDeviceSetupDialogContent } from 'client/app/components/DeviceLibrary/AccessibleDeviceSetupDialogContent';
import { AccessibleDeviceSetup } from 'common/types/device';
import Button from 'common/ui/components/Button';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import LinearProgress from 'common/ui/components/LinearProgress';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

/**
 * Display this when the dialog is opened and the device doesn't have
 * accessible_device_setup in the db yet.
 */
const DIALOG_DEFAULT_VALUES: AccessibleDeviceSetup = {
  address: '',
  orientation: 'left',
  allowRotated: false,
  deviceSpecific: {},
};

type Props = {
  parentDeviceId: DeviceId;
  accessibleDeviceId: DeviceId;
  accessibleDeviceName: string;
} & DialogProps<AccessibleDeviceSetup | null>;

/**
 * A simple dialog that allows editing the contents of an `AccessibleDeviceSetup` object.
 */
export default function AccessibleDeviceSetupDialog(props: Props) {
  const { parentDeviceId, accessibleDeviceId, accessibleDeviceName, onClose, isOpen } =
    props;

  const snackbarManager = useSnackbarManager();

  const { data, loading: isLoading } = useQuery(QUERY_ACCESSIBLE_DEVICE, {
    variables: {
      parentDeviceId,
      accessibleDeviceId,
    },
    // So that we don't have to get cache updating right on mutation -
    // potential source of bugs leading to confusing UX if they user opens
    // the dialog, edits, and then opens the dialog again and sees stale data.
    // This dialog is rarely used so keeping the code simpler and reloading
    // on every dialog open is OK.
    fetchPolicy: 'no-cache',
  });

  const [updateAccessibleDeviceMutation] = useMutation(
    MUTATION_UPDATE_ACCESSIBLE_DEVICE,
    {
      onError: e => snackbarManager.showError(e.message),
    },
  );

  const [accessibleDeviceSetup, setAccessibleDeviceSetup] =
    useState<AccessibleDeviceSetup>(DIALOG_DEFAULT_VALUES);

  const [isSaving, setIsSaving] = useState(false);

  // When data was loaded, populate local state so we can edit it.
  useEffect(() => {
    setAccessibleDeviceSetup(
      data?.accessibleDevice?.accessibleDeviceSetup ?? DIALOG_DEFAULT_VALUES,
    );
  }, [data]);

  const handleCancel = useCallback(() => {
    onClose(null);
  }, [onClose]);

  const handleConfirm = useCallback(async () => {
    setIsSaving(true);
    try {
      await updateAccessibleDeviceMutation({
        variables: {
          parentDeviceId,
          accessibleDeviceId,
          accessibleDeviceSetup,
        },
      });
    } finally {
      setIsSaving(false);
    }
    onClose(accessibleDeviceSetup);
  }, [
    accessibleDeviceId,
    accessibleDeviceSetup,
    onClose,
    parentDeviceId,
    updateAccessibleDeviceMutation,
  ]);

  const handleAccessibleDeviceSetupChange = useCallback(
    (accessibleDeviceSetup: AccessibleDeviceSetup) => {
      setAccessibleDeviceSetup(accessibleDeviceSetup);
    },
    [],
  );

  const classes = useStyles();

  return (
    <ComplexActionDialog
      title={accessibleDeviceName}
      isOpen={isOpen}
      onClose={handleCancel}
      content={
        <>
          {(isLoading || isSaving) && <LinearProgress />}
          {!isLoading && (
            <div className={classes.content}>
              <AccessibleDeviceSetupDialogContent
                accessibleDeviceSetup={accessibleDeviceSetup}
                isDisabled={isLoading || isSaving}
                onAccessibleDeviceSetupChange={handleAccessibleDeviceSetupChange}
              />
            </div>
          )}
        </>
      }
      dialogActions={
        <DialogActions>
          <Button variant="tertiary" onClick={handleCancel} disabled={isSaving}>
            Cancel
          </Button>
          <Button
            variant="tertiary"
            color="primary"
            onClick={handleConfirm}
            disabled={isSaving}
          >
            Confirm
          </Button>
        </DialogActions>
      }
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  content: {
    padding: theme.spacing(6),
  },
}));
