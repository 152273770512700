import React from 'react';

import UploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import { v4 as uuid } from 'uuid';

import { FileEditorValue, GenericFile } from 'common/types/fileParameter';
import {
  FiletreePath,
  sanitizeFilenameForFiletree,
  UploadInput,
} from 'common/types/filetree';
import DirectUploadItem from 'common/ui/components/DirectUploadItem';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useUUID from 'common/ui/hooks/useUUID';
import CloudUploadIcon from 'common/ui/icons/CloudUpload';

const FILETREE_VERSION = 'v1';

type SetIsUploading = React.Dispatch<React.SetStateAction<boolean>>;
export type Props = {
  // Optionally specify allowed file types for the native file selector.
  // Example: ['.jpg', '.gif']
  // The use case for this is to help the user select the right files.
  // This is passed directly to the underlying HTML input element,
  // see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
  acceptFileTypes?: readonly string[];
  value: FileEditorValue;
  /** What is done with the file once it has been uploaded to the browser. The result is passed to onChange(). */
  onUpload: (uploadInput: UploadInput) => Promise<any>;
  /** What is done with the result of onUpload once it has resolved. */
  onChange: (newValue: any) => void;
  onDownload?: (file: GenericFile) => void;
  targetFolder?: string;
  isDisabled?: boolean;
  renderType?: 'Button' | 'MenuItem';
  setIsUploading?: SetIsUploading;
};

function DirectUploaderPicker({
  renderType,
}: {
  renderType?: 'Button' | 'MenuItem';
}): JSX.Element {
  const classes = useStyles();
  if (renderType && renderType === 'MenuItem') {
    return <MenuItemWithIcon icon={<CloudUploadIcon />} text="Computer" />;
  }
  return (
    <Button component="span">
      <UploadIcon className={classes.leftIcon} />
      Upload
    </Button>
  );
}

export default React.memo(function DirectUploadEditor(props: Props) {
  const {
    acceptFileTypes,
    value,
    onChange,
    targetFolder = 'parameters',
    isDisabled,
    renderType = 'Button',
    setIsUploading,
    onDownload,
  } = props;

  const fileUploadButtonID = useUUID();

  const acceptedTypes = acceptFileTypes ? acceptFileTypes.join(',') : '';

  const handleChange = async (inputEvent: React.ChangeEvent<HTMLInputElement>) => {
    if (!inputEvent.target?.files?.length || isDisabled) {
      return;
    }
    setIsUploading?.(true);
    const file = inputEvent.target.files[0];
    inputEvent.target.value = '';
    const result = await uploadAndRetrieveFileInfo(file);
    onChange(result);
    setIsUploading?.(false);
  };

  const uploadAndRetrieveFileInfo = async (file: File) => {
    const filename = sanitizeFilenameForFiletree(file.name.trim());
    const fileIndexPath = [targetFolder, FILETREE_VERSION, filename, uuid()].join(
      '/',
    ) as FiletreePath;
    return props.onUpload({
      file: file,
      metadata: {
        indexPath: fileIndexPath,
      },
    });
  };

  const onRemoveExistingFile = () => {
    onChange(null);
  };

  return (
    <>
      {value ? (
        <DirectUploadItem
          file={value}
          onRemove={onRemoveExistingFile}
          isDisabled={isDisabled}
          onDownload={onDownload}
        />
      ) : (
        <>
          <input
            type="file"
            onChange={handleChange}
            accept={acceptedTypes}
            style={{ display: 'none' }}
            id={fileUploadButtonID}
          />
          <label htmlFor={fileUploadButtonID}>
            <DirectUploaderPicker renderType={renderType} />
          </label>
        </>
      )}
    </>
  );
});

const useStyles = makeStylesHook({
  leftIcon: {
    marginRight: '12px',
  },
});
