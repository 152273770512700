import { useCallback, useMemo } from 'react';

import DOMPurify from 'dompurify';
import { v4 as uuid } from 'uuid';

import isWorkflowReadonly from 'client/app/apps/workflow-builder/lib/isWorkflowReadonly';
import { useElementInstance } from 'client/app/apps/workflow-builder/lib/useElementInstance';
import { FilterPlateEditorState } from 'client/app/components/Parameters/FiltrationPlateLayout/lib/editorState';
import { MetaItem } from 'client/app/components/Parameters/MetaDataForm';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { ElementInstance } from 'common/types/bundle';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';

export type FiltrationPlateLayoutValue = {
  plateName: string;
  plateType: string;
  wellRegions: { [regionName: string]: WellRegion };
};

type WellRegion = {
  wellLocations: string[];
  resinName: string;
  resinVolume: string;
  totalWellVolume: string;
  metaData: { [key: string]: string };
};

export function useFiltrationPlateLayoutParameter() {
  const dispatch = useWorkflowBuilderDispatch();
  const parameters = useWorkflowBuilderSelector(state => state.parameters);
  const elementInstance = useElementInstance();

  const [parameterName, parameterValue] = useMemo(() => {
    const elementParamConfig = elementInstance?.element.configuration?.parameters;

    if (!elementParamConfig) return [null, null];

    for (const parameterName in elementParamConfig) {
      if (isPlateLayoutParameter(parameterName, elementInstance)) {
        return [parameterName, parameters[elementInstance.name]?.[parameterName]];
      }
    }

    return [null, null];
  }, [elementInstance, parameters]);

  const setValue = useCallback(
    (newValue: FiltrationPlateLayoutValue) => {
      if (!elementInstance || !parameterName) return;

      dispatch({
        type: 'updateParameter',
        payload: {
          instanceName: elementInstance.name,
          parameterName,
          value: newValue,
        },
      });
    },
    [dispatch, elementInstance, parameterName],
  );

  return useMemo(
    () => ({
      name: parameterName,
      value: parameterValue as FiltrationPlateLayoutValue,
      setValue,
    }),
    [parameterName, parameterValue, setValue],
  );
}

function isPlateLayoutParameter(parameterName: string, elementInstance: ElementInstance) {
  return (
    elementInstance?.element.configuration?.parameters[parameterName]?.editor.type ===
    EditorType.PLATE_LAYOUT
  );
}

export function useFactorCombinations() {
  const elementInstance = useElementInstance();
  return useMemo(() => {
    const outputs = elementInstance?.Meta.outputs ?? {};
    /**
     * FIXME: we should not hardcode output names in general.
     * However, curently there is no way of figuring our this output name
     * from any context.
     */
    const replicateCount = (outputs['FiltrationDesignReplicationOut'] ?? 0) as number;
    const factorCombinationCount = (outputs['NumFactorCombinations'] ?? 0) as number;

    return {
      factorCombinationCount,
      replicateCount,
      wellCount: factorCombinationCount * replicateCount,
    };
  }, [elementInstance?.Meta.outputs]);
}

export function useReadonly() {
  const editMode = useWorkflowBuilderSelector(state => state.editMode);
  const source = useWorkflowBuilderSelector(state => state.source);
  return isWorkflowReadonly(editMode, source);
}

export function mapFromEditorState(
  state: FilterPlateEditorState,
): FiltrationPlateLayoutValue {
  // If plate type has not been selected we reset the paramter to {}
  if (!state.plateType) return {} as FiltrationPlateLayoutValue;

  if (state.resinBufferVolume < Math.max(...state.wellRegions.map(r => +r.resinVolume))) {
    throw new Error('Resin + Buffer volume cannot be less than any resin volume.');
  }

  const resultRegions: { [regionName: string]: WellRegion } = {};

  for (const wellRegion of state.wellRegions) {
    const resultMeta: { [key: string]: string } = {};

    for (const metaItem of wellRegion.metaItems) {
      resultMeta[metaItem.key] = metaItem.value;
    }

    resultRegions[wellRegion.id] = {
      wellLocations: wellRegion.wells,
      resinName: wellRegion.resinName,
      resinVolume: `${wellRegion.resinVolume}ul`,
      totalWellVolume: `${state.resinBufferVolume}ul`,
      metaData: resultMeta,
    };
  }

  return {
    plateName: formatPlateName(state.plateName),
    plateType: state.plateType,
    wellRegions: resultRegions,
  };
}

export function mapToEditorState(
  parameterValue: FiltrationPlateLayoutValue | undefined,
  minWellCount: number,
): FilterPlateEditorState {
  const draft: FilterPlateEditorState = {
    isEditing: false,
    selectedWells: [],
    wellRegionDraft: undefined,
    plateType: parameterValue?.plateType ?? null,
    plateName: parameterValue?.plateName ?? null,
    wellCapacity: 0,
    resinBufferVolume: 0,
    minWellCount,
    wellRegions: [],
  };

  if (!parameterValue?.wellRegions) return draft;

  const liquidColors = LiquidColors.createAvoidingAllColorCollisions();
  const parameterWellRegions = parameterValue.wellRegions;

  for (const regionId in parameterWellRegions) {
    const paramWellRegion = parameterWellRegions[regionId];

    const metaItems: MetaItem[] = [];

    for (const metaKey in paramWellRegion.metaData) {
      metaItems.push({
        id: uuid(),
        key: metaKey,
        value: paramWellRegion.metaData[metaKey],
      });
    }

    draft.wellRegions.push({
      id: regionId,
      resinName: paramWellRegion.resinName,
      resinVolume: String(parseInt(paramWellRegion.resinVolume, 10)),
      wells: paramWellRegion.wellLocations,
      resinColor: liquidColors.getColorFromLiquidString(
        JSON.stringify(paramWellRegion),
        false,
      ),
      metaItems,
    });

    if (draft.resinBufferVolume === 0) {
      draft.resinBufferVolume = parseInt(paramWellRegion.totalWellVolume, 10);
    }
  }

  return draft;
}

function formatPlateName(text: string | null) {
  /**
   * Plate name should be trimmed and sanitized for not
   * to cause any errors on planner side.
   */
  if (!text) return '';
  return DOMPurify.sanitize(text.trim());
}
