import { useMemo } from 'react';

import groupBy from 'lodash/groupBy';
import partition from 'lodash/partition';

import { getEditorInfo } from 'client/app/components/DOEBuilder/factorUtils';
import { FactorParameterInfo } from 'client/app/components/DOEFactorForm/types';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { mapObject } from 'common/object';
import { FactorItem } from 'common/types/bundle';

export function useElementFactorGroups() {
  const factorisedParameters = useWorkflowBuilderSelector(
    state => state.factorisedParameters,
  );
  const factors = useWorkflowBuilderSelector(state => state.factors);
  const elementInstances = useWorkflowBuilderSelector(state => state.elementInstances);
  const workflowName = useWorkflowBuilderSelector(state => state.workflowName);

  return useMemo(() => {
    const groups: Record<
      string,
      Record<string, { parameterInfo: FactorParameterInfo; factors: FactorItem[] }>
    > = {};

    const [paramFactors, customFactors] = partition(factors, factor => !!factor.path);

    const factorsGroupedByElementAndParameter = mapObject(
      groupBy(paramFactors, 'path.1'),
      (_, elementFactors) => groupBy(elementFactors, 'path.2'),
    );

    for (const elementName in factorisedParameters) {
      /**
       * If elementInstance has been removed we don't care about factors
       * associated with this element as they should be removed too.
       */
      if (!elementInstances.find(ei => ei.name === elementName)) {
        delete groups[elementName];
        continue;
      }
      for (const parameterName in factorisedParameters[elementName]) {
        const isParameterFactorised = factorisedParameters[elementName][parameterName];

        if (!isParameterFactorised) continue;

        if (!groups[elementName]) {
          groups[elementName] = {};
        }

        const elementInstance = elementInstances.find(el => el.name === elementName);

        const param = elementInstance?.element.inputs.find(
          param => param.name === parameterName,
        );

        groups[elementName][parameterName] = {
          factors:
            factorsGroupedByElementAndParameter[elementName]?.[parameterName] ?? [],
          parameterInfo: {
            path: [workflowName, elementName, parameterName],
            ...getEditorInfo(param),
          },
        };
      }
    }

    return { groups, customFactors };
  }, [factors, factorisedParameters, elementInstances, workflowName]);
}
