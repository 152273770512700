import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const NamedPlateIcon = React.memo<SvgIconProps>(function NamedPlateIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M9 10.25a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zM13.75 10.25a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zM9 14.5a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zM13.75 14.5a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zM18.5 14.5a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z"
          fill="currentColor"
        />
        <path
          d="M14 5H4a3 3 0 00-3 3v9a3 3 0 003 3h16a3 3 0 003-3v-5h-2v5a1 1 0 01-1 1H4a1 1 0 01-1-1V8a1 1 0 011-1h10V5z"
          fill="currentColor"
        />
        <path
          d="M20.138 8.906h-1.933l-.337 1.092h-1.507l2.146-5.85h1.326l2.161 5.85H20.48l-.342-1.092zm-1.595-1.09h1.258l-.631-2.028-.627 2.029z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
