import React, { useCallback, useContext, useMemo, useState } from 'react';

import { ApolloError, useMutation } from '@apollo/client';
import TrashIcon from '@mui/icons-material/DeleteOutlined';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import { DatasetSettingsContext } from 'client/app/apps/experiments/dataset-settings/DatasetSettingsContext';
import {
  convertMomentToMomentTimezone,
  useFormatTimeStamp,
} from 'client/app/apps/experiments/dataset-settings/datasetSettingsUtils';
import DateTimePicker from 'client/app/apps/experiments/dataset-settings/DateTimePicker';
import { SampleAutocomplete } from 'client/app/apps/experiments/dataset-settings/SampleAutocomplete';
import {
  BioreactorDatasetColumns,
  useBioreactorDatasetColumns,
} from 'client/app/apps/experiments/dataset-settings/useBioreactorDatasetColumns';
import {
  MUTATION_LINK_SAMPLE_TO_DATASET,
  MUTATION_UPSERT_AND_LINK_SAMPLE_TO_DATASET,
} from 'client/app/apps/experiments/gql/mutations';
import { QUERY_DATASET_SAMPLES } from 'client/app/apps/experiments/gql/queries';
import { DatasetWithSettingsQuery } from 'client/app/gql';
import Button from 'common/ui/components/Button';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import IconButton from 'common/ui/components/IconButton';
import { TableHeaderCell } from 'common/ui/components/TableHeaderCell';
import Dropdown, { Option } from 'common/ui/filaments/Dropdown';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { cacheEvict } from 'common/utils';

type Props = {
  timezone: string;
};

type SampleDefinition = {
  id: DatasetSampleId;
  synthaceSampleId: string;
  bioreactorName: string;
  harvestTime: Date;
  rowKey: string;
};

/**
 * Will scroll the element into view.
 * This is used as a ref callback.
 * It is very important it is stable across render to avoid scrolling the element into view at each re-render.
 * This is wyh it is declared outside of the React component and not inlined into the 'ref' prop.
 */
function scrollIntoView(el: HTMLElement | null) {
  el?.scrollIntoView();
}

/**
 * Samples are defined on bioreactor datasets
 */
export function DatasetSettingsSamplesTab({ timezone }: Props) {
  const { dataset, isReadonly } = useContext(DatasetSettingsContext);
  const [isCreatingSample, setIsCreatingSample] = useState<boolean>(false);
  const classes = useStyles();

  const bioreactorDatasetColumns = useBioreactorDatasetColumns(dataset);

  const sampleDefinitions = useSampleDefinitions(dataset, bioreactorDatasetColumns);

  // Appserver limitations mean that only one sample can be deleted or changed at a time
  const [isSampleMutating, setIsSampleMutating] = useState<boolean>(false);

  if (!bioreactorDatasetColumns) {
    return (
      <Box ml={2}>
        <Typography variant="subtitle2" paragraph>
          No bioreactors
        </Typography>
        <Typography>Please enter bioreactor names on the Details tab.</Typography>
      </Box>
    );
  }

  return (
    <>
      <Table stickyHeader className={classes.table}>
        <colgroup>
          <col className={classes.sampleNameCol} />
          <col className={classes.bioreactorCol} />
          <col className={classes.timestampCol} />
          <col className={classes.deleteCol} />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Sample Name</TableHeaderCell>
            <TableHeaderCell>Bioreactor</TableHeaderCell>
            <TableHeaderCell colSpan={2}>Harvest time</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sampleDefinitions?.map(definition => (
            <SampleRow
              key={definition.synthaceSampleId}
              definition={definition}
              isReadonly={isReadonly || isSampleMutating}
              onIsMutatingChange={setIsSampleMutating}
            />
          ))}
          {isCreatingSample && (
            <NewSampleRow
              dataset={dataset}
              datasetColumns={bioreactorDatasetColumns}
              sampleDefinitions={sampleDefinitions}
              onClose={() => setIsCreatingSample(false)}
              timezone={timezone}
            />
          )}
        </TableBody>
      </Table>
      {!isReadonly && !isCreatingSample && (
        <Button
          className={classes.addSampleButton}
          variant="secondary"
          color="primary"
          size="small"
          onClick={() => setIsCreatingSample(true)}
        >
          Add Sample Name
        </Button>
      )}
    </>
  );
}

const CREATE_SAMPLE_PLACEHOLDER = 'Create sample name';
const CREATE_SAMPLE_SEARCH_PLACEHOLDER = 'Enter a new sample name...';
const PICK_EXISTING_MESSAGE = 'Or, pick an existing sample name';

function SampleRow({
  definition,
  sampleDefinitions,
  isReadonly,
  onIsMutatingChange,
}: {
  definition: SampleDefinition;
  sampleDefinitions?: SampleDefinition[];
  isReadonly: boolean;
  onIsMutatingChange: (isMutating: boolean) => void;
}) {
  const { dataset } = useContext(DatasetSettingsContext);
  const formatTimeStamp = useFormatTimeStamp();

  const [linkSampleToDataset, { loading: isDeleting }] = useMutation(
    MUTATION_LINK_SAMPLE_TO_DATASET,
  );

  const [upsertAndLinkSampleToDataset] = useMutation(
    MUTATION_UPSERT_AND_LINK_SAMPLE_TO_DATASET,
  );
  const handleUnlinkDatasetSample = async () => {
    onIsMutatingChange(true);
    try {
      // Unlinking is facilitated by linking this row key of the dataset to nothing.
      await linkSampleToDataset({
        variables: {
          datasetId: dataset.id,
          rowKey: definition.rowKey,
          sampleId: undefined,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          linkSampleToDataset: null,
        },
        update: cache =>
          cacheEvict({ id: definition.id, __typename: 'DatasetSample' }, cache),
      });
    } finally {
      onIsMutatingChange(false);
    }
  };

  const sampleIds = useMemo(
    () => new Set(sampleDefinitions?.map(def => def.synthaceSampleId)),
    [sampleDefinitions],
  );

  const handleSampleChange = async (sampleName: string | null) => {
    if (!sampleName) {
      // Do not allow user to select nothing
      return;
    }
    onIsMutatingChange(true);
    try {
      await upsertAndLinkSampleToDataset({
        variables: {
          datasetId: dataset.id,
          rowKey: definition.rowKey,
          sampleName: sampleName,
        },
        refetchQueries: [
          {
            query: QUERY_DATASET_SAMPLES,
            variables: { id: dataset.datasetId },
          },
        ],
        awaitRefetchQueries: true,
      });
    } finally {
      onIsMutatingChange(false);
    }
  };

  return (
    <TableRow key={definition.synthaceSampleId}>
      <TableCell>
        <SampleAutocomplete
          placeholder={CREATE_SAMPLE_PLACEHOLDER}
          searchPlaceholder={CREATE_SAMPLE_SEARCH_PLACEHOLDER}
          listHelperText={PICK_EXISTING_MESSAGE}
          value={definition.synthaceSampleId}
          onChange={handleSampleChange}
          exclude={sampleIds}
          allowCustom
          isDisabled={isReadonly}
        />
      </TableCell>
      <TableCell>{definition.bioreactorName}</TableCell>
      <TableCell>{formatTimeStamp(definition.harvestTime)}</TableCell>
      <TableCell align="right">
        {!isReadonly && !isDeleting && (
          <IconButton
            onClick={handleUnlinkDatasetSample}
            size="small"
            icon={<TrashIcon />}
          />
        )}
        {isDeleting ? <CircularProgress size={20} /> : null}
      </TableCell>
    </TableRow>
  );
}

function NewSampleRow({
  dataset,
  datasetColumns,
  onClose,
  timezone,
  sampleDefinitions = [],
}: {
  dataset: DatasetWithSettingsQuery['dataset'];
  datasetColumns: BioreactorDatasetColumns;
  onClose: () => void;
  timezone: string;
  sampleDefinitions?: SampleDefinition[];
}) {
  const classes = useStyles();

  const { defineSample, isCreating, error } = useCreateSampleDefinition(
    dataset,
    datasetColumns,
  );

  const bioreactors: Option<string>[] = datasetColumns?.bioreactorColumnValues.map(
    value => ({
      label: value,
      value,
    }),
  );

  const [name, setName] = useState<string | null>(null);
  const [bioreactor, setBioreactor] = useState<string>(bioreactors[0].value);

  // The default sample date is the start event timestamp if any, or the latest sample date, or now.
  const [timestamp, setTimestamp] = useState<moment.Moment>(() => {
    const start = dataset.events.find(e => e.name.toLowerCase() === 'start');
    const lastSample =
      dataset.samples[dataset.samples.length - 1]?.rowKeyForUI?.timestamp;
    const ts = start?.occurredAt ?? lastSample;
    return ts ? moment(ts) : moment();
  });

  const sampleIds = useMemo(
    () => new Set(sampleDefinitions.map(def => def.synthaceSampleId)),
    [sampleDefinitions],
  );

  const canCreate = name && bioreactor && timestamp.isValid() && !sampleIds.has(name);

  const handleTimestampChange = useCallback((value: moment.Moment | null) => {
    value && setTimestamp(value);
  }, []);

  const handleCreate = async () => {
    if (!canCreate) {
      return;
    }
    try {
      await defineSample({
        synthaceSampleId: name,
        bioreactorName: bioreactor,
        harvestTime: convertMomentToMomentTimezone(timestamp, timezone).toDate(),
      });
      onClose();
    } catch {
      return;
    }
  };

  return (
    <>
      <TableRow className={classes.newSampleRow}>
        <TableCell>
          <div className={classes.sampleAutocomplete}>
            <SampleAutocomplete
              placeholder={CREATE_SAMPLE_PLACEHOLDER}
              searchPlaceholder={CREATE_SAMPLE_SEARCH_PLACEHOLDER}
              listHelperText={PICK_EXISTING_MESSAGE}
              value={name}
              onChange={sampleName => setName(sampleName)}
              exclude={sampleIds}
              allowCustom
            />
          </div>
        </TableCell>
        <TableCell>
          <Dropdown
            className={classes.newSampleBioreactorCol}
            options={bioreactors}
            valueLabel={bioreactor}
            onChange={v => v && setBioreactor(v)}
            variant="outlined"
            isDisabled={bioreactors.length <= 1}
            margin="dense"
          />
        </TableCell>
        <TableCell colSpan={2}>
          <DateTimePicker value={timestamp} onChange={handleTimestampChange} />
        </TableCell>
      </TableRow>

      <TableRow ref={scrollIntoView} className={classes.newSampleRow}>
        <TableCell colSpan={4} align="right">
          {error && <GraphQLErrorPanel error={error} />}
          <Button
            variant="secondary"
            size="small"
            onClick={onClose}
            disabled={isCreating}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            size="small"
            onClick={handleCreate}
            color="primary"
            disabled={isCreating || !canCreate}
            className={classes.saveButton}
          >
            Save
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

/**
 * Get all samples that have been defined for a given bioreactor dataset.
 */
function useSampleDefinitions(
  dataset: DatasetWithSettingsQuery['dataset'],
  datasetColumns?: BioreactorDatasetColumns,
): SampleDefinition[] | undefined {
  if (!datasetColumns) {
    return;
  }

  return dataset.samples
    .map(datasetSample => ({
      id: datasetSample.id,
      rowKey: JSON.stringify(datasetSample.rowKey),
      synthaceSampleId: datasetSample.sample.name,
      bioreactorName: String(
        datasetSample.rowKey?.[datasetColumns.bioreactorColumnName] ?? '',
      ),
      harvestTime: new Date(datasetSample.rowKeyForUI?.timestamp ?? 0),
    }))
    .sort((a, b) => a.harvestTime.valueOf() - b.harvestTime.valueOf());
}

/**
 * Returns an object with a defineSample function for creating a sample for given
 * bioreactor and harvest of a bioreactor dataset.
 */
function useCreateSampleDefinition(
  dataset: DatasetWithSettingsQuery['dataset'],
  datasetColumns: BioreactorDatasetColumns,
): {
  defineSample: (
    sampleDefinition: Pick<
      SampleDefinition,
      'bioreactorName' | 'harvestTime' | 'synthaceSampleId'
    >,
  ) => Promise<void>;
  isCreating: boolean;
  error?: ApolloError;
} {
  const [upsertAndLinkSampleToDataset, { loading: isCreating, error }] = useMutation(
    MUTATION_UPSERT_AND_LINK_SAMPLE_TO_DATASET,
  );

  return {
    isCreating,
    error,
    defineSample: async ({
      synthaceSampleId: sampleName,
      bioreactorName,
      harvestTime,
    }) => {
      await upsertAndLinkSampleToDataset({
        variables: {
          datasetId: dataset.datasetId,
          sampleName,
          rowKey: JSON.stringify({
            [datasetColumns.bioreactorColumnName]: bioreactorName,
            [datasetColumns.timestampColumnName]: harvestTime.toISOString(),
          }),
        },
        refetchQueries: [
          {
            query: QUERY_DATASET_SAMPLES,
            variables: { id: dataset.datasetId },
          },
        ],
        awaitRefetchQueries: true,
      });
    },
  };
}

const useStyles = makeStylesHook(theme => ({
  newSampleRow: {
    verticalAlign: 'top',
    '& td': { borderBottom: 'none', paddingBottom: 0 },
  },
  saveButton: { marginLeft: theme.spacing(3) },
  sampleAutocomplete: { marginTop: '11px' },
  table: {
    tableLayout: 'fixed',
    '& td': { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) },
  },
  newSampleBioreactorCol: {
    width: '100px',
    minWidth: '100px',
  },
  sampleNameCol: { width: 'auto' },
  bioreactorCol: {
    // width of the bioreactor drowpdown + added padding
    width: 'calc(100px + 32px)',
  },
  timestampCol: { width: '180px' },
  deleteCol: { width: '56px' },
  addSampleButton: { marginTop: theme.spacing(6) },
}));
