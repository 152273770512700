import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const RemoveSelectedWellsIcon = React.memo<SvgIconProps>(
  function RemoveSelectedWellsIcon(props) {
    return (
      <SvgIcon viewBox="0 0 24 24" {...props}>
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M12 19.5c4.1421 0 7.5-3.3579 7.5-7.5l-.0002-.05h2.0001L21.5 12c0 5.2467-4.2533 9.5-9.5 9.5S2.5 17.2467 2.5 12 6.75329 2.5 12 2.5v2c-4.14214 0-7.5 3.35786-7.5 7.5 0 4.1421 3.35786 7.5 7.5 7.5Z"
            fill="currentColor"
          />
          <path
            d="M15 6h6v2h-6V6ZM14.5 12c0 1.3807-1.1193 2.5-2.5 2.5S9.5 13.3807 9.5 12s1.1193-2.5 2.5-2.5 2.5 1.1193 2.5 2.5Z"
            fill="currentColor"
          />
        </svg>
      </SvgIcon>
    );
  },
);
