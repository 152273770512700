import { Theme, ThemeOptions } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import Colors from 'common/ui/Colors';
import { linkNoStyle } from 'common/ui/commonStyles';

/** Rules used by Top Nav components */
export const topNavStyles = (theme: Theme) =>
  createStyles({
    additionalItems: {
      alignItems: 'center',
      display: 'flex',
    },
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      height: TOP_NAV_HEIGHT,
      padding: theme.spacing(0, 4),
      justifyContent: 'space-between',
      width: '100%',
      flexShrink: 0,
    },
    logo: {
      '&.MuiSvgIcon-root': {
        marginRight: theme.spacing(6),
        width: '32px',
        height: '32px',
      },
    },
    nav: {
      display: 'flex',
      alignItems: 'center',
    },
    center: {
      justifyContent: 'center',
    },

    // Dropdown Menu styles (e.g. OrgDetailsMenu)
    dropdownContent: {
      display: 'grid',
      padding: theme.spacing(3, 5),
      gridRowGap: theme.spacing(3),
    },
    popover: {
      borderRadius: '0 0 8px 8px',
    },
    iconInsideMenu: {
      marginLeft: theme.spacing(2),
      style: {
        textDecoration: 'none',
      },
    },
    bold: {
      fontWeight: 'bold',
    },
    btnWithDropdown: {
      display: 'flex',
      alignItems: 'center',
    },
    link: linkNoStyle.style,
    selectedLink: {
      '&.Mui-selected': {
        borderBottom: `5px solid ${Colors.BLUE_40}`,
        '&:hover': {
          background: 'none',
        },
      },
    },
  });

/** Theme overrides needed for the Top Navigation */
export const TOP_NAV_HEIGHT_NUMBER = 48;
export const TOP_NAV_HEIGHT = `${TOP_NAV_HEIGHT_NUMBER}px`;

export const topNavOverrides: ThemeOptions = {
  palette: {
    action: {
      hover: Colors.BLUE_40,
      selected: Colors.GREY_90,
    },
    background: {
      paper: Colors.GREY_90,
    },
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        button: {
          color: Colors.DARK_TEXT_PRIMARY,
          height: TOP_NAV_HEIGHT,
          width: '48px',
          padding: '8px 8px 3px', // Need to subtract for the bottom border
          borderBottom: '5px solid transparent', // Set this so that the icon doesn't "jump" on hover.
          '&$selected, &$selected:hover': {
            backgroundColor: 'inherit',
            borderBottom: `5px solid ${Colors.BLUE_40}`,
          },
          '&:hover': {
            backgroundColor: 'inherit',
            borderBottom: `5px solid ${Colors.BLUE_20}`,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '0 0 8px 8px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: '40px',
          // MenuItem inherits from ListItem so we want to unset the border bottom and hover.
          border: 'unset',
          '&:hover': {
            backgroundColor: Colors.BLUE_20,
            border: 'unset',
            color: Colors.TEXT_PRIMARY,
          },
          '&:active': {
            backgroundColor: Colors.BLUE_40,
          },
        },
      },
    },
  },
};
