import React from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import Colors from 'common/ui/Colors';
import IconButton, { IconButtonProps } from 'common/ui/components/IconButton';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type PlateContentsItemContainerProps = React.PropsWithChildren<{
  title: string;
  color?: string;
  startIcon?: React.ReactElement;
  endIconButtonProps?: IconButtonProps;
}>;

export default function PlateContentsItemContainer(
  props: PlateContentsItemContainerProps,
) {
  const { title, color, startIcon, endIconButtonProps, children } = props;

  return (
    <CardContainer variant="outlined">
      <CardTitleContainer>
        {startIcon && <StartIconContainer>{startIcon}</StartIconContainer>}
        {color && <LiquidColor sx={{ backgroundColor: color }} />}
        <TypographyWithTooltip color="textPrimary" variant="subtitle2">
          {title}
        </TypographyWithTooltip>
        {endIconButtonProps && <StyledIconButton {...endIconButtonProps} size="xsmall" />}
      </CardTitleContainer>
      {children}
    </CardContainer>
  );
}

const CardContainer = styled(Paper)(({ theme }) => ({
  border: `1px solid ${Colors.GREY_20}`,
  borderRadius: '4px',
  padding: theme.spacing(5),
}));

const CardTitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '24px',
  marginBottom: theme.spacing(5),
}));

const LiquidColor = styled('div')(({ theme }) => ({
  minWidth: '16px',
  minHeight: '16px',
  borderRadius: '50%',
  marginRight: theme.spacing(4),
}));

const StyledIconButton = styled(IconButton)(({ theme: { palette } }) => ({
  color: palette.text.secondary,
  marginLeft: 'auto',
}));

const StartIconContainer = styled('div')(({ theme: { palette, spacing } }) => ({
  display: 'flex',
  marginRight: spacing(3),
  '& svg': {
    height: '16px',
    width: '16px',
    color: palette.text.secondary,
  },
}));
