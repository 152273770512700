import React, { useEffect } from 'react';

const HIDDEN = { hidden: true };
const VISIBLE = { hidden: false };

// Context providing a hidden value.
export const RouteHiddenContext = React.createContext(VISIBLE);

export const RouteHiddenContextProvider = ({
  hidden,
  children,
  tabTitle,
}: {
  hidden: boolean;
  children?: React.ReactNode;
  tabTitle?: string;
}) => {
  useEffect(() => {
    // Update the document title using the browser API.
    if (!hidden && tabTitle) {
      document.title = tabTitle;
    }
  }, [hidden, tabTitle]);

  return (
    <RouteHiddenContext.Provider value={hidden ? HIDDEN : VISIBLE}>
      {children}
    </RouteHiddenContext.Provider>
  );
};
