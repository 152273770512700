import React, { useContext } from 'react';

import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import {
  ContentsByWell,
  PlateContentParams,
} from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import toWellLocationsOnDeckItem from 'client/app/components/Parameters/WellSelector/toWellLocationsOnDeckItem';
import WellSelector from 'client/app/components/WellSelector/WellSelector';
import {
  formatWellPosition,
  getColumnNumberFromWellPosition,
  getRowNumberFromWellPosition,
} from 'common/lib/format';
import { PlateContentsMatrix } from 'common/types/mix';
import { PlateType } from 'common/types/plateType';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { getLayoutForWellSelector } from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import { WellLabelContent } from 'common/ui/components/simulation-details/mix/WellLabel';
import { WellTooltipTitleProps } from 'common/ui/components/simulation-details/mix/WellTooltip';
import makeWellSelector from 'common/ui/components/simulation-details/PlateTransform';

type PlateContentsWellSelectorProps = {
  plateContentParams: PlateContentParams;
  contentsByWell: ContentsByWell;
  liquidColors: LiquidColors;
  plateType: PlateType;
  selectedWells: string[];
  onSelectWells: (newSelectedWells: string[]) => void;
  hasError?: boolean;
  getContentLabel?: (well: string) => WellLabelContent;
  TooltipTitle?: (props: WellTooltipTitleProps) => JSX.Element;
  isDisabled?: boolean;
};

/**
 * Render a plate that visualises the plate contents and allows selecting wells to modify
 * their contents.
 */
export default function PlateContentsWellSelector({
  plateContentParams,
  contentsByWell,
  liquidColors,
  selectedWells,
  plateType,
  onSelectWells,
  hasError,
  getContentLabel,
  TooltipTitle,
  isDisabled,
}: PlateContentsWellSelectorProps) {
  const { screenId } = useContext(ScreenContext);

  const contents: PlateContentsMatrix = {};
  for (const [wellLocation, wellParamValues] of contentsByWell ?? []) {
    const col = getColumnNumberFromWellPosition(wellLocation);
    const row = getRowNumberFromWellPosition(wellLocation);
    if (!contents[col]) {
      contents[col] = {};
    }
    contents[col][row] = {
      kind: 'liquid_summary',
      id: '0',
      name: wellParamValues?.[plateContentParams.contentLocationParam.name] ?? '', // This is used for the color of the well
      total_volume: { value: 0, unit: '' },
    };
  }

  const plateState: PlateState = { ...makeWellSelector(plateType), contents };

  return (
    <WellSelector
      wellSelectionProps={{
        selectedWells: toWellLocationsOnDeckItem(selectedWells, plateState),
        onSelectWells: newSelectedWells =>
          onSelectWells(newSelectedWells.map(formatWellPosition)),
      }}
      deckLayout={getLayoutForWellSelector(plateState)}
      plate={plateState}
      liquidColors={liquidColors}
      googleAnalyticsCategory={screenId as string}
      showContentLabels
      getContentLabel={getContentLabel}
      TooltipTitle={TooltipTitle}
      hasError={hasError}
      isDisabled={isDisabled}
    />
  );
}

type EmptyPlateContentsWellSelectorProps = Pick<
  PlateContentsWellSelectorProps,
  'liquidColors' | 'plateType' | 'isDisabled'
>;

/**
 * Returns an empty version of the well selector with no contents in wells.
 * All interactivity is disabled.
 * Wells are shown in active state, unless isDisabled is specified to true.
 * This is currently only used in PlateContentsEditorPanel in cases where the
 * layout is being optimized by Synthace (so the user doesn't need to click anything).
 */
export function EmptyPlateContentsWellSelector({
  liquidColors,
  plateType,
  isDisabled,
}: EmptyPlateContentsWellSelectorProps) {
  const { screenId } = useContext(ScreenContext);
  const plateState: PlateState = { ...makeWellSelector(plateType) };
  return (
    <WellSelector
      deckLayout={getLayoutForWellSelector(plateState)}
      plate={plateState}
      liquidColors={liquidColors}
      googleAnalyticsCategory={screenId as string}
      isDisabled={isDisabled}
    />
  );
}
