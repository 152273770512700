import React, { useCallback, useState } from 'react';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import Download from '@mui/icons-material/Download';
import Box from '@mui/material/Box';
import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import FileExtensionIcon from 'client/app/icons/FileExtensionIcon';
import { alphanumericCompare } from 'common/lib/strings';
import Colors from 'common/ui/Colors';
import Tooltip from 'common/ui/components/Tooltip';

type FileEntry = Readonly<{
  type: 'file';
  name: string;
  subtitle?: string;
  onDownload: () => void;
}>;

type DirectoryEntry = Readonly<{
  type: 'directory';
  name: string;
  subtitle?: string;
  entries: readonly TreeEntry[];
}>;

export type TreeEntry = FileEntry | DirectoryEntry;

export default function TreeEntryList({ entries }: { entries: readonly TreeEntry[] }) {
  const sortedEntries = [...entries].sort((a, b) => alphanumericCompare(a.name, b.name));
  return (
    <List>
      {sortedEntries.map((entry, i) => {
        if (entry.type === 'file') {
          return (
            <TreeNode
              key={i}
              icon={<FileExtensionIcon fileName={entry.name} />}
              label={entry.name}
              subtitle={entry.subtitle}
              onDownload={entry.onDownload}
            />
          );
        } else {
          return <Directory key={i} entry={entry} />;
        }
      })}
    </List>
  );
}

function Directory({ entry }: { entry: DirectoryEntry }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleClick = useCallback(() => setIsExpanded(v => !v), [setIsExpanded]);
  return (
    <>
      <TreeNode
        label={entry.name}
        icon={isExpanded ? <ArrowDropDown /> : <ArrowRight />}
        isDirectory
        onClick={handleClick}
      />
      {isExpanded && (
        <Box marginLeft={2}>
          <TreeEntryList entries={entry.entries} />
        </Box>
      )}
    </>
  );
}

type TreeNodeProps = {
  label: string;
  subtitle?: string;
  isDirectory?: boolean;
  icon?: React.ReactElement<any>;
  onClick?: () => void;
  onDownload?: () => void;
};

function TreeNode({
  label,
  subtitle,
  icon,
  isDirectory = false,
  onClick,
  onDownload,
}: TreeNodeProps) {
  return (
    <ListItem title={label} onClick={onClick} isDirectory={isDirectory}>
      {icon}
      <MuiListItemText
        inset={!icon}
        primary={
          isDirectory ? (
            <DirectoryLabel variant="body1">{label}</DirectoryLabel>
          ) : (
            <FileLabel variant="body1">{label}</FileLabel>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          display: 'block',
        }}
        secondary={subtitle}
      />
      {!isDirectory && (
        <Tooltip title="Download file">
          <DownloadIcon onClick={onDownload} />
        </Tooltip>
      )}
    </ListItem>
  );
}

const List = styled(MuiList)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: 0,
}));

const ListItem = styled(MuiListItem, {
  shouldForwardProp: prop => prop !== 'isDirectory',
})<{ isDirectory: boolean }>(({ theme, isDirectory }) => ({
  gap: theme.spacing(3),

  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: Colors.WHITE,

  cursor: isDirectory ? 'pointer' : 'initial',
}));

const DirectoryLabel = styled(Typography)({
  fontWeight: 700,
});

const FileLabel = styled(Typography)({
  fontWeight: 400,
  flexGrow: 1,
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
});

const DownloadIcon = styled(Download)({
  fontSize: '19px',
  cursor: 'pointer',
});
