export enum EditorType {
  ARRAY = 'ARRAY',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  CONNECTION_ONLY = 'CONNECTION_ONLY',
  CHECKBOX = 'CHECKBOX',
  CHERRY_PICK = 'CHERRY_PICK',
  CHROMATOGRAPHY_ACTIONS_MAP = 'CHROMATOGRAPHY_ACTIONS_MAP',
  GRADIENT_CHROMATOGRAPHY_ACTIONS_MAP = 'GRADIENT_CHROMATOGRAPHY_ACTIONS_MAP',
  DECK_POSITIONS = 'DECK_POSITIONS',
  DNA = 'DNA',
  DOE_MERGE_OPTION = 'DOE_MERGE_OPTION',
  DOE_MIX_RULES = 'DOE_MIX_RULES',
  DROPDOWN = 'DROPDOWN',
  EXISTING_PLATE = 'EXISTING_PLATE',
  FILE = 'FILE',
  FILE_SERIES = 'FILE_SERIES',
  FILTER_PLATE_PROTOCOL_DESIGN = 'FILTER_PLATE_PROTOCOL_DESIGN',
  FLOAT = 'FLOAT',
  INT = 'INT',
  LIQUID = 'LIQUID',
  MAP = 'MAP',
  MEASUREMENT = 'MEASUREMENT',
  MULTI_FILE = 'MULTI_FILE',
  ROBOCOLUMN_LAYOUT_MAP = 'ROBOCOLUMN_LAYOUT_MAP',
  PLATE_READER_ABSORBANCE_PROTOCOL = 'PLATE_READER_ABSORBANCE_PROTOCOL',
  PLATE_READER_ABSORBANCE_SPECTRA_PROTOCOL = 'PLATE_READER_ABSORBANCE_SPECTRA_PROTOCOL',
  PLATE_READER_FLUORESCENCE_PROTOCOL = 'PLATE_READER_FLUORESCENCE_PROTOCOL',
  PLATE_READER_LUMINESCENCE_PROTOCOL = 'PLATE_READER_LUMINESCENCE_PROTOCOL',
  PLATE_READER_SHAKING_TYPE = 'PLATE_READER_SHAKING_TYPE',
  PLATE_WASHER_PROTOCOL = 'PLATE_WASHER_PROTOCOL',
  PLATE = 'PLATE',
  PLATE_CONTENTS = 'PLATE_CONTENTS',
  PLATE_CONTENTS_SUMMARY = 'PLATE_CONTENTS_SUMMARY',
  PLATE_LAYOUT = 'PLATE_LAYOUT',
  PLATE_LAYOUT_LAYERS = 'PLATE_LAYOUT_LAYERS',
  PLATE_TYPE = 'PLATE_TYPE',
  POLICY = 'POLICY',
  // TODO This type can go once all workflows using it
  // are migrated to the newer `SIMULATION_RESULTS` (T449)
  SIMULATION = 'SIMULATION',
  SIMULATION_RESULTS = 'SIMULATION_RESULS',
  SPREADSHEET = 'SPREADSHEET',
  STRING = 'STRING',
  STRING_ARRAY = 'STRING_ARRAY',
  TIP_TYPE = 'TIP_TYPE',
  TOGGLE = 'TOGGLE',
  TOGGLE_BUTTONS = 'TOGGLE_BUTTONS',
  WELL_COORDS = 'WELL_COORDS',
  UNIT = 'UNIT',
}

/**
 * This is a sensible type to to fall back on if an editor type cannot be retrieved.
 */
export const BACKUP_EDITOR_TYPE = EditorType.STRING;

/**
 * These are sensible editor types to fall back on in cases where editor types
 * cannot be retrieved.
 */
export const BACKUP_EDITOR_OPTIONS = [EditorType.STRING, EditorType.CONNECTION_ONLY];
