import { useCallback, useState } from 'react';

// A hook to update both state and localStorage
export default function useStateWithLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, (value: T) => void] {
  return useStateWithStorage(key, initialValue, window.localStorage);
}

export function useStateWithSessionStorage<T>(
  key: string,
  initialValue: T,
): [T, (value: T) => void] {
  return useStateWithStorage(key, initialValue, window.sessionStorage);
}

function useStateWithStorage<T>(
  key: string,
  initialValue: T,
  storage: Storage,
): [T, (value: T) => void] {
  // Try to retrieve value already stored or use value provided by the caller
  const [dataToStore, setDataToStore] = useState<T>(() => {
    try {
      const item = storage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setData = useCallback(
    (value: T) => {
      setDataToStore(value);
      storage.setItem(key, JSON.stringify(value));
    },
    [key, storage],
  );

  return [dataToStore, setData];
}
