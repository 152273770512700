import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ElementParameterHelpIcon from 'client/app/components/Parameters/ElementParameterHelpIcon';
import { useFilterPlateEditorState } from 'client/app/components/Parameters/FiltrationPlateLayout/lib/editorState';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

const WELL_CAPACITY_PAD = 8;

type Props = {
  elementId: string;
  parameterName: string;
  isReadonly: boolean;
};

export default function ResinBufferInput({
  elementId,
  parameterName,
  isReadonly,
}: Props) {
  const classes = useStyles();
  const [dirty, setDirty] = useState(false);

  const {
    state: { isEditing, resinBufferVolume, wellCapacity, error },
    changeResinBufferVolume,
  } = useFilterPlateEditorState();

  const handleChange = useTextFieldChange(input => {
    const newValue = +input;
    if (!isNaN(newValue)) {
      changeResinBufferVolume(newValue);
      if (!dirty) setDirty(true);
    }
  });
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    /**
     * We want to select all the input so user doesn't have to remove 0 manualy.
     */
    event.target.select();
  };
  const handleBlur = () => setDirty(true);

  const wellCapacityCopy = ` / ${wellCapacity}`;

  return (
    <section className={classes.parameterInput}>
      <div className={classes.inputLabelSet}>
        <Typography className={classes.inputLabel} variant="subtitle2" noWrap>
          Resin + Buffer Volume / Total
        </Typography>
        <ElementParameterHelpIcon elementId={elementId} name={parameterName} />
      </div>
      <Box display="flex" alignItems="center">
        <div className={classes.wellVolumeWrap}>
          <TextField
            type="number"
            variant="outlined"
            value={resinBufferVolume}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={isEditing || isReadonly}
            error={dirty && !!error?.resinBufferVolume}
            helperText={
              <Typography
                variant="caption"
                noWrap
                hidden={!dirty || !error?.resinBufferVolume}
                className={classes.wellVolumeError}
              >
                {error?.resinBufferVolume}
              </Typography>
            }
            inputProps={{
              className: classes.wellVolumeInput,
              min: 0,
              style: {
                paddingRight: `${wellCapacityCopy.length * WELL_CAPACITY_PAD}px`,
              },
            }}
          />
          <Typography variant="body2" className={classes.wellCapacity}>
            {wellCapacityCopy}
          </Typography>
        </div>
        <Typography className={classes.unit} variant="subtitle2" noWrap>
          ul
        </Typography>
      </Box>
    </section>
  );
}

const useStyles = makeStylesHook(({ spacing, palette }) => ({
  parameterInput: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputLabelSet: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(2),
  },
  inputLabel: {
    marginRight: spacing(3),
  },
  wellVolumeWrap: {
    position: 'relative',
    marginRight: spacing(2),
  },
  wellVolumeError: {
    position: 'absolute',
    top: 45,
    left: 0,
  },
  wellVolumeInput: {
    textAlign: 'right',
    width: '50px',
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
  },
  wellCapacity: {
    position: 'absolute',
    top: 0,
    right: WELL_CAPACITY_PAD,
    lineHeight: '38px',
    color: palette.text.secondary,
  },
  unit: {
    marginBottom: spacing(2),
  },
}));
