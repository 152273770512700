import React from 'react';

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import {
  getSelectPositionsButtonCopy,
  useSelectedDeckConfiguration,
} from 'client/app/components/Parameters/DeckPositions/lib/deckPositionsParameterUtils';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  value: string[] | undefined;
};

export default function DeckPositionsParameter({ value }: Props) {
  const classes = useStyles();
  const dispatch = useWorkflowBuilderDispatch();
  const additionalPanel = useWorkflowBuilderSelector(state => state.additionalPanel);

  /**
   * runConfiguration defines deck layout.
   * Deck layout should be selected in settings beforehand.
   * Setting DeckPositionsParameter without deck layout should not be possible.
   * Editor should be disabled and error displayed.
   */
  const { loading, disableDeckPositions } = useSelectedDeckConfiguration();

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress size="1.5em" />
      </Box>
    );
  }

  const handleClick = () => {
    dispatch({ type: 'setAdditionalPanel', payload: 'DeckPositions' });
  };
  const buttonCopy = getSelectPositionsButtonCopy(value);

  return (
    <>
      <Button
        className={cx(classes.deckPositionsBtn, {
          [classes.active]: additionalPanel === 'DeckPositions',
        })}
        variant="secondary"
        onClick={handleClick}
        startIcon={<ChevronLeftOutlinedIcon />}
        disabled={disableDeckPositions}
      >
        <Typography className={classes.buttonText} variant="body2">
          {buttonCopy}
        </Typography>
      </Button>
      {disableDeckPositions && (
        <FormHelperText className={classes.errorText}>
          Please select deck layout in settings before selecting deck positions
        </FormHelperText>
      )}
    </>
  );
}

const useStyles = makeStylesHook(({ palette }) => ({
  deckPositionsBtn: {
    width: '100%',
    justifyContent: 'center',
  },
  buttonText: {
    textTransform: 'uppercase',
  },
  errorText: {
    color: palette.error.main,
  },
  active: {
    background: Colors.BLUE_5,
    borderColor: palette.primary.main,
    color: palette.primary.main,
  },
}));
