import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Context } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

export type Permission<PermissionKeys> = PermissionKeys;

export type PermissionsContext<PermissionKeys> = {
  currentUserHasPermission: (...permissions: Permission<PermissionKeys>[]) => boolean;
  permissions: Permission<PermissionKeys>[];
  updatePermissions: () => Promise<void>;
};

export const NO_PERMISSIONS = [];
export const DEFAULT_CONTEXT: PermissionsContext<unknown> = {
  currentUserHasPermission: () => false,
  permissions: NO_PERMISSIONS,
  updatePermissions: () => Promise.resolve(),
};

// Parse permissions from the access token, so we can gate UI features
// based on them. The access token is a JWT (https://jwt.io), a string
// containing three parts, separated by dots. The second part is the
// token's payload, presented as a base64url-encoded JSON document.
const permissionsFromAccessToken = (accessToken: string) => {
  const payloadBase64URLEncoded = accessToken.split('.')[1];
  const payloadBase64Encoded = payloadBase64URLEncoded
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  const payload = JSON.parse(atob(payloadBase64Encoded));
  return payload['permissions'];
};

type PermissionsProviderProps<PermissionKeys> = {
  children: React.ReactNode;
  defaultPermissions?: Permission<PermissionKeys>[];
};

export function createPermissionsProvider<PermissionKeys>(
  permissionsContext: Context<PermissionsContext<PermissionKeys>>,
) {
  return (props: PermissionsProviderProps<PermissionKeys>) => {
    const { getAccessTokenSilently } = useAuth0();
    const { children, defaultPermissions = NO_PERMISSIONS } = props;
    const [permissions, setPermissions] =
      useState<Permission<PermissionKeys>[]>(defaultPermissions);

    useEffect(() => {
      (async () => {
        const accessToken = await getAccessTokenSilently();
        try {
          if (accessToken) {
            const permissions = permissionsFromAccessToken(accessToken);
            setPermissions(permissions);
          } else {
            setPermissions(defaultPermissions);
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }, [getAccessTokenSilently, defaultPermissions, setPermissions]);

    // The linter doesn't know that _currentUserHasPermission has no dependencies other then permissions,
    // which is already declared as a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const currentUserHasPermission = useCallback(_currentUserHasPermission(permissions), [
      permissions,
      _currentUserHasPermission,
    ]);
    const updatePermissions = useCallback(async () => {
      const accessToken = await getAccessTokenSilently({ cacheMode: 'off' });
      try {
        const permissions = permissionsFromAccessToken(accessToken);
        setPermissions(permissions);
      } catch (e) {
        console.error(e);
      }
    }, [getAccessTokenSilently]);

    const ctx = useMemo<PermissionsContext<PermissionKeys>>(
      () => ({ currentUserHasPermission, permissions, updatePermissions }),
      [currentUserHasPermission, permissions, updatePermissions],
    );

    return (
      <permissionsContext.Provider value={ctx}>{children}</permissionsContext.Provider>
    );
  };
}

export const _currentUserHasPermission =
  <PermissionKeys,>(permissions: PermissionKeys[]) =>
  (...permission: Permission<PermissionKeys>[]): boolean =>
    permission.every(element => permissions.includes(element));
