import React, { useContext } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { WorkTreeModeContext } from 'client/app/apps/work-tree/WorkTreeModeContext';
import { WorkTreeApplicationName } from 'client/app/gql';
import { pluralize } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';

type WorkTreeCreateModePanelProps = {
  handleCancel: () => void;
  handleConfirm: () => void;
  selectedItems: number;
  applicationName: WorkTreeApplicationName;
};

export function WorkTreeCreateModePanel({
  handleCancel,
  handleConfirm,
  selectedItems,
  applicationName,
}: WorkTreeCreateModePanelProps) {
  const context = useContext(WorkTreeModeContext);

  if (context.mode !== 'creating-block') {
    // We only show the panel in 'creating-block' mode.
    return null;
  }

  const inputResultRequirements = context.creatingBlock.inputResultRequirements;
  const minItems = inputResultRequirements.minItems;
  const maxItems = inputResultRequirements.maxItems ?? Infinity;

  const description = getDescription(applicationName, minItems, maxItems);

  const canConfirm = selectedItems >= minItems && selectedItems <= maxItems;

  const validationStatus = getValidationStatus(selectedItems, minItems, maxItems);

  const selectionMessage = getSelectionMessage(selectedItems, maxItems);

  return (
    <WorkTreeModePanel
      title={`${
        applicationName.charAt(0).toUpperCase() + applicationName.slice(1)
      } result data`}
      description={description}
      selectionMessage={selectionMessage}
      validationStatus={validationStatus}
      confirmText="Create"
      confirmDisabled={!canConfirm}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    />
  );
}

/**
 * Formats the description to show on the WorkTreeCreateModePanel
 */
function getDescription(
  applicationName: WorkTreeApplicationName,
  minItems: number,
  maxItems: number,
): string {
  if (minItems === maxItems) {
    return `Select ${pluralize(minItems, 'block')} to ${applicationName}`;
  } else if (minItems > 0 && maxItems < Infinity) {
    return `Select between ${minItems} and ${maxItems} blocks to ${applicationName}`;
  } else if (minItems === 0 && maxItems < Infinity) {
    return `Select up to ${pluralize(maxItems, 'block')} to ${applicationName}`;
  } else if (minItems > 0 && maxItems === Infinity) {
    return `Select at least ${pluralize(minItems, 'block')} to ${applicationName}`;
  }
  return '';
}

/**
 * Returns the ValidationStatus to pass to WorkTreeModePanel.
 *
 * The return statuses are:
 *
 * 'warning' in cases when the user exceeds the maximum selection,
 * 'ok' when the user has met the minimum selection, but not exceeded the maximum,
 * 'neutral' when the user has not yet met the minimum selection,
 */
function getValidationStatus(
  selectedItems: number,
  minItems: number,
  maxItems: number,
): ValidationStatus {
  if (selectedItems > 0) {
    if (selectedItems <= maxItems && selectedItems >= minItems) {
      return 'ok';
    } else if (selectedItems > maxItems) {
      return 'warning';
    }
  }
  return 'neutral';
}

/**
 * Returns the selectionMessage to show on WorkTreeModePanel
 */
function getSelectionMessage(selectedItems: number, maxItems: number): string {
  if (selectedItems > maxItems) {
    return `You have selected more than ${pluralize(maxItems, 'block')}`;
  }
  return `${pluralize(selectedItems, 'block')} selected`;
}

type WorkTreeHiddenModePanelProps = {
  handleCancel: () => void;
  handleConfirm: () => void;
  hiddenCount: number;
};

export function WorkTreeHiddenModePanel({
  handleCancel,
  handleConfirm,
  hiddenCount,
}: WorkTreeHiddenModePanelProps) {
  return (
    <WorkTreeModePanel
      title="Set visibility of blocks"
      selectionMessage={`${pluralize(hiddenCount, 'block')} hidden`}
      confirmText="Done"
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    />
  );
}

type ValidationStatus = 'neutral' | 'ok' | 'warning';

export type WorkTreeModePanelProps = {
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  description?: string;
  selectionMessage: string;
  validationStatus?: ValidationStatus;
  confirmDisabled?: boolean;
  confirmText: string;
};

/**
 * Non-model panel to show instructions and allow exiting from the current mode.
 */
export function WorkTreeModePanel({
  title,
  selectionMessage,
  validationStatus,
  description,
  confirmText,
  confirmDisabled,
  onConfirm,
  onCancel,
}: WorkTreeModePanelProps) {
  return (
    <SelectionActions>
      <SelectionActionTextWrapper>
        <Typography variant="h5">{title}</Typography>
        {description && <Typography noWrap>{description}</Typography>}
        <SelectionMessage>
          {validationStatus && <ValidationIcon validationStatus={validationStatus} />}
          <SelectionMessageTypography noWrap warning={validationStatus === 'warning'}>
            {selectionMessage}
          </SelectionMessageTypography>
        </SelectionMessage>
      </SelectionActionTextWrapper>
      <SelectionActionButtonsWrapper>
        <Button onClick={onCancel} variant="tertiary" size="large">
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant="tertiary"
          size="large"
          color="primary"
          disabled={confirmDisabled}
        >
          {confirmText}
        </Button>
      </SelectionActionButtonsWrapper>
    </SelectionActions>
  );
}

type ValidationIconProps = {
  validationStatus: ValidationStatus;
};

function ValidationIcon(props: ValidationIconProps) {
  const { validationStatus } = props;
  switch (validationStatus) {
    case 'neutral':
      return <StyledCheckCircleIcon status="neutral" />;
    case 'ok':
      return <StyledCheckCircleIcon status="ok" />;
    case 'warning':
      return <WarningIcon />;
  }
}

const SelectionActions = styled('div')(({ theme }) => ({
  width: '420px',
  padding: theme.spacing(4, 3, 2, 4),
}));

const SelectionActionButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'right',
  marginTop: theme.spacing(3),
}));

const SelectionActionTextWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
}));

const SelectionMessage = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

const StyledCheckCircleIcon = styled(CheckCircleIcon, {
  shouldForwardProp: name => name !== 'neutral' && name !== 'ok',
})<{ status: 'neutral' | 'ok' }>(({ status, theme }) => ({
  width: '16px',
  height: '16px',
  color: status === 'neutral' ? Colors.DARK_TEXT_PRIMARY : theme.palette.success.main,
}));

const WarningIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
}));

const SelectionMessageTypography = styled(Typography, {
  shouldForwardProp: name => name !== 'warning',
})<{ warning: boolean }>(({ theme, warning }) =>
  warning
    ? {
        color: theme.palette.warning.main,
        fontWeight: 'bold',
      }
    : undefined,
);
