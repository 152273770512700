import React, { useContext, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import TextFieldMUI from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CommentContext from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/components/CommentContext';
import UIErrorBox from 'client/app/components/UIErrorBox';
import { getErrorMessage } from 'common/lib/graphQLErrors';
import Button from 'common/ui/components/Button';
import { DialogProps } from 'common/ui/hooks/useDialog';

export type Props = {
  existingComment: string | null;
  /** 1-indexed stage order number */
  orderNum: number;
  executionStageId: string;
  readonly: boolean;
} & DialogProps<void>;

function CommentDialogue({
  existingComment,
  orderNum,
  executionStageId,
  onClose,
  isOpen,
  readonly,
}: Props) {
  const [comment, setComment] = useState<string>(existingComment ?? '');
  const {
    updateCommentMutationTuple: [updateComment, { loading, error }],
  } = useContext(CommentContext);

  const closeDialog = () => {
    onClose();
  };

  const save = async () => {
    const newComment = comment === '' ? null : comment;
    // This should automatically update the cache as the mutation returns the new comment
    await updateComment({
      variables: { comment: newComment, executionStageId },
      onCompleted: () => {
        onClose();
      },
    });
  };

  const dialogueTitle = `Stage ${orderNum}: Comment`;

  return (
    <Dialog open={isOpen} onClose={closeDialog} maxWidth="sm" fullWidth scroll="paper">
      <>
        {error ? <UIErrorBox>{getErrorMessage(error)}</UIErrorBox> : null}
        <DialogTitle component="span">
          <Typography variant="h3">{dialogueTitle}</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            required
            placeholder="Add a comment about your execution"
            value={comment}
            onChange={e => setComment(e.target.value)}
            multiline
            minRows={4}
            fullWidth
            disabled={readonly}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="tertiary" onClick={closeDialog} disabled={loading}>
            Close
          </Button>
          {readonly ? null : (
            <Button
              variant="tertiary"
              color="primary"
              onClick={save}
              startIcon={loading && <CircularProgress size={10} />}
              disabled={loading || !comment}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </>
    </Dialog>
  );
}

const TextField = styled(TextFieldMUI)(({ theme }) => ({
  '& textarea.Mui-disabled': {
    color: theme.palette.text.primary,
    WebkitTextFillColor: 'unset',
  },
}));

export default CommentDialogue;
