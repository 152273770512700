import { useMemo } from 'react';

import {
  FactorKind,
  FactorParameterInfo,
  MutualExclusionInfo,
} from 'client/app/components/DOEFactorForm/types';
import { EditorType } from 'common/elementConfiguration/EditorType';
import {
  getKeyTypeFromAnthaType,
  getValueTypeFromAnthaType,
} from 'common/elementConfiguration/parameterUtils';
import { FactorItem, Parameter } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';

const SHOW_MORE_LABEL_WIDTH = 60;
const LEVEL_MARGIN = 8;
const PANEL_TRANSITION = 200;

/**
 * Examine the configuration of an element parameter to figure out what kind of
 * factor(s) can be assigned to it. Only double-maps support multiple factors.
 *
 * This info is used in the panel to determine whether to enable the add factor button
 * and inside the factor details panel to control which editors are shown.
 */
function getEditorInfo(param?: Parameter) {
  let result: {
    allowMultipleFactors: boolean;
    keyType?: string;
    valueType?: string;
    keyEditor?: ParameterEditorConfigurationSpec;
    valueEditor?: ParameterEditorConfigurationSpec;
  };
  const editor = param?.configuration?.editor;

  if (!editor) {
    return { allowMultipleFactors: false };
  }

  const valueType = getValueTypeFromAnthaType(param.type);

  if (
    editor.type === EditorType.MAP &&
    editor.additionalProps?.editor === EditorType.MAP
  ) {
    const nestedEditor = editor.additionalProps.valueEditor;

    result =
      nestedEditor?.type === EditorType.MAP &&
      nestedEditor?.additionalProps?.editor === EditorType.MAP
        ? {
            keyType: getKeyTypeFromAnthaType(valueType),
            keyEditor: nestedEditor.additionalProps.keyEditor,
            valueEditor: nestedEditor.additionalProps.valueEditor,
            allowMultipleFactors: true,
          }
        : {
            valueEditor: editor.additionalProps.valueEditor,
            allowMultipleFactors: false,
            valueType,
          };
  } else if (
    editor.type === EditorType.ARRAY &&
    editor.additionalProps?.editor === EditorType.ARRAY
  ) {
    result = {
      valueEditor: editor.additionalProps.itemEditor,
      allowMultipleFactors: false,
      valueType,
    };
  } else {
    result = {
      valueEditor: editor,
      allowMultipleFactors: false,
      valueType,
    };
  }

  return result;
}

function getTypeCopy(factor: FactorItem) {
  switch (factor.variableTypeName) {
    case 'factor':
      return factor.values.length === 1 ? 'Constant' : 'Design';
    case 'derived':
      return 'Derived';
    case 'quasi-replicating':
      return 'Quasi Replicate';
    default:
      return factor.variableTypeName;
  }
}

export function getFactorName(factor: Pick<FactorItem, 'displayName' | 'path'>) {
  return !factor.path?.[1]
    ? factor.displayName
    : `${factor.displayName} (${factor.path[1]})`;
}

const isNumericFactor = (factor: Pick<FactorItem, 'typeName'>) =>
  factor.typeName === 'continuous' ||
  factor.typeName === 'discrete' ||
  factor.typeName === 'ordinal';

const NUMERICAL_TYPES = [EditorType.MEASUREMENT, EditorType.INT, EditorType.FLOAT];

export function isNumericParameter(parameterInfo?: FactorParameterInfo): boolean {
  return (
    !!parameterInfo?.valueEditor?.type &&
    NUMERICAL_TYPES.includes(parameterInfo?.valueEditor?.type)
  );
}

export function getBasicFactorType(
  typeToAdd: 'numerical-factor' | 'categorical-factor' | 'derived' | 'constant',
  parameterInfo?: FactorParameterInfo,
  mutualExclusion?: MutualExclusionInfo,
) {
  return typeToAdd === 'numerical-factor' ||
    !!mutualExclusion ||
    isNumericParameter(parameterInfo)
    ? 'continuous'
    : 'nominal';
}

export function usePermittedFactorKinds(
  parameterInfo?: FactorParameterInfo,
  derivableFactors?: FactorItem[],
): FactorKind[] {
  return useMemo(() => {
    const permitted: FactorKind[] = ['constant'];

    const numericParameter = isNumericParameter(parameterInfo);

    if (numericParameter) {
      permitted.push('numerical-factor');
      if (parameterInfo?.allowMultipleFactors) {
        permitted.push('categorical-factor');
      }
    } else {
      permitted.push('categorical-factor');
    }

    if (parameterInfo) {
      if (parameterInfo.allowMultipleFactors) {
        permitted.push('mutual-exclusion');
      }
      if (derivableFactors?.length) {
        permitted.push('derived');
      }
    } else {
      // For custom factors
      permitted.push('numerical-factor', 'derived', 'mutual-exclusion');
    }

    return permitted;
  }, [derivableFactors?.length, parameterInfo]);
}

/**
 *  Returns the descriptor for the factor. If the factor has a path, then
 *  the factor descriptor consists of the path with the display name appended.
 *  If not, then the descriptor is just the display name.
 *  The path will be a unique path from workflow to factor, which is omitted
 *  if the factor does not refer to an element parameter.
 * e.g.
 * [ 'DOE Workflow', 'Make Mixtures', 'TargetVolume' ]
 * [ 'DOE Workflow', 'Make Mixtures', 'Compositions', 'Glucose' ]
 * @param factor
 * @returns String descriptor
 */
export function getFactorDescriptor(
  factor: Pick<FactorItem, 'path' | 'displayName'>,
): string {
  if (!factor.path) {
    return factor.displayName;
  }
  return factor.path.includes(factor.displayName)
    ? factor.path.join('/')
    : [...factor.path, factor.displayName].join('/');
}

export {
  SHOW_MORE_LABEL_WIDTH,
  LEVEL_MARGIN,
  PANEL_TRANSITION,
  getEditorInfo,
  getTypeCopy,
  isNumericFactor,
};
