import React from 'react';

import { ExperimentsNavigation } from 'client/app/apps/experiments/ExperimentsNavigation';
import { Tutorials } from 'client/app/apps/experiments/Tutorials/Tutorials';

export default function TutorialsScreen() {
  return (
    <ExperimentsNavigation title="Tutorials">
      <Tutorials />
    </ExperimentsNavigation>
  );
}
