import React from 'react';

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';

import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { pluralize } from 'common/lib/format';
import { FiltrationDesign } from 'common/types/filtration';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  value?: FiltrationDesign;
};

export default function FiltrationProtocolDesignParameter({ value }: Props) {
  const active =
    useWorkflowBuilderSelector(state => state.additionalPanel) ===
    'FiltrationProtocolDesign';

  const classes = useStyles({ active });
  const dispatch = useWorkflowBuilderDispatch();

  const handleClick = () => {
    dispatch({
      type: 'setAdditionalPanel',
      payload: 'FiltrationProtocolDesign',
    });
  };

  return (
    <Button
      className={cn(classes.launchButton, { [classes.active]: active })}
      variant="secondary"
      onClick={handleClick}
      startIcon={<ChevronLeftOutlinedIcon />}
    >
      <Typography className={classes.buttonText} variant="body2">
        {buildCopy(value)}
      </Typography>
    </Button>
  );
}

function buildCopy(value?: FiltrationDesign) {
  if (isEmpty(value)) return 'Configure Design';

  const factors = value.factors.length;
  const liquids = value.liquidGroups.length;

  return `${pluralize(factors, 'factor')}, ${pluralize(liquids, 'liquid group')}`;
}

const useStyles = makeStylesHook(({ palette }) => ({
  launchButton: {
    width: '100%',
    justifyContent: 'center',
  },
  buttonText: {
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  errorText: {
    color: palette.error.main,
  },
  progress: {
    color: 'inherit',
    margin: 'auto',
  },
  active: {
    background: Colors.BLUE_5,
    borderColor: palette.primary.main,
    color: palette.primary.main,
  },
}));
