import { createContext } from 'react';

export type WorkspaceCoordinates = {
  toScreenX: <T extends number | undefined>(
    x: T,
  ) => T extends number ? number : undefined;
  visibleWorkspaceArea: {
    left: number;
    top: number;
    width: number;
    height: number;
    right: number;
    bottom: number;
    zoom: number;
  };
};

const WorkspaceCoordinatesContext = createContext<WorkspaceCoordinates>({
  visibleWorkspaceArea: {
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    right: 0,
    bottom: 0,
    zoom: 1,
  },
  toScreenX(x: any) {
    return x === undefined ? undefined : x;
  },
});

export default WorkspaceCoordinatesContext;
