import React from 'react';

import WarningIcon from '@mui/icons-material/Warning';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import Colors from 'common/ui/Colors';
import { SMART_WORD_BREAK_STYLE } from 'common/ui/commonStyles';
import UIBox from 'common/ui/components/UIBox';

type Props = {
  heading: string;
  errors: readonly string[];
} & WithStyles<typeof styles>;

function ErrorListBox(props: Props) {
  const { heading, errors, classes } = props;
  return (
    <ErrorBoxComponent heading={heading} classes={classes}>
      {errors.map((err, index) => (
        <p key={index} className={classes.errorItem}>
          {err}
        </p>
      ))}
    </ErrorBoxComponent>
  );
}

type ErrorBoxProps = {
  heading: string;
  children: React.ReactNode;
} & WithStyles<typeof styles>;

function ErrorBoxComponent(props: ErrorBoxProps) {
  const { classes, children } = props;
  return (
    <UIBox margin="vm" className={classes.errorListBox}>
      <div className={classes.errorHeader}>
        <WarningIcon className={classes.errorIcon} />
        {props.heading}
      </div>
      <UIBox padding="m">{children}</UIBox>
    </UIBox>
  );
}

const styles = createStyles({
  errorListBox: {
    border: `1px solid ${Colors.ERROR}`,
    borderRadius: '5px',
    backgroundColor: Colors.WHITE,
  },
  errorIcon: {
    marginRight: '8px',
  },
  errorHeader: {
    alignItems: 'center',
    color: Colors.WHITE,
    backgroundColor: Colors.ERROR,
    display: 'flex',
    padding: '12px 16px',
    verticalAlign: 'middle',
    width: '100%',
  },
  errorList: {
    padding: '0 16px 0 20px',
    whiteSpace: 'pre-wrap',
  },
  errorItem: {
    ...SMART_WORD_BREAK_STYLE,
  },
});

const ErrorBox = withStyles(styles)(ErrorBoxComponent);

export { ErrorBox };

export default withStyles(styles)(ErrorListBox);
