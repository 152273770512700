import plateCarriers from 'common/types/plateCarriers';

export type PlateNameData = {
  // The human-readable name of the plate
  name: string;
  // The identifier of the plate type (called the "plate type" elsewhere)
  id: string;
};

// Logic copied from inventory-service
// (calculatePlateRiserTypeFromContainerName) and simplified.
export function getHumanReadablePlateLabel(
  plateAndCarrierConcatenated: string, // e.g. "SRWFB96_riser18"
  plateTypes: readonly PlateNameData[],
): string {
  // Plate types that appear at the start of the convoluted string,
  // for example if the convoluted string is "SRWFB96_riser18", plate
  // types will be two existing plates: ["SRWFB96", "SRWFB"].
  const plateCandidates = plateTypes.filter(plateType =>
    plateAndCarrierConcatenated.startsWith(plateType.id),
  );
  // Now, for each of the plate types, try to append all possible carriers
  // and see if "SRWFB96" + "_riser18" matches the given "SRWFB96_riser18".
  for (const plateType of plateCandidates) {
    for (const carrier of plateCarriers) {
      if (plateAndCarrierConcatenated === plateType.id + carrier.id) {
        if (carrier.id === '') {
          // This truly special empty id means 'no carrier'
          return plateType.name;
        } else {
          return `${plateType.name} on a ${carrier.name}`;
        }
      }
    }
  }
  // If we can't find more info, returning the raw input will have to do
  return plateAndCarrierConcatenated;
}
