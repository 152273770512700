import React, { useState } from 'react';

import Box from '@mui/material/Box';
import MuiDialog, { dialogClasses } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

import AccessibleDevicesPanel from 'client/app/components/DeviceLibrary/AccessibleDevicesPanel';
import DeviceConfigsPanel from 'client/app/components/DeviceLibrary/DeviceConfigsPanel';
import LiquidClassesPanel from 'client/app/components/DeviceLibrary/LiquidClassesPanel';
import UploadRunConfig from 'client/app/components/DeviceLibrary/UploadDeviceConfigButton';
import { DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import Button from 'common/ui/components/Button';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';

type Props = {
  onClose: () => void;
  device: DeviceCommon;
  onRefresh: () => void;
};

enum DeviceConfigTab {
  RUN_CONFIG,
  LIQUID_CLASS,
  ACCESSIBLE_DEVICES,
}

/**
 * If the device has this category, we show the tab 'Accessible Devices'.
 * Yes, this is a hardcoded string but in fact it is the unique identifier of the category, see
 * SQL migrations where this string is used to refer to this device category in a unique way.
 */
const DEVICE_CATEGORY_SUPPORTS_ACCESSIBLE_DEVICES = 'Liquid Handling Platforms';

function DeviceConfigurationDialog({ device, onClose, onRefresh }: Props) {
  const hasLiquidClasses = !!device.model.series.category.features['hasLiquidClasses'];
  const supportsAccessibleDevices =
    device.model.series.category.name === DEVICE_CATEGORY_SUPPORTS_ACCESSIBLE_DEVICES;
  const [tab, setTab] = useState<DeviceConfigTab>(DeviceConfigTab.RUN_CONFIG);

  const tabsInfo: TabsInfo<DeviceConfigTab> = [
    { label: 'Configurations', value: DeviceConfigTab.RUN_CONFIG },
  ];
  if (hasLiquidClasses) {
    tabsInfo.push({ label: 'Liquid Classes', value: DeviceConfigTab.LIQUID_CLASS });
  }
  if (supportsAccessibleDevices) {
    tabsInfo.push({
      label: 'Accessible Devices',
      value: DeviceConfigTab.ACCESSIBLE_DEVICES,
    });
  }

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{device.name} Configuration</DialogTitle>
      <DialogContent>
        <Box pl={2}>
          <Tabs activeTab={tab} tabsInfo={tabsInfo} onChangeTab={setTab} />
        </Box>
        <Divider />
        <ScrollingContent>
          {tab === DeviceConfigTab.RUN_CONFIG && (
            <DeviceConfigsPanel device={device} onRefresh={onRefresh} />
          )}
          {tab === DeviceConfigTab.LIQUID_CLASS && (
            <LiquidClassesPanel device={device} onRefresh={onRefresh} />
          )}
          {tab === DeviceConfigTab.ACCESSIBLE_DEVICES && (
            <AccessibleDevicesPanel device={device} />
          )}
        </ScrollingContent>
      </DialogContent>
      <Divider />
      <DialogActions>
        {tab === DeviceConfigTab.RUN_CONFIG && <UploadRunConfig device={device} />}
        <Button onClick={onClose} variant="tertiary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Dialog = styled(MuiDialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: '50vw',
    height: '80vh',
  },
});

const DialogContent = styled(MuiDialogContent)({
  display: 'flex',
  flexDirection: 'column',
});

const ScrollingContent = styled('div')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(0, 5),
  height: '100%',
  overflowY: 'auto',
}));

export default DeviceConfigurationDialog;
