import React, { useCallback, useMemo } from 'react';

import { useApolloClient } from '@apollo/client';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import ConfigFileSummary from 'client/app/components/DeviceLibrary/ConfigFileSummary';
import { callUpdateDeviceConfiguration } from 'client/app/components/DeviceLibrary/mutations';
import { ParsedConfigFile } from 'client/app/components/DeviceLibrary/parseConfigurationFile';
import { DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  onClose: (savedChanges: boolean) => void;
  device: DeviceCommon;
  parsedConfigFile: ParsedConfigFile;
};

function UploadDeviceConfig(props: Props) {
  const classes = useStyles();

  const { device, parsedConfigFile, onClose } = props;
  const apollo = useApolloClient();

  const handleSaveConfig = useCallback(async () => {
    await callUpdateDeviceConfiguration(apollo, {
      deviceId: device.id,
      configuration: parsedConfigFile.instanceConfig,
      runConfigurations: parsedConfigFile.runConfigs,
    });

    onClose(true); // true means we have made some changes that should force a refresh
  }, [
    apollo,
    device.id,
    onClose,
    parsedConfigFile.instanceConfig,
    parsedConfigFile.runConfigs,
  ]);

  const handleCancel = useCallback(() => onClose(false), [onClose]);

  const configSummary = parsedConfigFile.anthaDeviceConfig;

  const [runConfigsToAdd, runConfigsToUpdate] = useMemo(() => {
    if (!parsedConfigFile) {
      return [[], []];
    }

    const toAdd = [];
    const toUpdate = [];
    for (const { ConfigLabel } of parsedConfigFile.runConfigs) {
      if (!ConfigLabel) continue;
      if (device.runConfigSummaries.find(c => c.name === ConfigLabel)) {
        toUpdate.push(ConfigLabel);
      } else {
        toAdd.push(ConfigLabel);
      }
    }
    return [toAdd, toUpdate];
  }, [device, parsedConfigFile]);

  return (
    <Dialog open={!!parsedConfigFile} onClose={handleCancel} fullWidth maxWidth="xs">
      <DialogTitle>Upload device configuration</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <p className={classes.configUpdateWarning}>
          This will update the global configuration of this device and add any run
          configurations based on the file you selected.
        </p>
        <ConfigFileSummary summary={configSummary} />
        {runConfigsToAdd.length > 0 && (
          <ConfigNameList
            label="Following run configurations will be added:"
            names={runConfigsToAdd}
          />
        )}
        {runConfigsToUpdate.length > 0 && (
          <ConfigNameList
            label="Following run configurations will be updated:"
            names={runConfigsToUpdate}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSaveConfig} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ConfigNameList({ label, names }: { label: string; names: string[] }) {
  return (
    <div>
      {label}
      <List>
        {names.map(name => (
          <ListItem key={name}>
            <ListItemText>{name}</ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

const useStyles = makeStylesHook({
  configUpdateWarning: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  dialogContent: {
    color: Colors.BLACK,
    display: 'flex',
    flexDirection: 'column',
  },
});

export default UploadDeviceConfig;
