import {
  HAMILTON_ANTHA_CLASS,
  TECAN_EVO_ANTHA_CLASS,
  TECAN_FLUENT_ANTHA_CLASS,
} from 'common/types/bundle';

const useVendorSoftwareName = (anthaClass: string) => {
  switch (anthaClass) {
    case HAMILTON_ANTHA_CLASS:
      return 'Venus\u00ae';
    case TECAN_EVO_ANTHA_CLASS:
      return 'EVOware\u00ae';
    case TECAN_FLUENT_ANTHA_CLASS:
      return 'Fluent Control\u00ae';
    default:
      return 'vendor software';
  }
};
export default useVendorSoftwareName;
