import { useCallback } from 'react';

/**
 * Returns a memoized partially applied callback.
 *
 * @param boundArg  the arg to be applied
 * @param callback
 *
 * ```
 * function doSomething(argThatRarelyChanges, param1, param2){...}
 *  //...
 * const doSomethingThatWay = usePartialCallback(myWay, doSomething);
 *  //...
 * doSomethingThatWay(param1, param2)
 * ```
 */
export function usePartialCallback<T, Rest extends any[], Result>(
  boundArg: T,
  callback: (boundArg: T, ...rest: Rest) => Result,
): (...rest: Rest) => Result {
  return useCallback(
    (...rest: Rest) => callback(boundArg, ...rest),
    [boundArg, callback],
  );
}
