import React from 'react';

import AutocompleteEditor from 'client/app/components/Parameters/AutocompleteEditor';
import DNAParameter from 'client/app/components/Parameters/DNA/DNAParameter';
import DropdownEditor from 'client/app/components/Parameters/DropdownEditor';
import PlateSelectionEditor from 'client/app/components/Parameters/PlateType/PlateSelectionEditor';
import { PlateTypeSelect } from 'client/app/components/Parameters/PlateType/PlateTypeSelect';
import PolicyParameter from 'client/app/components/Parameters/Policy/PolicyParameter';
import {
  AutocompleteAdditionalProps,
  DropdownAdditionalProps,
} from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { CellEditorComponentProps, DefaultCellEditor } from 'common/ui/components/Table';

export default React.memo(function SpreadsheetCellEditor({
  editorType,
  editorProps,
  anthaType,
  value,
  isDisabled,
  onChange,
}: CellEditorComponentProps) {
  if (editorType === EditorType.AUTOCOMPLETE) {
    const {
      canAcceptCustomValues,
      useDynamicOptions,
      staticOptions,
      anthaTypeOverride,
      additionalSourceTypes,
      provideDefaultKey,
    } = editorProps as AutocompleteAdditionalProps;
    return (
      <AutocompleteEditor
        anthaType={anthaType}
        value={value}
        onChange={onChange}
        staticOptions={staticOptions}
        useDynamicOptions={useDynamicOptions}
        anthaTypeOverride={anthaTypeOverride}
        additionalSourceTypes={additionalSourceTypes}
        canAcceptCustomValues={canAcceptCustomValues}
        provideDefaultKey={provideDefaultKey}
        isDisabled={isDisabled}
        disableUnderline
      />
    );
  }
  if (editorType === EditorType.DROPDOWN) {
    const { useDynamicOptions, anthaTypeOverride, options } =
      editorProps as DropdownAdditionalProps;
    return (
      <DropdownEditor
        anthaType={anthaType}
        value={value}
        onChange={onChange}
        options={options}
        useDynamicOptions={useDynamicOptions}
        anthaTypeOverride={anthaTypeOverride}
        isDisabled={isDisabled}
        disableUnderline
      />
    );
  }
  if (editorType === EditorType.DNA) {
    return <DNAParameter value={value} onChange={onChange} isDisabled={isDisabled} />;
  }
  if (editorType === EditorType.PLATE_TYPE) {
    return (
      <PlateSelectionEditor
        value={value}
        onChange={onChange}
        isDisabled={isDisabled}
        plateSelectors={[PlateTypeSelect]}
      />
    );
  }
  if (editorType === EditorType.POLICY) {
    return <PolicyParameter value={value} onChange={onChange} isDisabled={isDisabled} />;
  }
  return (
    <DefaultCellEditor
      editorType={editorType}
      editorProps={editorProps}
      anthaType={anthaType}
      value={value}
      onChange={onChange}
      isDisabled={isDisabled}
    />
  );
});
