import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function BioprocessIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.7545 10L7 10L7 20H20V12H19.6974L17.7545 10ZM21.1875 22C21.6362 22 22 21.6362 22 21.1875V11.8297C22 11.6183 21.9176 11.4152 21.7703 11.2635L18.8393 8.24636C18.6863 8.08887 18.4761 8 18.2565 8H5.8125C5.36377 8 5 8.36377 5 8.8125V21.1875C5 21.6362 5.36377 22 5.8125 22H21.1875Z"
          fill="currentColor"
        />
        <path d="M17 13H21L17 9V13Z" fill="currentColor" />
        <path d="M8 11H11V13H8V11Z" fill="currentColor" />
        <path d="M8 14H11V16H8V14Z" fill="currentColor" />
        <path d="M8 17H11V19H8V17Z" fill="currentColor" />
        <path d="M12 14H19V16H12V14Z" fill="currentColor" />
        <path d="M12 17H19V19H12V17Z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.588 4L4 4L4 14H5V16L2.8125 16C2.36377 16 2 15.6362 2 15.1875V2.8125C2 2.36377 2.36377 2 2.8125 2H16.0701C16.2817 2 16.4848 2.08249 16.6365 2.22994L19.7539 5.26071C19.9112 5.41369 20 5.62381 20 5.84327V10H18V6.34497L15.588 4Z"
          fill="currentColor"
        />
        <path d="M15 7H19L15 3V7Z" fill="currentColor" />
        <path d="M5 5H14V7H5V5Z" fill="currentColor" />
        <path d="M5 8H8V10H5V8Z" fill="currentColor" />
        <path d="M9 8H16V10H9V8Z" fill="currentColor" />
      </svg>
    </SvgIcon>
  );
}
