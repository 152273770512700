import { ExecutionStage } from 'client/app/apps/execution-details/types';
import { trackHeapEvent } from 'common/ui/useHeapTracking';

export const EXECUTION_DETAILS_EVENTS = {
  PLATE_SETUP_OPENED: 'execution-details-plate-setup-opened',
  STAGE_PREVIEW_OPENED: 'execution-details-stage-preview-opened',
  RESOURCES_LABWARE_EXPANDED: 'execution-details-resources-labware-expanded',
  RESOURCES_REAGENTS_EXPANDED: 'execution-details-resources-reagents-expanded',

  PREVIEW_PLATE: 'execution-details-preview-plate',
  PREVIEW_LIQUIDS: 'execution-details-preview-liquids',
  PRINT_PLATES: 'execution-details-print-plates',
  CHECK_HELP_ARTICLE: 'execution-details-check-help-article',
} as const;

type EventData = {
  [EXECUTION_DETAILS_EVENTS.PLATE_SETUP_OPENED]: ExecutionStageData;
  [EXECUTION_DETAILS_EVENTS.STAGE_PREVIEW_OPENED]: ExecutionStageData;
  [EXECUTION_DETAILS_EVENTS.RESOURCES_LABWARE_EXPANDED]: ResourcesData;
  [EXECUTION_DETAILS_EVENTS.RESOURCES_REAGENTS_EXPANDED]: ResourcesData;

  [EXECUTION_DETAILS_EVENTS.PREVIEW_PLATE]: ExecutionStageData;
  [EXECUTION_DETAILS_EVENTS.PREVIEW_LIQUIDS]: ExecutionStageData;
  [EXECUTION_DETAILS_EVENTS.PRINT_PLATES]: ExecutionStageData;
  [EXECUTION_DETAILS_EVENTS.CHECK_HELP_ARTICLE]: ExecutionStageData;
};

type MetricEvent =
  (typeof EXECUTION_DETAILS_EVENTS)[keyof typeof EXECUTION_DETAILS_EVENTS];

type ExecutionStageData = {
  simulationId: string;
  stageIndex: number;
  main_device_model: string;
};

type ResourcesData = {
  device_models_comma_separated: string;
};

function trackExecutionDetailsStageEvent<TEvent extends MetricEvent>(
  event: TEvent,
  data: EventData[TEvent],
) {
  trackHeapEvent(event, data);
}

function trackStageEvent(event: MetricEvent, executionStage: ExecutionStage) {
  trackExecutionDetailsStageEvent(event, {
    simulationId: executionStage.simulationStage.simulationId,
    stageIndex: executionStage.simulationStage.orderNum + 1,
    main_device_model:
      executionStage.deviceExecutionMode.mainDevice.model.anthaLangDeviceClass,
  });
}

export function trackPlateSetupOpened(executionStage: ExecutionStage) {
  trackStageEvent(EXECUTION_DETAILS_EVENTS.PLATE_SETUP_OPENED, executionStage);
}

export function trackStagePreviewOpened(executionStage: ExecutionStage) {
  trackStageEvent(EXECUTION_DETAILS_EVENTS.STAGE_PREVIEW_OPENED, executionStage);
}

export function trackPreviewPlate(executionStage: ExecutionStage) {
  trackStageEvent(EXECUTION_DETAILS_EVENTS.PREVIEW_PLATE, executionStage);
}

export function trackPreviewLiquids(executionStage: ExecutionStage) {
  trackStageEvent(EXECUTION_DETAILS_EVENTS.PREVIEW_LIQUIDS, executionStage);
}

export function trackPrintPlates(executionStage: ExecutionStage) {
  trackStageEvent(EXECUTION_DETAILS_EVENTS.PRINT_PLATES, executionStage);
}

export function trackCheckHelpArticle(executionStage: ExecutionStage) {
  trackStageEvent(EXECUTION_DETAILS_EVENTS.CHECK_HELP_ARTICLE, executionStage);
}

export function trackResourcesExpanded(
  event:
    | typeof EXECUTION_DETAILS_EVENTS.RESOURCES_LABWARE_EXPANDED
    | typeof EXECUTION_DETAILS_EVENTS.RESOURCES_REAGENTS_EXPANDED,
  executionStages: ExecutionStage[],
) {
  trackExecutionDetailsStageEvent(event, {
    device_models_comma_separated: executionStages
      .map(stage => stage.deviceExecutionMode.mainDevice.model.anthaLangDeviceClass)
      .join(','),
  });
}
