import React from 'react';

import { Bounds } from 'common/types/Position';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type DragRectProps = {
  bounds: Bounds;
  wellDiameter: number;
};

/**
 * An SVG rectangle for highlighting the dragged area of the WellSelector.
 */
export default function WellSelectorDragRect({ bounds, wellDiameter }: DragRectProps) {
  const { x1, y1, x2, y2 } = bounds;
  const classes = useStyles();
  const borderRadius = (1.16 * wellDiameter) / 2;
  return (
    <rect
      className={classes.selectRect}
      x={Math.min(x1, x2)}
      y={Math.min(y1, y2)}
      rx={borderRadius}
      ry={borderRadius}
      width={Math.abs(x1 - x2)}
      height={Math.abs(y1 - y2)}
    />
  );
}

const useStyles = makeStylesHook({
  selectRect: {
    fill: Colors.PRIMARY_MAIN,
    opacity: 0.3,
    // The selection rectangle is above the wells, which normally would prevent
    // wells from receiving pointer events. Setting 'pointer-events: none'
    // prevents this behaviour.
    pointerEvents: 'none',
  },
});
