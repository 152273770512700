import React, { useMemo } from 'react';

import { TipType } from 'common/types/device';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import Dropdown from 'common/ui/filaments/Dropdown';

type Props = {
  value: string;
  onChange: (value?: string) => void;
  supportedTipTypes?: TipType[];
} & ParameterEditorBaseProps<string>;

const TipTypeDropdownEditor = function (props: Props) {
  const { supportedTipTypes, value } = props;

  const options = useMemo(() => {
    return (
      supportedTipTypes?.map(tt => ({ value: tt.name, label: tt.shortDescription })) ?? []
    );
  }, [supportedTipTypes]);

  const labelForValue = useMemo(() => {
    return supportedTipTypes?.find(tt => tt.name === value)?.shortDescription ?? '';
  }, [supportedTipTypes, value]);

  return (
    <Dropdown
      placeholder={props.placeholder}
      valueLabel={labelForValue}
      options={options}
      onChange={props.onChange}
      isDisabled={props.isDisabled}
      isRequired={props.isRequired}
    />
  );
};

export default TipTypeDropdownEditor;
