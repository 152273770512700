import React, { useCallback, useMemo, useRef, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import cx from 'classnames';

import {
  useCreateFromFile,
  useCreatePlateType,
  usePlatesByType,
  useUpdatePlateType,
} from 'client/app/api/PlateTypesApi';
import PlateForm, { PlateFormAction } from 'client/app/apps/plate-constructor/PlateForm';
import { PlateFormFields } from 'client/app/apps/plate-constructor/PlateFormFields';
import PlateVisualization from 'client/app/apps/plate-constructor/PlateVisualization';
import WellVisualization from 'client/app/apps/plate-constructor/WellVisualization';
import { ScreenRegistry } from 'client/app/registry';
import { generateSimple9wellTemplate } from 'common/lib/defaultPlate';
import getFileBytes from 'common/lib/getFileBytes';
import stopPropagation from 'common/lib/stopPropagation';
import { FormPlateType, PlateType } from 'common/types/plateType';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSavePlate: (plateName: string) => void;
  selectedPlateId?: string;
  action: PlateFormAction;
};

export default function PlateConstructorDialog(props: Props) {
  const [plates, isLoading] = usePlatesByType();
  const plateTypes = useMemo(
    () => new Set(Object.values(plates).map(plate => plate.type)),
    [plates],
  );

  const [plate, setPlate] = useState<FormPlateType>(() => {
    if (props.selectedPlateId) {
      const existingPlate = Object.values(plates).find(
        plate => plate.id === props.selectedPlateId,
      );
      return existingPlate ? existingPlate : generateSimple9wellTemplate();
    }
    return generateSimple9wellTemplate();
  });
  const [selectedField, setSelectedField] = useState<PlateFormFields | undefined>();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingFromFile, setIsLoadingFromFile] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const createPlateType = useCreatePlateType();
  const updatePlateType = useUpdatePlateType();

  const classes = useStyles();

  const { onClose } = props;

  const savePlate = useCallback(async () => {
    setIsSaving(true);

    try {
      // It is safe to cast FormPlateType as PlateType once it went through
      // form validation
      if (props.action === PlateFormAction.CREATE) {
        logEvent('create-plate', ScreenRegistry.PLATE_TYPE_LIBRARY);
        await createPlateType(plate as PlateType);
      } else if (props.action === PlateFormAction.UPDATE) {
        logEvent('update-plate', ScreenRegistry.PLATE_TYPE_LIBRARY);
        await updatePlateType(plate as PlateType);
      } else {
        throw new Error('Unknown plate type constructor form action');
      }

      setIsSaving(false);
      props.onSavePlate(plate.name);
      onClose();
    } catch (error) {
      setError(`Failed to save: ${error.message}`);
      setIsSaving(false);
    }
  }, [props, plate, onClose, createPlateType, updatePlateType]);

  const onValidationUpdate = (hasErrors: boolean) => setIsSaveDisabled(hasErrors);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const createFromFile = useCreateFromFile();
  const onCreateFromFile = useCallback(() => {
    logEvent('open_plate_from_file', ScreenRegistry.PLATE_TYPE_LIBRARY);
    (async () => {
      if (!fileInputRef.current?.files) {
        return;
      }
      const file = fileInputRef.current.files[0];
      // we need to reset it, so if user select directly the same file, the onChange will still be triggered
      fileInputRef.current.value = '';
      setIsLoadingFromFile(true);
      const content = await getFileBytes(file);

      const uploadedFile = {
        filename: file.name,
        content,
      };

      try {
        const plate = await createFromFile(uploadedFile);
        setPlate(plate);
      } catch (e) {
        console.error(e);
        // The error returned by anthaFetch() is not very user-friendly. Will look into updating
        // this to use it once it's revamped.
        setError('Failed to import your file. Please check your network connection.');
      } finally {
        setIsLoadingFromFile(false);
      }
    })();
  }, [createFromFile]);

  const showFileInput = () => {
    fileInputRef.current?.click();
  };

  // Title changes according to the action being performed
  const title =
    props.action === PlateFormAction.CREATE
      ? 'Add a new plate type'
      : `Edit "${plate.type}"`;

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      fullWidth
      maxWidth="md"
      scroll="paper"
      onWheel={stopPropagation}
    >
      <DialogTitle component="div" className={classes.title}>
        <h2>{title}</h2>
        <Button variant="secondary" onClick={showFileInput}>
          Import from file
        </Button>
        <input
          id="uploadPlateFile"
          type="file"
          accept=".lhr"
          ref={fileInputRef}
          className={classes.hidden}
          onChange={onCreateFromFile}
        />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={cx([classes.column, classes.form])}>
          <PlateForm
            plate={plate}
            setPlate={setPlate}
            setSelectedField={setSelectedField}
            plateTypes={plateTypes}
            disabled={isLoading}
            onValidationUpdate={onValidationUpdate}
            action={props.action}
          />
        </div>
        <div className={cx([classes.column, classes.visualizations])}>
          <div className={classes.plate}>
            <PlateVisualization plate={plate} selectedField={selectedField} />
          </div>
          <div className={classes.wells}>
            <div className={classes.well}>
              <WellVisualization plate={plate} selectedField={selectedField} />
            </div>
            <div className={classes.well}>
              <WellVisualization
                plate={plate}
                selectedField={selectedField === 'wellY' ? selectedField : undefined}
                direction="y"
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <span className={classes.error}>{error}</span>
        {!isSaving && (
          <>
            <Button variant="tertiary" onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              variant="tertiary"
              color="primary"
              disabled={isSaveDisabled || isLoadingFromFile}
              onClick={savePlate}
            >
              Save
            </Button>
          </>
        )}
        {isSaving && (
          <>
            Your plate type is being saved...
            <CircularProgress className={classes.progress} />
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStylesHook({
  plate: { height: '60%' },
  wells: {
    height: '40%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '20px',
  },
  well: {
    display: 'inline',
    paddingLeft: '10px',
    flex: 1,
    paddingTop: '30px', // Leave room at the top for the dimension annotations.
  },
  visualizations: {
    flex: 1,
    padding: '0 6ch', // Leave room for the annotations when adjusting well characteristics.
  },
  form: {
    flex: 2,
    paddingRight: '20px', // Leave room for the scrollbar.
  },
  column: {
    flexDirection: 'column',
    overflowY: 'scroll',
  },
  content: {
    display: 'flex',
    borderBottom: '1px solid #eee',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #eee',
  },
  progress: {
    width: '20px',
    height: '20px',
    marginLeft: '8px',
  },
  hidden: {
    display: 'none',
  },
  error: {
    color: Colors.ERROR,
    fontSize: '0.9rem',
    marginLeft: '16px',
    flex: 1,
  },
});
