import React, { useCallback } from 'react';

import ExperimentsList from 'client/app/apps/experiments/ExperimentsList';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

/**
 * Render the Experiments list in a dialog, allowing users to click on one.
 * Returns the ExperimentId of experiment selected.
 */
export default function ExperimentsDialog(
  props: DialogProps<ExperimentId | typeof CANCEL_CHOICE> & {
    disabledExperimentIds?: ExperimentId[];
    visibleExperimentIds?: ExperimentId[];
    highlightedExperiments?: ExperimentId[];
  },
) {
  const {
    isOpen,
    onClose,
    disabledExperimentIds,
    visibleExperimentIds,
    highlightedExperiments,
  } = props;

  const classes = useStyles();

  const handleSelectExperiment = useCallback(
    (experimentId: ExperimentId) => onClose(experimentId),
    [onClose],
  );

  const onCancel = useCallback(() => onClose(CANCEL_CHOICE), [onClose]);

  return (
    <ComplexActionDialog
      title="Choose an Experiment"
      onClose={onCancel}
      isOpen={isOpen}
      showCloseButton
      fullHeight
      content={
        <div className={classes.dialogContent}>
          <ExperimentsList
            onExperimentListItemClick={handleSelectExperiment}
            disabledExperimentIds={disabledExperimentIds}
            visibleExperimentIds={visibleExperimentIds}
            highlightedExperiments={highlightedExperiments}
            variant="noEntities"
            dialog
          />
        </div>
      }
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: 0,
    flexGrow: 1,
    padding: theme.spacing(5),
  },
}));
