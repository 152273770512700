import React, { useState } from 'react';

import isWorkflowReadonly from 'client/app/apps/workflow-builder/lib/isWorkflowReadonly';
import { LayoutOptions } from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import DeckPositionWithPreferences from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/DeckPositionWithPreferences';
import { ScreenRegistry } from 'client/app/registry';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { DeckState } from 'common/types/mix';
import Carrier, { isCarrier } from 'common/ui/components/simulation-details/mix/Carrier';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import MixGrid from 'common/ui/components/simulation-details/mix/MixGrid';
import Workspace from 'common/ui/components/Workspace/Workspace';

type DeckLayoutWithPreferencesProps = {
  deckState: DeckState;
  validLayoutOptions: LayoutOptions;
};

export default React.memo(function DeckLayoutWithPreferences(
  props: DeckLayoutWithPreferencesProps,
) {
  const originalLayout = props.deckState;
  const deckLayout = new DeckLayout({
    before: originalLayout,
    after: originalLayout,
    version: '',
  });

  const isReadonly = useWorkflowBuilderSelector(state =>
    isWorkflowReadonly(state.editMode, state.source),
  );

  const [gridVisible, setGridVisible] = useState(true);
  return (
    <Workspace
      isGridSwitchVisible
      gridVisible={gridVisible}
      setGridVisible={setGridVisible}
      isShowAllButtonVisible
      isShowHelpButtonVisible
      canvasControlVariant="light_float"
      initialShowAll
      logCategory={ScreenRegistry.WORKFLOW}
    >
      <div style={deckLayout.deckBounds}>
        <MixGrid deckLayout={deckLayout} gridVisible={gridVisible} />
        {deckLayout.getAllDeckPositions().map(deckPosition => {
          if (isCarrier(deckPosition)) {
            return (
              <Carrier key={deckPosition.deckPositionName} deckPosition={deckPosition} />
            );
          }
          return (
            <DeckPositionWithPreferences
              key={deckPosition.deckPositionName}
              deckPosition={deckPosition}
              validLayoutOptions={props.validLayoutOptions}
              isReadonly={isReadonly}
            />
          );
        })}
      </div>
    </Workspace>
  );
});
