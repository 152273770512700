import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import toWellLocationsOnDeckItem from 'client/app/components/Parameters/WellSelector/toWellLocationsOnDeckItem';
import WellSelectorDragRect from 'client/app/components/WellSelector/WellSelectorDragRect';
import { formatWellPosition } from 'common/lib/format';
import {
  Plate,
  WellContents,
  WellLocation,
  WellLocationOnDeckItem,
} from 'common/types/mix';
import { Bounds, Position2d } from 'common/types/Position';
import PlateLayout, {
  Props as PlateLayoutProps,
} from 'common/ui/components/PlateLayout/PlateLayout';
import computeSelectedWells from 'common/ui/components/simulation-details/mix/computeSelectedWells';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import { selectLine } from 'common/ui/components/simulation-details/mix/selectLine';
import { WellHighlightMode } from 'common/ui/components/simulation-details/mix/Well';
import { WellLabelContent } from 'common/ui/components/simulation-details/mix/WellLabel';
import { WellTooltipTitleProps } from 'common/ui/components/simulation-details/mix/WellTooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useThrottle from 'common/ui/hooks/useThrottle';
import { Point2D } from 'common/ui/lib/ClickRecognizer';
import { getDistance } from 'common/ui/lib/position';

/**
 * Distance (in pixels) that the pointer must be moved before drag selection
 * occurs. If this is too low, then tapping on a well using a touch pad (where
 * the pointer may move a few pixel) might activate drag selection.
 */
const DRAG_THRESHOLD = 10;

type SetSelectedWellsCallback = (
  newSelectedWells: readonly WellLocationOnDeckItem[],
) => void;
export type WellSelectionProps = {
  selectedWells: readonly WellLocationOnDeckItem[];
  onSelectWells: SetSelectedWellsCallback;
  disabledWells?: readonly WellLocationOnDeckItem[];
};
type Props = {
  // In some places we want users to be able to select wells (e.g. in the
  // WellSelectorDialog). In other places, we don't,  as there is no action
  // associated with well selection. (e.g. in the Cherry Picker)
  // Thus, we pass this object only when the parents keeps a state of
  // the selected wells.
  wellSelectionProps?: WellSelectionProps;
  deckLayout: DeckLayout;
  // One of our "apps". Find a list of available ones in registry.ts
  googleAnalyticsCategory: string;
  getContentLabel?: (
    well: string,
    wellContents: WellContents | undefined,
    location: { row: number; col: number },
  ) => WellLabelContent;
  TooltipTitle?: (props: WellTooltipTitleProps) => JSX.Element;
  hasError?: boolean;
  isDisabled?: boolean;
  highlightMode?: WellHighlightMode;
  focusedWells?: readonly WellLocationOnDeckItem[];
} & Pick<
  PlateLayoutProps,
  'showContentLabels' | 'plate' | 'liquidColors' | 'onWellMouseEnter' | 'onWellMouseLeave'
>;

export default React.memo(function WellSelector({
  wellSelectionProps,
  plate,
  liquidColors,
  deckLayout,
  googleAnalyticsCategory,
  showContentLabels,
  getContentLabel,
  TooltipTitle,
  hasError,
  isDisabled,
  highlightMode = WellHighlightMode.SELECTION,
  onWellMouseEnter,
  onWellMouseLeave,
  focusedWells,
}: Props) {
  const classes = useStyles();

  // Determines whether dragging will select or unselect the wells. `undefined`
  // means it's unknown whether dragging should be selecting or deselecting,
  // which happens when the user started dragging outside a well. In this case
  // we wait until the drag box reaches the first well before determining
  // select/unselect mode.
  const [shouldDragUnselect, setShouldDragUnselect] = useState<boolean | undefined>();

  const geometry = useMemo(
    () => deckLayout.getCurrentGeometry(plate),
    [deckLayout, plate],
  );

  const { width: plateWidth, height: plateHeight } = geometry.getDimensions();

  const handleUpdateSelectedWells = useCallback(
    (newSelectedWells: readonly WellLocationOnDeckItem[]) => {
      if (!wellSelectionProps) {
        return;
      }

      wellSelectionProps.onSelectWells(newSelectedWells);
    },
    [wellSelectionProps],
  );

  const handlePlatePointerDown = useCallback(() => {
    // Clicking and dragging on the plate should always add to the selection
    setShouldDragUnselect(false);
  }, []);

  const handleWellPointerDown = useCallback(
    (clickedLoc: WellLocationOnDeckItem, e: React.PointerEvent) => {
      const { newSelectedWells, alreadySelected } = computeSelectedWells(
        wellSelectionProps?.selectedWells ?? [],
        clickedLoc,
        e,
        googleAnalyticsCategory,
        true,
      );
      setShouldDragUnselect(alreadySelected);
      handleUpdateSelectedWells(newSelectedWells);
    },
    [googleAnalyticsCategory, handleUpdateSelectedWells, wellSelectionProps],
  );

  const handleSelectCol = useCallback(
    (colIndex: number) => {
      const newSelectedWells = selectLine(
        wellSelectionProps?.selectedWells ?? [],
        colIndex,
        'col',
        geometry,
        plate.id,
      );
      handleUpdateSelectedWells(newSelectedWells);
    },
    [geometry, handleUpdateSelectedWells, plate.id, wellSelectionProps],
  );

  const handleSelectRow = useCallback(
    (rowIndex: number) => {
      const newSelectedWells = selectLine(
        wellSelectionProps?.selectedWells ?? [],
        rowIndex,
        'row',
        geometry,
        plate.id,
      );
      handleUpdateSelectedWells(newSelectedWells);
    },
    [geometry, handleUpdateSelectedWells, plate.id, wellSelectionProps],
  );

  // Start point of the dragged rectangle (undefined if not dragging)
  const [dragStart, setDragStart] = useState<Position2d | undefined>();
  // End point of the dragged rectangle (undefined if not dragged beyond
  // threshold)
  const [dragEnd, setDragEnd] = useState<Position2d | undefined>();

  // We need to keep a record of what the selection was before the user started
  // dragging. Each time the user moves their mouse, we reset the selection to
  // how it was before and add the new dragged box of wells. Using a set of
  // serialised well positions (A1, A2, ... etc) lets us efficiently manage the
  // selection while the user is dragging.
  const [dragInitSelection, setDragInitSelection] = useState<Set<string> | undefined>();

  const dragBounds = useMemo<Bounds | undefined>(() => {
    return dragStart && dragEnd
      ? { x1: dragStart.x, y1: dragStart.y, x2: dragEnd.x, y2: dragEnd.y }
      : undefined;
  }, [dragEnd, dragStart]);

  const plateRectRef = useRef<SVGRectElement | null>(null);

  const handleDragStart = useCallback(
    (e: React.PointerEvent<SVGSVGElement>) => {
      // Only handle primary mouse button
      if (!plateRectRef.current || e.buttons !== 1) {
        return;
      }
      const rectBounds = plateRectRef.current.getBoundingClientRect();
      // We attach the handler to the svg to detect pointer events in the svg, but we
      // don't want to start dragging if the pointer event is recorded outside the bounds of the rect
      // (i.e. outside of the plate).
      if (
        e.pageX < rectBounds.left ||
        e.pageX > rectBounds.right ||
        e.pageY < rectBounds.top ||
        e.pageY > rectBounds.bottom
      ) {
        return;
      }
      setDragStart(
        getRelativePointerPosition(plateRectRef.current, plateHeight, plateWidth, e),
      );
    },
    [plateHeight, plateWidth],
  );

  // On mouse up (handled by window pointer up), unset drag bounds
  const handleDragEnd = useCallback(() => {
    if (dragBounds) {
      logEvent(`drag-select-well`, googleAnalyticsCategory);
    }
    setDragStart(undefined);
    setDragEnd(undefined);
    setDragInitSelection(undefined);
    setShouldDragUnselect(undefined);
  }, [dragBounds, googleAnalyticsCategory]);

  // When dragging, update the selection bounds
  const handleDrag = useCallback(
    (e: PointerEvent) => {
      if (!dragStart || !plateRectRef.current) {
        return;
      }

      if (e.buttons !== 1) {
        // If the user starts dragging, drags outside the bounds of the window,
        // then releases, the pointerup may not always fire (e.g. Chrome on
        // OSX). This means the user will still be in drag mode when they move
        // their mouse back to antha. So this just checks if the mouse has been
        // released and, if it has, stops dragging.
        handleDragEnd();
        return;
      }

      const mousePos = getRelativePointerPosition(
        plateRectRef.current,
        plateHeight,
        plateWidth,
        e,
      );

      const pastThreshold =
        dragStart && getDistance(dragStart, mousePos) > DRAG_THRESHOLD;

      // Don't activate drag until the pointer has moved beyond the drag
      // threshold. We also check if dragEnd is set, which would indicate
      // threshold was already passed on this drag. For example, the user starts
      // dragging, moves pointer beyond threshold (at which point dragEnd will
      // be set), then back within the threshold, at which point we should still
      // render the dragged area.
      if (!pastThreshold && !dragEnd) {
        return;
      }

      // Update bounds to reflect current mouse position
      setDragEnd(mousePos);

      setDragInitSelection((dragInitSelection?: Set<string>) => {
        if (!dragInitSelection) {
          const selection = wellSelectionProps?.selectedWells ?? [];
          // Serialize existing selection (A1, A2, ... etc).
          const positions = selection.map(formatWellPosition);
          // Keep track of the selection before dragging was started
          return new Set(positions);
        } else {
          return dragInitSelection;
        }
      });
    },
    [
      dragEnd,
      dragStart,
      handleDragEnd,
      plateHeight,
      plateWidth,
      wellSelectionProps?.selectedWells,
    ],
  );

  // Function to update the list of selected wells within the dragged bounds.
  const updateDragSelection = useCallback(() => {
    if (!dragBounds || !dragInitSelection) {
      return;
    }

    // Get all wells within the dragged bounds
    const wells = geometry.getWellsInBounds(dragBounds);

    // Create a copy of the selection before dragging started
    const selection = new Set(dragInitSelection);

    // If it's unknown if the dragged box should be selecting or deselecting,
    // then figure it out based on whether the first well within the box is
    // selected.
    if (shouldDragUnselect === undefined && wells[0]) {
      const firstWell = formatWellPosition(wells[0]);
      setShouldDragUnselect(selection.has(firstWell));
      return;
    }

    let newSelectedWells: readonly WellLocationOnDeckItem[];

    if (shouldDragUnselect) {
      newSelectedWells = removeWellsWithinBounds(wells, selection, plate);
    } else {
      newSelectedWells = addWellsWithinBounds(wells, selection, plate);
    }

    handleUpdateSelectedWells(newSelectedWells);
  }, [
    dragBounds,
    dragInitSelection,
    geometry,
    handleUpdateSelectedWells,
    plate,
    shouldDragUnselect,
  ]);

  // Update the selection each time the dragged area changes. This is throttled
  // to prevent it being called every time the mouse moves (this would cause
  // choppy movement).
  const updateDragSelectionThrottled = useThrottle(updateDragSelection, 100);
  useEffect(
    () => updateDragSelectionThrottled(),
    [dragBounds, updateDragSelectionThrottled],
  );

  useEffect(() => {
    window.addEventListener('pointerup', handleDragEnd);
    window.addEventListener('blur', handleDragEnd);
    window.addEventListener('pointermove', handleDrag);
    // Clean up when component unmounts
    return () => {
      window.removeEventListener('pointerup', handleDragEnd);
      window.removeEventListener('blur', handleDragEnd);
      window.removeEventListener('pointermove', handleDrag);
    };
  }, [handleDragEnd, handleDrag]);

  const plateDivStyle: CSSProperties = plate.color
    ? { backgroundColor: plate.color }
    : {};

  return (
    <div style={plateDivStyle} className={classes.wellSelector}>
      <svg
        className={classes.plateContainer}
        onPointerDown={isDisabled ? undefined : handleDragStart}
        viewBox={`0 0 ${plateWidth} ${plateHeight}`}
      >
        <PlateLayout
          geometry={geometry}
          plate={plate}
          highlightedWells={wellSelectionProps?.selectedWells}
          disabledWells={wellSelectionProps?.disabledWells}
          disableAllWells={isDisabled}
          hasError={hasError}
          liquidColors={liquidColors}
          isInteractive={!!wellSelectionProps && !isDisabled}
          onWellPointerDown={handleWellPointerDown}
          onColHeaderClick={handleSelectCol}
          onRowHeaderClick={handleSelectRow}
          getContentLabel={getContentLabel}
          showContentLabels={showContentLabels}
          onPlatePointerDown={handlePlatePointerDown}
          TooltipTitle={TooltipTitle}
          plateRectRef={plateRectRef}
          highlightMode={highlightMode}
          onWellMouseEnter={onWellMouseEnter}
          onWellMouseLeave={onWellMouseLeave}
          focusedWells={focusedWells}
        />
        {dragBounds && (
          <WellSelectorDragRect
            bounds={dragBounds}
            wellDiameter={Math.min(
              plate.well_dimensions.x_mm,
              plate.well_dimensions.y_mm,
            )}
          />
        )}
      </svg>
    </div>
  );
});

//#region Helpers

/**
 * Get the mouse coords relative to a given plateRectTarget accounting
 * for the relative size of this element based on the plate height and width.
 */
function getRelativePointerPosition(
  plateRectTarget: Element,
  plateHeight: number,
  plateWidth: number,
  e: PointerEvent | React.PointerEvent,
): Point2D {
  // Get the absolute position of the plate rect on the page
  const rectBounds = plateRectTarget.getBoundingClientRect();
  // The rect might be scaled down within the svg, so we need to adjust by the absolute height
  // and width of the plate.
  const heightRatio = plateHeight / rectBounds.height;
  const widthRatio = plateWidth / rectBounds.width;
  return {
    x: (e.pageX - rectBounds.x) * widthRatio,
    y: (e.pageY - rectBounds.y) * heightRatio,
  };
}

function addWellsWithinBounds(
  wells: WellLocation[],
  initSelection: Set<string>,
  plate: Plate,
) {
  const selection = new Set(initSelection);

  for (const well of wells) {
    const wellPosition = formatWellPosition(well);
    /**
     * When adding a range of wells there might be some wells selected before, which are in this range.
     * We want to ignore those wells and select this range as a new "clean" range.
     */
    if (selection.has(wellPosition)) {
      selection.delete(wellPosition);
    }
    selection.add(wellPosition);
  }

  return toWellLocationsOnDeckItem(Array.from(selection), plate);
}

function removeWellsWithinBounds(
  wells: WellLocation[],
  initSelection: Set<string>,
  plate: Plate,
) {
  const selection = new Set(initSelection);

  for (const well of wells) {
    const wellPosition = formatWellPosition(well);
    selection.delete(wellPosition);
  }

  return toWellLocationsOnDeckItem(Array.from(selection), plate);
}

//#endregion

const useStyles = makeStylesHook({
  plateContainer: {
    width: '100%',
    height: '100%',
  },
  wellSelector: {
    backgroundColor: 'white',
    // prevent selecting column/row headers when making a drag selection
    userSelect: 'none',
    position: 'relative',
    height: '100%',
  },
});
