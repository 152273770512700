import getSegmentsFromPath from 'client/app/lib/file-browser/getSegmentsFromPath';
import {
  FileBrowserFileSelection,
  FileBrowserFileSingleSelection,
  FileBrowserValueTree,
} from 'client/app/lib/file-browser/types';

// This helper function converts a FileBrowserFileSelection (one or more data repo
// file path strings) into a FileBrowserValueTree (the same information in a
// trie-like structure.
//
// For an input file selection that looks like this:
//   [
//     '/device/XXXX/foo.txt',
//     '/device/XXXX/bar.txt',
//     '/device/YYYY/baz.txt',
//   ]
//
// An output tree would look like:
//
// {
//   'device': {
//     'XXXX': {
//       'foo.txt': true,
//       'bar.txt': true,
//     },
//     'YYYY': {
//       'baz.txt': true,
//     }
//   }
// }
export default function convertFileSelectionToTree(
  value: FileBrowserFileSelection,
): FileBrowserValueTree {
  const t: FileBrowserValueTree = {};
  if (value === null) {
    return t;
  }

  const v: FileBrowserFileSingleSelection[] = Array.isArray(value) ? value : [value];
  for (let ii = 0; ii < v.length; ii++) {
    const path = v[ii];
    const segments = getSegmentsFromPath(path.localPath);
    let node = t;
    for (let jj = 0; jj < segments.length; jj++) {
      const segment = segments[jj];
      const segmentIsDirectory = segment.endsWith('/');
      if (!node[segment] && segmentIsDirectory) {
        node[segment] = {};
      } else if (!segmentIsDirectory) {
        node[segment] = path.pathWithScheme; // path value meaning this segment refers to a file
      }

      // Until we get to the last segment, recurse into the next level
      if (typeof node[segment] !== 'string') {
        node = node[segment] as FileBrowserValueTree;
      }
    }
  }

  return t;
}
