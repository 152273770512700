import React, { Fragment } from 'react';

import sanitizeLink from 'client/app/lib/sanitizeLink';

/**
 * This is a 'good enough' regex for URLs.
 */
const URL_REGEXP = /(https?:\/\/[^\s]+)/g;

type TextWithLinksProps = { text: string };

/**
 * Converts all URLs in a given piece of text to hyperlinks
 * Note: This will likely be replaced by a markdown viewer
 */
export function TextWithLinks({ text }: TextWithLinksProps) {
  const matches = [...text.matchAll(URL_REGEXP)].map((match, i, arr) => {
    // Although TS marks `index` as optional, it will never be undefined
    // https://github.com/microsoft/TypeScript/issues/36788#issuecomment-633178914
    const index = match.index;
    const url = match[0];
    // Substring between the end of this URL and the start of the next. If there is no
    // next URL, then this is the substring to the end of the text.
    const textAfter = text.slice(index + url.length, arr[i + 1]?.index);
    return { index, url, textAfter };
  });

  // Get the substring preceding the first URL. If there are no matches, then the second
  // argument to slice is undefined, which means the entire text will be returned.
  const startText = text.slice(0, matches[0]?.index);
  return (
    <>
      {startText}
      {matches.map(({ index, url, textAfter }) => (
        <Fragment key={index}>
          <a href={sanitizeLink(url)} target="_blank" rel="noreferrer">
            {url}
          </a>
          {textAfter}
        </Fragment>
      ))}
    </>
  );
}
