import React, { useMemo } from 'react';

import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import { SvgIconProps } from '@mui/material/SvgIcon';
import cx from 'classnames';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type IconButtonProps = {
  /**
   * `component` is part of the MUI IconButton API. However,
   * in MUI version < v5, it throws an error. See:
   * https://github.com/mui-org/material-ui/issues/22452#issuecomment-685756045
   * TODO: Remove this prop when updating MUI to v5 (T4261)
   *
   * component="span" or "label" is needed when using the Button to open
   * the file selection of an <input type="file" />
   *
   * For enabling a Tooltip on a disabled IconButton, you should wrap the IconButton in
   * a <span /> or another component that receives pointer events, as per the MUI docs.
   * Adding component="span" will not work.
   * https://material-ui.com/components/tooltips/#disabled-elements
   */
  component?: 'span' | 'label';
  /** Please use a SVGIcon here. TODO: Add proper typing for this. */
  icon: React.ReactElement;
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  className?: string;
} & Omit<MuiIconButtonProps, 'size' | 'children'>;

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const { size, icon, className, ...muiProps } = props;

  const classes = useStyles();

  // We want a different icon size depending on what the overall size of the IconButton.
  const iconSize = useMemo(() => {
    switch (size) {
      case 'xsmall':
        return 'small';
      case 'large':
        return 'large';
      case 'small':
      case 'medium':
      default:
        return 'medium';
    }
  }, [size]);

  const styledIcon = React.cloneElement<SvgIconProps>(icon, {
    fontSize: iconSize,
  });

  return (
    <MuiIconButton
      id={props.id} // Needed for Intercom button
      className={cx(classes[size ?? 'medium'], className)}
      ref={ref}
      {...muiProps}
    >
      {styledIcon}
    </MuiIconButton>
  );
});

const useStyles = makeStylesHook({
  xsmall: {
    height: '24px',
    width: '24px',
    padding: '2px',
    '&:hover': {
      padding: '2px',
    },
  },
  small: {
    height: '32px',
    width: '32px',
    padding: '4px',
    '&:hover': {
      padding: '4px',
    },
  },
  medium: {
    height: '48px',
    width: '48px',
    padding: '12px',
    '&:hover': {
      padding: '12px',
    },
  },
  large: {
    height: '60px',
    width: '60px',
    padding: '12px',
    '&:hover': {
      padding: '12px',
    },
  },
});

export default IconButton;
