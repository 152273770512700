import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';

import { MUTATION_UPDATE_USER_ROLE } from 'client/app/api/gql/mutations';
import { ArrayElement, availableRolesQuery, userRolesQuery } from 'client/app/gql';
import Colors from 'common/ui/Colors';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { UserRole } from 'common/ui/components/UserRole';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { UserInitialsIcon } from 'common/ui/icons/UserInitialsIcon';
import { theme } from 'common/ui/theme';

const defaultErrorMessage = 'Role update failed, please try again';
const roleIdErrorMessage =
  'No Role ID provided, role update failed. Please contact Matrix Team';
const successMessage = 'Successfully update role';

type UserRole = ArrayElement<userRolesQuery['users']>;
type AvailableRole = ArrayElement<availableRolesQuery['roles']>;

type UserRowProps = {
  user: UserRole;
  isEditable?: boolean;
  availableRoles?: readonly AvailableRole[] | undefined;
};

export const UserRow = React.memo(
  ({ user, isEditable = false, availableRoles }: UserRowProps) => {
    const classes = useStyles();

    const snackbar = useSnackbarManager();
    const [updateRoleMutation] = useMutation(MUTATION_UPDATE_USER_ROLE, {
      onCompleted: _ => {
        snackbar.showSuccess(successMessage);
      },
      onError: _ => {
        snackbar.showError(defaultErrorMessage);
      },
    });

    const onRoleChange = useCallback(
      async (roleId: string | null) => {
        if (!roleId) {
          snackbar.showSuccess(roleIdErrorMessage);
        } else {
          await updateRoleMutation({
            variables: { roleUpdate: { roleId, userId: user.id } },
          });
        }
      },
      [snackbar, updateRoleMutation, user.id],
    );

    return (
      <div className={classes.container}>
        <UserInitialsIcon userDisplayName={user.displayName} />
        <div className={classes.userDetails}>
          <Typography variant="overline">{user.displayName}</Typography>
          <br />
          <Typography variant="overline" style={{ color: Colors.GREY_50, fontSize: 12 }}>
            {user.email}
          </Typography>
        </div>
        <UserRole
          currentRole={user.role}
          availableRoles={isEditable ? availableRoles : undefined}
          onChange={onRoleChange}
          variant="hidden"
        />
      </div>
    );
  },
);

const useStyles = makeStylesHook(
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    userDetails: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      flexGrow: 1,
    },
  }),
);
