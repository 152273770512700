import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import cx from 'classnames';

import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import Tooltip from 'common/ui/components/Tooltip';
import {
  CanvasControlVariant,
  isLightVariant,
} from 'common/ui/components/Workspace/CanvasControl';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import CrossHairIcon from 'common/ui/icons/CrossHairIcon';
import DefaultCursorIcon from 'common/ui/icons/DefaultCursorIcon';
import ZoomInIcon from 'common/ui/icons/ZoomInIcon';

export default React.memo(function MouseModeControl({
  disabledMouseSelect,
  canvasControlVariant,
}: {
  disabledMouseSelect?: boolean;
  canvasControlVariant: CanvasControlVariant;
}) {
  const classes = useStyles();
  const { mode, setMode } = useContext(MouseModeControlContext);
  return (
    <div
      className={cx(
        classes.controls,
        isLightVariant(canvasControlVariant) && classes.light,
      )}
    >
      <Tooltip title="Zoom">
        <IconButton
          className={cx(classes.iconButton, {
            [classes.active]: mode === 'zoom',
          })}
          size="xsmall"
          icon={<ZoomInIcon />}
          onClick={() => setMode('zoom')}
        />
      </Tooltip>
      {!disabledMouseSelect && (
        <Tooltip title="Select">
          <IconButton
            className={cx(classes.iconButton, {
              [classes.active]: mode === 'select',
            })}
            size="xsmall"
            icon={<CrossHairIcon />}
            onClick={() => setMode('select')}
          />
        </Tooltip>
      )}
      <Tooltip title="Pan">
        <IconButton
          className={cx(classes.iconButton, {
            [classes.active]: mode === 'pan',
          })}
          size="xsmall"
          icon={<DefaultCursorIcon />}
          onClick={() => setMode('pan')}
        />
      </Tooltip>
    </div>
  );
});

const useStyles = makeStylesHook(theme => ({
  controls: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(5),
    flex: 0,
  },
  light: {
    '& $iconButton': {
      color: Colors.TEXT_PRIMARY,
    },
    '& $active': {
      color: Colors.BLUE_30,
    },
  },
  iconButton: {
    color: Colors.DARK_TEXT_SECONDARY,
    '&:hover': {
      color: Colors.BLUE_40,
      backgroundColor: 'inherit',
    },
  },
  active: {
    color: Colors.BLUE_30,
  },
}));

export type MouseMode = 'pan' | 'select' | 'zoom';
type MouseModeContextValue = Readonly<{
  mode: MouseMode;
  setMode: Dispatch<SetStateAction<MouseMode>>;
}>;

export const MouseModeControlContext = createContext<MouseModeContextValue>({
  get mode(): MouseModeContextValue['mode'] {
    throw new Error('MouseModeControlContext.Provider is missing.');
  },
  get setMode(): MouseModeContextValue['setMode'] {
    throw new Error('MouseModeControlContext.Provider is missing.');
  },
});

export function MouseModeControlContextProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [mode, setMode] = useState<MouseMode>('pan');
  const contextValue = useMemo(
    () => ({
      mode,
      setMode,
    }),
    [mode],
  );
  return (
    <MouseModeControlContext.Provider value={contextValue}>
      {children}
    </MouseModeControlContext.Provider>
  );
}
