import { Reducer, useCallback, useReducer } from 'react';

type UndoStack<State> = { undo: State[]; current: State; redo: State[] };

/**
 * Given a reducer function, this hook returns a useReducer hook that has undo/
 * redo functionality. It contains the current state, an undo stack and a redo
 * stack. It also supports filtering of actions using a filter function to
 * which the action should be passed.
 * */
export function useUndoReducer<State, Action extends { type: string }>(
  reducer: Reducer<State, Action>,
  initialState: State,
  /**
   * A filtering function which should return whether the given action
   * can be undone/redone or not.
   * */
  isActionUndoable?: (action: Action) => boolean,
) {
  const initialUndoReducerState: UndoStack<State> = {
    undo: [],
    current: initialState,
    redo: [],
  };

  /**
   * The reducer that produces the undo/redo/current state structure.
   * It can take in the actions of the reducer it wraps or its own undo and
   * redo actions and updates the stacks and current state accordingly.
   */
  const undoReducer = useCallback(
    (state: UndoStack<State>, action: Action | UndoReducerAction): UndoStack<State> => {
      if (action.type === UNDO) {
        if (state.undo.length === 0) {
          return state;
        }

        // Pop off the previous state from the top of the stack and use the rest
        // as the new undo stack.
        const [newCurrent, ...undo] = state.undo;

        return {
          undo,
          current: newCurrent,
          redo: [state.current, ...state.redo],
        };
      }

      if (action.type === REDO) {
        if (state.redo.length === 0) {
          return state;
        }
        const [newCurrent, ...redo] = state.redo;

        return {
          undo: [state.current, ...state.undo],
          current: newCurrent,
          redo,
        };
      }

      // It's not an undo reducer-specific action so pass it off to the reducer
      // we've wrapped to get the new current state.
      const newCurrent = reducer(state.current, action as Action);

      // We only store states, and not actions, but in order to filter actions by
      // not adding them to the undo or redo stacks, we need to know if the action
      // that resulted in the current state should be filtered or not.
      // If we want to filter the action that resulted in the existing current state, we just
      // don't add it to the undo stack when applying the new action.
      const undo = isActionUndoable?.(action as Action)
        ? [state.current, ...state.undo]
        : state.undo;

      return {
        undo,
        current: newCurrent,
        redo: [],
      };
    },
    [isActionUndoable, reducer],
  );

  return useReducer(undoReducer, initialUndoReducerState);
}

const UNDO = 'undo';
const REDO = 'redo';

export function undo(): { type: typeof UNDO } {
  return { type: UNDO };
}

export function redo(): { type: typeof REDO } {
  return { type: REDO };
}

type UndoReducerAction = { type: typeof UNDO } | { type: typeof REDO };
