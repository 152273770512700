import React from 'react';

import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import { NoExperimentsMessage } from 'client/app/apps/experiments/NoExperimentsMessage';

type Props = {
  hasNoSearchResults: boolean;
  searchQuery?: string;
};

const ExperimentsListNoDataComponent = ({ hasNoSearchResults, searchQuery }: Props) => {
  return hasNoSearchResults ? (
    <NoExperimentsMessage />
  ) : (
    <NoEntitiesMessage
      entityName="experiments"
      messageType={MessageType.NO_FILTER_RESULTS}
      searchQuery={searchQuery}
    />
  );
};

export default ExperimentsListNoDataComponent;
