import React from 'react';

import FormHelperText from '@mui/material/FormHelperText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  summary: DeviceConfigSummary;
};

function ConfigFileSummary({ summary }: Props) {
  const classes = useStyles();
  return (
    <>
      <FormHelperText>Summary</FormHelperText>
      <Table size="small" className={classes.configSummaryTable}>
        <TableBody>
          <TableRow>
            <TableCell>Device: </TableCell>
            <TableCell>
              {summary.deviceManufacturer} {summary.deviceModel}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>S/N: </TableCell>
            <TableCell>{summary.deviceSN}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date:</TableCell>
            <TableCell> {new Date(summary.configDate).toLocaleString()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}

const useStyles = makeStylesHook({
  configSummaryTable: {
    marginBottom: '1rem',
  },
});

export default ConfigFileSummary;
