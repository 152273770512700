import React, { useCallback, useMemo, useState } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';

import { getDeviceModelLabel } from 'client/app/api/deviceFromGraphql';
import DeviceConfigurationDialog from 'client/app/components/DeviceLibrary/DeviceConfigurationDialog';
import DeviceLibrary from 'client/app/components/DeviceLibrary/DeviceLibrary';
import { useDeleteDeviceMutation } from 'client/app/components/DeviceLibrary/mutations';
import { useDeviceFilterBar } from 'client/app/components/DeviceLibrary/useDeviceFilterBar';
import { useDevices } from 'client/app/components/DeviceLibrary/useDevices';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { pluralize } from 'common/lib/format';
import { Device } from 'common/types/device';
import Colors from 'common/ui/Colors';
import { scrollableContent } from 'common/ui/commonStyles';
import Button from 'common/ui/components/Button';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import Fab from 'common/ui/components/Fab';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

/**
 * Will get the device and render a device library as full screen.
 */
export default function DeviceLibraryApp() {
  const { loading, error, data, refetch } = useDevices();
  const devices = useMemo(() => data?.devices ?? [], [data?.devices]);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<Set<string>>(new Set());
  const [deleteDevice] = useDeleteDeviceMutation();
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const [configuredDeviceID, setConfiguredDeviceID] = useState<UUID | null>(null);
  const deviceFilters = useDeviceFilterBar();

  const userProfile = useUserProfile();
  const isSupport = userProfile?.isSupport || false;
  const isToggleEnabled = useFeatureToggle('BULK_DELETE_DEVICE');

  const isBulkDeleteAllowed = isSupport && isToggleEnabled;

  const classes = useStyles();

  const onClickDelete = useCallback(async () => {
    if (selectedDeviceIds.size === 0) {
      return;
    }
    const singleDevice =
      selectedDeviceIds.size === 1
        ? devices.find(d => (d.id as any) === selectedDeviceIds.values().next().value)
        : null;
    const confirmDelete = await openConfirmationDialog({
      action: 'delete',
      isActionDestructive: true,
      additionalMessage: 'This cannot be undone, be careful.',
      object: singleDevice ? 'device' : pluralize(selectedDeviceIds.size, 'device'),
      ...(singleDevice
        ? {
            specificObject: `${singleDevice.name} (${getDeviceModelLabel(singleDevice)})`,
          }
        : {}),
    });

    if (confirmDelete) {
      await Promise.all(
        devices
          .filter(d => selectedDeviceIds.has(d.id))
          .map(d => deleteDevice({ deviceID: d.id })),
      );
    }
  }, [deleteDevice, devices, openConfirmationDialog, selectedDeviceIds]);

  const onSelect = useCallback((id: string) => {
    setSelectedDeviceIds(devices => {
      const newDevices = new Set(devices);
      if (newDevices.has(id)) {
        newDevices.delete(id);
      } else {
        newDevices.add(id);
      }
      return newDevices;
    });
  }, []);

  const deviceActions = useCallback(
    (device: Device) => {
      const handleDelete = async () => {
        const confirmDelete = await openConfirmationDialog({
          action: 'delete',
          isActionDestructive: true,
          object: 'device',
          specificObject: `${device.name} (${device.model})`,
        });

        if (confirmDelete) {
          await deleteDevice({ deviceID: device.id as DeviceId });
        }
      };

      const handleClickConfigurations = () => {
        setConfiguredDeviceID(device.id as DeviceId);
      };

      return (
        <>
          <Button className={classes.action} variant="tertiary" onClick={handleDelete}>
            Delete
          </Button>
          {device.isConfigurable && (
            <Button
              className={classes.action}
              variant="tertiary"
              onClick={handleClickConfigurations}
            >
              Configuration
            </Button>
          )}
        </>
      );
    },
    [classes.action, deleteDevice, openConfirmationDialog],
  );

  // We were not using DialogManager here as we want to pass the latest device state
  // when it changes via DeviceConfigurationDialog's props - this is not possible
  // with dialog manager as it stores a snapshot of the props that never changes.
  // TODO Check if we could use useDialog instead?
  const deviceConfigurationDialog = useMemo(() => {
    const device = configuredDeviceID && devices.find(d => d.id === configuredDeviceID);
    if (!device) {
      return null;
    }
    return (
      <DeviceConfigurationDialog
        device={device}
        onRefresh={refetch}
        onClose={() => setConfiguredDeviceID(null)}
      />
    );
  }, [configuredDeviceID, devices, refetch]);

  if (error) {
    return <GraphQLErrorPanel error={error} onRetry={refetch} />;
  }
  return (
    <div className={classes.content}>
      <DeviceLibrary
        isLoading={loading}
        devices={data?.devices ?? []}
        renderActions={deviceActions}
        selectedDeviceIds={Array.from(selectedDeviceIds)}
        onSelect={onSelect}
        showSelectionStatus={isBulkDeleteAllowed}
        parentDeviceFilters={deviceFilters}
      />
      {isBulkDeleteAllowed && selectedDeviceIds.size !== 0 && (
        <Fab
          onClick={onClickDelete}
          icon={<DeleteIcon />}
          styleOverrides={{ backgroundColor: Colors.RED, color: Colors.WHITE }}
        />
      )}
      {deviceConfigurationDialog}
      {confirmationDialog}
    </div>
  );
}

const useStyles = makeStylesHook({
  content: {
    background: Colors.GREY_5,
    flex: 1,
    overflow: 'hidden',
  },
  action: {
    color: Colors.GREY_60,
  },
  scrollContainer: {
    ...scrollableContent,
    paddingTop: '16px',
  },
});
