import React from 'react';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  name: string;
  onlyGroup: boolean; // whether this is the only group being rendered
};

export default function ElementParameterGroupHeader(props: Props) {
  const classes = useStyles();
  const { name, onlyGroup } = props;
  if (!name && onlyGroup) {
    return null;
  } else if (!name) {
    return (
      <div className={classes.paramGroupName} style={{ color: 'red' }}>
        ** SET GROUP FOR THESE PARAMETERS **
      </div>
    );
  }

  return <div className={classes.paramGroupName}>{name}</div>;
}

const useStyles = makeStylesHook(theme => ({
  paramGroupName: {
    cursor: 'pointer',
    position: 'relative',
    fontSize: '12px',
    margin: '16px 0 0 0',
    fontWeight: 500,
    wordWrap: 'break-word',
    maxWidth: '80%',
    color: theme.palette.info.dark,
  },
}));
