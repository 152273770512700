/// <reference path="../types/markdown-it-collapsible.d.ts" />
/// <reference path="../types/markdown-it-ins.d.ts" />

import MarkdownIt from 'markdown-it';
import MarkdownItCollapsible from 'markdown-it-collapsible';
import MarkdownItIns from 'markdown-it-ins';
import MarkdownItMultiMdTable from 'markdown-it-multimd-table';

import { OpaqueAlias } from 'common/types/OpaqueAlias';

export type Markdown = OpaqueAlias<string, 'Markdown'>;

// memoize parser so it can be easily reused
let parser: MarkdownIt | null = null;
function getParser() {
  return parser ?? (parser = newParser());
}

let safeParser: MarkdownIt | null = null;

function getSafeParser() {
  return safeParser ?? (safeParser = newParser(false));
}

function newParser(allowHtml = true): MarkdownIt {
  const p = new MarkdownIt();
  p.set({ html: allowHtml });
  const tableOpts = {
    multiline: true,
    rowspan: true,
    headerless: true,
    multibody: true,
    aotolabel: true,
  };
  return p
    .use(MarkdownItCollapsible)
    .use(MarkdownItMultiMdTable, tableOpts)
    .use(MarkdownItIns);
}

/**
 * Converts Markdown into HTML, returns string containing the HTML.
 */
export function renderMarkdownUnsafe(markdown: Markdown): string {
  return getParser().render(markdown);
}

/**
 * Converts Markdown into HTML safely, as no HTML is allowed in the source text.
 * This should be used for user-supplied text.
 */
export function renderMarkdownSafe(markdown: Markdown) {
  return getSafeParser().render(markdown);
}

/**
 * Converts plain text into markdown. This is not a perfect conversion to markdown,
 * e.g. we do not escape characters for bold/italic/strikethrough.
 * @param plainText
 */
export function stringToMarkdown(plainText: string): Markdown {
  return plainText as Markdown;
}
