import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const SnapshotIcon = React.memo<SvgIconProps>(function SnapshotIcon(props) {
  return (
    <SvgIcon viewBox="0 -3 26 23" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.101C0 .493.493 0 1.101 0h6.883c.608 0 1.102.493 1.102 1.101v1.377h5.23V1.1C14.317.493 14.81 0 15.419 0h3.854c.609 0 1.102.493 1.102 1.101v3.855c0 .608-.493 1.101-1.102 1.101h-3.854a1.101 1.101 0 0 1-1.101-1.101V3.579H9.086v1.927h3.19v2.352c-.38.109-.748.236-1.101.382V6.608h-2.09v1.376c0 .608-.493 1.102-1.1 1.102H1.1A1.101 1.101 0 0 1 0 7.984V1.101ZM8.958 13.756a6.932 6.932 0 0 1 6.447-4.395 6.932 6.932 0 0 1 6.447 4.395l.148.374-.148.374a6.932 6.932 0 0 1-6.447 4.395 6.932 6.932 0 0 1-6.447-4.395l-.148-.374.148-.374Zm2.065.374a4.887 4.887 0 0 0 4.382 2.728c1.92 0 3.582-1.11 4.382-2.728a4.887 4.887 0 0 0-4.382-2.728 4.887 4.887 0 0 0-4.382 2.728Z"
        fill="currentColor"
      />
      <path
        d="M15.522 12.679a1.532 1.532 0 1 0 .001 3.063 1.532 1.532 0 0 0 0-3.063Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.958 13.756a6.932 6.932 0 0 1 6.447-4.395 6.932 6.932 0 0 1 6.447 4.395l.148.374-.148.374a6.932 6.932 0 0 1-6.447 4.395 6.932 6.932 0 0 1-6.447-4.395l-.148-.374.148-.374Zm2.065.374a4.887 4.887 0 0 0 4.382 2.728c1.92 0 3.582-1.11 4.382-2.728a4.887 4.887 0 0 0-4.382-2.728 4.887 4.887 0 0 0-4.382 2.728Z"
        fill="currentColor"
      />
      <path
        d="M15.522 12.679a1.532 1.532 0 1 0 .001 3.063 1.532 1.532 0 0 0 0-3.063Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
