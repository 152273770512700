import findLast from 'lodash/findLast';
import memoize from 'lodash/memoize';

import {
  getGroupDimensions,
  isElementWithinSelection,
} from 'client/app/lib/layout/LayoutHelper';
import { Connection, ElementInstance, Group } from 'common/types/bundle';

/**
 * Gets the drop target group for the dragged element. This is the top-most group that
 * intersects with the element, unless the element still intersects with its current
 * group (if it has one).
 */
export const getDropTargetGroup = memoize(
  <G extends Group>(
    draggedElement: ElementInstance,
    allGroups: G[],
    connections: Connection[],
  ) => {
    const currentGroup = allGroups.find(group =>
      group.elementIds.includes(draggedElement.Id),
    );

    if (
      currentGroup &&
      isElementWithinSelection(
        getGroupDimensions(currentGroup),
        draggedElement,
        connections,
      )
    ) {
      return currentGroup;
    }

    return (
      findLast(allGroups, group =>
        isElementWithinSelection(getGroupDimensions(group), draggedElement, connections),
      ) ?? null
    );
  },
);
