import React, { useCallback, useContext, useRef, useState } from 'react';

import { useQuery } from '@apollo/client';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';

import { QUERY_SIMULATIONS_DIALOG } from 'client/app/api/gql/queries';
import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import { useUserList } from 'client/app/apps/experiments/useUserList';
import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import SimulationCard from 'client/app/components/cards/SimulationCard';
import {
  ArrayElement,
  SimulationsForDialogQuery,
  SimulationsForDialogQueryVariables,
} from 'client/app/gql';
import { FavoritedByTypes } from 'client/app/gql';
import usePagination from 'client/app/hooks/usePagination';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { PageInfo } from 'common/server/graphql/pagination';
import { loadingWithinDialog } from 'common/ui/commonStyles';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import DialogWrap from 'common/ui/components/Dialog/DialogWrap';
import FilterChipWithAutocomplete from 'common/ui/components/FilterChip/FilterChipWithAutocomplete';
import FilterChipWithDateRange, {
  DateRange,
} from 'common/ui/components/FilterChip/FilterChipWithDateRange';
import FilterChipWithSwitch from 'common/ui/components/FilterChip/FilterChipWithSwitch';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import SearchField from 'common/ui/components/SearchField';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type SimulationsForDialogItem = ArrayElement<
  SimulationsForDialogQuery['simulations']['items']
>;

type DialogContentProps = {
  onSelectSimulationFromDialog: (simulation: SimulationsForDialogItem) => void;
  onClose: () => void;
  isOpen: boolean;
  dialogClassName?: string;
  onBackClick?: () => void;
  disableRestoreFocus?: boolean;
  disabledSimulationIds?: SimulationId[];
};

export default function SimulationsDialogContainer(props: DialogContentProps) {
  const classes = useStyles();
  const {
    isOpen,
    onSelectSimulationFromDialog,
    onClose,
    dialogClassName,
    onBackClick,
    disableRestoreFocus,
    disabledSimulationIds,
  } = props;
  const [query, setQuery] = useState('');
  const scrollableRef = useRef<HTMLDivElement>(null);
  const { screenId } = useContext(ScreenContext);
  const currentUser = useUserProfile();
  const usersDropdownOptions = useUserList();
  const [filterUserId, setFilterUserId] = useState(currentUser?.id);
  const onFilterUser = useCallback(
    (id?: string) => {
      logEvent(
        'filter-simulations-by-user',
        screenId as string,
        currentUser && id === currentUser.id ? 'self' : 'others',
      );
      setFilterUserId(id);
    },
    [currentUser, screenId],
  );

  const [favorited, setFilterFavorited] = useState(false);
  const onFilterFavorited = useCallback(
    (value: boolean) => {
      logEvent(
        'filter-simulations-by-favorited',
        screenId as string,
        value ? 'favorited' : 'none',
      );
      setFilterFavorited(value);
    },
    [screenId],
  );

  const [filterDateRange, setFilterDateRange] = useState<DateRange>({
    startDate: undefined,
    endDate: undefined,
  });
  const onFilterByDateRange = useCallback(
    (newValue: DateRange) => {
      logEvent('filter-simulations-by-date', screenId as string);
      setFilterDateRange(newValue);
    },
    [screenId],
  );

  const handleClose = useCallback(() => {
    onClose();
    // Reset filter states, so that the dialog will be
    // reopened in a clean state.
    setFilterDateRange({});
    setFilterFavorited(false);
    setQuery('');
    setFilterUserId(currentUser?.id);
  }, [currentUser, onClose]);

  return (
    <ComplexActionDialog
      title="Simulations"
      onClose={handleClose}
      isOpen={isOpen}
      showCloseButton
      onBackClick={onBackClick}
      className={dialogClassName}
      fullHeight
      disableRestoreFocus={disableRestoreFocus}
      content={
        <ContainerWithIntersectionBar
          filters={
            <>
              <div className={classes.filter}>
                <Chip
                  color="primary"
                  label="Successful"
                  disabled
                  className={classes.chip}
                />
                <FilterChipWithAutocomplete
                  heading="Filter by Author"
                  defaultChipLabel="Author"
                  dropdownOptions={usersDropdownOptions}
                  filterValue={filterUserId}
                  onFilter={onFilterUser}
                  className={classes.chip}
                />
                <FilterChipWithSwitch
                  heading="Filter by Stars"
                  activeChipLabel="Starred"
                  inactiveChipLabel="Stars"
                  filterValue={favorited}
                  onFilter={onFilterFavorited}
                  className={classes.chip}
                />
                <FilterChipWithDateRange
                  heading="Filter by Date Range"
                  defaultChipLabel="Date Range"
                  filterValue={filterDateRange}
                  onFilter={onFilterByDateRange}
                  className={classes.chip}
                />
              </div>
              <SearchField
                label="Search"
                onQueryChange={setQuery}
                className={classes.search}
              />
            </>
          }
          content={
            <SimulationsContainer
              favorited={favorited}
              filterUserId={filterUserId}
              filterDateRange={filterDateRange}
              // Right now, only allow user to be able to filter by successful simulations even though
              // simulations technically can be in other intermediate states.
              filterSuccessfulSimulations
              searchQuery={query}
              scrollableRef={scrollableRef}
              onSelectSimulationFromDialog={onSelectSimulationFromDialog}
              disabledSimulationIds={disabledSimulationIds}
            />
          }
          scrollableRef={scrollableRef}
        />
      }
    />
  );
}

type ContainerProps = {
  scrollableRef: React.RefObject<HTMLDivElement>;
  filterDateRange: DateRange;
  filterSuccessfulSimulations: boolean;
  searchQuery: string;
  favorited: boolean;
  onSelectSimulationFromDialog: (simulation: SimulationsForDialogItem) => void;
  filterUserId?: string;
  disabledSimulationIds?: SimulationId[];
};

const SimulationsContainer = React.memo(function SimulationsContainer(
  props: ContainerProps,
) {
  const {
    filterUserId,
    filterDateRange,
    filterSuccessfulSimulations,
    searchQuery,
    scrollableRef,
    onSelectSimulationFromDialog,
    favorited,
    disabledSimulationIds,
  } = props;

  const classes = useStyles();

  const variables: SimulationsForDialogQueryVariables = {
    userId: filterUserId,
    filterStartDate: filterDateRange.startDate
      ? filterDateRange.startDate.format('YYYY-MM-DD')
      : undefined,
    filterEndDate: filterDateRange.endDate
      ? filterDateRange.endDate.format('YYYY-MM-DD')
      : undefined,
    searchQuery: searchQuery,
    favoriteToFilter: favorited
      ? FavoritedByTypes.FAVORITED_BY_ANYONE
      : FavoritedByTypes.ALL,
    status: filterSuccessfulSimulations ? 'COMPLETED' : undefined,
  };

  const {
    loading: isInitialLoading,
    error,
    data,
    refetch,
    fetchMore,
  } = useQuery(QUERY_SIMULATIONS_DIALOG, {
    variables,
    // If a simulation was created in another tab the cache will be stale. Skip the cache.
    fetchPolicy: 'network-only',
  });

  const simulations = data?.simulations?.items || [];
  const pageInfo = data?.simulations.pageInfo as PageInfo | undefined;
  const dependencies = [
    filterUserId,
    filterDateRange,
    searchQuery,
    favorited,
    filterSuccessfulSimulations,
  ];
  const hasNextPage = usePagination({
    entity: 'simulations',
    pageInfo,
    fetchMore,
    dependencies,
    scrollableRef,
    isInitialLoading,
    variables,
  });

  if (error) {
    return <GraphQLErrorPanel error={error} onRetry={refetch} />;
  }
  if (isInitialLoading) {
    return (
      <DialogWrap dialog className={classes.circularLoadingFullDialog}>
        <CircularProgress />
      </DialogWrap>
    );
  }

  if (simulations.length === 0) {
    return (
      <NoEntitiesMessage
        entityName="simulations"
        messageType={MessageType.NO_FILTER_RESULTS}
        searchQuery={searchQuery}
      />
    );
  }

  return (
    <div className={classes.list}>
      {simulations.map(simulation => (
        <SimulationCard
          key={simulation.id}
          simulation={simulation}
          showNewTabButton
          onClick={() => onSelectSimulationFromDialog(simulation)}
          isDisabled={disabledSimulationIds?.includes(simulation.id)}
        />
      ))}
      {hasNextPage && (
        <div className={classes.circularLoadingContainer}>
          <CircularProgress size={24} />
        </div>
      )}
    </div>
  );
});

const useStyles = makeStylesHook(theme => ({
  ...experimentsStyles(theme),
  circularLoadingFullDialog: {
    ...loadingWithinDialog.circularLoadingFullDialog,
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    marginTop: theme.spacing(6),
  },
}));
