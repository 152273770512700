import React, { useCallback } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';

import Checkbox from 'common/ui/components/Checkbox';
import FilterChip, {
  FilterChipWithPopoverProps,
} from 'common/ui/components/FilterChip/FilterChip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type Option<T> = {
  label: string;
  value: T;
  selected: boolean;
};

type Props<T> = FilterChipWithPopoverProps<Option<T>[]>;

/**
 * A FilterChip that has multiple checkboxes to use as filter.
 */
export default function FilterChipWithCheckbox<T>(props: Props<T>) {
  const classes = useStyles();
  const { filterValue, defaultChipLabel, onFilter, size } = props;

  // Update the label of the chip depending on whether there is 0, 1, 2+ items.

  const selectedLabels = new Set<string>();
  filterValue.forEach(v => {
    v.selected && selectedLabels.add(v.label);
  });

  const valueLabel =
    selectedLabels.size === 1
      ? [...selectedLabels][0]
      : selectedLabels.size > 1
      ? `${defaultChipLabel} - ${selectedLabels.size} selected`
      : defaultChipLabel;

  const chipLabelVariant = selectedLabels.size > 0 ? 'filled' : 'outlined';

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // We support selection of one label as a way of referring to multiple values.
      const newValue = filterValue.map(v => {
        return v.label === e.target.id ? { ...v, selected: e.target.checked } : v;
      });
      onFilter(newValue);
    },
    [filterValue, onFilter],
  );

  const distinctOptionsByLabel = Object.values(
    Object.fromEntries(filterValue.map(v => [v.label, v])),
  );

  const clearSelection = useCallback(() => {
    const newValue = filterValue.map(option => ({
      ...option,
      selected: false,
    }));
    onFilter(newValue);
  }, [filterValue, onFilter]);

  return (
    <FilterChip
      heading={props.heading}
      chipLabel={valueLabel}
      chipLabelVariant={chipLabelVariant}
      filterValue={distinctOptionsByLabel}
      onDelete={clearSelection}
      isActive={selectedLabels.size > 0}
      className={props.className}
      size={size}
      popoverContent={
        <div className={classes.content}>
          {distinctOptionsByLabel.map(option => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChange}
                    id={option.label}
                    checked={option.selected}
                  />
                }
                label={option.label}
                key={option.label}
              />
            );
          })}
        </div>
      }
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '276px',
    marginTop: theme.spacing(3),
  },
}));
