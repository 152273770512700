import { useCallback } from 'react';

import { ApolloError, useApolloClient } from '@apollo/client';

import { callUploadLiquidClassesCSVForExistingDevice } from 'client/app/api/gql/mutations';
import { DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import { getFileText } from 'common/lib/getFileBytes';
import { useDialogManager } from 'common/ui/components/DialogManager';
import InfoDialog from 'common/ui/components/InfoDialog';

export const useHandleUploadLiquidClassCsvForExistingDevice = (
  device: DeviceCommon,
  onRefresh?: () => void,
) => {
  const apollo = useApolloClient();

  const upload = useCallback(
    (csv: string) =>
      callUploadLiquidClassesCSVForExistingDevice(apollo, {
        deviceID: device.id,
        csv,
      }).then(({ data }) => {
        onRefresh?.();
        return data?.updateDeviceLiquidClasses;
      }),
    [apollo, device.id, onRefresh],
  );

  return useUploadCallback(upload);
};

function useUploadCallback(
  upload: (
    csv: string,
  ) => Promise<{ created: number; updated: number } | null | undefined>,
) {
  const dialogManager = useDialogManager();

  return useCallback(
    async (e: React.FormEvent<HTMLInputElement>) => {
      const files = e.currentTarget.files;
      if (!files) {
        // no files selected, user cancelled
        return;
      }

      if (files.length !== 1) {
        throw new Error('Please select one CSV file.');
      }
      const data = await getFileText(files[0]);

      try {
        const results = await upload(data);

        if (!results) {
          throw new Error('Did not receive any data from the backend.');
        }
        const { updated, created } = results;
        dialogManager.openDialog('UPLOAD_LIQUID_CLASSES_CSV_SUCCESS', InfoDialog, {
          title: 'Upload device liquid classes',
          message: `File uploaded successfully: created ${created} liquid classes, updated ${updated}.`,
        });
      } catch (e) {
        if (e instanceof ApolloError) {
          const errorMessage = e.graphQLErrors[0]?.message;
          dialogManager.openDialog('UPLOAD_LIQUID_CLASSES_CSV_ERROR', InfoDialog, {
            title: 'Upload device liquid classes',
            message:
              `Could not parse the liquid class file.\n` +
              `Please fix the following error(s):\n\n${errorMessage}`,
            maxWidth: 'lg',
          });
          return;
        }
        throw e;
      }
    },
    [dialogManager, upload],
  );
}
