import { useCallback } from 'react';

import * as xhr from 'client/app/lib/xhr';
import { getFileArrayBuffer, getFileText } from 'common/lib/getFileBytes';

const uploadWorkflowBundle = async (
  postRaw: ReturnType<typeof xhr.usePostRaw>,
  bundleFile: string | ArrayBuffer,
  headers: { 'Content-Type': string; 'Content-Length': string },
) => {
  const response = await postRaw(`/web/v2/workflow/createFromBundle`, {
    headers,
    body: bundleFile,
  });
  const { id } = await response.json();
  return id as string;
};

const errorHandler = (err: Error) => {
  // Log it so we see the full stack trace
  console.error(err);
  throw new Error(`Upload error: ${err.message}`);
};

// This upload is quite tricky,  we have 4 ways the bundle upload has to work:
// Upload 2 x 2 use case:
//
// 1. NWE and bundle V2: upload the json/zip and let appserver do its work (translate to V1 save in postgres); get workflow ID and redirect to workflow/id
// 2. OWE and bundle V2:upload the json/zip and let appserver do its work (translate to V1 save in postgres); get back a bundle v1 and inject it into local store
// 3. OWE and bundle V1: easy, inject the bundle into local store
// 4.  NWE and bundle V1:  same as openIn? send bundle to BE to create workflow in postgress, get back id and redirect.
//   2 and 3 are taken care in client/app/components/WorkflowToolbar/UploadButton.tsx and will disappear
// For now we take care only of bundle v2 uploads (1.).
// TODO take care of 4. (i.e) NEW upload of v1 bundle.

export default function useUploadFileBundle() {
  const postRaw = xhr.usePostRaw();
  return useCallback(
    function uploadFileBundle(file: File) {
      const isZip = file.name.match(/\.zip$/); // mime type vary so we have to check that the extension is ".zip".

      const headers = {
        'Content-Type': file.type,
        'Content-Length': file.size.toString(),
      };

      if (isZip) {
        return getFileArrayBuffer(file)
          .then(bundle => uploadWorkflowBundle(postRaw, bundle, headers))
          .catch(errorHandler);
      } else {
        // we're assuming that whatever is not a zip is textual
        return getFileText(file)
          .then(buffer => {
            return uploadWorkflowBundle(postRaw, buffer, headers);
          })
          .catch(errorHandler);
      }
    },
    [postRaw],
  );
}
