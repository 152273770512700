import React, { useMemo } from 'react';

import OpenInNew from '@mui/icons-material/OpenInNew';
import MuiCard from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import { paperClasses } from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import { useChecklist } from 'client/app/api/ChecklistApi';
import {
  accessibleDeviceFromGraphQL,
  deviceFromGraphQL,
} from 'client/app/api/deviceFromGraphql';
import CommentButton from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/components/CommentButton';
import DeviceSection from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/components/DeviceSection';
import Tasks from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/components/Tasks';
import ExecutionStageProvider from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/ExecutionStageContext';
import {
  trackPlateSetupOpened,
  trackStagePreviewOpened,
} from 'client/app/apps/execution-details/ExecuteTab/metrics/executionDetailsMetrics';
import { ExecutionStage } from 'client/app/apps/execution-details/types';
import { ExecutionModeEnum, ExecutionStatusEnum } from 'client/app/gql';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { formatDateTime } from 'common/lib/format';

type Props = {
  executionStage: ExecutionStage;
  showTasks?: boolean;
  buttonSection?: React.ReactNode | React.ReactNode[];
  printSection?: React.ReactNode;
};

export default function ExecutionStageCard({
  executionStage,
  buttonSection,
  showTasks = false,
  printSection,
}: Props) {
  const { mainDevice, peripheralDevices } = useMemo(
    () => ({
      mainDevice: deviceFromGraphQL(executionStage.deviceExecutionMode.mainDevice),
      peripheralDevices: executionStage.deviceExecutionMode.peripheralDevices.map(
        accessibleDeviceFromGraphQL,
      ),
    }),
    [
      executionStage.deviceExecutionMode.mainDevice,
      executionStage.deviceExecutionMode.peripheralDevices,
    ],
  );

  const { simulationId, name, orderNum } = executionStage.simulationStage;
  const previewLink = `/#/simulation-details/${simulationId}/preview?stage=${
    orderNum + 1
  }`;
  const setupLink = `/#/simulation-details/${simulationId}/setup?stage=${orderNum + 1}`;

  const showManualCopy =
    executionStage.deviceExecutionMode.mode === ExecutionModeEnum.Manual;
  const isCompleted =
    executionStage.status === ExecutionStatusEnum.EXECUTION_COMPLETED ||
    executionStage.status === ExecutionStatusEnum.EXECUTION_SUCCESS; // EXECUTION_SUCCESS is a subset of EXECUTION_COMPLETED where no tasks failed
  const completedDate = useMemo(
    () =>
      isCompleted
        ? formatDateTime(
            moment
              .max(executionStage.tasks.map(task => moment(task.lastModifiedAt)))
              .toDate(),
          )
        : null,
    [executionStage.tasks, isCompleted],
  );

  const stageNumber = orderNum + 1;
  const checklist = useChecklist(executionStage);
  const isEnabledMultiIOPlates = useFeatureToggle(
    'MULTI_INPUT_OUTPUT_PLATE_EXECUTION_DETAILS',
  );
  const showSetupLink = isEnabledMultiIOPlates ? !checklist : true;

  return (
    <ExecutionStageProvider stage={executionStage}>
      <Card>
        <CardContent showTasks={showTasks}>
          <StageNameArea>
            <StageIndex variant="caption">
              Stage {stageNumber}
              {name ? ':' : null}
            </StageIndex>
            {name && <StageName variant="caption">{name}</StageName>}
          </StageNameArea>
          <ExecutionModeArea>
            <DeviceSection device={mainDevice} showManualCopy={showManualCopy} />
            {peripheralDevices.map(device => (
              <DeviceSection key={device.id} device={device} />
            ))}
          </ExecutionModeArea>
          <ButtonsArea>{buttonSection}</ButtonsArea>
          {showTasks && (
            <Tasks
              stage={executionStage}
              checklist={checklist}
              printSection={printSection}
            />
          )}
          <LinksArea>
            <NewTabLink
              href={previewLink}
              target="_blank"
              rel="noreferrer"
              onClick={() => trackStagePreviewOpened(executionStage)}
            >
              <Typography variant="body1">Stage preview</Typography>
              <OpenInNew />
            </NewTabLink>
            {showSetupLink && (
              <NewTabLink
                href={setupLink}
                target="_blank"
                rel="noreferrer"
                onClick={() => trackPlateSetupOpened(executionStage)}
              >
                <Typography variant="body1">Plate setup</Typography>
                <OpenInNew />
              </NewTabLink>
            )}
          </LinksArea>
          {isCompleted && (
            <InfoArea>
              <CompleteDate variant="body2">
                Completed on <b>{completedDate}</b>
              </CompleteDate>
              <CommentButton
                comment={executionStage.comment}
                executionStageId={executionStage.id}
                orderNum={stageNumber}
              />
            </InfoArea>
          )}
        </CardContent>
      </Card>
    </ExecutionStageProvider>
  );
}

//#region Styles

const Card = styled(MuiCard)(({ theme }) => ({
  maxWidth: 'unset',
  border: `1px solid ${theme.palette.divider}`,
  margin: 'unset',
  [`&.${paperClasses.root}`]: {
    overflow: 'visible',
  },
}));

const CardContent = styled(MuiCardContent, {
  shouldForwardProp: prop => prop !== 'showTasks',
})<{ showTasks: boolean }>(({ theme, showTasks }) => ({
  display: 'grid',
  gridTemplateAreas: showTasks
    ? `
      "stage-name . "
      "execution-mode buttons"
      "tasks tasks"
      "links infos"
      `
    : `
      "stage-name . "
      "execution-mode buttons"
      "links infos"
      `,
  gridTemplateColumns: '1fr auto',
  gridTemplateRows: showTasks ? '14px auto auto 24px' : '14px auto 24px',
  columnGap: theme.spacing(5),
  rowGap: theme.spacing(4),

  padding: theme.spacing(6),
}));

const StageNameArea = styled('div')(({ theme }) => ({
  gridArea: 'stage-name',
  display: 'flex',
  gap: theme.spacing(3),
}));

const ExecutionModeArea = styled('div')(({ theme }) => ({
  gridArea: 'execution-mode',

  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  overflowX: 'hidden',
}));

const ButtonsArea = styled(Stack)(({ theme }) => ({
  gridArea: 'buttons',

  justifyContent: 'flex-end',
  flexDirection: 'row',
  gap: theme.spacing(5),
}));

const LinksArea = styled('div')(({ theme }) => ({
  gridArea: 'links',

  display: 'flex',
  gap: theme.spacing(5),
}));

const InfoArea = styled('div')(({ theme }) => ({
  gridArea: 'infos',

  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(5),
}));

const NewTabLink = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),

  textDecoration: 'none',
  color: 'black',

  '&:visited': {
    color: 'black',
  },
  '& svg': {
    fontSize: '14px',
  },
}));

const CompleteDate = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const StageIndex = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  fontWeight: 600,
}));

const StageName = styled(StageIndex)({
  textTransform: 'none',
  fontWeight: 500,
});

//#endregion
