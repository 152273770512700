import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

const BreadcrumbsMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.spacing(3),
    margin: '18px 0 0 -30px',
    overflow: 'visible',
  },
  '& .MuiPaper-root > ul': {
    padding: theme.spacing(2, 0, 0),

    '&::before': {
      content: '""',
      position: 'absolute',
      top: -8,
      left: 34,
      width: 16,
      height: 16,
      backgroundColor: theme.palette.background.paper,
      transform: 'rotate(-45deg)',
      clipPath:
        'polygon(-10px -10px, calc(100% + 10px) -10px, calc(100% + 10px) calc(100% + 10px))',
    },
  },
}));

export default BreadcrumbsMenu;
