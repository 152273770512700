import React from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { formatDateTime } from 'common/lib/format';

type Props = {
  deviceName: string;
  simulationName: string;
  stageIndex: number;
  startedAt: string;
};

export default function Header({
  deviceName,
  simulationName,
  stageIndex,
  startedAt,
}: Props) {
  return (
    <Stack gap={2} mb={4}>
      <Headline>
        <Typography variant="body1" fontWeight={500}>
          Simulation:
        </Typography>
        <Typography variant="body1" fontWeight={400}>
          {simulationName} (Stage {stageIndex + 1})
        </Typography>
      </Headline>

      <Headline>
        <Typography variant="body1" fontWeight={500}>
          Device:
        </Typography>
        <Typography variant="body1" fontWeight={400}>
          {deviceName}
        </Typography>
      </Headline>

      <Headline>
        <Typography variant="body1" fontWeight={500}>
          Started on:
        </Typography>
        <Typography variant="body1" fontWeight={400}>
          {formatDateTime(new Date(startedAt), 'at')}
        </Typography>
      </Headline>
    </Stack>
  );
}

const Headline = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  margin: 0,
}));
