import { useMemo } from 'react';

import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { UserTrackingInfo } from 'common/types/user';
import { useHeapTracking } from 'common/ui/useHeapTracking';

export default function ProductAnalyticsTrackers() {
  const user = useUserProfile();

  const userTrackingInfo = useMemo<UserTrackingInfo | undefined>(() => {
    return user
      ? {
          id: user.id,
          email: user.email,
          isSynthaceEmployee: user.isSynthaceEmployee,
          organisationName: user.organisationName,
        }
      : undefined;
  }, [user]);

  useHeapTracking(userTrackingInfo);

  return null;
}
