import React, { forwardRef, ReactElement } from 'react';

import { IntercomTourIDs } from 'common/lib/intercom';

type IntercomTargetProps = {
  children: ReactElement;
  tour: IntercomTourIDs;
  name: string;
  /**
   * Attach data-intercom-target to the child component instead of wrapping the child in a
   * div. The child component must handle the `data-intercom-target` prop. This includes
   * all DOM components (`div`, `a`, etc) and MUI components, which convert unknown props
   * to element attributes
   * (https://reactjs.org/blog/2017/09/08/dom-attributes-in-react-16.html).
   */
  decorate?: boolean;
};

/**
 * Wrap the children in a div with a data-intercom-target attribute so that the intercom
 * tour can reliably find the element on the page.
 *
 * In some cases we don't want an additional div. For example, a MUI MenuItem should be a
 * direct child of a Menu. Or we may not want the div because it breaks the layout. In
 * such cases we can specify `decorate` to attach the data-intercom-target directly to the
 * child.
 */
export const IntercomTarget = forwardRef<any, IntercomTargetProps>(
  function IntercomTarget({ children, tour, name, decorate }, ref) {
    const targetId = `${tour}-${name}`;
    if (decorate) {
      // Clone the child element and give it the data-intercom-target prop and the
      // forwarded ref.
      return React.cloneElement(children, {
        ['data-intercom-target']: targetId,
        ref,
      });
    } else {
      return (
        <div data-intercom-target={targetId}>{React.cloneElement(children, { ref })}</div>
      );
    }
  },
);
