import React from 'react';

import { Execution } from 'client/app/apps/execution-details/types';
import { formatShortTransitiveSimulationStatus } from 'client/app/lib/formatTransitiveSimulationStatus';
import CommonHeader, {
  CommonHeaderInfo,
} from 'common/ui/components/simulation-details/CommonHeader';

type Props = {
  execution: Execution;
};

const ExecutionDetailsHeader = React.memo(({ execution }: Props) => {
  // Convert to a format the CommonHeader understands.
  // The CommonHeader is used in AnthaHub, too.
  const executionHeaderInfo: CommonHeaderInfo = {
    name: 'Execution details',
    submitterName: execution.simulation.user.displayName,
    dateCreated: new Date(execution.createdAt),
    dateFinished: execution.finishedAt ? new Date(execution.finishedAt) : undefined,
  };

  return (
    <CommonHeader
      headerInfo={executionHeaderInfo}
      estimatedTimeSeconds={execution.simulation.timeEstimateSeconds || null}
      status={formatShortTransitiveSimulationStatus(
        execution.simulation.transitiveStatus,
      )}
      createdAtAction="Started"
    />
  );
});

export default ExecutionDetailsHeader;
