import React, { useContext, useState } from 'react';

import { useMutation } from '@apollo/client';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';

import { ExperimentDetailContext } from 'client/app/apps/experiments/ExperimentDetailScreen';
import { MUTATION_UPDATE_EXPERIMENT_MODULE } from 'client/app/apps/experiments/gql/mutations';
import { QUERY_EXPERIMENT } from 'client/app/apps/experiments/gql/queries';
import { TitleBlock as GraphQLTitleBlock } from 'client/app/gql';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDebounce from 'common/ui/hooks/useDebounce';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

export const TITLE_PLACEHOLDER = 'Please enter a title';

type Props = {
  experimentId: ExperimentId;
  block: GraphQLTitleBlock;
};

export default function TitleBlock({ block, experimentId }: Props) {
  const classes = useStyles();

  const { isEditing } = useContext(ExperimentDetailContext);

  const [updateModule] = useMutation(MUTATION_UPDATE_EXPERIMENT_MODULE);

  const [title, setTitle] = useState<string | null>(block.title);

  // Debounce the request so we don't flood the server with mutations as the user types
  const updateBlockDebounced = useDebounce(() => {
    void updateModule({
      variables: { id: block.id, title },
      refetchQueries: [{ query: QUERY_EXPERIMENT, variables: { id: experimentId } }],
    });
  }, 1000);

  const handleTitleChange = useTextFieldChange(newTitle => {
    setTitle(newTitle);
    updateBlockDebounced();
  });

  return (
    <div className={classes.titleBlock}>
      {isEditing ? (
        <Input
          disableUnderline
          classes={{ root: classes.title, input: classes.titleInput }}
          value={title}
          placeholder={TITLE_PLACEHOLDER}
          onChange={handleTitleChange}
        />
      ) : (
        <Typography variant="h3" className={classes.title}>
          {title || TITLE_PLACEHOLDER}
        </Typography>
      )}
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  titleBlock: {
    display: 'flex',
    width: '100%',
  },
  title: {
    flexGrow: 1,
    alignSelf: 'center',
  },
  titleInput: {
    ...theme.typography.h3,
    borderBottom: 'none',
  },
}));
