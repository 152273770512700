import { AdditionalEditorProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { Markdown } from 'common/lib/markdown';
import { AlwaysTrueCondition, Rule } from 'common/rules/types';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import { ColumnDataType } from 'common/types/spreadsheetEditor';

/**  Configuration settings for the Spreadsheet input type. */
export type SpreadsheetConfiguration = {
  sheets: SheetConfiguration[];
  rules?: SpreadsheetRule[];
  buttonTitle?: string;
};

export type SheetConfiguration = {
  name: string;
  columns: ColumnConfiguration[];
  /** Allow users to add new columns. */
  canAddColumns: boolean;
};

export const dragToFillBehaviours = ['copy', 'increment'] as const;
export type DragToFillBehaviour = typeof dragToFillBehaviours[number];

export type ColumnConfiguration = {
  name: string;
  /** Provided so that we can ingest values and select values for autocomplete */
  anthaType: string;
  /** Used to validate the columns on file upload, and passed to antha for parsing. */
  dataType: ColumnDataType;
  displayName: string | null;
  description: Markdown;
  /** The editor type and settings that should be used for input values into this column */
  editor: ColumnEditorConfiguration;
  hasTrailingGap: boolean;
  /** To be used only for the first column in a Sheet. */
  isFixed?: boolean;
  /** Choose the behaviour of the drag to fill action. Defaults to copy. */
  dragToFillBehaviour: DragToFillBehaviour;
};

type ColumnEditorConfiguration = Pick<ParameterEditorConfigurationSpec, 'type'> & {
  additionalProps: AdditionalEditorProps | null;
};

/** Permitted input types for Spreadsheet columns */
export type ColumnEditorType =
  | EditorType.AUTOCOMPLETE
  | EditorType.CHECKBOX
  | EditorType.DNA
  | EditorType.DROPDOWN
  | EditorType.FLOAT
  | EditorType.INT
  | EditorType.MEASUREMENT
  | EditorType.UNIT
  | EditorType.PLATE_TYPE
  | EditorType.POLICY
  | EditorType.STRING
  | EditorType.TOGGLE;

export type SpreadsheetRule = Rule<
  SpreadsheetConfigurationCondition,
  SpreadsheetConfigurationAction
>;

export type SpreadsheetConfigurationCondition = AlwaysTrueCondition;
export type SpreadsheetConfigurationAction = SetColumnRequiredAction;

export type SetColumnRequiredAction = {
  type: 'set-columns-required';
  sheetName: string;
  columnNames: string[];
  revertIfConditionNotMet: boolean;
};
export const SPREADSHEET_ANALYTICS_CATEGORY = 'table-ui';
