import React, { useCallback } from 'react';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import {
  isPlateBigEnough,
  PlateMinSizeByName,
} from 'client/app/apps/cherry-picker/CherryPickApi';
import {
  AllPlatesByType,
  SetPlatesByName,
} from 'client/app/apps/cherry-picker/CherryPickContext';
import PlateLibraryDialog from 'client/app/components/Parameters/PlateType/PlateLibraryDialog';
import { AppIcon } from 'client/app/icons';
import { ScreenRegistry } from 'client/app/registry';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

type TypeSelectorProps = {
  setPlatesByName: SetPlatesByName;
  plateName: string;
  plates: AllPlatesByType;
  plateMinSizeByName: PlateMinSizeByName;
  // Render as a single button only
  compact?: boolean;
  isDisabled: boolean;
};
/**
 *  If a plate type hasn't been selected, render a dummy plate with a button
 *  to allow users to select one.
 */
export default React.memo(function TypeSelector(props: TypeSelectorProps) {
  const { compact, setPlatesByName, plateName, plates, plateMinSizeByName, isDisabled } =
    props;
  const classes = useStyles();
  const [plateLibraryDialog, openPlateLibraryDialog] = useDialog(PlateLibraryDialog);
  const snackbarManager = useSnackbarManager();

  const handleSelectPlateType = useCallback(async () => {
    logEvent('set-plate-type-from-plate-viz', ScreenRegistry.CHERRY_PICKER);
    const plateType = await openPlateLibraryDialog({});

    if (typeof plateType === 'string') {
      const plate = plates[plateType];

      const error = isPlateBigEnough(plate, plateName, plateMinSizeByName);
      // Prevent selecting the plate if we know it won't simulate
      if (error) {
        logEvent('selected-plate-too-small', ScreenRegistry.CHERRY_PICKER);
        snackbarManager.showWarning(error);
        return;
      }

      setPlatesByName(prev => ({
        ...prev,
        [plateName]: plate,
      }));
    }
  }, [
    openPlateLibraryDialog,
    plateMinSizeByName,
    plateName,
    plates,
    setPlatesByName,
    snackbarManager,
  ]);

  return (
    <>
      {!compact ? (
        // Inline style to override the `:last-child` padding if the last child is
        // a TypeSelector
        <div className={classes.placeholder} style={{ padding: '8rem' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSelectPlateType}
            startIcon={<AppIcon iconId="antha:plate" />}
          >
            Plate Type
          </Button>
        </div>
      ) : (
        <Tooltip title="Select a plate from the inventory">
          <IconButton
            onClick={handleSelectPlateType}
            color="primary"
            disabled={isDisabled}
            size="large"
          >
            <AppIcon iconId="antha:plate" />
          </IconButton>
        </Tooltip>
      )}
      {plateLibraryDialog}
    </>
  );
});

const useStyles = makeStylesHook({
  placeholder: {
    border: '1px solid black',
    alignSelf: 'center',
  },
});
