import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export const ReleaseQualityClientSpecificIcon = React.memo(
  function ReleaseQualityClientSpecificIcon(props) {
    return (
      <SvgIcon viewBox="0 0 24 24" {...props}>
        <svg width="24" height="24" fill="none">
          <path
            d="M9.17 6l2 2H20v10H4V6h5.17zM10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"
            fill="currentColor"
          />
          <path
            d="M14.406 14.074a2.088 2.088 0 01-.32 1.051c-.195.302-.47.537-.824.703-.352.167-.754.25-1.207.25-.748 0-1.336-.243-1.766-.73-.43-.487-.644-1.175-.644-2.063v-.281c0-.557.096-1.044.289-1.461.195-.42.475-.742.84-.969.364-.229.786-.344 1.265-.344.69 0 1.245.183 1.664.547.42.362.658.862.715 1.5h-1.367c-.01-.346-.098-.595-.262-.746-.164-.15-.414-.226-.75-.226-.341 0-.591.127-.75.383-.159.255-.242.662-.25 1.222v.402c0 .607.076 1.04.227 1.301.153.26.416.39.789.39.315 0 .556-.073.722-.222.167-.148.256-.384.266-.707h1.363z"
            fill="currentColor"
          />
        </svg>
      </SvgIcon>
    );
  },
);
