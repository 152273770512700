import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { styled } from '@mui/material/styles';

import { MUTATION_RESTORE_SHARED_WORKFLOW } from 'client/app/api/gql/mutations';
import { QUERY_ALL_DEVICES } from 'client/app/api/gql/queries';
import { ArrayElement, IncomingWorkflowsQuery } from 'client/app/gql';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { workflowRoutes } from 'client/app/lib/nav/actions';
import getWorkflowPropsBySource from 'client/app/lib/workflow/getWorkflowPropsBySource';
import { EntityCard } from 'common/ui/components/EntityCard';
import EntityCardAction from 'common/ui/components/EntityCardAction';
import IconButton from 'common/ui/components/IconButton';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

type SharedWorkflow = ArrayElement<IncomingWorkflowsQuery['sharedWorkflows']>;

type IncomingWorkflowCardProps = {
  sharedWorkflow: SharedWorkflow;
};

/**
 * An incoming workflow is a workflow that has been shared from one organisation to
 * another. This is used for sharing workflows to and from Synthace during user support.
 *
 * 1. A user needs help with a failing workflow and presses 'Request help' from the
 *    simulation to share the workflow with Synthace.
 *
 * 2. Any user in the Synthace org can access the workflow from the list of Incoming
 *    Workflows. Clicking on the workflow copies it to their drafts and opens it in the
 *    builder. Devices are also copied to ensure the workflow can be simulated.
 *
 * 3. The Synthace user can make changes, simulate, and share the workflow back to the
 *    other organisation.
 *
 * 4. The original user can access the workflow in their Incoming Workflows list. Clicking
 *    on the workflow copies to the draft and opens it. Unlike the Synthace org, the
 *    devices are not copied to prevent adding unintuitively named copies to their device
 *    list.
 */
export default function IncomingWorkflowCard({
  sharedWorkflow,
}: IncomingWorkflowCardProps) {
  const userProfile = useUserProfile();
  const isSupport = userProfile?.isSupport || false;

  const {
    id,
    sourceWorkflow: { name, source },
    sourceOrg: { name: organisationName },
    sourceUser: { displayName: author },
    createdAt,
  } = sharedWorkflow;

  const workflowProps = getWorkflowPropsBySource(source);
  const EditorIcon = workflowProps.EditorIcon;

  const { navigate } = useNavigation();
  const snackbar = useSnackbarManager();
  const [restoreSharedWorkflowMutation, { loading }] = useMutation(
    MUTATION_RESTORE_SHARED_WORKFLOW,
    {
      onError: e => {
        console.error(e.message);
        snackbar.showError(
          'Sorry. We are having issues with copying the shared workflow.',
        );
      },
    },
  );

  const createSharedWorkflowCopy = useCallback(async () => {
    try {
      const copyWithDevices = isSupport;
      const result = await restoreSharedWorkflowMutation({
        variables: {
          sharedWorkflowId: id,
          createDevices: copyWithDevices,
        },
        refetchQueries: copyWithDevices ? [{ query: QUERY_ALL_DEVICES }] : [], // get the list of devices if some have been created, to update the cache
        awaitRefetchQueries: true,
      });
      if (result) {
        const newWorkflowId = result.data?.restoreWorkflow.workflowId as SharedWorkflowId;
        if (!newWorkflowId) {
          throw new Error(`Could not restore shared workflow: ${id}`);
        }
        navigate(workflowRoutes.openInWorkflowBuilder, {
          workflowId: newWorkflowId,
        });
      }
    } catch (e) {
      console.error(e);
      snackbar.showError('Failed to import workflow');
    }
  }, [id, isSupport, navigate, restoreSharedWorkflowMutation, snackbar]);

  const nameColumn = { label: 'Workflow name', value: name };
  const authorColumn = { label: 'Sent by', value: author };
  const dateColumn = { label: 'Time received', value: new Date(createdAt) };
  const additionalColumn = isSupport
    ? {
        label: 'Organisation',
        value: organisationName,
      }
    : undefined;

  const interaction = { onClick: !loading ? createSharedWorkflowCopy : undefined };

  return (
    <EntityCard
      icon={<EditorIcon fontSize="small" />}
      nameColumn={nameColumn}
      authorColumn={authorColumn}
      dateColumn={dateColumn}
      additionalColumn={additionalColumn}
      interaction={interaction}
      rightSlot={
        <EntityCardAction
          action={
            <Button
              size="xsmall"
              icon={<ArchiveOutlinedIcon fontSize="small" />}
              disabled={loading}
              onClick={createSharedWorkflowCopy}
            />
          }
          tooltipText="Open request"
        />
      }
    />
  );
}

const Button = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
