import React from 'react';

import MuiDialogActions, {
  DialogActionsProps as MuiDialogActionsProps,
} from '@mui/material/DialogActions';
import cx from 'classnames';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = { className?: string } & MuiDialogActionsProps;

export default function DialogActions(props: Props) {
  const classes = useStyles();

  return (
    <MuiDialogActions {...props} className={cx(props.className, classes.dialogActions)} />
  );
}

const useStyles = makeStylesHook(theme => ({
  dialogActions: {
    backgroundColor: Colors.GREY_5,
    height: '56px',
    padding: theme.spacing(0, 3, 0, 6),
  },
}));
