import React from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import doNothing from 'common/lib/doNothing';
import Colors from 'common/ui/Colors';
import IconButtonWithPopper from 'common/ui/components/IconButtonWithPopper';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type SettingsPanelContainerProps = {
  /** Content of the container. */
  children: React.ReactNode;
  /** Title of the settings which the container related to. */
  title: string;
  /** Info to show for the help icon. */
  helpContent: string;
  /** A link that shows more information for the help icon (e.g Intercom Help center article) */
  additionalHelpLinkUrl?: string;
};

/**
 * SettingsPanelContainer is used in the SettingsPanel to provide a consistent stylized container
 * component in which to display content related to a specific setting.
 */
export default function SettingsPanelContainer(props: SettingsPanelContainerProps) {
  const classes = useStyles();
  const { additionalHelpLinkUrl, children, helpContent, title } = props;
  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.headerText} variant="subtitle2">
          {title}
        </Typography>
        <IconButtonWithPopper
          content={
            <Typography variant="caption">
              {helpContent}{' '}
              {additionalHelpLinkUrl && (
                <>
                  To learn more, click{' '}
                  <Link
                    href={additionalHelpLinkUrl}
                    target="_blank"
                    rel="noopenner noreferrer"
                    variant="caption"
                    underline="hover"
                  >
                    here
                  </Link>
                  .
                </>
              )}
            </Typography>
          }
          iconButtonProps={{ icon: <HelpOutlineIcon />, size: 'xsmall' }}
          onClick={doNothing} //TODO: Update with logging
        />
      </div>
      <div className={classes.container}> {children}</div>
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  container: {
    border: `1px solid ${Colors.BLUE_5}`,
    borderRadius: '0 0 4px 4px',
    padding: theme.spacing(3),
    '&:not(:last-child)': { marginBottom: theme.spacing(6) },
  },
  header: {
    alignItems: 'center',
    border: `1px solid ${Colors.BLUE_5}`,
    borderBottom: 'none',
    borderTopRightRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3),
  },
  headerText: {
    color: Colors.BLUE_100,
  },
  icon: {
    color: Colors.TEXT_PRIMARY,
  },
}));
