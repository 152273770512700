import { useQuery } from '@apollo/client';

import { QUERY_ALL_DEVICES } from 'client/app/api/gql/queries';

export function useDevices() {
  const devicesQueryResult = useQuery(QUERY_ALL_DEVICES, {
    fetchPolicy: 'network-only',
  });
  return devicesQueryResult;
}
