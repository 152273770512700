const PREFIX_SUFFIX_RE = /^(.)(.*)$/m;

export type Sequence = { description: string; sequence: string };
export function isSequence(
  maybeSequence: Sequence | boolean | undefined | null,
): maybeSequence is Sequence {
  return maybeSequence != null && maybeSequence !== true;
}

function readFileAsString(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = e => reject(e);
    reader.onload = () => {
      if (!reader.result) {
        reject('No Content');
      } else {
        resolve(reader.result as string);
      }
    };
    reader.readAsText(file);
  });
}
export default async function parseFasta(
  file: File,
): Promise<(Sequence | boolean)[] | false> {
  const fileStr = await readFileAsString(file);

  return new FastaParser(fileStr).parse();
}

// Copy fastaed from the legacy antha-fasta-file-parser.js
class FastaParser {
  fileContent: string;
  _pc: number;
  constructor(file: string) {
    this.fileContent = file;
    this._pc = 0;
  }

  /**
   * Parse file into sequence objects.
   *
   * @returns {Array} Array of sequence objects.
   */
  parse() {
    const sequences = [];
    let sequence;
    this._pc = 0;

    do {
      sequence = this._readSequence();
      if (sequence) {
        sequences.push(sequence);
      }
    } while (sequence);

    if (sequences.length === 0) {
      return false;
    }

    return sequences;
  }

  /**
   * Reads a sequence from the current program counter position in the file.
   *
   * @returns {Object|Boolean} A sequence object or false if not present.
   */
  _readSequence() {
    const defLine = this._readDefLine();
    if (!defLine) {
      return false;
    }

    // Ignore comments
    let commentLine;
    do {
      commentLine = this._readCommentLine();
    } while (commentLine);

    const data = this._readSequenceData();
    if (!data) {
      return false;
    }

    return {
      description: defLine,
      sequence: data,
    } as Sequence;
  }

  /**
   * Reads a "defline" from the current program counter position in the file.
   *
   * @returns {Object|Boolean} A defline object or false if not present.
   */
  _readDefLine() {
    const line = this.fileContent.substr(this._pc).match(PREFIX_SUFFIX_RE);
    if (!line) {
      return false;
    }

    const [, prefix, suffix] = line;
    if (prefix !== '>' && prefix !== ';') {
      return false;
    }

    this._pc += suffix.length + 2;
    return suffix;
  }

  /**
   * Reads a comment line from the current program counter position in the file.
   *
   * @returns {Object|Boolean} A defline object or false if not present.
   */
  _readCommentLine() {
    const line = this.fileContent.substr(this._pc).match(PREFIX_SUFFIX_RE);
    if (!line) {
      return false;
    }

    const [, prefix, suffix] = line;
    if (prefix !== ';') {
      return false;
    }

    this._pc += suffix.length + 2;
    return suffix;
  }

  /**
   * Reads sequence data from the current program counter position in the file.
   *
   * @returns {String|Boolean} Sequence data or false if not present.
   */
  _readSequenceData() {
    const lines = [];
    let line;

    do {
      line = this._readSequenceDataLine();
      if (line) {
        lines.push(line);
      }
    } while (line);

    if (lines.length === 0) {
      return false;
    }

    return lines.join('');
  }

  /**
   * Reads a line of sequence data from the current program counter position in
   * the file.
   *
   * @returns {Object|Boolean} Sequence data or false if not present.
   */
  _readSequenceDataLine() {
    const match = this.fileContent.substr(this._pc).match(PREFIX_SUFFIX_RE);

    if (!match) {
      return false;
    }

    const [line, prefix] = match;
    if (prefix === '>' || prefix === ';') {
      return false;
    }

    this._pc += line.length + 1;
    return line;
  }
}
