import React, { useCallback, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShareIcon from '@mui/icons-material/Share';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import {
  MUTATION_COPY_TEMPLATE_WORKFLOW,
  MUTATION_DELETE_TEMPLATE_WORKFLOW,
} from 'client/app/api/gql/mutations';
import {
  activeStyle,
  clickableStyle,
  hoverStyle,
} from 'client/app/apps/experiments/commonExperimentsStyles';
import { ArrayElement, TemplateWorkflowsQuery } from 'client/app/gql';
import { templateWorkflowRoutes } from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import { formatDateTime } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import IconButton from 'common/ui/components/IconButton';
import IconWithPopover from 'common/ui/components/IconWithPopover';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import { EditIcon } from 'common/ui/icons/Edit';

type GraphQLTemplateWorkflow = ArrayElement<
  TemplateWorkflowsQuery['templateWorkflows']['items']
>;

type Props = {
  templateWorkflow: GraphQLTemplateWorkflow;
  onDeleteCompleted: () => void;
  closeDialog: () => void;
};

export default React.memo(function TemplateWorkflowCard(props: Props) {
  const { templateWorkflow, onDeleteCompleted, closeDialog } = props;
  const snackbarManager = useSnackbarManager();
  const navigation = useNavigation();
  const classes = useStyles();

  // Used to copy to clipboard the "share" link, which creates a form.
  const shareLinkTextareaRef = useRef<HTMLTextAreaElement | null>(null);

  const [copyTemplate, { error }] = useMutation(MUTATION_COPY_TEMPLATE_WORKFLOW, {
    variables: {
      templateWorkflowName: templateWorkflow.name,
      workflowId: templateWorkflow.workflow.id,
    },
  });

  const [deleteDialog, openDeleteDialog] = useDialog(ConfirmationDialog);
  const [deleteMutation] = useMutation(MUTATION_DELETE_TEMPLATE_WORKFLOW);
  const [isUserDeletingWorkflow, setUserDeleteTemplateWorkflow] = useState(false);
  const onDeleteClick = useCallback(
    async (event: React.MouseEvent) => {
      // Prevent redirect if the user is trying to delete this particular template workflow.
      event.preventDefault();
      event.stopPropagation();
      setUserDeleteTemplateWorkflow(true);
      const isConfirmed = await openDeleteDialog({
        action: 'delete',
        isActionDestructive: true,
        object: 'template',
        specificObject: templateWorkflow.name,
      });

      logEvent(
        'delete-template-workflow',
        ScreenRegistry.EXPERIMENTS,
        isConfirmed ? 'yes' : 'no',
      );

      if (isConfirmed) {
        deleteMutation({
          variables: { id: templateWorkflow.id },
        })
          .then(onDeleteCompleted)
          .catch(e => snackbarManager.showError(e.message));
      }
    },
    [
      deleteMutation,
      onDeleteCompleted,
      openDeleteDialog,
      snackbarManager,
      templateWorkflow.id,
      templateWorkflow.name,
    ],
  );

  const handleClick = useCallback(() => {
    if (!isUserDeletingWorkflow) {
      closeDialog();
      logEvent('create-new-workflow', ScreenRegistry.EXPERIMENTS, 'from-template');
      navigation.navigate(templateWorkflowRoutes.createForm, {
        id: templateWorkflow.id,
      });
    }
  }, [isUserDeletingWorkflow, closeDialog, navigation, templateWorkflow.id]);

  const handleEdit = useCallback(
    (event: React.MouseEvent) => {
      logEvent('edit-template-workflow', ScreenRegistry.EXPERIMENTS);
      event.preventDefault();
      event.stopPropagation();
      closeDialog();
      navigation.navigate(templateWorkflowRoutes.editTemplate, {
        id: templateWorkflow.id,
      });
    },
    [navigation, closeDialog, templateWorkflow.id],
  );

  const handleCopyShareLink = useCallback(
    (event: React.MouseEvent) => {
      logEvent('copy-share-link-for-template-workflow', ScreenRegistry.EXPERIMENTS);
      event.preventDefault();
      event.stopPropagation();

      if (shareLinkTextareaRef.current) {
        // Unhide the textarea before copying the text to make it work.
        shareLinkTextareaRef.current.hidden = false;
        const partialLinkToCreateForm = templateWorkflowRoutes.createForm.getPath({
          id: templateWorkflow.id,
        });
        shareLinkTextareaRef.current.innerHTML = `${window.location.origin}/#${partialLinkToCreateForm}`;
        shareLinkTextareaRef.current.select();
        document.execCommand('copy');
        shareLinkTextareaRef.current.hidden = true;
        snackbarManager.showInfo('Link has been copied.');
      }
    },
    [snackbarManager, templateWorkflow.id],
  );

  const handleCopyTemplate = useCallback(
    async (event: React.MouseEvent) => {
      logEvent('copy-template-workflow', ScreenRegistry.EXPERIMENTS);
      event.preventDefault();
      event.stopPropagation();
      closeDialog();
      const { data } = await copyTemplate();
      if (error || !data) {
        snackbarManager.showError('Could not create a copy.');
        return;
      }

      navigation.navigate(templateWorkflowRoutes.editTemplate, {
        id: data.copyTemplateWorkflow.id,
      });
    },
    [closeDialog, copyTemplate, error, navigation, snackbarManager],
  );

  return (
    <Card variant="outlined" className={classes.card} onClick={handleClick}>
      <div className={classes.title}>
        <Typography variant="h4" className={classes.titleText}>
          {templateWorkflow.name}
        </Typography>
        <IconWithPopover
          icon={<InfoOutlinedIcon />}
          onHover={logPopoverOpen}
          popoverContent={
            <AdditionalInfoPopoverContent templateWorkflow={templateWorkflow} />
          }
        />
      </div>
      <Divider />

      <div className={classes.actions}>
        <div>
          {/* Adding spaces to "Share" to prevent AdBlock from
            removing the share button. */}
          <Tooltip title="&nbsp;Share&nbsp;">
            <IconButton
              icon={<ShareIcon />}
              size="medium"
              onClick={handleCopyShareLink}
            />
          </Tooltip>
          <Tooltip title="Create a copy">
            <IconButton icon={<CopyIcon />} size="medium" onClick={handleCopyTemplate} />
          </Tooltip>
          {templateWorkflow.isEditable ? (
            <>
              <Tooltip title="Edit">
                <IconButton icon={<EditIcon />} size="medium" onClick={handleEdit} />
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  icon={<DeleteOutlineIcon />}
                  onClick={onDeleteClick}
                  size="medium"
                />
              </Tooltip>
            </>
          ) : null}
        </div>
      </div>
      {deleteDialog}
      <textarea ref={shareLinkTextareaRef} hidden readOnly />
    </Card>
  );
});

function AdditionalInfoPopoverContent({
  templateWorkflow,
}: {
  templateWorkflow: GraphQLTemplateWorkflow;
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.group}>
        <Typography variant="overline" gutterBottom>
          Author
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {templateWorkflow.createdBy.displayName}
        </Typography>
      </div>

      <div className={classes.group}>
        <Typography variant="overline" gutterBottom>
          Created at
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {formatDateTime(new Date(templateWorkflow.createdAt))}
        </Typography>
      </div>

      {templateWorkflow.description && (
        <div className={classes.group}>
          <Typography variant="overline" gutterBottom>
            Description
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {templateWorkflow.description}
          </Typography>
        </div>
      )}
    </>
  );
}

function logPopoverOpen() {
  logEvent('hoverover-info-template-workflow', ScreenRegistry.EXPERIMENTS);
}

const useStyles = makeStylesHook(theme => ({
  actions: {
    display: 'flex',
    padding: theme.spacing(3, 5, 3, 5),
    alignItems: 'center',
  },
  card: {
    borderColor: Colors.GREY_30,
    borderRadius: '8px',
    display: 'grid',
    gridTemplateRows: '[title] 1.5fr [divider] 1px [action] 1fr',
    width: '288px',
    textAlign: 'left',
    ...hoverStyle(theme).background,
    '&:active': {
      ...activeStyle.style,
    },
  },
  clickable: clickableStyle.style,
  group: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  popover: {
    borderRadius: '8px',
    width: '248px',
  },
  popoverContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5, 5, 3, 5),
  },
  title: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    padding: theme.spacing(5),
  },
  titleText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    minHeight: '4em',
    width: '220px',
    textOverflow: 'ellipsis',
    marginLeft: theme.spacing(3),
  },
}));
