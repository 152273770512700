import React from 'react';

import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/**
 * Displays a "LID" label in the bottom right of the deck position geometry. This should
 * be rendered after plates such that it appears on top of the plate.
 */
export default function LidView() {
  const classes = useStyles();
  return (
    <div className={classes.lid}>
      <Typography variant="h5" className={classes.label}>
        LID
      </Typography>
    </div>
  );
}

const useStyles = makeStylesHook({
  lid: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  label: {
    // The label is position at bottom right of the lid and rotated 45deg anti-clockwise
    position: 'absolute',
    right: '16px',
    bottom: '16px',
    // Width is arbitrary, just needs to be long enough to flow off the edges of the plate
    width: '100px',
    transform: 'translate(50%, 50%) rotateZ(-45deg)',
    background: Colors.YELLOW,
    textAlign: 'center',
    fontWeight: 'bold',
  },
});
