import React, { useCallback, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import LinearProgress from '@mui/material/LinearProgress';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import memoisedBind from 'memoize-bind';

import { QUERY_EXECUTION_FILES } from 'client/app/api/gql/queries';
import { NoEntitiesMessage } from 'client/app/apps/experiments/NoEntitiesMessage';
import { getFileDisplayName } from 'client/app/apps/simulation-details/overview/results/usePlates';
import FileIcon from 'client/app/components/FileBrowser/FileIcon';
import { ExecutionFilesQuery, LabwareHistoryAnalyticProperties } from 'client/app/gql';
import { groupBy, isDefined } from 'common/lib/data';
import { basename, byName } from 'common/lib/strings';
import { FileObject } from 'common/types/fileParameter';
import Colors from 'common/ui/Colors';
import CardGrid from 'common/ui/components/CardGrid';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import DialogActions from 'common/ui/components/Dialog/DialogActions';
import SelectionActions from 'common/ui/components/Dialog/SelectionActions';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

type ExecutionFileSelectorDialogContainerProps = {
  value: FileObject | null;
  onChange: (value: FileObject | null) => void;
  executionId: ExecutionId;
  onBackClick?: () => void;
  dialogClassName?: string;
} & DialogProps<boolean>;

export default React.memo(function ExecutionFileSelectorDialogContainer(
  props: ExecutionFileSelectorDialogContainerProps,
) {
  const { isOpen, onClose, onChange, executionId, onBackClick, dialogClassName } = props;
  const classes = useStyles();

  const [value, setValue] = useState<FileObject | null>(props.value);

  const {
    loading: isExecutionLoading,
    data,
    error,
    refetch,
  } = useQuery(QUERY_EXECUTION_FILES, {
    variables: {
      id: executionId,
    },
  });
  const execution = data?.execution;

  const handleSelect = useCallback(() => {
    // Save the value and close the dialog when selecting.
    onChange(value);
    onClose(true);
  }, [onChange, onClose, value]);

  const handleCancel = useCallback(() => {
    // On cancel, reset the value back to whatever was initially passed in
    // so that when the dialog closes, it will fire the onChange
    // callback with that same value
    setValue(props.value);
    onClose(false);
  }, [onClose, props.value]);

  const handleSelectFile = useCallback((newFile: FileObject) => {
    setValue(newFile);
  }, []);

  if (error) {
    return <GraphQLErrorPanel error={error} onRetry={refetch} />;
  }

  return (
    <ComplexActionDialog
      isOpen={isOpen}
      onClose={handleCancel}
      title={`Select file from "${execution ? execution.simulation.name : 'execution'}"`}
      onBackClick={onBackClick}
      className={dialogClassName}
      content={
        isExecutionLoading || !execution ? (
          <div className={classes.dialogContent}>
            <LinearProgress />
          </div>
        ) : (
          <ExecutionFileSelectorDialog
            execution={execution}
            selectedFile={value}
            onSelectFile={handleSelectFile}
          />
        )
      }
      dialogActions={
        <DialogActions>
          <SelectionActions
            itemLabel="file"
            primaryActionLabel="Select"
            handleCancel={handleCancel}
            handleConfirm={handleSelect}
          />
        </DialogActions>
      }
    />
  );
});

type ExecutionFileSelectorDialogProps = {
  execution: ExecutionFilesQuery['execution'];
  selectedFile: FileObject | null;
  onSelectFile: (file: FileObject) => void;
};
function ExecutionFileSelectorDialog(props: ExecutionFileSelectorDialogProps) {
  const { execution, selectedFile, onSelectFile } = props;
  const classes = useStyles();

  const platesByName = useMemo(() => {
    // We selected an execution in the previous dialog, so we are sure the
    // execution exists.
    const labwareHistoriesByName = groupBy(execution.labwareHistory, 'name');
    const platesByName = new Map<
      string,
      {
        name: string;
        files: FileObject[];
      }
    >();

    for (const labwareHistories of Object.values(labwareHistoriesByName)) {
      // All labwareHistories refer to the same labware, so we can take the first one.
      const firstLabwareHistory = labwareHistories[0];
      for (const lh of labwareHistories) {
        // There may be duplicates among the origin files if a single origin file produced multiple
        // parsed files, and we don't want to show them twice
        const displayedOriginFiles = new Set<string>();
        const files: FileObject[] = lh.datasets
          .map(ds => {
            if (displayedOriginFiles.has(ds.originFile.originFiletreeLink)) {
              return undefined;
            }
            displayedOriginFiles.add(ds.originFile.originFiletreeLink);

            const displayName = getFileDisplayName(
              lh.historyType,
              (lh.properties as LabwareHistoryAnalyticProperties).kind,
              ds.originFile.originFiletreeLink,
              ds.deviceName,
            );
            return {
              Name: displayName,
              Path: ds.originFile.originFiletreeLink,
            };
          })
          .filter(isDefined);

        if (!files || files.length === 0) {
          continue;
        }

        const isPlateAlreadyInMap = platesByName.has(lh.name);
        if (!isPlateAlreadyInMap) {
          platesByName.set(firstLabwareHistory.name, {
            name: firstLabwareHistory.name,
            files: [],
          });
        }
        const plate = platesByName.get(lh.name)!;
        plate.files = plate.files.concat(files);
      }
    }

    return platesByName;
  }, [execution]);

  const platesWithFiles = useMemo(() => {
    return platesByName ? [...platesByName.values()].sort(byName) : [];
  }, [platesByName]);

  return (
    <div className={classes.dialogContent}>
      <CardGrid>
        {platesWithFiles.length > 0 ? (
          platesWithFiles.map(plate => (
            <div key={plate.name} className={classes.fileCard}>
              <Typography variant="subtitle2" color="textPrimary" gutterBottom>
                {plate.name}
              </Typography>
              <div>
                {plate.files.map(file => {
                  const fileObject = {
                    Name: basename(file.Path),
                    Path: file.Path,
                  };
                  return (
                    <div key={fileObject.Path} className={classes.fileListItem}>
                      <div className={classes.fileMetadata}>
                        <FileIcon file={{ name: fileObject.Name }} />
                        <Typography variant="body1" color="textPrimary">
                          {fileObject.Name}
                        </Typography>
                      </div>
                      <div className={classes.radioBtnContainer}>
                        <Radio
                          checked={selectedFile?.Path === fileObject.Path}
                          onChange={memoisedBind(onSelectFile, null, fileObject)}
                          className={classes.radioBtn}
                          size="small"
                          color="primary"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))
        ) : (
          <NoEntitiesMessage entityName="files" />
        )}
      </CardGrid>
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  dialogContent: {
    height: '85vh',
    overflowY: 'scroll',
  },
  fileListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
  },
  fileMetadata: {
    display: 'flex',
    alignItems: 'end',
    wordBreak: 'break-all',
  },
  radioBtnContainer: {
    display: 'flex',
    alignItems: 'end',
  },
  radioBtn: {
    padding: 0,
    marginLeft: theme.spacing(3),
  },
  fileCard: {
    backgroundColor: Colors.GREY_10,
    borderRadius: '4px',
    width: '300px',
    height: 'fit-content',
    margin: theme.spacing(3, 3, 0, 0),
    padding: theme.spacing(3),
  },
}));
