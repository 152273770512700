import React from 'react';

import SchoolIcon from '@mui/icons-material/School';
import { styled } from '@mui/material/styles';

import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import { ExperimentsQuery } from 'client/app/gql';
import { experimentsRoutes } from 'client/app/lib/nav/actions';
import { EntityCard } from 'common/ui/components/EntityCard';

const TutorialsListDataComponent = ({ data }: { data: ExperimentsQuery }) => {
  const experiments = data.experiments?.items || [];

  return (
    <List>
      {experiments.map(experiment => {
        const nameColumn = { label: 'Tutorial name', value: experiment.name };
        const interaction = {
          to: experimentsRoutes.detail.getPath({ id: experiment.id }),
        };

        return (
          <EntityCard
            key={experiment.id}
            heapTrackingLabel="TUTORIAL"
            icon={<TutorialIcon />}
            nameColumn={nameColumn}
            interaction={interaction}
          />
        );
      })}
    </List>
  );
};

const List = styled('div')(({ theme }) => ({
  ...experimentsStyles(theme).list,
}));

const TutorialIcon = styled(SchoolIcon)(() => ({
  width: 18,
}));

export default TutorialsListDataComponent;
