import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { MUTATION_SHARE_WORKFLOW_WITH_ORG } from 'client/app/api/gql/mutations';
import ShareWithOrganisationsDialog from 'client/app/apps/workflow-builder/dialogs/ShareWithOrganisationsDialog';
import FavoriteStar from 'client/app/components/FavoriteStar';
import { EditableSimulationName } from 'client/app/components/SimulationNotification/EditableSimulationName';
import UILeftRight from 'client/app/components/UILeftRight';
import useRequestHelpForSimulation from 'client/app/hooks/useRequestHelpForSimulation';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { simulationRoutes } from 'client/app/lib/nav/actions';
import sanitizeLink from 'client/app/lib/sanitizeLink';
import { formatSimulationErrorAsString } from 'client/app/lib/workflow/format';
import {
  SimulationNotificationDetails,
  SimulationStatus,
} from 'client/app/lib/workflow/types';
import useElementConfigs from 'client/app/lib/workflow/useElementConfigs';
import { ScreenRegistry } from 'client/app/registry';
import RouteButton from 'common/ui/components/navigation/RouteButton';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

type Props = {
  elementSetID: string;
  simulation: SimulationNotificationDetails;
  onDismissNotification: (simulationToDismiss: SimulationNotificationDetails) => void;
};

function LegacySimulationCard(props: Props) {
  const { simulation, onDismissNotification } = props;
  const classes = useStyles();
  const { requestHelpDialog, handleRequestHelp } = useRequestHelpForSimulation(
    ScreenRegistry.WORKFLOW,
    simulation.simulationId,
    simulation.name,
  );

  const [shareWorkflowDialog, openShareWorkflowDialog] = useDialog(
    ShareWithOrganisationsDialog,
  );

  const [shareWorkflow] = useMutation(MUTATION_SHARE_WORKFLOW_WITH_ORG);
  const snack = useSnackbarManager();
  const { getAbsoluteURL } = useNavigation();
  const simulationDetailsUrl = simulation.simulationId
    ? `${getAbsoluteURL(simulationRoutes.openInSimulationDetails, {
        simulationId: simulation.simulationId,
      })}`
    : '';

  const handleDismissNotification = useCallback(() => {
    onDismissNotification(simulation);
  }, [onDismissNotification, simulation]);

  const handleShareWorkflow = useCallback(async () => {
    if (!simulation.workflowId) {
      // This sould be unreachable; we only allow sharing if simulation is a success, and a successfull simulation has snapshot for sure.
      return;
    }
    const orgsToShareWith = await openShareWorkflowDialog({
      workflowId: simulation.workflowId as WorkflowId,
    });
    if (orgsToShareWith) {
      const { errors } = await shareWorkflow({
        variables: {
          simulationId: simulation.simulationId!,
          destOrgName: orgsToShareWith.humanIdentifier,
          message: 'shared from Synthace',
        },
        // @ts-ignore This is valid option. TODO update types def when fix is available
        errorPolicy: 'all',
      });
      if (errors) {
        snack.showError(`Failed to share with ${orgsToShareWith.name}`);
      } else {
        snack.showSuccess(`Shared with ${orgsToShareWith.name}`);
      }
    }
  }, [
    openShareWorkflowDialog,
    shareWorkflow,
    simulation.simulationId,
    simulation.workflowId,
    snack,
  ]);

  return (
    <>
      <Card className={classes.container}>
        <UILeftRight>
          <div className={classes.titleText}>
            <Typography variant="caption">Simulation</Typography>
            <FiberManualRecordIcon fontSize="small" className={classes.icon} />
            <Typography variant="caption">{simulation.startTime}</Typography>
            {simulation.status === 'SUCCESS' && (
              // Default to showing not favorited since this simulation is new.
              <FavoriteStar
                isFavoritedByCurrentUser={simulation.isFavoritedByCurrentUser ?? false}
                favoritedBy={simulation.favoritedBy ?? []}
                simulationId={simulation.simulationId! as SimulationId}
                size="xsmall"
              />
            )}
          </div>
          <div className={classes.titleText}>
            <div className={classes.statusText}>
              <Status status={simulation.status} />
            </div>

            <IconButton
              className={classes.iconButton}
              color="primary"
              onClick={handleDismissNotification}
              size="large"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        </UILeftRight>
        <EditableSimulationName simulation={simulation} />
        <SimulationActions
          simulation={simulation}
          elementSetID={props.elementSetID}
          handleRequestHelp={handleRequestHelp}
          handleShareWorkflow={handleShareWorkflow}
          simulationDetailsUrl={simulationDetailsUrl}
        />
      </Card>
      {requestHelpDialog}
      {shareWorkflowDialog}
    </>
  );
}

/**
 * Display a different indicator depending on the status.
 */
function Status({ status }: { status: SimulationStatus }) {
  switch (status) {
    case 'IN_PROGRESS':
      return <CircularProgress variant="indeterminate" size={16} />;
    case 'SUCCESS':
      return <Typography variant="caption"> Success </Typography>;
    case 'ERROR':
      return (
        <Typography variant="caption" color="error">
          Failed
        </Typography>
      );
    default:
      return <Typography variant="caption"> Starting...</Typography>;
  }
}

function ShowDebugTraceLinks(props: { logsUrl: string; traceUrl: string }) {
  const classes = useStyles();
  const { logsUrl, traceUrl } = props;
  return (
    <>
      <Link
        href={sanitizeLink(logsUrl)}
        target="_blank"
        className={classes.link}
        variant="caption"
        rel="noopener noreferrer"
        underline="hover"
      >
        Logs
      </Link>

      <Link
        href={sanitizeLink(traceUrl)}
        target="_blank"
        className={classes.link}
        variant="caption"
        rel="noopener noreferrer"
        underline="hover"
      >
        Trace
      </Link>
    </>
  );
}

/**
 * Show additional actions that can be taken based on simulation status.
 * If the simulation succeeds, the user can go to the simulation details.
 * If the simulation errors, the card will provide an error message.
 */
function SimulationActions(props: {
  simulation: SimulationNotificationDetails;
  elementSetID: string;
  handleRequestHelp?: () => void;
  handleShareWorkflow: () => void;
  simulationDetailsUrl: string;
}) {
  const classes = useStyles();
  const { elementConfigs } = useElementConfigs(props.elementSetID);
  const { simulation, handleRequestHelp, handleShareWorkflow, simulationDetailsUrl } =
    props;
  // Logs and Trace will only be available internally for Synthace employees.
  const logsUrl = simulation.logsUrl;
  const traceUrl = simulation.traceUrl;
  let leftActions = null;
  let additionalContent = null;
  // Only show request help if the simulation is in a terminal state.
  const showRequestHelp = simulation.status !== 'IN_PROGRESS' && handleRequestHelp;
  const userProfile = useUserProfile();

  if (simulation.status === 'SUCCESS') {
    leftActions = (
      <>
        {simulation.simulationId && (
          <RouteButton
            className={classes.actionButton}
            color="primary"
            label="View"
            route={simulationRoutes.openInSimulationDetails}
            routeParam={{ simulationId: simulation.simulationId }}
          />
        )}
        {userProfile?.isSupport && (
          // Only allow sharing of workflow if in Synthace support org.
          <Button
            className={classes.actionButton}
            color="primary"
            onClick={handleShareWorkflow}
          >
            Share workflow
          </Button>
        )}
      </>
    );
  } else if (simulation.status === 'ERROR') {
    const { simulationId, errors } = simulation;
    additionalContent = (
      <div className={classes.errorContainer}>
        {errors && (
          <div className={classes.errorInfo}>
            {errors.map((error, index) => (
              <div key={`${index}`}>
                <Typography variant="caption">
                  {formatSimulationErrorAsString(error, elementConfigs)}
                </Typography>
              </div>
            ))}
          </div>
        )}
      </div>
    );

    leftActions = simulationId && (
      <Link
        href={sanitizeLink(simulationDetailsUrl)}
        target="_blank"
        className={classes.link}
        variant="caption"
        rel="noopener noreferrer"
        underline="hover"
      >
        Error Details
      </Link>
    );
  }
  return (
    <>
      {additionalContent}
      <UILeftRight>
        {leftActions}
        {logsUrl && traceUrl && (
          <ShowDebugTraceLinks logsUrl={logsUrl} traceUrl={traceUrl} />
        )}
        {showRequestHelp && (
          <Link
            variant="caption"
            onClick={handleRequestHelp}
            className={classes.link}
            underline="hover"
          >
            Request Help
          </Link>
        )}
      </UILeftRight>
    </>
  );
}

const useStyles = makeStylesHook({
  actionButton: {
    minWidth: 'auto',
  },
  container: {
    flexShrink: 0,
    margin: '0',
    width: 'auto',
    padding: '8px',
  },
  debugContainer: {
    alignItems: 'baseline',
    display: 'flex',
  },
  errorContainer: {
    overflowWrap: 'break-word',
    paddingBottom: '8px',
  },
  errorInfo: {
    overflow: 'auto',
    maxHeight: '275px',
  },
  expandClosed: {
    transform: 'rotate(0deg)',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  icon: {
    padding: '6px',
  },
  iconButton: { padding: '4px' },
  link: {
    cursor: 'pointer',
    paddingRight: '12px',
  },
  statusText: {
    padding: '0 8px',
  },
  titleText: {
    alignItems: 'center',
    display: 'flex',
  },
});

export default LegacySimulationCard;
