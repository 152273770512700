import React, { useCallback } from 'react';

import AnnotationDialog from 'client/app/components/ElementPlumber/AnnotationDialog';
import { ElementInstance } from 'common/types/bundle';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import { AnnotateIcon } from 'common/ui/icons/Annotate';
import { AnnotateEmptyIcon } from 'common/ui/icons/AnnotateEmpty';

type Props = {
  elementInstance: ElementInstance;
  onAnnotate: (annotation: string | undefined) => void;
};

export default function AnnotationButton({ elementInstance, onAnnotate }: Props) {
  const classes = useStyles();
  const { annotation } = elementInstance.Meta;

  const [annotationDialog, openAnnotationDialog] = useDialog(AnnotationDialog);

  const handleAnnotate = useCallback(async () => {
    const newAnnotation = await openAnnotationDialog({
      elementInstance,
      value: annotation,
    });
    onAnnotate(newAnnotation);
  }, [annotation, elementInstance, onAnnotate, openAnnotationDialog]);

  return (
    <>
      <div className={classes.iconShim} onPointerDown={handleAnnotate}>
        {annotation ? (
          <AnnotateEmptyIcon className={classes.headerIcon} />
        ) : (
          <AnnotateIcon className={classes.headerIcon} />
        )}
      </div>
      {annotationDialog}
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  iconShim: {
    cursor: 'pointer',
    display: 'flex',
    marginRight: theme.spacing(2),
  },
  headerIcon: {
    height: '14px',
    width: '14px',
  },
}));
