import { useCallback } from 'react';

import {
  ApolloClient,
  InternalRefetchQueryDescriptor,
  MutationOptions,
} from '@apollo/client';
import {
  MutationFunctionOptions,
  MutationHookOptions,
  useMutation,
} from '@apollo/client';
import { MutationBaseOptions } from '@apollo/client/core/watchQueryOptions';
import { DocumentNode, ExecutionResult } from 'graphql';

import { ContentType, WorkflowEditModeEnum, WorkflowSourceEnum } from 'client/app/gql';
import { ServerSideBundle } from 'common/types/bundle';
import { RequiredTypeParam } from 'common/utils';

export type GraphQLWorkflow = {
  id: WorkflowId;
  name: string;
  version: number;
  workflow: ServerSideBundle;
  parentWorkflowID: WorkflowId | null;
  editMode: WorkflowEditModeEnum;
  source: WorkflowSourceEnum;
  createdBy: {
    id: string;
    displayName: string;
  };
  contentSource: ContentType;
};

// Invalidated queries can be specified either as list of graphql documents (created with the gql tag)
// or a function taking the mutation result and returning
// a list of queries (same as apollo's refetchQueries).

type InvalidateQueries<Result> =
  | DocumentNode[]
  | ((result: ExecutionResult<Result>) => InternalRefetchQueryDescriptor[]);

//   Wraps a GrapqhQL mutation so that it can be easily used as a hook or imperative call,
//   with some options prepopulated and enforced input type.

//   Example (defined globally):
//      const [useMyMutation, doMyMutation] = createMutationNew<MyMutationVariables, MyMutation>(
//        gql`
//          mutation MyMutation(...) {
//            ...
//          }
//        `,
//        [SOME_QUERY]
//      );

//   Then you can use it in a component as a hook:
//      const [myMutation, myMutationResult] = useMyMutation();
//      // in a handler:
//      await myMutation({ some variables });

//   Or anywhere by calling it with apollo client:
//     await doMyMutation(getApolloClient(), { some variables });

export function createMutationNew<
  VarsType = RequiredTypeParam<'InputType parameter is required for a mutation'>,
  ResultType = RequiredTypeParam<'ResultType parameter is required for a mutation'>,
>(mutation: DocumentNode, invalidatedQueries?: InvalidateQueries<ResultType>) {
  // If the queries are specified as documents we have to extract the name
  const initialRefetchQueries: RefetchQueriesType<ResultType, VarsType> =
    invalidatedQueries && Array.isArray(invalidatedQueries)
      ? invalidatedQueries.map(getQueryName)
      : invalidatedQueries;

  const useMutationWithVars = (options?: MutationHookOptions<ResultType, VarsType>) => {
    const [mutate, result] = useMutation<ResultType, VarsType>(mutation, {
      ...options,
      refetchQueries: mergeRefetchQueries(initialRefetchQueries, options?.refetchQueries),
    });
    const mutateWithVars = useCallback(
      (variables: VarsType, options?: MutationFunctionOptions<ResultType, VarsType>) => {
        return mutate({
          variables,
          ...options,
        });
      },
      [mutate],
    );
    return [mutateWithVars, result] as const;
  };

  const call = (
    apollo: ApolloClient<unknown>,
    variables: VarsType,
    options?: Omit<MutationOptions<ResultType, VarsType>, 'mutation' | 'variables'>,
  ) => {
    return apollo.mutate<ResultType, VarsType>({
      mutation,
      variables,
      ...options,
      refetchQueries: mergeRefetchQueries(initialRefetchQueries, options?.refetchQueries),
    });
  };

  return [useMutationWithVars, call] as const;
}

// This is coding error, should never be visible to the user.
const INVALID_QUERY_ERROR = 'Invalid query passed to createMutationNew.';

/**
 * Extracts query name from a GraphQL DocumentNode.
 */
function getQueryName(q: DocumentNode) {
  if (q.kind !== 'Document' || q.definitions.length === 0) {
    throw new Error(INVALID_QUERY_ERROR);
  }
  const queryDef = q.definitions[0];
  if (queryDef.kind !== 'OperationDefinition' || !queryDef.name) {
    throw new Error(INVALID_QUERY_ERROR);
  }
  return queryDef.name.value;
}

// Helper alias
type RefetchQueriesType<Result, Vars> = MutationBaseOptions<
  Result,
  Vars
>['refetchQueries'];

/**
 * Merges refetchQueries passed during mutation creation and mutation execution.
 * As each can be a static array or a function returning array (after mutation is executed),
 * we have to handle couple different cases, esp. when merging function.
 */
function mergeRefetchQueries<Result, Vars>(
  initialQueries: RefetchQueriesType<Result, Vars>,
  optionsQueries: RefetchQueriesType<Result, Vars>,
): RefetchQueriesType<Result, Vars> {
  if (!optionsQueries) {
    return initialQueries;
  }

  if (!initialQueries) {
    return optionsQueries;
  }

  if (Array.isArray(initialQueries) && Array.isArray(optionsQueries)) {
    // Easy case, just merge two static arrays
    return [...initialQueries, ...optionsQueries];
  } else {
    // We need to create a new function that will pass the mutation result to each query
    return result => {
      return ([] as InternalRefetchQueryDescriptor[]).concat(
        typeof initialQueries === 'function' ? initialQueries(result) : initialQueries,
        typeof optionsQueries === 'function' ? optionsQueries(result) : optionsQueries,
      );
    };
  }
}
