import React from 'react';

import DatasetIcon from '@mui/icons-material/InsertChartOutlinedOutlined';

import { DatasetFragmentFragment as DatasetFragment } from 'client/app/gql';
import { EntityCard } from 'common/ui/components/EntityCard';

type Props = {
  dataset: DatasetFragment;
  isSelected?: boolean;
  onClick?: () => void;
};

export default function DatasetCard({ dataset, isSelected, onClick }: Props) {
  const { name, createdAt, device } = dataset;
  const deviceName = device?.name ?? 'Stitched';

  const nameColumn = { label: 'Dataset name', value: name };
  const authorColumn = { label: 'Device', value: deviceName };
  const dateColumn = { label: 'Created at', value: new Date(createdAt) };

  const interaction = { onClick };

  return (
    <EntityCard
      icon={<DatasetIcon fontSize="small" />}
      nameColumn={nameColumn}
      authorColumn={authorColumn}
      dateColumn={dateColumn}
      interaction={interaction}
      isSelected={isSelected}
    />
  );
}
