import {
  AdditionalEditorProps,
  ArrayAdditionalProps,
  MapAdditionalProps,
} from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { getArrayTypeFromAnthaType } from 'common/elementConfiguration/parameterUtils';

export default function canSpecifyDefaultValueForEditor(
  editorType: EditorType,
  editorProps?: AdditionalEditorProps,
): boolean {
  /**
   * To make sure editorTypesWithDefaultValues is kept in sync with switch
   * statement in DefaultValueEditor, we return null if the given editor type
   * isn't in there.
   * */
  if (editorType !== EditorType.ARRAY && editorType !== EditorType.MAP) {
    return editorTypesWithDefaultValues.includes(editorType);
  }

  if (!editorProps) {
    throw new Error(
      `EditorProps must be specified for an editor of type ${editorType} but none were found.`,
    );
  }

  /**
   * For compound editors, we recursively check if the items in arrays,
   * or the values in maps, are also compound editor types to check
   * if these can also specify default values.
   * */
  if (editorType === EditorType.ARRAY) {
    const { itemEditor } = editorProps as Required<ArrayAdditionalProps>;
    const itemEditorType = getArrayTypeFromAnthaType(itemEditor.type ?? '') as EditorType;
    return canSpecifyDefaultValueForEditor(
      itemEditorType,
      itemEditor.additionalProps ?? undefined,
    );
  }

  if (editorType === EditorType.MAP) {
    const { keyEditor, valueEditor } = (editorProps as MapAdditionalProps) ?? {};
    if (keyEditor?.type && valueEditor?.type) {
      return (
        canSpecifyDefaultValueForEditor(
          keyEditor.type,
          keyEditor.additionalProps ?? undefined,
        ) &&
        canSpecifyDefaultValueForEditor(
          valueEditor.type,
          valueEditor.additionalProps ?? undefined,
        )
      );
    }
  }
  return false;
}

/**
 * A list of the editor types that can default values associated with
 * the parameters they are used to set.
 */
const editorTypesWithDefaultValues = [
  EditorType.AUTOCOMPLETE,
  EditorType.DROPDOWN,
  EditorType.POLICY,
  EditorType.CHECKBOX,
  EditorType.DOE_MERGE_OPTION,
  EditorType.FILE,
  EditorType.FLOAT,
  EditorType.INT,
  EditorType.LIQUID,
  EditorType.PLATE_TYPE,
  EditorType.STRING,
  EditorType.STRING_ARRAY,
  EditorType.TOGGLE,
  EditorType.TIP_TYPE,
  EditorType.PLATE_READER_SHAKING_TYPE,
  EditorType.MEASUREMENT,
];
