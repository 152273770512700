import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material/';

export default React.forwardRef<SVGSVGElement, SvgIconProps>(function MissingSamplesIcon(
  props,
  ref,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" ref={ref} {...props}>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m1.68 9.5 1-1.73L22.32 19.1l-1 1.73L1.68 9.5ZM18 2H6v2h2v4.54l8 4.61V4h2V2Zm-4 2h-4v3h4V4Z"
          fill="currentColor"
        />
        <path d="m16 15.46-8-4.62V19a4 4 0 0 0 8 0v-3.54Z" fill="currentColor" />
      </svg>
    </SvgIcon>
  );
});
