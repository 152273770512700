import {
  AdditionalEditorProps,
  DropdownAdditionalProps,
} from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { getAdditionalEditorPropsForEditorType } from 'common/elementConfiguration/getEditorTypeInfo';
import { getDefaultEditorForAnthaType } from 'common/elementConfiguration/parameterUtils';
import { ParameterValue } from 'common/types/bundle';
import { DeviceAdvancedOption } from 'common/types/device';

/**
 * Overrides for any DeviceAdvancedOption type.
 */
const deviceAdvancedOptionsEditorTypesOverrides: Record<string, EditorType> = {
  bool: EditorType.TOGGLE,
};

/**
 * Returns the editorType and editorProps information that should be passed to the ParameterEditor
 * to correctly format the given DeviceAdvancedOption, based on the type of the DeviceAdvancedOption.
 * Also optionally returns a function to retrieve the original parameter value from the stringified
 * version used in this function.
 *
 * If the DeviceAdvancedOption contains an options field, we will format these as a Dropdown.
 *
 * If no options field, we will preferentially use any overrides specified in deviceAdvancedOptionsEditorTypesOverrides
 * before getting the default info for the DeviceAdvancedOption type.
 */
export function getEditorInfoForDeviceAdvancedOption(option: DeviceAdvancedOption): {
  editorType: EditorType;
  editorProps: AdditionalEditorProps | undefined;
  getOriginalValue?: (value: ParameterValue) => void;
} {
  if (option.options) {
    // Our Dropdown component casts all option values to string, so here we will store a
    // record of the original value, and a stringified version for lookup.
    const optionsValueRecord: Record<string, ParameterValue> = {};
    option.options.forEach(option => {
      optionsValueRecord[`${option.value}`] = option.value;
    });

    // Because we are stringifying values, we need to convert these back using our optionsValueRecord
    // so we return a helper function to do so.
    const getOriginalValue = (value: ParameterValue) => {
      return optionsValueRecord[value] ?? value;
    };

    const formattedOptions = option.options.map(option => {
      const stringifiedValue = Object.keys(optionsValueRecord).find(
        key => optionsValueRecord[key] === option.value,
      );
      return { label: option.label, value: stringifiedValue };
    });

    const editorProps = {
      options: formattedOptions,
      useDynamicOptions: false,
    } as DropdownAdditionalProps;

    return { editorType: EditorType.DROPDOWN, editorProps, getOriginalValue };
  }

  const editorType =
    deviceAdvancedOptionsEditorTypesOverrides[option.type] ??
    getDefaultEditorForAnthaType(option.type);
  const editorProps =
    getAdditionalEditorPropsForEditorType(editorType, option.type) ?? undefined;
  return { editorType, editorProps };
}
