import React, { useCallback, useEffect, useRef, useState } from 'react';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControl from '@mui/material/FormControl';
import GlobalStyles from '@mui/material/GlobalStyles';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { css, styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { getAuth0ClientId } from 'client/app/apps/authentication/api';
import { Auth0ClientData, emptyClientData } from 'client/app/apps/authentication/hooks';
import supportedBrowsers from 'client/app/lib/supportedBrowsers';
import { SynthaceLogo } from 'common/assets/SynthaceLogo';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';

const isUnsupportedBrowser = !supportedBrowsers.test(navigator.userAgent);

type OrganizationPageProps = {
  setAuthorizationData: (value: Auth0ClientData) => void;
  connectionOverride: string | null;
  missingAuth0Config?: boolean;
};

function OrganizationPage({
  setAuthorizationData: setAuth0ClientData,
  missingAuth0Config = false,
  connectionOverride,
}: OrganizationPageProps) {
  const [organization, setOrganization] = useState<undefined | string>(undefined);

  const [userTyping, setUserTyping] = useState(false);
  const inputRef = useRef<HTMLInputElement | undefined>(undefined);

  const [{ selectionStart, selectionEnd }, setSelectionRange] = useState<{
    selectionStart: number | null;
    selectionEnd: number | null;
  }>({
    selectionStart: null,
    selectionEnd: null,
  });
  const handleChangeOrganisation = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { selectionStart, selectionEnd, value } = e.target;
      setSelectionRange({ selectionStart, selectionEnd });
      setOrganization(value);
      setAuth0ClientData(emptyClientData);
      setUserTyping(true);
      setTooltipOpen(false);
    },
    [setAuth0ClientData, setOrganization],
  );

  // Make sure the cursor doesn't jump to the end if a user clicks
  // in the middle of the word that is being typed after a re-render.
  useEffect(
    () => inputRef.current?.setSelectionRange(selectionStart, selectionEnd),
    [selectionStart, selectionEnd],
  );

  const [fetchOrganizationResult, setFetchOrganisationResult] = React.useState<
    'success' | 'client_error' | 'server_error' | 'loading' | undefined
  >(undefined);

  const handleSubmit = useCallback(async () => {
    const normalisedOrganization = organization?.toLowerCase().trim();
    if (!normalisedOrganization) {
      setTooltipOpen(true);
      return;
    }

    try {
      setFetchOrganisationResult('loading');
      const response = await getAuth0ClientId(normalisedOrganization);
      if (response.ok) {
        setFetchOrganisationResult('success');
        const {
          auth0ClientId: clientId,
          customerConnection,
        }: { auth0ClientId: string; customerConnection: string | undefined } =
          await response.json();
        const connection = connectionOverride
          ? connectionOverride
          : customerConnection ?? '';
        const clientData = { ...emptyClientData, clientId, connection };
        setAuth0ClientData(clientData);
      } else if (response.status >= 400 && response.status < 500) {
        setFetchOrganisationResult('client_error');
        setOrganization('');
        inputRef.current?.blur();
      } else {
        setFetchOrganisationResult('server_error');
        setOrganization('');
        inputRef.current?.blur();
      }
    } catch (_) {
      setFetchOrganisationResult('server_error');
      setOrganization('');
      inputRef.current?.blur();
    }
  }, [connectionOverride, organization, setAuth0ClientData]);

  const handleFormSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      (async () => {
        await handleSubmit();
      })();
    },
    [handleSubmit],
  );

  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const OrganizationErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme: _ }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: Colors.WHITE,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: '',
      fontSize: '16px',
      border: '1px solid #dadde9',
      padding: '8px',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: Colors.WHITE,
    },
  }));

  function getAuth0ErrorFromURL(): string | undefined {
    const url = new URL(window.location.href);
    const error = url.searchParams.get('error');
    const errorDescription = url.searchParams.get('error_description');
    return error || errorDescription
      ? `${
          errorDescription
            ? `${errorDescription.charAt(0).toUpperCase()}${errorDescription.slice(1)}`
            : 'Something wrong happened'
        } ${error ? `(${error})` : ''}.`
      : undefined;
  }

  const authenticationError = getAuth0ErrorFromURL();

  return (
    <Main data-testid="organization-page">
      <GlobalStyles
        styles={css`
          body {
            overflow: auto !important;
          }
        `}
      />
      <Section>
        <Wrapper>
          <Header>
            <Logo />
            <HeaderTitle variant="h1" color="textPrimary" align="center">
              Enter Organization
            </HeaderTitle>
            <HeaderSubtitle color="textPrimary" align="center" variant="h3">
              Please enter the name of your organization.
            </HeaderSubtitle>
          </Header>
          <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
            <Content>
              <OrganizationForm onSubmit={handleFormSubmit}>
                <OrganizationErrorTooltip
                  PopperProps={{ disablePortal: true }}
                  onClose={() => setTooltipOpen(false)}
                  open={tooltipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <Typography variant="h6" style={{ padding: '4px' }}>
                      Please fill in this field.
                    </Typography>
                  }
                >
                  <StyledFormControl fullWidth>
                    <InputLabel
                      htmlFor="component-outlined"
                      error={
                        fetchOrganizationResult === 'client_error' ||
                        fetchOrganizationResult === 'server_error' ||
                        (fetchOrganizationResult &&
                          fetchOrganizationResult !== 'loading' &&
                          missingAuth0Config) ||
                        !!authenticationError
                      }
                    >
                      Organization name here
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      label="Organization name here"
                      autoFocus={userTyping}
                      onChange={handleChangeOrganisation}
                      onBlur={() => setUserTyping(false)}
                      inputRef={inputRef}
                      value={organization}
                      disabled={fetchOrganizationResult === 'loading'}
                      error={
                        fetchOrganizationResult === 'client_error' ||
                        fetchOrganizationResult === 'server_error' ||
                        (fetchOrganizationResult &&
                          fetchOrganizationResult !== 'loading' &&
                          missingAuth0Config) ||
                        !!authenticationError
                      }
                    />
                  </StyledFormControl>
                </OrganizationErrorTooltip>
                {fetchOrganizationResult === 'client_error' && (
                  <TooltipErrorSpan id="error-element-organizationName">
                    <TooltipErrorIcon role="img" />
                    The organization you entered is invalid
                  </TooltipErrorSpan>
                )}
                {!missingAuth0Config && fetchOrganizationResult === 'server_error' && (
                  <TooltipErrorSpan id="error-element-general">
                    <TooltipErrorIcon role="img" />
                    An error happened while trying to retrieve the organization
                  </TooltipErrorSpan>
                )}
                {authenticationError && (
                  <TooltipErrorSpan id="error-element-general">
                    <TooltipErrorIcon role="img" />
                    {authenticationError}
                  </TooltipErrorSpan>
                )}
                {fetchOrganizationResult &&
                  fetchOrganizationResult !== 'client_error' &&
                  fetchOrganizationResult !== 'loading' &&
                  missingAuth0Config && (
                    <TooltipErrorSpan id="error-element-missingAuth0Config">
                      <TooltipErrorIcon role="img" />
                      Please reload the page or contact support if issue persists
                    </TooltipErrorSpan>
                  )}
              </OrganizationForm>
              <LoginButton
                fullWidth
                disableRipple
                variant="primary"
                color="primary"
                type="submit"
                form="loginForm"
                onClick={handleSubmit}
                disabled={fetchOrganizationResult === 'loading'}
              >
                Continue to login
              </LoginButton>
            </Content>
          </ClickAwayListener>
        </Wrapper>
        {isUnsupportedBrowser && (
          <Banner>
            <BannerIcon />
            <Typography variant="body2" color="textPrimary">
              This browser version is not actively supported. Update your browser to
              experience Synthace&apos;s latest updates.
            </Typography>
          </Banner>
        )}
      </Section>
    </Main>
  );
}

const Banner = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(8),
  width: '300px',
}));

const BannerIcon = styled(ErrorOutlineOutlinedIcon)(({ theme }) => ({
  marginRight: theme.spacing(5),
}));

const TooltipErrorIcon = styled('span')({
  backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334z' fill='%23D00E17' stroke='%23D00E17' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 4.583a.75.75 0 0 1 .75.75V8a.75.75 0 0 1-1.5 0V5.333a.75.75 0 0 1 .75-.75z' fill='%23fff'/%3E%3Cpath d='M8.667 10.667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0z' fill='%23fff'/%3E%3C/svg%3E")`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '16px',
  display: 'inline-block',
  height: '16px',
  width: '16px',
  marginRight: '8px',
});

const TooltipErrorSpan = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
  display: 'flex',
  textAlign: 'left',
  fontSize: theme.typography.pxToRem(12),
  marginTop: '4px',
}));

const LoginButton = styled(Button)(({ theme }) => ({
  minHeight: '52px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  cursor: 'pointer',
  boxShadow: 'none',
  fontSize: theme.typography.pxToRem(18),
  fontWeight: '300',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
  },
  margin: theme.spacing(6, 0, 0, 0),
  padding: theme.spacing(2, 5),
}));

const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 8, 8, 8),
}));

const Header = styled('header')(({ theme }) => ({
  padding: theme.spacing(8, 8, 6, 8),
  textAlign: 'center',
  lineHeight: '1.5',
  flexShrink: 0,
}));

const HeaderTitle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(6, 0, 5, 0),
  fontSize: theme.typography.pxToRem(25),
  fontWeight: '100',
  letterSpacing: 0,
  lineHeight: '38px',
}));

const HeaderSubtitle = styled(Typography)(({ theme }) => ({
  fontWeight: '100',
  fontSize: theme.typography.pxToRem(18),
  letterSpacing: 0,
  lineHeight: '26px',
}));

const Wrapper = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.background.default,
  boxShadow: '0 12px 40px rgba(0,0,0,0.12)',
  borderRadius: '5px',
  border: '0 solid transparent',
  minHeight: '540px',
  justifyContent: 'center',
}));

const Logo = styled(SynthaceLogo)({
  height: '25px',
});

const OrganizationForm = styled('form')({
  marginTop: '8px',
});

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 'normal',
    fontWeight: '100',
  },
  '& .MuiOutlinedInput-notchedOutline > legend': {
    width: '172px',
  },
  '& .MuiOutlinedInput-root > input': {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '100',
  },
}));

const Main = styled('main')({
  display: 'grid',
  alignItems: 'center',
  padding: '80px',
  justifyContent: 'center',
  justifyItems: 'center',
  gridTemplateRows:
    '[left-start center-start right-start] 1fr [left-end center-end right-end]',
  gridTemplateColumns:
    '[left-start center-start] 1fr [left-end right-start] 1fr [center-end right-end]',
});

const Section = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gridArea: 'center',
  borderRadius: '5px',
  whiteSpace: 'normal',
  position: 'relative',
  width: '400px',
  height: 'auto',
  backgroundColor: theme.palette.background.default,
}));

export default React.memo(OrganizationPage);
