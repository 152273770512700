import React, { useContext } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import WarningOutlined from '@mui/icons-material/WarningOutlined';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Handle, NodeProps, Position } from 'react-flow-renderer';

import { useNodeState } from 'client/app/apps/work-tree/useNodeState';
import { NODE_HEIGHT, NODE_WIDTH } from 'client/app/apps/work-tree/workTreeDimensions';
import { WorkTreeModeContext } from 'client/app/apps/work-tree/WorkTreeModeContext';
import { FavoritedBy, FavoriteStarIconButton } from 'client/app/components/FavoriteStar';
import { EXPERIMENT_SOURCE_PARAM } from 'client/app/components/nav/CreateNewExperimentButton';
import {
  ArrayElement,
  ExperimentWorkTreeQuery,
  MethodStatus,
  WorkTreeApplicationName,
} from 'client/app/gql';
import { formatDateTime } from 'common/lib/format';
import stopPropagation from 'common/lib/stopPropagation';
import Colors, { PERCEPTUAL_PALETTE } from 'common/ui/Colors';
import Popover, { PopoverSection } from 'common/ui/components/Popover';
import Tooltip from 'common/ui/components/Tooltip';
import CherryPickerIcon from 'common/ui/icons/CherryPickerIcon';
import { DataSetIcon } from 'common/ui/icons/DataSetIcon';
import DOETemplateIcon from 'common/ui/icons/DOETemplateIcon';
import { ExecutionIcon } from 'common/ui/icons/Execution';
import { GraphDataIcon } from 'common/ui/icons/GraphDataIcon';
import { SimulationIcon } from 'common/ui/icons/SimulationIcon';
import { SnapshotIcon } from 'common/ui/icons/SnapshotIcon';
import { WorkflowIcon } from 'common/ui/icons/Workflow';

type Block = ArrayElement<
  NonNullable<ExperimentWorkTreeQuery['experimentWorkTree']>['blocks']
>;

export type EntityNodeProps = Block & {
  experimentid: ExperimentId;
};

export default function EntityNode(props: NodeProps<EntityNodeProps>) {
  const { selected, greyedOut, clickable, highlighted } = useNodeState(props.data);

  return (
    <StyledPaper
      isClickable={clickable}
      isGreyedOut={greyedOut}
      isHighlighted={highlighted}
      isSelected={selected}
    >
      <EntityNodeContent {...props} isGreyedOut={greyedOut} />
    </StyledPaper>
  );
}

function EntityNodeContent({
  data: block,
  isGreyedOut,
}: NodeProps<EntityNodeProps> & { isGreyedOut: boolean }) {
  switch (block.applicationName) {
    case WorkTreeApplicationName.cherry_picker:
    case WorkTreeApplicationName.doe_template_editor:
    case WorkTreeApplicationName.workflow_builder:
    case WorkTreeApplicationName.snapshot:
      return <EntityNodeDetails block={block} isGreyedOut={isGreyedOut} />;
    case WorkTreeApplicationName.simulate:
    case WorkTreeApplicationName.execute:
      return block.applicationVersion === '0.0.0' ? (
        <EntityNodeDetails
          block={block}
          showFavorited
          showStatus
          isGreyedOut={isGreyedOut}
        />
      ) : (
        <EntityNodeDetails
          block={block}
          isDataSetApplication
          showStatus
          isGreyedOut={isGreyedOut}
        />
      );
    case WorkTreeApplicationName.align:
    case WorkTreeApplicationName.analyse:
    case WorkTreeApplicationName.append:
    case WorkTreeApplicationName.bioprocess:
    case WorkTreeApplicationName.bp_bioprocess:
    case WorkTreeApplicationName.design:
    case WorkTreeApplicationName.lg_export:
    case WorkTreeApplicationName.lg_link_plates:
    case WorkTreeApplicationName.lg_load_bioprocess:
    case WorkTreeApplicationName.lg_load_execution:
    case WorkTreeApplicationName.rbc_analyse:
    case WorkTreeApplicationName.rbc_cherry_pick:
    case WorkTreeApplicationName.rbc_view:
    case WorkTreeApplicationName.notebook:
    case WorkTreeApplicationName.render:
    case WorkTreeApplicationName.view:
    case WorkTreeApplicationName.workbook:
      return (
        <EntityNodeDetails
          block={block}
          isDataSetApplication
          showStatus
          isGreyedOut={isGreyedOut}
        />
      );
    case WorkTreeApplicationName.wf_builder:
    case WorkTreeApplicationName.wf_cherry_picker:
    case WorkTreeApplicationName.wf_form_editor:
    case WorkTreeApplicationName.doe_analyse_responses:
    case WorkTreeApplicationName.doe_prepare_data:
      return (
        <EntityNodeDetails
          block={block}
          isDataSetApplication
          showStatus={false}
          isGreyedOut={isGreyedOut}
        />
      );
    default:
      return <EntityNodeDetails block={block} showStatus isGreyedOut={isGreyedOut} />;
  }
}

function EntityNodeDetails({
  block,
  showStatus,
  isDataSetApplication,
  showFavorited,
  isGreyedOut,
}: {
  block: EntityNodeProps;
  isDataSetApplication?: boolean;
  showStatus?: boolean;
  showFavorited?: boolean; // Only required for simulation or execution nodes currently
  isGreyedOut: boolean;
}) {
  const context = useContext(WorkTreeModeContext);
  const { selectable, highlighted } = useNodeState(block);
  const hasVersionData = block.data?.versionData;

  const { icon, color, copy } = getEntityNodeIconAndCopy(block.applicationName);

  const handleClick = () => {
    if (selectable && !hasVersionData) {
      context.addSelectedBlock(block.id);
    } else if (selectable && hasVersionData) {
      const versionData = [...block.data!.versionData];
      versionData.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
      if (versionData.length > 0) {
        context.addSelectedBlock(versionData[0].id);
      }
    } else if (context.mode === 'editing-hidden-blocks') {
      context.toggleHidden(block.id);
    }
  };

  const selectedIndex = Math.min(context.selectedBlocks.indexOf(block.id) + 1, 99);
  let maxNumberOfSelectedBlocks = Infinity;
  if (
    context.mode === 'creating-block' &&
    context.creatingBlock.inputResultRequirements.maxItems
  ) {
    maxNumberOfSelectedBlocks = context.creatingBlock.inputResultRequirements.maxItems;
  }

  const fmtCreatedAt = block.createdAt ? formatDateTime(new Date(block.createdAt)) : '';

  const shouldShowMultiLineText = !showStatus;

  let resultName = isDataSetApplication ? block.label : block.result?.name;
  if (!resultName) {
    // Fallback case for any non-defined names
    resultName = copy.toUpperCase();
  }

  const updatedHref = addExperimentIdParams(block.href, block.experimentid);

  const title = (
    <TitleTypography
      isSingleLine={!shouldShowMultiLineText}
      isMultiLine={shouldShowMultiLineText}
      variant="body2"
    >
      {resultName}
    </TitleTypography>
  );

  const showVersions = block.data?.versionData && block.data.versionData.length > 0;

  return (
    <>
      <StyledHandle type="target" position={Position.Left} isConnectable={false} />
      <>
        {highlighted && block.applicationName === WorkTreeApplicationName.execute && (
          <HighlightedTextHelp color="textSecondary" variant="caption">
            Click ‘+’ button in the toolbar to continue
          </HighlightedTextHelp>
        )}
        <NodeContents onClick={handleClick}>
          <NodeIcon
            showVersions={showVersions}
            isGreyedOut={isGreyedOut}
            style={{ backgroundColor: color }}
          >
            {icon}
          </NodeIcon>
          <NodeBody isGreyedOut={isGreyedOut}>
            <Popover
              title={
                <div>
                  {block.applicationDisplayName && (
                    <PopoverSection header="Type" text={block.applicationDisplayName} />
                  )}
                  {resultName && <PopoverSection header="Name" text={resultName} />}
                  <PopoverSection
                    header="Created by"
                    text={block.createdBy.displayName}
                  />
                  {fmtCreatedAt && (
                    <PopoverSection header="Created at" text={fmtCreatedAt} />
                  )}
                </div>
              }
            >
              {context.mode === 'default' ? (
                <Link
                  color="textPrimary"
                  onClick={stopPropagation}
                  href={updatedHref}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="hover"
                >
                  {title}
                </Link>
              ) : (
                title
              )}
            </Popover>
            {showStatus && (
              <Typography
                variant="caption"
                style={{ color: getStatusLabelColor(block.status) }}
              >
                {block.status}
              </Typography>
            )}
          </NodeBody>
          <NodeIconsWrapper>
            {showFavorited && block.isFavoritedByCurrentUser && (
              <StyledFavoriteStarIconButton
                status={FavoritedBy.FAVORITED_BY_SELF}
                size="xsmall"
                disabled
              />
            )}
            {context.mode === 'editing-hidden-blocks' && (
              <VisibilityButton block={block} />
            )}
            {context.mode === 'creating-block' &&
              !hasVersionData &&
              selectable &&
              selectedIndex > 0 && (
                <MultiSelectIcon
                  type="entityNodeMultiSelectIcon"
                  index={selectedIndex}
                  warningState={selectedIndex > maxNumberOfSelectedBlocks}
                />
              )}
          </NodeIconsWrapper>
        </NodeContents>
        {showVersions && <NodeVersions {...block} isGreyedOut={isGreyedOut} />}
      </>
      <StyledHandle type="source" position={Position.Right} isConnectable={false} />
    </>
  );
}

function VisibilityButton({ block }: { block: Block }) {
  const { greyedOut, clickable } = useNodeState(block);

  if (greyedOut) {
    return (
      <Tooltip
        title={
          clickable
            ? ''
            : 'This block cannot be unhidden because a parent block is hidden'
        }
      >
        <StyledVisibilityOff isDisabled={!clickable} />
      </Tooltip>
    );
  } else {
    return <StyledVisibility isDisabled={!clickable} />;
  }
}

function NodeVersions(block: EntityNodeProps & { isGreyedOut: boolean }) {
  const context = useContext(WorkTreeModeContext);
  const { selectable: parentBlockSelectable, greyedOut: parentBlockGreyedOut } =
    useNodeState(block);
  let maxNumberOfSelectedBlocks = Infinity;
  if (
    context.mode === 'creating-block' &&
    context.creatingBlock.inputResultRequirements.maxItems
  ) {
    maxNumberOfSelectedBlocks = context.creatingBlock.inputResultRequirements.maxItems;
  }
  const isCollapsed = !context.expandedBlocks.includes(block.id);

  const toggleCollapse = () => {
    context.toggleExpandBlock(block.id);
  };

  const handleHoverVersion = (versionId: BlockId | undefined) => {
    if (context.mode !== 'creating-block') {
      const versionBlock = block.data?.versionData.find(
        version => version.id === versionId,
      );
      const dependants = versionBlock ? versionBlock.dependants : [];
      context.onHoverVersion({
        parentBlockId: block.id,
        versionId,
        dependants,
      });
    }
  };

  const handleClickVersion = (versionId: BlockId) => {
    const versionBlock = block.data?.versionData.find(
      version => version.id === versionId,
    );
    const dependants = versionBlock ? versionBlock.dependants : [];
    context.onClickVersion({ parentBlockId: block.id, versionId, dependants });
  };

  if (!block.data) {
    return null;
  }

  const { color } = getEntityNodeIconAndCopy(block.applicationName);

  const versionData = [...block.data.versionData];
  versionData.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
  const latestVersion = versionData.length > 0 ? versionData[0] : 0;

  const latestVersionSelectedIndex = latestVersion
    ? Math.min(context.selectedBlocks.indexOf(latestVersion.id) + 1, 99)
    : 0;

  const handleClickVersionContainer = () => {
    if (latestVersion && parentBlockSelectable) {
      context.addSelectedBlock(latestVersion.id);
    }
  };

  return (
    <NodeVersionsContainer onClick={handleClickVersionContainer}>
      <NodeIcon
        isGreyedOut={block.isGreyedOut}
        isVersionIcon
        style={{ backgroundColor: color }}
      />
      <NodeVersionsCollapseContainer>
        <NodeVersionsCollapseTitle isGreyedOut={parentBlockGreyedOut}>
          {isCollapsed ? (
            <NodeVersionTimeStamp variant="button">
              {formatDateTime(new Date(block.createdAt))}
            </NodeVersionTimeStamp>
          ) : (
            <Typography>Past versions</Typography>
          )}
          {context.mode === 'creating-block' && parentBlockSelectable ? (
            <MultiSelectIcon
              type="versionMultiSelectIcon"
              index={latestVersionSelectedIndex}
              warningState={latestVersionSelectedIndex > maxNumberOfSelectedBlocks}
            />
          ) : (
            <CollapseIconButton
              onClick={toggleCollapse}
              size="small"
              disabled={
                versionData.some(version =>
                  context.selectedBlocks.includes(version.id),
                ) ||
                parentBlockGreyedOut ||
                context.mode === 'creating-block'
              }
            >
              {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </CollapseIconButton>
          )}
        </NodeVersionsCollapseTitle>
        <Collapse in={!isCollapsed}>
          <NodeVersionsList>
            {versionData.map(versionBlock => {
              const updatedHref = addExperimentIdParams(
                versionBlock.href,
                block.experimentid,
              );

              const versionTitle = (
                <div>
                  <TitleTypography isSingleLine variant="body2">
                    {versionBlock.label}
                  </TitleTypography>
                  <NodeVersionTimeStamp variant="button">
                    {formatDateTime(new Date(versionBlock.createdAt))}
                  </NodeVersionTimeStamp>
                </div>
              );

              return (
                <NodeVersionListItem
                  component="div"
                  key={versionBlock.id}
                  isHover={context.selectedVersion?.versionId === versionBlock.id}
                  isSelected={
                    context.selectedVersion?.versionId === versionBlock.id &&
                    context.selectedVersion.userSelected
                  }
                  onMouseEnter={() => handleHoverVersion(versionBlock.id)}
                  onMouseLeave={() => handleHoverVersion(undefined)}
                  onClick={() => handleClickVersion(versionBlock.id)}
                >
                  {context.mode !== 'creating-block' ? (
                    <NodeVersionLink
                      color="textPrimary"
                      onClick={stopPropagation}
                      href={updatedHref}
                      target="_blank"
                      rel="noopener noreferrer"
                      underline="hover"
                    >
                      {versionTitle}
                    </NodeVersionLink>
                  ) : (
                    versionTitle
                  )}
                  {versionBlock.dependants.length > 0 && (
                    <StyledLinkOutlinedIcon fontSize="small" />
                  )}
                </NodeVersionListItem>
              );
            })}
          </NodeVersionsList>
        </Collapse>
      </NodeVersionsCollapseContainer>
    </NodeVersionsContainer>
  );
}

type MultiSelectIconProps = {
  index: number;
  warningState?: boolean;
  type: 'versionMultiSelectIcon' | 'entityNodeMultiSelectIcon';
};

function MultiSelectIcon(props: MultiSelectIconProps) {
  const { index, warningState, type } = props;
  if (index === 0) {
    return null;
  }
  return (
    <MultiSelectIconTypography type={type} warning={warningState}>
      {index}
    </MultiSelectIconTypography>
  );
}

// Adds the experimentid as a query parameter to the given href.
// Our hrefs come from the server as relative urls that can already contain parameters:
//
// e.g. /#/workflow/0f8a5563-ec08-4462-b65b-bfe81e92a950
// e.g. /#/vis/view/align?method_id=ae11b3f0-e121-4abb-a3db-d608eb861a9a
//
// This function returns an updated relative url.
function addExperimentIdParams(href: string, experimentid: ExperimentId): string {
  const hasParams = href.includes('?');
  const existingParams = hasParams ? href.split('?')[1] : '';
  const urlWithoutParams = hasParams ? href.split('?')[0] : href;
  const newParams = new URLSearchParams(existingParams);
  newParams.set(EXPERIMENT_SOURCE_PARAM, experimentid);
  return urlWithoutParams + '?' + newParams.toString();
}

function getStatusLabelColor(status: MethodStatus): string {
  switch (status) {
    case MethodStatus.COMPLETED:
      return Colors.SUCCESS_LIGHT;
    case MethodStatus.FAILED:
      return Colors.ERROR_MAIN;
    default:
      return Colors.INFO_MAIN;
  }
}

function getEntityNodeIconAndCopy(applicationName: WorkTreeApplicationName): {
  icon: JSX.Element;
  copy: string;
  color: string;
} {
  let icon;
  let copy;
  let color;
  switch (applicationName) {
    case WorkTreeApplicationName.workflow_builder:
    case WorkTreeApplicationName.wf_builder:
    case WorkTreeApplicationName.wf_form_editor:
      icon = <WorkflowIcon fontSize="small" />;
      copy = 'WORKFLOW';
      color = PERCEPTUAL_PALETTE.blue;
      break;
    case WorkTreeApplicationName.snapshot:
      icon = <SnapshotIcon fontSize="small" />;
      copy = 'SNAPSHOT';
      color = PERCEPTUAL_PALETTE.blue;
      break;
    case WorkTreeApplicationName.cherry_picker:
    case WorkTreeApplicationName.wf_cherry_picker:
      icon = <CherryPickerIcon fontSize="small" />;
      copy = 'CHERRY PICKER';
      color = PERCEPTUAL_PALETTE.peach;
      break;
    case WorkTreeApplicationName.doe_template_editor:
      icon = <DOETemplateIcon fontSize="small" />;
      copy = 'DOE TEMPLATE';
      color = PERCEPTUAL_PALETTE.lightGreen;
      break;
    case WorkTreeApplicationName.simulate:
      icon = <SimulationIcon />;
      copy = 'SIMULATION';
      color = PERCEPTUAL_PALETTE.lavender;
      break;
    case WorkTreeApplicationName.execute:
      icon = <ExecutionIcon fontSize="small" />;
      copy = 'EXECUTION';
      color = PERCEPTUAL_PALETTE.yellow;
      break;
    case WorkTreeApplicationName.align:
    case WorkTreeApplicationName.analyse:
    case WorkTreeApplicationName.append:
    case WorkTreeApplicationName.design:
    case WorkTreeApplicationName.render:
    case WorkTreeApplicationName.view:
    case WorkTreeApplicationName.bioprocess:
    case WorkTreeApplicationName.notebook:
    case WorkTreeApplicationName.workbook:
      icon = <DataSetIcon fontSize="small" />;
      copy = applicationName;
      color = PERCEPTUAL_PALETTE.green;
      break;
    case WorkTreeApplicationName.data_parser:
      icon = <DataSetIcon fontSize="small" />;
      copy = applicationName;
      color = PERCEPTUAL_PALETTE.lavender;
      break;
    case WorkTreeApplicationName.doe_prepare_data:
      icon = <DataSetIcon fontSize="small" />;
      copy = 'DOE PREPARE DATA';
      color = PERCEPTUAL_PALETTE.green;
      break;
    case WorkTreeApplicationName.doe_analyse_responses:
      icon = <GraphDataIcon fontSize="small" />;
      copy = 'DOE ANALYSE RESPONSES';
      color = PERCEPTUAL_PALETTE.tan;
      break;
    default:
      icon = <WarningOutlined fontSize="small" />;
      copy = applicationName;
      color = Colors.GREY_50;
      break;
  }
  return { icon, copy, color: alpha(color, 0.4) };
}

const StyledPaper = styled(Paper, {
  shouldForwardProp: name =>
    name !== 'isSelected' &&
    name !== 'isGreyedOut' &&
    name !== 'isClickable' &&
    name !== 'isHighlighted',
})<{
  isSelected: boolean;
  isGreyedOut: boolean;
  isClickable: boolean;
  isHighlighted: boolean;
}>(({ theme, isSelected, isGreyedOut, isClickable, isHighlighted }) => {
  const selectedStyles = {
    outline: `2px solid ${Colors.PRIMARY_DARK}`,
  };

  const greyedOutStyles = {
    backgroundColor: alpha(Colors.GREY_0, 0.6),
    boxShadow: `inset 0 0 0 1px ${alpha(Colors.GREY_30, 0.6)}`,
  };

  const clickableNodeStyles = {
    cursor: 'pointer',
  };

  const highlightedNodeStyles = {
    outline: `4px solid ${alpha(Colors.SECONDARY_MAIN, 0.2)}`,
  };

  return {
    boxShadow: `${theme.shadows[6]}, inset 0 0 0 1px ${Colors.GREY_30}`,
    borderRadius: '8px',
    backgroundColor: Colors.GREY_0,
    cursor: 'auto',
    pointerEvents: 'all',
    width: `${NODE_WIDTH}px`,
    ...(isSelected ? selectedStyles : {}),
    ...(isGreyedOut ? greyedOutStyles : {}),
    ...(isClickable ? clickableNodeStyles : {}),
    ...(isHighlighted ? highlightedNodeStyles : {}),
  };
});

const MultiSelectIconTypography = styled(Typography, {
  shouldForwardProp: name => name !== 'warning' && name !== 'type',
})<{ type: 'versionMultiSelectIcon' | 'entityNodeMultiSelectIcon'; warning?: boolean }>(
  ({ theme, type, warning }) => ({
    color: Colors.WHITE,
    fontSize: '11px',
    fontWeight: 700,
    height: '14px',
    width: '14px',
    borderRadius: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: warning ? theme.palette.warning.main : theme.palette.success.main,

    gridRow: type === 'entityNodeMultiSelectIcon' ? 'lower-node-icon' : undefined,

    ...(type === 'versionMultiSelectIcon'
      ? {
          gridColumn: 'right-node-icon',
          justifySelf: 'center',
        }
      : {}),
  }),
);

const CollapseIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const StyledHandle = styled(Handle)({
  display: 'none',
});

const TitleTypography = styled(Typography, {
  shouldForwardProp: name => name !== 'isSingleLine' && name !== 'isMultiLine',
})<{
  isSingleLine: boolean;
  isMultiLine?: boolean;
}>(({ isSingleLine: singleLine, isMultiLine: multiLine }) => ({
  overflow: 'hidden',
  maxWidth: '175px',

  ...(singleLine ? { textOverflow: 'ellipsis', whiteSpace: 'nowrap' } : {}),

  ...(multiLine
    ? {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
      }
    : {}),
}));

const NodeBody = styled('div', { shouldForwardProp: name => name !== 'isGreyedOut' })<{
  isGreyedOut: boolean;
}>(({ theme, isGreyedOut }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  opacity: isGreyedOut ? 0.5 : undefined,
}));

const VisiblityIconStyles = ({ isDisabled }: { isDisabled: boolean }) => ({
  gridRow: 'lower-node-icon',
  color: isDisabled ? Colors.ACTION_DISABLED : undefined,
});

const StyledVisibilityOff = styled(VisibilityOff, {
  shouldForwardProp: name => name !== 'isDisabled',
})(VisiblityIconStyles);
const StyledVisibility = styled(Visibility, {
  shouldForwardProp: name => name !== 'isDisabled',
})(VisiblityIconStyles);

const StyledFavoriteStarIconButton = styled(FavoriteStarIconButton)({
  gridRow: 'upper-node-icon',
});

const StyledLinkOutlinedIcon = styled(LinkOutlinedIcon)({
  gridColumn: 'link-icon',
  justifySelf: 'center',
});

const HighlightedTextHelp = styled(Typography)({
  position: 'absolute',
  top: '-24px',
  left: '10px',
});

const NodeVersionsContainer = styled('div')({
  display: 'flex',
  borderTop: `1px solid ${Colors.GREY_30}`,
});

const NodeVersionsCollapseContainer = styled('div')({
  width: '100%',
});

const NodeVersionTimeStamp = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const NodeVersionsList = styled(List)(({ theme }) => ({
  padding: theme.spacing(0, 0, 4, 0),
}));

const NodeVersionLink = styled(Link)({
  gridColumn: 'title',
});

const NodeVersionsCollapseTitle = styled('div', {
  shouldForwardProp: name => name !== 'isGreyedOut',
})<{ isGreyedOut: boolean }>(({ isGreyedOut, theme }) => ({
  height: '36px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3),
  opacity: isGreyedOut ? 0.5 : undefined,
}));

const NodeContents = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  height: `${NODE_HEIGHT}px`,
  width: '100%',
}));

const NodeIconsWrapper = styled('div')({
  display: 'grid',
  gridTemplateRows: '[upper-node-icon] 1fr [lower-node-icon] 1fr',
  gridTemplateColumns: '32px',
  height: '100%',
  justifyItems: 'center',
  alignItems: 'center',
  marginLeft: 'auto',
});

const NodeVersionListItem = styled(ListItem<'div'>, {
  shouldForwardProp: name => name !== 'isHover' && name !== 'isSelected',
})<{
  isHover: boolean;
  isSelected: boolean | undefined;
}>(({ theme, isHover, isSelected }) => ({
  display: 'grid',
  gridTemplateColumns: '[title] 5fr [link-icon] 1fr',
  padding: theme.spacing(1, 3),
  backgroundColor: isHover ? Colors.BLUE_5 : isSelected ? Colors.BLUE_20 : undefined,
}));

const NodeIcon = styled('div', {
  shouldForwardProp: name =>
    name !== 'isVersionIcon' && name !== 'showVersions' && name !== 'isGreyedOut',
})<{
  isVersionIcon?: boolean;
  showVersions?: boolean;
  isGreyedOut: boolean;
}>(({ theme, isVersionIcon, isGreyedOut, showVersions }) => ({
  minWidth: '30px',
  minHeight: '100%',
  display: 'flex',
  borderRadius: '8px 0 0 8px',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.text.secondary,

  borderBottomLeftRadius: showVersions ? 0 : undefined,
  borderTopLeftRadius: isVersionIcon ? 0 : undefined,
  opacity: isGreyedOut ? 0.5 : undefined,
}));
