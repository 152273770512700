import React, { CSSProperties, Ref } from 'react';

import MUIFab, { FabProps } from '@mui/material/Fab';
import { styled } from '@mui/material/styles';

type Props = {
  icon: React.ReactElement;
  label?: string;
  color?: FabProps['color'];
  size?: FabProps['size'];
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /** Use sparingly. */
  styleOverrides?: CSSProperties;
  className?: string;
};

export default React.forwardRef(function Fab(
  { icon, label, onClick, color, size, styleOverrides, className }: Props,
  ref: Ref<HTMLButtonElement>,
) {
  return (
    <StyledFab
      className={className}
      onClick={onClick}
      color={color || 'secondary'}
      size={size || 'medium'}
      variant={label ? 'extended' : 'circular'}
      style={styleOverrides}
      ref={ref}
    >
      {icon} {label && <Label>{label}</Label>}
    </StyledFab>
  );
});

const StyledFab = styled(MUIFab)({
  position: 'absolute',
  bottom: '32px',
  right: '32px',
});

const Label = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(3),
}));
