import React, { useCallback } from 'react';

import { Link } from 'react-router-dom';

import IconButton, { IconButtonProps } from 'common/ui/components/IconButton';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import { NavigationRoute } from 'common/ui/navigation';

type Props<TRouteParam> = {
  route: NavigationRoute<TRouteParam>;
  routeParam: TRouteParam;
  openInNewTab?: boolean;
  /** If set, we'll log this event to analytics */
  logEventCategory?: string;
} & IconButtonProps;

/**
 * Renders an icon button that will navigate to the route on click.
 *
 * Optionally can open the route in new tab, if openInNewTab is set to true.
 */
export default function RouteIconButton<TRouteParam>({
  route,
  routeParam,
  logEventCategory,
  openInNewTab,
  icon,
  size,
  ...otherProps
}: Props<TRouteParam>) {
  const target = openInNewTab ? '_blank' : undefined;
  const onClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (logEventCategory) {
        logEvent('navigate', logEventCategory, route.pathTemplate);
      }
    },
    [logEventCategory, route],
  );

  return (
    <Link to={route.getPath(routeParam)} onClick={onClick} target={target}>
      <IconButton {...otherProps} icon={icon} size={size} />
    </Link>
  );
}
