import React, { useContext, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import produce from 'immer';
import isEqual from 'lodash/isEqual';

import { DatasetSettingsContext } from 'client/app/apps/experiments/dataset-settings/DatasetSettingsContext';
import DatasetSettingsError from 'client/app/apps/experiments/dataset-settings/DatasetSettingsError';
import { useHasBioreactorDataset } from 'client/app/apps/experiments/dataset-settings/hasBioreactorDataset';
import { SampleAutocomplete } from 'client/app/apps/experiments/dataset-settings/SampleAutocomplete';
import { MUTATION_LINK_SAMPLE_TO_DATASET } from 'client/app/apps/experiments/gql/mutations';
import { QUERY_DATASET_WITH_SETTINGS } from 'client/app/apps/experiments/gql/queries';
import QueryHighlighter from 'client/app/components/QueryHighlighter';
import { ArrayElement, DatasetWithSettingsQuery, SamplesQuery } from 'client/app/gql';
import Colors from 'common/ui/Colors';
import SearchField from 'common/ui/components/SearchField';
import { TableHeaderCell } from 'common/ui/components/TableHeaderCell';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/**
 * For analytical datasets (non-bioreactor datasets), each row of the dataset can be
 * 'matched' to an existing sample.
 */
export function DatasetSettingsSampleMatchTab() {
  const { derivationId, dataset, isReadonly } = useContext(DatasetSettingsContext);
  const { hasBioreactorDataset, loading: loadingDerivation } =
    useHasBioreactorDataset(derivationId);
  const classes = useStyles();

  const [searchQuery, setSearchQuery] = useState('');
  const filterWithQuery = ({ label }: { label: string }) =>
    label.toLowerCase().includes(searchQuery.toLowerCase());

  const searchQueryRexExp = useMemo(
    () => (searchQuery ? new RegExp(searchQuery, 'gi') : null),
    [searchQuery],
  );

  const [linkSampleToDataset, { loading: isLinkingSample }] = useMutation(
    MUTATION_LINK_SAMPLE_TO_DATASET,
  );

  const { rowKeyName, rowKeys } = useDatasetRowKeys(dataset);

  if (!rowKeys) {
    return null;
  }

  type Sample = ArrayElement<SamplesQuery['samples']['items']>;

  const onLinkSample = async (rowKey: DatasetSampleRowKey, sample: Sample | null) => {
    await linkSampleToDataset({
      variables: {
        datasetId: dataset.datasetId,
        sampleId: sample?.id,
        rowKey: JSON.stringify(rowKey),
      },
      update: (cache, response) => {
        // Apollo doesn't know that the link should be added or removed from the dataset's
        // list of samples, so we need to manually updated the cache.
        const cacheData = cache.readQuery({
          query: QUERY_DATASET_WITH_SETTINGS,
          variables: { id: dataset.datasetId },
        });

        if (!cacheData || !response.data) return;

        const newSampleLink = response.data.linkSampleToDataset;

        const newCacheData = produce(cacheData, data => {
          // Remove the old sample
          data.dataset.samples = data.dataset.samples.filter(
            sample => !isEqual(sample.rowKey, rowKey),
          );
          // Add the new sample (unless the user was just un-linking the sample)
          if (newSampleLink) {
            data.dataset.samples.push(newSampleLink);
          }
        });

        cache.writeQuery({
          query: QUERY_DATASET_WITH_SETTINGS,
          variables: { id: dataset.datasetId },
          data: newCacheData,
        });
      },
    });
  };

  if (loadingDerivation) {
    // This should never happen, as it is loaded earlier already, will be a cache hit.
    return null;
  }

  if (!hasBioreactorDataset) {
    return (
      <DatasetSettingsError
        title="Bioreactor dataset is required for sample information"
        subtitle="Please add a bioreactor dataset to the bioprocessing block"
      />
    );
  }

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableHeaderCell>From the dataset: {rowKeyName}</TableHeaderCell>
          <TableHeaderCell>Sample Name</TableHeaderCell>
        </TableRow>
        <TableRow className={classes.filterRow}>
          <TableCell>
            <SearchField
              label="Search"
              variant="outlined"
              onQueryChange={setSearchQuery}
              margin="dense"
            />
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>

      <TableBody>
        {rowKeys.filter(filterWithQuery).map(({ rowKey, label }) => {
          const existingMatch = dataset.samples.find(sample =>
            isEqual(sample.rowKey, rowKey),
          );

          return (
            <TableRow key={label}>
              <TableCell className={classes.cell}>
                <QueryHighlighter text={label} query={searchQueryRexExp} />
              </TableCell>
              <TableCell className={classes.cell}>
                <SampleAutocomplete
                  placeholder="Select sample name"
                  listHelperText="Sample names within this bioprocessing block"
                  value={existingMatch?.sample.name ?? null}
                  limitToDatasetDerivationId={derivationId}
                  onChange={(_, sample) => onLinkSample(rowKey, sample ?? null)}
                  isDisabled={isReadonly || isLinkingSample}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

/**
 * Returns a list of 'keys' for each row of the dataset, where each key is a column name
 * and value pair that uniquely identifies the row.
 */
function useDatasetRowKeys(dataset: DatasetWithSettingsQuery['dataset']): {
  rowKeyName?: string;
  rowKeys?: { label: string; rowKey: DatasetSampleRowKey }[];
} {
  // Backend outputs matching options in order of preference so we can just pick the
  // first. In future we might support multiple key columns (e.g. plate column and plate
  // row).
  const keyColumn = dataset.sampleMatchingOptions?.[0];

  return {
    rowKeyName: keyColumn?.columnName,
    rowKeys: keyColumn?.columnValues.map(value => ({
      rowKey: { [keyColumn.columnName]: value },
      label: value,
    })),
  };
}

const useStyles = makeStylesHook(theme => ({
  cell: {
    width: '50%',
  },
  table: {
    tableLayout: 'fixed',
    '& td': { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) },
  },
  filterRow: {
    '& th': {
      backgroundColor: Colors.GREY_5,
    },
  },
}));
