import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

function StampingIcon(
  props: React.PropsWithChildren<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>>,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M5.833 0H0v.833h5.833V0Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.167 1.667v2.5h-.834V5h-1.25v-.833H6.25V5H5v-.833h-.833V5h-1.25v-.833h-.834V5H.833v-.833H0v-2.5h3.75V.833h2.083v.834h3.334ZM1.053 2.719h7.061v.395H1.053v-.395ZM8.333 5.417V5h-1.25v.417L7.553 10h.312l.468-4.583Zm-.291.263L7.708 8.94 7.375 5.68h.667ZM6.25 5.417 5.781 10H5.47L5 5.417V5h1.25v.417Zm-.291.263L5.625 8.94 5.291 5.68h.668ZM4.167 5.417 3.697 10h-.312l-.468-4.583V5h1.25v.417Zm-.292.263L3.542 8.94 3.208 5.68h.667ZM2.083 5.417V5H.833v.417L1.302 10h.313l.468-4.583Zm-.291.263L1.458 8.94 1.125 5.68h.667Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default React.memo(StampingIcon);
