import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { QUERY_DATASET_DERIVATION } from 'client/app/apps/experiments/gql/queries';
import VisserverIFrame from 'client/app/components/VisserverIFrame';
import setBrowserTabTitle from 'client/app/lib/setBrowserTabTitle';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import LinearProgress from 'common/ui/components/LinearProgress';

type DatasetDerivationAppProps = {
  derivationId: DatasetDerivationId;
};

export function BioprocessApp({ derivationId }: DatasetDerivationAppProps) {
  const [visserverLoading, setVisserverLoading] = useState<boolean>(true);
  const {
    data,
    loading: queryLoading,
    error,
  } = useQuery(QUERY_DATASET_DERIVATION, {
    variables: { id: derivationId },
  });

  useEffect(() => {
    setBrowserTabTitle('Bioprocessing');
  }, []);

  if (queryLoading || !data) {
    return <LinearProgress />;
  }

  if (error) {
    return <GraphQLErrorPanel error={error} />;
  }

  const { inputs } = data.datasetDerivation;

  return (
    <>
      {visserverLoading && <LinearProgress />}
      <StyledTypography variant="h1">Bioprocessing</StyledTypography>
      <VisserverIFrame
        view="bioprocess"
        params={{
          dataset_ids: inputs.map(input => input.datasetId).join(','),
        }}
        onReady={() => setVisserverLoading(false)}
        onReload={() => setVisserverLoading(true)}
      />
    </>
  );
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(5, 0, 0, 6),
}));
