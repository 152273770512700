import React, { useCallback, useState } from 'react';

import CloudUpload from '@mui/icons-material/CloudUploadOutlined';
import ExtensionIcon from '@mui/icons-material/Extension';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';

import { useUpload } from 'client/app/api/filetree';
import { FileUploadData } from 'client/app/apps/execution-details/ResultsTab/hooks/useUploadLabwareFile';
import { LabwareCardData } from 'client/app/apps/execution-details/types';
import { useDataUploadPolling } from 'client/app/apps/simulation-details/overview/results/hooks';
import SyntheticDataDialog from 'client/app/apps/simulation-details/overview/results/SyntheticDataDialog';
import PathSelectorDialog from 'client/app/components/FileBrowser/PathSelectorDialog';
import SimpleDeviceSelectorDialog, {
  SimpleDeviceSelectorDialogWithPrefixItems,
} from 'client/app/components/SimpleDeviceSelectorDialog';
import { DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import AppIcon from 'client/app/icons/AppIcon';
import GenericDeviceParserIcon from 'client/app/icons/GenericDeviceParserIcon';
import { DEVICE_BASE_PATH } from 'client/app/lib/file-browser/path';
import {
  FileBrowserFileSelection,
  FileBrowserFileSingleSelection,
} from 'client/app/lib/file-browser/types';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { basename, concatURL } from 'common/lib/strings';
import { FileEditorValue, FileObject } from 'common/types/fileParameter';
import Button from 'common/ui/components/Button';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import DirectUploadEditor from 'common/ui/components/ParameterEditors/DirectUploadEditor';
import useDialog from 'common/ui/hooks/useDialog';

const GENERIC_DATA_ID = 'generic_data';
const GENERIC_DATA_PARSER = 'europe-docker.pkg.dev/synthace-images/synthace/data-processing-generic';

type Props = {
  labware: LabwareCardData;
  onAddFile: (data: FileUploadData) => Promise<DataUploadId | undefined>;
  onRefresh: () => Promise<void>;
};

function AddFileButton({ labware, onAddFile: doProcessing, onRefresh }: Props) {
  const isSyntheticDataEnabled = useFeatureToggle('SYNTHETIC_DATA');

  const [uploading, setUploading] = useState(false);
  const upload = useUpload();

  // When we initiate a data upload, we set uploadToPoll, and then this hook will repeatedly poll
  // the status of the upload and call the callback when it's done. At that point we call onRefresh
  // to show the new file(s).
  const [uploadToPoll, setUploadToPoll] = useState<DataUploadId | undefined>(undefined);
  useDataUploadPolling(uploadToPoll, async () => {
    setUploadToPoll(undefined);
    await onRefresh();
    setUploading(false);
  });

  // State that determines what the UI shows
  const [uploadMenuAnchor, setUploadMenuAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );

  // Dialogs
  const [pathSelectorDialog, openPathSelectorDialog] = useDialog(PathSelectorDialog);
  // A regular deviceSelectorDialog, for use in selecting a device for the file-from-device dialog.
  // This can't be the one with prefixItems because they have different types for their result
  // (Device | null versus Device | string | null).
  const [deviceSelectorDialog, openDeviceSelectorDialog] = useDialog(
    SimpleDeviceSelectorDialog,
  );
  // A deviceSelectorDialog with the generic upload card prefixed, for use in selecting a parser in
  // the file-from-computer flow.
  const [deviceSelectorDialogWithPrefixItems, openDeviceSelectorDialogWithPrefixItems] =
    useDialog(SimpleDeviceSelectorDialogWithPrefixItems);
  const [syntheticDataDialog, openSyntheticDataDialog] = useDialog(SyntheticDataDialog);

  // For uploading from the local machine, we need to first upload the file, then choose a device
  // for processing, then do the processing.  The actual upload is handled by DirectUploadEditor, so
  // when this callback is called the file is in Filetree and we just need to choose a device.
  const handleUploadFromComputer = useCallback(
    async (value: FileEditorValue) => {
      if (!value) {
        return;
      }
      setUploadMenuAnchorEl(null);
      const fileObject = value as FileObject;

      const selectDeviceResult = await openDeviceSelectorDialogWithPrefixItems({
        title: `Select source device of ${fileObject.Name} for ${labware.name}`,
        prefixItems: [
          {
            id: GENERIC_DATA_ID,
            title: 'Another device',
            body: "Data from devices that haven't been integrated into Synthace can be uploaded as external data.\n\nSelect this option to use our generic parser.",
            image: <GenericDeviceParserIcon />,
          },
        ],
      });
      if (selectDeviceResult) {
        try {
          setUploading(true);
          const fileUploadId = await doProcessing({
            labwareId: labware.id,
            link: fileObject.Path,
            filename: fileObject.Name,
            deviceId:
              selectDeviceResult === GENERIC_DATA_ID
                ? undefined
                : (selectDeviceResult as DeviceCommon).id,
            parser:
              selectDeviceResult === GENERIC_DATA_ID ? GENERIC_DATA_PARSER : undefined,
          });
          setUploadToPoll(fileUploadId);
        } catch {
          setUploading(false);
        }
      }
    },
    [doProcessing, labware.id, labware.name, openDeviceSelectorDialogWithPrefixItems],
  );

  // For uploading from a device, we need to choose the device, then choose the file in that
  // device's directory.  We'll assume that we want to use the same device for processing; it would
  // be weird to take data uploaded by one device and parse it as if it were data for another
  // device, and the user can download locally and reupload if they need to do that.
  const handleUploadFromDevice = useCallback(async () => {
    setUploadMenuAnchorEl(null);

    const selectDeviceResult = await openDeviceSelectorDialog({
      title: `Select source device for ${labware.name}`,
    });

    if (selectDeviceResult) {
      const { id, name } = selectDeviceResult;
      const devicePath = concatURL(DEVICE_BASE_PATH, id);

      let selectedPath: FileBrowserFileSelection = null;
      const pathSuccess = await openPathSelectorDialog({
        value: null,
        basePath: devicePath,
        onChange: val => {
          selectedPath = val;
        },
        overrideDialogTitle: `Select file from ${name} for ${labware.name}`,
      });
      if (pathSuccess && selectedPath) {
        const link = (selectedPath as FileBrowserFileSingleSelection).pathWithScheme;

        try {
          setUploading(true);
          const fileUploadId = await doProcessing({
            labwareId: labware.id,
            link,
            filename: basename(link),
            deviceId: id,
          });
          setUploadToPoll(fileUploadId);
        } catch {
          setUploading(false);
        }
      }
    }
  }, [
    doProcessing,
    labware.id,
    labware.name,
    openDeviceSelectorDialog,
    openPathSelectorDialog,
  ]);

  const handleSyntheticData = useCallback(async () => {
    setUploadMenuAnchorEl(null);

    const selectDeviceResult = await openDeviceSelectorDialog({
      title: `Select device to generate data for ${labware.name}`,
    });
    if (selectDeviceResult) {
      const { id, name } = selectDeviceResult;
      const syntheticDataFile = await openSyntheticDataDialog({
        deviceId: id,
        deviceName: name,
        labwareName: labware.name,
      });
      if (syntheticDataFile) {
        try {
          setUploading(true);
          const fileUploadId = await doProcessing({
            labwareId: labware.id,
            link: syntheticDataFile,
            filename: basename(syntheticDataFile),
            deviceId: id,
          });
          setUploadToPoll(fileUploadId);
        } catch {
          setUploading(false);
        }
      }
    }
  }, [
    doProcessing,
    labware.id,
    labware.name,
    openDeviceSelectorDialog,
    openSyntheticDataDialog,
  ]);

  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setUploadMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setUploadMenuAnchorEl(null);
  }, []);

  return (
    <>
      <Button
        color="primary"
        variant="secondary"
        startIcon={uploading ? <CircularProgress size="14px" /> : <CloudUpload />}
        onClick={handleOpenMenu}
        disabled={uploading}
      >
        Add file
      </Button>
      <Menu
        anchorEl={uploadMenuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!uploadMenuAnchor}
        onClose={handleCloseMenu}
      >
        <Typography variant="body2" sx={{ ml: 5, mt: 2 }}>
          FROM
        </Typography>
        <DirectUploadEditor
          value={null}
          renderType="MenuItem"
          onChange={handleUploadFromComputer}
          setIsUploading={setUploading}
          onUpload={upload}
        />
        <MenuItemWithIcon
          onClick={handleUploadFromDevice}
          icon={<AppIcon iconId="antha:device" />}
          text="Device"
        />
        {isSyntheticDataEnabled && (
          <MenuItemWithIcon
            onClick={handleSyntheticData}
            icon={<ExtensionIcon />}
            text="Synthetic Data"
          />
        )}
      </Menu>
      {deviceSelectorDialog}
      {deviceSelectorDialogWithPrefixItems}
      {pathSelectorDialog}
      {syntheticDataDialog}
    </>
  );
}

export default AddFileButton;
