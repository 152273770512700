import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import { deviceFromGraphQL } from 'client/app/api/deviceFromGraphql';
import { QUERY_ALL_DEVICES } from 'client/app/api/gql/queries';
import DeviceItems from 'client/app/apps/workflow-builder/panels/workflow-settings/devices/DeviceItems';
import { useWorkflowSettingsState } from 'client/app/apps/workflow-builder/panels/workflow-settings/workflowSettingsState';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { ConfiguredDevice, ConfiguredDeviceDeviceId } from 'common/types/bundle';
import {
  addAccessibleDevice,
  removeAccessibleDeviceByDeviceId,
} from 'common/types/bundleConfigUtils';
import { Device, SimpleDevice } from 'common/types/device';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/**
 * This component is used for showing the selected devices and a button to open the SelectDevicesPanel
 * to allow the user to select the devices. This differs from DeviceSelector which opens up a dialog when
 * you are selecting devices.
 *
 * TODO: Handle missing device + missing run config.
 *
 */
export default function DeviceSelectorCard() {
  const classes = useStyles();
  const {
    additionalPanel,
    allConfiguredDevices,
    configuredDevicesForSelectedStage,
    isReadonly,
  } = useWorkflowSettingsState();
  const dispatch = useWorkflowBuilderDispatch();
  const { stages } = useWorkflowBuilderSelector(state => state);
  // We need to fetch all of the devices because the device that we are currently getting from the
  // workflow doesn't have all the info that we need to show the device item (e.g. the image url).
  // Most customers have a small number of devices so it is okay that we are doing a query for all
  // devices.
  const { data, loading } = useQuery(QUERY_ALL_DEVICES);
  // We need to support legacy workflows with 0 stages for readonly display, and in this case, we show all devices.
  const configuredDevices =
    stages.length > 0 ? configuredDevicesForSelectedStage : allConfiguredDevices;
  const selectedDevices = useMemo<Device[]>(() => {
    const configuredDeviceIds = configuredDevices.map(cd => cd.deviceId);
    return (
      data?.devices
        .filter(device => configuredDeviceIds.includes(device.id))
        .map(deviceFromGraphQL) ?? []
    );
  }, [configuredDevices, data?.devices]);

  const onSelectedDevicesChange = useCallback(
    (newConfiguredDevices: ConfiguredDevice[]) => {
      dispatch({
        type: 'setSelectedDevices',
        payload: newConfiguredDevices,
      });
    },
    [dispatch],
  );

  const deviceSelectorPanelIsOpen = additionalPanel === 'DeviceSelector';

  const handleToggleDeviceSelectorPanel = useCallback(() => {
    dispatch({
      type: 'setAdditionalPanel',
      payload: deviceSelectorPanelIsOpen ? undefined : 'DeviceSelector',
    });
  }, [dispatch, deviceSelectorPanelIsOpen]);

  const handleAccessibleDeviceEnabledChange = useCallback(
    (accessibleDevice: SimpleDevice, isEnabled: boolean) => {
      if (!isEnabled) {
        const newConfiguredDevices = removeAccessibleDeviceByDeviceId(
          configuredDevices,
          accessibleDevice.id as ConfiguredDeviceDeviceId,
        );
        onSelectedDevicesChange?.(newConfiguredDevices);
      } else {
        const parentDevice = selectedDevices.find(device =>
          device.accessibleDevices.some(a => a.id === accessibleDevice.id),
        );
        if (!parentDevice) {
          // TODO(CI-1299): Decide if we should be logging this in a better way.
          console.error(
            `Trying to enable an accessible device ${accessibleDevice.id} ` +
              'but there is no corresponding parent device in the workflow config. ' +
              'This can only happen if the workflow config is in a bad state due to a bug.',
          );
          return;
        }
        const newConfiguredDevices = addAccessibleDevice(
          configuredDevices,
          accessibleDevice,
          parentDevice.id,
        );
        onSelectedDevicesChange?.(newConfiguredDevices);
      }
    },
    [configuredDevices, onSelectedDevicesChange, selectedDevices],
  );

  const isEditable =
    !isReadonly && !!onSelectedDevicesChange && !deviceSelectorPanelIsOpen;
  const areDevicesSelected = selectedDevices.length > 0;

  return (
    <>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <DeviceItems
            devices={selectedDevices}
            configuredDevices={configuredDevices}
            isEditable={isEditable}
            onAccessibleDeviceEnabledChange={
              (isEditable && handleAccessibleDeviceEnabledChange) || undefined
            }
          />
          {!isReadonly && (
            <div className={classes.deviceButton}>
              <Button
                className={classes.smallButton}
                color="primary"
                variant="secondary"
                onClick={handleToggleDeviceSelectorPanel}
                size="small"
              >
                {`${areDevicesSelected ? 'Change' : 'Select'} Execution Mode`}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  deviceButton: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
  },
  message: {
    margin: theme.spacing(3, 0),
  },
  smallButton: {
    height: '24px',
  },
}));
