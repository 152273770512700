import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';

import { useFiltrationProtocolDesign } from 'client/app/components/Parameters/FiltrationProtocolDesign/FiltrationProtocolDesignState';
import LiquidGroupCard from 'client/app/components/Parameters/FiltrationProtocolDesign/LiquidGroupCard';
import LiquidGroupForm from 'client/app/components/Parameters/FiltrationProtocolDesign/LiquidGroupForm';
import { LiquidGroup } from 'client/app/components/Parameters/FiltrationProtocolDesign/types';
import LabBenchBanner from 'common/assets/LabBenchBanner';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export default function LiquidsTab({ isReadonly }: { isReadonly: boolean }) {
  const classes = useStyles();
  const { liquidGroups } = useFiltrationProtocolDesign();
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [selectedLiquidGroup, selectLiquidGroup] = useState<LiquidGroup | undefined>();

  const addButton = (
    <Button
      startIcon={<AddIcon />}
      variant="secondary"
      color="primary"
      disabled={showSidePanel || isReadonly}
      onClick={() => {
        setShowSidePanel(true);
        selectLiquidGroup(undefined);
      }}
    >
      Add New Liquid Group
    </Button>
  );

  return (
    <main className={classes.container}>
      {liquidGroups.length > 0 ? (
        <section className={classes.content}>
          {addButton}
          <div className={classes.list}>
            {liquidGroups.map(liquidGroup => (
              <LiquidGroupCard
                key={liquidGroup.clientId}
                liquidGroup={liquidGroup}
                selectable={!showSidePanel || isReadonly}
                selected={selectedLiquidGroup?.clientId === liquidGroup.clientId}
                onClick={() => {
                  if (!showSidePanel || isReadonly) {
                    setShowSidePanel(true);
                    selectLiquidGroup(liquidGroup);
                  }
                }}
                isReadonly={isReadonly}
              />
            ))}
          </div>
        </section>
      ) : (
        <section className={classes.empty}>
          <LabBenchBanner className={classes.emptyBanner} />
          <div className={classes.emptyCopy}>
            <Typography variant="body1">
              There are currently no liquid groups defined.
            </Typography>
          </div>
          {addButton}
        </section>
      )}
      {showSidePanel && (
        <section className={classes.sidebar}>
          <LiquidGroupForm
            selectedLiquidGroup={selectedLiquidGroup}
            onClose={() => {
              setShowSidePanel(false);
              selectLiquidGroup(undefined);
            }}
            isReadonly={isReadonly}
          />
        </section>
      )}
    </main>
  );
}

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  container: {
    display: 'grid',
    gridTemplate: `'list sidebar' 1fr / minmax(0, 1fr) auto`,
    flex: 1,
    placeItems: 'stretch',
    borderTop: `1px solid ${palette.grey[300]}`,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(5),
    padding: spacing(6),
    alignItems: 'start',
  },
  list: {
    gridArea: 'list',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(4),
    alignItems: 'stretch',
    alignSelf: 'stretch',
    flexGrow: 1,
    height: '1px', // this is necessary for overflow to scroll while the real size is defined by flexGrow
    overflowY: 'auto',
  },
  sidebar: {
    gridArea: 'sidebar',
    width: '344px',
    borderLeft: `1px solid ${palette.grey[300]}`,
    display: 'grid',
    gridTemplate: '1fr / 1fr',
    placeItems: 'stretch',
  },
  emptyBanner: {
    width: '342px',
    height: '192px',
  },
  empty: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(6),
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyCopy: {
    display: 'flex',
    gap: spacing(2),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: spacing(6),
  },
}));
