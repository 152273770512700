import React, { useCallback, useState } from 'react';

import cx from 'classnames';

import PathSelectorDialog from 'client/app/components/FileBrowser/PathSelectorDialog';
import SimulationsDialogContainer from 'client/app/components/Parameters/SimulationsDialog/SimulationsDialogContainer';
import { ArrayElement, SimulationsForDialogQuery } from 'client/app/gql';
import getFileObjectFromPath from 'client/app/lib/file-browser/getFileObjectFromPath';
import { JOB_BASE_PATH } from 'client/app/lib/file-browser/path';
import {
  FileBrowserFileSelection,
  FileBrowserFileSingleSelection,
} from 'client/app/lib/file-browser/types';
import { concatURL } from 'common/lib/strings';
import { FileObject } from 'common/types/fileParameter';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

// We render two contents for this dialog, which will determine what we show
// in the surrounding dialog container (e.g. ArrowBackIcon, or Select button).
enum DialogPages {
  SIMULATIONS_LIST_DIALOG,
  PATH_SELECTOR_DIALOG,
}

type Props = DialogProps<FileObject | null>;

/**
 * SimulationAndPathSelectionDialog provides a dialog container in which the user
 * can navigate back and forth between a list of all successfully executed simulations,
 * and (upon selecting a simulation) all the files from that simulation.
 * Selecting a file will return that file in the onClose prop that is handled by
 * the useDialog hook and can be processed by the caller.
 */
export default React.memo(function SimulationAndPathSelectionDialog({
  isOpen,
  onClose,
}: Props) {
  const classes = useStyles();

  const [currentDialogContent, setCurrentDialogContent] = useState<DialogPages>(
    DialogPages.SIMULATIONS_LIST_DIALOG,
  );
  const [selectedPath, setselectedPath] = useState<string | undefined>('');
  const [selectedSimulationName, setSelectedSimulationName] = useState('');

  type SimulationsForDialogItem = ArrayElement<
    SimulationsForDialogQuery['simulations']['items']
  >;

  const onSelectSimulationFromDialog = useCallback(
    (simulation: SimulationsForDialogItem) => {
      const { name: simulationName, id: simulationId } = simulation;
      if (simulationId) {
        setselectedPath(concatURL(JOB_BASE_PATH, simulationId));
        setSelectedSimulationName(simulationName);
      }
      setCurrentDialogContent(DialogPages.PATH_SELECTOR_DIALOG);
    },
    [],
  );

  const onCancel = useCallback(() => {
    onClose(null);
  }, [onClose]);

  const onBackButtonClick = useCallback(() => {
    setCurrentDialogContent(DialogPages.SIMULATIONS_LIST_DIALOG);
    setselectedPath(undefined);
  }, []);

  const onConfirmSelectionClick = useCallback(
    (value: FileBrowserFileSelection) => {
      onClose(
        getFileObjectFromPath((value as FileBrowserFileSingleSelection).pathWithScheme),
      );
    },
    [onClose],
  );

  const showPathSelectionPage =
    currentDialogContent === DialogPages.PATH_SELECTOR_DIALOG && selectedPath;

  return (
    <>
      <SimulationsDialogContainer
        onSelectSimulationFromDialog={onSelectSimulationFromDialog}
        onClose={onCancel}
        isOpen={isOpen}
        dialogClassName={cx({
          [classes.hideDialogPage]: showPathSelectionPage,
        })}
      />
      {selectedPath && (
        <PathSelectorDialog
          basePath={selectedPath}
          selectMultiple={false}
          value={null}
          overrideDialogTitle={`Select file from "${selectedSimulationName}"`}
          onBackClick={onBackButtonClick}
          onChange={onConfirmSelectionClick}
          onClose={onCancel}
          isOpen={isOpen}
          dialogClassName={cx({
            [classes.hideDialogPage]: !showPathSelectionPage,
          })}
        />
      )}
    </>
  );
});

const useStyles = makeStylesHook({
  hideDialogPage: {
    // We want to hide certain components depending on what dialog content
    // is selected, and not have those components retain their screen space
    // or lose their state (e.g. the SimulationsDialogContainer).
    display: 'none',
  },
});
