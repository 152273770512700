/**
 * Sometimes we need a state that can be used in a callback without being staled.
 * We could use a ref, but then a change wouldn't tigger a re-render.
 *
 * Let's use both.
 * This hooks returns a state, its setter and a ref that point to the most up to date state.
 */

import { useEffect, useRef, useState } from 'react';

export default function useStateWithRef<S>(
  initialState: S | (() => S),
): [S, React.Dispatch<React.SetStateAction<S>>, React.MutableRefObject<S>] {
  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  });

  return [state, setState, stateRef];
}
