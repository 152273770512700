import { useCallback } from 'react';

import { useFetchMixPreview } from 'client/app/api/MixPreviewApi';
import {
  ArrayElement,
  SimulationOrExecutionStatusesEnum,
  SimulationQuery,
} from 'client/app/gql';
import { isDefined } from 'common/lib/data';
import { Deck, Plate } from 'common/types/mix';
import { platesOnly } from 'common/ui/components/simulation-details/mix/deckContents';

type SimulationTask = ArrayElement<NonNullable<SimulationQuery['simulation']['tasks']>>;

/**
 * Given a Simulation, finds the liquid handling tasks in the task list.
 */
export function getLiquidHandlingTasks(tasks: readonly SimulationTask[] | undefined) {
  if (!tasks) {
    return undefined;
  }
  return tasks.filter((current: SimulationTask | undefined) => {
    return current?.type === 'mix';
  });
}

export function useFetchMixPreviewForSimulation() {
  const fetchMixPreview = useFetchMixPreview();
  return useCallback(
    async function fetchMixPreviewForSimulation(
      simulationId: SimulationId,
      simulationActionsLayoutFiletreeLink: FiletreeLink | null,
    ) {
      if (!simulationActionsLayoutFiletreeLink) {
        // If the workflow does no liquid handling, we have no data for the Preview.
        return null;
      }
      try {
        return await fetchMixPreview(simulationId, simulationActionsLayoutFiletreeLink);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [fetchMixPreview],
  );
}

const simulationStatusesWithExecution = [
  SimulationOrExecutionStatusesEnum.EXECUTION_READY,
  SimulationOrExecutionStatusesEnum.EXECUTION_SCHEDULED,
  SimulationOrExecutionStatusesEnum.EXECUTION_IN_PROGRESS,
  SimulationOrExecutionStatusesEnum.EXECUTION_SUCCESS,
  SimulationOrExecutionStatusesEnum.EXECUTION_FAILED,
  SimulationOrExecutionStatusesEnum.EXECUTION_COMPLETED,
];

/**
 * Returns true if the simulation has an associated execution
 */
export function hasExecution(simulation: SimulationQuery['simulation']): boolean {
  return (
    simulation.execution !== null &&
    simulationStatusesWithExecution.includes(simulation.transitiveStatus)
  );
}

export type OverviewPlate = {
  name: string;
  plate: Plate;
  plateFileName: string;
};

export function extractPlates(deck: Deck): OverviewPlate[] {
  const finalPlates = platesOnly(
    Object.values(deck.after.positions)
      .map(pos => pos.item)
      .filter(isDefined),
  );
  if (finalPlates.length === 0) {
    return [];
  }

  return finalPlates.map(plate => {
    const plateFileName = `${plate.name}.csv`;
    return {
      name: plate.name,
      plate,
      plateFileName,
    };
  });
}
