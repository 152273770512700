import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
export default function useSvgScale(
  ref: React.RefObject<SVGSVGElement>,
  width: number,
  height: number,
) {
  const [scale, setScale] = useState(1);
  const updateScale = useCallback(() => {
    if (!ref.current) {
      return;
    }
    const scaleClient = Math.max(
      width / ref.current.clientWidth,
      height / ref.current.clientHeight,
    );
    setScale(scaleClient);
  }, [setScale, width, height, ref]);
  useLayoutEffect(updateScale, [updateScale]);

  useEffect(() => {
    window.addEventListener('resize', updateScale);
    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, [updateScale]);
  return scale;
}
