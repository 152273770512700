import React from 'react';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

type Props = {
  imageUrl: string | null;
} & WithStyles<typeof styles>;

function DeviceThumbnail({ classes, imageUrl }: Props) {
  return <img className={classes.deviceThumbnail} src={imageUrl ?? undefined} />;
}

const styles = createStyles({
  deviceThumbnail: {
    height: '48px',
    objectFit: 'contain',
    width: '48px',
  },
});

export default withStyles(styles)(DeviceThumbnail);
