import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { OutlinedTextFieldProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import cx from 'classnames';

import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import getMeasurementFromString from 'common/ui/components/ParameterEditors/unitRegistry';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type AdditionalTextFieldProps = Omit<
  OutlinedTextFieldProps,
  'value' | 'onChange' | 'variant'
>;

type ExtraProps =
  | {
      hideError: true;
      overrideErrorState?: boolean;
    }
  | {
      hideError?: false;
      overrideErrorState?: never;
    };

type Props = {
  units: string[];
  onChange: (value?: string) => void;
  defaultUnit?: string;
  disableUnderline?: boolean;
  className?: string;
  textFieldProps?: AdditionalTextFieldProps;
} & ParameterEditorBaseProps<string> &
  ExtraProps;

const StringMeasurementEditor = React.memo(function StringMeasurementEditor(
  props: Props,
) {
  const classes = useStyles();
  const { onChange, value, units, defaultUnit } = props;
  const measurement = useMemo(
    () => getMeasurementFromString(value ?? '', undefined, units),
    [units, value],
  );

  const [currentValue, setCurrentValue] = useState('');
  const [currentUnit, setCurrentUnit] = useState(defaultUnit ?? '');
  useEffect(() => {
    const hasValue = !isNaN(measurement.value);
    const initialValue = hasValue ? measurement.value.toString() : '';
    setCurrentValue(initialValue);

    if (hasValue && !measurement.unit) {
      setCurrentUnit('');
    } else {
      setCurrentUnit(measurement.unit || defaultUnit || '');
    }
  }, [defaultUnit, measurement.unit, measurement.value]);

  const onBlurValue = useCallback(() => {
    const newValue = formatValue(currentValue, currentUnit);
    if (newValue === value) {
      return;
    }

    onChange(newValue);
  }, [currentUnit, currentValue, onChange, value]);

  const onChangeUnit = useCallback(
    (_event: any, unit: string | null) => {
      if (unit) {
        setCurrentUnit(unit);
      }
      const newValue = formatValue(currentValue, unit ?? '');
      onChange(newValue);
    },
    [currentValue, onChange],
  );

  const onChangeValue = useTextFieldChange((value: string) => {
    /**
     * We don't change the props when the user types here, because we
     * would then be running getMeasurementFromString() on every change
     * which validates by default against a large number of units in the
     * unitRegistry. So wait until the user has completed (using onBlurValue)
     * and only run getMeasurementFromString() once.
     * */
    setCurrentValue(value);
  });

  const validationError =
    currentValue !== '' && !/^[0-9.+\-eE\b]+$/.test(currentValue)
      ? 'Only numbers are valid'
      : '';

  const errorState = props.hideError
    ? props.overrideErrorState ?? false
    : validationError !== '';

  const helperText = props.hideError ? null : validationError !== '' && validationError;

  const isSingleUnitOption = units.length === 1 && !!defaultUnit;

  return (
    <div className={cx(classes.box, props.className)}>
      <TextField
        {...props.textFieldProps}
        className={classes.value}
        placeholder={props.placeholder ?? ''}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          ...props.textFieldProps?.InputProps,
          disableUnderline: props.disableUnderline,
        }}
        inputRef={props.textFieldProps?.inputRef}
        value={currentValue}
        onChange={onChangeValue}
        onBlur={onBlurValue}
        disabled={props.isDisabled}
        required={props.isRequired}
        error={errorState}
        helperText={helperText}
        inputProps={{
          style: { textAlign: 'right' },
          ...props.textFieldProps?.inputProps,
        }}
      />
      <Autocomplete
        autoSelect
        disableClearable
        disabled={props.isDisabled || currentValue === ''}
        readOnly={isSingleUnitOption}
        forcePopupIcon={!isSingleUnitOption}
        selectOnFocus={!isSingleUnitOption}
        options={units}
        className={classes.unit}
        value={currentUnit}
        onChange={onChangeUnit}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              disableUnderline: props.disableUnderline,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    </div>
  );
});

const useStyles = makeStylesHook({
  box: {
    display: 'flex',
  },
  unit: {
    flex: 1,
  },
  value: {
    flex: 1.25,
    'margin-right': '10px',
  },
});

function formatValue(value: string, unit: string) {
  const num = parseFloat(value);
  if (isNaN(num)) {
    return '';
  }
  return `${num}${unit ?? ''}`;
}

export default StringMeasurementEditor;
