import React from 'react';

import DataTableOutput from 'client/app/components/ElementPlumber/ElementOutputs/components/DataTableOutput';
import ErrorPreview from 'client/app/components/ElementPlumber/ElementOutputs/components/ErrorPreview';
import LiquidsOutput from 'client/app/components/ElementPlumber/ElementOutputs/components/LiquidsOutput';
import LoadingPreview from 'client/app/components/ElementPlumber/ElementOutputs/components/LoadingPreview';
import NoPreview from 'client/app/components/ElementPlumber/ElementOutputs/components/NoPreview';
import NumberOutput from 'client/app/components/ElementPlumber/ElementOutputs/components/NumberOutput';
import {
  getOutputVisualisationType,
  isDataTableOutput,
  isLiquidCountOutput,
  isLiquidOutput,
  isNumber,
} from 'client/app/components/ElementPlumber/ElementOutputs/helpers';
import { OutputEntity } from 'client/app/components/ElementPlumber/ElementOutputs/types';
import { useElementParameterOutput } from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import { ElementOutput, Parameter } from 'common/types/bundle';

type Props = {
  elementId: string;
  parameter: Parameter;
  entity?: OutputEntity;
  withDropdown?: boolean;
  onClick?: (entity?: OutputEntity) => void;
};

export default function OutputVisualisation({
  elementId,
  parameter,
  entity,
  withDropdown = false,
  onClick,
}: Props) {
  const [isLoading, output = null, errors] = useElementParameterOutput<ElementOutput>(
    elementId,
    parameter.name,
  );
  const type = getOutputVisualisationType(parameter);

  if (isLoading) {
    return <LoadingPreview />;
  }
  if (errors && errors.length > 0) {
    return <ErrorPreview errors={errors} />;
  }
  if (type === undefined) {
    return <NoPreview label="No preview available" />;
  }
  if (output === null) {
    return <NoPreview label="No output data" />;
  }
  if (isLiquidOutput(type, output)) {
    return (
      <LiquidsOutput
        elementId={elementId}
        parameterName={parameter.name}
        type={type}
        value={output}
        entity={entity}
        withDropdown={withDropdown}
        onClick={onClick}
      />
    );
  }
  if (isLiquidCountOutput(type, output)) {
    return <NumberOutput value={output.default} />;
  }
  if (isNumber(type, output)) {
    return <NumberOutput value={output} />;
  }
  if (isDataTableOutput(type, output)) {
    return (
      <DataTableOutput
        elementId={elementId}
        parameterName={parameter.name}
        value={output}
        entity={entity}
        withDropdown={withDropdown}
        onClick={onClick}
      />
    );
  }
  throw new Error(`"${type}" is not supported for output visualisation`);
}
