import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { AccessibleDeviceSetup } from 'common/types/device';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useCheckboxChange from 'common/ui/hooks/useCheckboxChange';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  orientation: AccessibleDeviceSetup['orientation'];
  allowRotatePlate: boolean;
  disabled?: boolean;
  onOrientationChange: (orientation: AccessibleDeviceSetup['orientation']) => void;
  onAllowRotatePlateChange: (allowRotatePlate: boolean) => void;
};

export default function AccessibleDeviceSetupTabOrientation({
  orientation,
  allowRotatePlate,
  disabled,
  onOrientationChange,
  onAllowRotatePlateChange,
}: Props) {
  const classes = useStyles();

  const handleOrientationChange = useTextFieldChange((orientation: string) => {
    let validOrientation: AccessibleDeviceSetup['orientation'];
    switch (orientation) {
      case 'left':
      case 'right':
      case 'front':
      case 'rear':
        validOrientation = orientation;
        onOrientationChange(validOrientation);
        break;
      default:
        throw new Error('Invalid orientation: ' + orientation);
    }
  });

  const handleAllowRotatePlateChange = useCheckboxChange(onAllowRotatePlateChange);

  return (
    <div className={classes.tabOrientation}>
      <RadioGroup
        className={classes.orientations}
        value={orientation}
        onChange={handleOrientationChange}
      >
        <DeviceOrientationOption
          label="front"
          rotation={0}
          platePosition="below"
          disabled={disabled}
        />
        <DeviceOrientationOption
          label="rear"
          rotation={0}
          platePosition="above"
          disabled={disabled}
        />
        <DeviceOrientationOption
          label="left"
          rotation={90}
          platePosition="below"
          disabled={disabled}
        />
        <DeviceOrientationOption
          label="right"
          rotation={90}
          platePosition="above"
          disabled={disabled}
        />
      </RadioGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={allowRotatePlate}
            disabled={disabled}
            onChange={handleAllowRotatePlateChange}
          />
        }
        label="Allow plate to be rotated"
      />
    </div>
  );
}

function DeviceOrientationOption({
  label,
  rotation,
  platePosition,
  disabled,
}: {
  label: string;
  rotation: number;
  platePosition: 'above' | 'below';
  disabled?: boolean;
}) {
  const classes = useStyles();
  return (
    <div className={classes.orientationPreview}>
      <FormControlLabel
        className={cx(classes.capitalize, classes.orientationRadioButton)}
        value={label}
        control={<Radio />}
        label={label}
        disabled={disabled}
      />
      <div
        className={classes.deviceAndPlate}
        style={{ transform: `rotate(${rotation}deg)` }}
      >
        {platePosition === 'above' && <PlateSketch />}
        <div className={classes.deviceSketch}>
          <Typography>DEVICE</Typography>
        </div>
        {platePosition === 'below' && <PlateSketch />}
      </div>
    </div>
  );
}

function PlateSketch() {
  const classes = useStyles();
  // These tiny circles represent the small labels 1..12 on the edge of the plate.
  // Since the plate sketch is so small, they would not be readable, and showing
  // a small dot to represent each well label works.
  // These are the dots along the top side of the plate.
  const horizontalDots = [...Array(12).keys()]
    .map(i => i + 1)
    .map(i => (
      <circle
        key={`horiz-dot-${i}`}
        // Divide the space for 12 dots evenly, and shift a little
        cx={`${(i * 100) / 13 + 2}%`}
        cy="2"
        r="0.6"
        fill={Colors.GREY_50}
      />
    ));
  // The dots along the left side of the plate.
  const verticalDots = [...Array(8).keys()]
    .map(i => i + 1)
    .map(i => (
      <circle
        key={`vert-dot-${i}`}
        cx="2"
        // Divide the space for 8 dots evenly, and shift a little
        cy={`${(i * 100) / 9 + 3}%`}
        r="0.6"
        fill={Colors.GREY_50}
      />
    ));
  return (
    <div className={classes.plateSketch}>
      <Typography>PLATE</Typography>
      <svg className={classes.dotsSketchSvg}>
        {horizontalDots}
        {verticalDots}
      </svg>
    </div>
  );
}

const sketchRectangleStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${Colors.GREY_50}`,
  borderRadius: '4px',
  color: Colors.TEXT_DISABLED,
};

const useStyles = makeStylesHook(theme => ({
  tabOrientation: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  orientations: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
    marginBottom: theme.spacing(6),
  },
  orientationRadioButton: {
    marginBottom: theme.spacing(7),
  },
  orientationPreview: {
    display: 'flex',
    flexDirection: 'column',
  },
  deviceAndPlate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  deviceSketch: {
    width: '154px',
    height: '90px',
    ...sketchRectangleStyle,
  },
  plateSketch: {
    width: '82px',
    height: '60px',
    ...sketchRectangleStyle,
    margin: theme.spacing(3),
    position: 'relative',
  },
  dotsSketchSvg: {
    position: 'absolute',
    left: '3px',
    top: '3px',
    width: '90%',
    height: '90%',
  },
}));
