import React, { useEffect } from 'react';

import Fab, { FabProps } from '@mui/material/Fab';
import cx from 'classnames';
import { animated, to, useSpring, UseSpringProps } from 'react-spring';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

// Same as extended Fab
const FAB_BORDER_RADIUS = '24px';

const FX_OUTER = Colors.INDIGO_30;
const FX_INNER = 'var(--bg)';

// Where does the border starts and ends.
const [start, end] = ((start, delta) => [start, start + delta])(-90, 720);
// These values were arbitrarily determined and tuned based on what seems to make sense.
const fxConfig = {
  mass: 20,
  tension: 64,
  friction: 54,
  clamp: true,
};
const GRADIENT_THICKNESS = 40;
const GROWING_GRADIENT_STEP = GRADIENT_THICKNESS / 360;
const springProps: UseSpringProps = {
  from: { keyframes: 0 },
  config: fxConfig,
};

/**
 * Create a background image that will appear as the border of the Fab.
 * 1. It has a linear-gradient with one color as a safety background in case the rounded border of the Fab mismatches with the Fx wrapper.
 * 2. (More importantly!) It has a conic-gradient that is animated to give the effect of a rotating and growing border.
 *
 * To achieve this we animate 2 things:
 * - when the gradient starts, it will do 2 turns.
 * - the gradient steps between FX_OUTER and FX_INNER, which we call gradient thickness.
 *
 * The gradient thickness grows quickly to its max (GRADIENT_THICKNESS), stabilizes for a while and then goes back to 0.
 * When the initial thickness is growing, and the final shortening happens, we need to adjust the gradient start.
 *
 */
const interpolateBackgroundImage = (degree: number, gradientDelta: number) =>
  `linear-gradient(var(--bg), var(--bg)),
  conic-gradient(from ${degree}deg, ${FX_OUTER},${FX_OUTER},
     ${FX_INNER} ${GRADIENT_THICKNESS / 2 + gradientDelta}deg,
      ${FX_INNER} ${
    360 - GRADIENT_THICKNESS / 2 - gradientDelta
  }deg, ${FX_OUTER},${FX_OUTER})`;

function useFX(isAnimating: boolean) {
  const [{ keyframes }, api] = useSpring(() => springProps);

  // how thick is the inner part of the conic gradient
  const gradientDelta = keyframes.to({
    range: [0, GROWING_GRADIENT_STEP, 1 - GROWING_GRADIENT_STEP, 1],
    output: [-GRADIENT_THICKNESS / 2, 0, 0, -GRADIENT_THICKNESS / 2],
  });

  // When the gradient starts, this gives the rotation effect.
  // We need to compensate for the gradientThickness growing.
  const degree = keyframes.to({
    range: [0, GROWING_GRADIENT_STEP, 1 - GROWING_GRADIENT_STEP, 1],
    output: [start, start + GRADIENT_THICKNESS / 2, end - GRADIENT_THICKNESS / 2, end],
  });

  /*
   * When the Fx is not on, the Fab's ripple effect stops before the Fx border. The contrast looks like a border on the Fab
   * so to fix this, we are immediately resetting various aspects to act like a normal Fab.
   */
  const style = isAnimating
    ? {
        backgroundImage: to([degree, gradientDelta], interpolateBackgroundImage),
      }
    : {
        border: 'solid 3px var(--bg)',
        // This applies the same transition to the border of animated div than the Fab's background
        transition: 'border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        // when the fx finishes, the border will transition from transparent to var(--bg), this is ugly, so we put immediatly a bg
        backgroundImage:
          'linear-gradient(var(--bg), var(--bg)),linear-gradient(var(--bg), var(--bg))',
      };

  useEffect(() => {
    if (isAnimating) {
      api.start({
        to: { keyframes: 1 },
        loop: true,
        reset: true,
      });
    } else {
      api.stop(true);
    }
  }, [api, isAnimating]);

  return { style };
}

export default React.memo(function FabWithEffect(props: FabProps & { busy: boolean }) {
  const classes = useStyles({ color: props.color ?? 'secondary' });
  const { disabled, onClick, busy, ...fabProps } = props;
  const { style } = useFX(!!busy);
  if (disabled) {
    return <Fab onClick={onClick} disabled {...fabProps} />;
  }
  return (
    <animated.div className={cx(classes.container)} style={style}>
      <Fab
        {...fabProps}
        className={cx(props.className, classes.negativeMargin, classes.fab)}
        disabled={disabled}
        onClick={onClick}
      />
    </animated.div>
  );
});

const useStyles = makeStylesHook<string, { color: 'primary' | 'secondary' }>(theme => ({
  container: ({ color }) => ({
    '--bg': theme.palette[color].main,
    '&:hover': {
      '--bg': theme.palette[color].dark,
    },
    borderRadius: FAB_BORDER_RADIUS,
    backgroundClip: 'padding-box, border-box',
    border: '3px solid transparent',
    backgroundOrigin: 'border-box',
    boxShadow: theme.shadows[6], // Same as the original Fab.
    '&:active': {
      boxShadow: theme.shadows[12],
    },
  }),
  /* On Chrome there is tiny blue border appearing between the Fab and the rotating border.
   * By putting this negative margin, the Fab will cover that border.
   */
  negativeMargin: {
    margin: '-1px',
  },
  fab: {
    boxShadow: 'none',
  },
}));
