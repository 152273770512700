import React from 'react';

import { ParameterMode } from 'client/app/apps/cherry-picker/CherryPickLayout';
import DeviceSettings from 'client/app/apps/cherry-picker/cp-settings/DeviceSettings';
import EnforceOrderSettings from 'client/app/apps/cherry-picker/cp-settings/EnforceOrderSettings';
import SimulationSettings from 'client/app/apps/cherry-picker/cp-settings/OtherSimulationSettings';
import PlateSettings from 'client/app/apps/cherry-picker/cp-settings/PlateSettings';
import PolicySettings from 'client/app/apps/cherry-picker/cp-settings/PolicySettings';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

// This useful map {Liquid name: policy name} allows the users to
// easily modify the policy for all the rows with a specific liquid name.
export type PolicyByLiquid = { [liquidName: string]: string };

type Props = {
  parameterMode?: ParameterMode;
};

export default function CherryPicKSettings({ parameterMode }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <EnforceOrderSettings />
      {!parameterMode && (
        <>
          <DeviceSettings />
          <SimulationSettings />
        </>
      )}
      <PlateSettings />
      <PolicySettings />
    </div>
  );
}

const useStyles = makeStylesHook({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '1rem',
    margin: '1rem',
  },
});
