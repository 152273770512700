/*
 * This file contains the file types that are used by antha-com for keeping track of the
 * various file types used in uploading and downloading including the legacy bytes,
 * datarepo format and filetree format.
 */

/** Remote file representation in v1 bundle */
export type FileObjectLegacy = {
  name: string;
  hostFile: {
    filename: string;
  };
};

/** Remote file representation in v2 bundle */
export type FileObject = {
  Name: string;
  Path: string;
  IsOutput?: boolean;
};

/** Deprecated. Base64 encoded file - should only be used by LHPolicyEditor at this point */
export type DirectUploadSingleValueLegacy = {
  name: string;
  bytes: {
    bytes: string;
  };
};

/**
 * Deprecated. Maps to the FileSeries antha type - only used in the Upload
 * Raman Spectra element. Prefer File[] over using this.
 * */
export type FileSeries = {
  Files: (FileObject | FileObjectLegacy)[];
};

export type GenericFile = DirectUploadSingleValueLegacy | FileObjectLegacy | FileObject;

// DirectUploadValueLegacy and FileValue allow us to be able to get info about a single file or a list of files.
// These are currently being used by the Editor (and DirectUploadEditor and LHPolicyUploadEditor)
// and FileBrowser.

export type FileValue = FileObjectLegacy | FileObject | null;
export type FileEditorValue = GenericFile | null;
export type MultiFileEditorValue = GenericFile[] | null;

/** For file display/utility components that also need to support FileSeries */
export type FileEditorValueOrFileSeries = FileValue | MultiFileEditorValue | FileSeries;

export function isFileSeries(file: FileEditorValueOrFileSeries): file is FileSeries {
  return !!file && Object.prototype.hasOwnProperty.call(file, 'Files');
}

export function isDirectUploadSingleValueLegacy(
  file: GenericFile,
): file is DirectUploadSingleValueLegacy {
  return !!file && Object.prototype.hasOwnProperty.call(file, 'bytes');
}

export function isFileObjectLegacy(file: GenericFile): file is FileObjectLegacy {
  return !!file && Object.prototype.hasOwnProperty.call(file, 'hostFile');
}

export function isFileObject(file: GenericFile): file is FileObject {
  return (
    !!file &&
    Object.prototype.hasOwnProperty.call(file, 'Name') &&
    Object.prototype.hasOwnProperty.call(file, 'Path')
  );
}

/**
 * Convert a FileObject v1 to V2
 * @param file
 */
export function convertFileObjectToV2(file: FileObject | FileObjectLegacy): FileObject {
  if (isFileObjectLegacy(file)) {
    return { Name: file.name, Path: file.hostFile.filename };
  }
  return file;
}

/**
 * Convert a FileObject v2 to V1
 * @param file
 */
export function convertFileObjectToV1(
  file: FileObject | FileObjectLegacy,
): FileObjectLegacy {
  if (isFileObject(file)) {
    return { name: file.Name, hostFile: { filename: file.Path } };
  }
  return file;
}

export type FactorsExportFormat = 'csv' | 'jsl' | 'xlsx';
