import { useCallback } from 'react';

import { interpolateConfiguredNames } from 'client/app/lib/workflow/format';
import useElementConfigs from 'client/app/lib/workflow/useElementConfigs';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';

export default function useElementErrorParser() {
  const elementSetId = useWorkflowBuilderSelector(state => state.elementSet?.id);
  const { elementConfigs } = useElementConfigs(elementSetId);

  return useCallback(
    (templateString: string = '') =>
      interpolateConfiguredNames(templateString, elementConfigs),
    [elementConfigs],
  );
}
