import React, { useCallback } from 'react';

import Button, { ButtonProps } from '@mui/material/Button';
import { Link } from 'react-router-dom';

import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import { NavigationRoute } from 'common/ui/navigation';

type Props<TRouteParam> = {
  route: NavigationRoute<TRouteParam>;
  routeParam: TRouteParam;
  label: string;
  openInNewTab?: boolean;
  logEventCategory?: string; // If this log event is set, we should log this as the category.
} & Pick<
  ButtonProps,
  // not all ButtonProps are compatible with Button that renders component Link; there
  // are 285 overlapping props between Link and Button that are not compatible :-( so we
  // have to explicitly list which props will be accepted. If you need another property,
  // try listing it here and it might just work TM.

  | 'className'
  | 'color'
  | 'disabled'
  | 'endIcon'
  | 'fullWidth'
  | 'size'
  | 'startIcon'
  | 'variant'
>;

/**
 * Renders a button that will navigate to the route on click.
 *
 * Optionally can open the route in new tab, is openInNewTab is set to true.
 */
function RouteButton<TRouteParam>(
  {
    route,
    routeParam,
    openInNewTab,
    label,
    logEventCategory,
    color = 'inherit',
    ...otherButtonProps
  }: Props<TRouteParam>,
  ref: React.Ref<HTMLAnchorElement>,
) {
  const target = openInNewTab ? '_blank' : undefined;
  const onClick = useCallback(() => {
    if (logEventCategory) {
      logEvent('navigate', logEventCategory, route.pathTemplate);
    }
  }, [logEventCategory, route]);
  return (
    <Button
      component={Link}
      color={color}
      to={route.getPath(routeParam)}
      target={target}
      onClick={onClick}
      ref={ref}
      {...otherButtonProps}
    >
      {label}
    </Button>
  );
}

// We need to cast a the return valu of React.forwardRef in order to support generic.
// https://stackoverflow.com/a/58473012/1523159
export default React.forwardRef(RouteButton) as typeof RouteButton;
