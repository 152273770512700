import React, { useMemo } from 'react';

import { styled } from '@mui/material/styles';

import { WellLocationOnDeckItem } from 'common/types/mix';
import PlateLayout, {
  Props as PlateLayoutProps,
} from 'common/ui/components/PlateLayout/PlateLayout';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';

type Props = {
  deckLayout: DeckLayout;
  // Well location under cursor. Synchronised between the sidebar and the plate view.
  highlightedWellLocation: WellLocationOnDeckItem | null;
} & Omit<PlateLayoutProps, 'geometry'>;

// Shows a 2d grid of wells in the plate prep screen.
function PlateView({ highlightedWellLocation, deckLayout, plate, ...plateProps }: Props) {
  const geometry = useMemo(
    () => deckLayout.getCurrentGeometry(plate),
    [deckLayout, plate],
  );
  const { width: plateWidth, height: plateHeight } = geometry.getDimensions();
  const highlightedWells = useMemo(
    () => (highlightedWellLocation ? [highlightedWellLocation] : undefined),
    [highlightedWellLocation],
  );
  return (
    <SvgContainer viewBox={`0 0 ${plateWidth} ${plateHeight}`}>
      <PlateLayout
        {...plateProps}
        plate={plate}
        geometry={geometry}
        highlightedWells={highlightedWells}
        showContentLabels
      />
    </SvgContainer>
  );
}

export default React.memo(PlateView);

const SvgContainer = styled('svg')({
  // Make sure the whole plate is visible on screen
  height: '100%',
  width: '100%',
});
