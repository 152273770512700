import React from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popover from '@mui/material/Popover';

import Colors from 'common/ui/Colors';
import IconButton, { IconButtonProps } from 'common/ui/components/IconButton';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { usePopover } from 'common/ui/hooks/usePopover';

type Props = {
  iconButtonProps: Omit<IconButtonProps, 'onClick'>;
  onClick?: () => void;
  popoverContent: JSX.Element;
  variant: 'click' | 'hover';
};

/**
 * Renders the given iconButton and reveals a popover with the given popoverContent on hover.
 * Attaches the given onClick handler to the iconButton.
 * Location of popover can be customised by passing in PopoverOrigins.
 */
export default function IconButtonWithPopover({
  iconButtonProps,
  onClick: handleClick,
  popoverContent,
  variant,
}: Props) {
  const classes = useStyles();

  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();

  let btnElement: React.ReactNode;
  switch (variant) {
    case 'click':
      btnElement = (
        <ClickAwayListener onClickAway={onHidePopover}>
          <IconButton
            {...iconButtonProps}
            style={{ color: isPopoverOpen ? Colors.BLUE_40 : Colors.TEXT_PRIMARY }}
            onClick={event => {
              if (isPopoverOpen) {
                onHidePopover();
              } else {
                onShowPopover(event);
              }
            }}
          />
        </ClickAwayListener>
      );
      break;
    case 'hover':
    default:
      btnElement = (
        <IconButton
          {...iconButtonProps}
          onMouseOver={onShowPopover}
          onMouseLeave={onHidePopover}
          onClick={() => {
            onHidePopover();
            handleClick?.();
          }}
        />
      );
      break;
  }

  return (
    <>
      {btnElement}
      <Popover
        sx={{ pointerEvents: variant === 'hover' ? 'none' : 'initial' }}
        classes={{
          paper: classes.paper,
        }}
        anchorEl={popoverAnchorElement}
        open={isPopoverOpen}
        onClose={onHidePopover}
        elevation={4}
        disableRestoreFocus // When popover disappears, we don't want to restore focus to anything else on screen.
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {popoverContent}
      </Popover>
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Colors.WHITE,
    borderRadius: '4px',
    padding: theme.spacing(4),
    margin: theme.spacing(4, 0, 0, -6),
  },
}));
