import { useMemo } from 'react';

import { useDownloadRemoteFileFromPath } from 'client/app/api/filetree';
import {
  Execution,
  LabwareHistory,
  LabwareHistoryDataset,
  LabwareHistoryDatasetOriginFile,
} from 'client/app/apps/execution-details/types';
import {
  extractPlates,
  getLiquidHandlingTasks,
} from 'client/app/apps/simulation-details/dataUtils';
import { downloadPlateFile } from 'client/app/apps/simulation-details/overview/simulationDetailsFiles';
import { LabwareHistoryAnalyticProperties, LabwareHistoryType } from 'client/app/gql';
import { groupBy, isDefined } from 'common/lib/data';
import { basename } from 'common/lib/strings';
import { Deck } from 'common/types/mix';

type LabwareHistoryAnalyticProperties_ = Omit<
  LabwareHistoryAnalyticProperties,
  'plateName'
>;

type PlateFile = {
  name: string;
  displayName: string;
  onDownload: () => void;
  onDelete?: (setLoading: (loading: boolean) => void) => void;
};

type LabwarePanelPlate = {
  name: string;
  labwareId: LabwareId;
  files: PlateFile[];
  barcode: string | null;
  synthetic: boolean;
};

export function usePlates(
  execution: Execution,
  deck: Deck,
  deleteFile?: (
    file: LabwareHistoryDatasetOriginFile,
    setLoading: (loading: boolean) => void,
  ) => Promise<void>,
): LabwarePanelPlate[] {
  const downloadRemoteFileFromPath = useDownloadRemoteFileFromPath();

  return useMemo(() => {
    const deckPlates = new Map(extractPlates(deck).map(p => [p.name, p]));
    const platesByName = new Map<string, LabwarePanelPlate>();

    // Group all the labware histories by name
    const labwareHistoriesByName = groupBy(execution.labwareHistory, 'name');

    // For each name, create a plate and fill in all the files referenced by those histories
    for (const labwareHistories of Object.values(labwareHistoriesByName)) {
      // Sort the histories so that the files show up in chronological order
      labwareHistories.sort((a, b) => a.ordering - b.ordering);
      // We grouped these by name above, so every labwareHistory entry has the same name and — by
      // extension, since the name identifies the piece of labware — labware.  Use the first one
      // arbitrarily to get those properties.
      const firstLabwareHistory = labwareHistories[0];
      platesByName.set(firstLabwareHistory.name, {
        name: firstLabwareHistory.name,
        labwareId: firstLabwareHistory.labware.id,
        files: [],
        barcode: firstLabwareHistory.labware.barcode,
        synthetic: firstLabwareHistory.historyType === LabwareHistoryType.MAPPED_LAYOUT,
      });
      for (const lh of labwareHistories) {
        const plate = platesByName.get(lh.name)!;
        plate.files.push(...getDataFiles(downloadRemoteFileFromPath, lh, deleteFile));
      }
    }

    const mixTasks = getLiquidHandlingTasks(execution.simulation.tasks);
    if (mixTasks) {
      for (const plate of platesByName.values()) {
        // There is always a file named "PLATE NAME.csv" for each plate from the execution, and we
        // want to list it first
        if (!plate.synthetic && deckPlates.has(plate.name)) {
          const deckPlate = deckPlates.get(plate.name)!;
          plate.files.unshift({
            name: deckPlate.plateFileName,
            displayName: 'Final plate layout',
            onDownload: () =>
              downloadPlateFile(
                execution.simulation.id,
                deckPlate.plate,
                deckPlate.plateFileName,
                'download-output-plate-file-from-card',
              ),
          });
        }
      }
    }
    const plateArray = [...platesByName.values()];
    plateArray.sort((a, b) => a.name.localeCompare(b.name));
    return plateArray;
  }, [
    deck,
    deleteFile,
    downloadRemoteFileFromPath,
    execution.labwareHistory,
    execution.simulation.id,
    execution.simulation.tasks,
  ]);
}

function getDataFiles(
  downloadRemoteFileFromPath: ReturnType<typeof useDownloadRemoteFileFromPath>,
  lh: LabwareHistory,
  deleteFile?: (
    file: LabwareHistoryDatasetOriginFile,
    setLoading: (loading: boolean) => void,
  ) => Promise<void>,
): PlateFile[] {
  // There may be duplicates among the origin files if a single origin file produced multiple
  // parsed files, and we don't want to show them twice
  const displayedOriginFiles = new Set<string>();
  return lh.datasets
    .map(ds => {
      if (displayedOriginFiles.has(ds.originFile.originFiletreeLink)) {
        return undefined;
      }
      displayedOriginFiles.add(ds.originFile.originFiletreeLink);
      return makeDataFile(
        downloadRemoteFileFromPath,
        ds.originFile.originFiletreeLink,
        lh,
        ds,
        deleteFile,
      );
    })
    .filter(isDefined);
}

export function getFileDisplayName(
  labwareHistoryType: LabwareHistoryType,
  analyticalPropsKind: string,
  filetreeLink: FiletreeLink,
  datasetDeviceName: string | null | undefined,
) {
  let displayName;
  if (labwareHistoryType === LabwareHistoryType.ANALYTIC) {
    displayName = displayNameForKind(analyticalPropsKind);
  } else {
    displayName = `${basename(filetreeLink)} — Uploaded data`;
    if (datasetDeviceName) {
      displayName += ` from ${datasetDeviceName}`;
    }
  }
  return displayName;
}

function makeDataFile(
  downloadRemoteFileFromPath: ReturnType<typeof useDownloadRemoteFileFromPath>,
  ftl: FiletreeLink,
  lh: LabwareHistory,
  dataset: LabwareHistoryDataset,
  deleteFile?: (
    file: LabwareHistoryDatasetOriginFile,
    setLoading: (loading: boolean) => void,
  ) => Promise<void>,
): PlateFile {
  const displayName = getFileDisplayName(
    lh.historyType,
    (lh.properties as LabwareHistoryAnalyticProperties_).kind,
    ftl,
    dataset?.deviceName,
  );
  return {
    name: ftl,
    displayName,
    onDownload: () => downloadRemoteFileFromPath(ftl),
    onDelete:
      deleteFile && lh.historyType === LabwareHistoryType.EXTERNAL_DATA
        ? (setLoading: (loading: boolean) => void) => {
            // We intentially ignore the Promise<void> using the void operator, since we don't have
            // anything meaningful to do with the result of deleteFile()
            void deleteFile(dataset.originFile, setLoading);
          }
        : undefined,
  };
}

function displayNameForKind(kind: string): string {
  switch (kind) {
    case 'platereader':
      return 'Plate reader data';
    default:
      return 'Device data';
  }
}

export function arePlateDataUploaded(plates: LabwarePanelPlate[]): boolean {
  // All plates have 'Final plate layout.csv' by default (length=1).
  // If they have additional data attached, then length becomes >1.
  // Here we check if any of the plates has data attached.
  for (const plate of plates) {
    if (plate.files.length > 1) {
      return true;
    }
  }
  return false;
}
