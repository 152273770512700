import React, { useContext, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import ReactFlow, {
  Background,
  EdgeSmoothStepProps,
  EdgeTypes,
  NodeTypes,
  ReactFlowProvider,
  useReactFlow,
  useViewport,
} from 'react-flow-renderer';
import { Route, RouteComponentProps } from 'react-router-dom';

import ExperimentViewMenu from 'client/app/apps/experiments/ExperimentViewMenu';
import EntityNode from 'client/app/apps/work-tree/components/EntityNode';
import WorkTreeFabMenu from 'client/app/apps/work-tree/components/WorkTreeFabMenu';
import {
  WorkTreeCreateModePanel,
  WorkTreeHiddenModePanel,
} from 'client/app/apps/work-tree/components/WorkTreeModePanel';
import WorkTreeOptionsMenu from 'client/app/apps/work-tree/components/WorkTreeStateOptionsMenu';
import { MUTATION_CREATE_BLOCK } from 'client/app/apps/work-tree/mutations';
import { QUERY_EXPERIMENT_WORK_TREE } from 'client/app/apps/work-tree/queries';
import { useWaitForWorkTreeNode } from 'client/app/apps/work-tree/useWaitForWorkTreeNode';
import { useWorkTree } from 'client/app/apps/work-tree/useWorkTree';
import {
  EDGE_END_ARROW_MARKER_ID,
  EdgeData,
  useWorkTreeLayout,
} from 'client/app/apps/work-tree/useWorkTreeLayout';
import {
  WorkTreeModeContext,
  WorkTreeModeContextProvider,
} from 'client/app/apps/work-tree/WorkTreeModeContext';
import UIErrorBox from 'client/app/components/UIErrorBox';
import { ArrayElement, ExperimentWorkTreeQuery } from 'client/app/gql';
import Colors from 'common/ui/Colors';
import LinearProgress from 'common/ui/components/LinearProgress';
import CanvasControl from 'common/ui/components/Workspace/CanvasControl';
import { route } from 'common/ui/navigation';

export const HIGHLIGHTED_ENTITY_PARAM = 'highlighted';

const WORKTREE_BACKGROUND_COLOR = '#FAFBFA';
const WORKTREE_FILL_COLOR = '#F2F3F2';
const EDGE_WEIGHT = 1;

type WorkTreeRouteProps = {
  entityId: ExperimentId;
};

export const workTreeRoute = route<WorkTreeRouteProps>(
  '/work-tree/:entityType/:entityId',
);

/**
 * Work Tree is an experimental UI being built by the Fusion Cell.
 *
 * It visualises a users work (workflows, data, analyses, etc) in a tree, allowing the
 * user to see how particular entities have been derived from other entities.
 */
export function WorkTreeScreen() {
  return (
    <Route
      exact
      path={workTreeRoute.pathTemplate}
      render={(props: RouteComponentProps<WorkTreeRouteProps>) => (
        <WorkTreeContainer entityId={props.match.params.entityId} />
      )}
    />
  );
}

function WorkTreeContainer({ entityId }: WorkTreeRouteProps) {
  const workTreeResult = useWorkTree(entityId);

  let mainContent: JSX.Element | null = null;

  switch (workTreeResult.status) {
    case 'loading':
      mainContent = <LinearProgress />;
      break;
    case 'error':
      mainContent = <UIErrorBox>{String(workTreeResult.error)}</UIErrorBox>;
      break;
    case 'success':
    case 'reloading':
      mainContent = (
        <WorkTreeWithProvider
          blocks={workTreeResult.blocks}
          experimentid={entityId}
          refetchWorkTree={workTreeResult.refetchWorkTree}
        />
      );
      break;
  }

  return <>{mainContent}</>;
}

type Block = ArrayElement<
  NonNullable<ExperimentWorkTreeQuery['experimentWorkTree']>['blocks']
>;

type WorkTreeVisualizationProps = {
  blocks: readonly Block[];
  experimentid: ExperimentId;
  refetchWorkTree: () => Promise<void>;
};

function WorkTreeWithProvider(props: WorkTreeVisualizationProps) {
  return (
    <ReactFlowProvider>
      <WorkTreeModeContextProvider experimentId={props.experimentid}>
        <WorkTreeVisualization {...props} />
      </WorkTreeModeContextProvider>
    </ReactFlowProvider>
  );
}

const FIT_VIEW_PADDING = 0.3;

function WorkTreeVisualization({
  blocks,
  experimentid,
  refetchWorkTree,
}: WorkTreeVisualizationProps) {
  const flow = useReactFlow<Block>();

  const context = useContext(WorkTreeModeContext);

  // Need to refetch after creating or importing a node. TODO update the mutations to
  // return the updated worktrees so we don't need to refetch.
  const handleRefetchWorkTree = async () => {
    context.setShowLoadingState(true);
    refetchWorkTree().finally(() => {
      context.setShowLoadingState(false);
    });
  };

  const handleFitView = () => {
    flow.fitView({ padding: FIT_VIEW_PADDING });
  };

  const [createBlockMutation] = useMutation(MUTATION_CREATE_BLOCK);
  const handleCreateNewBlock = async () => {
    if (context.mode !== 'creating-block') {
      return;
    }

    const selectedNodeResultIds = workTree.nodes
      .filter(node => context.selectedBlocks.includes(node.data.id))
      .map(node => node.data.result?.id)
      .filter((resultId): resultId is ResultId => resultId !== null);

    const versionNodeResultIds = workTree.nodes
      .flatMap(node => node.data.data?.versionData.map(versionBlock => versionBlock))
      .filter(
        versionBlock => versionBlock && context.selectedBlocks.includes(versionBlock.id),
      )
      .map(versionBlock => versionBlock?.result?.id)
      .filter((resultId): resultId is ResultId => resultId !== null);

    const { data } = await createBlockMutation({
      variables: {
        applicationName: context.creatingBlock.applicationName,
        applicationVersion: context.creatingBlock.applicationVersion,
        inputResultIds: [...selectedNodeResultIds, ...versionNodeResultIds],
        experimentId: experimentid,
      },
      refetchQueries: [
        {
          query: QUERY_EXPERIMENT_WORK_TREE,
          variables: { experimentId: experimentid },
        },
      ],
    });

    const nodeId = data?.createBlock?.id;

    if (nodeId) {
      waitForNode(nodeId, node => {
        context.focusOnNode(node);
      });
    }

    context.setModeViewing();
  };

  const { zoom } = useViewport();
  // The maxZoom is the same as the react-flow default.
  // The minZoom we reduced (the default is 0.5) to allow users to visualise
  // larger maps, and this value was deemed to be decent for this in testing.
  // We have them as variables here so we can use to perform
  // the calculation below, and pass them into react-flow component
  // to ensure consistency.
  const minZoom = 0.25;
  const maxZoom = 2;
  // Convert the existing zoom in range minZoom to maxZoom
  // to a range of 0 - 1 for the CanvasControl.
  const ratioZoom = (zoom - minZoom) / (maxZoom - minZoom);

  const onZoomChange = (ratio: number) => {
    const updatedZoom = (maxZoom - minZoom) * ratio + minZoom;
    flow.setViewport({ ...flow.getViewport(), zoom: updatedZoom });
  };

  // We currently have to prevent re-layout when changing to creating-block mode because it
  // will reset all state (including multi-select and selectable states) set on the nodes.
  // Only re-layout when switching in and out of editing hidden blocks mode.
  const showHidden = context.mode === 'editing-hidden-blocks';

  // Memoize to prevent re-layout on each render
  const visibleBlocks = useMemo(
    () => (showHidden ? blocks : blocks.filter(block => !block.hidden)),
    [blocks, showHidden],
  );

  const layout = useWorkTreeLayout(visibleBlocks);

  const { waitForNode } = useWaitForWorkTreeNode(
    layout.status === 'success' ? layout.workTree : undefined,
  );

  if (layout.status === 'loading') {
    return <LinearProgress />;
  }

  if (layout.status === 'error') {
    return <UIErrorBox>{String(layout.error)}</UIErrorBox>;
  }

  const workTree = layout.workTree;

  const nodes = workTree.nodes.map(node => {
    return { ...node, data: { ...node.data, experimentid: experimentid } };
  });

  return (
    <>
      <StyledReactFlow
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultNodes={nodes}
        defaultEdges={workTree.edges}
        // Prevent react-flow from instantly deleting the node in the UI, we'll rely on a
        // custom handler instead.
        deleteKeyCode={null}
        // Always fit view on load, except if we have a highlightedBlock (from url params)
        // because in that case, we focus on that node, and don't fit automatically
        fitView={!context.highlightedBlock}
        fitViewOptions={{ padding: FIT_VIEW_PADDING }}
        // By default, ReactFlow has an eventListener for the shift key to allow multi-select
        // which disabled pointer events.
        // Because we have links on our nodes, we want the user to be able to press shift to
        // open in a new window, so we can disable that listener here.
        selectionKeyCode={null}
        onPaneClick={() => context.clearSelectedBlocks()}
        elementsSelectable={false}
        maxZoom={maxZoom}
        minZoom={minZoom}
      >
        <Background gap={9} size={1} color={WORKTREE_FILL_COLOR} />
        {context.showLoadingState && (
          <>
            <StyledLinearProgress />
            <LoadingOverlay />
          </>
        )}
        <FabMenuAndControlsWrapper>
          <WorkTreeFabMenu
            experimentId={experimentid}
            disabled={context.mode !== 'default'}
            visibleBlocks={visibleBlocks} // Don't pass in all blocks, as we don't want to take into account hidden blocks
            workTree={workTree}
            refetchWorkTree={handleRefetchWorkTree}
          />
          <DefaultWorkTreeControls>
            <WorkTreeOptionsMenu
              disabled={context.mode !== 'default'}
              experimentId={experimentid}
              refetchWorkTree={handleRefetchWorkTree}
            />
            <StyledDivider orientation="vertical" />
            <ExperimentViewMenu
              experimentId={experimentid}
              currentView="map"
              disabled={context.mode !== 'default'}
            />
          </DefaultWorkTreeControls>
        </FabMenuAndControlsWrapper>
        {context.mode === 'creating-block' && (
          <AlternateWorkTreeControls>
            <WorkTreeCreateModePanel
              selectedItems={context.selectedBlocks.length}
              handleConfirm={handleCreateNewBlock}
              handleCancel={() => context.setModeViewing()}
              applicationName={context.creatingBlock.applicationName}
            />
          </AlternateWorkTreeControls>
        )}
        {context.mode === 'editing-hidden-blocks' && (
          <AlternateWorkTreeControls>
            <WorkTreeHiddenModePanel
              hiddenCount={context.hiddenBlocks.size}
              handleConfirm={() => {
                context.saveHidden();
                context.setModeViewing();
              }}
              handleCancel={() => context.setModeViewing()}
            />
          </AlternateWorkTreeControls>
        )}
      </StyledReactFlow>
      <CanvasControl
        onShowAll={handleFitView}
        onZoomChange={onZoomChange}
        currentZoomRatio={ratioZoom}
        canvasControlVariant="light_float"
        zIndex={5}
      />
      <svg width="0" height="0">
        <defs>
          <marker
            id={EDGE_END_ARROW_MARKER_ID}
            markerWidth="32"
            markerHeight="32"
            viewBox="-10 -10 20 20"
            orient="auto"
            refX={EDGE_WEIGHT / 2}
          >
            <StyledPath d="M -5, -4 L 0, 0 L -5, 4" />
          </marker>
        </defs>
      </svg>
    </>
  );
}

type WorkTreeControlsProps = React.PropsWithChildren<{
  className?: string;
}>;

function WorkTreeControls(props: WorkTreeControlsProps) {
  return (
    <StyledPaper elevation={4} className={props.className}>
      {props.children}
    </StyledPaper>
  );
}

function Edge({ id, style, data, markerEnd }: EdgeSmoothStepProps<EdgeData>) {
  const path = data?.path.flatMap(({ x, y }, i) => [i > 0 ? 'L' : 'M', x, y]).join(' ');
  return <StyledPath id={id} style={style} d={path} markerEnd={markerEnd} />;
}

const nodeTypes: NodeTypes = { entity: EntityNode };
const edgeTypes: EdgeTypes = { default: Edge };

const LoadingOverlay = styled('div')({
  width: '100%',
  height: '100%',
  zIndex: 1000,
  backgroundColor: Colors.GREY_0,
  position: 'absolute',
  opacity: 0.5,
});

const StyledLinearProgress = styled(LinearProgress)({
  zIndex: 1000,
});

const StyledReactFlow = styled(ReactFlow)({
  backgroundColor: WORKTREE_BACKGROUND_COLOR,
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: Colors.WHITE,
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(3),
}));

const DefaultWorkTreeControls = styled(WorkTreeControls)({
  height: '32px',
});
/** Used fore modes which are not `default` */
const AlternateWorkTreeControls = styled(WorkTreeControls)({
  top: 16,
  left: '50%',
  transform: 'translate(-50%, 0)',

  position: 'absolute',
  zIndex: 5, // This is the same z-index as react-flow uses for it's native Controls component.
});

const FabMenuAndControlsWrapper = styled('div')(({ theme }) => ({
  top: 16,
  left: 16,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(5),
  position: 'absolute',
  zIndex: 5, // This is the same z-index as react-flow uses for it's native Controls component.
}));

const StyledDivider = styled(Divider)({
  height: '24px',
});

const StyledPath = styled('path')({
  stroke: Colors.GREY_50,
  strokeWidth: EDGE_WEIGHT,
  fill: 'transparent',
});
