import React, { useCallback, useMemo } from 'react';

import AssignmentIcon from '@mui/icons-material/Assignment';

import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import PlateReaderProtocolDialog, {
  ProtocolType,
} from 'client/app/components/Parameters/PlateReaderProtocol/PlateReaderProtocolDialog';
import { WorkflowConfig } from 'common/types/bundle';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import SelectFromDialogButton from 'common/ui/components/SelectFromDialogButton';

type Props = {
  onChange: (value: string | undefined) => void;
  protocolType: ProtocolType;
  workflowId?: string;
  workflowConfig?: WorkflowConfig;
} & ParameterEditorBaseProps<string>;

export default function PlateReaderProtocolParameter(props: Props) {
  const { value, onChange, protocolType, workflowId, workflowConfig, isDisabled } = props;

  const handleChange = useCallback(
    async (newVal: string | undefined | typeof CANCEL_CHOICE) => {
      if (newVal === CANCEL_CHOICE) {
        return;
      }
      onChange(newVal);
    },
    [onChange],
  );

  const safeValue = value ?? '';

  const dialogProps = useMemo(
    () => ({
      value: safeValue,
      protocolType,
      workflowId,
      workflowConfig,
    }),
    [protocolType, safeValue, workflowConfig, workflowId],
  );

  return (
    <SelectFromDialogButton
      value={safeValue}
      icon={<AssignmentIcon />}
      dialog={PlateReaderProtocolDialog}
      dialogProps={dialogProps}
      placeholder="Select a protocol"
      onChange={handleChange}
      isDisabled={isDisabled}
    />
  );
}
