import React from 'react';

import { sanitizeDeckItemName } from 'common/lib/format';
import { TipwasteState } from 'common/ui/components/simulation-details/mix/MixState';
import Styles from 'common/ui/components/simulation-details/Styles';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  tipwaste: TipwasteState;
};

export default React.memo(function TipwasteView({ tipwaste }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.tipwaste} style={{ backgroundColor: tipwaste.color }}>
      <div className={classes.deckItemLabel}>
        <div className={classes.deckItemName}>{sanitizeDeckItemName(tipwaste.name)}</div>
      </div>
    </div>
  );
});

const useStyles = makeStylesHook({
  tipwaste: {
    position: 'relative',
    height: '100%',
    alignItems: 'center',
    background: 'white',
    border: Styles.lightBorder,
    display: 'flex',
    justifyContent: 'center',
  },
  deckItemLabel: Styles.deckItemLabel,
  deckItemName: Styles.deckItemName,
});
