import React, { useMemo } from 'react';

import cx from 'classnames';

import Colors from 'common/ui/Colors';
import { WELL_BORDER_WIDTH_PX } from 'common/ui/components/simulation-details/mix/DeckLayout';
import {
  isWellTypeCircular,
  Props,
  Well,
} from 'common/ui/components/simulation-details/mix/Well';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/**
 * If a well contains a filter matrix, then give the well the appearance of a
 * RoboColumn, which has a lip around the top.
 */
const ROBOCOLUMN_LIP_SIZE = 4;

/**
 * Wraps the Well component and adds a white lip around the outside to indicate
 * this is a RoboColumn.
 */
export default function RoboColumn(props: Props) {
  const classes = useStyles();
  const {
    wellPos,
    wellType,
    wellContents,
    showContentLabel,
    color = Colors.WHITE,
    strokeColor = Colors.WELL_BORDER,
    isHighlighted,
    isDisabled,
    showEmptyWellsAsPossiblyAllocated,
  } = props;
  const isWellCircular = isWellTypeCircular(wellType);

  // For RoboColumns, the "well" (which is actually representing a RoboColumn) has an inner circle to contain the liquid, and an outer ring to represent the lip of the robocolumn.
  const roboColumnPos = useMemo(
    () => ({
      left: wellPos.left - ROBOCOLUMN_LIP_SIZE,
      top: wellPos.top - ROBOCOLUMN_LIP_SIZE,
      width: wellPos.width + ROBOCOLUMN_LIP_SIZE * 2,
      height: wellPos.height + ROBOCOLUMN_LIP_SIZE * 2,
    }),
    [wellPos],
  );

  return (
    <>
      <g transform={`translate(${roboColumnPos.left},${roboColumnPos.top})`}>
        <rect
          width={roboColumnPos.width}
          height={roboColumnPos.height}
          rx={isWellCircular ? roboColumnPos.width / 2 : 0}
          ry={isWellCircular ? roboColumnPos.height / 2 : 0}
          stroke={strokeColor}
          className={cx(classes.roboColumn, {
            [classes.highlighted]: isHighlighted,
            [classes.disabled]: isDisabled,
          })}
          // Used to identify the click target in the MixScreen
          data-well="true"
        />
      </g>
      <Well
        wellPos={wellPos}
        wellType={wellType}
        wellContents={wellContents}
        showContentLabel={showContentLabel}
        color={color}
        strokeColor={strokeColor}
        isDisabled={isDisabled}
        showEmptyWellsAsPossiblyAllocated={showEmptyWellsAsPossiblyAllocated}
      />
    </>
  );
}

const useStyles = makeStylesHook(({ palette }) => ({
  roboColumn: {
    strokeWidth: WELL_BORDER_WIDTH_PX,
    vectorEffect: 'non-scaling-stroke',
    fill: Colors.WHITE,
  },
  disabled: {
    stroke: Colors.WELL_BORDER,
  },
  highlighted: {
    strokeWidth: WELL_BORDER_WIDTH_PX,
    stroke: palette.primary.main,
  },
}));
