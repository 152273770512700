import React, { useCallback } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';

import { GenericFile, isFileObject } from 'common/types/fileParameter';
import { removeIcon } from 'common/ui/commonStyles';

type Props = {
  file: GenericFile;
  onRemove: (file: GenericFile) => void;
  isDisabled?: boolean;
  onDownload?: (file: GenericFile) => void;
};

// There a bunch of ways in which we could end up with no file name. The
// most common one is uploads that are now baked into bundles which don't
// follow the bundle file shape that we expect now. To be defensive against
// this, we'll use this default value any place where a valid name doesn't
// show up.
const UNKNOWN_FILE = 'unknown file';

function DirectUploadItem(props: Props) {
  const { file, onDownload } = props;
  const classes = useStyles();
  const fileName = isFileObject(file) ? file.Name : file.name;

  const onFileNameClick = useCallback(() => {
    onDownload?.(file);
  }, [file, onDownload]);

  const { onRemove, isDisabled } = props;
  const _onRemove = useCallback(() => {
    onRemove(file);
  }, [onRemove, file]);

  return (
    <div className={classes.currentValue}>
      {/* The following used to be <Link component="button" but that
       * seems to have broken: with a React upgrade
       * https://github.com/mui-org/material-ui/issues/15105 */}
      <Button
        component="a"
        className={classes.uploadedFileName}
        onClick={onFileNameClick}
        title="Click to download"
      >
        {fileName || UNKNOWN_FILE}
      </Button>
      {!isDisabled && (
        <IconButton className={classes.removeIcon} onClick={_onRemove} size="large">
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  currentValue: {
    display: 'flex',
    flexDirection: 'row',
  },
  uploadedFileName: {
    wordBreak: 'break-word',
    textAlign: 'left',
    textTransform: 'none',
  },
  removeIcon: {
    ...removeIcon.style,
    fontSize: '1em',
  },
});

export default DirectUploadItem;
