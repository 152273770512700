import React from 'react';

import AddFileButton from 'client/app/components/Parameters/AddFileButton';
import { FileEditorValue, FileObject } from 'common/types/fileParameter';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  value: FileObject;
  template?: FileObject;
  onChange: (newValue: FileEditorValue) => void;
  isDisabled?: boolean;
};

export default React.memo(function FileEditor({
  value,
  template,
  onChange,
  isDisabled,
}: Props) {
  const classes = useStyles();

  if (!value && isDisabled) {
    return <div className={classes.emptyMessage}>None selected</div>;
  }

  return (
    <AddFileButton
      value={value}
      onChange={onChange}
      template={template}
      isDisabled={isDisabled}
    />
  );
});

const useStyles = makeStylesHook({
  emptyMessage: {
    color: Colors.GREY_40,
    fontStyle: 'italic',
  },
});
