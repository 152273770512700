import React, { useMemo } from 'react';

import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Button from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { alpha, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { getDeviceModelLabel } from 'client/app/api/deviceFromGraphql';
import { hasExecution } from 'client/app/apps/simulation-details/dataUtils';
import { getStageRunConfig } from 'client/app/apps/simulation-details/useStageDetails';
import { DeviceThumbnailFragment, Simulation, SimulationStage } from 'client/app/gql';
import { executionRoutes } from 'client/app/lib/nav/actions';
import { hasDispenserDevice, hasManualDevice } from 'common/types/bundleConfigUtils';
import { ensureV3Config } from 'common/types/bundleTransforms';
import DeviceThumbnail from 'common/ui/components/DeviceThumbnail';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import { ExecutionIcon } from 'common/ui/icons/Execution';

type Props = {
  simulation: Simulation;
};

export default function ExecutionModeCard({ simulation }: Props) {
  const workflow = ensureV3Config(simulation.workflow.workflow);
  const isManualExecution = hasManualDevice(workflow.Config);
  const isDispenserExecution = hasDispenserDevice(workflow.Config);
  const isExecuted = hasExecution(simulation);

  const { navigate } = useNavigation();

  const handleNavigate = () => {
    if (!simulation.execution?.id) {
      return;
    }
    navigate(executionRoutes.executionDetailsSubscreen, {
      executionId: simulation.execution?.id,
    });
  };

  const scheduleStatus = useMemo<string | null>(() => {
    if (isExecuted) {
      return null;
    }
    return isManualExecution || isDispenserExecution
      ? 'This simulation has not yet been executed. Click start execution to proceed.'
      : 'This simulation is not currently scheduled for execution.';
  }, [isDispenserExecution, isExecuted, isManualExecution]);

  return (
    <Card>
      <Typography variant="h2" marginBottom={6}>
        Execution
      </Typography>
      <Stack>
        {scheduleStatus ? (
          <Typography color="textSecondary">{scheduleStatus}</Typography>
        ) : (
          <ExecutionButton
            data-heap-tracking="navigate-to-execution-button"
            variant="outlined"
            color="primary"
            startIcon={<ExecutionIcon />}
            endIcon={<ArrowForwardOutlinedIcon />}
            onClick={handleNavigate}
          >
            <ButtonTextContainer>
              <ButtonText title={simulation.name}>{simulation.name}</ButtonText>
              <ButtonCaptionText variant="caption">Execution</ButtonCaptionText>
            </ButtonTextContainer>
          </ExecutionButton>
        )}
        <DeviceContainer>
          {simulation.stages.map(stage => {
            const stageCopy = getStageCopy(stage);
            const runConfig = getStageRunConfig(stage, workflow);
            const isManualStage =
              stage.mainDevice.model.anthaLangDeviceClass === 'Manual';

            return (
              <Stack key={stage.id} gap={4}>
                <Typography variant="subtitle2">
                  {simulation.stages.length > 1 ? stageCopy : 'Execution mode'}
                </Typography>

                {isManualStage ? (
                  <ManualDeviceContainer>
                    <DeviceThumbnail imageUrl="app/cdn/assets/devices/manual.png" />
                    <Typography variant="body1" color="textSecondary">
                      This simulation was configured for a manual execution.
                    </Typography>
                  </ManualDeviceContainer>
                ) : (
                  <Stack gap={3}>
                    <DeviceItem device={stage.mainDevice} runConfig={runConfig} />
                    {stage.peripheralDevices.length > 0 && (
                      <Typography variant="caption" textTransform="uppercase">
                        Accessible devices
                      </Typography>
                    )}
                    {stage.peripheralDevices.map(device => (
                      <DeviceItem key={device.id} device={device} />
                    ))}
                  </Stack>
                )}
              </Stack>
            );
          })}
        </DeviceContainer>
      </Stack>
    </Card>
  );
}

const DeviceItem = ({
  device,
  runConfig,
}: {
  device: DeviceThumbnailFragment;
  runConfig?: string;
}) => (
  <Stack direction="row" gap={3}>
    <DeviceThumbnail imageUrl={device.model.pictureURL} />
    <Stack gap={2}>
      <Typography variant="body1" color="textPrimary">
        {getDeviceModelLabel(device)}
        {runConfig && (
          <Typography variant="caption" marginLeft={2} fontStyle="italic">
            ({runConfig})
          </Typography>
        )}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {device.name}
      </Typography>
    </Stack>
  </Stack>
);

function getStageCopy(stage: SimulationStage) {
  return !stage.name
    ? `Stage ${stage.orderNum + 1}`
    : `Stage ${stage.orderNum + 1}: ${stage.name}`;
}

const BUTTON_WIDTH = 278;

const Card = styled(MuiCard)(({ theme }) => ({
  flex: 1,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(6),
  margin: 0,
  minHeight: 220,
  minWidth: 350,
  maxWidth: 'unset',
}));

const DeviceContainer = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: spacing(4),
  marginTop: spacing(6),
}));

const ManualDeviceContainer = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: spacing(4),
}));

const ExecutionButton = styled(Button)(({ theme: { spacing, palette } }) => ({
  justifyContent: 'space-between',
  borderColor: alpha(palette.primary.main, 0.5),
  height: 58,
  width: BUTTON_WIDTH,
  '& .MuiButton-endIcon svg': {
    fontSize: 24,
  },
  '& .MuiButton-startIcon': {
    paddingLeft: spacing(2),
  },
}));

const ButtonTextContainer = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: spacing(1),
  width: '100%',
  overflowX: 'auto',
}));

const ButtonText = styled(TypographyWithTooltip)({
  textAlign: 'start',
  textTransform: 'none',
  fontWeight: 500,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '100%',
});

const ButtonCaptionText = styled(Typography)({
  fontWeight: 700,
  fontSize: 11,
});
