import { useCallback } from 'react';

import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';

/**
 * Callback for setting the selected plate name into the Builder state
 */
export default function usePlateSelection() {
  const dispatch = useWorkflowBuilderDispatch();
  return useCallback(
    (plateName: string | undefined) => {
      dispatch({
        type: 'selectPlateInOutputPreview',
        payload: plateName,
      });
    },
    [dispatch],
  );
}
