import React, { useMemo } from 'react';

import VisserverIFrame, {
  VisserverIFrameProps,
} from 'client/app/components/VisserverIFrame';

type Props = {
  id: string;
  visserverApp: string;
  executionID?: string;
} & Pick<VisserverIFrameProps, 'onReady'>;

/**
 * Shows a visserver visualization within an iframe
 */
export default function VisualizationIFrame({
  id,
  visserverApp,
  executionID,
  onReady,
}: Props) {
  const params = useMemo(() => {
    const params: Record<string, string> = { vis_id: id, iframe: 'true' };
    // If we are handling an execution, pass this context to vis so that it can
    // retrieve data from analytic execution tasks.
    if (executionID !== undefined) {
      params.execution_id = executionID;
    }
    return params;
  }, [executionID, id]);

  return <VisserverIFrame view={visserverApp} params={params} onReady={onReady} />;
}
