import { createContext, ReactNode, useContext, useMemo, useReducer } from 'react';
import React from 'react';

import produce from 'immer';

import {
  mapFactorsFromElementParameter,
  mapLiquidGroupsFromElementParameter,
} from 'client/app/components/Parameters/FiltrationProtocolDesign/lib/parameterUtils';
import useFiltrationDesignParameter from 'client/app/components/Parameters/FiltrationProtocolDesign/lib/useFiltrationDesignParameter';
import {
  Factor,
  LiquidGroup,
} from 'client/app/components/Parameters/FiltrationProtocolDesign/types';
import { FiltrationDesign } from 'common/types/filtration';

type FiltrationProtocolDesignState = {
  factors: Factor[];
  liquidGroups: LiquidGroup[];
};

type FiltrationProtocolDesignContext = FiltrationProtocolDesignState & {
  addFactor: (factor: Factor) => void;
  updateFactor: (id: string, factor: Factor) => void;
  deleteFactor: (id: string) => void;
  addLiquidGroup: (liquidGroup: LiquidGroup) => void;
  updateLiquidGroup: (liquidGroup: LiquidGroup) => void;
  deleteLiquidGroup: (id: string) => void;
};

type FilterProtocolDesignAction =
  | {
      type: 'addFactor';
      payload: Factor;
    }
  | {
      type: 'updateFactor';
      payload: {
        clientId: string;
        factor: Factor;
      };
    }
  | {
      type: 'deleteFactor';
      payload: string;
    }
  | {
      type: 'addLiquidGroup';
      payload: LiquidGroup;
    }
  | {
      type: 'updateLiquidGroup';
      payload: LiquidGroup;
    }
  | {
      type: 'deleteLiquidGroup';
      payload: string;
    };

export const FiltrationProtocolDesignContext =
  createContext<FiltrationProtocolDesignContext>({
    factors: [],
    liquidGroups: [],
    addFactor() {
      throw new Error(`addFactor is not implemented`);
    },
    updateFactor() {
      throw new Error(`updateFactor is not implemented`);
    },
    deleteFactor() {
      throw new Error(`deleteFactor is not implemented`);
    },
    addLiquidGroup() {
      throw new Error('addLiquidGroup is not implemented');
    },
    updateLiquidGroup() {
      throw new Error('updateLiquidGroup is not implemented');
    },
    deleteLiquidGroup() {
      throw new Error('deleteLiquidGroup is not implemented');
    },
  });

export function filtrationProtocolDesignReducer(
  state: FiltrationProtocolDesignState,
  action: FilterProtocolDesignAction,
) {
  return produce(state, draft => {
    switch (action.type) {
      case 'addFactor':
        draft.factors.push(action.payload);
        break;
      case 'updateFactor':
        draft.factors = draft.factors.map(f =>
          f.clientId === action.payload.clientId ? action.payload.factor : f,
        );
        break;
      case 'deleteFactor':
        draft.factors = draft.factors.filter(f => f.clientId !== action.payload);
        break;
      case 'addLiquidGroup':
        draft.liquidGroups.push(action.payload);
        break;
      case 'updateLiquidGroup':
        draft.liquidGroups = draft.liquidGroups.map(nextLiquid =>
          nextLiquid.clientId === action.payload.clientId ? action.payload : nextLiquid,
        );
        break;
      case 'deleteLiquidGroup':
        draft.liquidGroups = draft.liquidGroups.filter(
          liquidGroup => liquidGroup.clientId !== action.payload,
        );
    }
  });
}

function mapStateFromConfig(
  design: FiltrationDesign | null,
): FiltrationProtocolDesignState {
  const resultFactors = mapFactorsFromElementParameter(design?.factors);
  return {
    factors: resultFactors,
    liquidGroups: mapLiquidGroupsFromElementParameter(
      design?.liquidGroups,
      resultFactors,
    ),
  };
}

export function useFiltrationProtocolDesignActions(
  dispatch: React.Dispatch<FilterProtocolDesignAction>,
) {
  return useMemo(
    () => ({
      addFactor(factor: Factor) {
        dispatch({ type: 'addFactor', payload: factor });
      },
      updateFactor(clientId: string, factor: Factor) {
        dispatch({
          type: 'updateFactor',
          payload: {
            clientId,
            factor,
          },
        });
      },
      deleteFactor(clientId: string) {
        dispatch({
          type: 'deleteFactor',
          payload: clientId,
        });
      },
      addLiquidGroup(liquidGroup: LiquidGroup) {
        dispatch({ type: 'addLiquidGroup', payload: liquidGroup });
      },
      updateLiquidGroup(liquidGroup: LiquidGroup) {
        dispatch({ type: 'updateLiquidGroup', payload: liquidGroup });
      },
      deleteLiquidGroup(id: string) {
        dispatch({ type: 'deleteLiquidGroup', payload: id });
      },
    }),
    [dispatch],
  );
}

export function FiltrationProtocolDesignProvider({ children }: { children: ReactNode }) {
  const parameter = useFiltrationDesignParameter();
  const [state, dispatch] = useReducer(
    filtrationProtocolDesignReducer,
    parameter.value,
    mapStateFromConfig,
  );

  const actions = useFiltrationProtocolDesignActions(dispatch);

  const value = useMemo(() => ({ ...state, ...actions }), [state, actions]);

  return (
    <FiltrationProtocolDesignContext.Provider value={value}>
      {children}
    </FiltrationProtocolDesignContext.Provider>
  );
}

export function useFiltrationProtocolDesign() {
  return useContext(FiltrationProtocolDesignContext);
}
