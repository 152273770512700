import React, { useCallback, useRef } from 'react';

import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

import DataComponent from 'client/app/apps/experiments/ExperimentsListDataComponent';
import NoDataComponent from 'client/app/apps/experiments/ExperimentsListNoDataComponent';
import { QUERY_EXPERIMENTS } from 'client/app/apps/experiments/gql/queries';
import ListPageHeader from 'client/app/apps/experiments/ListPageHeader';
import { useUserList } from 'client/app/apps/experiments/useUserList';
import FabWithFX from 'client/app/apps/workflow-builder/FabWithFX';
import { useCreateNew } from 'client/app/components/NavigationSidepanel';
import { ContentType, ExperimentsQueryVariables } from 'client/app/gql';
import usePagination from 'client/app/hooks/usePagination';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { ScreenRegistry } from 'client/app/registry';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { IntercomTourIDs } from 'common/lib/intercom';
import { IntercomTarget } from 'common/lib/IntercomTarget';
import { PageInfo } from 'common/server/graphql/pagination';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import { EntityCardSkeletonList } from 'common/ui/components/EntityCard';
import FilterChipWithAutocomplete from 'common/ui/components/FilterChip/FilterChipWithAutocomplete';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';
import SearchField from 'common/ui/components/SearchField';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

type Props = {
  /**
   * - If specified, each item will become clickable and invoke this
   *   callback when clicked. This is useful we want to allow a person
   *   to pick an Experiment.
   * - If not specified, each item will navigate to the Experiment detail
   *   screen when clicked.
   */
  onExperimentListItemClick?: (experimentId: ExperimentId) => void;
  /**
   * Show these experiments in a disabled state
   */
  disabledExperimentIds?: ExperimentId[];
  /**
   * Show these experiments in a highlighted state.
   */
  highlightedExperiments?: ExperimentId[];
  /**
   * If provided, we will only query for these experiments. Useful if
   * only a certain list of experiments need to be shown in the dialog
   * but still requiring existing functionality.
   */
  visibleExperimentIds?: ExperimentId[];
  /**
   * If provided, the the noEntities variant dictates which empty state screen is to be used
   * for a given user journey.
   */
  variant?: 'noEntities';
  dialog?: boolean;
};

export default function ExperimentsList({
  onExperimentListItemClick,
  disabledExperimentIds,
  visibleExperimentIds,
  highlightedExperiments,
  variant,
  dialog,
}: Props) {
  const isLandingPageEnabled = useFeatureToggle('LANDING_PAGE');

  // Needed for the infinite scrolling.
  // The ExperimentsList attaches a scroll listener to this div.
  // The reason why the div is rendered here at the top level
  // is explained in the ExperimentsList.
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const usersDropdownOptions = useUserList();

  const [searchQuery, setSearchQuery] = useStateWithURLParams({
    paramName: 'search',
    paramType: 'string',
    defaultValue: '',
  });

  const currentUserId = useUserProfile()?.id;
  const [filterUserId, setFilterUserId] = useStateWithURLParams({
    paramName: 'user',
    paramType: 'string',
    defaultValue: currentUserId,
    emptyValue: 'all',
  });

  const onFilterByUser = (id?: string) => {
    logEvent(
      'filter-experiments-by-user',
      ScreenRegistry.EXPERIMENTS,
      id === currentUserId ? 'self' : 'others',
    );
    setFilterUserId(id);
  };

  const { createNew, isCreating } = useCreateNew();

  const onNewExperiment = useCallback(() => {
    if (!isCreating) {
      void createNew('new-experiment');
    }
  }, [createNew, isCreating]);

  return (
    <>
      <ListPageHeader
        left={
          <FilterChipWithAutocomplete
            heading="Filter by Author"
            defaultChipLabel="Author"
            dropdownOptions={usersDropdownOptions}
            filterValue={filterUserId}
            onFilter={onFilterByUser}
          />
        }
        right={
          <>
            <SearchField
              variant="standard"
              label="Search"
              defaultValue={searchQuery}
              onQueryChange={setSearchQuery}
            />
            {isLandingPageEnabled && !dialog && (
              <IntercomTarget
                tour={IntercomTourIDs.EXPERIMENTS_HOME_PAGE}
                name="new-experiment"
              >
                <NewExperimentFab
                  onClick={onNewExperiment}
                  busy={isCreating}
                  variant="extended"
                  color="secondary"
                  size="medium"
                >
                  <Add /> New Experiment
                </NewExperimentFab>
              </IntercomTarget>
            )}
          </>
        }
      />
      <ContainerWithIntersectionBar
        scrollableRef={scrollableContainerRef}
        noHeader
        content={
          <ExperimentsListItems
            scrollableContainerRef={scrollableContainerRef}
            contentSource={ContentType.USER_GENERATED}
            filterUserId={filterUserId}
            searchQuery={searchQuery}
            onExperimentListItemClick={onExperimentListItemClick}
            disabledExperimentIds={disabledExperimentIds}
            visibleExperimentIds={visibleExperimentIds}
            highlightedExperiments={highlightedExperiments}
            variant={variant}
          />
        }
        dense
      />
    </>
  );
}

type ExperimentsListItemsProps = {
  /**
   * IMPORTANT: The scrollable div must always be rendered, and an easy way
   * to achieve that is render the div in the parent component and pass
   * the ref down.
   * If we render the div inside this component *conditionally*, only
   * once we have the data, the `usePagination` and specifically its
   * internal helper `useInfiniteScroll` won't be able to attach a scroll
   * listener to the div due to the way the `useInfiniteScroll` uses
   * `useEffect`.
   */
  scrollableContainerRef: React.RefObject<HTMLDivElement>;
  contentSource?: ContentType;
  filterUserId?: string;
  searchQuery?: string;
  onExperimentListItemClick?: (experimentId: ExperimentId) => void;
  disabledExperimentIds?: ExperimentId[];
  visibleExperimentIds?: ExperimentId[];
  highlightedExperiments?: ExperimentId[];
  variant?: 'noEntities';
};

function ExperimentsListItems({
  scrollableContainerRef,
  contentSource,
  filterUserId,
  searchQuery,
  onExperimentListItemClick,
  disabledExperimentIds,
  visibleExperimentIds,
  highlightedExperiments,
  variant,
}: ExperimentsListItemsProps) {
  const currentUser = useUserProfile();

  const variables: ExperimentsQueryVariables = {
    userId: filterUserId,
    search: searchQuery,
    contentSource: contentSource,
    experimentIds: visibleExperimentIds,
  };

  const experimentsQuery = useQuery(QUERY_EXPERIMENTS, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const pageInfo = experimentsQuery.data?.experiments.pageInfo as PageInfo | undefined;
  const hasNextPage = usePagination({
    entity: 'experiments',
    pageInfo,
    fetchMore: experimentsQuery.fetchMore,
    dependencies: [],
    scrollableRef: scrollableContainerRef,
    isInitialLoading: experimentsQuery.loading,
    variables,
  });
  const hasNoSearchResults =
    variant !== 'noEntities' &&
    (variables.userId === currentUser?.id || variables.userId === undefined) &&
    !searchQuery;

  return (
    <RenderQuery
      query={experimentsQuery}
      renderData={DataComponent}
      renderNoData={NoDataComponent}
      loadingComponent={EntityCardSkeletonList}
      additionalDataProps={{
        hasNextPage,
        disabledExperimentIds,
        highlightedExperiments,
        onClick: onExperimentListItemClick,
      }}
      additionalNoDataProps={{
        hasNoSearchResults,
        searchQuery,
      }}
      emptyCondition={data => data.experiments.items.length === 0}
    />
  );
}

const NewExperimentFab = styled(FabWithFX)(({ theme }) => ({
  position: 'static',
  padding: theme.spacing(0, 5),
  height: 36,
}));

const Add = styled(AddIcon)(({ theme }) => ({
  marginRight: theme.spacing(3),
}));
