import React from 'react';

import { produce } from 'immer';
import { WritableDraft } from 'immer/dist/types/types-external';

import { isDeckPositionsParameter } from 'client/app/components/Parameters/DeckPositions/lib/deckPositionsParameterUtils';
import { PlateIcon } from 'client/app/icons';
import { State } from 'client/app/state/WorkflowBuilderStateContext';
import { LayoutPreferences, WorkflowConfig } from 'common/types/bundle';
import { OpaqueAlias } from 'common/types/OpaqueAlias';
import { InputPlateIcon } from 'common/ui/icons/InputPlateIcon';
import { NamedPlateIcon } from 'common/ui/icons/NamedPlateIcon';
import { OutputPlateIcon } from 'common/ui/icons/OutputPlateIcon';
import { TipBoxIcon } from 'common/ui/icons/TipBoxIcon';
import { TipWasteIcon } from 'common/ui/icons/TipWasteIcon';

export type NamedPlate = OpaqueAlias<string, 'labware:NamedPlate'>;

export const SIMPLE_LABWARE_TYPE = [
  'outputPlates',
  'inputPlates',
  'tipBoxes',
  'tipWastes',
  'temporaryLocations',
] as const;
export type SimpleLabwareType = (typeof SIMPLE_LABWARE_TYPE)[number];

export type LabwareType = SimpleLabwareType | NamedPlate;
export type LabwarePreference = { labwareType: LabwareType; position: string };

const NAMED_PLATE_PREFIX = 'named_plate:';

export function toNamedPlate(name: string) {
  return (NAMED_PLATE_PREFIX + name) as NamedPlate;
}

export function fromNamedPlate(namedPlate: NamedPlate) {
  return namedPlate.slice(NAMED_PLATE_PREFIX.length);
}

export function formatLabwareTypeName(labware: LabwareType | undefined) {
  if (!labware) {
    return '';
  }
  if (isNamedPlate(labware)) {
    return fromNamedPlate(labware);
  }
  switch (labware) {
    case 'inputPlates':
      return 'Input Plates';
    case 'outputPlates':
      return 'Output Plates';
    case 'temporaryLocations':
      return 'Temporary Locations';
    case 'tipBoxes':
      return 'Tip Boxes';
    case 'tipWastes':
      return 'Tip Wastes';
  }
}

export function isNamedPlate(
  labwareType: LabwareType | undefined,
): labwareType is NamedPlate {
  return labwareType?.indexOf(NAMED_PLATE_PREFIX) === 0;
}

/**
 * returns the list of unique named plates from all configuredDevices
 */
export function getNamedPlates(state: State) {
  const names = state.config.configuredDevices?.reduce((acc, cd) => {
    const plates = cd.layoutPreferences?.plates;
    if (plates) {
      acc.push(...Object.keys(plates));
    }
    return acc;
  }, [] as string[]);

  return Array.from(new Set(names)).map(name => toNamedPlate(name));
}

const LABWARETYPE_TO_LAYOUTPREFERENCES_MAP: {
  [key in SimpleLabwareType]:
    | 'tipboxes'
    | 'inputs'
    | 'outputs'
    | 'tipwastes'
    | 'temporaryLocations';
} = {
  outputPlates: 'outputs',
  inputPlates: 'inputs',
  tipBoxes: 'tipboxes',
  tipWastes: 'tipwastes',
  temporaryLocations: 'temporaryLocations',
};

export function areLabwareTypesEqual(
  labwareType1: LabwareType | undefined,
  labwareType2: LabwareType | undefined,
) {
  return labwareType1 === labwareType2;
}

const DEFAULT_LAYOUT_PREFERENCES: LayoutPreferences = {
  inputs: [],
  outputs: [],
  tipwastes: [],
  tipboxes: [],
  temporaryLocations: [],
  plates: {},
};

/**
 * return the layout preferences from the state.
 */
function getLayoutPreferences(state: State): LayoutPreferences {
  const prefs = state.config.configuredDevices?.find(
    cd => cd.layoutPreferences,
  )?.layoutPreferences;

  return prefs ?? DEFAULT_LAYOUT_PREFERENCES;
}

/**
 * Returns the list of labware types that have the position assigned as a preference.
 * Useful for UI rendering in the deck layout.
 */
export function getLabwareForPosition(state: State, position: string): LabwareType[] {
  const labware: LabwareType[] = [];

  // we're assuming for now that there's only one device with layout preferences
  const prefs = getLayoutPreferences(state);

  for (const key in LABWARETYPE_TO_LAYOUTPREFERENCES_MAP) {
    const lpKey = LABWARETYPE_TO_LAYOUTPREFERENCES_MAP[key as SimpleLabwareType];

    const positions = prefs[lpKey] ?? [];
    if (positions.includes(position)) {
      labware.push(key as SimpleLabwareType);
    }
  }

  for (const [plateName, preferences] of Object.entries(prefs.plates ?? {})) {
    if (preferences?.includes(position)) {
      labware.push(toNamedPlate(plateName));
    }
  }

  return labware;
}

export function getLabwareIndexForPosition(
  state: State,
  labware: LabwareType,
  position?: string,
): number {
  const prefs = getLabwarePreferences(state, labware);
  return prefs.findIndex(p => p === position);
}

/**
 * return the layout preferences from the state.
 * If no device has layout preferences, we'll create one on the first deck-having device
 */
function getLayoutPreferencesDraft(
  state: WritableDraft<State>,
): WritableDraft<LayoutPreferences> {
  const prefs = state.config.configuredDevices?.find(
    cd => cd.layoutPreferences,
  )?.layoutPreferences;

  if (!prefs) {
    // default preferences should have been added when the device was added
    throw new Error('no configured device has layout preferences');
  }

  return prefs;
}

function getLabwarePreferencesDraft(
  draft: WritableDraft<State>,
  labwareType: LabwareType,
) {
  const draftPrefs = getLayoutPreferencesDraft(draft);
  if (isNamedPlate(labwareType)) {
    const plateName = fromNamedPlate(labwareType);
    if (!draftPrefs.plates) {
      draftPrefs.plates = {};
    }
    if (!draftPrefs.plates[plateName]) {
      draftPrefs.plates[plateName] = [];
    }
    return draftPrefs.plates[plateName];
  }
  const lpKey = LABWARETYPE_TO_LAYOUTPREFERENCES_MAP[labwareType];
  if (!draftPrefs[lpKey]) {
    draftPrefs[lpKey] = [];
  }
  return draftPrefs[lpKey]!;
}

function getLabwarePreferences(state: State, labwareType: LabwareType) {
  const prefs = getLayoutPreferences(state);
  if (isNamedPlate(labwareType)) {
    const plateName = fromNamedPlate(labwareType);
    return prefs.plates?.[plateName] ?? [];
  }
  const lpKey = LABWARETYPE_TO_LAYOUTPREFERENCES_MAP[labwareType];
  return prefs[lpKey] ?? [];
}

export function getNumberOfPositions(state: State, labwareType: LabwareType) {
  const labwarePreferences = getLabwarePreferences(state, labwareType);
  return labwarePreferences.length;
}
export function addLabwarePreference(state: State, pref: LabwarePreference) {
  return produce(state, draft => {
    const { position, labwareType } = pref;
    const labwarePreferences = getLabwarePreferencesDraft(draft, labwareType);
    const idx = labwarePreferences.indexOf(position);
    if (idx >= 0) {
      return;
    }
    labwarePreferences.push(position);
    if (!draft.labwarePreferencesAddedOrder[position]) {
      draft.labwarePreferencesAddedOrder[position] = new Set<LabwareType>();
    }
    draft.labwarePreferencesAddedOrder[position]?.delete(labwareType);
    draft.labwarePreferencesAddedOrder[position]?.add(labwareType);
  });
}

export function removeLabwarePreference(state: State, pref: LabwarePreference) {
  return produce(state, draft => {
    const { position, labwareType } = pref;
    const labwarePreferences = getLabwarePreferencesDraft(draft, labwareType);
    const idx = labwarePreferences.indexOf(position);
    labwarePreferences.splice(idx, 1);
    draft.labwarePreferencesAddedOrder[position]?.delete(labwareType);
  });
}

export function removeAllLabwareTypePreferences(state: State, labwareType: LabwareType) {
  return produce(state, draft => {
    const draftPrefs = getLayoutPreferencesDraft(draft);

    if (isNamedPlate(labwareType)) {
      const plateName = fromNamedPlate(labwareType);
      draftPrefs.plates[plateName] = [];
    } else {
      const lpKey = LABWARETYPE_TO_LAYOUTPREFERENCES_MAP[labwareType];
      draftPrefs[lpKey] = [];
    }
    Object.values(draft.labwarePreferencesAddedOrder).forEach(labwareTypes => {
      labwareTypes?.delete(labwareType);
    });
  });
}

export function addAndSetNamedPlate(state: State, plateName: string) {
  return produce(state, draft => {
    const draftPrefs = getLayoutPreferencesDraft(draft);
    if (!draftPrefs.plates[plateName]) {
      draftPrefs.plates[plateName] = [];
      draft.labwarePreferenceType = toNamedPlate(plateName);
    }
  });
}

export function removeNamedPlate(state: State, plateName: string) {
  return produce(state, draft => {
    const draftPrefs = getLayoutPreferencesDraft(draft);
    delete draftPrefs.plates[plateName];

    // remove from any position order tracking
    Object.values(draft.labwarePreferencesAddedOrder).forEach(position => {
      void position?.delete?.(toNamedPlate(plateName));
    });

    // if the named plate we are removing is the currently selected labware type, we need to unselect it
    if (
      isNamedPlate(draft.labwarePreferenceType) &&
      draft.labwarePreferenceType === toNamedPlate(plateName)
    ) {
      draft.labwarePreferenceType = undefined;
    }
  });
}

export function renameNamedPlate(
  state: State,
  oldPlateName: string,
  newPlateName: string,
) {
  return produce(state, draft => {
    const draftPrefs = getLayoutPreferencesDraft(draft);
    // if a plate with the new name already exist, we do nothing.
    if (draftPrefs.plates[newPlateName]) {
      return;
    }
    draftPrefs.plates[newPlateName] = draftPrefs.plates[oldPlateName];
    delete draftPrefs.plates[oldPlateName];
    // if the named plate we are renaming is the currently selected labware type, we need to update it
    if (
      isNamedPlate(draft.labwarePreferenceType) &&
      draft.labwarePreferenceType === toNamedPlate(oldPlateName)
    ) {
      draft.labwarePreferenceType = toNamedPlate(newPlateName);
    }
    // if the named plate we are renaming has preferences, we need to update the labwarePreferencesAddedOrder
    const entries = Object.entries(draft.labwarePreferencesAddedOrder);
    entries.forEach(([position, labwareTypes]) => {
      const lt = [...(labwareTypes ?? [])];
      const idx = lt.indexOf(toNamedPlate(oldPlateName));
      if (idx >= 0) {
        lt.splice(idx, 1, toNamedPlate(newPlateName)),
          (draft.labwarePreferencesAddedOrder[position] = new Set(lt));
      }
    });
  });
}

export function insertLabwarePreference(
  state: State,
  pref: LabwarePreference,
  idx: number,
) {
  return produce(state, draft => {
    const { position, labwareType } = pref;
    const labwarePreferences = getLabwarePreferencesDraft(draft, labwareType);
    labwarePreferences.splice(idx, 0, position);
  });
}

export function getLabwarePreferencesAddedOrderFromConfig(config: WorkflowConfig) {
  const preferencesOrder = {} as { [key: string]: Set<LabwareType> };
  for (const configuredDevice of config.configuredDevices || []) {
    const layoutPrefs = configuredDevice.layoutPreferences;
    if (!layoutPrefs) continue;

    Object.entries(LABWARETYPE_TO_LAYOUTPREFERENCES_MAP).forEach(([key, driverKey]) => {
      layoutPrefs[driverKey]?.forEach(position => {
        if (!preferencesOrder[position]) {
          preferencesOrder[position] = new Set();
        }
        preferencesOrder[position].add(key as LabwareType);
      });
    });

    Object.keys(layoutPrefs.plates).forEach(key => {
      layoutPrefs.plates[key].forEach(position => {
        if (!preferencesOrder[position]) {
          preferencesOrder[position] = new Set();
        }
        preferencesOrder[position].add(toNamedPlate(key));
      });
    });
  }
  return preferencesOrder;
}

/**
 * Uses the given runConfiguration to update the draft. Specifically, the GlobalMixer
 * of the draft config, as well as updating related fields for labwarePreference that
 * are dependent on changes to the GlobalMixer.
 */
export function setDefaultConfig(draft: WritableDraft<State>) {
  // It's important to update the labware preferences here, so we display correct lab items
  // for each deck position.
  draft.labwarePreferencesAddedOrder = getLabwarePreferencesAddedOrderFromConfig(
    draft.config,
  );
  draft.labwarePreferenceType = undefined;

  resetDeckPositionParametersToDefaults(draft);
}

/**
 * Here we reset specific parameters related to selected device or run configuration.
 * When any of those change we reset `parameterName` to `defaultValue` for all element instances
 * so that their respective parameter editors are not broken.
 */
function resetDeckPositionParametersToDefaults(draft: WritableDraft<State>) {
  for (const ei of draft.elementInstances) {
    const elementParamConfig = ei.element.configuration?.parameters;

    if (!elementParamConfig) continue;

    for (const parameterName in elementParamConfig) {
      if (isDeckPositionsParameter(parameterName, ei)) {
        draft.parameters[ei.name][parameterName] =
          elementParamConfig[parameterName]?.defaultValue;
      }
    }
  }
}

export function getLabwareIcon(labware: LabwareType | undefined) {
  if (!labware) return;

  if (isNamedPlate(labware)) {
    return <NamedPlateIcon fontSize="small" />;
  }
  switch (labware) {
    case 'inputPlates':
      return <InputPlateIcon fontSize="small" />;
    case 'outputPlates':
      return <OutputPlateIcon fontSize="small" />;
    case 'temporaryLocations':
      return <PlateIcon fontSize="small" />;
    case 'tipBoxes':
      return <TipBoxIcon fontSize="small" />;
    case 'tipWastes':
      return <TipWasteIcon fontSize="small" />;
    default:
      return;
  }
}
