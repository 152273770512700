import React, { PropsWithChildren } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import { styled } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import isEqual from 'lodash/isEqual';

import EarlyToken from 'client/app/apps/authentication/components/EarlyToken';
import OrganizationPage from 'client/app/apps/authentication/components/OrganizationPage';
import {
  Auth0ClientData,
  emptyClientData,
  useAuth0ClientData,
  useAuth0Config,
} from 'client/app/apps/authentication/hooks';
import { wellKnownURLParams } from 'client/app/apps/authentication/urlParams';
import Colors from 'common/ui/Colors';
/**
 * This component checks the auth status and decide to show the LoginPage or the app.
 */
function LoginCheck({ children }: PropsWithChildren<{}>) {
  const params = new URLSearchParams(window.location.search);
  const url = new URL(window.location.href);
  const { clientData, setClientData, storeClientData } = useAuth0ClientData({ url });
  const { auth0Config } = useAuth0Config();

  const lauchAuth0Login =
    !isEqual(emptyClientData, clientData) &&
    auth0Config &&
    auth0Config !== 'fetch_auth0_config_error';

  if (lauchAuth0Login && !clientData.connection) {
    // This _should_ never happen. But it does happen under certain conditions.
    // We don't understand at the moment when this can happen and hope that
    // that instrumenting the code this way can help us.
    Sentry.captureMessage(`Connection missing in client data.`, {
      level: 'error',
      extra: {
        url,
        clientData,
      },
      tags: {
        cell: 'core',
        url: url.toString(),
        'clientData.client_id': clientData.clientId,
        'clientData.cached': clientData.cached,
      },
    });
  }

  return (
    <>
      {(isEqual(emptyClientData, clientData) ||
        auth0Config === 'fetch_auth0_config_error') && (
        <LoginFlow>
          <OrganizationPage
            setAuthorizationData={setClientData}
            // Allow overriding auth0 connection via url params in case someone wants to test
            // the auth with a different user source - in particular with a different SSO setup.
            connectionOverride={params.get(wellKnownURLParams.connectionOverride)}
            missingAuth0Config={auth0Config === 'fetch_auth0_config_error'}
          />
        </LoginFlow>
      )}
      {lauchAuth0Login && (
        <Auth0Provider
          domain={auth0Config.domain}
          clientId={clientData.clientId}
          useRefreshTokens
          useRefreshTokensFallback
          cacheLocation="localstorage"
          authorizationParams={{
            audience: auth0Config.audience,
            connection: clientData.connection,
            // Include the client ID in the URL so that we don't have to store it at this point.
            // If we store _before_ the login is completed, a user navigating back from the
            // authentication screen show by Auth0 will NOT see the organization page. The user
            // will be redirected to the authentication screen. This would make changing the
            // organization name very cumbersome.
            redirect_uri: mkRedirectURI(clientData),
            // Scopes need to be spelled out explicitly in v2.
            scope: 'openid profile email offline_access',
          }}
        >
          <LoginTransition onLoginCompletedSuccessfully={storeClientData}>
            <Login />
            <EarlyToken>{children}</EarlyToken>
          </LoginTransition>
        </Auth0Provider>
      )}
    </>
  );
}

function mkRedirectURI({ clientId, connection }: Auth0ClientData): string {
  const url = new URL(window.location.href);
  // Add custom parameters as a means of saving state without having to use local storage.
  // This helps achieving some of the login behaviour we want (see WARNING in antha-com/client/app/apps/authentication/hooks.tsx)
  if (!url.searchParams.has(wellKnownURLParams.clientId)) {
    url.searchParams.append(wellKnownURLParams.clientId, clientId);
  }
  if (!url.searchParams.has(wellKnownURLParams.connection)) {
    url.searchParams.append(wellKnownURLParams.connection, connection);
  }

  // Delete params that the Auth0 library adds. We are not interested in them.
  url.searchParams.delete('error');
  url.searchParams.delete('error_description');
  url.searchParams.delete('state');
  url.searchParams.delete('code');

  return url.toString();
}

function mkReturnToURI(): string {
  const url = new URL(window.location.href);

  // Delete params that the Auth0 library adds and the ones we add.
  url.searchParams.delete('error');
  url.searchParams.delete('error_description');
  url.searchParams.delete('state');
  url.searchParams.delete('code');
  url.searchParams.delete(wellKnownURLParams.clientId);
  url.searchParams.delete(wellKnownURLParams.connection);

  return url.toString();
}

// React component that simply shows the login flow background to make the loading
// of different screens in the login flow more pleasant to see. Without this, users
// would see the organization page and then a black page while the page from Auth0
// is loading.
// TODO(SYN-6965): this still doesn't fix the black screen users see _after_ a
// successful login. My guess it's that it happens because it's "too far down"
// the rendering hierarchy. Ideally we'd have this component at the top-level
// or anyway before the Auth0 provider. Which we did initially. And that
// broke vertical scrolling for _all_ screens.
const LoginTransition = React.memo(function LoginTransition({
  children,
  onLoginCompletedSuccessfully,
}: {
  children?: React.ReactNode | undefined;
  onLoginCompletedSuccessfully: () => void;
}) {
  const { isLoading, isAuthenticated } = useAuth0();

  if (!isAuthenticated || isLoading) {
    return <LoginFlow>{children}</LoginFlow>;
  }

  onLoginCompletedSuccessfully();
  return <>{children}</>;
});

const Login = React.memo(function Login() {
  const { isLoading, loginWithRedirect, isAuthenticated } = useAuth0();

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    (async () => {
      await loginWithRedirect({
        appState: {
          // Restore the current URL in the browser location bar after logging in.
          returnTo: mkReturnToURI(),
        },
      });
    })();
  }

  return null;
});

const LoginFlow = styled('div')(({ theme }) => ({
  // Linear gradient background of BLUE_50 and INDIGO_70 with opacity of 0.3 overlayed with white background.
  background: `linear-gradient(126.6deg, rgba(22, 112, 255, 0.3) 47.04%, rgba(151, 16, 255, 0.3) 82.93%),\
linear-gradient(${Colors.WHITE}, ${Colors.WHITE})`,
  display: 'grid',
  fontSize: theme.typography.pxToRem(14),
  height: 'fit-content',
  minHeight: '100vh',
}));

export default LoginCheck;
