import React, { useContext, useEffect, useRef, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';

import OutputVisualisation from 'client/app/components/ElementPlumber/ElementOutputs/OutputVisualisation';
import { OutputEntity } from 'client/app/components/ElementPlumber/ElementOutputs/types';
import stopPropagation from 'common/lib/stopPropagation';
import { Parameter } from 'common/types/bundle';
import ZoomContext from 'common/ui/components/Workspace/ZoomContext';

const TOOLTIP_OFFSET = 30;
const TOOLTIP_OFFSET_DELTA = TOOLTIP_OFFSET / 10;

type Props = {
  anchorEl: HTMLElement | null;
  elementId: string;
  data: Parameter;
};

export default function PortTooltip({ anchorEl, data, elementId }: Props) {
  const [activeEntity, setActiveEntity] = useState<OutputEntity | undefined>();

  const savedAnchor = useSavedAnchor(anchorEl);
  const open = useOpenForActiveEntity(activeEntity, anchorEl);
  const id = open ? `${elementId}-${data.name}` : undefined;

  const close = () => {
    setActiveEntity(undefined);
  };

  const zoom = useContext(ZoomContext);
  const verticalOffset =
    zoom < 1 ? TOOLTIP_OFFSET : TOOLTIP_OFFSET - TOOLTIP_OFFSET_DELTA * zoom;

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={close}>
      <HoverArea
        id={id}
        open={open}
        anchorEl={savedAnchor}
        placement="right-start"
        onWheel={stopPropagation}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-verticalOffset, 0],
            },
          },
        ]}
      >
        <Container elevation={3}>
          <OutputVisualisation
            elementId={elementId}
            parameter={data}
            entity={activeEntity}
            withDropdown
            onClick={setActiveEntity}
          />
        </Container>
      </HoverArea>
    </ClickAwayListener>
  );
}

/**
 * Saves the Port element as the anchor element in case
 * the cursor is moved away from the port and anchorEl is set to null
 */
function useSavedAnchor(anchorEl: HTMLElement | null) {
  const anchorRef = useRef<HTMLElement | null>(null);
  if (!anchorRef.current && anchorEl) {
    anchorRef.current = anchorEl;
  }
  return anchorRef.current;
}

/**
 * Controls the open state of the tooltip to make sure
 * it is always open when one of the blue chips is active
 */
function useOpenForActiveEntity(
  activeEntity: OutputEntity | undefined,
  anchorEl: HTMLElement | null,
) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!activeEntity) {
      setOpen(Boolean(anchorEl));
    }
  }, [activeEntity, anchorEl]);
  return open;
}

const Container = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  borderRadius: theme.spacing(2),

  minHeight: 34,
  maxHeight: 280,
  overflow: 'hidden auto',
}));

const HoverArea = styled(Popper)(({ theme }) => ({
  zIndex: 1,
  padding: theme.spacing(5),
}));
