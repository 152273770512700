import React, { useCallback, useEffect, useRef } from 'react';

import { FileMetadata } from 'client/app/api/FilesApi';
import FileSegment from 'client/app/components/FileBrowser/FileSegment';
import {
  FileBrowserFileSingleSelection,
  FileBrowserValueTree,
} from 'client/app/lib/file-browser/types';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  valueTree: FileBrowserValueTree;
  displayPathSegments: string[];
  onItemClick: (item: FileMetadata, path: string) => void;
  onToggleItemSelected: (path: FileBrowserFileSingleSelection) => void;
  setSelection: (paths: FileBrowserFileSingleSelection[] | null) => void;
  selectMultiple: boolean;
  skipDisplayPathSegments?: string[];
};

const useStyles = makeStylesHook({
  segmentView: {
    alignItems: 'stretch',
    border: `1px ${Colors.GREY_30} solid`,
    display: 'flex',
    flex: 1,
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    overflow: 'auto',
    whiteSpace: 'nowrap',
  },
  segmentButton: {
    borderRadius: 0,
    textAlign: 'left',
  },
});

const FileSegmentView = function (props: React.PropsWithChildren<Props>) {
  const classes = useStyles();
  const segmentViewRef = useRef<HTMLDivElement>(null);

  const jumpToMaxScroll = useCallback(() => {
    if (segmentViewRef.current) {
      segmentViewRef.current.scrollLeft = segmentViewRef.current.scrollWidth;
    }
  }, []);

  // If anything has changed, update the scroll position to make sure the
  // right-most segment is visible.
  useEffect(() => {
    jumpToMaxScroll();
  }, [jumpToMaxScroll, props]);

  const { displayPathSegments, valueTree, skipDisplayPathSegments } = props;

  const fileSegments = [];
  let node = valueTree;
  for (let ii = 0; ii < displayPathSegments.length; ii++) {
    const segment = displayPathSegments[ii];

    // If it doesn't have a trailing slash, we infer that it must be a file
    // and not a directory, so we don't need to add segment for it.
    if (!segment.endsWith('/')) {
      continue;
    }

    const path = displayPathSegments.slice(0, ii + 1).join('');
    let valueTree;
    if (node && typeof node[segment] !== 'string') {
      valueTree = node[segment] as FileBrowserValueTree;
    }

    // If this is a segment to skip, we will not push the FileSegment to the FileSegmentView.
    if (
      !skipDisplayPathSegments ||
      (skipDisplayPathSegments && segment !== skipDisplayPathSegments[ii])
    ) {
      fileSegments.push(
        <FileSegment
          key={path}
          path={path}
          // `true` is a flag value in the FileBrowserValueTree meaning that the
          // segment is a file, not a directory. So, we need to avoid indexing
          // into it.
          valueTree={valueTree}
          nextDisplayPathSegment={displayPathSegments[ii + 1]}
          onItemClick={props.onItemClick}
          onToggleItemSelected={props.onToggleItemSelected}
          onDataLoaded={jumpToMaxScroll}
          setSelection={props.setSelection}
          selectMultiple={props.selectMultiple}
          showContextMenuBar={ii === displayPathSegments.length - 1 && ii > 0}
        />,
      );
    }

    // `true` is the flag value for the a file, meaning there is no
    // further to iterate
    if (node && typeof node[segment] !== 'string') {
      node = node[segment] as FileBrowserValueTree;
    }
  }

  return (
    <div className={classes.segmentView} ref={segmentViewRef}>
      {props.children}
      {fileSegments}
    </div>
  );
};

export default FileSegmentView;
