import React from 'react';

import { styled } from '@mui/material/styles';

import { ExecutionStatusIndicatorWithTooltip } from 'client/app/components/ExecutionStatusIndicator';
import {
  ArrayElement,
  ExecutionsQuery,
  ExecutionSummaryFragmentFragment as ExecutionSummaryFragment,
  SimulationSummaryFragmentFragment as SimulationSummaryFragment,
} from 'client/app/gql';
import { executionRoutes } from 'client/app/lib/nav/actions';
import { formatDuration } from 'common/lib/format';
import { EntityCard } from 'common/ui/components/EntityCard';
import EntityCardAction from 'common/ui/components/EntityCardAction';
import EntityCardStatus from 'common/ui/components/EntityCardStatus';
import { DataSetIcon } from 'common/ui/icons/DataSetIcon';
import { ExecutionIcon } from 'common/ui/icons/Execution';

type Execution = ArrayElement<ExecutionsQuery['executions']['items']>;

type ExecutionRowProps = {
  execution:
    | Execution
    | (ExecutionSummaryFragment & { simulation: SimulationSummaryFragment });
  showDatasetIcon?: boolean;
  hasDatasets?: boolean;
  isLink?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
  isSelected?: boolean;
};

export function ExecutionCard({
  execution,
  showDatasetIcon = false,
  hasDatasets = false,
  isLink,
  onClick,
  isDisabled = false,
  isSelected = false,
}: ExecutionRowProps) {
  const {
    id,
    simulation: { name, timeEstimateSeconds },
    lastModifiedAt,
    status,
    user: { displayName: author },
  } = execution;

  const path = isLink
    ? executionRoutes.executionDetailsSubscreen.getPath({
        executionId: id,
      })
    : undefined;

  const nameColumn = { label: 'Execution name', value: name };
  const authorColumn = { label: 'Scheduled by', value: author };
  const dateColumn = { label: 'Date scheduled', value: new Date(lastModifiedAt) };
  const additionalColumn = {
    label: 'Estimated time',
    value: timeEstimateSeconds ? formatDuration(timeEstimateSeconds) : 'N/A',
  };

  const interaction = {
    to: path,
    onClick,
  };

  return (
    <EntityCard
      icon={<ExecutionIcon fontSize="small" />}
      nameColumn={nameColumn}
      authorColumn={authorColumn}
      dateColumn={dateColumn}
      additionalColumn={additionalColumn}
      interaction={interaction}
      isDisabled={isDisabled}
      isSelected={isSelected}
      rightSlot={
        <>
          <EntityCardStatus
            disabled={isDisabled}
            icon={<ExecutionStatusIndicatorWithTooltip status={status} />}
          />
          {showDatasetIcon && (
            <EntityCardAction
              label="Data"
              tooltipText={
                isDisabled
                  ? ''
                  : hasDatasets
                  ? 'Data available. Go to the execution page to see details.'
                  : 'This execution doesn’t have data attached. Please go to the execution page to upload data first'
              }
              action={
                <IconContainer>
                  <Icon fontSize="medium" isDisabled={!hasDatasets} />
                </IconContainer>
              }
            />
          )}
        </>
      }
    />
  );
}

const IconContainer = styled('div')(() => ({
  display: 'flex',
}));

const Icon = styled(DataSetIcon, {
  shouldForwardProp: propName => propName !== 'isDisabled',
})<{ isDisabled: boolean }>(({ theme, isDisabled }) => ({
  selfAlign: 'center',
  ...(isDisabled && { color: theme.palette.text.disabled }),
}));
