import { v4 as uuid } from 'uuid';

import {
  AllDevicesQuery,
  ArrayElement,
  DeviceCommonFragment as DeviceCommon,
} from 'client/app/gql';
import { indexBy } from 'common/lib/data';
import { ConfiguredDevice, ConfiguredDeviceId } from 'common/types/bundle';
import {
  getGenericDeviceType,
  getGenericDeviceTypeFromAnthaClass,
  removeAccessibleDeviceByDeviceId,
} from 'common/types/bundleConfigUtils';
import { getAnthaDeviceTypeByDeviceClass } from 'common/types/bundleTransforms';

type GraphQLDevice = ArrayElement<AllDevicesQuery['devices']>;

/**
 * Check that all devices in the workflow device configuration actually still exists in the devices list,
 * and removes devices that do not exist anymore.
 */
export function removeMissingDeviceFromDeviceConfiguration(
  configuredDevices: ConfiguredDevice[] | undefined,
  devices: readonly GraphQLDevice[],
): ConfiguredDevice[] {
  if (!configuredDevices) {
    return [];
  }
  const devicesKeys = new Set(devices.map(d => d.id as string));
  return configuredDevices.filter(cd => devicesKeys.has(cd.deviceId));
}

export function buildConfiguredDevice(
  selectedDevices: readonly DeviceCommon[],
  selectedRunConfigs?: { [deviceUUID: string]: string | undefined },
  existingDevices?: ConfiguredDevice[],
): { configuredDevices: ConfiguredDevice[]; skippedAccessibleDevices: boolean } {
  const ret: ConfiguredDevice[] = [];
  let skippedAccessibleDevices = false;

  const existingDeviceTypes = new Set(
    existingDevices?.map(d => getGenericDeviceType(d.type)),
  );

  for (const device of selectedDevices) {
    if (!device.model.anthaModel) {
      // some devices can't actually be added to the config
      continue;
    }
    const cd: ConfiguredDevice = {
      id: uuid() as ConfiguredDeviceId,
      deviceId: device.id,
      type: getAnthaDeviceTypeByDeviceClass(device.model.anthaLangDeviceClass),
      model: device.model.anthaModel,
      runConfigId: selectedRunConfigs?.[device.id],
    };
    ret.push(cd);

    // if the device has accessible devices, they should be added  automatically
    if (device.accessibleDevices && device.accessibleDevices.length > 0) {
      // first, update the configured device to reference the accesiible devices
      cd.accessibleDeviceConfigurationIds = [];
      // then add all accessible devices to the workflow config.
      for (const accessibleDevice of device.accessibleDevices) {
        // Don't add the accessible device if there is already a selected device of the same type.
        if (
          !existingDeviceTypes.has(
            getGenericDeviceTypeFromAnthaClass(
              accessibleDevice.model.anthaLangDeviceClass,
            ),
          )
        ) {
          const id = uuid() as ConfiguredDeviceId;
          cd.accessibleDeviceConfigurationIds.push(id);

          ret.push({
            id,
            deviceId: accessibleDevice.id,
            model: accessibleDevice.model.anthaModel ?? undefined,
            // As of Jun 2020, accessible devices are always plate washers
            // and plate readers which have no run configs.
            // This could change in the future.
            type: getAnthaDeviceTypeByDeviceClass(
              accessibleDevice.model.anthaLangDeviceClass,
            ),
          });
        } else {
          removeAccessibleDeviceByDeviceId(ret, accessibleDevice.id);
          skippedAccessibleDevices = true;
        }
      }
    }
  }

  return { configuredDevices: ret, skippedAccessibleDevices };
}

export function getSelectedDevices(
  allDevices: readonly DeviceCommon[],
  configuredDevices: ConfiguredDevice[],
): DeviceCommon[] {
  const selectedIds = configuredDevices.map(cd => cd.deviceId);
  return allDevices.filter(d => selectedIds.includes(d.id));
}
export function configHasDeletedDevice(
  allDevices: readonly DeviceCommon[],
  configuredDevices: ConfiguredDevice[],
) {
  const selectedDevices = getSelectedDevices(allDevices, configuredDevices);
  return selectedDevices.length < new Set(configuredDevices.map(cd => cd.deviceId)).size;
}

/**
 * For each device in the workflow config, if they have a run config check whether their version is outdated.
 */
export function configHasOutdatedDeviceRunConfig(
  allDevices: readonly DeviceCommon[],
  configuredDevices: ConfiguredDevice[],
) {
  const selectedDevices = getSelectedDevices(allDevices, configuredDevices);
  const devicesById = indexBy(selectedDevices, 'id');
  return configuredDevices.some(cd => {
    if (
      // the device still exists
      devicesById[cd.deviceId] &&
      // has a config specified
      cd.runConfigId
    ) {
      // the config does exist
      const latestRunConfig = devicesById[cd.deviceId].runConfigSummaries.find(
        config => config.id === cd.runConfigId,
      );

      return (latestRunConfig?.version ?? 1) > (cd.runConfigVersion ?? 1);
    }
    return false;
  });
}

export function configHasDeletedDeviceRunConfig(
  allDevices: readonly DeviceCommon[],
  configuredDevices: ConfiguredDevice[],
) {
  const selectedDevices = getSelectedDevices(allDevices, configuredDevices);
  const devicesById = indexBy(selectedDevices, 'id');
  return configuredDevices.some(cd => {
    return (
      // the device still exists
      devicesById[cd.deviceId] &&
      // has a config specified
      cd.runConfigId &&
      // but the config does not exist
      !devicesById[cd.deviceId]?.runConfigSummaries
        .map(rc => rc.id as string)
        .includes(cd.runConfigId)
    );
  });
}

export function isLiquidHandlingDevice(device: DeviceCommon) {
  return !!device.model?.series?.category?.features?.hasLiquidClasses;
}
