import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import TemplateWorkflowCard from 'client/app/components/cards/TemplateWorkflowCard';
import { ArrayElement, TemplateWorkflowsQuery } from 'client/app/gql';
import { useInfiniteScroll } from 'client/app/hooks/useInfiniteScrolling';
import doNothing from 'common/lib/doNothing';
import { loadingWithinDialog } from 'common/ui/commonStyles';
import CardGrid from 'common/ui/components/CardGrid';
import DialogWrap from 'common/ui/components/Dialog/DialogWrap';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type GraphQLTemplateWorkflow = ArrayElement<
  TemplateWorkflowsQuery['templateWorkflows']['items']
>;

export default function TemplateWorkflowsGrid(props: {
  hasNextPage: boolean;
  isLoading: boolean;
  nextPage: () => void;
  templateWorkflows: readonly GraphQLTemplateWorkflow[];
  refetch: () => void;
  closeDialog?: () => void;
}) {
  const { hasNextPage, isLoading, nextPage, templateWorkflows, refetch, closeDialog } =
    props;

  const elRef = useInfiniteScroll({
    canFetchMore: hasNextPage,
    nextPage,
    isLoading,
  });
  const classes = useStyles();

  if (isLoading) {
    return (
      <DialogWrap dialog className={classes.circularLoadingFullDialog}>
        <CircularProgress />
      </DialogWrap>
    );
  }

  if (templateWorkflows.length === 0) {
    return (
      <NoEntitiesMessage
        entityName="templates"
        messageType={MessageType.NO_FILTER_RESULTS}
      />
    );
  }

  return (
    <div ref={elRef} className={classes.container}>
      <CardGrid>
        {templateWorkflows.map(templateWorkflow => {
          return (
            <TemplateWorkflowCard
              key={templateWorkflow.id}
              templateWorkflow={templateWorkflow}
              onDeleteCompleted={refetch}
              closeDialog={closeDialog ?? doNothing}
            />
          );
        })}
      </CardGrid>
    </div>
  );
}

const useStyles = makeStylesHook({
  circularLoadingFullDialog: {
    ...loadingWithinDialog.circularLoadingFullDialog,
  },
  container: {
    width: '100%',
    overflow: 'auto',
    textAlign: 'center',
  },
});
