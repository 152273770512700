import React from 'react';

import { byName } from 'common/lib/strings';
import { ContentSourceType } from 'common/types/contentSource';
import { Plate, WellContents, WellLocationOnDeckItem } from 'common/types/mix';
import { PlateType } from 'common/types/plateType';
import { getHumanReadableSource } from 'common/ui/components/DeviceCard/DeviceCard';

export function getPlateInfo(plate: PlateType | Plate) {
  if (!plate) {
    return undefined;
  }

  const { manufacturer, type } = plate;

  const { wellShape, defaultResidualVolume, catalogNumber, contentSource } =
    'wellShape' in plate ? plate : ({} as Partial<PlateType>);

  const originValue = getHumanReadableSource(
    contentSource as unknown as ContentSourceType,
  ).humanReadableName;

  return (
    <ul style={{ padding: '0 16px' }}>
      {wellShape && <li>Well volume: {wellShape.volumeOverrideUl}ul</li>}
      {defaultResidualVolume && <li>Residual volume: {defaultResidualVolume}ul</li>}
      {manufacturer && <li>Manufacturer: {manufacturer}</li>}
      {catalogNumber && <li>Number: {catalogNumber}</li>}
      {type && <li>Type: {type}</li>}
      {contentSource && <li>Owner: {originValue}</li>}
    </ul>
  );
}

type WellContentsWithPosition = {
  loc: WellLocationOnDeckItem;
  wellContents: WellContents;
};

// As opposed to PlateContentsMatrix
type PlateContentsFlat = WellContentsWithPosition[];

export function flattenPlateContents(plate: Plate): PlateContentsFlat | undefined {
  const res: PlateContentsFlat = [];
  const matrix = plate.contents;
  if (!matrix) {
    return undefined;
  }
  for (const colIdx of Object.keys(matrix)) {
    const column = matrix[Number(colIdx)];
    for (const rowIdx of Object.keys(column)) {
      res.push({
        loc: {
          row: Number(rowIdx),
          col: Number(colIdx),
          deck_item_id: plate.id,
        },
        wellContents: column[Number(rowIdx)],
      });
    }
  }
  return res;
}

export function getWellName(liquid: WellContents) {
  if (liquid.name) {
    return liquid.name;
  } else if (liquid.sub_liquids) {
    return buildWellNameFrom(liquid.sub_liquids);
  } else if (liquid.components) {
    return buildWellNameFrom(liquid.components);
  } else {
    // Empty name, and no components. The backend should never return
    // liquids like this, but if it does there's not much we can do.
    return '';
  }
}

export function buildWellNameFrom(reagents: { name: string }[]) {
  const reagentsSortedByName = [...reagents];
  // Sort, for consistency with how we show a list of components
  // in the RightPanel
  reagentsSortedByName.sort(byName);
  return (
    <div>
      {reagentsSortedByName.map((reagent, index) => (
        <div key={index}>
          {reagent.name}
          {index < reagents.length - 1 && ' +'}
        </div>
      ))}
    </div>
  );
}
