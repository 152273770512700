import { useMemo } from 'react';

import { getSensibleMeasurementUnits } from 'common/ui/components/ParameterEditors/unitRegistry';

export default function useUnitOptions() {
  return useMemo(() => {
    const concUnits = getSensibleMeasurementUnits('Concentration');
    return concUnits.map(value => ({ label: value, value }));
  }, []);
}
