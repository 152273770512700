import React from 'react';

import MuiErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MuiInfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box, { BoxProps } from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';

type Variant = 'info' | 'warning' | 'success' | 'info-grey' | 'error';

type Props = {
  variant: Variant;
  header?: string;
  content: JSX.Element;
} & BoxProps;

type Colour = {
  icon: string;
  iconBackground: string;
  textBackground: string;
};

export default function Feedback({ header, content, variant, ...boxProps }: Props) {
  const hasHeader = !!header;
  const colours = useColours(variant);

  let icon: JSX.Element;
  switch (variant) {
    case 'error':
      icon = <ErrorOutlineIcon htmlColor={colours.icon} />;
      break;
    case 'info':
    case 'info-grey':
    case 'warning':
    case 'success':
    default:
      icon = <InfoOutlinedIcon htmlColor={colours.icon} />;
      break;
  }

  return (
    <Box display="flex" {...boxProps}>
      <IconContainer sx={{ backgroundColor: colours.iconBackground }}>
        {icon}
      </IconContainer>
      <TextContainer sx={{ backgroundColor: colours.textBackground }}>
        {hasHeader && <PrimaryText variant="h5">{header}</PrimaryText>}
        {content}
      </TextContainer>
    </Box>
  );
}

const useColours = (variant: Variant): Colour => {
  const theme = useTheme();
  switch (variant) {
    case 'info':
      return {
        icon: Colors.BLUE_40,
        iconBackground: Colors.BLUE_5,
        textBackground: Colors.BLUE_0,
      };
    case 'success':
      return {
        icon: theme.palette.success.main,
        iconBackground: Colors.GREEN_10,
        textBackground: Colors.SUCCESS_LIGHT_BG,
      };
    case 'warning':
      return {
        icon: theme.palette.warning.main,
        iconBackground: '#FFDEAD',
        textBackground: Colors.WARNING_LIGHT_BG,
      };
    case 'error':
      return {
        icon: theme.palette.error.main,
        iconBackground: '#FFBFC5',
        textBackground: Colors.ERROR_LIGHT_BG,
      };
    case 'info-grey':
    default:
      return {
        icon: theme.palette.text.primary,
        iconBackground: Colors.GREY_40,
        textBackground: Colors.GREY_20,
      };
  }
};

const TextContainer = styled('div')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: theme.spacing(0, 3, 3, 0),
}));

const IconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: theme.spacing(3, 0, 0, 3),
}));

const InfoOutlinedIcon = styled(MuiInfoOutlinedIcon)(({ theme }) => ({
  alignSelf: 'center',
  margin: theme.spacing(3, 4),
}));

const ErrorOutlineIcon = styled(MuiErrorOutlineIcon)(({ theme }) => ({
  alignSelf: 'center',
  margin: theme.spacing(3, 4),
}));

const PrimaryText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));
