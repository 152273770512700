import { FileObject } from 'common/types/fileParameter';

function getFileNameFromFileBrowserPath(path: string): string {
  const filenameRegex = /[^/]+$/;
  const match = path.match(filenameRegex);
  if (match) {
    return match[0];
  }

  return path;
}

export default function getFileObjectFromPath(path: string): FileObject {
  return {
    Name: getFileNameFromFileBrowserPath(path),
    Path: path,
  };
}
