export function TypeColors(): { color: string; type: string; example: any }[] {
  return [
    // maps and strange types (not important, too weird to comprehend atm anyways)
    { color: '#cccccc', type: 'map[string]*wtype.LHComponent', example: {} }, // TODO: remove when we stop supporting LHComponent
    { color: '#cccccc', type: 'map[string]*wtype.Liquid', example: {} },
    { color: '#cccccc', type: 'map[string][2]string', example: {} },
    { color: '#cccccc', type: 'map[string][]*wtype.LHComponent', example: {} }, // TODO: remove when we stop supporting LHComponent
    { color: '#cccccc', type: 'map[string][]*wtype.Liquid', example: {} },
    { color: '#cccccc', type: 'map[string][]float64', example: {} },
    { color: '#cccccc', type: 'map[string][]doe.Run', example: {} },
    { color: '#cccccc', type: 'map[string][]sequences/kmer.Hit', example: {} },
    { color: '#cccccc', type: 'map[string][]wtype.DNASequence', example: {} },
    { color: '#cccccc', type: 'map[string][]wunit.Concentration', example: {} },
    { color: '#cccccc', type: 'map[string][]wunit.Moles', example: {} },
    { color: '#cccccc', type: 'map[string][]wunit.Volume', example: {} },
    { color: '#cccccc', type: 'map[string][]string', example: {} },
    { color: '#cccccc', type: 'map[string]bool', example: {} },
    { color: '#cccccc', type: 'map[string]float64', example: {} },
    { color: '#cccccc', type: 'map[string]lib.DNASeqAndConc', example: {} },
    { color: '#cccccc', type: 'map[string]lib.positionConcPair', example: {} },
    { color: '#cccccc', type: 'map[string]doe.DOEPair', example: {} },
    { color: '#cccccc', type: 'map[string]sequences/kmer.Hit', example: {} },
    {
      color: '#cccccc',
      type: 'map[string]sequences/oligos.Primer',
      example: {},
    },
    { color: '#cccccc', type: 'map[string]wtype.DNASequence', example: {} },
    { color: '#cccccc', type: 'map[string]wtype.File', example: {} },
    { color: '#cccccc', type: 'map[string]wtype.PolicyName', example: {} },
    { color: '#cccccc', type: 'map[string]wunit.Concentration', example: {} },
    { color: '#cccccc', type: 'map[string]wunit.Density', example: {} },
    { color: '#cccccc', type: 'map[string]wunit.Mass', example: {} },
    { color: '#cccccc', type: 'map[string]wunit.Volume', example: {} },
    { color: '#cccccc', type: 'map[string]lib.Dataset', example: {} },
    { color: '#cccccc', type: 'map[string]string', example: {} },
    { color: '#cccccc', type: 'map[string][]float64', example: {} },
    { color: '#cccccc', type: 'map[string]float64', example: {} },
    { color: '#cccccc', type: 'map[string][]doe.Run', example: {} },
    { color: '#cccccc', type: 'map[string][]wunit.Volume', example: {} },
    { color: '#cccccc', type: 'map[string]wunit.Volume', example: {} },
    { color: '#cccccc', type: 'map[string]bool', example: {} },
    { color: '#cccccc', type: 'map[string]int', example: {} },
    {
      color: '#cccccc',
      type: 'map[string]sequences/oligos.Primer',
      example: {},
    },
    { color: '#cccccc', type: 'map[string]wunit.Concentration', example: {} },
    { color: '#cccccc', type: 'map[string][]wtype.DNASequence', example: {} },
    { color: '#cccccc', type: 'map[string]wtype.DNASequence', example: {} },
    { color: '#cccccc', type: 'map[string]lib.Dataset', example: {} },
    { color: '#cccccc', type: 'map[string]wunit.Density', example: {} },
    { color: '#cccccc', type: 'map[string][]*wtype.LHComponent', example: {} }, // TODO: remove when we stop supporting LHComponent
    { color: '#cccccc', type: 'map[string]*wtype.LHComponent', example: {} }, // TODO: remove when we stop supporting LHComponent
    { color: '#cccccc', type: 'map[string][]*wtype.Liquid', example: {} },
    { color: '#cccccc', type: 'map[string]*wtype.Liquid', example: {} },
    { color: '#cccccc', type: 'map[string]wunit.Mass', example: {} },
    { color: '#cccccc', type: 'map[string][]string', example: {} },
    { color: '#cccccc', type: 'map[string]string', example: {} },
    { color: '#cccccc', type: 'map[string]lib.positionConcPair', example: {} },
    { color: '#cccccc', type: '[]doe.Run', example: [] },
    { color: '#cccccc', type: '[]*image.NRGBA', example: [] },
    { color: '#cccccc', type: '[]doe.Run', example: [] },
    { color: '#cccccc', type: '[]image/color.Color', example: ['red', 'blue'] },
    { color: '#cccccc', type: '[]search.Thingfound', example: [] },
    {
      color: '#cccccc',
      type: 'lib.relativeTransformationEfficiency',
      example: 0,
    },
    { color: '#cccccc', type: 'lib.transformationEfficiency', example: 0 },
    { color: '#cccccc', type: '[]PCR.PCRReaction', example: [] },
    { color: '#cccccc', type: '[]enzymes.Assemblyparameters', example: [] },

    // basic ones
    { color: '#C90000', type: 'error', example: 'error' },
    {
      color: '#C90000',
      type: '[]error',
      example: ['error', 'something went wrong'],
    },
    { color: '#a894b2', type: 'bool', example: false },
    { color: '#a291aa', type: 'float64', example: 0.0 },
    { color: '#a291aa', type: '[]float64', example: [] },
    { color: '#a291aa', type: '[][]float64', example: [[], []] },
    { color: '#f39619', type: 'int', example: 0 },
    { color: '#f39619', type: '[]int', example: [] },
    { color: '#f39619', type: 'uint8', example: 0 },
    { color: '#f39619', type: '[]uint8', example: [] },
    { color: '#f5d328', type: 'string', example: '' },
    { color: '#f5d328', type: 'wtype.PolicyName', example: '' },
    { color: '#f5d328', type: '[]string', example: [] },
    { color: '#f5d328', type: '[2]string', example: ['a', 'b'] },
    { color: '#f5d328', type: '[3]string', example: ['a', 'b', 'c'] },
    { color: '#f5d328', type: '[4]string', example: ['a', 'b', 'c', 'd'] },
    { color: '#f5d328', type: '[2]string', example: ['a', 'b'] },
    { color: '#f5d328', type: '[][]string', example: [[], []] },

    // generic data
    { color: '#8b008b', type: 'wtype.File', example: {} },
    { color: '#8b008b', type: '[]wtype.File', example: [] },
    { color: '#5b005b', type: '[]lib.TimecourseData', example: [] },

    // wtype
    { color: '#51a7f9', type: '*wtype.LHPlate', example: 'greiner384' }, // TODO: remove when we stop supporting LHPlate
    { color: '#51a7f9', type: '*wtype.Plate', example: 'greiner384' },
    { color: '#51a7f9', type: '*wtype.LHComponent', example: 'water' }, // TODO: remove when we stop supporting LHComponent
    { color: '#51a7f9', type: '[]*wtype.LHComponent', example: [] }, // TODO: remove when we stop supporting LHComponent
    { color: '#51a7f9', type: '[][]*wtype.LHComponent', example: [] }, // TODO: remove when we stop supporting LHComponent
    { color: '#51a7f9', type: '*wtype.Liquid', example: 'water' },
    { color: '#51a7f9', type: '[]*wtype.Liquid', example: [] },
    { color: '#51a7f9', type: '[][]*wtype.Liquid', example: [] },
    { color: '#4052a0', type: 'wtype.RestrictionEnzyme', example: {} },
    { color: '#4052a0', type: '[]wtype.RestrictionEnzyme', example: [] },
    { color: '#4052a0', type: '[]enzymes.Restrictionsites', example: [] },
    { color: '#44b72f', type: 'wtype.Absorbance', example: '0.0' },

    // some dna stuff
    {
      color: '#7052a0',
      type: '[]sequences/biogo/ncbi/blast.Hit',
      example: ['cat', 'lion'],
    },
    {
      color: '#7052a0',
      type: 'sequences/biogo/ncbi/blast.Hit',
      example: 'cat',
    },
    { color: '#4052a0', type: 'sequences/oligos.Primer', example: 'AGT' },
    {
      color: '#4052a0',
      type: '[]sequences/oligos.Primer',
      example: ['AGT', 'TTT'],
    },
    {
      color: '#4052a0',
      type: '[]lib.PrimerPair',
      example: [
        ['AGT', 'GGG'],
        ['AAA', 'CCC'],
      ],
    },
    { color: '#4052a0', type: 'Pubchem.Molecule', example: 'carbon' },
    {
      color: '#4052a0',
      type: '[]Pubchem.Molecule',
      example: ['carbon', 'iron'],
    },
    {
      color: '#4052a0',
      type: 'wtype.DNASequence',
      example: { nm: 'exampleSequence', seq: 'AAAAAAAAAAA', plasmid: false },
    },
    { color: '#4052a0', type: '[]wtype.DNASequence', example: [] },
    { color: '#4052a0', type: '[]*wtype.DNASequence', example: [] },
    { color: '#4052a0', type: '[][]wtype.DNASequence', example: [[], []] },

    // physical stuff
    { color: '#71a7f9', type: 'lib.componentList', example: [] },
    { color: '#3da82d', type: 'wunit.Concentration', example: '0.0g/L' },
    { color: '#3da82d', type: '[]wunit.Concentration', example: [] },
    { color: '#3da82d', type: '[][]wunit.Concentration', example: [[], []] },
    { color: '#39a12c', type: 'wunit.Density', example: '0.0kg/m^3' },
    { color: '#369a2b', type: 'wunit.Length', example: '0.0mm' },
    { color: '#369a2b', type: 'wunit.Mass', example: '0.0g' },
    { color: '#369a2b', type: '[]wunit.Mass', example: [] },
    { color: '#33932b', type: 'wunit.Pressure', example: '0.0Pa' },
    { color: '#2f8c2a', type: 'wunit.FlowRate', example: '0.0ml/min' },
    { color: '#2f8c2a', type: 'wunit.Rate', example: '0.0/s' },
    { color: '#b36ae2', type: 'wunit.Temperature', example: '37C' },
    { color: '#b36ae2', type: 'wunit.Time', example: '0.0s' },
    { color: '#257728', type: 'wunit.Velocity', example: '0.0m/s' },
    { color: '#a36af2', type: 'wunit.Moles', example: '0.0M' },
    { color: '#a36af2', type: '[]wunit.Moles', example: [] },
    { color: '#b36ae2', type: 'wunit.Volume', example: '0.0ul' },
    { color: '#b36ae2', type: '[]wunit.Volume', example: [] },
    { color: '#b36ae2', type: '[][]wunit.Volume', example: [[], []] },
  ];
}
