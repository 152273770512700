import React, { useCallback, useMemo, useState } from 'react';

import { NavNode } from 'client/app/components/nav/breadcrumbs/types';
import useBreadcrumbsEntity, {
  BreadcrumbsEntity,
} from 'client/app/components/nav/breadcrumbs/useBreadcrumbsEntity';
import { BreadcrumbsEntityEnum } from 'client/app/gql';

export type CurrentEntityContext<T extends BreadcrumbsEntity = BreadcrumbsEntity> =
  | (T & {
      showInfo?: boolean;
      toggleShowInfo: () => void;
    })
  | null;

export const BreadcrumbsEntityContext = React.createContext<CurrentEntityContext>(null);

export default function CurrentEntityProvider({
  currentEntity = BreadcrumbsEntityEnum.EXPERIMENT,
  children,
}: React.PropsWithChildren<{
  currentEntity?: BreadcrumbsEntityEnum;
}>) {
  const node = { entity: currentEntity } as NavNode;
  const entity = useBreadcrumbsEntity(node);
  const [showInfo, setShowInfo] = useState(false);

  const toggleShowInfo = useCallback(() => setShowInfo(value => !value), []);

  const value = useMemo(
    () => ({ ...entity, showInfo, toggleShowInfo }),
    [entity, showInfo, toggleShowInfo],
  );

  return (
    <BreadcrumbsEntityContext.Provider value={value}>
      {children}
    </BreadcrumbsEntityContext.Provider>
  );
}

export const useCurrentEntity = <T = {},>() =>
  React.useContext(BreadcrumbsEntityContext)! as CurrentEntityContext & T;
