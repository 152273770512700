import { isPortConnectable } from 'client/app/lib/layout/ConnectionHelper';
import { ElementOutput, Liquid, Parameter } from 'common/types/bundle';
import { DataTable } from 'common/types/spreadsheetEditor';

const VisualisableOutput = {
  Liquids: 'Liquids',
  LiquidCount: 'LiquidCount',
  FilterMatrix: 'FilterMatrix',
  DataTable: 'DataTable',
  Number: 'Number',
} as const;

export type VisualisableOutputType = keyof typeof VisualisableOutput;

const supportedTypes = new Map<string, VisualisableOutputType>([
  ['*github.com/Synthace/antha/antha/anthalib/wtype.Liquid', VisualisableOutput.Liquids],
  [
    '[]*github.com/Synthace/antha/antha/anthalib/wtype.Liquid',
    VisualisableOutput.Liquids,
  ],
  [
    'map[github.com/Synthace/antha/stdlib/schemas/aliases.LiquidIdentifier]int',
    VisualisableOutput.LiquidCount,
  ],
  [
    'map[github.com/Synthace/antha/stdlib/schemas/aliases.LiquidIdentifier]float64',
    VisualisableOutput.LiquidCount,
  ],
  [
    'map[github.com/Synthace/antha/stdlib/schemas/aliases.MixtureName]int',
    VisualisableOutput.LiquidCount,
  ],
  [
    'map[github.com/Synthace/antha/stdlib/schemas/aliases.MixtureName]float64',
    VisualisableOutput.LiquidCount,
  ],
  ['map[string]int', VisualisableOutput.LiquidCount],
  ['map[string]float64', VisualisableOutput.LiquidCount],
  ['int', VisualisableOutput.Number],
  ['float64', VisualisableOutput.Number],
  [
    '*github.com/Synthace/antha/antha/anthalib/wtype.FilterMatrix',
    VisualisableOutput.FilterMatrix,
  ],
  [
    '[]*github.com/Synthace/antha/antha/anthalib/wtype.FilterMatrix',
    VisualisableOutput.FilterMatrix,
  ],
  ['*github.com/Synthace/antha/antha/anthalib/data.Table', VisualisableOutput.DataTable],
  [
    '[]*github.com/Synthace/antha/antha/anthalib/data.Table',
    VisualisableOutput.DataTable,
  ],
]);

/**
 * Checks if the given `parameter` is eligible to display a visualisation
 * of its output in the Port tooltip and returns the output type.
 */
export function getOutputVisualisationType(
  parameter: Parameter | undefined,
): VisualisableOutputType | undefined {
  if (!parameter || !isPortConnectable(parameter)) return undefined;
  return getOutputVisualisationTypeFromParameterType(parameter.type);
}

export function getOutputVisualisationTypeFromParameterType(
  parameterType: string,
): VisualisableOutputType | undefined {
  return supportedTypes.get(parameterType);
}

export function isLiquidOutput(
  type: VisualisableOutputType,
  output: ElementOutput,
): output is Liquid[] {
  return type === 'Liquids' || type === 'FilterMatrix';
}

export function isLiquidCountOutput(
  type: VisualisableOutputType,
  output: ElementOutput,
): output is { default: number } {
  return type === 'LiquidCount';
}

export function isDataTableOutput(
  type: VisualisableOutputType,
  output: ElementOutput,
): output is DataTable {
  return type === 'DataTable';
}

export function isNumber(
  type: VisualisableOutputType,
  output: ElementOutput,
): output is number {
  return type === 'Number';
}
