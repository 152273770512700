import React, { useMemo, useState } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Big from 'big.js';

import { calculateMass } from 'client/app/apps/standalone-tools/mass-molarity-calcuator/calculations';
import ChemicalCompoundMass from 'client/app/apps/standalone-tools/mass-molarity-calcuator/ChemicalCompoundMass';
import { validateAndConvertToDecimal } from 'client/app/apps/standalone-tools/utils';
import { getUnit } from 'common/lib/units';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import Toggle, { ToggleButton } from 'common/ui/components/Toggle/Toggle';

const MASS_UNIT_OPTIONS = [getUnit('ug'), getUnit('mg'), getUnit('g')];

const VOLUME_UNIT_OPTIONS = [getUnit('ul'), getUnit('ml'), getUnit('l')];

const CONCENTRATION_UNIT_OPTIONS = [
  getUnit('nMol/l'),
  getUnit('uMol/l'),
  getUnit('mMol/l'),
  getUnit('Mol/l'),
];

export default function MassMolarityCalculator() {
  const [mass, setMass] = useState<Big | null>(null);
  const [molecularWeight, setMolecularWeight] = useState<string>('');
  const [volume, setVolume] = useState<string>('');
  const [volumeUnitCoefficient, setVolumeUnitCoefficient] = useState<number>(1);
  const [concentration, setConcentration] = useState<string>('');
  const [concentrationUnitCoefficient, setConcentrationUnitCoefficient] =
    useState<number>(1);
  const [outputMassUnitCoefficient, setOutputMassUnitCoefficient] = useState(
    MASS_UNIT_OPTIONS[2].coefficient,
  );

  const [isMolecularWeightErrored, setIsMolecularWeightErrored] = useState(false);
  const [isVolumeErrored, setIsVolumeErrored] = useState(false);
  const [isConcentrationErrored, setIsConcentrationErrored] = useState(false);

  function calculate() {
    const molecularWeightDecimal = validateAndConvertToDecimal(molecularWeight);
    const volumeDecimal = validateAndConvertToDecimal(volume);
    const concentrationDecimal = validateAndConvertToDecimal(concentration);

    setIsMolecularWeightErrored(!molecularWeightDecimal);
    setIsVolumeErrored(!volumeDecimal);
    setIsConcentrationErrored(!concentrationDecimal);

    if (molecularWeightDecimal && volumeDecimal && concentrationDecimal) {
      const result = calculateMass(
        volumeDecimal,
        volumeUnitCoefficient,
        concentrationDecimal,
        concentrationUnitCoefficient,
        molecularWeightDecimal,
      );

      setMass(result);
    } else {
      setMass(null);
      return;
    }
  }

  const [mode, setMode] = useState<'formula' | 'weight'>('weight');

  const scaledMass = useMemo(
    () => mass?.div(outputMassUnitCoefficient).toString() ?? '',
    [mass, outputMassUnitCoefficient],
  );

  return (
    <>
      <InputsContainer>
        <Typography>
          The molarity calculator calculates the mass of compound required to achieve a
          specific molar concentration and volume. Alternatively, the chemical formula for
          the compound (which can also include waters of hydration) can be provided and
          will be used to calculate the molecular weight.
        </Typography>
        <Toggle
          value={mode}
          exclusive
          onChange={(_, value) => {
            setMode(value);
            setMolecularWeight('');
            setIsMolecularWeightErrored(false);
          }}
        >
          <ToggleButton
            value="weight"
            data-heap-tracking="standalone-tools-molarity-calculator-mode-toggle-weight"
          >
            Molecular weight
          </ToggleButton>
          <ToggleButton
            value="formula"
            data-heap-tracking="standalone-tools-molarity-calculator-mode-toggle-formula"
          >
            Chemical formula
          </ToggleButton>
        </Toggle>

        <FieldsContainer>
          {mode === 'weight' ? (
            <>
              <Label>Molecular weight</Label>
              <StyledTextField
                placeholder="Weight"
                inputMode="decimal"
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">g&#47;mol</InputAdornment>,
                }}
                value={molecularWeight}
                onChange={e => {
                  setMolecularWeight(e.target.value);
                }}
                error={isMolecularWeightErrored}
                helperText={
                  isMolecularWeightErrored
                    ? 'The molecular weight must be a positive, numeric value'
                    : ' '
                }
              />
            </>
          ) : (
            <ChemicalCompoundMass
              molecularWeight={molecularWeight}
              onChange={val => {
                setMolecularWeight(val);
              }}
            />
          )}
          <Label>Desired final volume</Label>
          <StyledTextField
            placeholder="Volume"
            size="small"
            inputMode="decimal"
            value={volume}
            onChange={e => {
              setVolume(e.target.value);
            }}
            error={isVolumeErrored}
            helperText={
              isVolumeErrored ? 'The volume must be a positive, numeric value' : ' '
            }
          />
          <StyledTextField
            label="Unit"
            size="small"
            select
            value={volumeUnitCoefficient}
            onChange={e => {
              const numericValue = Number(e.target.value);
              if (Number.isNaN(numericValue)) {
                return;
              }
              setVolumeUnitCoefficient(numericValue);
            }}
          >
            {VOLUME_UNIT_OPTIONS.map(option => (
              <MenuItem key={option.label} value={option.coefficient}>
                {option.label}
              </MenuItem>
            ))}
          </StyledTextField>
          <Label>Desired concentration</Label>
          <StyledTextField
            placeholder="Concentration"
            size="small"
            inputMode="decimal"
            value={concentration}
            onChange={e => {
              setConcentration(e.target.value);
            }}
            error={isConcentrationErrored}
            helperText={
              isConcentrationErrored
                ? 'The concentration must be a positive, numeric value'
                : ' '
            }
          />
          <StyledTextField
            label="Unit"
            size="small"
            select
            value={concentrationUnitCoefficient}
            onChange={e => {
              const numericValue = Number(e.target.value);
              if (Number.isNaN(numericValue)) {
                return;
              }
              setConcentrationUnitCoefficient(numericValue);
            }}
          >
            {CONCENTRATION_UNIT_OPTIONS.map(option => (
              <MenuItem key={option.label} value={option.coefficient}>
                {option.label}
              </MenuItem>
            ))}
          </StyledTextField>
        </FieldsContainer>
      </InputsContainer>
      <ResultSection>
        <Button
          variant="primary"
          color="primary"
          onClick={calculate}
          data-heap-tracking="standalone-tools-molarity-calculator-calculate"
        >
          Calculate
        </Button>
        <MassOutput
          value={scaledMass}
          inputMode="decimal"
          size="small"
          placeholder="Mass"
          color="secondary"
          InputProps={{
            readOnly: true,
            sx: {
              background: Colors.WHITE,
            },
          }}
          contentEditable={false}
        />
        <StyledTextField
          label="Unit"
          size="small"
          select
          value={outputMassUnitCoefficient}
          onChange={e => {
            const numericValue = Number(e.target.value);
            if (Number.isNaN(numericValue)) {
              return;
            }
            setOutputMassUnitCoefficient(numericValue);
          }}
          InputProps={{
            sx: {
              background: Colors.WHITE,
            },
          }}
        >
          {MASS_UNIT_OPTIONS.map(option => (
            <MenuItem key={option.label} value={option.coefficient}>
              {option.label}
            </MenuItem>
          ))}
        </StyledTextField>
      </ResultSection>
    </>
  );
}

const InputsContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(6, 6, 4, 6),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
}));

const FieldsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '4fr 1fr',
  gap: theme.spacing(3),
}));

const Label = styled(Typography)({
  gridColumn: 'span 2',
});

const ResultSection = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr minmax(110px, auto)',
  gap: theme.spacing(4),
  padding: theme.spacing(6),
  borderTop: `solid 1px ${Colors.GREY_30}`,
  background: Colors.GREY_10,
}));

const StyledTextField = styled(TextField)({
  '& input': {
    textAlign: 'right',
  },
});

const MassOutput = styled(TextField)(({ theme }) => ({
  input: {
    color: theme.palette.secondary.main,
    textAlign: 'right',
  },
}));
