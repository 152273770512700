import React, { useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useFetchGraphQLElementSet } from 'client/app/api/ElementsApi';
import { useFetchFileStructure } from 'client/app/api/FilesApi';
import { getLiquidHandlingTasks } from 'client/app/apps/simulation-details/dataUtils';
import FileBrowserLink from 'client/app/apps/simulation-details/overview/FileBrowserLink';
import {
  fetchOutputPlateFiles,
  useFetchElementOutputFiles,
  useGetDebuggingFiles,
} from 'client/app/apps/simulation-details/overview/simulationDetailsFiles';
import TreeEntryList, {
  TreeEntry,
} from 'client/app/apps/simulation-details/overview/TreeEntryList';
import { ElementSetQuery, SimulationQuery } from 'client/app/gql';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { parseFiletreeLink } from 'common/types/filetree';
import { Deck } from 'common/types/mix';
import Colors from 'common/ui/Colors';

/** Allows us to configure which sections are shown in this sidebar. */
export type FilesSidebarSection =
  | 'output_plates'
  | 'element_outputs'
  | 'reagents'
  | 'debugging';

type Props = {
  simulation: SimulationQuery['simulation'];
  deck?: Deck;
  /** Allows us to configure which sections are shown in this sidebar. */
  sections: Set<FilesSidebarSection>;
};

export default React.memo(function FilesSidebar({ simulation, deck, sections }: Props) {
  const [outputPlateFiles, setOutputPlateFiles] = useState<readonly TreeEntry[] | null>(
    null,
  );
  const [elementOutputFiles, setElementOutputFiles] = useState<
    readonly TreeEntry[] | null
  >(null);
  const [internalDebuggingFiles, setInternalDebuggingFiles] = useState<TreeEntry[]>([]);
  const [elementSet, setElementSet] = useState<ElementSetQuery['elementSet'] | null>(
    null,
  );

  const fetchElementOutputFiles = useFetchElementOutputFiles();
  const fetchFileStructure = useFetchFileStructure();

  const fetchGraphQLElementSet = useFetchGraphQLElementSet();
  const getDebuggingFiles = useGetDebuggingFiles();
  const userProfile = useUserProfile();

  useEffect(() => {
    let didCancel = false;
    const checkIfValid =
      <T, U>(fn: (val: T) => U) =>
      (value: T) =>
        !didCancel && fn(value);

    const liquidHandlingTasks = getLiquidHandlingTasks(simulation.tasks);

    if (sections.has('output_plates') && liquidHandlingTasks && deck) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchOutputPlateFiles(simulation.id, deck).then(checkIfValid(setOutputPlateFiles));
    }

    if (sections.has('element_outputs')) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchElementOutputFiles(simulation.filetreeLink).then(
        checkIfValid(setElementOutputFiles),
      );
    }

    if (userProfile?.isSynthaceEmployee) {
      fetchGraphQLElementSet(simulation.workflow.workflow.elementSetId)
        .then(elementSet => {
          if (!didCancel) {
            setElementSet(elementSet);
          }
        })
        .catch(() => {
          console.error('Failed to get branch information for this simulation');
        });

      if (sections.has('debugging')) {
        // Check files actually exist to avoid showing a download button in the UI
        // which fails silently with a 404. (T2746)
        fetchFileStructure(simulation.filetreeLink)
          .then(({ resp }) => {
            if (!didCancel) {
              const hasDebuggingFiles = resp.find(
                file => file.name === 'composer_input.tar.gz',
              );
              if (hasDebuggingFiles) {
                const debuggingFiles = getDebuggingFiles(simulation);
                setInternalDebuggingFiles(debuggingFiles);
              }
            }
          })
          .catch(error => {
            console.error(
              'Failed to get internal debugging files for this simulation: ' + error,
            );
          });
      }
    }

    return function cleanup() {
      didCancel = true;
    };
  }, [deck, fetchElementOutputFiles, fetchFileStructure, fetchGraphQLElementSet, getDebuggingFiles, sections, simulation, userProfile?.isSynthaceEmployee]);

  return (
    <Container>
      {!!outputPlateFiles?.length && (
        <Stack gap={3}>
          <Typography variant="h5" color="textPrimary">
            Plate Files
          </Typography>
          <TreeEntryList entries={outputPlateFiles} />
        </Stack>
      )}
      {!!elementOutputFiles?.length && (
        <Stack gap={3}>
          <Typography variant="h5" color="textPrimary">
            Element Outputs
          </Typography>
          <TreeEntryList entries={elementOutputFiles} />
        </Stack>
      )}
      {userProfile?.isSynthaceEmployee && (
        <Stack gap={3}>
          <Typography variant="h5" color="textPrimary">
            Debugging
          </Typography>
          <Typography variant="caption">Visible to Synthace employees only</Typography>
          {elementSet && (
            <BranchName isRelease={elementSet.isRelease}>
              Simulated from branch: {elementSet.name}
            </BranchName>
          )}
          {!!internalDebuggingFiles?.length && (
            <TreeEntryList entries={internalDebuggingFiles} />
          )}
        </Stack>
      )}
      <FileBrowserLink
        simulationFiletreePath={parseFiletreeLink(simulation.filetreeLink).path}
      />
    </Container>
  );
});

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  minWidth: 0,
  backgroundColor: Colors.SIMULATION_DETAILS_BACKGROUND_GRAY,
  flexShrink: 0,
  width: '320px',
  padding: theme.spacing(5, 5, 6),
}));

const BranchName = styled(Typography, {
  shouldForwardProp: propName => propName !== 'isRelease',
})<{ isRelease: boolean }>(({ theme, isRelease }) => ({
  color: isRelease ? 'inherit' : theme.palette.error.dark,
}));
