import React, { useCallback, useMemo } from 'react';

import { useApolloClient, useQuery } from '@apollo/client';
import Autocomplete, {
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { callRecordEntityAccessMutation } from 'client/app/api/gql/mutations';
import {
  QUERY_ELEMENT_SETS,
  QUERY_RECENTLY_ACCESSED_ELEMENT_SETS,
} from 'client/app/api/gql/queries';
import { AccessHistoryEntityType, ArrayElement, ElementSetsQuery } from 'client/app/gql';
import { indexBy, isDefined } from 'common/lib/data';
import LinearProgress from 'common/ui/components/LinearProgress';

type Props = {
  selectedElementSetID: string | null;
  onChange: (elementSetID: string) => void;
};

type GraphQLElementSet = ArrayElement<ElementSetsQuery['latestElementSets']>;

// Autocomplete option type representing single element set
type ElementSetOption = {
  elementSet: GraphQLElementSet;

  // options are grouped into these two groups, with recently access at the top
  group: 'Recently accessed' | 'All';
};

export function ElementSetSelector({ selectedElementSetID, onChange }: Props) {
  const elementSetsQuery = useQuery(QUERY_ELEMENT_SETS);
  const recentlyAccessedSetsQuery = useQuery(QUERY_RECENTLY_ACCESSED_ELEMENT_SETS);

  const apollo = useApolloClient();
  const onElementSetChange = useCallback(
    async (_event: any, option: ElementSetOption | null) => {
      onChange(option!.elementSet.id);

      // log recently accessed element set
      if (option) {
        await callRecordEntityAccessMutation(apollo, {
          entityIdentifier: option.elementSet.id,
          entityType: AccessHistoryEntityType.ELEMENT_SET,
        });
      }
    },
    [apollo, onChange],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField {...params} label="Branch" variant="outlined" />
    ),
    [],
  );

  const filterOptions = useMemo(
    () =>
      createFilterOptions<ElementSetOption>({
        stringify: option => option.elementSet.name,
      }),
    [],
  );

  const groupOptionsBy = useCallback((option: ElementSetOption) => option.group, []);

  const getOptionLabel = useCallback(
    (option: ElementSetOption) => option.elementSet.name,
    [],
  );

  // TODO (T2514): handle errors
  const elementSets = elementSetsQuery.data?.latestElementSets;
  const recentlyAccessedSets =
    recentlyAccessedSetsQuery.data?.loggedInUser?.recentlyAccessed.elementSets;

  const options = useMemo(() => {
    if (!elementSets || !recentlyAccessedSets) {
      return [];
    }
    const setsByID = indexBy(elementSets, 'id');

    const recentOptions: ElementSetOption[] = recentlyAccessedSets
      .map(set => setsByID[set.legacyId])
      .filter(isDefined)
      .map(elementSet => ({
        elementSet,
        group: 'Recently accessed',
      }));

    const allOptions: ElementSetOption[] = elementSets.map(elementSet => ({
      elementSet,
      group: 'All',
    }));

    return [...recentOptions, ...allOptions];
  }, [elementSets, recentlyAccessedSets]);

  const selectedOptionOrDefault = useMemo(
    () =>
      options.find(option => option.elementSet.id === selectedElementSetID) ??
      // null is important - passing undefined means the component would be "uncontrolled",
      // and later passing a value makes it "controlled", and that is discouraged. Component should
      // either always have a defined value (even null), or always undefined. But never jump between
      // defined and undefined
      null,
    [options, selectedElementSetID],
  );

  if (elementSetsQuery.loading || recentlyAccessedSetsQuery.loading) {
    return (
      <>
        <Typography gutterBottom>Loading...</Typography>
        <LinearProgress />
      </>
    );
  }

  return (
    <Autocomplete
      disableClearable
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      groupBy={groupOptionsBy}
      options={options}
      renderInput={renderInput}
      value={selectedOptionOrDefault ?? undefined}
      onChange={onElementSetChange}
    />
  );
}
