import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DeviceIcon = React.memo<SvgIconProps>(function DeviceIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 9H4v10h12V9zM4 7a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V7H4z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 5h12v12a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2h2z"
          fill="currentColor"
        />
        <path
          d="M19.992 15l1.414 1.414-3.992 3.992L16 18.992 19.992 15zM20.309 3.27l1.414 1.414-4.16 4.16-1.415-1.414 4.16-4.16zM6.703 3.477L8.117 4.89 3.95 9.059 2.535 7.645l4.168-4.168zM8 12a1 1 0 11-2 0 1 1 0 012 0zM4 15h12v2H4v-2z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
