import React from 'react';

import { ExecutionListWithURLState } from 'client/app/apps/experiments/ExecutionsList';
import { ExperimentsNavigation } from 'client/app/apps/experiments/ExperimentsNavigation';

export function ExecutionsScreen() {
  return (
    <ExperimentsNavigation title="Executions">
      <ExecutionListWithURLState />
    </ExperimentsNavigation>
  );
}
