import React, { useCallback, useMemo } from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  flattenPlateContents,
  getWellName,
} from 'client/app/components/PlatePrep/helpers';
import { formatWellPosition, roundNumber } from 'common/lib/format';
import { Liquid, Plate, WellContents, WellLocationOnDeckItem } from 'common/types/mix';
import Colors from 'common/ui/Colors';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { isSameLocation } from 'common/ui/components/simulation-details/mix/deckContents';

type Props = {
  plate: Plate;
  // Well location under cursor. Synchronised between the sidebar and the plate view.
  highlightedWellLocation: WellLocationOnDeckItem | null;
  liquidColors: LiquidColors;
  onWellMouseEnter?: (loc: WellLocationOnDeckItem) => void;
  onWellMouseLeave?: () => void;
};

export default function WellsList({
  plate,
  highlightedWellLocation,
  liquidColors,
  onWellMouseEnter,
  onWellMouseLeave,
}: Props) {
  const plateContents = useMemo(() => flattenPlateContents(plate), [plate]);

  return !plateContents || plateContents.length === 0 ? (
    <Stack justifyContent="center" alignItems="center" pt={5}>
      <Typography variant="h6" color="textPrimary">
        No contents to display
      </Typography>
    </Stack>
  ) : (
    <ListContainer>
      {plateContents.map(({ loc, wellContents }) => (
        <WellListItem
          key={formatWellPosition(loc)}
          liquidColors={liquidColors}
          contents={wellContents}
          location={loc}
          isHighlighted={isSameLocation(loc.row, loc.col, highlightedWellLocation)}
          onMouseEnter={onWellMouseEnter}
          onMouseLeave={onWellMouseLeave}
        />
      ))}
    </ListContainer>
  );
}

type WellListItemProps = {
  liquidColors: LiquidColors;
  contents: WellContents;
  location: WellLocationOnDeckItem;
  isHighlighted: boolean;
  onMouseEnter?: (loc: WellLocationOnDeckItem) => void;
  onMouseLeave?: () => void;
};

const WellListItem = React.memo(function WellListItem({
  liquidColors,
  location,
  contents,
  isHighlighted,
  onMouseEnter,
  onMouseLeave,
}: WellListItemProps) {
  const handleMouseEnter = useCallback(
    () => onMouseEnter?.(location),
    [onMouseEnter, location],
  );
  const color = useMemo(
    () => liquidColors.getColorForWell(contents as Liquid),
    [liquidColors, contents],
  );
  return (
    <ListItem
      highlighted={isHighlighted}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <PositionCircle color={color}>{formatWellPosition(location)}</PositionCircle>
      <Name variant="body1">{getWellName(contents)}</Name>
      {contents.total_volume && (
        <>
          <Typography variant="body1" color="textPrimary" fontWeight={500}>
            {roundNumber(contents.total_volume.value)}
          </Typography>
          <Typography variant="body1" color="textPrimary" fontWeight={400}>
            {contents.total_volume.unit}
          </Typography>
        </>
      )}
    </ListItem>
  );
});

const ListContainer = styled('ul')(() => ({
  margin: 0,
  padding: 0,
  listStyle: 'none',
}));

const ListItem = styled('li', {
  shouldForwardProp: prop => prop !== 'highlighted',
})<{ highlighted: boolean }>(({ theme, highlighted }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),

  marginBottom: theme.spacing(2),
  padding: theme.spacing(2, 3),
  // Avoid breaking the row into two pages when printing it
  breakInside: 'avoid',

  background: highlighted ? Colors.GREY_20 : 'unset',
}));

const Name = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  flexGrow: 1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

const PositionCircle = styled('div', { shouldForwardProp: prop => prop !== 'color' })<{
  color: string;
}>(({ theme, color }) => ({
  alignSelf: 'center',

  border: `1px solid ${Colors.GREY_40}`,
  borderRadius: '32px',
  padding: theme.spacing(3),

  color: theme.palette.getContrastText(color),
  backgroundColor: color,
}));
