import { useCallback } from 'react';

import { ApolloError } from '@apollo/client';

import { ConflictRefreshDialog } from 'client/app/components/ConflictRefreshDialog';
import { ErrorCodes } from 'common/types/errorCodes';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import useDialog from 'common/ui/hooks/useDialog';

/**
 * Checks if the error response is of an conflict type, which means the entity has
 * been editied in the meantime and the resolution is for the user to refresh the page.
 * If this is the case, we open a dialog to prompt a refresh.
 *
 * @returns Handler for checking the conflict error, and the dialog JSX component.
 */
export default function useEntityConflictErrorDialog() {
  const [conflictDialog, openConflictDialog] = useDialog(ConflictRefreshDialog);
  const { showError } = useSnackbarManager();

  const handleCheckConflictError = useCallback(
    async (error: any, entityType: string, errorCodeToCheck: ErrorCodes) => {
      if (error instanceof ApolloError) {
        if (error.graphQLErrors[0]) {
          // There is limited space to display errors so only display the first if there are multiple.
          const errorToDisplay = error.graphQLErrors[0];
          if (errorToDisplay.extensions?.code === errorCodeToCheck) {
            // Prompt the user to reload
            await openConflictDialog({ entityType: entityType });
          } else {
            showError(errorToDisplay.message);
          }
        }
        if (error.networkError) {
          showError('No internet connection');
        }
      } else {
        throw error;
      }
    },
    [openConflictDialog, showError],
  );
  return { handleCheckConflictError, conflictDialog };
}
