import React from 'react';

import { useQuery } from '@apollo/client';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import UploadIcon from '@mui/icons-material/Upload';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

import {
  QUERY_DEVICE_LIQUID_CLASSES_COUNT,
  QUERY_GET_MISSING_DEVICE_LIQUID_CLASSES,
} from 'client/app/api/gql/queries';
import { useHandleDownloadLiquidClassCsvTemplate } from 'client/app/components/DeviceLibrary/useHandleDownloadLiquidClassCsvTemplate';
import { useHandleUploadLiquidClassCsvForExistingDevice } from 'client/app/components/DeviceLibrary/useHandleUploadLiquidClassCsv';
import { DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import { pluralize } from 'common/lib/format';
import { TECAN_EVO_ANTHA_CLASS, TECAN_FLUENT_ANTHA_CLASS } from 'common/types/bundle';
import Button from 'common/ui/components/Button';
import Feedback from 'common/ui/components/Feedback';
import LinearProgress from 'common/ui/components/LinearProgress';

type Props = { device: DeviceCommon };

const LiquidClassesTab = ({ device }: Props) => {
  const isTecanLiquidHandler =
    device.model.anthaClass === TECAN_EVO_ANTHA_CLASS ||
    device.model.anthaClass === TECAN_FLUENT_ANTHA_CLASS;
  return (
    <TabContainer>
      {isTecanLiquidHandler ? (
        <TecanLiquidHandlerLiquidClasses device={device} />
      ) : (
        <HamiltonLiquidHandlerLiquidClasses device={device} />
      )}
    </TabContainer>
  );
};

/**
 * The user journey for validating liquid classes differs between the Tecan liquid handlers and the Hamilton liquid handlers
 */
const TecanLiquidHandlerLiquidClasses = ({ device }: Props) => {
  const handleDownloadCSVTemplate = useHandleDownloadLiquidClassCsvTemplate(device);
  const handleUploadCSV = useHandleUploadLiquidClassCsvForExistingDevice(device);

  const { data, loading } = useQuery(QUERY_GET_MISSING_DEVICE_LIQUID_CLASSES, {
    variables: {
      anthaHubGUID: device.anthaHubGUID!,
    },
  });

  if (loading) {
    return <StyledLinearProgress />;
  }

  const noMissingLiquidClasses = data?.devices[0]?.liquidClasses.missing.length;
  const hasMissingLiquidClasses =
    noMissingLiquidClasses !== undefined && noMissingLiquidClasses !== 0;

  const header = hasMissingLiquidClasses
    ? `${pluralize(
        noMissingLiquidClasses,
        'new liquid class has',
        'new liquid classes have',
      )} been detected in this configuration.`
    : 'No new liquid classes have been detected in this configuration. No action is required.';
  const text = hasMissingLiquidClasses
    ? 'Please follow the instructions below if you wish to update the liquid classes for this device. The updated liquid classes will be available to use across all configurations for this device.'
    : 'If any exsting classes have been modified, please follow the instructions below to update the liquid classes for this device. The updated liquid classes will be available to use across all configurations for this device. You can skip this step if there have been no changes.';
  return (
    <>
      <Feedback
        variant="info"
        header={header}
        content={<Typography>{text}</Typography>}
      />
      <HeadingTypography variant="h2">
        To check or update the liquid classes for this device:
      </HeadingTypography>
      <StyledDescriptionList>
        <dt>
          <ListNumber>1</ListNumber>
          <DescriptionTitleTypography variant="h5">
            Download a template file with all available liquid classes detected in this
            configuration
          </DescriptionTitleTypography>
        </dt>
        <dd>
          <Button
            variant="secondary"
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={handleDownloadCSVTemplate}
          >
            Download CSV file
          </Button>
        </dd>
        <dt>
          <ListNumber>2</ListNumber>
          <DescriptionTitleTypography variant="h5">
            Check the CSV file and fill in any missing information
          </DescriptionTitleTypography>
        </dt>
        <dd>
          <Typography>
            Any liquid classes you want to use need to have a{' '}
            <ItalicInlineTypography>Proxy Liquid</ItalicInlineTypography> assigned. If a
            liquid class you want to use has been classified as &lsquo;undefined&rsquo;,
            replace with one of the following options: Aqueous, FastAqueous, Surfactant,
            Glycerol, Serum, Ethanol, DMSO, System, Robo.
          </Typography>
        </dd>
        <dt>
          <ListNumber>3</ListNumber>
          <DescriptionTitleTypography variant="h5">
            Upload the reviewed CSV file to update the liquid classes
          </DescriptionTitleTypography>
        </dt>
        <dd>
          <Button
            component="label"
            variant="secondary"
            color="primary"
            startIcon={<UploadIcon />}
          >
            <input type="file" hidden onChange={handleUploadCSV} />
            Upload CSV file
          </Button>
        </dd>
      </StyledDescriptionList>
    </>
  );
};

const HamiltonLiquidHandlerLiquidClasses = ({ device }: Props) => {
  const handleUploadCSV = useHandleUploadLiquidClassCsvForExistingDevice(device);

  const { data, loading } = useQuery(QUERY_DEVICE_LIQUID_CLASSES_COUNT, {
    variables: {
      anthaHubGUID: device.anthaHubGUID!,
    },
  });

  if (loading) {
    return <StyledLinearProgress />;
  }

  const hasLiquidClasses = (data?.devices[0].liquidClasses.count ?? 0) !== 0;

  const header = hasLiquidClasses
    ? 'This device already has liquid classes defined. No action is required.'
    : 'This device does not have liquid classes defined yet';
  const text = hasLiquidClasses
    ? 'Please follow the instructions below if you wish to update the liquid classes for this device. The updated liquid classes will be available to use across all configurations for this device.'
    : 'Please follow the instructions below to update the liquid clases for this device.';

  return (
    <>
      <Feedback
        variant="info"
        header={header}
        content={<Typography>{text}</Typography>}
      />
      <HeadingTypography variant="h2">
        To update the liquid classes for this device:
      </HeadingTypography>
      <StyledDescriptionList>
        <dt>
          <ListNumber>1</ListNumber>
          <DescriptionTitleTypography variant="h5">
            Prepare the liquid classes CSV file
          </DescriptionTitleTypography>
        </dt>
        <dd>
          <Typography>
            Ask your FAS for a standard liquid classes CSV file to use Synthace liquid
            classes with your device. Alternatively, prepare a CSV to use your custom
            liquid classes by following the instructions detailed{' '}
            <a
              target="_blank"
              href="https://intercom.help/antha/en/articles/5451268-hamilton-microlab-star-or-starlet-liquid-classes-with-synthace"
              rel="noreferrer"
            >
              here <StyledOpenInNewIcon />
            </a>
            .
          </Typography>
        </dd>
        <dt>
          <ListNumber>2</ListNumber>
          <DescriptionTitleTypography variant="h5">
            Upload the CSV file below
          </DescriptionTitleTypography>
        </dt>
        <dd>
          <Button
            component="label"
            variant="secondary"
            color="primary"
            startIcon={<UploadIcon />}
          >
            <input type="file" hidden onChange={handleUploadCSV} />
            Upload CSV file
          </Button>
        </dd>
      </StyledDescriptionList>
    </>
  );
};

const TabContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  overflowY: 'auto',
  marginTop: theme.spacing(6),
  padding: theme.spacing(0, 4),
  width: '100%',
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));

const StyledDescriptionList = styled('dl')(({ theme }) => ({
  margin: theme.spacing(0),
  padding: theme.spacing(0, 6),
  '& dt': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  '& dd': {
    marginLeft: 0,
    marginBottom: theme.spacing(6),
  },
}));

const ListNumber = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.text.primary,
  color: theme.palette.primary.contrastText,
  borderRadius: '50%',
  width: '18px',
  height: '18px',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '1em',
}));

const HeadingTypography = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 6),
}));

const DescriptionTitleTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(3),
}));

const ItalicInlineTypography = styled((props: Omit<TypographyProps, 'component'>) => (
  <Typography component="span" {...props} />
))({
  fontStyle: 'italic',
});

const StyledOpenInNewIcon = styled(OpenInNewIcon)(({ theme }) => ({
  fontSize: theme.typography.fontSize,
  marginLeft: theme.spacing(1),
}));

export default LiquidClassesTab;
