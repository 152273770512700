import {
  GraphQLBoolean,
  GraphQLEnumType,
  GraphQLFloat,
  GraphQLInputObjectType,
  GraphQLInputType,
  GraphQLInt,
  GraphQLList,
  GraphQLNonNull,
  GraphQLNullableType,
  GraphQLObjectType,
  GraphQLScalarType,
  GraphQLString,
} from 'graphql';
import {
  GraphQLFieldConfig,
  GraphQLObjectTypeConfig,
  ThunkObjMap,
} from 'graphql/type/definition';
import { GraphQLDateTime, GraphQLUUID } from 'graphql-custom-types';

import paginatedList from 'common/server/graphql/paginatedList';

// Shorthands for defining GraphQL schemas

export const graphql = {
  /**
   * A list which cannot be null or contain null values
   */
  List: <T extends GraphQLNullableType>(
    type: T,
  ): GraphQLNonNull<GraphQLList<GraphQLNonNull<T>>> =>
    new GraphQLNonNull(new GraphQLList(new GraphQLNonNull(type))),
  paginatedList,
  NotNull: <T extends GraphQLNullableType>(type: T): GraphQLNonNull<T> =>
    new GraphQLNonNull(type),
  NotNullBoolean: new GraphQLNonNull(GraphQLBoolean),
  NotNullFloat: new GraphQLNonNull(GraphQLFloat),
  NotNullInteger: new GraphQLNonNull(GraphQLInt),
  NotNullString: new GraphQLNonNull(GraphQLString),
  NotNullDate: new GraphQLNonNull(GraphQLDateTime),
  EntityID: new GraphQLNonNull(GraphQLUUID),

  /**
   * Creates a new scalar UUID type. In the schema it will be referred as `"entityNameId"`.
   * To use it make sure it's defined in graphqlScalarTypes.d.ts like this:
   * ```
   *   type WorkflowId = OpaqueAlias<string, 'Workflow.Id'>;
   * ```
   */
  NamedEntityId: (entityName: string) =>
    new GraphQLNonNull(
      new GraphQLScalarType({
        ...GraphQLUUID.toConfig(),
        name: `${entityName}Id`,
        description: `String UUID for ${entityName}`,
      }),
    ) ,

  /**
   * Creates a new nullable scalar UUID type. In the schema it will be referred as `"entityNameId"`.
   * To use it make sure it's defined in graphqlScalarTypes.d.ts like this:
   * ```
   *   type WorkflowId = OpaqueAlias<string, 'Workflow.Id'>;
   * ```
   */
  NullableNamedEntityId: (entityName: string) =>
    new GraphQLScalarType({
      ...GraphQLUUID.toConfig(),
      name: `${entityName}Id`,
      description: `String UUID for ${entityName}`,
    }),

  /**
   * Creates a new scalar type of provided base type. In the schema it will be referred as `name`.
   * Use in schema definition:
   * `graphql.NamedScalarType('DeviceDatastoreId', GraphQLString)`
   * and define a corresponding type in graphqlScalarTypes.d.ts
   * `type DeviceDatastoreId = OpaqueAlias<string, 'Device.DatastoreId'>`
   */
  NamedScalarType: (name: string, originalType: GraphQLScalarType) =>
    new GraphQLScalarType({ ...originalType.toConfig(), name }),

  /**
   * Helper to create a GraphQL enum definition based on an ordinary TypeScript enum.
   * This is useful if you have an enum declared in your business logic layer and want
   * to expose that exact same enum via GraphQL.
   *
   * Example usage:
   * ```
   * export enum WorkflowSource {
   *   WORKFLOW_EDITOR = 'WORKFLOW_EDITOR',
   *   CHERRY_PICKER = 'CHERRY_PICKER',
   * }
   *
   * ...
   * In GraphQL schema definition:
   * type: graphql.Enum('WorkflowSourceEnum', WorkflowSource)
   */
  Enum: (
    graphqlTypeName: string,
    enumType: { [_: string]: string },
    description?: string,
  ) =>
    new GraphQLEnumType({
      name: graphqlTypeName,
      description: description,
      values: Object.fromEntries(
        // matching by value is required for resolution of enum variables on the query side.
        Object.values(enumType).map(enumValue => [enumValue, { value: enumValue }]),
      ),
    }),

  /**
   * Shortcut helper to define an input of one field. Very often we need to define
   * an input that contains one field (like id). This helps with that.
   */
  SingleFieldInput: <T extends GraphQLInputType>(
    type: T,
    name: string,
    fieldName: string = 'id',
  ) =>
    graphql.NotNull(
      new GraphQLInputObjectType({
        name,
        fields: { [fieldName]: { type } },
      }),
    ),

  /**
   * Like GraphQLObjectType from 'graphql' but allows resolver functions where argument has
   * an explicitly defined type.
   * This is a workaround for:
   * - https://github.com/graphql/graphql-js/issues/2152
   * - https://github.com/graphql/graphql-js/issues/2829
   */
  GraphQLObjectType<TSource = any, TContext = any>(
    config: Readonly<SynthaceGraphQLObjectTypeConfig<TSource, TContext>>,
  ): GraphQLObjectType<TSource, TContext> {
    // Maybe in the future we'll change how we define GraphQL schema and e.g. stop using typed
    // args in resolvers but the main goal behind adding this workaround was to upgrade graphql
    // library without major code changes.
    return new GraphQLObjectType(config);
  },
};

/**
 * This is a workaround for:
 * - https://github.com/graphql/graphql-js/issues/2152
 * - https://github.com/graphql/graphql-js/issues/2829
 */
export type SynthaceGraphQLFieldConfigMap<TSource, TContext> = {
  [key: string]: GraphQLFieldConfig<TSource, TContext, any>; // the key part of the workaround - setting TArg to any
};

/**
 * This is a workaround for:
 * - https://github.com/graphql/graphql-js/issues/2152
 * - https://github.com/graphql/graphql-js/issues/2829
 */
export type SynthaceGraphQLObjectTypeConfig<TSource, TContext> = Omit<
  GraphQLObjectTypeConfig<TSource, TContext>,
  'fields'
> & {
  fields: ThunkObjMap<GraphQLFieldConfig<TSource, TContext, any>>;
};
