import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const TemplateFileIcon = React.memo<SvgIconProps>(function TemplateFileIcon(
  props,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2H14L20 8V20C20 21.1 19.1 22 18 22H5.99C4.89 22 4 21.1 4 20V4C4 2.9 4.9 2 6 2ZM6 20H18V9H13V4H6V20Z"
          fill="currentColor"
        />
        <path d="M9 11H15V13H9V11Z" fill="currentColor" />
        <path d="M13 13L13 18L11 18L11 13L13 13Z" fill="currentColor" />
      </svg>
    </SvgIcon>
  );
});
