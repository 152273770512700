import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DraggableSyntheticListeners,
  DragOverlay,
  DragStartEvent,
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIcon from '@mui/icons-material/DragIndicator';
import cx from 'classnames';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props<TItem> = {
  /** Data to be rendered. */
  items: TItem[];
  /** Defines how to render single item. */
  renderItem: (
    item: TItem,
    dragHandleProps: DragHandleProps,
    index: number,
  ) => React.ReactNode;
  /** Returns a unique ID that can be used to identify an item in the items prop. */
  getIdFromItem: (item: TItem) => string;
  /** Callback invoked when item is dropped. */
  onChangeOrder: (reorderedItems: TItem[], movedItem: TItem) => void;
};

/**
 * Taken from the dnd-kit type defintions.
 * These should be spread into the drag handle element as so:
 * <DragHandle ...otherProps {...dragHandle.attributes} {...dragHandle.listeners} />
 * */
export type DragHandleProps = {
  attributes?: {
    role: string;
    tabIndex: number;
    'aria-pressed': boolean | undefined;
    'aria-roledescription': string;
    'aria-describedby': string;
  };
  listeners?: DraggableSyntheticListeners;
  dragIcon: ReactNode;
  isDragOverlay?: boolean;
};

/**
 * A generic component for handling the common case of reordering vertically
 * sorted lists.  It's not hard to do this in dnd-kit, but there's some shared
 * code and this was originally made as part of when we used a different, less
 * simple library.
 *
 * To use it, you pass in a number of props, including a render prop which can
 * take in drag handle props (wherever these are spread will be the part where
 * you can start the drag) and an function that will return a unique string ID
 * when given an item in the list. This is how the order is tracked.
 */
export function DraggableList<TItem>({
  items,
  renderItem,
  getIdFromItem,
  onChangeOrder,
}: Props<TItem>) {
  const classes = useStyles();

  const [activeId, setActiveId] = useState<string | null>(null);

  const itemIds = useMemo(
    () => items.map(item => getIdFromItem(item)),
    [getIdFromItem, items],
  );
  const itemsById: Record<string, TItem> = useMemo(
    () => Object.fromEntries(items.map(item => [getIdFromItem(item), item])),
    [getIdFromItem, items],
  );

  const handleDragStart = useCallback(
    (event: DragStartEvent) => setActiveId(String(event.active.id)),
    [],
  );

  const handleDragEnd = useCallback(
    (result: DragEndEvent) => {
      setActiveId(null);

      if (!result.active || !result.over) {
        return;
      }

      const originalIndex = itemIds.indexOf(String(result.active.id));
      const newIndex = itemIds.indexOf(String(result.over.id));

      const updatedItemIds = [...itemIds];
      const [itemToMove] = updatedItemIds.splice(originalIndex, 1);
      updatedItemIds.splice(newIndex, 0, itemToMove);

      const reorderedItems = updatedItemIds.map(id => itemsById[id]);

      onChangeOrder(reorderedItems, itemsById[result.active.id]);
    },
    [itemIds, itemsById, onChangeOrder],
  );

  const activeItem = activeId
    ? items.find(item => getIdFromItem(item) === activeId)
    : null;

  return (
    <div>
      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToParentElement]}
        collisionDetection={closestCenter}
      >
        <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
          {items.map((item, index) => (
            <DraggableItem
              item={item}
              key={itemIds[index]}
              id={itemIds[index]}
              renderItem={renderItem}
            />
          ))}
          {/**
           * DragOverlay contains the item that is currently being dragged. Using
           * DragOverlay instead of just applying transform the existing item allows
           * dragging down scrollable lists.
           */}
          <DragOverlay className={classes.dragOverlay}>
            {activeItem &&
              renderItem(
                activeItem,
                {
                  dragIcon: <DragIcon className={classes.dragIcon} />,
                  isDragOverlay: true,
                },
                0,
              )}
          </DragOverlay>
        </SortableContext>
      </DndContext>
    </div>
  );
}

function DraggableItem<TItem>({
  item,
  id,
  renderItem,
}: {
  item: TItem;
  id: string;
  renderItem: (
    item: TItem,
    dragHandleProps: DragHandleProps,
    index: number,
  ) => React.ReactNode;
}) {
  const classes = useStyles();

  const { attributes, listeners, setNodeRef, transform, transition, isDragging, index } =
    useSortable({
      id,
    });

  const dragIcon = (
    <DragIcon className={classes.dragIcon} {...attributes} {...listeners} />
  );

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        transition: transition ?? undefined,
      }}
      className={cx({ [classes.draggingItem]: isDragging })}
    >
      {renderItem(item, { dragIcon, attributes, listeners }, index)}
    </div>
  );
}

const useStyles = makeStylesHook({
  dragIcon: {
    // default is inline-block which causes a space below it (due to line height)
    display: 'block',
    cursor: 'grab',
  },
  dragOverlay: {
    cursor: 'grabbing',
  },
  draggingItem: {
    // The currently dragged item is rendered twice: once in the list and once in
    // DragOverlay. The one in the list should be invisible so it creates a gap of the
    // correct size where the item will be dropped.
    opacity: 0,
  },
});
