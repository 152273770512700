import React, { useCallback, useMemo } from 'react';

import cx from 'classnames';

import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import CarrierSelect from 'client/app/components/Parameters/PlateType/CarrierSelect';
import ExistingPlateSelect from 'client/app/components/Parameters/PlateType/ExistingPlateSelect';
import PlateTypeEditor from 'client/app/components/Parameters/PlateType/PlateTypeEditor';
import {
  getPlateParameterDisplayValueAsString,
  getPlateParameterValueAsString,
  getValueWithNewCarrier,
  PlateParameterValue,
} from 'client/app/components/Parameters/PlateType/processPlateParameterValue';
import { splitFullPlateNameWithDefault } from 'client/app/components/Parameters/PlateType/splitFullPlateName';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { PlateType } from 'common/types/plateType';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type PlatesByType = { [type: string]: PlateType };

type PlateAndCarrierProps = {
  isDisabled?: boolean;
  value: PlateParameterValue;
  onChange: (value?: PlateParameterValue) => void;
  platesByType: PlatesByType;
  fullWidth?: boolean;
  isWorkflowSettingsPanel?: boolean;
};

type PlateSelection = string | null | undefined | typeof CANCEL_CHOICE;

export function PlateAndCarrier({
  isDisabled,
  onChange,
  value,
  platesByType,
  fullWidth,
  isWorkflowSettingsPanel,
}: PlateAndCarrierProps) {
  const classes = useStyles();

  // Only Gilson devices can have plates on a riser.
  const canUseRisers = useWorkflowBuilderSelector(
    state => !!state.config.configuredDevices?.some(cd => cd.type === 'GilsonPipetMax'),
  );

  // We keep plate and carrier separate as each of them has its own dropdown
  const [plateType, carrier] = useMemo((): string[] => {
    const plateAndCarrier = getPlateParameterValueAsString(value);
    return splitFullPlateNameWithDefault(plateAndCarrier);
  }, [value]);

  const onCarrierChange = useCallback(
    (carrier: string) => {
      const newValue = getValueWithNewCarrier(value, plateType, carrier);
      onChange(newValue);
    },
    [onChange, plateType, value],
  );

  const onPlateChange = useCallback(
    (plate: PlateSelection) => {
      if (plate === CANCEL_CHOICE) {
        return;
      }

      if (typeof plate === 'string') {
        onChange(plate + carrier);
      } else {
        // Users selected "Select none" or pressed the Clear icon
        onChange();
      }
    },
    [carrier, onChange],
  );

  const onExistingPlateChange = useCallback(
    (plate?: PlateParameterValue) => {
      if (typeof plate === 'object') {
        onChange(plate);
      } else {
        // Users selected "Select none" or pressed the Clear icon
        onChange();
      }
    },
    [onChange],
  );

  // The value for plate types are strings and the value for existing plates
  // are objects.
  const isPlateType = typeof value === 'string';
  const displayValue = getPlateParameterDisplayValueAsString(
    value,
    platesByType[plateType]?.name || plateType,
  );

  return (
    <>
      <div className={cx({ [classes.fullWidth]: fullWidth })}>
        {isPlateType ? (
          <PlateTypeEditor
            // If users select an existing plate, we display its name e.g. "My Aliquot Plate"
            // rather than the human readable plate type
            displayValue={displayValue}
            selectedPlateType={plateType}
            platesByType={platesByType}
            onChange={onPlateChange}
            isDisabled={isDisabled}
            isWorkflowSettingsPanel={isWorkflowSettingsPanel}
          />
        ) : (
          <ExistingPlateSelect
            displayValue={displayValue}
            value={value}
            onChange={onExistingPlateChange}
            isDisabled={isDisabled}
          />
        )}
      </div>
      {(canUseRisers || !!carrier) && (
        <div className={cx(classes.select, { [classes.fullWidth]: fullWidth })}>
          <CarrierSelect
            value={carrier}
            onChange={onCarrierChange}
            isDisabled={isDisabled}
          />
        </div>
      )}
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  fullWidth: { width: '100%' },
  select: { marginTop: theme.spacing(3) },
}));
