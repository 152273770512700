import React from 'react';

import { styled } from '@mui/material/styles';

import FilesSidebar, {
  FilesSidebarSection,
} from 'client/app/apps/simulation-details/overview/LeftSidebar';
import { SimulationQuery } from 'client/app/gql';
import {
  formatSimulationError,
  formatSimulationErrorAsString,
} from 'client/app/lib/workflow/format';
import useElementConfigs from 'client/app/lib/workflow/useElementConfigs';
import ErrorListBox, { ErrorBox } from 'common/ui/components/ErrorListBox';
import LinearProgress from 'common/ui/components/LinearProgress';
import SimulationError from 'common/ui/components/simulation-details/SimulationError';

type Props = {
  simulation: SimulationQuery['simulation'];
  heading?: string;
  messageList?: readonly string[];
};

/** Show the debugging files (composer_input.tar.gz). Only for employees. */
const ERROR_FILES_SECTIONS = new Set<FilesSidebarSection>(['debugging']);

export default function SimulationErrorScreen({
  heading,
  messageList,
  simulation,
}: Props) {
  return (
    <>
      {simulation.internalSimulationInputPath && (
        <FilesSidebar simulation={simulation} sections={ERROR_FILES_SECTIONS} />
      )}
      <SimulationErrors
        heading={heading}
        messageList={messageList}
        simulation={simulation}
      />
    </>
  );
}

export function SimulationErrors({ heading, messageList, simulation }: Props) {
  const { isLoading: areConfigsLoading, elementConfigs } = useElementConfigs(
    simulation.workflow.workflow.elementSetId,
  );

  if (areConfigsLoading) {
    return <StyledLinearProgress />;
  } else {
    return (
      <FailedSimulationErrors>
        {messageList ? (
          <ErrorListBox
            heading={heading ?? 'The simulation failed with the following errors:'}
            errors={
              messageList ??
              simulation.errors.map(e => formatSimulationErrorAsString(e, elementConfigs))
            }
          />
        ) : (
          <ErrorBox
            heading={heading ?? 'The simulation failed with the following errors:'}
          >
            <SimulationError
              errors={simulation.errors.map(e =>
                formatSimulationError(e, elementConfigs),
              )}
              triggeredAt={new Date(simulation.startedAt)}
            />
          </ErrorBox>
        )}
      </FailedSimulationErrors>
    );
  }
}

const FailedSimulationErrors = styled('div')({
  margin: '32px',
});

const StyledLinearProgress = styled(LinearProgress)({
  loading: {
    width: '100%',
  },
});
