import { useCallback, useEffect, useMemo, useRef } from 'react';

import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  QueryParamConfig,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params';

import { switchFail } from 'common/assert';

type AllowedTypeNames = 'string' | 'boolean' | 'number' | 'string[]';

/**
 * Converts a string to a type. E.g. "string" to type `string`,
 * "boolean" to type `boolean` etc.
 */
type GetTypeFromTypeName<TypeName extends AllowedTypeNames> = TypeName extends 'string'
  ? string
  : TypeName extends 'boolean'
  ? boolean
  : TypeName extends 'number'
  ? number
  : TypeName extends 'string[]'
  ? string[]
  : never;

type Options<TypeName extends AllowedTypeNames> = {
  /**
   * Name that will appear in the URL.
   */
  paramName: string;
  /**
   * Expected type for this param. E.g. "string"
   */
  paramType: TypeName;
  /**
   * Initial value if no value is specified in the URL.
   */
  defaultValue?: GetTypeFromTypeName<TypeName>;
  /**
   * How undefined should be serialised in the URL. By default the param will not be
   * present in the URL if the value is undefined.
   */
  emptyValue?: GetTypeFromTypeName<TypeName>;
};

/**
 * This hook will store state in the URL.
 * This is useful when we want to preserve filters as part of the history,
 * so when navigating away and back, the filters are preserved.
 * This way, people can also share URLs which link to the same filtered results.
 *
 * @example const [searchQuery, setSearchQuery] = useStateWithURLParams('query', 'string', '');
 */
export function useStateWithURLParams<TypeName extends AllowedTypeNames>({
  paramName,
  paramType,
  defaultValue,
  emptyValue,
}: Options<TypeName>): [
  GetTypeFromTypeName<TypeName> | undefined,
  (newValue: GetTypeFromTypeName<TypeName> | undefined) => void,
] {
  const paramConfig = useMemo(
    () =>
      paramType === 'string[]'
        ? // Library quirk: Array must be initialised this way.
          withDefault(getParamConfig(paramType), [])
        : getParamConfig(paramType),
    [paramType],
  );
  const [value, setValue] = useQueryParam<GetTypeFromTypeName<TypeName> | undefined>(
    paramName,
    paramConfig,
  );

  // When setting the `value` intentionally to `undefined`,
  // we should not restore the default value to the URL.
  const shouldRestoreDefaultValue = useRef(true);

  const setValueToURL = useCallback(
    (newValue: GetTypeFromTypeName<TypeName> | undefined) => {
      const shouldRemoveParamFromURL = !newValue;
      if (shouldRemoveParamFromURL) {
        // Library quirk: the only way to remove a param from
        // the URL is for it to have value `undefined`.
        setValue(undefined ?? emptyValue, 'replaceIn');
        shouldRestoreDefaultValue.current = false;
      } else {
        setValue(newValue, 'replaceIn');
      }
    },
    [emptyValue, setValue],
  );

  // If defaultValue changes asynchronously, update the state too.
  useEffect(() => {
    const hasExistingValue = !!value;
    if (!hasExistingValue && shouldRestoreDefaultValue.current) {
      setValueToURL(defaultValue);
      shouldRestoreDefaultValue.current = true;
    }
  }, [defaultValue, setValue, setValueToURL, value]);

  const valueFixed = value === emptyValue ? undefined : value;

  return [valueFixed, setValueToURL];
}

function getParamConfig(typeName: AllowedTypeNames): QueryParamConfig<any> {
  switch (typeName) {
    case 'number':
      return NumberParam;

    case 'string':
      return StringParam;

    case 'boolean':
      return BooleanParam;

    case 'string[]':
      return ArrayParam;

    default:
      return switchFail(typeName);
  }
}
