import { Location, LocationDescriptor } from 'history';
import { generatePath } from 'react-router';

export type NavItem = Readonly<{
  screenID: ScreenID;
  label: string;
  icon: AnthaIcons;
  showOnlyIfActivated?: boolean;
}>;

// TODO very soon use | for union of explicit possible ScreenID
export type ScreenID = string | null;

/**
 * Represents single route.  Please use method route(...) below to create instances
 * of NavigationRoute.
 */
export type NavigationRoute<TRouteParam> = {
  /**
   * Template path like '/workflow'.
   *
   * Can contain parameters like '/workflow/:workflowId'.
   *
   * Can contain optional parameters like '/workflow/:workflowId/:subscreen?'
   */
  readonly pathTemplate: string;

  /**
   * Returns path, which is the URL *without* the prefix "/#". Use this with <Link> component
   * or <NavLink> from react-router, or with history.push(path) calls.
   *
   * Do not use this with <Link> component from material-ui, it will not work.
   */
  getPath: (routeParam: TRouteParam) => string;
};

/**
 * Type extracted for generatePath call, it's not declared & published in react-router
 * library.
 */
type URLParams =
  | { [paramName: string]: string | number | boolean | undefined }
  | undefined;

/**
 * Creates NavigationRoute<void> without parameters. This overload is here only because void
 * is not compatible with undefined and URLParams cannot include `| void`, see
 * https://github.com/Microsoft/TypeScript/issues/939
 */
export function route<T extends void | URLParams = void>(
  pathTemplate: string,
): NavigationRoute<T>;

/**
 * Creates NavigationRoute<T> that safely generates URLs for navigation.
 */
export function route<TRouteParam extends URLParams>(
  pathTemplate: string,
): NavigationRoute<TRouteParam> {
  return {
    pathTemplate,
    getPath: (routeParam: TRouteParam) => generatePath(pathTemplate, routeParam),
  };
}

export type AnthaIcons =
  | 'antha:cherry-picker'
  | 'antha:device'
  | 'antha:file-browser'
  | 'antha:file-manager'
  | 'antha:template-workflow'
  | 'antha:plate'
  | 'antha:policy'
  | 'antha:settings'
  | 'antha:execution'
  | 'antha:workflow'
  | 'icons:info-outline';

/**
 * Allows preserving URLSearchParams when navigating to a new location.
 * @example <Link to={navigateToAndKeepURLSearchParams(experimentsRoutes.base.getPath())} />
 */
export function navigateToAndKeepURLSearchParams(pathname: string) {
  return (location: Location<unknown>): LocationDescriptor<unknown> => ({
    pathname,
    search: location.search,
  });
}
