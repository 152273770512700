import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { VisibilityIcon } from 'client/app/components/ElementPlumber/ElementOutputs/components/styles';

type Props = {
  value: number;
};

export default function NumberOutput({ value }: Props) {
  return (
    <Stack direction="row" gap={3} paddingX={3} alignItems="center">
      <VisibilityIcon />
      <Typography variant="caption" fontWeight={500}>
        {Number.isInteger(value) ? value : value.toFixed(3)}
      </Typography>
    </Stack>
  );
}
