/**
 * Helper to format and change browser tabs' titles.
 *
 * @param newTabTitle What to change the browser tab title to.
 * @param prefix (Optional) If present, will prepend itself to the `newTabTitle`.
 * e.g. setBrowserTabTitle('Drafts', 'Experiments); // "Experiments | Drafts"
 */
export default function setBrowserTabTitle(newTabTitle?: string, prefix?: string) {
  const tabPrefixOrNothing = `${!prefix ? '' : `${prefix} | `}`;
  const fullTabTitle = `${tabPrefixOrNothing}${newTabTitle}`;
  document.title = fullTabTitle;
}
