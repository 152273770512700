import { useCallback, useRef } from 'react';

import useResizeObserver from 'use-resize-observer';

import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import useDebounce from 'common/ui/hooks/useDebounce';

/**
 * This hook is used by `ControlOverlay` to measure the visible portion of the canvas,
 * i.e. that part which is not covered up by the toolbar, active panel or instance panel.
 * This area is then stored in the state, so it can be used by the `Workspace` component
 * to properly size the workflow to fit the canvas.
 */
export function useMeasureVisibleCanvasArea() {
  const measurerRef = useRef<HTMLDivElement>(null);
  const dispatch = useWorkflowBuilderDispatch();

  /**
   * This callback initiates a resize of an element within the ResizeObserver resize loop.
   * This leads to undelivered notifications error in ResizeObserver.
   *
   * To prevent this error we debounce the callback with a 0 sec timeout making sure
   * it is run after all microtasks scheduled by the ResizeObserver.
   * https://github.com/juggle/resize-observer/blob/v3/src/utils/queueMicroTask.ts
   *
   * For more reference please visit:
   * https://github.com/juggle/resize-observer#resize-loop-detection
   */
  const measureSize = useDebounce(
    useCallback(() => {
      const measurer = measurerRef.current;

      if (measurer) {
        dispatch({
          type: 'setVisibleCanvasArea',
          // The resize observer callback only provides the new width and height,
          // but we need the top and left offset values as well.
          payload: {
            top: measurer.offsetTop,
            left: measurer.offsetLeft,
            width: measurer.offsetWidth,
            height: measurer.offsetHeight,
          },
        });
      }
    }, [dispatch]),
  );

  useResizeObserver({
    ref: measurerRef,
    onResize: measureSize,
  });

  return measurerRef;
}
