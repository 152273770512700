import React, { createContext, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import noop from 'lodash/noop';

import { ExperimentBody } from 'client/app/apps/experiments/ExperimentBody';
import { ExperimentReadonlyBar } from 'client/app/apps/experiments/ExperimentReadonlyBar';
import { ExperimentSidebar } from 'client/app/apps/experiments/ExperimentSidebar';
import { QUERY_EXPERIMENT } from 'client/app/apps/experiments/gql/queries';
import { ContentType, TitleBlock } from 'client/app/gql';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import Colors from 'common/ui/Colors';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import LinearProgress from 'common/ui/components/LinearProgress';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/**
 * The drag handle of each block resides in the left gutter and the delete button in the
 * right gutter.
 * */
export const BLOCK_GUTTER_WIDTH = 48;
const MAX_BLOCK_WIDTH = 912;
const MAX_TOTAL_BLOCK_WIDTH = MAX_BLOCK_WIDTH + BLOCK_GUTTER_WIDTH * 2;

type Props = {
  experimentID: ExperimentId;
};

export const ExperimentDetailContext = createContext<{
  /** Clicking link in sidebar will cause the selected block to scroll into view */
  blockToScrollToId?: ExperimentModuleId | undefined;
  setBlockToScrollToId: (blockId: ExperimentModuleId | undefined) => void;
  isEditing?: boolean;
  setIsEditing: (isEditing: boolean) => void;
}>({ setIsEditing: noop, setBlockToScrollToId: noop });

export default function ExperimentDetailScreen({ experimentID }: Props) {
  const classes = useStyles();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [blockToScrollToId, setBlockToScrollToId] = useState<
    ExperimentModuleId | undefined
  >();

  const { data, loading, error } = useQuery(QUERY_EXPERIMENT, {
    variables: { id: experimentID },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const experiment = data?.experiment;

  const currentUser = useUserProfile();

  // When adding a new experiment, we want to start in edit mode to give an obvious way to
  // interact with it.
  useEffect(() => {
    if (
      experiment &&
      currentUser &&
      experiment.contentSource === ContentType.USER_GENERATED &&
      experiment.blocks.length === 0 &&
      experiment.createdBy.id === currentUser.id
    ) {
      setIsEditing(true);
    }
  }, [currentUser, data, experiment]);

  if (error) {
    return <GraphQLErrorPanel error={error} />;
  }

  if (loading || !experiment) {
    return <LinearProgress />;
  }

  const titleBlocks = experiment.blocks.filter(
    (block): block is TitleBlock =>
      block.__typename === 'TitleBlock' && block.title?.trim() !== '',
  );

  const isExampleExperiment = experiment.contentSource === ContentType.EXAMPLE;
  return (
    <ExperimentDetailContext.Provider
      value={{ blockToScrollToId, setBlockToScrollToId, isEditing, setIsEditing }}
    >
      {isExampleExperiment && <ExperimentReadonlyBar />}
      <div className={classes.screen}>
        {titleBlocks.length > 0 && (
          <ExperimentSidebar
            titleBlocks={titleBlocks}
            className={classes.sidebar}
            onTitleClick={setBlockToScrollToId}
          />
        )}
        <ExperimentBody experiment={experiment} className={classes.mainColumn} />
      </div>
    </ExperimentDetailContext.Provider>
  );
}

const useStyles = makeStylesHook(theme => ({
  screen: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    overflow: 'auto',
    // Center content horizontally
    justifyContent: 'center',
    backgroundColor: Colors.GREY_5,
    alignItems: 'flex-start',
    // Needed for dnd-kit
    position: 'relative',
  },
  sidebar: {
    flex: '0 0 272px',
    position: 'sticky',
    top: 0,
    overflowY: 'auto',
    height: '100%',
  },
  mainColumn: {
    flex: `0 1 ${MAX_TOTAL_BLOCK_WIDTH}px`,
  },
  bar: {
    alignItems: 'center',
    padding: theme.spacing(3),
    background: Colors.INFO_LIGHT,
    borderBottom: `1px ${Colors.GREY_30} solid`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
}));
