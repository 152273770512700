import { ApolloClient } from '@apollo/client';
import { useApolloClient } from '@apollo/client';

import { getResultOrThrow } from 'client/app/api/apolloClient';
import {
  QUERY_DEFAULT_ELEMENT_SET,
  QUERY_ELEMENT_SET_WITH_ELEMENTS,
} from 'client/app/api/gql/queries';
import { usePartialCallback } from 'common/ui/hooks/usePartialCallback';

async function fetchGraphQLElementSet(
  apolloClient: ApolloClient<object>,
  elementSetID: string,
) {
  const result = await apolloClient.query({
    query: QUERY_ELEMENT_SET_WITH_ELEMENTS,
    variables: { id: elementSetID },
  });
  return getResultOrThrow(result, 'Fetch element set', data => data.elementSet);
}

export function useFetchGraphQLElementSet() {
  const apolloClient = useApolloClient();
  return usePartialCallback(apolloClient, fetchGraphQLElementSet);
}

async function fetchGraphQLDefaultElementSet(apollo: ApolloClient<object>) {
  const result = await apollo.query({
    query: QUERY_DEFAULT_ELEMENT_SET,
  });
  return getResultOrThrow(result, 'Fetch element set', data => data.defaultElementSet);
}

export function useFetchGraphQLDefaultElementSet() {
  const apollo = useApolloClient();
  return usePartialCallback(apollo, fetchGraphQLDefaultElementSet);
}
