import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Position2d } from 'common/types/Position';

export type ContextMenuOption = {
  label: string;
  action: () => void;
};
/**
 * @summary Custom hook to render a context menu where users click.
 * @param menuOptions Options to show in the context menu. Each option
 * should be associated with an action.
 *
 * @example
 * const contextMenuOptions: ContextMenuOption[] = [
 *  {
 *    label: 'Option A',
 *    action: () => {
 *      console.log('Selected Option A');
 *    },
 *  },
 * ];
 *
 * const [contextMenu, openContextMenu] = useContextMenu(contextMenuOptions);
 * return <div onContextMenu={openContextMenu}>{children}{contextMenu}</div>
 */
export default function useContextMenu(
  defaultMenuOptions: ContextMenuOption[],
): [
  ReactElement,
  (event: React.MouseEvent<HTMLDivElement>) => void,
  (newMenuOptions: ContextMenuOption[]) => void,
] {
  const [menuOptions, setMenuOptions] = useState(defaultMenuOptions);
  const [menuPosition, setMenuPosition] = useState<Position2d | null>(null);

  const openContextMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (menuOptions.length < 1) {
        return;
      }
      event.preventDefault();
      setMenuPosition({
        // Moving the position slightly to mimic native
        // context menu.
        x: event.clientX - 2,
        y: event.clientY - 4,
      });
    },
    [menuOptions.length],
  );

  const handleClose = useCallback(() => {
    setMenuPosition(null);
    // Reset options after the closing animation ends.
    setTimeout(() => setMenuOptions(defaultMenuOptions), 200);
  }, [defaultMenuOptions]);

  const handleAction = useCallback(
    (action: ContextMenuOption['action']) => {
      action();
      handleClose();
    },
    [handleClose],
  );

  // Used to dynamically update context menu options.
  const updateMenuOptions = useCallback((newMenuOptions: ContextMenuOption[]) => {
    setMenuOptions(newMenuOptions);
  }, []);

  useEffect(() => {
    updateMenuOptions(defaultMenuOptions);
  }, [defaultMenuOptions, updateMenuOptions]);

  const contextMenu = useMemo(
    () => (
      <Menu
        keepMounted
        PaperProps={{ style: { borderRadius: '4px' } }}
        open={!!menuPosition}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          menuPosition ? { top: menuPosition.y, left: menuPosition.x } : undefined
        }
      >
        {menuOptions.map(({ action, label }) => (
          <MenuItem key={label} onClick={handleAction.bind(null, action)}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    ),
    [handleAction, handleClose, menuOptions, menuPosition],
  );

  return [contextMenu, openContextMenu, updateMenuOptions];
}
