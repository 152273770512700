import React from 'react';

import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';

import Button, { Props } from 'common/ui/components/Button';
import { AddSelectedWellsIcon } from 'common/ui/icons/AddSelectedWellsIcon';
import { RemoveSelectedWellsIcon } from 'common/ui/icons/RemoveSelectedWellsIcon';

type CollapsibleEditWellsButtonProps = Omit<Props, 'startIcon' | 'variant'> & {
  visible: boolean;
  mode: 'add' | 'clear' | 'remove';
};

export default function CollapsibleEditWellsButton(
  props: CollapsibleEditWellsButtonProps,
) {
  const { mode, visible, ...buttonProps } = props;
  const getButtonLabel = () => {
    switch (mode) {
      case 'add':
        return 'Add selected wells';
      case 'clear':
        return 'Clear selected wells';
      case 'remove':
        return 'Remove selected wells';
    }
  };
  return (
    <CollapseContainer in={visible}>
      <Button
        fullWidth
        variant="secondary"
        size="small"
        startIcon={
          mode === 'add' ? <AddSelectedWellsIcon /> : <RemoveSelectedWellsIcon />
        }
        {...buttonProps}
      >
        {getButtonLabel()}
      </Button>
    </CollapseContainer>
  );
}

const CollapseContainer = styled(Collapse)(({ theme }) => ({
  '& .MuiCollapse-wrapperInner': {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2, 5, 5, 5),
  },
}));
