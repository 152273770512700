import React from 'react';

import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import useCreateExecution from 'client/app/apps/simulation-details/useCreateExecution';

type Props = {
  simulationId: SimulationId;
  hasStartedExecution: boolean;
};

export default function CreateExecutionButton({
  simulationId,
  hasStartedExecution,
}: Props) {
  const [createExecution, { loading }] = useCreateExecution(simulationId);
  const disabled = hasStartedExecution || loading;

  const button = (
    <StyledFab
      data-heap-tracking="start-execution-button"
      color="secondary"
      size="medium"
      variant="extended"
      onClick={createExecution}
      disabled={disabled}
    >
      Start Execution
    </StyledFab>
  );

  return hasStartedExecution ? (
    <Tooltip title="The execution is already started. If you want to run in the lab again, please re-simulate the workflow.">
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
}

const StyledFab = styled(Fab)(({ theme: { spacing } }) => ({
  gap: spacing(3),
}));
