import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

const SubItemContainer = styled(MenuItem)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '24px 1fr 5px',
  gap: theme.spacing(5),

  padding: '12px 22px',
  cursor: 'pointer',

  '&:not(:last-child)': {
    '&::after': {
      content: "''",

      position: 'absolute',
      bottom: -1,
      left: 12,
      width: 'calc(100% - 24px)',

      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));

export default SubItemContainer;
