import React from 'react';

import Success from '@mui/icons-material/CheckCircleOutlineRounded';
import Close from '@mui/icons-material/Close';
import Error from '@mui/icons-material/ErrorOutlineRounded';
import Info from '@mui/icons-material/InfoOutlined';
import Warning from '@mui/icons-material/ReportProblemOutlined';
import IconButton from '@mui/material/IconButton';
import Portal from '@mui/material/Portal';
import MaterialSnackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import cx from 'classnames';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const SNACKBAR_SHOW_DURATION_MS = 6000;

const variantIcon = {
  success: Success,
  warning: Warning,
  error: Error,
  info: Info,
};

export type SnackbarVariant = 'success' | 'warning' | 'error' | 'info';

type Props = {
  isOpen: boolean;
  message: string;
  variant: SnackbarVariant;
  onClose: () => void;
};

export default function Snackbar(props: Props) {
  const classes = useStyles();
  const { message, onClose, isOpen, variant } = props;
  const Icon = variantIcon[variant];

  // Snackbars use the MaterialUI Portal so that this framework can automatically
  // handle stacking properly and show our snackbars on top of Dialogs etc.
  return (
    <Portal>
      <MaterialSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={SNACKBAR_SHOW_DURATION_MS}
        onClose={onClose}
        open={isOpen}
      >
        <SnackbarContent
          className={classes.content}
          message={
            <div className={classes.message}>
              <Icon className={cx(classes.icon, classes.iconVariant, classes[variant])} />
              {message}
            </div>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={onClose}
              size="large"
            >
              <Close className={cx(classes.icon, classes[variant])} />
            </IconButton>,
          ]}
        />
      </MaterialSnackbar>
    </Portal>
  );
}

const useStyles = makeStylesHook(theme => ({
  icon: {
    fontSize: '24px',
  },
  iconVariant: {
    marginRight: theme.spacing(3),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    color: Colors.DARK_TEXT_PRIMARY,
    backgroundColor: Colors.GREY_60,
  },
  error: {
    color: Colors.ERROR_LIGHT,
  },
  info: {
    color: Colors.INFO_LIGHT,
  },
  success: {
    color: Colors.SUCCESS_LIGHT,
  },
  warning: {
    color: Colors.WARNING,
  },
}));
