import React, { useMemo } from 'react';

import Typography from '@mui/material/Typography';

import { isDefined } from 'common/lib/data';
import stopPropagation from 'common/lib/stopPropagation';
import { Device, DeviceRunConfig } from 'common/types/device';
import Colors from 'common/ui/Colors';
import Dropdown, { Option } from 'common/ui/filaments/Dropdown';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type DeviceConfigSelection = {
  [deviceUUID: string]: string | undefined;
};

type ConfigSelectorProps = {
  device: Device;
  selectedConfigs: DeviceConfigSelection;
  handleSelectConfig: (deviceId: string) => (value?: DeviceRunConfig) => void;
  hasConfigError?: boolean;
};

function configToOption(config: DeviceRunConfig | null): Option<DeviceRunConfig> | null {
  if (!config) {
    return null;
  }
  return { value: config, label: config.name };
}

function computeConfigToUse(
  allConfigsOfDevice: readonly DeviceRunConfig[],
  device: Device,
  selectedConfigs: DeviceConfigSelection,
) {
  if (allConfigsOfDevice.length === 0) {
    return null;
  }

  // If there is only one configuration available, we want to use that as default
  if (allConfigsOfDevice.length === 1) {
    return allConfigsOfDevice[0];
  }

  if (allConfigsOfDevice.length > 1) {
    // If there are multiple configs available and no config has been selected yet,
    // display the "default" menu item
    return selectedConfigs[device.id]
      ? allConfigsOfDevice.find(config => config.id === selectedConfigs[device.id]) ??
          null
      : null;
  }
  return null;
}

/**
 *  Render a select input for device config
 */
export default function ConfigSelector(props: ConfigSelectorProps) {
  const classes = useStyles();
  const { device, selectedConfigs, handleSelectConfig, hasConfigError } = props;

  const allConfigsOfDevice: Option<DeviceRunConfig>[] = useMemo(
    () => (device.runConfigs ?? []).map(configToOption).filter(isDefined),
    [device.runConfigs],
  );
  const currentConfig = configToOption(
    computeConfigToUse(device.runConfigs ?? [], device, selectedConfigs),
  );

  const defaultConfig = currentConfig?.label ?? 'Default Config';

  const onSelectDeviceConfig = useMemo(
    () => handleSelectConfig(device.id),
    [device.id, handleSelectConfig],
  );

  return (
    /*  We need to stop the click event to propagate up to the card,
     *   or it would select the device.
     */
    <div className={classes.dropdown} onClick={stopPropagation}>
      {allConfigsOfDevice.length > 1 ? (
        <Dropdown
          valueLabel={currentConfig?.label ?? ''}
          options={allConfigsOfDevice}
          onChange={onSelectDeviceConfig}
          isRequired={hasConfigError}
          placeholder="CONFIGURE"
        />
      ) : (
        <Typography className={classes.defaultConfig}>{defaultConfig}</Typography>
      )}
    </div>
  );
}

const useStyles = makeStylesHook({
  dropdown: {
    margin: '8px 16px',
  },
  defaultConfig: {
    margin: '4px',
    color: Colors.GREY_60,
  },
});
