import React, { useRef } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { QUERY_EXPERIMENTS } from 'client/app/apps/experiments/gql/queries';
import {
  Entity,
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import DataComponent from 'client/app/apps/experiments/Tutorials/TutorialsListDataComponent';
import { ContentType, ExperimentsQueryVariables, SortTypeEnum } from 'client/app/gql';
import usePagination from 'client/app/hooks/usePagination';
import { PageInfo } from 'common/server/graphql/pagination';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import { EntityCardSkeletonList } from 'common/ui/components/EntityCard';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';
import SearchField from 'common/ui/components/SearchField';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

export function Tutorials() {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const [searchQuery, setSearchQuery] = useStateWithURLParams({
    paramName: 'search',
    paramType: 'string',
    defaultValue: '',
  });

  return (
    <Stack gap={6} height="100%">
      <Stack gap={3}>
        <Typography variant="h2">Tutorials</Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
          <Typography variant="body1">
            Explore our collection of onboarding materials to learn how to use Synthace,
            with step-by-step tutorials and practical examples of how to get started.
          </Typography>
          <SearchField
            label="Search"
            defaultValue={searchQuery}
            onQueryChange={setSearchQuery}
          />
        </Stack>
      </Stack>
      <ContainerWithIntersectionBar
        scrollableRef={scrollableContainerRef}
        content={
          <TutorialList
            scrollableContainerRef={scrollableContainerRef}
            searchQuery={searchQuery}
          />
        }
        noHeader
        dense
      />
    </Stack>
  );
}

type Props = {
  scrollableContainerRef: React.RefObject<HTMLDivElement>;
  searchQuery?: string;
};

function TutorialList({ scrollableContainerRef, searchQuery }: Props) {
  const variables: ExperimentsQueryVariables = {
    search: searchQuery,
    sort: SortTypeEnum.ALPHABETICAL,
    contentSource: ContentType.EXAMPLE,
  };

  const experimentsQuery = useQuery(QUERY_EXPERIMENTS, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const pageInfo = experimentsQuery.data?.experiments.pageInfo as PageInfo | undefined;
  const hasNextPage = usePagination({
    entity: 'experiments',
    pageInfo,
    fetchMore: experimentsQuery.fetchMore,
    dependencies: [],
    scrollableRef: scrollableContainerRef,
    isInitialLoading: experimentsQuery.loading,
    variables,
  });

  return (
    <>
      <RenderQuery
        query={experimentsQuery}
        renderData={DataComponent}
        renderNoData={NoEntitiesMessage}
        additionalNoDataProps={{
          entityName: 'tutorials' as Entity,
          messageType: MessageType.NO_FILTER_RESULTS,
          searchQuery,
        }}
        emptyCondition={data => data.experiments.items.length === 0}
        loadingComponent={EntityCardSkeletonList}
      />
      {hasNextPage && (
        <LoadingContainer>
          <CircularProgress size={24} />
        </LoadingContainer>
      )}
    </>
  );
}

const LoadingContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}));
