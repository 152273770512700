import React, { PropsWithChildren, useCallback, useState } from 'react';

import Popover, { PopoverProps } from '@mui/material/Popover';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/** Renders the given icon and reveals a popover with the given content on hover */
export default function IconWithPopover({
  icon,
  popoverContent,
  disableRestoreFocus,
  onHover,
}: {
  icon: JSX.Element;
  popoverContent: React.ReactNode;
  /**
   * By default, when closing the popover, the focus will go back to
   * the previous element which was in focus. This prop disables
   * that behaviour.
   */
  disableRestoreFocus?: boolean;
  onHover?: () => void;
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openInfo = Boolean(anchorEl);
  const handleInfoOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      onHover?.();
    },
    [onHover],
  );
  const handleInfoClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <div
        className={classes.container}
        onMouseEnter={handleInfoOpen}
        onMouseLeave={handleInfoClose}
      >
        {icon}
      </div>
      <AdditionalInfoPopover
        open={openInfo}
        anchorEl={anchorEl}
        disableRestoreFocus={disableRestoreFocus}
      >
        {popoverContent}
      </AdditionalInfoPopover>
    </>
  );
}

function AdditionalInfoPopover({
  id,
  anchorEl,
  open,
  children,
  disableRestoreFocus,
}: PropsWithChildren<
  Pick<PopoverProps, 'id' | 'anchorEl' | 'open' | 'children' | 'disableRestoreFocus'>
>) {
  const classes = useStyles();

  return (
    <Popover
      id={id}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      classes={{ paper: classes.popover }}
      style={{ pointerEvents: 'none' }} // This is needed for proper onMouseLeave behavior.
      disableRestoreFocus={disableRestoreFocus}
    >
      <div className={classes.popoverContent}>
        <div className={classes.group}>{children}</div>
      </div>
    </Popover>
  );
}

const useStyles = makeStylesHook(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  popover: {
    borderRadius: '8px',
    width: '248px',
  },
  popoverContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5, 5, 3, 5),
  },
}));
