import React from 'react';

import CalculateIcon from '@mui/icons-material/Calculate';
import ButtonBase from '@mui/material/ButtonBase';
import Tooltip from '@mui/material/Tooltip';

import { useFeatureToggle } from 'common/features/useFeatureToggle';

type Props = {
  onClick: () => void;
};

export default function StandaloneToolsButton({ onClick }: Props) {
  const showStandaloneTools = useFeatureToggle('STANDALONE_TOOLS');

  if (!showStandaloneTools) {
    return null;
  }

  return (
    <Tooltip title="Tools">
      <ButtonBase
        data-heap-tracking="breadcrumbs-standalone-tools-button"
        onClick={e => {
          e.stopPropagation();
          onClick();
        }}
        disableRipple
      >
        <CalculateIcon color="primary" />
      </ButtonBase>
    </Tooltip>
  );
}
