import { useCallback, useEffect, useRef } from 'react';

/**
 * ⚠ Do not use unless you have very good reasons.
 *
 * This hooks returns a never-changing(*) memoized callback that will call the changing function passed in as argmument.
 *
 * This can be useful in some rare cases where you want to avoid at all cost an effect to rerun, or a component to re-render.
 *
 * A few valid examples:
 * - we do not want to remove an event listener B on window while that event is beeing handled and handler A would cause the effect adding/removing B to run.
 * - we do not want to recreate the apollo client and lose its cache just after the authentication is done (we mount the app earlier).
 *
 * @param fn A function that may change between renders.
 * @return a memoized function that will never change, but will execute the current fn
 */
export default function useRefCallback<T extends (...args: never[]) => unknown>(
  fn: T,
): T {
  const fnRef = useRef<T>(fn);
  useEffect(() => {
    fnRef.current = fn;
  }, [fn]);
  return useCallback((...args: never[]) => fnRef.current(...args), []) as unknown as T;
}
