import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import MoreVert from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';

import {
  COPY_PROTOCOL_INSTANCE,
  DELETE_PROTOCOL_INSTANCE,
} from 'client/app/api/gql/mutations';
import BreadcrumbsMenu from 'client/app/components/nav/breadcrumbs/components/BreadcrumbsMenu';
import { BreadcrumbsEntityDropdownProps } from 'client/app/components/nav/breadcrumbs/useBreadcrumbsEntity';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { experimentsRoutes, protocolsRoutes } from 'client/app/lib/nav/actions';
import stopPropagation from 'common/lib/stopPropagation';
import IconButton from 'common/ui/components/IconButton';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { usePopover } from 'common/ui/hooks/usePopover';

function ProtocolInstanceDropdownButton({ entity }: BreadcrumbsEntityDropdownProps) {
  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();
  const navigation = useNavigation();
  const userProfile = useUserProfile();

  const canEdit = entity.currentSubItem?.createdBy?.id === userProfile?.id;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    stopPropagation(event);
    onShowPopover(event);
  };

  const handleAnyMenuClick = (event: React.MouseEvent) => {
    stopPropagation(event);
    onHidePopover();
  };

  const snackbar = useSnackbarManager();

  const [copyProtocol] = useMutation(COPY_PROTOCOL_INSTANCE);
  const [deleteProtocol] = useMutation(DELETE_PROTOCOL_INSTANCE);

  const handleCopyProtocol = useCallback(async () => {
    onHidePopover();

    try {
      const { data } = await copyProtocol({
        variables: {
          input: {
            id: entity.id as ProtocolInstanceId,
          },
        },
      });

      const newId = data?.copyProtocolInstance?.protocolInstance.id;

      if (newId) {
        navigation.navigate(protocolsRoutes.editProtocolInstance, { id: newId });
      } else {
        throw new Error(`No new protocol instance id was returned from the backend`);
      }
    } catch (error) {
      console.error(error);
      snackbar.showError(error);
    }
  }, [copyProtocol, entity.id, navigation, onHidePopover, snackbar]);

  const handleDeleteProtocol = useCallback(async () => {
    onHidePopover();

    try {
      await deleteProtocol({
        variables: {
          input: {
            id: entity.id as ProtocolInstanceId,
          },
        },
      });
      navigation.navigate(experimentsRoutes.base, undefined);
    } catch (error) {
      console.error(error.message);
      snackbar.showError(error.message);
    }
  }, [deleteProtocol, entity.id, navigation, onHidePopover, snackbar]);

  return (
    <>
      <MoreButton icon={<MoreVert />} size="xsmall" onClick={handleClick} />
      <BreadcrumbsMenu
        anchorEl={popoverAnchorElement}
        open={isPopoverOpen}
        onClose={onHidePopover}
        onClick={handleAnyMenuClick}
        onDoubleClick={stopPropagation}
      >
        <MenuItemWithIcon
          icon={<FileCopyOutlined />}
          text="Copy Protocol"
          onClick={handleCopyProtocol}
        />
        {canEdit && (
          <MenuItemWithIcon
            icon={<DeleteOutline />}
            text="Delete Protocol"
            onClick={handleDeleteProtocol}
          />
        )}
      </BreadcrumbsMenu>
    </>
  );
}

export default function ProtcolInstanceDropdown(props: BreadcrumbsEntityDropdownProps) {
  if (!props.expanded) {
    return null;
  }

  return <ProtocolInstanceDropdownButton {...props} />;
}

const MoreButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(3),
}));
