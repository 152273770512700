import React, { useCallback, useContext, useMemo, useState } from 'react';

// Properties that are required on a dialog class.
// Close can return some data via onClose handler, e.g.:
//  - we use the dialog for selecting a value
//  - we want to know if user clicked OK or Cancel
// If a user provides their own onClose, DialogManager will wrap it with its handler.
export type DialogProps = {
  onClose: (() => void) | ((arg: any) => void);
};

/**
 * If `onClose` takes one argument, `openDialogPromise` return promise will resolve to that argument.
 * If it takes zero arguments it will resolve to void.
 * There is not other option as DialogProps only allows `onClose` with one or two arguments.
 */
//prettier-ignore
type DialogReturnType<Props extends DialogProps> = (
    Parameters<Props['onClose']> extends [infer T] ? T
  : Props['onClose'] extends () => void ? void
  : never
);

/**
 * DialogManager provides functions to open dialogs from anywhere in React component hierarchy.
 */
export type DialogManager = {
  /**
   * Open dialog returing a promise.
   *
   * The promise will be resolved when onClose prop of the dialog is called with the same value
   * that was passed to onClose.
   *
   * The `dialogComponent` has to take an `onClose(result: any)` prop (it has to take exactly one argument).
   */
  openDialogPromise: <Props extends DialogProps>(
    dialogID: string,
    dialogComponent: React.ComponentType<Props>,
    props: Omit<Props, 'onClose'>, // We'll provide custom onClose, the user shouldn't specify it
  ) => Promise<DialogReturnType<Props>>;

  /**
   * Open-and-forget a dialog.
   *
   * The `dialogComponent` component has to take an `onClose()` prop but you don't have
   * to provide `onClose` in the `props` parameter.
   */
  openDialog: <Props extends DialogProps>(
    dialogID: string,
    dialogComponent: React.ComponentType<Props>,
    props: Omit<Props, 'onClose'> & Partial<DialogProps>,
  ) => void;
};

const DEFAULT_CONTEXT: DialogManager = {
  openDialogPromise: () => {
    throw new Error('No DialogManagerContext provider registered.');
  },
  openDialog: () => {
    throw new Error('No DialogManagerContext provider registered.');
  },
};

const DialogManagerContext = React.createContext<DialogManager>(DEFAULT_CONTEXT);

/**
 * useDialogManager hook returns a `DialogManager` instance from the current context provider.
 */
export function useDialogManager() {
  return useContext(DialogManagerContext);
}

export type WithDialogManager = {
  dialogManager: DialogManager;
};

/**
 * `withDialogManager` wraps a component by injecting a `DialogManager` instance from the current context provider.
 */
export function withDialogManager<Props extends WithDialogManager>(
  Component: React.ComponentType<Props>,
) {
  return (props: Omit<Props, keyof WithDialogManager>) => {
    const dialogManager = useDialogManager();

    // We need the `as Props` to workaround an issue with TS
    // See https://github.com/Microsoft/TypeScript/issues/28938
    // Basically the type:
    //   (Omit<Props, 'dialogManager> & { dialogManager: DialogManagerContext})
    // is not compatible with `Props`.
    // (see https://github.com/microsoft/TypeScript/issues/28884 for discussion)

    return <Component {...(props as Props)} dialogManager={dialogManager} />;
  };
}

type Dialog<Props extends DialogProps = DialogProps> = {
  id: string;
  dialogComponent: React.ComponentType<Props>;
  props: Props;
};

/**
 * `DialogManageProvider`:
 *  - provides a `DialogManager` instance that can be acessed using `useDialogManager` or `withDialogManger`,
 *  - renders currently open dialogs after all its children.
 */
export function DialogManagerProvider({ children }: React.PropsWithChildren<{}>) {
  const [dialogs, setDialogs] = useState<Dialog<DialogProps>[]>([]);

  /**
   * closeDialog with undefined `dialogID` could be used to close the top-level dialog.
   */
  const closeDialog = useCallback(
    (dialogID?: string) => {
      if (dialogID) {
        setDialogs(oldDialogs => oldDialogs.filter(d => d.id !== dialogID));
      } else {
        setDialogs(oldDialogs => oldDialogs.slice(0, oldDialogs.length - 1));
      }
    },
    [setDialogs],
  );

  const openDialog = useCallback(
    // This `any` here is where we get rid of types, as we're shoving Dialogs with various Props
    // all into one array.
    // It feels like we could use `Dialog<DialogProps>` here, but for some reason
    // TS can't unify `Dialog<DialogProps>` with `Dialog<T>` where `T` extends `DialogProps`
    // this comes down to a crazy type of React.ComponentType.
    // But we know that anything that gets passed into `openDialog` actually has `onClose` so everything is OK.
    (newDialog: Dialog<any>) => {
      setDialogs(oldDialogs => {
        const alreadyOpen = oldDialogs.find(d => d.id === newDialog.id);
        if (alreadyOpen) {
          oldDialogs = oldDialogs.filter(d => d.id !== newDialog.id);
        }
        return [...oldDialogs, newDialog];
      });
    },
    [setDialogs],
  );

  const ctx = useMemo<DialogManager>(
    () => ({
      /**
       * `onClose` called by the dialog component will resolve the promise returned here.
       */
      openDialogPromise: (dialogID, dialogComponent, props) => {
        return new Promise(accept => {
          const newOnClose = (arg: any) => {
            closeDialog(dialogID);
            accept(arg);
          };

          openDialog({
            id: dialogID,
            dialogComponent,
            props: {
              ...props,
              onClose: newOnClose,
            },
          });
        });
      },

      openDialog: <Props extends DialogProps>(
        dialogID: string,
        dialogComponent: React.ComponentType<Props>,
        props: Omit<Props, 'onClose'> & Partial<DialogProps>,
      ) => {
        const newOnClose = (...args: any[]) => {
          closeDialog(dialogID);
          if (props.onClose) {
            (props.onClose as any)(...args);
          }
        };

        openDialog({
          id: dialogID,
          dialogComponent,
          props: {
            ...props,
            onClose: newOnClose,
          },
        });
      },
    }),
    [openDialog, closeDialog],
  );

  return (
    <DialogManagerContext.Provider value={ctx}>
      {children}
      {dialogs.map(dialog => {
        const DialogComponent = dialog.dialogComponent;
        return <DialogComponent key={dialog.id} {...dialog.props} />;
      })}
    </DialogManagerContext.Provider>
  );
}
