import React, { useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import isEqual from 'lodash/isEqual';

import ColumnVolumeEditor from 'client/app/components/Parameters/ChromatographyActions/ColumnVolumeEditor';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import {
  divideColumnVolumes,
  fromColumnVolume,
  loadVolumesToTotalAndFractionVolume,
  residenceTimeToFlowRate,
  toColumnVolume,
  totalAndFractionVolumeToLoadVolumes,
} from 'common/lib/chromatography';
import {
  getUnit,
  massToVolume,
  timeToVolume,
  volumeToMass,
  volumeToTime,
} from 'common/lib/units';
import { Measurement } from 'common/types/mix';
import InlineHelp from 'common/ui/components/InlineHelp/InlineHelp';
import GenericInputEditor from 'common/ui/components/ParameterEditors/GenericInputEditor';
import MeasurementEditor from 'common/ui/components/ParameterEditors/MeasurementEditor';

const MICROLITRES = getUnit('ul');
const MILLIGRAMS = getUnit('mg');
const SECONDS = getUnit('s');

type FractionInputsProps = {
  isDisabled?: boolean;
  isGradient?: boolean;
  robocolumnVolume?: Measurement;
  loadVolumesInCV?: number[];
  setLoadVolumesInCV: (newLoadVolumesInCV?: number[]) => void;
  /**
   * Required to calculate mass. In units SI prefixed units of g/l if provided
   */
  liquidConcentration?: Measurement;
  /**
   * Required to calculate fraction collection time
   */
  residenceTime?: Measurement;
};

/**
 * Inputs for determining the exact individual load volumes to add to a RoboColumn.
 */
export function LoadCalculationInputs({
  isDisabled,
  isGradient,
  robocolumnVolume,
  liquidConcentration,
  residenceTime,
  loadVolumesInCV,
  setLoadVolumesInCV,
}: FractionInputsProps) {
  const showLoadMassInput = useFeatureToggle('LOAD_ROBOCOLUMN_BY_MASS');
  const calculateMass = useCallback(
    (volume?: Measurement, conc?: Measurement) =>
      volumeToMilligrams(fromColumnVolume(volume, robocolumnVolume), conc),
    [robocolumnVolume],
  );

  const calculateTime = useCallback(
    (volume?: Measurement, flowRate?: Measurement) =>
      volumeToSeconds(fromColumnVolume(volume, robocolumnVolume), flowRate),
    [robocolumnVolume],
  );

  // round the initially displayed column volume; we don't want to scare users
  // with the unrounded floating point values
  const asColumnVolume = (n: number) => ({ value: +n.toFixed(3), unit: 'CV' });
  const initialColumnVolume = loadVolumesToTotalAndFractionVolume(loadVolumesInCV);
  const [totalLoadVolume, setTotalLoadVolume] = useState<Measurement | undefined>(
    initialColumnVolume && asColumnVolume(initialColumnVolume.total),
  );
  const [fractionVolume, setFractionVolume] = useState<Measurement | undefined>(
    initialColumnVolume && asColumnVolume(initialColumnVolume.fraction),
  );
  const [fractionCount, setFractionCount] = useState<string | undefined>(
    // count should be a value > 1. So toFixed dp is precision is good enough
    divideColumnVolumes(totalLoadVolume, fractionVolume)?.toFixed(3),
  );
  const [fractionWarning, setFractionWarning] = useState('');
  const [flowRate, setFlowRate] = useState<Measurement | undefined>(
    residenceTimeToFlowRate(residenceTime, robocolumnVolume),
  );
  const [fractionTime, setFractionTime] = useState<Measurement | undefined>(
    calculateTime(fractionVolume, flowRate),
  );
  const [totalMass, setTotalMass] = useState<Measurement | undefined>(
    calculateMass(totalLoadVolume, liquidConcentration),
  );

  const handleCalculations = (total?: Measurement, fraction?: Measurement) => {
    const totalLoadColumnVolume = toColumnVolume(total, robocolumnVolume);
    const fractionColumnVolume = toColumnVolume(fraction, robocolumnVolume);
    const calculated = totalAndFractionVolumeToLoadVolumes({
      total: totalLoadColumnVolume,
      fraction: fractionColumnVolume,
    });
    setLoadVolumesInCV(calculated.loadColumnVolumes);
    setFractionCount(calculated.numFractions.toFixed(3));
    setTotalLoadVolume(total);

    // if numFractions = 1, then by definition fraction never be higher than
    // total since it means we can collect all of the total load volume within
    // one fraction. From QA, it was felt that the fraction volume should be
    // capped to the total to clearly indicate this to the user
    const cappedFraction =
      calculated.numFractions !== 1
        ? fraction
        : fraction?.unit === 'CV'
        ? totalLoadColumnVolume
        : fromColumnVolume(total, robocolumnVolume);
    const warning = !isEqual(cappedFraction, fraction)
      ? 'Fraction volume cannot be higher than total load volume'
      : '';
    setFractionWarning(warning);
    setFractionVolume(cappedFraction);
  };

  const handleFractionVolumeChange = (newFractionVolume?: Measurement) => {
    handleCalculations(totalLoadVolume, newFractionVolume);
  };

  const handleFractionTimeChange = (newFractionTime?: Measurement) => {
    const newFractionVolume = timeToMicrolitres(newFractionTime, flowRate);
    handleCalculations(totalLoadVolume, newFractionVolume);
    // rely on useEffect to update time value
  };

  const handleTotalLoadVolumeChange = (newTotalLoadVolume?: Measurement) => {
    handleCalculations(newTotalLoadVolume, fractionVolume);
  };

  const handleTotalMassChange = (newTotalMass?: Measurement) => {
    const newTotalLoadVolume = massToMicrolitres(newTotalMass, liquidConcentration);
    handleCalculations(newTotalLoadVolume, fractionVolume);
    // rely on useEffect to update mass value
  };

  useEffect(() => {
    const newTotalMass = calculateMass(totalLoadVolume, liquidConcentration);
    setTotalMass(newTotalMass);
  }, [calculateMass, liquidConcentration, totalLoadVolume]);

  useEffect(() => {
    // don't chance fraction volume, just recalculate fraction time
    const newFlowRate = residenceTimeToFlowRate(residenceTime, robocolumnVolume);
    setFlowRate(newFlowRate);
    setFractionTime(calculateTime(fractionVolume, newFlowRate));
  }, [calculateTime, fractionVolume, residenceTime, robocolumnVolume]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="subtitle2">Load Calculations</Typography>
        <InlineHelp>
          <p>
            The following calculations are automatically performed for you:
            <ul>
              <li>
                Fraction collection time (s) = load volume per fraction (l) ÷ flow rate
                (l/s)
              </li>
              <li>
                Load volume per fraction (l) = fraction collection time (s) × flow rate
                (l/s)
              </li>
              <li>
                Num. fractions = total load volume (l) ÷ load volume per fraction (l)
              </li>
              <li>
                Total load volume (l) = total load mass (g) ÷ liquid concentration (g/l)
              </li>
              <li>
                Total load mass (g) = total load volume (l) × liquid concentration (g/l)
              </li>
            </ul>
          </p>
        </InlineHelp>
      </Box>
      <Box>
        <InputLabel shrink>Fraction Collection Time</InputLabel>
        <MeasurementEditor
          validUnits={[SECONDS.label]}
          defaultUnit={SECONDS.label}
          value={fractionTime}
          onChange={handleFractionTimeChange}
          isDisabled={isDisabled || !residenceTime || !robocolumnVolume}
          isRequired
        />
        {!residenceTime && (
          <FormHelperText disabled>
            To set fraction times, please ensure a residence time is set.
          </FormHelperText>
        )}
        {!robocolumnVolume && (
          <FormHelperText disabled>
            To set fraction times, please edit robocolumns with identical volumes.
          </FormHelperText>
        )}
      </Box>
      <Box>
        <InputLabel shrink>Max Load Volume Per Fraction</InputLabel>
        <ColumnVolumeEditor
          value={fractionVolume}
          robocolumnVolume={robocolumnVolume}
          onChange={handleFractionVolumeChange}
          isDisabled={isDisabled}
          isRequired
        />
        {fractionWarning && <FormHelperText>{fractionWarning}</FormHelperText>}
      </Box>
      <Box>
        <InputLabel shrink>Calculated Number of Fractions</InputLabel>
        <GenericInputEditor
          type=""
          value={fractionCount}
          onChange={() => {}}
          isDisabled
        />
      </Box>
      <Box>
        <InputLabel shrink>Total Load Volume</InputLabel>
        <ColumnVolumeEditor
          value={totalLoadVolume}
          robocolumnVolume={robocolumnVolume}
          onChange={handleTotalLoadVolumeChange}
          isDisabled={isDisabled}
          isRequired
        />
      </Box>
      {showLoadMassInput && !isGradient && (
        <Box>
          <InputLabel shrink>Total Load Mass</InputLabel>
          <MeasurementEditor
            validUnits={[MILLIGRAMS.label]}
            defaultUnit={MILLIGRAMS.label}
            value={totalMass}
            onChange={handleTotalMassChange}
            isDisabled={isDisabled || !liquidConcentration || !robocolumnVolume}
            isRequired
          />
          {!robocolumnVolume ? (
            <FormHelperText disabled>
              To load by mass, please edit robocolumns with identical volumes.
            </FormHelperText>
          ) : !liquidConcentration ? (
            <FormHelperText disabled>
              To load by mass, ensure the liquid to load has no subcomponents and an
              overall SI prefixed mass concentration (e.g. mg/ml) and that there are no
              errors before this element.
            </FormHelperText>
          ) : undefined}
        </Box>
      )}
    </>
  );
}

function timeToMicrolitres(time?: Measurement, flowRate?: Measurement) {
  const calc = timeToVolume({ initial: time, rate: flowRate, inUnit: MICROLITRES });
  if (!calc) {
    return;
  }
  // For devices that support robocolumn chromatography, their tips can not
  // transfer below 0.1 ul. So we round to 1 dp here
  return { ...calc, value: +calc.value.toFixed(1) };
}

function volumeToSeconds(volume?: Measurement, flowRate?: Measurement) {
  const calc = volumeToTime({ initial: volume, rate: flowRate, inUnit: SECONDS });
  if (!calc) {
    return;
  }
  // For robocolumns, sub-0.1 seconds times are not feasible given the flow
  // rates and robocolumn volumes, so rounding to 1 dp to prevent floating point
  // imprecision is fine
  return { ...calc, value: +calc.value.toFixed(1) };
}

function volumeToMilligrams(volume?: Measurement, concentration?: Measurement) {
  const calc = volumeToMass({ initial: volume, rate: concentration, inUnit: MILLIGRAMS });
  if (!calc) {
    return;
  }
  // For scientists, the integer part of the number is normally always
  // significant, whereas the floating part is less important and 3 sig figs
  // is usually enough
  const value = calc.value > 1 ? calc.value.toFixed(3) : calc.value.toPrecision(3);
  return { ...calc, value: +value };
}

function massToMicrolitres(mass?: Measurement, concentration?: Measurement) {
  const calc = massToVolume({ initial: mass, rate: concentration, inUnit: MICROLITRES });
  if (!calc) {
    return;
  }
  // For devices that support robocolumn chromatography, their tips can not
  // transfer below 0.1 ul. So we round to 1 dp here
  return { ...calc, value: +calc.value.toFixed(1) };
}
