import React from 'react';

import Colors from 'common/ui/Colors';
import UIBox from 'common/ui/components/UIBox';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

// This is meant to be the simplest possible, reusable UI component
// for putting an error onto the screen anywhere in the UI. It's not
// a modal and isn't intended to be interactive at all. It's just a
// red box with white text.
export default function UIErrorBox(props: React.PropsWithChildren<{}>) {
  const classes = useStyles();
  return (
    <UIBox className={classes.errorBox} padding="m" margin="vm">
      {props.children}
    </UIBox>
  );
}

const useStyles = makeStylesHook({
  errorBox: {
    color: Colors.WHITE,
    background: Colors.RED,
    border: '1px #C73E2C solid',
    borderRadius: '2px',
  },
});
