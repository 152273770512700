import React, { ReactNode, useCallback } from 'react';

import ExpandMore from '@mui/icons-material/ArrowDropDown';
import ExpandLess from '@mui/icons-material/ArrowDropUp';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const EXPAND_BUTTON_WIDTH = '34px';
export const ENTITY_CARD_EXPANDABLE_GRID_TEMPLATE_COLUMNS = `[expand] ${EXPAND_BUTTON_WIDTH} [summary] 1fr [action] 80px [end]`;
const ICON_DIMENSIONS = { width: 24, height: 24 };

type EntityCardExpandableProps = {
  entityCardContent: React.ReactElement;
  entityCardAction: React.ReactElement;
  entityCardActionText?: string;
  entityCardCollapseContent: React.ReactElement;
  onClick?: () => void;
  disabledExpand?: boolean;
};

export function CardLink(props: {
  to?: string;
  title?: string;
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
}) {
  return props.to ? (
    <Link to={props.to} {...props} />
  ) : (
    <div {...props} style={{ width: '100%' }} />
  );
}

export const ENTITY_CARD_COLUMN_GAP = 5;
export const ENTITY_CARD_COLUMN_TEMPLATE =
  '[icon] 24px [icon2] 24px [name] minmax(30%, 1fr) [additional] 120px [author] minmax(60px, 188px) [date] minmax(100px, 140px) [status] 48px [end]';
export const ENTITY_CARD_COLUMN_TEMPLATE_NO_STATUS =
  '[icon] 24px [icon2] 24px [name] minmax(30%, 1fr) [additional] 120px [author] minmax(60px, 188px) [date] minmax(100px, 140px) [status] 0px [end]';
export const ENTITY_CARD_LEFT_PADDING = '20px';
export const ENTITY_CARD_RIGHT_PADDING = 6;
export const ENTITY_CARD_ROW_TEMPLATE = '24px 24px';
export const ENTITY_CARD_Y_PADDING = 5;

export default function EntityCardExpandable(props: EntityCardExpandableProps) {
  const classes = useStyles();
  const {
    entityCardContent,
    entityCardAction,
    entityCardCollapseContent,
    onClick,
    disabledExpand,
  } = props;

  const [expanded, setExpanded] = React.useState(false);
  const handleExpand = useCallback(() => {
    onClick?.();
    if (!disabledExpand) {
      setExpanded(!expanded);
    }
  }, [disabledExpand, expanded, onClick]);

  return (
    <Paper
      className={cx(classes.card, classes.cardGrid, { [classes.cardExpanded]: expanded })}
      variant="outlined"
    >
      <CardExpand
        showDetails={expanded}
        onClick={handleExpand}
        disabled={disabledExpand}
      />
      <div className={classes.cardContent} onClick={handleExpand}>
        {entityCardContent}
      </div>

      <div className={classes.cardAction}>
        <Typography variant="overline">
          {props.entityCardActionText || 'Action'}
        </Typography>
        {entityCardAction}
      </div>

      <Collapse
        className={classes.cardCollapse}
        in={expanded}
        unmountOnExit
        timeout="auto"
      >
        {entityCardCollapseContent}
      </Collapse>
    </Paper>
  );
}

function CardExpand({
  showDetails,
  onClick,
  disabled,
}: {
  showDetails?: boolean;
  onClick: () => void;
  disabled?: boolean;
}) {
  const classes = useStyles();
  return (
    <Tooltip title={disabled ? '' : showDetails ? 'See less' : 'See more'}>
      <IconButton
        icon={showDetails ? <ExpandLess /> : <ExpandMore />}
        onClick={onClick}
        className={cx(classes.expandButton, {
          [classes.expandButtonExpanded]: showDetails,
        })}
        size="small"
        disabled={disabled}
      />
    </Tooltip>
  );
}

type EntityCardExpandableHeaderRowProps = {
  summaryTitle: string;
  authorTitle: string;
  dateTitle: string;
  additionalColumnTitle?: string;
  statusTitle?: string;
};

export function EntityCardExpandableHeaderRow(props: EntityCardExpandableHeaderRowProps) {
  const classes = useStyles();
  return (
    <div className={cx(classes.headerRow, classes.headerGrid)}>
      <div
        className={cx(classes.headerGridRow, {
          [classes.headerGridRowNoStatus]: !props.statusTitle,
        })}
      >
        <Typography
          variant="overline"
          className={cx(
            props.additionalColumnTitle ? classes.rowNameWithAdditional : classes.rowName,
          )}
        >
          {props.summaryTitle}
        </Typography>
        {props.additionalColumnTitle && (
          <Typography variant="overline" className={classes.rowAdditional}>
            {props.additionalColumnTitle}
          </Typography>
        )}
        <Typography variant="overline" className={classes.rowAuthor}>
          {props.authorTitle}
        </Typography>
        <Typography variant="overline" className={classes.rowDate}>
          {props.dateTitle}
        </Typography>
        {props.statusTitle && (
          <Typography variant="overline" className={classes.rowStatus}>
            {props.statusTitle}
          </Typography>
        )}
      </div>
    </div>
  );
}

type EntityCardExpandableRowProps = {
  path?: string;
  title: string;
  onClick?: () => void;
  icon?: React.ReactElement;
  exampleWorflowIcon?: React.ReactElement;
  name: React.ReactElement;
  author: string;
  date: string;
  additionalColumnValue?: React.ReactNode;
  action?: React.ReactElement;
  status?: React.ReactElement;
  heapTrackingLabel?: string;
};

export function EntityCardExpandableRow(props: EntityCardExpandableRowProps) {
  const classes = useStyles();
  return (
    <div
      data-heap-tracking={
        props.heapTrackingLabel ? `entity-card-row-${props.heapTrackingLabel}` : undefined
      }
      className={cx(classes.cardGrid, classes.expandableRow)}
    >
      <CardLink
        to={props.path}
        title={props.title}
        onClick={props.onClick}
        className={cx(classes.headerGridRow, classes.cardContent, classes.link, {
          [classes.headerGridRowNoStatus]: !props.status,
        })}
      >
        {props.exampleWorflowIcon && (
          <div className={classes.exampleWorflowIcon}>{props.exampleWorflowIcon}</div>
        )}
        {props.icon && <div className={classes.rowIcon}>{props.icon}</div>}

        <div
          className={cx(classes.rowName, {
            [classes.rowNameWithAdditional]: props.additionalColumnValue,
          })}
        >
          {props.name}
        </div>
        {props.additionalColumnValue && (
          <Typography variant="body2" noWrap className={classes.rowAdditional}>
            {props.additionalColumnValue}
          </Typography>
        )}
        <Typography variant="body2" noWrap className={classes.rowAuthor}>
          {props.author}
        </Typography>
        <Typography variant="body2" className={classes.rowDate}>
          {props.date}
        </Typography>
        {props.status && <div className={classes.rowStatus}>{props.status}</div>}
      </CardLink>
      {props.action && <div className={classes.rowAction}>{props.action}</div>}
    </div>
  );
}

type EntityCardExpandableSkeletonRowProps = {
  showIcon?: boolean;
  showStatus?: boolean;
};

export function EntityCardExpandableSkeletonRow(
  props: EntityCardExpandableSkeletonRowProps,
) {
  const classes = useStyles();
  return (
    <div className={cx(classes.expandableRow, classes.cardGrid)}>
      <div className={classes.headerGridRow}>
        {props.showIcon && (
          <Skeleton
            variant="circular"
            width={ICON_DIMENSIONS.width}
            height={ICON_DIMENSIONS.height}
            className={classes.rowIcon}
          />
        )}
        <Skeleton variant="text" className={classes.rowName} />
        <Skeleton variant="text" className={classes.rowAuthor} />
        <Skeleton variant="text" className={classes.rowDate} />
        {props.showStatus && (
          <Skeleton
            variant="circular"
            width={ICON_DIMENSIONS.width}
            height={ICON_DIMENSIONS.height}
            className={classes.rowStatus}
          />
        )}
      </div>
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  card: {
    borderRadius: '8px',
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: ENTITY_CARD_EXPANDABLE_GRID_TEMPLATE_COLUMNS,
    alignItems: 'stretch',
  },
  cardExpanded: {
    borderColor: Colors.INDIGO_90,
  },
  expandButton: {
    gridColumn: 'expand',
    borderRadius: '6px 0 0 0',
    borderRight: `1px solid ${Colors.GREY_30}`,
    height: 'auto',
    width: 'auto',
    alignSelf: 'stretch',
  },
  expandButtonExpanded: {
    backgroundColor: Colors.INDIGO_90,
    borderRightColor: Colors.INDIGO_90,
    color: 'white',
    '&:hover': {
      backgroundColor: Colors.INDIGO_100,
    },
  },
  cardContent: {
    '&:hover': {
      backgroundColor: Colors.ACTION_PRIMARY_MAIN_HOVER,
      borderColor: 'transparent',
      cursor: 'pointer',
      transition: theme.transitions.easing.easeInOut,
    },
  },
  cardCollapse: {
    gridColumn: 'expand / end',
    gridRow: '2',
  },
  cardAction: {
    display: 'grid',
    gridTemplateRows: ENTITY_CARD_ROW_TEMPLATE,
    alignItems: 'center',
    justifyItems: 'center',
    marginTop: theme.spacing(ENTITY_CARD_Y_PADDING),
  },
  headerRow: {
    backgroundColor: Colors.GREY_20,
    height: '48px',
  },
  headerGrid: {
    display: 'grid',
    gridTemplateColumns: ENTITY_CARD_EXPANDABLE_GRID_TEMPLATE_COLUMNS,
  },
  headerGridRow: {
    gridColumn: 'expand / action',
    display: 'grid',
    gridTemplateColumns: ENTITY_CARD_COLUMN_TEMPLATE,
    gap: theme.spacing(0, ENTITY_CARD_COLUMN_GAP),
    alignItems: 'center',
    padding: theme.spacing(
      0,
      ENTITY_CARD_RIGHT_PADDING,
      0,
      `calc(${EXPAND_BUTTON_WIDTH} - 16px)`,
    ),
  },
  headerGridRowNoStatus: {
    gridTemplateColumns: ENTITY_CARD_COLUMN_TEMPLATE_NO_STATUS,
  },
  rowName: {
    gridColumn: 'name / author',
    display: 'flex',
    alignItems: 'center',
    // Only show action icon buttons when hovering over row
    '& button': {
      visibility: 'hidden',
    },
    '&:hover button': {
      visibility: 'visible',
    },
  },
  rowNameWithAdditional: {
    gridColumn: 'name',
  },
  rowAuthor: {
    gridColumn: 'author',
  },
  rowDate: {
    gridColumn: 'date',
    // When the date column is shrunk the time will wrap onto the next line. Set the
    // height to be 1 line so the time is not visible.
    height: theme.typography.body1.lineHeight,
    overflow: 'hidden',
  },
  rowStatus: {
    gridColumn: 'status',
    justifySelf: 'center',
    display: 'flex',
  },
  rowAdditional: {
    gridColumn: 'additional',
  },
  rowIcon: { gridColumn: 'icon2' },
  exampleWorflowIcon: { gridColumn: 'icon' },
  rowAction: {
    gridColumn: 'action',
    justifySelf: 'center',
    alignSelf: 'center',
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
  expandableRow: {
    borderTop: `1px solid ${Colors.GREY_20}`,
    height: '48px',
    cursor: 'pointer',
    textDecoration: 'none',
    overflow: 'hidden',
    '&:last-of-type': {
      borderRadius: '0 0 6px 6px',
    },
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: Colors.TEXT_PRIMARY,
    '&:active': {
      backgroundColor: Colors.ACTION_PRIMARY_MAIN_ACTIVE,
      borderColor: 'transparent',
      transition: '0s',
    },
  },
}));
