import { useMemo } from 'react';

import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

import { BreadcrumbsEntityEnum } from 'client/app/gql';
import * as navigation from 'client/app/lib/nav/actions';
import { getScreenInfo } from 'client/app/lib/nav/mainNav';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';

export default function useCurrentScreenEntity() {
  const location = useLocation();
  const { currentScreenID } = useNavigation();

  const isEnabledLandingPage = useFeatureToggle('LANDING_PAGE');

  return useMemo(() => {
    const matchExperimentList = matchPath<{ id: string }>(
      location.pathname,
      navigation.experimentsRoutes.base.pathTemplate,
    );
    if (matchExperimentList?.isExact) {
      return BreadcrumbsEntityEnum.EXPERIMENT;
    }

    const matchExperimentReport = matchPath<{ id: string }>(
      location.pathname,
      navigation.experimentsRoutes.detail.pathTemplate,
    );
    if (matchExperimentReport?.isExact) {
      return BreadcrumbsEntityEnum.EXPERIMENT;
    }

    const matchBioprocessing = matchPath<{ experimentId: string; derivationId: string }>(
      location.pathname,
      navigation.experimentsRoutes.bioprocess.pathTemplate,
    );
    if (matchBioprocessing?.isExact) {
      return BreadcrumbsEntityEnum.DATASET_DERIVATION;
    }

    const { entity } = getScreenInfo(currentScreenID, isEnabledLandingPage);
    return entity ?? BreadcrumbsEntityEnum.EXPERIMENT;
  }, [currentScreenID, isEnabledLandingPage, location.pathname]);
}
