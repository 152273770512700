import { parseMeasurement } from 'common/lib/format';
import { getFirstValue } from 'common/object';
import { RoboColumnContent } from 'common/types/robocolumns';

/**
 * We convert the volumes to lowercase - ie 100ul not 100uL - because all conversions in
 * chromatography.ts expect lowercase. We won't need to worry about this when we converted
 * it to use core services (T4040).
 */
function getResinVolumes(robocolumns: RoboColumnContent[]) {
  return new Set(robocolumns.map(robocolumn => robocolumn?.Volume?.toLowerCase()));
}

/**
 * Get the volume of the robocolumns. Return undefined if there is more than one volume
 * (e.g. one robocolumn has 200ul and another has 100ul), since we won't know which to
 * use.
 */
export function getRobocolumnVolume(robocolumns: RoboColumnContent[]) {
  const resinVolumes = getResinVolumes(robocolumns);
  const resinVolume = resinVolumes.size === 1 ? getFirstValue(resinVolumes) : '';
  return parseMeasurement(resinVolume!);
}
