import { v4 as uuid } from 'uuid';

type Identifier = { id: string };
export type Identifiable<T extends object> = T & Identifier;

export default function cloneWithUUID<T extends object>(o: T): Identifiable<T> {
  if (Reflect.has(o, 'id')) {
    return o as Identifiable<T>;
  }
  return {
    id: uuid(),
    ...o,
  };
}
