import React, { useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';

import { useMixPreview } from 'client/app/api/MixPreviewApi';
import DataProcessingAlert from 'client/app/apps/execution-details/DataProcessingAlert/DataProcessingAlert';
import ExecuteTab from 'client/app/apps/execution-details/ExecuteTab/ExecuteTab';
import ResultsTab from 'client/app/apps/execution-details/ResultsTab/ResultsTab';
import {
  ExecutionDetailsTab as Tab,
  ExecutionDetailsTabPanel as TabPanel,
} from 'client/app/apps/execution-details/Tabs';
import { Execution } from 'client/app/apps/execution-details/types';
import UIErrorBox from 'client/app/components/UIErrorBox';
import Colors from 'common/ui/Colors';

type ExecutionDetailsProps = {
  execution: Execution;
  onRefresh: () => Promise<unknown>;
};

export default function ExecutionDetailsContent({
  execution,
  onRefresh,
}: ExecutionDetailsProps) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { status: fetchPreviewStatus, mixPreview } = useMixPreview(execution.simulation);

  if (fetchPreviewStatus === 'loading') {
    return <LinearProgress />;
  }

  if (fetchPreviewStatus === 'error' || !mixPreview) {
    return (
      <UIErrorBox>
        Sorry. We could not retrieve the proper information needed for this execution.
        Please contact our Support team for assistance.
      </UIErrorBox>
    );
  }

  return (
    <Screen>
      <DataProcessingAlert execution={execution} onRefresh={onRefresh} />
      <TabContainer value={activeTabIndex}>
        <Tab label="Execute" index={0} switchTab={setActiveTabIndex} />
        <Tab label="Results" index={1} switchTab={setActiveTabIndex} />
      </TabContainer>
      <TabPanel active={activeTabIndex === 0}>
        <ExecuteTab execution={execution} deck={mixPreview.deck} />
      </TabPanel>
      <TabPanel active={activeTabIndex === 1}>
        <ResultsTab execution={execution} deck={mixPreview.deck} onRefresh={onRefresh} />
      </TabPanel>
    </Screen>
  );
}

const Screen = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,

  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: Colors.GREY_10,
}));

const TabContainer = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
