import React, { useCallback, useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';

import SimpleDialog from 'common/ui/components/Dialog/SimpleDialog';
import { DialogProps } from 'common/ui/hooks/useDialog';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';
import { trackHeapEvent } from 'common/ui/useHeapTracking';

type Props = {
  simulationName: string | undefined;
  screenRegistry: string;
} & DialogProps<string | undefined>;

export default function RenameSimulationDialog(props: Props) {
  const { onClose, isOpen, simulationName, screenRegistry } = props;

  const [stagedValue, setStagedValue] = useState<string>(simulationName || '');
  const handleChange = useTextFieldChange(setStagedValue);
  const handleSave = useCallback(() => {
    onClose(stagedValue);
    trackHeapEvent('rename-entity', {
      source: screenRegistry,
      previousName: simulationName,
      newName: stagedValue,
      entityType: 'SIMULATION',
    });
  }, [onClose, screenRegistry, simulationName, stagedValue]);
  const handleCancel = useCallback(
    () => onClose(simulationName),
    [onClose, simulationName],
  );
  useEffect(() => setStagedValue(simulationName || ''), [simulationName, isOpen]);

  return (
    <SimpleDialog
      title="Edit simulation name"
      content={
        <TextField
          autoFocus
          margin="dense"
          type="text"
          fullWidth
          onChange={handleChange}
          defaultValue={stagedValue}
          variant="standard"
        />
      }
      onSubmit={handleSave}
      isOpen={isOpen}
      onClose={handleCancel}
    />
  );
}
