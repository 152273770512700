import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Node, useReactFlow } from 'react-flow-renderer';
import { useQueryParam } from 'use-query-params';

import { MUTATION_SET_HIDDEN_BLOCKS } from 'client/app/apps/work-tree/mutations';
import { useWorkTree } from 'client/app/apps/work-tree/useWorkTree';
import { HIGHLIGHTED_ENTITY_PARAM } from 'client/app/apps/work-tree/WorkTree';
import { NODE_HEIGHT, NODE_WIDTH } from 'client/app/apps/work-tree/workTreeDimensions';
import {
  ArrayElement,
  ExperimentWorkTreeQuery,
  WorkTreeApplicationName,
  WorkTreeApplicationsQuery,
} from 'client/app/gql';
import { getWorkTreeMethodsAndDescendants } from 'common/lib/getHiddenWorkTreeMethodsAndDescendants';

type ContextBase = {
  setModeViewing: () => void;
  setModeCreatingBlock: (init: CreatingBlock) => void;
  setModeEditingHiddenBlocks: () => void;
  toggleHidden: (blockId: BlockId) => void;
  hiddenBlocks: Set<BlockId>;
  saveHidden: () => void;
  selectedBlocks: BlockId[];
  addSelectedBlock: (blockId: BlockId) => void;
  clearSelectedBlocks: () => void;
  focusOnNode: (node: Node) => void;
  showLoadingState: boolean;
  setShowLoadingState: (show: boolean) => void;
  expandedBlocks: BlockId[];
  toggleExpandBlock: (blockId: BlockId) => void;
  selectedVersion: SelectedVersion | undefined;
  onHoverVersion: (rootVersion: SelectedVersion) => void;
  onClickVersion: (rootVersion: SelectedVersion) => void;
  highlightedBlock: BlockId | undefined;
};

type ContextState =
  | { mode: 'default' }
  | { mode: 'creating-block'; creatingBlock: CreatingBlock }
  | {
      mode: 'editing-hidden-blocks';
      /**
       * Stores the temporary state of each block's visibility according to the users
       * selection while in editing-hidden-blocks mode. A block ID will correspond to
       * true if the user hides the block in the UI, false if they unhide the block in
       * the UI, or unset if the user has not interacted with the visibility state of this
       * node.
       */
      hiddenBlockChanges: Map<BlockId, boolean>;
    };

type InputResultRequirements = ArrayElement<
  WorkTreeApplicationsQuery['workTreeApplications']
>['inputResultRequirements'];

type CreatingBlock = {
  applicationName: WorkTreeApplicationName;
  applicationVersion: string;
  inputResultRequirements: InputResultRequirements;
};

export type SelectedVersion = {
  parentBlockId: BlockId;
  versionId?: BlockId;
  userSelected?: boolean;
  dependants?: BlockId[];
};

type Block = ArrayElement<
  NonNullable<ExperimentWorkTreeQuery['experimentWorkTree']>['blocks']
>;

export const WorkTreeModeContext = createContext<ContextBase & ContextState>({
  mode: 'default',
  setModeViewing: () => undefined,
  setModeCreatingBlock: () => undefined,
  setModeEditingHiddenBlocks: () => undefined,
  toggleHidden: () => undefined,
  hiddenBlocks: new Set(),
  saveHidden: () => undefined,
  selectedBlocks: [],
  addSelectedBlock: () => undefined,
  clearSelectedBlocks: () => undefined,
  focusOnNode: () => undefined,
  showLoadingState: false,
  setShowLoadingState: () => undefined,
  expandedBlocks: [],
  toggleExpandBlock: () => undefined,
  selectedVersion: undefined,
  onHoverVersion: () => undefined,
  onClickVersion: () => undefined,
  highlightedBlock: undefined,
});

export function WorkTreeModeContextProvider({
  children,
  experimentId,
}: {
  children: ReactNode;
  experimentId: ExperimentId;
}) {
  const [modeState, setModeState] = useState<ContextState>({ mode: 'default' });

  const workTreeData = useWorkTree(experimentId);

  const hiddenBlocks = useMemo<Set<BlockId>>(() => {
    if (workTreeData.status === 'error' || workTreeData.status === 'loading') {
      return new Set();
    }

    if (modeState.mode === 'editing-hidden-blocks') {
      const hiddenIds = workTreeData.blocks
        .filter(
          block =>
            modeState.hiddenBlockChanges.get(block.id) === true ||
            (block.hidden && modeState.hiddenBlockChanges.get(block.id) !== false),
        )
        .map(block => block.id);

      return new Set(getWorkTreeMethodsAndDescendants(workTreeData.blocks, hiddenIds));
    }

    return new Set(
      workTreeData.blocks.filter(block => block.hidden).map(block => block.id),
    );
  }, [modeState, workTreeData]);

  const toggleHidden = (blockId: BlockId) => {
    if (modeState.mode !== 'editing-hidden-blocks') {
      return;
    }
    setModeState({
      mode: 'editing-hidden-blocks',
      hiddenBlockChanges: new Map([
        ...modeState.hiddenBlockChanges,
        [blockId, !hiddenBlocks.has(blockId)],
      ]),
    });
  };

  const [setHiddenBlocks, { loading: savingHidden }] = useMutation(
    MUTATION_SET_HIDDEN_BLOCKS,
  );

  const saveHidden = () => {
    void setHiddenBlocks({
      variables: { experimentId, blockIds: [...hiddenBlocks] },
    });
  };

  const [selectedBlocks, setSelectedBlocks] = useState<BlockId[]>([]);

  const addSelectedBlock = (blockId: BlockId) => {
    setSelectedBlocks(prevState => {
      const updatedMultiSelectNodes = [...prevState];
      if (!prevState.includes(blockId)) {
        updatedMultiSelectNodes.push(blockId);
      } else {
        const idx = prevState.indexOf(blockId);
        updatedMultiSelectNodes.splice(idx, 1);
      }
      return updatedMultiSelectNodes;
    });
  };

  const clearSelectedBlocks = () => {
    setSelectedBlocks([]);
  };

  const blocks =
    workTreeData.status === 'reloading' || workTreeData.status === 'success'
      ? workTreeData.blocks
      : undefined;

  // Ensure selection does not contain non-existant blocks if we get a cache update (e.g.
  // due to polling after node removed in another tab).
  useEffect(() => {
    if (blocks) {
      setSelectedBlocks(prevState =>
        prevState.filter(blockId => blocks.map(block => block.id).includes(blockId)),
      );
    }
  }, [blocks]);

  const setModeCreatingBlock = (init: CreatingBlock) => {
    setModeState({ mode: 'creating-block', creatingBlock: init });
    clearSelectedBlocks();
    setExpandedBlocksState([]);
  };

  const setModeViewing = () => {
    setModeState({ mode: 'default' });
    clearSelectedBlocks();
  };

  const setModeEditingHiddenBlocks = () => {
    setModeState({ mode: 'editing-hidden-blocks', hiddenBlockChanges: new Map() });
    clearSelectedBlocks();
  };

  const flow = useReactFlow<Block>();
  const focusOnNode = (node: Node<Block>) => {
    clearSelectedBlocks();
    flow.setCenter(node.position.x + NODE_WIDTH / 2, node.position.y + NODE_HEIGHT / 2, {
      zoom: flow.getZoom(),
      duration: 1000,
    });
    addSelectedBlock(node.data.id);
  };

  const [showLoadingState, setShowLoadingState] = useState(false);
  useEffect(() => {
    setShowLoadingState(savingHidden);
  }, [savingHidden]);

  const [expandedBlocks, setExpandedBlocksState] = useState<BlockId[]>([]);
  const toggleExpandBlock = (blockId: BlockId) => {
    const isExpanded = expandedBlocks.includes(blockId);
    setExpandedBlocksState(prev => {
      return isExpanded ? prev.filter(block => block !== blockId) : [...prev, blockId];
    });
    // Reset selectedVersion if the user collapses this node.
    if (isExpanded && blockId === selectedVersion?.parentBlockId) {
      setSelectedVersionState(undefined);
    }
  };

  const [selectedVersion, setSelectedVersionState] = useState<
    SelectedVersion | undefined
  >(undefined);

  const onHoverVersion = (rootVersion: SelectedVersion) => {
    // If user has already clicked and selected a version, we
    // don't update the selectedVersion state on hover.
    // Similarly, we don't want to allow hover if the block isn't expanded
    // (for example, a mouseOver event that is triggered while the collapse
    // transition is not yet complete).
    if (
      selectedVersion?.userSelected ||
      !expandedBlocks.includes(rootVersion.parentBlockId)
    ) {
      return;
    }
    setSelectedVersionState({
      parentBlockId: rootVersion.parentBlockId,
      versionId: rootVersion.versionId,
      userSelected: false,
      dependants: rootVersion.dependants,
    });
  };

  const onClickVersion = (rootVersion: SelectedVersion) => {
    // If the user has selected a version already, we reset the
    // selectedVersion state only if they click again on that
    // exact version (i.e. same parentBlockId and versionId).
    if (selectedVersion?.userSelected) {
      if (
        rootVersion.parentBlockId === selectedVersion.parentBlockId &&
        rootVersion.versionId === selectedVersion.versionId
      ) {
        setSelectedVersionState(undefined);
      }
    } else {
      setSelectedVersionState({
        parentBlockId: rootVersion.parentBlockId,
        versionId: rootVersion.versionId,
        userSelected: true,
        dependants: rootVersion.dependants,
      });
    }
  };
  const [highlightedBlock, setHighlightedBlock] = useState<BlockId | undefined>(
    undefined,
  );
  const [highlightedEntityParam, setHighlightedEntityParam] = useQueryParam<
    string | undefined
  >(HIGHLIGHTED_ENTITY_PARAM);

  useEffect(() => {
    const clearHighlightedBlock = () => {
      if (highlightedEntityParam) {
        setHighlightedBlock(undefined);
        setHighlightedEntityParam(undefined, 'replaceIn');
      }
    };
    // We can't grab any of the nodes until the react-flow viewport is intialized.
    if (highlightedEntityParam && flow.viewportInitialized) {
      const block = flow
        .getNodes()
        .find(
          node =>
            node.data.result?.id === highlightedEntityParam ||
            node.data.methodId === highlightedEntityParam,
        );
      if (block) {
        setHighlightedBlock(block.id as BlockId);
        flow.setCenter(
          block.position.x + NODE_WIDTH / 2,
          block.position.y + NODE_HEIGHT / 2,
          {
            zoom: flow.getZoom(),
            duration: 1000,
          },
        );
      }
      window.addEventListener('click', clearHighlightedBlock);
    }
    return () => window.removeEventListener('click', clearHighlightedBlock);
  }, [flow, highlightedEntityParam, setHighlightedEntityParam]);

  const common: ContextBase = {
    setModeViewing,
    setModeCreatingBlock,
    setModeEditingHiddenBlocks,
    hiddenBlocks,
    toggleHidden,
    saveHidden,
    selectedBlocks,
    addSelectedBlock,
    clearSelectedBlocks,
    focusOnNode,
    showLoadingState,
    setShowLoadingState,
    expandedBlocks,
    toggleExpandBlock,
    selectedVersion,
    onHoverVersion,
    onClickVersion,
    highlightedBlock,
  };

  return (
    <WorkTreeModeContext.Provider value={{ ...common, ...modeState }}>
      {children}
    </WorkTreeModeContext.Provider>
  );
}
