import { graphql } from 'common/server/graphql';

export enum ContentSourceType {
  /**
   * Tutorials content.
   *
   * It's called this way for historical reasons (tutorials was originally named "example gallery")
   */
  EXAMPLE = 'EXAMPLE',
  /**
   * Example workflows content
   */
  EXAMPLE_WORKFLOW = 'EXAMPLE_WORKFLOW',
  /*
   * Content generated by customers
   */
  USER_GENERATED = 'USER_GENERATED',
}

export const ContentTypeEnum = graphql.Enum(
  'ContentType',
  ContentSourceType,
  'The source of a platform asset',
);
