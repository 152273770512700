import React from 'react';

import ButtonBase from '@mui/material/ButtonBase';
import MuiCard from '@mui/material/Card';
import MuiCardActions from '@mui/material/CardActions';
import MuiCardContent from '@mui/material/CardContent';
import MuiCardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { LiquidPolicy, LiquidPolicySourceEnum } from 'client/app/gql';
import { SynthaceBrandmark } from 'common/assets/SynthaceBrandmark';
import { formatDateTime } from 'common/lib/format';
import Tooltip from 'common/ui/components/Tooltip';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import CustomLiquidPolicyIcon from 'common/ui/icons/CustomLiquidPolicyIcon';

type Props = {
  policy: LiquidPolicy;
  selected?: boolean;
  onClick?: (policy: LiquidPolicy) => void;
  onDoubleClick?: (policy: LiquidPolicy) => void;
};

export default function LiquidPolicyCard({
  policy,
  selected = false,
  onClick,
  onDoubleClick,
}: Props) {
  const avatarIcon =
    policy.source === LiquidPolicySourceEnum.user ? (
      <CustomLiquidPolicyIcon />
    ) : (
      <SynthaceBrandmark logoColour="multicolor" />
    );

  return (
    <Card
      onClick={() => onClick?.(policy)}
      onDoubleClick={() => onDoubleClick?.(policy)}
      selected={selected}
    >
      <CardHeader avatar={avatarIcon} title={<Title variant="h3">{policy.name}</Title>} />
      <CardContent>
        <Tooltip title={policy.description}>
          <Description variant="caption">
            {policy.description?.substring(0, 120) + '...'}
          </Description>
        </Tooltip>

        <Stack gap={2}>
          <Author>
            <Typography variant="body1">Created By:</Typography>
            <Typography variant="body1" fontWeight={500}>
              {policy.createdBy?.displayName ?? 'Synthace'}
            </Typography>
          </Author>
          <CreateDate>
            <Typography variant="body1">Created On:</Typography>
            <Typography variant="body1" fontWeight={500}>
              {formatDateTime(new Date(policy.createdAt))}
            </Typography>
          </CreateDate>
          <UpdateDate>
            <Typography variant="body1">Last Update:</Typography>
            <Typography variant="body1" fontWeight={500}>
              {/* TODO: at the moment it is unclear if we would allow editing policies and how
              editing policies would look like. So it is kept the same as creation date */}
              {formatDateTime(new Date(policy.createdAt))}
            </Typography>
          </UpdateDate>
        </Stack>
      </CardContent>
      <CardActions>
        <ViewButton>
          <Typography variant="body1" color="textSecondary" textTransform="uppercase">
            View details
          </Typography>
          <ArrowIcon />
        </ViewButton>
      </CardActions>
    </Card>
  );
}

//#region Styles

const Card = styled(MuiCard, { shouldForwardProp: prop => prop !== 'selected' })<{
  selected: boolean;
}>(({ theme, selected }) => ({
  width: 325,
  height: 244,
  outline: selected ? `1px solid ${theme.palette.primary.main}` : 'none',
  cursor: 'default',
  userSelect: 'none',
}));
const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
  padding: theme.spacing(6, 6, 3),
}));
const CardContent = styled(MuiCardContent)(({ theme }) => ({
  padding: theme.spacing(1, 6, 6),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));
const CardActions = styled(MuiCardActions)(({ theme }) => ({
  border: 'none',
  padding: theme.spacing(4),
  justifyContent: 'flex-end',
}));

const Title = styled(TypographyWithTooltip)(({ theme }) => ({
  color: theme.palette.text.primary,
  width: 200,
}));
const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  wordBreak: 'break-word',
}));

const TextSection = styled('section')(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  gap: theme.spacing(2),
}));
const Author = TextSection;
const CreateDate = TextSection;
const UpdateDate = TextSection;

const ViewButton = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  padding: theme.spacing(1, 2),
}));

//#endregion

/**
 * FIXME:
 * This icon is not yet approved and the design is likely to change so it resides here for now
 */
const ArrowIcon = () => (
  <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <path d="M18.333 10 15 6.667v2.5H2.5v1.667H15v2.5L18.333 10Z" fill="#666" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
