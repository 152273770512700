import { useQuery } from '@apollo/client';

import { isBioreactorDataset } from 'client/app/apps/experiments/dataset-settings/isBioreactorDataset';
import { QUERY_DATASET_DERIVATION } from 'client/app/apps/experiments/gql/queries';
import { DatasetDerivationQuery } from 'client/app/gql';

export default function hasBioreactorDataset(
  datasetDerivation: DatasetDerivationQuery['datasetDerivation'],
) {
  return datasetDerivation.inputs.some(dataset => isBioreactorDataset(dataset));
}

export function useHasBioreactorDataset(
  id: DatasetDerivationQuery['datasetDerivation']['id'],
) {
  const { data, loading } = useQuery(QUERY_DATASET_DERIVATION, {
    variables: { id: id },
  });

  if (loading) {
    return { loading, hasBioreactorDataset: false };
  }

  if (!data) {
    return { loading: false, hasBioreactorDataset: false };
  }
  return {
    loading: false,
    hasBioreactorDataset: hasBioreactorDataset(data.datasetDerivation),
  };
}
