import React, { useEffect } from 'react';

import { useMutation } from '@apollo/client';

import { MUTATION_CREATE_WORKFLOW_FROM_TEMPLATE } from 'client/app/api/gql/mutations';
import UIErrorBox from 'client/app/components/UIErrorBox';
import { workflowRoutes } from 'client/app/lib/nav/actions';
import { getErrorMessage } from 'common/lib/graphQLErrors';
import LinearProgress from 'common/ui/components/LinearProgress';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';

type Props = {
  templateWorkflowId: TemplateWorkflowId;
};
export default function TemplateWorkflowCreateForm({ templateWorkflowId }: Props) {
  const navigation = useNavigation();
  // When this mounts, we create a form workflow from a template and then redirect to the form editor
  const [createForm, { error }] = useMutation(MUTATION_CREATE_WORKFLOW_FROM_TEMPLATE, {
    variables: { templateWorkflowId },
  });
  useEffect(() => {
    let stale = false;
    (async () => {
      const formWorkflow = await createForm();
      const workflowId = formWorkflow.data?.createWorkflowFromTemplate?.workflow.id;
      if (workflowId && !stale) {
        navigation.navigate(
          workflowRoutes.editForm,
          {
            workflowId,
          },
          { method: 'replace' },
        );
      }
    })();
    return () => {
      stale = true;
    };
  }, [createForm, navigation]);
  return (
    <>{error ? <UIErrorBox>{getErrorMessage(error)}</UIErrorBox> : <LinearProgress />}</>
  );
}
