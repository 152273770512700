import { useCallback } from 'react';

import { useDownloadRemoteFileFromPath } from 'client/app/api/filetree';
import { LabwareFile } from 'client/app/apps/execution-details/types';
import { downloadPlateFile } from 'client/app/apps/simulation-details/overview/simulationDetailsFiles';

export default function useDownloadLabwareFile(simulationId: SimulationId) {
  const downloadRemoteFileFromPath = useDownloadRemoteFileFromPath();
  return useCallback(
    async (file: LabwareFile) => {
      if (file.deckPlate) {
        downloadPlateFile(
          simulationId,
          file.deckPlate.plate,
          file.deckPlate.plateFileName,
          'download-output-plate-file-from-card',
        );
      } else {
        await downloadRemoteFileFromPath(file.name);
      }
    },
    [downloadRemoteFileFromPath, simulationId],
  );
}
