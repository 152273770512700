import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import isUndefined from 'lodash/isUndefined';

import { useInputLiquids } from 'client/app/apps/workflow-builder/lib/useElementContext';
import AutocompleteWithParameterValues from 'client/app/components/Parameters/AutocompleteWithParameterValues';
import { LoadCalculationInputs } from 'client/app/components/Parameters/ChromatographyActions/LoadCalculationInputs';
import { ResidenceTimeInputs } from 'client/app/components/Parameters/ChromatographyActions/ResidenceTimeInputs';
import { getRobocolumnVolume } from 'client/app/components/Parameters/ChromatographyActions/roboColumnVolumeUtils';
import { WellParametersProps } from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import PolicyParameter from 'client/app/components/Parameters/Policy/PolicyParameter';
import { isDefined } from 'common/lib/data';
import { formatMeasurement, parseMeasurement } from 'common/lib/format';
import { isCompatible } from 'common/lib/units';
import { getFirstValue, mapValues } from 'common/object';
import { Liquid } from 'common/types/bundle';
import { Measurement } from 'common/types/mix';
import { BasicChromatographyAction, RoboColumnContent } from 'common/types/robocolumns';
import { GradientChromatographyAction } from 'common/types/robocolumns';
import FloatEditor from 'common/ui/components/ParameterEditors/FloatEditor';

/**
 * Antha type for looking up and suggesting liquid name's if we do not have
 * element outputs information from the relevant parameter
 */
const LIQUID_TYPE = 'github.com/Synthace/antha/stdlib/schemas/aliases.LiquidName';
/**
 * Element input parameter name that corresponds to liquids available for use in
 * gradient chromatography
 */
const GRADIENT_LIQUIDS_PARAMETER = 'AvailableBuffersToMix';
/**
 * Element input parameter name that corresponds to liquids available for use in
 * normal chromatography
 */
const NON_GRADIENT_LIQUIDS_PARAMETER = 'AvailableLiquidsToLoad';

type Props = {
  /**
   * RoboColumns that the user previously defined on the plate.
   */
  robocolumnsByWell?: Map<string, RoboColumnContent>;
  isGradient?: boolean;
} & WellParametersProps<BasicChromatographyAction>;

/**
 * Fields for configuring a group of identical chromatography actions within the
 * Chromatography Actions or Gradient Chromatography Actions dialog.
 */
export default function ChromatographyActionParameters({
  onChange,
  contentsByWell,
  robocolumnsByWell,
  isGradient,
  isDisabled,
}: Props) {
  // Contents of each well within the group will be identical, so we can
  // arbitrarily pick one.
  const contentsOfFirstWell = useMemo(
    () => getFirstValue(contentsByWell),
    [contentsByWell],
  );

  const [loadVolumesInCV, setLoadVolumesInCV] = useState<number[] | undefined>(
    contentsOfFirstWell?.LoadVolumes || [1],
  );
  const [residenceTime, setResidenceTime] = useState<Measurement | undefined>(
    contentsOfFirstWell?.ResidenceTime
      ? parseMeasurement(contentsOfFirstWell?.ResidenceTime)
      : { value: 10, unit: 's' },
  );
  const [aspLiquidPolicy, setAspLiquidPolicy] = useState<string | undefined>(
    contentsOfFirstWell?.AspirationLiquidPolicyOverride,
  );

  const { liquidNames, liquidConcentrations } = useLiquidNamesAndConcentrations(
    isGradient ? GRADIENT_LIQUIDS_PARAMETER : NON_GRADIENT_LIQUIDS_PARAMETER,
  );

  const liquidConcentration = getMassConcentration(
    contentsOfFirstWell?.LiquidToLoad,
    liquidConcentrations,
  );

  const robocolumns = useMemo<RoboColumnContent[]>(
    () =>
      [...contentsByWell.keys()]
        .map(wellLocation => robocolumnsByWell?.get(wellLocation))
        .filter(isDefined),
    [contentsByWell, robocolumnsByWell],
  );

  const robocolumnVolume = getRobocolumnVolume(robocolumns);

  const [liquidParams, setLiquidParams] = useState<BasicChromatographyAction | undefined>(
    contentsOfFirstWell,
  );
  const [isLiquidParamsValid, setIsLiquidParamsValid] = useState<boolean>(false);

  // When the states change, trigger onChange with new well contents
  useEffect(() => {
    const isValid =
      isLiquidParamsValid &&
      !!residenceTime &&
      loadVolumesInCV !== undefined &&
      loadVolumesInCV.length > 0;
    const newContentsByWell = mapValues(contentsByWell, () => ({
      ...liquidParams,
      LoadVolumes: loadVolumesInCV,
      ResidenceTime: residenceTime
        ? formatMeasurement(residenceTime.value, residenceTime.unit, false)
        : '',
      AspirationLiquidPolicyOverride: aspLiquidPolicy,
    }));
    onChange(newContentsByWell, isValid);
  }, [
    aspLiquidPolicy,
    contentsByWell,
    isLiquidParamsValid,
    liquidParams,
    loadVolumesInCV,
    onChange,
    residenceTime,
    robocolumnVolume,
  ]);

  const handleLiquidParamChange = useCallback(
    (action: BasicChromatographyAction, isValid: boolean) => {
      setLiquidParams(action);
      setIsLiquidParamsValid(isValid);
    },
    [],
  );

  const LiquidParametersComponent = isGradient
    ? GradientChromatographyLiquidParameters
    : ChromatographyLiquidParameters;

  return (
    <Stack spacing={3}>
      <LiquidParametersComponent
        action={contentsOfFirstWell}
        liquidNames={liquidNames}
        onChange={handleLiquidParamChange}
      />
      <StyledDivider />
      <ResidenceTimeInputs
        isDisabled={isDisabled}
        robocolumnVolume={robocolumnVolume}
        residenceTime={residenceTime}
        setResidenceTime={setResidenceTime}
      />
      <StyledDivider />
      <LoadCalculationInputs
        isDisabled={isDisabled}
        isGradient={isGradient}
        robocolumnVolume={robocolumnVolume}
        residenceTime={residenceTime}
        liquidConcentration={liquidConcentration}
        loadVolumesInCV={loadVolumesInCV}
        setLoadVolumesInCV={setLoadVolumesInCV}
      />
      <StyledDivider />
      <Typography variant="subtitle2">Optional Parameters</Typography>
      <Box>
        <InputLabel shrink>Aspiration Liquid Policy Override</InputLabel>
        <PolicyParameter value={aspLiquidPolicy || ''} onChange={setAspLiquidPolicy} />
      </Box>
    </Stack>
  );
}

type ChromatographyLiquidParametersProps = {
  action?: BasicChromatographyAction;
  /**
   * liquidNames we know the user must select from to be a valid input
   */
  liquidNames?: string[];
  onChange: (action: BasicChromatographyAction, isValid: boolean) => void;
};

/**
 * Parameters specific to defining the liquid to load in a chromatography
 * action. Used by ChromatographyActionParameters.
 */
function ChromatographyLiquidParameters({
  action,
  liquidNames,
  onChange,
}: ChromatographyLiquidParametersProps) {
  const handleChange = useCallback(
    (liquidToLoad?: string) =>
      onChange({ ...action, LiquidToLoad: liquidToLoad }, liquidToLoad !== undefined),
    [action, onChange],
  );
  return (
    <>
      <Typography variant="subtitle2">Liquid to Load</Typography>
      <Box>
        <AutocompleteWithParameterValues
          valueLabel={action?.LiquidToLoad}
          onChange={handleChange}
          additionalOptions={liquidNames}
          anthaType={liquidNames?.length ? '' : LIQUID_TYPE}
          acceptCustomValues={!liquidNames?.length}
          isRequired
        />
      </Box>
    </>
  );
}

type GradientChromatographyLiquidParametersProps = {
  action?: GradientChromatographyAction;
  /**
   * liquidNames we know the user must select from to be a valid input
   */
  liquidNames?: string[];
  onChange: (action: GradientChromatographyAction, isValid: boolean) => void;
};

/**
 * Parameters specific to defining the liquid to load in a gradient
 * chromatography action.
 */
function GradientChromatographyLiquidParameters({
  action,
  liquidNames,
  onChange,
}: GradientChromatographyLiquidParametersProps) {
  const handleChange = useCallback(
    (newAction: GradientChromatographyAction) => {
      const isValid = ![
        action?.BufferA,
        action?.BufferB,
        action?.BStartPercentage,
        action?.BEndPercentage,
      ].some(isUndefined);
      onChange(newAction, isValid);
    },
    [action, onChange],
  );
  const handleBufferAChange = useCallback(
    (bufferA?: string) => handleChange({ ...action, BufferA: bufferA }),
    [handleChange, action],
  );
  const handleBufferBChange = useCallback(
    (bufferB?: string) => handleChange({ ...action, BufferB: bufferB }),
    [handleChange, action],
  );
  const handleStartPercentChange = useCallback(
    (startPercent?: number | null) =>
      handleChange({ ...action, BStartPercentage: startPercent ?? undefined }),
    [handleChange, action],
  );
  const handleEndPercentChange = useCallback(
    (endPercent?: number | null) =>
      handleChange({ ...action, BEndPercentage: endPercent ?? undefined }),
    [handleChange, action],
  );

  return (
    <>
      <Typography variant="subtitle2">Gradient Preparation</Typography>
      <Box>
        <InputLabel shrink>Buffer A</InputLabel>
        <AutocompleteWithParameterValues
          valueLabel={action?.BufferA}
          onChange={handleBufferAChange}
          additionalOptions={liquidNames}
          anthaType={liquidNames?.length ? '' : LIQUID_TYPE}
          acceptCustomValues={!liquidNames?.length}
          isRequired
        />
      </Box>
      <Box>
        <InputLabel shrink>Buffer B</InputLabel>
        <AutocompleteWithParameterValues
          valueLabel={action?.BufferB}
          onChange={handleBufferBChange}
          additionalOptions={liquidNames}
          anthaType={liquidNames?.length ? '' : LIQUID_TYPE}
          acceptCustomValues={!liquidNames?.length}
          isRequired
        />
      </Box>
      <Box>
        <InputLabel shrink>Start Percentage of Buffer B</InputLabel>
        <FloatEditor
          type=""
          value={action?.BStartPercentage}
          onChange={handleStartPercentChange}
          isRequired
        />
      </Box>
      <Box>
        <InputLabel shrink>End Percentage of Buffer B</InputLabel>
        <FloatEditor
          type=""
          value={action?.BEndPercentage}
          onChange={handleEndPercentChange}
          isRequired
        />
      </Box>
    </>
  );
}

function useLiquidNamesAndConcentrations(inputParameter: string) {
  const { loading, inputLiquids } = useInputLiquids(inputParameter);

  return useMemo(() => {
    if (loading) {
      return {};
    }
    const liquids = inputLiquids[0];
    const names = liquids.map((liquid: Liquid) => liquid.name);
    const concentrations: { [name: string]: Measurement } = {};
    liquids.forEach(liquid => {
      const name = liquid.name;
      if (liquid.subComponents && name in liquid.subComponents) {
        concentrations[name] = liquid.subComponents[name];
      }
    });
    return { liquidNames: names, liquidConcentrations: concentrations };
  }, [inputLiquids, loading]);
}

function getMassConcentration(
  name?: string,
  concs: { [name: string]: Measurement } = {},
) {
  if (name && name in concs) {
    const conc = concs[name];
    if (isCompatible('g/l', conc.unit)) {
      return conc;
    }
  }
  return;
}

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3),
}));
