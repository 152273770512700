import React from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import { indexById, isDefined } from 'common/lib/data';
import { byName } from 'common/lib/strings';
import { ConfiguredDevice } from 'common/types/bundle';
import { getGenericDeviceTypeFromAnthaClass } from 'common/types/bundleConfigUtils';
import { Device, DeviceRunConfig, SimpleDevice } from 'common/types/device';
import Colors from 'common/ui/Colors';
import ConfigSelector from 'common/ui/components/ConfigSelector';
import DeviceListItem from 'common/ui/components/DeviceListItem';
import IconButton from 'common/ui/components/IconButton';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const MISSING_RUN_CONFIG_MSG = 'Device run configuration has been deleted.';

type Props = {
  devices: Device[];
  configuredDevices: ConfiguredDevice[];
  /**
   * If true, show interactive elements, for example toggles to enable / disable
   * accessible devices.
   * Otherwise this is a simple, purely read-only list of devices.
   */
  isEditable?: boolean;
  onAccessibleDeviceEnabledChange?: (
    accessibleDevice: SimpleDevice,
    isEnabled: boolean,
  ) => void;
  onRemoveDevice?: (deviceToRemove: SimpleDevice) => void;
  onSelectConfig?: (deviceId: string) => (value?: DeviceRunConfig) => void;
};

export default React.memo(function DeviceList(props: Props) {
  const classes = useStyles();
  const {
    devices,
    configuredDevices,
    isEditable,
    onAccessibleDeviceEnabledChange,
    onRemoveDevice,
    onSelectConfig,
  } = props;
  const { deviceGroups, standaloneDevices } = groupDevices(
    configuredDevices,
    devices,
    isEditable ?? false,
  );

  const ConfigSelectorContainer = (device: Device, selectedRunConfigId?: string) => {
    if (!onSelectConfig || (device.runConfigs && device.runConfigs.length <= 1)) {
      return null;
    }

    return (
      <div className={classes.dropdownContainer}>
        <ConfigSelector
          device={device}
          selectedConfigs={{ [device.id]: selectedRunConfigId }}
          handleSelectConfig={onSelectConfig}
        />
      </div>
    );
  };

  return (
    <List className={classes.deviceList}>
      {/* List groups with accessible devices first, as Cards. */}
      {deviceGroups.map(({ mainDevice, accessibleDevicesForMainDevice }) => {
        const { selectedRunConfig, isMissingRunConfig } = getSelectedRunConfig(
          mainDevice,
          configuredDevices,
        );
        return (
          <div key={mainDevice.id} className={classes.deviceCard}>
            {isEditable && onRemoveDevice && (
              <CardHeader
                className={classes.cardHeader}
                action={
                  <IconButton
                    onClick={() => onRemoveDevice(mainDevice)}
                    size="xsmall"
                    icon={<ClearIcon />}
                  />
                }
              />
            )}
            <DeviceListItem
              device={mainDevice}
              deviceConfig={selectedRunConfig}
              error={isMissingRunConfig ? MISSING_RUN_CONFIG_MSG : undefined}
            />
            {ConfigSelectorContainer(mainDevice, selectedRunConfig?.id)}
            <Typography variant="overline" className={classes.accessibleDevicesLabel}>
              Accessible devices
            </Typography>
            {accessibleDevicesForMainDevice.map(({ accessibleDevice, isEnabled }) => {
              const accessibleDeviceProps =
                isEditable && onAccessibleDeviceEnabledChange
                  ? // If the DeviceList is editable, show a toggle for enabling / disabling
                    // each accessible device
                    {
                      accessibleDeviceSwitchValue: isEnabled,
                      onAccessibleDeviceEnabledChange: (newIsEnabled: boolean) =>
                        onAccessibleDeviceEnabledChange(accessibleDevice, newIsEnabled),
                    }
                  : // In the non-editable case, don't show toggles for accessible devices.
                    undefined;
              return (
                <DeviceListItem
                  key={accessibleDevice.id}
                  device={accessibleDevice}
                  accessibleDeviceProps={accessibleDeviceProps}
                />
              );
            })}
          </div>
        );
      })}
      {/* Then, list all standalone devices as a flat list, no grouping. */}
      {standaloneDevices.map(standaloneDevice => {
        const { selectedRunConfig, isMissingRunConfig } = getSelectedRunConfig(
          standaloneDevice,
          configuredDevices,
        );
        return (
          <div key={standaloneDevice.id} className={classes.deviceCard}>
            <DeviceListItem
              key={standaloneDevice.id}
              device={standaloneDevice}
              deviceConfig={selectedRunConfig}
              onRemoveDevice={isEditable ? onRemoveDevice : undefined}
              error={isMissingRunConfig ? MISSING_RUN_CONFIG_MSG : undefined}
            />
            {ConfigSelectorContainer(standaloneDevice, selectedRunConfig?.id)}
          </div>
        );
      })}
    </List>
  );
});

export type AccessibleDeviceForUI = {
  accessibleDevice: SimpleDevice;
  isEnabled: boolean;
  canBeEnabled: boolean;
};

export type DeviceGroup = {
  mainDevice: Device;
  accessibleDevicesForMainDevice: AccessibleDeviceForUI[];
};

/**
 * Given the ConfiguredDevices, makes groups of devices for display in the UI.
 *
 * Each group of devices consists of:
 * - The top level device (e.g. a liquid handler)
 * - Accessible devices shown below (e.g. a plate washer and plate reader)
 * Each standalone device is a simply any device that's not part of a group.
 */
export function groupDevices(
  configuredDevices: ConfiguredDevice[],
  devices: Device[],
  /**
   * If the list is editable, we must include accessible devices that are not part
   * of the workflow config. This is so that the UI shows toggles for those.
   */
  isEditable: boolean,
): { deviceGroups: DeviceGroup[]; standaloneDevices: Device[] } {
  // This is what's returned from the function
  const deviceGroups: DeviceGroup[] = [];

  const devicesById = indexById(devices);
  const devicesByConfigId = configuredDevices.reduce((acc, cd) => {
    acc[cd.id] = devicesById[cd.deviceId];
    return acc;
  }, {} as Record<string, Device>);

  // Sort devices by name in the UI
  const devicesSortedByName = devices.sort(byName);
  const accessibleDeviceIds = new Set(
    configuredDevices
      .flatMap(conf => conf.accessibleDeviceConfigurationIds ?? [])
      // careful here since the accessible device may have been deleted
      // from the db and thus not present in 'devices'
      .map(configId => devicesByConfigId[configId]?.id)
      .filter(isDefined),
  );

  const standaloneDevices: Device[] = devicesSortedByName.filter(
    device =>
      device.accessibleDevices.length === 0 && !accessibleDeviceIds.has(device.id),
  );

  const standaloneDeviceTypes = new Set(
    standaloneDevices.map(device =>
      getGenericDeviceTypeFromAnthaClass(device.anthaLangDeviceClass),
    ),
  );

  devicesSortedByName
    // Loop through the top-level devices (not accessible or standalone devices)
    .filter(
      device =>
        !(
          accessibleDeviceIds.has(device.id) ||
          standaloneDevices.some(d => d.id === device.id)
        ),
    )
    .forEach(device => {
      // These accessible devices were part of the workflow configuration.
      const enabledAccessibleDevices =
        configuredDevices
          .find(cd => cd.deviceId === device.id)
          ?.accessibleDeviceConfigurationIds?.map(configId => devicesByConfigId[configId])
          // The check for isDefined here is important - the parent might reference
          // accessible devices that were deleted and are therefore not present in
          // devicesById.
          .filter(isDefined) ?? [];

      // These extra accessible devices are available on the device but
      // were not enabled in the workflow configuration.
      const remainingAccessibleDevices = device.accessibleDevices.filter(
        accessibleDevice => !accessibleDeviceIds.has(accessibleDevice.id),
      );

      const accessibleDevicesForMainDevice: AccessibleDeviceForUI[] =
        enabledAccessibleDevices.map(accessibleDevice => ({
          accessibleDevice,
          // All of these were enabled
          isEnabled: true,
          canBeEnabled: true,
        }));

      if (remainingAccessibleDevices?.length > 0 && isEditable) {
        // Only include these extra devices in the UI if we allow editing,
        // in other words enabling accessible devices using toggles.
        accessibleDevicesForMainDevice.push(
          ...remainingAccessibleDevices.map(accessibleDevice => ({
            accessibleDevice,
            // All of these extra available devices are shown as not enabled
            isEnabled: false,
            canBeEnabled: !standaloneDeviceTypes.has(
              getGenericDeviceTypeFromAnthaClass(accessibleDevice.anthaLangDeviceClass),
            ),
          })),
        );
      }

      // If there are accessible devices for this device, show a group:
      if (accessibleDevicesForMainDevice.length > 0) {
        // Sort accessible devices within the group by name
        accessibleDevicesForMainDevice.sort((a, b) =>
          a.accessibleDevice.name.localeCompare(b.accessibleDevice.name),
        );
        deviceGroups.push({
          mainDevice: device,
          accessibleDevicesForMainDevice,
        });
      } else {
        standaloneDevices.push(device);
      }
    });
  return { deviceGroups, standaloneDevices };
}

function getSelectedRunConfig(device: Device, configuredDevices: ConfiguredDevice[]) {
  const cd = configuredDevices.find(cd => cd.deviceId === device.id);
  const selectedRunConfig = cd?.runConfigId
    ? device.runConfigs?.find(runConfig => runConfig.id === cd.runConfigId)
    : undefined;
  const isMissingRunConfig = cd?.runConfigId && !selectedRunConfig;
  return { selectedRunConfig, isMissingRunConfig };
}

const useStyles = makeStylesHook(theme => ({
  cardHeader: {
    padding: 0,
    margin: theme.spacing(5, 5, 0, 0),
  },
  dropdownContainer: {
    border: `1px solid ${Colors.GREY_30}`,
    margin: theme.spacing(0, 5, 5, 5),
  },
  accessibleDevicesLabel: {
    padding: theme.spacing(0, 5),
    textTransform: 'uppercase',
  },
  deviceCard: {
    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '4px',
    padding: theme.spacing(3),
  },
  deviceList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    width: '100%',
  },
}));
