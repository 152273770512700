import React from 'react';

import InputLabel from '@mui/material/InputLabel';

import { indexBy } from 'common/lib/data';
import PlateCarriers from 'common/types/plateCarriers';
import Dropdown from 'common/ui/filaments/Dropdown';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const CARRIER_OPTIONS = PlateCarriers.map(({ name, id }) => ({
  label: name,
  value: id,
}));

const CARRIER_ID_MAP = indexBy(PlateCarriers, 'id');
export default function CarrierSelect(props: any) {
  const classes = useStyles();
  const selectedValueLabel = CARRIER_ID_MAP[props.value].name ?? '';
  return (
    <>
      <InputLabel className={classes.label}>Carrier</InputLabel>
      <Dropdown
        valueLabel={selectedValueLabel}
        onChange={props.onChange}
        options={CARRIER_OPTIONS}
        isDisabled={props.isDisabled}
      />
    </>
  );
}

const useStyles = makeStylesHook({
  label: { fontSize: '12px' },
});
