import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const TraceIcon = React.memo<SvgIconProps>(function TraceIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" fill="none">
        <path d="M14 6h8v2h-8V6zM2 11h20v2H2v-2zM2 16h10v2H2v-2z" fill="currentColor" />
      </svg>
    </SvgIcon>
  );
});
