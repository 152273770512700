import React, { useEffect, useState } from 'react';

import Big from 'big.js';

export function validateAndConvertToDecimal(val: string | number): Big | null {
  try {
    const result = new Big(val);

    if (result.gte(0)) {
      return result;
    }
    return null;
  } catch (_) {
    return null;
  }
}

export default function useHasUserInteracted(
  inputElement: React.RefObject<HTMLInputElement>,
) {
  const [hasUserInteracted, setHasUserInteracted] = useState(false);

  useEffect(() => {
    const input = inputElement.current;
    function onBlur() {
      setHasUserInteracted(true);
    }
    if (input) {
      input.addEventListener('blur', onBlur);
    }

    return () => {
      if (input) {
        input.removeEventListener('blur', onBlur);
      }
    };
  }, [inputElement]);

  return hasUserInteracted;
}
