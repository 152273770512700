/**
 * Version in MAJOR.MINOR format, e.g: "2.51".
 * Versions are comparable: 1.5 == 1.5 < 1.15 < 2.0 < 2.2 < 3.1
 */
export type SchemaVersion = {
  Major: number;
  Minor: number;
};

export type SchemaVersionValidator = {
  Valid: (v: SchemaVersion) => boolean;
  SupportedVersions: string;
};

/**
 * newVersionFromString parses the version string.
 * @param str the string to be parsed.
 * @returns The schema version.
 * @throws an error if the string is incorrectly formatted
 */
function newSchemaVersionFromString(str: string): SchemaVersion {
  const strs = str.split('.');
  if (strs.length !== 1 && strs.length !== 2) {
    throw new Error(`Unknown version format "${str}"`);
  }
  const major = parseInt(strs[0], 10);
  if (Number.isNaN(major)) {
    throw new Error(`Unknown version format "${str}"`);
  }
  const minor = parseInt(strs[1], 10);
  if (Number.isNaN(minor)) {
    throw new Error(`Unknown version format "${str}"`);
  }
  return { Major: major, Minor: minor };
}

type AtLeastOneParameter<T> = [T, ...T[]];

class ValidatorBuilder {
  Major: number;

  constructor(major: number) {
    this.Major = major;
  }

  /**
   * Minor sets explicit minor versions which must be present along side the major version
   * @param minors the minor versions to validate against.
   * @returns A @see SchemaVersionValidator for use with @see VerifySchemaVersion or @see VerifySchemaVersionMatrix
   */
  Minor(...versions: AtLeastOneParameter<number>): SchemaVersionValidator {
    const SupportedVersions = versions.map(minor => `${this.Major}.${minor}`);
    return {
      Valid: (v: SchemaVersion) => {
        return v.Major === this.Major && versions.includes(v.Minor);
      },
      SupportedVersions: SupportedVersions.join(', '),
    };
  }

  /**
   * AnyMinor will be successful for any version which has the correct major version
   * @returns A @see SchemaVersionValidator for use with @see VerifySchemaVersion or @see VerifySchemaVersionMatrix
   */
  AnyMinor(): SchemaVersionValidator {
    return {
      Valid: (v: SchemaVersion) => {
        return v.Major === this.Major;
      },
      SupportedVersions: `${this.Major}.X`,
    };
  }

  /**
   * MinorMinimum sets the minimum minor version number which is considered valid
   * @param smallestMinor the smallest valid minor version, any minor version larger than this is also valid
   * @returns A @see SchemaVersionValidator for use with @see VerifySchemaVersion or @see VerifySchemaVersionMatrix
   */
  MinorMinimum(smallestMinor: number): SchemaVersionValidator {
    return {
      Valid: (v: SchemaVersion) => {
        return v.Major === this.Major && v.Minor >= smallestMinor;
      },
      SupportedVersions: `${this.Major}.${smallestMinor}+`,
    };
  }
}

/**
 * Major constructs a @see SchemaVersionValidator
 * @param major the major version to be checked against, this is a strict equality test.
 * @returns a partially constructed object, finalize it by calling a minor version builder.
 */
export function Major(major: number): ValidatorBuilder {
  return new ValidatorBuilder(major);
}

/**
 * VerifyVersion will take a version string and a list of @see SchemaVersionValidator which will check against the given input
 * string, if one validator returns true then the version is considered valid.
 * @param version a string containing the version to check
 * @param VersionValidators a list of @see SchemaVersionValidator to check against the input string
 * @returns the found valid version
 * @throws an error is no valid version was found
 */
export function VerifySchemaVersion(
  version: string,
  ...VersionValidators: SchemaVersionValidator[]
): SchemaVersion {
  const toCheck = newSchemaVersionFromString(version);
  for (const validator of VersionValidators) {
    if (validator.Valid(toCheck)) {
      return toCheck;
    }
  }
  const versions = VersionValidators.map(vv => vv.SupportedVersions);
  throw new Error(
    `The version "${version}" isn't supported. Supported versions: [${versions.join(
      ', ',
    )}]`,
  );
}

/**
 * VerifyVersionMatrix will iterate over a map of keyed versions, it will then verify that a valid combination of @see SchemaVersionValidator
 * has at least one match for the input versions. This allows for multiple versions to be tied together
 * so that only certain combinations are valid. E.g.
 * ``` ts
 * VerifySchemaVersionMatrix(
 *   new Map<string, string>([
 *     ["actions", "1.1"],
 *     ["layout", "1.0"],
 *   ]),
 *   new Map<string, SchemaVersionValidator>([
 *      ["actions", Major(1).Minor(0, 1)],
 *      ["layout", Major(1).Minor(0, 1)],
 *   ]),
 *   new Map<string, SchemaVersionValidator>([
 *     ["actions", Major(2).MinorMinimum(0)],
 *     ["layout", Major(1).MinorMinimum(1)],
 *   ])
 * )
 * ```
 * This code snippet shows that the versions in the keys `"actions"` and `"layout"` are `"1.1"` and `"1.0"`, which will
 * be accepted because the validators used allow that combination of major and minor versions. If the version `"2.1"`
 * and `"1.0"` were passed instead this would be invalid.
 * @param keyedVersions the input versions to be checked
 * @param VersionMatrix the validators to be run against the input
 * @returns the found valid versions for each key
 * @throws an error is no valid version was found
 */
export function VerifySchemaVersionMatrix(
  keyedVersions: Map<string, string>,
  ...VersionMatrix: Map<string, SchemaVersionValidator>[]
): Map<string, SchemaVersion> {
  // first convert the input to solid versions, returning early if the input is malformed
  const versions = new Map<string, SchemaVersion>();
  for (const [key, value] of keyedVersions) {
    const v = newSchemaVersionFromString(value);
    versions.set(key, v);
  }

  // now to do the actual check, iterate over the matrix of valid version checkers
  for (const validatorMap of VersionMatrix) {
    let valid = true;
    // now iterate over the input, for each key and version we find, get the matching validator using the input key and
    // check it against the validator found. If all the validators for this given `i` are valid then we can return here
    // as we found a valid version combination. Else we continue to try the next set of validators.
    for (const [key, version] of versions) {
      const verifyer = validatorMap.get(key);
      if (verifyer === undefined) {
        throw new Error(`There was no matching validator for key "${key}"`);
      }
      valid &&= verifyer.Valid(version);
    }
    if (valid) {
      return versions;
    }
  }

  // We have failed at this point, create a nice error message for why this version didn't match.
  const supported = VersionMatrix.map(vvp => {
    let ret = '[';
    for (const [key, v] of vvp) {
      ret += `"${key}" => ${v.SupportedVersions} `;
    }
    ret += ']';
    return ret;
  });
  let input = '[';
  for (const [key, v] of keyedVersions) {
    input += `"${key}" => ${v} `;
  }
  input += ']';
  throw new Error(
    `The versions "${input}" isn't supported. Supported versions: ${supported.join(
      ', ',
    )}`,
  );
}
