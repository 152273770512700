import { Location } from 'history';

import { ScreenID } from 'common/ui/navigation';

/**
 * Extracts screenID from Location object. This is hacky, but at least the
 * hack is contained, well defined and tested.
 *
 * We have convention that screenID is the first word in URL after '#/' and
 * before next "/". For example for URL '/#/workflow/config' the screenID is
 * 'workflow'. Note that location.pathname never contains the '/#' prefix.
 */
export function parseScreenID(location: Location<any>): ScreenID {
  const locationMatches = location.pathname.match(/^\/([^/]+)(.*)/i);
  return locationMatches ? (locationMatches[1] as ScreenID) : null;
}
