import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

function CustomPolicyIcon(
  props: React.PropsWithChildren<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>>,
) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="m11.763 10.92-2.902-.774-1.505 2.982-.71-.126-.282-2.207L1.231 16 0 14.77l5.191-5.122-2.19-.28-.1-.703L5.899 7.12l-.822-2.879.514-.514 2.422 2.423 3.162-1.85.541.54-1.851 3.16 2.41 2.41-.511.51ZM13.949 0h-2.872v1.641h2.872c.225 0 .41.185.41.41v2.872H16V2.051C16 .92 15.08 0 13.949 0Zm.41 13.539c0 .225-.185.41-.41.41h-2.872v1.64h2.872c1.131 0 2.051-.92 2.051-2.05v-2.872h-1.641v2.872ZM2.05 2.05c0-.225.184-.41.41-.41h2.872V0H2.461C1.33 0 .41.92.41 2.051v2.872h1.64V2.051Z"
        fill="url(#custom_policy_gradient)"
      />
      <defs>
        <linearGradient
          id="custom_policy_gradient"
          x1="8"
          y1="0"
          x2="8"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8C99FF" />
          <stop offset="1" stopColor="#E0A5FF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}

export default React.memo(CustomPolicyIcon);
