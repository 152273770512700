import React, { useCallback } from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import {
  useGetSelectedMainDevice,
  useParseDeviceRunConfig,
} from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import { getEditorInfoForDeviceAdvancedOption } from 'client/app/apps/workflow-builder/panels/workflow-settings/deviceAdvancedOptionsUtils';
import SettingsPanelContainer from 'client/app/apps/workflow-builder/panels/workflow-settings/SettingsPanelContainer';
import ParameterEditor from 'client/app/components/Parameters/ParameterEditor';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { sanitiseParameterValue } from 'common/elementConfiguration/parameterUtils';
import doNothing from 'common/lib/doNothing';
import { ParameterValue, ParameterValueDict } from 'common/types/bundle';
import { DeviceAdvancedOption } from 'common/types/device';
import IconButtonWithPopper from 'common/ui/components/IconButtonWithPopper';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  onChange: (optionName: string, value: ParameterValue) => void;
  parameterValueDict: ParameterValueDict;
  isDisabled: boolean;
};

export default React.memo(function DeviceAdvancedOptionsList(props: Props) {
  const { parameterValueDict, onChange, isDisabled } = props;
  const classes = useStyles();
  const { selectedDevice } = useGetSelectedMainDevice();

  const selectedRunConfigId = selectedDevice?.runConfigId;
  const { loading: loadingRunConfig, data: runConfigData } = useParseDeviceRunConfig(
    selectedDevice?.deviceId ?? '',
    selectedRunConfigId,
  );
  const options = runConfigData?.parsedRunConfig.config.options ?? [];

  if (loadingRunConfig) {
    return <CircularProgress />;
  }

  if (!selectedDevice) {
    return null;
  }

  return (
    <>
      <Divider className={classes.divider} />
      <SettingsPanelContainer
        title="Device-specific Options"
        helpContent="Options for the devices that you have selected."
        additionalHelpLinkUrl="https://intercom.help/antha/en/articles/5608976-advanced-settings"
      >
        {options.length === 0 && (
          <Typography>No device specific options found. </Typography>
        )}
        {options.map((option: DeviceAdvancedOption) => {
          if (!option.isVisible) {
            return null;
          }
          const value = parameterValueDict[option.name];
          return (
            <DeviceAdvancedOptionListItem
              key={option.name}
              isDisabled={isDisabled}
              onChange={onChange}
              option={option}
              value={value}
            />
          );
        })}
      </SettingsPanelContainer>
    </>
  );
});

type DeviceAdvancedOptionListItemProps = {
  option: DeviceAdvancedOption;
  onChange: (optionName: string, value: ParameterValue) => void;
  value: ParameterValue;
  isDisabled: boolean;
};

const DeviceAdvancedOptionListItem = React.memo(function DeviceAdvancedOptionListItem(
  props: DeviceAdvancedOptionListItemProps,
) {
  const classes = useStyles();
  const { option, onChange, value, isDisabled } = props;

  const { editorType, editorProps, getOriginalValue } =
    getEditorInfoForDeviceAdvancedOption(option);

  const sanitisedOnChange = useCallback(
    (value: ParameterValue) => {
      const originalValue = getOriginalValue ? getOriginalValue(value) : value;
      onChange(option.name, sanitiseParameterValue(originalValue));
    },
    [getOriginalValue, onChange, option.name],
  );

  // For most ParameterEditors we would want to display them on their own
  // row, below the option header, but for Toggle, we want to display these
  // inline, with the help icon at the end.
  const isToggleEditor = editorType === EditorType.TOGGLE;

  return (
    <div
      className={cx(classes.option, {
        [classes.optionFlexWrap]: !isToggleEditor,
      })}
    >
      <Typography className={classes.optionHeader} variant="subtitle2">
        {option.displayName}
      </Typography>
      <IconButtonWithPopper
        content={<Typography variant="caption">{option.description}</Typography>}
        iconButtonProps={{ size: 'xsmall', icon: <HelpOutlineIcon /> }}
        onClick={doNothing} //TODO: Update with logging
      />
      <div
        className={cx({
          [classes.parameterEditor]: !isToggleEditor,
        })}
      >
        <ParameterEditor
          anthaType={option.type}
          value={value}
          onChange={sanitisedOnChange}
          editorType={editorType}
          editorProps={editorProps}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
});

const useStyles = makeStylesHook(theme => ({
  optionFlexWrap: {
    flexWrap: 'wrap',
  },
  option: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  optionHeader: {
    marginRight: 'auto',
  },
  parameterEditor: {
    width: '100%',
  },
  divider: {
    margin: theme.spacing(4, 0, 6, 0),
  },
}));
