import React, { useCallback } from 'react';

import HelpIcon from '@mui/icons-material/HelpOutline';

import ElementDetailsDialog from 'client/app/components/ElementPlumber/ElementDetailsDialog';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

type Props = {
  /** Parameter name (original, not changed by configuration). */
  name: string;
  elementId: string;
  output?: string;
};

/**
 * A help icon that shows a dialog with the given name of the parameter
 * highlighted in the list of parameters.
 */
export default function ElementParameterHelpIcon(props: Props) {
  const classes = useStyles();
  const [infoDialog, openInfoDialog] = useDialog(ElementDetailsDialog);

  const handleInfoIconClick = useCallback(
    () =>
      openInfoDialog({
        elementId: props.elementId,
        selectedParameterName: props.name,
        selectedOutputName: props.output,
      }),
    [openInfoDialog, props.elementId, props.name, props.output],
  );

  return (
    <>
      <IconButton
        size="xsmall"
        onClick={handleInfoIconClick}
        icon={<HelpIcon className={classes.icon} />}
      />
      {infoDialog}
    </>
  );
}

const useStyles = makeStylesHook({
  icon: {
    color: Colors.TEXT_PRIMARY,
  },
});
