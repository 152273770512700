// Workflow Builder. Config properties do not come from the element instances
// (i.e. they're not Parameters)  but are instead standard across all
// workflows. For historical reasons, they're hard-coded.  I'm somewhat
// confident that these properties should live somewhere in a Go struct
// definition and be handed down to the client so that there is a single source
// of truth. We're a long way off from that though.

import { ArrayAdditionalProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { Markdown } from 'common/lib/markdown';
import { Parameter } from 'common/types/bundle';

export const INPUTPLATES: Parameter = {
  description:
    'Depending on your workflow, Synthace might automatically define the input plates needed. ' +
    'Here you can specify the types of plates you would like to use.',
  name: 'inputPlateTypes',
  type: '[]github.com/Synthace/antha/antha/anthalib/wtype.PlateTypeName',
  configuration: {
    displayName: 'Input Plate Types',
    displayDescription: '' as Markdown,
    editor: {
      type: EditorType.ARRAY,
      placeholder: '',
      additionalProps: {
        overrideAddNewItemCopy: 'New plate type',
        itemEditor: {
          type: EditorType.PLATE_TYPE,
          additionalProps: {
            editor: EditorType.PLATE_TYPE,
            isWorkflowSettingsPanel: true,
          },
        },
      } as ArrayAdditionalProps,
    },
    isVisible: true,
  },
};

export const TIPTYPES: Parameter = {
  description: 'Types of tips you would like to use.',
  name: 'tipTypes',
  type: '[]github.com/Synthace/antha/antha/anthalib/wtype.LHTip',
  configuration: {
    displayName: 'Tip Types',
    displayDescription: '' as Markdown,
    editor: {
      type: EditorType.ARRAY,
      placeholder: '',
      additionalProps: {
        overrideAddNewItemCopy: 'New tip type',
      } as ArrayAdditionalProps,
    },
    isVisible: true,
  },
};

const ADVANCED: Parameter[] = [
  {
    description:
      'If enabled, at most one tip box for each tip type needed will be placed on the deck. If ' +
      'more tip boxes are needed the liquid handler will stop and prompt the user to change the ' +
      'tip box.',
    name: 'useDriverTipTracking',
    type: 'bool',
  },
  {
    description:
      'If enabled, Synthace will try to fill all empty deck positions with relevant tip boxes, thus minimizing ' +
      'the number of manual tip box changes. If disabled, Synthace will only add one tip box for each type of used tips. ' +
      'It can only be enabled if useDriverTipTracking is enabled.',
    name: 'useTipboxAutofill',
    type: 'bool',
  },
  {
    description: 'List of deck positions. Advanced setting.',
    name: 'driverSpecificInputPreferences',
    type: '[]string',
  },
  {
    description: 'List of deck positions. Advanced setting.',
    name: 'driverSpecificOutputPreferences',
    type: '[]string',
  },
  {
    description:
      'List of temporary deck positions to use for moving plates from Hotels. Advanced setting.',
    name: 'driverSpecificTemporaryLocations',
    type: '[]string',
  },
  {
    description:
      'Override the location preferences for specific plates whose names match the prefixes given by the keys of the map. ' +
      'Matching is case sensitive. If a plate name matches two keys then the longest prefix is selected. ' +
      'For example given: ' +
      '  {"auto": ["position_1"], "auto_input": ["position_2"]} ' +
      'then a plate called "auto_input_plate_1" will be assigned to "position_2", ' +
      'while a plate called "automatic" will be assigned to "position_1"',
    name: 'driverSpecificPlatePreferences',
    type: 'map[string][]string',
  },
  {
    description:
      'Ignore the output of physical simulation if a workflow is known to be safe.',
    name: 'ignorePhysicalSimulation',
    type: 'bool',
  },
  {
    description:
      'Version of the input auto-allocation algorithm to use during simulation.',
    name: 'allocateInputsVersion',
    type: 'int',
  },
  {
    description: 'List of deck positions. Advanced setting.',
    name: 'driverSpecificTipPreferences',
    type: '[]string',
  },
  {
    description: 'List of deck positions. Advanced setting.',
    name: 'driverSpecificTipWastePreferences',
    type: '[]string',
  },
];

export const ALL_CONFIG_PROPERTIES: Parameter[] = [INPUTPLATES, TIPTYPES, ...ADVANCED];

// For v1, we only need tipTypes and driverTipTracking.
export const CHERRYPICK_CONFIG = [
  {
    description:
      'If enabled, at most one tip box for each tip type needed will be placed on the deck. If ' +
      'more tip boxes are needed the liquid handler will stop and prompt the user to change the ' +
      'tip box.',
    name: 'useDriverTipTracking',
    type: 'bool',
  },
];
