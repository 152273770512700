import { getEnvName } from 'common/ui/lib/envs';

const validGAEnvs = ['localhost', 'dev', 'staging', 'anthahub', 'production', 'unknown'];

/**
 * Returns the appropriate env nickname to log in GoogleAnalytics.
 * @param location a value of type 'Location'
 * @returns the appropriate env nickname to log in GoogleAnalytics
 */
export default function getGATrackingEnvironment(location: Location | undefined) {
  const name = getEnvName(location);
  if (validGAEnvs.includes(name)) {
    return name;
  }
  return 'production';
}
