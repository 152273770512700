import getHighLevelCssClassName from 'client/app/lib/antha-lang/parameter-type-map';

const SOMETHING_WENT_WRONG = 'something-went-wrong';

// Pattern to extract type name from Antha system type string.
const PARAMETER_TYPE_PATTERN = /((map)?(\[.*?\]))*\*?.*?([^/]*)$/;

function getClassNameForType(typeName: string) {
  // New_API: This is super nasty. We should not be running magical
  // regexes against complex strings from the backend. This is super
  // brittle and obscure. I'm carrying it forward here because I don't
  // want to change too many things at once in rebuilding the Element
  // Plumber. At some point we should move this to the backend and
  // just return a sane type string to the client.
  const matchyBit = (typeName.match(PARAMETER_TYPE_PATTERN) || [])[4];
  if (!matchyBit) {
    console.log('No known class name for ', typeName);
    return null;
  }

  const highLevelTypeName = getHighLevelCssClassName(matchyBit);
  if (highLevelTypeName === undefined) {
    return SOMETHING_WENT_WRONG;
  }

  return highLevelTypeName;
}

export default getClassNameForType;
