import React, { useCallback, useEffect, useState } from 'react';

import { Location } from 'history';
import { Prompt } from 'react-router-dom';

import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { route } from 'common/ui/navigation';

type Props = {
  /**
   * When true, block navigation.
   */
  when: boolean;
  /**
   * Used if we need to block the navigation only for certain URLs.
   * E.g. Going from Builder editor tab to the config tab triggers
   * a URL change but should not block navigation.
   */
  shouldBlockNavigation?: (location: Location) => boolean;
};

/**
 * Intercept when users navigate to a different route while
 * an operation we don't want to interrupt (e.g. saving) is
 * being performed, and wait for it to complete before navigating away.
 */
const RouteLeavingGuard = ({ when, shouldBlockNavigation = () => true }: Props) => {
  const navigation = useNavigation();
  const [lastLocation, setLastLocation] = useState<Location | null>(null);

  useEffect(() => {
    if (!when && lastLocation) {
      navigation.navigate(route(lastLocation.pathname), undefined);
      setLastLocation(null);
    }
  }, [lastLocation, navigation, when]);

  /**
   * - If we return a string to the `message` prop, an `alert()` will
   *   be shown. We never want this.
   * - If you return `true`, we allow the navigation to the next page.
   * - If we return `false`, we block the navigation and *not* show the
   *   `alert()`.
   */
  const handleBlockedNavigation = useCallback(
    (nextLocation: Location): boolean => {
      if (when && shouldBlockNavigation(nextLocation)) {
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [shouldBlockNavigation, when],
  );

  return <Prompt when={when} message={handleBlockedNavigation} />;
};
export default RouteLeavingGuard;
