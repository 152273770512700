import React, { useContext } from 'react';

import {
  CreateBlockProps,
  useCreateExperimentBlock,
} from 'client/app/apps/experiments/useCreateExperimentBlock';
import { useWaitForWorkTreeNode } from 'client/app/apps/work-tree/useWaitForWorkTreeNode';
import { PositionedWorkTree } from 'client/app/apps/work-tree/useWorkTreeLayout';
import { WorkTreeModeContext } from 'client/app/apps/work-tree/WorkTreeModeContext';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import {
  ExperimentBlockType as BlockType,
  WorkTreeApplicationName,
} from 'client/app/gql';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import { DataSetIcon } from 'common/ui/icons/DataSetIcon';
import { ExecutionIcon } from 'common/ui/icons/Execution';
import { SimulationIcon } from 'common/ui/icons/SimulationIcon';
import { WorkflowIcon } from 'common/ui/icons/Workflow';

type ImportEntitiesMenuProps = {
  experimentId: ExperimentId;
  refetchWorkTree: () => void;
  workTree: PositionedWorkTree;
  onHidePopover: () => void;
};

export default React.forwardRef(function ExistingEntitiesMenuItems(
  props: ImportEntitiesMenuProps,
  ref: React.Ref<HTMLLIElement>,
) {
  const { experimentId, refetchWorkTree, workTree, onHidePopover } = props;
  const context = useContext(WorkTreeModeContext);

  const { waitForNodeWithResultId } = useWaitForWorkTreeNode(workTree);

  const {
    createBlock,
    simulationsDialog,
    executionsDialog,
    workflowsDialog,
    datasetDialog,
  } = useCreateExperimentBlock(experimentId);

  const simulationIdsInWorkTree: SimulationId[] = [];
  const workflowIdsInWorkTree: WorkflowId[] = [];
  const executionIdsInWorkTree: ExecutionId[] = [];
  const datasetIdsInWorkTree: DatasetId[] = [];

  workTree.nodes.forEach(node => {
    switch (node.data.applicationName) {
      case WorkTreeApplicationName.simulate:
        simulationIdsInWorkTree.push(node.data.result?.id as unknown as SimulationId);
        break;
      case WorkTreeApplicationName.workflow_builder:
      case WorkTreeApplicationName.cherry_picker:
        workflowIdsInWorkTree.push(node.data.result?.id as unknown as WorkflowId);
        break;
      case WorkTreeApplicationName.data_parser:
        datasetIdsInWorkTree.push(node.data.result?.id as unknown as DatasetId);
        break;
      case WorkTreeApplicationName.execute:
        executionIdsInWorkTree.push(node.data.result?.id as unknown as ExecutionId);
    }
  });

  const handleCreateBlock = async (props: CreateBlockProps) => {
    onHidePopover();
    const selection = await createBlock(props);
    if (selection === CANCEL_CHOICE) {
      return;
    }
    let id: ResultId | undefined;
    if (selection?.__typename === 'WorkflowBlock') {
      id = selection.workflow.id as unknown as ResultId;
    } else if (selection?.__typename === 'SimulationBlock') {
      if (selection.simulation.execution) {
        id = selection.simulation.execution.id as unknown as ResultId;
      } else {
        id = selection.simulation.id as unknown as ResultId;
      }
    } else if (selection?.__typename === 'DatasetBlock') {
      id = selection as unknown as ResultId;
    } else {
      // not possible - only a workflow or simulation will be returned (if selecting an
      // execution, a simulation block will be returned)
      return;
    }

    waitForNodeWithResultId(id, node => {
      context.focusOnNode(node);
    });

    refetchWorkTree();
  };

  return (
    <>
      <MenuItemWithIcon
        text="Existing workflow"
        icon={<WorkflowIcon />}
        onClick={() =>
          handleCreateBlock({
            type: BlockType.WORKFLOW,
            disabledWorkflowIds: workflowIdsInWorkTree,
          })
        }
        ref={ref}
      />
      <MenuItemWithIcon
        text="Existing simulation"
        icon={<SimulationIcon />}
        onClick={() =>
          handleCreateBlock({
            type: BlockType.SIMULATION,
            disabledSimulationIds: simulationIdsInWorkTree,
          })
        }
        ref={ref}
      />
      <MenuItemWithIcon
        text="Existing execution"
        icon={<ExecutionIcon />}
        onClick={() =>
          handleCreateBlock({
            type: 'execution',
            disabledExecutionIds: executionIdsInWorkTree,
          })
        }
        ref={ref}
      />
      <MenuItemWithIcon
        text="Existing dataset"
        icon={<DataSetIcon />}
        onClick={() =>
          handleCreateBlock({
            type: BlockType.DATASET,
            disabledDatasetIds: datasetIdsInWorkTree,
          })
        }
        ref={ref}
      />
      {workflowsDialog}
      {simulationsDialog}
      {executionsDialog}
      {datasetDialog}
    </>
  );
});
