import React, { useCallback } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export enum ChromatographyColorMethod {
  LIQUIDTOLOAD = 'LiquidToLoad',
  LOADVOLUMEPERFRACTION = 'LoadVolumePerFraction',
  RESIDENCETIME = 'ResidenceTime',
}

type Props = {
  value: ChromatographyColorMethod;
  onChange: (newValue: ChromatographyColorMethod) => void;
};

export default function ChromatographyColourMethodSelector({ value, onChange }: Props) {
  const handleColorMethodChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      onChange(e.currentTarget.value as ChromatographyColorMethod),
    [onChange],
  );

  return (
    <FormControl>
      <FormLabel>Color locations by:</FormLabel>
      <RadioGroup value={value} onChange={handleColorMethodChange}>
        <FormControlLabel
          control={<Radio />}
          value={ChromatographyColorMethod.LIQUIDTOLOAD}
          label="Liquid to Load"
        />
        <FormControlLabel
          control={<Radio />}
          value={ChromatographyColorMethod.LOADVOLUMEPERFRACTION}
          label="Load Volume Per Fraction"
        />
        <FormControlLabel
          control={<Radio />}
          value={ChromatographyColorMethod.RESIDENCETIME}
          label="Residence Time"
        />
      </RadioGroup>
    </FormControl>
  );
}
