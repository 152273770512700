import { Plate } from 'common/types/mix';

export type ExistingPlate = {
  item: Plate;
  version: string;
};
/**
 * The *Plate parameter accepts a string which looks like "plateName_riser",
 * e.g. "costar48well_riser18" but it can also accept an ExistingPlate:
 * This is a plate which is retrieved from a previous Job.
 */
export type PlateParameterValue = ExistingPlate | string | null;

/**
 * Strategy pattern: encapsulate the different strategies (i.e. different methods)
 * to adopt depending on the type of the value.
 * */
function processPlateParameterValue<T>(
  value: PlateParameterValue,
  processString: (value: string) => T,
  processPlate: (existingPlate: ExistingPlate) => T,
  processNull: () => T,
) {
  const valueType = typeof value;

  if (valueType === 'string') {
    return processString(value as string);
  } else if (value === null) {
    return processNull();
  } else if (valueType === 'object') {
    return processPlate(value as ExistingPlate);
  } else {
    throw new Error(
      `[processPlateParameterValue]: Plate cannot be a "${valueType.toUpperCase()}"`,
    );
  }
}

/**
 * Get the value of the parameter. This can be a plate, or a plate with carrier.
 * e.g. "costar48well", "costar48well_riser18"
 *
 * @param value Value of the PlateParameter
 */
export function getPlateParameterValueAsString(value: PlateParameterValue): string {
  return processPlateParameterValue(
    value,
    stringValue => stringValue,
    existingPlate => existingPlate.item.type,
    () => '',
  );
}

/**
 * Get the new value ("plate_carrier") based on the newly selected carrier.
 *
 * @param value value of the PlateParameter
 * @param plate plate type, e.g. "costar48wells"
 * @param newCarrier carrier, e.g. "riser18"
 */
export function getValueWithNewCarrier(
  value: PlateParameterValue,
  plate: string,
  newCarrier: string,
) {
  return processPlateParameterValue<ExistingPlate | string>(
    value,
    _plateAndCarrier => plate + newCarrier,
    existingPlate => {
      const newValue: ExistingPlate = {
        ...existingPlate,
        item: { ...existingPlate.item },
      };
      newValue.item.type = plate + newCarrier;
      return newValue;
    },
    () => '',
  );
}

/**
 * Get the display value of the parameter. If a user selected an empty plate, it's the
 * human readable plate type (e.g. "Costar 48 wells, clear"). If users selected a plate
 * from a job, it's its name (e.g. "My Plate 123")
 *
 * @param value Value of the PlateParameter
 */
export function getPlateParameterDisplayValueAsString(
  value: PlateParameterValue,
  humanReadablePlateName: string,
): string {
  return processPlateParameterValue(
    value,
    _ => humanReadablePlateName,
    existingPlate => existingPlate.item.name,
    () => '',
  );
}
