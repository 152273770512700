import { ArrayElement, DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import { Device, SimpleDevice } from 'common/types/device';

export type GraphQLDevice = DeviceCommon;

/**
 * Makes a common representation of a device for the UI which can be used both
 * in antha.com and AnthaHub. This way we can reuse UI components across antha.com
 * and AnthaHub.
 * This means we need some manual mapping code for each app but there's no better
 * way so far (Jul 2020) because antha.com and AnthaHub each have a different backend
 * representation of a device. As of Jul 2020, Antha.com uses GraphQL and AnthaHub UI
 * uses a REST API to fetch devices in a different format from the C# local server.
 * Here is the antha.com part of converting into a common format `Device`.
 */
export function deviceFromGraphQL(d: GraphQLDevice): Device {
  return {
    id: d.id,
    name: d.name || '',
    anthaHubGUID: d.anthaHubGUID,
    imageUrl: d.model.pictureURL || '',
    model: getDeviceModelLabel(d),
    isConfigurable: !!d.model.series.category.features['isConfigurable'],
    runConfigs: d.runConfigSummaries,
    anthaLangDeviceClass: d.model.anthaLangDeviceClass,
    accessibleDevices: d.accessibleDevices.map(accessibleDeviceFromGraphQL),
    contentSource: d.contentSource,
  };
}

type AccessibleDevice = ArrayElement<DeviceCommon['accessibleDevices']> & {
  contentSource: Device['contentSource'];
};

export function accessibleDeviceFromGraphQL(
  accessibleDevice: AccessibleDevice,
): SimpleDevice {
  return {
    id: accessibleDevice.id,
    name: accessibleDevice.name,
    model: getDeviceModelLabel(accessibleDevice),
    imageUrl: accessibleDevice.model.pictureURL,
    anthaLangDeviceClass: accessibleDevice.model.anthaLangDeviceClass,
    contentSource: accessibleDevice.contentSource,
  };
}

/**
 * Accepts any type of device that has a model.
 */
export function getDeviceModelLabel(device: {
  model: {
    name: string;
    series: { manufacturer: { name: string } };
  };
}) {
  return `${device.model.series.manufacturer.name} ${device.model.name}`;
}
