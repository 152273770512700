import React, { useCallback, useRef } from 'react';

import { useQuery } from '@apollo/client';
import ClearIcon from '@mui/icons-material/Clear';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import DownloadIcon from '@mui/icons-material/GetApp';
import KebabMenuIcon from '@mui/icons-material/MoreVert';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { QUERY_WORKFLOW_DETAILS_PANEL } from 'client/app/api/gql/queries';
import { useCopyWorkflowAndNavigate } from 'client/app/api/WorkflowsApi';
import {
  DEFAULT_TRANSFER,
  downloadCSVTemplate,
} from 'client/app/apps/cherry-picker/CherryPickApi';
import { useCherryPickContext } from 'client/app/apps/cherry-picker/CherryPickContext';
import { handleUploadCherryPickFile } from 'client/app/apps/cherry-picker/cp-file-upload/csvUploadHelper';
import { ScreenRegistry } from 'client/app/registry';
import { formatDateTime } from 'common/lib/format';
import { EditorType } from 'common/types/bundle';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import HeadersMappingDialog from 'common/ui/components/Dialog/HeadersMappingDialog';
import IconButton from 'common/ui/components/IconButton';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import useDialog from 'common/ui/hooks/useDialog';
import { usePopover } from 'common/ui/hooks/usePopover';

export default function CherryPickerActionsHeader({
  workflowId,
}: {
  workflowId: WorkflowId;
}) {
  const {
    isReadonly,
    setCherryPick,
    setUploadedFileName,
    setPlatesByName,
    setActiveStep,
  } = useCherryPickContext();
  const { loading, data } = useQuery(QUERY_WORKFLOW_DETAILS_PANEL, {
    variables: { id: workflowId },
  });
  const snackbar = useSnackbarManager();
  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const [headersMappingDialog, openHeadersMappingDialog] =
    useDialog(HeadersMappingDialog);

  const onCopy = useCopyWorkflowAndNavigate(
    data?.workflow.id,
    data?.workflow.version,
    EditorType.CHERRY_PICKER,
  );
  ``;

  const handleCopy = async () => {
    logEvent('copy-workflow', ScreenRegistry.CHERRY_PICKER);
    await onCopy();
  };

  const onDownload = useCallback(() => {
    logEvent('download-template', ScreenRegistry.CHERRY_PICKER);
    downloadCSVTemplate();
  }, []);

  const onClear = useCallback(async () => {
    logEvent('clear-cherrypick', ScreenRegistry.CHERRY_PICKER);
    const confirmClear = await openConfirmationDialog({
      action: 'clear',
      object: 'content',
      additionalMessage: 'You will lose all your changes.',
    });
    if (confirmClear) {
      setCherryPick([DEFAULT_TRANSFER]);
      setUploadedFileName(null);
      setPlatesByName({});
    }
  }, [openConfirmationDialog, setCherryPick, setPlatesByName, setUploadedFileName]);

  const onUpload = useCallback(async () => {
    logEvent('upload-new-cherrypick', ScreenRegistry.CHERRY_PICKER);
    const file = fileInputRef.current?.files?.[0];
    if (!file) {
      return;
    }

    await handleUploadCherryPickFile(
      file,
      fileInputRef.current,
      snackbar,
      openHeadersMappingDialog,
      setCherryPick,
      setUploadedFileName,
      setPlatesByName,
      setActiveStep,
    );
  }, [
    openHeadersMappingDialog,
    setActiveStep,
    setCherryPick,
    setPlatesByName,
    setUploadedFileName,
    snackbar,
  ]);

  const inputId = 'cherry-pick-upload-topbar';

  if (loading) {
    return <CircularProgress size={24} />;
  }

  if (!data) {
    return null;
  }

  return (
    <ActionsContainer>
      <ActionsText>
        <Typography variant="subtitle2" color="textSecondary">
          Created by:&nbsp;
        </Typography>
        <Typography variant="subtitle2">{data.workflow.createdBy.displayName}</Typography>
        <ActionsDivider orientation="vertical" />
        <Typography variant="subtitle2" color="textSecondary">
          Last modified:&nbsp;
        </Typography>
        <Typography variant="subtitle2">
          {formatDateTime(new Date(data.workflow.lastModifiedAt))}
        </Typography>
      </ActionsText>
      <ActionsIconButton size="small" icon={<KebabMenuIcon />} onClick={onShowPopover} />
      <ActionsMenu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={popoverAnchorElement}
        keepMounted
        open={isPopoverOpen}
        onClose={onHidePopover}
      >
        <MenuItemWithIcon
          icon={<CopyIcon />}
          text="Copy this Cherry Picker workflow"
          onClick={handleCopy}
        />
        <MenuItemWithIcon
          icon={<DownloadIcon />}
          text="Download CSV template pick list"
          onClick={onDownload}
        />
        <input
          id={inputId}
          type="file"
          accept=".csv"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={onUpload}
          disabled={isReadonly}
        />
        <label htmlFor={inputId}>
          <MenuItemWithIcon
            icon={<UploadIcon />}
            text="Upload CSV template pick list"
            disabled={isReadonly}
          />
        </label>
        <MenuItemWithIcon
          icon={<ClearIcon />}
          text="Clear all list entries"
          onClick={onClear}
          disabled={isReadonly}
        />
      </ActionsMenu>
      {confirmationDialog}
      {headersMappingDialog}
    </ActionsContainer>
  );
}

const ActionsContainer = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(6),
}));

const ActionsText = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const ActionsMenu = styled(Menu)({
  '& .MuiMenu-paper': {
    borderRadius: '8px',
  },
});

const ActionsIconButton = styled(IconButton)(({ theme: { palette } }) => ({
  color: palette.text.primary,
}));

const ActionsDivider = styled(Divider)(({ theme: { palette, spacing } }) => ({
  borderRightColor: palette.divider,
  height: '16px',
  margin: spacing(0, 3),
}));
