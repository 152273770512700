import { reportError } from 'client/app/lib/errors';
import {
  addParameterMessage,
  setParametersBooleanField,
} from 'client/app/lib/rules/elementConfiguration/parameterStateActions';
import {
  areParametersConnected,
  areParametersSet,
  compareParameterValue,
} from 'client/app/lib/rules/elementConfiguration/parameterStateConditions';
import { Connections, ElementInstance, ParameterValueDict } from 'common/types/bundle';
import {
  ElementConfigurationAction as Action,
  ElementConfigurationCondition as Condition,
  ElementConfigurationRule as Rule,
} from 'common/types/elementConfiguration';

export type ParameterState = {
  isValid: boolean;
  isVisible: boolean;
  isRequired: boolean;
  isEnabled: boolean;
  errorMessages: string[];
  warningMessages: string[];
};

function getDefaultState(): ParameterState {
  return {
    isValid: true,
    isVisible: true,
    isRequired: false,
    isEnabled: true,
    errorMessages: [],
    warningMessages: [],
  };
}

export type ParameterStateRuleContext = {
  parameterValues: ParameterValueDict | undefined;
  connections: Connections;
  elementInstance: ElementInstance;
};

export type ParameterStateRuleResult = {
  parameterStateDict: { [parameterName: string]: ParameterState };
  // add other data when new actions are added, if more information than parameter state is needed
};

/**
 * Evaluates all rules associated with element configurations.
 *
 * Note that actions are executed even when their condition is not met. This is because some actions
 * execute the opposite effect when the condition is not met (for example showing/hiding a parameter).
 */
export function evaluateParameterState(
  context: ParameterStateRuleContext,
  rules: Rule[],
): ParameterStateRuleResult {
  const parameterStateDict = context.elementInstance.element.inputs
    .map(input => input.name)
    .reduce(
      (acc, parameterName) => ({ ...acc, [parameterName]: getDefaultState() }),
      {} as ParameterStateRuleResult['parameterStateDict'],
    );

  context.elementInstance.Meta.errors
    ?.flatMap(err => err.parameters)
    .forEach(parameterName => {
      if (parameterName) {
        if (!parameterStateDict[parameterName]) {
          reportError(
            new Error(
              `Got an error for parameter "${parameterName}" which was not recognised in element "${context.elementInstance.element.name}"`,
            ),
          );
        } else {
          parameterStateDict[parameterName].isValid = false;
        }
      }
    });

  let result: ParameterStateRuleResult = { parameterStateDict };

  for (const { condition, actions } of rules) {
    const isConditionMet = evaluateCondition(context, condition);
    for (const action of actions) {
      result = runAction(action, isConditionMet, result);
    }
  }

  return result;
}

function evaluateCondition(
  context: ParameterStateRuleContext,
  condition: Condition,
): boolean {
  switch (condition.type) {
    case 'list': {
      const childResults = condition.childConditions.map(childCondition =>
        evaluateCondition(context, childCondition),
      );
      switch (condition.operator) {
        case 'and':
          return childResults.every(r => r);
        case 'or':
          return childResults.some(r => r);
      }
      break;
    }

    case 'const-true':
      return true;

    case 'not':
      return !evaluateCondition(context, condition.childCondition);

    case 'are-parameters-set':
      return areParametersSet(context, condition);

    case 'are-parameters-connected':
      return areParametersConnected(context, condition);

    case 'parameter-value-compare':
      return compareParameterValue(context, condition);
  }
}

function runAction(
  action: Action,
  isConditionMet: boolean,
  result: ParameterStateRuleResult,
): ParameterStateRuleResult {
  switch (action.type) {
    case 'set-parameters-required':
    case 'set-parameters-not-required':
    case 'set-parameters-visible':
    case 'set-parameters-not-visible':
    case 'set-parameters-enabled':
    case 'set-parameters-not-enabled':
      return setParametersBooleanField(action, isConditionMet, result);

    case 'add-parameter-error':
    case 'add-parameter-warning':
      return addParameterMessage(action, isConditionMet, result);
  }
}
