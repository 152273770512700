import React, { useCallback, useMemo, useState } from 'react';

/**
 * Hook for easier work with dialogs.
 *
 * If you want to display a confirmation message/question, have a look at hook
 * useConfirmationDialog.
 *
 * This hook requires a component that implements DialogProps<TResult>.
 * You need to build that component, or reuse existing one if suitable.
 *
 * Your dialog-ish component must accept props DialogProps<TResult>. They contain props
 * isOpen and onClose and onCancel that tell you whether the dialog is open and provide
 * actions to confirm and cancel the dialog. It's up to you to use these approprietly in
 * your own dialog component. Your dialog can contain other props too, you will be able
 * to pass them when calling openDialog(...).
 *
 * Let's say we want a dialog for editing a string value.
 *
 *   // Step 1/3: Build a dialog component that accepts DialogProps<string>
 *   // StringEditDialog.tsx
 *
 *   type Props = DialogProps<string>;
 *
 *   export default function StringEditDialog({isOpen, onClose}: DialogProps<string>) {
 *     const [value, setValue] = useState('');
 *
 *     // call onConfirm(value) when you want to confirm the dialog and close it:
 *
 *     const onConfirmClick = useCallback(() => onClose(value), [value, onConfirm]);
 *     return (
 *       <Dialog open={isOpen}>
 *         ...
 *         <TextField value={value} onChange={e => setValue(e.target.value)} />
 *         <Button onClick={onConfirmClick}>Confirm</Button>
 *       </Dialog>
 *     )
 *   }
 *
 *
 *   // Step 2/3: Call useDialog to generate [dialog, openDialog]:
 *
 *   // App.tsx
 *
 *   const [dialog, openDialog] = useDialog(StringEditDialog);
 *   const onOpenDialogClick = useCallback(
 *     async () => {
 *       const result = await openDialog({
 *         // note: if your dialog requires extra props, you can pass them here:
 *         yourDialogProp1: value1,
 *       });
 *       // here you can process the result
 *     },
 *   );
 *
 *   // Step 3/3: Render the dialog - this is important if you want your dialog
 *   // to be shown (work in progres to get rid of this step)
 *
 *   <App>
 *     {dialog}
 *     <Button onClick={onOpenDialogClick}>Open dialog</Button>
 *   </App>
 *
 * -----
 *
 * Problems with DialogManager:
 *
 *   1. It hides the dialog by removing the component, but the correct way is
 *      to render it and pass open={false} which allows for effects like fade-out
 *      and other animations. When the dialog is removed from the DOM, it just blinks
 *      and disappears instantly.
 *   2. It renders the dialogs globally, which introduces a bug when user displays
 *      the dialog and clicks back/forward in the browser. User is then on another
 *      page, but the dialog stays open, not usually what we want. This hook solves it.
 *   3. It is not possible to start loading data and pass it to the dialog in a
 *      reliable way. Dialog manager only renders the dialog once, when we trigger
 *      DialogManager.openDialogPromise, at which point the props are passed to the
 *      dialog. But if any of these props changes, the dialog will not receive it.
 *      This is problematic if we want to load data in the parent component and pass
 *      it to the dialog. We still have the problem here.
 */

export type DialogProps<TResult> = {
  isOpen: boolean;
  onClose: (result: TResult) => void;
  /**
   * If true, all inputs of the dialog should disallow user input. This could be
   * for viewing selected wells in a readonly workflow.
   */
  isDisabled?: boolean;
  /**
   * Prevent restoring focus to previously focused element when dialog is closed.
   */
  disableRestoreFocus?: boolean;
};

// result of useDialog(Component) call
type HookResult<TProps, TResult> = [
  JSX.Element | null,
  (props: Omit<TProps, 'isOpen' | 'onClose' | 'isDisabled'>) => Promise<TResult>,
];

/**
 * Shortcut for managing dialog state. This hook is useful for dialogs where user
 * selects something (TResult) and confirms selection. You will receive TResult in
 * your callback onClose, where you will usually want to somehow process it.
 *
 * Typical usage:
 *
 *   // assumes we have YourDialog with props DialogProps<Result>
 *   const [dialog, openDialog] = useDialog<Result>(YourDialog);
 *   const onOpenDialog = useCallback(async () => {
 *     const result = await openDialog();
 *     processResult(result);
 *   });
 *
 *   ...
 *
 *   // Render the dialog somewhere in your UI:
 *
 *   <App>
 *     {dialog}
 *     <Button onClick={onOpenDialog}>Open dialog</Button>
 *   </App>
 *
 */
export default function useDialog<TResult, TProps = {}>(
  DialogComponent: React.FunctionComponent<DialogProps<TResult> & TProps>,
): HookResult<TProps, TResult> {
  const [dialogProps, setDialogProps] = useState<(DialogProps<TResult> & TProps) | null>(
    null,
  );

  const dialog = useMemo(
    () => (dialogProps ? <DialogComponent {...dialogProps} /> : null),
    [DialogComponent, dialogProps],
  );

  const openDialogPromise = useCallback(
    (props: Omit<TProps, 'isOpen' | 'onClose' | 'isDisabled'>) =>
      new Promise((resolve: (result: TResult) => void) => {
        const onClose = (result: TResult) => {
          setDialogProps(props => ({ ...props!, isOpen: false }));
          resolve(result);
        };
        const dialogProps = {
          isOpen: true,
          onClose,
          ...props,
        } as DialogProps<TResult> & TProps;
        setDialogProps(dialogProps);
      }),
    [],
  );

  return [dialog, openDialogPromise];
}
