import MuiSlider from '@mui/material/Slider';
import withStyles from '@mui/styles/withStyles';

import Colors from 'common/ui/Colors';

const styles = {
  root: {
    color: Colors.DARK_TEXT_SECONDARY,
  },
  active: { color: Colors.BLUE_40 },
  track: {
    transition: 'none',
  },
  thumb: {
    transition: 'none',
    '&:hover, &:focus-visible': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
};

const Slider = withStyles(styles)(MuiSlider);

export default Slider;
