import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

function XlsxIcon(
  props: React.PropsWithChildren<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>>,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2H2C.9 2 0 2.9 0 4v16c0 1.1.89 2 1.99 2H14c1.1 0 2-.9 2-2v-1h-2v1H2V4h7v5h5v1h2V8l-6-6Z"
        fill="#26A414"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.368 15.56a.65.65 0 0 1 .039.232c0 .099-.025.19-.075.274a.53.53 0 0 1-.234.198 1.036 1.036 0 0 1-.422.072c-.161 0-.304-.014-.43-.042a.839.839 0 0 1-.316-.145.636.636 0 0 1-.195-.27 1.117 1.117 0 0 1-.066-.41h-1.376c0 .327.068.61.204.848.138.236.321.43.55.582.23.15.485.261.766.334.284.072.572.107.863.107.318 0 .606-.034.864-.103a2.03 2.03 0 0 0 .664-.3c.184-.135.326-.298.425-.49.1-.194.149-.414.149-.663a1.448 1.448 0 0 0-.5-1.122 2.473 2.473 0 0 0-.598-.391 5.417 5.417 0 0 0-.805-.316 5.581 5.581 0 0 1-.437-.156 2.136 2.136 0 0 1-.328-.167.816.816 0 0 1-.207-.187.396.396 0 0 1-.07-.228c0-.101.03-.194.09-.278a.605.605 0 0 1 .265-.205c.117-.05.262-.076.434-.076.177 0 .324.03.441.091.117.061.204.145.262.251.06.107.09.23.09.37h1.363c0-.35-.091-.654-.274-.91a1.727 1.727 0 0 0-.75-.597 2.676 2.676 0 0 0-1.101-.213 3.26 3.26 0 0 0-.871.11 2.218 2.218 0 0 0-.696.312 1.479 1.479 0 0 0-.457.49c-.109.191-.164.405-.164.644 0 .248.054.466.16.654.107.185.252.347.434.487.182.137.39.257.621.361.232.101.473.193.723.274.213.068.385.134.516.198.132.06.234.121.304.182.07.061.119.127.145.198ZM6.035 13.547l.93-1.89H8.53l-1.59 2.82 1.633 2.867H6.992l-.957-1.926-.957 1.926H3.5l1.629-2.867-1.586-2.82h1.562l.93 1.89Zm5.008 2.742h2.387v1.055H9.672v-5.688h1.371v4.633Zm11.922-4.633-.93 1.89-.93-1.89h-1.562l1.586 2.82-1.629 2.868h1.578l.957-1.926.957 1.926h1.582l-1.633-2.867 1.59-2.82h-1.566Z"
        fill="#26A414"
      />
    </SvgIcon>
  );
}

export default React.memo(XlsxIcon);
