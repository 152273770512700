import React from 'react';

import { useQuery } from '@apollo/client';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { QUERY_WORKFLOW_STATUS_BREADCRUMBS } from 'client/app/api/gql/queries';
import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import StatusBreadcrumbs from 'client/app/apps/landing-page/status/StatusBreadcrumbs';
import { WorkflowSourceEnum, WorkflowStatusBreadcrumbsQuery } from 'client/app/gql';
import { workflowRoutes } from 'client/app/lib/nav/actions';
import { formatDateTime } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';

const RECENT_WORKFLOW_COUNT = 5;

export default function RecentSection() {
  const query = useQuery(QUERY_WORKFLOW_STATUS_BREADCRUMBS, {
    fetchPolicy: 'network-only',
    variables: {
      count: RECENT_WORKFLOW_COUNT,
    },
  });

  return (
    <Workflows>
      <RenderQuery
        query={query}
        renderData={RenderData}
        renderNoData={NoEntitiesMessage}
        additionalNoDataProps={{
          entityName: 'workflows' as const,
          messageType: MessageType.NO_FILTER_RESULTS,
        }}
        loadingComponent={Loading}
      />
    </Workflows>
  );
}

const Loading = () => (
  <>
    {Array.from({ length: RECENT_WORKFLOW_COUNT }).map((_, i) => (
      <WorkflowItem key={`${i}`}>
        <ItemSkeleton variant="rounded" />
      </WorkflowItem>
    ))}
  </>
);

function RenderData({ data }: { data: WorkflowStatusBreadcrumbsQuery }) {
  const navigation = useNavigation();

  return (
    <>
      {data.workflowStatusBreadcrumbs.map(({ workflow, lastUpdated, steps }) => (
        <WorkflowItem
          onClick={() =>
            navigation.navigate(getWorkflowLink(workflow.source), {
              workflowId: workflow.id,
            })
          }
          key={workflow.id}
        >
          <Title variant="h5" color="textPrimary">
            {workflow.name}
          </Title>
          <LastModified variant="caption" color="textSecondary">
            {formatDateTime(new Date(lastUpdated))}
          </LastModified>
          <Status breadcrumbs={steps} />
        </WorkflowItem>
      ))}
    </>
  );
}

function getWorkflowLink(source: WorkflowSourceEnum) {
  switch (source) {
    case WorkflowSourceEnum.CHERRY_PICKER:
      return workflowRoutes.openInCherryPicker;
    case WorkflowSourceEnum.FORM_EDITOR:
      return workflowRoutes.editForm;
    default:
      return workflowRoutes.openInWorkflowBuilder;
  }
}

const Workflows = styled('div')({
  listStyleType: 'none',
  position: 'relative',
});

const Title = styled(Typography)({
  gridArea: 'title',
});

const LastModified = styled(Typography)({
  gridArea: 'modified',
});

const Status = styled(StatusBreadcrumbs)({
  gridArea: 'status',
  alignSelf: 'center',
});

const WorkflowItem = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(5, 0),
  display: 'grid',
  gridTemplate: `
    'title status' auto
    'modified status' auto / 1fr auto
  `,
  flexDirection: 'column',
  gap: theme.spacing(3),
  zIndex: 0,
  cursor: 'pointer',

  '&:not(:last-child)': {
    borderBottom: `1px solid ${Colors.GREY_20}`,
  },
  textDecoration: 'none',
  '&:hover,&:focus': {
    outline: 0,
    '::before': {
      content: '""',
      position: 'absolute',
      inset: `${theme.spacing(2)} -${theme.spacing(4)}`,
      background: Colors.BLUE_0,
      borderRadius: 8,
      zIndex: -1,
    },

    [`${Title}`]: {
      color: Colors.PRIMARY_MAIN,
    },
  },
  ':focus::before': {
    outline: `2px solid ${Colors.PRIMARY_MAIN}`,
  },
}));

const ItemSkeleton = styled(Skeleton)({
  height: 44,
  width: '100%',
  gridArea: '1 / 1 / 3 / 2',
});
