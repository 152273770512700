import React from 'react';

import InlineHelp from 'common/ui/components/InlineHelp/InlineHelp';

const plateTypeHelp = (
  <InlineHelp>
    <p>Select the type of plate you would like to use for mixing.</p>
  </InlineHelp>
);
const assignmentModeHelp = (
  <InlineHelp>
    <p>Select how you would like to describe your mixtures.</p>
    <hr />
    <p>
      <b>Descriptive</b> mode allows you to describe exactly where liquids should go on a
      plate.
    </p>
    <p>
      For example, if you want your control to be in well A1 and your samples in wells B1
      to H1, choose this mode.
    </p>
    <hr />
    <p>
      <b>Combinatorial</b> mode allows you to describe the combinations of liquids you
      would like to mix together.
    </p>
    <p>
      For example, if you have 10 samples, 2 master mixes and 1 additive:
      <ol>
        <li>Select the region of the plate that mixtures can be assigned to</li>
        <li>
          Create a 1<sup>st</sup> layer with the samples liquid group
        </li>
        <li>
          Create a 2<sup>nd</sup> layer with the master mixes liquid group
        </li>
        <li>
          Create a 3<sup>rd</sup> layer with the single additive liquid
        </li>
      </ol>
      Synthace will then create all possible combinations (10 x 2 x 1 = 20) and assign
      them to wells in the region your selected. If there are less wells than
      combinations, extra plates will be created.
    </p>
  </InlineHelp>
);
const finalTargetVolumeHelp = (
  <InlineHelp>
    <p>Set the final target volume you would like for your all of your mixtures.</p>
    <hr />
    <p>
      If <b>any</b> of the liquids you want to add to your mixtures are described{' '}
      <b>by concentration</b>, you must set the final target volume.
    </p>
    <hr />
    <p>
      If <b>all</b> of the liquids you want to add to your mixtures are described{' '}
      <b>by volume</b>, you do not need to set the final target volume unless you want to
      add a diluent to top up the mixtures to the final target volume.
    </p>
  </InlineHelp>
);
const diluentNameHelp = (
  <InlineHelp>
    <p>
      <b>Optional.</b> Set the diluent you would like to use to reach the final target
      volume for all of your mixtures.
    </p>
    <hr />
    <p>
      If the diluent is not provided, no diluent will be added to the mixtures, but all
      other liquids will be by calculated to reach their target concentrations in the
      final target volume.
    </p>
    <p>
      This is useful if you want to delay adding your diluent until later on (e.g. you
      want to incubate the samples first).
    </p>
  </InlineHelp>
);
const replicatesHelp = (
  <InlineHelp>
    <p>Set the number of times you would like your mixtures to be repeated.</p>
    <p>This is useful if you need to do biological or technical repeats for example.</p>
  </InlineHelp>
);
const wellSetSortByHelp = (
  <InlineHelp>
    <p>
      Select whether you would like to re-order the selected wells by column or by row.
    </p>
  </InlineHelp>
);
const layerNameHelp = (
  <InlineHelp>
    <p>
      <b>Optional.</b> Add a description for this layer of liquids.
    </p>
  </InlineHelp>
);
const liquidNameHelp = (
  <InlineHelp>
    <p>
      Select the liquid(s) to add to this layer by either:{' '}
      <ul>
        <li>Subcomponent name</li>
        <li>Liquid name</li>
        <li>Liquid group (&quot;Tag&quot;) name</li>
      </ul>
    </p>
    <hr />
    <p>
      Note that for <b>Descriptive</b> mode:
      <ul>
        <li>
          If you select a <b>subcomponent</b> or <b>liquid name</b>, every selected well
          will have this subcomponent or liquid in the final mixtures.
        </li>
        <li>
          If you select a <b>liquid group</b>, the liquids within that group will be
          repeated until all wells are filled or overflow onto extra plates if there are
          fewer wells than liquids
        </li>
      </ul>
    </p>
  </InlineHelp>
);
const liquidTargetHelp = (
  <InlineHelp>
    <p>Select the target volume or concentration you would like.</p>
  </InlineHelp>
);
const liquidGroupSortHelp = (
  <InlineHelp>
    <p>
      Select how the group of liquids should be ordered when they are added to the wells
      you selected.
    </p>
    <hr />
    <p>
      For <b>None</b> mode, the liquids in the group will not be reordered. They will
      retain the order set in the previous element.
    </p>
    <hr />
    <p>
      For <b>Source Conc.</b> mode, the liquids in the group will be reordered by their
      source concentration. This can be the overall concentration or the subcomponent
      concentration.
    </p>
  </InlineHelp>
);
const liquidGroupSubComponentNameHelp = (
  <InlineHelp>
    <p>
      Select the subcomponent within the group of liquids that you would like to use for
      concentration based calculations.
    </p>
    <p>
      If you do not select a subcomponent the overall concentration of the liquid will be
      used for calculations.
    </p>
  </InlineHelp>
);
const namingModeHelp = (
  <InlineHelp>
    <p>Select how you would like your mixtures to be named.</p>
    <hr />
    <p>
      In <b>Default</b> mode, mixtures will be named in the form <i>Mixture N</i>, where N
      is a number that uniquely distinguishes one mixture from another by the
      subcomponents and metadata of the mixture.
    </p>
    <hr />
    <p>
      In <b>By Layer</b> mode, mixtures will retain the name of the liquid in the layer
      you select. If there is no liquid in that layer, then a generic name will be
      autogenerated.
    </p>
  </InlineHelp>
);
const namingPlateLayerIDHelp = (
  <InlineHelp>
    <p>
      Select the layer of liquids you would like to retain the names of for your mixtures.
    </p>
  </InlineHelp>
);

export default {
  plateTypeHelp,
  assignmentModeHelp,
  finalTargetVolumeHelp,
  diluentNameHelp,
  replicatesHelp,
  wellSetSortByHelp,
  layerNameHelp,
  liquidNameHelp,
  liquidTargetHelp,
  liquidGroupSortHelp,
  liquidGroupSubComponentNameHelp,
  namingModeHelp,
  namingPlateLayerIDHelp,
};
