import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export default makeStylesHook({
  'golang-bool': {
    backgroundColor: '#c0b3ce',
  },
  'golang-error': {
    backgroundColor: '#ea582d',
  },
  'golang-float64': {
    backgroundColor: '#53b153',
  },
  'golang-unit8': {
    backgroundColor: '#53b153',
  },
  'golang-int': {
    backgroundColor: '#f39729',
  },
  'golang-string': {
    backgroundColor: '#fabf22',
  },
  'antha-primitive': {
    backgroundColor: '#51a7f9',
  },
  'antha-measure': {
    backgroundColor: '#b36ae2',
  },
  'antha-stdlib': {
    backgroundColor: '#4658a2',
  },
  'antha-table': {
    backgroundColor: '#88b3d1',
  },
  'something-went-wrong': {
    backgroundColor: '#0dd',
  },

  'connection-golang-bool': {
    stroke: '#c0b3ce',
  },
  'connection-golang-error': {
    stroke: '#ea582d',
  },
  'connection-golang-float64': {
    stroke: '#53b153',
  },
  'connection-golang-unit8': {
    stroke: '#53b153',
  },
  'connection-golang-int': {
    stroke: '#f39729',
  },
  'connection-golang-string': {
    stroke: '#fabf22',
  },
  'connection-antha-primitive': {
    stroke: '#51a7f9',
  },
  'connection-antha-measure': {
    stroke: '#b36ae2',
  },
  'connection-antha-stdlib': {
    stroke: '#4658a2',
  },
  'connection-antha-table': {
    stroke: '#88b3d1',
  },
  'connection-something-went-wrong': {
    stroke: '#0dd',
  },
});
