import React, { useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography, { typographyClasses } from '@mui/material/Typography';

import { DispenseChecklist } from 'client/app/api/ChecklistApi';
import ChecklistTable from 'client/app/apps/execution-details/StagePrintout/ChecklistTable';
import Header from 'client/app/apps/execution-details/StagePrintout/Header';
import PlateSection from 'client/app/apps/execution-details/StagePrintout/PlateSection';
import { PlateType } from 'common/types/plateType';
import Colors from 'common/ui/Colors';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';

type Props = {
  deviceName: string;
  dispenseChecklist: DispenseChecklist | undefined;
  simulationName: string;
  startedAt: string;
  stageIndex: number;
  plateTypes: readonly PlateType[];
};

function StagePrintout({
  deviceName,
  dispenseChecklist,
  simulationName,
  stageIndex,
  startedAt,
  plateTypes,
}: Props) {
  const liquidColors = useMemo(() => LiquidColors.createAvoidingAllColorCollisions(), []);
  const plateMap = useMemo(
    () => new Map(plateTypes.map(plate => [plate.type, plate])),
    [plateTypes],
  );

  if (plateMap.size === 0 || !dispenseChecklist) return null;

  return (
    <Container>
      <Header
        deviceName={deviceName}
        simulationName={simulationName}
        stageIndex={stageIndex}
        startedAt={startedAt}
      />
      <ChecklistTable dispenseChecklist={dispenseChecklist} />
      <Divider />
      <Stack gap={2}>
        <PlatesHeadline variant="body1">Plates</PlatesHeadline>
        <Steps>
          {dispenseChecklist?.dispense_list_steps.map((step, stepIndex) => (
            <SingleStep key={stepIndex} index={stepIndex}>
              <Typography
                variant="body1"
                fontWeight={500}
                fontSize={12}
                color="textSecondary"
              >
                Input plates
              </Typography>
              {step.source_plates.map((sourcePlate, sourcePlateIdx) => (
                <PlateSection
                  key={sourcePlateIdx}
                  plateInfo={sourcePlate}
                  plateTypeData={plateMap.get(sourcePlate.plate_type)}
                  liquidColors={liquidColors}
                />
              ))}

              <Typography
                variant="body1"
                fontWeight={500}
                fontSize={12}
                color="textSecondary"
              >
                Output plates
              </Typography>
              {step.destination_plates.map((destinationPlate, destinationPlateIdx) => (
                <PlateSection
                  key={destinationPlateIdx}
                  plateInfo={destinationPlate}
                  plateTypeData={plateMap.get(destinationPlate.plate_type)}
                  liquidColors={liquidColors}
                />
              ))}
            </SingleStep>
          ))}
        </Steps>
      </Stack>
    </Container>
  );
}

export default React.memo(StagePrintout);

//#region Styles

const Container = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(6),
  overflow: 'auto',

  '@media print': {
    '@page': {
      size: 'A4 landscape',
      margin: '1cm',
    },
  },
}));

const Steps = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(7),
  gap: theme.spacing(8),
}));

const SingleStep = styled(Stack, { shouldForwardProp: prop => prop !== 'index' })<{
  index: number;
}>(({ theme, index }) => ({
  gap: theme.spacing(6),

  [`& > .${typographyClasses.root}`]: {
    position: 'relative',

    '@media print': {
      pageBreakAfter: 'avoid',
    },

    '&:before': {
      content: `"${index + 1}"`,

      position: 'absolute',
      top: -3,
      left: -32,
      height: 24,
      width: 24,

      display: 'grid',
      alignContent: 'center',
      justifyContent: 'center',

      backgroundColor: Colors.GREY_10,
      color: theme.palette.text.secondary,
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 500,

      borderRadius: '50%',
    },
  },
}));

const PlatesHeadline = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 12,
  marginBottom: theme.spacing(5),

  '@media print': {
    pageBreakAfter: 'avoid',
  },
}));

//#endregion
