import React, { ReactNode } from 'react';

import { lighten, styled } from '@mui/material/styles';

import Colors from 'common/ui/Colors';

type Props = { icon: ReactNode; className?: string; color?: string; disabled?: boolean };
export default function CardType({
  icon,
  className,
  color = Colors.GREEN_80,
  disabled,
}: Props) {
  const updatedColor = disabled ? lighten(color, 0.7) : color;
  const backgroundColor = color ? lighten(color, 0.8) : Colors.GREEN_TERTIARY_0;
  const updatedBackgroundColor = disabled
    ? lighten(backgroundColor, 0.7)
    : backgroundColor;

  return (
    <IconWrapper
      className={className}
      style={{ backgroundColor: updatedBackgroundColor, color: updatedColor }}
    >
      {icon}
    </IconWrapper>
  );
}

const IconWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  justifySelf: 'center',
  width: '24px',
  height: '28px',
  borderRadius: '4px',
}));
