import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

function CsvIcon(
  props: React.PropsWithChildren<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>>,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2H4c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H16c1.1 0 2-.9 2-2v-1h-2v1H4V4h7v5h5v1h2V8l-6-6Z"
        fill="#26A414"
      />
      <path
        d="M10.672 15.42a2.089 2.089 0 0 1-.32 1.05c-.196.303-.47.537-.825.704-.351.166-.754.25-1.207.25-.747 0-1.336-.244-1.765-.73-.43-.488-.645-1.175-.645-2.063v-.281c0-.558.097-1.045.29-1.461.195-.42.474-.742.84-.97.364-.228.786-.343 1.265-.343.69 0 1.244.182 1.664.547.419.362.657.862.715 1.5H9.316c-.01-.346-.097-.595-.261-.746-.164-.151-.414-.227-.75-.227-.341 0-.591.128-.75.383-.16.255-.242.663-.25 1.223v.402c0 .607.075 1.04.226 1.301.154.26.417.39.79.39.314 0 .555-.074.722-.222.167-.148.255-.384.266-.707h1.363ZM14.654 15.834c0-.2-.072-.357-.215-.469-.14-.112-.39-.228-.746-.347a7.168 7.168 0 0 1-.875-.348c-.737-.362-1.105-.86-1.105-1.492 0-.315.09-.593.273-.832.185-.242.445-.43.781-.563a3.007 3.007 0 0 1 1.133-.203c.409 0 .775.073 1.098.219.325.146.578.354.758.625.18.268.269.575.269.922h-1.367c0-.232-.072-.412-.215-.54-.14-.127-.332-.19-.574-.19-.245 0-.44.054-.582.163a.494.494 0 0 0-.211.41c0 .146.078.28.234.399.156.117.431.24.824.367.394.125.716.26.97.406.614.354.921.843.921 1.465 0 .498-.187.888-.562 1.172-.375.284-.89.426-1.543.426-.461 0-.88-.082-1.254-.246-.373-.167-.654-.393-.844-.68a1.79 1.79 0 0 1-.281-.996h1.375c0 .305.078.53.234.676.159.143.415.215.77.215.226 0 .405-.049.535-.145a.487.487 0 0 0 .199-.414ZM19.335 15.799l1.133-4.14h1.531l-1.914 5.687h-1.5l-1.898-5.688h1.52l1.128 4.14Z"
        fill="#26A414"
      />
    </SvgIcon>
  );
}

export default React.memo(CsvIcon);
