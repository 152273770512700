import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const GraceIcon = React.memo<SvgIconProps>(function GraceIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg
        width="215"
        height="215"
        viewBox="0 0 215 215"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M157.569 37.3239C157.577 38.2191 157.308 39.0949 156.798 39.8307C156.288 40.5666 155.563 41.1263 154.721 41.4329L149.426 43.3988C143.802 45.4869 139.376 49.9376 137.321 55.574L135.389 60.8707C135.082 61.7105 134.526 62.4362 133.794 62.9499C133.062 63.4635 132.191 63.7403 131.297 63.743C130.403 63.7457 129.529 63.4741 128.795 62.9648C128.06 62.4556 127.499 61.7332 127.188 60.8952L125.215 55.6061C123.122 49.9942 118.676 45.5805 113.049 43.5284L107.733 41.5898C106.894 41.2835 106.168 40.727 105.654 39.9952C105.141 39.2635 104.864 38.3919 104.861 37.4979C104.858 36.6039 105.13 35.7306 105.639 34.9958C106.149 34.2611 106.871 33.7002 107.709 33.3889L113.004 31.423C118.629 29.3348 123.054 24.8842 125.11 19.2478L127.042 13.9511C127.348 13.1112 127.905 12.3855 128.636 11.8719C129.368 11.3583 130.24 11.0814 131.134 11.0788C132.028 11.0761 132.901 11.3477 133.636 11.8569C134.371 12.3662 134.931 13.0886 135.243 13.9266L137.209 19.2218C139.297 24.8463 143.747 29.2716 149.384 31.3275L154.681 33.2595C155.522 33.5567 156.251 34.1067 156.768 34.8343C157.285 35.5618 157.565 36.4313 157.569 37.3239Z"
          fill="#313131"
        />
        <g style={{ mixBlendMode: 'multiply' }}>
          <rect
            x="100.29"
            y="42.386"
            width="36.1584"
            height="103.648"
            rx="18.0792"
            transform="rotate(45 100.29 42.386)"
            fill="#333333"
          />
        </g>
        <g style={{ mixBlendMode: 'multiply' }}>
          <rect
            x="163.366"
            y="90.1476"
            width="36.1555"
            height="126.27"
            rx="18.0777"
            transform="rotate(45 163.366 90.1476)"
            fill="#333333"
          />
        </g>
        <rect
          x="181.201"
          y="97.6529"
          width="36.1555"
          height="146.696"
          rx="18.0777"
          transform="rotate(90 181.201 97.6529)"
          fill="#333333"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.1587 97.653L65.3517 128.46C58.2914 135.52 46.8443 135.52 39.7839 128.46C37.5177 126.194 35.9789 123.476 35.1675 120.595C34.736 119.048 34.5054 117.416 34.5054 115.731C34.5054 105.747 42.599 97.653 52.5831 97.653L96.1587 97.653Z"
          fill="#333333"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M162.662 97.6529C158.303 97.8429 154 99.6021 150.671 102.931L119.793 133.808H163.212C173.196 133.808 181.29 125.715 181.29 115.731C181.29 105.747 173.196 97.6529 163.212 97.6529L162.662 97.6529Z"
          fill="#333333"
        />
      </svg>
    </SvgIcon>
  );
});
