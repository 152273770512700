import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export const TextBlockIcon = React.memo(function TextBlockIcon(
  props: React.PropsWithChildren<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>>,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.6426 14.0137H10.3379L9.64648 16H8.10547L11.3281 7.46875H12.6582L15.8867 16H14.3398L13.6426 14.0137ZM10.7539 12.8184H13.2266L11.9902 9.2793L10.7539 12.8184Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.125 4.3125H3.875V19.6875H20.125V4.3125ZM3.875 2.3125C2.77043 2.3125 1.875 3.20793 1.875 4.3125V19.6875C1.875 20.7921 2.77043 21.6875 3.875 21.6875H20.125C21.2296 21.6875 22.125 20.7921 22.125 19.6875V4.3125C22.125 3.20793 21.2296 2.3125 20.125 2.3125H3.875Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
