import React, { useCallback, useContext, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import { UserProfileType } from 'client/app/hooks/useUserProfile';
import * as xhr from 'client/app/lib/xhr';
import Button from 'common/ui/components/Button';
import Checkbox from 'common/ui/components/Checkbox';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  simulationId: string;
  simulationName: string;
  userProfile: UserProfileType;
  onClose: () => void;
} & DialogProps<void>;

function RequestHelpDialog(props: Props) {
  const { onClose, simulationId, simulationName, isOpen, userProfile } = props;
  const classes = useStyles();
  const { screenId } = useContext(ScreenContext);

  const [description, setDescription] = React.useState('');
  const handleChangeDescription = useTextFieldChange(setDescription);
  const apolloClient = useApolloClient();

  const [allowShareWorkflow, setAllowShareWorkflow] = React.useState(true);
  const handleChangeAllowShareWorkflow = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllowShareWorkflow(event.target.checked);
  };
  const snackbarManager = useSnackbarManager();

  const postJSON = xhr.usePostJSON();
  /*
   * Upon hitting submit, we will share the workflow and open Intercom.
   */
  const [isClicked, setIsClicked] = useState(false);
  const [isResolved, setIsResolved] = useState(true);

  const handleSubmit = useCallback(async () => {
    logEvent('request-help-from-simulation', screenId as string);
    if (!isClicked) {
      setIsClicked(true);
    }
    setIsResolved(false);

    const requestHelpBody = {
      body: {
        description,
        simulationName: simulationName,
        simulationId: simulationId,
        userId: userProfile.intercomUserId,
      },
    };

    try {
      await postJSON(
        `/appserver/requestHelp/shareWorkflowAndOpenIntercom`,
        requestHelpBody,
      );
    } catch (e) {
      console.error(e);
      snackbarManager.showError(
        `Simulation:${simulationId} (${simulationName}) has been deleted elsewhere and cannot be shared. Please resimulate and try again.`,
      );
    } finally {
      setIsResolved(true);
    }

    apolloClient.cache.modify({
      id: apolloClient.cache.identify({ __typename: 'Simulation', id: simulationId }),
      fields: {
        isSharedExternally() {
          return true;
        },
      },
    });

    onClose();
  }, [
    screenId,
    isClicked,
    description,
    simulationName,
    simulationId,
    userProfile.intercomUserId,
    apolloClient.cache,
    onClose,
    postJSON,
    snackbarManager,
  ]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog open={isOpen} fullWidth onClose={handleClose} maxWidth="sm">
      <DialogTitle>Request help with {simulationName}</DialogTitle>
      <DialogContent dividers>
        <TextField
          placeholder="Please describe the problem. It usually helps us resolve it more quickly."
          fullWidth
          multiline
          value={description}
          variant="outlined"
          onChange={handleChangeDescription}
          rows={4}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={allowShareWorkflow}
              onChange={handleChangeAllowShareWorkflow}
            />
          }
          label="Share this workflow with Synthace"
          className={classes.content}
        />
        <div className={classes.content}>
          <Typography variant="caption">
            Check the box to confirm that you want to share this workflow with us. If you
            do, we will use a copy of the workflow to diagnose your problem.
          </Typography>
        </div>
        <div className={classes.content}>
          <Typography variant="caption">
            An alternative option is to use the in-app chat to contact us. However, we
            will be able to resolve your problem much faster if you share this workflow
            with us.
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" onClick={props.onClose}>
          Close
        </Button>
        {isResolved ? (
          <Button
            variant="tertiary"
            color="primary"
            onClick={handleSubmit}
            disabled={!allowShareWorkflow || isClicked}
          >
            Submit
          </Button>
        ) : (
          <CircularProgress size={24} />
        )}
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStylesHook({
  content: {
    paddingTop: '8px',
  },
});

export default RequestHelpDialog;
