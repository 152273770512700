import { useMemo } from 'react';

import forEachRight from 'lodash/forEachRight';

import { indexBy } from 'common/lib/data';
import { ElementInstance, Group } from 'common/types/bundle';

/**
 * Determine the order in which elements and groups should be drawn.
 *
 * Selected elements and groups are drawn first, with the most recently
 * selected appearing top-most. Then comes groups and their elements,
 * and then ungrouped elements.
 */
export function useDrawOrder(
  selectedObjectIds: string[],
  elementInstances: ElementInstance[],
  elementGroups: Group[] = [],
) {
  return useMemo(() => {
    /**
     * This is a set of ids. We are using the fact that sets iterate in insertion
     * order, and that attempts to insert an already present value do not affect this order.
     * We add all the selected objects first, then the groups and their elements, then the
     * ungrouped elements. When we get the values of the set and reverse them, we have
     * the order to draw, from the lowest z-index to the highest.
     */
    const order = new Set<string>(selectedObjectIds);
    const elementMap = indexBy(elementInstances, 'Id');

    // We iterate from right-to-left because new groups are added to the end of the array
    // but they should be shown first.
    forEachRight(elementGroups, group => {
      forEachRight(group.elementIds, id => {
        const ei = elementMap[id];
        if (ei) {
          order.add(ei.Id);
        }
      });
      order.add(group.id);
    });

    // As above. The newest elements are at the end of the array, but should be shown first.
    forEachRight(elementInstances, ei => {
      order.add(ei.Id);
    });

    return new Map(
      Array.from(order.values())
        .reverse()
        .map((id, index) => [id, index + 1]),
    );
  }, [elementGroups, elementInstances, selectedObjectIds]);
}
