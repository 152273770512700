import { SetStateAction } from 'react';

import { useCreateWorkflow } from 'client/app/api/WorkflowsApi';
import { OPEN_EXAMPLE_WORKFLOWS_DIALOG_PARAM } from 'client/app/components/ExampleWorkflows/exampleWorkflowsUtils';
import getWorkflowPropsBySource from 'client/app/lib/workflow/getWorkflowPropsBySource';
import { EditorType } from 'common/types/bundle';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export async function createAndNavigateToWorkflow(
  name: string,
  editorType: EditorType,
  snackbar: ReturnType<typeof useSnackbarManager>,
  navigation: ReturnType<typeof useNavigation>,
  createWorkflow: ReturnType<typeof useCreateWorkflow>,
  setCreatingWorkflow: React.Dispatch<SetStateAction<boolean>>,
  launchExampleWorkflowsDialog?: boolean,
) {
  setCreatingWorkflow(true);
  const additionalURLSearchParams = launchExampleWorkflowsDialog
    ? new URLSearchParams(`${OPEN_EXAMPLE_WORKFLOWS_DIALOG_PARAM}=true`)
    : undefined;
  try {
    const newWorkflow = await createWorkflow(name, editorType);
    navigation.navigate(
      getWorkflowPropsBySource(editorType).route,
      {
        workflowId: newWorkflow.id,
      },
      { additionalURLSearchParams },
    );
  } catch {
    snackbar.showError('Unable to create the Workflow.');
  } finally {
    setCreatingWorkflow(false);
  }
}
