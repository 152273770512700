import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default React.memo<SvgIconProps>(function LiquidsIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14.9711C7 17.7325 9.23858 19.9711 12 19.9711C14.7614 19.9711 17 17.7325 17 14.9711C17 13.381 16.1184 11.4744 14.6695 9.27629C13.993 8.25014 13.2504 7.24192 12.5083 6.23432C12.4814 6.19775 12.4545 6.16118 12.4275 6.12461C12.2851 5.93125 12.1422 5.73709 12 5.54274C11.8578 5.73709 11.7149 5.93125 11.5725 6.12461C11.5455 6.16118 11.5186 6.19776 11.4917 6.23433C10.7495 7.24193 10.007 8.25014 9.33054 9.27629C7.88157 11.4744 7 13.381 7 14.9711ZM10.7795 3.82024C10.4883 4.22421 10.1828 4.63895 9.87076 5.06259C7.6078 8.1349 5 11.6754 5 14.9711C5 18.8371 8.13401 21.9711 12 21.9711C15.866 21.9711 19 18.8371 19 14.9711C19 11.6754 16.3922 8.1349 14.1292 5.06259C13.8172 4.63895 13.5117 4.22421 13.2205 3.82024C12.7699 3.19502 12.3535 2.59561 12 2.02893C11.6465 2.59561 11.2301 3.19502 10.7795 3.82024Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
