import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';

import { DatasetsList, DatasetsListProps } from 'client/app/apps/experiments/DatasetList';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import Button from 'common/ui/components/Button';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import DialogActions from 'common/ui/components/Dialog/DialogActions';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

type DatasetsDialogProps = {
  dialogClassName?: string;
} & Pick<
  DatasetsListProps,
  'excludedDatasetIds' | 'deviceCategory' | 'notDeviceCategory'
>;

/**
 * Render a list of Datasets in a dialog, allowing the user to pick one Dataset.
 */
export default function DatasetsDialog(
  props: DatasetsDialogProps & DialogProps<DatasetId | typeof CANCEL_CHOICE>,
) {
  const { isOpen, onClose, dialogClassName, excludedDatasetIds } = props;

  const onSelectDatasetId = (clickedDatasetId: DatasetId) => onClose(clickedDatasetId);
  const onCancel = () => onClose(CANCEL_CHOICE);

  const classes = useStyles();
  return (
    <ComplexActionDialog
      title="Datasets"
      onClose={onCancel}
      isOpen={isOpen}
      showCloseButton
      className={dialogClassName}
      content={
        <div className={classes.dialogContent}>
          <DatasetsList
            onClickDataset={onSelectDatasetId}
            excludedDatasetIds={excludedDatasetIds}
          />
        </div>
      }
    />
  );
}

/**
 * Allow user to pick one or more datasets
 */
export function MultiselectDatasetsDialog(
  props: DatasetsDialogProps & DialogProps<DatasetId[] | typeof CANCEL_CHOICE>,
) {
  const {
    isOpen,
    onClose,
    dialogClassName,
    excludedDatasetIds,
    deviceCategory,
    notDeviceCategory,
  } = props;

  const [selectedDatasetIds, setSelectedDatasetIds] = useState<Set<DatasetId>>(new Set());

  // reset selection when dialog is closed/opened
  useEffect(() => setSelectedDatasetIds(new Set()), [isOpen]);

  const onClickDataset = (clickedDatasetId: DatasetId) =>
    setSelectedDatasetIds(ids => {
      const newSet = new Set(ids);
      if (newSet.has(clickedDatasetId)) {
        newSet.delete(clickedDatasetId);
      } else {
        newSet.add(clickedDatasetId);
      }
      return newSet;
    });
  const onConfirm = () => onClose([...selectedDatasetIds]);
  const onCancel = () => onClose(CANCEL_CHOICE);
  const onClear = () => setSelectedDatasetIds(new Set());

  const classes = useStyles();

  return (
    <ComplexActionDialog
      title="Datasets"
      onClose={onCancel}
      isOpen={isOpen}
      showCloseButton
      dialogActions={
        <DialogActions className={classes.dialogAction}>
          <div className={classes.selectionStatus}>
            <Typography
              color="textPrimary"
              variant="body1"
              className={classes.selectionStatusText}
            >
              {selectedDatasetIds.size} datasets selected
            </Typography>
            <Button variant="tertiary" onClick={onClear}>
              Clear
            </Button>
          </div>

          <Button variant="tertiary" color="primary" onClick={onConfirm}>
            Select
          </Button>
        </DialogActions>
      }
      className={dialogClassName}
      content={
        <div className={classes.dialogContent}>
          <DatasetsList
            selectedDatasetIds={selectedDatasetIds}
            excludedDatasetIds={excludedDatasetIds}
            onClickDataset={onClickDataset}
            deviceCategory={deviceCategory}
            notDeviceCategory={notDeviceCategory}
          />
        </div>
      }
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  dialogAction: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectionStatus: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  selectionStatusText: {
    marginRight: theme.spacing(3),
  },
  dialogContent: {
    display: 'flex',
    height: 'calc(100vh - 180px)',
    padding: theme.spacing(5),
    // Make the DatasetsList fill the dialog
    alignItems: 'stretch',
    justifyContent: 'stretch',
  },
}));
