import React from 'react';

import LinkIcon from '@mui/icons-material/Link';

import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import TextField from 'common/ui/filaments/TextField';

const ConnectionOnlyEditor = React.memo(function ConnectionOnlyEditor(
  props: ParameterEditorBaseProps<null | string>,
) {
  const { isDisabled, value } = props;
  return (
    <TextField
      fullWidth
      disabled={isDisabled}
      multiline
      value={value ?? 'Provide value by connection'}
      InputProps={{
        readOnly: true,
        startAdornment: <LinkIcon />,
      }}
    />
  );
});

export default ConnectionOnlyEditor;
