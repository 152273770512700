import React, { PropsWithChildren, useMemo } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

const DEFAULT: {
  earlyGetAccessTokenSilently: () => Promise<string>;
} = {
  earlyGetAccessTokenSilently: () => {
    throw new Error('EarlyLoginContext (new) not implemented');
  },
};

export const EarlyTokenContext = React.createContext(DEFAULT);
EarlyTokenContext.displayName = 'EarlyTokenContext';

function EarlyToken({ children }: PropsWithChildren<{}>) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const value = useMemo(() => {
    return {
      earlyGetAccessTokenSilently: getAccessTokenSilently,
    };
  }, [getAccessTokenSilently]);

  return isAuthenticated ? (
    <EarlyTokenContext.Provider value={value}>
      <>{children}</>
    </EarlyTokenContext.Provider>
  ) : null;
}

export default React.memo(EarlyToken);
