import React, { useCallback, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import { DNA } from 'client/app/components/Parameters/DNA/DNAParameter';
import parseFasta, {
  isSequence,
  Sequence,
} from 'client/app/components/Parameters/DNA/parseFasta';
import SequencePickerDialog from 'client/app/components/Parameters/DNA/SequencePickerDialog';
import Button from 'common/ui/components/Button';
import FileDropZone from 'common/ui/components/FileDropZone';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Switch from 'common/ui/components/Switch';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog, { DialogProps } from 'common/ui/hooks/useDialog';

function useOnChangeCallback(setter: React.Dispatch<React.SetStateAction<string>>) {
  return useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setter(e.target.value),
    [setter],
  );
}
function useOnToggleCallback(setter: React.Dispatch<React.SetStateAction<boolean>>) {
  return useCallback(() => setter(value => !value), [setter]);
}

type Props = {
  value: DNA;
} & DialogProps<DNA | typeof CANCEL_CHOICE>;

export default function DNADialog(props: Props) {
  const classes = useStyles();
  const [sequencesPickerDialog, openSequencesPickerDialog] =
    useDialog(SequencePickerDialog);
  const { value, onClose, isOpen } = props;

  const [nm, setNm] = useState<string>(value.nm);
  const [seq, setSeq] = useState<string>(value.seq);
  const [plasmid, setPlasmid] = useState<boolean>(value.plasmid);
  const [doubleStranded, setDoubleStranded] = useState<boolean>(value.double_stranded);
  const snack = useSnackbarManager();

  useEffect(() => {
    if (isOpen) {
      setNm(value.nm);
      setSeq(value.seq);
      setPlasmid(value.plasmid);
      setDoubleStranded(value.double_stranded);
    }
  }, [isOpen, value]);

  const onFastaDrop = useCallback(
    async (files: FileList) => {
      const sequences = await parseFasta(files[0]);
      if (!sequences) {
        snack.showError('There is no sequence in the file');
        return;
      }
      let sequence: Sequence | undefined | boolean = sequences[0];
      if (sequences.length > 1) {
        sequence = await openSequencesPickerDialog({ sequences });
      }
      if (!isSequence(sequence)) {
        // This should happen only when the user cancels.
        return;
      }
      setNm(sequence.description);
      setSeq(sequence.sequence);
      // We do not know if it is single or double stranded, linear or plasmid. Use sensible defaults, and let user decide.
      setDoubleStranded(true);
      setPlasmid(false);
    },
    [snack, openSequencesPickerDialog],
  );

  const onChangeNm = useOnChangeCallback(setNm);
  const onChangeSeq = useOnChangeCallback(setSeq);
  const onTogglePlasmid = useOnToggleCallback(setPlasmid);
  const onToggleDoubleStranded = useOnToggleCallback(setDoubleStranded);

  const onSave = useCallback(
    () =>
      onClose({
        nm,
        seq,
        double_stranded: doubleStranded,
        single_stranded: !doubleStranded,
        plasmid: plasmid,
        linear: !plasmid,
      }),
    [nm, seq, plasmid, doubleStranded, onClose],
  );

  const onCancel = useCallback(() => onClose(CANCEL_CHOICE), [onClose]);

  return (
    <>
      <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="lg">
        <DialogTitle>Edit DNA sequence</DialogTitle>
        <FileDropZone
          handleFileDrop={onFastaDrop}
          maskMessage={<Typography>Drop file here</Typography>}
        >
          <DialogContent className={classes.dialogContent}>
            <TextField value={nm} onChange={onChangeNm} label="Name" variant="standard" />
            <TextField
              variant="standard"
              className={classes.seq}
              value={seq}
              onChange={onChangeSeq}
              label="Sequence"
              multiline
              maxRows={8}
            />
            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={3}>
                <Grid item>Linear</Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    checked={plasmid}
                    onChange={onTogglePlasmid}
                    value="plasmid"
                  />
                </Grid>
                <Grid item>Plasmid</Grid>
              </Grid>
            </Typography>
            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={3}>
                <Grid item>Single-stranded</Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    checked={doubleStranded}
                    onChange={onToggleDoubleStranded}
                    value="doubleStranded"
                  />
                </Grid>
                <Grid item>Double-stranded</Grid>
              </Grid>
            </Typography>
            <Typography align="center" variant="caption">
              You can also drop FASTA files into this view
            </Typography>
          </DialogContent>
        </FileDropZone>
        <DialogActions>
          <Button variant="tertiary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="tertiary" onClick={onSave} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {sequencesPickerDialog}
    </>
  );
}

const useStyles = makeStylesHook({
  dialogContent: { display: 'flex', flexDirection: 'column' },
  seq: { marginTop: '10px' },
});
