import React, { useState } from 'react';

import ColumnMenuIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

import Colors from 'common/ui/Colors';

type Props = {
  disabled?: boolean;
  menu: { label: string; onClick: (e: React.MouseEvent) => void }[];
};

const EntityCardMenu = ({ disabled, menu }: Props) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const closeMenu = () => setMenuAnchor(null);

  return (
    <>
      <Button onClick={e => setMenuAnchor(e.currentTarget)} disabled={disabled}>
        <ColumnMenuIcon />
      </Button>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        PaperProps={{ square: false }}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {menu.map(({ label, onClick }) => (
          <MenuItem
            key={label}
            onClick={e => {
              closeMenu();
              onClick(e);
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const Button = styled(IconButton)(() => ({
  alignSelf: 'stretch',
  borderRadius: '0',
  borderLeft: `1px solid ${Colors.GREY_30}`,
  '&:hover': { backgroundColor: Colors.ACTION_PRIMARY_MAIN_HOVER },
  '&:active': { backgroundColor: Colors.ACTION_PRIMARY_MAIN_ACTIVE },
}));

export default EntityCardMenu;
