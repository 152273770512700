import React, { useMemo } from 'react';

import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { pluralizeWord } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import { useManualInterventions } from 'common/ui/components/simulation-details/mix/ManualInterventions/ManualInterventionContext';
import { ManualInterventionDarkIcon as ManualInterventionIcon } from 'common/ui/components/simulation-details/StepSlider/components/styles';
import {
  areAdjacent,
  getPrevKeyPoint,
} from 'common/ui/components/simulation-details/StepSlider/helpers';
import { type KeyPoint } from 'common/ui/components/simulation-details/StepSlider/types';

type Props = {
  keyPoints: KeyPoint[][];
};

function ManualInterventionsButton({ keyPoints }: Props) {
  const manualInterventions = useManualInterventions();
  const manualInterventionCount = useMemo(() => {
    let count = 0;

    for (const stageKeyPoints of keyPoints) {
      for (let i = 0; i < stageKeyPoints.length; i++) {
        const keyPoint = stageKeyPoints[i];
        const prevKeyPoint = getPrevKeyPoint(i, stageKeyPoints);

        if (keyPoint.isManualIntervention) {
          if (areAdjacent(keyPoint, prevKeyPoint)) {
            if (prevKeyPoint?.isManualIntervention) {
              // don't count
            } else {
              count += 1;
            }
          } else {
            count += 1;
          }
        }
      }
    }

    return count;
  }, [keyPoints]);

  return manualInterventionCount > 0 ? (
    <ManualIntervention
      isActive={manualInterventions.showAll}
      onClick={manualInterventions.toggle}
      focusRipple
      data-heap-tracking="button-manual-interventions"
    >
      <ManualInterventionIcon />
      <Typography variant="body2" fontWeight={700}>
        {manualInterventionCount}
      </Typography>
      <Typography variant="body2" fontWeight={400}>
        {pluralizeWord(manualInterventionCount, 'Manual intervention')}
      </Typography>
    </ManualIntervention>
  ) : null;
}

export default React.memo(ManualInterventionsButton);

const ManualIntervention = styled(ButtonBase, {
  shouldForwardProp: prop => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  gridArea: 'manual-intervention',

  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),

  padding: theme.spacing(2, 4),

  border: `1px solid ${theme.palette.background.paper}`,
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows['3'],
  cursor: 'pointer',
  backgroundColor: theme.palette.background.paper,

  ...(isActive
    ? {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: Colors.BLUE_0,
        },
      }
    : {
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: Colors.BLUE_5,
          borderColor: Colors.BLUE_5,
        },
      }),
}));
