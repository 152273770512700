import React, { useCallback } from 'react';

import { GraphQLDevice } from 'client/app/api/deviceFromGraphql';
import DeviceLibrary from 'client/app/components/DeviceLibrary/DeviceLibrary';
import { useDevices } from 'client/app/components/DeviceLibrary/useDevices';
import { DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  title: string;
} & DialogProps<GraphQLDevice | null>;

type PrefixItemsProps = {
  title: string;
  prefixItems: Required<Parameters<typeof DeviceLibrary>[0]>['prefixItems'];
} & DialogProps<GraphQLDevice | string | null>;

function hasPrefixItems(props: Props | PrefixItemsProps): props is PrefixItemsProps {
  return 'prefixItems' in props;
}

/**
 * Allow to pick one device, directly on click.
 * No device configuration, no confirm button.
 */
export default function SimpleDeviceSelectorDialog(props: Props) {
  return SimpleDeviceSelectorDialogBase(props);
}

export function SimpleDeviceSelectorDialogWithPrefixItems(props: PrefixItemsProps) {
  return SimpleDeviceSelectorDialogBase(props);
}

function SimpleDeviceSelectorDialogBase(props: Props | PrefixItemsProps) {
  const { title, isOpen } = props;

  const { loading, error, data, refetch } = useDevices();
  const devices = data?.devices ?? [];
  const handleCancel = useCallback(() => {
    props.onClose(null);
  }, [props]);

  const onSelect = (id: string) => {
    const device = devices.find(d => d.id === id) as DeviceCommon;
    if (device) {
      props.onClose(device);
    } else if (hasPrefixItems(props) && id) {
      props.onClose(id);
    }
  };

  if (error) {
    return <GraphQLErrorPanel error={error} onRetry={refetch} />;
  }
  return (
    <ComplexActionDialog
      title={title}
      isOpen={isOpen}
      onClose={handleCancel}
      showCloseButton
      fullHeight
      content={
        <DeviceLibrary
          isLoading={loading}
          onSelect={onSelect}
          devices={devices}
          selectedDeviceIds={[]}
          dialog
          smallCard
          prefixItems={hasPrefixItems(props) ? props.prefixItems : undefined}
        />
      }
    />
  );
}
