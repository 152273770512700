import { CellValue, ColumnDataType } from 'common/types/spreadsheetEditor';

/**
 * Uploaded plain text files or copy pasted data is always a string. However,
 * each column has a type, so we need to cast the data into that type.
 * This function converts strings into either boolean or numbers.
 */
export default function castValueToType(value: CellValue, type: ColumnDataType) {
  if (typeof value !== 'string' || typeof value === type) {
    return value;
  }

  switch (type) {
    case 'number': {
      const numericVal = parseFloat(value);
      if (isNaN(numericVal)) {
        return value;
      } else {
        return numericVal;
      }
    }

    case 'boolean':
      return value.toLowerCase() === 'true' || value === '1' ? true : false;

    default:
      return value;
  }
}
