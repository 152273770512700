import React, { useState } from 'react';

import Delete from '@mui/icons-material/DeleteOutlined';
import Download from '@mui/icons-material/Download';
import HelpIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import AddFileButton from 'client/app/apps/execution-details/ResultsTab/components/AddFileButton';
import { FileUploadData } from 'client/app/apps/execution-details/ResultsTab/hooks/useUploadLabwareFile';
import { LabwareCardData, LabwareFile } from 'client/app/apps/execution-details/types';
import BarcodeIcon from 'client/app/icons/BarcodeIcon';
import FileExtensionIcon from 'client/app/icons/FileExtensionIcon';
import { basename } from 'common/lib/strings';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import useDialog from 'common/ui/hooks/useDialog';

type Props = {
  labware: LabwareCardData;
  onAddFile: (data: FileUploadData) => Promise<DataUploadId | undefined>;
  onDeleteFile: (file: LabwareFile) => Promise<void>;
  onDownloadFile: (file: LabwareFile) => void;
  onRefresh: () => Promise<void>;
};

export default function LabwareCard({
  labware,
  onAddFile,
  onDeleteFile,
  onDownloadFile,
  onRefresh,
}: Props) {
  return (
    <Container>
      <Stack direction="row" gap={3}>
        <Typography variant="h5" color="textSecondary">
          Labware Name:
        </Typography>
        <Typography variant="h5" color="textPrimary">
          {labware.name}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="h5" color="textSecondary">
          Files
        </Typography>
        {labware.files.map(file => (
          <FileRow
            key={file.name}
            file={file}
            onDeleteFile={onDeleteFile}
            onDownloadFile={onDownloadFile}
          />
        ))}
        <OptionalAttributes labware={labware} />
      </Stack>
      <AddFileButton labware={labware} onAddFile={onAddFile} onRefresh={onRefresh} />
    </Container>
  );
}

function FileRow({
  file,
  onDownloadFile,
  onDeleteFile,
}: { file: LabwareFile } & Pick<Props, 'onDeleteFile' | 'onDownloadFile'>) {
  const [confirmDeleteFileDialog, openDeletionConfirmationDialog] =
    useDialog(ConfirmationDialog);

  const { showError } = useSnackbarManager();

  const handleDelete = async (file: LabwareFile) => {
    if (!file.originFile) {
      showError(`Cannot download file ${file.displayName}`);
      return;
    }

    const confirmed = await openDeletionConfirmationDialog({
      action: 'remove',
      isActionDestructive: true,
      object: 'file from execution',
      specificObject: basename(file.originFile.originFiletreeLink),
      additionalMessage:
        'The original file and processed data will still be stored in Files, but will no longer be connected to this execution.',
    });

    if (!confirmed) return;

    try {
      setDeleting(true);
      await onDeleteFile(file);
    } catch {
      setDeleting(false);
    } finally {
      setDeleting(false);
    }
  };
  const [deleting, setDeleting] = useState(false);

  return (
    <Row>
      <FileExtensionIcon fileName={file.name} />
      <Name variant="body1">{file.displayName}</Name>
      {!deleting && (
        <IconButton
          color="inherit"
          onClick={() => {
            onDownloadFile(file);
          }}
        >
          <Tooltip title="Download">
            <Download />
          </Tooltip>
        </IconButton>
      )}
      {file.allowDelete && (
        <>
          {deleting ? (
            <Box paddingRight={4}>
              <CircularProgress size="16px" />
            </Box>
          ) : (
            <IconButton color="inherit" onClick={() => handleDelete(file)}>
              <Tooltip title="Delete">
                <Delete />
              </Tooltip>
            </IconButton>
          )}
        </>
      )}
      {confirmDeleteFileDialog}
    </Row>
  );
}

function OptionalAttributes({ labware }: { labware: LabwareCardData }) {
  return (
    <>
      {labware.synthetic && (
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="body1">Synthetic Plate</Typography>
          <Tooltip title="This plate was created using the Plate Mapper">
            <HelpIcon fontSize="small" />
          </Tooltip>
        </Stack>
      )}
      {labware.barcode && (
        <Stack direction="row" alignItems="center" gap={2}>
          <BarcodeIcon />
          <span>{labware.barcode}</span>
        </Stack>
      )}
    </>
  );
}

const Container = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(5),

  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(5),
}));

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  alignItems: 'center',
}));

const Name = styled(Typography)({
  flexGrow: 1,
});
