import React, { useMemo } from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useDeckLayoutsForUpload } from 'client/app/components/DeviceLibrary/DeviceConfigurationValidation/DeckLayout/SelectionContext';
import { DeckLayoutConfig } from 'client/app/components/DeviceLibrary/DeviceConfigurationValidation/types';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import Checkbox from 'common/ui/components/Checkbox';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import Dropdown, { Option } from 'common/ui/filaments/Dropdown';

type Props = {
  deckLayoutConfigList: DeckLayoutConfig[];
  activeDeckLayoutConfig: DeckLayoutConfig;
  setActiveDeckLayoutConfig: (layout: DeckLayoutConfig) => void;
};

const Header = ({
  deckLayoutConfigList,
  activeDeckLayoutConfig,
  setActiveDeckLayoutConfig,
}: Props) => {
  const numberOfLayouts = deckLayoutConfigList.length;
  const disableValidation = useFeatureToggle('DISABLE_RUN_CONFIG_UPLOAD_VALIDATION');
  const { deckLayoutsForUpload, selectForUpload } = useDeckLayoutsForUpload();
  const isActiveDeckLayoutConfigSelected = !!deckLayoutsForUpload.get(
    activeDeckLayoutConfig.label,
  );

  const isSelectForUploadCheckboxEnabled =
    disableValidation || activeDeckLayoutConfig.valid;

  const numberOfSelectedLayouts = useMemo(
    () => [...deckLayoutsForUpload].filter(([_, selected]) => selected).length,
    [deckLayoutsForUpload],
  );

  const options = useMemo<Option<DeckLayoutConfig>[]>(
    () =>
      deckLayoutConfigList.map(deckLayout => ({
        value: deckLayout,
        label: deckLayout.label,
      })),
    [deckLayoutConfigList],
  );

  return (
    <Container>
      <Typography variant="h5">
        Deck layouts &#40;{numberOfSelectedLayouts}/{numberOfLayouts} selected&#41;
      </Typography>
      <Dropdown
        options={options}
        valueLabel={activeDeckLayoutConfig.label}
        onChange={option => {
          if (option) {
            setActiveDeckLayoutConfig(option);
          }
        }}
        renderValue={value => renderValue(value, activeDeckLayoutConfig.valid)}
        renderLabel={option =>
          renderLabel(option, !!deckLayoutsForUpload.get(option.label))
        }
        size="small"
      />
      <StyledFormControlLabel
        control={
          <Checkbox
            checked={isActiveDeckLayoutConfigSelected}
            onChange={(_, checked) => {
              selectForUpload(activeDeckLayoutConfig.label, checked);
            }}
            size="small"
            disabled={!isSelectForUploadCheckboxEnabled}
          />
        }
        label="Select for Upload"
      />
    </Container>
  );
};

const renderValue = (value: string, isValid: boolean) => (
  <ValueContainer>
    <ValueText>{value}</ValueText>
    {isValid ? null : <StyledErrorOutlineIcon color="error" />}
  </ValueContainer>
);

const renderLabel = (option: Option<DeckLayoutConfig>, isSelected: boolean) => (
  <>
    <Typography variant="h5">{option.label}</Typography>
    {isSelected ? <Typography>&nbsp;- Selected for upload</Typography> : null}
    {option.value.valid ? null : <StyledErrorOutlineIcon color="error" />}
  </>
);

const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 2fr 1fr',
  gap: theme.spacing(5),
  alignItems: 'center',
  padding: theme.spacing(2, 4),
}));

const StyledFormControlLabel = styled(FormControlLabel)({
  justifySelf: 'end',
  userSelect: 'none',
});

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)(({ theme }) => ({
  marginLeft: theme.spacing(3),
}));

const ValueContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
});

const ValueText = styled(TypographyWithTooltip)({
  padding: '3px 0',
});

export default Header;
