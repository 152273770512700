import React from 'react';

import Avatar from '@mui/material/Avatar';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type UserRoleProps = {
  userDisplayName: string | undefined;
  size?: 'small' | 'medium' | 'large';
};

export function userInitials(userName: string | undefined) {
  if (userName) {
    return userName
      .split(' ')
      .map(n => n[0])
      .join('');
  }
  console.warn('User detected with no defined userName');
  return '';
}

export const UserInitialsIcon = React.memo(
  ({ userDisplayName, size = 'medium' }: UserRoleProps) => {
    const classes = useStyles();

    return <Avatar className={classes[size]}>{userInitials(userDisplayName)}</Avatar>;
  },
);

const useStyles = makeStylesHook({
  small: {
    fontSize: '1.2rem',
    height: '20px',
    width: '20px',
  },
  medium: {
    fontSize: '1.2rem',
    height: '40px',
    width: '40px',
  },
  large: {
    fontSize: '1.2rem',
    height: '60px',
    width: '60px',
  },
});
