import { ApolloError } from '@apollo/client';

/**
 * Use this on the client to display an error message.
 *
 * This is better than using `err.message` because the Apollo `err.message`
 * starts with a non-user-friendly prefix "GraphQL error".
 */
export function getErrorMessage(err: ApolloError): string {
  // If we use err.message, we get "GraphQL error: ..."
  // We want the actual error message, which Apollo returns like this.
  return err?.graphQLErrors?.[0]?.message ?? 'Sorry, something went wrong.';
}
