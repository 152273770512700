import React from 'react';

import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint, { IReactToPrintProps } from 'react-to-print';

import Fab from 'common/ui/components/Fab';

export default function Print(props: IReactToPrintProps) {
  return (
    <ReactToPrint
      // the trigger prop below is a default and can be overriden in props
      trigger={() => {
        return <Fab icon={<PrintIcon />} color="inherit" size="small" />;
      }}
      {...props}
    />
  );
}

export const PRINT_WIDTH_A4 = 950;
export const PRINT_HEIGHT_A4 = 550;
