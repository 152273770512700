import React, { ReactNode } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

type Props = { icon: ReactNode; disabled?: boolean };

const EntityCardStatus = ({ icon, disabled }: Props) => {
  return (
    <Field>
      <Typography variant="overline">Status</Typography>
      <IconWrapper disabled={disabled}>{icon}</IconWrapper>
    </Field>
  );
};

const Field = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(5, 6),
  gap: 1,
}));

const IconWrapper = styled('div')<{ disabled?: boolean }>(({ disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...(disabled && {
    opacity: 0.7,
    pointerEvents: 'none',
  }),
}));

export default EntityCardStatus;
