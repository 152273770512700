import React from 'react';

import { SvgIconProps } from '@mui/material/SvgIcon';

export const ExampleWorkflowIcon = React.memo<SvgIconProps>(function ExampleWorkflowIcon(
  props,
) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.04997 23C8.05588 23 7.25 22.1941 7.25 21.2L7.25 10.4002C7.25 9.40609 8.05587 8.60021 9.04997 8.60021L22.5498 8.60021C23.5439 8.60021 24.3497 9.40609 24.3497 10.4002L24.3497 21.2C24.3497 22.1941 23.5439 23 22.5498 23L9.04997 23ZM9.04997 21.2L22.5498 21.2L22.5498 10.4002L9.04997 10.4002L9.04997 21.2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0497 5.00021L5.44988 5.00021C4.45578 5.00021 3.6499 5.80608 3.6499 6.80018L3.6499 15.8L5.44988 15.8L5.44988 14.0001L5.44988 8.60016L5.44988 6.80018L7.24985 6.80018L16.2497 6.80018L18.0497 6.80018L18.0497 5.00021Z"
        fill="currentColor"
      />
      <rect
        x="18.1248"
        y="16.4756"
        width="2.02497"
        height="2.69996"
        fill="currentColor"
      />
      <rect
        x="18.1248"
        y="12.4253"
        width="2.02497"
        height="2.69996"
        fill="currentColor"
      />
      <path
        d="M18.8001 13.7756H16.1001V17.8256H18.8001"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <rect
        x="13.3999"
        y="15.2949"
        width="2.69996"
        height="1.01249"
        fill="currentColor"
      />
      <rect
        x="12.0505"
        y="14.4512"
        width="2.02497"
        height="2.69996"
        fill="currentColor"
      />
    </svg>
  );
});
