import React from 'react';

import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import PlateLayoutEditorContents from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContents';
import Colors from 'common/ui/Colors';

type Props = {
  onClose: () => void;
  className?: string;
};

export default function PlateLayoutEditorPanel({ onClose, className }: Props) {
  return (
    <Grow in unmountOnExit>
      <Panel className={className} elevation={4}>
        <PlateLayoutEditorContents onClose={onClose} />
      </Panel>
    </Grow>
  );
}

const Panel = styled(Paper)({
  display: 'grid',
  gridTemplate: `
    'titlebar' auto
    'main' minmax(0, 1fr)
  `,
  backgroundColor: Colors.WHITE,
  borderRadius: '8px',
});
