import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {
  MUTATION_MIGRATE_TEMPLATE_WORKFLOW,
  MUTATION_UPDATE_TEMPLATE_WORKFLOW,
} from 'client/app/api/gql/mutations';
import AddInputDialog, {
  GraphQLTemplateWorkflow,
} from 'client/app/apps/template-workflows/editor/AddInputDialog';
import { toTemplateInput } from 'client/app/apps/template-workflows/editor/helpers';
import InputList from 'client/app/apps/template-workflows/editor/InputList';
import { TemplateWorkflowConfigParameterList } from 'client/app/apps/template-workflows/editor/TemplateWorkflowConfigParameterList';
import { TemplateWorkflowConfigParameterListDialog } from 'client/app/apps/template-workflows/editor/TemplateWorkflowConfigParameterListDialog';
import { useElements } from 'client/app/hooks/useElements';
import { templateWorkflowRoutes } from 'client/app/lib/nav/actions';
import { TemplateWorkflowInput } from 'common/types/bundle';
import Button from 'common/ui/components/Button';
import Checkbox from 'common/ui/components/Checkbox';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import LinearProgress from 'common/ui/components/LinearProgress';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import UIBox from 'common/ui/components/UIBox';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useCheckboxChange from 'common/ui/hooks/useCheckboxChange';
import useDialog from 'common/ui/hooks/useDialog';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  id: TemplateWorkflowId;
};

export default React.memo((props: Props) => {
  const [migrateTemplateWorkflow, { data, loading, error }] = useMutation(
    MUTATION_MIGRATE_TEMPLATE_WORKFLOW,
    {
      variables: { id: props.id },
    },
  );

  useEffect(() => {
    // Fire and forget is OK here. This will cause a re-render once finished.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    migrateTemplateWorkflow();
  }, [migrateTemplateWorkflow]);

  const templateWorkflow = data?.migrateTemplateWorkflow;
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      {loading ? (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      ) : null}
      <Grid item xs={12} className={classes.topSpace} />
      {error ? (
        <Grid item>
          <GraphQLErrorPanel error={error} />
        </Grid>
      ) : null}
      <Grid item xs={10} md={6}>
        {templateWorkflow ? (
          // set key to enforce re-render when id changes
          <Editor key={templateWorkflow.id} templateWorkflow={templateWorkflow} />
        ) : null}
      </Grid>
    </Grid>
  );
});

type EditorProps = {
  templateWorkflow: GraphQLTemplateWorkflow;
};

export type Input = TemplateWorkflowInput & {
  configName?: string;
  elementName?: string;
};

function Editor({ templateWorkflow }: EditorProps) {
  const [name, setName] = useState(templateWorkflow.name);
  const onNameChange = useTextFieldChange(setName);

  const [description, setDescription] = useState(templateWorkflow.description);
  const onDescriptionChange = useTextFieldChange(setDescription);

  const [isShared, setIsShared] = useState(templateWorkflow.isShared);
  const onIsSharedChange = useCheckboxChange(setIsShared);

  // Start loading elements early so they are available for the dialog.
  // Result of this hook cannot be passed down the dialog since it changes
  // during the API query and the dialog requires static props. The dialog
  // will call this hook again to get the data, this one just starts loading
  // them early. Multiple queries won't be triggered due to apollo caching.
  useElements(templateWorkflow.workflow.workflow.elementSetId);

  const [selectedInputs, setSelectedInputs] = useState<Input[]>(() => {
    const bundle = templateWorkflow.workflow.workflow;
    const elementNamesById: { [key: string]: string } = {};
    for (const [name, instance] of Object.entries(bundle.Elements.Instances)) {
      elementNamesById[instance.Id] = name;
    }

    if (!bundle.Template) {
      return [];
    }

    return bundle.Template.Inputs.map(input => {
      return {
        elementName: elementNamesById[input.ElementInstanceId],
        ...input,
      };
    });
  });

  const [addInputDialog, openInputDialog] = useDialog(AddInputDialog);

  const showAddInputDialog = useCallback(
    async (elementName?: string) => {
      const initialElementInstanceId = elementName
        ? templateWorkflow.workflow.workflow.Elements.Instances[elementName]?.Id
        : '';
      const inputs = await openInputDialog({
        templateWorkflow,
        selectedInputs,
        initialElementInstanceId,
      });
      if (inputs) {
        setSelectedInputs(inputs);
      }
    },
    [selectedInputs, openInputDialog, templateWorkflow],
  );

  // this is needed because showAddInputDialog has one optional parameter, and passing it
  // directly to onClick is not correct because the first argument is then the click Event
  const onShowAddInputDialogClick = useCallback(
    () => showAddInputDialog(),
    [showAddInputDialog],
  );

  const onShowAddInputDialogForInstance = useCallback(
    (elementName: string) => showAddInputDialog(elementName),
    [showAddInputDialog],
  );

  const [configParameters, setConfigParameters] = useState(() => {
    const bundle = templateWorkflow.workflow.workflow;
    if (!bundle.Template) {
      return [];
    }
    return bundle.Template.Config;
  });
  const [configParametersDialog, openConfigParametersDialog] = useDialog(
    TemplateWorkflowConfigParameterListDialog,
  );
  const onEditConfigParameters = useCallback(async () => {
    const newConfigParameters = await openConfigParametersDialog({
      configParameters,
    });
    if (newConfigParameters) {
      setConfigParameters(newConfigParameters);
    }
  }, [configParameters, openConfigParametersDialog]);

  const { openInNewTab } = useNavigation();

  const snackbarManager = useSnackbarManager();

  const [updateTemplateworkflow, { loading: isSaving }] = useMutation(
    MUTATION_UPDATE_TEMPLATE_WORKFLOW,
    {
      onError: e => snackbarManager.showError(e.message),
      onCompleted: () => snackbarManager.showSuccess(`Your template was saved.`),
    },
  );
  const onSave = useCallback(
    () =>
      updateTemplateworkflow({
        variables: {
          id: templateWorkflow.id,
          name,
          isShared,
          description,
          inputs: selectedInputs.map(toTemplateInput),
          configParameters,
        },
      }),
    [
      updateTemplateworkflow,
      templateWorkflow.id,
      name,
      isShared,
      description,
      selectedInputs,
      configParameters,
    ],
  );

  const onOpen = useCallback(
    () =>
      openInNewTab(templateWorkflowRoutes.createForm, {
        id: templateWorkflow.id,
      }),
    [openInNewTab, templateWorkflow.id],
  );

  const classes = useStyles();
  const cardClasses = useMemo(() => ({ root: classes.paper }), [classes.paper]);

  return (
    <>
      <Card classes={cardClasses}>
        <CardContent>
          <Typography variant="h1" gutterBottom>
            Template details
          </Typography>
          <UIBox padding="bs">
            <TextField
              variant="standard"
              disabled={isSaving}
              fullWidth
              label="Name"
              value={name}
              onChange={onNameChange}
            />
          </UIBox>
          <UIBox padding="bl">
            <TextField
              variant="standard"
              disabled={isSaving}
              fullWidth
              label="Description"
              value={description}
              onChange={onDescriptionChange}
            />
          </UIBox>
          <FormControlLabel
            control={
              <Checkbox
                checked={isShared}
                disabled={isSaving}
                onChange={onIsSharedChange}
              />
            }
            label="Share with organisation"
          />

          <UIBox padding="vm">
            <Typography variant="h5" gutterBottom>
              Workflow inputs
            </Typography>
          </UIBox>
          <InputList
            inputs={selectedInputs}
            setInputs={setSelectedInputs}
            onEditInstanceInputs={onShowAddInputDialogForInstance}
          />
          {selectedInputs.length === 0 && (
            <Button
              variant="secondary"
              disabled={isSaving}
              onClick={onShowAddInputDialogClick}
            >
              Edit inputs
            </Button>
          )}

          <UIBox padding="vl">
            <Typography variant="h5" gutterBottom>
              Configuration parameters
            </Typography>
          </UIBox>
          <TemplateWorkflowConfigParameterList
            configParameters={configParameters}
            onConfigParametersChange={setConfigParameters}
          />
          <Button
            variant="secondary"
            disabled={isSaving}
            onClick={onEditConfigParameters}
          >
            Edit configuration parameters
          </Button>
        </CardContent>
        {isSaving ? <LinearProgress /> : null}

        <CardActions>
          <Button variant="tertiary" onClick={onOpen}>
            Open
          </Button>
          <Button variant="tertiary" disabled={isSaving} onClick={onSave}>
            Save
          </Button>
        </CardActions>
      </Card>
      {addInputDialog}
      {configParametersDialog}
    </>
  );
}

const useStyles = makeStylesHook({
  container: {
    justifyContent: 'center',
    overflow: 'auto',
  },
  topSpace: {
    marginTop: '2em',
  },
  paper: {
    maxWidth: 'none',
  },
});
