import React from 'react';

import { Dimensions } from 'common/types/Dimensions';

type Props = Dimensions & {
  borderColor: string;
  backgroundColor: string;
  borderStyle?: string;
};
export function VisualSelectionBox({
  height,
  left,
  width,
  top,
  backgroundColor,
  borderColor,
  borderStyle,
}: Props) {
  return (
    <div
      style={{
        backgroundColor,
        height: height + 'px',
        left: left + 'px',
        pointerEvents: 'none',
        position: 'absolute',
        top: top + 'px',
        width: width + 'px',
        zIndex: 2,
        borderColor,
        borderStyle: borderStyle ?? 'solid',
        borderWidth: '1px',
      }}
    />
  );
}
