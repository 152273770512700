import React, { useContext } from 'react';

import { styled } from '@mui/material/styles';

import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { Stage } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import useDraggable from 'common/ui/components/useDraggable';
import WorkspaceCoordinatesContext from 'common/ui/components/Workspace/WorkspaceCoordinatesContext';

type CanvasStageProps = {
  stage: Stage;
  nextStage?: Stage;
  isSelected: boolean;
  number: number;
  isReadonly: boolean;
  minX?: number;
  maxX?: number;
  allowInteraction: boolean;
};

export default function CanvasStage({
  stage,
  nextStage,
  number,
  isSelected,
  isReadonly,
  minX,
  maxX,
  allowInteraction,
}: CanvasStageProps) {
  const dispatch = useWorkflowBuilderDispatch();
  const invalidPosition = stage.meta.showAsInvalid ?? false;
  const workspaceCoords = useContext(WorkspaceCoordinatesContext);
  const stageDragDelta = useWorkflowBuilderSelector(state => state.stageDragDelta);
  const startOffset = isSelected
    ? stageDragDelta * workspaceCoords.visibleWorkspaceArea.zoom
    : 0;

  const isFirstStage = number === 1;

  const xEnd = workspaceCoords.toScreenX(nextStage?.meta.x);
  const xStart = isFirstStage
    ? Math.min(0, (xEnd ?? 0) - 5)
    : workspaceCoords.toScreenX(stage.meta.x);

  const xDragMin = workspaceCoords.toScreenX(minX) ?? 0;
  const xDragMax = workspaceCoords.toScreenX(maxX);

  const { isDragging: isResizing, ...handlers } = useDraggable({
    onDragStart() {
      if (!isSelected) {
        dispatch({
          type: 'setSelectedStageId',
          payload: { id: stage.id, isThroughTimeline: false },
        });
      }
    },
    onDrag(delta) {
      // Constrain the drag so that the previous and next stages, if they exist,
      // remain large enough to contain an element.
      const minDelta =
        xDragMin !== undefined && xStart !== undefined ? xDragMin - xStart : undefined;
      const maxDelta =
        xDragMax !== undefined && xStart !== undefined ? xDragMax - xStart : undefined;
      let newDelta = maxDelta !== undefined ? Math.min(delta.x, maxDelta) : delta.x;
      newDelta = minDelta !== undefined ? Math.max(newDelta, minDelta) : newDelta;

      dispatch({
        type: 'setStageDragDelta',
        payload: newDelta / workspaceCoords.visibleWorkspaceArea.zoom,
      });
    },
    onDrop() {
      dispatch({
        type: 'applyStageDragDelta',
      });
    },
  });

  const handleStageNumberClick = () => {
    if (allowInteraction) {
      dispatch({
        type: 'setSelectedStageId',
        payload: { id: stage.id, isThroughTimeline: false },
      });
    }
  };

  return xStart !== undefined ? (
    <>
      <StageLine
        style={{ left: `${xStart + startOffset}px` }}
        isSelected={isSelected}
        isInvalid={invalidPosition}
        isReadonly={isReadonly}
      >
        {!isFirstStage && (
          <StageResizeArea isReadonly={isReadonly} {...(isReadonly ? {} : handlers)} />
        )}
        <StageIndicator isSelected={isSelected} isInvalid={invalidPosition}>
          {!isFirstStage && (
            <StageHandle
              isSelected={isSelected}
              isReadonly={isReadonly}
              isInvalid={invalidPosition}
              {...(isReadonly ? {} : handlers)}
            >
              <svg width="7" height="8" viewBox="0 0 7 8" fill="none">
                <rect
                  width="1"
                  height="8"
                  transform="matrix(-1 0 0 1 1 0)"
                  fill="currentColor"
                />
                <rect
                  width="1"
                  height="8"
                  transform="matrix(-1 0 0 1 4 0)"
                  fill="currentColor"
                />
                <rect
                  width="1"
                  height="8"
                  transform="matrix(-1 0 0 1 7 0)"
                  fill="currentColor"
                />
              </svg>
            </StageHandle>
          )}
          <StageNumber
            isSelected={isSelected}
            isInvalid={invalidPosition}
            isFirstStage={isFirstStage}
            isInteractive={allowInteraction}
            onClick={handleStageNumberClick}
          >
            {number}
          </StageNumber>
        </StageIndicator>
      </StageLine>
      {(isSelected || invalidPosition) && (
        <StageBackground
          style={{
            left: `${xStart + startOffset}px`,
            right: xEnd === undefined ? '0' : 'auto',
            width: xEnd !== undefined ? `${xEnd - xStart - startOffset}px` : 'auto',
          }}
          isResizing={isResizing}
          isInvalid={invalidPosition}
        />
      )}
    </>
  ) : null;
}

const StageIndicator = styled('div')<{
  isSelected: boolean;
  isInvalid: boolean;
}>(({ isSelected, isInvalid }) => ({
  display: 'flex',
  alignItems: 'stretch',
  gridArea: '1 / 1',
  alignSelf: 'center',
  marginLeft: '-9px',
  color: isSelected || isInvalid ? Colors.PRIMARY_CONTRAST : Colors.TEXT_PRIMARY,
  boxShadow: isSelected ? undefined : `0 3px 3px rgba(0,0,0,.1)`,
  zIndex: 2,
}));

const StageResizeArea = styled('div')<{ isReadonly: boolean }>(({ isReadonly }) => ({
  cursor: isReadonly ? 'inherit' : 'ew-resize',
  gridArea: '1 / 1',
  width: 9,
  marginLeft: -4,
  zIndex: 3,
}));

const StageLine = styled('div')<{
  isSelected: boolean;
  isInvalid: boolean;
  isReadonly: boolean;
}>(({ isSelected, isInvalid, isReadonly }) => ({
  position: 'absolute',
  display: 'grid',
  grid: '1fr / 1px',
  top: 0,
  bottom: 0,
  alignItems: 'stretch',
  justifyItems: 'start',
  zIndex: 2,
  '&::after': {
    content: '""',
    gridArea: '1 / 1',
    width: '1px',
    borderLeft: `1px solid ${
      isInvalid ? Colors.ERROR_MAIN : isSelected ? Colors.PRIMARY_MAIN : Colors.GREY_30
    }`,
  },
  '&:hover': !(isReadonly || isSelected || isInvalid)
    ? {
        '&::after': {
          borderLeftColor: Colors.BLUE_10,
        },
        [`& ${StageHandle}`]: {
          background: Colors.BLUE_10,
        },
        [`& ${StageNumber}`]: {
          background: Colors.BLUE_0,
        },
      }
    : {},
}));

const StageBackground = styled('div')<{
  isResizing: boolean;
  isInvalid: boolean;
}>(({ isResizing, isInvalid }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  background: isInvalid ? Colors.ERROR_MAIN : isResizing ? Colors.BLUE_50 : Colors.BLUE_5,
  opacity: isResizing || isInvalid ? 0.1 : 0.2,
  pointerEvents: 'none',
  zIndex: -2,
}));

const StageHandle = styled('div')<{
  isSelected: boolean;
  isReadonly: boolean;
  isInvalid: boolean;
}>(({ isSelected, isReadonly, isInvalid, theme: { spacing } }) => ({
  display: 'grid',
  placeItems: 'center',
  padding: `0 6px`,
  borderTopLeftRadius: spacing(1),
  borderBottomLeftRadius: spacing(1),
  backgroundColor: isInvalid
    ? Colors.ERROR_MAIN
    : isSelected
    ? Colors.PRIMARY_DARK
    : Colors.GREY_20,
  cursor: isReadonly ? 'inherit' : 'ew-resize',
}));

const StageNumber = styled('div')<{
  isSelected: boolean;
  isInvalid: boolean;
  isFirstStage: boolean;
  isInteractive: boolean;
}>(({ isSelected, isInvalid, isFirstStage, isInteractive, theme: { spacing } }) => ({
  background: isInvalid
    ? Colors.ERROR_LIGHT
    : isSelected
    ? Colors.PRIMARY_MAIN
    : Colors.WHITE,
  padding: spacing(3, 4),
  paddingLeft: isFirstStage ? '20px' : undefined,
  borderTopRightRadius: spacing(2),
  borderBottomRightRadius: spacing(2),
  fontWeight: 500,
  cursor: isInteractive ? 'pointer' : 'default',
}));
