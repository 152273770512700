import React, { useCallback } from 'react';

/**
 * Helper hook to make it easier to work with Checkbox. Checkbox's callback
 * is of type React.ChangeEvent<HTMLInputElement>, but we usually want just the
 * boolean.
 *
 * Old usage without this hook (6 non-empty lines):
 *
 *   const [isChecked, setIsChecked] = useState(false);
 *   const onCheckedChange = useCallback(
 *     (e: React.ChangeEvent<HTMLInputElement>) => setIsChecked(e.target.checked),
 *     [setIsChecked],
 *   );
 *
 *   <Checkbox checked={isChecked} onChange={onCheckedChange} />
 *
 * Typical usage with this hook (3 non-empty lines):
 *
 *   const [isChecked, setIsChecked] = useState(false);
 *   const onCheckedChange = useCheckboxChange(setIsChecked);
 *
 *   <Checkbox checked={isChecked} onChange={onCheckedChange} />
 */
export default function useCheckboxChange(setter: (value: boolean) => void) {
  return useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setter(e.target.checked),
    [setter],
  );
}
