import React, { useCallback, useContext } from 'react';

import Button from '@mui/material/Button';

import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import PathSelectorDialog from 'client/app/components/FileBrowser/PathSelectorDialog';
import FileDisplay from 'client/app/components/Parameters/FileEditor/FileDisplay';
import SimpleDeviceSelectorDialog from 'client/app/components/SimpleDeviceSelectorDialog';
import getFileObjectFromPath from 'client/app/lib/file-browser/getFileObjectFromPath';
import { DEVICE_BASE_PATH } from 'client/app/lib/file-browser/path';
import {
  FileBrowserFileSelection,
  FileBrowserFileSingleSelection,
} from 'client/app/lib/file-browser/types';
import { isFalsyOrEmptyObject } from 'common/lib/data';
import { concatURL } from 'common/lib/strings';
import { FileSeries } from 'common/types/fileParameter';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import useDialog from 'common/ui/hooks/useDialog';

type Props = {
  value: FileSeries;
  isDisabled?: boolean;
  onChange: (value?: FileSeries) => void;
};

const FileSeriesEditor = React.memo(function FileSeriesEditor({
  isDisabled,
  onChange,
  value,
}: Props) {
  const [pathSelectorDialog, openPathSelectorDialog] = useDialog(PathSelectorDialog);
  const [deviceSelectorDialog, openDeviceSelectorDialog] = useDialog(
    SimpleDeviceSelectorDialog,
  );
  const { screenId } = useContext(ScreenContext);
  const onClear = useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  /**
   * Responses from PathSelectorDialog is not in the format that can be saved.
   * We need to save the value depending on one or multiple file selection.
   */
  const handlePathSelectorResponse = useCallback(
    (value: FileBrowserFileSelection) => {
      const Files = (value as FileBrowserFileSingleSelection[]).map(path =>
        getFileObjectFromPath(path.pathWithScheme),
      );
      onChange({ Files });
    },
    [onChange],
  );

  const handleUploadFromDevice = useCallback(async () => {
    logEvent('add-file-from-device-selection', screenId as string);

    const selectDeviceResult = await openDeviceSelectorDialog({
      title: `Select files from a device`,
    });

    if (selectDeviceResult) {
      const { id, name } = selectDeviceResult;
      const devicePath = concatURL(DEVICE_BASE_PATH, id);

      await openPathSelectorDialog({
        selectMultiple: true,
        value: null,
        basePath: devicePath,
        onChange: handlePathSelectorResponse,
        overrideDialogTitle: `Select files from ${name}`,
      });
    }
  }, [
    handlePathSelectorResponse,
    openDeviceSelectorDialog,
    openPathSelectorDialog,
    screenId,
  ]);

  if (!isFalsyOrEmptyObject(value)) {
    return <FileDisplay isDisabled={isDisabled} value={value} onUnsetValue={onClear} />;
  } else {
    return (
      <>
        <Button variant="outlined" onClick={handleUploadFromDevice} disabled={isDisabled}>
          Add files from device
        </Button>
        {deviceSelectorDialog}
        {pathSelectorDialog}
      </>
    );
  }
});

export default FileSeriesEditor;
