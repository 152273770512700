import produce from 'immer';

import { ElementInstanceMeta, Stage } from 'common/types/bundle';

/**
 * Normalise (x,y) for all workflow elements and groups to start from (0,0)
 */
export default function normaliseLayoutPositions<
  TElement extends { Meta: ElementInstanceMeta },
  TGroup extends { Meta: ElementInstanceMeta },
>(elementInstances: TElement[], elementGroups: TGroup[], stages?: Stage[]) {
  let minX = Infinity;
  let minY = Infinity;

  for (const instance of elementInstances) {
    minX = Math.min(instance.Meta.x, minX);
    minY = Math.min(instance.Meta.y, minY);
  }

  for (const group of elementGroups) {
    minX = Math.min(group.Meta.x, minX);
    minY = Math.min(group.Meta.y, minY);
  }

  if (stages) {
    stages.forEach((stage, i) => {
      if (i > 0 && stage.meta.x !== undefined) {
        minX = Math.min(stage.meta.x, minX);
      }
    });
  }

  const resultElementInstances = produce(elementInstances, draft =>
    draft.forEach(ei => {
      ei.Meta.x -= minX;
      ei.Meta.y -= minY;
    }),
  );

  const resultElementGroups = produce(elementGroups, draft =>
    draft.forEach(group => {
      group.Meta.x -= minX;
      group.Meta.y -= minY;
    }),
  );

  const resultStages = stages
    ? produce(stages, draft =>
        draft.forEach(stage => {
          if (stage.meta.x !== undefined) {
            stage.meta.x -= minX;
          }
        }),
      )
    : undefined;

  return {
    elementInstances: resultElementInstances,
    elementGroups: resultElementGroups,
    stages: resultStages,
  };
}
