import React from 'react';

import Checkbox from 'common/ui/components/Checkbox';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import useCheckboxChange from 'common/ui/hooks/useCheckboxChange';

type Props = {
  onChange: (newValue?: boolean) => void;
} & ParameterEditorBaseProps<boolean>;

const CheckBoxEditor = React.memo(function CheckBoxEditor(props: Props) {
  const { value, isDisabled, onChange } = props;
  const onClick = useCheckboxChange(value => {
    onChange(value);
  });
  return <Checkbox checked={value} onChange={onClick} disabled={isDisabled} />;
});

export default CheckBoxEditor;
