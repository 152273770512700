import React from 'react';

import ChromatographyColumns from 'client/app/components/Labware/components/ChromatographyColumns';
import Plates from 'client/app/components/Labware/components/Plates';
import Tips from 'client/app/components/Labware/components/Tips';
import Tipwaste from 'client/app/components/Labware/components/Tipwaste';
import { LabwareInfoSection } from 'client/app/components/Labware/types';

type Props = {
  section: LabwareInfoSection;
};

export default function LabwareSection({ section }: Props) {
  switch (section.sectionHeader) {
    case 'Plates':
      return <Plates section={section} />;
    case 'Tips':
      return <Tips section={section} />;
    case 'Tipwaste':
      return <Tipwaste section={section} />;
    case 'Chromatography Columns':
      return <ChromatographyColumns section={section} />;
    default:
      return null;
  }
}
