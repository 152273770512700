import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { ExtraDeckAnnotation } from 'common/types/mix';
import Colors from 'common/ui/Colors';
import { DeckPositionRect } from 'common/ui/components/simulation-details/mix/DeckLayout';

type ExtraDeckAnnotationType = DeckPositionRect & {
  extraDeck: ExtraDeckAnnotation;
};

type Props = {
  deckPosition: ExtraDeckAnnotationType;
  highlight: boolean;
};

const StyledBox = styled(Box, {
  shouldForwardProp: propName => propName !== 'hightlight',
})<{ highlight: boolean }>(({ theme, highlight }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid',
  borderRadius: '4px',
  borderColor: highlight ? Colors.BLUE_50 : theme.palette.divider,
  backgroundColor: highlight ? Colors.BLUE_5 : Colors.GREY_20,
  color: highlight ? Colors.BLUE_50 : theme.palette.text.primary,
}));

export function isExtraDeckAnnotationType(
  deckPosition: DeckPositionRect,
): deckPosition is ExtraDeckAnnotationType {
  return !!deckPosition.extraDeck;
}

export default function ExtraDeckAnnotationView({
  deckPosition,
  highlight = false,
}: Props) {
  const { top, left, width, height } = deckPosition.absolutePosInDeckPixels;
  return (
    <StyledBox
      highlight={highlight}
      style={{
        left,
        top,
        width,
        height,
      }}
    >
      {deckPosition.extraDeck?.label && (
        <Typography variant="body2">{deckPosition.extraDeck.label}</Typography>
      )}
    </StyledBox>
  );
}
