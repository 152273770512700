const CDN_ASSETS_PATH = '/app/cdn/assets/';

export const MANUFACTURER_IMAGES: { [manufacturer: string]: string } = {
  '': '',
  AbGene: `${CDN_ASSETS_PATH}vendor/abgene.png`,
  Axygen: `${CDN_ASSETS_PATH}vendor/axygen.png`,
  'Bio-Rad': `${CDN_ASSETS_PATH}vendor/biorad.png`,
  CoStar: `${CDN_ASSETS_PATH}vendor/costar.png`,
  Falcon: `${CDN_ASSETS_PATH}vendor/falcon.png`,
  Greiner: `${CDN_ASSETS_PATH}vendor/greiner.png`,
  Kombi: '',
  Nunclon: `${CDN_ASSETS_PATH}vendor/nuncon.png`,
  'Phenix Research': `${CDN_ASSETS_PATH}vendor/phenix-research-icon.png`,
  AppliedBiosystems: `${CDN_ASSETS_PATH}vendor/appliedBiosystems-icon.png`,
  FluidX: `${CDN_ASSETS_PATH}vendor/fluidX-icon.png`,
  StarLab: `${CDN_ASSETS_PATH}vendor/starlab.png`,
  'Thermo-Fisher': `${CDN_ASSETS_PATH}vendor/thermofisher.png`,
  VWR: `${CDN_ASSETS_PATH}vendor/vwr.png`,
  Labcyte: `${CDN_ASSETS_PATH}vendor/labcyte.png`,
  'Applied Biosystems': `${CDN_ASSETS_PATH}vendor/applied-biosystems.png`,
  undefined: '',
};
