import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { getDeviceModelLabel } from 'client/app/api/deviceFromGraphql';
import { QUERY_DEVICES_WITH_USER_EXECUTIONS } from 'client/app/api/gql/queries';
import { DeviceOption } from 'client/app/apps/plate-library/DeviceFilter';

export default function useDevicesForFilter(
  opts: { withExecutionsByUserId?: string } = {},
): [
  deviceOptions: DeviceOption[],
  isLoading: boolean,
  manualDeviceId: string | undefined,
] {
  const { data: devices, loading } = useQuery(QUERY_DEVICES_WITH_USER_EXECUTIONS, {
    variables: {
      userId: opts.withExecutionsByUserId,
    },
  });
  const manualDeviceId = useMemo(
    () => devices?.devices.find(device => device.model.anthaModel === 'Manual')?.id,
    [devices?.devices],
  );
  const deviceOptions = useMemo(
    () =>
      devices?.devices.map<DeviceOption>(device => ({
        id: device.id,
        name: device.name,
        model: getDeviceModelLabel(device),
        imageUrl: device.model.pictureURL,
        accessibleDevices: device.accessibleDevices.map<
          DeviceOption['accessibleDevices'][number]
        >(accessibleDevice => ({
          id: accessibleDevice.id,
          model: getDeviceModelLabel(accessibleDevice),
          imageUrl: accessibleDevice.model.pictureURL,
        })),
      })) ?? [],
    [devices],
  );

  return [deviceOptions, loading, manualDeviceId];
}
