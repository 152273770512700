import React from 'react';

import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import DOEDesignToolScreen from 'client/app/apps/doe-template-editor/DOEDesignTool';
import WorkflowBuilder from 'client/app/apps/workflow-builder/WorkflowBuilder';
import { doeTemplateRoutes } from 'client/app/lib/nav/actions';

type DOETemplateRouteMatch = {
  workflowId: string;
};

/**
 * The DOE Template Editor is used in the DOEMS project. It is similar to the
 * Workflow Builder, except certain parameter editor components are replaced by
 * alternative editor components, more appropriate for specifying inputs to a
 * DOE.
 **/
function DOETemplateScreen() {
  const [filetreeURL] = useQueryParam('filetreeURL', StringParam);
  return (
    <>
      <Route
        exact
        path={doeTemplateRoutes.edit.pathTemplate}
        render={(props: RouteComponentProps<DOETemplateRouteMatch>) => (
          <WorkflowBuilder
            workflowId={props.match.params.workflowId as WorkflowId}
            doeTemplateMode
          />
        )}
      />
      <Route
        exact
        path={doeTemplateRoutes.design.pathTemplate}
        render={(props: RouteComponentProps<{ workflowId?: string }>) => (
          <DOEDesignToolScreen
            workflowId={props.match.params.workflowId as WorkflowId | undefined}
            filetreeURL={filetreeURL ?? undefined}
          />
        )}
      />
    </>
  );
}

export default withRouter(DOETemplateScreen);
