import React, { useRef } from 'react';

import Warning from '@mui/icons-material/Warning';
import { inputBaseClasses } from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { CONCENTRATION_UNIT_OPTIONS } from 'client/app/apps/standalone-tools/stock-dilution-calculator/StockDilutionCalculator';
import useHasUserInteracted from 'client/app/apps/standalone-tools/utils';
import StringMeasurementEditor from 'common/ui/components/ParameterEditors/StringMeasurementEditor';
import Tooltip from 'common/ui/components/Tooltip';

const PLACEHOLDER_VALUE = 'Value';

type StockDilutionRowProps = {
  stockVolume: string;
  diluentVolume: string;
  storedConcentration: string | undefined;
  isDisabled: boolean;
  errorMessage: string | undefined;
  onSetTargetConcentration: (targetConcentration: string) => void;
};

export default function StockDilutionTargetRow({
  stockVolume,
  diluentVolume,
  isDisabled,
  errorMessage,
  storedConcentration,
  onSetTargetConcentration,
}: StockDilutionRowProps) {
  const handleTargetConcentrationChange = (newValue: string | undefined) => {
    onSetTargetConcentration(newValue ?? '');
  };

  // Determines if a user has interacted with the element.
  // On this basis we can hide/display an error message
  // when the component is initially rendered.
  const ref = useRef<HTMLInputElement>(null);
  const hasUserInteracted = useHasUserInteracted(ref);

  return (
    <>
      <StyledMeasurementEditor
        value={storedConcentration}
        defaultUnit="Mol/l"
        units={CONCENTRATION_UNIT_OPTIONS}
        onChange={handleTargetConcentrationChange}
        isDisabled={isDisabled}
        placeholder={PLACEHOLDER_VALUE}
        hideError
        overrideErrorState={!!errorMessage && hasUserInteracted}
        textFieldProps={{
          InputProps: {
            startAdornment:
              errorMessage && hasUserInteracted ? (
                <Tooltip title={errorMessage}>
                  <StyledWarnedIcon />
                </Tooltip>
              ) : undefined,
            inputRef: ref,
          },
          inputProps: {
            'data-heap-tracking':
              'standalone-tools-stock-dilution-calculator-target-volume',
          },
        }}
      />
      <Typography variant="body1">=</Typography>
      <StyledTextField
        variant="outlined"
        color="secondary"
        size="small"
        inputProps={{
          readOnly: true,
        }}
        value={stockVolume}
      />
      <Typography variant="body1">+</Typography>
      <StyledTextField
        variant="outlined"
        color="secondary"
        size="small"
        inputProps={{
          readOnly: true,
        }}
        value={diluentVolume}
      />
    </>
  );
}

const StyledTextField = styled(TextField)(({ theme: { palette } }) => ({
  input: {
    color: palette.secondary.main,
    backgroundColor: palette.common.white,
  },
}));

const StyledWarnedIcon = styled(Warning)(({ theme: { palette } }) => ({
  color: palette.error.main,
  cursor: 'help',
}));

const StyledMeasurementEditor = styled(StringMeasurementEditor)(
  ({ theme: { palette } }) => ({
    [`& .${inputBaseClasses.root}`]: { background: palette.common.white },
  }),
);
