import React from 'react';

export function ConditionalWrapper({
  children,
  condition,
  wrapper,
}: {
  children: React.ReactNode;
  condition: boolean;
  wrapper: (child: React.ReactNode) => JSX.Element;
}) {
  return condition ? wrapper(children) : <>{children}</>;
}
