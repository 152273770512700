import produce from 'immer';

import { isDefined } from 'common/lib/data';
import {
  Cap,
  Deck,
  DeckItem,
  DeckPosition,
  DOEDesign,
  Lid,
  Plate,
  PlateContentsMatrix,
  WellContents,
  WellContentUpdate,
  WellLocationOnDeckItem,
} from 'common/types/mix';
import {
  DeckItemState,
  DeckState,
  PlateState,
} from 'common/ui/components/simulation-details/mix/MixState';
import { initTipbox } from 'common/ui/components/simulation-details/mix/TipboxState';

// Helper functions for working with contents of the Deck

/**
 * Given the raw deck from the backend, makes initial deck items.
 */
export function getDeckItemStates(deck: Deck): DeckItemState[] {
  // We only care about the before state for now
  return Object.entries(deck.before.positions).flatMap(
    ([deckPositionName, location]): DeckItemState[] => {
      const { item, lid, cap } = getItemsAtPosition(location);
      return [item, lid, cap].filter(isDefined).map(item => {
        if (item.kind === 'tipbox') {
          return initTipbox(item, deckPositionName);
        }
        // The current location is the initial location
        return {
          ...item,
          currentDeckPositionName: deckPositionName,
          currentRotationDegrees: 0,
        };
      });
    },
  );
}

type ItemsAtPosition = {
  item?: Exclude<DeckItem, Lid | Cap>;
  lid?: Lid;
  cap?: Cap;
};

/**
 * Get the item and/or lid or cap at a given deck position. Although the layout.json structure
 * allows arbitrary stacking of deck items, antha core only stacks one deck item and/or
 * one lid/cap.
 */
export function getItemsAtPosition(deckPosition: DeckPosition): ItemsAtPosition {
  let items = deckPosition.items;
  // Prior to Mar 2021, a deck position could only have a single item.
  if (!items && deckPosition.item) {
    items = [deckPosition.item];
  }
  return {
    item: items?.find(
      (item): item is Exclude<DeckItem, Lid | Cap> =>
        item.kind !== 'lid' && item.kind !== 'cap',
    ),
    lid: items?.find((item): item is Lid => item.kind === 'lid'),
    cap: items?.find((item): item is Cap => item.kind === 'cap'),
  };
}

export function platesOnly(deckItems: readonly DeckItemState[]): PlateState[];
export function platesOnly(deckItems: readonly DeckItem[]): Plate[];
export function platesOnly<TPlate extends PlateState | Plate>(
  deckItems: readonly DeckItemState[] | readonly DeckItem[],
): TPlate[] {
  function isPlate(deckItem: DeckItem): deckItem is TPlate {
    return deckItem.kind === 'plate';
  }
  return deckItems.filter(isPlate) as TPlate[];
}

/**
 * Return all plates with their contents after the last step of the simulation.
 */
export function getFinalPlatesOnDeck(deck: Deck): Plate[] {
  return Object.values(deck.after.positions)
    .map(deckPos => getItemsAtPosition(deckPos).item)
    .filter((item): item is Plate => item?.kind === 'plate');
}

export function isSameLocation(
  row: number,
  col: number,
  loc: WellLocationOnDeckItem | null,
): boolean {
  if (!loc) {
    return false;
  }
  return row === loc.row && col === loc.col;
}

// In the array of deck items, find the one with the given id
export function findDeckItemIndex(
  deckItems: readonly DeckItemState[],
  itemId: string,
): number {
  const foundIndex = deckItems.findIndex(item => item.id === itemId);
  if (foundIndex < 0) {
    throw new Error(`Deck item with local id ${itemId} does not exist`);
  }
  return foundIndex;
}

// Return contents of a well anywhere on the deck
export function getWellContents(
  deck: DeckState,
  loc: WellLocationOnDeckItem,
  design?: DOEDesign,
): WellContents | null {
  const plate = _assertPlate(deck.items[findDeckItemIndex(deck.items, loc.deck_item_id)]);
  const wellContents = getWellContentsHelper(plate, loc.col, loc.row) ?? null;
  return produce(wellContents, contents => {
    if (contents && design) {
      const doeTag = contents.tags?.find(tag => tag.label === 'DOE Run Index');

      if (doeTag) {
        const run = doeTag.value_string?.match(/^Run (\d+)/)?.[1];
        if (run !== undefined) {
          const runNum = Number.parseInt(run);
          contents.designFactors = {
            run: runNum,
            values: design.runs[runNum - 1],
          };
        }
      }
    }
  });
}

export function getAllWellContentsOnPlate(plate: Plate): WellContents[] {
  return Object.values(plate.contents || {}).flatMap(columnOfWells =>
    Object.values(columnOfWells),
  );
}

// Return contents of a well in a given plate
export function getWellContentsHelper(
  plate: Plate,
  col: number,
  row: number,
): Readonly<WellContents> | undefined {
  return plate.contents?.[col]?.[row];
}

// Update the state to reflect a change to a single well.
// Use structural sharing (do the minimum amount of copying):
// - Only replace specific wells in deck items affected by this step
// - Keep references to deck items that this step doesn't change
export function updatePlate(
  deckItems: readonly DeckItemState[],
  change: WellContentUpdate,
): readonly DeckItemState[] {
  const newDeckItems = [...deckItems];
  const foundIndex = findDeckItemIndex(newDeckItems, change.loc.deck_item_id);
  const plate = _assertPlate(newDeckItems[foundIndex]);

  const newPlateContents = _setWellContentsWithoutMutation(
    plate.contents || {},
    change.loc,
    // The backend gives us the complete state of the well after applying the
    // liquid handling step.
    change.new_content,
  );

  newDeckItems[foundIndex] = {
    ...plate,
    contents: newPlateContents,
  };

  return newDeckItems;
}

// Update contents of a single well inside a specific plate.
// Use structural sharing (do the minimum amount of copying).
function _setWellContentsWithoutMutation(
  contentsMatrix: PlateContentsMatrix,
  loc: WellLocationOnDeckItem,
  newContents: WellContents,
): PlateContentsMatrix {
  const { col, row } = loc;
  const newMatrix = { ...contentsMatrix };
  const existingCol = newMatrix[col];
  if (!existingCol) {
    newMatrix[col] = { [row]: newContents };
    return newMatrix;
  }
  newMatrix[col] = { ...existingCol };
  newMatrix[col][row] = newContents;
  return newMatrix;
}

// Used to "downcast" a DeckItem to a Plate in places where we are absolutely
// sure it must be a Plate.
export function _assertPlate(deckItem: DeckItemState): PlateState {
  if (deckItem.kind !== 'plate') {
    throw new Error(
      `Expected deck item ${deckItem.id} to be a plate, but it is a ${deckItem.kind}.`,
    );
  }
  return deckItem;
}
