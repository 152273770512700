import React from 'react';

import { createContextWithoutDefault } from 'common/ui/createContextWithoutDefault';

type FilterChipContextType = {
  /**
   * Useful for closing the popover after configuring the option within if
   * there is only one. This saves the user from having to click again to
   * close it.
   * */
  onClose: () => void;
};

export const FilterChipContext = createContextWithoutDefault<FilterChipContextType>();

export const FilterChipContextProvider = ({
  children,
  onClose,
}: FilterChipContextType & { children: React.ReactNode }) => {
  return (
    <FilterChipContext.Provider value={{ onClose }}>
      {children}
    </FilterChipContext.Provider>
  );
};
