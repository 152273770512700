import React from 'react';

import { Route, Switch } from 'react-router';

import CreateProtocolFromSimulation from 'client/app/apps/protocols/CreateProtocolFromSimulation';
import CreateProtocolInstance from 'client/app/apps/protocols/CreateProtocolInstance';
import EditProtocolInstanceScreen from 'client/app/apps/protocols/EditProtocolInstanceScreen';
import EditProtocolScreen from 'client/app/apps/protocols/EditProtocolScreen';
import { protocolsRoutes } from 'client/app/lib/nav/actions';
import Screen404 from 'common/ui/components/Screen404';

export function ProtocolsRouter() {
  return (
    <Switch>
      <Route
        exact
        path={protocolsRoutes.createFromSimulation.pathTemplate}
        render={route => <CreateProtocolFromSimulation id={route.match.params.id} />}
      />
      <Route
        exact
        path={protocolsRoutes.createProtocolInstance.pathTemplate}
        render={route => <CreateProtocolInstance id={route.match.params.id} />}
      />
      <Route
        exact
        path={protocolsRoutes.editProtocol.pathTemplate}
        render={route => (
          <EditProtocolScreen
            id={route.match.params.id}
            version={route.match.params.version}
          />
        )}
      />
      <Route
        exact
        path={protocolsRoutes.editProtocolInstance.pathTemplate}
        render={route => <EditProtocolInstanceScreen id={route.match.params.id} />}
      />
      <Route component={Screen404} />
    </Switch>
  );
}
