import React, { useCallback } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { isSequence, Sequence } from 'client/app/components/Parameters/DNA/parseFasta';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  sequences: (Sequence | boolean)[];
} & DialogProps<Sequence | undefined>;

export default function SequencePickerDialog(props: Props) {
  const classes = useStyles();
  const { sequences, onClose, isOpen } = props;

  const onSelect = useCallback((sequence: Sequence) => onClose(sequence), [onClose]);
  const onCancel = useCallback(() => onClose(undefined), [onClose]);
  return (
    <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="lg">
      <DialogTitle>Choose Sequence</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <List>
          {sequences
            .filter(seq => isSequence(seq))
            .map((value, i) => {
              const seq = value as Sequence; // boolean are filtered out above
              return (
                <ListItem key={i} button onClick={() => onSelect(seq)}>
                  <ListItemText>{seq.description}</ListItemText>
                </ListItem>
              );
            })}
        </List>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStylesHook({
  dialogContent: { display: 'flex', flexDirection: 'column' },
});
