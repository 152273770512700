import React, { useContext, useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { MUTATION_UPDATE_WORK_TREE_STATE } from 'client/app/apps/work-tree/mutations';
import { QUERY_WORK_TREE_STATE } from 'client/app/apps/work-tree/queries';
import { WorkTreeModeContext } from 'client/app/apps/work-tree/WorkTreeModeContext';
import { pluralize } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import IconButton from 'common/ui/components/IconButton';
import Switch from 'common/ui/components/Switch';
import Tooltip from 'common/ui/components/Tooltip';
import { usePopover } from 'common/ui/hooks/usePopover';

type WorkTreeOptionsMenuProps = {
  experimentId: ExperimentId;
  disabled: boolean;
  refetchWorkTree: () => Promise<void>;
};

type WorkTreeOption = 'showFailed';

export default function WorkTreeOptionsMenu(props: WorkTreeOptionsMenuProps) {
  const { disabled, experimentId, refetchWorkTree } = props;
  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();
  const context = useContext(WorkTreeModeContext);

  const IconWithPopover = (
    <Tooltip title="Choose whether to make the blocks that represent failed simulations or hidden blocks visible, and vice versa">
      <StyledIconButton
        icon={
          <StyledSettingsOutlinedIcon active={context.mode === 'editing-hidden-blocks'} />
        }
        size="small"
        onClick={onShowPopover}
        disabled={disabled}
      />
    </Tooltip>
  );

  const [updateState] = useMutation(MUTATION_UPDATE_WORK_TREE_STATE);

  const {
    data,
    loading,
    error,
    refetch: refetchWorkTreeState,
  } = useQuery(QUERY_WORK_TREE_STATE, {
    variables: { experimentID: experimentId },
  });

  const [showFailed, setShowFailed] = useState(false);
  useEffect(() => {
    setShowFailed(data?.workTreeState?.showFailed || false);
  }, [data?.workTreeState?.showFailed]);

  if (loading || error) {
    // TODO - Handle these states better
    // Right now, just show the button
    return IconWithPopover;
  }

  const workTreeStateData = data?.workTreeState;

  const handleSetShowFailed = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowFailed(e.target.checked);
    await handleChange('showFailed', e.target.checked);
  };

  const handleChange = async (option: WorkTreeOption, value: boolean) => {
    const updatedState = {
      ...workTreeStateData,
      [option]: value,
      experimentID: experimentId,
    };
    await updateState({
      variables: { ...updatedState },
    });
    await refetchWorkTreeState();
    await refetchWorkTree();
  };

  return (
    <>
      {IconWithPopover}
      <Menu
        anchorEl={popoverAnchorElement}
        keepMounted
        open={isPopoverOpen}
        onClose={onHidePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{ square: false }}
      >
        <StyledFormControl component="fieldset" variant="standard">
          <Label variant="overline">Settings</Label>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Switch
                  checked={showFailed}
                  onChange={handleSetShowFailed}
                  name="showFailed"
                  color="primary"
                />
              }
              label={<Typography variant="body2">Show failed simulations</Typography>}
              labelPlacement="start"
            />
          </FormGroup>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Button
                  variant="tertiary"
                  color="primary"
                  onClick={() => {
                    context.setModeEditingHiddenBlocks();
                    onHidePopover();
                  }}
                >
                  Edit
                </Button>
              }
              label={
                <>
                  <Typography variant="body2">Visibility of blocks</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {pluralize(context.hiddenBlocks.size, 'hidden block')}
                  </Typography>
                </>
              }
              labelPlacement="start"
            />
          </FormGroup>
        </StyledFormControl>
      </Menu>
    </>
  );
}

const StyledIconButton = styled(IconButton)({
  color: Colors.TEXT_PRIMARY,
});

const StyledSettingsOutlinedIcon = styled(SettingsOutlinedIcon, {
  shouldForwardProp: name => name !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  color: active ? theme.palette.primary.main : undefined,
}));

const Label = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(3, 4, 5, 5),
}));

const StyledFormControl = styled(FormControl<'fieldset'>)(({ theme }) => ({
  padding: theme.spacing(0, 5, 2, 0),
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  justifyContent: 'space-between',
  gap: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));
