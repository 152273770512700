import Big from 'big.js';

export function calculateMass(
  volume: Big,
  volumeUnitCoefficient: number,
  concentration: Big,
  concentrationUnitCoefficient: number,
  formulaWeight: Big,
) {
  const volumeInLitres = volume.mul(volumeUnitCoefficient);
  const concentrationInMol = concentration.mul(concentrationUnitCoefficient);

  return concentrationInMol.mul(volumeInLitres).mul(formulaWeight);
}
