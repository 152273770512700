import { WorkflowSourceEnum } from 'client/app/gql';
import getWorkflowPropsBySource from 'client/app/lib/workflow/getWorkflowPropsBySource';
import { Option } from 'common/ui/components/FilterChip/FilterChipWithCheckbox';

const SUPPORTED_FILTER_WORKFLOW_TYPES = [
  WorkflowSourceEnum.CHERRY_PICKER,
  WorkflowSourceEnum.FORM_EDITOR,
  WorkflowSourceEnum.WORKFLOW_EDITOR,
];

export function getSupportedWorkflowTypeFiltersOptions(): Option<WorkflowSourceEnum>[] {
  return SUPPORTED_FILTER_WORKFLOW_TYPES.map(workflowType => ({
    value: workflowType,
    label: getWorkflowPropsBySource(workflowType).humanReadableName,
    selected: false,
  }));
}

export function getSelectedSupportedWorkflowTypeFiltersOptions(
  selectedTypes: Option<WorkflowSourceEnum>['label'][],
): Option<WorkflowSourceEnum>[] {
  return SUPPORTED_FILTER_WORKFLOW_TYPES.map(workflowType => {
    const label = getWorkflowPropsBySource(workflowType).humanReadableName;
    return {
      value: workflowType,
      label,
      selected: selectedTypes.includes(label),
    };
  });
}
