import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DraftIcon = React.memo<SvgIconProps>(function DraftIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 13C5.55 13 6 13.45 6 14C6 15.1 5.1 16 4 16C3.83 16 3.67 15.98 3.5 15.95C3.81 15.4 4 14.74 4 14C4 13.45 4.45 13 5 13ZM16.67 0C16.41 0 16.16 0.1 15.96 0.29L7 9.25L9.75 12L18.71 3.04C19.1 2.65 19.1 2.02 18.71 1.63L17.37 0.29C17.17 0.09 16.92 0 16.67 0ZM5 11C3.34 11 2 12.34 2 14C2 15.31 0.84 16 0 16C0.92 17.22 2.49 18 4 18C6.21 18 8 16.21 8 14C8 12.34 6.66 11 5 11Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
