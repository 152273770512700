import React, { useMemo } from 'react';

import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { selectClasses } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import isEmpty from 'lodash/isEmpty';

import { VisualisableOutputType } from 'client/app/components/ElementPlumber/ElementOutputs/helpers';
import usePlateSelection from 'client/app/components/ElementPlumber/ElementOutputs/hooks/usePlateSelection';
import useSelectedPlateName from 'client/app/components/ElementPlumber/ElementOutputs/hooks/useSelectedPlateName';
import {
  useOutputFilterMatrixesByPlate,
  useOutputLiquidsByPlate,
} from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import PlateLayout from 'common/ui/components/PlateLayout/PlateLayout';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import {
  DeckItemWithWellsGeometry,
  getLayoutForWellSelector,
} from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import Dropdown from 'common/ui/filaments/Dropdown';
import { PlateIcon } from 'common/ui/icons/Plate';

type Props = {
  elementId: string;
  parameterName: string;
  type: VisualisableOutputType;
};

export default function PlatePreview({ elementId, parameterName, type }: Props) {
  const liquidColors = useMemo(() => LiquidColors.createAvoidingAllColorCollisions(), []);

  switch (type) {
    case 'Liquids':
      return (
        <LiquidPlatePreview
          elementId={elementId}
          parameterName={parameterName}
          liquidColors={liquidColors}
        />
      );
    case 'FilterMatrix':
      return (
        <FilterMatrixPlatePreview
          elementId={elementId}
          parameterName={parameterName}
          liquidColors={liquidColors}
        />
      );
    default:
      throw new Error(`Unsupported output type ${type}`);
  }
}

//#region Components

type PreviewProps = {
  elementId: string;
  parameterName: string;
  liquidColors: LiquidColors;
};

function LiquidPlatePreview({ elementId, parameterName, liquidColors }: PreviewProps) {
  const [loading, plateStateMap, outputLiquids] = useOutputLiquidsByPlate(
    elementId,
    liquidColors,
    parameterName,
  );
  const plateOptions = Array.from(plateStateMap.keys()).map(name => ({
    label: name,
    value: name,
  }));
  const selectedPlateName = useSelectedPlateName(plateStateMap, plateOptions);

  const plateState = plateStateMap.get(selectedPlateName);
  const geometry = useMemo(
    () =>
      plateState
        ? getLayoutForWellSelector(plateState).getCurrentGeometry(plateState)
        : undefined,
    [plateState],
  );

  const selectPlate = usePlateSelection();

  if (loading) {
    return (
      <Stack py={5} alignItems="center">
        <CircularProgress size="18px" />
      </Stack>
    );
  }
  if (!outputLiquids) {
    return <Alert severity="error">No output liquids</Alert>;
  }
  if (!geometry || !plateState) {
    return <Alert severity="error">No plate data available</Alert>;
  }

  return (
    <PlateLayoutPreview
      selectedPlateName={selectedPlateName}
      plateOptions={plateOptions}
      onChange={selectPlate}
      geometry={geometry}
      plateState={plateState}
      liquidColors={liquidColors}
    />
  );
}

function FilterMatrixPlatePreview({
  elementId,
  parameterName,
  liquidColors,
}: PreviewProps) {
  const [loading, plateStateMap, outputFilterMatrixes] = useOutputFilterMatrixesByPlate(
    elementId,
    liquidColors,
    parameterName,
  );
  const plateOptions = Array.from(plateStateMap.keys()).map(name => ({
    label: name,
    value: name,
  }));
  const selectedPlateName = useSelectedPlateName(plateStateMap, plateOptions);

  const plateState = plateStateMap.get(selectedPlateName);
  const geometry = useMemo(
    () =>
      plateState
        ? getLayoutForWellSelector(plateState).getCurrentGeometry(plateState)
        : undefined,
    [plateState],
  );

  const selectPlate = usePlateSelection();

  if (loading) {
    return (
      <Stack py={5} alignItems="center">
        <CircularProgress size="18px" />
      </Stack>
    );
  }
  if (!outputFilterMatrixes) {
    return <Alert severity="error">No output filter matrixes</Alert>;
  }
  if (!geometry || !plateState) {
    return <Alert severity="error">No plate data available</Alert>;
  }

  return (
    <PlateLayoutPreview
      selectedPlateName={selectedPlateName}
      plateOptions={plateOptions}
      onChange={selectPlate}
      geometry={geometry}
      plateState={plateState}
      liquidColors={liquidColors}
    />
  );
}

type PlateLayoutPreviewProps = {
  selectedPlateName: string;
  plateOptions: { label: string; value: string }[];
  onChange: (value: string | undefined) => void;
  geometry: DeckItemWithWellsGeometry;
  plateState: PlateState;
  liquidColors: LiquidColors;
};

function PlateLayoutPreview({
  selectedPlateName,
  plateOptions,
  onChange: handleChange,
  geometry,
  plateState,
  liquidColors,
}: PlateLayoutPreviewProps) {
  const dimensions = geometry.getDimensions();

  const hasWellContents = !isEmpty(plateState.contents);

  return (
    <Stack p={3} gap={3}>
      <PlateSelectDropdown
        placeholder="Plate name"
        valueLabel={selectedPlateName}
        options={plateOptions}
        onChange={handleChange}
        isRequired
        isDisabled={plateOptions.length <= 1}
        renderValue={value => (
          <Stack direction="row" alignItems="center" gap={3}>
            <PlateIcon />
            <Typography variant="body1" color="inherit">
              {value}
            </Typography>
          </Stack>
        )}
      />
      <Svg viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}>
        <PlateLayout
          geometry={geometry}
          plate={plateState}
          liquidColors={liquidColors}
          showContentLabels={false}
          showAxisLabels={false}
          showEmptyWellsAsPossiblyAllocated={hasWellContents}
          disableAllWells={!hasWellContents}
        />
      </Svg>
      {hasWellContents ? (
        <Alert severity="warning">Plate information is partial only</Alert>
      ) : (
        <Alert severity="info">Allocation is optimized by Synthace</Alert>
      )}
    </Stack>
  );
}

//#endregion

//#region Styles

const PlateSelectDropdown = styled(Dropdown<string>)(({ theme }) => ({
  [`& > .${selectClasses.select}`]: { padding: theme.spacing(2) },
}));

const Alert = styled(MuiAlert)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  height: 30,
  borderRadius: theme.spacing(2),
}));

const Svg = styled('svg')({
  alignSelf: 'center',
  width: '100%',
  height: 140,
});

//#endregion
