import React, { useCallback } from 'react';

import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import SimulationsDialogContainer from 'client/app/components/Parameters/SimulationsDialog/SimulationsDialogContainer';
import { ArrayElement, SimulationsForDialogQuery } from 'client/app/gql';
import { DialogProps } from 'common/ui/hooks/useDialog';

type SimulationForDialog = ArrayElement<
  SimulationsForDialogQuery['simulations']['items']
>;

/**
 * Render the Simulations list in a dialog, allowing users to click on one.
 * Used to select a plate from a simulation/execution's preview. e.g. in `ExistingPlateSelect`
 */
export default function SimulationsDialog(
  props: DialogProps<SimulationForDialog | typeof CANCEL_CHOICE> & {
    disabledSimulationIds?: SimulationId[];
  },
) {
  const { isOpen, onClose, disableRestoreFocus, disabledSimulationIds } = props;

  const onSelectSimulationFromDialog = useCallback(
    (simulation: SimulationForDialog) => onClose(simulation),
    [onClose],
  );
  const onCancel = useCallback(() => onClose(CANCEL_CHOICE), [onClose]);
  return (
    <SimulationsDialogContainer
      onSelectSimulationFromDialog={onSelectSimulationFromDialog}
      onClose={onCancel}
      isOpen={isOpen}
      disableRestoreFocus={disableRestoreFocus}
      disabledSimulationIds={disabledSimulationIds}
    />
  );
}
