import React, { useCallback, useMemo } from 'react';

import Button from '@mui/material/Button';

import {
  areAllPlateTypesSelected,
  getSerialisedJson,
  isAtLeastOneTransferValid,
  NO_VALID_TRANSFERS_MSG,
  serialiseLiquids,
} from 'client/app/apps/cherry-picker/CherryPickApi';
import { useCherryPickContext } from 'client/app/apps/cherry-picker/CherryPickContext';
import { ParameterMode } from 'client/app/apps/cherry-picker/CherryPickLayout';
import { ScreenRegistry } from 'client/app/registry';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const NOT_ALL_PLATES_SELECTED_MSG = 'Please select all plate types first';

type Props = {
  parameterMode: ParameterMode;
};

/** This component is rendered only in the modal version of the Cherry Picker.
 * It must have the `parameterMode` prop to work as expected.
 */
function CherryPickBottomBar({ parameterMode }: Props) {
  const classes = useStyles();
  const {
    bundleConfig,
    cherryPick,
    enforceOrder,
    platesByName,
    plateOrigins,
    uniqueLiquidNames,
    uniquePlateNames,
  } = useCherryPickContext();
  const snackbar = useSnackbarManager();

  const liquids = useMemo(
    // The Cherry Picker doesn't support SubComponents yet. However, the Workflows generated
    // by Visserver do have SubComponents. Use these, or fallback to serialising liquids from liquid names.
    () => parameterMode.transferData?.Liquids || serialiseLiquids(uniqueLiquidNames),
    [parameterMode.transferData, uniqueLiquidNames],
  );

  const handleOK = useCallback(() => {
    logEvent('save-parameter-changes', ScreenRegistry.CHERRY_PICKER);
    try {
      const transferData = getSerialisedJson(
        cherryPick,
        platesByName,
        liquids,
        enforceOrder,
        bundleConfig,
        plateOrigins,
      );
      parameterMode.onUpdateTransferData(transferData);
    } catch (error) {
      snackbar.showError(error.message);
    }
  }, [
    bundleConfig,
    cherryPick,
    enforceOrder,
    liquids,
    parameterMode,
    plateOrigins,
    platesByName,
    snackbar,
  ]);

  const allPlatesSelected = useMemo(() => {
    return areAllPlateTypesSelected(platesByName, uniquePlateNames);
  }, [platesByName, uniquePlateNames]);

  const atLeastOneValidTransfer = useMemo(
    () => isAtLeastOneTransferValid(cherryPick),
    [cherryPick],
  );

  // Create the caption "Please select <what's missing> before simulating"
  const tooltipCaption = useMemo(() => {
    if (!allPlatesSelected) {
      return NOT_ALL_PLATES_SELECTED_MSG;
    }
    if (!atLeastOneValidTransfer) {
      return NO_VALID_TRANSFERS_MSG;
    }

    return 'Save changes';
  }, [allPlatesSelected, atLeastOneValidTransfer]);

  const handleCancel = useCallback(() => {
    logEvent('cancel-parameter-changes', ScreenRegistry.CHERRY_PICKER);
    parameterMode.onCancel(false);
  }, [parameterMode]);

  return (
    <div className={classes.actions}>
      <Button
        color="inherit"
        variant="contained"
        className={classes.actionBtn}
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Tooltip title={tooltipCaption}>
        <span>
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            onClick={handleOK}
            disabled={!allPlatesSelected || !atLeastOneValidTransfer}
          >
            Done
          </Button>
        </span>
      </Tooltip>
    </div>
  );
}

const useStyles = makeStylesHook({
  actions: {
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  actionBtn: {
    margin: '0.5rem',
  },
});

export default CherryPickBottomBar;
