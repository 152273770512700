import React, { useCallback, useMemo, useState } from 'react';

import IconHelp from '@mui/icons-material/HelpOutline';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import cx from 'classnames';

import {
  formatParameterValue,
  getInputsForElement,
} from 'client/app/apps/template-workflows/editor/helpers';
import { Input } from 'client/app/apps/template-workflows/TemplateWorkflowEditor';
import { indexBy } from 'common/lib/data';
import { Connection, Element, ServerSideElementInstance } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import Checkbox from 'common/ui/components/Checkbox';
import Switch from 'common/ui/components/Switch';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

/**
 * Render list of inputs to choose from.
 */
export default function AddDialogInputTable({
  elementInstance,
  connections,
  selectedInputs,
  onInputChange,
  onSelectInput,
  onUnselectInput,
}: {
  elementInstance: ServerSideElementInstance & {
    name: string;
    element: Element;
  };
  connections: Connection[];
  selectedInputs: Input[];
  onInputChange: (input: Input) => void;
  onSelectInput: (input: Input) => void;
  onUnselectInput: (input: Input) => void;
}) {
  const classes = useStyles();

  const elementInstanceId = elementInstance.Id;
  const elementInstanceName = elementInstance.name;
  const element = elementInstance.element;

  const inputs = useMemo(
    () => getInputsForElement(element, elementInstanceId, elementInstanceName),
    [element, elementInstanceId, elementInstanceName],
  );

  const selectedInputsByInputName: {
    [inputName: string]: Input;
  } = useMemo(() => {
    const selectedInputsForCurrentInstance = selectedInputs.filter(
      input => input.ElementInstanceId === elementInstanceId,
    );
    return indexBy(selectedInputsForCurrentInstance, 'InputName');
  }, [elementInstanceId, selectedInputs]);

  const inputsToDisplay = useMemo(() => {
    const parameterConfigMap = element.configuration?.parameters;
    if (!parameterConfigMap) {
      return inputs;
    }
    const visibleInputs = inputs.filter(
      input => parameterConfigMap[input.InputName]?.isVisible !== false,
    );
    return visibleInputs;
  }, [element.configuration, inputs]);

  return (
    <Table size="small" className={classes.tableRoot}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.checkboxCell} />
          <TableCell>Input name</TableCell>
          <TableCell>Collapse</TableCell>
          <TableCell>Displayed name</TableCell>
          <TableCell className={classes.infoCellHeader}>Info</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {inputsToDisplay.map(input => {
          const selectedInput = selectedInputsByInputName[input.InputName];
          return (
            <AddDialogInputTableRow
              key={input.InputName}
              connections={connections}
              elementInstance={elementInstance}
              input={selectedInput ?? input}
              isChecked={selectedInput !== undefined}
              onInputChange={onInputChange}
              onSelectInput={onSelectInput}
              onUnselectInput={onUnselectInput}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}

function AddDialogInputTableRow({
  connections,
  elementInstance,
  input,
  isChecked,
  onInputChange,
  onSelectInput,
  onUnselectInput,
}: {
  connections: Connection[];
  elementInstance: ServerSideElementInstance;
  input: Input;
  isChecked: boolean;
  onInputChange: (input: Input) => void;
  onSelectInput: (input: Input) => void;
  onUnselectInput: (input: Input) => void;
}) {
  const classes = useStyles();

  // Nice hack - we only want to focus the text field for editing the display name
  // when user selects the input. If we set autoFocus to true on the TextField, the
  // last textfield is autofocused which scrolls the dialog down on show, which feels
  // strange.
  const [autoFocus, setAutoFocus] = useState(false);

  const isDisabled = useMemo(() => {
    return connections.some(
      connection =>
        connection.Target.ElementInstance === input.ElementInstanceId &&
        connection.Target.ParameterName === input.InputName,
    );
  }, [connections, input.InputName, input.ElementInstanceId]);

  const onChecked = useCallback(() => {
    if (isDisabled) {
      return;
    }
    if (!isChecked) {
      setAutoFocus(true);
      onSelectInput(input);
    } else {
      onUnselectInput(input);
    }
  }, [input, isChecked, isDisabled, onSelectInput, onUnselectInput]);

  const onCollapsedChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      onInputChange({ ...input, Collapsed: e.target.checked }),
    [input, onInputChange],
  );

  const onDisplayNameChange = useTextFieldChange(
    useCallback(
      (DisplayName: string) => onInputChange({ ...input, DisplayName }),
      [input, onInputChange],
    ),
  );

  return (
    <TableRow
      className={cx({ [classes.disabledRow]: isDisabled })}
      title={isDisabled ? 'This parameter is already wired' : undefined}
    >
      <TableCell>
        <Checkbox checked={isChecked} onChange={onChecked} disabled={isDisabled} />
      </TableCell>
      <TableCell className={classes.inputNameCell} onClick={onChecked}>
        {input.configName ?? input.InputName}
      </TableCell>
      <TableCell>
        {isChecked && (
          <Switch
            color="primary"
            onChange={onCollapsedChange}
            checked={input.Collapsed ?? false}
          />
        )}
      </TableCell>
      <TableCell>
        {isChecked && (
          <TextField
            variant="standard"
            autoFocus={autoFocus}
            fullWidth
            value={input.DisplayName}
            onChange={onDisplayNameChange}
          />
        )}
      </TableCell>
      <TableCell>
        <Tooltip
          placement="left"
          title={
            <div>
              <p>
                Default value:
                {formatParameterValue(elementInstance.Parameters[input.InputName])}
              </p>
            </div>
          }
        >
          <span className={classes.infoCell}>
            <IconHelp />
          </span>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

const useStyles = makeStylesHook({
  tableRoot: {
    tableLayout: 'fixed',
    marginBottom: '2rem',
  },
  checkboxCell: {
    width: '48px',
  },
  infoCellHeader: {
    width: '48px',
  },
  inputNameCell: {
    cursor: 'pointer',
  },
  infoCell: {
    color: Colors.GREY_40,
  },
  elementTitle: {
    marginTop: '1rem',
  },
  disabledRow: {
    '& td': {
      color: Colors.GREY_40,
    },
  },
});
