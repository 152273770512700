import React, { useCallback, useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { MUTATION_CREATE_SYNTHETIC_FILE } from 'client/app/api/gql/mutations';
import { QUERY_SYNTHETIC_DATA_GENERATOR } from 'client/app/api/gql/queries';
import { FiletreeLink } from 'common/types/filetree';
import Button from 'common/ui/components/Button';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import LinearProgress from 'common/ui/components/LinearProgress';
import { DialogProps } from 'common/ui/hooks/useDialog';

// Synthetic data files created by this dialog are stored in a directory named
// .syntheticData/${deviceId}/filename.  The filenames have a timestamp embedded in them, so this
// should be sufficient to avoid collisions.
const UPLOAD_DIRECTORY = '.syntheticData';

type SyntheticDataDialogProps = {
  deviceId: DeviceId;
  deviceName: string;
  labwareName: string;
} & DialogProps<FiletreeLink | null>;

export default function SyntheticDataDialog({
  isOpen,
  onClose,
  deviceId,
  deviceName,
  labwareName,
}: SyntheticDataDialogProps) {
  const { loading, error, data } = useQuery(QUERY_SYNTHETIC_DATA_GENERATOR, {
    variables: {
      deviceId,
    },
  });
  const [createSyntheticFile, { loading: mutationRunning }] = useMutation(
    MUTATION_CREATE_SYNTHETIC_FILE,
    {
      onCompleted: data => onClose(data.createSyntheticFile),
    },
  );

  const [value, setValue] = useState('');
  // When we load the default values, we want to set the initial state to them, but we don't want to
  // overwrite what the user has typed in, so we use an effect.  We execute it either when new data
  // comes in (which happens on first load) or when the deviceId changes.
  useEffect(() => {
    if (!data) {
      return;
    }
    setValue(JSON.stringify(data.deviceDataGenerator.defaultParameters, null, 2));
  }, [data, deviceId]);

  const handleConfirm = useCallback(async () => {
    await createSyntheticFile({
      variables: {
        deviceId,
        filetreePath: [UPLOAD_DIRECTORY, deviceId].join('/') as FiletreePath,
        parameters: JSON.parse(value),
      },
    });
  }, [createSyntheticFile, deviceId, value]);

  if (error) {
    return <GraphQLErrorPanel error={error} />;
  }
  return (
    <Dialog open={isOpen} onClose={() => onClose(null)} maxWidth="lg">
      <DialogTitle>
        <h2>
          Generate Synthetic Data from {deviceName} for {labwareName}
        </h2>
      </DialogTitle>
      <DialogContent>
        {loading || mutationRunning ? (
          <LinearProgress />
        ) : (
          <TextField
            multiline
            fullWidth
            variant="filled"
            margin="normal"
            label="Generator parameters"
            maxRows={20}
            value={value}
            onChange={e => setValue(e.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} variant="primary" disabled={mutationRunning}>
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
}
