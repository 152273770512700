import React, { useCallback, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import Download from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';

import { QUERY_EXAMPLE_WORKFLOWS } from 'client/app/api/gql/queries';
import * as xhr from 'client/app/lib/xhr';
import { downloadBlob } from 'common/lib/download';
import IconButton from 'common/ui/components/IconButton';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Tooltip from 'common/ui/components/Tooltip';

const DownloadExampleWorkflows = () => {
  const [fetching, setFetching] = useState(false);

  const get = xhr.useGet();
  const { showError } = useSnackbarManager();
  const [executeQuery, _] = useLazyQuery(QUERY_EXAMPLE_WORKFLOWS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const handleClick = useCallback(async () => {
    try {
      setFetching(true);
      const response = await executeQuery();
      if (response.error) {
        throw response.error;
      }

      const ids: string[] = [];
      let examples = response.data?.exampleWorkflows;
      while (examples?.items.length) {
        ids.push(...examples.items.map(e => e.workflowId));
        const next = await response.fetchMore({
          variables: { after: examples.pageInfo.endCursor },
        });
        examples = next.data.exampleWorkflows;
      }

      const download = await get(`/web/v2/workflow/download?ids=${ids.join('&ids=')}`);
      const blob = await download.blob();

      downloadBlob(blob, 'exampleWorkflows.zip');
    } catch (e) {
      showError(e.message);
    } finally {
      setFetching(false);
    }
  }, [executeQuery, get, showError]);

  return (
    <Tooltip
      title={
        fetching ? 'Download in progress' : 'Download example workflows (internal only)'
      }
    >
      {fetching ? (
        <CircularProgress size={18} />
      ) : (
        <IconButton
          size="xsmall"
          color="inherit"
          icon={<Download />}
          onClick={handleClick}
        />
      )}
    </Tooltip>
  );
};

export default DownloadExampleWorkflows;
