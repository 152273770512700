import React from 'react';

import DialogContent from '@mui/material/DialogContent';

type Props = {
  children: React.ReactNode;
  dialog: boolean;
  className?: string;
};

/*
 * Wrap content in a dialog content if this component is a dialog.
 * Otherwise, pass along the original div.
 *
 * Sample usage:
 * <DialogWrap dialog={isDialog} className={classes.linearProgress}> <LinearProgress /> </DialogWrap>
 */
export default function DialogWrap(props: Props) {
  return props.dialog ? (
    <DialogContent className={props.className}>{props.children}</DialogContent>
  ) : (
    <div className={props.className}>{props.children}</div>
  );
}
