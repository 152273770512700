import React, { useCallback, useEffect, useMemo } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

import CollapsibleEditWellsButton from 'client/app/components/Parameters/PlateLayout/CollapsibleEditWellsButton';
import Layer from 'client/app/components/Parameters/PlateLayout/Layer';
import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import Button from 'common/ui/components/Button';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import SimpleDialog from 'common/ui/components/Dialog/SimpleDialog';
import { DraggableList } from 'common/ui/components/DragDrop/DraggableList';
import useDialog, { DialogProps } from 'common/ui/hooks/useDialog';

export default function LiquidLevels() {
  const {
    plateLayers,
    addNewLayer,
    isReadonly,
    selectedWells,
    clearSelectedWells,
    reorderLayers,
    notifyDeletedLiquids,
    clearNotifyDeletedLiquids,
  } = usePlateLayoutEditorContext();

  const canRemoveWells = useMemo(() => {
    let canRemove = false;
    if (selectedWells.length) {
      const wells = plateLayers
        .filter(layer => !layer.isReadOnly)
        .flatMap(layer => layer.wellSets)
        .flatMap(wellSet => wellSet.wells);
      for (const well of selectedWells) {
        const isInLiquid = wells.some(
          value => value.x === well.col && value.y === well.row,
        );
        canRemove = isInLiquid;
        if (isInLiquid) {
          break;
        }
      }
    }

    return canRemove;
  }, [plateLayers, selectedWells]);

  const [confirmClearWellsDialog, openConfirmClearWellsDialog] =
    useDialog(ConfirmationDialog);

  const [deletedLiquidsDialog, openDeletedLiquidsDialog] =
    useDialog(DeletedLiquidsDialog);

  useEffect(() => {
    if (notifyDeletedLiquids) {
      void openDeletedLiquidsDialog({
        liquidNames: notifyDeletedLiquids,
      });
    }
  }, [clearNotifyDeletedLiquids, notifyDeletedLiquids, openDeletedLiquidsDialog]);

  const handleClearWells = async () => {
    const isConfirmed = await openConfirmClearWellsDialog({
      action: 'clear',
      isActionDestructive: true,
      object: 'selected wells',
      additionalMessage: 'These wells will be cleared from all liquids in all layers.',
    });
    if (isConfirmed) {
      clearSelectedWells();
    }
  };

  return (
    <>
      <Wrapper>
        <StyledButton
          disabled={isReadonly}
          startIcon={<AddIcon />}
          variant="tertiary"
          onClick={() => addNewLayer()}
        >
          New Layer
        </StyledButton>
        <InnerWrapper>
          <DraggableList
            items={plateLayers}
            getIdFromItem={layer => layer.id}
            onChangeOrder={reorderLayers}
            renderItem={(layer, dragProps) => (
              <Layer layer={layer} dragProps={dragProps} />
            )}
          />
        </InnerWrapper>
      </Wrapper>
      <CollapsibleEditWellsButton
        visible={canRemoveWells}
        mode="clear"
        onClick={() => handleClearWells()}
        disabled={isReadonly}
      />
      {confirmClearWellsDialog}
      {deletedLiquidsDialog}
    </>
  );
}

function DeletedLiquidsDialog({
  liquidNames,
  ...props
}: { liquidNames: string[] } & DialogProps<void>) {
  const { clearNotifyDeletedLiquids } = usePlateLayoutEditorContext();

  const onSubmit = useCallback(() => {
    clearNotifyDeletedLiquids();
    props.onClose();
  }, [clearNotifyDeletedLiquids, props]);

  return (
    <SimpleDialog
      title="Liquids Deleted"
      submitButtonLabel="OK"
      hideCancel
      content={
        liquidNames.length === 1 ? (
          <>
            Liquid <b>{liquidNames[0]}</b> was removed as it had no assigned wells.
          </>
        ) : (
          <>
            Liquids <b>{liquidNames.join(', ')}</b> were removed as they had no assigned
            wells.
          </>
        )
      }
      onSubmit={onSubmit}
      {...props}
    />
  );
}

const Wrapper = styled('div')({
  padding: '6px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflowX: 'hidden',
});

const InnerWrapper = styled('div')({
  overflowY: 'auto',
});

const StyledButton = styled(Button)({
  width: '100%',
  '& > svg': {
    fontSize: '16px',
  },
});
