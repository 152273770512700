import { useMemo } from 'react';

import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';

/**
 * [Derived Factor](https://www.notion.so/synthace/Derived-Factors-583f9a5745df4128a598ff161180d557?pvs=4) = factor that is either Numerical Derived Factor or a Categorical Derived Factor
 *
 * [Numerical Derived Factor](https://www.notion.so/synthace/Derived-numerical-factors-223071d6d02e414db500da868640ad47?pvs=4) = factor that does not define levels but instead they are computed from the "derivingExpression" out of level values of Numerical Factors used as variables in that expression.
 *
 * [Categorical Derived Factor](https://www.notion.so/synthace/Derived-categorical-factors-4d7c2f33346b471291095060d1368802?pvs=4) = factor which is a mapping between its levels (free flow text) and levels of a "sourceFactor" where the later can be either a Numeric Factor or a Categorical Factor but cannot be a Derived Factor itself.
 */
export default function useHasDerivedFactors(factorId: string | undefined): {
  isPartOfDerivingExpression: boolean;
  isDerivationSourceFactor: boolean;
  derivedNumericalFactor?: string;
  derivedCategoricalFactor?: string;
} {
  const factors = useWorkflowBuilderSelector(state => state.factors);

  return useMemo(() => {
    if (!factorId) {
      return {
        isPartOfDerivingExpression: false,
        isDerivationSourceFactor: false,
      };
    }

    const derivedNumericalFactor = factors?.find(
      factor =>
        factor.variableTypeName === 'derived' &&
        factor.derivingExpression?.includes(factorId),
    );
    const derivedCategoricalFactor = factors?.find(
      factor =>
        factor.variableTypeName === 'derived' && factor.sourceFactor?.id === factorId,
    );

    return {
      isPartOfDerivingExpression: !!derivedNumericalFactor,
      isDerivationSourceFactor: !!derivedCategoricalFactor,
      derivedNumericalFactor: derivedNumericalFactor?.displayName,
      derivedCategoricalFactor: derivedCategoricalFactor?.displayName,
    };
  }, [factorId, factors]);
}
