import { SpreadsheetState } from 'common/rules/evaluateSpreadsheetRules';
import { SetColumnRequiredAction } from 'common/types/spreadsheet';

export function setColumnsBooleanField(
  // This will be updated later with additional boolean action types
  action: SetColumnRequiredAction,
  isConditionMet: boolean,
  result: SpreadsheetState,
): SpreadsheetState {
  const shouldExecute = isConditionMet || action.revertIfConditionNotMet;
  if (!shouldExecute) {
    return result;
  }

  const value = getValue(action);

  const fieldValue = isConditionMet ? value : !value;
  const { sheetName, columnNames } = action;
  for (const columnName of columnNames) {
    result.sheets[sheetName].columns[columnName].isRequired = fieldValue;
  }
  return result;
}

function getValue(action: SetColumnRequiredAction) {
  switch (action.type) {
    case 'set-columns-required':
      return true;
    default:
      return false;
  }
}
