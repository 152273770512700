import * as FilesApi from 'client/app/api/FilesApi';
import { FileMetadata } from 'client/app/api/FilesApi';
import { PATH_SEGMENT_SEPARATOR } from 'client/app/lib/file-browser/normalizePath';
import { FileBrowserFileSingleSelection } from 'client/app/lib/file-browser/types';
import { DATA_REPO_SCHEME } from 'common/lib/download';

export default function getPathObject(
  path: string,
  item: FileMetadata,
): FileBrowserFileSingleSelection {
  let localPath = path + item.name;
  if (item.type === FilesApi.DIRECTORY) {
    localPath += PATH_SEGMENT_SEPARATOR;
  }
  return {
    localPath,
    pathWithScheme: item.ftl ?? DATA_REPO_SCHEME + localPath,
  };
}
