import React, { useCallback } from 'react';

import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import DialogContentText from '@mui/material/DialogContentText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';

import { useCherryPickContext } from 'client/app/apps/cherry-picker/CherryPickContext';
import { ScreenRegistry } from 'client/app/registry';
import Switch from 'common/ui/components/Switch';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

export default function EnforceOrderSettings() {
  const { enforceOrder, setEnforceOrder, isReadonly } = useCherryPickContext();

  const handleToggleEnforceOrder = useCallback(() => {
    logEvent('set-enforce-order', ScreenRegistry.CHERRY_PICKER);
    setEnforceOrder(prev => !prev);
  }, [setEnforceOrder]);

  return (
    <Paper>
      <CardHeader title="Transfer order" />
      <CardContent>
        <DialogContentText gutterBottom>
          Synthace can optimise the transfer order to multichannel and reduce the liquid
          handling run time. By turning this off the transfers will occur one after the
          other in the original input order.
        </DialogContentText>
        <FormControlLabel
          // Label is the opposite as `enforceOrder`, thus the negation. This was driven by UX:
          // We're explicitly saying that Synthace is *optimising* things for users.
          control={
            <Switch
              checked={!enforceOrder}
              onChange={handleToggleEnforceOrder}
              color="primary"
            />
          }
          label="Optimise transfer order"
          disabled={isReadonly}
        />
      </CardContent>
    </Paper>
  );
}
