import React, { useCallback, useEffect, useState } from 'react';

import Button from '@mui/material/Button';

import * as PlateTypesApi from 'client/app/api/PlateTypesApi';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { createMixPreview } from 'common/lib/mix';
import { MixPreview } from 'common/types/mixPreview';
import { PlateType } from 'common/types/plateType';
import MixScreen from 'common/ui/components/simulation-details/mix/MixScreen';
import UIBox from 'common/ui/components/UIBox';

function LocalDevApp() {
  const [mixPreview, setMixPreview] = useState<MixPreview | null>(null);
  const [plateTypes, setPlateTypes] = useState<PlateType[]>([]);
  const [lastUploadTime, setLastUploadTime] = useState<string>('');
  const loadPlatesAllCached = PlateTypesApi.useLoadAllCached();

  const handleUploadFiles = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (!files) {
      // no files selected, user cancelled
      return;
    }
    processFileUpload(files)
      .then(({ actionsFile, layoutFile }: any) => {
        const newMixPreview = createMixPreview(layoutFile, actionsFile);
        if (newMixPreview.valid) {
          setLastUploadTime(`${Date.now()}`);
          setMixPreview(newMixPreview);
        } else {
          // TODO: better error handling for invalid mix graph
          throw new Error('Mix graph files are not valid');
        }
      })
      .catch((e: Error) => {
        // TODO: better error handling
        alert(e.message);
      });
  }, []);

  useEffect(() => {
    (async () => {
      const plateTypes = await loadPlatesAllCached();
      setPlateTypes(plateTypes);
    })();
  }, [loadPlatesAllCached]);

  const isPlaybackEnabled = useFeatureToggle('SIMULATION_PLAYBACK');

  return (
    <>
      <UIBox padding="l">
        <Button variant="outlined" component="label" color="primary">
          Load from disk
          <input
            type="file"
            hidden
            multiple
            onChange={handleUploadFiles}
            accept=".json"
          />
        </Button>
      </UIBox>
      {mixPreview && (
        // The key prop below is used to reset the component state when new files are uploaded.
        <MixScreen
          key={lastUploadTime}
          plateTypes={plateTypes}
          mixPreview={mixPreview}
          isPlaybackEnabled={isPlaybackEnabled}
        />
      )}
    </>
  );
}

// Promisify FileReader
function readFileAsJSON<T = object>(file: File): Promise<T> {
  return new Promise<T>((accept, reject) => {
    const reader = new FileReader();

    reader.onloadend = function () {
      let data: T | undefined = undefined;

      if (reader.result && typeof reader.result === 'string') {
        try {
          data = JSON.parse(reader.result);
        } catch (e) {
          return reject(e);
        }
      }

      if (!data) {
        return reject('Uploaded file is not a valid JSON file.');
      }

      accept(data);
    };
    reader.readAsText(file);
  });
}

async function processFileUpload(fileList: FileList) {
  const files = Array.from(fileList);
  const actionsFileJSON = files.find(f => f.name === 'actions.json');
  const layoutFileJSON = files.find(f => f.name === 'layout.json');

  if (!actionsFileJSON || !layoutFileJSON) {
    throw new Error('Please select two files named actions.json and layout.json.');
  }
  const [actionsFile, layoutFile] = await Promise.all([
    readFileAsJSON(actionsFileJSON),
    readFileAsJSON(layoutFileJSON),
  ]);

  return {
    actionsFile,
    layoutFile,
  };
}

export default LocalDevApp;
