/**
 * Converts the item passed to a string and then tries to parse it as a JSON.
 **/
export default function isJSON(item: unknown) {
  const stringifiedItem: string = typeof item !== 'string' ? JSON.stringify(item) : item;

  try {
    const parsedItem = JSON.parse(stringifiedItem);
    if (typeof parsedItem === 'object' && parsedItem !== null) {
      return true;
    }
  } catch (e) {
    return false;
  }

  return false;
}
