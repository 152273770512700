import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material/';

export default function DOEIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" fill="none">
        <path
          d="M17.5 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm0 11a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm-11 0a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm0-11a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM3.25 8.25h12.5v12.5H3.25V8.25zm1.5 1.5v9.5h9.5v-9.5h-9.5zM23 3.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm0 11a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm-11 0a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm0-11a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM8.75 2.75h12.5v12.5H8.75V2.75zm1.5 1.5v9.5h9.5v-9.5h-9.5zm-.72.28l-5.5 5.497-1.06-1.061 5.5-5.497L9.53 4.53zm0 11.004l-5.5 5.496-1.06-1.06 5.5-5.497 1.06 1.061zM20.28 4.53l-5.5 5.497-1.06-1.061 5.5-5.497 1.06 1.061zm0 11.004l-5.5 5.496-1.06-1.06 5.5-5.497 1.06 1.061z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
