import React, { useCallback, useContext, useMemo, useState } from 'react';

import Snackbar, { SnackbarVariant } from 'common/ui/components/Snackbar';

export type SnackbarProps = {
  isOpen: boolean;
  message: string;
  variant: SnackbarVariant;
};

// We only need to expose a function to open a Snackbar. It will close itself
// on backdrop click, timeout, or by pressing the (X) on the snackbar.
export type SnackbarManager = {
  showError: (message: string) => void;
  showInfo: (message: string) => void;
  showWarning: (message: string) => void;
  showSuccess: (message: string) => void;
};

const throwNoContext = (_message: string) => {
  throw new Error('No SnackbarContext provider registered.');
};

const DEFAULT_CONTEXT: SnackbarManager = {
  showError: throwNoContext,
  showInfo: throwNoContext,
  showSuccess: throwNoContext,
  showWarning: throwNoContext,
};

const SnackBarContext = React.createContext<SnackbarManager>(DEFAULT_CONTEXT);

export function useSnackbarManager() {
  return useContext(SnackBarContext);
}

export default function SnackbarManagerProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [snackbar, setSnackbar] = useState<SnackbarProps>({
    isOpen: false,
    message: '',
    variant: 'error',
  });

  const showError = useCallback(
    (message: string) => setSnackbar({ isOpen: true, message, variant: 'error' }),
    [setSnackbar],
  );

  const showWarning = useCallback(
    (message: string) => setSnackbar({ isOpen: true, message, variant: 'warning' }),
    [setSnackbar],
  );

  const showInfo = useCallback(
    (message: string) => setSnackbar({ isOpen: true, message, variant: 'info' }),
    [setSnackbar],
  );

  const showSuccess = useCallback(
    (message: string) => setSnackbar({ isOpen: true, message, variant: 'success' }),
    [setSnackbar],
  );

  const closeSnackbar = useCallback(() => {
    setSnackbar({ ...snackbar, isOpen: false });
  }, [setSnackbar, snackbar]);

  const ctx = useMemo<SnackbarManager>(
    () => ({
      showWarning,
      showSuccess,
      showInfo,
      showError,
    }),
    [showError, showInfo, showSuccess, showWarning],
  );

  return (
    <SnackBarContext.Provider value={ctx}>
      {children}
      <Snackbar {...snackbar} onClose={closeSnackbar} />
    </SnackBarContext.Provider>
  );
}
