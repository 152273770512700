import React, { ReactElement } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Tooltip from 'common/ui/components/Tooltip';

type Props = {
  action: ReactElement;
  tooltipText: string;
  label?: string;
};

const EntityCardAction = ({ action, tooltipText, label = 'Action' }: Props) => {
  return (
    <Action>
      <Typography variant="overline">{label}</Typography>
      <Tooltip title={tooltipText}>{action}</Tooltip>
    </Action>
  );
};

const Action = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(5, 6),
}));

export default EntityCardAction;
