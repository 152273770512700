import React, { useCallback, useMemo } from 'react';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { getTimeZones, TimeZone } from '@vvo/tzdb';

type TimezoneSelectProps = {
  // A string matching a timezone per the IANA tz database.
  defaultValue: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

export default function TimezoneSelect(props: TimezoneSelectProps) {
  const { defaultValue, onChange, disabled } = props;
  const handleChange = useCallback(
    (_event: any, value: TimeZone | null) => {
      value && onChange(value.name);
    },
    [onChange],
  );

  // Returns formatted and up to date timezones. Using this means we do
  // not need to create/format/update a hardcoded list.
  const timeZoneOptions = useMemo(() => getTimeZones(), []);

  const getOptionLabel = (option: TimeZone) => {
    return `UTC ${option.currentTimeFormat}`;
  };

  const currentTimezone = defaultValue
    ? timeZoneOptions.find(zone => zone.name === defaultValue)
    : undefined;

  return (
    <>
      <Typography color="textPrimary" gutterBottom>
        Timezone
      </Typography>
      <Autocomplete
        value={currentTimezone}
        options={timeZoneOptions}
        onChange={handleChange}
        disabled={disabled}
        disableClearable
        filterOptions={createFilterOptions()}
        getOptionLabel={getOptionLabel}
        renderInput={params => <TextField variant="standard" {...params} />}
        placeholder="Select timezone"
      />
    </>
  );
}
