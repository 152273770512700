import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const HelpIcon = React.memo<SvgIconProps>(function HelpIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg fill="none" viewBox="0 0 24 24">
        <path fill="currentColor" d="M19 2h2v16h-2zM3 5h2v14H3z" />
        <path
          d="M21 17H6a2 2 0 00-2 2v0a2 2 0 002 2h15M21 3H6a2 2 0 00-2 2v0"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          fill="currentColor"
          d="M19 18h2v2h-2zM11.044 11.524c0-.523.059-.935.177-1.235.118-.305.31-.568.575-.79.265-.222.465-.424.602-.607.14-.186.209-.39.209-.612 0-.54-.233-.811-.698-.811a.648.648 0 00-.521.236c-.133.158-.203.37-.21.64H9.363c.007-.717.233-1.274.677-1.671.444-.401 1.067-.602 1.869-.602.798 0 1.416.185 1.853.553.44.37.66.894.66 1.574 0 .297-.059.568-.177.811a2.74 2.74 0 01-.57.747l-.617.575a1.445 1.445 0 00-.365.52c-.068.176-.106.4-.113.672h-1.536zm-.22 1.633c0-.265.095-.482.284-.65.194-.172.434-.258.72-.258.287 0 .525.086.715.258.193.168.29.385.29.65a.838.838 0 01-.29.655c-.19.168-.428.253-.715.253-.286 0-.526-.085-.72-.253a.845.845 0 01-.284-.655z"
        />
      </svg>
    </SvgIcon>
  );
});

export default HelpIcon;
