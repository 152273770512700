import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DownloadNotCloud = React.memo<SvgIconProps>(function DataSetIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M14.5705 4H9.42963V9.57143H4.90918L12.0001 17L19.091 9.57143H14.5705V4Z"
        fill="currentColor"
      />
      <path d="M5.00012 19H19.0001V21H5.00012V19Z" fill="currentColor" />
    </SvgIcon>
  );
});
