import React from 'react';

import PlateVisualization from 'client/app/apps/plate-constructor/PlateVisualization';
import { reportError } from 'client/app/lib/errors';
import { PlateType } from 'common/types/plateType';

export default class PlateDrawingNew extends React.Component<
  { plate: PlateType },
  { error: boolean }
> {
  state = {
    error: false,
  };
  componentDidCatch(error: any, info: any) {
    console.warn(error, info);
    reportError(error);
  }
  static getDerivedStateFromError() {
    return { error: true };
  }
  render() {
    if (this.state.error) {
      return <p>This plate cannot be visualized</p>;
    }
    return <PlateVisualization plate={this.props.plate} />;
  }
}
