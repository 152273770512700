import React from 'react';

import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import FolderIcon from '@mui/icons-material/Folder';
import IndeterminateCheckBox from '@mui/icons-material/IndeterminateCheckBox';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { DIRECTORY, FILE, FileMetadata } from 'client/app/api/FilesApi';
import FileIcon from 'client/app/components/FileBrowser/FileIcon';
import getPathObject from 'client/app/components/FileBrowser/getPathObject';
import { FileBrowserFileSingleSelection } from 'client/app/lib/file-browser/types';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  path: string;
  item: FileMetadata;
  isSelected: boolean;
  isInDisplayPath: boolean;
  onClick: (item: FileMetadata, path: string) => void;
  onToggleSelected: (path: FileBrowserFileSingleSelection) => void;
  selectMultiple: boolean;
};

export default React.memo(function FileListItem(props: Props) {
  const { item, path, selectMultiple, isSelected, isInDisplayPath } = props;

  const classes = useStyles();

  const isFile = item.type === FILE;

  const onClick = () => {
    if (selectMultiple && isFile) {
      props.onToggleSelected(getPathObject(path, item));
      return;
    }

    props.onClick(item, path);
  };

  let checkboxIcon;
  if (selectMultiple) {
    if (isSelected) {
      if (isFile) {
        checkboxIcon = <CheckBox className={classes.icon} />;
      } else {
        checkboxIcon = <IndeterminateCheckBox className={classes.icon} />;
      }
    } else {
      checkboxIcon = <CheckBoxOutlineBlank className={classes.icon} />;
    }
  }

  return (
    <div
      className={cx({
        [classes.isInDisplayPath]: isInDisplayPath,
        [classes.selectedEntry]: isSelected,
      })}
    >
      <Button
        fullWidth
        className={cx(classes.button, {
          [classes.directory]: item.type === DIRECTORY,
        })}
        onClick={onClick}
        title={item.name}
      >
        {checkboxIcon}
        {isFile ? <FileIcon file={item} /> : <FolderIcon className={classes.icon} />}
        <div className={classes.textContainer}>
          {item.title && (
            <Typography className={cx(classes.text, classes.title)}>
              {item.title}
            </Typography>
          )}
          <Typography variant="caption" component="p" className={classes.text}>
            {item.name}
          </Typography>
        </div>
      </Button>
    </div>
  );
});

const useStyles = makeStylesHook(theme => ({
  button: {
    borderRadius: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    maxWidth: '100%',
  },
  directory: {
    '& $icon': {
      color: Colors.BLUE,
    },
  },
  textContainer: {
    flex: 1,
    overflow: 'hidden',
    color: theme.palette.text.primary,
  },
  text: {
    textAlign: 'start',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    color: Colors.GREY_40,
    marginRight: theme.spacing(4),
  },
  isInDisplayPath: {
    background: Colors.GREY_20,
  },
  selectedEntry: {
    background: Colors.LIGHT_BLUE,
  },
}));
