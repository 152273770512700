import React, { useCallback, useMemo } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { Input } from 'client/app/apps/template-workflows/TemplateWorkflowEditor';
import { groupBy } from 'common/lib/data';
import {
  DraggableList,
  DragHandleProps,
} from 'common/ui/components/DragDrop/DraggableList';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  inputs: Input[];
  setInputs: (inputs: Input[]) => void;
  onEditInstanceInputs: (instanceName: string) => void;
};

type Group = {
  elementName: string;
  inputs: Input[];
};

function getIdFromGroup(group: Group) {
  return group.elementName;
}

/**
 * Renders list of template inputs that can be reordered.
 */
export default function InputList({ inputs, setInputs, onEditInstanceInputs }: Props) {
  // Inputs are grouped by the instance name, so inputs for each instance are shown together.
  // The order of inputs is preserved thanks to groupBy preserving the order.
  const groups = useMemo(
    () =>
      Object.entries(groupBy(inputs, 'elementName')).map(([elementName, inputs]) => ({
        elementName,
        inputs,
      })),
    [inputs],
  );

  // reordering inputs inside one group
  const onGroupInputsChange = useCallback(
    (group: Group) => {
      const newGroups = groups.map(g =>
        g.elementName === group.elementName ? group : g,
      );
      const newInputs = newGroups.flatMap(group => group.inputs);
      setInputs(newInputs);
    },
    [groups, setInputs],
  );

  // reordering groups
  const onGroupOrderChanged = useCallback(
    (newGroups: Group[]) => {
      const newInputs = newGroups.flatMap(group => group.inputs);
      setInputs(newInputs);
    },
    [setInputs],
  );
  const onEditClick = useCallback(
    (group: Group) => onEditInstanceInputs(group.elementName),
    [onEditInstanceInputs],
  );

  const renderGroupCard = useCallback(
    (group: Group, dragHandleProps: DragHandleProps) => (
      <GroupCard
        group={group}
        dragHandleProps={dragHandleProps}
        onGroupInputsChange={onGroupInputsChange}
        onEdit={onEditClick}
      />
    ),
    [onEditClick, onGroupInputsChange],
  );

  if (inputs.length === 0) {
    return (
      <Typography gutterBottom>
        <em>No inputs have been selected to be configurable.</em>
      </Typography>
    );
  }

  return (
    <DraggableList
      renderItem={renderGroupCard}
      getIdFromItem={getIdFromGroup}
      items={groups}
      onChangeOrder={onGroupOrderChanged}
    />
  );
}

function GroupCard({
  dragHandleProps,
  group,
  onGroupInputsChange,
  onEdit,
}: {
  dragHandleProps: DragHandleProps;
  group: Group;
  onGroupInputsChange: (group: Group) => void;
  onEdit: (group: Group) => void;
}) {
  const classes = useStyles();
  const onEditClick = useCallback(() => onEdit(group), [group, onEdit]);
  return (
    <Card className={cx(classes.card, classes.groupCard)}>
      <CardHeader
        className={classes.cardHeader}
        avatar={dragHandleProps.dragIcon}
        title={group.elementName}
        action={
          <IconButton onClick={onEditClick} size="large">
            <EditIcon />
          </IconButton>
        }
      />
      <CardContent className={classes.cardContent}>
        <GroupCardInputs group={group} onGroupInputsChange={onGroupInputsChange} />
      </CardContent>
    </Card>
  );
}

function getIdFromInput(input: Input) {
  return input.InputName;
}

function GroupCardInputs({
  group,
  onGroupInputsChange,
}: {
  group: Group;
  onGroupInputsChange: (group: Group) => void;
}) {
  const onOrderChanged = useCallback(
    (inputs: Input[]) => onGroupInputsChange({ ...group, inputs }),
    [group, onGroupInputsChange],
  );

  const renderItem = useCallback(
    (input: Input, dragHandleProps: any) => (
      <InputCard input={input} dragHandleProps={dragHandleProps} />
    ),
    [],
  );

  return (
    <DraggableList
      items={group.inputs}
      getIdFromItem={getIdFromInput}
      renderItem={renderItem}
      onChangeOrder={onOrderChanged}
    />
  );
}

function InputCard({
  dragHandleProps,
  input,
}: {
  dragHandleProps: DragHandleProps;
  input: Input;
}) {
  const classes = useStyles();
  const title =
    input.InputName === input.DisplayName
      ? input.DisplayName
      : `${input.DisplayName} (${input.InputName})`;

  return (
    <Card className={classes.card}>
      <CardHeader avatar={dragHandleProps.dragIcon} title={title} />
    </Card>
  );
}

const useStyles = makeStylesHook({
  card: {
    maxWidth: '100%',
  },
  groupCard: {
    marginBottom: '1rem',
  },
  cardHeader: {
    paddingBottom: 0,
  },
  cardContent: {
    paddingTop: 0,
  },
});
