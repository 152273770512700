import { useState } from 'react';

/**
 * Custom hook encapsulating boilerplate code for setting the anchor
 * element when opening and closing a MUI Popover or Menu (which uses
 * a Popover internally).
 *
 * Example usage:
 *
 * const { popoverAnchorElement, onShowPopover, onHidePopover } = usePopover();
 * ...
 * <IconButton onClick={onShowPopover} ... />
    <Popover
      anchorEl={popoverAnchorElement}
      open={isPopoverOpen}
      onClose={onHidePopover}
    >
      <PopoverItem ... />
      <PopoverItem ... />
    </Popover>
 */
export function usePopover() {
  const [popoverAnchorElement, setPopoverAnchorElement] = useState<null | HTMLElement>(
    null,
  );

  const isPopoverOpen = Boolean(popoverAnchorElement);

  const onShowPopover = (event: React.MouseEvent<HTMLElement>) => {
    setPopoverAnchorElement(event.currentTarget);
  };

  const onHidePopover: () => void = () => {
    setPopoverAnchorElement(null);
  };

  return {
    popoverAnchorElement,
    isPopoverOpen,
    onShowPopover,
    onHidePopover,
  };
}
