import React from 'react';

import DropdownWithParameterValues from 'client/app/components/Parameters/DropdownWithParameterValues';
import { DropdownAdditionalProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { indexBy } from 'common/lib/data';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import Dropdown from 'common/ui/filaments/Dropdown';

type Props = ParameterEditorBaseProps<string> &
  Omit<DropdownAdditionalProps, 'editor'> & {
    /** The type of parameters existing values should be taken from - this can be from an override. */
    anthaType: string;
    onChange: (value?: string) => void;
    useDynamicOptions?: boolean;
    disableUnderline?: boolean;
  };

/**
 * A Dropdown component that can optionally source options from values
 * already provided to parameters of the same antha type within the
 * Workflow Builder.
 *
 * Consumes AutocompleteParameterValuesContext so needs to be nested under
 * AutocompleteParameterValuesContextProvider.
 */

export default function DropdownEditor(props: Props) {
  if (props.useDynamicOptions) {
    return (
      <DropdownWithParameterValues
        anthaType={props.anthaTypeOverride || props.anthaType}
        additionalOptions={props.options}
        value={props.value}
        onChange={props.onChange}
        isDisabled={props.isDisabled}
        isRequired={props.isRequired}
        disableUnderline={props.disableUnderline}
      />
    );
  }

  // The value and label might be different here, but the MUI Select component
  // in Dropdown will complain if the real value (props.value) isnt present
  // in the given options, and won't show it. So get the correct label of it here.
  const idOptionMap = indexBy(props.options, 'value');
  const labelForValue = (value: string) => idOptionMap[value]?.label ?? '';

  return (
    <Dropdown
      valueLabel={labelForValue(props.value ?? '')}
      onChange={props.onChange}
      isDisabled={props.isDisabled}
      isRequired={props.isRequired}
      options={props.options}
      placeholder={props.placeholder}
      disableUnderline={props.disableUnderline}
    />
  );
}
