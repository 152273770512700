import React, { useMemo } from 'react';

import MuiSwitch, { SwitchProps } from '@mui/material/Switch';
import withStyles from '@mui/styles/withStyles';

import Colors from 'common/ui/Colors';

export default function Switch(props: SwitchProps) {
  const StyledSwitch = useMemo(
    () =>
      withStyles({
        colorPrimary: {
          '&$checked': {
            color: Colors.BLUE_20,
          },
          '&$checked + $track': {
            backgroundColor: Colors.BLUE_20,
          },
        },
        checked: {}, // Needed to override the color
        track: {}, // Needed to override the color
        thumb: {}, // Needed to override the color
      })(MuiSwitch),
    [],
  );

  return <StyledSwitch {...props} />;
}
