import React, { useMemo } from 'react';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import WavesIcon from '@mui/icons-material/Waves';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  useLiquidIdentifierOption,
  useLiquidIdentifierPathSelection,
} from 'client/app/components/Parameters/PlateLayout/liquidIdentifierUtils';
import { LiquidNameOrGroupName } from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import { formatMeasurementObj, pluralize } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import Tooltip from 'common/ui/components/Tooltip';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import LiquidsIcon from 'common/ui/icons/LiquidsIcon';

type Props = {
  liquids: LiquidNameOrGroupName[];
};

/**
 * Component that renders out a list of liquids and the hierarchy of sub-liquids
 * in a nested menu format.
 */
export default function LiquidIdentifierList({ liquids }: Props) {
  const { path, addToPath, removeFromPath } = useLiquidIdentifierPathSelection();

  const { parent, visibleOptions: visibleLiquids } = useLiquidIdentifierOption(
    liquids,
    path,
  );

  return (
    <Listbox>
      {visibleLiquids.map(liquid => {
        return liquid === parent ? (
          <LiquidIdentifierListHeader
            key={liquid.id}
            liquid={liquid}
            onClick={removeFromPath}
          />
        ) : (
          <LiquidIdentifierListItem
            key={liquid.id}
            liquid={liquid}
            onClick={() => addToPath(liquid.id)}
          />
        );
      })}
    </Listbox>
  );
}

function LiquidIdentifierListHeader(props: {
  liquid: LiquidNameOrGroupName;
  onClick: () => void;
}) {
  const { onClick, liquid } = props;
  return (
    <StyledListHeader
      onClick={() => {
        onClick();
      }}
    >
      <ChevronLeft />
      <OptionName variant="caption" component="span">
        {liquid.type === 'group' ? 'Liquids in ' : 'Sub-components of '}
        <b>{liquid.name}</b>
      </OptionName>
    </StyledListHeader>
  );
}

type LiquidIdentifierListItemProps = {
  liquid: LiquidNameOrGroupName;
  onClick: () => void;
};

function LiquidIdentifierListItem(props: LiquidIdentifierListItemProps) {
  const { onClick, liquid } = props;
  const target = liquid.target
    ? 'concentration' in liquid.target
      ? formatMeasurementObj(liquid.target.concentration)
      : formatMeasurementObj(liquid.target.volume)
    : null;

  const liquidNameCopy =
    liquid.replicates && liquid.replicates > 1
      ? `${liquid.replicates} x ${liquid.name}`
      : liquid.name;

  const liquidContentsLength = useMemo(() => {
    if (liquid.contents && liquid.contents.length > 0) {
      if (liquid.type === 'group') {
        return liquid.contents.reduce((acc, curr) => acc + (curr.replicates ?? 1), 0);
      }
      return liquid.contents.length;
    }
    return undefined;
  }, [liquid.contents, liquid.type]);

  return (
    <StyledListItem>
      <ItemInfoContainer>
        {liquid.type === 'name' ? <LiquidsIcon /> : <WavesIcon />}
        <ItemNameAndTarget>
          <LiquidNameCopy variant="caption">{liquidNameCopy}</LiquidNameCopy>
          {target && <LiquidTargetCopy variant="caption">{target}</LiquidTargetCopy>}
        </ItemNameAndTarget>
      </ItemInfoContainer>
      {liquidContentsLength && (
        <Tooltip
          title={pluralize(
            liquidContentsLength,
            liquid.type === 'group' ? 'Liquid' : 'Sub-component',
          )}
          placement="right"
        >
          <ExpandItemButton
            size="small"
            onClick={() => {
              onClick();
            }}
          >
            <Typography variant="caption">{liquidContentsLength}</Typography>
            <ChevronRight />
          </ExpandItemButton>
        </Tooltip>
      )}
    </StyledListItem>
  );
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  display: 'flex',
  position: 'static',
  marginBottom: theme.spacing(3),
  '&:first-of-type': {
    marginTop: theme.spacing(3),
  },
}));

const StyledListHeader = styled(ListItemButton)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    color: theme.palette.text.primary,
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gap: theme.spacing(2),
    padding: theme.spacing(3, 4),
    alignItems: 'center',
    borderBottom: `1px solid ${Colors.GREY_20}`,
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    },
  },
}));

const LiquidNameCopy = styled(TypographyWithTooltip)({
  fontWeight: 600,
});

const LiquidTargetCopy = styled(TypographyWithTooltip)(({ theme }) => ({
  fontSize: '10px',
  marginTop: theme.spacing(1),
}));

const ExpandItemButton = styled(Button)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'end',
    marginLeft: 'auto',
    minWidth: 'fit-content',
    padding: '6px',
    paddingLeft: '12px',
    borderRadius: '12px',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    '& .MuiSvgIcon-root': {
      fontSize: '14px',
    },
  },
}));

const ItemInfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  padding: '6px 12px',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
    color: theme.palette.text.secondary,
  },
  overflow: 'hidden',
}));

const ItemNameAndTarget = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto auto',
});

const Listbox = styled(List)({
  padding: 0,
});

const OptionName = styled(TypographyWithTooltip)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '& > b': {
    color: theme.palette.text.primary,
  },
}));
