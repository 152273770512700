import { useCallback, useMemo } from 'react';

import { useElementInstance } from 'client/app/apps/workflow-builder/lib/useElementInstance';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { FiltrationDesign } from 'common/types/filtration';

export default function useFiltrationDesignParameter() {
  const dispatch = useWorkflowBuilderDispatch();
  const parameters = useWorkflowBuilderSelector(state => state.parameters);
  const elementInstance = useElementInstance();

  const [parameterName, parameterValue] = useMemo(() => {
    const config = elementInstance?.element.configuration?.parameters;

    if (config) {
      for (const parameterName in config) {
        if (
          config[parameterName].editor.type === EditorType.FILTER_PLATE_PROTOCOL_DESIGN
        ) {
          return [
            parameterName,
            parameters[elementInstance?.name]?.[parameterName] as FiltrationDesign,
          ];
        }
      }
    }

    return [null, null];
  }, [
    elementInstance?.element.configuration?.parameters,
    elementInstance?.name,
    parameters,
  ]);

  const setValue = useCallback(
    (value: FiltrationDesign) => {
      if (elementInstance && parameterName) {
        dispatch({
          type: 'updateParameter',
          payload: {
            instanceName: elementInstance.name,
            parameterName,
            value,
          },
        });
      }
    },
    [dispatch, elementInstance, parameterName],
  );

  return useMemo(
    () => ({
      name: parameterName,
      value: parameterValue,
      setValue,
    }),
    [parameterName, parameterValue, setValue],
  );
}
