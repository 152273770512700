import React, { useCallback, useMemo, useRef, useState } from 'react';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import KebabIcon from '@mui/icons-material/MoreVertOutlined';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { sanitize } from 'dompurify';

import isWorkflowReadonly from 'client/app/apps/workflow-builder/lib/isWorkflowReadonly';
import { LayoutOptions } from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import {
  formatLabwareTypeName,
  fromNamedPlate,
  getNamedPlates,
  getNumberOfPositions,
  isNamedPlate,
  LabwareType,
  NamedPlate,
  SIMPLE_LABWARE_TYPE,
} from 'client/app/state/LabwarePreference';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { useEnterKeyPress } from 'common/ui/hooks/useEnterKeyPress';

// Returns false if one of the namedPlates already has a name identical to the query
// or if query is an invalid entry (length is zero, or contains only whitespace).
// This check is case sensitive and will remove whitespace from the query before comparing.
function isNewPlateNameValid(namedPlates: NamedPlate[], query: string = '') {
  return (
    query.trim().length > 0 && // query.trim().length checks for appended or prepended whitespace
    !namedPlates.map(plate => fromNamedPlate(plate)).some(plate => plate === query.trim())
  );
}

// Trims trailing whitespace from the name.
function sanitisePlateName(name: string = '') {
  return sanitize(name.trim());
}

// When using the default auto transition, the value for the autocomplete appeared in the input
// of the popover on exit, and we don't want that. We set the styles here so it exits
// with no animation. For the other values, these were determined inspecting the opacity
// transition of the component in dev tools, and are approximations.
// See the MUI Grow component implementation for more details.
const popoverTransitionDurations = { appear: 232, enter: 232, exit: 0 };

type LabwareSelectorProps = {
  /** Valid layout options for the current configuration. This should come from antha
   * core service and is used for disabling labware type selection if invalid. */
  validLayoutOptions: LayoutOptions | undefined;
};

type LabwareOption = {
  /** The labwaretype for the option. */
  value: LabwareType;
  /** Used to conditionally render the NamedPlatePlaceholder option. */
  isNewNamedPlate?: boolean;
};

export default function LabwareSelector({ validLayoutOptions }: LabwareSelectorProps) {
  const classes = useStyles();
  const dispatch = useWorkflowBuilderDispatch();
  const { selectedLabwareType, namedPlates, isReadonly, numberOfPositions } =
    useWorkflowBuilderSelector(state => ({
      selectedLabwareType: state.labwarePreferenceType,
      namedPlates: getNamedPlates(state),
      isReadonly: isWorkflowReadonly(state.editMode, state.source),
      numberOfPositions: state.labwarePreferenceType
        ? getNumberOfPositions(state, state.labwarePreferenceType)
        : 0,
    }));

  const [inputValue, setInputValue] = useState<string>();
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = !!anchorEl;

  const handleClick = () => {
    setAnchorEl(popoverRef.current);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setInputValue('');
  }, []);

  const labelForDeckItemChip = selectedLabwareType
    ? `[${numberOfPositions}] ${formatLabwareTypeName(selectedLabwareType)}`
    : 'Select Labware';

  const options = useMemo<LabwareOption[]>(
    () =>
      [...SIMPLE_LABWARE_TYPE, ...namedPlates]
        .sort((a, b) => formatLabwareTypeName(a).localeCompare(formatLabwareTypeName(b)))
        .filter(labwareType =>
          inputValue
            ? labwareType.toLowerCase().includes(inputValue.toLowerCase())
            : true,
        )
        .map(labwareType => ({
          value: labwareType,
          isNewNamedPlate: false,
        })),
    [inputValue, namedPlates],
  );

  const getOptionDisabled = (option: LabwareOption) => {
    if (!validLayoutOptions) {
      return true; // Always disable if we don't get a valid layout.
    }
    if (isNamedPlate(option.value)) {
      return false;
    }
    return validLayoutOptions?.[option.value] ? false : true;
  };

  const handleSelectOption = useCallback(
    (option: LabwareOption) => {
      if (option) {
        if (option.isNewNamedPlate) {
          const originalName = option.value;
          dispatch({ type: 'addAndSetNamedPlate', payload: originalName });
        } else {
          const updatedValue =
            option.value === selectedLabwareType ? undefined : option.value;
          dispatch({ type: 'setLabwarePreferenceType', payload: updatedValue });
        }
      }
      handleClose();
    },
    [dispatch, handleClose, selectedLabwareType],
  );

  return (
    <>
      <div className={classes.chip} onClick={handleClick} ref={popoverRef}>
        <div
          className={cx(
            classes.label,
            selectedLabwareType ? classes.labelActive : classes.labelInactive,
          )}
        >
          <Typography variant="body2" noWrap>
            {labelForDeckItemChip}
          </Typography>
        </div>
        <div
          className={cx(
            classes.endIcon,
            selectedLabwareType ? classes.endIconActive : classes.endIconInactive,
          )}
        >
          {open ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
        </div>
      </div>
      <Popover
        classes={{
          paper: classes.popover,
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'right',
        }}
        transitionDuration={popoverTransitionDurations}
      >
        <header>
          <InputBase
            className={classes.searchInput}
            autoFocus
            placeholder="Search or create"
            onChange={event => {
              setInputValue(event.target.value);
            }}
          />
          {!inputValue ? (
            <Typography
              className={classes.helperText}
              color="textPrimary"
              variant="caption"
            >
              [x] Deck positions with allocated labware
            </Typography>
          ) : !isReadonly ? (
            <NamedPlatePlaceholder
              namedPlate={inputValue as NamedPlate}
              onSelect={handleSelectOption}
              disabled={!validLayoutOptions}
            />
          ) : (
            options.length === 0 && (
              <Typography
                variant="body1"
                sx={{ color: Colors.TEXT_SECONDARY, padding: '14px 8px 2px' }}
              >
                No options
              </Typography>
            )
          )}
        </header>
        <main>
          {options.map(option => (
            <LabwareListItem
              key={option.value}
              labwareType={option.value}
              onSelect={handleSelectOption}
              disabled={getOptionDisabled(option)}
            />
          ))}
        </main>
      </Popover>
    </>
  );
}

type NamedPlatePlaceholderProps = {
  namedPlate: NamedPlate;
  disabled: boolean;
  onSelect: (option: LabwareOption) => void;
};

const NamedPlatePlaceholder = React.memo(function NamedPlatePlaceholder({
  namedPlate,
  disabled,
  onSelect: handleSelect,
}: NamedPlatePlaceholderProps) {
  const classes = useStyles();

  return (
    <div className={classes.namedPlatePlaceholder}>
      <Button
        className={classes.createNameButton}
        size="small"
        variant="outlined"
        color="inherit"
        disabled={disabled}
        onClick={() => handleSelect({ value: namedPlate, isNewNamedPlate: true })}
      >
        Create Name
      </Button>
      <Typography
        color="textPrimary"
        variant="body2"
        sx={{ opacity: disabled ? 0.4 : 1 }}
      >
        {namedPlate}
      </Typography>
    </div>
  );
});

type LabwareListItemProps = {
  labwareType: LabwareType;
  disabled: boolean;
  onSelect: (option: LabwareOption) => void;
};

const LabwareListItem = React.memo(function LabwareListItem({
  labwareType,
  disabled,
  onSelect: handleSelect,
}: LabwareListItemProps) {
  const classes = useStyles();

  const { selectedLabwareType, numberOfPositions, isReadonly } =
    useWorkflowBuilderSelector(state => ({
      selectedLabwareType: state.labwarePreferenceType,
      numberOfPositions: getNumberOfPositions(state, labwareType),
      isReadonly: isWorkflowReadonly(state.editMode, state.source),
    }));

  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = !!anchorEl;

  const onKebabMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(popoverRef.current);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const isItemSelected = selectedLabwareType === labwareType;

  const isLabwareANamedPlate = isNamedPlate(labwareType);
  const label = formatLabwareTypeName(labwareType);

  return (
    <div
      className={cx(classes.listItem, {
        [classes.selectedItem]: isItemSelected,
        [classes.disabledItem]: disabled,
      })}
      ref={popoverRef}
      onClick={() => handleSelect({ value: labwareType, isNewNamedPlate: false })}
    >
      <Typography
        className={cx({ [classes.selectedText]: isItemSelected })}
        color="textPrimary"
        variant="subtitle2"
        noWrap
      >
        {`[${numberOfPositions}] ${label}`}
      </Typography>
      {isLabwareANamedPlate && !isReadonly && (
        <>
          <IconButton
            className={cx(isItemSelected ? classes.whiteIcon : classes.blackIcon)}
            size="xsmall"
            icon={<KebabIcon />}
            onClick={onKebabMenuClick}
          />
          <Popover
            classes={{
              paper: cx(classes.popover, classes.namedPlatePopover),
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClick={event => event.stopPropagation()} // Don't let clickevents propagate
            onMouseDown={event => event.stopPropagation()} // Don't let mouseevents propagate
          >
            <NamedPlatesSelectorPopover namedPlate={labwareType} onClose={onClose} />
          </Popover>
        </>
      )}
    </div>
  );
});

type NamedPlatesSelectorPopoverProps = {
  /** The named plate to which the popover applies. */
  namedPlate: NamedPlate;
  onClose: () => void;
};

const NamedPlatesSelectorPopover = React.memo(function NamedPlatesSelectorPopover(
  props: NamedPlatesSelectorPopoverProps,
) {
  const classes = useStyles();
  const dispatch = useWorkflowBuilderDispatch();
  const { namedPlate, onClose } = props;
  const { namedPlates } = useWorkflowBuilderSelector(state => ({
    namedPlates: getNamedPlates(state),
  }));
  // We have to format the namedPlate to it's original string here.
  // TODO - See if we could tidy up and not need to do this.
  const originalPlateName = fromNamedPlate(namedPlate);
  const [updatedPlateName, setUpdatedPlateName] = useState(originalPlateName);

  const onTextFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedPlateName(e.target.value);
  }, []);

  const isNewNameUnique = isNewPlateNameValid(namedPlates, updatedPlateName);
  const isNewNameNotEmpty = updatedPlateName.length > 0;
  const isNewNameSameAsOldName = originalPlateName === updatedPlateName;

  const canSaveName = isNewNameSameAsOldName || (isNewNameUnique && isNewNameNotEmpty);

  let helperText = '';
  if (!isNewNameSameAsOldName && !isNewNameUnique) {
    helperText = 'Prefix already exists';
  }
  if (!isNewNameSameAsOldName && !isNewNameNotEmpty) {
    helperText = 'Prefix cannot be empty';
  }

  const handleNameChange = useCallback(() => {
    if (!canSaveName) {
      return;
    }
    dispatch({
      type: 'renameNamedPlate',
      payload: {
        oldName: originalPlateName,
        newName: sanitisePlateName(updatedPlateName),
      },
    });
    onClose();
  }, [canSaveName, dispatch, onClose, originalPlateName, updatedPlateName]);

  const handleDeleteNamedPlate = useCallback(() => {
    dispatch({
      type: 'removeNamedPlate',
      payload: originalPlateName,
    });
    onClose();
  }, [dispatch, onClose, originalPlateName]);

  const onKeyPress = useEnterKeyPress(handleNameChange);

  return (
    <TextField
      className={classes.namedPlatePopoverTextField}
      autoFocus
      error={!canSaveName}
      helperText={helperText}
      fullWidth
      onChange={onTextFieldChange}
      onKeyPress={onKeyPress}
      value={updatedPlateName}
      size="small"
      variant="standard"
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <IconButton
            icon={<DeleteOutlinedIcon className={classes.blackIcon} />}
            onClick={handleDeleteNamedPlate}
            size="small"
          />
        ),
        classes: {
          input: cx(classes.namedPlatePopoverTextFieldInput, {
            [classes.namedPlatePopoverTextFieldError]: !canSaveName,
          }),
        },
      }}
    />
  );
});

const POPOVER_WIDTH = 278;

const useStyles = makeStylesHook(({ spacing, palette }) => ({
  blackIcon: {
    color: Colors.TEXT_PRIMARY,
  },
  whiteIcon: {
    color: Colors.PRIMARY_CONTRAST,
  },
  chip: {
    cursor: 'pointer',
    display: 'flex',
  },
  createNameButton: {
    height: '24px',
    whiteSpace: 'nowrap',
    minWidth: 'min-content',
    borderColor: palette.divider,
    marginBottom: spacing(2),
  },
  endIcon: {
    alignItems: 'center',
    borderRadius: '0 16px 16px 0',
    display: 'flex',
    height: '32px',
    paddingRight: spacing(2),
  },
  endIconActive: {
    color: Colors.PRIMARY_CONTRAST,
    backgroundColor: Colors.PRIMARY_DARK,
  },
  endIconInactive: {
    backgroundColor: Colors.GREY_0,
    border: `1px solid ${Colors.GREY_30}`,
    borderLeft: 'none',
  },
  helperText: {
    display: 'block',
    height: '28px',
    marginTop: spacing(2),
  },
  label: {
    alignItems: 'center',
    borderRadius: '16px 0 0 16px',
    display: 'flex',
    height: '32px',
    padding: spacing(2, 3, 2, 5),
  },
  labelActive: {
    backgroundColor: Colors.GREY_70,
    color: Colors.PRIMARY_CONTRAST,
  },
  labelInactive: {
    backgroundColor: Colors.GREY_0,
    border: `1px solid ${Colors.GREY_30}`,
  },
  popover: {
    borderRadius: spacing(3),
    padding: spacing(4, 3),
    width: POPOVER_WIDTH,

    '& > main': {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(2),
    },
  },
  searchInput: {
    height: '24px',
    width: '100%',
    padding: spacing(0, 3),
    margin: spacing(2, 0, 5),
    backgroundColor: Colors.GREY_10,
  },
  selectedText: {
    color: Colors.PRIMARY_CONTRAST,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '32px',
    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '16px',
    width: '100%',
    padding: '8px',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#e2eeff',
      borderColor: '#e2eeff',
    },
  },
  selectedItem: {
    backgroundColor: Colors.BLUE_80,
    '&:hover': {
      backgroundColor: Colors.BLUE_80,
    },
  },
  disabledItem: {
    pointerEvents: 'none',
    opacity: 0.4,
  },
  namedPlatePopover: {
    padding: 0,
    marginTop: spacing(2),
  },
  namedPlatePlaceholder: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(2),
    gap: '10px',
  },
  namedPlatePopoverTextField: {
    minWidth: '278px',
    padding: spacing(2, 2, 2, 3),
  },
  namedPlatePopoverTextFieldInput: {
    width: '100%',
    backgroundColor: Colors.ACTION_PRIMARY_MAIN_HOVER,
    padding: spacing(1, 2),
  },
  namedPlatePopoverTextFieldError: {
    border: `1px solid ${Colors.ERROR_MAIN}`,
  },
}));
