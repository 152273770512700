import React, { useCallback } from 'react';

import KitchenIcon from '@mui/icons-material/KitchenOutlined';
import OpenInNew from '@mui/icons-material/OpenInNew';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

type Props = {
  simulationFiletreePath: FiletreePath;
};

export default function FileBrowserLink({ simulationFiletreePath }: Props) {
  const onClick = useCallback(() => {
    window.open(`/#/file-browser/-/${simulationFiletreePath}/`, '_blank');
  }, [simulationFiletreePath]);

  return (
    <ListItem onClick={onClick}>
      <KitchenIcon />
      <MuiListItemText
        primary={
          <Stack direction="row" alignItems="center" gap={3}>
            <Typography variant="h5" color="inherit">
              File Browser
            </Typography>
            <LinkIcon />
          </Stack>
        }
      />
    </ListItem>
  );
}

const ListItem = styled(MuiListItem)(({ theme }) => ({
  cursor: 'pointer',
  padding: 0,
  gap: theme.spacing(3),

  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.primary.dark,
  },
}));

const LinkIcon = styled(OpenInNew)({
  fontSize: '18px',
});
