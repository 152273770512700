import React from 'react';

import Download from '@mui/icons-material/Download';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Button from 'common/ui/components/Button';

type Props = {
  loading: boolean;
  disabled: boolean;
  onDownload: () => Promise<void>;
};

export default function DataExport({ loading, disabled, onDownload: download }: Props) {
  return (
    <Container>
      <Typography variant="h2">Data Export</Typography>

      <Stack gap={3}>
        <Typography variant="h3">Auto structured data for this execution</Typography>
        <Typography variant="body1" color="textSecondary">
          Download a zip file comprising the plates’ structured analytical data in csv
          format
        </Typography>
      </Stack>

      <Button
        color="primary"
        variant="secondary"
        startIcon={loading ? <CircularProgress size="14px" /> : <Download />}
        onClick={download}
        disabled={loading || disabled}
      >
        Download execution data
      </Button>
    </Container>
  );
}

const Container = styled(Card)(({ theme }) => ({
  maxWidth: 'unset',
  margin: 0,
  padding: theme.spacing(6, 7),

  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(2),
  boxShadow:
    '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px 0px rgba(0, 0, 0, 0.20)',

  gap: theme.spacing(5),
}));
