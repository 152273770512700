import React, { useCallback, useEffect, useRef, useState } from 'react';

import { QueryResult, useQuery } from '@apollo/client';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import Box from '@mui/material/Box';
import MuiChip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { QUERY_SIMULATION_COUNTS_BY_WORKFLOW_ID } from 'client/app/api/gql/queries';
import Panel from 'client/app/apps/workflow-builder/panels/Panel';
import {
  A_TO_D_DURATION,
  containerCardTransitionExit,
} from 'client/app/apps/workflow-builder/panels/simulations/deleteAnimation';
import SimulationCardItem from 'client/app/apps/workflow-builder/panels/simulations/simulation-card/SimulationCardItem';
import SimulationCountInfo from 'client/app/apps/workflow-builder/panels/simulations/SimulationCountInfo';
import SimulationErrorPanel from 'client/app/apps/workflow-builder/panels/simulations/SimulationErrorPanel';
import {
  isSimulationPlaceholder,
  PollingErrors,
  SimulationPart,
} from 'client/app/apps/workflow-builder/panels/simulations/useSimulations';
import { simulationsForWorkflowQuery } from 'client/app/gql';
import usePagination from 'client/app/hooks/usePagination';
import useElementConfigs from 'client/app/lib/workflow/useElementConfigs';
import { ScreenRegistry } from 'client/app/registry';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import NoEntitiesBanner from 'common/assets/NoEntitiesBanner';
import { pluralizeWord } from 'common/lib/format';
import { PageInfo } from 'common/server/graphql/pagination';
import { ElementConfigurationSpec } from 'common/types/elementConfiguration';
import Colors from 'common/ui/Colors';
import FilterChipWithDateRange, {
  DateRange,
} from 'common/ui/components/FilterChip/FilterChipWithDateRange';
import FilterChipWithSwitch from 'common/ui/components/FilterChip/FilterChipWithSwitch';
import SearchField, { SearchFieldLogProps } from 'common/ui/components/SearchField';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDebounce from 'common/ui/hooks/useDebounce';

const Chip = withStyles({
  clickable: {
    '&:hover, &:focus': {
      backgroundColor: Colors.BLUE_90,
    },
  },
  colorPrimary: {
    backgroundColor: Colors.BLUE_100,
    color: Colors.PRIMARY_CONTRAST,
  },
  deleteIcon: {
    '&:hover': {
      color: Colors.DARK_TEXT_PRIMARY,
    },
  },
})(MuiChip);

const DEBOUNCE_SCROLL_UNREAD_SIMULATIONS_DELAY_MS = 200;

type SimulationsPanelProps = {
  onClose: () => void;
  className: string;
  errorPanelClassName: string;
  simulationsForWorkflowQueryResult: QueryResult<simulationsForWorkflowQuery>;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  favorited: boolean;
  setFilterFavorited: React.Dispatch<React.SetStateAction<boolean>>;
  filterSuccessfulSimulations: boolean;
  setFilterSuccessful: React.Dispatch<React.SetStateAction<boolean>>;
  filterDateRange: DateRange;
  setFilterDateRange: React.Dispatch<React.SetStateAction<DateRange>>;
  unreadSimulations: number;
  setUnreadSimulations: React.Dispatch<React.SetStateAction<number>>;
  workflowId: WorkflowId;
  pollingErrors: PollingErrors;
};

export default React.memo(function SimulationsPanel(props: SimulationsPanelProps) {
  const classes = useStyles();
  const {
    onClose,
    className,
    errorPanelClassName,
    simulationsForWorkflowQueryResult,
    searchQuery,
    setSearchQuery,
    favorited,
    setFilterFavorited,
    filterSuccessfulSimulations,
    setFilterSuccessful,
    filterDateRange,
    setFilterDateRange,
    unreadSimulations,
    setUnreadSimulations,
    workflowId,
    pollingErrors,
  } = props;

  const elementSetID = useWorkflowBuilderSelector(state => state.elementSet)?.id;
  const dispatch = useWorkflowBuilderDispatch();

  const { elementConfigs } = useElementConfigs(elementSetID);

  const scrollableRef = useRef<HTMLDivElement>(null);
  const handleClearUnreadSimulations = useCallback(() => {
    setUnreadSimulations(0);
  }, [setUnreadSimulations]);

  const { loading, data: countInfo } = useQuery(QUERY_SIMULATION_COUNTS_BY_WORKFLOW_ID, {
    variables: { id: workflowId },
  });
  const allSimulationCount = countInfo?.workflow.allSimulationCount;
  const starredSimulationCount = countInfo?.workflow.starredSimulationCount;

  const handleScrollToTop = useCallback(() => {
    if (scrollableRef?.current) {
      scrollableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  const resetIfScrolledToTop = useDebounce(
    useCallback(() => {
      setUnreadSimulations(count => {
        if (count > 0 && scrollableRef.current?.scrollTop === 0) {
          return 0;
        }
        return count;
      });
    }, [setUnreadSimulations]),
    DEBOUNCE_SCROLL_UNREAD_SIMULATIONS_DELAY_MS,
  );

  /* When the simulations panel is open, we should clear the unread simulations. */
  useEffect(() => {
    if (unreadSimulations > 0) {
      resetIfScrolledToTop();
    }
  }, [resetIfScrolledToTop, unreadSimulations]);

  const [selectedSimulation, setSelectedSimulation] = useState<SimulationPart | null>(
    null,
  );
  const handleCloseErrorPanel = useCallback(() => {
    setSelectedSimulation(null);
  }, []);

  const handleCloseSimulationsPanel = useCallback(() => {
    handleCloseErrorPanel();
    onClose();
  }, [handleCloseErrorPanel, onClose]);

  const onFilterFavorited = useCallback(
    (value: boolean) => {
      logEvent(
        'filter-simulations-by-favorited',
        ScreenRegistry.WORKFLOW,
        value ? 'favorited' : 'none',
      );
      setFilterFavorited(value);
      handleCloseErrorPanel();
    },
    [handleCloseErrorPanel, setFilterFavorited],
  );

  const onFilterSuccessfulSimulations = useCallback(
    (value: boolean) => {
      logEvent(
        'filter-simulations-by-successful-simulations',
        ScreenRegistry.WORKFLOW,
        value ? 'successful-only' : 'all',
      );
      setFilterSuccessful(value);
      handleCloseErrorPanel();
    },
    [handleCloseErrorPanel, setFilterSuccessful],
  );

  const onSetSearchQuery = useCallback(
    (query: string) => {
      setSearchQuery(query);
      handleCloseErrorPanel();
    },
    [handleCloseErrorPanel, setSearchQuery],
  );

  const onFilterByDateRange = useCallback(
    (value: DateRange) => {
      logEvent('filter-simulations-by-date', ScreenRegistry.WORKFLOW);
      setFilterDateRange(value);
      handleCloseErrorPanel();
    },
    [handleCloseErrorPanel, setFilterDateRange],
  );

  const handleClickViewErrorPanel = useCallback(
    (simulation: SimulationPart) => {
      simulation.id === selectedSimulation?.id
        ? setSelectedSimulation(null)
        : setSelectedSimulation(simulation);
      dispatch({
        type: 'showErroredElementInstance',
        payload: { element: simulation.errors?.[0]?.context?.element },
      });
    },
    [dispatch, selectedSimulation?.id],
  );

  const searchFieldLogProps: SearchFieldLogProps = {
    logAction: 'search-simulations-panel',
    logCategory: ScreenRegistry.WORKFLOW,
  };

  return (
    <>
      <Panel
        className={className}
        title="Simulations"
        onClose={handleCloseSimulationsPanel}
        scrollableRef={scrollableRef}
        onScroll={resetIfScrolledToTop}
        panelContent="Simulations"
        filters={
          <>
            <SimulationCountInfo
              allSimulationCount={allSimulationCount}
              starredSimulationCount={starredSimulationCount}
              loading={loading}
              className={classes.count}
            />
            <SearchField
              label="Search"
              onQueryChange={onSetSearchQuery}
              defaultValue={searchQuery}
              fullWidth
              className={classes.search}
              searchFieldLogProps={searchFieldLogProps}
            />
            <div className={classes.filter}>
              <FilterChipWithSwitch
                heading="Filter by Status"
                activeChipLabel="Successful"
                inactiveChipLabel="Status"
                filterValue={filterSuccessfulSimulations}
                onFilter={onFilterSuccessfulSimulations}
                className={classes.chip}
                size="small"
              />
              <FilterChipWithSwitch
                heading="Filter by Stars"
                activeChipLabel="Starred"
                inactiveChipLabel="Stars"
                filterValue={favorited}
                onFilter={onFilterFavorited}
                className={classes.chip}
                size="small"
              />
              <FilterChipWithDateRange
                heading="Filter by Date Range"
                defaultChipLabel="Date Range"
                filterValue={filterDateRange}
                onFilter={onFilterByDateRange}
                className={classes.chip}
                size="small"
              />
            </div>
          </>
        }
      >
        <Box p={3}>
          {unreadSimulations > 0 && (
            <Box display="flex" justifyContent="center">
              <Chip
                icon={<ArrowUpwardOutlinedIcon fontSize="small" />}
                clickable
                color="primary"
                onClick={handleScrollToTop}
                onDelete={handleClearUnreadSimulations}
                label={`${unreadSimulations} new ${pluralizeWord(
                  unreadSimulations,
                  'simulation',
                )}`}
                className={classes.scrollToTopChip}
              />
            </Box>
          )}
          <SimulationsContainer
            scrollableRef={scrollableRef}
            searchQuery={searchQuery}
            favorited={favorited}
            filterSuccessfulSimulations={filterSuccessfulSimulations}
            filterDateRange={filterDateRange}
            simulationsForWorkflowQueryResult={simulationsForWorkflowQueryResult}
            onClickViewErrorPanel={handleClickViewErrorPanel}
            selectedSimulation={selectedSimulation}
            elementConfigs={elementConfigs}
            workflowId={props.workflowId}
            pollingErrors={pollingErrors}
          />
        </Box>
      </Panel>
      {selectedSimulation && (
        <SimulationErrorPanel
          simulation={selectedSimulation}
          className={errorPanelClassName}
          onClose={handleCloseErrorPanel}
          elementConfigs={elementConfigs}
        />
      )}
    </>
  );
});

type SimulationsProps = {
  scrollableRef: React.RefObject<HTMLDivElement>;
  searchQuery: string;
  favorited: boolean;
  filterSuccessfulSimulations: boolean;
  filterDateRange: DateRange;
  simulationsForWorkflowQueryResult: QueryResult<simulationsForWorkflowQuery>;
  onClickViewErrorPanel: (simulation: SimulationPart) => void;
  selectedSimulation: SimulationPart | null;
  elementConfigs?: Record<string, ElementConfigurationSpec | null>;
  workflowId: WorkflowId;
  pollingErrors: PollingErrors;
};

function SimulationsContainer(props: SimulationsProps) {
  const {
    scrollableRef,
    searchQuery,
    favorited,
    filterSuccessfulSimulations,
    filterDateRange,
    simulationsForWorkflowQueryResult,
    onClickViewErrorPanel,
    selectedSimulation,
    elementConfigs,
    workflowId,
    pollingErrors,
  } = props;
  const classes = useStyles();

  const {
    loading: isInitialLoading,
    data,
    error,
    fetchMore,
    variables,
  } = simulationsForWorkflowQueryResult;

  const simulations = data?.simulationsForWorkflow.items || [];
  const pageInfo = data?.simulationsForWorkflow.pageInfo as PageInfo | undefined;
  const dependencies = [
    searchQuery,
    favorited,
    filterSuccessfulSimulations,
    filterDateRange,
  ];
  const hasNextPage = usePagination({
    entity: 'simulationsForWorkflow',
    pageInfo,
    fetchMore,
    dependencies,
    scrollableRef,
    isInitialLoading,
    variables: variables ?? {},
  });

  if (error) {
    return (
      <Typography variant="body1" color="error">
        Could not fetch simulations.
      </Typography>
    );
  }
  if (isInitialLoading) {
    return <CircularProgress className={classes.loading} />;
  }

  if (!simulations || simulations.length === 0) {
    return (
      <div className={classes.noSimulationsMessageAndGraphic}>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          className={classes.noSimulationsMessage}
        >
          No simulations
        </Typography>
        <NoEntitiesBanner />
      </div>
    );
  }
  return (
    <>
      <TransitionGroup component="div" className={classes.container}>
        {simulations.map(simulation => {
          const isSimulationPlaceHolder = isSimulationPlaceholder(simulation);
          const animationProperties = isSimulationPlaceHolder
            ? {
                timeout: 0,
              }
            : {
                timeout: A_TO_D_DURATION,
                classNames: {
                  exit: classes.cardTransitionExit,
                  exitActive: classes.cardTransitionExitActive,
                },
              };
          return (
            <CSSTransition {...animationProperties} key={simulation.id}>
              <SimulationCardItem
                simulation={simulation}
                handleSimulationError={onClickViewErrorPanel}
                showInErrorState={
                  selectedSimulation ? selectedSimulation.id === simulation.id : false
                }
                elementConfigs={elementConfigs}
                workflowId={workflowId}
                pollingError={pollingErrors[simulation.id]}
              />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
      {hasNextPage && <CircularProgress size={24} className={classes.loading} />}
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  card: {
    marginBottom: theme.spacing(3),
  },
  ...containerCardTransitionExit,
  chip: {
    margin: theme.spacing(3, 3, 0, 0),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  count: {
    marginTop: theme.spacing(3),
  },
  filter: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(3),
  },
  icon: {
    color: Colors.TEXT_DISABLED,
    display: 'flex',
    justifyContent: 'center',
  },
  loading: {
    margin: '0 auto',
    display: 'block',
  },
  noSimulationsMessageAndGraphic: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  noSimulationsMessage: {
    margin: theme.spacing(4, 0),
  },
  search: {
    // override the existing SearchField margin.
    margin: theme.spacing(3, 0, 0, 0),
  },
  scrollToTopChip: {
    marginTop: theme.spacing(2),
    position: 'absolute',
  },
}));
