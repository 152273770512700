import React from 'react';

import { styled } from '@mui/material/styles';

/**
 * Used for aligning elements to the left and right.
 * Pass in two children elements, the first to be left-aligned
 * and the second to be right-aligned.
 */
export default function UILeftRight(props: React.PropsWithChildren<{}>) {
  if (!props.children || !Array.isArray(props.children)) {
    throw new Error('You must pass two children to UILeftRight');
  }

  const [left, right] = props.children;
  return (
    <ContainerDiv>
      {left && <LeftDiv>{left}</LeftDiv>}
      {right && <RightDiv>{right}</RightDiv>}
    </ContainerDiv>
  );
}

const ContainerDiv = styled('div')({
  display: 'flex',
});

const LeftDiv = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flex: 'auto',
  justifyContent: 'flex-start',
});

const RightDiv = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flex: 'auto',
  justifyContent: 'flex-end',
});
