import { useCallback, useEffect, useMemo, useState } from 'react';

import { ApolloError } from '@apollo/client';

import { useFetchGraphQLElementSet } from 'client/app/api/ElementsApi';
import { useQueryProtocolInstance } from 'client/app/apps/protocols/api/ProtocolsAPI';
import { deserialiseWorkflowResponse } from 'client/app/apps/workflow-builder/lib/workflowUtils';
import { ProtocolInstanceQuery } from 'client/app/gql';
import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import { getElementId, getElementParameterName } from 'common/types/schema';

// TODO - Remove this once we implement query for all protocols on ProtocolScreen.
export const MockProtocols: {
  name: string;
  protocolId: string;
  protocolInstanceId: string;
}[] = [
  {
    name: 'DC Protein Quantification Assay',
    protocolId: '03c31d42-f634-42d2-aa90-564112b1ba31',
    protocolInstanceId: '8e706ccc-fa5e-46b2-87c5-5946d543c5cc',
  },
  {
    name: 'Mycoplasma Luminescence Assay',
    protocolId: '8d7a919d-5814-4d72-b45a-14f7d3b1af82',
    protocolInstanceId: 'c43f6f46-7804-4df6-970e-60340c5208b4',
  },
];

type QueryProtocolResult =
  | { status: 'error'; error: ApolloError | Error }
  | { status: 'protocol-instance-loading' }
  | {
      status: 'parameters-loading';
      // While parameters are loading, we can start to render the protocolInstance
      // which is why it is returned with this result.
      protocolInstance: NonNullable<ProtocolInstanceQuery['protocolInstance']>;
    }
  | {
      status: 'success';
      protocolInstance: NonNullable<ProtocolInstanceQuery['protocolInstance']>;
    };

/**
 * Query the ProtocolInstnace and converts the underlying worklfow from the
 * Protocol into the workflowState to populate WorkflowBuilderStateContext,
 * but uses the specified parameter values in the WorkflowSchema to override
 * the default values.
 */
export const useGetDataAndInitialiseState = (
  protocolInstanceId: ProtocolInstanceId,
): QueryProtocolResult => {
  const dispatch = useWorkflowBuilderDispatch();
  const fetchGraphQLElementSet = useFetchGraphQLElementSet();

  const {
    data: protocolInstanceData,
    loading: protocolInstanceLoading,
    error: protocolInstanceLoadingError,
  } = useQueryProtocolInstance(protocolInstanceId);

  const [parametersLoading, setParametersLoading] = useState(false);
  const [parametersLoadingError, setParametersLoadingError] = useState<Error>();

  const workflow = useMemo(
    () => protocolInstanceData?.protocolInstance.instance.protocol.workflow,
    [protocolInstanceData?.protocolInstance.instance.protocol.workflow],
  );

  const getParameter = useCallback(
    (input: any) =>
      protocolInstanceData?.protocolInstance.instance.parameters[input.id] ||
      input.default,
    [protocolInstanceData?.protocolInstance.instance.parameters],
  );

  useEffect(() => {
    (async () => {
      try {
        if (!workflow) {
          return;
        }
        setParametersLoading(true);
        const elementSet = await fetchGraphQLElementSet(workflow?.id);
        const { workflowState } = deserialiseWorkflowResponse(workflow, elementSet);
        // Here we take the parameters from workflowState, which stores all the parameter
        // values indexed by element instance name, and we update the values of those parameters
        // using the default value as provided by protocol instance or the WorkflowSchema.
        const copyParameters = { ...workflowState.parameters };

        workflow.workflow.Schema?.inputs?.forEach(input => {
          const elementInstanceId = getElementId(input.path);
          const parameterName = getElementParameterName(input.path);
          const elementInstanceName = workflowState.elementInstances.find(
            elementInstance => elementInstance.Id === elementInstanceId,
          )?.name;
          const parameterData = getParameter(input);
          if (elementInstanceName && parameterName) {
            copyParameters[elementInstanceName][parameterName] = parameterData;
          }
        });

        dispatch({
          type: 'resetToWorkflow',
          payload: { ...workflowState, parameters: copyParameters },
        });

        if (protocolInstanceData?.protocolInstance.elementContext.elementContextMap) {
          dispatch({
            type: 'updateElementsWithContexts',
            payload:
              protocolInstanceData?.protocolInstance.elementContext.elementContextMap,
          });
        }
        if (protocolInstanceData?.protocolInstance.elementContext.elementContextError) {
          dispatch({
            type: 'setElementContextError',
            payload:
              protocolInstanceData?.protocolInstance.elementContext.elementContextError,
          });
        }
      } catch (err) {
        setParametersLoadingError(err);
      } finally {
        setParametersLoading(false);
      }
    })();
    // TODO: It is a little bit of a smell but to make current implemntation working
    // We need to remove getParameter off dependency list
    // otherwise this useEffect will be callled multiple times calling `'resetToWorkflow'`
    // whenever protocolInstance gets updated.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, fetchGraphQLElementSet, workflow]);

  if (protocolInstanceLoading) {
    return { status: 'protocol-instance-loading' };
  } else if (
    protocolInstanceLoadingError ||
    parametersLoadingError ||
    !protocolInstanceData?.protocolInstance
  ) {
    return {
      status: 'error',
      error:
        protocolInstanceLoadingError ||
        parametersLoadingError ||
        new Error('Protocol instance data not found'),
    };
  } else if (parametersLoading) {
    return {
      status: 'parameters-loading',
      protocolInstance: protocolInstanceData.protocolInstance,
    };
  }
  return {
    status: 'success',
    protocolInstance: protocolInstanceData.protocolInstance,
  };
};
