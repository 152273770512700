import React, { ReactNode } from 'react';

import Box from '@mui/material/Box';
import cx from 'classnames';

import ElementParameterHelpIcon from 'client/app/components/Parameters/ElementParameterHelpIcon';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type BaseProps = {
  /** Parameter display name, shown in the header. */
  displayName: string;

  isRequired?: boolean;
  isValid?: boolean;
  doeSwitch?: ReactNode;
  help?: ReactNode;
  className?: string;
};

type Props = BaseProps & {
  /**
   * Parameter name (original, not changed by configuration). Required for ElementParameterHelpIcon to be shown.
   */
  name: string;

  /**
   * Required for ElementParameterHelpIcon to be shown.
   */
  elementId: string;
};

export function ParameterHeader({
  displayName,
  isRequired = false,
  isValid = true,
  doeSwitch,
  help,
  className,
}: BaseProps) {
  const classes = useStyles();
  return (
    <div className={cx(classes.paramHeader, className, { [classes.invalid]: !isValid })}>
      <span
        className={cx({
          [classes.paramName]: true,
          [classes.asterisk]: isRequired,
        })}
      >
        {displayName}
      </span>
      <Box display="flex" alignItems="center">
        {doeSwitch}
        {help}
      </Box>
    </div>
  );
}

export default function ElementParameterHeader({ name, elementId, ...props }: Props) {
  return (
    <ParameterHeader
      {...props}
      help={<ElementParameterHelpIcon elementId={elementId} name={name} />}
    />
  );
}

const useStyles = makeStylesHook({
  paramHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '4px 0',
  },
  asterisk: {
    '&:after': {
      fontSize: '1.25em',
      content: `'*'`,
      color: Colors.RED,
    },
  },
  paramName: {
    fontSize: '12px',
    fontWeight: 'normal',
    wordWrap: 'break-word',
    maxWidth: '80%',
    padding: '4px 0',
  },
  invalid: {
    backgroundColor: Colors.ERROR_LIGHT_BG,
  },
});
