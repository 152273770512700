import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';

export type Step<T> = {
  /** Unique identifier for this step */
  value: T;
  /** Label to be displayed for this step */
  label: string;
  /** Callback for step selection*/
  onSelectStep?: (value: T) => void;
  /** Optional number to be displayed next to step label */
  number?: number;
  /** Is this step diabled? Defaults to false */
  disabled?: boolean;
};

type StepperProps<T> = {
  steps: Step<T>[];
  activeStepValue: T;
  /** Disable navigating between steps by clicking on them. Defaults to false */
  disableStepSelection?: boolean;
};

/**
 * A styled toggle switch that allows selection between two states.
 */
export default function Stepper<T>({
  steps,
  activeStepValue,
  disableStepSelection,
}: StepperProps<T>) {
  return (
    <StyledNav noSteps={steps.length} disableStepSelection={disableStepSelection}>
      {steps.map((step, index, orig) => (
        <React.Fragment key={index}>
          {index === 0 ? null : (
            <StepArrow
              previousStepActive={orig[index - 1].value === activeStepValue}
              previousStepIndex={index - 1}
            />
          )}
          <StepContainer
            variant="button"
            color="primary"
            onClick={() =>
              !step.disabled && !disableStepSelection && step.onSelectStep?.(step.value)
            }
            activeState={activeStepValue === step.value}
            disabled={!!step.disabled}
          >
            <span>{step.label}</span>
            {step.number !== undefined && (
              <StepNumber activeState={activeStepValue === step.value}>
                {step.number}
              </StepNumber>
            )}
          </StepContainer>
        </React.Fragment>
      ))}
    </StyledNav>
  );
}

const STEP_WIDTH = 185;
const STEP_GAP = 6;

const StyledNav = styled('nav', {
  shouldForwardProp: propName =>
    propName !== 'noSteps' && propName !== 'disableStepSelection',
})<{ noSteps: number; disableStepSelection?: boolean }>(
  ({ noSteps, disableStepSelection, theme: { spacing } }) => ({
    display: 'flex',
    borderRadius: '6px',
    border: `1px solid ${Colors.BLUE_20}`,
    padding: spacing(1),
    userSelect: 'none',
    position: 'relative',
    width: noSteps * STEP_WIDTH + STEP_GAP,

    '& > .MuiTypography-button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',

      padding: spacing(2),
      borderRadius: spacing(2),
      width: '185px',
      cursor: disableStepSelection ? undefined : 'pointer',
    },
  }),
);

const StepContainer = styled(Typography, {
  shouldForwardProp: propName => propName !== 'activeState' && propName !== 'disabled',
})<{ activeState: boolean; disabled: boolean }>(
  ({ activeState, disabled, theme: { palette } }) => ({
    background: activeState ? palette.primary.main : palette.primary.contrastText,
    color: disabled
      ? palette.primary.light
      : activeState
      ? palette.primary.contrastText
      : palette.primary.main,
  }),
);

const StepNumber = styled('span', {
  shouldForwardProp: propName => propName !== 'activeState',
})<{ activeState: boolean }>(({ activeState, theme: { palette, spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: !activeState ? palette.primary.main : Colors.BLUE_30,
  color: palette.primary.contrastText,
  borderRadius: '50%',
  width: '18px',
  height: '18px',
  fontSize: '12px',
  lineHeight: '1em',
  marginLeft: spacing(3),
}));

const StepArrow = styled('div', {
  shouldForwardProp: propName =>
    propName !== 'previousStepActive' && propName !== 'previousStepIndex',
})<{ previousStepActive: boolean; previousStepIndex: number }>(
  ({ previousStepActive, previousStepIndex, theme: { palette } }) => ({
    position: 'absolute',
    top: '6px',
    left: 174 + previousStepIndex * STEP_WIDTH,
    width: '21px',
    height: '21px',

    borderRadius: '3px',
    transform: 'rotate(45deg)',
    background: previousStepActive ? palette.primary.main : palette.primary.contrastText,
  }),
);
