import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

import Header from 'client/app/components/DeviceLibrary/DeviceConfigurationValidation/DeckLayout/Header';
import Preview from 'client/app/components/DeviceLibrary/DeviceConfigurationValidation/DeckLayout/Preview';
import { DeckLayoutConfig } from 'client/app/components/DeviceLibrary/DeviceConfigurationValidation/types';
import Colors from 'common/ui/Colors';

type Props = {
  deckLayoutConfigList: DeckLayoutConfig[];
};

const ValidationDeckLayout = ({ deckLayoutConfigList }: Props) => {
  const [activeDeckLayoutConfig, setActiveDeckLayoutConfig] = useState<DeckLayoutConfig>(
    deckLayoutConfigList[0],
  );

  return activeDeckLayoutConfig ? (
    <Container>
      <HeaderContainer>
        <Header
          deckLayoutConfigList={deckLayoutConfigList}
          activeDeckLayoutConfig={activeDeckLayoutConfig}
          setActiveDeckLayoutConfig={setActiveDeckLayoutConfig}
        />
      </HeaderContainer>
      <WorkspaceContainer>
        <Preview
          deck={activeDeckLayoutConfig.deck}
          error={activeDeckLayoutConfig.error}
        />
      </WorkspaceContainer>
    </Container>
  ) : null;
};

const Container = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',

  borderRadius: '8px',
  borderColor: Colors.GREY_30,
  borderWidth: '1px',
  borderStyle: 'solid',
});

const HeaderContainer = styled('div')({
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
  background: Colors.BLUE_0,
});

const WorkspaceContainer = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  margin: theme.spacing(3),
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: Colors.BLUE_5,
}));

export default ValidationDeckLayout;
