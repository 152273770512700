import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { deviceFromGraphQL } from 'client/app/api/deviceFromGraphql';
import {
  QUERY_ALL_DEVICES,
  QUERY_DEVICE_CONFIG_RESPONSE,
} from 'client/app/api/gql/queries';
import { PanelWithoutScroll } from 'client/app/apps/workflow-builder/panels/Panel';
import {
  getSelectedMainDevice,
  useGetDeviceCommonForWorkflow,
} from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import { useWorkflowSettingsState } from 'client/app/apps/workflow-builder/panels/workflow-settings/workflowSettingsState';
import DeviceLibrary from 'client/app/components/DeviceLibrary/DeviceLibrary';
import { AllDevicesQuery, DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import {
  buildConfiguredDevice,
  removeMissingDeviceFromDeviceConfiguration,
} from 'client/app/lib/workflow/deviceConfigUtils';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { DATA_ONLY_DUMMY_DEVICE } from 'common/constants/manual-device';
import { indexBy } from 'common/lib/data';
import { ConfiguredDevice, ConfiguredDeviceId, Stage } from 'common/types/bundle';
import {
  GenericDeviceType,
  getGenericDeviceType,
  getGenericDeviceTypeFromAnthaClass,
  isDataOnly,
  removeAccessibleDeviceByConfiguredDeviceID,
} from 'common/types/bundleConfigUtils';
import { Device } from 'common/types/device';
import Button from 'common/ui/components/Button';
import SimpleDialog from 'common/ui/components/Dialog/SimpleDialog';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog, { DialogProps } from 'common/ui/hooks/useDialog';

type AllDevices = AllDevicesQuery['devices'];

type DeviceSelectorPanelProps = {
  onClose: () => void;
  className: string;
};

export type DeviceDisabledReason = {
  disabled: boolean;
  reason?: string;
  disabledButtonCopy?: string;
};

/**
 * Panel that allows you to search for devices + select device(s).
 */
export default React.memo(function DeviceSelectorPanel(props: DeviceSelectorPanelProps) {
  const classes = useStyles();
  const { onClose, className } = props;
  const dispatch = useWorkflowBuilderDispatch();
  const stages = useWorkflowBuilderSelector(state => state.stages);
  const { loading, data, error, refetch } = useQuery(QUERY_ALL_DEVICES);
  const { devices, savedDevices } = useSavedDevices(data?.devices);

  const [
    confirmAccessibleDevicesDisabledDialog,
    openConfirmAccessibleDevicesDisabledDialog,
  ] = useDialog(ConfirmAccessibleDevicesDisabled);

  const [selectedDevices, setSelectedDevices] =
    useState<ConfiguredDevice[]>(savedDevices);
  const devicesCommon = useGetDeviceCommonForWorkflow(selectedDevices);
  const { selectedDevice: selectedMainDevice } = getSelectedMainDevice(
    selectedDevices,
    devicesCommon,
  );

  const selectedDeviceByDeviceId = useMemo(
    () => indexBy(selectedDevices, 'deviceId'),
    [selectedDevices],
  );

  const [getParsedRunConfig] = useLazyQuery(QUERY_DEVICE_CONFIG_RESPONSE);

  const selectedDeviceTypes = useMemo(() => {
    return new Set(selectedDevices.map(device => getGenericDeviceType(device.type)));
  }, [selectedDevices]);

  // TODO make this useful by helping apollo caching the result (CI-1345).
  // For that we would need the DeviceRunConfig graphql response to provide an ID
  // (possibly the device id X runconfig ID as some devices have 0 run configs).
  // The benefit of this is that we preload the run config before click saving, so it is faster
  useEffect(() => {
    if (
      selectedMainDevice &&
      !selectedDeviceTypes.has('DataOnly') &&
      !selectedDeviceTypes.has('Manual')
    ) {
      void getParsedRunConfig({
        variables: { id: selectedMainDevice.deviceId },
      });
    }
  }, [getParsedRunConfig, selectedDeviceTypes, selectedMainDevice]);

  const isDeviceDisabled = useCallback(
    (device: Device | DeviceCommon): DeviceDisabledReason => {
      return checkIfDeviceIsDisabled(
        device,
        selectedDeviceByDeviceId,
        stages,
        selectedDeviceTypes,
      );
    },
    [selectedDeviceByDeviceId, selectedDeviceTypes, stages],
  );
  const handleSelect = useCallback(
    (id: string) => {
      const deviceInfo = devices.find(device => device.id === id);
      if (deviceInfo && isDeviceDisabled(deviceFromGraphQL(deviceInfo)).disabled) {
        return;
      }

      const previouslySelected = selectedDevices.find(cd => cd.deviceId === id);

      let updatedSelectedDevices: ConfiguredDevice[];

      if (!previouslySelected) {
        if (deviceInfo) {
          // Select automation device
          const { configuredDevices: selectedDeviceConfig, skippedAccessibleDevices } =
            buildConfiguredDevice([deviceInfo], undefined, selectedDevices);
          updatedSelectedDevices = [...selectedDeviceConfig, ...selectedDevices];

          if (skippedAccessibleDevices) {
            void openConfirmAccessibleDevicesDisabledDialog({});
          }
        } else {
          // Select Data-only and unselect all other devices
          updatedSelectedDevices = [
            {
              id: DATA_ONLY_DUMMY_DEVICE.id as ConfiguredDeviceId,
              deviceId: DATA_ONLY_DUMMY_DEVICE.id as DeviceId,
              type: 'DataOnly',
            },
          ];
        }
      } else {
        // The deselected device might be an accessible device, so try removing it.
        updatedSelectedDevices = removeAccessibleDeviceByConfiguredDeviceID(
          selectedDevices,
          previouslySelected.id,
        );

        // Unselect a device and its accessible devices if the id matches.
        updatedSelectedDevices = updatedSelectedDevices.filter(
          cd =>
            !(
              cd.deviceId === id ||
              previouslySelected.accessibleDeviceConfigurationIds?.includes(cd.id)
            ),
        );
      }
      setSelectedDevices(updatedSelectedDevices);
    },
    [
      devices,
      isDeviceDisabled,
      selectedDevices,
      openConfirmAccessibleDevicesDisabledDialog,
    ],
  );

  const handleClear = () => setSelectedDevices([]);

  const apollo = useApolloClient();
  const handleSave = async () => {
    if (selectedDeviceTypes.has('DataOnly')) {
      dispatch({
        type: 'saveSelectedDevices',
        payload: {
          configuredDevices: selectedDevices,
          runConfiguration: undefined,
        },
      });
    } else if (selectedDeviceTypes.has('Manual')) {
      /**
       * There is no runConfiguration available for Manual device at the time writing this.
       * Hence, we handle this case separately and not request for runConfig or advanced options.
       */
      dispatch({
        type: 'saveSelectedDevices',
        payload: {
          configuredDevices: selectedDevices,
          runConfiguration: undefined,
        },
      });
    } else {
      // We have to wait to get the apollo result before we can actually dispatch `saveSelectedDevices`.
      // This is to avoid dispatching the action and have a transient unstable state between those 2 actions (selected device but not yet config).
      // If we did not have magic default runConfig we wouldn't need to fetch runConfig and wait.
      // If we do not get any runConfigData back from the apollo query, we will set the devices only, and reset the
      // default values that would have come from the run config, until the user selects a new run config in the
      // DeckOptionsPanel.
      // TODO Consider if it is necessary to improve speed
      const runConfigData = await apollo
        .query({
          query: QUERY_DEVICE_CONFIG_RESPONSE,
          variables: { id: selectedMainDevice?.deviceId ?? '' },
        })
        .catch(() => null); // Do not crash if no default.

      dispatch({
        type: 'saveSelectedDevices',
        payload: {
          configuredDevices: selectedDevices,
          runConfiguration: runConfigData?.data,
        },
      });
    }
  };

  const selectedDeviceIds = Object.keys(selectedDeviceByDeviceId);

  let panelContent;
  if (error) {
    panelContent = <GraphQLErrorPanel error={error} onRetry={refetch} />;
  } else if (loading) {
    panelContent = <CircularProgress />;
  } else if (devices.length === 0) {
    panelContent = <Typography variant="h5"> No devices found.</Typography>;
  } else {
    panelContent = (
      <DeviceLibrary
        isLoading={loading}
        onSelect={handleSelect}
        devices={devices}
        selectedDeviceIds={selectedDeviceIds}
        isDeviceDisabled={isDeviceDisabled}
        showSelectionStatus
        showManualDeviceRelatedCards
        smallCard
        dialog
        clearSelectionProps={{
          selectedDeviceCount: selectedDeviceIds.length,
          totalDeviceCount: devices.length,
          onClear: handleClear,
        }}
      />
    );
  }

  return (
    <PanelWithoutScroll
      title="Execution Mode"
      className={className}
      onClose={onClose}
      panelContent="DeviceSelector"
      fullWidth
      panelActions={
        <div className={classes.actions}>
          <Button
            className={classes.rightAlign}
            onClick={handleSave}
            variant="tertiary"
            color="primary"
          >
            {selectedDeviceIds.length > 0 ? 'Next' : 'Save'}
          </Button>
        </div>
      }
    >
      {panelContent}
      {confirmAccessibleDevicesDisabledDialog}
    </PanelWithoutScroll>
  );
});

const NO_DEVICES: AllDevices = [];

function useSavedDevices(allDevices: AllDevices | undefined) {
  const devices = allDevices ?? NO_DEVICES;
  const { configuredDevicesForSelectedStage } = useWorkflowSettingsState();

  const savedDevices = useWorkflowBuilderSelector((state): ConfiguredDevice[] => {
    if (isDataOnly(state.config)) {
      return [
        {
          id: '' as ConfiguredDeviceId,
          deviceId: DATA_ONLY_DUMMY_DEVICE.id as DeviceId,
          type: 'DataOnly',
        },
      ];
    } else {
      return removeMissingDeviceFromDeviceConfiguration(
        configuredDevicesForSelectedStage,
        devices,
      );
    }
  });
  return { devices, savedDevices };
}

export function checkIfDeviceIsDisabled(
  device: DeviceCommon | Device,
  selectedDeviceByDeviceId: Record<string, ConfiguredDevice>,
  stages: Stage[],
  selectedDeviceTypes: Set<GenericDeviceType>,
) {
  let anthaClass: string;

  if ('anthaLangDeviceClass' in device) {
    anthaClass = device.anthaLangDeviceClass;
  } else {
    anthaClass = device.model.anthaLangDeviceClass;
  }

  if (selectedDeviceByDeviceId[device.id]) {
    return { disabled: false };
  }

  const deviceType = getGenericDeviceTypeFromAnthaClass(anthaClass);

  if (deviceType === 'Unknown') {
    return {
      disabled: true,
      reason: 'This device cannot be used in a workflow',
    };
  }

  const isDispenser = deviceType === 'Dispenser';
  const isLiquidHandler = deviceType === 'LiquidHandler';
  const isPlateReader = deviceType === 'PlateReader';
  const isPlateWasher = deviceType === 'PlateWasher';
  const isDataOnly = deviceType === 'DataOnly';
  const isManual = deviceType === 'Manual';

  if (selectedDeviceTypes.has('DataOnly')) {
    return {
      disabled: true,
      reason:
        'Data-only (Computational) mode cannot be used in conjunction with any other execution mode',
    };
  }

  if (isDataOnly && selectedDeviceTypes.size > 0) {
    return {
      disabled: true,
      reason:
        'Data-only (Computational) mode cannot be used in conjunction with any other execution mode',
    };
  }

  if (stages.length > 1 && (isLiquidHandler || isDataOnly)) {
    return {
      disabled: true,
      reason: 'This device cannot be used in multi-stage workflows',
      disabledButtonCopy: 'Unavailable',
    };
  }

  if (isPlateWasher && selectedDeviceTypes.has('PlateWasher')) {
    return {
      disabled: true,
      reason: `Only one plate washer can be selected for a single stage`,
    };
  }

  if (isPlateReader && selectedDeviceTypes.has('PlateReader')) {
    return {
      disabled: true,
      reason: `Only one plate reader can be selected for a single stage`,
    };
  }

  if (
    (isDispenser || isLiquidHandler || isManual) &&
    (selectedDeviceTypes.has('LiquidHandler') ||
      selectedDeviceTypes.has('Dispenser') ||
      selectedDeviceTypes.has('Manual'))
  ) {
    return {
      disabled: true,
      reason: selectedDeviceTypes.has('Manual')
        ? 'Manual mode cannot be used in conjunction with liquid handlers or dispensers'
        : 'Only one liquid handler or dispenser can be selected at a time',
    };
  }

  if (isPlateReader || isPlateWasher) {
    const hasDispenser = selectedDeviceTypes.has('Dispenser');
    const hasManual = selectedDeviceTypes.has('Manual');
    if (hasDispenser || hasManual) {
      const deviceCopy = isPlateReader ? 'Plate readers' : 'Plate washers';
      const selectedDeviceCopy = hasDispenser ? 'a dispenser' : 'manual mode';
      return {
        disabled: true,
        reason: `${deviceCopy} cannot be used in conjunction with ${selectedDeviceCopy} in the same stage. Please create a separate stage to use this device`,
      };
    }
  }

  if (isDispenser || isManual) {
    const hasPlateReader = selectedDeviceTypes.has('PlateReader');
    const hasPlateWasher = selectedDeviceTypes.has('PlateWasher');
    if (hasPlateReader || hasPlateWasher) {
      const deviceCopy = isDispenser ? 'A dispenser' : 'Manual mode';
      const selectedDeviceCopy = hasPlateReader ? 'plate readers' : 'plate washers';
      return {
        disabled: true,
        reason: `${deviceCopy} cannot be used in conjunction with ${selectedDeviceCopy} in the same stage. Please create a separate stage to use this device`,
      };
    }
  }

  return { disabled: false };
}

function ConfirmAccessibleDevicesDisabled(props: DialogProps<void>) {
  return (
    <SimpleDialog
      title="Notification"
      contentText="Some accessible devices were disabled as another device of the same type has already been selected."
      submitButtonLabel="OK"
      onSubmit={props.onClose}
      hideCancel
      {...props}
    />
  );
}

const useStyles = makeStylesHook({
  actions: {
    display: 'flex',
  },
  rightAlign: {
    marginLeft: 'auto',
  },
});
