import React, { useMemo } from 'react';

import { MutationTuple } from '@apollo/client';

import CommentDialogue, {
  Props as CommentDialogueProps,
} from 'client/app/apps/execution-details/ExecuteTab/ExecutionStageCard/components/CommentDialogue';
import {
  UpdateExecutionStageCommentInput,
  UpdateExecutionStageCommentMutation,
} from 'client/app/gql';
import { createContextWithoutDefault } from 'common/ui/createContextWithoutDefault';
import useDialog from 'common/ui/hooks/useDialog';

export type UpdateCommentMutationTuple = MutationTuple<
  UpdateExecutionStageCommentMutation,
  UpdateExecutionStageCommentInput
>;

type CommentContextType = {
  openDialogue: (
    props: Omit<CommentDialogueProps, 'isOpen' | 'onClose' | 'isDisabled'>,
  ) => Promise<void>;
  updateCommentMutationTuple: UpdateCommentMutationTuple;
};

const CommentContext = createContextWithoutDefault<CommentContextType>();

type CommentContextProviderProps = {
  children: React.ReactNode;
  updateCommentMutationTuple: UpdateCommentMutationTuple;
};

export const CommentContextProvider = ({
  children,
  updateCommentMutationTuple,
}: CommentContextProviderProps) => {
  const [dialogue, openDialogue] = useDialog(CommentDialogue);

  const value = useMemo<CommentContextType>(
    () => ({
      openDialogue,
      updateCommentMutationTuple,
    }),
    [openDialogue, updateCommentMutationTuple],
  );

  return (
    <CommentContext.Provider value={value}>
      {dialogue}
      {children}
    </CommentContext.Provider>
  );
};

export default CommentContext;
