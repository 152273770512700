import React, {
  createContext,
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { WellLocationOnDeckItem } from 'common/types/mix';
import Colors from 'common/ui/Colors';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import EdgeLabel from 'common/ui/components/simulation-details/mix/EdgeLabel';
import {
  routeAllEdges,
  RoutedEdge,
} from 'common/ui/components/simulation-details/mix/edgeRouting';
import EdgeSvg from 'common/ui/components/simulation-details/mix/EdgeSvg';
import {
  DeckItemState,
  Edge,
  isLiquidMovementEdge,
  MoveLiquidEdge,
} from 'common/ui/components/simulation-details/mix/MixState';
import zIndex from 'common/ui/components/simulation-details/mix/zIndex';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  deckLayout: DeckLayout;
  /**
   * Needed because each deck item holds information about its current deck position
   * as it moves around the deck.
   */
  deckItems: readonly DeckItemState[];
  edges: readonly Edge[];
  selectedEdge?: Edge;
  onEdgeClick?: (edge: Edge) => void;
};

export enum ArrowHeadSize {
  SMALL,
  MEDIUM,
  LARGE,
}

const SMALL_ARROW_SIZE = 5;
const MEDIUM_ARROW_SIZE = 13;
const LARGE_ARROW_SIZE = 32;

const ARROW_HEAD_SIZES = new Map<ArrowHeadSize, number>([
  [ArrowHeadSize.SMALL, SMALL_ARROW_SIZE],
  [ArrowHeadSize.MEDIUM, MEDIUM_ARROW_SIZE],
  [ArrowHeadSize.LARGE, LARGE_ARROW_SIZE],
]);

const DEFAULT_ARROW_HEAD_CONTEXT: (
  edge: Edge,
  isShortPath: boolean,
  flip?: boolean,
) => string = () => '';

/**
 * Used by child components of ArrowHeadDefs to get arrow head marker URL.
 */
export const ArrowHeadContext = createContext(DEFAULT_ARROW_HEAD_CONTEXT);

function arrowHeadID(defsID: number, size: ArrowHeadSize, flip?: boolean) {
  return `${defsID}-${size}-${flip ? 'flipped' : 'normal'}`;
}

/**
 * Used for creating a unique ID for each set of marker defs
 */
let defsCount = 0;

type ArrowHeadDefsProps = {
  children: ReactNode;
};

/**
 * Defines the arrow head markers within an SVG <defs> element.
 *
 * This component must go at the root of the SVG (because <defs> must be at the
 * root). Child components can access the marker URLs using ArrowHeadContext.
 */
function ArrowHeadDefs({ children }: ArrowHeadDefsProps) {
  // The ID of each def is prefixed with this unique ID. If we didn't do this,
  // we'd have multiple SVG's with defs with the same IDs. If referencing one of
  // these defs from the second SVG, and the first SVG is display:none, then the
  // markers won't show.
  const defsID = useMemo(() => defsCount++, []);

  const getArrowURL = useCallback(
    (edge: Edge, isShortPath: boolean, flip: boolean = false): string => {
      let size: ArrowHeadSize;
      if (edge.type === 'move_plate') {
        // Plate movement arrows and arrowheads should be larger so they can be
        // seen from a further zoom (e.g., so the user can see plate movement
        // between devices).
        size = ArrowHeadSize.LARGE;
      } else if (isShortPath) {
        // When the path is very short, the regular sized arrow head is
        // disproportionately large and obscures the label.
        size = ArrowHeadSize.SMALL;
      } else {
        size = ArrowHeadSize.MEDIUM;
      }
      return `url(#${arrowHeadID(defsID, size, flip)})`;
    },
    [defsID],
  );

  return (
    <ArrowHeadContext.Provider value={getArrowURL}>
      <defs>
        {[...ARROW_HEAD_SIZES.keys()].map(size => (
          <Fragment key={size}>
            <ArrowHeadMarker id={arrowHeadID(defsID, size)} size={size} />
            <ArrowHeadMarker id={arrowHeadID(defsID, size, true)} size={size} flip />
          </Fragment>
        ))}
      </defs>
      {children}
    </ArrowHeadContext.Provider>
  );
}

type ArrowHeadMarkerProps = {
  id: string;
  size: ArrowHeadSize;
  flip?: boolean;
};

function ArrowHeadMarker({ id, size, flip }: ArrowHeadMarkerProps) {
  const width = ARROW_HEAD_SIZES.get(size)!;
  const height = 0.7 * width;
  // The point within the arrow joins to the line. This is a couple of pixels
  // before the end of the arrow head so that way the line doesn't extend beyond
  // the arrow tip.
  const joinPoint = width - 2;
  return (
    <marker
      id={id}
      markerWidth={width}
      markerHeight={height}
      refX={joinPoint}
      refY={height / 2}
      orient={flip ? 'auto-start-reverse' : 'auto'}
      markerUnits="userSpaceOnUse"
    >
      <path
        d={`M0,0 L0,${height} L${width},${height / 2} z `}
        fill={Colors.MIX_PREVIEW_EDGE}
      />
    </marker>
  );
}

export default function EdgesSvgOverlay({
  deckLayout,
  deckItems,
  edges,
  selectedEdge,
  onEdgeClick,
}: Props) {
  const classes = useStyles();

  const routedEdges = useMemo<RoutedEdge[]>(
    () => routeAllEdges(edges, deckItems, deckLayout),
    [deckItems, deckLayout, edges],
  );

  // When hovering over an edge's label, bring it above all other labels.
  const [hoveredEdgeKey, setHoveredEdgeKey] = useState<string | undefined>();

  const handleEdgeMouseEnter = useCallback(
    (edge: Edge) => setHoveredEdgeKey(getEdgeReactKey(edge)),
    [],
  );

  const handleEdgeMouseLeave = useCallback(() => setHoveredEdgeKey(undefined), []);

  // Put the edge the user has hovered over last, so that it appears on top in SVG
  const orderedEdges = useMemo<RoutedEdge[]>(() => {
    const hoveredEdge = routedEdges.find(
      routedEdge => getEdgeReactKey(routedEdge.edge) === hoveredEdgeKey,
    );
    if (!hoveredEdge) {
      return routedEdges;
    }
    return [...routedEdges.filter(routedEdge => routedEdge !== hoveredEdge), hoveredEdge];
  }, [hoveredEdgeKey, routedEdges]);

  const labeledEdges = useMemo<RoutedEdge[]>(
    () =>
      orderedEdges
        // Don't show labels for 0 volume transfers, such as transfers in the
        // plate mapper which have no volume associated with them.
        .filter(
          ({ edge }) => !isLiquidMovementEdge(edge) || edge.action.volume.value > 0,
        ),
    [orderedEdges],
  );

  // For quick lookups of deck items based on ID
  const deckItemDict = useMemo(
    () => new Map<string, DeckItemState>(deckItems.map(item => [item.id, item])),
    [deckItems],
  );
  const selectedEdgeKey = selectedEdge && getEdgeReactKey(selectedEdge);

  // Render lines then labels, so that lines can never overlap labels.
  return (
    <svg className={classes.svg} style={deckLayout.deckBounds}>
      <ArrowHeadDefs>
        {routedEdges.map(({ edge, curve, arrowAtEnd, pathLengthSquared }) => (
          <EdgeSvg
            key={getEdgeReactKey(edge)}
            edge={edge}
            curve={curve}
            arrowAtEnd={arrowAtEnd}
            pathLengthSquared={pathLengthSquared}
          />
        ))}
      </ArrowHeadDefs>
      {labeledEdges.map(({ edge, curve, pathLengthSquared }) => {
        const key = getEdgeReactKey(edge);
        return (
          <EdgeLabel
            key={key}
            edge={edge}
            curve={curve}
            pathLengthSquared={pathLengthSquared}
            deckItemDict={deckItemDict}
            isSelected={key === selectedEdgeKey}
            onClick={onEdgeClick}
            onMouseEnter={handleEdgeMouseEnter}
            onMouseLeave={handleEdgeMouseLeave}
          />
        );
      })}
    </svg>
  );
}

function getLiquidMovementEdgeKey(edge: MoveLiquidEdge): string {
  return [
    edge.type,
    // Adding the step is important because we sometimes do the exact same
    // transfer (same pair of wells) multiple times in a row. This happens for
    // example when the tip is not large enough to hold all the liquid we want
    // to transfer.
    edge.stepNumber,
    _getLocationReactKey(edge.action.from.loc),
    // Dealing with the case where the `from` and `to` are the same but the
    // `filter` is different. For example, aspirating from a reservoir,
    // dispensing into 3 robocolumns, then all of those robocolumns
    // outflowing into a waste tray.
    _getLocationReactKey(edge.action.tipDestination.loc),
    _getLocationReactKey(edge.action.liquidDestination.loc),
  ].join('-');
}

function getEdgeReactKey(edge: Edge): string {
  switch (edge.type) {
    case 'liquid_dispense':
    case 'liquid_transfer':
      // It's possible for multiple channels to visit the source and
      // destination, so we also need to make the key unique on channel.
      return getLiquidMovementEdgeKey(edge) + '-' + edge.channel;
    case 'filtration':
      return getLiquidMovementEdgeKey(edge);
    case 'move_plate':
      return [edge.stepNumber, edge.fromDeckPositionName, edge.toDeckPositionName].join(
        '-',
      );
    case 'stamping':
      return [
        getLiquidMovementEdgeKey(edge),
        edge.source.deckPositionName,
        edge.destination.deckPositionName,
      ].join('-');
  }
}

function _getLocationReactKey(loc: WellLocationOnDeckItem): string {
  return `${loc.deck_item_id}:${loc.row}:${loc.col}`;
}

const useStyles = makeStylesHook({
  svg: {
    left: 0,
    top: 0,
    overflow: 'visible',
    // The svg element covers the deck.
    // Ignore clicks so that the wells inside plates are clickable.
    pointerEvents: 'none',
    position: 'absolute',
    zIndex: zIndex.edgesSvgOverlay,
  },
});
