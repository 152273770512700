import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const TipWasteIcon = React.memo<SvgIconProps>(function TipWasteIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M11.727 3.384l1.366.366L16.124 2l1 1.732-12.124 7L4 9l3.031-1.75.366-1.366 4.33-2.5zM7.5 20.224c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-10h-12v10zm2-8h8v8h-8v-8z"
          fill="currentColor"
        />
        <path
          d="M15 8.366l2.598 1.5-6.474 8.964-.65-.375L15 8.366z"
          fill="currentColor"
        />
        <path d="M16 6.634l2.598 1.5-1 1.732L15 8.366l1-1.732z" fill="currentColor" />
      </svg>
    </SvgIcon>
  );
});
