import { useMemo, useState } from 'react';

import { LiquidNameOrGroupName } from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';

/**
 * Hook for storing and updating a path of liquid ids, used for
 * rendering out a nested menu of liquids.
 *
 * For example, a path would look like:
 *
 * ['liquid1', 'sub-liquid2', 'sub-sub-liquid3']
 */
export function useLiquidIdentifierPathSelection(): {
  path: string[];
  addToPath: (id: string) => void;
  removeFromPath: () => void;
} {
  const [path, setPath] = useState<string[]>([]);
  const addToPath = (id: string) => {
    setPath(path => [...path, id]);
  };
  const removeFromPath = () => setPath(path => path.slice(0, -1));
  return { path, addToPath, removeFromPath };
}

/**
 * Hook that returns the parent LiquidNameOrGroupName for the given path, as well as the
 * list of LiquidNameOrGroupName options that are children of the current parent.
 *
 * @param options The full list of LiquidNameOrGroupName options
 * @param path An array of liquid ids generated from useLiquidIdentifierPathSelection()
 */
export function useLiquidIdentifierOption(
  options: LiquidNameOrGroupName[],
  path: string[],
): { parent: LiquidNameOrGroupName | null; visibleOptions: LiquidNameOrGroupName[] } {
  const [parent, visibleOptions] = useMemo(() => {
    function followPath(options: LiquidNameOrGroupName[], path: string[]) {
      const match = options.find(child => child.id === path[0]);

      if (match) {
        if (path.length === 1 || !match.contents) {
          return match;
        } else {
          return followPath(match.contents, path.slice(1));
        }
      }

      return null;
    }

    if (path.length) {
      const parent = followPath(options, path);
      const contents = parent?.contents ?? [];
      return [parent, parent ? [parent, ...contents] : contents];
    }

    return [null, options];
  }, [options, path]);
  return { parent, visibleOptions };
}
