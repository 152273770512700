import { ApolloClient } from '@apollo/client';
import { useApolloClient } from '@apollo/client';

import { getResultOrThrow } from 'client/app/api/apolloClient';
import { MUTATION_DELETE_SIMULATION } from 'client/app/api/gql/mutations';
import {
  QUERY_JOB_SIMULATION_REDIRECT,
  QUERY_SIMULATION_COUNTS_BY_WORKFLOW_ID,
  QUERY_SIMULATION_POLL,
  QUERY_WORKFLOW_BY_ID_WITH_SIMULATIONS,
} from 'client/app/api/gql/queries';
import {
  SimulationForPollQuery,
  WorkflowsBySimulationsQueryVariables,
} from 'client/app/gql';
import { usePartialCallback } from 'common/ui/hooks/usePartialCallback';
import { cacheEvict } from 'common/utils';

/**
 * After a simulation is started, we poll its status with repeated queries to
 * the server.  This function implements one such query.
 */
export async function fetchSimulationForPoll(
  apollo: ApolloClient<object>,
  id: SimulationId,
): Promise<SimulationForPollQuery['simulation']> {
  const res = await apollo.query({
    query: QUERY_SIMULATION_POLL,
    variables: { id },
    // Bypass Apollo cache because we repeatedly poll this to get status updates
    fetchPolicy: 'network-only',
  });
  return getResultOrThrow(res, 'fetchSimulationForPoll', data => data.simulation);
}

/**
 * Drop a given simulation from the DB. This also deletes all associated entities, such
 * as Simulation Tasks, Executions, Executions Tasks, Labware. Returns the simulation ID.
 *
 * In order to properly update the apollo cache you should pass the parent workflow ID.
 * TODO: make parentWorkflowId not optional
 */
async function deleteSimulation(
  apollo: ApolloClient<object>,
  id: SimulationId,
  associatedWorkflowId?: WorkflowId,
  additionalVariables?: WorkflowsBySimulationsQueryVariables,
): Promise<SimulationId> {
  const deleteResult = await apollo.mutate({
    mutation: MUTATION_DELETE_SIMULATION,
    variables: {
      simulationID: id,
    },
    update: cache => cacheEvict({ id: id, __typename: 'Simulation' }, cache),
    optimisticResponse: {
      __typename: 'Mutation',
      deleteSimulation: {
        __typename: 'DeleteSimulationOutput',
        simulationId: id,
      },
    },
    refetchQueries: associatedWorkflowId
      ? [
          {
            query: QUERY_WORKFLOW_BY_ID_WITH_SIMULATIONS,
            variables: {
              workflowId: associatedWorkflowId,
              ...additionalVariables,
            },
          },
          {
            query: QUERY_SIMULATION_COUNTS_BY_WORKFLOW_ID,
            variables: {
              id: associatedWorkflowId,
            },
          },
        ]
      : undefined,
  });
  return getResultOrThrow(
    deleteResult,
    'Delete simulation',
    data => data.deleteSimulation!.simulationId,
  );
}

export function useDeleteSimulation() {
  const apollo = useApolloClient();
  return usePartialCallback(apollo, deleteSimulation);
}

/**
 * Checks if the UI should redirect an Simulation Details page for an old Job id
 * to a Simulation (which was migrated from a legacy Job object in legacy Datastore).
 *
 * If the given legacyJobId does not exist then undefined is returned. This causes the
 * Simulation page to show a "not found" error instead of redirecting.
 */
async function checkRedirect(
  apollo: ApolloClient<object>,
  legacyJobId: string,
): Promise<SimulationId | undefined> {
  const { errors, data } = await apollo.query({
    query: QUERY_JOB_SIMULATION_REDIRECT,
    variables: {
      legacyJobId,
    },
  });
  if (errors) {
    throw new Error(
      `Error querying JobSimulationRedirect: ${errors.map(e => e.message).join(', ')}`,
    );
  }
  return data?.jobSimulationRedirect?.id;
}

export function useCheckRedirect() {
  const apollo = useApolloClient();

  return usePartialCallback(apollo, checkRedirect);
}
