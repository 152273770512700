import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DataSetIcon = React.memo<SvgIconProps>(function DataSetIcon(props) {
  return (
    <SvgIcon viewBox="-1 -4 24 24" {...props}>
      <path
        d="M4 4h4v2H4V4ZM9 4h4v2H9V4ZM9 7h7v2H9V7ZM4 10h4v2H4v-2ZM4 7h4v2H4V7ZM9 10h7v2H9v-2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2h13.172L18 4.828V14H2V2Zm18 13a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h14.586a1 1 0 0 1 .707.293l3.414 3.414a1 1 0 0 1 .293.707V15Z"
        fill="currentColor"
      />
      <path d="M15 5h4l-4-4v4Z" fill="currentColor" />
    </SvgIcon>
  );
});
