import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export const ReleaseQualityUnknownIcon = React.memo(function ReleaseQualityUnknownIcon(
  props,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" fill="none">
        <path
          d="M9.17 6l2 2H20v10H4V6h5.17zM10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"
          fill="currentColor"
        />
        <path
          d="M11.718 13.974c0-.428.048-.764.145-1.01.097-.25.253-.465.47-.647.217-.181.38-.347.492-.496a.816.816 0 00.172-.501c0-.443-.19-.664-.572-.664a.53.53 0 00-.426.194c-.108.129-.165.303-.171.523h-1.486c.006-.586.19-1.042.554-1.367.363-.328.873-.492 1.53-.492.653 0 1.158.15 1.515.452.36.302.54.731.54 1.288a1.5 1.5 0 01-.144.664c-.097.199-.252.402-.466.61l-.505.47c-.144.138-.243.28-.3.427a1.66 1.66 0 00-.091.55h-1.257zm-.18 1.336c0-.217.077-.394.232-.532a.854.854 0 01.59-.21c.234 0 .429.07.584.21a.673.673 0 01.237.532c0 .217-.079.396-.237.536a.848.848 0 01-.585.207.865.865 0 01-.588-.207.691.691 0 01-.233-.536z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
