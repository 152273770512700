import React, { useCallback, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import { MapEntryEditorBase } from 'client/app/components/Parameters/MapEntryEditor';
import {
  getKeyTypeFromAnthaType,
  getValueTypeFromAnthaType,
} from 'common/elementConfiguration/parameterUtils';
import { ParameterValue, ParameterValueDict } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useProgressiveList from 'common/ui/hooks/useProgressiveList';

type Props = {
  anthaType: string;
  keyEditorProps?: ParameterEditorConfigurationSpec;
  valueEditorProps?: ParameterEditorConfigurationSpec;
  onChange: (value: object | undefined, instanceName?: string) => void;
  instanceName?: string;
  /**
   * Make the key and value editors occupy the same line
   */
  inline?: boolean;
  /**
   * This locks the map so no keys can be added, removed, or changed. Only existing values
   * can be changed.
   */
  disableKeys?: boolean;
  overrideAddNewEntryCopy?: string;
} & ParameterEditorBaseProps<ParameterValueDict>;
type PropsBase = {
  keyType: string;
  valueType: string;
} & Omit<Props, 'anthaType'>;
type Entries = Map<string, ParameterValue>;
const MapEditorBase = React.memo(function (props: PropsBase) {
  const classes = useStyles();
  const [isNewEntryEditorVisible, setIsNewEntryEditorVisible] = useState(false);
  const [newEntryKey, setNewEntryKey] = useState('');
  const [newEntryValue, setNewEntryValue] = useState('');
  const [entries, setEntries] = useState<Entries>(
    new Map(Object.entries(props.value ?? {})),
  );
  useEffect(() => {
    setEntries(new Map(Object.entries(props.value ?? {})));
  }, [props.value]);

  const { onChange } = props;

  const getUpdatedEntries = useCallback(
    (oldKey: string, newKey: string, value: string, shouldAddEntry?: boolean) => {
      // Replace '' with null so it doesn't get set to undefined by
      // sanitiseParameterValue, as that would be invalid JSON so
      // the whole entry would be ignored when the workflow is sent
      // to appserver
      const sanitisedValue = value !== '' && value !== undefined ? value : null;
      const newEntries = new Map(entries);
      if (shouldAddEntry) {
        newEntries.set(newKey, sanitisedValue);
        setEntries(newEntries);
        return newEntries;
      } else {
        const entriesArray = [...newEntries.entries()];
        // Convert to array to be able to change entry at specific index.
        const oldEntryIndex = entriesArray.findIndex(([key]) => key === oldKey);
        entriesArray.splice(oldEntryIndex, 1, [newKey, sanitisedValue]);
        const newEntriesMap = new Map(entriesArray);
        setEntries(newEntriesMap);
        return newEntriesMap;
      }
    },
    [entries],
  );

  const getUpdatedValueObject = useCallback(
    (oldKey: string, newKey: string, value: string) =>
      Object.fromEntries(getUpdatedEntries(oldKey, newKey, value)),
    [getUpdatedEntries],
  );

  const getValueObjectWithNewEntry = useCallback(
    (oldKey: string, newKey: string, value: string) =>
      Object.fromEntries(getUpdatedEntries(oldKey, newKey, value, true)),
    [getUpdatedEntries],
  );

  const onEntryKeyChange = useCallback(
    (oldKey: string, newKey: string, instanceName?: string) => {
      if (oldKey === newKey) {
        return;
      }
      const value = props.value?.[oldKey];
      const newValueObject = getUpdatedValueObject(oldKey, newKey, value);
      onChange(newValueObject, instanceName);
    },
    [getUpdatedValueObject, onChange, props.value],
  );

  const onEntryValueChange = useCallback(
    (key: string, value: string) => {
      const newValueObject = getUpdatedValueObject(key, key, value);
      onChange(newValueObject);
    },
    [getUpdatedValueObject, onChange],
  );

  const onDeleteEntry = useCallback(
    (key: string) => {
      const newEntries = new Map(entries);
      newEntries.delete(key);
      setEntries(newEntries);

      // Reset the value to undefined rather than {} make it clear it has been unset.
      if (newEntries.size === 0) {
        onChange(undefined);
      } else {
        onChange(Object.fromEntries(newEntries));
      }
    },
    [entries, onChange],
  );

  const onNewEntryKeyChange = useCallback(
    (oldKey: string, newKey: string, instanceName?: string) => {
      if (oldKey === newKey) {
        return;
      }
      setNewEntryKey('');
      setNewEntryValue('');
      setIsNewEntryEditorVisible(false);
      const newValueObject = getValueObjectWithNewEntry(oldKey, newKey, newEntryValue);
      onChange(newValueObject, instanceName);
    },
    [getValueObjectWithNewEntry, newEntryValue, onChange],
  );

  const onDeleteNewEntry = useCallback(() => {
    setNewEntryKey('');
    setNewEntryValue('');
    setIsNewEntryEditorVisible(false);
  }, []);

  const showNewEntryEditor = useCallback(() => setIsNewEntryEditorVisible(true), []);

  const {
    keyType,
    valueType,
    isDisabled,
    instanceName,
    keyEditorProps,
    valueEditorProps,
    inline,
    disableKeys,
  } = props;
  const valueKeys = entries.keys();
  const keySet = new Set(valueKeys);
  // JavaScript objects only support string values for keys, so the editor
  // that we use for key values is going to have to start from a string
  // representation of whatever value it actually wants.
  const slowEntries = useProgressiveList([...entries]);
  const children = [...slowEntries].map(([key, parameterValue]) => (
    <MapEntryEditorBase
      keySet={keySet}
      keyType={keyType}
      // React has its own special `key` property, so we need our own,
      // separate keyString property
      key={key}
      keyString={key}
      valueType={valueType}
      value={parameterValue}
      onKeyChange={onEntryKeyChange}
      onValueChange={onEntryValueChange}
      onDeleteEntry={onDeleteEntry}
      isDisabled={isDisabled}
      instanceName={instanceName}
      keyEditorProps={keyEditorProps}
      valueEditorProps={valueEditorProps}
      inline={inline}
      disableKeys={disableKeys}
    />
  ));

  let newEntryEditor = null;
  if (isNewEntryEditorVisible) {
    newEntryEditor = (
      <MapEntryEditorBase
        keyType={keyType}
        // React has its own special `key` property, so we need our own,
        // separate keyString property
        keySet={keySet}
        keyString={newEntryKey}
        valueType={valueType}
        value={newEntryValue}
        onKeyChange={onNewEntryKeyChange}
        onValueChange={setNewEntryValue}
        onDeleteEntry={onDeleteNewEntry}
        instanceName={props.instanceName}
        keyEditorProps={keyEditorProps}
        valueEditorProps={valueEditorProps}
        inline={inline}
        disableKeys={disableKeys}
      />
    );
  }

  return (
    <>
      {children}
      {!props.isDisabled && !disableKeys && (
        <>
          {newEntryEditor}
          <Button
            color="primary"
            variant="tertiary"
            startIcon={<AddIcon color="primary" fontSize="small" />}
            onClick={showNewEntryEditor}
            className={classes.button}
            disabled={isNewEntryEditorVisible}
            fullWidth
          >
            {props.overrideAddNewEntryCopy ?? 'Add new entry'}
          </Button>
        </>
      )}
      {props.isDisabled && children.length === 0 && (
        <div className={classes.emptyMessage}>None selected</div>
      )}
    </>
  );
});

const useStyles = makeStylesHook({
  button: {
    alignItems: 'stretch',
  },
  emptyMessage: {
    color: Colors.GREY_40,
    fontStyle: 'italic',
  },
});

export default function MapEditor(props: Props) {
  const { anthaType, ...rest } = props;
  const keyType = getKeyTypeFromAnthaType(anthaType);
  const valueType = getValueTypeFromAnthaType(anthaType);
  return <MapEditorBase {...rest} keyType={keyType} valueType={valueType} />;
}

/**
 * A MapEditor where the key and value occupy the same line. This is used by the Mixture
 * Definitions editor.
 */
export function InlineMapEditor(props: Props) {
  return <MapEditor {...props} inline />;
}
