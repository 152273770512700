import { useApolloClient } from '@apollo/client';

import { QUERY_DEVICE_LIQUID_CLASSES_CSV_BY_GUID } from 'client/app/api/gql/queries';
import { DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import { downloadTextFile } from 'common/lib/download';

const useHandleDownloadLiquidClassCsv = (device: DeviceCommon) => {
  const apollo = useApolloClient();

  const handleDownloadCSV = async () => {
    // TODO: this query should probably be by device ID, not anthahub id.
    // It's ok currently as all devices with RunConfig have anthaHubGUID
    if (device.anthaHubGUID == null) {
      throw new Error(`Device is missing antha hub GUID`);
    }

    const result = await apollo.query({
      query: QUERY_DEVICE_LIQUID_CLASSES_CSV_BY_GUID,
      variables: {
        anthaHubGUID: device.anthaHubGUID,
      },
      fetchPolicy: 'network-only',
    });
    if (result.errors || !result.data) {
      return;
    }
    if (result.data.devices.length === 0) {
      throw new Error('Requested device not found.');
    }

    const { liquidClasses } = result.data.devices[0];

    downloadTextFile(
      liquidClasses.asCSV,
      `${device.name}_liquid_classes.csv`,
      'application/csv',
    );
  };

  return handleDownloadCSV;
};

export default useHandleDownloadLiquidClassCsv;
