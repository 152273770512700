import React from 'react';

import IconGridOn from '@mui/icons-material/GridOn';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import PlateView from 'client/app/components/PlatePrep/PlateView';
import WellsList from 'client/app/components/PlatePrep/WellsList';
import doNothing from 'common/lib/doNothing';
import { PRINT_HEIGHT_A4 } from 'common/ui/components/Print';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';

type Props = {
  deckLayout: DeckLayout;
  simulationName?: string;
  selectedPlate: PlateState;
  liquidColors: LiquidColors;
};

/** This component a "Printer friendly" version of the PlatePrepScreen.
 *  It has a simplified layout so that everything fits nicely in the page
 *  when users want to print their setup screen.
 */
function PlatePrepScreenPrintable({
  deckLayout,
  simulationName,
  selectedPlate,
  liquidColors,
}: Props) {
  return (
    <Container>
      {simulationName && (
        <Header>
          <Typography variant="h1" fontWeight={300} color="textPrimary">
            Simulation:
          </Typography>
          <LongNameTypography variant="h1">{simulationName}</LongNameTypography>
          <SetupIconPlaceholder>
            <IconGridOn />
            <Typography variant="h2" color="textPrimary">
              Setup
            </Typography>
          </SetupIconPlaceholder>
        </Header>
      )}

      <PlateNamePlaceholder>
        <Typography variant="h4" fontWeight={300} color="textPrimary">
          Plate:
        </Typography>
        <LongNameTypography variant="h4">{selectedPlate.name}</LongNameTypography>
      </PlateNamePlaceholder>
      <PlatePlaceholder>
        {selectedPlate && (
          <PlateView
            key={selectedPlate.id}
            deckLayout={deckLayout}
            plate={selectedPlate}
            liquidColors={liquidColors}
            // Unused properties in the printable version
            highlightedWellLocation={null}
            onWellMouseEnter={doNothing}
            onWellMouseLeave={doNothing}
          />
        )}
      </PlatePlaceholder>

      <Typography variant="h4" fontWeight={300} color="textPrimary">
        Liquids:
      </Typography>
      <WellListWrapper>
        <WellsList
          plate={selectedPlate}
          liquidColors={liquidColors}
          // Unused properties in the printable version
          highlightedWellLocation={null}
        />
      </WellListWrapper>
    </Container>
  );
}

const Container = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(5),

  height: PRINT_HEIGHT_A4,

  '@media print': {
    '@page': {
      size: 'A4 landscape',
      margin: '1cm',
    },
  },
}));

const Header = styled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  gap: theme.spacing(3),
}));

const SetupIconPlaceholder = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

const PlatePlaceholder = styled('section')({
  height: PRINT_HEIGHT_A4,
  pageBreakAfter: 'always',
});

const PlateNamePlaceholder = styled('section')(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  gap: theme.spacing(2),
}));

const LongNameTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.text.primary,
  flexGrow: 1,
}));

const WellListWrapper = styled('section')({
  columnCount: 'auto',
  // Standard A4 format is roughly 8in. A bit less than
  // half to account for page margin.
  columnWidth: '3.5in',
});

export default PlatePrepScreenPrintable;
