import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import hasBioreactorDataset from 'client/app/apps/experiments/dataset-settings/hasBioreactorDataset';
import { isBioreactorDataset } from 'client/app/apps/experiments/dataset-settings/isBioreactorDataset';
import { MultiselectDatasetsDialog } from 'client/app/apps/experiments/DatasetDialog';
import {
  DatasetListHeader,
  DatasetListItem,
} from 'client/app/apps/experiments/DatasetListItem';
import {
  MUTATION_ADD_DATASET_DERIVATION_INPUTS,
  MUTATION_REMOVE_DATASET_DERIVATION_INPUT,
} from 'client/app/apps/experiments/gql/mutations';
import { QUERY_DATASET_DERIVATION } from 'client/app/apps/experiments/gql/queries';
import { BaseCard } from 'client/app/components/cards/BaseCard';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import { ArrayElement, DatasetDerivationQuery } from 'client/app/gql';
import { experimentsRoutes } from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import CardType from 'common/ui/components/CardType';
import EmptyGridCell from 'common/ui/components/EmptyGridCell';
import IconButton from 'common/ui/components/IconButton';
import RouteButton from 'common/ui/components/navigation/RouteButton';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import BioprocessIcon from 'common/ui/icons/DatasetDerivationIcon';

type Props = {
  derivationId: DatasetDerivationId;
  experimentId: ExperimentId;
  onClick?: () => void;
  isReadonly?: boolean;
};

/**
 * An experiment block that allows the user to add datasets from bioreactor and analytical
 * devices, match rows in the datasets to samples, and launch the python-based analysis
 * tool.
 */
export default function BioprocessBlock({
  derivationId,
  experimentId,
  onClick,
  isReadonly,
}: Props) {
  const classes = useStyles();

  // This block is a frontend to a 'dataset derivation'. We're not using datasetDerivation
  // as it was originally intended; which is to link input datasets to a consequent output
  // dataset. We do not generate an output dataset, instead only using the derivation data
  // structure to store a list of datasets that should be opened in the bioprocessing vis
  // app.
  const { data, loading } = useQuery(QUERY_DATASET_DERIVATION, {
    variables: { id: derivationId },
  });

  const datasetDerivation = data?.datasetDerivation;

  const [datasetsDialog, openDatasetsDialog] = useDialog(MultiselectDatasetsDialog);

  const [addInputMutation] = useMutation(MUTATION_ADD_DATASET_DERIVATION_INPUTS);

  const [removeInputMutation] = useMutation(MUTATION_REMOVE_DATASET_DERIVATION_INPUT);

  const onSelectExistingDataset = async (type?: 'bioreactor' | 'analytical') => {
    const selectedDatasetIds = await openDatasetsDialog({
      excludedDatasetIds: datasetDerivation?.inputs.map(input => input.datasetId),
      deviceCategory: type === 'bioreactor' ? 'Bio Reactors' : undefined,
      notDeviceCategory: type === 'analytical' ? 'Bio Reactors' : undefined,
    });

    if (selectedDatasetIds === CANCEL_CHOICE) {
      return;
    }
    await addInputMutation({
      variables: {
        datasetIds: selectedDatasetIds as unknown as UUID[],
        derivationId: derivationId as unknown as UUID,
      },
      refetchQueries: [
        {
          query: QUERY_DATASET_DERIVATION,
          variables: { id: derivationId },
        },
      ],
    });
  };

  const onRemoveInput = async (datasetId: DatasetId) => {
    await removeInputMutation({
      variables: {
        datasetId: datasetId as unknown as UUID,
        derivationId: derivationId as unknown as UUID,
      },
      refetchQueries: [
        {
          query: QUERY_DATASET_DERIVATION,
          variables: { id: derivationId },
        },
      ],
    });
  };

  const isNextGen = useFeatureToggle('NEXT_GEN_BIOPROCESS_BLOCK');

  if (loading || !datasetDerivation) {
    return null;
  }

  const isDeriveDisabled =
    isReadonly ||
    datasetDerivation.inputs.length < 1 ||
    !hasBioreactorDataset(datasetDerivation);

  const bioreactorDatasets = datasetDerivation.inputs.filter(isBioreactorDataset);
  const analyticalDatasets = datasetDerivation.inputs.filter(
    d => !isBioreactorDataset(d),
  );

  return (
    <>
      <BaseCard className={classes.container} onClick={onClick}>
        <CardType icon={<BioprocessIcon fontSize="small" />} />
        <div>
          {isNextGen ? (
            <>
              <BioprocessDatasetList
                type="Bioreactor"
                placeholder="Please add one or more bioreactor datasets"
                datasetDerivation={datasetDerivation}
                datasets={bioreactorDatasets}
                onAddClick={() => onSelectExistingDataset('bioreactor')}
                onRemoveDataset={onRemoveInput}
                isReadonly={isReadonly}
              />
              <BioprocessDatasetList
                type="Analytical"
                placeholder="Optionally add one or more analytical datasets"
                datasetDerivation={datasetDerivation}
                datasets={analyticalDatasets}
                onAddClick={() => onSelectExistingDataset('analytical')}
                onRemoveDataset={onRemoveInput}
                isReadonly={isReadonly}
              />
            </>
          ) : (
            <LegacyDatasetList
              datasetDerivation={datasetDerivation}
              onAddClick={onSelectExistingDataset}
              onRemoveDataset={onRemoveInput}
              isReadonly={isReadonly}
            />
          )}
        </div>

        <EmptyGridCell />

        {datasetDerivation.outputDataset ? (
          <>
            <Typography variant="h5" className={classes.outputHeader}>
              Output dataset
            </Typography>
            <EmptyGridCell />
            <div className={classes.outputRow}>
              <Typography>{datasetDerivation.outputDataset.name}</Typography>

              <Button
                className={classes.ctaButton}
                variant="contained"
                disabled={isDeriveDisabled}
              >
                View
              </Button>
            </div>
          </>
        ) : (
          <div className={classes.ctaRow}>
            <RouteButton
              variant="contained"
              color="primary"
              disabled={isDeriveDisabled}
              label={isNextGen ? 'Analyze' : 'Derive'}
              route={experimentsRoutes.bioprocess}
              routeParam={{ derivationId, experimentId }}
              logEventCategory={ScreenRegistry.EXPERIMENTS}
            />
          </div>
        )}
      </BaseCard>
      {datasetsDialog}
    </>
  );
}

type DatasetDerivationInput = ArrayElement<
  DatasetDerivationQuery['datasetDerivation']['inputs']
>;

function BioprocessDatasetList({
  type,
  placeholder,
  datasetDerivation,
  datasets,
  onAddClick,
  onRemoveDataset,
  isReadonly,
}: {
  type: string;
  placeholder: string;
  datasetDerivation: DatasetDerivationQuery['datasetDerivation'];
  datasets: DatasetDerivationInput[];
  onAddClick: () => void;
  onRemoveDataset: (id: DatasetId) => void;
  isReadonly?: boolean;
}) {
  const classes = useStyles();
  return (
    <div className={classes.datasetList}>
      <Typography variant="h5" className={classes.datasetListTitle}>
        {type} datasets
      </Typography>

      {datasets.length === 0 ? (
        <Typography variant="body1">{placeholder}</Typography>
      ) : (
        <>
          <DatasetListHeader />
          {datasets.map(dataset => (
            <DatasetListItem
              key={dataset.datasetId}
              derivationId={datasetDerivation.id}
              dataset={dataset}
              onRemoveDataset={
                isReadonly ? undefined : () => onRemoveDataset(dataset.datasetId)
              }
              isReadonly={isReadonly}
            />
          ))}
        </>
      )}

      {!isReadonly && (
        <Button
          className={classes.addDatasetButton}
          startIcon={<AddIcon />}
          color="primary"
          onClick={onAddClick}
        >
          Add {type} Dataset
        </Button>
      )}
    </div>
  );
}

function LegacyDatasetList({
  datasetDerivation,
  onAddClick,
  onRemoveDataset,
  isReadonly,
}: {
  datasetDerivation: DatasetDerivationQuery['datasetDerivation'];
  onAddClick: () => void;
  onRemoveDataset: (id: DatasetId) => void;
  isReadonly?: boolean;
}) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.inputsHeader}>
        <Typography variant="h5">Input datasets</Typography>
        {!isReadonly && (
          <IconButton onClick={onAddClick} icon={<AddIcon />} size="large" />
        )}
      </div>
      {datasetDerivation.inputs.map((dataset, i) => (
        <DatasetListItem
          key={dataset.datasetId}
          derivationId={datasetDerivation.id}
          dataset={dataset}
          className={cx({
            [classes.inputItem]: i < datasetDerivation.inputs.length - 1,
          })}
          onRemoveDataset={
            isReadonly ? undefined : () => onRemoveDataset(dataset.datasetId)
          }
          isReadonly={isReadonly}
        />
      ))}
      {datasetDerivation.inputs.length === 0 && (
        <Typography variant="body1">No input datasets have been added yet.</Typography>
      )}
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  container: {
    gridTemplateColumns: '[icon] min-content [card-content] auto',
    alignItems: 'unset',
  },
  datasetList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    margin: theme.spacing(4, 0, 6),
  },
  addDatasetButton: {
    alignSelf: 'flex-start',
  },
  datasetListTitle: {
    marginBottom: theme.spacing(3),
  },
  inputsHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    height: '48px',
  },
  inputItem: {
    marginBottom: theme.spacing(2),
  },
  ctaRow: {
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'end',
  },
  ctaButton: {
    alignSelf: 'flex-end',
  },
  outputHeader: {
    marginTop: theme.spacing(3),
  },
  outputRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
