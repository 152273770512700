// For the time being, we don't have a way to persist this list to the server.
// When the appserver/postgres project has steam in its sails, we can migrate
// this there. In the mean time, this.
export type Carrier = {
  name: string;
  height: number;
  id: string;
};

const Carriers = [
  {
    name: '20mm riser (Gilson)',
    height: 20,
    id: '_riser20',
  },
  {
    name: '40mm riser (CyBio)',
    height: 40.0,
    id: '_riser40',
  },
  {
    name: '18mm riser (Gilson)',
    height: 18.0,
    id: '_riser18',
  },
  {
    name: 'Bioshake incubator flat bottom standard adapter (Cat No. 1808-1021)',
    height: 5.0,
    id: '_bioshake_standard_adaptor',
  },
  {
    name: 'Bioshake incubator PCR plate 96well adapter (Cat No. 1808-1041)',
    height: 4.5,
    id: '_bioshake_96well_adaptor',
  },
  {
    name: 'Magnetic Ring Stand (Applied Biosytems)',
    height: 12.0,
    id: '_with_AB_magnetic_ring_stand',
  },
  {
    name: 'no riser',
    height: 0,
    id: '',
  },
];

export default Carriers;
