import { useCallback, useEffect, useRef } from 'react';

import throttle from 'lodash/throttle';

type Callback<T extends any[]> = (...args: T) => any;

/**
 * A throttled callback has no return value because the callback is deferred by
 * a timeout.
 */
type ThrottledCallback<T extends any[]> = (...args: T) => void;

/**
 * Settings for lodash.throttle. Unfortunately this type isn't exported by @types/lodash
 * so we need to rewrite it here.
 */
type ThrottleSettings = {
  leading?: boolean;
  trailing?: boolean;
};

/**
 * Throttle a callback to every x ms. Use like:
 *
 * ```
 * const doSomething = useCallback(() => {
 *   expensiveOperation();
 * }, [expensiveOperation]);
 * const throttledDoSomething = useThrottle(doSomething, 1000);
 * ```
 *
 * Now, throttledDoSomething() will only invoke doSomething up to once a second.
 *
 * We cannot simply do `useCallback(throttle(...), [deps...])` because the
 * throttled function would be redeclared (and thus reset) each render.
 */
export default function useThrottle<T extends any[]>(
  callback: Callback<T>,
  delay: number,
  options?: ThrottleSettings,
): ThrottledCallback<T> {
  // Below, we initialise state using `useRef()` rather than `useState()` to
  // prevent re-render when updating the date.

  // Keep the callback in a reference accessible by the throttled function
  const savedCallback = useRef<Callback<T>>(callback);
  // The actual throttled function, kept in a reference
  const throttledCallback = useRef<ThrottledCallback<T> | undefined>();

  // Each time the callback changes, update the callback ref. Without this, the
  // old callback would be called by the timeout.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Each time the delay changes (on on initialisation), set up the throttled
  // function. (Note: we cannot put this with the callback effect above, because
  // the callback can change on each render).
  useEffect(() => {
    const throttled = throttle(
      (...args: T) => savedCallback.current(...args),
      delay,
      options,
    );
    throttledCallback.current = throttled;
    return () => throttled.cancel();
  }, [delay, options]);

  // When invoked, use the throttled version of the callback
  return useCallback((...args: T) => {
    throttledCallback.current?.(...args);
  }, []);
}
