import { useMutation, useQuery } from '@apollo/client';
import produce from 'immer';

import {
  MUTATION_CREATE_EXAMPLE_WORKFLOW,
  MUTATION_DELETE_EXAMPLE_WORKFLOW,
  MUTATION_UPDATE_EXAMPLE_WORKFLOW,
} from 'client/app/api/gql/mutations';
import { QUERY_EXAMPLE_WORKFLOW } from 'client/app/api/gql/queries';
import { Markdown } from 'common/lib/markdown';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export type ExampleWorkflowHandler = ReturnType<typeof useExampleWorkflowHandlers>;

export default function useExampleWorkflowHandlers(
  snapshotId: WorkflowId,
  parentWorkflowId?: WorkflowId,
) {
  const { showError } = useSnackbarManager();

  const { data: exampleWorkflowData, loading: reading } = useQuery(
    QUERY_EXAMPLE_WORKFLOW,
    {
      variables: {
        parentWorkflowId,
      },
      skip: !parentWorkflowId,
    },
  );

  const [createExampleWorkflow, { loading: creating }] = useMutation(
    MUTATION_CREATE_EXAMPLE_WORKFLOW,
  );
  const [updateExampleWorkflow, { loading: updating }] = useMutation(
    MUTATION_UPDATE_EXAMPLE_WORKFLOW,
  );
  const [deleteExampleWorkflow, { loading: deleting }] = useMutation(
    MUTATION_DELETE_EXAMPLE_WORKFLOW,
  );

  const queryExampleWorkflow = {
    query: QUERY_EXAMPLE_WORKFLOW,
    variables: { parentWorkflowId },
  };

  return {
    data: exampleWorkflowData?.exampleWorkflow,
    exist: !!exampleWorkflowData?.exampleWorkflow,
    loading: reading || creating || updating || deleting,
    create: async (description: Markdown, summary: string, tags: string[]) => {
      try {
        await createExampleWorkflow({
          variables: {
            snapshotId,
            description,
            summary,
            tags,
          },
          refetchQueries: [queryExampleWorkflow],
          update(cache) {
            cache.updateQuery(queryExampleWorkflow, data =>
              produce(data, draft => {
                if (draft?.exampleWorkflow) {
                  draft.exampleWorkflow.description = description;
                  draft.exampleWorkflow.summary = summary;
                }
              }),
            );
          },
        });
      } catch (error) {
        showError(error.message);
      }
    },
    update: async (updates: {
      description?: Markdown;
      summary?: string;
      tags?: string[];
    }) => {
      try {
        if (!exampleWorkflowData?.exampleWorkflow) {
          throw new Error('Updating unexisting example');
        }

        await updateExampleWorkflow({
          variables: {
            exampleWorkflowUpdates: {
              exampleId: exampleWorkflowData.exampleWorkflow.id,
              updates: {
                snapshotId,
                ...updates,
              },
            },
          },
          refetchQueries: [queryExampleWorkflow],
          update(cache) {
            cache.updateQuery(queryExampleWorkflow, data =>
              produce(data, draft => {
                if (draft && updates.description) {
                  draft.exampleWorkflow!.description = updates.description;
                }
                if (draft && updates.summary) {
                  draft.exampleWorkflow!.summary = updates.summary;
                }
              }),
            );
          },
        });
      } catch (error) {
        showError(error.message);
      }
    },
    delete: async () => {
      try {
        if (!exampleWorkflowData?.exampleWorkflow) {
          throw new Error('Deleting unexisting example');
        }

        await deleteExampleWorkflow({
          variables: {
            exampleId: exampleWorkflowData.exampleWorkflow.id,
          },
          refetchQueries: [queryExampleWorkflow],
        });
      } catch (error) {
        showError(error.message);
      }
    },
  };
}
