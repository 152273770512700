import React, { ReactNode } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarToday from '@mui/icons-material/CalendarToday';
import PersonOutline from '@mui/icons-material/PersonOutline';
import TimerOutlined from '@mui/icons-material/TimerOutlined';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { formatDateTime, formatDuration } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import StatusIndicator, { Status } from 'common/ui/components/simulation-details/Status';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

export type CommonHeaderInfo = {
  name: string;
  submitterName: string;
  dateCreated: Date;
  dateFinished?: Date;
};

export type CommonHeaderProps = {
  headerInfo: CommonHeaderInfo;
  status?: Status | null;
  simulationSeriesPart?: number | null;
  estimatedTimeSeconds?: number | null;
  createdAtAction?: 'Started' | 'Created';
  /** A component that precedes the title */
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  /** Show an arrow button in the top left corner. */
  onGoBackClick?: () => void;
};

export default function CommonHeader({
  headerInfo,
  status,
  simulationSeriesPart,
  estimatedTimeSeconds,
  createdAtAction,
  startAdornment,
  onGoBackClick,
  endAdornment,
}: CommonHeaderProps) {
  const hasStatus = status && status !== 'Unknown' && status !== 'None';

  const showMarginLeftOnTitle = startAdornment || onGoBackClick;

  return (
    <Header>
      <HeaderTop>
        {onGoBackClick && (
          <IconButton onClick={onGoBackClick} icon={<ArrowBackIcon />} size="small" />
        )}
        {startAdornment}
        <Title
          sx={{
            marginLeft: showMarginLeftOnTitle ? 3 : 0,
          }}
          variant="h2"
        >
          {headerInfo.name}
        </Title>
        {simulationSeriesPart && simulationSeriesPart > 0 && (
          <SimulationSeriesPartIndicator variant="subtitle1" color="textPrimary">
            Part {simulationSeriesPart}
          </SimulationSeriesPartIndicator>
        )}
        <div style={{ marginLeft: 'auto' }}>{endAdornment}</div>
      </HeaderTop>

      <Metadata>
        <MetadataLabel>
          <PersonOutline fontSize="small" />
          Creator:
        </MetadataLabel>
        <MetadataValue>{headerInfo.submitterName}</MetadataValue>

        <MetadataLabel>
          <CreatedAtIcon />
          <span>{createdAtAction ? createdAtAction + ' on' : 'Date'}: </span>
        </MetadataLabel>
        <MetadataValue>{formatDateTime(headerInfo.dateCreated, 'at')}</MetadataValue>

        {typeof estimatedTimeSeconds === 'number' && (
          <>
            <MetadataLabel>
              <EstimatedTimeIcon fontSize="small" />
              Estimated time:
            </MetadataLabel>
            <MetadataValue>{formatDuration(estimatedTimeSeconds)}</MetadataValue>
          </>
        )}

        {hasStatus && (
          <>
            <MetadataLabel>Status:</MetadataLabel>
            <MetadataValue>
              <StatusIndicator status={status} />
              {headerInfo.dateFinished ? (
                <>
                  &nbsp;<Typography variant="body1">on</Typography>&nbsp;
                  {formatDateTime(headerInfo.dateFinished, 'at')}
                </>
              ) : null}
            </MetadataValue>
          </>
        )}
      </Metadata>
    </Header>
  );
}

const Header = styled('header')(({ theme }) => ({
  backgroundColor: Colors.SIMULATION_DETAILS_BACKGROUND_GRAY,
  padding: theme.spacing(5, 6, 4, 6),
}));

const HeaderTop = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  // Make row the same height as the "schedule" button, such that when it's not present
  // the row is still the same height
  height: 40,
});

const Title = styled(TypographyWithTooltip)(({ theme }) => ({
  marginRight: theme.spacing(3),
}));

const SimulationSeriesPartIndicator = styled(Typography)(({ theme }) => ({
  background: Colors.BLUE_5,
  borderRadius: theme.spacing(4),
  fontWeight: 500,
  padding: theme.spacing(2, 4),
  marginRight: theme.spacing(3),
  whiteSpace: 'nowrap',
}));

const Metadata = styled('dl')(({ theme }) => ({
  margin: theme.spacing(0),
  color: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  rowGap: theme.spacing(2),
}));

const MetadataLabel = styled('dt')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  ...theme.typography.body1,
  ':not(:first-of-type)': {
    borderLeft: `1px solid ${theme.palette.grey[400]}`,
    paddingLeft: theme.spacing(5),
  },
}));

const MetadataValue = styled('dd')(({ theme }) => ({
  display: 'inline-flex',
  marginLeft: theme.spacing(2),
  paddingRight: theme.spacing(5),
  ...theme.typography.subtitle2,
}));

const CreatedAtIcon = styled(CalendarToday)({
  fontSize: 14,
});

const EstimatedTimeIcon = styled(TimerOutlined)({
  fontSize: 14,
});
