import produce from 'immer';

import { endsWithNumber } from 'common/lib/format';
import { Position2d } from 'common/types/Position';
import { CellValue, DataTable, Row } from 'common/types/spreadsheetEditor';
import {
  getColumnNameByIndex,
  getLastNonEmptyRowIndexByColumn,
  getLastNonEmptyRowIndexInRange,
} from 'common/ui/components/Dialog/spreadsheetHelpers';
import {
  findSelectionRange,
  getLastContiguousSimilarRowIndexInColumn,
  isCellInSelectionRange,
  isEmptyCellValue,
} from 'common/ui/components/Table';

export type Action =
  | {
      type: 'SELECT_RANGE';
      start: Position2d | null;
      end: Position2d | null;
      moveFocusToStart?: boolean;
      isDragSelecting?: boolean;
    }
  | {
      type: 'SELECT_CELL';
      position: Position2d;
      isDragFilling?: boolean;
    }
  | {
      type: 'SELECT_ROW';
      index: number;
    }
  | {
      type: 'POINTER_DOWN_ON_COLUMN';
      index: number;
      startDragSelection: boolean;
    }
  | {
      type: 'POINTER_MOVE_ON_COLUMN';
      index: number;
    }
  | {
      type: 'POINTER_UP_ON_COLUMN';
      index: number;
    }
  | {
      type: 'POINTER_DOWN_ON_CELL';
      position: Position2d;
      isHoldingShift: boolean;
      isRightClick: boolean;
    }
  | {
      type: 'POINTER_MOVE_ON_CELL';
      position: Position2d;
    }
  | {
      type: 'POINTER_UP_ON_CELL';
      position: Position2d;
      isIncrementingColumn: boolean;
    }
  | { type: 'STOP_DRAG_FILL' }
  | {
      type: 'SET_TABLE_DATA';
      data: Row[];
    }
  | {
      type: 'SET_TABLE';
      dataTable: DataTable;
    }
  | {
      type: 'SET_CELL_VALUE';
      position: Position2d;
      value: CellValue;
    }
  | {
      type: 'FILL_REST_OF_COLUMN';
      position: Position2d;
    };

type TableState = {
  dataTable: DataTable;
  selectionStart: Position2d | null;
  selectionEnd: Position2d | null;
  isDragFilling: boolean;
  isDragSelecting: boolean;
  isDragSelectingColumns: boolean;
  focussedCell: Position2d | null;
};

export function tableReducer(tableState: TableState, action: Action): TableState {
  switch (action.type) {
    case 'SELECT_RANGE':
      return {
        ...tableState,
        selectionStart: action.start,
        selectionEnd: action.end,
        isDragSelecting: action.isDragSelecting ?? tableState.isDragSelecting,
        focussedCell: action.moveFocusToStart ? action.start : tableState.focussedCell,
      };
    case 'SELECT_CELL':
      return {
        ...tableState,
        selectionStart: action.position,
        selectionEnd: action.position,
        isDragFilling: action.isDragFilling ?? tableState.isDragSelecting,
      };
    case 'STOP_DRAG_FILL':
      return {
        ...tableState,
        isDragFilling: false,
      };
    case 'SELECT_ROW':
      return {
        ...tableState,
        selectionStart: { x: 0, y: action.index },
        selectionEnd: {
          x: tableState.dataTable.schema.fields.length - 1,
          y: action.index,
        },
      };
    case 'POINTER_DOWN_ON_COLUMN': {
      const lastNonEmptyRowIndex = getLastNonEmptyRowIndexByColumn(
        tableState.dataTable,
        action.index,
      );

      return {
        ...tableState,
        selectionStart: { y: 0, x: action.index },
        selectionEnd: { y: lastNonEmptyRowIndex, x: action.index },
        isDragSelectingColumns:
          action.startDragSelection || tableState.isDragSelectingColumns,
      };
    }
    case 'POINTER_MOVE_ON_COLUMN': {
      if (!tableState.isDragSelectingColumns) {
        return tableState;
      }
      const lastNonEmptyRowIndexInRange = getLastNonEmptyRowIndexInRange(
        tableState.dataTable,
        tableState.selectionStart?.x ?? 0,
        tableState.selectionEnd?.x ?? 0,
      );

      return {
        ...tableState,
        selectionEnd: {
          y: lastNonEmptyRowIndexInRange,
          x: action.index,
        },
      };
    }
    case 'POINTER_UP_ON_COLUMN': {
      return {
        ...tableState,
        isDragSelectingColumns: false,
      };
    }
    case 'POINTER_DOWN_ON_CELL': {
      if (action.isRightClick) {
        const isAlreadySelected = isCellInSelectionRange(
          action.position.y,
          action.position.x,
          findSelectionRange(tableState.selectionStart, tableState.selectionEnd),
        );
        // Do not update selection on right click if users click on already selected cells.
        // This allows users to perform context menu actions on said cells.
        if (isAlreadySelected) {
          return tableState;
        }

        return {
          ...tableState,
          selectionStart: action.position,
          selectionEnd: action.position,
        };
      }

      return {
        ...tableState,
        isDragSelecting: action.isHoldingShift || tableState.isDragSelecting,
        selectionStart: action.position,
        selectionEnd: !action.isHoldingShift ? action.position : tableState.selectionEnd,
      };
    }
    case 'POINTER_UP_ON_CELL': {
      let newTable;

      const { selectionStart, selectionEnd } = tableState;

      if (tableState.isDragFilling && selectionStart && selectionEnd) {
        newTable = produce(tableState.dataTable, draft => {
          const initialColumnSelected = getColumnNameByIndex(
            tableState.dataTable,
            tableState.selectionStart!.x,
          );
          for (let column = selectionStart.x; column <= selectionEnd.x; column++) {
            const columnName = getColumnNameByIndex(tableState.dataTable, column);

            let newValue =
              tableState.dataTable.data[selectionStart.y][initialColumnSelected];

            for (let row = selectionStart.y; row <= selectionEnd.y; row++) {
              if (action.isIncrementingColumn) {
                const { number, numberIndex } = endsWithNumber(String(newValue));
                if (numberIndex && row !== selectionStart.y) {
                  newValue = `${String(newValue).substring(0, numberIndex)}${number + 1}`;
                }
              }
              draft.data[row][columnName] = newValue;
            }
          }
        });
      }

      return {
        ...tableState,
        isDragFilling: false,
        isDragSelecting: false,
        ...(tableState.isDragSelecting && {
          selectionEnd: action.position,
        }),
        ...(newTable && { dataTable: newTable }),
      };
    }
    case 'POINTER_MOVE_ON_CELL': {
      if (!tableState.isDragSelecting && !tableState.selectionStart) {
        return tableState;
      }

      if (tableState.isDragSelecting) {
        return {
          ...tableState,
          selectionEnd: action.position,
        };
      }

      if (
        tableState.isDragFilling &&
        tableState.selectionStart &&
        tableState.selectionEnd
      ) {
        const isDraggingHorizontally =
          tableState.selectionEnd.x - tableState.selectionStart.x >= 1;
        const isDraggingVertically =
          tableState.selectionEnd.y - tableState.selectionStart.y >= 1;
        const isDraggingDiagonally =
          tableState.selectionStart.x !== action.position.x &&
          tableState.selectionStart.y !== action.position.y;

        // If dragging horizontally but the pointer is in a different row,
        // make sure to fill the row where the selection started.
        let rowIndexToFill = action.position.y;
        // Likewise, if dragging vertically (or diagonally) but the pointer
        // is in a different column, make sure to modify the right column.
        // Rather than allowing diagonal dragging we force it to be vertical like GDocs
        let columnIndexToFill = action.position.x;

        if (isDraggingHorizontally) {
          rowIndexToFill = tableState.selectionStart.y;
        } else if (isDraggingVertically || isDraggingDiagonally) {
          columnIndexToFill = tableState.selectionStart.x;
        }
        return {
          ...tableState,
          selectionEnd: { x: columnIndexToFill, y: rowIndexToFill },
        };
      }
      return tableState;
    }
    case 'SET_CELL_VALUE': {
      const columnName = tableState.dataTable.schema.fields[action.position.x].name;
      // Some parameter editors might return 'undefined' (e.g. any using SelectFromDialogButton)
      // but we do not allow this as a CellValue, as this will be stripped when we convert this
      // to JSON later. So ensure we cast these as null.
      const sanitisedValue = action.value === undefined ? null : action.value;
      return produce(tableState, draft => {
        draft.dataTable.data[action.position.y][columnName] = sanitisedValue;
      });
    }
    case 'SET_TABLE': {
      return {
        ...tableState,
        dataTable: action.dataTable,
      };
    }
    case 'SET_TABLE_DATA': {
      return {
        ...tableState,
        dataTable: {
          ...tableState.dataTable,
          data: action.data,
        },
      };
    }
    /**
     * Executed when the user double-clicks on a drag fill handle, this fills any
     * empty cells beneath until it reaches a cell that has a value or the first
     * totally empty row.
     */
    case 'FILL_REST_OF_COLUMN': {
      const { x: columnIndex, y: rowIndex } = action.position;
      const columnName = tableState.dataTable.schema.fields[action.position.x].name;
      const fillValue = tableState.dataTable.data[rowIndex][columnName];

      if (isEmptyCellValue(fillValue)) {
        return tableState;
      }

      const lastRowIndexInContiguousCellBlock = getLastContiguousSimilarRowIndexInColumn(
        tableState.dataTable,
        rowIndex,
        columnIndex,
      );

      const newData = produce(tableState.dataTable.data, draft => {
        for (let row = rowIndex; row <= lastRowIndexInContiguousCellBlock; row++) {
          draft[row][columnName] = fillValue;
        }
      });

      return {
        ...tableState,
        selectionStart: { y: rowIndex, x: columnIndex },
        selectionEnd: {
          y: lastRowIndexInContiguousCellBlock,
          x: columnIndex,
        },
        dataTable: {
          ...tableState.dataTable,
          data: newData,
        },
      };
    }
  }
}

export function isUndoable(action: Action) {
  return undoableActions.includes(action.type);
}

const undoableActions: Action['type'][] = ['SET_TABLE_DATA', 'SET_TABLE'];
