import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ElementGroupIcon = React.memo<SvgIconProps>(function ElementGroupIcon(
  props,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M1 15.6h2.42V18H1v-2.4Zm0-4.8h2.42v2.4H1v-2.4Zm0 9.61h2.42v2.4A2.42 2.42 0 0 1 1 20.42ZM6.06 1.17h2.42v2.4H6.06v-2.4ZM1 5.98h2.42v2.4H1v-2.4Zm2.42-4.8v2.4H1c0-1.33 1.09-2.4 2.42-2.4Zm17.16 4.8H23v2.4h-2.42v-2.4ZM6.06 20.41h2.42v2.4H6.06v-2.4ZM20.58 1.17A2.42 2.42 0 0 1 23 3.57h-2.42v-2.4Zm-9.68 0h2.42v2.4H10.9v-2.4Zm4.84 0h2.42v2.4h-2.42v-2.4Z"
      />
      <path
        fill="currentColor"
        d="M8.48 20.41H6.06v2.4h2.42v-2.4Zm9.9 2.42v-11h-2.13v11h2.13Z"
      />
      <path fill="currentColor" d="M11.99 18.22h11v-2.13h-11v2.13Z" />
    </SvgIcon>
  );
});
