import React, { useContext, useMemo } from 'react';

import unique from 'lodash/uniq';

import { AutocompleteParameterValuesContext } from 'client/app/state/AutocompleteParameterValuesContext';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import Autocomplete, { Option } from 'common/ui/filaments/Autocomplete';

type Props = {
  anthaType: string;
  valueLabel?: string;
  onChange: (newValue?: string) => void;
  acceptCustomValues: boolean;
  /** Other types than the antha type that options should be sourced from */
  additionalSourceTypes?: string[];
  /**
   * Options to offer in addition to those in the Parameter values.
   * These will be displayed even if they aren't used anywhere in the workflow.
   * */
  additionalOptions?: string[];
  disableClearable?: boolean;
  onBlur?: () => void;
  label?: string;
  disableUnderline?: boolean;
} & Omit<ParameterEditorBaseProps<string>, 'value'>;

/**
 * An Autocomplete component that sources options from values already provided
 * to parameters of the same antha type within the Workflow Builder.
 *
 * Consumes AutocompleteParameterValuesContext so needs to be nested under
 * AutocompleteParameterValuesContextProvider.
 */
export default function AutocompleteWithParameterValues(props: Props) {
  const { anthaType, additionalSourceTypes, additionalOptions, valueLabel } = props;
  const { optionsForType, optionsForTypes } = useContext(
    AutocompleteParameterValuesContext,
  );

  const options: Option<string>[] = useMemo(() => {
    const dynamicOptions = additionalSourceTypes
      ? optionsForTypes(additionalSourceTypes.concat(anthaType))
      : optionsForType(anthaType);
    return filterBy(valueLabel, dynamicOptions, additionalOptions);
  }, [
    additionalOptions,
    additionalSourceTypes,
    anthaType,
    optionsForType,
    optionsForTypes,
    valueLabel,
  ]);

  return (
    <Autocomplete<string>
      valueLabel={valueLabel}
      options={options}
      onChange={props.onChange}
      onBlur={props.onBlur}
      acceptCustomValues={props.acceptCustomValues}
      isDisabled={props.isDisabled}
      disableClearable={props.disableClearable}
      isRequired={props.isRequired}
      placeholder={props.placeholder}
      hasError={props.hasError}
      label={props.label}
      disableUnderline={props.disableUnderline}
    />
  );
}

function filterBy(
  valueLabel: string | undefined,
  dynamicOptions: string[] = [],
  additionalOptions: string[] = [],
): Option<string>[] {
  const additionalOptionsCopy = [...additionalOptions];
  const dynamicOptionsCopy = dynamicOptions.filter(opt => typeof opt == 'string');

  additionalOptionsCopy.sort();
  dynamicOptionsCopy.sort();

  let result = unique([...additionalOptionsCopy, ...dynamicOptionsCopy]);

  if (valueLabel) {
    result = result.filter(option =>
      option.toLowerCase().includes(valueLabel.toLowerCase()),
    );
  }

  return result.map(value => ({ label: value, value }));
}
