import React, { useCallback, useContext, useMemo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import {
  getPlateParameterValueAsString,
  PlateParameterValue,
} from 'client/app/components/Parameters/PlateType/processPlateParameterValue';
import SimulationAndPlateSelectionDialog from 'client/app/components/Parameters/PlateType/SimulationAndPlateSelectionDialog';
import { AppIcon } from 'client/app/icons';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import { SimulationIcon } from 'common/ui/icons/SimulationIcon';

export type ExistingPlateSelectProps = {
  value?: PlateParameterValue;
  displayValue?: string;
  isDisabled?: boolean;
  onChange: (value?: PlateParameterValue) => void;
};

export default function ExistingPlateSelect({
  onChange,
  value = null,
  displayValue,
  isDisabled,
}: ExistingPlateSelectProps) {
  const classes = useStyles();
  const { screenId } = useContext(ScreenContext);
  const [simulationAndPlateSelectionDialog, openSimulationAndPlateSelectionDialog] =
    useDialog(SimulationAndPlateSelectionDialog);
  const handleChooseSimulationAndPlate = async () => {
    logEvent('select-plate-from-simulations-list', screenId as string);
    const selectedPlate = await openSimulationAndPlateSelectionDialog({
      initialValue: value,
    });
    onChange(selectedPlate);
  };

  const clearSelection = useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  const label = useMemo(
    () => displayValue ?? getPlateParameterValueAsString(value),
    [displayValue, value],
  );

  return (
    <>
      {!value ? (
        <Button
          variant="secondary"
          onClick={handleChooseSimulationAndPlate}
          className={classes.chooseBtn}
          startIcon={<SimulationIcon />}
        >
          Plate from a simulation
        </Button>
      ) : (
        <ButtonBase
          className={cx({ [classes.clickable]: !isDisabled }, classes.selectedItem)}
          onClick={!isDisabled ? handleChooseSimulationAndPlate : undefined}
        >
          <AppIcon iconId="antha:plate" />
          <Typography
            variant="overline"
            color="textPrimary"
            className={classes.typography}
          >
            {label}
          </Typography>
          {!isDisabled && (
            <CloseIcon
              onClick={clearSelection}
              fontSize="small"
              className={classes.closeIcon}
            />
          )}
        </ButtonBase>
      )}
      {simulationAndPlateSelectionDialog}
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  clickable: {
    cursor: 'pointer',
  },
  closeIcon: {
    '&:hover': {
      color: Colors.RED,
    },
  },
  chooseBtn: {
    justifyContent: 'left',
  },
  loadingIndicator: {
    textAlign: 'center',
  },
  selectedItem: {
    alignItems: 'center',
    backgroundColor: Colors.GREY_20,
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    height: 'auto',
    '& span': {
      whiteSpace: 'unset',
    },
    padding: '5px 11px', // Match MUI outlined button padding.
    textAlign: 'left',
    width: '100%',
  },
  typography: { marginLeft: theme.spacing(2), marginRight: 'auto' },
}));
