import React, { useCallback } from 'react';

import SettingsPanelContainer from 'client/app/apps/workflow-builder/panels/workflow-settings/SettingsPanelContainer';
import ParameterEditor from 'client/app/components/Parameters/ParameterEditor';
import { sanitiseParameterValue } from 'common/elementConfiguration/parameterUtils';
import { Parameter, ParameterValue } from 'common/types/bundle';

type Props = {
  parameter: Parameter;
  value: ParameterValue;
  onChange: (parameterName: string, value: ParameterValue) => void;
  isDisabled?: boolean;
  helpContent: string;
  additionalHelpLinkUrl?: string;
};

export default React.memo(function DeviceSettingOption(props: Props) {
  const { parameter, onChange, helpContent, isDisabled, value, additionalHelpLinkUrl } =
    props;
  const sanitisedOnChange = useCallback(
    (value: ParameterValue) => {
      onChange(parameter.name, sanitiseParameterValue(value));
    },
    [onChange, parameter.name],
  );
  const editorProps = parameter.configuration?.editor.additionalProps ?? undefined;
  return (
    <SettingsPanelContainer
      title={parameter.configuration?.displayName ?? 'Setting Option'}
      helpContent={helpContent}
      additionalHelpLinkUrl={additionalHelpLinkUrl}
    >
      <ParameterEditor
        anthaType={parameter.type}
        parameter={parameter}
        editorProps={editorProps}
        value={value}
        onChange={sanitisedOnChange}
        isDisabled={isDisabled}
      />
    </SettingsPanelContainer>
  );
});
