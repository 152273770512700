import Big from 'big.js';

import { validateAndConvertToDecimal } from 'client/app/apps/standalone-tools/utils';
import { assertCompatible, isCompatible, UnitLibrary } from 'common/lib/units';
import getMeasurementFromString from 'common/ui/components/ParameterEditors/unitRegistry';

export function calculateVolumes(
  stockConcentration: string,
  desiredVolume: string,
  targetConcentration: string,
): {
  diluentVolumeMeasurement: string;
  stockVolumeMeasurement: string;
  errorMessage?: string;
} {
  const parsedTargetConcentration = getMeasurementFromString(targetConcentration);
  const parsedStockConcentration = getMeasurementFromString(stockConcentration);
  const parsedDesiredVolume = getMeasurementFromString(desiredVolume);

  // Confirm if stock and target concentrations are using compatible units
  if (!isCompatible(parsedStockConcentration.unit, parsedTargetConcentration.unit)) {
    return {
      diluentVolumeMeasurement: '',
      stockVolumeMeasurement: '',
      errorMessage:
        'Stock and target units must both be consistently molar or mass concentration.',
    };
  }

  // Define formula variables and convert to decimal values
  const initialConcentration = validateAndConvertToDecimal(
    parsedStockConcentration.value,
  );
  const targetVolume = validateAndConvertToDecimal(parsedDesiredVolume.value);
  const desiredConcentration = validateAndConvertToDecimal(
    parsedTargetConcentration.value,
  );

  // Validate input values - return a string error message if validation fails
  if (
    initialConcentration?.lte(0) ||
    targetVolume?.lte(0) ||
    desiredConcentration?.lte(0) ||
    desiredConcentration === null ||
    initialConcentration === null ||
    targetVolume === null
  ) {
    return {
      diluentVolumeMeasurement: '',
      stockVolumeMeasurement: '',
      errorMessage: 'Please enter valid numerical values for all fields.',
    };
  } else if (
    bigGreaterThan(
      desiredConcentration,
      initialConcentration,
      parsedTargetConcentration.unit,
      parsedStockConcentration.unit,
    )
  ) {
    return {
      diluentVolumeMeasurement: '',
      stockVolumeMeasurement: '',
      errorMessage:
        'Please ensure the target concentration is lower than the stock concentration.',
    };
  }

  // Compare concentration units and ascertain the volume of stock required
  const stockVolume = targetVolume?.mul(
    bigDivideValues(
      desiredConcentration,
      initialConcentration,
      parsedTargetConcentration.unit,
      parsedStockConcentration.unit,
    ),
  );

  // Subsequently derive the volume of diluent needed to meet the target concentration
  const diluentVolume = targetVolume.minus(stockVolume);
  // Parse as string with the original units as suffix
  //
  // NB - big.js becomes problematic when the maximum number of decimal places
  // for a decimalised value is achieved.
  // For example:
  // x = new Big(1);
  // y = new Big(3);
  // x.div(y) = 0.33333333333333333333
  // However:
  // y.mul(x) !== 1
  // Instead because of the decimalisation to 20dp it is 0.999...(to 20dp)
  //
  // We want to do the following:
  // * Handle recursive values produced by division
  // * Ensure values are legible (remove excessive trailing zeros)
  // * Ensure that with extremely small values the sum of stock and diluent volume
  // is not greater than the desired volume.
  //
  // Solution:
  // * Remove trailing by rounding and parsing to string.
  // * Handle recursive values round(N,M) where N > default dp for big.js and M
  // indicates half-even rounding
  // * When target conc is extremely small relative to stock conc, diluent volume
  // converges to the desired volume - if this happens, throw error.

  // Check if desired and diluent volumes have converged.
  if (parsedDesiredVolume.value === diluentVolume.toNumber()) {
    return {
      diluentVolumeMeasurement: '',
      stockVolumeMeasurement: '',
      errorMessage:
        'Stock and target concentration difference is too large. Please adjust dilution magnitude.',
    };
  }

  // Parse stock and diluent volumes
  const diluentVolumeMeasurement =
    diluentVolume.round(19, 2).toString() + parsedDesiredVolume.unit;
  const stockVolumeMeasurement =
    stockVolume.round(19, 2).toString() + parsedDesiredVolume.unit;
  return { diluentVolumeMeasurement, stockVolumeMeasurement };
}

// To ensure that we don't encounter floating point errors during mathematical
// operations, we are using 'big.js'.
// The helper functions exist to convert numeric values to 'Big' values, perform
// unit comparisons, and execute mathematical operations.
// They make use of/are inspired by the UnitLibrary class and functions found in
// '.../common/lib/units.ts'.

const _unitLibrary = new UnitLibrary();

function bigGreaterThan(a: Big, b: Big, aUnit: string, bUnit: string): boolean {
  assertCompatible(aUnit, bUnit);
  if (bigIsNone(a, aUnit) || bigIsNone(b, bUnit)) {
    return false;
  }
  return bigComparable(a, aUnit).gt(bigComparable(b, bUnit));
}

function bigDivideValues(a: Big, b: Big, aUnit: string, bUnit: string): Big {
  assertCompatible(aUnit, bUnit);
  if (bigIsNone(a, aUnit)) {
    return new Big(0.0);
  }

  if (bigIsNone(b, bUnit)) {
    return new Big(b).mul(Infinity);
  }

  return bigComparable(a, aUnit).div(bigComparable(b, bUnit));
}

function bigIsNone(a: Big, aUnit: string): boolean {
  return a.eq(0.0) && aUnit === '';
}

function bigComparable(value: Big, unit: string): Big {
  const libraryUnit = _unitLibrary.units.get(unit);
  if (libraryUnit) {
    const bigCoefficient = new Big(libraryUnit.coefficient);
    return value.mul(bigCoefficient);
  }
  throw `unknown unit ${unit}`;
}
