import React from 'react';

import { styled } from '@mui/material/styles';

const ActiveDot = () => <Element>&#x2022;</Element>;

const Element = styled('span')`
  color: ${props => props.theme.palette.primary.dark};
  font-size: 22px;
`;

export default ActiveDot;
