import React, { useContext, useRef } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DatasetIcon from '@mui/icons-material/InsertChartOutlined';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import cx from 'classnames';

import { ExperimentDetailContext } from 'client/app/apps/experiments/ExperimentDetailScreen';
import { BLOCK_GUTTER_WIDTH } from 'client/app/apps/experiments/ExperimentDetailScreen';
import {
  CreateBlockProps,
  useCreateExperimentBlock,
} from 'client/app/apps/experiments/useCreateExperimentBlock';
import {
  ExperimentBlockFragmentFragment as ExperimentBlock,
  ExperimentBlockType as BlockType,
} from 'client/app/gql';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import Fab from 'common/ui/components/Fab';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { usePopover } from 'common/ui/hooks/usePopover';
import { ExecutionIcon } from 'common/ui/icons/Execution';
import { SimulationIcon } from 'common/ui/icons/SimulationIcon';
import { TextBlockIcon } from 'common/ui/icons/TextBlockIcon';
import { TitleBlockIcon } from 'common/ui/icons/TitleBlockIcon';
import { WorkflowIcon } from 'common/ui/icons/Workflow';

/**
 * Height of the area between blocks that can be hovered over to reveal the add button
 */
const HIT_BOX_HEIGHT = 20;

type NewExperimentBlockMenuProps = {
  experimentId: ExperimentId;
  insertBeforeId?: ExperimentModuleId;
  /**
   * Add space above & below the divider. Used when presenting menu outside modal.
   */
  hasDividerMargin?: boolean;
  experimentBlocks?: readonly ExperimentBlock[];
};

export function NewExperimentBlockMenu(props: NewExperimentBlockMenuProps) {
  const classes = useStyles();
  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();

  const { isEditing } = useContext(ExperimentDetailContext);

  const fabButton = useRef<HTMLButtonElement>(null);

  if (!isEditing) {
    return null;
  }

  const closeMenu = () => {
    onHidePopover();
    // When the menu closes, MUI restores focus to the button. The button shows when it is
    // focused. To hide the button we need to remove focus.
    //
    // setTimeout is needed to defer blurring until after MUI has restored focus to the
    // button.
    //
    // MUI's `disableRestoreFocus` prop looked like a promising alternative, but this just
    // moves focus to the Menu wrapper which is still a descendant of the container and
    // therefore still causes the button to show.
    setTimeout(() => fabButton.current?.blur());
  };

  return (
    <div className={classes.container}>
      <div className={classes.hitBox}>
        <div className={classes.line} />
        <Fab
          onClick={onShowPopover}
          icon={<AddIcon />}
          className={classes.addButton}
          color="primary"
          size="small"
          ref={fabButton}
        />
        <div className={classes.line} />
      </div>
      <Menu
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={popoverAnchorElement}
        keepMounted
        PaperProps={{ square: false }}
        open={isPopoverOpen}
        onClose={closeMenu}
        // For focus-within to work (see styles below) the menu must a descendant of the
        // container div.
        disablePortal
      >
        <NewExperimentBlockMenuItems {...props} onClick={closeMenu} />
      </Menu>
    </div>
  );
}

type NewExperimentBlockMenuItemsProps = NewExperimentBlockMenuProps & {
  onClick?: () => void;
  experimentBlocks?: readonly ExperimentBlock[];
};

// When used in MUI Menu the menu ref needs to be forwarded to all menu items.
export const NewExperimentBlockMenuItems = React.forwardRef<
  HTMLLIElement,
  NewExperimentBlockMenuItemsProps
>(function NewExperimentBlockMenuItems(
  {
    experimentId,
    insertBeforeId,
    hasDividerMargin,
    onClick,
    experimentBlocks,
  }: NewExperimentBlockMenuItemsProps,
  ref,
) {
  const classes = useStyles();

  const { createBlock, simulationsDialog, executionsDialog, workflowsDialog } =
    useCreateExperimentBlock(experimentId, insertBeforeId);

  const handlePick = (props: CreateBlockProps) => {
    onClick?.();
    void createBlock(props);
  };

  const workflowIdsInReport: WorkflowId[] = [];
  const simulationIdsInReport: SimulationId[] = [];
  const executionIdsInReport: ExecutionId[] = [];

  experimentBlocks?.forEach(block => {
    switch (block.__typename) {
      case 'WorkflowBlock':
        workflowIdsInReport.push(block.workflow.id);
        break;
      case 'SimulationBlock':
        if (block.simulation.execution) {
          executionIdsInReport.push(block.simulation.execution.id);
        } else {
          simulationIdsInReport.push(block.simulation.id);
        }
    }
  });

  const bioprocessEnabled = useFeatureToggle('BIOPROCESS_UI');

  return (
    <>
      <div className={classes.menuItems}>
        <MenuItemWithIcon
          text="Heading"
          icon={<TitleBlockIcon />}
          ref={ref}
          onClick={() => handlePick({ type: BlockType.TITLE })}
        />
        <MenuItemWithIcon
          text="Text"
          icon={<TextBlockIcon />}
          ref={ref}
          onClick={() => handlePick({ type: BlockType.TEXT })}
        />
        <Divider
          orientation="horizontal"
          className={cx({ [classes.dividerWithMargin]: hasDividerMargin })}
        />
        <MenuItemWithIcon
          text="Execution"
          icon={<ExecutionIcon />}
          ref={ref}
          onClick={() =>
            handlePick({ type: 'execution', disabledExecutionIds: executionIdsInReport })
          }
        />
        <MenuItemWithIcon
          text="Simulation"
          icon={<SimulationIcon />}
          ref={ref}
          onClick={() =>
            handlePick({
              type: BlockType.SIMULATION,
              disabledSimulationIds: simulationIdsInReport,
            })
          }
        />
        <MenuItemWithIcon
          text="Workflow"
          icon={<WorkflowIcon />}
          ref={ref}
          onClick={() =>
            handlePick({
              type: BlockType.WORKFLOW,
              disabledWorkflowIds: workflowIdsInReport,
            })
          }
        />
        {bioprocessEnabled && (
          <MenuItemWithIcon
            text="Bioprocessing"
            icon={<DatasetIcon />}
            ref={ref}
            onClick={() => handlePick({ type: BlockType.DATASET_DERIVATION })}
          />
        )}
      </div>
      {simulationsDialog}
      {executionsDialog}
      {workflowsDialog}
    </>
  );
});

const useStyles = makeStylesHook(theme => ({
  container: {
    height: 0,
    opacity: 0,
    margin: theme.spacing(0, `${BLOCK_GUTTER_WIDTH}px`),
    // Show the add button on hover or when a descendant (button or menu) is focused. This
    // prevents the button from disappearing when it's pressed and the MUI menu takes
    // focus. It also means the button will show when accessed via the tab key.
    '&:hover, &:focus-within': {
      opacity: 1,
    },
  },
  hitBox: {
    transform: `translateY(-${HIT_BOX_HEIGHT / 2}px)`,
    height: `${HIT_BOX_HEIGHT}px`,
    // Appear in front of blocks this button is in between
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  // position a line each side of the button that stretches to container edges
  line: {
    flexGrow: 1,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  addButton: {
    position: 'static',
  },
  menuItems: {
    color: theme.palette.text.primary,
  },
  dividerWithMargin: {
    margin: theme.spacing(2, 0),
  },
}));
