import React from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { DeckLayoutConfig } from 'client/app/components/DeviceLibrary/DeviceConfigurationValidation/types';
import Feedback from 'common/ui/components/Feedback';

const MAX_TEXT_CONTENT_WIDTH = 800;

type Props = {
  deckLayoutConfigList: DeckLayoutConfig[];
};

export default function DeckLayoutFeedback({ deckLayoutConfigList }: Props) {
  const invalidLayoutsCopy = getInvalidLayoutsCopy(deckLayoutConfigList);
  const layoutsFoundCopy = getLayoutsFoundCopy(deckLayoutConfigList);

  return (
    <Stack gap={6}>
      <TextWrapper>
        <Typography variant="h3" fontWeight={500}>
          Validate layouts
        </Typography>
        <Typography variant="h6" fontWeight={400}>
          Review the device layouts available in this configuration and select the ones
          you want to upload. You can skip this step by selecting no layouts if you don’t
          want to upload anything.
        </Typography>
      </TextWrapper>
      {deckLayoutConfigList.length > 0 ? (
        <Feedback
          variant="success"
          width="100%"
          maxWidth={MAX_TEXT_CONTENT_WIDTH}
          content={
            <Typography variant="body1" lineHeight={2}>
              {layoutsFoundCopy}
            </Typography>
          }
        />
      ) : (
        <Feedback
          variant="info-grey"
          width="100%"
          maxWidth={MAX_TEXT_CONTENT_WIDTH}
          content={
            <Typography variant="subtitle2" lineHeight={2}>
              No layouts found
            </Typography>
          }
        />
      )}
      {invalidLayoutsCopy && (
        <Feedback
          variant="error"
          width="100%"
          maxWidth={MAX_TEXT_CONTENT_WIDTH}
          header="Layout error"
          content={
            <Typography variant="body1" lineHeight={2}>
              {invalidLayoutsCopy}
            </Typography>
          }
        />
      )}
    </Stack>
  );
}

const MAX_ERRORED_LAYOUTS = 3;

export function getInvalidLayoutsCopy(
  deckLayoutConfigList: DeckLayoutConfig[],
  maxToShow = MAX_ERRORED_LAYOUTS,
) {
  const invalidLayouts = deckLayoutConfigList.filter(layout => !layout.valid);

  if (invalidLayouts.length === 0) {
    return null;
  } else if (invalidLayouts.length === 1) {
    return `Deck layout name: "${invalidLayouts[0].label}" contains errors.`;
  } else if (invalidLayouts.length <= maxToShow) {
    return `Deck layout names: ${invalidLayouts
      .map(layout => `"${layout.label}"`)
      .join(', ')} contain errors.`;
  } else {
    return `Deck layout names: ${invalidLayouts
      .slice(0, maxToShow)
      .map(layout => `"${layout.label}"`)
      .join(', ')} (+${invalidLayouts.length - maxToShow} more) contain errors.`;
  }
}

function getLayoutsFoundCopy(deckLayoutConfigList: DeckLayoutConfig[]) {
  return deckLayoutConfigList.length > 1
    ? `${deckLayoutConfigList.length} deck layouts have been found.`
    : '1 deck layout has been found.';
}

const TextWrapper = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  maxWidth: MAX_TEXT_CONTENT_WIDTH,
}));
