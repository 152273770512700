import React, { useMemo, useState } from 'react';

import { useApolloClient, useQuery } from '@apollo/client';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDivider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { deviceFromGraphQL } from 'client/app/api/deviceFromGraphql';
import {
  QUERY_DEVICE_PLATE_MATCHES,
  QUERY_PARSED_DECK_LAYOUT_CONFIG_LIST,
} from 'client/app/api/gql/queries';
import DeckLayoutTab, {
  ParsedDeckLayoutConfigListQueryType,
} from 'client/app/components/DeviceLibrary/DeviceConfigurationValidation/DeckLayout/DeckLayoutTab';
import DeckLayoutsForUploadContextProvider, {
  useDeckLayoutsForUpload,
} from 'client/app/components/DeviceLibrary/DeviceConfigurationValidation/DeckLayout/SelectionContext';
import LabwareTab, {
  PlateMatchesQueryType,
} from 'client/app/components/DeviceLibrary/DeviceConfigurationValidation/LabwareTab';
import LiquidClassesTab from 'client/app/components/DeviceLibrary/DeviceConfigurationValidation/LiquidClassesTab';
import { callUpdateDeviceConfiguration } from 'client/app/components/DeviceLibrary/mutations';
import { ParsedConfigFile } from 'client/app/components/DeviceLibrary/parseConfigurationFile';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import Stepper, { Step } from 'client/app/components/Stepper';
import {
  DeviceCommonFragment as DeviceCommon,
  ParsedDeckLayoutConfigListQuery,
  ParsedDeckLayoutConfigListQueryVariables,
} from 'client/app/gql';
import { isDefined } from 'common/lib/data';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import CommonIconButton from 'common/ui/components/IconButton';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import StyledTooltip from 'common/ui/components/Tooltip';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import useDialog, { DialogProps as CommonDialogProps } from 'common/ui/hooks/useDialog';

type DeviceConfigurationStep = 'deck-layout' | 'labware' | 'liquid-classes';

const OK_CHOICE = Symbol('OK_CHOICE');

type DialogProps = {
  device: DeviceCommon;
  parsedConfigFile: ParsedConfigFile;
} & CommonDialogProps<typeof CANCEL_CHOICE | typeof OK_CHOICE>;

function Dialog({ device, parsedConfigFile, onClose, isOpen }: DialogProps) {
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);

  const configurationName = useConfigurationName(parsedConfigFile.runConfigs);

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const steps = useMemo<Step<DeviceConfigurationStep>[]>(
    () => [
      {
        value: 'deck-layout',
        label: 'Deck Layout',
      },
      {
        value: 'labware',
        label: 'Labware',
      },
      {
        value: 'liquid-classes',
        label: 'Liquid classes',
      },
    ],
    [],
  );

  const { selectForUpload } = useDeckLayoutsForUpload();
  const parsedDeckLayoutConfigListQueryResult = useQuery<
    ParsedDeckLayoutConfigListQuery,
    ParsedDeckLayoutConfigListQueryVariables
  >(QUERY_PARSED_DECK_LAYOUT_CONFIG_LIST, {
    variables: {
      deviceId: deviceFromGraphQL(device).id,
      deviceConfig: parsedConfigFile.instanceConfig,
      runConfigs: parsedConfigFile.runConfigs,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ parsedDeckLayoutConfigList }) => {
      parsedDeckLayoutConfigList.forEach(layout => {
        if (layout.valid) {
          selectForUpload(layout.label, true);
        }
      });
    },
  });
  const plateMatchingQueryResult = useQuery(QUERY_DEVICE_PLATE_MATCHES, {
    variables: {
      deviceConfig: parsedConfigFile.instanceConfig,
      anthaHubGUID: device.anthaHubGUID!,
    },
    fetchPolicy: 'network-only',
  });

  const handleCancel = async () => {
    if (steps[currentStepIndex].value === 'liquid-classes') {
      // Confirmation dialogue not required on liquid classes tab as the device configuration has already been uploaded
      onClose(CANCEL_CHOICE);
      setCurrentStepIndex(0);
      return;
    }
    const cancel = await openConfirmationDialog({
      action: 'leave',
      isActionDestructive: true,
      object: 'process to re-upload configuration',
      additionalMessage:
        'By closing this panel, you will not add the configuration to the selected device.',
      cancelButtonLabel: 'Cancel',
      confirmButtonLabel: 'Confirm to leave',
    });
    if (cancel) {
      setCurrentStepIndex(0);
      onClose(CANCEL_CHOICE);
    }
  };

  const { loading: addConfigLoading, addConfig } = useAddConfig(
    device.id,
    parsedConfigFile,
    () => setCurrentStepIndex(2),
  );

  const backButton = (
    <BackButton
      variant="text"
      size="small"
      onClick={() => setCurrentStepIndex(currIdx => currIdx - 1)}
      // Disable the back button on the liquid classes tab
      disabled={steps[currentStepIndex].value === 'liquid-classes' || addConfigLoading}
    >
      Back
    </BackButton>
  );

  return (
    <>
      <DialogContainer open={isOpen} onClose={handleCancel} fullWidth maxWidth="lg">
        <DialogTitle>
          <VerifiedUserOutlinedIcon />
          <Typography whiteSpace="nowrap" variant="h5">
            Validating configuration
          </Typography>
          <Divider orientation="vertical" />
          <SecondaryColorTypography variant="h5">Device:</SecondaryColorTypography>
          <TypographyWithTooltip variant="h5">{`${device.name}`}</TypographyWithTooltip>
          <Divider orientation="vertical" />
          <SecondaryColorTypography variant="h5">Configuration:</SecondaryColorTypography>
          <TypographyWithTooltip variant="h5">{configurationName}</TypographyWithTooltip>
          <IconButton icon={<CloseOutlined />} onClick={handleCancel} size="small" />
        </DialogTitle>
        <DialogContent dividers>
          <Stepper<DeviceConfigurationStep>
            activeStepValue={steps[currentStepIndex].value}
            steps={steps}
            disableStepSelection
          />
          <CurrentStepContent
            step={steps[currentStepIndex].value}
            device={device}
            plateMatchingQueryResult={plateMatchingQueryResult}
            parsedDeckLayoutConfigListQueryResult={parsedDeckLayoutConfigListQueryResult}
          />
        </DialogContent>
        <DialogActions>
          {currentStepIndex > 0 &&
            (steps[currentStepIndex].value === 'liquid-classes' ? (
              <StyledTooltip
                title="To modify your device configuration, start the device validation journey again from scratch"
                placement="top"
              >
                {/* Wrapping span required for tooltip to show on disabled button */}
                <span>{backButton}</span>
              </StyledTooltip>
            ) : (
              backButton
            ))}
          {steps[currentStepIndex].value === 'liquid-classes' ? (
            <Button variant="contained" size="small" onClick={() => onClose(OK_CHOICE)}>
              Done
            </Button>
          ) : steps[currentStepIndex].value === 'labware' ? (
            <Button
              variant="contained"
              size="small"
              onClick={addConfig}
              disabled={addConfigLoading || plateMatchingQueryResult.loading}
              startIcon={addConfigLoading && <CircularProgress size={10} />}
            >
              Add config to this device
            </Button>
          ) : (
            <Button
              variant="outlined"
              size="small"
              onClick={() => setCurrentStepIndex(currIdx => currIdx + 1)}
              disabled={parsedDeckLayoutConfigListQueryResult.loading}
            >
              Next
            </Button>
          )}
        </DialogActions>
      </DialogContainer>
      {confirmationDialog}
    </>
  );
}

function CurrentStepContent({
  step,
  device,
  plateMatchingQueryResult,
  parsedDeckLayoutConfigListQueryResult,
}: {
  step: DeviceConfigurationStep;
  device: DeviceCommon;
  plateMatchingQueryResult: PlateMatchesQueryType;
  parsedDeckLayoutConfigListQueryResult: ParsedDeckLayoutConfigListQueryType;
}) {
  switch (step) {
    case 'labware':
      return <LabwareTab device={device} queryResult={plateMatchingQueryResult} />;
    case 'liquid-classes':
      return <LiquidClassesTab device={device} />;
    case 'deck-layout':
    default:
      return <DeckLayoutTab queryResult={parsedDeckLayoutConfigListQueryResult} />;
  }
}

export default function DialogWithContext(props: DialogProps) {
  if (!props.isOpen) {
    // Unmount the dialogue when closing to ensure no stale data is kept
    return null;
  }
  return (
    <DeckLayoutsForUploadContextProvider runConfigs={props.parsedConfigFile.runConfigs}>
      <Dialog {...props} />
    </DeckLayoutsForUploadContextProvider>
  );
}

const useConfigurationName = (runConfigs: ParsedConfigFile['runConfigs']) => {
  return useMemo(() => {
    const configNames = runConfigs
      .map(runConfig => runConfig.ConfigLabel)
      .filter(isDefined);
    if (configNames.length === 0) {
      // We do not expect to reach this case if config files generated from our GetConfigTools are being used
      return 'Default configuration';
    }
    return configNames.join(';');
  }, [runConfigs]);
};

function useAddConfig(
  deviceId: DeviceId,
  parsedConfigFile: ParsedConfigFile,
  onSuccess: () => void,
) {
  const [loading, setLoading] = useState(false);

  const { deckLayoutsForUpload } = useDeckLayoutsForUpload();
  const snackbar = useSnackbarManager();

  const apollo = useApolloClient();
  const addConfig = async () => {
    const allRunConfigs = parsedConfigFile.runConfigs;
    const runConfigsForUpload = allRunConfigs.filter(config =>
      deckLayoutsForUpload.get(config.ConfigLabel ?? ''),
    );

    setLoading(true);

    try {
      await callUpdateDeviceConfiguration(apollo, {
        deviceId,
        configuration: parsedConfigFile.instanceConfig,
        runConfigurations: runConfigsForUpload,
      });

      onSuccess();
    } catch (error) {
      snackbar.showError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    addConfig,
  };
}

const DialogContainer = styled(MuiDialog)(() => ({
  '& .MuiDialog-paper': { height: '100%' },
}));

const DialogTitle = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  gap: spacing(3),
  padding: spacing(3, 5),
}));

const DialogContent = styled(MuiDialogContent)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: spacing(3, 8),
}));

const Divider = styled(MuiDivider)(({ theme: { palette } }) => ({
  borderRightColor: palette.text.primary,
  marginTop: '10px',
  marginBottom: '10px',
  height: '12px',
}));

const IconButton = styled(CommonIconButton)(() => ({
  marginLeft: 'auto',
}));

const DialogActions = styled(MuiDialogActions)(({ theme: { spacing } }) => ({
  padding: spacing(5),
}));

const SecondaryColorTypography = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.text.secondary,
}));

const BackButton = styled(Button)(({ theme: { palette } }) => ({
  color: palette.text.primary,
}));
