import { useMemo } from 'react';

import { getLiquidNameForColor } from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import { PlateContentsMatrix } from 'common/types/mix';
import { PlateLayer } from 'common/types/plateAssignments';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';

function contentsofLayer(layer: PlateLayer | undefined, liquidColors: LiquidColors) {
  if (!layer) {
    return undefined;
  }

  const wellSets = new Map(layer.wellSets.map(ws => [ws.id, ws]));

  const result: PlateContentsMatrix = {};

  layer.liquids.forEach(liquid => {
    const wellSet = wellSets.get(liquid.wellSetID);

    if (wellSet) {
      wellSet.wells.forEach(({ x, y }) => {
        if (!result[x]) {
          result[x] = {};
        }

        const volume = liquid.existing
          ? liquid.existing.volume
          : 'volume' in liquid.target
          ? liquid.target.volume
          : undefined;

        const concentration = liquid.existing
          ? liquid.existing.concentration
          : 'concentration' in liquid.target
          ? liquid.target.concentration
          : undefined;

        result[x][y] = {
          kind: 'liquid_layer',
          id: `${wellSet.id}-${x}-${y}`,
          total_volume: volume,
          overallConcentration: concentration,
          color: liquidColors.getColorFromLiquidString(getLiquidNameForColor(liquid)),
          name: liquid.liquidName ?? liquid.liquidGroup,
          isGroup: liquid.liquidName === undefined,
        };
      });
    }
  });

  return result;
}

export function useContentsOfLayer(
  plateLayers: PlateLayer[],
  activeLayerIndex: number,
  liquidColors: LiquidColors,
) {
  return useMemo<
    [PlateContentsMatrix | undefined, PlateContentsMatrix | undefined]
  >(() => {
    if (activeLayerIndex === -1) {
      return [undefined, undefined];
    }

    const activeLayer = plateLayers[activeLayerIndex];
    const layerBelow = plateLayers[activeLayerIndex + 1];

    return [
      contentsofLayer(activeLayer, liquidColors),
      contentsofLayer(layerBelow, liquidColors),
    ];
  }, [activeLayerIndex, liquidColors, plateLayers]);
}
