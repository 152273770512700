import React, { useCallback, useMemo, useState } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';

import { WellGroup } from 'client/app/components/Parameters/PlateContents/BasePlateContentsEditorWellGroupList';
import { WellParametersProps } from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import { formatWellRange } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import IconButton from 'common/ui/components/IconButton';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props<T> = {
  wellGroup: WellGroup<T>;
  isEditing?: boolean;
  isReadOnly?: boolean;
  isDeletable?: boolean;
  /**
   * Callback which generates the component on the right hand side of the
   * dialog, which should contain fields for modifying currently selected wells.
   */
  wellParameters: (params: WellParametersProps<T>) => JSX.Element;
  /**
   * Fires when the user clicks on the list item and the well parameters are
   * shown.
   */
  onEdit?: (groupId: string) => void;
  onDelete?: (contentsByWell: Set<string>) => void;
  onSave: (contentsByWell: Map<string, T>) => void;
  onCancel: () => void;
};

/**
 * Show information about a group of wells. When the item is clicked, the
 * contents of each well in the group can be edited.
 */
export default function BasePlateContentsEditorWellGroupListItem<T>({
  wellGroup,
  wellParameters,
  isEditing,
  isDeletable,
  isReadOnly,
  onEdit,
  onDelete,
  onSave,
  onCancel,
}: Props<T>) {
  const classes = useStyles();

  const { id, contentsByWell, title, subtitle, color, isEmpty } = wellGroup;

  // Work on a copy of contentsByWell. This will be changed each time the user
  // changes the parameters, and committed when the user presses save.
  const [stagedContentsByWell, setStagedContentsByWell] = useState(contentsByWell);
  // Prevent saving if user has entered invalid parameters
  const [isInvalid, setIsInvalid] = useState(false);

  const handleItemClick = useCallback(() => onEdit?.(id), [onEdit, id]);

  const selectionLabel = useMemo(
    () => formatWellRange([...stagedContentsByWell.keys()]),
    [stagedContentsByWell],
  );

  const handleDelete = useCallback(
    () => onDelete?.(new Set(stagedContentsByWell.keys())),
    [stagedContentsByWell, onDelete],
  );

  const handleSave = useCallback(
    () => onSave(stagedContentsByWell),
    [stagedContentsByWell, onSave],
  );

  const handleCancel = useCallback(() => {
    setStagedContentsByWell(contentsByWell);
    onCancel();
  }, [contentsByWell, onCancel]);

  // Fires whenever the user changes a field within the well parameters
  const handleParameterChange = useCallback(
    (newContentsByWell: Map<string, T>, valid: boolean) => {
      // Do a deep comparison to check if the parameter values are different.
      // Without this, we can end up in an infinite loop - onParameterChange ->
      // handleParameterChange -> setStagedContentsByWell ->
      // onParameterChange...
      const changed = [...newContentsByWell.keys()].some(
        wellLocation =>
          !isEqual(
            newContentsByWell.get(wellLocation),
            stagedContentsByWell.get(wellLocation),
          ),
      );
      if (changed) {
        setStagedContentsByWell(newContentsByWell);
      }
      setIsInvalid(!valid);
    },
    [stagedContentsByWell],
  );

  const wellParametersMemo = useMemo(
    () =>
      wellParameters({
        contentsByWell: stagedContentsByWell,
        isDisabled: isReadOnly,
        onChange: handleParameterChange,
      }),
    [handleParameterChange, isReadOnly, stagedContentsByWell, wellParameters],
  );

  const hasChanged = stagedContentsByWell !== contentsByWell;

  return (
    <>
      <ListItem onClick={handleItemClick} button>
        <ListItemIcon classes={{ root: classes.listItemIcon }}>
          <div
            className={cx(classes.well, {
              [classes.wellEmpty]: isEmpty,
            })}
            style={{ backgroundColor: color }}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <span className={classes.wellSelection}>[{selectionLabel}]</span> - {title}
            </>
          }
          secondary={subtitle}
        />
        {isDeletable && (
          <ListItemSecondaryAction>
            <IconButton onClick={handleDelete} size="small" icon={<DeleteIcon />} />
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {isEditing && (
        <Box pt={4}>
          <Paper variant="outlined" square={false}>
            <Box height={300} p={4} overflow="auto">
              {wellParametersMemo}
            </Box>
            <Box p={4}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Button onClick={handleCancel} variant="tertiary">
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleSave}
                    variant="primary"
                    disabled={isReadOnly || isInvalid || !hasChanged}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  );
}

const useStyles = makeStylesHook({
  well: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
  },
  wellEmpty: {
    border: `1px solid ${Colors.GREY_30}`,
  },
  listItemIcon: {
    // MUI's ListItemIcon makes 52px available for icons. We don't have that much
    // space to spare in this context.
    minWidth: '32px',
  },
  wellSelection: {
    fontWeight: 500,
  },
});
