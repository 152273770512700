import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const GraphDataIcon = React.memo<SvgIconProps>(function GraphDataIcon(props) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M 18.99996,2.9999912 H 5.000004 C 3.9,2.9999912 3,3.8999852 3,4.9999892 V 18.999969 c 0,1.10004 0.9,2.00004 2.000004,2.00004 H 18.99996 c 1.10004,0 2.00004,-0.9 2.00004,-2.00004 V 4.9999892 c 0,-1.100004 -0.9,-1.999998 -2.00004,-1.999998 z m 0,15.9999778 H 5.000004 V 4.9999892 H 18.99996 Z M 6.999996,9.9999927 H 9 V 17.000049 H 6.999996 Z m 4.000008,-2.9999995 h 1.999992 V 17.000049 H 11.000004 Z M 15,12.999993 h 2.00004 v 4.000056 H 15 Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
