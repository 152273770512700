import React, { PropsWithChildren, useMemo } from 'react';

import {
  evaluate,
  SpreadsheetState,
  SpreadsheetStateContextProps,
} from 'common/rules/evaluateSpreadsheetRules';
import { SpreadsheetConfiguration } from 'common/types/spreadsheet';

type SpreadsheetStateContext = SpreadsheetState;

const DEFAULT_CONTEXT: SpreadsheetStateContext = {
  sheets: {},
};

/**
 * Evaluation engine for spreadsheet rules specified in the spreadsheet configuration.
 * Given the spreadsheet cell values and configuration, it calculates state for each
 * column of each sheet.
 * */
export const SpreadsheetStateContext =
  React.createContext<SpreadsheetStateContext>(DEFAULT_CONTEXT);

type Props = PropsWithChildren<SpreadsheetStateContextProps>;

function calculateSpreadsheetState(
  configuration: SpreadsheetConfiguration,
): SpreadsheetState {
  const stateMap: SpreadsheetState = { sheets: {} };

  if (!configuration?.rules) {
    return stateMap;
  }

  return evaluate(configuration);
}

export default function SpreadsheetStateContextProvider(props: Props) {
  const spreadsheetState = useMemo(
    () => calculateSpreadsheetState(props.configuration),
    [props.configuration],
  );

  return (
    <SpreadsheetStateContext.Provider value={spreadsheetState}>
      {props.children}
    </SpreadsheetStateContext.Provider>
  );
}
