import assert from 'assert';

import { MixPreview } from 'common/types/mixPreview';
import {
  applyStep,
  getInitialState,
  MixState,
  restoreState,
} from 'common/ui/components/simulation-details/mix/MixState';

// Every n steps, we cache the full MixState.
// This makes it much quicker to compute the state. If we compute the state
// for step 250, and later we want to compute the state for step 270 (this
// is very common), we just need to apply 20 steps on top of the cached state,
// instead of starting at initial state and applying all 270 steps.
const CACHE_EVERY_N_STEPS = 50;

type MixStateCacheOptions = {
  /**
   * `noLabwareMovements` is used by the Plate Setup screen because there is not deck to
   * show these movements.
   */
  noLabwareMovements?: boolean;
};

// Speeds up computation of MixState at a specific step
export default class MixStateCache {
  // Sparse matrix (stageIndex -> stepIndex -> MixState)
  private readonly cache: MixState[][] = [[]];

  constructor(
    private readonly mixPreview: MixPreview,
    private options?: MixStateCacheOptions,
  ) {}

  computeState(currentStage: number = 0, appliedSteps: number = 0): MixState {
    const stageCount = this.mixPreview.stages.length;

    assert(currentStage >= 0, `Stage must be >= 0. Was ${currentStage}`);
    assert(
      currentStage < stageCount,
      `Stage out of range: ${currentStage}. Max available stage is ${stageCount - 1}`,
    );

    const steps = this.mixPreview.stages[currentStage];

    assert(
      appliedSteps >= 0,
      `Number of applied steps must be >= 0. Was ${appliedSteps}`,
    );
    assert(
      appliedSteps <= steps.length,
      `Number of applied steps out of range: ${appliedSteps}. Stage has ${steps.length} steps`,
    );

    const intermediateState = this.getIntermediateState(currentStage, appliedSteps);

    let state: MixState = intermediateState;

    for (
      let stepIndex = intermediateState.stepIndex;
      stepIndex < appliedSteps;
      stepIndex++
    ) {
      state = applyStep(
        state,
        stepIndex + 1,
        steps[stepIndex],
        this.options?.noLabwareMovements,
      );
      if (stepIndex % CACHE_EVERY_N_STEPS === 0 || stepIndex === steps.length - 1) {
        // Cache state
        // we've applied one step (step index 0) => currentStep is 1
        this.cacheState(currentStage, stepIndex + 1, state);
      }
    }
    // Extra sanity check
    assert(
      state.stepIndex === appliedSteps,
      `Step index should be ${appliedSteps}, was ${state.stepIndex}`,
    );

    return state;
  }

  private getIntermediateState(currentStage: number, appliedSteps: number) {
    const cachedState = this.findPreviousCachedState(currentStage, appliedSteps);
    return cachedState
      ? cachedState
      : currentStage === 0
      ? getInitialState(this.mixPreview.deck)
      : this.getInitialStateForStage(currentStage);
  }

  /**
   * Find cached state calculated from applying less than or equal to `appliedSteps`
   * of the stage with `stageIndex`
   */
  private findPreviousCachedState(
    stageIndex: number,
    appliedSteps: number,
  ): MixState | undefined {
    const stageCache = this.getStageCache(stageIndex);

    if (stageCache.length === 0) return undefined;

    let bestCachedStep = appliedSteps;
    while (!stageCache[bestCachedStep] && bestCachedStep > 0) {
      bestCachedStep -= 1;
    }
    const cachedState = stageCache[bestCachedStep];

    if (cachedState) {
      // Extra sanity checks
      assert(
        cachedState.stepIndex <= appliedSteps,
        `Cached state should be from a step before ${appliedSteps}, was ${cachedState.stepIndex}`,
      );
      assert(
        cachedState.stepIndex === bestCachedStep,
        `Cached step index should be ${appliedSteps}, was ${bestCachedStep}`,
      );
    }
    return cachedState;
  }

  private getInitialStateForStage(stageIndex: number): MixState {
    const prevStageIndex = stageIndex - 1;
    const allStepsOfPrevStage = this.mixPreview.stages[prevStageIndex].length;
    const prevStageCache = this.getStageCache(prevStageIndex);

    let finalStateOfPrevStage = prevStageCache[allStepsOfPrevStage];
    if (!finalStateOfPrevStage) {
      finalStateOfPrevStage = this.computeState(prevStageIndex, allStepsOfPrevStage);
    }
    return restoreState(finalStateOfPrevStage);
  }

  private cacheState(stageIndex: number, stepIndex: number, state: MixState) {
    const stageCache = this.getStageCache(stageIndex);
    stageCache[stepIndex] = state;
  }

  private getStageCache(stageIndex: number) {
    if (!this.cache[stageIndex]) {
      this.cache[stageIndex] = [];
    }
    return this.cache[stageIndex];
  }
}
