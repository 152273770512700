import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const SimulationCardsIcon = React.memo<SvgIconProps>(function SimulationCardsIcon(
  props,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 5H3v14h18V5zM3 3a2 2 0 00-2 2v14a2 2 0 002 2h18a2 2 0 002-2V5a2 2 0 00-2-2H3z"
          fill="currentColor"
        />
        <path
          d="M12 11.125h7v2h-7v-2zM5 15.067h14v2H5v-2zM19 8a1 1 0 11-2 0 1 1 0 012 0zM8.645 11.368a.551.551 0 00-.252-.482c-.164-.115-.456-.234-.873-.357a9.141 9.141 0 01-1.025-.357c-.862-.371-1.294-.882-1.294-1.532 0-.323.107-.608.32-.854.217-.249.522-.441.915-.577A3.961 3.961 0 017.762 7c.479 0 .907.075 1.285.225.381.15.677.363.887.641.21.276.316.591.316.947h-1.6c0-.238-.084-.423-.252-.554-.165-.13-.389-.196-.672-.196-.287 0-.514.056-.682.168-.164.11-.247.25-.247.421 0 .15.092.287.275.41.183.12.504.245.965.377.46.128.838.267 1.134.417.72.363 1.079.865 1.079 1.504 0 .51-.22.911-.659 1.203-.439.291-1.04.437-1.806.437-.54 0-1.029-.084-1.468-.253-.436-.17-.765-.403-.988-.697A1.675 1.675 0 015 11.027h1.61c0 .313.091.544.274.694.186.147.486.22.901.22.265 0 .474-.05.627-.148a.48.48 0 00.233-.425z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
