import React from 'react';

import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Collapse, { collapseClasses } from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import { useProtocolsParamState } from 'client/app/apps/protocols/lib/utils';
import { ElementErorrSeverity } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import IconButton from 'common/ui/components/IconButton';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

export const InputStepList = () => {
  const { steps, selectedStep, stepErrors, handleSelectStep } = useStepsContext();
  const { expandInputList, handleSetExpandInputList } = useProtocolsParamState(steps);

  const iconForStepError = (stepId: string) => {
    const errors = stepErrors.get(stepId);
    if (!errors || errors.length === 0) {
      return null;
    }
    const isAtLeastOneErrorSeverity = errors.some(err => err.severity === 'error');
    return (
      <StyledInfoOutlinedIcon
        severity={isAtLeastOneErrorSeverity ? 'error' : 'warning'}
      />
    );
  };

  return (
    <Wrapper>
      <StyledIconButton
        icon={expandInputList ? <ArrowBackSharpIcon /> : <MenuRoundedIcon />}
        size="xsmall"
        color="default"
        onClick={() => handleSetExpandInputList()}
      />
      <StyledCollapse orientation="horizontal" in={expandInputList}>
        <Typography color="textSecondary" variant="body2">
          STEPS ({steps.length})
        </Typography>
        <ContainerWithIntersectionBar
          noHeader
          dense
          content={
            <StyledList dense>
              {steps.map(step => (
                <StyledListItem key={step.id}>
                  <StyledListItemButton
                    active={step.id === selectedStep.id}
                    onClick={() => handleSelectStep(step)}
                  >
                    <ListItemText disableTypography>
                      <TypographyWithTooltip variant="body2">
                        {step.displayName}
                      </TypographyWithTooltip>
                    </ListItemText>
                    {iconForStepError(step.id)}
                  </StyledListItemButton>
                </StyledListItem>
              ))}
            </StyledList>
          }
        />
      </StyledCollapse>
    </Wrapper>
  );
};

const StyledListItem = styled(ListItem)({
  paddingLeft: 0,
  paddingRight: 0,
  minWidth: '200px',
  maxWidth: '200px',
});

const StyledList = styled(List)({
  paddingTop: 0,
});

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== 'active',
})<{ active: boolean }>(({ active, theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: theme.spacing(2),
  borderLeft: `4px solid ${active ? theme.palette.primary.main : 'transparent'}`,
  backgroundColor: active ? Colors.BLUE_5 : 'default',
  '&:hover, &:focus': {
    backgroundColor: active ? Colors.BLUE_5 : 'default',
  },
}));

const Wrapper = styled('div')(({ theme }) => ({
  gridArea: 'list',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
}));

const StyledCollapse = styled(Collapse)(({ theme }) => ({
  [`& .${collapseClasses.wrapperInner}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '& > svg': {
    color: theme.palette.text.primary,
  },
}));

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon, {
  shouldForwardProp: prop => prop !== 'severity',
})<{ severity: ElementErorrSeverity }>(({ severity, theme }) => ({
  color: severity === 'error' ? theme.palette.error.main : theme.palette.warning.dark,
  height: '20px',
  width: '20px',
}));
