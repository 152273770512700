import { useEffect, useRef } from 'react';

import { Node } from 'react-flow-renderer';

import { PositionedWorkTree } from 'client/app/apps/work-tree/useWorkTreeLayout';
import { ArrayElement, ExperimentWorkTreeQuery } from 'client/app/gql';

type HookResult = {
  waitForNode: (nodeId: string, callback: WaitForNodeCallback) => void;
  waitForNodeWithResultId: (resultId: ResultId, callback: WaitForNodeCallback) => void;
};

type Block = ArrayElement<
  NonNullable<ExperimentWorkTreeQuery['experimentWorkTree']>['blocks']
>;

type WaitForNodeCallback = (node: Node<Block>) => void;

/**
 * Sometimes we know the ID of a node we want to do something with but it hasn't been rendered
 * yet. This hook lets us schedule work for when a node has been rendered.
 */
export function useWaitForWorkTreeNode(
  workTree: PositionedWorkTree | undefined,
): HookResult {
  const createdNodeIdRef = useRef(new Map<string, WaitForNodeCallback>());
  const createdResultIdRef = useRef(new Map<ResultId, WaitForNodeCallback>());

  useEffect(() => {
    for (const [id, callback] of createdNodeIdRef.current) {
      const createdNode = createdNodeIdRef.current
        ? workTree?.nodes.find(node => node.id === id)
        : undefined;

      if (createdNode) {
        callback(createdNode);
        createdNodeIdRef.current.delete(id);
      }
    }
    for (const [id, callback] of createdResultIdRef.current) {
      const createdNode = createdResultIdRef.current
        ? workTree?.nodes.find(node => node.data.result?.id === id)
        : undefined;

      if (createdNode) {
        callback(createdNode);
        createdResultIdRef.current.delete(id);
      }
    }
  }, [workTree?.nodes]);

  return {
    waitForNode: (id, callback) => createdNodeIdRef.current.set(id, callback),
    waitForNodeWithResultId: (resultId, callback) =>
      createdResultIdRef.current.set(resultId, callback),
  };
}
