import React, { useCallback, useMemo } from 'react';

import Chip from '@mui/material/Chip';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import Colors from 'common/ui/Colors';
import { FilterChipContextProvider } from 'common/ui/components/FilterChip/FilterChipContext';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useUUID from 'common/ui/hooks/useUUID';

/**
 * Props shared by the FilterChip and various FilterChipWith[PopoverType]
 * components.
 * */
type BaseProps<T> = {
  filterValue: T;
  heading: string;
  className?: string;
  isDisabled?: boolean;
  size?: 'small' | 'medium';
};

export type FilterChipWithPopoverProps<T> = BaseProps<T> & {
  onFilter: (newValue: T) => void;
  /** What to display if no options have been selected. */
  defaultChipLabel: string;
};

type Props = BaseProps<any> & {
  chipLabel: string;
  chipLabelVariant?: 'filled' | 'outlined'; // Set this if the variant should vary depending on value type.
  popoverContent: React.ReactNode;
  isActive: boolean;
  onDelete?: () => void;
};

/**
 * This component is used for filtering results. A popover will appear to give more
 * options about how to drill down.
 */
export default React.memo(function FilterChip(props: Props) {
  const {
    heading,
    popoverContent,
    filterValue,
    chipLabel,
    chipLabelVariant,
    onDelete,
    isDisabled,
    isActive,
    size,
  } = props;

  const classes = useStyles();

  const popoverID = useUUID();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const onClickOnChip = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const setVariant = useMemo(() => {
    if (chipLabelVariant) {
      return chipLabelVariant;
    }
    return filterValue ? 'filled' : 'outlined';
  }, [chipLabelVariant, filterValue]);

  return (
    <div className={props.className}>
      <Chip
        color={isActive ? 'primary' : 'default'}
        clickable
        label={chipLabel}
        onClick={onClickOnChip}
        variant={setVariant}
        onDelete={!isDisabled && isActive ? onDelete : undefined}
        disabled={isDisabled}
        className={cx({ [classes.unselected]: !isActive })}
        size={size}
      />
      <Popover
        id={popoverID}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        classes={{ paper: classes.popover }}
      >
        <div className={classes.content}>
          <Typography variant="overline"> {heading} </Typography>
          <FilterChipContextProvider onClose={onClose}>
            {popoverContent}
          </FilterChipContextProvider>
        </div>
      </Popover>
    </div>
  );
});

const useStyles = makeStylesHook(theme => ({
  content: {
    padding: theme.spacing(6),
  },
  popover: {
    borderRadius: '8px',
  },
  unselected: {
    borderColor: Colors.GREY_80,
  },
}));
