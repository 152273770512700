/**
 * Call this in the `default` clause of a `switch` on a union/enum type.
 * Because the parameter is of type `never`, TypeScript will give an error
 * unless all cases of a union type or enum are handled by the switch so it can
 * prove the default case never happens.
 *
 * [StackOverflow](https://stackoverflow.com/questions/39419170/how-do-i-check-that-a-switch-block-is-exhaustive-in-typescript)
 *
 * @param x the value switched on
 * @return Value of type never.  If needed, assign to your variable to suppress
 * "used before assigned" warnings.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const switchFail = (x: never): never => {
  throw new Error('not all switch cases handled');
};
