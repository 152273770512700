import { graphql } from 'client/app/gql';

graphql(/* GraphQL */ `
  fragment WorkTreeBlockFragment on WorkTreeBlock {
    id
    methodId
    parentRevisionId
    applicationName
    applicationVersion
    applicationDisplayName
    status
    createdAt
    createdBy {
      displayName
    }
    dependencies
    href
    result {
      id
      type
      name
    }
    hidden
    isFavoritedByCurrentUser
    label
    data {
      blockType
      versionData {
        id
        applicationName
        applicationVersion
        status
        dependants
        href
        createdAt
        label
        result {
          id
          type
          name
        }
      }
    }
  }
`);

graphql(/* GraphQL */ `
  fragment WorkTreeFragment on ExperimentWorkTree {
    id
    blocks {
      ...WorkTreeBlockFragment
    }
  }
`);

export const QUERY_EXPERIMENT_WORK_TREE = graphql(/* GraphQL */ `
  query ExperimentWorkTree($experimentId: ExperimentId!) {
    experimentWorkTree(experimentId: $experimentId) {
      ...WorkTreeFragment
    }
  }
`);

export const QUERY_WORK_TREE_APPLICATIONS = graphql(/* GraphQL */ `
  query WorkTreeApplications {
    workTreeApplications {
      name
      version
      applicationDisplayName
      description
      path
      inputResultRequirements {
        resultType
        minItems
        maxItems
      }
    }
  }
`);

export const QUERY_WORK_TREE_APPLICATION = graphql(/* GraphQL */ `
  query WorkTreeApplication($methodId: MethodId!) {
    workTreeApplication(methodId: $methodId) {
      applicationDisplayName
    }
  }
`);

export const QUERY_WORK_TREE_STATE = graphql(/* GraphQL */ `
  query WorkTreeState($experimentID: ExperimentId!) {
    workTreeState(experimentID: $experimentID) {
      showFailed
    }
  }
`);

export const QUERY_FIND_WORK_TREES = graphql(/* GraphQL */ `
  query FindWorkTrees(
    $workflowId: WorkflowId
    $simulationId: SimulationId
    $methodId: MethodId
  ) {
    findWorkTrees(
      workflowId: $workflowId
      simulationId: $simulationId
      methodId: $methodId
    )
  }
`);
