import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PlateIcon = React.memo<SvgIconProps>(function PlateIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg fill="none" viewBox="0 0 24 24">
        <circle cx="7.5" cy="9.5" r="1.5" fill="currentColor" />
        <circle cx="7.5" cy="14.5" r="1.5" fill="currentColor" />
        <circle cx="12" cy="9.5" r="1.5" fill="currentColor" />
        <circle cx="12" cy="14.5" r="1.5" fill="currentColor" />
        <circle cx="16.5" cy="9.5" r="1.5" fill="currentColor" />
        <circle cx="16.5" cy="14.5" r="1.5" fill="currentColor" />
        <rect
          x="3"
          y="5"
          width="18"
          height="14"
          rx="1"
          stroke="currentColor"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
});
