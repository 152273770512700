import React from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import Colors from 'common/ui/Colors';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  on?: boolean;
  disabled?: boolean;
  disabledWithReason?: string;
  labelOn: string;
  labelOff: string;
};
export default function StatusTick(props: Props) {
  const { labelOn, labelOff, on, disabled, disabledWithReason } = props;
  const classes = useStyles();

  return (
    <Tooltip title={disabledWithReason ?? ''}>
      <span
        className={cx(classes.container, {
          [classes.on]: on,
          [classes.off]: !on,
          [classes.disabled]: disabled || disabledWithReason,
        })}
      >
        {on && <CheckCircleOutlineIcon />}
        <Typography className={classes.label}>{on ? labelOn : labelOff}</Typography>
      </span>
    </Tooltip>
  );
}

const useStyles = makeStylesHook({
  container: {
    display: 'flex',
    flex: 0,
    backgroundColor: Colors.WHITE,
    borderRadius: '16px',
    padding: '0px 10px 0px 0px',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > svg': { marginRight: '6px', width: '24px', height: '24px' },
  },
  label: {
    fontSize: '14px',
    lineHeight: '18px',
    padding: '5px 0px',
  },
  on: { color: Colors.GREY_60, '& > svg': { color: Colors.GREEN } },
  off: {
    color: Colors.GREY_60,
    justifyContent: 'center',
    padding: 0,
  },
  disabled: {
    color: Colors.GREY_30,
  },
});
