import React from 'react';

import { styled } from '@mui/material/styles';

import PlateLibrary from 'client/app/apps/plate-library/PlateLibrary';
import Colors from 'common/ui/Colors';

export default function PlateLibraryApp() {
  return (
    <Wrapper>
      <PlateLibrary />
    </Wrapper>
  );
}

const Wrapper = styled('div')({
  backgroundColor: Colors.GREY_5,
  flex: 1,
  overflow: 'hidden',
});
