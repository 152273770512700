import React from 'react';

import MuiLinearProgress, {
  LinearProgressProps as MuiLinearProgressProps,
} from '@mui/material/LinearProgress';

type Props = MuiLinearProgressProps;

export default function LinearProgress(props: Props) {
  return <MuiLinearProgress {...props} />;
}
