import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { VisibilityIcon } from 'client/app/components/ElementPlumber/ElementOutputs/components/styles';

export default function LoadingPreview() {
  return (
    <Stack direction="row" gap={3} paddingX={3} alignItems="center">
      <VisibilityIcon color="secondary" />
      <Typography
        variant="body2"
        color="textSecondary"
        whiteSpace="nowrap"
        fontStyle="italic"
      >
        Loading preview...
      </Typography>
    </Stack>
  );
}
