import React from 'react';

import { styled } from '@mui/material/styles';
import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';

import Details from 'client/app/components/Parameters/PlateLayout/Details';
import LayoutPlate, {
  ActivePlateSelector,
} from 'client/app/components/Parameters/PlateLayout/LayoutPlate';
import LiquidLevels from 'client/app/components/Parameters/PlateLayout/LiquidLevels';
import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import PlateSetup from 'client/app/components/Parameters/PlateLayout/PlateSetup';
import Toolbar from 'client/app/components/Parameters/PlateLayout/Toolbar';
import Colors from 'common/ui/Colors';

export default function LiquidsLayoutEditor() {
  return (
    <Wrapper>
      <Toolbar />
      <LayoutPlate />
      <SidePanel />
      <ActivePlateSelector />
    </Wrapper>
  );
}

function SidePanel() {
  const {
    activeSidePanelTab,
    setActiveSidePanelTab,
    selectedLiquidOrLayerId,
    plateAssignment,
  } = usePlateLayoutEditorContext();

  return (
    <SidePanelWrapper>
      <SidePanelTabs
        value={activeSidePanelTab}
        variant="fullWidth"
        onChange={(_, value) => setActiveSidePanelTab(value)}
      >
        <SidePanelTab label="Setup" value="setup" />
        <SidePanelTab
          label="Liquids"
          value="liquids"
          disabled={!plateAssignment.plateType}
        />
      </SidePanelTabs>
      {activeSidePanelTab === 'liquids' ? (
        selectedLiquidOrLayerId ? (
          <Details />
        ) : (
          <LiquidLevels />
        )
      ) : (
        <PlateSetup />
      )}
    </SidePanelWrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(6),
  display: 'grid',
  grid: `
    'toolbar side' auto
    'main side' minmax(0, 1fr)
    'plateSelect side' auto / 1fr 300px
  `,
  placeItems: 'stretch',
  columnGap: theme.spacing(6),
}));

const SidePanelFrame = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.spacing(2),
  boxShadow: `0px 1px 1px 0px rgba(0, 0, 0, 0.14)`,
}));

const SidePanelWrapper = styled(SidePanelFrame)({
  gridArea: 'side',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr',
  placeItems: 'stretch',
});

const SidePanelTabs = styled(MuiTabs)(({ theme }) => ({
  background: theme.palette.grey['100'],
  borderRadius: theme.spacing(2, 2, 0, 0),
  minHeight: 'auto',
  '.MuiTabs-indicator': {
    display: 'none',
  },
}));

const SidePanelTab = styled(MuiTab)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2, 2, 0, 0),
  borderBottom: `1px solid ${theme.palette.grey['300']}`,
  minHeight: 'auto',
  '&.Mui-selected': {
    background: Colors.WHITE,
    borderBottomColor: 'transparent',
    '&:first-of-type': {
      borderRight: `1px solid ${theme.palette.grey['300']}`,
    },
    '&:last-of-type': {
      borderLeft: `1px solid ${theme.palette.grey['300']}`,
    },
  },
}));
