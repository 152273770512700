import { useCallback, useEffect } from 'react';

import { v4 as uuid } from 'uuid';

import { makeBundleContentsUnique } from 'client/app/apps/workflow-builder/lib/workflowUtils';
import cloneWithUUID from 'client/app/lib/workflow/cloneWithUUID';
import { buildBundle } from 'client/app/lib/workflow/types';
import { ScreenRegistry } from 'client/app/registry';
import {
  State as WorkflowState,
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { indexBy } from 'common/lib/data';
import {
  BundleParameters,
  Element,
  ServerSideBundle,
  ServerSideElementInstance,
} from 'common/types/bundle';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import { isInteractiveInput } from 'common/ui/lib/browser';
import { isNotNull } from 'common/utils';

// Helper functions for copying and pasting objects in the Workflow Builder.

function copyBundleToClipboard(clipboard: DataTransfer | null, bundle: ServerSideBundle) {
  if (!clipboard) {
    console.error('Cannot set contents of clipboard. Clipboard not available.');
    return;
  }
  clipboard.setData('text/plain', JSON.stringify(bundle, null, 4));
}

function getBundleFromClipboard(
  clipboard: DataTransfer | null,
): ServerSideBundle | undefined {
  if (!clipboard) {
    console.error('Cannot get contents of clipboard. Clipboard not available.');
    return;
  }
  try {
    return JSON.parse(clipboard.getData('text/plain'));
  } catch (ex) {
    console.error('Could not parse pasted data');
    return;
  }
}

function usePasteToWorkflow() {
  const elements = useWorkflowBuilderSelector(state => state.elementSet?.elements);
  const existingElementInstances = useWorkflowBuilderSelector(
    state => state.elementInstances,
  );
  const existingElementGroups = useWorkflowBuilderSelector(state => state.elementGroups);

  const dispatch = useWorkflowBuilderDispatch();

  const pasteToWorkflow = useCallback(
    (pastedBundle: ServerSideBundle) => {
      const existingInstanceNames = new Set(existingElementInstances.map(ei => ei.name));
      const existingElementGroupNames = new Set(existingElementGroups.map(eg => eg.name));
      const dedupedBundle = makeBundleContentsUnique(
        pastedBundle,
        existingInstanceNames,
        existingElementGroupNames,
      );

      const {
        elementInstances,
        parameters,
        InstancesConnections: connections,
        elementGroups,
      } = deserialisePastedV2BundleIntoWorkflowBuilderState(
        dedupedBundle,
        elements ?? [],
      );

      dispatch({
        type: 'addPastedObjects',
        payload: {
          elementInstances,
          parameters: parameters ?? {},
          connections,
          elementGroups,
        },
      });
    },
    [dispatch, elements, existingElementGroups, existingElementInstances],
  );

  return pasteToWorkflow;
}

export function useCopyPasteIntoBuilder(canCopy: boolean, canPaste: boolean) {
  const elementSet = useWorkflowBuilderSelector(state => state.elementSet);
  const selectedObjectIds = useWorkflowBuilderSelector(state => state.selectedObjectIds);
  const getSelectedObjectsAsBundle = useGetSelectedObjectsAsBundle();
  const { showInfo } = useSnackbarManager();
  const onCopy = useCallback(
    (e: Event) => {
      const selection = document.getSelection();
      if (
        selection?.type === 'Range' || // if text is selected
        document.activeElement !== document.body // or input is focussed
      ) {
        return; // don't copy
      }
      if (!canCopy) {
        if (selectedObjectIds.length > 0) {
          showInfo(
            'Please use the top bar to make an editable copy or go to the latest version to copy elements',
          );
        }
        return;
      }
      if (!(e instanceof ClipboardEvent)) {
        // Typeguard so that we can access the ClipboardEvent-specific properties.
        return;
      }
      if (!elementSet) {
        return;
      }
      logEvent('copy', ScreenRegistry.WORKFLOW);
      const bundle = getSelectedObjectsAsBundle();
      if (bundle) {
        copyBundleToClipboard(e.clipboardData, bundle);
        e.preventDefault();
      }
    },
    [canCopy, elementSet, getSelectedObjectsAsBundle, selectedObjectIds.length, showInfo],
  );
  const pasteToWorkflow = usePasteToWorkflow();

  const onPaste = useCallback(
    (e: Event) => {
      if (!canPaste) {
        // Forbid pasting elements in e.g. WF snapshots or DOE templates
        return;
      }

      if (!(e instanceof ClipboardEvent)) {
        return;
      }

      if (isInteractiveInput(e.target)) {
        // Don't paste JSON into a text input
        return;
      }

      logEvent('paste', ScreenRegistry.WORKFLOW);
      const bundle = getBundleFromClipboard(e.clipboardData);

      if (bundle) {
        pasteToWorkflow(bundle);
      }
    },
    [canPaste, pasteToWorkflow],
  );

  useEffect(() => {
    window.addEventListener('copy', onCopy);
    window.addEventListener('paste', onPaste);

    return () => {
      window.removeEventListener('copy', onCopy);
      window.removeEventListener('paste', onPaste);
    };
  }, [onCopy, onPaste]);
}

function deserialisePastedV2BundleIntoWorkflowBuilderState(
  pastedBundle: ServerSideBundle,
  elements: readonly Element[],
): Pick<
  WorkflowState,
  'InstancesConnections' | 'elementInstances' | 'parameters' | 'elementGroups'
> {
  const {
    Elements: { Instances, InstancesConnections },
    Groups,
  } = pastedBundle;
  const elementsByName = indexBy(elements, 'name');
  const instanceEntries = Object.entries(Instances);

  // We use this to update the groups' list of element ids
  const newElementIdMap = new Map<string, string>();

  const elementInstances = instanceEntries
    .filter(([_, instance]) => !!elementsByName[instance.TypeName])
    .map(([name, instance]) => {
      const newId = uuid();
      newElementIdMap.set(instance.Id, newId);

      return {
        ...instance,
        name,
        element: elementsByName[instance.TypeName],
        Id: newId,
      };
    });

  return {
    elementInstances,
    InstancesConnections: InstancesConnections.map(connection =>
      cloneWithUUID(connection),
    ),
    parameters: instanceEntries.reduce(
      (acc: BundleParameters, [name, instance]: [string, ServerSideElementInstance]) => {
        return {
          ...acc,
          [name]: { ...instance.Parameters },
        };
      },
      {},
    ),
    elementGroups:
      Groups?.map(group => ({
        ...group,
        id: uuid(),
        elementIds: group.elementIds
          .map(id => newElementIdMap.get(id) ?? null)
          .filter(isNotNull),
      })) ?? [],
  };
}

/** Returns a workflow bundle containing the currently selected objects in the workflow */
function useGetSelectedObjectsAsBundle() {
  const {
    elementInstances,
    parameters,
    connections,
    workflowName,
    config,
    elementSet,
    selectedObjectIds,
    groups,
    stages,
  } = useWorkflowBuilderSelector(state => ({
    elementInstances: state.elementInstances,
    parameters: state.parameters,
    connections: state.InstancesConnections,
    workflowName: state.workflowName,
    config: state.config,
    elementSet: state.elementSet,
    selectedObjectIds: state.selectedObjectIds,
    groups: state.elementGroups,
    stages: state.stages,
  }));

  const getSelectedObjectsAsBundle = useCallback(() => {
    if (elementSet === null) {
      console.error('Cannot copy, no element set is selected.');
      return;
    }

    const selectedElementInstances = elementInstances.filter(instance =>
      selectedObjectIds.includes(instance.Id),
    );

    const parametersOfSelectedInstances: BundleParameters =
      selectedElementInstances.reduce(
        (acc, instance) => ({
          ...acc,
          [instance.name]: parameters[instance.name],
        }),
        {},
      );

    const selectedConnections = connections.filter(connection =>
      selectedObjectIds.includes(connection.id),
    );

    const selectedGroups = groups
      .filter(group => selectedObjectIds.includes(group.id))
      .map(group => ({
        ...group,
        elementIds: group.elementIds.filter(id =>
          selectedElementInstances.some(ei => ei.Id === id),
        ),
      }));

    const bundle = buildBundle({
      workflowMeta: {
        Name: workflowName,
      },
      config,
      workflowSchemaVersion: elementSet.workflowSchemaVersion,
      elementSetId: elementSet.id,
      elementInstances: selectedElementInstances,
      InstancesConnections: selectedConnections,
      parameters: parametersOfSelectedInstances,
      groups: selectedGroups,
      stages: stages,
      factors: null,
    });

    return bundle;
  }, [
    config,
    connections,
    elementInstances,
    elementSet,
    groups,
    parameters,
    selectedObjectIds,
    stages,
    workflowName,
  ]);

  return getSelectedObjectsAsBundle;
}
