import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { Palette } from '@mui/material/styles/createPalette';

import * as LayoutHelper from 'client/app/lib/layout/LayoutHelper';
import { ElementInstanceStatus } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  showStatus: boolean;
  status?: ElementInstanceStatus;
  isLoading: boolean;
};

export default function ElementInstanceStatusBar({
  showStatus,
  status = 'neutral',
  isLoading,
}: Props) {
  const classes = useStyles({ status, isLoading });

  let variant: 'determinate' | 'indeterminate';

  if (!showStatus) {
    return null;
  } else if (isLoading) {
    variant = 'indeterminate';
  } else {
    variant = 'determinate';
  }

  return <LinearProgress variant={variant} value={0} className={classes.statusBar} />;
}

const useStyles = makeStylesHook<
  string,
  { status: ElementInstanceStatus; isLoading: boolean }
>(({ palette }) => ({
  statusBar: ({ status, isLoading }) => ({
    position: 'absolute',
    top: LayoutHelper.ELEMENT_INSTANCE_HEADER_HEIGHT,
    height: '4px',
    width: '100%',
    background: isLoading ? palette.primary.light : getStatusColor(status, palette),
  }),
}));

function getStatusColor(status: ElementInstanceStatus, palette: Palette): string {
  switch (status) {
    case 'ok':
    case 'neutral':
      return Colors.GREY_40;
    case 'warning':
      return palette.warning.main;
    case 'error':
      return palette.error.dark;
    default:
      return 'initial';
  }
}
