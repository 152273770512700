import React from 'react';

import Autorenew from '@mui/icons-material/Autorenew';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ErrorOutlined from '@mui/icons-material/ErrorOutline';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useFeatureToggle } from 'common/features/useFeatureToggle';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  isReadonly: boolean;
  isSaving: boolean;
  hasWorkflowSaveFailed: boolean;
  dragError?: string;
};

function WorkflowBuilderStatus({
  isReadonly,
  isSaving,
  hasWorkflowSaveFailed,
  dragError,
}: Props) {
  const classes = useStyles();
  const isEnabledElementParameterValidation = useFeatureToggle('PARAMETER_VALIDATION');
  const statusCopy = isEnabledElementParameterValidation
    ? 'Saving and validating...'
    : 'Saving ...';

  const failedToSaveCopy = 'Failed to save';

  let content: React.ReactElement | null;

  if (isReadonly) {
    content = null;
  } else if (dragError) {
    content = (
      <DragError>
        <ErrorOutline fontSize="small" />
        <Typography variant="caption">{dragError}</Typography>
      </DragError>
    );
  } else if (hasWorkflowSaveFailed) {
    content = (
      <>
        <ErrorOutlined fontSize="small" color="error" />
        <Typography
          variant="body1"
          className={classes.saveStatus}
          color="error"
          noWrap
          align="left"
        >
          {failedToSaveCopy}
        </Typography>
      </>
    );
  } else if (isSaving) {
    content = (
      <>
        <Autorenew fontSize="small" className={classes.progress} />
        <Typography variant="body1" noWrap className={classes.saveStatus} align="left">
          {statusCopy}
        </Typography>
      </>
    );
  } else {
    content = (
      <>
        <CheckRoundedIcon fontSize="small" />
        <Typography variant="body1" className={classes.saveStatus} noWrap align="left">
          All changes saved
        </Typography>
      </>
    );
  }

  return <div className={classes.container}>{content}</div>;
}

export default React.memo(WorkflowBuilderStatus);

const useStyles = makeStylesHook(({ spacing, palette }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    color: palette.text.secondary,
    height: 'auto',
    padding: 0,
    minWidth: '174px',
  },
  saveStatus: {
    overflow: 'hidden',
    alignSelf: 'center',
    flex: 1,
    marginLeft: spacing(3),
  },
  progress: {
    animation: '$rotation 1s linear',
    animationIterationCount: 'infinite',
  },
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

const DragError = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(3),
  color: Colors.WHITE,
}));
