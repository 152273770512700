import React from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography, { typographyClasses } from '@mui/material/Typography';

import { LabwareInfoSection } from 'client/app/components/Labware/types';
import Colors from 'common/ui/Colors';

type Props = {
  section: LabwareInfoSection;
};

export default function Tips({ section: { sectionHeader, sectionItems } }: Props) {
  return sectionItems?.length > 0 ? (
    <Stack gap={4}>
      <Typography variant="caption" fontWeight={600} color="textPrimary">
        {sectionHeader}
      </Typography>
      <Stack gap={4}>
        {sectionItems.map((item, index) => (
          <TipRow key={index}>
            <TypeArea>
              <Typography variant="body1" fontWeight={600}>
                Type:
              </Typography>
              <Typography variant="body1">{item.primaryLabel}</Typography>
            </TypeArea>
            <NumberArea>
              <Typography variant="body1" fontWeight={600}>
                Number:
              </Typography>
              <Typography variant="body1">{item.secondaryLabels}</Typography>
            </NumberArea>
            <MaxVolumeArea>
              <Typography variant="body1" fontWeight={600}>
                Max volume:
              </Typography>
              <Typography variant="body1">{item.tertiaryLabels}</Typography>
            </MaxVolumeArea>
          </TipRow>
        ))}
      </Stack>
    </Stack>
  ) : null;
}

const TipRow = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateAreas: `
    "type number"
    "max-volume ."
  `,
  gridRowGap: theme.spacing(5),

  padding: theme.spacing(5, 6),
  backgroundColor: Colors.WHITE,
  border: `1px solid ${theme.palette.divider}`,

  [`& .${typographyClasses.body1}`]: {
    color: theme.palette.text.primary,
  },
}));

const TypeArea = styled(Stack)(({ theme }) => ({
  gridArea: 'type',
  gap: theme.spacing(2),
}));

const NumberArea = styled(Stack)(({ theme }) => ({
  gridArea: 'number',
  alignItems: 'flex-end',
  gap: theme.spacing(2),
}));

const MaxVolumeArea = styled(Stack)(({ theme }) => ({
  gridArea: 'max-volume',
  gap: theme.spacing(2),
}));
