import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material/';

export default function DOETemplateIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.95014 16.05C9.50242 16.05 9.95014 15.6023 9.95014 15.05C9.95014 14.4978 9.50242 14.05 8.95014 14.05C8.39785 14.05 7.95014 14.4978 7.95014 15.05C7.95014 15.6023 8.39785 16.05 8.95014 16.05ZM8.95014 17.55C10.3308 17.55 11.4501 16.4308 11.4501 15.05C11.4501 13.6693 10.3308 12.55 8.95014 12.55C7.56943 12.55 6.45014 13.6693 6.45014 15.05C6.45014 16.4308 7.56943 17.55 8.95014 17.55Z"
          fill="currentColor"
        />
        <path
          d="M16.9502 20.55C16.9502 21.9307 15.8309 23.05 14.4502 23.05C13.0695 23.05 11.9502 21.9307 11.9502 20.55C11.9502 19.1693 13.0695 18.05 14.4502 18.05C15.8309 18.05 16.9502 19.1693 16.9502 20.55Z"
          fill="currentColor"
        />
        <path
          d="M5.9502 20.55C5.9502 21.9307 4.83091 23.05 3.4502 23.05C2.06948 23.05 0.950195 21.9307 0.950195 20.55C0.950195 19.1693 2.06948 18.05 3.4502 18.05C4.83091 18.05 5.9502 19.1693 5.9502 20.55Z"
          fill="currentColor"
        />
        <path
          d="M5.9502 9.54999C5.9502 10.9307 4.83091 12.05 3.4502 12.05C2.06948 12.05 0.950195 10.9307 0.950195 9.54999C0.950195 8.16928 2.06948 7.04999 3.4502 7.04999C4.83091 7.04999 5.9502 8.16928 5.9502 9.54999Z"
          fill="currentColor"
        />
        <path
          d="M22.4501 15.05C22.4501 16.4308 21.3308 17.55 19.9501 17.55C18.5694 17.55 17.4501 16.4308 17.4501 15.05C17.4501 13.6693 18.5694 12.55 19.9501 12.55C21.3308 12.55 22.4501 13.6693 22.4501 15.05Z"
          fill="currentColor"
        />
        <path
          d="M11.4501 4.05005C11.4501 5.43076 10.3308 6.55005 8.95014 6.55005C7.56943 6.55005 6.45014 5.43076 6.45014 4.05005C6.45014 2.66934 7.56943 1.55005 8.95014 1.55005C10.3308 1.55005 11.4501 2.66934 11.4501 4.05005Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.98033 5.08055L3.48033 10.5766L2.42004 9.51561L7.92004 4.01952L8.98033 5.08055Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00011 17.1327L3.13146 22L2.00011 20.8673L6.86876 16L8.00011 17.1327Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7303 16.0845L14.2303 21.5806L13.17 20.5196L18.67 15.0235L19.7303 16.0845Z"
          fill="currentColor"
        />
        <path
          d="M8.2002 3.30005H13V4.80005H9.7002V13H8.2002V3.30005Z"
          fill="currentColor"
        />
        <path d="M11 15.8H20.7002V13H19.2002V14.3H11V15.8Z" fill="currentColor" />
        <path
          d="M11 8.25H3.25V20.75H15.75V13.0001H14.25V19.25H4.75V9.75H11V8.25Z"
          fill="currentColor"
        />
        <path
          d="M17.177 6.05034L17.9486 6.82215L15.4491 9.32215H14.6775V8.55034L17.177 6.05034ZM20.1965 1C19.9868 1 19.7687 1.08389 19.6093 1.24329L18.0744 2.77852L21.2197 5.9245L22.7547 4.38926C23.0818 4.06208 23.0818 3.53356 22.7547 3.20638L20.792 1.24329C20.6242 1.0755 20.4145 1 20.1965 1ZM17.177 3.67617L13 7.85403V11H16.1453L20.3223 6.82215L17.177 3.67617Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
