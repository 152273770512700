import React, { useMemo, useState } from 'react';

import HelpIcon from '@mui/icons-material/HelpOutline';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { svgIconClasses } from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { ErrorBoundary } from '@sentry/react';

import { ReagentAccordion } from 'client/app/apps/execution-details/ExecuteTab/Reagents/components/ReagentAccordion';
import ResourcesInput from 'client/app/apps/execution-details/ExecuteTab/Reagents/components/ResourcesInput';
import { StockView } from 'client/app/apps/execution-details/ExecuteTab/Reagents/components/StockView';
import Helpers from 'client/app/apps/execution-details/ExecuteTab/Reagents/helpers';
import { StockConcentration } from 'client/app/apps/execution-details/types';
import ParameterEditor from 'client/app/components/Parameters/ParameterEditor';
import { Reagent } from 'client/app/gql';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { roundUp } from 'common/lib/format';
import { byName } from 'common/lib/strings';
import { Measurement } from 'common/types/mix';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';
import Tooltip from 'common/ui/components/Tooltip';
import { DownloadNotCloud } from 'common/ui/icons/DownloadNotCloud';

const NO_REAGENTS: readonly Reagent[] = [] as const;

enum TabIds {
  INPUTS = 'inputs',
  STOCKS = 'stocks',
}
const TABS: TabsInfo<TabIds> = [
  { value: TabIds.INPUTS, label: 'Inputs' },
  { value: TabIds.STOCKS, label: 'Stocks' },
];

type Props = {
  reagents: readonly Reagent[] | null;
  onDownload: (stockConcentrations: StockConcentration[], reagents: Reagent[]) => void;
};

export default function Reagents(props: Props) {
  return (
    <ErrorBoundary
      fallback={
        <Typography variant="body1">
          A problem occurred while generating the reagents list.
        </Typography>
      }
    >
      <ReagentsListContent {...props} />
    </ErrorBoundary>
  );
}

function ReagentsListContent({
  reagents = NO_REAGENTS,
  onDownload: downloadReagents,
}: Props) {
  const [extraVolume, setExtraVolume] = useState<number | ''>('');
  const [roundUpEnabled, setRoundUpEnabled] = useState(false);

  const sortedReagents = useMemo(() => {
    const maybeRound = roundUpEnabled ? roundUp : (val: number) => val;

    return [...reagents!].sort(byName).map(r => ({
      ...r,
      volumeUl: maybeRound(r.volumeUl + (extraVolume || 0.0)),
    }));
  }, [extraVolume, reagents, roundUpEnabled]);

  const [stockConcentrations, setStockConcentrations] = useState(
    Helpers.getDefaultStockConcentrations(sortedReagents),
  );

  const onUpdateStock = (name: string, value: Measurement) => {
    const newConcs = [...stockConcentrations];
    const conc = Helpers.getConcentration(newConcs, name, value.unit)!;
    conc.concentration = value;
    setStockConcentrations(newConcs);
  };

  const [tabId, setTabId] = useState(TabIds.INPUTS);

  return sortedReagents.length > 0 ? (
    <Stack gap={5}>
      <ReagentTabs tabsInfo={TABS} activeTab={tabId} onChangeTab={tab => setTabId(tab)} />
      {tabId === TabIds.INPUTS && (
        <Stack gap={4}>
          <RoundToggleControl>
            <Typography variant="subtitle2">Round Up Volumes</Typography>
            <Tooltip
              title={
                <Typography variant="caption">
                  Round totals up to nearest 2 significant figures
                </Typography>
              }
            >
              <HelpIcon />
            </Tooltip>
            <ParameterEditor
              anthaType="bool"
              value={roundUpEnabled}
              onChange={setRoundUpEnabled}
              editorType={EditorType.TOGGLE}
            />
          </RoundToggleControl>
          <ResourcesInput
            label="Extra volume"
            value={extraVolume}
            type="number"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="body1" color="textSecondary" marginRight={2}>
                    ul
                  </Typography>
                  <Tooltip
                    title={
                      <Typography variant="caption">
                        Increase the volume to make of each input liquid
                      </Typography>
                    }
                  >
                    <HelpIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            onChange={event => {
              const newValue = parseFloat(event.target.value);
              setExtraVolume(Number.isNaN(newValue) ? '' : newValue);
            }}
          />
          <DownloadButton
            variant="outlined"
            color="primary"
            startIcon={<DownloadNotCloud />}
            onClick={() => downloadReagents(stockConcentrations, sortedReagents)}
          >
            Download
          </DownloadButton>
          {sortedReagents.map((reagent, idx) => (
            <ReagentAccordion
              key={idx}
              reagent={reagent}
              stockConcentrations={stockConcentrations}
            />
          ))}
        </Stack>
      )}
      {tabId === TabIds.STOCKS && (
        <Stack>
          <StockView
            stockConcentrations={stockConcentrations}
            onUpdateStock={onUpdateStock}
            reagents={sortedReagents}
          />
        </Stack>
      )}
    </Stack>
  ) : (
    <Typography variant="body1" textAlign="center">
      No reagents to display
    </Typography>
  );
}

const ReagentTabs = styled(Tabs)({
  '& button': {
    maxWidth: 'unset',
    flex: 1,
  },
}) as typeof Tabs;

const DownloadButton = styled(Button)({
  height: 40,
  fontSize: 15,
});

const RoundToggleControl = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  alignItems: 'center',
  gap: theme.spacing(2),

  color: theme.palette.text.primary,

  [`& .${svgIconClasses.root}`]: {
    color: theme.palette.text.primary,
    fontSize: '20px',
  },
}));
