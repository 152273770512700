import React, { useCallback, useState } from 'react';

import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const localeData = moment()
  .locale(window.navigator.language || 'en-us')
  .localeData(); // set locale for just this instance

const dateFormat = localeData.longDateFormat('L');
// us format is `h:mm:ss A` but we need the ability to input 2`h`
const timeFormat = localeData
  .longDateFormat('LTS')
  .replace(/h{1,2}/, 'hh')
  .replace(/H{1,2}/, 'HH');

type DateTimePickerProps = {
  value: moment.Moment;
  onChange: (date: moment.Moment | null) => void;
};

export default function DateTimePicker(props: DateTimePickerProps) {
  const classes = useStyles();
  const { value, onChange } = props;
  /**
   * Store date and time separately so they can be controlled by independent date and time
   * pickers. Using the same date object for both pickers causes time to reset when
   * picking a date and vice versa.
   **/
  const [datePart, setDatePart] = useState(value);
  const [timePart, setTimePart] = useState(value);

  const updateDateTime = useCallback(
    (datePart, timePart) => {
      const dateTime = moment({
        year: datePart.year(),
        month: datePart.month(),
        date: datePart.date(),
        hour: timePart.hour(),
        minute: timePart.minute(),
        second: timePart.second(),
      });
      onChange(dateTime);
    },
    [onChange],
  );

  const handleDateChange = useCallback(
    (maybeNewValue: unknown) => {
      if (!maybeNewValue) {
        return;
      }
      const newValue = moment(maybeNewValue);
      setDatePart(newValue);
      updateDateTime(newValue, timePart);
    },
    [timePart, updateDateTime, setDatePart],
  );

  const handleTimeChange = useCallback(
    (maybeNewValue: unknown) => {
      if (!maybeNewValue) {
        return;
      }
      const newValue = moment(maybeNewValue);
      setTimePart(newValue);
      updateDateTime(datePart, newValue);
    },
    [datePart, updateDateTime, setTimePart],
  );

  return (
    <div className={classes.dateTimePicker}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        dateFormats={{ fullDate: dateFormat, fullTime: timeFormat }}
      >
        <DatePicker
          className={classes.date}
          value={datePart}
          onChange={handleDateChange}
        />
        <TimePicker
          className={classes.time}
          ampm
          value={timePart}
          onChange={handleTimeChange}
          views={['hours', 'minutes', 'seconds']}
        />
      </LocalizationProvider>
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  dateTimePicker: {
    display: 'flex',
    gap: '8px',
  },
  date: {
    flex: '3 0',
  },
  time: {
    flex: '2 1',
  },
  noIcon: {
    display: 'none',
  },
  icon: {
    padding: theme.spacing(0),
  },
}));
