import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ElementsListIcon = React.memo<SvgIconProps>(function ElementsListIcon(
  props,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 7V21H16V7H4ZM3 5C2.44772 5 2 5.44772 2 6V22C2 22.5523 2.44772 23 3 23H17C17.5523 23 18 22.5523 18 22V6C18 5.44772 17.5523 5 17 5H3Z"
          fill="currentColor"
        />
        <path d="M4 17H6V19H4V17Z" fill="currentColor" />
        <path d="M16 13H14V15H16V13Z" fill="currentColor" />
        <path
          d="M4 13H6C6.55228 13 7 13.4477 7 14C7 14.5523 6.55228 15 6 15H4V13Z"
          fill="currentColor"
        />
        <path
          d="M16 9H14C13.4477 9 13 9.44772 13 10C13 10.5523 13.4477 11 14 11H16V9Z"
          fill="currentColor"
        />
        <path
          d="M4 9H6C6.55228 9 7 9.44772 7 10C7 10.5523 6.55228 11 6 11H4V9Z"
          fill="currentColor"
        />
        <path
          d="M10 3L20.0503 3V13H22.0503L22.0503 3C22.0503 1.89543 21.1549 1 20.0503 1L10 1L10 3Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
