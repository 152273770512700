import React, { useCallback, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import {
  QUERY_SIMULATION_NAME,
  QUERY_SIMULATION_NAME_BY_LEGACY_JOB_ID,
} from 'client/app/api/gql/queries';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import SimulationsDialog from 'client/app/components/Parameters/SimulationsDialog/SimulationsDialog';
import { Simulation } from 'client/app/gql';
import { isUUID } from 'common/lib/strings';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import SelectFromDialogButton from 'common/ui/components/SelectFromDialogButton';

// The value of this parameter is the job/simulation id, rather than a simulation object
type Props = {
  onChange: (simulationOrLegacyJobId?: string) => void;
} & ParameterEditorBaseProps<string>;

const DIALOG_PROPS = {};

export default function SimulationParameter(props: Props) {
  const { value: simulationOrLegacyJobId, onChange, isDisabled } = props;

  const [simulationName, setSimulationName] = useState<string>(
    'Loading simulation info...',
  );

  /**
   * Some legacy elements like "Get Simulation Details for Job" store the
   * Legacy Job ID as value for their parameters. The UI needs to show the name
   * of the simulation and we need to fetch it, using the Legacy Job ID.
   * TODO Drop `fetchSimulationByLegacyJobId` and the relative useEffect
   * once soon to be deprecated elements which use legacy Job IDs will be removed. (T2792)
   */
  const [getSimulationName, { data, loading, error }] = useLazyQuery(
    QUERY_SIMULATION_NAME,
    {
      variables: {
        id: (simulationOrLegacyJobId ?? '') as SimulationId,
      },
    },
  );

  const [
    getLegacySimulationName,
    { data: legacyData, loading: legacyLoading, error: legacyError },
  ] = useLazyQuery(QUERY_SIMULATION_NAME_BY_LEGACY_JOB_ID, {
    variables: {
      id: simulationOrLegacyJobId ?? '',
    },
  });
  useEffect(() => {
    if (isUUID(simulationOrLegacyJobId ?? '')) {
      void getSimulationName();
    } else {
      void getLegacySimulationName();
    }
  }, [getLegacySimulationName, getSimulationName, simulationOrLegacyJobId]);

  // Fetch the simulation name and display it once ready.
  useEffect(() => {
    if (!loading && !error && data?.simulation.name) {
      setSimulationName(data.simulation.name);
    }
    if (!legacyLoading && !legacyError && legacyData?.simulationByLegacyJobId?.name) {
      setSimulationName(legacyData.simulationByLegacyJobId.name ?? 'Loading job info...');
    }

    // Handle cases when there is no simulation associated with the legacy job ID
    if (
      data?.simulation === undefined &&
      legacyData?.simulationByLegacyJobId === null &&
      simulationOrLegacyJobId
    ) {
      setSimulationName(simulationOrLegacyJobId);
    }
  }, [
    data,
    error,
    legacyData,
    legacyError,
    legacyLoading,
    loading,
    simulationOrLegacyJobId,
  ]);

  const handleChange = useCallback(
    (simulation: Simulation | typeof CANCEL_CHOICE | undefined) => {
      if (simulation === CANCEL_CHOICE) {
        // User exited the dialog without making a selection
        return;
      }
      if (!simulation) {
        // User cleared the value
        setSimulationName('');
        onChange(undefined);
        return;
      }
      setSimulationName(simulation.name);
      // For old simulations (pre April 2020) that were migrated from the old data model:
      // We need to use the legacy job id (the id in the pre-migration Google Datastore)
      // because there are still filetree files stored under the legacy id - the filetree
      // files were not migrated to a new path. And we still have some legacy elements like
      // "Get Simulation Details for Job" which use the Webdav protocol to access files
      // at those old filetree paths. This needs to go away but only after those legacy
      // elements are deleted.
      onChange(simulation.legacyJobId || simulation.id);
    },
    [onChange],
  );

  return (
    <SelectFromDialogButton
      value={simulationOrLegacyJobId}
      selectedValueLabel={simulationName}
      dialog={SimulationsDialog}
      dialogProps={DIALOG_PROPS}
      placeholder="Select a simulation"
      onChange={handleChange}
      isDisabled={isDisabled}
    />
  );
}
