import React, { useCallback } from 'react';

import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import GenericInputEditor from 'common/ui/components/ParameterEditors/GenericInputEditor';

type Props = {
  type: string;
  disableUnderline?: boolean;
  onChange: (newValue: number | null) => void;
} & ParameterEditorBaseProps<number>;

const validate = (numString: string | null) => {
  if (numString === null || numString === '') {
    return '';
  }

  const num = parseFloat(numString);
  if (!numString.match(/^(-)?(0)?\d+(\.\d+)?(e\d+)?$/) || isNaN(num)) {
    return 'Invalid float value';
  }

  if (Math.abs(num) < 0.000001 && num !== 0) {
    return 'Values smaller than 0.000001 cause issues in the web UI';
  }

  if (num > Number.MAX_SAFE_INTEGER) {
    return `Numbers greater than ${Number.MAX_SAFE_INTEGER} may cause issues in the web UI`;
  }

  if (num < Number.MIN_SAFE_INTEGER) {
    return `Numbers less than ${Number.MIN_SAFE_INTEGER} may cause issues in the web UI`;
  }

  return '';
};

const FloatEditor = React.memo(function FloatEditor(props: Props) {
  const onChange = useCallback(
    (value: string) => {
      const num = parseFloat(value);
      props.onChange(isNaN(num) ? null : num);
    },
    [props],
  );

  const { value } = props;
  return (
    <GenericInputEditor
      type={props.type}
      value={value?.toString(10) ?? ''}
      onChange={onChange}
      validate={validate}
      isDisabled={props.isDisabled}
      isRequired={props.isRequired}
      placeholder={props.placeholder}
      disableUnderline={props.disableUnderline}
    />
  );
});

export default FloatEditor;
