import React, { useCallback, useMemo } from 'react';

import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import PolicyDialog from 'client/app/components/Parameters/Policy/PolicyDialog';
import { AppIcon } from 'client/app/icons';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import SelectFromDialogButton from 'common/ui/components/SelectFromDialogButton';

type Props = {
  value: string;
  onChange: (value: string | undefined) => void;
  isDisabled?: boolean;
} & ParameterEditorBaseProps<string>;

export default function PolicyParameter(props: Props) {
  const { value, onChange, isDisabled } = props;

  const handleChange = useCallback(
    (newVal: string | undefined | typeof CANCEL_CHOICE) => {
      if (newVal === CANCEL_CHOICE) {
        // Cancel - don't deselect the current choice.
        return;
      }
      if (!newVal) {
        // They actively selected none - clear the selection.
        onChange(undefined);
      }
      onChange(newVal);
    },
    [onChange],
  );

  const dialogProps = useMemo(() => ({ value }), [value]);

  return (
    <SelectFromDialogButton
      value={value}
      dialog={PolicyDialog}
      dialogProps={dialogProps}
      placeholder="Select a policy"
      icon={<AppIcon iconId="antha:policy" />}
      onChange={handleChange}
      isDisabled={isDisabled}
    />
  );
}
