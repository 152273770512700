import React, { ReactNode } from 'react';

import Grid from '@mui/material/Grid';
import { createStyles } from '@mui/styles';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { theme } from 'common/ui/theme';

export type StackProps = {
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  spaceAbove?: number;
  direction?: 'row' | 'column';
  children: ReactNode[];
};

/**
 * A simple version of a stack component, which is used to display child components with uniform spacing between them.
 * This should be replaced with https://mui.com/material-ui/react-stack/ when we upgrade to MUI v5
 */
export const Stack = ({
  spacing = 2,
  children,
  spaceAbove = 3,
  direction = 'column',
}: StackProps) => {
  const classes = useStyles({ spaceAbove });

  return (
    <Grid container direction={direction} spacing={spacing} className={classes.container}>
      {children.map((child, ind) => (
        <Grid item xs={direction === 'row' ? true : 12} key={ind}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

type StyleProps = {
  spaceAbove: number;
};

const useStyles = makeStylesHook(
  createStyles({
    container: {
      marginTop: (props: StyleProps) => theme.spacing(props.spaceAbove),
    },
  }),
);
