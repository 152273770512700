import React, { useCallback } from 'react';

import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import KebabMenuIcon from '@mui/icons-material/MoreVert';
import Update from '@mui/icons-material/Update';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Menu, { MenuProps } from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { useCreateDOETemplate } from 'client/app/api/DOETemplateApi';
import ExampleWorkflowDialog from 'client/app/apps/simulation-details/ExampleWorkflow/Dialog';
import useExampleWorkflowHandlers from 'client/app/apps/simulation-details/ExampleWorkflow/useExampleWorkflowHandlers';
import { SimulationQuery, WorkflowSourceEnum } from 'client/app/gql';
import useRequestHelpForSimulation from 'client/app/hooks/useRequestHelpForSimulation';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import {
  doeTemplateRoutes,
  graceRoutes,
  protocolsRoutes,
  templateWorkflowRoutes,
} from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { IntercomTourIDs } from 'common/lib/intercom';
import Colors from 'common/ui/Colors';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import IconButton from 'common/ui/components/IconButton';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Tooltip from 'common/ui/components/Tooltip';
import useDialog from 'common/ui/hooks/useDialog';
import { ExampleWorkflowIcon } from 'common/ui/icons/';
import DOEIcon from 'common/ui/icons/DOEIcon';
import { GraceIcon } from 'common/ui/icons/GraceIcon';
import { TalkToUsIcon } from 'common/ui/icons/TalkToUs';
import { TemplateWorkflowIcon } from 'common/ui/icons/TemplateWorkflow';

type Props = {
  simulation: SimulationQuery['simulation'];
  onLoadingChange: (isLoading: boolean) => void;
  showCreateDOEButton: boolean;
};

/**
 * The button in the Experiments screen that allows the user to create
 * a new Experiment, as well as a few different types of Workflows.
 */
export default function SimulationHeaderDropdown({
  simulation,
  onLoadingChange,
  showCreateDOEButton,
}: Props) {
  const areProtocolsEnabled = useFeatureToggle('PROTOCOLS');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const [updateExampleWorkflowDialog, openUpdateExampleWorflowDialog] =
    useDialog(ConfirmationDialog);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const editInGracePath = graceRoutes.openSimulationInGrace.getPath({
    simulationId: simulation.id,
  });

  const snackbarManager = useSnackbarManager();
  const { navigate } = useNavigation();

  const createDOETemplate = useCreateDOETemplate();

  const handleCreateDOE = useCallback(async () => {
    closeMenu();
    onLoadingChange(true);
    try {
      const workflowId = await createDOETemplate(simulation.id);
      navigate(doeTemplateRoutes.edit, { workflowId });
    } catch (error) {
      snackbarManager.showError(
        `Could not create DOE template from this simulation: ${error.message}`,
      );
    } finally {
      onLoadingChange(false);
    }
  }, [createDOETemplate, navigate, onLoadingChange, simulation.id, snackbarManager]);

  const { requestHelpDialog, handleRequestHelp } = useRequestHelpForSimulation(
    ScreenRegistry.SIMULATION_DETAILS,
    simulation.id,
    simulation.name,
  );

  const closeMenuAndRequestHelp = () => {
    closeMenu();
    void handleRequestHelp();
  };
  const preventTemplateCreation = simulation.stages.length > 1;

  const exampleWorkflow = useExampleWorkflowHandlers(
    simulation.workflow.id,
    simulation.editableWorkflow?.id,
  );
  const [exampleWorkflowDialog, openExampleWorkflowsDialog] =
    useDialog(ExampleWorkflowDialog);

  const userProfile = useUserProfile();
  const isEnabledQuickStartActivation = useFeatureToggle('QUICK_START_ACTIVATION');
  const isSimulationBaseForWorkflowExample =
    exampleWorkflow.data?.workflowId === simulation.workflow.id;

  const handleUpdateExampleWorkflow = async () => {
    const isConfirmed = await openUpdateExampleWorflowDialog({
      action: 'update',
      object: 'example workflow',
      confirmButtonLabel: 'Confirm',
      omitConfirmationQuestion: true,
      additionalMessage:
        'Updating the example will change the snapshot an example workflow uses, to reflect the current simulation. Are you sure you want to proceed?',
    });
    if (isConfirmed) {
      await exampleWorkflow.update({});
    }
  };

  const exampleWorkflowMenu =
    isEnabledQuickStartActivation &&
    userProfile?.isExampleWorkflowsSourceOrg &&
    userProfile?.isSynthaceEmployee
      ? [
          <Divider key="divider" />,
          exampleWorkflow.loading ? (
            <Stack key="loader" alignItems="center" py={3}>
              <CircularProgress size={30} />
            </Stack>
          ) : exampleWorkflow.exist ? (
            [
              isSimulationBaseForWorkflowExample ? (
                <MenuItemWithIcon
                  key="edit"
                  icon={<Edit />}
                  text="Edit example"
                  onClick={() =>
                    openExampleWorkflowsDialog({
                      title: `Edit Example: ${exampleWorkflow.data?.name}`,
                      exampleWorkflow,
                    })
                  }
                />
              ) : (
                <MenuItemWithIcon
                  key="update"
                  icon={<Update />}
                  text="Update example"
                  onClick={handleUpdateExampleWorkflow}
                />
              ),
              <MenuItemWithIcon
                key="delete"
                icon={<Delete />}
                text="Delete example"
                onClick={() =>
                  openExampleWorkflowsDialog({
                    title: 'Delete Example',
                    exampleWorkflow,
                    deleteExample: true,
                  })
                }
              />,
            ]
          ) : (
            <MenuItemWithIcon
              key="create"
              icon={<ExampleWorkflowIcon />}
              text="Save as example"
              onClick={() =>
                openExampleWorkflowsDialog({
                  title: 'Create Example',
                  exampleWorkflow,
                })
              }
            />
          ),
        ]
      : null;

  return (
    <Stack direction="row" alignItems="center">
      <MenuIconButton
        size="small"
        onClick={handleOpenMenu}
        icon={<KebabMenuIcon />}
        data-intercom-target={`${IntercomTourIDs.SIMULATION_OPTIONS}-simulation-menu`}
      />
      <DropdownMenu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        {simulation.status === 'COMPLETED' &&
          simulation.workflow.source !== WorkflowSourceEnum.FORM_EDITOR &&
          (preventTemplateCreation ? (
            <Tooltip title="Template creation disabled for multi-stage workflows">
              <span>
                <MenuItemWithIcon
                  icon={<TemplateWorkflowIcon />}
                  text="Save as template"
                  disabled
                />
              </span>
            </Tooltip>
          ) : (
            <MenuItemLink
              data-heap-tracking="simulation-save-as-template-button"
              to={templateWorkflowRoutes.createFromSimulation.getPath({
                simulationId: simulation.id,
              })}
            >
              <MenuItemWithIcon icon={<TemplateWorkflowIcon />} text="Save as template" />
            </MenuItemLink>
          ))}
        {areProtocolsEnabled && (
          <MenuItemLink
            to={protocolsRoutes.createFromSimulation.getPath({
              id: simulation.id,
            })}
          >
            <MenuItemWithIcon icon={<TemplateWorkflowIcon />} text="Create protocol" />
          </MenuItemLink>
        )}
        {showCreateDOEButton && (
          <MenuItemWithIcon
            icon={<DOEIcon />}
            text="Create DOE"
            onClick={handleCreateDOE}
          />
        )}
        <MenuItemWithIcon
          icon={<TalkToUsIcon />}
          text="Request help"
          onClick={closeMenuAndRequestHelp}
        />
        {userProfile?.isSynthaceEmployee && simulation.id && (
          <a key="grace" target="_blank" rel="noopener noreferrer" href={editInGracePath}>
            <MenuItemWithIcon icon={<GraceIcon />} text="Edit with Grace" />
          </a>
        )}
        {exampleWorkflowMenu}
      </DropdownMenu>
      {requestHelpDialog}
      {exampleWorkflowDialog}
      {updateExampleWorkflowDialog}
    </Stack>
  );
}

function DropdownMenu(props: MenuProps) {
  return (
    <Menu
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  );
}

const MenuIconButton = styled(IconButton)({
  color: Colors.GREY_80,
});

const MenuItemLink = styled(Link)({
  // We want to make each menu item look like normal a clickable row,
  // even though it's a hyperlink.
  textDecoration: 'none',
  color: 'inherit',
});
