import DOMPurify from 'dompurify';

/**
 * Take a potentially dirty URL and sanitize using DOMPurify.
 * It's a bit of an overkill since DOMPurify is usually used for the HTML itself, but
 * thought that this is a bit nicer than having a whitelisted option of URLs.
 */
export default function sanitizeLink(potentiallyDirtyURL: string) {
  const a = document.createElement('a');
  a.setAttribute('href', potentiallyDirtyURL);
  return (DOMPurify.sanitize(a, { IN_PLACE: true }) as unknown as HTMLLinkElement).href;
}
