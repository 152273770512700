import {
  getColumnNumberFromWellPosition,
  getRowNumberFromWellPosition,
} from 'common/lib/format';
import { Plate, WellLocationOnDeckItem } from 'common/types/mix';

/**
 * Given a list of wells e.g. ["A1", "C20" ..], return the WellLocationOnDeck[],
 * e.g. [{row: 0, col 0, ..}, {row: 2, col: 19, ..}, ..]
 *
 * Useful to convert human readable wells location to a standard type for well locations
 * on deck.
 *
 * @param selectedWells A list of wells, e.g. ["A1", "B2", ...].
 * @param plate The plate to which the wells belong.
 */
export default function toWellLocationsOnDeckItem(
  selectedWells: string[],
  plate: Plate,
): readonly WellLocationOnDeckItem[] {
  return selectedWells.map(selectedWell => {
    const row = getRowNumberFromWellPosition(selectedWell);
    const col = getColumnNumberFromWellPosition(selectedWell);
    return { row, col, deck_item_id: plate.id };
  });
}
