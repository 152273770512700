import React, { useCallback, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import ExpandMore from '@mui/icons-material/ArrowDropDown';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  QUERY_EXAMPLE_WORKFLOW,
  QUERY_SIMULATIONS_FOR_WORKFLOW,
} from 'client/app/api/gql/queries';
import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import SimulationCountInfo, {
  SIMULATION_COUNT_INFO_CONTAINER_HEIGHT,
} from 'client/app/apps/workflow-builder/panels/simulations/SimulationCountInfo';
import { deleteTooltip } from 'client/app/components/cards/common/WorkflowCardCommon';
import FavoriteStar from 'client/app/components/FavoriteStar';
import { SimulationStatusIndicatorWithTooltip } from 'client/app/components/SimulationStatusIndicator';
import {
  ArrayElement,
  simulationsForWorkflowQuery,
  WorkflowEditModeEnum,
  WorkflowsBySimulationsQuery,
  WorkflowsBySimulationsQueryVariables,
} from 'client/app/gql';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { simulationRoutes } from 'client/app/lib/nav/actions';
import getWorkflowPropsBySource from 'client/app/lib/workflow/getWorkflowPropsBySource';
import { ScreenRegistry } from 'client/app/registry';
import { formatDateTime, pluralize } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import {
  ENTITY_CARD_BORDER_RADIUS,
  ENTITY_CARD_HEIGHT,
  EntityCardContent,
} from 'common/ui/components/EntityCard';
import EntityCardExpandable, {
  EntityCardExpandableHeaderRow,
  EntityCardExpandableRow,
  EntityCardExpandableSkeletonRow,
} from 'common/ui/components/EntityCardExpandable';
import IconButton from 'common/ui/components/IconButton';
import RouteIconButton from 'common/ui/components/navigation/RouteIconButton';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { ExampleWorkflowIcon } from 'common/ui/icons';
import { EditIcon as EditWorkflowIcon } from 'common/ui/icons/Edit';

const MAX_SIMULATION_ROWS_TO_SHOW = 5;

type WorkflowsBySimulationsItem = ArrayElement<
  WorkflowsBySimulationsQuery['workflowsBySimulations']['items']
>;

type WorkflowExpandableCardProps = {
  workflow: WorkflowsBySimulationsItem;
  onDeleteSimulation: (
    simulationId: SimulationId,
    simulationName: string,
    workflowId: WorkflowId,
  ) => void;
  onRenameSimulation: (
    simulationId: SimulationId,
    simulationName: string,
    workflowId: WorkflowId,
  ) => void;
  workflowQueryVariables: WorkflowsBySimulationsQueryVariables;
};

export default function WorkflowCardExpandable(props: WorkflowExpandableCardProps) {
  const classes = useStyles();
  const userProfile = useUserProfile();
  const { workflow, onDeleteSimulation, onRenameSimulation, workflowQueryVariables } =
    props;

  const [getSimulationsForWorkflow, { data }] = useLazyQuery(
    QUERY_SIMULATIONS_FOR_WORKFLOW,
    {
      variables: { workflowId: workflow.id, ...workflowQueryVariables },
      fetchPolicy: 'cache-and-network',
    },
  );

  const [getExampleWorflow, { data: exampleWorkflowData }] = useLazyQuery(
    QUERY_EXAMPLE_WORKFLOW,
    {
      variables: {
        parentWorkflowId: workflow.id,
      },
    },
  );

  const [expanded, setExpanded] = React.useState(false);
  const onExpand = useCallback(() => {
    if (!expanded) {
      void getSimulationsForWorkflow();
      if (userProfile?.isExampleWorkflowsSourceOrg) void getExampleWorflow();
    }
    logEvent(
      'workflow-card-with-sims',
      ScreenRegistry.EXPERIMENTS,
      expanded ? 'collapse' : 'expand',
    );
    setExpanded(!expanded);
  }, [
    expanded,
    getExampleWorflow,
    getSimulationsForWorkflow,
    userProfile?.isExampleWorkflowsSourceOrg,
  ]);

  const deleteSimulationForWorkflow = useCallback(
    async (simulationId: SimulationId, simulationName: string) => {
      onDeleteSimulation(simulationId, simulationName, workflow.id);
    },
    [onDeleteSimulation, workflow.id],
  );
  const renameSimulationForWorkflow = useCallback(
    (simulationId: SimulationId, simulationName: string) =>
      onRenameSimulation(simulationId, simulationName, workflow.id),
    [onRenameSimulation, workflow.id],
  );

  const skeletonRowsToShow = useMemo(() => {
    const rowsToShow = [];
    const numOfRows = Math.min(workflow.allSimulationCount, MAX_SIMULATION_ROWS_TO_SHOW);
    for (let i = 1; i <= numOfRows; i++) {
      rowsToShow.push(<EntityCardExpandableSkeletonRow key={i} showIcon showStatus />);
    }

    return <>{rowsToShow}</>;
  }, [workflow.allSimulationCount]);

  const { EditorIcon } = getWorkflowPropsBySource(workflow.source);

  const actionText =
    workflow.editMode === WorkflowEditModeEnum.ENABLED_LATEST_OWNED_BY_ME
      ? 'Edit'
      : 'View';

  return (
    <EntityCardExpandable
      onClick={onExpand}
      entityCardContent={
        <>
          <EntityCardContent
            icon={<EditorIcon fontSize="small" />}
            nameColumn={{ label: 'Workflow name', value: workflow.name }}
            authorColumn={{ label: 'Author', value: workflow.createdBy.displayName }}
            dateColumn={{
              label: 'Last modified',
              value: new Date(workflow.lastModifiedAt),
            }}
          />
          <SimulationCountInfo
            className={classes.count}
            allSimulationCount={workflow.allSimulationCount}
            starredSimulationCount={workflow.starredSimulationCount}
          />
        </>
      }
      entityCardAction={<EditWorkflowButton workflow={workflow} />}
      entityCardActionText={actionText}
      entityCardCollapseContent={
        <>
          <EntityCardExpandableHeaderRow
            summaryTitle="Recent Simulations"
            authorTitle="Simulated by"
            dateTitle="Date Simulated"
            statusTitle="Status"
            additionalColumnTitle={workflow.hasDOEDesign ? 'Parts' : undefined}
          />
          {!data
            ? skeletonRowsToShow
            : data.simulationsForWorkflow.items.map(simulation => (
                <SimulationDetailRow
                  key={simulation.id}
                  simulation={simulation}
                  onDeleteSimulation={deleteSimulationForWorkflow}
                  onRenameSimulation={renameSimulationForWorkflow}
                  workflowId={workflow.id}
                  exampleWorkflowId={exampleWorkflowData?.exampleWorkflow?.workflowId}
                  workflowQueryVariables={workflowQueryVariables}
                  hasDesign={workflow.hasDOEDesign}
                />
              ))}
        </>
      }
    />
  );
}

function EditWorkflowButton({ workflow }: { workflow: WorkflowsBySimulationsItem }) {
  const { route } = getWorkflowPropsBySource(workflow.source);
  const openWorkflowIcon =
    workflow.editMode === WorkflowEditModeEnum.ENABLED_LATEST_OWNED_BY_ME ? (
      <EditWorkflowIcon />
    ) : (
      <PageviewOutlinedIcon />
    );
  const openWorkflowHelperText =
    workflow.editMode === WorkflowEditModeEnum.ENABLED_LATEST_OWNED_BY_ME
      ? 'Open Workflow'
      : 'Open Read-only Workflow';

  return (
    <RouteIconButton
      icon={openWorkflowIcon}
      route={route}
      routeParam={{ workflowId: workflow.id }}
      size="small"
      title={openWorkflowHelperText}
      logEventCategory={ScreenRegistry.EXPERIMENTS}
    />
  );
}

type SimulationsForWorkflowItem = ArrayElement<
  simulationsForWorkflowQuery['simulationsForWorkflow']['items']
>;

type PartsButtonProps = {
  simulation: SimulationsForWorkflowItem;
};

const SINGLE_PART_DOE_INDICATOR = 1;

function PartsButton({ simulation }: PartsButtonProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const parts = useMemo(
    () => [simulation, ...(simulation.simulationSeriesSiblings ?? [])],
    [simulation],
  );

  const navigation = useNavigation();

  return (
    <div className={classes.simulationParts}>
      {parts.length > 1 ? (
        <button
          className={classes.partsButton}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
        >
          {pluralize(parts.length, 'Part', 'Parts')}
          <ExpandMore className={classes.partsButtonIcon} />
        </button>
      ) : (
        <Typography color="textSecondary">{SINGLE_PART_DOE_INDICATOR}</Typography>
      )}
      {anchorEl && (
        <Menu
          PaperProps={{
            style: {
              maxHeight: '300px',
            },
          }}
          open
          onClose={(e: Event) => {
            setAnchorEl(null);

            // The event that triggered the close (e.g. backdrop click) propagates to
            // the row and triggers navigation, so we need to stop it propagating.
            e.stopPropagation?.();
          }}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          {parts.map(part => {
            return (
              <MenuItem
                data-heap-tracking="workflow-card-view-simulation-part-button"
                key={part.id}
                title="View Simulation"
                className={classes.partsListItem}
                onClick={e => {
                  e.stopPropagation();
                  setAnchorEl(null);

                  navigation.navigate(simulationRoutes.simulationDetailsSubscreen, {
                    simulationId: part.id,
                  });
                }}
              >
                <div className={classes.partName}>
                  <Typography variant="caption" className={classes.partNameIndicator}>
                    Part {part.simulationSeriesPart}
                  </Typography>
                </div>
                <SimulationStatusIndicatorWithTooltip status={part.status} />
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </div>
  );
}

type SimulationProps = {
  workflowId: WorkflowId;
  exampleWorkflowId?: WorkflowId;
  workflowQueryVariables: WorkflowsBySimulationsQueryVariables;
  simulation: SimulationsForWorkflowItem;
  onDeleteSimulation: (simulationId: SimulationId, simulationName: string) => void;
  onRenameSimulation: (simulationId: SimulationId, simulationName: string) => void;
  hasDesign: boolean;
};

function SimulationDetailRow(props: SimulationProps) {
  const {
    workflowId,
    exampleWorkflowId,
    simulation,
    onDeleteSimulation,
    onRenameSimulation,
    workflowQueryVariables,
    hasDesign,
  } = props;
  const classes = useStyles();

  const lastUpdatedAt = new Date(simulation.lastUpdatedAt ?? simulation.startedAt);
  const lastUpdatedDateTime = formatDateTime(lastUpdatedAt);

  // Can only delete a simulation if it is yours and has not been sent to the lab already.
  const userProfile = useUserProfile();
  const isOwnSimulation = userProfile?.id === simulation.user.id;
  const hasExecution = simulation.execution?.id ? true : false;
  const isSharedExternally = simulation.isSharedExternally ? true : false;
  const isDisabled = hasExecution || isSharedExternally;

  const handleDelete = useCallback(
    (event: React.MouseEvent) => {
      // Icon for delete is under <a> tag that causes redirect.
      // In order to prevent the redirect, the click event must not propagate up
      event.preventDefault();
      event.stopPropagation();
      onDeleteSimulation(simulation.id, simulation.name);
    },
    [onDeleteSimulation, simulation.id, simulation.name],
  );

  const handleRenameSimulation = useCallback(
    async (event: React.MouseEvent) => {
      // Icon for rename is under <a> tag that causes redirect.
      // In order to prevent the redirect, the click event must not propagate up
      event.preventDefault();
      event.stopPropagation();
      onRenameSimulation(simulation.id, simulation.name);
    },
    [onRenameSimulation, simulation.id, simulation.name],
  );

  const path = simulationRoutes.openInSimulationDetails.getPath({
    simulationId: simulation.id,
  });

  const handleClick = useCallback(() => {
    logEvent('open-simulation-details', ScreenRegistry.EXPERIMENTS);
  }, []);

  const isStatusIndeterminate = useMemo(() => {
    if (simulation.simulationSeriesSiblings) {
      return simulation.simulationSeriesSiblings.some(
        sim => sim.status !== simulation.status,
      );
    }

    return false;
  }, [simulation.simulationSeriesSiblings, simulation.status]);

  const showExampleWorflowIcon =
    userProfile?.isExampleWorkflowsSourceOrg &&
    exampleWorkflowId === simulation.workflow.id;

  return (
    <EntityCardExpandableRow
      heapTrackingLabel="SIMULATION"
      path={path}
      title="View Simulations"
      onClick={handleClick}
      icon={
        <FavoriteStar
          isFavoritedByCurrentUser={simulation.isFavoritedByCurrentUser}
          favoritedBy={simulation.favoritedBy}
          simulationId={simulation.id}
          size="small"
          associatedWorkflowId={workflowId}
          additionalVariables={workflowQueryVariables}
        />
      }
      exampleWorflowIcon={
        showExampleWorflowIcon ? (
          <ExampleWorkflowIcon className={classes.exampleWorkflowIcon} />
        ) : undefined
      }
      name={
        <>
          <Typography variant="body2" noWrap>
            {simulation.name}
          </Typography>
          {isOwnSimulation && (
            <Tooltip
              title="Edit simulation name"
              className={classes.simulationRenameIcon}
            >
              <IconButton
                size="small"
                icon={<EditOutlinedIcon />}
                onClick={handleRenameSimulation}
              />
            </Tooltip>
          )}
        </>
      }
      additionalColumnValue={
        hasDesign ? <PartsButton simulation={simulation} /> : undefined
      }
      author={simulation.user.displayName}
      date={lastUpdatedDateTime}
      status={
        <SimulationStatusIndicatorWithTooltip
          status={simulation.status}
          indeterminate={isStatusIndeterminate}
        />
      }
      action={
        isOwnSimulation ? (
          <Tooltip title={deleteTooltip(isSharedExternally, hasExecution)}>
            <IconButton
              onClick={isDisabled ? undefined : handleDelete}
              size="small"
              component="span"
              icon={<DeleteOutlineIcon />}
              className={classes.simulationDelete}
              disabled={isDisabled}
            />
          </Tooltip>
        ) : undefined
      }
    />
  );
}

export function WorklfowCardExpandableSkeletonList() {
  return (
    <List>
      <WorkflowCardExpandableSkeleton />
      <WorkflowCardExpandableSkeleton />
      <WorkflowCardExpandableSkeleton />
    </List>
  );
}

function WorkflowCardExpandableSkeleton() {
  return (
    <Skeleton
      sx={{ borderRadius: `${ENTITY_CARD_BORDER_RADIUS}px` }}
      variant="rounded"
      height={ENTITY_CARD_HEIGHT + SIMULATION_COUNT_INFO_CONTAINER_HEIGHT + 8} // 8 is from theme.spacing(3) to adjust for margin
      width="100%"
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  count: {
    margin: theme.spacing(0, 0, 3, '66px'),
  },
  exampleWorkflowIcon: {
    color: Colors.GREY_50,
  },
  simulationRenameIcon: {
    marginLeft: theme.spacing(3),
  },
  simulationParts: {
    gridColumn: 'additional',
    display: 'flex',
  },
  simulationDelete: {
    gridColumn: 'action',
    justifySelf: 'center',
    alignSelf: 'center',
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
  partsButton: {
    background: Colors.BLUE_5,
    borderRadius: theme.spacing(4),
    display: 'flex',
    padding: theme.spacing(2, 3, 2, 4),
    alignItems: 'center',
    border: 'none',
    ...theme.typography.caption,
    fontWeight: 500,
    gap: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      background: Colors.BLUE_10,
    },
  },
  partsButtonIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  partsList: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '250px',
    padding: theme.spacing(3, 0),
  },
  partsListItem: {
    display: 'flex',
    padding: theme.spacing(2, 4),
    gap: theme.spacing(4),
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      background: Colors.BLUE_0,
    },
  },
  partName: {
    flex: 1,
  },
  partNameIndicator: {
    background: Colors.BLUE_5,
    borderRadius: theme.spacing(4),
    fontWeight: 500,
    padding: theme.spacing(2, 3),
  },
}));

const List = styled('div')(({ theme }) => ({
  ...experimentsStyles(theme).list,
}));
