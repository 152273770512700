import React, { useCallback, useEffect, useState } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import normalizePath from 'client/app/lib/file-browser/normalizePath';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import Keys from 'common/ui/lib/keyboard';

type Props = {
  disabled: boolean;
  path: string;
  onPathChange: (path: string) => void;
};

const useStyles = makeStylesHook({
  root: {
    alignItems: 'center',
    display: 'flex',
    padding: '5px 0',
  },
  text: {
    padding: '8px',
  },
});

const PathInput = function (props: Props) {
  const { disabled, path, onPathChange } = props;
  const [tempPath, setTempPath] = useState(path);

  const classes = useStyles();

  useEffect(() => {
    setTempPath(path);
  }, [path, setTempPath]);

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setTempPath(normalizePath(value));
    },
    [setTempPath],
  );

  const onNotify = useCallback(() => {
    onPathChange(tempPath);
  }, [tempPath, onPathChange]);

  const onKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === Keys.ENTER) {
        onNotify();
      }
    },
    [onNotify],
  );

  return (
    <div className={classes.root}>
      <div className={classes.text}>
        <Typography>Open: </Typography>
      </div>
      <TextField
        variant="standard"
        fullWidth
        value={tempPath}
        disabled={disabled}
        onChange={onInputChange}
        onKeyUp={onKeyUp}
      />
      <IconButton onClick={onNotify} disabled={disabled} size="large">
        <ArrowForwardIcon />
      </IconButton>
    </div>
  );
};

export default PathInput;
