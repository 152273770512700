import React, { useMemo, useState } from 'react';

import ErrorIcon from '@mui/icons-material/ErrorOutlineRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion, { accordionClasses } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { paperClasses } from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { svgIconClasses } from '@mui/material/SvgIcon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import Helpers from 'client/app/apps/execution-details/ExecuteTab/Reagents/helpers';
import { StockConcentration } from 'client/app/apps/execution-details/types';
import { Reagent } from 'client/app/gql';
import { formatMeasurement, formatMeasurementObj } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import Tooltip from 'common/ui/components/Tooltip';

type Props = {
  reagent: Reagent;
  stockConcentrations: StockConcentration[];
};
export function ReagentAccordion({ reagent, stockConcentrations }: Props) {
  const [expanded, setExpanded] = useState(false);

  const secondaryLabel = Helpers.getDescription(reagent);

  const requiredVolumes = useMemo(
    () => Helpers.getRequiredSoluteVolumes(stockConcentrations, reagent),
    [stockConcentrations, reagent],
  );

  const remainingVolume = useMemo(
    () => Helpers.getDiluentVolume(reagent, requiredVolumes),
    [reagent, requiredVolumes],
  );

  const hasSolutes = reagent.solutes.length > 0;
  const hasTags = reagent.tags.length > 0;
  const hasError = remainingVolume < 0;
  const volumeCopy = formatMeasurement(reagent.volumeUl, 'ul');

  const reagentInfo = (
    <Stack direction="row" alignItems="center" gap={5} flexGrow={1}>
      {hasError && (
        <Tooltip
          title={
            <Stack p={3} gap={2}>
              <Typography variant="caption" component="p">
                Concentration is too low for this stock.
              </Typography>
              <Typography variant="caption" component="p">
                Please, increase it in the Stocks tab.
              </Typography>
            </Stack>
          }
        >
          <ErrorIcon color="error" fontSize="small" />
        </Tooltip>
      )}
      <ReagentName
        variant="subtitle1"
        color={hasError ? 'error' : 'textPrimary'}
        title={reagent.name}
      >
        {reagent.name}
      </ReagentName>
      <InlineCopy variant="body1" color="textPrimary" title={secondaryLabel}>
        {secondaryLabel}
      </InlineCopy>
      <InlineCopy variant="body1" color="textPrimary" title={volumeCopy}>
        {volumeCopy}
      </InlineCopy>
    </Stack>
  );

  if (hasError) {
    return <ErrorBox>{reagentInfo}</ErrorBox>;
  }
  if (!hasSolutes && !hasTags) {
    return <ReagentBox>{reagentInfo}</ReagentBox>;
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={(_: React.ChangeEvent<{}>, isExpanded: boolean) =>
        setExpanded(isExpanded)
      }
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{reagentInfo}</AccordionSummary>
      <AccordionDetails>
        {hasSolutes && (
          <Stack gap={2}>
            <Typography variant="subtitle2">Contents</Typography>
            <Table size="small" aria-label="contents">
              <TableHead>
                <TableRow>
                  <TableCell>Stock</TableCell>
                  <TableCell align="right">Target</TableCell>
                  <TableCell align="right">Volume</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reagent.solutes.map(solute => (
                  <TableRow key={solute.name}>
                    <TableCell component="th" scope="row">
                      {`${solute.name} @\u00A0${formatMeasurementObj(
                        Helpers.getConcentration(
                          stockConcentrations,
                          solute.name,
                          solute.concentration.unit,
                        )!.concentration,
                      )}`}
                    </TableCell>
                    <TableCell align="right">
                      {formatMeasurementObj(solute.concentration)}
                    </TableCell>
                    <TableCell align="right">
                      {formatMeasurementObj(requiredVolumes.get(solute)!)}
                    </TableCell>
                  </TableRow>
                ))}
                {remainingVolume > 0 && (
                  <TableRow key="_diluent">
                    <TableCell component="th" scope="row">
                      {reagent.solutes.length ? 'Diluent' : reagent.name}
                    </TableCell>
                    <TableCell align="right" />
                    <TableCell align="right">
                      {formatMeasurement(remainingVolume, 'ul')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Stack>
        )}
        {hasTags && (
          <Stack gap={2}>
            <Typography variant="subtitle2">Metadata</Typography>
            <Table size="small" aria-label="contents">
              <TableHead>
                <TableRow>
                  <TableCell>Label</TableCell>
                  <TableCell align="right">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reagent.tags.map(tag => (
                  <TableRow key={tag.label}>
                    <TableCell component="th" scope="row">
                      {tag.label}
                    </TableCell>
                    <TableCell align="right">
                      {tag.valueFloat ?? (tag.valueString || 'n/a')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,

  [`&.${paperClasses.root}`]: {
    borderRadius: 0,
  },
  [`&.${accordionClasses.expanded}`]: {
    margin: 0,
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  gap: theme.spacing(5),

  padding: theme.spacing(2, 6),

  [`& .${svgIconClasses.root}`]: {
    color: theme.palette.text.primary,
  },
}));

const ErrorBox = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(5, 6),
  border: `1px solid ${theme.palette.error.main}`,
  backgroundColor: Colors.WHITE,
}));

const ReagentBox = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(5, 6, 5, 11),
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: Colors.WHITE,
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),

  padding: theme.spacing(6),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const InlineCopy = styled(Typography)({
  flex: 3,
  width: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const ReagentName = styled(InlineCopy)({
  flex: 4,
});
