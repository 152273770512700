import React from 'react';

import { DOEDoubleMapEditorButton } from 'client/app/components/Parameters/DOEDoubleMap/DOEDoubleMapDialog';
import {
  DoubleMapParameterValue,
  FactorType,
} from 'client/app/components/Parameters/DOEDoubleMap/doeDoubleMapUtils';
import DOEMixRulesEditor from 'client/app/components/Parameters/DOEMixRulesEditor';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { Parameter } from 'common/types/bundle';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type DOEDoubleMapEditorProps = {
  parameter?: Parameter;
  instanceName?: string;
  isDisabled?: boolean;
  onChange: (param: DoubleMapParameterValue | undefined, instanceName?: string) => void;
  value: DoubleMapParameterValue;
};

/**
 * Launches a dialog for setting up constants and factors to define within DOE.
 * Combinations of those constants and factors will be picked by the DOE design.
 * Specifically, this editor is launched from Define Mix Set Plan in the DOE
 * Template Editor.
 */
export default function DOEDoubleMapEditor({
  parameter,
  instanceName,
  isDisabled,
  onChange,
  value,
}: DOEDoubleMapEditorProps) {
  const classes = useStyles();

  if (useFeatureToggle('DOE_DOUBLE_MAP_EDITOR')) {
    switch (parameter?.name) {
      case 'MixtureDefinitions':
        return (
          <DOEDoubleMapEditorButton
            instanceName={instanceName}
            isDisabled={isDisabled}
            onChange={onChange}
            value={value}
            primaryKeyType="github.com/Synthace/antha/stdlib/schemas/aliases.MixtureName"
            secondaryKeyType="github.com/Synthace/antha/stdlib/schemas/aliases.ComponentName"
            valueType="github.com/Synthace/antha/antha/anthalib/wunit.VolumeOrConcentration"
            title="Set Factors for Mixture Definitions"
            // Constants
            constantSectionTitle="Constants"
            constantSectionInfo="These define components whose volumes or concentrations do not vary between mixtures (i.e., between DOE runs). These are not considered factors of a DOE design."
            constantNameLabel="Component Name"
            constantValueLabel="Volume or Concentration"
            // Factors
            factorSectionTitle="Factors"
            factorSectionInfo="These define single components or groups of components that vary between mixtures (i.e., between DOE runs) in volume, concentration, and/or composition. In each mixture, these will take on values determined by the DOE design, constrained by the &ldquo;levels&rdquo; provided here."
            factorTypes={{
              [FactorType.VALUE]: 'Volume or Concentration',
              [FactorType.NAMEVALUE]: 'Component at Volume or Concentration',
              [FactorType.NAMEVALUESET]: 'Mixture',
            }}
            valuesLabel="Either Volumes or Concentrations (but not both)"
            nameValuesLabel="Component-Volume or Component-Concentration Pairs"
            nameValueSetsLabel="Mixtures"
            nameValueSetFactorName="Mixture Definitions"
            factorTooltip={
              <dl className={classes.factorTypeTooltip}>
                <dt>
                  <strong>Volume or Concentration:</strong>
                </dt>
                <dd>
                  Corresponding factors represent single mixture components, each of a
                  particular type, with varying volume or concentration. <br />
                  E.g.,
                  <blockquote>
                    <b>Factor:</b> NaCl <br />
                    <b>Level 1:</b> 0 mM <br />
                    <b>Level 2:</b> 50 mM
                  </blockquote>
                </dd>
                <dt>
                  <strong>Component at Volume or Concentration:</strong>
                </dt>
                <dd>
                  Corresponding factors represent single mixture components that vary in
                  both component type and volume or concentration. <br />
                  E.g.,
                  <blockquote>
                    <b>Factor:</b> Detergent <br />
                    <b>Level 1:</b> 100mM CHAPS at 2 ul <br />
                    <b>Level 2:</b> Tween-20 at 0.002% w/v <br />
                    <b>Level 3:</b> NP-40 at 0.003% w/v
                  </blockquote>
                </dd>
                <dt>
                  <strong>Mixture:</strong>
                </dt>
                <dd>
                  The corresponding factor represents a (sub-)mixture that varies in
                  composition---i.e., varies in combinations of component-volume or
                  component-concentration pairs. Only one factor of this type is allowed
                  here. <br />
                  E.g.,
                  <blockquote>
                    <b>Factor:</b> Mixture Definitions <br />
                    <b>Level 1:</b> Gin at 33% v/v, Sweet Vermouth at 33% v/v, Campari at
                    25% v/v
                    <br />
                    <b>Level 2:</b> Rye at 66% v/v, Sweet Vermouth at 33% v/v, Bitters at
                    5 ul
                  </blockquote>
                </dd>
              </dl>
            }
          />
        );

      case 'Compositions':
        return (
          <DOEDoubleMapEditorButton
            instanceName={instanceName}
            isDisabled={isDisabled}
            onChange={onChange}
            value={value}
            primaryKeyType="github.com/Synthace/antha/stdlib/schemas/aliases.MixtureName"
            secondaryKeyType="github.com/Synthace/antha/stdlib/schemas/aliases.ComponentName"
            valueType="github.com/Synthace/antha/antha/anthalib/wunit.VolumeOrConcentration"
            title="Set Factors for Mixture Compositions"
            // Constants
            constantSectionTitle="Constants"
            constantSectionInfo="These define components whose volumes or concentrations do not vary between mixtures (i.e., between DOE runs). These are not considered factors of a DOE design."
            constantNameLabel="Liquid Name"
            constantValueLabel="Volume or Concentration"
            // Factors
            factorSectionTitle="Factors"
            factorSectionInfo="These define single components or groups of components that vary between mixtures (i.e., between DOE runs) in volume, concentration, and/or composition. In each mixture, these will take on values determined by the DOE design, constrained by the &ldquo;levels&rdquo; provided here."
            factorTypes={{
              [FactorType.VALUE]: 'Volume or Concentration',
              [FactorType.NAMEVALUE]: 'Component at Volume or Concentration',
              [FactorType.NAMEVALUESET]: 'Mixture',
            }}
            valuesLabel="Either Volumes or Concentrations (but not both)"
            nameValuesLabel="Component-Volume or Component-Concentration Pairs"
            nameValueSetsLabel="Mixtures"
            nameValueSetFactorName="Mixture Compositions"
            factorTooltip={
              <dl className={classes.factorTypeTooltip}>
                <dt>
                  <strong>Volume or Concentration:</strong>
                </dt>
                <dd>
                  Corresponding factors represent single mixture components, each of a
                  particular type, with varying volume or concentration. <br />
                  E.g.,
                  <blockquote>
                    <b>Factor:</b> NaCl <br />
                    <b>Level 1:</b> 0 mM <br />
                    <b>Level 2:</b> 50 mM
                  </blockquote>
                </dd>
                <dt>
                  <strong>Liquid at Volume or Concentration:</strong>
                </dt>
                <dd>
                  Corresponding factors represent single mixture components that vary in
                  both liquid type and volume or concentration. <br />
                  E.g.,
                  <blockquote>
                    <b>Factor:</b> Detergent <br />
                    <b>Level 1:</b> 100mM CHAPS at 2 ul <br />
                    <b>Level 2:</b> Tween-20 at 0.002% w/v <br />
                    <b>Level 3:</b> NP-40 at 0.003% w/v
                  </blockquote>
                </dd>
                <dt>
                  <strong>Mixture:</strong>
                </dt>
                <dd>
                  The corresponding factor represents a (sub-)mixture that varies in
                  composition---i.e., varies in combinations of component-volume or
                  component-concentration pairs. Only one factor of this type is allowed
                  here. <br />
                  E.g.,
                  <blockquote>
                    <b>Factor:</b> Mixture Compositions <br />
                    <b>Level 1:</b> Gin at 33% v/v, Sweet Vermouth at 33% v/v, Campari at
                    25% v/v
                    <br />
                    <b>Level 2:</b> Rye at 66% v/v, Sweet Vermouth at 33% v/v, Bitters at
                    5 ul
                  </blockquote>
                </dd>
              </dl>
            }
          />
        );

      case 'AssemblyDefinitions':
        return (
          <DOEDoubleMapEditorButton
            instanceName={instanceName}
            isDisabled={isDisabled}
            onChange={onChange}
            value={value}
            primaryKeyType="string"
            secondaryKeyType="github.com/Synthace/antha/stdlib/schemas/aliases.ComponentName"
            valueType="string"
            title="Set Factors for Assembly Definitions"
            // Constants
            constantSectionTitle="Constants"
            constantSectionInfo="These define the assembly components whose corresponding DNA part is the same in each assembly (DOE run). These are not considered factors of a DOE design. (Assembly components must be identified as &ldquo;Vector&rdquo; or &ldquo;Insert 1&rdquo;, &ldquo;Insert 2&rdquo;, etc.)"
            constantNameLabel="Assembly Component"
            constantValueLabel="DNA Part Name"
            // Factors
            factorSectionTitle="Factors"
            factorSectionInfo="These define the assembly components whose corresponding DNA part varies between assemblies (i.e., between DOE runs). The part chosen for each assembly will be determined by the DOE design, from the part options provided here. (Assembly components must be identified as &ldquo;Vector&rdquo; or &ldquo;Insert 1&rdquo;, &ldquo;Insert 2&rdquo;, etc.)"
            factorTypes={{
              [FactorType.VALUE]: 'DNA Part',
              // [FactorType.NameValue]: 'Assembly Component of DNA Part', // Not used; a DOE of this type doesn't make much sense.
              // [FactorType.NameValueSet]: 'Mixture of Assembly Components', // Not used; a DOE of this type doesn't make much sense.
            }}
            valuesLabel="DNA Part Names"
            nameValuesLabel="Assembly Component-DNA Part Pairs" // Not used; a DOE of this type doesn't make much sense.
            nameValueSetsLabel="Assembly Component Mixtures" // Not used; a DOE of this type doesn't make much sense.
            nameValueSetFactorName="Assembly Definitions" // Not used; a DOE of this type doesn't make much sense.
            factorTooltip={
              <dl className={classes.factorTypeTooltip}>
                <dt>
                  <strong>DNA Part:</strong>
                </dt>
                <dd>
                  Corresponding factors represent individual assembly components (i.e.,
                  one of &ldquo;Vector&rdquo;, &ldquo;Insert 1&rdquo;, &ldquo;Insert
                  2&rdquo;, etc.) with varying DNA parts. <br />
                  E.g.,
                  <blockquote>
                    <b>Factor:</b> Insert 1 <br />
                    <b>Level 1:</b> RBS_weak <br />
                    <b>Level 2:</b> RBS_medium <br />
                    <b>Level 3:</b> RBS_strong
                  </blockquote>
                </dd>
              </dl>
            }
          />
        );

      case 'LiquidPolicyOverrides':
        return (
          <DOEDoubleMapEditorButton
            instanceName={instanceName}
            isDisabled={isDisabled}
            onChange={onChange}
            value={value}
            primaryKeyType="github.com/Synthace/antha/stdlib/schemas/aliases.MixtureName"
            secondaryKeyType="github.com/Synthace/antha/stdlib/schemas/aliases.LiquidName"
            valueType="github.com/Synthace/antha/antha/anthalib/wtype.PolicyName"
            title="Set Factors for Liquid Policy Overrides"
            // Constants
            constantSectionTitle="Constants"
            constantSectionInfo="These define liquids with policy overrides that do not vary between DOE runs. These are not considered factors of a DOE design."
            constantNameLabel="Liquid Name"
            constantValueLabel="Policy"
            // Factors
            factorSectionTitle="Factors"
            factorSectionInfo="These define single liquids or groups of liquids that vary between DOE runs in policy and/or composition. In each DOE run, these will take on values determined by the DOE design, constrained by the &ldquo;levels&rdquo; provided here."
            factorTypes={{
              [FactorType.VALUE]: 'Policy',
              [FactorType.NAMEVALUE]: 'Liquid with Policy',
              [FactorType.NAMEVALUESET]: 'Set of Liquids with Policies',
            }}
            valuesLabel="Policy"
            nameValuesLabel="Liquid-with-Policy Pairs"
            nameValueSetsLabel="Sets of Liquid-with-Policy Pairs"
            nameValueSetFactorName="Liquid Policy Overrides"
            factorTooltip={
              <dl className={classes.factorTypeTooltip}>
                <dt>
                  <strong>Policy:</strong>
                </dt>
                <dd>
                  Corresponding factors represent single liquids, each of a particular
                  type, with varying policy. <br />
                  E.g.,
                  <blockquote>
                    <b>Factor:</b> Cell Solution <br />
                    <b>Level 1:</b> PreMix <br />
                    <b>Level 2:</b> Culture
                  </blockquote>
                </dd>
                <dt>
                  <strong>Liquid with Policy:</strong>
                </dt>
                <dd>
                  Corresponding factors represent single liquids that vary in both its
                  liquid type and its policy. <br />
                  E.g.,
                  <blockquote>
                    <b>Factor:</b> Additive to Mix Well <br />
                    <b>Level 1:</b> Additive A with SmartMix <br />
                    <b>Level 2:</b> Additive B with SmartMix <br />
                    <b>Level 3:</b> Additive A with MegaMix
                  </blockquote>
                </dd>
                <dt>
                  <strong>Set of Liquids with Policies:</strong>
                </dt>
                <dd>
                  The corresponding factor represents a grouping of varying combinations
                  of liquid-policy pairs. Only one factor of this type is allowed here.
                  <br />
                  E.g.,
                  <blockquote>
                    <b>Factor:</b> Liquid Policy Overrides <br />
                    <b>Level 1:</b> Cell Solution with PreMix, Glycerol Solution with
                    PostMix
                    <br />
                    <b>Level 2:</b> Cell Solution with Culture, Glycerol Solution with
                    SmartMix
                  </blockquote>
                </dd>
              </dl>
            }
          />
        );

      case 'InsertTargetConcentrations':
        return (
          <DOEDoubleMapEditorButton
            instanceName={instanceName}
            isDisabled={isDisabled}
            onChange={onChange}
            value={value}
            primaryKeyType="string"
            secondaryKeyType="string"
            valueType="github.com/Synthace/antha/antha/anthalib/wunit.Concentration"
            title="Set Factors for Insert Target Concentrations"
            // Constants
            constantSectionTitle="Constants"
            constantSectionInfo="These define the insert DNA parts whose concentrations do not vary between assemblies (i.e, between DOE runs). These are not considered factors of a DOE design."
            constantNameLabel="DNA Part Name"
            constantValueLabel="Concentration"
            // Factors
            factorSectionTitle="Factors"
            factorSectionInfo="These define the insert DNA parts whose concentration varies between assemblies (i.e., between DOE runs). The concentration chosen for each assembly will be determined by the DOE design, constrained by the concentraions provided here."
            factorTypes={{
              [FactorType.VALUE]: 'Concentration',
              // [FactorType.NameValue]: 'DNA Part at Concentration', // Not used; a DOE of this type doesn't make much sense.
              // [FactorType.NameValueSet]: 'Mixture of DNA Parts', // Not used; a DOE of this type doesn't make much sense.
            }}
            valuesLabel="Concentrations"
            nameValuesLabel="DNA Part-Concentration Pairs" // Not used; a DOE of this type doesn't make much sense.
            nameValueSetsLabel="DNA Part Mixtures" // Not used; a DOE of this type doesn't make much sense.
            nameValueSetFactorName="Insert Target Concentrations" // Not used; a DOE of this type doesn't make much sense.
            factorTooltip={
              <dl className={classes.factorTypeTooltip}>
                <dt>
                  <strong>Concentration:</strong>
                </dt>
                <dd>
                  Corresponding factors represent particular DNA parts with varying
                  concentration. Use the part name &ldquo;default&rdquo; to refer to all
                  insert DNA parts in a given assembly. <br />
                  E.g.,
                  <blockquote>
                    <b>Factor:</b> Insert 1 Part <br />
                    <b>Level 1:</b> 5 ng/ul <br />
                    <b>Level 2:</b> 10 ng/ul
                  </blockquote>
                </dd>
                {/* <dt><strong>DNA Part at Concentration:</strong></dt>
                <dd>
                  Corresponding factors represent inserts that vary in both DNA part and
                  concentration.
                </dd>
                <dt><strong>Mixture of DNA Parts:</strong></dt>
                <dd>
                  The corresponding factor represents a mixture of inserts that varies in
                  composition---i.e., varies in combinations of DNA part-concentration
                  pairs. Only one factor of this type is allowed here.
                </dd> */}
              </dl>
            }
          />
        );

      case 'EnzymeTargetConcentrations':
        return (
          <DOEDoubleMapEditorButton
            instanceName={instanceName}
            isDisabled={isDisabled}
            onChange={onChange}
            value={value}
            primaryKeyType="string"
            secondaryKeyType="string"
            valueType="github.com/Synthace/antha/antha/anthalib/wunit.Concentration"
            title="Set Factors for Enzyme Target Concentrations"
            // Constants
            constantSectionTitle="Constants"
            constantSectionInfo="These define enzymes whose concentrations do not vary between assemblies (i.e, between DOE runs). These are not considered factors of a DOE design."
            constantNameLabel="Enzyme Name"
            constantValueLabel="Concentration"
            // Factors
            factorSectionTitle="Factors"
            factorSectionInfo="These define single enzymes or groups of enzymes that vary between assemblies (i.e., between DOE runs) in concentration and/or composition. In each assembly, these will take on values determined by the DOE design, constrained by the &ldquo;levels&rdquo; provided here."
            factorTypes={{
              [FactorType.VALUE]: 'Concentration',
              [FactorType.NAMEVALUE]: 'Enzyme at Concentration',
              [FactorType.NAMEVALUESET]: 'Enzyme Mixture',
            }}
            valuesLabel="Concentrations"
            nameValuesLabel="Enzyme-Concentration Pairs"
            nameValueSetsLabel="Enzyme Mixtures"
            nameValueSetFactorName="Enzyme Target Concentrations"
            factorTooltip={
              <dl className={classes.factorTypeTooltip}>
                <dt>
                  <strong>Concentration:</strong>
                </dt>
                <dd>
                  Corresponding factors represent single enzymes, each of a particular
                  type, with varying concentration. <br />
                  E.g.,
                  <blockquote>
                    <b>Factor:</b> SapI <br />
                    <b>Level 1:</b> 250 U/ml <br />
                    <b>Level 2:</b> 500 U/ml
                  </blockquote>
                </dd>
                <dt>
                  <strong>Enzyme at Concentration:</strong>
                </dt>
                <dd>
                  Corresponding factors represent single enzymes that vary in both enzyme
                  type and concentration. <br />
                  E.g.,
                  <blockquote>
                    <b>Factor:</b> Carrier Plasmid Enzyme <br />
                    <b>Level 1:</b> BsaI at 500 U/ml <br />
                    <b>Level 2:</b> Bsa1-HF 200 U/ml
                  </blockquote>
                </dd>
                <dt>
                  <strong>Enzyme Mixture:</strong>
                </dt>
                <dd>
                  The corresponding factor represents a enzyme mixture that varies in
                  composition---i.e., varies in combinations of enzyme type-concentration
                  pairs. Only one factor of this type is allowed here. <br />
                  E.g.,
                  <blockquote>
                    <b>Factor:</b> Enzyme Target Concentrations <br />
                    <b>Level 1:</b> SapI at 100 U/ml, BsaI at 100 U/ml
                    <br />
                    <b>Level 2:</b> SapI at 500 U/ml, BsaI at 500 U/ml
                  </blockquote>
                </dd>
              </dl>
            }
          />
        );
    }
  }

  // If feature toggle is off or we haven't set up Double Map editor for this parameter,
  // use the legacy editor
  return (
    <DOEMixRulesEditor
      valueDict={value}
      onChange={onChange}
      instanceName={instanceName}
      isDisabled={isDisabled}
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  factorTypeTooltip: {
    '& dd': {
      margin: theme.spacing(3, 0),
    },
  },
}));
