import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { MUTATION_SCHEDULE_EXECUTION_STAGE } from 'client/app/api/gql/mutations';
import { ExecutionStageID } from 'common/types/execution';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export default function useScheduleExecutionStage() {
  const [scheduleExecutionStage, { loading }] = useMutation(
    MUTATION_SCHEDULE_EXECUTION_STAGE,
  );
  const { showError } = useSnackbarManager();

  const scheduleExecutionStageCallback = useCallback(
    async (executionStageId: ExecutionStageID) => {
      await scheduleExecutionStage({
        variables: {
          executionStageId,
        },
        onError(error) {
          showError(error.message);
        },
      });
    },
    [showError, scheduleExecutionStage],
  );

  return [scheduleExecutionStageCallback, { scheduling: loading }] as const;
}
