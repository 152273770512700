import React from 'react';

import ButtonBase from '@mui/material/ButtonBase';

import HelpIcon from 'client/app/icons/Help';
import { TOP_NAV_MENU_ID } from 'client/app/registry';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

export default function BreadcrumbsDocsButton() {
  const onClickGuides = () => {
    logEvent('open-help-and-docs', TOP_NAV_MENU_ID);
  };

  return (
    <Tooltip title="Help Center">
      <ButtonBase
        data-heap-tracking="breadcrumbs-docs-button"
        component="a"
        onClick={onClickGuides}
        href="https://intercom.help/antha/en"
        target="_blank"
        rel="noreferrer"
      >
        <HelpIcon />
      </ButtonBase>
    </Tooltip>
  );
}
