import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { MUTATION_DELETE_FILES_FROM_UPLOAD } from 'client/app/api/gql/mutations';
import { LabwareFile } from 'client/app/apps/execution-details/types';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export default function useDeleteLabwareFile() {
  const { showError } = useSnackbarManager();
  const [deleteFilesFromUpload] = useMutation(MUTATION_DELETE_FILES_FROM_UPLOAD);
  return useCallback(
    async (file: LabwareFile) => {
      if (!file.originFile || !file.allowDelete) return;

      try {
        await deleteFilesFromUpload({
          variables: {
            uploadId: file.originFile.upload.id,
            fileIds: [file.originFile.id],
          },
        });
      } catch (error) {
        showError(error.message);
        throw error;
      }
    },
    [deleteFilesFromUpload, showError],
  );
}
