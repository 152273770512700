import React from 'react';

import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { formatDateTime } from 'common/lib/format';
import { stringToMarkdown } from 'common/lib/markdown';
import { FormattedSimulationError } from 'common/types/simulation_types';
import { MarkdownPreview } from 'common/ui/components/MarkdownPreview';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  triggeredAt: Date;
  errors: FormattedSimulationError[];
};

export default function SimulationError({ errors, triggeredAt }: Props) {
  const classes = useStyles();
  return (
    <>
      {errors && (
        <>
          <Typography variant="caption" color="textSecondary">
            {formatDateTime(triggeredAt)}
          </Typography>
          <div className={classes.container}>
            {errors.map((error, index) => (
              <div key={`${index}`}>
                <Typography
                  className={classes.headerBottomSpacing}
                  variant="h4"
                  color="error"
                >
                  Summary
                </Typography>
                {error.message &&
                  (error.message_type === 'markdown' ? (
                    <MarkdownPreview
                      className={classes.errorPreview}
                      markdown={stringToMarkdown(error.message)}
                    />
                  ) : (
                    <Typography
                      className={classes.textPreWrap}
                      variant="body2"
                      color="textSecondary"
                    >
                      {error.message}
                    </Typography>
                  ))}
                {error.details && (
                  <>
                    {/** Only show the Details heading if we have error.details and error.message, otherwise we will
                     * show the error.details under Summary heading. */}
                    {error.message && (
                      <>
                        <Typography
                          className={cx(
                            classes.headerBottomSpacing,
                            classes.headerTopSpacing,
                          )}
                          variant="h4"
                          color="error"
                        >
                          Details
                        </Typography>
                        {error.message_type === 'markdown' ? (
                          <MarkdownPreview
                            className={classes.errorPreview}
                            markdown={stringToMarkdown(error.details)}
                          />
                        ) : (
                          <Typography
                            className={classes.textPreWrap}
                            variant="body2"
                            color="textSecondary"
                          >
                            {error.details}
                          </Typography>
                        )}
                      </>
                    )}
                  </>
                )}
                {error.code && (
                  <Typography
                    className={cx(classes.headerBottomSpacing, classes.headerTopSpacing)}
                    variant="body2"
                    color="textSecondary"
                  >
                    Error code: {error.code}
                  </Typography>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  wide: {
    width: '444px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    flex: 1,
  },
  container: {
    overflowWrap: 'break-word',
    margin: theme.spacing(5, 0),
  },
  headerBottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  headerTopSpacing: {
    marginTop: theme.spacing(6),
  },
  textPreWrap: {
    whiteSpace: 'pre-wrap',
  },
  errorPreview: {
    '& h1': {
      margin: '18px 0',
      ...theme.typography.h4,
    },
    '& h2': {
      margin: '16px 0',
      ...theme.typography.h5,
    },
    '& h3': {
      margin: '14px 0',
      ...theme.typography.h6,
    },
  },
}));
