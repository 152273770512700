import React, { useCallback } from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';

import { PlateFormFields } from 'client/app/apps/plate-constructor/PlateFormFields';

// TextField where the id must be one of PlateFormFields and blur/focus toggle field selection
export function PlateFormTextField(
  props: TextFieldProps & {
    id: PlateFormFields;
    onSelectField?: (id?: PlateFormFields) => void;
  },
) {
  const { id, onSelectField, ...otherProps } = props;
  const onFocus = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!onSelectField) {
        return;
      }
      // We know for sure it is a PlateFormField as the Field id has to be a PlateFormField
      // But TS does not know.
      onSelectField(e.target.id as PlateFormFields);
    },
    [onSelectField],
  );
  const onBlur = useCallback(
    (_: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!onSelectField) {
        return;
      }
      onSelectField();
    },
    [onSelectField],
  );
  return (
    <TextField
      {...otherProps}
      onFocus={onFocus}
      onBlur={onBlur}
      id={id as string}
      variant="standard"
    />
  );
}
