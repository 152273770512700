import React, { useRef } from 'react';

import { DOEList } from 'client/app/apps/experiments/DOE/DOEList';
import { ExperimentsNavigation } from 'client/app/apps/experiments/ExperimentsNavigation';
import ListPageHeader from 'client/app/apps/experiments/ListPageHeader';
import { useUserList } from 'client/app/apps/experiments/useUserList';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { responseAnalysisRoutes } from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import FilterChipWithAutocomplete from 'common/ui/components/FilterChip/FilterChipWithAutocomplete';
import RouteButton from 'common/ui/components/navigation/RouteButton';
import SearchField from 'common/ui/components/SearchField';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

export function DOETemplatesScreen() {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const isDOEAlphaEnabled = useFeatureToggle('DOE_ALPHA');

  const usersDropdownOptions = useUserList();
  const [searchQuery, setSearchQuery] = useStateWithURLParams({
    paramName: 'search',
    paramType: 'string',
    defaultValue: '',
  });
  const currentUserId = useUserProfile()?.id;
  const [filterUserId, setFilterUserId] = useStateWithURLParams({
    paramName: 'user',
    paramType: 'string',
    defaultValue: currentUserId,
    emptyValue: 'all',
  });
  const onFilterUser = (id?: string) => {
    logEvent('filter-doe-workflows-by-user', ScreenRegistry.EXPERIMENTS);
    setFilterUserId(id);
  };

  return (
    <ExperimentsNavigation title="DOE Templates">
      <>
        <ListPageHeader
          left={
            <FilterChipWithAutocomplete
              heading="Filter by Author"
              defaultChipLabel="Author"
              dropdownOptions={usersDropdownOptions}
              filterValue={filterUserId}
              onFilter={onFilterUser}
            />
          }
          right={
            <>
              {isDOEAlphaEnabled && (
                <RouteButton
                  route={responseAnalysisRoutes.base}
                  routeParam={undefined}
                  label="Launch Response Analysis"
                  variant="outlined"
                  color="primary"
                  size="small"
                />
              )}
              <SearchField
                label="Search"
                defaultValue={searchQuery}
                onQueryChange={setSearchQuery}
              />
            </>
          }
        />
        <ContainerWithIntersectionBar
          scrollableRef={scrollableRef}
          content={
            <DOEList
              filterUserId={filterUserId}
              scrollableRef={scrollableRef}
              searchQuery={searchQuery}
            />
          }
          dense
          noHeader
        />
      </>
    </ExperimentsNavigation>
  );
}
