import { interpolateConfiguredNames } from 'client/app/lib/workflow/format';
import { getObjectFriendlyName } from 'common/lib/format';
import { Markdown, stringToMarkdown } from 'common/lib/markdown';
import { Element, Parameter } from 'common/types/bundle';
import { ElementConfigurationSpec } from 'common/types/elementConfiguration';

export function getElementDisplayName(
  element: Element,
  isDebugModeEnabled: boolean,
): string {
  const elementName = getObjectFriendlyName(element.name);
  const configElementName = element.configuration?.elementDisplayName;

  if (!configElementName) {
    return elementName;
  }
  if (isDebugModeEnabled) {
    return `${configElementName} (${elementName})`;
  }
  return configElementName;
}

export function getParameterDisplayName(
  parameter: Parameter,
  isDebugModeEnabled: boolean,
): string {
  if (!parameter.configuration?.displayName) {
    return parameter.name;
  }
  const { displayName } = parameter.configuration;
  // The parameter config object is filled in for every parameter on a configured
  // element, even if the parameter has had none of its details changed.
  if (isDebugModeEnabled && displayName !== parameter.name) {
    return `${displayName} (${parameter.name})`;
  }
  return displayName;
}

export function getElementDisplayDescription(
  element: Element,
  elementConfigs?: Record<string, ElementConfigurationSpec | null>,
): Markdown {
  // elementConfigs are optional here - if they don't exist, we just remove the
  // formatting {{}} braces.
  const description = interpolateConfiguredNames(
    element.configuration?.elementDisplayDescription ?? element.description,
    elementConfigs,
  );
  return stringToMarkdown(description);
}

export function getParameterDisplayDescription(
  parameter: Parameter,
  elementConfigs?: Record<string, ElementConfigurationSpec | null>,
): Markdown {
  // elementConfigs are optional here - if they don't exist, we just remove the
  // formatting {{}} braces.
  const description = interpolateConfiguredNames(
    parameter.configuration?.displayDescription ?? parameter.description,
    elementConfigs,
  );
  return stringToMarkdown(description);
}
