import React from 'react';

import { SimulationStatusIndicatorWithTooltip } from 'client/app/components/SimulationStatusIndicator';
import {
  ArrayElement,
  SimulationsForDialogQuery,
  SimulationSummaryFragmentFragment as SimulationSummaryFragment,
} from 'client/app/gql';
import { simulationRoutes } from 'client/app/lib/nav/actions';
import { EntityCard } from 'common/ui/components/EntityCard';
import EntityCardMenu from 'common/ui/components/EntityCardMenu';
import EntityCardStatus from 'common/ui/components/EntityCardStatus';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { SimulationIcon } from 'common/ui/icons/SimulationIcon';

type SimulationForDialog = ArrayElement<
  SimulationsForDialogQuery['simulations']['items']
>;

type SimulationProps<
  SimulationType extends SimulationForDialog | SimulationSummaryFragment,
> = {
  simulation: SimulationType;
  showNewTabButton?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
  isLink?: boolean;
};

type SimulationsForDialogItem = ArrayElement<
  SimulationsForDialogQuery['simulations']['items']
>;

export default function SimulationCard<
  SimulationType extends SimulationsForDialogItem | SimulationSummaryFragment,
>({
  simulation,
  isLink,
  isDisabled,
  showNewTabButton,
  onClick,
}: SimulationProps<SimulationType>) {
  const { getAbsoluteURL } = useNavigation();

  const {
    id,
    name,
    startedAt,
    lastUpdatedAt,
    status,
    user: { displayName: author },
  } = simulation;

  const path = isLink
    ? simulationRoutes.openInSimulationDetails.getPath({
        simulationId: id,
      })
    : undefined;

  const handleMenuOnClick = () =>
    window.open(
      getAbsoluteURL(simulationRoutes.openInSimulationDetails, {
        simulationId: id,
      }),
    );

  const nameColumn = { label: 'Simulation name', value: name };
  const authorColumn = { label: 'Simulated by', value: author };
  const dateColumn = {
    label: 'Date Simulated',
    value: new Date(lastUpdatedAt ?? startedAt),
  };

  const interaction = { to: path, onClick };

  return (
    <EntityCard
      icon={<SimulationIcon />}
      nameColumn={nameColumn}
      authorColumn={authorColumn}
      dateColumn={dateColumn}
      interaction={interaction}
      isDisabled={isDisabled}
      rightSlot={
        <>
          <EntityCardStatus
            key="simulation-status"
            disabled={isDisabled}
            icon={<SimulationStatusIndicatorWithTooltip status={status} />}
          />
          {showNewTabButton && (
            <EntityCardMenu
              menu={[
                {
                  label: 'Open in new tab',
                  onClick: handleMenuOnClick,
                },
              ]}
            />
          )}
        </>
      }
    />
  );
}
