import React, { PropsWithChildren } from 'react';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export default function CardGrid(props: PropsWithChildren<{}>) {
  const classes = useStyles();
  return <section className={classes.cardgrid}>{props.children}</section>;
}

const useStyles = makeStylesHook({
  cardgrid: {
    padding: '4px',
    maxWidth: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
});
