import React from 'react';

import Typography from '@mui/material/Typography';

import { getResinName } from 'common/lib/chromatography';
import { formatMeasurementObj, formatWellPosition } from 'common/lib/format';
import { WellContents, WellLocationOnDeckItem } from 'common/types/mix';
import { formatTagValue } from 'common/ui/components/simulation-details/mix/helpers';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export const TOOLTIP_FONTSIZE = 12;

const TOOLTIP_MAX_VISIBLE_ITEMS = 4;

export type WellTooltipTitleProps = {
  isPossiblyAllocated?: boolean;
  wellContents?: WellContents;
  wellLocationOnDeckItem: WellLocationOnDeckItem;
};

export function DefaultWellTooltipTitle({
  isPossiblyAllocated,
  wellContents,
  wellLocationOnDeckItem,
}: WellTooltipTitleProps) {
  const classes = useStyles();
  if (isPossiblyAllocated) {
    return (
      <Typography
        className={classes.tooltipHeader}
        variant="subtitle2"
        color="textPrimary"
      >
        Well at {formatWellPosition(wellLocationOnDeckItem)} may have liquids allocated
        from another element
      </Typography>
    );
  }
  if (!wellContents) {
    return (
      <Typography
        className={classes.tooltipHeader}
        variant="subtitle2"
        color="textPrimary"
      >
        Empty well at {formatWellPosition(wellLocationOnDeckItem)}
      </Typography>
    );
  }
  switch (wellContents.kind) {
    // This is undefined on older simulations, in which case it can be assumed this is a
    // liquid.
    case 'liquid_summary':
    case undefined:
      return (
        <div className={classes.tooltipTable}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.tooltipHeader}
          >
            Well at {formatWellPosition(wellLocationOnDeckItem)}
          </Typography>
          <dl>
            {wellContents.name && (
              <>
                <dt>Liquid Name:</dt>
                <dd>{wellContents.name}</dd>
              </>
            )}
            {wellContents.type && (
              <>
                <dt>Liquid Type:</dt>
                <dd>{wellContents.type}</dd>
              </>
            )}
            {wellContents.total_volume.value > 0 && (
              <>
                <dt>Volume:</dt>
                <dd>{formatMeasurementObj(wellContents.total_volume)}</dd>
              </>
            )}
            {wellContents.solutes && (
              <>
                <dt className={classes.tooltipSubheader}>Final Concentrations</dt>
                {wellContents.solutes
                  .slice(0, TOOLTIP_MAX_VISIBLE_ITEMS)
                  .map((solute, idx) => (
                    <dt key={solute.name + idx}>
                      {`${formatMeasurementObj(solute.concentration)} ${solute.name}`}
                    </dt>
                  ))}
                {wellContents.solutes.length > TOOLTIP_MAX_VISIBLE_ITEMS && (
                  <dt>
                    +{wellContents.solutes.length - TOOLTIP_MAX_VISIBLE_ITEMS} more...
                  </dt>
                )}
              </>
            )}
            {wellContents.tags && (
              <>
                <dt className={classes.tooltipSubheader}>Metadata</dt>
                {wellContents.tags.slice(0, TOOLTIP_MAX_VISIBLE_ITEMS).map((tag, idx) => (
                  <React.Fragment key={idx}>
                    <dt>{tag.label || 'n/a'}</dt>
                    <dd>{formatTagValue(tag)}</dd>
                  </React.Fragment>
                ))}
                {wellContents.tags.length > TOOLTIP_MAX_VISIBLE_ITEMS && (
                  <dt>+{wellContents.tags.length - TOOLTIP_MAX_VISIBLE_ITEMS} more...</dt>
                )}
              </>
            )}
          </dl>
        </div>
      );
    case 'filter_matrix_summary':
      return (
        <div className={classes.tooltipTable}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.tooltipHeader}
          >
            Chromatography Column at {formatWellPosition(wellLocationOnDeckItem)}
          </Typography>
          <dl>
            {wellContents.name && (
              <>
                <dt>RoboColumn Name:</dt>
                <dd>{wellContents.name}</dd>
              </>
            )}
            <dt>Resin:</dt>
            <dd>{getResinName(wellContents)}</dd>
            <dt>Column Volume:</dt>
            <dd>{formatMeasurementObj(wellContents.total_volume)}</dd>
          </dl>
        </div>
      );
    case 'filtration_plate_matrix_summary':
      return (
        <div className={classes.tooltipTable}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.tooltipHeader}
          >
            Well at {formatWellPosition(wellLocationOnDeckItem)}
          </Typography>
          <dl>
            {wellContents.name && (
              <>
                <dt>Resin Name:</dt>
                <dd>{wellContents.name}</dd>
              </>
            )}
            {wellContents.total_volume.value > 0 && (
              <>
                <dt>Resin Volume:</dt>
                <dd>{formatMeasurementObj(wellContents.total_volume)}</dd>
              </>
            )}
            {wellContents.tags && wellContents.tags.length > 0 && (
              <>
                <dt className={classes.tooltipSubheader}>Metadata</dt>
                {wellContents.tags.slice(0, TOOLTIP_MAX_VISIBLE_ITEMS).map((tag, idx) => (
                  <React.Fragment key={idx}>
                    <dt>{tag.label || 'n/a'}</dt>
                    <dd>{formatTagValue(tag)}</dd>
                  </React.Fragment>
                ))}
                {wellContents.tags.length > TOOLTIP_MAX_VISIBLE_ITEMS && (
                  <dt>+{wellContents.tags.length - TOOLTIP_MAX_VISIBLE_ITEMS} more...</dt>
                )}
              </>
            )}
          </dl>
        </div>
      );
    case 'mixture_summary':
      return (
        <div className={classes.tooltipTable}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.tooltipHeader}
          >
            Well at {formatWellPosition(wellLocationOnDeckItem)}
          </Typography>

          <dl>
            {wellContents.name && (
              <>
                <dt>Mixture Name:</dt>
                <dd>{wellContents.name}</dd>
              </>
            )}
            {wellContents?.total_volume.value > 0 && (
              <>
                <dt>Volume:</dt>
                <dd>{formatMeasurementObj(wellContents.total_volume)}</dd>
              </>
            )}
            {wellContents.sub_liquids && (
              <>
                <dt className={classes.tooltipSubheader}>Content Liquids</dt>
                {[...wellContents.sub_liquids]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .slice(0, TOOLTIP_MAX_VISIBLE_ITEMS)
                  .map((subLiquid, idx) => (
                    <dt key={subLiquid.name + idx}>
                      {`${formatMeasurementObj(subLiquid.volume)} ${subLiquid.name}`}
                    </dt>
                  ))}
                {wellContents.sub_liquids.length > TOOLTIP_MAX_VISIBLE_ITEMS && (
                  <dt>
                    +{wellContents.sub_liquids.length - TOOLTIP_MAX_VISIBLE_ITEMS} more...
                  </dt>
                )}
              </>
            )}
            {wellContents.solutes && (
              <>
                <dt className={classes.tooltipSubheader}>Final Concentrations</dt>
                {[...wellContents.solutes]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .slice(0, TOOLTIP_MAX_VISIBLE_ITEMS)
                  .map((solute, idx) => (
                    <dt key={solute.name + idx}>
                      {`${formatMeasurementObj(solute.concentration)} ${solute.name}`}
                    </dt>
                  ))}
                {wellContents.solutes.length > TOOLTIP_MAX_VISIBLE_ITEMS && (
                  <dt>
                    +{wellContents.solutes.length - TOOLTIP_MAX_VISIBLE_ITEMS} more...
                  </dt>
                )}
              </>
            )}
          </dl>
        </div>
      );
    case 'liquid_layer':
      return (
        <div className={classes.tooltipTable}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.tooltipHeader}
          >
            Well at {formatWellPosition(wellLocationOnDeckItem)}
          </Typography>
          <dl>
            {wellContents.name && (
              <>
                <dt>{wellContents.isGroup ? 'Group' : 'Liquid'} Name:</dt>
                <dd>{wellContents.name}</dd>
              </>
            )}
            {wellContents?.total_volume && (
              <>
                <dt>Volume:</dt>
                <dd>{formatMeasurementObj(wellContents.total_volume)}</dd>
              </>
            )}
            {wellContents.overallConcentration && (
              <>
                <dt>Concentration:</dt>
                <dd>{formatMeasurementObj(wellContents.overallConcentration)}</dd>
              </>
            )}
          </dl>
        </div>
      );
  }
}

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  tooltipTable: {
    padding: spacing(2),

    color: palette.text.primary,

    fontSize: TOOLTIP_FONTSIZE,
    fontWeight: 400,
    lineHeight: spacing(5),
    letterSpacing: '0.4px',

    '& dl': {
      display: 'grid',
      gap: spacing(3, 2),
      margin: spacing(3, 0, 0, 0),
    },

    '& dt': {
      gridColumn: 1,
    },
    '& dd': {
      margin: 0,
      gridColumn: 2,
    },
  },
  tooltipHeader: {
    fontSize: TOOLTIP_FONTSIZE,
    fontWeight: 700,
    lineHeight: spacing(5),
    letterSpacing: '0.4px',
  },
  tooltipSubheader: {
    fontSize: TOOLTIP_FONTSIZE,
    fontWeight: 500,
    letterSpacing: '0.1px',
    whiteSpace: 'nowrap',
    paddingTop: spacing(2),
  },
}));
