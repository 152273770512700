import React from 'react';

import MapEditor from 'client/app/components/Parameters/MapEditor';
import ParameterEditor from 'client/app/components/Parameters/ParameterEditor';
import ConfigParameterHeader from 'client/app/components/WorkflowSettings/ConfigParameterHeader';
import { ParameterValue, ParameterValueDict } from 'common/types/bundle';
import ArrayEditor from 'common/ui/components/ParameterEditors/ArrayEditor';

type Props = {
  instanceName?: string;
  isDisabled?: boolean;
  onChange: (param: ParameterValue, instanceName?: string) => void;
  valueDict: ParameterValue;
};

// TODO: remove this in favour of the MixtureDefinitionsEditor
export default function DOEMixRulesEditor(props: Props) {
  const { isDisabled, valueDict, onChange, instanceName } = props;

  // An onchange method which updates a specified field of the
  // parameter value when called from a sub component editor.
  const subOnChange = (key: string, value: ParameterValueDict | ParameterValue) => {
    const newValue: ParameterValueDict = { ...valueDict };
    newValue[key]['value'] = value;
    onChange(newValue);
  };

  return (
    <>
      <ConfigParameterHeader
        name="Constant components. These are not DOE factors."
        type={valueDict['mapConstant']['anthaType']}
        description="Each entry defines a component and its value, which is held fixed for all DOE runs."
      />
      <MapEditor
        anthaType={valueDict['mapConstant']['anthaType']}
        value={valueDict['mapConstant']['value']}
        onChange={newParamValue => subOnChange('mapConstant', newParamValue)}
        isDisabled={isDisabled}
        instanceName={instanceName}
      />
      <ConfigParameterHeader
        name="Single-component (of constant type) DOE factors."
        type={valueDict['mapSingleConstant']['anthaType']}
        description="Each entry defines a new DOE factor, and one or more factor values."
      />
      <MapEditor
        anthaType={valueDict['mapSingleConstant']['anthaType']}
        value={valueDict['mapSingleConstant']['value']}
        onChange={newParamValue => subOnChange('mapSingleConstant', newParamValue)}
        isDisabled={isDisabled}
        instanceName={instanceName}
      />
      <ConfigParameterHeader
        name="Single-component (of variable type) DOE factors."
        type={valueDict['mapSingleVariable']['anthaType']}
        description="Each entry defines a new DOE factor. For each factor, each sub-entry specifies a categoric level, in the form of a component name-value pair."
      />
      <MapEditor
        anthaType={valueDict['mapSingleVariable']['anthaType']}
        value={valueDict['mapSingleVariable']['value']}
        onChange={newParamValue => subOnChange('mapSingleVariable', newParamValue)}
        isDisabled={isDisabled}
        instanceName={instanceName}
      />
      <ConfigParameterHeader
        name="A single, multi-component DOE factor."
        type={valueDict['mapMultiComponent']['anthaType']}
        description="Each entry specifies a group of multiple component name-value pairs. Each of these groups represents a categoric level for the same DOE factor. This single factor is the overall element parameter."
      />
      <ArrayEditor
        component={ParameterEditor}
        anthaType={valueDict['mapMultiComponent']['anthaType']}
        value={valueDict['mapMultiComponent']['value']}
        onChange={newParamValue => subOnChange('mapMultiComponent', newParamValue)}
        isDisabled={isDisabled}
      />
    </>
  );
}
