import Big from 'big.js';

/**
 * Calculates the density in g/cm^3
 */
export function calculateDensity(
  volume: Big,
  volumeUnitCoefficient: number,
  mass: Big,
  massUnitCoefficient: number,
) {
  const volumeInLitres = volume.mul(volumeUnitCoefficient);
  const volumeInCubicCentimetres = volumeInLitres.mul(1000);
  const massInGrammes = mass.mul(massUnitCoefficient);

  return massInGrammes.div(volumeInCubicCentimetres);
}
