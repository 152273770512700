import React from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  open: boolean;
  onClose: () => void;
  readonlyMode?: boolean;
};

export default function HelpDialog(props: Props) {
  const classes = useStyles();

  return (
    <ComplexActionDialog
      title="Shortcuts"
      isOpen={props.open}
      onClose={props.onClose}
      paperClassName={classes.paper}
      maxWidth="sm"
      content={
        <DialogContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <HotKeyContents>
                    <KeyboardKey>
                      <Typography color="textPrimary" variant="h5">
                        ?
                      </Typography>
                    </KeyboardKey>
                  </HotKeyContents>
                </TableCell>
                <TableCell>
                  <Typography color="textPrimary">Display keyboard shortcuts.</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <HotKeyContents>
                    <KeyboardKey>
                      <Typography color="textPrimary" variant="h5">
                        a
                      </Typography>
                    </KeyboardKey>
                  </HotKeyContents>
                </TableCell>
                <TableCell>
                  <Typography color="textPrimary">
                    Centre everything on the canvas at the closest zoom level.
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <HotKeyContents>
                    <KeyboardKey>
                      <Typography color="textPrimary" variant="h5">
                        0
                      </Typography>
                    </KeyboardKey>
                  </HotKeyContents>
                </TableCell>
                <TableCell>
                  <Typography color="textPrimary">Reset the zoom level.</Typography>
                </TableCell>
              </TableRow>

              {!props.readonlyMode && (
                <>
                  <TableRow>
                    <TableCell align="center">
                      <HotKeyContents>
                        <KeyboardKey>
                          <ArrowDropUpIcon />
                        </KeyboardKey>
                      </HotKeyContents>
                      <HotKeyContents>
                        <KeyboardKey>
                          <ArrowLeftIcon />
                        </KeyboardKey>
                        <KeyboardKey>
                          <ArrowDropDownIcon />
                        </KeyboardKey>
                        <KeyboardKey>
                          <ArrowRightIcon />
                        </KeyboardKey>
                      </HotKeyContents>
                    </TableCell>
                    <TableCell>
                      <Typography color="textPrimary">
                        Nudge the selected elements. Hold to nudge them further.
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <HotKeyContents>
                        <KeyboardKey>
                          <Typography color="textPrimary" variant="h5">
                            Del
                          </Typography>
                        </KeyboardKey>
                      </HotKeyContents>
                    </TableCell>
                    <TableCell>
                      <Typography color="textPrimary">
                        Delete the selected elements and their connections.
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <HotKeyContents>
                        <MetaKeyContents />
                        <KeyboardKey>
                          <Typography color="textPrimary" variant="h5">
                            a
                          </Typography>
                        </KeyboardKey>
                      </HotKeyContents>
                    </TableCell>
                    <TableCell>
                      <Typography color="textPrimary">
                        Select everything on the canvas.
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <HotKeyContents>
                        <MetaKeyContents />
                        <KeyboardKey>
                          <Typography color="textPrimary" variant="h5">
                            c
                          </Typography>
                        </KeyboardKey>
                      </HotKeyContents>
                    </TableCell>
                    <TableCell>
                      <Typography color="textPrimary">
                        Copy the selected elements (including the values of their
                        parameters) and/or connections.
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <HotKeyContents>
                        <MetaKeyContents />
                        <KeyboardKey>
                          <Typography color="textPrimary" variant="h5">
                            g
                          </Typography>
                        </KeyboardKey>
                      </HotKeyContents>
                    </TableCell>
                    <TableCell>
                      <Typography color="textPrimary">
                        Create a new group containing the selected elements.
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <HotKeyContents>
                        <MetaKeyContents />
                        <KeyboardKey>
                          <Typography color="textPrimary" variant="h5">
                            v
                          </Typography>
                        </KeyboardKey>
                      </HotKeyContents>
                    </TableCell>
                    <TableCell>
                      <Typography color="textPrimary">
                        Paste. Works between browser tabs.
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <HotKeyContents>
                        <MetaKeyContents />
                        <KeyboardKey>
                          <Typography color="textPrimary" variant="h5">
                            z
                          </Typography>
                        </KeyboardKey>
                      </HotKeyContents>
                    </TableCell>
                    <TableCell>
                      <Typography color="textPrimary">
                        Undo your most recent change to an element, parameter or
                        connection.
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <HotKeyContents>
                        <Typography color="textPrimary" variant="h5">
                          <MetaKey />
                        </Typography>
                      </HotKeyContents>
                    </TableCell>
                    <TableCell>
                      <Typography color="textPrimary">
                        Select multiple elements and/or connections by clicking and
                        dragging a crosshair over them.
                      </Typography>
                    </TableCell>
                  </TableRow>
                </>
              )}
              <TableRow>
                <TableCell>
                  <HotKeyContents>
                    <MetaKeyContents />
                    <Typography color="textPrimary" variant="h5">
                      click
                    </Typography>
                  </HotKeyContents>
                </TableCell>
                <TableCell>
                  <Typography color="textPrimary">
                    Select multiple elements and/or connections by clicking them.
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      }
      dialogActions={
        <DialogActions>
          <Button variant="tertiary" onClick={props.onClose}>
            Close
          </Button>
        </DialogActions>
      }
    />
  );
}

function MetaKey() {
  // Rough and ready OS detection, bringing in a library is too heavyweight
  // for the single usage here. The special case here is Macs - PC and Linux
  // both have Ctrl keys.
  const metaKey = window.navigator.appVersion.includes('Macintosh') ? (
    <Typography color="textPrimary" variant="h5">
      &#8984;
    </Typography>
  ) : (
    <Typography color="textPrimary" variant="h5">
      Ctrl
    </Typography>
  );
  return <KeyboardKey>{metaKey}</KeyboardKey>;
}

function MetaKeyContents() {
  const classes = useStyles();
  return (
    <>
      <MetaKey />
      <Typography className={classes.metaKey} color="textPrimary" variant="h3">
        +
      </Typography>
    </>
  );
}

function HotKeyContents({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  return <div className={classes.hotKeyContents}>{children}</div>;
}

function KeyboardKey({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  return (
    <Paper elevation={4} className={classes.hotKey}>
      {children}
    </Paper>
  );
}

const useStyles = makeStylesHook(theme => ({
  hotKey: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: Colors.GREY_5,
    borderRadius: '4px',
    height: '36px',
    width: '36px',
    margin: theme.spacing(2, 1),
  },
  hotKeyContents: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  metaKey: {
    margin: theme.spacing(0, 2),
  },
  paper: {
    height: '80vh',
    backgroundColor: Colors.GREY_5,
  },
}));
