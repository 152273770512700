import React from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';

import { experimentsRoutes, workTreeRoutes } from 'client/app/lib/nav/actions';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { usePopover } from 'common/ui/hooks/usePopover';
import { ExperimentMapIcon } from 'common/ui/icons/ExperimentMapIcon';
import { ExperimentReportIcon } from 'common/ui/icons/ExperimentReportIcon';

type ExperimentView = 'map' | 'report';

type ExperimentViewMenuProps = {
  experimentId: ExperimentId;
  currentView: ExperimentView;
  disabled?: boolean;
};

export default function ExperimentViewMenu(props: ExperimentViewMenuProps) {
  const { experimentId, currentView, disabled } = props;
  const classes = useStyles();
  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();

  const navigation = useNavigation();

  const handleSelect = (selectedView: ExperimentView) => {
    if (currentView === selectedView) {
      // Don't navigate if we are already on the selected page view.
      return;
    }
    if (selectedView === 'map') {
      navigation.navigate(workTreeRoutes.workTreeExperiment, {
        experimentId: experimentId,
      });
    } else {
      navigation.navigate(experimentsRoutes.detail, { id: experimentId });
    }
  };

  const CurrentIcon = currentView === 'map' ? ExperimentMapIcon : ExperimentReportIcon;

  return (
    <>
      <Tooltip title="Switch between the map and report views">
        <Button
          onClick={onShowPopover}
          className={classes.button}
          startIcon={<CurrentIcon />}
          endIcon={<ArrowDropDownIcon />}
          variant="tertiary"
          disabled={disabled}
        >
          <Typography>{currentView}</Typography>
        </Button>
      </Tooltip>
      <Menu
        anchorEl={popoverAnchorElement}
        keepMounted
        open={isPopoverOpen}
        onClose={onHidePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{ square: false }}
      >
        <MenuItemWithIcon
          onClick={() => handleSelect('report')}
          icon={<ExperimentReportIcon fontSize="small" />}
          text="Report"
          selected={currentView === 'report'}
        />
        <MenuItemWithIcon
          onClick={() => handleSelect('map')}
          icon={<ExperimentMapIcon fontSize="small" />}
          text="Map"
          selected={currentView === 'map'}
        />
      </Menu>
    </>
  );
}

const useStyles = makeStylesHook({
  button: {
    textTransform: 'capitalize',
    color: Colors.TEXT_PRIMARY,
  },
});
