import React, { useCallback, useMemo, useRef, useState } from 'react';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import cx from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import * as FilesApi from 'client/app/api/FilesApi';
import { FileMetadataWithUrl } from 'client/app/api/FilesApi';
import { useUserDownloadFile } from 'client/app/api/filetree';
import FilePicker from 'client/app/components/FileBrowser/FilePicker';
import { FileBrowserFileSelection } from 'client/app/lib/file-browser/types';
import { formatDateTime, formatFileSize } from 'common/lib/format';
import { removeScheme } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import { SMART_WORD_BREAK_STYLE } from 'common/ui/commonStyles';
import { scrollableContent } from 'common/ui/commonStyles';
import LinearProgress from 'common/ui/components/LinearProgress';
import UIBox from 'common/ui/components/UIBox';

const FILE_BROWSER_URL = '/file-browser';

// Under normal circumstances, we'd want to use the matchPath method from the
// react-router-dom library. It's the right choice for extracting slugs from the
// URL. In this case however, we want to let the literal file path exist in the
// URL with no escaping. So, we have to do the match ourselves.
const ROUTE_PATTERN = /\/file-browser\/-(.*)$/;

const DEFAULT_PATH = '/';

function getCurrentPath(locationPath: string) {
  const result = locationPath.match(ROUTE_PATTERN);
  if (!result) {
    return DEFAULT_PATH;
  }

  const pathFromURI = result[1];

  if (!pathFromURI) {
    return DEFAULT_PATH;
  }

  return decodeFiletreePath(pathFromURI);
}

type Props = WithStyles<typeof styles> & RouteComponentProps;

const FileBrowserApp = function (props: Props) {
  const { history, location } = props;
  const currentPath = getCurrentPath(location.pathname);
  const [currentFile, setCurrentFile] = useState<FileMetadataWithUrl | null>(null);
  const [currentFileIsLoading, setCurrentFileIsLoading] = useState<boolean>(false);
  const [currentFileIsErrored, setCurrentFileIsErrored] = useState<boolean>(false);
  const pathLoadingRef = useRef('');

  const fetchSingleFile = FilesApi.useFetchSingleFile();
  const fetchFileAtPathAndUpdateState = useCallback(
    async (path: string) => {
      let p = path;
      // The datarepo API barfs on trailing slashes
      if (p.endsWith('/')) {
        p = p.substr(0, p.length - 1);
      }
      pathLoadingRef.current = path;
      try {
        const { resp: fileWithMetadata } = await fetchSingleFile(p);

        if (fileWithMetadata.type !== FilesApi.FILE) {
          return;
        }
        // If the user's connection is slow or if our backend is slow (or
        // both), we can wind up in a state where the user has clicked two
        // different files and the network requests for file details finish
        // either a) far apart from one another or (worse) b) out of order.
        // This can mean that the user gets shown the details for the wrong
        // file. To avoid this, we check to see if the `pathLoadingRef.current` is still
        // the same one that the user chose when this callback was setup.
        // We do compare it with the `ftl` of the response.
        // If they aren't the same, it means the user
        // has selected a different file path between when the network request
        // was initiated and when it completed, so we should throw away the
        // result to avoid confusion.
        if (
          removeScheme(fileWithMetadata.ftl ?? '') ===
          removeScheme(pathLoadingRef.current)
        ) {
          setCurrentFile(fileWithMetadata);
        }
      } catch {
        setCurrentFileIsErrored(true);
      } finally {
        setCurrentFileIsLoading(false);
      }
    },
    [fetchSingleFile],
  );

  const userDownloadFile = useUserDownloadFile();
  const onDownload = useCallback(() => {
    // All files should have ftl now. If the file does not, we should attempt to allow for download.
    if (!currentFile?.ftl) {
      return;
    }
    return userDownloadFile(currentFile.ftl, currentFile.name);
  }, [currentFile, userDownloadFile]);

  const onPathSelected = useCallback(
    async (value: FileBrowserFileSelection) => {
      setCurrentFile(null);
      setCurrentFileIsLoading(true);
      setCurrentFileIsErrored(false);
      pathLoadingRef.current = '';

      // A FileBrowserFileSelection can be null, a list of paths, or a single path. For the
      // currently supported use case of this UI, we can only handle the single
      // file path case. So in every other case, we'll bail out for now.
      if (!value || Array.isArray(value)) {
        return;
      }

      const encoded = encodeFiletreePath(value.localPath);
      const uri = `${FILE_BROWSER_URL}/-${encoded}`;

      if (encodeURI(location.pathname) !== uri) {
        history.push(uri);
      }

      await fetchFileAtPathAndUpdateState(value.pathWithScheme);
    },
    [history, location.pathname, fetchFileAtPathAndUpdateState],
  );

  const currentPathWithScheme = currentFile?.ftl ?? '';

  const filePickerValue = useMemo(
    () => ({
      localPath: currentPath,
      pathWithScheme: currentPathWithScheme,
    }),
    [currentPath, currentPathWithScheme],
  );

  const { classes } = props;

  return (
    <UIBox padding="l" className={classes.root}>
      <div
        className={cx({
          [classes.picker]: true,
          [classes.detailsOpen]:
            !!currentFile || currentFileIsLoading || currentFileIsErrored,
        })}
      >
        <FilePicker value={filePickerValue} onChange={onPathSelected} />
      </div>
      {currentFile !== null ? (
        <UIBox padding="m" className={classes.details}>
          <Card>
            <CardContent>
              <Typography variant="h3" className={classes.fileName}>
                {currentFile.name}
              </Typography>
            </CardContent>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>Modified</strong>
                  </TableCell>
                  <TableCell>{formatDateTime(new Date(currentFile.modified))}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>File Size</strong>
                  </TableCell>
                  <TableCell>{formatFileSize(currentFile.size)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <CardActions className={classes.actions}>
              <Button onClick={onDownload} color="primary">
                <CloudDownloadIcon className={classes.downloadIcon} /> Download
              </Button>
            </CardActions>
          </Card>
        </UIBox>
      ) : (
        <div className={classes.details}>
          {currentFileIsLoading ? (
            <LinearProgress />
          ) : currentFileIsErrored ? (
            'Sorry something went wrong when fetching the file details.'
          ) : null}
        </div>
      )}
    </UIBox>
  );
};

/**
 * Encode filetree path to be safe to use with history.push
 *
 * Filetree paths can contain special characters which shouldn't exist in the address bar.
 * A good example is # which has a special meaning (fragment identifier) and
 * putting filetree paths with # into URL leads to odd behaviour. For example
 * location.pathname returns only the path and not the hash part.
 * To keep things sane let's just encode each part of the URL.
 * It can be decoded once it's retrieved from location.path
 */
function encodeFiletreePath(path: string): string {
  const parts = path.split('/');
  return parts.map(p => encodeURIComponent(p)).join('/');
}

// inverse function of encodeFiletreePath
function decodeFiletreePath(path: string): string {
  const parts = path.split('/');
  return parts.map(p => decodeURIComponent(p)).join('/');
}

const styles = createStyles({
  root: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    ...scrollableContent,
    backgroundColor: Colors.GREY_5,
    height: '100%',
  },
  picker: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    overflowY: 'hidden',
  },
  detailsOpen: {
    paddingRight: '400px',
  },
  details: {
    position: 'absolute',
    right: 0,
    top: '70px', // align with the body of the FilePicker
    width: '400px',
  },
  fileName: {
    ...SMART_WORD_BREAK_STYLE,
  },
  downloadIcon: {
    marginRight: '8px',
  },
  actions: {
    justifyContent: 'flex-end',
  },
});

export default withRouter(withStyles(styles)(FileBrowserApp as any) as any);
