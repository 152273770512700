/**
 * (T2038) There are two cases in which we *expect* an invalid picklist:
 * 1. On a newly created workflow, which hasn't been edited yet. The PickList will
 * not be valid until users add plate types.
 * 2. When using the Cherry Picker UI as a parameter editor. In this case, we don't
 * load a workflow from the DB, thus the picklist will be empty (and that's not valid)
 * We use this error to catch specifically these two cases.
 *
 * When a PickList is not valid, we use a workaround to still display the UI: set the
 * picklist to be `[DEFAULT_TRANSFER]` (i.e. a dummy, single default transfer)
 */
export class InvalidPicklistError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}
