import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import DownloadExampleWorkflows from 'client/app/components/ExampleWorkflows/DownloadExampleWorkflows';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import SearchField from 'common/ui/components/SearchField';

type Props = {
  title?: string;
  searchQuery?: string;
  setSearchQuery: (q: string) => void;
};

export const HeaderWithDescription = ({ title, searchQuery, setSearchQuery }: Props) => {
  const userProfile = useUserProfile();
  return (
    <HeaderWrapper>
      {title && (
        <Title>
          <Typography variant="h2">{title}</Typography>
          {userProfile?.isExampleWorkflowsSourceOrg &&
            userProfile?.isSynthaceEmployee && <DownloadExampleWorkflows />}
        </Title>
      )}
      <HeaderBody>
        <div>
          <Typography variant="body1">
            Explore our collection of ready-to-use example workflows for common scientific
            use cases.
          </Typography>
          <Typography variant="body1">
            You can run these directly or customize the design to fit your needs.
          </Typography>
        </div>
        <SearchField
          variant="standard"
          label="Search"
          defaultValue={searchQuery}
          onQueryChange={setSearchQuery}
        />
      </HeaderBody>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(6),
}));

const HeaderBody = styled('div')(() => ({
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'space-between',
}));

const Title = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));
