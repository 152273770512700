export default async function fetchWithRetries({
  url,
  options,
  maxRetries = 3,
  retries = 0,
}: {
  url: string;
  options?: RequestInit;
  maxRetries?: number;
  retries?: number;
}): Promise<Response> {
  if (retries >= maxRetries) {
    return Promise.reject({
      error: {
        message: `Failed to fetch ${url} after ${maxRetries} retries`,
      },
    });
  }

  let response: Response | undefined;
  try {
    response = await fetch(url, options);
    if (response.ok) {
      return response;
    }
  } catch (e) {
    console.error(`Error during fetch attempt ${retries + 1}:`, e);
  }

  if ((response && [500, 502, 503, 504].includes(response.status)) || !response) {
    const delay = Math.pow(2, retries) * 100; // Exponential backoff
    await new Promise(resolve => setTimeout(resolve, delay));

    return fetchWithRetries({ url, options, maxRetries, retries: retries + 1 });
  }

  return response;
}
