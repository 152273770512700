import React from 'react';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import cx from 'classnames';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type SettingsPanelButtonProps = {
  /** Icon to be displayed at the start of the button. */
  icon: React.ReactElement;
  /** Applies custom selected styling. */
  selected?: boolean;
} & Omit<MuiButtonProps, 'startIcon' | 'endIcon'>;

/**
 * SettingsPanelButton renders a button with a given icon at the start of the button, and a
 * right-pointing chevron icon at the end of the button.
 *
 * It is custom designed to be used in the SettingsPanel.
 */
export default function SettingsPanelButton(props: SettingsPanelButtonProps) {
  const classes = useStyles();
  const { icon, selected, color = 'inherit', ...buttonProps } = props;
  return (
    <MuiButton
      {...buttonProps}
      className={cx(classes.button, selected ? classes.selected : classes.unselected)}
      classes={{ endIcon: classes.endIcon }}
      endIcon={<ChevronRightOutlinedIcon />}
      startIcon={icon}
      color={color}
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  button: {
    transition: theme.transitions.easing.easeInOut,
  },
  endIcon: {
    marginLeft: 'auto',
  },
  selected: {
    color: Colors.WHITE,
    backgroundColor: Colors.BLUE_90,
    '&:hover': {
      backgroundColor: Colors.BLUE_90,
    },
  },
  unselected: {
    backgroundColor: Colors.GREY_10,
    '&:hover': {
      backgroundColor: Colors.ACTION_PRIMARY_MAIN_HOVER,
    },
  },
}));
