import React from 'react';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import { useMixturesTable } from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import Colors from 'common/ui/Colors';

export default function MixturesTable() {
  const { inputLiquids, liquidParameters } = usePlateLayoutEditorContext();
  const { rows, columnHeaders } = useMixturesTable(inputLiquids, liquidParameters);
  return (
    <StyledTableContainer>
      <Table stickyHeader>
        <TableHead>
          <StyledRow>
            <TableCell>Mixture</TableCell>
            {columnHeaders?.map((columnHeader, idx) => {
              return <TableCell key={`${columnHeader}-${idx}`}>{columnHeader}</TableCell>;
            })}
            <TableCell>Replicates</TableCell>
          </StyledRow>
        </TableHead>
        <TableBody>
          {rows?.map(row => {
            return (
              <StyledRow key={row.id}>
                <TableCell>{row.mixtureName}</TableCell>
                {row.cells.map(cell => (
                  <TableCell key={cell.id}>{cell.content}</TableCell>
                ))}
                <TableCell>{row.replicates}</TableCell>
              </StyledRow>
            );
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}

const StyledTableContainer = styled(TableContainer)(({ theme: { spacing } }) => ({
  gridColumnStart: 'plate',
  gridColumnEnd: 'mixtures',
  border: `1px solid ${Colors.GREY_30}`,
  borderRadius: spacing(2),
}));

const StyledRow = styled(TableRow)(({ theme: { palette } }) => ({
  '& .MuiTableCell-root:first-of-type': {
    position: 'sticky',
    left: 0,
    backgroundColor: palette.common.white,
    borderRight: `1px solid ${palette.divider}`,
    '&.MuiTableCell-head': {
      zIndex: 3, // Mui Header cells have a zIndex of 2, we need this to be higher to always be visible
    },
  },
}));
