// This is a union of the UI statuses of a Simulation/Execution in Simulation Details UI
// in antha-com and UI statuses in AnthaHub.
// We can't depend on them directly because this component is in common. Currently,
// these are disjoint sets, but everything will still work if they aren't as long as we

import React from 'react';

import { styled } from '@mui/material/styles';

// can use the same styling for the same state on both.
export type Status =
  // antha-com Simulation Details legacy UI
  | 'Error'
  | 'Computational'
  | 'Simulation'
  | 'Queued'
  | 'Completed'
  | 'Unknown'
  // antha-com Simulation Details UI
  | 'Simulation failed'
  | 'Simulation succeeded'
  | 'Ready to run'
  | 'Execution scheduled'
  | 'Execution failed'
  | 'Execution succeeded'
  | 'Execution running'
  | 'Execution incomplete'
  | 'Succeeded'
  | 'Incomplete'
  | 'Scheduled'
  // anthahub-ui
  | 'None'
  | 'New'
  | 'NewBlocked'
  | 'Pending'
  | 'Running'
  | 'CancelledPending'
  | 'Cancelled'
  | 'Succeeded'
  | 'Failed'
  | 'ResumeFailed';

const ERROR_STATUSES: Status[] = [
  'Error',
  'Execution failed',
  'ResumeFailed',
  'Simulation failed',
  'Failed',
  'Incomplete',
  'Execution incomplete',
];

const SUCCESS_STATUSES: Status[] = [
  'Succeeded',
  'Execution succeeded',
  'Simulation succeeded',
];

const INTERMEDIATE_STATUSES: Status[] = [
  'Running',
  'Scheduled',
  'Execution running',
  'Execution scheduled',
];

type Props = {
  status: Status;
};

export default function StatusIndicator({ status }: Props) {
  return <Wrapper status={status}>{status}</Wrapper>;
}

const Wrapper = styled('div')<Props>(({ status, theme: { spacing, palette } }) => {
  const isError = status && ERROR_STATUSES.includes(status);
  const isSuccess = status && SUCCESS_STATUSES.includes(status);
  const isIntermediateStatus = status && INTERMEDIATE_STATUSES.includes(status);

  const color = isError
    ? palette.error.main
    : isSuccess
    ? palette.success.main
    : isIntermediateStatus
    ? palette.warning.dark
    : palette.text.secondary;

  return {
    display: 'flex',
    color,
    gap: spacing(1),
    alignItems: 'center',
    '::before': {
      content: '""',
      width: 8,
      height: 8,
      borderRadius: 4,
      margin: spacing(2),
      background: color,
    },
  };
});
