import React, { useCallback, useState } from 'react';

import cx from 'classnames';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  handleFileDrop: (files: FileList) => void;
  maskMessage: string | React.ReactNode;
  children: React.ReactNode;
};
export default function FileDropZone(props: Props) {
  const { handleFileDrop, children, maskMessage } = props;
  const classes = useStyles();
  const [dragging, setDragging] = useState(false);
  const onDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      setDragging(false);
      if (!e.dataTransfer) {
        return;
      }
      const { files } = e.dataTransfer;
      handleFileDrop(files);
    },
    [handleFileDrop],
  );
  const onDragLeave = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      setDragging(false);
    },
    [setDragging],
  );
  const onDragOver = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
      setDragging(true);
    },
    [setDragging],
  );

  return (
    <div className={classes.dropZone} onDrop={onDrop} onDragOver={onDragOver}>
      <div className={cx({ [classes.dropZoneMasked]: dragging })}>{children}</div>
      <div
        onDragLeave={onDragLeave}
        className={cx(classes.dropMask, {
          [classes.dropMaskVisible]: dragging,
        })}
      >
        {maskMessage}
      </div>
    </div>
  );
}

const useStyles = makeStylesHook({
  dropZone: {
    position: 'relative',
  },
  dropZoneMasked: {
    filter: 'opacity(0.5) blur(3px)',
  },
  dropMask: {
    border: `1px dashed ${Colors.DARK_BORDER}`,
    visibility: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  dropMaskVisible: {
    visibility: 'visible',
  },
});
