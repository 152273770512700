import { useMutation } from '@apollo/client';

import DatasetsDialog from 'client/app/apps/experiments/DatasetDialog';
import ExecutionsDialog from 'client/app/apps/experiments/ExecutionsDialog';
import { MUTATION_CREATE_EXPERIMENT_BLOCK } from 'client/app/apps/experiments/gql/mutations';
import { QUERY_EXPERIMENT } from 'client/app/apps/experiments/gql/queries';
import WorkflowsDialog from 'client/app/apps/experiments/Workflows/WorkflowsDialog';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import SimulationsDialog from 'client/app/components/Parameters/SimulationsDialog/SimulationsDialog';
import { CreateExperimentBlockMutation } from 'client/app/gql';
import { ExperimentBlockType as BlockType } from 'client/app/gql';
import useDialog from 'common/ui/hooks/useDialog';

type BlockTypeFiltered = Exclude<
  BlockType,
  BlockType.WORKFLOW | BlockType.SIMULATION | BlockType.DATASET
>;

export type CreateBlockProps =
  | { type: BlockType.SIMULATION; disabledSimulationIds?: SimulationId[] }
  | { type: BlockType.WORKFLOW; disabledWorkflowIds?: WorkflowId[] }
  | { type: 'execution'; disabledExecutionIds?: ExecutionId[] }
  | { type: BlockType.DATASET; disabledDatasetIds?: DatasetId[] }
  | { type: BlockTypeFiltered };

export const useCreateExperimentBlock = (
  experimentID: ExperimentId,
  insertBefore?: ExperimentModuleId,
) => {
  const [workflowsDialog, openWorkflowsDialog] = useDialog(WorkflowsDialog);
  const [simulationsDialog, openSimulationsDialog] = useDialog(SimulationsDialog);
  const [executionsDialog, openExecutionsDialog] = useDialog(ExecutionsDialog);
  const [datasetDialog, openDatasetDialog] = useDialog(DatasetsDialog);

  const [createBlockMutation] = useMutation(MUTATION_CREATE_EXPERIMENT_BLOCK);

  const createBlock = async (
    props: CreateBlockProps,
  ): Promise<
    | CreateExperimentBlockMutation['createExperimentBlock']
    | undefined
    | typeof CANCEL_CHOICE
  > => {
    let workflowID: WorkflowId | undefined;
    let simulationID: SimulationId | undefined;
    let datasetID: DatasetId | undefined;

    if (props.type === BlockType.WORKFLOW) {
      const pickedWorkflowId = await openWorkflowsDialog({
        disableRestoreFocus: true,
        disabledWorkflowIds: props.disabledWorkflowIds,
      });
      if (pickedWorkflowId === CANCEL_CHOICE) {
        return CANCEL_CHOICE;
      }
      workflowID = pickedWorkflowId;
    } else if (props.type === BlockType.SIMULATION) {
      const simulation = await openSimulationsDialog({
        disableRestoreFocus: true,
        disabledSimulationIds: props.disabledSimulationIds,
      });
      if (simulation === CANCEL_CHOICE) {
        return CANCEL_CHOICE;
      }
      simulationID = simulation.id;
    } else if (props.type === 'execution') {
      const execution = await openExecutionsDialog({
        disableRestoreFocus: true,
        disabledExecutionIds: props.disabledExecutionIds,
      });
      if (execution === CANCEL_CHOICE) {
        return CANCEL_CHOICE;
      }
      simulationID = execution.simulationId;
    } else if (props.type === BlockType.DATASET) {
      const dataset = await openDatasetDialog({
        disableRestoreFocus: true,
        excludedDatasetIds: props.disabledDatasetIds,
      });
      if (dataset === CANCEL_CHOICE) {
        return CANCEL_CHOICE;
      } else {
        datasetID = dataset;
      }
    }

    const type = props.type === 'execution' ? BlockType.SIMULATION : props.type;

    const { data } = await createBlockMutation({
      variables: {
        experimentID,
        insertBefore,
        type,
        workflowID,
        simulationID,
        datasetID,
      },
      refetchQueries: [{ query: QUERY_EXPERIMENT, variables: { id: experimentID } }],
    });

    return data?.createExperimentBlock;
  };

  return {
    createBlock,
    simulationsDialog,
    executionsDialog,
    workflowsDialog,
    datasetDialog,
  };
};
