import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';
import RouteIconButton from 'common/ui/components/navigation/RouteIconButton';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { NavigationRoute } from 'common/ui/navigation';

type Props<T> = {
  route: NavigationRoute<T>;
  routeParam: T;
  text: string;
};

/**
 * A banner with a back arrow icon and the workflow name.
 * Typical usage is below the Top Nav.
 */
export default function NavigateBackBanner<T>({ text, route, routeParam }: Props<T>) {
  const classes = useStyles();
  return (
    <div className={classes.topbar}>
      <RouteIconButton
        route={route}
        routeParam={routeParam}
        icon={<ArrowBackIcon className={classes.backIcon} />}
        disableRipple
        size="xsmall"
      />
      <Typography variant="h5">{text}</Typography>
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  topbar: {
    display: 'flex',
    backgroundColor: Colors.BLUE_0,
    color: theme.palette.primary.main,
    minHeight: '36px',
    alignItems: 'center',
    paddingLeft: theme.spacing(5),
    boxShadow: `0px 1px 3px ${Colors.GREY_30}`,
    zIndex: 1,
    gap: theme.spacing(2),
  },
  backIcon: {
    color: Colors.TEXT_PRIMARY,
  },
}));
