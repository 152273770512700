import React, { KeyboardEvent, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import DOMPurify from 'dompurify';

import {
  DESCRIPTION_HEIGHT,
  MIN_DESCRIPTION_WIDTH,
} from 'client/app/components/ElementGroup/constants';
import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import stopPropagation from 'common/lib/stopPropagation';
import { Group } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import Keys from 'common/ui/lib/keyboard';

type Props = {
  group: Group;
  onStartEditing: () => void;
  onFinishedEditing: () => void;
  show: boolean;
  editing: boolean;
  canAutofocus: boolean;
  shouldResize: boolean;
  zIndex?: number;
};

const ElementGroupDescription = ({
  group,
  onStartEditing,
  onFinishedEditing,
  show,
  editing,
  canAutofocus,
  shouldResize,
  zIndex,
}: Props) => {
  const styles = useStyles({ editing, shouldResize });
  const [textareaEl, setTextareaEl] = useState<HTMLTextAreaElement | null>(null);
  const [descriptionValue, setDescriptionValue] = useState(group.Meta.description ?? '');
  const dispatch = useWorkflowBuilderDispatch();

  useEffect(() => {
    if (editing && textareaEl && canAutofocus) {
      textareaEl.focus();
    }
  }, [editing, textareaEl, canAutofocus]);

  useEffect(() => {
    setDescriptionValue(group.Meta.description ?? '');
  }, [group.Meta.description]);

  const updateDescription = () => {
    const sanitized = DOMPurify.sanitize(descriptionValue.trim());
    const newDescription = sanitized === '' ? undefined : sanitized;

    dispatch({
      type: 'setElementGroupDescription',
      payload: {
        id: group.id,
        description: newDescription,
      },
    });

    onFinishedEditing();
  };

  const onKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === Keys.ESCAPE) {
      updateDescription();
    }
  };

  return (
    <Box position="absolute" top="100%" width="100%">
      <Grow in={show} style={{ zIndex }}>
        <Paper
          className={styles.description}
          elevation={1}
          onWheel={stopPropagation}
          onPointerDown={stopPropagation}
          onPointerMove={stopPropagation}
        >
          {editing ? (
            <textarea
              ref={setTextareaEl}
              className={styles.descriptionTextEdit}
              value={descriptionValue}
              onChange={e => setDescriptionValue(e.currentTarget.value)}
              placeholder="Enter a description for the group..."
              onBlur={updateDescription}
              onKeyDown={onKeyDown}
            />
          ) : (
            <div
              className={styles.descriptionContent}
              onDoubleClick={e => {
                e.stopPropagation();
                onStartEditing();
              }}
            >
              <Typography variant="body1">{group.Meta.description}</Typography>
            </div>
          )}
        </Paper>
      </Grow>
    </Box>
  );
};

const useStyles = makeStylesHook<string, { editing: boolean; shouldResize: boolean }>(
  ({ typography, spacing, palette }) => ({
    description: ({ editing, shouldResize }) => ({
      position: 'absolute',
      left: shouldResize ? `calc(50% - ${MIN_DESCRIPTION_WIDTH / 2}px)` : 0,
      width: shouldResize ? `max(100%, ${MIN_DESCRIPTION_WIDTH}px)` : '100%',
      borderRadius: '4px',
      marginTop: spacing(3),
      border: `1px solid ${Colors.GREY_40}`,
      ...(editing
        ? {
            height: DESCRIPTION_HEIGHT,
            display: 'grid',
            placeItems: 'stretch',
          }
        : {
            maxHeight: DESCRIPTION_HEIGHT,
            overflow: 'auto',
          }),
      '& ::selection': {
        backgroundColor: palette.primary.light,
      },
    }),
    descriptionContent: {
      padding: spacing(3, 4),
      whiteSpace: 'break-spaces',
      userSelect: 'none',
    },
    descriptionTextEdit: {
      ...typography.body1,
      padding: spacing(3, 4),
      border: 'none',
      borderRadius: '4px',
      resize: 'none',
    },
  }),
);

export default ElementGroupDescription;
