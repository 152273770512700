/** This is the JSON Schema (https://json-schema.org/) for
 * the Cherry Picker. It's the single source of truth for the
 * UI code and the Element code.
 */
export const schema = {
  definitions: {},
  type: 'object',
  required: ['SchemaVersion', 'SchemaType', 'Liquids', 'PickList', 'EnforceOrder'],
  properties: {
    SchemaVersion: {
      type: 'string',
    },
    SchemaType: {
      type: 'string',
    },
    Liquids: {
      items: {
        type: 'object',
        required: ['Name', 'Concentration', 'SubComponents'],
        properties: {
          Name: {
            type: 'string',
          },
          Concentration: {
            type: 'object',
            required: ['Value', 'Unit'],
            properties: {
              Value: {
                type: 'number',
              },
              Unit: {
                type: 'string',
              },
            },
          },
          SubComponents: {
            type: 'array',
          },
        },
      },
    },
    PickList: {
      type: 'array',
      items: {
        type: 'object',
        required: [
          'Source Plate Name',
          'Source Plate Type',
          'Source Well',
          'Source Liquid Name',
          'Transfer Volume',
          'Transfer Liquid Policy',
          'Destination Plate Name',
          'Destination Plate Type',
          'Destination Well',
        ],
        properties: {
          'Source Plate Name': {
            type: 'string',
          },
          'Source Plate Type': {
            type: 'string',
          },
          'Source Well': {
            type: 'string',
          },
          'Source Liquid Name': {
            type: 'string',
          },
          'Source Volume': {
            type: 'object',
            required: ['Value', 'Unit'],
            properties: {
              Value: {
                type: 'number',
              },
              Unit: {
                type: 'string',
              },
            },
          },
          'Transfer Volume': {
            type: 'object',
            required: ['Value', 'Unit'],
            properties: {
              Value: {
                type: 'number',
              },
              Unit: {
                type: 'string',
              },
            },
          },
          'Transfer Liquid Policy': {
            type: 'string',
          },
          'Destination Plate Name': {
            type: 'string',
          },
          'Destination Plate Type': {
            type: 'string',
          },
          'Destination Well': {
            type: 'string',
          },
        },
      },
    },
    EnforceOrder: {
      type: 'boolean',
    },
  },
};
