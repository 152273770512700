import { useContext } from 'react';

import { WorkTreeModeContext } from 'client/app/apps/work-tree/WorkTreeModeContext';
import { ArrayElement, ExperimentWorkTreeQuery } from 'client/app/gql';

type NodeState = {
  clickable: boolean;
  greyedOut: boolean;
  selectable: boolean;
  selected: boolean;
  highlighted: boolean;
};

type Block = ArrayElement<
  NonNullable<ExperimentWorkTreeQuery['experimentWorkTree']>['blocks']
>;

export function useNodeState(block: Block): NodeState {
  const context = useContext(WorkTreeModeContext);

  switch (context.mode) {
    case 'default': {
      const selectedVersion = context.selectedVersion;
      const isRootVersionBlock = block.id === selectedVersion?.parentBlockId;
      const isVersionOfRootBlock = block.id === selectedVersion?.versionId;
      const isDependantOfRootBlockVersion = selectedVersion?.dependants?.includes(
        block.id,
      );

      const greyedOut =
        !!selectedVersion &&
        !isRootVersionBlock &&
        !isVersionOfRootBlock &&
        !isDependantOfRootBlockVersion;

      return {
        clickable: false,
        greyedOut: greyedOut,
        selectable: false,
        selected: context.selectedBlocks.includes(block.id),
        highlighted: block.id === context.highlightedBlock,
      };
    }

    case 'creating-block': {
      const disabled =
        block.result?.type !== context.creatingBlock.inputResultRequirements.resultType &&
        !block.data?.versionData.some(
          version =>
            version.result?.type ===
            context.creatingBlock.inputResultRequirements.resultType,
        );

      const selected =
        context.selectedBlocks.includes(block.id) ||
        block.data?.versionData.some(version =>
          context.selectedBlocks.includes(version.id),
        );

      return {
        clickable: !disabled,
        greyedOut: disabled,
        selectable: !disabled,
        selected: !!selected,
        highlighted: false,
      };
    }

    case 'editing-hidden-blocks': {
      const hasHiddenParent = block.dependencies.some(parentBlockId =>
        context.hiddenBlocks.has(parentBlockId),
      );
      return {
        clickable: !hasHiddenParent,
        greyedOut: context.hiddenBlocks.has(block.id),
        selectable: false,
        selected: false,
        highlighted: false,
      };
    }
  }
}
