import React, { Fragment, useMemo } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import PlateContentsItemContainer from 'client/app/components/Parameters/PlateContents/PlateContentsItemContainer';
import PlateView from 'client/app/components/PlatePrep/PlateView';
import { formatMeasurementObj } from 'common/lib/format';
import { Mixture } from 'common/types/mix';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { getLayoutForWellSelector } from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';

type Props = {
  plate: PlateState;
  uniqueMixtures: Mixture[];
  liquidColors: LiquidColors;
};

export default function MixturesPlate({ plate, uniqueMixtures, liquidColors }: Props) {
  const deckLayout = useMemo(() => getLayoutForWellSelector(plate), [plate]);

  return (
    <>
      <PlateViewWrapper>
        <PlateView
          liquidColors={liquidColors}
          deckLayout={deckLayout}
          highlightedWellLocation={null}
          plate={plate}
        />
      </PlateViewWrapper>
      <MixtureCards>
        {uniqueMixtures.map(mixture => (
          <PlateContentsItemContainer
            key={mixture.id}
            title={mixture.name ?? ''}
            color={mixture.color}
          >
            {mixture.sub_liquids && (
              <MixtureCardSubtitle color="textPrimary" variant="subtitle2">
                Content Liquids
              </MixtureCardSubtitle>
            )}
            <MixtureContent>
              {[...(mixture.sub_liquids ?? [])]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(subLiquid => (
                  <Fragment key={subLiquid.name}>
                    <Typography variant="body2">{subLiquid.name}</Typography>
                    <MixtureContentVolume variant="body2">
                      {formatMeasurementObj(subLiquid.volume)}
                    </MixtureContentVolume>
                  </Fragment>
                ))}
            </MixtureContent>
            {mixture.solutes && (
              <MixtureCardSubtitle color="textPrimary" variant="subtitle2">
                Final Concentrations
              </MixtureCardSubtitle>
            )}
            <MixtureContent>
              {[...(mixture.solutes ?? [])]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((solute, idx) => (
                  <Fragment key={solute.name + idx}>
                    <Typography variant="body2">{solute.name}</Typography>
                    <MixtureContentVolume variant="body2">
                      {formatMeasurementObj(solute.concentration)}
                    </MixtureContentVolume>
                  </Fragment>
                ))}
            </MixtureContent>
          </PlateContentsItemContainer>
        ))}
      </MixtureCards>
    </>
  );
}

const PlateViewWrapper = styled('div')({
  gridArea: 'plate',
});

const MixtureCards = styled('div')(({ theme }) => ({
  gridArea: 'mixtures',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  overflowY: 'auto',
}));

const MixtureContent = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  rowGap: theme.spacing(3),
}));

const MixtureContentVolume = styled(Typography)({
  textAlign: 'right',
});

const MixtureCardSubtitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));
