import React from 'react';

// If the mouse moves less than two pixels between pointerdown and pointerup,
// we'll call this a click.
// Useful for deciding when a pointerup event was a click vs a drag.
const MAX_DISTANCE_FOR_CLICK_SQUARED = 4;

export type Point2D = { x: number; y: number };

export function getPosFromEvent(pointerEvent: React.PointerEvent | PointerEvent) {
  return { x: pointerEvent.clientX, y: pointerEvent.clientY };
}

export function isDrag(
  pointerDownPos: Point2D | null,
  pointerUpEvent: React.PointerEvent | PointerEvent,
) {
  if (!pointerDownPos) {
    return false;
  }
  const pointerUpPos = getPosFromEvent(pointerUpEvent);
  const distX = pointerDownPos.x - pointerUpPos.x;
  const distY = pointerDownPos.y - pointerUpPos.y;
  const distSquared = distX ** 2 + distY ** 2;
  return distSquared >= MAX_DISTANCE_FOR_CLICK_SQUARED;
}

export function isLeftMouseClick(
  event: React.PointerEvent<Element> | globalThis.PointerEvent,
) {
  return event.button === 0;
}

export function isLeftMousePressed(
  event: React.PointerEvent<Element> | globalThis.PointerEvent,
) {
  return event.buttons & 1;
}
