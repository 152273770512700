import React from 'react';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import cx from 'classnames';

import { switchFail } from 'common/assert';
import { lowerCaseExceptFirstLetter } from 'common/lib/strings';
import Colors from 'common/ui/Colors';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { LoadingIcon } from 'common/ui/icons/Loading';

const SimulationStatusIndicator = React.memo(function StatusIcon({
  status,
  className,
  isPolling,
  indeterminate,
}: {
  status: SimulationStatus;
  className?: string;
  isPolling?: boolean; // Set true if this component is actively being updated.
  indeterminate?: boolean;
}) {
  const classes = useStyles();
  let statusIcon;

  if (indeterminate) {
    statusIcon = (
      <RemoveCircleOutlineIcon
        htmlColor={Colors.WARNING_MAIN}
        titleAccess="Indeterminate"
      />
    );
  } else {
    switch (status) {
      case 'COMPLETED':
        statusIcon = (
          <CheckCircleOutlineOutlinedIcon
            htmlColor={Colors.SUCCESS_MAIN}
            titleAccess="Success"
          />
        );
        break;
      case 'FAILED':
      case 'UNKNOWN':
        statusIcon = (
          <ErrorOutlineOutlinedIcon htmlColor={Colors.ERROR_MAIN} titleAccess="Error" />
        );
        break;
      case 'RUNNING':
      case 'QUEUED':
      case 'RETRYABLE':
        statusIcon = isPolling ? (
          <CircularProgress size={20} className={classes.status} />
        ) : (
          <LoadingIcon titleAccess="Running" />
        );
        break;
      default:
        switchFail(status);
    }
  }
  return <div className={cx(classes.center, className)}>{statusIcon} </div>;
});

export function SimulationStatusIndicatorWithTooltip({
  status,
  className,
  isPolling,
  containerClassName,
  indeterminate,
}: {
  status: SimulationStatus;
  className?: string;
  isPolling?: boolean; // Set true if this component is actively being updated.
  containerClassName?: string;
  indeterminate?: boolean;
}) {
  return (
    <Tooltip title={indeterminate ? 'Indeterminate' : lowerCaseExceptFirstLetter(status)}>
      <div className={containerClassName}>
        <SimulationStatusIndicator
          status={status}
          className={className}
          isPolling={isPolling}
          indeterminate={indeterminate}
        />
      </div>
    </Tooltip>
  );
}
const useStyles = makeStylesHook(theme => ({
  center: {
    display: 'flex',
    margin: 'auto',
    placeContent: 'center',
  },
  status: {
    margin: theme.spacing(1),
  },
}));
