import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

const DeckLayoutsForUploadContext = createContext<{
  deckLayoutsForUpload: Map<string, boolean>;
  selectForUpload: (layoutLabel: string, select: boolean) => void;
}>({
  deckLayoutsForUpload: new Map<string, boolean>(),
  selectForUpload: () => {},
});

export default function DeckLayoutsForUploadContextProvider({
  runConfigs,
  children,
}: React.PropsWithChildren<{ runConfigs: DeviceRunConfigData[] }>) {
  const [deckLayoutsForUpload, setDeckLayoutsForUpload] = useState(() => {
    const layoutsMap = new Map<string, boolean>();
    for (const runConfig of runConfigs) {
      if (runConfig.ConfigLabel) {
        layoutsMap.set(runConfig.ConfigLabel, false);
      }
    }
    return layoutsMap;
  });

  const selectForUpload = useCallback((layoutLabel: string, select: boolean) => {
    setDeckLayoutsForUpload(oldMap => {
      const newMap = new Map(oldMap);
      if (newMap.has(layoutLabel)) {
        newMap.set(layoutLabel, select);
      }
      return newMap;
    });
  }, []);

  return (
    <DeckLayoutsForUploadContext.Provider
      value={useMemo(
        () => ({
          deckLayoutsForUpload,
          selectForUpload,
        }),
        [deckLayoutsForUpload, selectForUpload],
      )}
    >
      {children}
    </DeckLayoutsForUploadContext.Provider>
  );
}

export function useDeckLayoutsForUpload() {
  return useContext(DeckLayoutsForUploadContext);
}
