import { useMemo, useReducer } from 'react';

import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import { v4 } from 'uuid';

import {
  getBasicFactorType,
  getFactorDescriptor,
  isNumericFactor,
  isNumericParameter,
} from 'client/app/components/DOEBuilder/factorUtils';
import { SimpleMutualExclusionLevelDetail } from 'client/app/components/DOEFactorForm/components/SimpleMutualExclusionFactorLevelEditor';
import {
  FactorParameterInfo,
  MutualExclusionInfo,
} from 'client/app/components/DOEFactorForm/types';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { pluralize } from 'common/lib/format';
import { FactorItem, FactorItemType, FactorPath } from 'common/types/bundle';

export type DOEFactorFormErrors = {
  name?: string;
  unit?: string;
  levels?: string;
  levelValues?: Record<string, string>;
  levelFactorValues?: Record<string, string>;
  levelUnits?: Record<string, string>;
  unmappedLevels?: string;
  numberOfZeros?: string;
  derivingExpression?: string;
  sourceFactor?: string;
};

type Level = {
  id: string;
  value: string;
};

type FactorDetails = Omit<FactorItem, 'values'>;

type DOEFactorFormState = {
  errors?: DOEFactorFormErrors;
  showErrors: boolean;
  levels: Level[];
  levelMapping: Record<string, string>;
  levelsToMapCount: number;
  factor: FactorDetails;
  isCustom: boolean;
  isNew: boolean;
  isConstant: boolean;
  factorDescriptors: string[];
  unitOptions: string[] | null;
  simpleMutualExclusionLevelDetails: SimpleMutualExclusionLevelDetail[];
  isSimpleMutualExclusion: boolean;
  existingGroupNames: string[];
};

type DOEFactorFormAction =
  | {
      type: 'updateFactor';
      payload: Partial<FactorDetails>;
    }
  | {
      type: 'updateLevels';
      payload: Level[];
    }
  | {
      type: 'updateIsSimpleMutualExclusion';
      payload: boolean;
    }
  | {
      type: 'updateSimpleMutualExclusionLevelDetails';
      payload: SimpleMutualExclusionLevelDetail[];
    }
  | {
      type: 'updateDerivingExpression';
      payload: string;
    }
  | {
      type: 'updateSourceFactor';
      payload: {
        sourceFactor: FactorDetails['sourceFactor'];
        levelsToMapCount: number;
      };
    }
  | {
      type: 'mapFactorLevels';
      payload: {
        sourceFactorLevels: string[];
        derivedFactorLevel: string;
      };
    }
  | {
      type: 'showErrors';
    };

const reducer = produce(
  (draft: DOEFactorFormState, action: DOEFactorFormAction): DOEFactorFormState => {
    switch (action.type) {
      case 'updateFactor': {
        draft.factor = {
          ...draft.factor,
          ...action.payload,
        };
        break;
      }
      case 'updateLevels': {
        const oldLevels = [...draft.levels];
        const newLevels = action.payload;

        if (draft.factor.sourceFactor) {
          /**
           * In case of Derived Categorical factor we need to update the level mapping
           */
          draft.levelMapping = buildDerivedFactorLevelMapping(
            draft.levelMapping,
            oldLevels,
            newLevels,
          );
        }

        if (draft.isSimpleMutualExclusion) {
          draft.simpleMutualExclusionLevelDetails =
            syncLevelsAndSimpleMutualExclusionDetails(
              [...draft.simpleMutualExclusionLevelDetails],
              newLevels,
              draft.factor.unit ?? '',
            );
        }
        draft.levels = newLevels;
        break;
      }
      case 'showErrors': {
        draft.showErrors = true;
        break;
      }
      case 'updateIsSimpleMutualExclusion': {
        draft.isSimpleMutualExclusion = action.payload;
        if (draft.isSimpleMutualExclusion) {
          draft.simpleMutualExclusionLevelDetails =
            syncLevelsAndSimpleMutualExclusionDetails(
              [...draft.simpleMutualExclusionLevelDetails],
              draft.levels,
              draft.factor.unit ?? '',
            );
        }
        break;
      }
      case 'updateSimpleMutualExclusionLevelDetails': {
        draft.simpleMutualExclusionLevelDetails = action.payload;
        break;
      }
      case 'updateDerivingExpression': {
        draft.factor.derivingExpression = action.payload;
        break;
      }
      case 'updateSourceFactor': {
        draft.factor.sourceFactor = action.payload.sourceFactor;
        draft.levelsToMapCount = action.payload.levelsToMapCount;
        draft.levelMapping = {}; // reset mapping when user selects a different factor to derive from
        break;
      }
      case 'mapFactorLevels': {
        const { derivedFactorLevel, sourceFactorLevels } = action.payload;
        draft.levelMapping = mapSourceFactorLevelsToDerivedFactorLevel(
          draft.levelMapping,
          sourceFactorLevels,
          derivedFactorLevel,
        );
        break;
      }
    }

    draft.errors = getErrors(draft);

    if (!draft.errors) {
      draft.showErrors = false;
    }

    return draft;
  },
);

function buildDerivedFactorLevelMapping(
  valueMap: Record<string, string>,
  oldLevels: Level[],
  newLevels: Level[],
) {
  const newValueMap = { ...valueMap };

  for (const sourceFactorLevel in newValueMap) {
    const derivedFactorLevel = newValueMap[sourceFactorLevel];
    const oldLevel = oldLevels.find(level => level.value === derivedFactorLevel);
    const newLevel = newLevels.find(level => level.value === derivedFactorLevel);

    if (newLevel) {
      // corresponding derived factor level was unchanged so do nothing
    } else if (oldLevel) {
      // corresponding derived factor level was renamed or removed
      const correspondingNewLevel = newLevels.find(l => l.id === oldLevel.id);

      if (correspondingNewLevel) {
        // derived factor level was renamed so update the mapping
        newValueMap[sourceFactorLevel] = correspondingNewLevel.value;
      } else {
        // derived factor level was removed so remove the mapping
        delete newValueMap[sourceFactorLevel];
      }
    } else {
      throw new Error(
        `[DOEFactorForm reducer]: derivedFactorLevel="${derivedFactorLevel}" was not found`,
      );
    }
  }

  return newValueMap;
}

function syncLevelsAndSimpleMutualExclusionDetails(
  levelDetails: SimpleMutualExclusionLevelDetail[],
  newLevels: Level[],
  defaultUnit: string,
): SimpleMutualExclusionLevelDetail[] {
  const oldDetailIds = levelDetails.map(details => details.id);
  const newLevelIds = newLevels.map(level => level.id);

  const detailIdsToAdd = newLevelIds.filter(id => !oldDetailIds.includes(id));

  return levelDetails
    .filter(details => newLevelIds.includes(details.id))
    .concat(detailIdsToAdd.map(id => ({ id, factorValue: '', unit: defaultUnit })));
}

function mapSourceFactorLevelsToDerivedFactorLevel(
  valueMap: Record<string, string>,
  sourceFactorLevels: string[],
  derivedFactorLevel: string,
): Record<string, string> {
  const newValueMap = { ...valueMap };

  // Clear all level keys mapped to this level of the derived factor
  for (const sourceFactorLevel in newValueMap) {
    if (newValueMap[sourceFactorLevel] === derivedFactorLevel) {
      delete newValueMap[sourceFactorLevel];
    }
  }
  // Map selected levels to this level of the derived factor
  for (const sourceFactorLevel of sourceFactorLevels) {
    newValueMap[sourceFactorLevel] = derivedFactorLevel;
  }

  return newValueMap;
}

function getErrors(state: DOEFactorFormState): DOEFactorFormErrors | undefined {
  const result: DOEFactorFormErrors = {};

  const isNumeric =
    state.factor.typeName === 'continuous' ||
    state.factor.typeName === 'discrete' ||
    state.factor.typeName === 'ordinal';

  const isDerived = state.factor.variableTypeName === 'derived';

  const currentFactorDescriptor = getFactorDescriptor(state.factor);

  const groupFactors = mapStateToMutualExclusionFactors(
    state,
    undefined,
    state.factor.path,
  );

  if (state.isSimpleMutualExclusion) {
    if (state.factor.displayName === '') {
      result.name = 'Name is required';
    } else if (state.existingGroupNames.includes(state.factor.displayName)) {
      result.name = 'Name must be unique and cannot match mutual exclusion names';
    }
  } else {
    if (state.factor.displayName === '') {
      result.name = 'Factor name is required';
    } else if (state.factorDescriptors.includes(currentFactorDescriptor)) {
      result.name = 'Factor name must be unique';
    }
  }

  if (state.unitOptions && (!state.factor.unit || state.factor.unit === '')) {
    result.unit = 'Unit is required';
  }

  if (isDerived) {
    if (isNumeric && !state.factor.derivingExpression) {
      result.derivingExpression = 'Expression is required';
    }
    if (!isNumeric && !state.factor.sourceFactor) {
      result.sourceFactor = 'Source factor is required';
    }

    const unmappedLevelCount = state.levelsToMapCount - keys(state.levelMapping).length;
    if (!isNumeric && unmappedLevelCount > 0) {
      result.unmappedLevels = pluralize(unmappedLevelCount, 'level');
    }
  }

  if (!(isNumeric && isDerived)) {
    /**
     * Levels are only applicable to factors that are not Derived Numeric factors
     */
    if (state.levels.length === 0) {
      result.levels = 'One or more levels is required';
    } else {
      const allowSingleValueFactors =
        state.isConstant || state.factor.mutualExclusionGroup;
      if (state.levels.length === 1 && !allowSingleValueFactors) {
        result.levels = state.isSimpleMutualExclusion
          ? 'At least two levels are required.'
          : 'At least two levels are required. Single levels are only allowed for constants.';
      }

      if (state.isSimpleMutualExclusion) {
        const levelValueErrors = new Map<string, string>();
        const levelFactorValueErrors = new Map<string, string>();
        const levelUnitErrors = new Map<string, string>();

        const seenValues = new Set<string>();

        for (const level of state.levels) {
          const groupFactor = groupFactors.find(f => f.id === level.id);

          if (groupFactor) {
            const groupFactorDescriptor = getFactorDescriptor(groupFactor);

            if (level.value.trim() === '') {
              levelValueErrors.set(level.id, 'Level name is required');
            } else if (state.factorDescriptors.includes(groupFactorDescriptor)) {
              levelValueErrors.set(level.id, 'Level name cannot match factor names');
            } else if (seenValues.has(groupFactorDescriptor)) {
              levelValueErrors.set(level.id, 'Level value cannot be a duplicate');
            }

            if (levelValueErrors.size) {
              result.levelValues = Object.fromEntries(levelValueErrors);
            }

            if (groupFactor.values[0] === '') {
              levelFactorValueErrors.set(level.id, 'Level value is required');
            } else if (Number.isNaN(+groupFactor.values[0])) {
              levelFactorValueErrors.set(level.id, 'Level value must be a number');
            }
            if (levelFactorValueErrors.size) {
              result.levelFactorValues = Object.fromEntries(levelFactorValueErrors);
            }

            if (state.unitOptions && (!groupFactor.unit || groupFactor.unit === '')) {
              levelUnitErrors.set(level.id, 'Unit is required');
            }
            if (levelUnitErrors.size) {
              result.levelUnits = Object.fromEntries(levelUnitErrors);
            }

            seenValues.add(groupFactorDescriptor);
          }
        }
      } else {
        const seenValues = new Set<string>();

        const levelValueErrors = state.levels.flatMap(level => {
          if (level.value.trim() === '') {
            return [[level.id, 'Level value is required']];
          } else if (isNumeric && Number.isNaN(+level.value)) {
            return [[level.id, 'Level value must be a number']];
          } else if (seenValues.has(level.value)) {
            return [[level.id, 'Level value cannot be a duplicate']];
          } else if (
            isDerived &&
            !Object.values(state.levelMapping).includes(level.value)
          ) {
            return [[level.id, "Level hasn't been assigned any source factor levels"]];
          }

          seenValues.add(level.value);

          return [];
        });

        if (levelValueErrors.length) {
          result.levelValues = Object.fromEntries(levelValueErrors);
        }
      }
    }
  }

  const zeroCount = state.factor.numberOfZerosToInclude ?? -1;
  if (
    state.factor.sampleMode === 'continuous' &&
    (!Number.isInteger(zeroCount) || zeroCount < 0)
  ) {
    result.numberOfZeros = 'This value has to be a positive integer';
  }

  return isEmpty(result) ? undefined : result;
}

export function mapStateToFactor(
  state: DOEFactorFormState,
  parameterInfo?: FactorParameterInfo,
): FactorItem {
  const path = parameterInfo?.allowMultipleFactors
    ? ([...parameterInfo.path, state.factor.displayName] as FactorPath)
    : parameterInfo?.path;

  let values = state.levels.map(l => l.value.trim());
  let derivingExpression: FactorItem['derivingExpression'];
  let sourceFactor: FactorItem['sourceFactor'];

  if (state.factor.variableTypeName !== 'derived') {
    /**
     * Here we leave .derivingExpression or .sourceFactor undefined.
     * Because otherwise if the factor is not Derived, and has .derivingExpression or .sourceFactor defined
     * we will not be able to use it in any new deriving expression which is a contradiction.
     */
  } else if (isNumericFactor(state.factor)) {
    derivingExpression = state.factor.derivingExpression;
    values = []; // must come from derivingExpression, which is handled in visserver app
  } else {
    // values are known as the mapping of factors is simpler
    sourceFactor = state.factor.sourceFactor && {
      id: state.factor.sourceFactor.id,
      valueMap: state.levelMapping,
    };
  }

  /**
   * This value only makes sense for range sampling.
   * In case of discrete sampling design calculations will fail to consider number of zeros.
   */
  const numberOfZerosToInclude =
    state.factor.sampleMode === 'continuous'
      ? state.factor.numberOfZerosToInclude
      : undefined;

  return {
    ...state.factor,
    path,
    values,
    displayName: state.factor.displayName.trim(),
    derivingExpression,
    sourceFactor,
    numberOfZerosToInclude,
  };
}

export function mapStateToMutualExclusionFactors(
  state: DOEFactorFormState,
  parameterInfo?: FactorParameterInfo,
  pathOverride?: FactorPath,
): FactorItem[] {
  const groupName = state.factor.displayName.trim();

  return state.simpleMutualExclusionLevelDetails.map(details => {
    const displayName =
      state.levels.find(level => level.id === details.id)?.value.trim() ?? '';
    const path = pathOverride
      ? pathOverride
      : parameterInfo?.allowMultipleFactors
      ? ([...parameterInfo.path, displayName] as FactorPath)
      : parameterInfo?.path;

    return {
      ...state.factor,
      id: details.id,
      displayName,
      unit: details.unit,
      values: [details.factorValue],
      mutualExclusionGroup: groupName,
      path,
      typeName: 'continuous',
    };
  });
}

function initState([
  parameter,
  factor,
  factorDescriptors,
  existingGroupNames,
  typeToAdd,
  mutualExclusion,
  group,
]: [
  parameter: FactorParameterInfo | undefined,
  factor: FactorItem | null,
  factorDescriptors: string[],
  existingGroupNames: string[],
  typeToAdd: 'numerical-factor' | 'categorical-factor' | 'derived' | 'constant',
  mutualExclusion?: MutualExclusionInfo,
  group?: FactorItem[],
]): DOEFactorFormState {
  let initFactor: DOEFactorFormState['factor'];
  let initLevels: DOEFactorFormState['levels'];
  let unitOptions: string[] | null = null;
  let defaultUnit: string = '';
  let simpleMutualExclusionLevelDetails: SimpleMutualExclusionLevelDetail[] = [];

  const replicate = mutualExclusion?.replicate;

  if (parameter?.valueEditor?.additionalProps?.editor === EditorType.MEASUREMENT) {
    unitOptions = parameter?.valueEditor?.additionalProps?.units;
    defaultUnit = parameter?.valueEditor?.additionalProps.defaultUnit ?? '';
  }

  const numericParameter = isNumericParameter(parameter);

  const basicType: FactorItemType = getBasicFactorType(
    typeToAdd,
    parameter,
    mutualExclusion,
  );

  if (factor) {
    initFactor = { ...factor };
    initLevels = factor.values.map(value => ({ id: v4(), value }));
  } else if (group?.length) {
    initFactor = {
      id: v4(),
      displayName: group[0].mutualExclusionGroup ?? '',
      typeName: 'nominal',
      unit: defaultUnit,
      variableTypeName: 'factor',
      hardToChange: false,
      included: true,
      path: parameter?.path,
    };
    initLevels = group.map(groupFactor => ({
      id: groupFactor.id,
      value: groupFactor.displayName,
    }));
    simpleMutualExclusionLevelDetails = group.map(groupFactor => ({
      id: groupFactor.id,
      factorValue: groupFactor.values[0],
      unit: groupFactor.unit,
    }));
  } else {
    initFactor = {
      id: v4(),
      displayName: parameter?.allowMultipleFactors ? '' : parameter?.path[2] ?? '',
      typeName: basicType,
      unit: replicate?.unit ?? defaultUnit,
      variableTypeName:
        typeToAdd === 'constant' ||
        typeToAdd === 'numerical-factor' ||
        typeToAdd === 'categorical-factor'
          ? 'factor'
          : typeToAdd,
      hardToChange: false,
      included: true,
      path: parameter?.path,
      mutualExclusionGroup: mutualExclusion?.groupName,
    };

    if (replicate) {
      initLevels = replicate?.values.map(value => ({ id: v4(), value }));
    } else if (mutualExclusion?.levelCount) {
      initLevels = Array.from({ length: mutualExclusion.levelCount }, () => ({
        id: v4(),
        value: '',
      }));
    } else {
      initLevels = [
        {
          id: v4(),
          value: '',
        },
      ];
    }
  }

  if (basicType === 'continuous') {
    initFactor.sampleMode = factor?.sampleMode ?? 'discrete';
    initFactor.numberOfZerosToInclude = factor?.numberOfZerosToInclude ?? 0;
  }

  const groupFactorDescriptors = !group ? [] : group.map(f => getFactorDescriptor(f));
  const filteredFactorDescriptors = factor
    ? factorDescriptors.filter(descriptor => descriptor !== getFactorDescriptor(factor))
    : group
    ? factorDescriptors.filter(descriptor => !groupFactorDescriptors.includes(descriptor))
    : factorDescriptors;

  return {
    factor: initFactor,
    errors: !(!!factor || !!group) ? {} : undefined,
    showErrors: false,
    levels: initLevels,
    levelMapping: factor?.sourceFactor?.valueMap ?? {},
    levelsToMapCount: keys(factor?.sourceFactor?.valueMap).length,
    isCustom: !parameter,
    isNew: !(!!factor || !!group),
    isConstant: typeToAdd === 'constant',
    factorDescriptors: filteredFactorDescriptors,
    unitOptions,
    simpleMutualExclusionLevelDetails,
    isSimpleMutualExclusion:
      !!group || (numericParameter && typeToAdd === 'categorical-factor'),
    existingGroupNames: group?.length
      ? existingGroupNames.filter(groupName => groupName !== initFactor?.displayName)
      : existingGroupNames,
  };
}

export default function useDOEFactorForm(
  parameter: FactorParameterInfo | undefined = undefined,
  factor: FactorItem | null = null,
  factorDescriptors: string[],
  existingGroupNames: string[],
  typeToAdd:
    | 'numerical-factor'
    | 'categorical-factor'
    | 'derived'
    | 'constant' = 'numerical-factor',
  mutualExclusion?: MutualExclusionInfo,
  group?: FactorItem[],
) {
  const [state, dispatch] = useReducer(
    reducer,
    [
      parameter,
      factor,
      factorDescriptors,
      existingGroupNames,
      typeToAdd,
      mutualExclusion,
      group,
    ],
    initState,
  );

  const actions = useMemo(
    () => ({
      updateFactor: (factor: Partial<FactorDetails>) =>
        dispatch({ type: 'updateFactor', payload: factor }),
      updateLevels: (levels: Level[]) =>
        dispatch({ type: 'updateLevels', payload: levels }),
      updateIsSimpleMutualExclusion: (isSimpleMutualExclusion: boolean) =>
        dispatch({
          type: 'updateIsSimpleMutualExclusion',
          payload: isSimpleMutualExclusion,
        }),
      updateSimpleMutualExclusionLevelDetails: (
        levelDetails: SimpleMutualExclusionLevelDetail[],
      ) =>
        dispatch({
          type: 'updateSimpleMutualExclusionLevelDetails',
          payload: levelDetails,
        }),
      updateDerivingExpression: (derivingExpression: string) =>
        dispatch({ type: 'updateDerivingExpression', payload: derivingExpression }),
      updateSourceFactor: (
        sourceFactor: FactorItem['sourceFactor'],
        levelsToMapCount: number,
      ) =>
        dispatch({
          type: 'updateSourceFactor',
          payload: { sourceFactor, levelsToMapCount },
        }),
      mapFactorLevels: (sourceFactorLevels: string[], derivedFactorLevel: string) =>
        dispatch({
          type: 'mapFactorLevels',
          payload: { sourceFactorLevels, derivedFactorLevel },
        }),
      showErrors() {
        dispatch({ type: 'showErrors' });
      },
    }),
    [],
  );

  return { state, ...actions };
}
