import { useEffect, useMemo, useReducer } from 'react';

import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuid } from 'uuid';

import {
  Factor,
  FactorType,
} from 'client/app/components/Parameters/FiltrationProtocolDesign/types';

type Level = {
  id: string;
  value: string;
};

type Errors = {
  subComponentNames?: string;
  subComponentNamesValues?: Record<string, string>;
  levels?: string;
  levelValues?: Record<string, string>;
  name?: string;
  unit?: string;
};

type FactorFormState = {
  clientId: string;
  factorName: string;
  overrideDefaultFactorName: boolean;
  subComponentNames: Level[];
  metadataLabel: string;
  overrideMetadataLabel: boolean;
  type?: FactorType;
  levels: Level[];
  isNew: boolean;
  unit?: string;
  showErrors: boolean;
  errors?: Errors;
  otherFactorNames: string[];
};

const toLevel = (value: string | number): Level => ({ id: uuid(), value: `${value}` });

type FactorFormAction =
  | {
      type: 'updateType';
      payload: FactorType;
    }
  | { type: 'updateName'; payload: string }
  | { type: 'updateLevels'; payload: Level[] }
  | { type: 'updateNames'; payload: Level[] }
  | { type: 'updateUnit'; payload: string | undefined }
  | { type: 'updateMetadataLabel'; payload: string }
  | { type: 'toggleMetadataLabelOverride'; payload: boolean }
  | { type: 'toggleDefaultFactorNameOverride'; payload: boolean }
  | { type: 'showErrors' }
  | { type: 'setToValue'; value: Factor | undefined };

function getInitialState(
  factor?: Factor,
  otherFactorNames: string[] = [],
): FactorFormState {
  return {
    clientId: factor?.clientId ?? uuid(),
    factorName: factor?.name ?? '',
    type: factor?.type,
    levels: factor
      ? (factor.type === 'subComponent' ? factor.value.values : factor.value).map(toLevel)
      : [],
    isNew: !factor,
    ...(factor?.type === 'subComponent'
      ? {
          subComponentNames: factor.value.names.map(toLevel),
          unit: factor.value.unit,
        }
      : {
          subComponentNames: [],
        }),
    showErrors: false,
    errors: undefined,
    ...(factor?.type === 'categorical' || factor?.type === 'numeric'
      ? {
          metadataLabel: factor.metadataName ?? '',
          overrideMetadataLabel: !!factor.metadataName,
        }
      : { metadataLabel: '', overrideMetadataLabel: false }),
    overrideDefaultFactorName:
      factor?.type === 'subComponent' && factor.name !== factor.value.names.join(', '),
    otherFactorNames,
  };
}

export default function useFactorForm(factor?: Factor, otherFactorNames: string[] = []) {
  const [state, dispatch] = useReducer(
    factorFormReducer,
    factor,
    (factor): FactorFormState => getInitialState(factor, otherFactorNames),
  );

  useEffect(() => {
    dispatch({
      type: 'setToValue',
      value: factor,
    });
  }, [factor]);

  const actions = useMemo(
    () => ({
      updateType(type: FactorType) {
        dispatch({ type: 'updateType', payload: type });
      },
      updateName(name: string) {
        dispatch({ type: 'updateName', payload: name });
      },
      updateLevels(categories: Level[]) {
        dispatch({ type: 'updateLevels', payload: categories });
      },
      updateNames(names: Level[]) {
        dispatch({ type: 'updateNames', payload: names });
      },
      updateUnit(unit: string | undefined) {
        dispatch({ type: 'updateUnit', payload: unit });
      },
      showErrors() {
        dispatch({ type: 'showErrors' });
      },
      updateMetdataLabel(label: string) {
        dispatch({ type: 'updateMetadataLabel', payload: label });
      },
      toggleMetadataLabelOverride(toggle: boolean) {
        dispatch({ type: 'toggleMetadataLabelOverride', payload: toggle });
      },
      toggleDefaultFactorNameOverride(toggle: boolean) {
        dispatch({ type: 'toggleDefaultFactorNameOverride', payload: toggle });
      },
    }),
    [],
  );

  return { state, ...actions };
}

function factorFormReducer(
  state: FactorFormState,
  action: FactorFormAction,
): FactorFormState {
  if (action.type === 'setToValue') {
    return getInitialState(action.value, state.otherFactorNames);
  }

  return produce(state, draft => {
    switch (action.type) {
      case 'updateType':
        if (action.payload !== draft.type) {
          draft.type = action.payload;
          draft.levels = [{ id: uuid(), value: '' }];
          draft.subComponentNames =
            action.payload === 'subComponent' ? [{ id: uuid(), value: '' }] : [];
        }
        break;
      case 'updateName':
        draft.factorName = action.payload;
        break;
      case 'updateLevels':
        draft.levels = action.payload;
        break;
      case 'updateNames':
        draft.subComponentNames = action.payload;
        if (!draft.overrideDefaultFactorName) {
          draft.factorName = draft.subComponentNames.map(level => level.value).join(', ');
        }
        break;
      case 'updateUnit':
        draft.unit = action.payload;
        break;
      case 'showErrors':
        draft.showErrors = true;
        break;
      case 'updateMetadataLabel':
        draft.metadataLabel = action.payload;
        break;
      case 'toggleMetadataLabelOverride':
        draft.overrideMetadataLabel = action.payload;
        if (!action.payload) {
          draft.metadataLabel = '';
        }
        break;
      case 'toggleDefaultFactorNameOverride':
        draft.overrideDefaultFactorName = action.payload;
        if (!action.payload) {
          draft.factorName = draft.subComponentNames.map(level => level.value).join(', ');
        }
        break;
    }

    const errors = getErrors(draft);

    draft.errors = errors;

    if (!errors) {
      draft.showErrors = false;
    }
  });
}

export function mapStateToFactor(state: FactorFormState): Factor | null {
  if (state.type) {
    switch (state.type) {
      case 'categorical':
        return {
          clientId: state.clientId,
          name: state.factorName,
          type: 'categorical',
          value: state.levels.map(c => c.value),
          metadataName: state.metadataLabel,
        };
      case 'numeric':
        return {
          clientId: state.clientId,
          name: state.factorName,
          type: 'numeric',
          value: state.levels.map(n => parseFloat(n.value)),
          metadataName: state.metadataLabel,
        };
      case 'subComponent': {
        return {
          clientId: state.clientId,
          name: state.factorName,
          type: 'subComponent',
          value: {
            names: state.subComponentNames.map(n => n.value),
            values: state.levels.map(n => parseFloat(n.value)),
            unit: state.unit ?? 'mM',
          },
        };
      }
    }
  }

  return null;
}

function getErrors(state: FactorFormState): Errors | undefined {
  const result: Errors = {};

  if (state.type === 'subComponent') {
    if (state.subComponentNames.length === 0) {
      result.subComponentNames = 'One or more sub-component is required';
    } else {
      const namesValues = state.subComponentNames
        .filter(l => l.value === '')
        .map(l => [l.id, 'Sub-component name cannot be empty']);

      if (namesValues.length) {
        result.subComponentNamesValues = Object.fromEntries(namesValues);
      }
    }

    if (state.unit === '' || state.unit === undefined) {
      result.unit = 'Unit cannot be empty';
    }
  } else {
    if (state.factorName === '') {
      result.name = 'Factor name cannot be empty';
    }
  }

  const requireNumber = state.type === 'subComponent' || state.type === 'numeric';

  if (state.levels.length === 0) {
    result.levels = 'One or more level values is required';
  } else {
    const levelsValues = state.levels.flatMap(level => {
      if (level.value === '') {
        return [[level.id, 'Level value cannot be empty']];
      } else if (requireNumber && Number.isNaN(Number.parseFloat(level.value))) {
        return [[level.id, 'Level value must be a number']];
      }

      return [];
    });

    if (levelsValues.length) {
      result.levelValues = Object.fromEntries(levelsValues);
    }
  }

  if (!result.name && state.otherFactorNames.includes(state.factorName)) {
    result.name = `The factor name must be unique`;
  }

  if (isEmpty(result)) {
    return undefined;
  }

  return result;
}
