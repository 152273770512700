import React, { useCallback, useEffect, useRef, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import Fab from '@mui/material/Fab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useQueryParam } from 'use-query-params';

import { ExampleWorkflow } from 'client/app/components/cards/ExampleWorkflowCard';
import CategoriesList from 'client/app/components/ExampleWorkflows/CategoriesList';
import ExampleWorkflowsList from 'client/app/components/ExampleWorkflows/ExampleWorkflowsList';
import {
  LSKEY_HIDE_EXAMPLE_WORKFLOWS_DIALOG_ON_NEW_WORKFLOW,
  OPEN_EXAMPLE_WORKFLOWS_DIALOG_PARAM,
  useCreateWorkflowFromExampleAndNavigate,
} from 'client/app/components/ExampleWorkflows/exampleWorkflowsUtils';
import { HeaderWithDescription } from 'client/app/components/ExampleWorkflows/HeaderWithDescription';
import WorkflowPreview from 'client/app/components/ExampleWorkflows/WorkflowPreview';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { simulationRoutes, workflowRoutes } from 'client/app/lib/nav/actions';
import { Markdown } from 'common/lib/markdown';
import Colors from 'common/ui/Colors';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import IconButton from 'common/ui/components/IconButton';
import { MarkdownPreview } from 'common/ui/components/MarkdownPreview';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import Tooltip from 'common/ui/components/Tooltip';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import { DialogProps } from 'common/ui/hooks/useDialog';
import useStateWithLocalStorage from 'common/ui/hooks/useStateWithLocalStorage';
import { SimulationIcon } from 'common/ui/icons/SimulationIcon';
import { WorkflowIcon } from 'common/ui/icons/Workflow';
import { trackHeapEvent } from 'common/ui/useHeapTracking';

type Props = DialogProps<void> & {
  initialExampleWorkflow?: ExampleWorkflow;
  shouldShowConfirmationDialog?: boolean;
};

/**
 * Dialog component for allowing users to select from a List of ExampleWorkflows
 * and inspect Details of each ExampleWorkflow.
 *
 * @param initialExampleWorkflow If provided, will launch only in Details mode and prevent
 * the user from accessing the List mode.
 */
function ExampleWorkflowsDialog(props: Props) {
  const { isOpen, onClose, initialExampleWorkflow, shouldShowConfirmationDialog } = props;

  // The dialog can be launched from an example workflow list directly and in this
  // case an initialExampleWorkflow would be provided.
  const hasLaunchedFromExample = !!initialExampleWorkflow;

  const [exampleWorkflow, setExampleWorkflow] = useState<ExampleWorkflow | undefined>(
    initialExampleWorkflow,
  );

  useEffect(() => {
    setExampleWorkflow(initialExampleWorkflow);
  }, [initialExampleWorkflow]);

  const [isPreferenceToHideDialog, setIsPreferenceToHideDialog] =
    useStateWithLocalStorage<boolean>(
      LSKEY_HIDE_EXAMPLE_WORKFLOWS_DIALOG_ON_NEW_WORKFLOW,
      false,
    );

  const [_, setOpenExampleDialog] = useQueryParam<boolean | undefined>(
    OPEN_EXAMPLE_WORKFLOWS_DIALOG_PARAM,
  );

  const handleClose = useCallback(() => {
    setOpenExampleDialog(undefined, 'replaceIn');
    onClose();
  }, [onClose, setOpenExampleDialog]);

  const handleCloseAndTrack = useCallback(() => {
    handleClose();
    trackHeapEvent('close-example-dialog');
  }, [handleClose]);

  const handleSetPreferenceToHideDialog = useCallback(
    (checked: boolean) => {
      setIsPreferenceToHideDialog(checked);
      trackHeapEvent('set-preference-to-hide-dialog', {
        isPreferenceToHideDialog: checked === true ? 'true' : 'false',
      });
    },
    [setIsPreferenceToHideDialog],
  );

  const handleDetailsBackClick = useCallback(() => {
    setExampleWorkflow(undefined);
  }, []);

  return (
    <StyledDialog
      open={isOpen}
      onClose={handleCloseAndTrack}
      maxWidth="lg"
      fullWidth
      TransitionProps={{
        unmountOnExit: true,
        onExited: hasLaunchedFromExample
          ? undefined
          : () => setExampleWorkflow(undefined),
      }} // Reset only at end of Transition exit to prevent content of dialog from shifting.
    >
      {exampleWorkflow ? (
        <ExampleWorkflowDetails
          exampleWorkflow={exampleWorkflow}
          onClose={handleCloseAndTrack}
          onBackClick={hasLaunchedFromExample ? undefined : handleDetailsBackClick}
          onUseExampleWorkflowClick={handleClose}
          shouldShowConfirmationDialog={shouldShowConfirmationDialog}
        />
      ) : (
        <ExampleWorkflowsListContent
          onClose={handleCloseAndTrack}
          onExampleWorkflowDetailsClick={setExampleWorkflow}
          isPreferenceToHideDialog={isPreferenceToHideDialog}
          handleSetPreferenceToHideDialog={handleSetPreferenceToHideDialog}
        />
      )}
    </StyledDialog>
  );
}

type ExampleWorkflowsListProps = {
  onClose: () => void;
  onExampleWorkflowDetailsClick: (exampleWorkflow: ExampleWorkflow) => void;
  isPreferenceToHideDialog: boolean;
  handleSetPreferenceToHideDialog: (checked: boolean) => void;
};

function ExampleWorkflowsListContent(props: ExampleWorkflowsListProps) {
  const {
    onClose,
    onExampleWorkflowDetailsClick,
    isPreferenceToHideDialog,
    handleSetPreferenceToHideDialog,
  } = props;

  const [searchQuery, setSearchQuery] = useState('');
  const [tagQuery, setTagQuery] = useState('');

  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <StyledDialogHeader>
        <Typography variant="h2">Get started</Typography>
        <StyledDialogHeaderRightContentContainer>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isPreferenceToHideDialog}
                onChange={(_, checked) => handleSetPreferenceToHideDialog(checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Do not show this window when creating a new workflow"
          />
          <IconButton
            icon={<CloseIcon />}
            onClick={onClose}
            color="inherit"
            size="small"
          />
        </StyledDialogHeaderRightContentContainer>
      </StyledDialogHeader>
      <HeaderWithDescription searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      <CategoriesAndExampleWorkflowsWrapper>
        <CategoriesList selectedTag={tagQuery} onSelectTag={setTagQuery} />
        <ContainerWithIntersectionBar
          scrollableRef={scrollableContainerRef}
          noHeader
          content={
            <ExampleWorkflowsListWrapper>
              <ExampleWorkflowsList
                scrollableContainerRef={scrollableContainerRef}
                searchQuery={searchQuery}
                tagQuery={tagQuery}
                onExampleWorkflowDetailsClick={onExampleWorkflowDetailsClick}
                logCategory="dialog"
              />
            </ExampleWorkflowsListWrapper>
          }
          dense
        />
      </CategoriesAndExampleWorkflowsWrapper>
    </>
  );
}

type ExampleWorkflowDetailsProps = {
  exampleWorkflow: ExampleWorkflow;
  onClose: () => void;
  onUseExampleWorkflowClick: () => void;
  onBackClick?: () => void;
  shouldShowConfirmationDialog?: boolean;
};

function ExampleWorkflowDetails(props: ExampleWorkflowDetailsProps) {
  const {
    onClose,
    onBackClick,
    exampleWorkflow,
    onUseExampleWorkflowClick,
    shouldShowConfirmationDialog,
  } = props;

  const {
    handleCreateWorkflowFromExample,
    busy: shouldDisableWorkflowNavigation,
    confirmationDialog,
  } = useCreateWorkflowFromExampleAndNavigate(
    exampleWorkflow.id,
    onUseExampleWorkflowClick,
    shouldShowConfirmationDialog,
  );

  const handleCreateWorkflowFromExampleAndTrack = useCallback(() => {
    trackHeapEvent('start-from-example');
    handleCreateWorkflowFromExample();
  }, [handleCreateWorkflowFromExample]);

  const navigation = useNavigation();

  const userProfile = useUserProfile();
  const showEditButtons =
    userProfile?.isExampleWorkflowsSourceOrg && userProfile?.isSynthaceEmployee;

  const representativeSimulationId = exampleWorkflow.representativeSimulation?.id;
  const editableWorkflowId =
    exampleWorkflow.representativeSimulation?.editableWorkflow?.id;

  const handleNavigateToRepresentativeSimulation = () => {
    if (!representativeSimulationId) {
      return;
    }
    navigation.navigate(simulationRoutes.openInSimulationDetails, {
      simulationId: representativeSimulationId,
    });
  };

  const handleNavigateToEditableWorkflow = () => {
    if (!editableWorkflowId) {
      return;
    }
    navigation.navigate(workflowRoutes.openInWorkflowBuilder, {
      workflowId: editableWorkflowId,
    });
  };

  return (
    <>
      <StyledDialogHeader>
        {onBackClick && (
          <IconButton
            onClick={onBackClick}
            icon={<ArrowBackIcon />}
            color="inherit"
            size="small"
          />
        )}
        <TypographyWithTooltip variant="h2">{exampleWorkflow.name}</TypographyWithTooltip>
        <StyledDialogHeaderRightContentContainer>
          <IconButton
            icon={<CloseIcon />}
            onClick={onClose}
            color="inherit"
            size="small"
          />
        </StyledDialogHeaderRightContentContainer>
      </StyledDialogHeader>
      <Stack gap={5} overflow="auto" paddingRight={3} marginRight={-3} height="100%">
        <WorkflowPreview workflowId={exampleWorkflow.workflowId} />
        <StyledButtonsStack gap={5} direction="row">
          <Fab
            variant="extended"
            color="primary"
            onClick={handleCreateWorkflowFromExampleAndTrack}
            disabled={shouldDisableWorkflowNavigation}
          >
            <Stack direction="row" gap={3} alignItems="center">
              <FileCopyIcon fontSize="small" />
              <Typography variant="button" whiteSpace="nowrap">
                Start from example
              </Typography>
            </Stack>
          </Fab>
          {showEditButtons && (
            <>
              <Tooltip
                title={
                  representativeSimulationId
                    ? 'Go to the Simulation to edit the summary and details from the top-right menu on the Simulation page.'
                    : 'The Simulation associated with this Example Workflow cannot be found.'
                }
              >
                <span>
                  <Fab
                    onClick={handleNavigateToRepresentativeSimulation}
                    disabled={!representativeSimulationId}
                    size="medium"
                    color="secondary"
                  >
                    <SimulationIcon />
                  </Fab>
                </span>
              </Tooltip>
              <Tooltip
                title={
                  editableWorkflowId
                    ? 'Go to the Parent Workflow to edit the name.'
                    : 'The Parent Workflow associated with this Example Workflow cannot be found.'
                }
              >
                <span>
                  <Fab
                    onClick={handleNavigateToEditableWorkflow}
                    disabled={!editableWorkflowId}
                    size="medium"
                    color="secondary"
                  >
                    <WorkflowIcon />
                  </Fab>
                </span>
              </Tooltip>
            </>
          )}
        </StyledButtonsStack>
        <StyledSummaryAndDescriptionStack
          rightMargin={showEditButtons ? 356 : 224}
          height={80}
          flexGrow={1}
          gap={6}
        >
          <Stack gap={3}>
            <Typography variant="h2">Summary</Typography>
            <Typography variant="body1">{exampleWorkflow.summary}</Typography>
          </Stack>
          <Stack>
            <Typography variant="h2">Description</Typography>
            <Typography variant="body1" component="div">
              <MarkdownPreview markdown={exampleWorkflow.description as Markdown} />
            </Typography>
          </Stack>
        </StyledSummaryAndDescriptionStack>
      </Stack>
      {confirmationDialog}
    </>
  );
}

const StyledDialog = styled(Dialog)(({ theme: { spacing } }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: Colors.GREY_5,
    padding: spacing(6),
    height: '100%',
  },
}));

const StyledDialogHeader = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  minHeight: '38px',
  marginBottom: spacing(5),
  gap: spacing(3),
}));

const StyledDialogHeaderRightContentContainer = styled('div')({
  marginLeft: ' auto',
  display: 'flex',
  alignItems: 'center',
});

const StyledButtonsStack = styled(Stack)({
  position: 'sticky',
  top: 0,
  justifyContent: 'end',
  alignItems: 'start',
  height: 'min-content',
});

const StyledSummaryAndDescriptionStack = styled(Stack, {
  shouldForwardProp: prop => prop !== 'rightMargin',
})<{ rightMargin: number }>(({ rightMargin }) => ({
  marginTop: '-65px',
  width: `calc(100% - ${rightMargin}px)`,
}));

const ExampleWorkflowsListWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
}));

const CategoriesAndExampleWorkflowsWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'minmax(180px, 0.75fr) 4fr',
  columnGap: theme.spacing(8),
  overflow: 'hidden',
}));

export default ExampleWorkflowsDialog;
