import React from 'react';

import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

export const FeatureTogglesFallback = (): JSX.Element => {
  return (
    <>
      <DialogTitle>
        <Typography variant="h3">Feature toggles</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="caption" align="center">
          You do not have permission to view feature toggles. Please use the in-app chat
          to contact us if you require access.
        </Typography>
      </DialogContent>
    </>
  );
};
