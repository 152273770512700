import React from 'react';

import cx from 'classnames';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const INTERSECTION_BAR_THICKNESS = 4;

/**
 * A horizontal rule that appears at the top of a container when it is scrolled.
 */
export function IntersectionBar() {
  const classes = useStyles();
  // The bar cover must be the same color as the container background, which we
  // automatically detect when the component is mounted.
  const [barCoverBackground, setBarCoverBackground] = React.useState('white');
  return (
    <>
      <div
        className={classes.barCoverContainer}
        ref={e => e && setBarCoverBackground(getParentBGColor(e))}
      >
        <div
          className={cx(classes.barCover, classes.barCommon)}
          style={{ borderColor: barCoverBackground }}
        />
      </div>
      <div className={cx(classes.intersectionBar, classes.barCommon)} />
    </>
  );
}

const useStyles = makeStylesHook({
  // The bar sticks to the top of the container. It is initially covered by a div with the
  // container background, which is shifted up as the user scrolls to reveal the bar.
  intersectionBar: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    borderColor: Colors.BLUE_90,
  },
  barCoverContainer: {
    position: 'relative',
  },
  barCover: {
    position: 'absolute',
    zIndex: 2,
  },
  barCommon: {
    width: '100%',
    // using a border means the bar height will not be affected by flex or grid layouts
    // used in the container
    borderTopWidth: INTERSECTION_BAR_THICKNESS,
    borderTopStyle: 'solid',
  },
});

/**
 * Iterate through the ancestors of the given element until an element with a background
 * color is found.
 */
function getParentBGColor(el: HTMLElement): string {
  if (!el.parentElement) {
    return 'white';
  }
  const parentBG = getComputedStyle(el.parentElement).backgroundColor;
  // A transparent or unset background is serialised as rgba(0, 0, 0, 0) in modern
  // browsers. https://www.w3.org/TR/css-color-4/#serializing-other-colors
  if (parentBG === 'rgba(0, 0, 0, 0)') {
    return getParentBGColor(el.parentElement);
  }
  return parentBG;
}
