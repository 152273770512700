import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const LoadingIcon = React.memo<SvgIconProps>(function LoadingIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" fill="none">
        <circle
          cx="11.833"
          cy="11.833"
          r="9.861"
          stroke="currentColor"
          strokeWidth="1.972"
        />
        <circle cx="7.437" cy="11.957" r="1.479" fill="currentColor" />
        <circle cx="11.874" cy="11.957" r="1.479" fill="currentColor" />
        <circle cx="16.312" cy="11.957" r="1.479" fill="currentColor" />
      </svg>
    </SvgIcon>
  );
});
