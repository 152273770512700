import React from 'react';

import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import {
  BlueChip,
  ChipRow,
  ExpandButton,
  TableCell,
  VisibilityIcon,
} from 'client/app/components/ElementPlumber/ElementOutputs/components/styles';
import { OutputEntity } from 'client/app/components/ElementPlumber/ElementOutputs/types';
import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import { pluralize } from 'common/lib/format';
import { DataTable } from 'common/types/spreadsheetEditor';

type Props = {
  elementId: string;
  parameterName: string;
  value: DataTable;
  entity?: OutputEntity;
  withDropdown?: boolean;
  onClick?: (entity?: OutputEntity) => void;
};

export default function DataTableOutput({
  elementId,
  parameterName,
  value: table,
  entity: activeEntity,
  withDropdown = false,
  onClick: handleClick,
}: Props) {
  const dispatch = useWorkflowBuilderDispatch();
  const handleOpenOutputPreview = () => {
    dispatch({
      type: 'openOutputPreview',
      payload: {
        selectedElementId: elementId,
        selectedOutputParameterName: parameterName,
        outputType: 'DataTable',
        entityView: activeEntity ?? 'rows',
      },
    });
  };
  const toggleEntity = (entity: OutputEntity) => {
    handleClick?.(entity !== activeEntity ? entity : undefined);
  };

  const rowCount = table.data.length;
  const columnCount = table.schema.fields.length;

  return (
    <Stack>
      <Stack direction="row" gap={3} padding={3} alignItems="center">
        <VisibilityIcon />
        <ChipRow>
          <BlueChip active={activeEntity === 'rows'} onClick={() => toggleEntity('rows')}>
            <Typography variant="caption" textTransform="capitalize" fontWeight={500}>
              {pluralize(rowCount, 'row')}
            </Typography>
          </BlueChip>
          <BlueChip active={activeEntity === 'cols'} onClick={() => toggleEntity('cols')}>
            <Typography variant="caption" textTransform="capitalize" fontWeight={500}>
              {pluralize(columnCount, 'column')}
            </Typography>
          </BlueChip>
          {withDropdown && activeEntity && (
            <ExpandButton
              icon={<OpenInNewRoundedIcon />}
              size="xsmall"
              onClick={handleOpenOutputPreview}
            />
          )}
        </ChipRow>
      </Stack>
      {withDropdown && activeEntity && (
        <>
          <Divider />
          <Stack width={284} maxHeight={240} pb={3} overflow="auto">
            {activeEntity === 'rows' && <RowsContent data={table} />}
            {activeEntity === 'cols' && <ColsContent data={table.schema} />}
          </Stack>
        </>
      )}
    </Stack>
  );
}

const MAX_ROWS_TO_DISPLAY = 5;

const RowsContent = React.memo(function RowsContent({
  data: { data, schema },
}: {
  data: DataTable;
}) {
  const remainingRowCount = data.length - MAX_ROWS_TO_DISPLAY;
  const rowCopy = remainingRowCount > 1 ? 'rows' : 'row';

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {schema.fields.map(field => (
              <TableCell key={field.name}>
                <Typography variant="body1" whiteSpace="nowrap" fontWeight={500}>
                  {field.name}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.slice(0, MAX_ROWS_TO_DISPLAY).map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {schema.fields.map(field => (
                <TableCell key={field.name}>
                  <Typography variant="body1" whiteSpace="nowrap">
                    {row[field.name] ?? '-'}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {remainingRowCount > 0 && (
        <Typography variant="body1" pt={3} px={3} color="textSecondary">
          {remainingRowCount} more {rowCopy}...
        </Typography>
      )}
    </>
  );
});

const ColsContent = React.memo(function ColsContent({
  data,
}: {
  data: DataTable['schema'];
}) {
  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="body1" whiteSpace="nowrap" fontWeight={500}>
              Column
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1" whiteSpace="nowrap" fontWeight={500}>
              Type
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.fields.map(field => (
          <TableRow key={field.name}>
            <TableCell>
              <Typography variant="body1" whiteSpace="nowrap">
                {field.name}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1" whiteSpace="nowrap" textTransform="capitalize">
                {field.type}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
});
