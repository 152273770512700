import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { LogoColours } from 'common/ui/lib/envs';

const SynthaceBrandmarkLight = React.memo<SvgIconProps & { logoColour: LogoColours }>(
  function SynthaceBrandmarkLight({ logoColour, ...props }) {
    switch (logoColour) {
      case 'purple':
        return (
          <SvgIcon viewBox="0 0 513 432" {...props}>
            <g opacity=".9">
              <path
                opacity=".9"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M284.463 8.659c-25.124-15.681-58.224-8.04-73.931 17.068L8.169 349.2c-15.707 25.107-8.073 58.173 17.05 73.854 25.124 15.681 58.224 8.039 73.931-17.069L301.514 82.513c15.707-25.108 8.073-58.173-17.05-73.854zm135.079 107.235c-25.124-15.681-58.224-8.039-73.931 17.069L210.333 349.201c-15.707 25.107-8.073 58.173 17.051 73.854 25.123 15.681 58.224 8.039 73.931-17.069l135.277-216.238c15.707-25.107 8.074-58.173-17.05-73.854z"
                fill="url(#paint0_linear)"
              />
              <g opacity=".9">
                <path
                  d="M25.22 8.659C50.343-7.022 83.442.619 99.15 25.727L301.514 349.2c15.707 25.107 8.073 58.173-17.05 73.854-25.124 15.681-58.224 8.039-73.931-17.069L8.169 82.513C-7.538 57.405.096 24.34 25.219 8.659z"
                  fill="url(#paint1_linear)"
                />
                <path
                  d="M227.544 8.66c25.123-15.681 58.224-8.04 73.931 17.068L503.837 349.2c15.707 25.107 8.074 58.173-17.05 73.854-25.124 15.681-58.224 8.039-73.931-17.069L210.493 82.514C194.786 57.406 202.42 24.34 227.544 8.66z"
                  fill="url(#paint2_linear)"
                />
              </g>
              <path
                opacity=".6"
                d="M53.648 431.315c-29.625 0-53.642-24.015-53.642-53.64 0-29.624 24.017-53.64 53.642-53.64 29.626 0 53.642 24.016 53.642 53.64 0 29.625-24.016 53.64-53.642 53.64zM53.648 107.78C24.022 107.78.006 83.765.006 54.14S24.022.5 53.648.5c29.625 0 53.642 24.015 53.642 53.64s-24.017 53.64-53.642 53.64z"
                fill="#D07EFF"
              />
              <path
                d="M458.348 107.794c-29.625 0-53.641-24.016-53.641-53.64 0-29.625 24.016-53.64 53.641-53.64 29.626 0 53.642 24.015 53.642 53.64 0 29.624-24.016 53.64-53.642 53.64z"
                fill="#9710FF"
              />
              <path
                opacity=".6"
                d="M458.314 431.245c-29.625 0-53.642-23.989-53.642-53.58 0-29.591 24.017-53.579 53.642-53.579 29.626 0 53.642 23.988 53.642 53.579s-24.016 53.58-53.642 53.58z"
                fill="#B33EFF"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="496.003"
                y1="298.5"
                x2=".491"
                y2="314.055"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9710FF" />
                <stop offset="1" stopColor="#D07EFF" />
              </linearGradient>
              <linearGradient
                id="paint1_linear"
                x1="512.003"
                y1="242.5"
                x2="13.677"
                y2="323.071"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9710FF" />
                <stop offset="1" stopColor="#D07EFF" />
              </linearGradient>
              <linearGradient
                id="paint2_linear"
                x1="512.003"
                y1="242.5"
                x2="13.677"
                y2="323.071"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9710FF" />
                <stop offset="1" stopColor="#D07EFF" />
              </linearGradient>
            </defs>
          </SvgIcon>
        );
      case 'orange':
        return (
          <SvgIcon viewBox="0 0 512 432" {...props}>
            <g opacity=".9">
              <path
                opacity=".9"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M284.459 8.659c-25.123-15.681-58.223-8.04-73.931 17.068L8.166 349.2c-15.707 25.107-8.074 58.173 17.05 73.854 25.124 15.681 58.224 8.039 73.931-17.069L301.51 82.513c15.707-25.108 8.073-58.173-17.051-73.854zm135.08 107.235c-25.124-15.681-58.224-8.039-73.931 17.069L210.33 349.201c-15.707 25.107-8.073 58.173 17.05 73.854 25.124 15.681 58.224 8.039 73.931-17.069l135.278-216.238c15.707-25.107 8.074-58.173-17.05-73.854z"
                fill="url(#paint0_linear)"
              />
              <g opacity=".9">
                <path
                  d="M25.216 8.659C50.34-7.022 83.44.619 99.147 25.727L301.51 349.2c15.707 25.107 8.073 58.173-17.051 73.854-25.123 15.681-58.223 8.039-73.931-17.069L8.166 82.513C-7.541 57.405.092 24.34 25.216 8.659z"
                  fill="url(#paint1_linear)"
                />
                <path
                  d="M227.54 8.66c25.124-15.681 58.224-8.04 73.932 17.068L503.834 349.2c15.707 25.107 8.074 58.173-17.05 73.854-25.124 15.681-58.224 8.039-73.931-17.069L210.49 82.514c-15.707-25.108-8.073-58.173 17.05-73.854z"
                  fill="url(#paint2_linear)"
                />
              </g>
              <path
                opacity=".4"
                d="M53.645 431.315c-29.626 0-53.642-24.015-53.642-53.64 0-29.624 24.016-53.64 53.642-53.64 29.625 0 53.642 24.016 53.642 53.64 0 29.625-24.017 53.64-53.642 53.64zM53.645 107.78C24.019 107.78.003 83.765.003 54.14S24.02.5 53.645.5c29.625 0 53.642 24.015 53.642 53.64s-24.017 53.64-53.642 53.64z"
                fill="#F75C03"
              />
              <path
                d="M458.345 107.794c-29.625 0-53.642-24.016-53.642-53.64 0-29.625 24.017-53.64 53.642-53.64 29.626 0 53.642 24.015 53.642 53.64 0 29.624-24.016 53.64-53.642 53.64z"
                fill="#F75C03"
              />
              <path
                opacity=".6"
                d="M458.311 431.245c-29.626 0-53.642-23.989-53.642-53.58 0-29.591 24.016-53.579 53.642-53.579 29.625 0 53.642 23.988 53.642 53.579s-24.017 53.58-53.642 53.58z"
                fill="#C6510E"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="496"
                y1="298.5"
                x2=".488"
                y2="314.055"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F75C03" />
                <stop offset="1" stopColor="#FAB289" />
              </linearGradient>
              <linearGradient
                id="paint1_linear"
                x1="512"
                y1="242.5"
                x2="13.674"
                y2="323.071"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F75C03" />
                <stop offset="1" stopColor="#FAB289" />
              </linearGradient>
              <linearGradient
                id="paint2_linear"
                x1="512"
                y1="242.5"
                x2="13.674"
                y2="323.071"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F75C03" />
                <stop offset="1" stopColor="#FAB289" />
              </linearGradient>
            </defs>
          </SvgIcon>
        );
      case 'blue':
        return (
          <SvgIcon viewBox="0 0 513 432" {...props}>
            <g opacity=".9">
              <path
                opacity=".9"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M285.165 8.659c-25.124-15.681-58.224-8.04-73.931 17.068L8.871 349.2c-15.707 25.107-8.073 58.173 17.05 73.854 25.124 15.681 58.225 8.039 73.932-17.069L302.215 82.513c15.707-25.108 8.074-58.173-17.05-73.854zm135.079 107.235c-25.124-15.681-58.224-8.039-73.931 17.069L211.035 349.201c-15.707 25.107-8.073 58.173 17.051 73.854 25.124 15.681 58.224 8.039 73.931-17.069l135.277-216.238c15.708-25.107 8.074-58.173-17.05-73.854z"
                fill="url(#paint0_linear)"
              />
              <g opacity=".9">
                <path
                  d="M25.922 8.659C51.045-7.022 84.146.619 99.852 25.727L302.216 349.2c15.707 25.107 8.074 58.173-17.05 73.854-25.124 15.681-58.224 8.039-73.931-17.069L8.871 82.513C-6.836 57.405.798 24.34 25.921 8.659z"
                  fill="url(#paint1_linear)"
                />
                <path
                  d="M228.246 8.66C253.37-7.021 286.47.62 302.177 25.728L504.539 349.2c15.708 25.107 8.074 58.173-17.05 73.854-25.124 15.681-58.224 8.039-73.931-17.069L211.195 82.514c-15.707-25.108-8.073-58.173 17.051-73.854z"
                  fill="url(#paint2_linear)"
                />
              </g>
              <path
                opacity=".6"
                d="M54.35 431.315C24.725 431.315.71 407.3.71 377.675c0-29.624 24.016-53.64 53.641-53.64 29.626 0 53.642 24.016 53.642 53.64 0 29.625-24.016 53.64-53.642 53.64zM54.35 107.78C24.725 107.78.708 83.765.708 54.14S24.725.5 54.35.5c29.626 0 53.642 24.015 53.642 53.64s-24.016 53.64-53.642 53.64z"
                fill="#8CBAFF"
              />
              <path
                d="M459.051 107.794c-29.626 0-53.642-24.016-53.642-53.64 0-29.625 24.016-53.64 53.642-53.64 29.625 0 53.641 24.015 53.641 53.64 0 29.624-24.016 53.64-53.641 53.64z"
                fill="#1670FF"
              />
              <path
                opacity=".6"
                d="M459.016 431.245c-29.625 0-53.641-23.989-53.641-53.58 0-29.591 24.016-53.579 53.641-53.579 29.626 0 53.642 23.988 53.642 53.579s-24.016 53.58-53.642 53.58z"
                fill="#0862ED"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="496.705"
                y1="298.5"
                x2="1.194"
                y2="314.055"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1670FF" />
                <stop offset="1" stopColor="#5E9DFF" />
              </linearGradient>
              <linearGradient
                id="paint1_linear"
                x1="512.705"
                y1="242.5"
                x2="14.379"
                y2="323.071"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1670FF" />
                <stop offset="1" stopColor="#8CBAFF" />
              </linearGradient>
              <linearGradient
                id="paint2_linear"
                x1="512.705"
                y1="242.5"
                x2="14.379"
                y2="323.071"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1670FF" />
                <stop offset="1" stopColor="#8CBAFF" />
              </linearGradient>
            </defs>
          </SvgIcon>
        );
      default:
        return (
          <SvgIcon viewBox="0 0 30 25" {...props}>
            <path
              d="M6.14 1.877A3.1 3.1 0 0 0 .884 5.16l11.695 18.694a3.1 3.1 0 0 0 4.272.985 3.095 3.095 0 0 0 .985-4.268L6.14 1.877Z"
              fill="#1670FF"
              opacity=".6"
            />
            <path
              d="M12.576 1.892a3.082 3.082 0 0 1 4.271-.995 3.144 3.144 0 0 1 .985 4.31L6.136 24.087a3.082 3.082 0 0 1-4.271.995 3.144 3.144 0 0 1-.985-4.31l11.696-18.88Z"
              fill="#9710FF"
              opacity=".6"
            />
            <path
              d="M20.382 8.15a3.082 3.082 0 0 1 4.272-.994 3.144 3.144 0 0 1 .985 4.31l-7.818 12.62a3.082 3.082 0 0 1-4.271.995 3.144 3.144 0 0 1-.985-4.31l7.817-12.62Z"
              fill="#3AB83F"
              opacity=".6"
            />
            <path
              d="M17.832 1.892a3.082 3.082 0 0 0-4.271-.995 3.144 3.144 0 0 0-.985 4.31l11.696 18.88a3.082 3.082 0 0 0 4.271.995 3.144 3.144 0 0 0 .985-4.31L17.832 1.892Z"
              fill="#E6D220"
              opacity=".6"
            />
            <path
              d="M3.51 25.563c-1.714 0-3.099-1.403-3.099-3.13 0-1.727 1.389-3.13 3.099-3.13 1.71 0 3.099 1.403 3.099 3.13 0 1.727-1.389 3.13-3.099 3.13Z"
              fill="#ED3770"
            />
            <path
              d="M3.51 6.679c-1.714 0-3.099-1.403-3.099-3.13C.411 1.82 1.8.42 3.51.42c1.71 0 3.099 1.403 3.099 3.13 0 1.73-1.389 3.13-3.099 3.13Z"
              fill="#1670FF"
            />
            <path
              d="M26.898 6.682c-1.71 0-3.099-1.402-3.099-3.13 0-1.73 1.389-3.129 3.099-3.129 1.713 0 3.099 1.403 3.099 3.13 0 1.727-1.39 3.13-3.1 3.13Z"
              fill="#3AB83F"
            />
            <path
              d="M26.894 25.559c-1.71 0-3.099-1.4-3.099-3.126 0-1.727 1.39-3.126 3.1-3.126 1.713 0 3.098 1.399 3.098 3.126 0 1.727-1.389 3.126-3.099 3.126Z"
              fill="#F75C03"
            />
            <path
              d="M15.314.452h-.253c-.04 0-.083.004-.122.008h-.004c-.941.08-1.833.59-2.37 1.46l-.076.125a3.153 3.153 0 0 0 .076 3.191l2.63 4.245 2.63-4.245c.62-1.002.61-2.226.076-3.188l-.076-.124A3.108 3.108 0 0 0 15.314.452Z"
              fill="#B6637A"
            />
            <path
              d="M15.148 25.58H15.357c.015 0 .03 0 .044-.003.04-.004.083-.004.122-.007h.004a3.097 3.097 0 0 0 2.367-1.472l.075-.124c.53-.966.538-2.19-.086-3.188l-2.648-4.234-2.612 4.256a3.152 3.152 0 0 0-.061 3.191l.079.124a3.105 3.105 0 0 0 2.507 1.458Z"
              fill="#5AB28D"
            />
          </SvgIcon>
        );
    }
  },
);

export default SynthaceBrandmarkLight;
