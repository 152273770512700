import React, { useCallback, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { ElementInstance } from 'common/types/bundle';
import Button from 'common/ui/components/Button';
import { DialogProps } from 'common/ui/hooks/useDialog';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  value: string | undefined;
  elementInstance: ElementInstance;
} & DialogProps<string | undefined>;

export default function AnnotationDialog({
  isOpen,
  onClose,
  value,
  elementInstance,
}: Props) {
  const [stagedValue, setStagedValue] = useState<string>(value || '');

  const handleChange = useTextFieldChange(setStagedValue);
  const handleSave = useCallback(() => onClose(stagedValue), [onClose, stagedValue]);
  const handleCancel = useCallback(() => onClose(value), [onClose, value]);

  // Update the staged value whenever the dialog opens or the value changes
  useEffect(() => setStagedValue(value || ''), [value, isOpen]);

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={handleCancel}>
      <DialogTitle>Annotation: {elementInstance.name} </DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          multiline
          placeholder="Start annotation here"
          value={stagedValue}
          onChange={handleChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="tertiary" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
