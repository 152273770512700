import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const EditIcon = React.memo<SvgIconProps>(function EditIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" fill="none">
        <path
          d="M15.08 8.645l.9.9-2.918 2.918h-.9v-.9l2.918-2.918zm3.525-5.895a.98.98 0 00-.686.284l-1.792 1.792L19.8 8.498l1.792-1.792a.975.975 0 000-1.38L19.3 3.033a.961.961 0 00-.695-.284zM15.08 5.874l-4.877 4.876v3.672h3.672l4.877-4.877-3.672-3.671z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.75 4.25a2 2 0 00-2 2V18.5a2 2 0 002 2h14.5a2 2 0 002-2V12h-2v6.5H4.75V6.25H12v-2H4.75z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
