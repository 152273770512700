import { DatasetDerivationQuery } from 'client/app/gql';

const BIOREACTOR_CATEGORY_NAME = 'Bio Reactors';

export type DatasetDerivationInput =
  DatasetDerivationQuery['datasetDerivation']['inputs'][number];

export function isBioreactorDataset(dataset: DatasetDerivationInput): boolean {
  return dataset.device?.model.series.category.name === BIOREACTOR_CATEGORY_NAME;
}
