import React, { useCallback, useEffect, useMemo } from 'react';

import IconArrowLeft from '@mui/icons-material/ArrowLeft';
import IconArrowRight from '@mui/icons-material/ArrowRight';
import IconFirstPage from '@mui/icons-material/FirstPage';
import IconLastPage from '@mui/icons-material/LastPage';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { hasParallelTransferStage } from 'common/lib/mix';
import { MixPreviewStages, StageDetails } from 'common/types/mixPreview';
import Colors from 'common/ui/Colors';
import ControlButton from 'common/ui/components/simulation-details/StepSlider/components/ControlButton';
import SimulationPlaybackControl from 'common/ui/components/simulation-details/StepSlider/components/SimulationPlaybackControl';
import StepSliderBar from 'common/ui/components/simulation-details/StepSlider/components/StepSliderBar';
import { KeyPoint } from 'common/ui/components/simulation-details/StepSlider/types';
import { isInteractiveInput } from 'common/ui/lib/browser';
import Keys from 'common/ui/lib/keyboard';

export type Props = {
  /**
   * Current stage of the simulation.
   * Indexed from 0 to N-1 where N is the number of simulation stages.
   */
  currentStage: number;
  /**
   * Number of applied steps in the current stage of the simulation.
   * Indexed from 0 to N where N is the number of steps in the current simulation stage.
   */
  appliedSteps: number;
  /** All steps of the simulation grouped by simulation stage */
  stages: MixPreviewStages;
  /**
   * Detailed info about each simulation stage:
   * - name of the stage set in Builder
   * - device with accessible devices used in this simulation stage
   */
  stageDetails?: StageDetails[];
  onStageChange: (stage: number) => void;
  onStepChange: (stepCount: number, playbackTime?: number) => void;
  /**
   * Steps per simulation stage that are considered particularly interesting and will be visually
   * highlighted on the slider.
   */
  keyPoints: readonly KeyPoint[][];
  /**
   * Show a play/pause button which will automatically step through the
   * simulation using each step's estimated time.
   */
  allowPlayback?: boolean;
};

export default function StepSlider({
  currentStage,
  appliedSteps,
  stages,
  keyPoints,
  stageDetails,
  allowPlayback,
  onStageChange,
  onStepChange,
}: Props) {
  const {
    handleJumpToStart,
    handleStepBackwardClick,
    handleStepForwardClick,
    handleJumpToEnd,
  } = useSliderHandlers(
    stages,
    currentStage,
    appliedSteps,
    keyPoints,
    onStageChange,
    onStepChange,
  );

  const isMultiStage = stages.length > 1;
  const startsWithParallelTransfer = useMemo(
    () => stages[0].some(hasParallelTransferStage),
    [stages],
  );

  const backwardsDisabled = appliedSteps <= 0 && currentStage <= 0;
  const forwardDisabled =
    appliedSteps >= stages[currentStage].length && currentStage >= stages.length - 1;

  return (
    <SliderAndControls>
      <SliderLeft>
        {allowPlayback && (
          <SimulationPlaybackControl
            steps={stages[currentStage]}
            onStepChange={onStepChange}
          />
        )}
        <ControlButton
          icon={<IconFirstPage />}
          tooltipContent={<Typography variant="caption">Go to start</Typography>}
          disabled={backwardsDisabled}
          onClick={handleJumpToStart}
        />
        <ControlButton
          icon={<IconArrowLeft />}
          tooltipContent={
            <>
              <Typography variant="caption" component="p">
                Step back (left keyboard arrow).
              </Typography>
              <Typography variant="caption" component="p">
                If holding Shift: Go to previous key point.
              </Typography>
            </>
          }
          disabled={backwardsDisabled}
          onClick={handleStepBackwardClick}
        />
      </SliderLeft>
      <SliderContainer
        multiStage={isMultiStage}
        startsWithParallelTransfer={startsWithParallelTransfer}
      >
        <StepSliderBar
          currentStage={currentStage}
          appliedSteps={appliedSteps}
          keyPoints={keyPoints}
          stages={stages}
          stageDetails={stageDetails}
          onStageChange={onStageChange}
          onStepChange={onStepChange}
        />
      </SliderContainer>
      <SliderRight>
        <ControlButton
          icon={<IconArrowRight />}
          tooltipContent={
            <>
              <Typography variant="caption">
                Step forward (right keyboard arrow).
              </Typography>
              <Typography variant="caption">
                If holding Shift: Go to next key point.
              </Typography>
            </>
          }
          disabled={forwardDisabled}
          onClick={handleStepForwardClick}
        />
        <ControlButton
          icon={<IconLastPage />}
          tooltipContent={<Typography variant="caption">Go to end</Typography>}
          disabled={forwardDisabled}
          onClick={handleJumpToEnd}
        />
      </SliderRight>
    </SliderAndControls>
  );
}

function useSliderHandlers(
  stages: MixPreviewStages,
  currentStage: number,
  appliedSteps: number,
  keyPoints: readonly KeyPoint[][],
  onStageChange: (stage: number) => void,
  onStepChange: (stepCount: number, playbackTime?: number) => void,
) {
  const noStepsApplied = appliedSteps === 0;
  const allStepsApplied = appliedSteps === stages[currentStage].length;

  const oneStepForward = useCallback(() => {
    const stepCount = stages[currentStage].length;

    if (appliedSteps < stepCount) {
      onStepChange(appliedSteps + 1);
    } else if (currentStage < stages.length - 1) {
      onStageChange(currentStage + 1);
      onStepChange(1);
    }
  }, [currentStage, appliedSteps, onStageChange, onStepChange, stages]);

  const oneStepBack = useCallback(() => {
    if (appliedSteps > 1) {
      onStepChange(appliedSteps - 1);
    } else if (currentStage > 0) {
      onStageChange(currentStage - 1);
      onStepChange(stages[currentStage - 1].length);
    } else {
      // Here its a 1st stage of the Simulation which starts with a zero-step.
      onStepChange(0);
    }
  }, [currentStage, appliedSteps, onStageChange, onStepChange, stages]);

  const handleJumpToStart = useCallback(() => {
    if (appliedSteps === 1) {
      onStageChange(Math.max(0, currentStage - 1));
      onStepChange(currentStage > 1 ? 1 : 0);
    } else {
      onStepChange(currentStage > 0 ? 1 : 0);
    }
  }, [currentStage, appliedSteps, onStageChange, onStepChange]);

  const handleJumpToEnd = useCallback(() => {
    const lastStepOfCurrentStage = stages[currentStage].length;
    if (appliedSteps < lastStepOfCurrentStage) {
      onStepChange(lastStepOfCurrentStage);
    } else {
      const lastStage = stages.length - 1;
      const nextStage = Math.min(lastStage, currentStage + 1);
      const lastStepOfNextStage = stages[nextStage].length;

      onStageChange(nextStage);
      onStepChange(lastStepOfNextStage);
    }
  }, [currentStage, appliedSteps, onStageChange, onStepChange, stages]);

  const goToNextKeyPoint = useCallback(() => {
    const currentStageKeyPoints = keyPoints[currentStage];
    if (!currentStageKeyPoints) {
      handleJumpToEnd();
      return;
    }

    // Smallest step larger than current
    const nextStep = Math.min(
      ...currentStageKeyPoints.filter(kp => kp.step > appliedSteps).map(kp => kp.step),
    );
    const nextKeyPoint = currentStageKeyPoints.find(kp => kp.step === nextStep);
    const firstKeyPointOfNextStage = keyPoints[currentStage + 1]
      ? keyPoints[currentStage + 1][0]
      : undefined;
    if (nextKeyPoint) {
      onStepChange(nextKeyPoint.step);
    } else if (allStepsApplied && firstKeyPointOfNextStage) {
      onStageChange(currentStage + 1);
      onStepChange(firstKeyPointOfNextStage.step);
    } else {
      handleJumpToEnd();
    }
  }, [
    allStepsApplied,
    appliedSteps,
    currentStage,
    handleJumpToEnd,
    keyPoints,
    onStageChange,
    onStepChange,
  ]);

  const goToPreviousKeyPoint = useCallback(() => {
    const currentStageKeyPoints = keyPoints[currentStage];
    if (!currentStageKeyPoints) {
      handleJumpToStart();
      return;
    }

    // Largest step smaller than current
    const previousStep = Math.max(
      ...currentStageKeyPoints.filter(kp => kp.step < appliedSteps).map(kp => kp.step),
    );
    const previousKeyPoint = currentStageKeyPoints.find(kp => kp.step === previousStep);
    const lastKeyPointOfPrevStage = keyPoints[currentStage - 1]
      ? [...keyPoints[currentStage - 1]].reverse().at(0)
      : undefined;
    if (previousKeyPoint) {
      onStepChange(previousKeyPoint.step);
    } else if (noStepsApplied && lastKeyPointOfPrevStage) {
      onStageChange(currentStage - 1);
      onStepChange(lastKeyPointOfPrevStage.step);
    } else {
      handleJumpToStart();
    }
  }, [
    appliedSteps,
    currentStage,
    handleJumpToStart,
    keyPoints,
    noStepsApplied,
    onStageChange,
    onStepChange,
  ]);

  const stepForwardSmart = useCallback(
    (shiftKey: boolean) => {
      if (shiftKey) {
        goToNextKeyPoint();
      } else {
        oneStepForward();
      }
    },
    [goToNextKeyPoint, oneStepForward],
  );

  const stepBackwardSmart = useCallback(
    (shiftKey: boolean) => {
      if (shiftKey) {
        goToPreviousKeyPoint();
      } else {
        oneStepBack();
      }
    },
    [goToPreviousKeyPoint, oneStepBack],
  );

  const handleStepForwardClick = useCallback(
    (e: React.MouseEvent) => stepForwardSmart(e.shiftKey),
    [stepForwardSmart],
  );

  const handleStepBackwardClick = useCallback(
    (e: React.MouseEvent) => stepBackwardSmart(e.shiftKey),
    [stepBackwardSmart],
  );

  useEffect(() => {
    // keydown - holding the key repeats the action
    function handleKeyDown(e: KeyboardEvent) {
      if (isInteractiveInput(e.target)) {
        // Moving cursor inside an input using keyboard arrows
        return;
      }
      if (!e.altKey && !e.ctrlKey && !e.metaKey) {
        if (e.key === Keys.ARROW_LEFT) {
          stepBackwardSmart(e.shiftKey);
          e.preventDefault();
        }
        if (e.key === Keys.ARROW_RIGHT) {
          stepForwardSmart(e.shiftKey);
          e.preventDefault();
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [stepBackwardSmart, stepForwardSmart]);

  return {
    handleJumpToStart,
    handleStepBackwardClick,
    handleStepForwardClick,
    handleJumpToEnd,
  };
}

const SliderAndControls = styled('main')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: `auto 1fr auto`,
  borderTop: `1px solid ${theme.palette.divider}`,
  userSelect: 'none',
}));

const SliderAside = styled('aside')({
  display: 'flex',
  alignItems: 'stretch',
  backgroundColor: Colors.GREY_10,
});

const SliderLeft = styled(SliderAside)(({ theme }) => ({
  gridColumn: 1,
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const SliderRight = styled(SliderAside)(({ theme }) => ({
  gridColumn: 3,
  borderLeft: `1px solid ${theme.palette.divider}`,
}));

const SliderContainer = styled('section')<{
  multiStage: boolean;
  startsWithParallelTransfer: boolean;
}>(({ theme, multiStage, startsWithParallelTransfer }) => ({
  gridColumn: 2,
  padding: startsWithParallelTransfer
    ? theme.spacing(5, '20px', 5, '68px')
    : multiStage
    ? theme.spacing(5, '20px', 5, '44px')
    : theme.spacing(5, 6),
}));
