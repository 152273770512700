import { isDefined } from 'common/lib/data';

// Proxy to the the global Google Analytics ga object
type bagOfHolding = (...args: any) => void;
function yeMightyBagOfHolding() {}

function getGtag(): bagOfHolding {
  const { gtag } = window as any;
  if (!gtag) {
    return yeMightyBagOfHolding;
  }
  return gtag;
}

export function logPageView(appName: string, screenName: string) {
  getGtag()('event', 'screen_view', {
    app_name: appName,
    screen_name: screenName,
  });
}

// Log arbitrary events to Google Analytics
//
// Google's documentation: https://developers.google.com/analytics/devguides/collection/gtagjs/events)
export function logEvent(
  // Make this a descriptive string to uniquely identify the action
  // among all the other special actions.
  action: string,
  // In almost every case, `category` should be the "app" (i.e.
  // antha-workflow-editor, antha-execution-details, etc.). For consistency,
  // you should probably use constants from app/registry.js
  category: string,
  // Any descriptive string you want to send along with the event. Be
  // careful not to include anything user-identifying or anything that
  // includes IP/"material information" from a user's session.
  label?: string,
  // Any postive integer. Probably only useful as an enum value. Included
  // here for thoroughness.
  value?: string,
) {
  const data = {
    event_category: category,
    ...(label && { event_label: label }),
    ...(isDefined(value) ? { value } : undefined),
  };

  getGtag()('event', action, data);
}
