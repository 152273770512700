import React from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { QUERY_EXECUTIONS } from 'client/app/api/gql/queries';
import { Execution } from 'client/app/apps/execution-details/types';
import { DOEExecutionSelectionDialog } from 'client/app/apps/experiments/DOEExecutionSelectionDialog';
import {
  arePlateDataUploaded,
  usePlates,
} from 'client/app/apps/simulation-details/overview/results/usePlates';
import { ScreenRegistry } from 'client/app/registry';
import useLaunchPrepareData from 'client/app/stories/work-tree/useLaunchPrepareData';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { Deck } from 'common/types/mix';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Tooltip from 'common/ui/components/Tooltip';
import useDialog from 'common/ui/hooks/useDialog';
import { DataSetIcon } from 'common/ui/icons/DataSetIcon';

type Props = {
  execution: Execution;
  deck: Deck;
  isRobocolumnsSimulation?: boolean;
};

export default function StructureDataButton({
  execution,
  deck,
  isRobocolumnsSimulation,
}: Props) {
  const isNewDOEEnabled = useFeatureToggle('NEW_DOE');

  const plates = usePlates(execution, deck);
  const dataUploaded = arePlateDataUploaded(plates);

  const { data: doeExecutionsData, loading: queryExecutionsLoading } = useQuery(
    QUERY_EXECUTIONS,
    {
      variables: { kinOf: execution.id },
      fetchPolicy: 'cache-and-network',
      skip: !isNewDOEEnabled || !dataUploaded,
    },
  );

  const snackbarManager = useSnackbarManager();

  const { launchPrepareData, loading: launchPrepareDataLoading } = useLaunchPrepareData();

  const [doeExecutionsSelectionDialog, openDoeExecutionsSelectionDialog] = useDialog(
    DOEExecutionSelectionDialog,
  );
  const handleOpenDOEExecutionsSelectionDialog = async () => {
    // If the current execution has cousin executions from the same DOE Design
    // then we want to open a dialog to allow the user to select these.
    // If no cousin executions, then we directly launch prepare data with the
    // current execution.
    if (
      doeExecutionsData?.executions?.items &&
      doeExecutionsData.executions.items.length > 1
    ) {
      await openDoeExecutionsSelectionDialog({
        currentSimulation: execution.simulation,
      });
    } else {
      const { error } = await launchPrepareData(
        [{ executionId: execution.id, simulationId: execution.simulation.id }],
        ScreenRegistry.EXECUTION_DETAILS,
      );
      if (error) {
        snackbarManager.showError(error.message);
      }
    }
  };

  const loading = launchPrepareDataLoading || queryExecutionsLoading;

  return (
    <Tooltip
      title={
        !dataUploaded
          ? 'There is no analytical data uploaded to any output plate. Please upload data before structuring data.'
          : ''
      }
    >
      <span>
        <StyledFab
          color={isRobocolumnsSimulation ? 'default' : 'secondary'}
          size="medium"
          variant="extended"
          onClick={handleOpenDOEExecutionsSelectionDialog}
          disabled={loading || !dataUploaded}
        >
          {loading ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            <>
              <StyledDataSetIcon />
              <Typography variant="button">Structure DOE data</Typography>
            </>
          )}
        </StyledFab>
        {doeExecutionsSelectionDialog}
      </span>
    </Tooltip>
  );
}

const StyledDataSetIcon = styled(DataSetIcon)(({ theme }) => ({
  marginRight: theme.spacing(3),
}));

const StyledFab = styled(Fab)({
  '&.MuiFab-extended.MuiFab-sizeMedium': {
    minWidth: '170px',
  },
});
