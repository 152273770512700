import { ChangeEvent, useCallback, useReducer } from 'react';

import produce from 'immer';
import debounce from 'lodash/debounce';

import { WellRegion } from 'client/app/components/Parameters/FiltrationPlateLayout/lib/editorState';
import { MetaItem } from 'client/app/components/Parameters/MetaDataForm';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';

type ResinFormState = {
  resinName: string;
  resinVolume: string;
  maxVolume: number;
  metaItems: MetaItem[];

  dirty: boolean;
  showError: boolean;
  error?: {
    resinName?: string;
    resinVolume?: string;
    meta?: string;
  };
};

type ResinFormAction =
  | { type: 'updateName'; name: string }
  | { type: 'updateVolume'; volume: string }
  | { type: 'updateMeta'; payload: MetaItem[] }
  | { type: 'validate' }
  | { type: 'showError'; showError: boolean };

export default function useResinAssignForm(
  wellRegion: WellRegion | undefined,
  maxVolume: number,
) {
  const [state, dispatch] = useReducer(resinFormReducer, {
    resinName: wellRegion?.resinName ?? '',
    resinVolume: wellRegion?.resinVolume ?? '',
    maxVolume,
    metaItems: wellRegion?.metaItems ?? [],

    error: undefined,
    showError: false,
    dirty: false,
  });

  const updateName = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: 'updateName', name: event.target.value });
  const updateVolume = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: 'updateVolume', volume: event.target.value });

  const updateMeta = useCallback(
    (metaItems: MetaItem[]) => dispatch({ type: 'updateMeta', payload: metaItems }),
    [],
  );

  const validate = () => dispatch({ type: 'validate' });
  const showError = (showError: boolean) => dispatch({ type: 'showError', showError });

  return {
    state,
    updateName,
    updateVolume,
    updateMeta,
    validate,
    showError,
  };
}

function resinFormReducer(
  state: ResinFormState,
  action: ResinFormAction,
): ResinFormState {
  switch (action.type) {
    case 'updateName': {
      return produce(state, draft => {
        const newName = action.name;
        draft.resinName = newName;
        draft.dirty = true;
      });
    }
    case 'updateVolume': {
      return produce(state, draft => {
        const newVolume = action.volume;
        draft.resinVolume = newVolume;
        draft.dirty = true;
      });
    }
    case 'updateMeta': {
      return produce(state, draft => {
        draft.metaItems = action.payload;
        draft.dirty = true;
      });
    }
    case 'validate': {
      return produce(state, draft => {
        const error = getError(state);
        if (!error) {
          draft.showError = false;
        }
        draft.error = error;
      });
    }
    case 'showError': {
      return produce(state, draft => {
        draft.showError = action.showError;
      });
    }
    default: {
      return state;
    }
  }
}

function getError(state: ResinFormState) {
  let error = {} as ResinFormState['error'];

  if (!state.resinName || state.resinName.length === 0) {
    error!.resinName = 'Resin name cannot be empty.';
  } else if (!state.resinVolume || state.resinVolume.length === 0) {
    error!.resinVolume = 'Resin volume cannot be empty.';
  } else if (+state.resinVolume <= 0) {
    error!.resinVolume = 'Resin volume should be greater than zero.';
  } else if (+state.resinVolume > state.maxVolume) {
    error!.resinVolume = `Resin volume cannot be greater than ${state.maxVolume}ul (Resin + Buffer Volume)`;
  } else if (state.metaItems.some(item => !item.key || !item.value)) {
    error!.meta = 'Meta item cannot have an empty name or value.';
  } else {
    error = undefined;
  }

  return error;
}

export const getResinColor = debounce(
  (resinFormState: ResinFormState, liquidColors: LiquidColors) =>
    liquidColors.getColorFromLiquidString(JSON.stringify(resinFormState), false),
  1000,
  /**
   * Here we make sure to emit the first value.
   * So that user doesn't get a white color from undefined result.
   */
  { leading: true },
);
