import React from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import ResourcesInput from 'client/app/apps/execution-details/ExecuteTab/Reagents/components/ResourcesInput';
import Helpers from 'client/app/apps/execution-details/ExecuteTab/Reagents/helpers';
import { StockConcentration } from 'client/app/apps/execution-details/types';
import { Reagent } from 'client/app/gql';
import { formatMeasurementObj } from 'common/lib/format';
import { Measurement } from 'common/types/mix';

type Props = {
  reagents: Reagent[];
  stockConcentrations: StockConcentration[];
  onUpdateStock: (key: string, value: Measurement) => void;
};

export function StockView({ reagents, stockConcentrations, onUpdateStock }: Props) {
  const totalVolumes = Helpers.getTotalVolumes(reagents, stockConcentrations);
  const stockErrors = Helpers.getStockErrors(stockConcentrations, reagents);
  const stockWarnings = Helpers.getStockWarnings(
    stockErrors,
    reagents,
    stockConcentrations,
  );

  return stockConcentrations.length > 0 ? (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="caption" fontWeight={600} lineHeight={2}>
              Stock
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Stack>
              <Typography variant="caption" fontWeight={600}>
                Concentration
              </Typography>
              <Typography variant="caption" fontWeight={600}>
                (Volume Required)
              </Typography>
            </Stack>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {stockConcentrations.map(stock => (
          <TableRow key={`${stock.name}_${stock.concentration.unit}`}>
            <TableCell component="th" scope="row">
              <Typography variant="subtitle1" mb={4}>
                {stock.name}
              </Typography>
            </TableCell>
            <TableCell align="right" width={200}>
              <Stack alignItems="flex-end" gap={2} mt={5} mb={3}>
                <StockInput
                  concentration={stock.concentration}
                  onChange={concentration => onUpdateStock(stock.name, concentration)}
                  errorText={stockErrors.get(stock) || ''}
                  warningText={stockWarnings.get(stock) || ''}
                />
                {isNaN(stock.concentration.value) ? (
                  <Typography variant="caption" color="error">
                    Please input the concentration value
                  </Typography>
                ) : (
                  <Typography variant="caption">
                    (
                    {formatMeasurementObj(
                      totalVolumes.get(stock) || { value: 0, unit: 'ul' },
                    )}
                    )
                  </Typography>
                )}
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <Typography variant="h6" textAlign="center" marginTop={5}>
      No stocks to display
    </Typography>
  );
}

type StockInputProps = {
  concentration: Measurement;
  errorText: string;
  warningText: string;
  onChange: (a: Measurement) => void;
};

function StockInput({
  concentration,
  errorText,
  warningText,
  onChange,
}: StockInputProps) {
  const hasError = errorText !== '';
  const hasWarning = warningText !== '';
  const concentrationIsNan = isNaN(concentration.value);

  return (
    <ResourcesInput
      size="small"
      error={hasError || hasWarning || concentrationIsNan}
      helperText={errorText || warningText}
      type="number"
      value={concentration.value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{concentration.unit}</InputAdornment>
        ),
      }}
      onChange={evt =>
        onChange({ value: parseFloat(evt.target.value), unit: concentration.unit })
      }
    />
  );
}
