import React, { useRef } from 'react';

import { WELL_STROKE } from 'client/app/apps/plate-constructor/Colors';
import { PlateFormFields } from 'client/app/apps/plate-constructor/PlateFormFields';
import SVGAnnotation from 'client/app/apps/plate-constructor/SVGAnnotation';
import {
  FormPlateType,
  WellBottomShapeEnum,
  wellBottomShapeFromString,
} from 'common/types/plateType';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useSvgScale from 'common/ui/hooks/useSvgScale';

function getActualShape(
  shape: WellBottomShapeEnum | '',
  rows: number,
  columns: number,
  direction: 'x' | 'y',
): WellBottomShapeEnum {
  const isTrough =
    (direction === 'x' && columns === 1) || (direction === 'y' && rows === 1);
  if (isTrough) {
    return WellBottomShapeEnum.FLAT_BOTTOM;
  }
  return shape || WellBottomShapeEnum.FLAT_BOTTOM;
}

function getWellPath(shape: WellBottomShapeEnum, depth: number, width: number): string {
  switch (shape) {
    case WellBottomShapeEnum.FLAT_BOTTOM:
      // a simple |_|
      return `            
      l0, ${depth}
      l ${width}, 0  
      l 0,${-depth}`;
    case WellBottomShapeEnum.V_BOTTOM:
      // | |
      // \/
      return `            
      l0, ${depth - width / 2}
      l ${width / 2}, ${width / 2}  
      l ${width / 2}, ${-width / 2}  
      l 0,${-depth + width / 2}`;
    default:
      // go down, then make half a circle of diameter `width`, then up
      // `depth needs to be greater than `width`
      return `            
      l0, ${depth - width / 2}
      a ${width / 2},${width / 2}, 180, 0, 0, ${width}, 0  
      l 0,${-depth + width / 2}`;
  }
}

type Props = {
  plate: FormPlateType;
  selectedField?: PlateFormFields;
  direction: 'x' | 'y';
  preserveAspectRatio: string;
};
function WellVisualization(props: Props) {
  const { plate, selectedField, direction, preserveAspectRatio } = props;
  const dimensionMm = plate.wellShape.dimensionMm;

  // Because we get a FormPlate we can number may be `''`, they'll get converted to 0;
  const rows = Number(plate.rows);
  const columns = Number(plate.columns);
  const startZ = Number(plate.wellStart.z);
  const outerDepth = Number(dimensionMm.z);
  const plateHeight = Number(plate.dimension.z);
  const width = Number(dimensionMm[direction]);

  const bottomShape = wellBottomShapeFromString(plate.wellShape.bottomType);

  const shape = getActualShape(bottomShape, rows, columns, direction);
  // In case well_bottom_z_offset_mm is 0, set thickness to 0.1 so that we
  // render the well even though it's a degenerate case.  Also keep the original
  // thickness for the annotation.  Can be removed when/if T1212 is sorted so
  // that we never come in with this parameter <= 0.
  const originalThickness = Number(plate.wellBottomOffset);
  const thickness = Math.max(originalThickness, 0.1);
  const wellHeight = outerDepth + startZ;
  const depth = outerDepth - thickness;
  const maxHeight = Math.max(wellHeight, plateHeight);
  const wellX = Number(dimensionMm.x);
  const wellY = Number(dimensionMm.y);

  const classes = useStyles();

  // length of what we draw before the well, as well as after the well
  const offset = 3 * thickness;
  // We show the well and some nice border (of length `offset`) on each side
  const wellWidth = width + 2 * offset;
  // Here we use the max between the width of both well.
  // This allows us to use the same viewBox for both wells and have the same ratio.
  // The 2 offset are for the border before and after the well.
  const maxWellWidth = Math.max(wellX, wellY) + 2 * offset;

  const svgRef = useRef<SVGSVGElement>(null);
  const scale = useSvgScale(svgRef, maxWellWidth, maxHeight);

  // The annotations text should be just a little (10%) out of the well.
  const verticalAnnotationOffset = -1.1 * offset - width / 2;
  const horizontalAnnotationOffset = -1.1 * thickness - maxHeight + wellHeight;
  return (
    <svg
      ref={svgRef}
      className={classes.svgContainer}
      viewBox={`0 0 ${maxWellWidth} ${maxHeight}`}
      height="100%"
      width="100%"
      preserveAspectRatio={preserveAspectRatio}
      vectorEffect="non-scaling-stroke"
    >
      <defs>
        {/*
        We use a special trick to align the stroke on the inside of the path
        https://stackoverflow.com/a/32162431
        The clipPath does not care about stroke-width, it is a scalpel and will remove everying out of our path (left of the path)
        By having a path twice thicker clipped by itself we get rid of half of the stroke on the outside.

        See a straight path width stroke-width of `2*thickness` like this:

        outside stroke (1 thickness) ->  ░░░░░░░
        scalbel thin middle of stroke->  -------
        inside of stroke (1 thickness)-> ░░░░░░░

        We clip everything outside and get a unaliged of apparent `thickness` width:

                <- this has been clipped
        -------
        ░░░░░░░

        */}
        <path
          id={`ld_${direction}`}
          stroke={WELL_STROKE}
          strokeWidth={thickness * 2}
          fill="none"
          // We first draw the not well part, some border (offset) to make it nice
          // starting at the *
          //      _
          //   ._|       <- sometime the well starts above the plate
          //   |
          //   |_______*
          // Then we draw the well
          //      _
          //   ._| |_|
          //   |
          //   |_______*
          // and we finally finish the border (1 offset)
          //      _   _
          //   ._| |_|
          //   |
          //   |_______*
          d={`
            M 0, 0
            m 0, ${maxHeight} 
            m ${width + 2 * offset}, 0 
            l ${-width - 2 * offset}, 0 
            l 0, ${-plateHeight}
            l ${offset / 2}, 0
            l 0, ${+plateHeight - wellHeight}
            l ${offset / 2}, 0
            
            ${getWellPath(shape, depth, width)}

            l ${offset}, 0

        `}
        />
        <clipPath id={`clip_${direction}`}>
          <use xlinkHref={`#ld_${direction}`} clipPath={`url(#clip)_${direction}`} />
        </clipPath>
        <clipPath id={`clipWell_${direction}`}>
          <rect x="0" y="0" width={wellWidth} height={maxHeight} />
        </clipPath>
      </defs>
      <g clipPath={`url(#clipWell_${direction})`}>
        <use
          xlinkHref={`#ld_${direction}`}
          stroke={WELL_STROKE}
          clipPath={`url(#clip_${direction})`}
        />
      </g>
      {depth > 0 && selectedField === `well${direction.toUpperCase()}` ? (
        <SVGAnnotation
          from={{ x: offset, y: maxHeight - wellHeight }}
          to={{ x: offset + width, y: maxHeight - wellHeight }}
          direction="HORIZONTAL"
          unit="mm"
          offset={horizontalAnnotationOffset}
          value={width}
          scale={scale}
        />
      ) : null}
      {depth > 0 && selectedField === 'plateZ' ? (
        <SVGAnnotation
          from={{ x: offset + width / 2, y: maxHeight - plateHeight }}
          to={{ x: offset + width / 2, y: maxHeight }}
          direction="VERTICAL"
          unit="mm"
          offset={verticalAnnotationOffset}
          value={plateHeight}
          scale={scale}
        />
      ) : null}
      {depth > 0 && selectedField === 'wellZ' ? (
        <SVGAnnotation
          from={{ x: offset + width / 2, y: maxHeight - startZ - outerDepth }}
          to={{ x: offset + width / 2, y: maxHeight - startZ }}
          direction="VERTICAL"
          unit="mm"
          offset={verticalAnnotationOffset}
          value={outerDepth}
          scale={scale}
        />
      ) : null}
      {startZ > 0 && selectedField === 'startZ' ? (
        <SVGAnnotation
          from={{ x: offset + width / 2, y: maxHeight - startZ }}
          to={{ x: offset + width / 2, y: maxHeight }}
          direction="VERTICAL"
          unit="mm"
          offset={verticalAnnotationOffset}
          value={startZ}
          scale={scale}
        />
      ) : null}
      {originalThickness >= 0 && selectedField === 'wellBottomThickness' ? (
        <SVGAnnotation
          from={{
            x: offset + width / 2,
            y: maxHeight - startZ - originalThickness,
          }}
          to={{ x: offset + width / 2, y: maxHeight - startZ }}
          direction="VERTICAL"
          unit="mm"
          offset={verticalAnnotationOffset}
          value={originalThickness}
          scale={scale}
        />
      ) : null}
    </svg>
  );
}

WellVisualization.defaultProps = {
  direction: 'x',
  preserveAspectRatio: 'xMidYMid meet',
};

const useStyles = makeStylesHook({
  svgContainer: {
    // annotations may overflow
    overflow: 'visible',
    vectorEffect: 'non-scaling-stroke',
  },
  stop1: { stopColor: 'transparent' },
  stop2: { stopColor: WELL_STROKE },
});
export default React.memo(WellVisualization);
