import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const InputPlateIcon = React.memo<SvgIconProps>(function InputPlateIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M9 9L5 6v6l4-3z" fill="currentColor" />
        <path
          d="M1.001 8.2v1.6h4V8.2h-4zM15 10.25a1.75 1.75 0 103.5 0 1.75 1.75 0 00-3.5 0zM10.25 10.25a1.75 1.75 0 103.5 0 1.75 1.75 0 00-3.5 0zM15 14.5a1.75 1.75 0 103.5 0 1.75 1.75 0 00-3.5 0zM10.25 14.5a1.75 1.75 0 103.5 0 1.75 1.75 0 00-3.5 0zM5.5 14.5a1.75 1.75 0 103.5 0 1.75 1.75 0 00-3.5 0z"
          fill="currentColor"
        />
        <path
          d="M20 5a3 3 0 013 3v9a3 3 0 01-3 3H4a3 3 0 01-3-3v-4h2v4a1 1 0 001 1h16a1 1 0 001-1V8a1 1 0 00-1-1H9V5h11z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
