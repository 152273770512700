import React, { useContext, useEffect, useState } from 'react';

import { ApolloError, useMutation } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { DatasetSettingsContext } from 'client/app/apps/experiments/dataset-settings/DatasetSettingsContext';
import { isBioreactorDataset } from 'client/app/apps/experiments/dataset-settings/isBioreactorDataset';
import TimezoneSelect from 'client/app/apps/experiments/dataset-settings/TimezoneSelect';
import { useBioreactorDatasetColumns } from 'client/app/apps/experiments/dataset-settings/useBioreactorDatasetColumns';
import {
  MUTATION_CREATE_DATASET_TAG,
  MUTATION_REMOVE_DATASET_TAG,
} from 'client/app/apps/experiments/gql/mutations';
import { QUERY_DATASET_WITH_SETTINGS } from 'client/app/apps/experiments/gql/queries';
import { DatasetWithSettingsQuery } from 'client/app/gql';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type DatasetSettingsDetailsTabProps = {
  onTimezoneChange: (value: string) => void;
  timezone: string;
};

export function DatasetSettingsDetailsTab(props: DatasetSettingsDetailsTabProps) {
  const classes = useStyles();

  const { onTimezoneChange, timezone } = props;

  const { dataset, isReadonly } = useContext(DatasetSettingsContext);

  const showTimezoneSelect = useFeatureToggle('DATASET_TIMEZONES');

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.field}>
          <TextField
            label="Dataset name"
            variant="outlined"
            disabled
            value={dataset.name}
            fullWidth
            margin="dense"
          />
        </div>
        {isBioreactorDataset(dataset) && (
          <>
            <div className={classes.field}>
              <BioreactorNames />
            </div>
            {showTimezoneSelect && (
              <div className={classes.field}>
                <TimezoneSelect
                  defaultValue={timezone}
                  onChange={onTimezoneChange}
                  disabled={isReadonly}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div>
        <div>
          <TextField
            label="Source"
            disabled
            variant="outlined"
            margin="dense"
            value={dataset.device?.name ?? 'Derived'}
          />
        </div>
      </div>
    </div>
  );
}

/**
 * Show list of bioreactors in the dataset. If there are none, then allow user to define a
 * bioreactor name that all rows of the dataset pertain to.
 */
function BioreactorNames() {
  const { dataset } = useContext(DatasetSettingsContext);

  const names = useBioreactorNamesFromDataset(dataset);

  if (names) {
    return (
      <>
        <Typography color="textPrimary" gutterBottom>
          Bioreactor names
        </Typography>
        {names.map(name => (
          <TextField disabled variant="outlined" key={name} value={name} margin="dense" />
        ))}
      </>
    );
  }

  return <BioreactorNameInput />;
}

/**
 * Allows the user to define a bioreactor name for the dataset. This works by defining a
 * dataset tag of type 'labware.name' with a user defined value.
 */
function BioreactorNameInput() {
  const { dataset, isReadonly } = useContext(DatasetSettingsContext);

  const bioreactorNameTag = dataset.tags.find(
    tag => tag.columnMetadata?.type === 'labware.name',
  );

  const [newName, setNewName] = useState<string>(bioreactorNameTag?.value ?? '');

  // When the cached dataset changes, update the state with the new name.
  useEffect(() => setNewName(bioreactorNameTag?.value ?? ''), [bioreactorNameTag?.value]);

  const [createDatasetTag, { error: tagCreationError, loading: isCreatingTag }] =
    useMutation(MUTATION_CREATE_DATASET_TAG);

  const [removeDatasetTag, { error: tagRemovalError, loading: isRemovingTag }] =
    useMutation(MUTATION_REMOVE_DATASET_TAG);

  const handleSave = async () => {
    if (isCreatingTag || isRemovingTag) {
      return;
    }
    if (bioreactorNameTag) {
      // TODO: mutation to update tag. Currently we need to delete and recreate the
      // labware.name tag.
      await removeDatasetTag({
        variables: { id: bioreactorNameTag.id },
      });
    }
    await createDatasetTag({
      variables: {
        datasetId: dataset.datasetId,
        name: 'Bioreactor',
        type: 'labware.name',
        value: newName,
        // This is a key column, ie should form part of the unique liquid key
        isKeyColumn: true,
      },
      refetchQueries: [
        { query: QUERY_DATASET_WITH_SETTINGS, variables: { id: dataset.datasetId } },
      ],
    });
  };

  const handleNameChange = useTextFieldChange(setNewName);

  const error = tagCreationError ?? tagRemovalError;
  const loading = isCreatingTag || isRemovingTag;

  return (
    <>
      <TextField
        label="Bioreactor name"
        placeholder="Enter bioreactor name"
        InputLabelProps={{ shrink: true }}
        disabled={isReadonly || loading}
        value={newName}
        onChange={handleNameChange}
        onBlur={handleSave}
        fullWidth
        variant="outlined"
        margin="dense"
      />
      {loading && <CircularProgress />}
      <BioreactorNameChangeError error={error} />
    </>
  );
}

function BioreactorNameChangeError({ error }: { error?: ApolloError }) {
  const classes = useStyles();
  if (!error) {
    return null;
  }
  /** TODO: Make this a lot less brittle. The error message could change.
   *  See https://synthace.atlassian.net/browse/CI-1672
   **/
  if (
    error.graphQLErrors.some(
      ({ message }) => message === 'Tried to delete not existing DatasetTag',
    )
  ) {
    return (
      <div className={classes.error}>
        <Typography>
          The Bioreactor name was changed elsewhere, please refresh the page and try
          again.
        </Typography>
      </div>
    );
  }

  return <GraphQLErrorPanel error={error} />;
}

function useBioreactorNamesFromDataset(
  dataset: DatasetWithSettingsQuery['dataset'],
): readonly string[] | undefined {
  const bioreactorDatasetColumns = useBioreactorDatasetColumns(dataset);

  // labware name tag only exists if user entered a bioreactor name, in which case there
  // are no names in the dataset.
  const hasLabwareNameTag = dataset.tags.some(
    tag => tag.columnMetadata?.type === 'labware.name',
  );

  if (bioreactorDatasetColumns && !hasLabwareNameTag) {
    return bioreactorDatasetColumns.bioreactorColumnValues;
  }

  return;
}

const useStyles = makeStylesHook(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(6),
  },
  field: {
    marginBottom: theme.spacing(6),
  },
  error: {
    margin: theme.spacing(4),
    textAlign: 'center',
  },
}));
