import { useCallback } from 'react';

import { useGet } from 'client/app/lib/xhr';
import { downloadBlob } from 'common/lib/download';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export default function useDownloadExecutionResults(
  executionId: ExecutionId,
  executionName: string,
) {
  const { showError } = useSnackbarManager();
  const httpGet = useGet();

  return useCallback(async () => {
    const fileUrl = `/data/execution/${executionId}/results`;
    const fileName = `${executionName}.zip`;

    try {
      const blob = await httpGet(fileUrl).then(response => {
        if (!response.ok) {
          showError(`Could not fetch file: ${fileName}`);
        }
        return response.blob();
      });

      downloadBlob(blob, fileName);
    } catch (error) {
      showError(error.message);
    }
  }, [executionId, executionName, httpGet, showError]);
}
