import { Theme } from '@mui/material/styles';
import { StyleRules } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

/**
 * This helper function handles the intersection of:
 * a) Material UI's CSS-in-JS
 * b) React Hooks
 * c) A TS quirk that forces you to provide an object to useStyles
 *    even if you don't need it and it's just an empty object.
 *
 * Using this function, you'll get back a hook that compiles the styles
 * and returns a dictionary of classes when executed within a functional
 * component.
 *
 * The argument can be either:
 * - a style object;
 * - a function whose argument is the current theme and that returns a style object.
 *
 * Thanks to the function version,  you can access and use the theme in your style object.
 */
export default function makeStylesHook<C extends string, P extends object>(
  obj: StyleRules<P, C> | ((theme: Theme) => StyleRules<P, C>),
) {
  const useStyles =
    typeof obj === 'function'
      ? makeStyles<Theme, any, any>(theme => createStyles(obj(theme)))
      : makeStyles(createStyles(obj));
  return (props?: any) => {
    return useStyles(props || {});
  };
}
