import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { useFetchGraphQLElementSet } from 'client/app/api/ElementsApi';
import { useMigrateWorkflow } from 'client/app/api/WorkflowsApi';
import { deserialiseWorkflowResponse } from 'client/app/apps/workflow-builder/lib/workflowUtils';
import CanvasStages from 'client/app/components/ElementPlumber/CanvasStages';
import WorkflowLayout from 'client/app/components/ElementPlumber/WorkflowLayout';
import { State } from 'client/app/state/WorkflowBuilderStateContext';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Workspace from 'common/ui/components/Workspace/Workspace';

type Props = {
  workflowId: WorkflowId;
};

function WorkflowPreview({ workflowId }: Props) {
  const { loading, workflowState } = useInitialiseWorkflow(workflowId);

  const { connections, elementInstances, elementGroups, stages } =
    useCanvasElements(workflowState);

  const stopScroll = useStopScroll(loading);

  return (
    <Container ref={stopScroll}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Workspace
          isShowAllButtonVisible
          isShowHelpButtonVisible={false}
          initialShowAll
          logCategory="ExampleWorkflowPreview"
          canvasControlVariant="light_float"
          variant="dots"
          unzoomedContent={
            <CanvasStages isReadonly stages={stages} allowInteraction={false} />
          }
        >
          <WorkflowLayout
            connections={connections}
            elementInstances={elementInstances}
            elementGroups={elementGroups}
          />
        </Workspace>
      )}
    </Container>
  );
}

export default React.memo(WorkflowPreview);

function useInitialiseWorkflow(workflowId: WorkflowId) {
  const [loading, setLoading] = useState(false);
  const [workflowState, setWorkflowState] = useState<State | undefined>();

  const loadSourceWorkflow = useSourceWorkflow();

  const { showError } = useSnackbarManager();

  useEffect(() => {
    /**
     * Initial load of the workflow bundle and element set
     */
    async function load() {
      const [workflowState, errors] = await loadSourceWorkflow(workflowId);
      if (errors.length > 0) {
        throw new Error(errors.join(' '));
      } else {
        setWorkflowState(workflowState);
      }
    }

    setLoading(true);
    void load()
      .catch(error => {
        showError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loadSourceWorkflow, showError, workflowId]);

  return { loading, workflowState };
}

function useSourceWorkflow(): (workflowId: WorkflowId) => Promise<[State, string[]]> {
  const migrateWorkflow = useMigrateWorkflow();
  const fetchGraphQLElementSet = useFetchGraphQLElementSet();

  return useCallback(
    async (workflowId: WorkflowId) => {
      // workflow after migration returns the element set with elements
      const workflow = await migrateWorkflow(workflowId);
      const elementSet = await fetchGraphQLElementSet(workflow.workflow.elementSetId);
      const { workflowState, errors } = deserialiseWorkflowResponse(workflow, elementSet);
      return [workflowState, errors];
    },
    [fetchGraphQLElementSet, migrateWorkflow],
  );
}

function useCanvasElements(workflowState: State | undefined) {
  return useMemo(() => {
    const elementInstances = workflowState?.elementInstances ?? [];
    const elementGroups = workflowState?.elementGroups ?? [];
    const connections = workflowState?.InstancesConnections ?? [];
    const stages = workflowState?.stages ?? [];
    return { connections, elementInstances, elementGroups, stages };
  }, [workflowState]);
}

function useStopScroll(loading: boolean) {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function stopScrolling(event: WheelEvent) {
      if (!loading) {
        event.preventDefault();
      }
    }
    containerRef.current?.addEventListener('wheel', stopScrolling);
    return () => {
      containerRef.current?.removeEventListener('wheel', stopScrolling);
    };
  }, [loading]);

  return (element: HTMLDivElement | null) => (containerRef.current = element);
}

const Container = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  height: 480,
  borderRadius: 10,
  border: `1px solid ${theme.palette.divider}`,
  overflow: 'hidden',
}));
