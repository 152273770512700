import React from 'react';

import FormHelperText from '@mui/material/FormHelperText';

import { FactorParameterInfo } from 'client/app/components/DOEFactorForm/types';
import AutocompleteEditor from 'client/app/components/Parameters/AutocompleteEditor';
import { AutocompleteAdditionalProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import TextField from 'common/ui/filaments/TextField';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

export type FactorLevelEditorProps = {
  value: string;
  autoFocus: boolean;
  onChange: (value: string | undefined) => void;
  onPressEnter?: (e: React.KeyboardEvent) => void;
  parameterInfo: FactorParameterInfo | undefined;
  disabled: boolean;
  error: boolean;
  helperText: string | undefined;
};

export default function FactorLevelEditor({
  value,
  autoFocus,
  onChange: handleChange,
  onPressEnter: handlePressEnter,
  parameterInfo,
  disabled,
  error,
  helperText,
}: FactorLevelEditorProps) {
  const additionalProps = parameterInfo?.valueEditor
    ?.additionalProps as AutocompleteAdditionalProps;

  const handleTextChange = useTextFieldChange(handleChange);

  return additionalProps?.editor === EditorType.AUTOCOMPLETE ? (
    <>
      <AutocompleteEditor
        anthaType={parameterInfo?.valueType ?? parameterInfo?.keyType ?? ''}
        value={value}
        onChange={handleChange}
        isDisabled={disabled}
        disableClearable
        placeholder="Level Value"
        useDynamicOptions={additionalProps.useDynamicOptions}
        staticOptions={additionalProps.staticOptions}
        anthaTypeOverride={additionalProps.anthaTypeOverride}
        additionalSourceTypes={additionalProps.additionalSourceTypes}
        canAcceptCustomValues={additionalProps.canAcceptCustomValues}
        provideDefaultKey={false}
        hasError={error}
      />
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  ) : (
    <TextField
      value={value}
      autoFocus={autoFocus}
      onChange={handleTextChange}
      onKeyDown={handlePressEnter}
      placeholder="Level Value"
      disabled={disabled}
      error={error}
      helperText={helperText}
    />
  );
}
