import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import { QUERY_DRAFT_WORKFLOWS } from 'client/app/api/gql/queries';
import * as WorkflowsApi from 'client/app/api/WorkflowsApi';
import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import DraftWorkflowCard from 'client/app/components/cards/DraftWorkflowCard';
import {
  draftWorkflowsQuery,
  draftWorkflowsQueryVariables,
  WorkflowSourceEnum,
} from 'client/app/gql';
import usePagination from 'client/app/hooks/usePagination';
import { PageInfo } from 'common/server/graphql/pagination';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import { EntityCardSkeletonList } from 'common/ui/components/EntityCard';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

type DraftListProps = {
  scrollableRef: React.RefObject<HTMLDivElement>;
  searchQuery: string;
  filterWorkflowTypesList: WorkflowSourceEnum[];
  currentUserId: string;
};

export function DraftList(props: DraftListProps) {
  const { currentUserId, searchQuery, filterWorkflowTypesList, scrollableRef } = props;

  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);

  const draftWorkflowQueryVariables: draftWorkflowsQueryVariables = useMemo(
    () => ({
      search: searchQuery,
      userId: currentUserId,
      workflowTypeFilter: filterWorkflowTypesList ?? undefined,
    }),
    [currentUserId, filterWorkflowTypesList, searchQuery],
  );

  const draftWorkflowsQuery = useQuery(QUERY_DRAFT_WORKFLOWS, {
    variables: draftWorkflowQueryVariables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const deleteWorkflow = WorkflowsApi.useDeleteWorkflow();
  const handleDeleteDraftWorkflow = useCallback(
    async (draftId: WorkflowId, name: string) => {
      const isDeleteConfirmed = await openConfirmationDialog({
        action: 'delete',
        isActionDestructive: true,
        object: 'draft',
        specificObject: `${name}`,
      });
      if (isDeleteConfirmed) {
        await deleteWorkflow(draftId);
        await draftWorkflowsQuery.refetch();
      }
    },
    [openConfirmationDialog, deleteWorkflow, draftWorkflowsQuery],
  );

  const pageInfo = draftWorkflowsQuery.data?.draftWorkflows.pageInfo as
    | PageInfo
    | undefined;
  const dependencies = [searchQuery, filterWorkflowTypesList, currentUserId];
  const hasNextPage = usePagination({
    entity: 'draftWorkflows',
    pageInfo,
    fetchMore: draftWorkflowsQuery.fetchMore,
    dependencies,
    scrollableRef,
    isInitialLoading: draftWorkflowsQuery.loading,
    variables: draftWorkflowQueryVariables,
  });

  return (
    <>
      <RenderQuery
        query={draftWorkflowsQuery}
        renderData={DraftWorkflowsListDataComponent}
        renderNoData={DraftWorkflowsListNoDataComponent}
        loadingComponent={EntityCardSkeletonList}
        additionalDataProps={{
          handleDeleteDraftWorkflow,
          hasNextPage,
        }}
        additionalNoDataProps={{
          searchQuery,
        }}
        emptyCondition={data => data.draftWorkflows.items.length === 0}
      />
      {confirmationDialog}
    </>
  );
}

type DraftWorkflowsListDataComponentProps = {
  data: draftWorkflowsQuery;
  hasNextPage: boolean;
  handleDeleteDraftWorkflow: (draftId: WorkflowId, name: string) => void;
};

function DraftWorkflowsListDataComponent(props: DraftWorkflowsListDataComponentProps) {
  const { data, handleDeleteDraftWorkflow, hasNextPage } = props;
  const classes = useStyles();
  return (
    <div className={classes.list}>
      {data.draftWorkflows.items.map(draftWorkflow => (
        <DraftWorkflowCard
          key={draftWorkflow.id}
          draftWorkflow={draftWorkflow}
          onDeleteDraft={handleDeleteDraftWorkflow}
        />
      ))}
      {hasNextPage && (
        <div className={classes.circularLoadingContainer}>
          <CircularProgress size={24} />
        </div>
      )}
    </div>
  );
}

type DraftWorkflowsListNoDataComponentProps = { searchQuery?: string };

function DraftWorkflowsListNoDataComponent(
  props: DraftWorkflowsListNoDataComponentProps,
) {
  const { searchQuery } = props;
  return (
    <NoEntitiesMessage
      entityName="drafts"
      messageType={MessageType.NO_FILTER_RESULTS}
      searchQuery={searchQuery}
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  ...experimentsStyles(theme),
}));
