import React, { useState } from 'react';

import Popover from '@mui/material/Popover';
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { NEW_EXPERIMENT_NAME } from 'client/app/components/NavigationSidepanel';
import Colors from 'common/ui/Colors';
import Button, { Props } from 'common/ui/components/Button';
import { useEnterKeyPress } from 'common/ui/hooks/useEnterKeyPress';
import { usePopover } from 'common/ui/hooks/usePopover';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

export const EXPERIMENT_SOURCE_PARAM = 'experiment';

type CreateNewExperimentButtonProps = {
  onCreateNewExperiment: (experimentName: string) => void;
} & Props;

/**
 * Button that opens a popover with a textfield allowing the user to input a name
 * for the new experiment.
 * The onCreateNewExperiment is run on confirming the input.
 */
export default function CreateNewExperimentButton({
  onCreateNewExperiment,
  ...props
}: CreateNewExperimentButtonProps) {
  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();

  const [newName, setNewName] = useState(NEW_EXPERIMENT_NAME);

  const isNewNameValid = !!newName;

  const handleNameChange = useTextFieldChange(setNewName);

  const handleConfirm = () => {
    if (!isNewNameValid) {
      return;
    }
    onHidePopover();
    onCreateNewExperiment(newName);
  };

  const handleEnterKeyPress = useEnterKeyPress(handleConfirm);

  const handleShowPopover = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setNewName(NEW_EXPERIMENT_NAME);
    onShowPopover(event);
  };

  return (
    <>
      <AddToNewExperimentButton onClick={handleShowPopover} {...props}>
        <Typography variant="button">Add to a new experiment</Typography>
      </AddToNewExperimentButton>
      <CreatorPopover
        elevation={4}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={popoverAnchorElement}
        open={isPopoverOpen}
        onClose={onHidePopover}
      >
        <PopoverContent>
          <TextField
            variant="standard"
            sx={{
              height: '24px',
              '& input': {
                padding: 2,
                backgroundColor: Colors.ACTION_PRIMARY_MAIN_HOVER,
              },
            }}
            autoFocus
            size="small"
            value={newName}
            onChange={handleNameChange}
            onFocus={e => e.target.select()}
            onKeyDown={handleEnterKeyPress}
            InputProps={{
              disableUnderline: true,
            }}
          />
          <ConfirmAdding>
            <AddButton
              variant="tertiary"
              size="small"
              disabled={!isNewNameValid}
              onClick={handleConfirm}
            >
              <Typography variant="button">Add</Typography>
            </AddButton>
            {newName && <Typography variant="body2">{`"${newName}"`}</Typography>}
          </ConfirmAdding>
        </PopoverContent>
      </CreatorPopover>
    </>
  );
}

const AddToNewExperimentButton = styled(Button)(({ theme }) => ({
  height: '24px',
  width: 'max-content',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
  color: theme.palette.primary.main,
}));

const CreatorPopover = styled(Popover)(({ theme }) => ({
  marginTop: theme.spacing(1),
  '& .MuiPaper-root': {
    borderRadius: theme.spacing(3),
  },
}));

const PopoverContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  padding: theme.spacing(5, 4),
  width: '280px',
}));

const ConfirmAdding = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  alignItems: 'center',
  padding: theme.spacing(0, 2),
}));

const AddButton = styled(Button)(({ theme }) => ({
  height: '24px',
  width: 'max-content',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
}));
