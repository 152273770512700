import React from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import { QUERY_DOE_WORKFLOWS } from 'client/app/api/gql/queries';
import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import DOETemplateCard from 'client/app/components/cards/DOETemplateCard';
import { DOEWorkflowsQuery, DOEWorkflowsQueryVariables } from 'client/app/gql';
import usePagination from 'client/app/hooks/usePagination';
import { PageInfo } from 'common/server/graphql/pagination';
import { EntityCardSkeletonList } from 'common/ui/components/EntityCard';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type DOEListProps = {
  scrollableRef: React.RefObject<HTMLDivElement>;
  filterUserId?: string;
  searchQuery?: string;
};

export function DOEList(props: DOEListProps) {
  const { scrollableRef, filterUserId, searchQuery } = props;

  const variables: DOEWorkflowsQueryVariables = {
    userId: filterUserId,
    search: searchQuery,
  };

  const doeQuery = useQuery(QUERY_DOE_WORKFLOWS, { variables });

  const pageInfo = doeQuery.data?.workflows.pageInfo as PageInfo | undefined;
  const dependencies = [filterUserId, searchQuery];
  const hasNextPage = usePagination({
    entity: 'workflows',
    pageInfo,
    fetchMore: doeQuery.fetchMore,
    dependencies,
    scrollableRef,
    isInitialLoading: doeQuery.loading,
    variables,
  });

  return (
    <RenderQuery
      query={doeQuery}
      renderData={DOEListDataComponent}
      renderNoData={DOEListNoDataComponent}
      loadingComponent={EntityCardSkeletonList}
      additionalDataProps={{ hasNextPage }}
      additionalNoDataProps={{
        searchQuery,
      }}
      emptyCondition={data => data.workflows.items.length === 0}
    />
  );
}

type DOEListDataComponentProps = {
  data: DOEWorkflowsQuery;
  hasNextPage: boolean;
};

function DOEListDataComponent(props: DOEListDataComponentProps) {
  const { data, hasNextPage } = props;
  const classes = useStyles();
  return (
    <div className={classes.list}>
      {data.workflows.items.map(doe => (
        <DOETemplateCard key={doe.id} doe={doe} />
      ))}
      {hasNextPage && (
        <div className={classes.circularLoadingContainer}>
          <CircularProgress size={24} />
        </div>
      )}
    </div>
  );
}

type DOEListNoDataComponentProps = { searchQuery?: string };

function DOEListNoDataComponent(props: DOEListNoDataComponentProps) {
  const { searchQuery } = props;
  return (
    <NoEntitiesMessage
      entityName="DOEs"
      messageType={MessageType.NO_FILTER_RESULTS}
      searchQuery={searchQuery}
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  ...experimentsStyles(theme),
}));
