// Commonly used UI debounce timings
export const TEXT_INPUT_LOG_DEBOUNCE_MS = 3000;
export const SLIDER_LOG_DEBOUNCE_MS = 1000;
export const ZOOM_LOG_DEBOUNCE_MS = 1000;

// Identifiers associated with logging events.
export const SIMULATION_DETAILS_PREVIEW_TAB_ID = 'antha-simulations-preview';
export const SIMULATION_DETAILS_OVERVIEW_TAB_ID = 'antha-simulations-overview';
export const SIMULATION_DETAILS_SETUP_TAB_ID = 'antha-simulations-setup';
export const SIMULATION_DETAILS_EXECUTION_TAB_ID = 'antha-simulations-results';
