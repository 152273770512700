import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import cx from 'classnames';

import { TITLE_PLACEHOLDER } from 'client/app/apps/experiments/blocks/TitleBlock';
import { TitleBlock } from 'client/app/gql';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type ExperimentContentsBarProps = {
  titleBlocks: TitleBlock[];
  className?: string;
  onTitleClick: (moduleId: ExperimentModuleId) => void;
};

/**
 * Displays a list of title blocks within the experiment, each clickable to navigate to
 * that title in the experiment body.
 */
export function ExperimentSidebar({
  titleBlocks,
  className,
  onTitleClick,
}: ExperimentContentsBarProps) {
  const classes = useStyles();
  return (
    <List className={cx(classes.list, className)} disablePadding>
      {titleBlocks.map(block => (
        <ListItem button key={block.id} onClick={() => onTitleClick(block.id)}>
          <ListItemText className={classes.listText}>
            {block.title || TITLE_PLACEHOLDER}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}

const useStyles = makeStylesHook(theme => ({
  list: {
    paddingTop: theme.spacing(6),
  },
  listText: {
    marginLeft: theme.spacing(3),
  },
}));
