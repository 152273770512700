import React, { useContext } from 'react';

import { styled } from '@mui/material/styles';

import { Dimensions } from 'common/types/Dimensions';
import Colors from 'common/ui/Colors';
import zIndex from 'common/ui/components/simulation-details/mix/zIndex';
import WorkspaceCoordinatesContext from 'common/ui/components/Workspace/WorkspaceCoordinatesContext';

type Props = {
  region: Pick<Dimensions, 'left' | 'width'>;
  index: number;
  isActive: boolean;
  isFirstStage: boolean;
  isLastStage: boolean;
  onClick: (stage: number) => void;
};

export default function StageRegion({
  region,
  index: stageIndex,
  isActive,
  isFirstStage,
  isLastStage,
  onClick: setActiveStage,
}: Props) {
  const workspaceCoords = useContext(WorkspaceCoordinatesContext);

  const endX = workspaceCoords.toScreenX(region.left + region.width);
  const startX = isFirstStage
    ? Math.min(0, endX - STAGE_INDICATOR_OFFSET)
    : workspaceCoords.toScreenX(region.left);

  return (
    <>
      <StageLine positionX={startX} isActive={isActive}>
        <StageIndicator isActive={isActive} isFirstStage={isFirstStage}>
          <StageNumber
            isActive={isActive}
            isFirstStage={isFirstStage}
            onClick={() => {
              setActiveStage(stageIndex);
            }}
          >
            {stageIndex + 1}
          </StageNumber>
        </StageIndicator>
      </StageLine>
      {isActive && (
        <StageBackground
          style={{
            left: startX,
            width: isLastStage ? 'unset' : endX - startX,
            right: isLastStage ? 0 : 'unset',
          }}
        />
      )}
    </>
  );
}

export const STAGE_INDICATOR_OFFSET = 15;

const StageIndicator = styled('div')<{
  isActive: boolean;
  isFirstStage: boolean;
}>(({ isActive, isFirstStage }) => ({
  display: 'flex',
  alignItems: 'stretch',
  gridArea: '1 / 1',
  alignSelf: 'center',
  marginLeft: isFirstStage ? 0 : -STAGE_INDICATOR_OFFSET,
  color: isActive ? Colors.PRIMARY_CONTRAST : Colors.TEXT_PRIMARY,
  boxShadow: isActive ? undefined : `0 3px 3px rgba(0,0,0,.1)`,
}));

const StageLine = styled('div')<{
  positionX: number;
  isActive: boolean;
}>(({ positionX, isActive }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: positionX,
  zIndex: zIndex.stageLine,

  display: 'grid',
  grid: '1fr / 1px',
  alignItems: 'stretch',
  justifyItems: 'start',

  '&::after': {
    content: '""',
    gridArea: '1 / 1',
    width: '1px',
    borderLeft: `1px solid ${isActive ? Colors.PRIMARY_MAIN : Colors.GREY_30}`,
  },
  '&:hover': !isActive
    ? {
        '&::after': {
          borderLeftColor: Colors.BLUE_10,
        },
        [`& ${StageNumber}`]: {
          background: Colors.BLUE_0,
        },
      }
    : {},
}));

const StageBackground = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  background: Colors.BLUE_5,
  opacity: 0.2,
  pointerEvents: 'none',
  zIndex: zIndex.stageBackground,
}));

const StageNumber = styled('div')<{
  isActive: boolean;
  isFirstStage: boolean;
}>(({ isActive, isFirstStage, theme: { spacing } }) => ({
  background: isActive ? Colors.PRIMARY_MAIN : Colors.WHITE,
  padding: spacing(3, 4),
  borderRadius: isFirstStage ? spacing(0, 2, 2, 0) : spacing(2),
  fontWeight: 500,
  cursor: 'pointer',
  zIndex: zIndex.stageNumber,
}));
