/**
 * If a UUID starts with this prefix we know it is a client-side-only
 * UUID generated for objects used in optimistic mutations.
 * We set the first 8 hex characters (4 bytes) of the UUID, which is
 * the time portion of the UUID. This way we get a valid UUID.
 * Spec: https://tools.ietf.org/html/rfc4122#section-4.1.2
 */
const PREFIX = '12345678';

/**
 * Returns true if given UUID is a temporary client-side id used in an
 * optimistic mutation.
 */
export function isOptimisticResponseId(uuid: string): boolean {
  return uuid.startsWith(PREFIX);
}
