import { Bezier } from 'bezier-js';

import * as LayoutHelper from 'client/app/lib/layout/LayoutHelper';
import { isValidConnection } from 'client/app/lib/layout/ValidConnection';
import { Identifiable } from 'client/app/lib/workflow/cloneWithUUID';
import { arrayIsFalsyOrEmpty } from 'common/lib/data';
import { Connection, ElementInstance, Parameter, Terminus } from 'common/types/bundle';
import { Position2d } from 'common/types/Position';
import { Point2D } from 'common/ui/components/simulation-details/mix/DeckLayout';

export type Side = 'input' | 'output';

export function getOppositeSide(side: Side) {
  return side === 'input' ? 'output' : 'input';
}

export type ConnectionStartCallback = (
  startElementInstance: ElementInstance,
  startSide: Side,
  startPort: Parameter,
) => void;

export type PositionedConnection = {
  connection: Connection;
  position: Position2d;
};

export type ConnectionsLookup = {
  [elementInstanceName: string]: { [portName: string]: Connection[] };
};

/**
 * Returns a list to be used to quickly determine which of the ports the
 * user is nearest when they're dragging a new connection. The connection
 * field is populated with a connection object that could be dispatched
 * to the WorkflowBuilderStateContext.
 */
export function getAvailableConnectionsForPort(
  connectionsLookup: ConnectionsLookup,
  elementInstances: ElementInstance[],
  connections: Connection[],
  startElementInstance: ElementInstance,
  startSide: Side,
  startPort: Parameter,
  isTypeConfigConnectionSettingEnabled: boolean,
) {
  // To make a connection, you have to map an input to an output (or the
  // reverse), but you also have to make sure the 'type' of the complementary
  // port matches the start port.

  const endSide = getOppositeSide(startSide);
  const potentialConnections: PositionedConnection[] = [];
  const existingConnectionsHash = hashupConnections(connections);

  // Traverse all the existing element instances to see which ones still have
  // open ports for connections.
  elementInstances.forEach(endElementInstance => {
    // Prevent connecting an instance to itself
    if (endElementInstance === startElementInstance) {
      return;
    }

    // We know which side of the element the new connections will have to be
    // on (the complement of the start side), so we only look at one side of
    // the element.
    endElementInstance.element[endSide === 'input' ? 'inputs' : 'outputs'].forEach(
      portData => {
        // permit connections of equal type or any white listed connections

        // type compatibility is directional so ensure the ports are specified correctly
        // before feeding in to IsValidConnection
        let inputPort = startPort;
        let outputPort = portData;

        if (startSide === 'output') {
          inputPort = portData;
          outputPort = startPort;
        }

        if (
          isValidConnection(
            inputPort,
            outputPort,
            isTypeConfigConnectionSettingEnabled,
          ) &&
          portData.configuration?.isVisible !== false
        ) {
          // See the comment on makeConnectionHash to understand why the
          // conditional creation of the connection hash below is necessary.
          let newConnectionHash;
          if (endSide === 'input') {
            // Prevent more than one connection to an input.
            const connectionsForPorts = connectionsLookup[endElementInstance.name];
            // It's not enough in this case to just check if the connection lookup
            // has an entry for the current port - inputs and outputs can be named
            // the same thing so to make sure it's actually an input that has a
            // connection we would need to check the target terminus.
            if (
              connectionsForPorts?.[portData.name]?.some(
                connection => connection.Target.ParameterName === portData.name,
              )
            ) {
              return;
            }

            newConnectionHash = makeConnectionHash(
              startElementInstance.name,
              startPort.name,
              endElementInstance.name,
              portData.name,
            );
          } else {
            newConnectionHash = makeConnectionHash(
              endElementInstance.name,
              portData.name,
              startElementInstance.name,
              startPort.name,
            );
          }

          // If the connection already exists, omit it from the list.
          if (existingConnectionsHash.has(newConnectionHash)) {
            return;
          }

          const connection = makeConnectionWithPositionData(
            startElementInstance,
            startSide,
            startPort.name,
            endElementInstance,
            portData.name,
            connections,
          );

          if (connection) {
            potentialConnections.push(connection);
          }
        }
      },
    );
  });

  return potentialConnections;
}

// It would be nice to do something simpler here, but it's not possible.
// Connections are directional from source-to-target and, importantly, you
// can have two instances of the same element, each connecting the same output
// port in one to the input port of the other. For instance, AliquotLiquid
// has liquid inputs and liquid outputs. If you have two of them, you can
// technically create a circular mapping by mapping the output of A into the
// input of B and the output of B back into the input of A. Now, that's not
// a great idea, but it's technically correct. So, our hashing mechanism for
// the connections has be receive the correct ordering things. Womp womp.
function makeConnectionHash(
  sourceInstanceName: string,
  sourcePortName: string,
  targetInstanceName: string,
  targetPortName: string,
) {
  return [sourceInstanceName, sourcePortName, targetInstanceName, targetPortName].join(
    '___',
  );
}

// Converts the list of existing connections into a hash set so that we can
// do fast lookups when deciding what new connections are possible.
function hashupConnections(connections: Connection[]) {
  return new Set(
    connections.map((conn: Connection) =>
      makeConnectionHash(
        conn.Source.ElementInstance,
        conn.Source.ParameterName,
        conn.Target.ElementInstance,
        conn.Target.ParameterName,
      ),
    ),
  );
}

// It's important to remember that 'start' and 'end' here do not tell you
// whether the connection was started on an input and the user is trying
// to connect to an output or if it was the other way around. Hence all
// the hard work required here to figure out how to create the connection
// object that correctly represents the source->target configuration when
// the arguments to this function could be either way around.
function makeConnectionWithPositionData(
  startElementInstance: ElementInstance,
  startSide: Side,
  startPortName: string,
  endElementInstance: ElementInstance,
  endPortName: string,
  existingConnections: Connection[],
) {
  let sourceElementInstanceName;
  let outputPortName;
  let targetElementInstanceName;
  let inputPortName;

  if (startSide === 'output') {
    sourceElementInstanceName = startElementInstance.name;
    outputPortName = startPortName;

    targetElementInstanceName = endElementInstance.name;
    inputPortName = endPortName;
  } else {
    sourceElementInstanceName = endElementInstance.name;
    outputPortName = endPortName;

    targetElementInstanceName = startElementInstance.name;
    inputPortName = startPortName;
  }

  const endPos = LayoutHelper.getPortPositionByName(
    endPortName,
    startSide === 'input' ? 'output' : 'input',
    endElementInstance,
    existingConnections,
  );

  if (!endPos) {
    return undefined;
  }

  return {
    connection: {
      Source: {
        ParameterName: outputPortName,
        ElementInstance: sourceElementInstanceName,
      },
      Target: {
        ElementInstance: targetElementInstanceName,
        ParameterName: inputPortName,
      },
    },

    position: { x: endPos.x, y: endPos.y },
  };
}

export function getConnectionsLookup(connections: Connection[]) {
  const lookupTable: ConnectionsLookup = {};

  function addConnectionToLookupEntryForTerminus(
    connection: Connection,
    terminus: Terminus,
  ) {
    const { ElementInstance: instance, ParameterName: parameter } = terminus;
    // Add the connection to the entry for the parameter, but check if the entry
    // for the instance/parameter exists first, and if it doesn't, create it.
    lookupTable[instance] = lookupTable[instance] ?? {};
    lookupTable[instance][parameter] = lookupTable[instance][parameter] ?? [];
    lookupTable[instance][parameter].push(connection);
  }

  connections.forEach((connection: Connection) => {
    const { Source, Target } = connection;
    addConnectionToLookupEntryForTerminus(connection, Source);
    addConnectionToLookupEntryForTerminus(connection, Target);
  });

  return lookupTable;
}

/**
 * For each side, contains a <type, element instance id> map, so
 * we can lookup which element instances have an input or output of a
 * given type which can be connected to, i.e. not marked as hidden in
 * the element configuration.
 */
export type ConnectablePortMap = {
  [key in Side]: { [type: string]: Set<string> | undefined };
};

export function getConnectablePortMap(
  elementInstances: ElementInstance[],
  isTypeConfigConnectionSettingsEnabled: boolean,
): ConnectablePortMap {
  if (!elementInstances) {
    return { input: {}, output: {} };
  }
  const portMap: ConnectablePortMap = { input: {}, output: {} };

  function addInstanceSidePortsToPortMap(
    id: string,
    side: Side,
    ports: readonly Parameter[],
  ) {
    if (arrayIsFalsyOrEmpty(ports)) {
      return;
    }

    // Hidden ports shouldn't be added to the port map. Even if they are
    // made visible via existing connections, we don't want new
    // connections to be made to them.
    const visiblePorts = ports.filter(isPortConnectable);

    visiblePorts.forEach(port => {
      const { type } = port;
      if (!portMap[side][type]) {
        portMap[side][type] = new Set();
      }
      portMap[side][type]!.add(id);
    });

    if (isTypeConfigConnectionSettingsEnabled && side === 'input') {
      // The port map is used to find element instances that have at least
      // one port that can connect to a port of a given type. Sometimes ports
      // of different types can match - this is determined by the connections
      // object in the configurations of inputs and the easiest way to make
      // this information available to the relevant output ports is through
      // this port map.
      visiblePorts.forEach(port => {
        const { configuration } = port;
        for (const nonMatchingType of configuration?.connections?.allowedTypes ?? []) {
          if (!portMap.input[nonMatchingType]) {
            portMap.input[nonMatchingType] = new Set();
          }
          portMap.input[nonMatchingType]!.add(id);
        }
      });
    }
  }

  elementInstances.forEach(instance => {
    const {
      Id,
      element: { inputs, outputs },
    } = instance;
    addInstanceSidePortsToPortMap(Id, 'input', inputs);
    addInstanceSidePortsToPortMap(Id, 'output', outputs);
  });

  return portMap;
}

/*
 * The square of the greatest distance from the center of a port that we'll
 * consider a successful connection. Squared so that we don't have to do
 * the square root calculations when measuring distances. Jackson arrived at
 * this number by playing with the UI and seeing what felt right to him.
 */
const MAXIMUM_POINTER_UP_DISTANCE = 30 ** 2;

/**
 * Finds the connection and position of the closest connect-able
 * port to the X and Y position provided given a list of connections.
 * Will return null if the x and y are too far from any points.
 * */
export const findNearestConnectionToPosition = (
  currentPosition: Position2d,
  availableConnections: PositionedConnection[],
): PositionedConnection | null => {
  if (availableConnections.length === 0) {
    return null;
  }
  let closestDistance = Infinity;
  let closestConnection = null;
  const { x, y } = currentPosition;
  availableConnections.forEach(connData => {
    const { position } = connData;
    const distance = (x - position.x) ** 2 + (y - position.y) ** 2;
    if (distance < closestDistance) {
      closestDistance = distance;
      closestConnection = connData;
    }
  });

  if (closestDistance > MAXIMUM_POINTER_UP_DISTANCE) {
    return null;
  }

  return closestConnection;
};

/**
 * Given a list of connections and a bounding rectangle, return
 * the ids of the connections which are intersecting the bounding
 * rectangle.
 */
export const findConnectionsIntersectingBounds = (
  connections: Identifiable<Connection>[],
  bounds: LayoutHelper.LayoutDimensions,
  instancesByName: Map<string, ElementInstance>,
) => {
  const { top, left, width, height } = bounds;
  const bottom = top + height;
  const right = left + width;
  const boxLines = [
    // Top edge
    { start: { x: left, y: top }, end: { x: right, y: top } },

    // Right edge
    { start: { x: right, y: top }, end: { x: right, y: bottom } },

    // Bottom edge
    { start: { x: right, y: bottom }, end: { x: left, y: bottom } },

    // Left edge
    { start: { x: left, y: bottom }, end: { x: left, y: top } },
  ];
  const selectedConnectionIDs: string[] = [];

  connections.forEach(conn => {
    const sourceInstance = instancesByName.get(
      conn.Source.ElementInstance,
    ) as ElementInstance;
    const sourcePoint = LayoutHelper.getConnectionTerminusPosition(
      conn.Source,
      'output',
      sourceInstance,
      connections,
    );
    const targetInstance = instancesByName.get(
      conn.Target.ElementInstance,
    ) as ElementInstance;
    const targetPoint = LayoutHelper.getConnectionTerminusPosition(
      conn.Target,
      'input',
      targetInstance,
      connections,
    );

    if (!sourcePoint || !targetPoint) {
      return;
    }

    if (
      // 1) The connection is completely contained within the rectangle
      sourcePoint.x >= left &&
      sourcePoint.x <= right &&
      sourcePoint.y >= top &&
      sourcePoint.y <= bottom &&
      targetPoint.x >= left &&
      targetPoint.x <= right &&
      targetPoint.y >= top &&
      targetPoint.y <= bottom
    ) {
      selectedConnectionIDs.push(conn.id);
    }

    // 2) The connection intersects with one of the edges of the rectangle
    const [c1, c2] = getControlPoints(
      sourcePoint.x,
      sourcePoint.y,
      targetPoint.x,
      targetPoint.y,
    );

    for (const boxLine of boxLines) {
      const curve = new Bezier(
        sourcePoint.x,
        sourcePoint.y,
        c1.x,
        c1.y,
        c2.x,
        c2.y,
        targetPoint.x,
        targetPoint.y,
      );
      const line = { p1: boxLine.start, p2: boxLine.end };
      if (curve.intersects(line).length > 0) {
        selectedConnectionIDs.push(conn.id);
        break;
      }
    }
  });

  return selectedConnectionIDs;
};

export function getControlPoints(
  startX: number,
  startY: number,
  endX: number,
  endY: number,
): Point2D[] {
  const xDelta = endX - startX;
  const yDelta = endY - startY;
  const hyp = Math.sqrt(xDelta * xDelta + yDelta * yDelta);
  const xOffset = Math.max(20, Math.min(200, hyp * 0.5));
  return [
    {
      x: startX + xOffset,
      y: startY,
    },
    {
      x: endX - xOffset,
      y: endY,
    },
  ];
}

export function isPortConnectable(parameter: Parameter): boolean {
  return (
    parameter.configuration?.isVisible !== false &&
    parameter.configuration?.isConnectable !== false
  );
}

export function getConnectedPortsForInstance(
  elementInstance: ElementInstance,
  allConnections: Connection[],
) {
  const connectedPorts = {
    inputs: new Set(),
    outputs: new Set(),
  };

  if (!arrayIsFalsyOrEmpty(allConnections)) {
    allConnections.forEach(connection => {
      if (connection.Source.ElementInstance === elementInstance.name) {
        connectedPorts.outputs.add(connection.Source.ParameterName);
      }

      if (connection.Target.ElementInstance === elementInstance.name) {
        connectedPorts.inputs.add(connection.Target.ParameterName);
      }
    });
  }
  return connectedPorts;
}
