import React, { useEffect, useMemo, useRef, useState } from 'react';

import { styled } from '@mui/material/styles';
import { GridChildComponentProps, VariableSizeGrid } from 'react-window';
import useResizeObserver from 'use-resize-observer';

import {
  PlateTypeWithCompatibility,
  usePlatesWithCompatibility,
} from 'client/app/api/PlateTypesApi';
import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import { PlateCard } from 'client/app/components/Parameters/PlateType/PlateCard';
import { WellBottomShapeEnum } from 'common/types/plateType';
import { getHumanReadableSource } from 'common/ui/components/DeviceCard/DeviceCard';
import { Option } from 'common/ui/components/FilterChip/FilterChipWithCheckbox';
import LinearProgress from 'common/ui/components/LinearProgress';

type PlateMode = 'input' | 'output' | 'both';

function filterPlateByMode(
  { compatibility }: PlateTypeWithCompatibility,
  plateMode: PlateMode,
) {
  switch (plateMode) {
    case 'both':
      return compatibility.input || compatibility.output;
    case 'output':
      return compatibility.output;
    case 'input':
      return compatibility.input;
  }
}

function filterPlateBySource(
  plate: PlateTypeWithCompatibility,
  source: string | undefined,
) {
  if (source) {
    // Convert the plate contentSource value to be human readable
    // and comparable to the string value(s) included in the original query
    const plateSource = getHumanReadableSource(
      plate.plate.contentSource,
    ).humanReadableName;
    return source?.includes(plateSource);
  } else {
    return true;
  }
}

function filterPlateByWellNumber(
  plate: PlateTypeWithCompatibility,
  wellNumberFilter: Option<WellNumberEnum>[],
) {
  const selectedWellNumberFilters = wellNumberFilter.filter(x => x.selected);
  if (selectedWellNumberFilters.length === 0) {
    return true;
  }
  const plateWellCount = plate.plate.columns * plate.plate.rows;

  return selectedWellNumberFilters.some(x => {
    if (x.value === WellNumberEnum.OTHER) {
      return ![96, 384, 1536].includes(plateWellCount);
    }
    return x.value === plateWellCount;
  });
}

function filterPlateByWellBottomShape(
  plate: PlateTypeWithCompatibility,
  wellBottomShapeFilter: Option<WellBottomShapeEnum>[],
) {
  const selectedWellBottomShapeFilter = wellBottomShapeFilter.filter(x => x.selected);
  if (selectedWellBottomShapeFilter.length === 0) {
    return true;
  }
  return selectedWellBottomShapeFilter.some(
    x => x.value === plate.plate.wellShape.bottomType,
  );
}

function filterPlateByWellVolumeRange(
  plate: PlateTypeWithCompatibility,
  wellVolumeRangeFilter: Option<WellVolumeRangeEnum>[],
) {
  const selectedWellVolumeRangeFilter = wellVolumeRangeFilter.filter(x => x.selected);
  if (selectedWellVolumeRangeFilter.length === 0) {
    return true;
  }
  const wellVolumeUl = plate.plate.wellShape.volumeOverrideUl;
  for (const filter of selectedWellVolumeRangeFilter) {
    switch (filter.value) {
      case WellVolumeRangeEnum.VOLUME0_100UL:
        if (wellVolumeUl >= 0 && wellVolumeUl <= 100) {
          return true;
        }
        break;
      case WellVolumeRangeEnum.VOLUME100_500UL:
        if (wellVolumeUl >= 100 && wellVolumeUl <= 500) {
          return true;
        }
        break;
      case WellVolumeRangeEnum.VOLUME500_1000UL:
        if (wellVolumeUl >= 500 && wellVolumeUl <= 1000) {
          return true;
        }
        break;
      case WellVolumeRangeEnum.VOLUME1_2ML:
        if (wellVolumeUl >= 1000 && wellVolumeUl <= 2000) {
          return true;
        }
        break;
      case WellVolumeRangeEnum.VOLUME_GREATER_THAN_2ML:
        if (wellVolumeUl > 2000) {
          return true;
        }
        break;
    }
  }
  return false;
}

function filterPlates(
  queryWithCase: string | undefined,
  plates: PlateTypeWithCompatibility[],
  plateMode: PlateMode,
  source: string | undefined,
  wellNumberFilter: Option<WellNumberEnum>[],
  wellBottomShapeFilter: Option<WellBottomShapeEnum>[],
  wellVolumeRangeFilter: Option<WellVolumeRangeEnum>[],
) {
  // NOTE(flooey): This implementation is a bit wasteful, but currently (2019-10-24) rendering
  // the plate grid takes significantly more time than filtering the plates.  If performance
  // becomes an issue, there are significant optimizations we could make here.  In order of
  // least effort/complexity/effectiveness to most, these are probably:
  // 1. Cache the result of toLowerCase() on plate properties so it doesn't have to run
  // for every query
  // 2. Create a single property field that contains the concatenated searchable properties
  // and cache them there
  // 3. Have the server store and send the precomputed searchable property so it doesn't
  // have to be computed on the client side
  // 4. Create a real search index on the server side and serve search results from that
  const plateTypeFilteredPlates = plates.filter(plate => {
    return (
      filterPlateByMode(plate, plateMode) &&
      filterPlateByWellNumber(plate, wellNumberFilter) &&
      filterPlateByWellBottomShape(plate, wellBottomShapeFilter) &&
      filterPlateByWellVolumeRange(plate, wellVolumeRangeFilter) &&
      filterPlateBySource(plate, source)
    );
  });

  const query = queryWithCase ? queryWithCase.toLowerCase().split(/\s+/) : [];
  const queryFilteredPlates = plateTypeFilteredPlates.filter(({ plate }) => {
    // For each token in the query, see if it can be found anywhere on the plate.  We want
    // to only return the plates that match every token in the query, though all tokens need
    // not be found in the same field.
    const targets = [
      plate.name.toLowerCase(),
      plate.type.toLowerCase(),
      plate.description.toLowerCase(),
      plate.catalogNumber.toLowerCase(),
      plate.usage.toLowerCase(),
      plate.manufacturer.toLowerCase(),
    ];
    return query?.every(token => targets.some(t => t.includes(token)));
  });

  return queryFilteredPlates;
}

export enum WellNumberEnum {
  WELL96 = 96,
  WELL384 = 384,
  WELL1536 = 1536,
  OTHER = -1,
}

export enum WellVolumeRangeEnum {
  VOLUME0_100UL = 1,
  VOLUME100_500UL = 2,
  VOLUME500_1000UL = 3,
  VOLUME1_2ML = 4,
  VOLUME_GREATER_THAN_2ML = 5,
}

export type PlatesGridContainerProps = {
  onSelect?: (plate: string) => void;
  onDoubleSelect?: (plate: string) => void;
  query?: string;
  filterSource?: string;
  wellNumberFilter: Option<WellNumberEnum>[];
  wellBottomShapeFilter: Option<WellBottomShapeEnum>[];
  wellVolumeRangeFilter: Option<WellVolumeRangeEnum>[];
  selectedPlate?: string;
  deviceIds?: DeviceId[];
  plateMode: PlateMode;
  onDeletePlateType?: (plateName: string, plateType: string) => void;
  onCopyPlateType?: (plateId: string) => void;
  onUpdatePlateType?: (plateId: string) => void;
};

export default function PlatesGridContainer(props: PlatesGridContainerProps) {
  const {
    query,
    filterSource,
    wellBottomShapeFilter,
    wellNumberFilter,
    wellVolumeRangeFilter,
    onDeletePlateType,
    selectedPlate,
    onCopyPlateType,
    onDoubleSelect,
    onSelect,
    onUpdatePlateType,
    deviceIds,
    plateMode,
  } = props;
  const ref = useRef<HTMLDivElement>(null);

  const { width, height } = useResizeObserver({ ref });

  const [plateList, isInitialLoading] = usePlatesWithCompatibility(deviceIds);

  const filteredPlates = useMemo(() => {
    return filterPlates(
      query,
      plateList,
      plateMode,
      filterSource,
      wellNumberFilter,
      wellBottomShapeFilter,
      wellVolumeRangeFilter,
    );
  }, [
    filterSource,
    plateList,
    plateMode,
    query,
    wellBottomShapeFilter,
    wellNumberFilter,
    wellVolumeRangeFilter,
  ]);

  return (
    <FulHeightDiv ref={ref}>
      {isInitialLoading || !height || !width ? (
        <LinearProgress />
      ) : (
        <PlatesGrid
          onDeletePlateType={onDeletePlateType}
          selectedPlate={selectedPlate}
          onCopyPlateType={onCopyPlateType}
          onDoubleSelect={onDoubleSelect}
          onSelect={onSelect}
          onUpdatePlateType={onUpdatePlateType}
          plates={filteredPlates}
          width={width}
          height={height}
        />
      )}
    </FulHeightDiv>
  );
}

type PlateGridProps = Pick<
  PlatesGridContainerProps,
  | 'onSelect'
  | 'onCopyPlateType'
  | 'onDeletePlateType'
  | 'onDoubleSelect'
  | 'query'
  | 'selectedPlate'
  | 'onUpdatePlateType'
> & {
  plates: PlateTypeWithCompatibility[];
  width: number;
  height: number;
};

function PlatesGrid(props: PlateGridProps) {
  const {
    query,
    onDeletePlateType,
    selectedPlate,
    onCopyPlateType,
    onDoubleSelect,
    onSelect,
    onUpdatePlateType,
    plates,
    width,
    height,
  } = props;

  const columnCount = Math.floor(width / (COLUMN_WIDTH + GUTTER_SIZE));
  const rowCount = Math.ceil(plates.length / columnCount);

  // In order to centre the plate cards, apply some left padding. This is neccessary as the cards are positioned absolutely due to be windowed
  const leftGap = (width - columnCount * (COLUMN_WIDTH + GUTTER_SIZE)) / 2;

  const gridRef = useRef<VariableSizeGrid>(null);
  const cellHeights = useRef<number[]>([]);

  const [isGridLoaded, setIsGridLoaded] = useState(false);

  useEffect(() => {
    if (!isGridLoaded && gridRef.current) {
      const selectedPlateIndex = plates.findIndex(
        plate => selectedPlate === plate.plate.type,
      );
      if (selectedPlateIndex !== -1) {
        const columnIndex = selectedPlateIndex % columnCount;
        const rowIndex = Math.floor(selectedPlateIndex / columnCount);
        /**
         * We only wish to scroll to item if:
         * 1. On initial loading of the page.
         * 2. There is a selected plate.
         * 3. After `gridRef` has been set (tracked by `isGridLoaded`)
         */
        gridRef.current.scrollToItem({ columnIndex, rowIndex, align: 'start' });
      }

      setIsGridLoaded(true);
    }
  }, [columnCount, isGridLoaded, plates, rowCount, selectedPlate]);

  function getRowHeight(rowIndex: number) {
    const cellIndex = rowIndex * columnCount;
    // Get all cells in the current row
    const rowCellHeights = cellHeights.current.slice(cellIndex, cellIndex + columnCount);

    if (rowCellHeights.length === 0) {
      return ESTIMATED_ROW_HEIGHT + GUTTER_SIZE;
    }

    const rowHeight = Math.max(...rowCellHeights);
    if (isNaN(rowHeight)) {
      return ESTIMATED_ROW_HEIGHT + GUTTER_SIZE;
    }
    return rowHeight + GUTTER_SIZE;
  }

  function setCellHeight(index: number, height: number) {
    if (cellHeights.current[index] !== height) {
      // Only reset the grid if the cell height has changed
      gridRef.current?.resetAfterRowIndex(0);
      cellHeights.current[index] = height;
    }
  }

  return query !== '' && plates.length === 0 ? (
    <NoEntitiesMessage
      entityName="plate types"
      messageType={MessageType.NO_FILTER_RESULTS}
      searchQuery={query}
    />
  ) : (
    <VariableSizeGrid<CellProps>
      height={height}
      columnCount={columnCount}
      columnWidth={() => COLUMN_WIDTH + GUTTER_SIZE}
      rowCount={rowCount}
      rowHeight={getRowHeight}
      estimatedRowHeight={ESTIMATED_ROW_HEIGHT}
      width={width}
      ref={gridRef}
      itemData={{
        selectedPlate,
        columnCount,
        onCopyPlateType,
        onDeletePlateType,
        onDoubleSelect,
        onSelect,
        onUpdatePlateType,
        leftGap,
        setCellHeight,
        plates,
      }}
    >
      {Cell}
    </VariableSizeGrid>
  );
}

type CellProps = Pick<
  PlateGridProps,
  | 'onCopyPlateType'
  | 'onDeletePlateType'
  | 'onSelect'
  | 'onDoubleSelect'
  | 'onUpdatePlateType'
  | 'selectedPlate'
> & {
  columnCount: number;
  leftGap: number;
  setCellHeight: (index: number, height: number) => void;
  plates: PlateTypeWithCompatibility[];
};

function Cell({
  data,
  columnIndex,
  rowIndex,
  style,
}: GridChildComponentProps<CellProps>) {
  const {
    columnCount,
    leftGap,
    plates,
    onCopyPlateType,
    onDeletePlateType,
    onSelect,
    onDoubleSelect,
    onUpdatePlateType,
    setCellHeight,
  } = data;
  const index = rowIndex * columnCount + columnIndex;
  const plateCardRef = useRef<Element | null>(null);

  useResizeObserver({
    ref: plateCardRef,
    onResize(size) {
      if (size.height) {
        setCellHeight(index, size.height);
      }
    },
  });

  if (!plates[index]) {
    return null;
  }

  const { plate, compatibility } = plates[index];

  return (
    <div
      style={{
        ...style,
        left: typeof style.left === 'number' ? style.left + leftGap : undefined,
        top: typeof style.top === 'number' ? style.top + GUTTER_SIZE : undefined,
        width: typeof style.width === 'number' ? style.width - GUTTER_SIZE : undefined,
        height: typeof style.height === 'number' ? style.height - GUTTER_SIZE : undefined,
      }}
      ref={ref => {
        if (ref) {
          plateCardRef.current = ref.firstElementChild;
        }
      }}
    >
      <PlateCard
        key={plate.type}
        selected={!!data.selectedPlate && data.selectedPlate === plate.type}
        onSelect={onSelect}
        onDoubleSelect={onDoubleSelect}
        onDeletePlateType={onDeletePlateType}
        onCopyPlateType={onCopyPlateType}
        onUpdatePlateType={onUpdatePlateType}
        accessibleDeviceCompatibilityMessage={
          compatibility.accessibleDeviceCompatibilityMessage
        }
        plate={plate}
      />
    </div>
  );
}

const FulHeightDiv = styled('div')({ height: '100%' });

const GUTTER_SIZE = 20;
const COLUMN_WIDTH = 400;
const ESTIMATED_ROW_HEIGHT = 277;
