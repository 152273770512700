// Values for different keys can have different types
type Obj = { [k: string]: any };

// Given a list of objects with unique ids,
// returns an object mapping id -> object.
export function indexById<ObjType extends Obj>(
  objList: readonly ObjType[],
): { [id: string]: ObjType } {
  return indexBy(objList, 'id');
}

// Given a list of objects each with a unique value of `propName`,
// returns an object mapping `propName` -> object.
// Throws if the value of `propName` is not unique.
export function indexBy<ObjType extends Obj>(
  objList: readonly ObjType[],
  propName: keyof ObjType,
): { [propName: string]: ObjType } {
  return objList.reduce((map, obj) => {
    const val = obj[propName];
    if (map[val]) {
      throw Error(`Duplicate property ${String(propName)}: ${val}`);
    }
    map[val] = obj;
    return map;
  }, {} as { [propName: string]: ObjType });
}

/**
 * Given a list of things, returns a list of unique things.
 * Uniqueness is determined by mapping the object to string.
 */
export function unique<T>(objList: T[], keyFunc: (obj: T) => string): T[] {
  const r = objList.reduce((map, obj) => {
    map[keyFunc(obj)] = obj;
    return map;
  }, {} as { [k: string]: T });
  return Object.values(r);
}

// Given a list of objects each with a potentially non-unique value of
// `propName`, returns a "multimap" object mapping `propName` -> object[].
export function groupBy<ObjType>(
  objList: readonly ObjType[],
  propName: keyof ObjType,
): { [p: string]: ObjType[] } {
  return groupByFunc(objList, obj => String(obj[propName]));
}

// Given a list of objects, returns a "multimap" object mapping the return
// value of `propFunc` -> object[].
export function groupByFunc<ObjType>(
  objList: readonly ObjType[],
  propFunc: (o: ObjType) => string,
): { [p: string]: ObjType[] } {
  return objList.reduce((map, obj) => {
    const val = propFunc(obj);
    if (map[val]) {
      map[val].push(obj);
    } else {
      map[val] = [obj];
    }
    return map;
  }, {} as { [p: string]: ObjType[] });
}

// Returns true if obj is undefined, null or {}.
export function isFalsyOrEmptyObject(obj: Obj | null | undefined): boolean {
  if (!obj) {
    return true;
  }
  if (obj.constructor !== Object) {
    const msg = 'Passed value of wrong type to isFalsyOrEmptyObject';
    console.error(msg, obj);
    throw Error(msg);
  }
  return Object.keys(obj).length === 0;
}

// Returns true if array is undefined, null, or [].
export function arrayIsFalsyOrEmpty<T>(arr: readonly T[] | null | undefined): boolean {
  if (!arr) {
    return true;
  }
  if (!Array.isArray(arr)) {
    const msg = 'Passed value of wrong type to arrayIsFalsyOrEmpty';
    console.error(msg, arr);
    throw Error(msg);
  }
  return arr.length === 0;
}

// Allows TypeScript to infer the return type of array.filter() correctly.
// Example:
// const array = ['foo', 'bar', null, 'zoo', undefined];
// const filteredArray: string[] = array.filter(isDefined);
// See https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

// Helper to make discriminating between values of a union type a bit nicer.
// Example: Instead of `deckItem.kind === 'plate'`, use `is(deckItem, 'plate')`
export function is<
  // The union type, for example `type DeckItem = Plate | Tipbox`
  Union extends { kind: string },
  // Here is where the magic happens - the `Kind` gets fixed to a string
  // literal, for example the Kind will be 'plate'.
  Kind extends Union['kind'],
  // By doing `? Union : never`, we tell TypeScript to only choose the member
  // of the `Union` that has the matching `Kind`, which is fixed to 'plate',
  // for example.
  SelectedKind extends Union extends { kind: Kind } ? Union : never,
>(value: Union, kind: Kind): value is SelectedKind {
  return value.kind === kind;
}

/**
 * Performs equality check by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 *
 * This function is useful when you want the behavior of a PureComponent but
 * already extend some other class and therefore cannot extend PureComponent.
 */
export function shallowEqual<T extends Obj>(objA: T, objB: T): boolean {
  // Copied from the React codebase:
  // https://github.com/facebook/react/blob/b87aabdfe1b7461e7331abb3601d9e6bb27544bc/packages/shared/shallowEqual.js
  // We could look for a library in npm but having the bit of code here is simpler.
  // If we ever want a special children-aware comparison, check out
  // https://www.npmjs.com/package/react-shallow-equal
  if (Object.is(objA, objB)) {
    return true;
  }

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  for (let i = 0; i < keysA.length; i += 1) {
    if (
      !Object.hasOwnProperty.call(objB, keysA[i]) ||
      // Object.is is supported in all browsers (including Edge) except IE.
      // If we want to support IE we could use a polyfill.
      !Object.is(objA[keysA[i]], objB[keysA[i]])
    ) {
      return false;
    }
  }

  return true;
}

/**
 * Finds an element in an array using a predicate `pred` or throws an error with
 * the result of `errorMessage`.
 */
export function findOrFail<T>(
  objects: readonly T[],
  pred: (obj: T) => boolean,
  errorMessage: () => string,
): T {
  const obj = objects.find(pred);
  if (!obj) {
    throw new Error(errorMessage());
  }
  return obj;
}

/**
 * Get last element of an array.
 * @param items
 * @throws on empty list
 */
export function getLastOrFail<T>(items: T[]): T {
  if (!items) {
    throw new Error('The list is empty.');
  }
  return items[items.length - 1];
}

/**
 * Convert an array of objects into a map of 'key: object'. Note that duplicate keys are simply overwritten.
 * @param array An array of objects
 * @param keyName The property of the objects to use as the key
 */
export function toMap<Value extends object, Key extends string>(
  array: Value[],
  keyName: keyof Value & Key,
): Map<string, Value> {
  const map = new Map();
  array.forEach(value => {
    map.set(value[keyName], value);
  });
  return map;
}
