import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import Typography from '@mui/material/Typography';
import { mergeRefs } from 'react-merge-refs';
import useResizeObserver from 'use-resize-observer';

import {
  isNumericFactor,
  LEVEL_MARGIN,
  SHOW_MORE_LABEL_WIDTH,
} from 'client/app/components/DOEBuilder/factorUtils';
import { FactorItem } from 'common/types/bundle';
import getMeasurementFromString from 'common/ui/components/ParameterEditors/unitRegistry';
import useThrottle from 'common/ui/hooks/useThrottle';

type FactorLevelsProps = {
  factors: FactorItem[];
};

export default function FactorLevels({ factors }: FactorLevelsProps) {
  const allLevelsCount = factors.flatMap(f => f.values).length;
  const [levelsToShow, setLevelsToShow] = useState(allLevelsCount);
  const containerRef = useRef<HTMLElement | null>(null);
  const moreToShow = allLevelsCount - levelsToShow;
  const displayFactorName = factors.length > 1;

  const rerenderAllChips = useThrottle(
    /**
     * The +1 is here just to make the setState value different from the initial.
     * Because setting state to the same value would not trigger a re-render
     * and consequently the layout effect below.
     */
    () => setLevelsToShow(allLevelsCount + 1),
    100,
  );
  const observer = useResizeObserver({
    onResize: rerenderAllChips,
  });
  const mergedRef = mergeRefs([observer.ref, containerRef]);

  useEffect(() => {
    rerenderAllChips();
  }, [factors, rerenderAllChips]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    /**
     * This effect limits the amount of levels shown to avoid exceeding the factor row boundary.
     */
    const container = containerRef.current;
    if (container) {
      const box = container.getBoundingClientRect();
      const chips = container.getElementsByClassName('level');

      let chipTotalWidth = 0;
      let count = 0;
      let i = 1;

      for (const chip of chips) {
        // If this is the last chip, and it fits, we don't need to worry about saving space for the more label.
        const isLast = i === allLevelsCount;

        const chipRect = chip.getBoundingClientRect();
        const chipWidth = chipRect.width + LEVEL_MARGIN;

        if (chipTotalWidth + chipWidth > box.width - (isLast ? 0 : SHOW_MORE_LABEL_WIDTH))
          break;

        chipTotalWidth += chipWidth;
        count += 1;
        i++;
      }

      setLevelsToShow(count);
    }
  });

  return (
    <div className="levels" ref={mergedRef}>
      {factors
        .flatMap(f => f.values.map(v => ({ value: v, factor: f })))
        .slice(0, levelsToShow)
        .map((valueWithFactor, index) => {
          if (!valueWithFactor.value) return null;

          if (!isNumericFactor(valueWithFactor.factor)) {
            return (
              <div key={index} className="level">
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  className="stringValue"
                >
                  {valueWithFactor.value}
                </Typography>
              </div>
            );
          }

          const level = getMeasurementFromString(valueWithFactor.value);
          return (
            <div key={index} className="level">
              {displayFactorName && (
                <>
                  <Typography variant="subtitle2" color="textPrimary">
                    {valueWithFactor.factor.displayName}
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    :
                  </Typography>
                </>
              )}
              <Typography variant="subtitle2" color="textPrimary">
                {level.value}
              </Typography>
              {valueWithFactor.factor.unit && (
                <Typography variant="caption" color="textSecondary">
                  {valueWithFactor.factor.unit}
                </Typography>
              )}
            </div>
          );
        })}
      {moreToShow > 0 && (
        <Typography variant="caption" color="textSecondary" className="more">
          +{moreToShow} more
        </Typography>
      )}
    </div>
  );
}
