export const WELLS_384 = [
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
  'A6',
  'A7',
  'A8',
  'A9',
  'A10',
  'A11',
  'A12',
  'A13',
  'A14',
  'A15',
  'A16',
  'A17',
  'A18',
  'A19',
  'A20',
  'A21',
  'A22',
  'A23',
  'A24',
  'B1',
  'B2',
  'B3',
  'B4',
  'B5',
  'B6',
  'B7',
  'B8',
  'B9',
  'B10',
  'B11',
  'B12',
  'B13',
  'B14',
  'B15',
  'B16',
  'B17',
  'B18',
  'B19',
  'B20',
  'B21',
  'B22',
  'B23',
  'B24',
  'C1',
  'C2',
  'C3',
  'C4',
  'C5',
  'C6',
  'C7',
  'C8',
  'C9',
  'C10',
  'C11',
  'C12',
  'C13',
  'C14',
  'C15',
  'C16',
  'C17',
  'C18',
  'C19',
  'C20',
  'C21',
  'C22',
  'C23',
  'C24',
  'D1',
  'D2',
  'D3',
  'D4',
  'D5',
  'D6',
  'D7',
  'D8',
  'D9',
  'D10',
  'D11',
  'D12',
  'D13',
  'D14',
  'D15',
  'D16',
  'D17',
  'D18',
  'D19',
  'D20',
  'D21',
  'D22',
  'D23',
  'D24',
  'E1',
  'E2',
  'E3',
  'E4',
  'E5',
  'E6',
  'E7',
  'E8',
  'E9',
  'E10',
  'E11',
  'E12',
  'E13',
  'E14',
  'E15',
  'E16',
  'E17',
  'E18',
  'E19',
  'E20',
  'E21',
  'E22',
  'E23',
  'E24',
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6',
  'F7',
  'F8',
  'F9',
  'F10',
  'F11',
  'F12',
  'F13',
  'F14',
  'F15',
  'F16',
  'F17',
  'F18',
  'F19',
  'F20',
  'F21',
  'F22',
  'F23',
  'F24',
  'G1',
  'G2',
  'G3',
  'G4',
  'G5',
  'G6',
  'G7',
  'G8',
  'G9',
  'G10',
  'G11',
  'G12',
  'G13',
  'G14',
  'G15',
  'G16',
  'G17',
  'G18',
  'G19',
  'G20',
  'G21',
  'G22',
  'G23',
  'G24',
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'H6',
  'H7',
  'H8',
  'H9',
  'H10',
  'H11',
  'H12',
  'H13',
  'H14',
  'H15',
  'H16',
  'H17',
  'H18',
  'H19',
  'H20',
  'H21',
  'H22',
  'H23',
  'H24',
  'I1',
  'I2',
  'I3',
  'I4',
  'I5',
  'I6',
  'I7',
  'I8',
  'I9',
  'I10',
  'I11',
  'I12',
  'I13',
  'I14',
  'I15',
  'I16',
  'I17',
  'I18',
  'I19',
  'I20',
  'I21',
  'I22',
  'I23',
  'I24',
  'J1',
  'J2',
  'J3',
  'J4',
  'J5',
  'J6',
  'J7',
  'J8',
  'J9',
  'J10',
  'J11',
  'J12',
  'J13',
  'J14',
  'J15',
  'J16',
  'J17',
  'J18',
  'J19',
  'J20',
  'J21',
  'J22',
  'J23',
  'J24',
  'K1',
  'K2',
  'K3',
  'K4',
  'K5',
  'K6',
  'K7',
  'K8',
  'K9',
  'K10',
  'K11',
  'K12',
  'K13',
  'K14',
  'K15',
  'K16',
  'K17',
  'K18',
  'K19',
  'K20',
  'K21',
  'K22',
  'K23',
  'K24',
  'L1',
  'L2',
  'L3',
  'L4',
  'L5',
  'L6',
  'L7',
  'L8',
  'L9',
  'L10',
  'L11',
  'L12',
  'L13',
  'L14',
  'L15',
  'L16',
  'L17',
  'L18',
  'L19',
  'L20',
  'L21',
  'L22',
  'L23',
  'L24',
  'M1',
  'M2',
  'M3',
  'M4',
  'M5',
  'M6',
  'M7',
  'M8',
  'M9',
  'M10',
  'M11',
  'M12',
  'M13',
  'M14',
  'M15',
  'M16',
  'M17',
  'M18',
  'M19',
  'M20',
  'M21',
  'M22',
  'M23',
  'M24',
  'N1',
  'N2',
  'N3',
  'N4',
  'N5',
  'N6',
  'N7',
  'N8',
  'N9',
  'N10',
  'N11',
  'N12',
  'N13',
  'N14',
  'N15',
  'N16',
  'N17',
  'N18',
  'N19',
  'N20',
  'N21',
  'N22',
  'N23',
  'N24',
  'O1',
  'O2',
  'O3',
  'O4',
  'O5',
  'O6',
  'O7',
  'O8',
  'O9',
  'O10',
  'O11',
  'O12',
  'O13',
  'O14',
  'O15',
  'O16',
  'O17',
  'O18',
  'O19',
  'O20',
  'O21',
  'O22',
  'O23',
  'O24',
  'P1',
  'P2',
  'P3',
  'P4',
  'P5',
  'P6',
  'P7',
  'P8',
  'P9',
  'P10',
  'P11',
  'P12',
  'P13',
  'P14',
  'P15',
  'P16',
  'P17',
  'P18',
  'P19',
  'P20',
  'P21',
  'P22',
  'P23',
  'P24',
];

export const BY_COLUMN_96_QUADRANT_IN_384_WELLS = [
  'A1',
  'C1',
  'E1',
  'G1',
  'I1',
  'K1',
  'M1',
  'O1',
  'A3',
  'C3',
  'E3',
  'G3',
  'I3',
  'K3',
  'M3',
  'O3',
  'A5',
  'C5',
  'E5',
  'G5',
  'I5',
  'K5',
  'M5',
  'O5',
  'A7',
  'C7',
  'E7',
  'G7',
  'I7',
  'K7',
  'M7',
  'O7',
  'A9',
  'C9',
  'E9',
  'G9',
  'I9',
  'K9',
  'M9',
  'O9',
  'A11',
  'C11',
  'E11',
  'G11',
  'I11',
  'K11',
  'M11',
  'O11',
  'A13',
  'C13',
  'E13',
  'G13',
  'I13',
  'K13',
  'M13',
  'O13',
  'A15',
  'C15',
  'E15',
  'G15',
  'I15',
  'K15',
  'M15',
  'O15',
  'A17',
  'C17',
  'E17',
  'G17',
  'I17',
  'K17',
  'M17',
  'O17',
  'A19',
  'C19',
  'E19',
  'G19',
  'I19',
  'K19',
  'M19',
  'O19',
  'A21',
  'C21',
  'E21',
  'G21',
  'I21',
  'K21',
  'M21',
  'O21',
  'A23',
  'C23',
  'E23',
  'G23',
  'I23',
  'K23',
  'M23',
  'O23',
  'B1',
  'D1',
  'F1',
  'H1',
  'J1',
  'L1',
  'N1',
  'P1',
  'B3',
  'D3',
  'F3',
  'H3',
  'J3',
  'L3',
  'N3',
  'P3',
  'B5',
  'D5',
  'F5',
  'H5',
  'J5',
  'L5',
  'N5',
  'P5',
  'B7',
  'D7',
  'F7',
  'H7',
  'J7',
  'L7',
  'N7',
  'P7',
  'B9',
  'D9',
  'F9',
  'H9',
  'J9',
  'L9',
  'N9',
  'P9',
  'B11',
  'D11',
  'F11',
  'H11',
  'J11',
  'L11',
  'N11',
  'P11',
  'B13',
  'D13',
  'F13',
  'H13',
  'J13',
  'L13',
  'N13',
  'P13',
  'B15',
  'D15',
  'F15',
  'H15',
  'J15',
  'L15',
  'N15',
  'P15',
  'B17',
  'D17',
  'F17',
  'H17',
  'J17',
  'L17',
  'N17',
  'P17',
  'B19',
  'D19',
  'F19',
  'H19',
  'J19',
  'L19',
  'N19',
  'P19',
  'B21',
  'D21',
  'F21',
  'H21',
  'J21',
  'L21',
  'N21',
  'P21',
  'B23',
  'D23',
  'F23',
  'H23',
  'J23',
  'L23',
  'N23',
  'P23',
  'A2',
  'C2',
  'E2',
  'G2',
  'I2',
  'K2',
  'M2',
  'O2',
  'A4',
  'C4',
  'E4',
  'G4',
  'I4',
  'K4',
  'M4',
  'O4',
  'A6',
  'C6',
  'E6',
  'G6',
  'I6',
  'K6',
  'M6',
  'O6',
  'A8',
  'C8',
  'E8',
  'G8',
  'I8',
  'K8',
  'M8',
  'O8',
  'A10',
  'C10',
  'E10',
  'G10',
  'I10',
  'K10',
  'M10',
  'O10',
  'A12',
  'C12',
  'E12',
  'G12',
  'I12',
  'K12',
  'M12',
  'O12',
  'A14',
  'C14',
  'E14',
  'G14',
  'I14',
  'K14',
  'M14',
  'O14',
  'A16',
  'C16',
  'E16',
  'G16',
  'I16',
  'K16',
  'M16',
  'O16',
  'A18',
  'C18',
  'E18',
  'G18',
  'I18',
  'K18',
  'M18',
  'O18',
  'A20',
  'C20',
  'E20',
  'G20',
  'I20',
  'K20',
  'M20',
  'O20',
  'A22',
  'C22',
  'E22',
  'G22',
  'I22',
  'K22',
  'M22',
  'O22',
  'A24',
  'C24',
  'E24',
  'G24',
  'I24',
  'K24',
  'M24',
  'O24',
  'B2',
  'D2',
  'F2',
  'H2',
  'J2',
  'L2',
  'N2',
  'P2',
  'B4',
  'D4',
  'F4',
  'H4',
  'J4',
  'L4',
  'N4',
  'P4',
  'B6',
  'D6',
  'F6',
  'H6',
  'J6',
  'L6',
  'N6',
  'P6',
  'B8',
  'D8',
  'F8',
  'H8',
  'J8',
  'L8',
  'N8',
  'P8',
  'B10',
  'D10',
  'F10',
  'H10',
  'J10',
  'L10',
  'N10',
  'P10',
  'B12',
  'D12',
  'F12',
  'H12',
  'J12',
  'L12',
  'N12',
  'P12',
  'B14',
  'D14',
  'F14',
  'H14',
  'J14',
  'L14',
  'N14',
  'P14',
  'B16',
  'D16',
  'F16',
  'H16',
  'J16',
  'L16',
  'N16',
  'P16',
  'B18',
  'D18',
  'F18',
  'H18',
  'J18',
  'L18',
  'N18',
  'P18',
  'B20',
  'D20',
  'F20',
  'H20',
  'J20',
  'L20',
  'N20',
  'P20',
  'B22',
  'D22',
  'F22',
  'H22',
  'J22',
  'L22',
  'N22',
  'P22',
  'B24',
  'D24',
  'F24',
  'H24',
  'J24',
  'L24',
  'N24',
  'P24',
];

export const BY_ROW_96_QUADRANT_IN_384_WELLS = [
  'A1',
  'A3',
  'A5',
  'A7',
  'A9',
  'A11',
  'A13',
  'A15',
  'A17',
  'A19',
  'A21',
  'A23',
  'C1',
  'C3',
  'C5',
  'C7',
  'C9',
  'C11',
  'C13',
  'C15',
  'C17',
  'C19',
  'C21',
  'C23',
  'E1',
  'E3',
  'E5',
  'E7',
  'E9',
  'E11',
  'E13',
  'E15',
  'E17',
  'E19',
  'E21',
  'E23',
  'G1',
  'G3',
  'G5',
  'G7',
  'G9',
  'G11',
  'G13',
  'G15',
  'G17',
  'G19',
  'G21',
  'G23',
  'I1',
  'I3',
  'I5',
  'I7',
  'I9',
  'I11',
  'I13',
  'I15',
  'I17',
  'I19',
  'I21',
  'I23',
  'K1',
  'K3',
  'K5',
  'K7',
  'K9',
  'K11',
  'K13',
  'K15',
  'K17',
  'K19',
  'K21',
  'K23',
  'M1',
  'M3',
  'M5',
  'M7',
  'M9',
  'M11',
  'M13',
  'M15',
  'M17',
  'M19',
  'M21',
  'M23',
  'O1',
  'O3',
  'O5',
  'O7',
  'O9',
  'O11',
  'O13',
  'O15',
  'O17',
  'O19',
  'O21',
  'O23',
  'A2',
  'A4',
  'A6',
  'A8',
  'A10',
  'A12',
  'A14',
  'A16',
  'A18',
  'A20',
  'A22',
  'A24',
  'C2',
  'C4',
  'C6',
  'C8',
  'C10',
  'C12',
  'C14',
  'C16',
  'C18',
  'C20',
  'C22',
  'C24',
  'E2',
  'E4',
  'E6',
  'E8',
  'E10',
  'E12',
  'E14',
  'E16',
  'E18',
  'E20',
  'E22',
  'E24',
  'G2',
  'G4',
  'G6',
  'G8',
  'G10',
  'G12',
  'G14',
  'G16',
  'G18',
  'G20',
  'G22',
  'G24',
  'I2',
  'I4',
  'I6',
  'I8',
  'I10',
  'I12',
  'I14',
  'I16',
  'I18',
  'I20',
  'I22',
  'I24',
  'K2',
  'K4',
  'K6',
  'K8',
  'K10',
  'K12',
  'K14',
  'K16',
  'K18',
  'K20',
  'K22',
  'K24',
  'M2',
  'M4',
  'M6',
  'M8',
  'M10',
  'M12',
  'M14',
  'M16',
  'M18',
  'M20',
  'M22',
  'M24',
  'O2',
  'O4',
  'O6',
  'O8',
  'O10',
  'O12',
  'O14',
  'O16',
  'O18',
  'O20',
  'O22',
  'O24',
  'B1',
  'B3',
  'B5',
  'B7',
  'B9',
  'B11',
  'B13',
  'B15',
  'B17',
  'B19',
  'B21',
  'B23',
  'D1',
  'D3',
  'D5',
  'D7',
  'D9',
  'D11',
  'D13',
  'D15',
  'D17',
  'D19',
  'D21',
  'D23',
  'F1',
  'F3',
  'F5',
  'F7',
  'F9',
  'F11',
  'F13',
  'F15',
  'F17',
  'F19',
  'F21',
  'F23',
  'H1',
  'H3',
  'H5',
  'H7',
  'H9',
  'H11',
  'H13',
  'H15',
  'H17',
  'H19',
  'H21',
  'H23',
  'J1',
  'J3',
  'J5',
  'J7',
  'J9',
  'J11',
  'J13',
  'J15',
  'J17',
  'J19',
  'J21',
  'J23',
  'L1',
  'L3',
  'L5',
  'L7',
  'L9',
  'L11',
  'L13',
  'L15',
  'L17',
  'L19',
  'L21',
  'L23',
  'N1',
  'N3',
  'N5',
  'N7',
  'N9',
  'N11',
  'N13',
  'N15',
  'N17',
  'N19',
  'N21',
  'N23',
  'P1',
  'P3',
  'P5',
  'P7',
  'P9',
  'P11',
  'P13',
  'P15',
  'P17',
  'P19',
  'P21',
  'P23',
  'B2',
  'B4',
  'B6',
  'B8',
  'B10',
  'B12',
  'B14',
  'B16',
  'B18',
  'B20',
  'B22',
  'B24',
  'D2',
  'D4',
  'D6',
  'D8',
  'D10',
  'D12',
  'D14',
  'D16',
  'D18',
  'D20',
  'D22',
  'D24',
  'F2',
  'F4',
  'F6',
  'F8',
  'F10',
  'F12',
  'F14',
  'F16',
  'F18',
  'F20',
  'F22',
  'F24',
  'H2',
  'H4',
  'H6',
  'H8',
  'H10',
  'H12',
  'H14',
  'H16',
  'H18',
  'H20',
  'H22',
  'H24',
  'J2',
  'J4',
  'J6',
  'J8',
  'J10',
  'J12',
  'J14',
  'J16',
  'J18',
  'J20',
  'J22',
  'J24',
  'L2',
  'L4',
  'L6',
  'L8',
  'L10',
  'L12',
  'L14',
  'L16',
  'L18',
  'L20',
  'L22',
  'L24',
  'N2',
  'N4',
  'N6',
  'N8',
  'N10',
  'N12',
  'N14',
  'N16',
  'N18',
  'N20',
  'N22',
  'N24',
  'P2',
  'P4',
  'P6',
  'P8',
  'P10',
  'P12',
  'P14',
  'P16',
  'P18',
  'P20',
  'P22',
  'P24',
];
