import React from 'react';

import { styled } from '@mui/material/styles';
import MuiTab from '@mui/material/Tab';

type TabsProps = {
  index: number;
  label: string;
  switchTab: (tabIndex: number) => void;
};

export function ExecutionDetailsTab({ index, label, switchTab }: TabsProps) {
  return <Tab label={label} onClick={() => switchTab(index)} />;
}

type TabPanelProps = {
  children?: React.ReactNode;
  active: boolean;
};

export function ExecutionDetailsTabPanel({ children, active }: TabPanelProps) {
  return (
    <TabPanel role="tabpanel" hidden={!active}>
      {children}
    </TabPanel>
  );
}

const Tab = styled(MuiTab)({
  width: 200,
});

const TabPanel = styled('div')({
  flexGrow: 1,
});
