import { useMemo } from 'react';

import groupBy from 'lodash/groupBy';

import {
  Execution,
  LabwareCardData,
  LabwareFile,
  LabwareHistory,
  LabwareHistoryDataset,
} from 'client/app/apps/execution-details/types';
import {
  extractPlates,
  getLiquidHandlingTasks,
} from 'client/app/apps/simulation-details/dataUtils';
import { LabwareHistoryAnalyticProperties, LabwareHistoryType } from 'client/app/gql';
import { basename, byName } from 'common/lib/strings';
import { Deck } from 'common/types/mix';

/**
 * TODO: The simulation data and plate types should be a part of GQL payload
 * for each ExecutionStage.
 * For now we simply reuse the existing logic from SimulationDetails page as
 * the final design is likely to change and we would like to build the final
 * logic according to the final design.
 */
export default function useLabware(execution: Execution, deck: Deck) {
  return useMemo(() => {
    let labwareByName = new Map<string, LabwareCardData>();
    labwareByName = buildLabwareData(labwareByName, execution);
    labwareByName = attachFinalPlateLayoutFiles(labwareByName, execution, deck);

    return sortLabware(labwareByName);
  }, [deck, execution]);
}

function buildLabwareData(
  labwareByName: Map<string, LabwareCardData>,
  execution: Execution,
) {
  const result = new Map(labwareByName);

  // Group all the labware histories by name
  const labwareHistoriesByName = groupBy(execution.labwareHistory, 'name');

  // For each name, create a plate and fill in all the files referenced by those histories
  for (const labwareHistories of Object.values(labwareHistoriesByName)) {
    // Sort the histories so that the files show up in chronological order
    labwareHistories.sort((a, b) => a.ordering - b.ordering);
    // We grouped these by name above, so every labwareHistory entry has the same name and — by
    // extension, since the name identifies the piece of labware — labware.  Use the first one
    // arbitrarily to get those properties.
    const firstLabwareHistory = labwareHistories[0];
    result.set(firstLabwareHistory.name, {
      id: firstLabwareHistory.labware.id,
      name: firstLabwareHistory.name,
      files: [],
      barcode: firstLabwareHistory.labware.barcode,
      synthetic: firstLabwareHistory.historyType === LabwareHistoryType.MAPPED_LAYOUT,
    });
    for (const labwareHistory of labwareHistories) {
      const labware = result.get(labwareHistory.name)!;
      labware.files.push(...getDataFiles(labwareHistory));
    }
  }

  return result;
}

function attachFinalPlateLayoutFiles(
  labwareByName: Map<string, LabwareCardData>,
  execution: Execution,
  deck: Deck,
) {
  const result = new Map(labwareByName);

  const deckPlates = new Map(extractPlates(deck).map(p => [p.name, p]));

  /**
   * TODO: we should not use simulation object here as it incorporates a lot of
   * unnecessary payload being transfered through the network.
   * Instead we should figure out what parts of the simulation are used and for what
   * purposes and create dedicated GrapqhQL fields serving those purposes
   */
  const mixTasks = getLiquidHandlingTasks(execution.simulation.tasks);
  if (mixTasks) {
    for (const labware of result.values()) {
      // There is always a file named "PLATE NAME.csv" for each plate from the execution, and we
      // want to list it first
      if (!labware.synthetic && deckPlates.has(labware.name)) {
        const deckPlate = deckPlates.get(labware.name)!;
        labware.files.unshift({
          name: deckPlate.plateFileName,
          displayName: 'Final plate layout',
          deckPlate,
        });
      }
    }
  }

  return result;
}

function sortLabware(labwareByName: Map<string, LabwareCardData>) {
  const labwareList = [...labwareByName.values()];
  labwareList.sort((plateA, plateB) => {
    if (plateA.synthetic && !plateB.synthetic) {
      return -1; // if plate A is synthetic and plate B isn't, then move it up
    }
    if (!plateA.synthetic && plateB.synthetic) {
      return 1; // if plate A is not synthetic and plate B is, then move it down
    }

    return byName(plateA, plateB);
  });
  return labwareList;
}

function getDataFiles(labwareHistory: LabwareHistory): LabwareFile[] {
  const resultFiles: LabwareFile[] = [];

  // There may be duplicates among the origin files if a single origin file produced multiple
  // parsed files, and we don't want to show them twice
  const displayedOriginFiles = new Set<string>();

  for (const dataset of labwareHistory.datasets) {
    if (displayedOriginFiles.has(dataset.originFile.originFiletreeLink)) continue;

    displayedOriginFiles.add(dataset.originFile.originFiletreeLink);

    resultFiles.push(
      makeDataFile(dataset.originFile.originFiletreeLink, labwareHistory, dataset),
    );
  }

  return resultFiles;
}

function makeDataFile(
  fileTreeLink: FiletreeLink,
  labwareHistory: LabwareHistory,
  dataset: LabwareHistoryDataset,
): LabwareFile {
  const displayName = getFileDisplayName(
    labwareHistory.historyType,
    (labwareHistory.properties as LabwareHistoryAnalyticProperties).kind,
    fileTreeLink,
    dataset?.deviceName,
  );

  return {
    name: fileTreeLink,
    displayName,
    allowDelete: labwareHistory.historyType === LabwareHistoryType.EXTERNAL_DATA,
    originFile: dataset.originFile,
  };
}

function getFileDisplayName(
  labwareHistoryType: LabwareHistoryType,
  analyticalPropsKind: string,
  filetreeLink: FiletreeLink,
  datasetDeviceName: string | null,
) {
  let displayName;
  if (labwareHistoryType === LabwareHistoryType.ANALYTIC) {
    displayName = displayNameForKind(analyticalPropsKind);
  } else {
    displayName = `${basename(filetreeLink)} — Uploaded data`;
    if (datasetDeviceName) {
      displayName += ` from ${datasetDeviceName}`;
    }
  }
  return displayName;
}

function displayNameForKind(kind: string): string {
  switch (kind) {
    case 'platereader':
      return 'Plate reader data';
    default:
      return 'Device data';
  }
}

export function arePlateDataUploaded(labwareList: LabwareCardData[]): boolean {
  // All plates have 'Final plate layout.csv' by default (length=1).
  // If they have additional data attached, then length becomes >1.
  // Here we check if any of the plates has data attached.
  for (const labware of labwareList) {
    if (labware.files.length > 1) {
      return true;
    }
  }
  return false;
}
