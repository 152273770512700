import { Dimensions } from 'common/types/Dimensions';
import { Position2d } from 'common/types/Position';

export function arePointsClose(p1: Position2d, p2: Position2d, threshold: number) {
  return getDistance(p1, p2) <= threshold;
}

export function getDistance(p1: Position2d, p2: Position2d) {
  return Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
}

export function isPositionInRect(p: Position2d, rect: Dimensions) {
  return (
    p.x >= rect.left &&
    p.x <= rect.left + rect.width &&
    p.y >= rect.top &&
    p.y <= rect.top + rect.height
  );
}
