import React, { CSSProperties, useCallback, useMemo, useState } from 'react';

import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { formatWellColumn, formatWellRow } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import Popover from 'common/ui/components/Popover';
import {
  DeckItemWithWellsGeometry,
  RectPixels,
} from 'common/ui/components/simulation-details/mix/DeckLayout';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type AxisName = 'horizontal' | 'vertical';

export type Props = {
  geometry: DeckItemWithWellsGeometry;
  onLabelClick?: (index: number) => void;
  /**
   * horizontal = label on the top (1, 2, etc)
   * vertical = label on the left (A, B, etc)
   */
  axis: AxisName;
  colOrRow: number;
  /**
   * Allow clicking on row/column headers
   */
  isInteractive?: boolean;
  visible: boolean;
};

export default function AxisLabel({
  geometry,
  onLabelClick,
  colOrRow,
  axis,
  isInteractive,
  visible,
}: Props) {
  const classes = useStyles();
  const [isMouseOverLabel, setIsMouseOverLabel] = useState<boolean>(false);

  const labelStyle = useMemo(() => {
    const style: CSSProperties = {};
    if (isInteractive) {
      style.cursor = 'pointer';
    }
    if (geometry.getCurrentRotationDegrees() !== 0) {
      // If the whole plate is rotated, also rotate the labels
      style.transform = `rotate(${geometry.getCurrentRotationDegrees()}deg)`;
    }
    return style;
  }, [geometry, isInteractive]);

  const { centerX, centerY, labelSize } = useMemo(
    () =>
      axis === 'horizontal'
        ? geometry.getLabelPosForX(colOrRow)
        : geometry.getLabelPosForY(colOrRow),
    [axis, colOrRow, geometry],
  );

  const hoverRect = useMemo(
    () => getHoverRect(axis, colOrRow, geometry),
    [axis, colOrRow, geometry],
  );

  const handlePointerEnter = useCallback(() => setIsMouseOverLabel(true), []);
  const handlePointerLeave = useCallback(() => setIsMouseOverLabel(false), []);
  const handleClick = useCallback(
    () => onLabelClick?.(colOrRow),
    [colOrRow, onLabelClick],
  );

  const axisLabel =
    axis === 'horizontal' ? formatWellColumn(colOrRow) : formatWellRow(colOrRow);

  return (
    <g
      key={colOrRow}
      transform={`translate(${centerX}, ${centerY})`}
      visibility={visible ? 'visible' : 'hidden'}
    >
      {isInteractive && isMouseOverLabel && (
        <rect
          x={hoverRect.left}
          y={hoverRect.top}
          width={hoverRect.width}
          height={hoverRect.height}
          className={classes.rowHover}
        />
      )}
      <Popover
        classes={{ tooltip: classes.axisTooltip }}
        title={
          <Typography color="textPrimary" variant="h1">
            {axisLabel}
          </Typography>
        }
        placement={axis === 'horizontal' ? 'top' : 'left'}
        disableHoverListener={!isInteractive}
      >
        <text
          className={classes.axisLabel}
          style={labelStyle}
          textAnchor="middle"
          dominantBaseline="central"
          fontSize={labelSize}
          onPointerEnter={isInteractive ? handlePointerEnter : undefined}
          onPointerLeave={isInteractive ? handlePointerLeave : undefined}
          onClick={isInteractive ? handleClick : undefined}
        >
          {axisLabel}
        </text>
      </Popover>
    </g>
  );
}

function getHoverRect(
  axis: AxisName,
  colOrRow: number,
  geometry: DeckItemWithWellsGeometry,
): RectPixels {
  // We want the rect to overflow slightly the edge of the row/column
  // so we adjust each of the values with this arbritrary ratio.
  const rectAdjustRatio = 1.02;
  // Increase the rect height/width proportional to the well height/width to create an overflowing
  // rect that appears as wrapping the wells.
  const adjustedDimension = (dimension: number) => {
    return Math.min(dimension / 2, 5) * rectAdjustRatio;
  };
  // absolutePosInDeckPixels includes the height and width of the plate
  const plateDimensions = geometry.absolutePosInDeckPixels;
  const { yWellStartOffset, xWellStartOffset } = geometry.getWellStartOffsets();
  if (axis === 'horizontal') {
    const wellDimensions = geometry.getWellRect(colOrRow, 0);
    const adjustedWidth = wellDimensions.width + adjustedDimension(wellDimensions.width);
    return {
      left: -adjustedWidth / 2,
      top: -yWellStartOffset / 2,
      width: adjustedWidth,
      height: (plateDimensions.height - yWellStartOffset) * rectAdjustRatio,
    };
  } else {
    const wellDimensions = geometry.getWellRect(0, colOrRow);
    const adjustedHeight =
      wellDimensions.height + adjustedDimension(wellDimensions.height);
    return {
      left: -xWellStartOffset / 2,
      top: -adjustedHeight / 2,
      width: (plateDimensions.width - xWellStartOffset) * rectAdjustRatio,
      height: adjustedHeight,
    };
  }
}

const useStyles = makeStylesHook({
  axisLabel: {
    // Rotate each label around its own center (the center of the text),
    // as opposed to rotating around the center of the plate.
    transformBox: 'fill-box',
    transformOrigin: 'center',
  },
  axisTooltip: {
    height: '56px',
    width: '56px',
    textAlign: 'center',
  },
  rowHover: {
    fill: alpha(Colors.INFO_MAIN, 0.1),
  },
});
