import React, { useRef } from 'react';

import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import PlateContentsEditorContents from 'client/app/components/Parameters/PlateContents/PlateContentsEditorContents';
import Colors from 'common/ui/Colors';

type Props = {
  onClose: () => void;
  className: string;
};

export default function PlateContentsEditorPanel({ onClose, className }: Props) {
  const panelRef = useRef<HTMLDivElement>(null);

  return (
    <Grow in unmountOnExit>
      <Panel ref={panelRef} className={className} elevation={4}>
        <PlateContentsEditorContents containerRef={panelRef} onClose={onClose} />
      </Panel>
    </Grow>
  );
}

const Panel = styled(Paper)({
  display: 'grid',
  gridTemplate: `
    'titlebar' auto
    'main' minmax(0, 1fr)
  `,
  backgroundColor: Colors.WHITE,
  borderRadius: '8px',
});
