import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import produce from 'immer';
import debounce from 'lodash/debounce';

import { ElementParameterList } from 'client/app/components/Parameters/ElementParameterGroupList';
import {
  WELL_ITERATION_ORDER_PARAMETER_NAME,
  WELL_ITERATION_PATTERN_PARAMETER_NAME,
} from 'client/app/components/Parameters/PlateContents/lib/generateWellIterationUtils';
import {
  ContentsByWell,
  generatePlateContentParams,
  getLiquidIdentifierForGroup,
  getPlateContentsAdditionalProps,
  PlateContentParams,
} from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import { ParameterStateContext } from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import { isDefined } from 'common/lib/data';
import { getFirstValue, mapValues } from 'common/object';
import {
  Parameter,
  ParameterValue,
  ParameterValueDict,
  WorkflowConfig,
} from 'common/types/bundle';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

type Props = {
  contentsByWell: ContentsByWell;
  elementId: string;
  instanceName: string;
  isParameterEditingDisabled?: boolean;
  onGroupContentsChange: (contentsByWell: ContentsByWell) => void;
  /**
   * This function will run on mounting and return true if all the well parameters
   * can be saved (i.e. are all defined).
   */
  canSaveParameters?: (valid: boolean) => void;
  plateContentParams: PlateContentParams;
  workflowConfig: WorkflowConfig;
  allLiquidIdentifierNames: string[];
};

const DEFAULT_PARAMETERS: ParameterValueDict = {};

/**
 * Displays the list of parameter inputs for a given group of wells.
 */
export default function WellParameters({
  elementId,
  instanceName,
  plateContentParams,
  workflowConfig,
  contentsByWell,
  onGroupContentsChange,
  isParameterEditingDisabled: isParametersDisabled,
  canSaveParameters,
  allLiquidIdentifierNames,
}: Props) {
  const locationParamName = plateContentParams.contentLocationParam.name;
  const ignoreLocationParamWhenGrouping =
    getPlateContentsAdditionalProps(plateContentParams)?.ignoreWhenGrouping;

  const snackbarManager = useSnackbarManager();

  const parameters = generatePlateContentParams(plateContentParams);

  // The content is the same for all wells within a group so we can get the content for
  // the first well.
  //
  // The exception is the plateContentParams.contentLocationParam, which may be set to be ignored when
  // grouping such that multiple values can be specified per well.
  const paramValues = useMemo<ParameterValueDict>(() => {
    const paramValues: ParameterValueDict = {
      ...getFirstValue(contentsByWell),
    };
    if (ignoreLocationParamWhenGrouping) {
      const locationParamValues: Record<string, unknown> = {};
      for (const [well, wellParams] of contentsByWell) {
        locationParamValues[well] = wellParams[locationParamName];
      }
      paramValues[locationParamName] = locationParamValues;
    }
    return paramValues;
  }, [locationParamName, contentsByWell, ignoreLocationParamWhenGrouping]);

  // These are the parameters that can be specified for a given group of wells.
  // We should always have a parameter that is used as the map key that is of the name
  // contained in plateContentParams.contentLocationParam.name and which is aliased in
  // the element code to a string.
  // We also want to separate out the well pattern and well iteration order to allow
  // us to selectively disable those.
  const {
    liquidDefinitionParameter,
    wellPatternParameter,
    wellOrderParameter,
    allOtherParameters,
  } = useMemo<{
    liquidDefinitionParameter: Parameter[];
    wellPatternParameter?: Parameter;
    wellOrderParameter?: Parameter;
    allOtherParameters: Parameter[];
  }>(() => {
    const liquidDefinitionParameter: Parameter[] = [];
    let wellPatternParameter;
    let wellOrderParameter;
    const allOtherParameters: Parameter[] = [];
    parameters.forEach(param => {
      switch (param.name) {
        case plateContentParams.contentLocationParam.name:
          liquidDefinitionParameter.push(param);
          break;
        case WELL_ITERATION_PATTERN_PARAMETER_NAME:
          wellPatternParameter = param;
          break;
        case WELL_ITERATION_ORDER_PARAMETER_NAME:
          wellOrderParameter = param;
          break;
        default:
          allOtherParameters.push(param);
      }
    });
    return {
      liquidDefinitionParameter,
      wellPatternParameter,
      wellOrderParameter,
      allOtherParameters,
    };
  }, [parameters, plateContentParams.contentLocationParam.name]);

  const { getStateForParameter } = useContext(ParameterStateContext);

  const liquidIdentifierForGroup = useMemo(
    () => getLiquidIdentifierForGroup(contentsByWell, plateContentParams),
    [contentsByWell, plateContentParams],
  );

  // We want to make sure that the user cannot update an existing liquid identifier
  // to another that also exists. We need to keep track of the value the user inputs.
  // It would be neater to use, for example, a ref to get the current value of the input,
  // but this is tricky with our current code structure because we use generic ElementParameter
  // components to show the various inputs and cannot currently pass refs to all of these.
  // So, we store the input as a state.
  const [temporaryLiquidIdentifier, setTemporaryLiquidIdentifier] = useState(
    liquidIdentifierForGroup,
  );
  const areNonLiquidIdentifierParametersDisabled = useMemo(() => {
    // The liquidIdentifierForGroup is updated automatically if valid, so if the temporaryLiquidIdentifier
    // is not the same, then it must be invalid and cannot be saved.
    return isParametersDisabled || temporaryLiquidIdentifier !== liquidIdentifierForGroup;
  }, [isParametersDisabled, liquidIdentifierForGroup, temporaryLiquidIdentifier]);

  const hideNonLiquidIdentifierParameters = useMemo(() => {
    return !temporaryLiquidIdentifier || !liquidIdentifierForGroup;
  }, [liquidIdentifierForGroup, temporaryLiquidIdentifier]);

  const isWellIterationParameterDisabled = useMemo(() => {
    return (
      areNonLiquidIdentifierParametersDisabled ||
      paramValues[WELL_ITERATION_PATTERN_PARAMETER_NAME] === 'As Selected'
    );
  }, [areNonLiquidIdentifierParametersDisabled, paramValues]);

  const checkLiquidIdentifierAlreadyExists = useCallback(
    (name: string) => {
      return allLiquidIdentifierNames
        .filter(name => name !== liquidIdentifierForGroup)
        .includes(name);
    },
    [allLiquidIdentifierNames, liquidIdentifierForGroup],
  );

  useEffect(() => {
    const areAllRequiredParametersDefined = parameters.every(param => {
      const paramState = getStateForParameter(instanceName, param.name);
      return paramState?.isRequired ? isDefined(paramValues[param.name]) : true;
    });

    const isIdentifierPresent = checkLiquidIdentifierAlreadyExists(
      temporaryLiquidIdentifier,
    );

    canSaveParameters?.(
      areAllRequiredParametersDefined &&
        !!liquidIdentifierForGroup &&
        !isIdentifierPresent,
    );
  }, [
    allLiquidIdentifierNames,
    canSaveParameters,
    contentsByWell,
    getStateForParameter,
    instanceName,
    liquidIdentifierForGroup,
    paramValues,
    parameters,
    plateContentParams,
    temporaryLiquidIdentifier,
    checkLiquidIdentifierAlreadyExists,
  ]);

  /**
   * Because the change handler in WellGroupList that updates the state is debounced,
   * changes could be lost if the user changed two parameters in quick succession, as the
   * second call would wipe out the first. This would leave the form controls inconsistent
   * with the state store and sometimes stop the user from clicking "Add".
   *
   * We fix this here by storing all pending changes and including them in any subsequent
   * call to the change handler. When the debounced call is eventually fired and the
   * `contentsByWell` prop changes, we reset the pending changes.
   */
  const [pendingChanges, setPendingChanges] = useState<
    Record<string, ParameterValueDict>
  >({});

  useEffect(() => {
    setPendingChanges({});
  }, [contentsByWell]);

  const handleChange = useCallback(
    (parameterName: string, newValue: ParameterValue) => {
      pendingChanges[parameterName] = newValue;
      setPendingChanges(pendingChanges);

      onGroupContentsChange(
        // For each well, change the update the parameter value
        mapValues(contentsByWell, (well, contents) => {
          return produce(contents, draft => {
            for (const param in pendingChanges) {
              const value = pendingChanges[param];
              draft[param] =
                param === locationParamName && ignoreLocationParamWhenGrouping
                  ? value?.[well]
                  : value;
            }
          });
        }),
      );
    },
    [
      pendingChanges,
      onGroupContentsChange,
      contentsByWell,
      locationParamName,
      ignoreLocationParamWhenGrouping,
    ],
  );

  const showLiquidIdentifierSnackbarError = useCallback(
    (liquidName: string) => {
      snackbarManager.showError(
        `"${liquidName}" already exists on this plate, please choose a unique name.`,
      );
    },
    [snackbarManager],
  );

  const debouncedSnackbarError = useRef<any>();

  useEffect(() => {
    debouncedSnackbarError.current = debounce(showLiquidIdentifierSnackbarError, 1500);
    return () => debouncedSnackbarError.current?.cancel();
  }, [debouncedSnackbarError, showLiquidIdentifierSnackbarError]);

  const handleUpdateLiquidIndentifier = useCallback(
    (parameterName: string, newValue: ParameterValue) => {
      if (typeof newValue !== 'string' && newValue !== undefined) {
        // This parameter should always be a string
        return;
      }
      const isIdentifierPresent = checkLiquidIdentifierAlreadyExists(newValue);
      if (!isIdentifierPresent) {
        debouncedSnackbarError.current?.cancel();
        handleChange(parameterName, newValue);
      } else {
        debouncedSnackbarError.current?.(newValue);
      }
      setTemporaryLiquidIdentifier(newValue);
    },
    [checkLiquidIdentifierAlreadyExists, debouncedSnackbarError, handleChange],
  );

  return (
    <>
      <ElementParameterList
        parameters={liquidDefinitionParameter}
        elementId={elementId}
        parameterValueDict={paramValues}
        onChange={handleUpdateLiquidIndentifier}
        isDisabled={isParametersDisabled}
        workflowConfig={workflowConfig}
        instanceName={instanceName}
        defaultParameters={DEFAULT_PARAMETERS}
      />
      {!hideNonLiquidIdentifierParameters && (
        <>
          {wellPatternParameter && (
            <ElementParameterList
              parameters={[wellPatternParameter]}
              elementId={elementId}
              parameterValueDict={paramValues}
              onChange={handleChange}
              isDisabled={areNonLiquidIdentifierParametersDisabled}
              workflowConfig={workflowConfig}
              instanceName={instanceName}
              defaultParameters={DEFAULT_PARAMETERS}
            />
          )}
          {wellOrderParameter && (
            <ElementParameterList
              parameters={[wellOrderParameter]}
              elementId={elementId}
              parameterValueDict={paramValues}
              onChange={handleChange}
              isDisabled={isWellIterationParameterDisabled}
              workflowConfig={workflowConfig}
              instanceName={instanceName}
              defaultParameters={DEFAULT_PARAMETERS}
            />
          )}
          <ElementParameterList
            parameters={allOtherParameters}
            elementId={elementId}
            parameterValueDict={paramValues}
            onChange={handleChange}
            isDisabled={areNonLiquidIdentifierParametersDisabled}
            workflowConfig={workflowConfig}
            instanceName={instanceName}
            defaultParameters={DEFAULT_PARAMETERS}
          />
        </>
      )}
    </>
  );
}
