import React from 'react';
import { useCallback } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import CherryPickContainer, {
  ParameterMode,
} from 'client/app/apps/cherry-picker/CherryPickLayout';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  parameterMode: ParameterMode;
};

export default function CherryPickerEditorDialog(props: Props) {
  const classes = useStyles();
  const { parameterMode } = props;

  // Allow users to press ESC and close the modal without setting
  // new values
  const handleCancel = useCallback(() => {
    parameterMode.onCancel(false);
  }, [parameterMode]);

  return (
    <Dialog open onClose={handleCancel} fullScreen>
      <DialogContent className={classes.dialogContent}>
        <CherryPickContainer parameterMode={parameterMode} />
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStylesHook({
  dialogContent: {
    // Remove padding to make it truly full screen
    padding: '0',
    '&:first-child': {
      paddingTop: '0',
    },
  },
});
