import React from 'react';

import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';

type Props = {
  searchQuery?: string;
};

const ExampleWorkflowsListNoDataComponent = ({ searchQuery }: Props) => {
  return (
    <NoEntitiesMessage
      entityName="example workflows"
      messageType={MessageType.NO_FILTER_RESULTS}
      searchQuery={searchQuery}
    />
  );
};

export default ExampleWorkflowsListNoDataComponent;
