import { useContext, useEffect, useRef } from 'react';

import throttle from 'lodash/throttle';

import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import stopPropagation from 'common/lib/stopPropagation';
import { Group } from 'common/types/bundle';
import { Position2d } from 'common/types/Position';
import ZoomContext from 'common/ui/components/Workspace/ZoomContext';
import { getPosFromEvent, isDrag, isLeftMouseClick } from 'common/ui/lib/ClickRecognizer';

export default function useGroupMovement(group: Group) {
  const dispatch = useWorkflowBuilderDispatch();
  const zoom = useContext(ZoomContext);

  const clickPosition = useRef<Position2d | null>(null);

  const onPointerDown = (event: React.PointerEvent<HTMLElement>) => {
    if (!isLeftMouseClick(event)) return;

    stopPropagation(event);

    clickPosition.current = getPosFromEvent(event);
  };

  useEffect(() => {
    const handleGroupPointerMove = throttle((event: globalThis.PointerEvent) => {
      event.stopPropagation();

      const dragStart = clickPosition.current;

      if (!dragStart) return;

      const currentPosition = getPosFromEvent(event);
      const dx = (currentPosition.x - dragStart.x) / zoom;
      const dy = (currentPosition.y - dragStart.y) / zoom;
      dispatch({
        type: 'setDragDelta',
        payload: { delta: { x: dx, y: dy }, id: group.id },
      });
    }, 50);

    const handleGroupPointerUp = (event: globalThis.PointerEvent) => {
      event.stopPropagation();

      if (isDrag(clickPosition.current, event)) {
        dispatch({
          type: 'applyDragDelta',
        });
      }

      clickPosition.current = null;
    };

    window.addEventListener('pointermove', handleGroupPointerMove);
    window.addEventListener('pointerup', handleGroupPointerUp);

    return () => {
      window.removeEventListener('pointermove', handleGroupPointerMove);
      window.removeEventListener('pointerup', handleGroupPointerUp);
    };
  }, [dispatch, group.id, zoom]);

  return { onPointerDown };
}
