import React from 'react';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

import DataEntitiesMenuItems from 'client/app/apps/work-tree/components/DataEntitiesMenuItems';
import ExistingEntitiesMenuItems from 'client/app/apps/work-tree/components/ExistingEntitiesMenuItems';
import WorkflowEntitiesMenuItems from 'client/app/apps/work-tree/components/WorkflowEntitiesMenuItems';
import { PositionedWorkTree } from 'client/app/apps/work-tree/useWorkTreeLayout';
import { ArrayElement, ExperimentWorkTreeQuery } from 'client/app/gql';
import Tooltip from 'common/ui/components/Tooltip';
import { usePopover } from 'common/ui/hooks/usePopover';

type Block = ArrayElement<
  NonNullable<ExperimentWorkTreeQuery['experimentWorkTree']>['blocks']
>;

type WorkTreeFabMenuProps = {
  experimentId: ExperimentId;
  disabled: boolean;
  visibleBlocks: readonly Block[];
  workTree: PositionedWorkTree;
  refetchWorkTree: () => void;
};

export default function WorkTreeFabMenu(props: WorkTreeFabMenuProps) {
  const { experimentId, disabled, visibleBlocks, workTree, refetchWorkTree } = props;
  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();

  return (
    <>
      <Tooltip title="Add a new block to the map from a list of entity types">
        <StyledFab
          size="small"
          onClick={onShowPopover}
          disabled={disabled}
          color="secondary"
        >
          <AddOutlinedIcon />
        </StyledFab>
      </Tooltip>
      <Menu
        anchorEl={popoverAnchorElement}
        keepMounted
        open={isPopoverOpen}
        onClose={onHidePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{ square: false }}
      >
        <WorkflowEntitiesMenuItems
          experimentId={experimentId}
          onHidePopover={onHidePopover}
          workTree={workTree}
        />
        <Divider />
        <ExistingEntitiesMenuItems
          experimentId={experimentId}
          onHidePopover={onHidePopover}
          refetchWorkTree={refetchWorkTree}
          workTree={workTree}
        />
        <Divider />
        <DataEntitiesMenuItems
          onHidePopover={onHidePopover}
          visibleBlocks={visibleBlocks}
          isParentMenuOpen={isPopoverOpen}
        />
      </Menu>
    </>
  );
}

const StyledFab = styled(Fab)({
  height: '34px',
  width: '34px',
  minHeight: 0, // Overrides a MUI style that sets the minHeight to 36px (i.e. smaller than our size here)
});
