import React, { useCallback } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { compareElementInstances } from 'client/app/apps/template-workflows/editor/helpers';
import { ElementInstance } from 'common/types/bundle';

type Props = {
  elementInstances: ElementInstance[];
  selectedInstance: ElementInstance | undefined;
  onSelectInstance: (id: string) => void;
};

/**
 * Renders list of element instances to choose from.
 */
export default function AddInputDialogElementList({
  elementInstances,
  selectedInstance,
  onSelectInstance,
}: Props) {
  return (
    <List>
      {elementInstances.sort(compareElementInstances).map(elementInstance => (
        <ElementInstanceListItem
          key={elementInstance.name}
          elementInstance={elementInstance}
          isSelected={elementInstance.Id === selectedInstance?.Id}
          onSelect={onSelectInstance}
        />
      ))}
    </List>
  );
}

function ElementInstanceListItem({
  elementInstance,
  isSelected,
  onSelect,
}: {
  elementInstance: ElementInstance;
  isSelected: boolean;
  onSelect: (id: string) => void;
}) {
  const onClick = useCallback(
    () => onSelect(elementInstance.Id),
    [elementInstance, onSelect],
  );
  return (
    <ListItem button selected={isSelected} onClick={onClick}>
      {elementInstance.name}
    </ListItem>
  );
}
