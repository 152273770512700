import React, { useCallback, useEffect, useState } from 'react';

import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';

import { ScreenRegistry } from 'client/app/registry';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { ElementInstance } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDebounce from 'common/ui/hooks/useDebounce';

const TEXT_EDIT_DEBOUNCE_MS = 150;

type InstanceNameFieldProps = {
  elementInstance: ElementInstance;
  isDisabled?: boolean;
};

const InstanceNameField = React.memo(function InstanceNameField(
  props: InstanceNameFieldProps,
) {
  const { elementInstance, isDisabled } = props;
  const classes = useStyles();

  const [instanceName, setInstanceName] = useState(elementInstance.name);
  const [error, setErrorMessage] = useState<string | null>(null);

  const elementInstances = useWorkflowBuilderSelector(state => state.elementInstances);
  const getValidationError = useValidation(elementInstance, elementInstances);
  const saveInstanceNameDebounced = useWorkflowBuilderUpdate(
    elementInstance,
    instanceName,
    error,
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const tempInstanceName = e.target.value;
      const error = getValidationError(tempInstanceName);
      setInstanceName(tempInstanceName);
      setErrorMessage(error);
    },
    [getValidationError],
  );

  useEffect(() => {
    setInstanceName(elementInstance.name);
  }, [elementInstance.name]);

  return (
    <div className={classes.card}>
      <TextField
        value={instanceName}
        onChange={handleChange}
        onKeyUp={saveInstanceNameDebounced}
        label="Instance name"
        variant="standard"
        fullWidth
        disabled={isDisabled}
      />
      {error && <FormHelperText className={classes.errorText}>{error}</FormHelperText>}
    </div>
  );
});

function useValidation(
  elementInstance: ElementInstance,
  elementInstances: ElementInstance[],
) {
  return useCallback(
    (name: string) => {
      if (name === '') {
        return "Name can't be empty";
      }
      // T2246: Simulation will fail if any element instance's name does not follow the schema.
      const isValidNameRegex = /^[a-zA-Z0-9_ -]*$/;
      if (!isValidNameRegex.test(name)) {
        return 'Name can only include alphanumeric characters, hyphens, or underscores';
      }
      if (name.trim() !== name) {
        return "Name can't begin or end with a space";
      }

      if (!elementInstance || !elementInstances) {
        return null;
      }

      const instanceWithSameName = elementInstances.find(ei => ei.name === name);
      if (instanceWithSameName && instanceWithSameName.Id !== elementInstance.Id) {
        return 'Another item already has this name';
      }

      return null;
    },
    [elementInstance, elementInstances],
  );
}

/*
 * Update WorkflowBuilderStateContext with the new instance name
 */
function useWorkflowBuilderUpdate(
  elementInstance: ElementInstance,
  newName: string,
  error: string | null,
) {
  const dispatch = useWorkflowBuilderDispatch();

  return useDebounce(
    useCallback(() => {
      if (error) return;

      const isNewNameSameAsOldName = elementInstance.name === newName;
      if (!elementInstance || isNewNameSameAsOldName) {
        return;
      }

      dispatch({
        type: 'renameElementInstance',
        payload: {
          id: elementInstance.Id,
          newName,
        },
      });

      logEvent(
        'change-element-instance-name',
        ScreenRegistry.WORKFLOW,
        `old: ${elementInstance.name}; new: ${newName}`,
      );
    }, [dispatch, elementInstance, newName, error]),
    TEXT_EDIT_DEBOUNCE_MS,
  );
}

const useStyles = makeStylesHook(theme => ({
  card: {
    backgroundColor: Colors.GREY_10,
    borderRadius: '4px',
    padding: theme.spacing(3),
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

export default InstanceNameField;
