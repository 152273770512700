import React from 'react';

import { Route, RouteComponentProps } from 'react-router';

import ExperimentDetailScreen from 'client/app/apps/experiments/ExperimentDetailScreen';
import { BioprocessApp } from 'client/app/apps/Visualization/DatasetDerivationApp';
import { experimentsRoutes } from 'client/app/lib/nav/actions';

export default function ExperimentDetailRoute() {
  return (
    <>
      <Route
        exact
        path={experimentsRoutes.detail.pathTemplate}
        render={(props: RouteComponentProps<{ id: string }>) => (
          <ExperimentDetailScreen experimentID={props.match.params.id as ExperimentId} />
        )}
      />
      <Route
        exact
        path={experimentsRoutes.bioprocess.pathTemplate}
        render={(
          props: RouteComponentProps<{
            experimentId: string;
            derivationId: string;
          }>,
        ) => (
          <BioprocessApp
            derivationId={props.match.params.derivationId as DatasetDerivationId}
          />
        )}
      />
    </>
  );
}
