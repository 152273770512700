// These prefixes are repeated frequently and end up being very noisy in the UI.
// The order of them is important, since they are stripped in order, so if A is
// a prefix of B then B must precede A in the list.
//
// These are RegExps because they might appear multiple times (eg, in a map
// type), and using a string literal will result in only the first instance
// being replaced.
const PREFIXES_TO_REMOVE = [
  RegExp('github.com/Synthace/antha/antha/anthalib/wunit[.]', 'g'),
  RegExp('github.com/Synthace/antha/antha/anthalib/wtype[.]', 'g'),
  RegExp('github.com/Synthace/antha/antha/anthalib/', 'g'),
  RegExp('github.com/Synthace/antha/antha/', 'g'),
  RegExp('github.com/Synthace/antha/stdlib/schemas/aliases[.]', 'g'),
  RegExp('github.com/Synthace/antha/stdlib/', 'g'),
  RegExp('github.com/Synthace/antha/Elements/(.*[./])?', 'g'),
];

export function removeCommonPrefixes(val: string): string {
  PREFIXES_TO_REMOVE.forEach(prefix => {
    val = val.replace(prefix, '');
  });
  return val;
}
