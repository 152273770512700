import React, { useCallback } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog, { DialogProps } from 'common/ui/hooks/useDialog';

/**
 * This component is a base editor for types where the value should be
 * selected from a contextual dialog, such as picking a plate from
 * the PlateLibraryDialog or selecting wells from the WellSelectorDialog.
 *
 * It handles empty state, displaying a selected value, and allowing
 * the user to clear the selected value if the parameter is optional
 * (and also if it's not for the moment, usually).
 */
export default function SelectFromDialogButton<TResult, TProps, TOverride>(
  props: {
    /** An optional label for the selected value. */
    selectedValueLabel?: string;
    /** The dialog component to be displayed on click */
    dialog: React.FunctionComponent<DialogProps<TResult> & TProps>;
    /** The props to be passed to the dialog. */
    dialogProps: Pick<
      DialogProps<TResult> & TProps,
      Exclude<keyof TProps, 'isOpen' | 'onClose' | 'isDisabled'> | 'disableRestoreFocus'
    >;
    /** An SVG to be used as a start adornmnent */
    icon?: JSX.Element;
    onChange: (newValue: TResult | undefined) => void;
    /** Additional information to show on hover */
    tooltipContent?: React.ReactNode;
  } & ParameterEditorBaseProps<TResult | TOverride>,
) {
  const {
    selectedValueLabel,
    onChange,
    isDisabled,
    isRequired,
    placeholder,
    dialogProps,
    dialog,
    icon,
    value,
    tooltipContent,
  } = props;

  const classes = useStyles();
  const [selectionDialog, openSelectionDialog] = useDialog(dialog);

  const makeSelection = useCallback(async () => {
    const selectedItem = await openSelectionDialog(dialogProps);
    onChange(selectedItem);
  }, [dialogProps, onChange, openSelectionDialog]);

  const clearSelection = useCallback(
    (event: React.MouseEvent) => {
      if (isRequired) {
        return;
      }
      event.stopPropagation();
      onChange(undefined);
    },
    [isRequired, onChange],
  );

  return (
    <>
      {!value ? (
        <Button
          variant="secondary"
          startIcon={icon}
          onClick={makeSelection}
          disabled={isDisabled}
          color={props.hasError ? 'secondary' : undefined}
        >
          {placeholder}
        </Button>
      ) : (
        <Tooltip title={tooltipContent ?? ''} disableHoverListener={!tooltipContent}>
          <ButtonBase
            className={cx({ [classes.clickable]: !isDisabled }, classes.selectedItem)}
            onClick={!isDisabled ? makeSelection : undefined}
            color={props.hasError ? 'secondary' : undefined}
          >
            {icon}
            <Typography
              variant="overline"
              color="textPrimary"
              className={classes.typography}
            >
              {selectedValueLabel ?? value}
            </Typography>
            {!isDisabled && (
              <CloseIcon
                onClick={!isRequired ? clearSelection : undefined}
                fontSize="small"
                className={classes.closeIcon}
              />
            )}
          </ButtonBase>
        </Tooltip>
      )}
      {selectionDialog}
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  clickable: {
    cursor: 'pointer',
  },
  closeIcon: {
    '&:hover': {
      color: Colors.RED,
    },
  },
  selectedItem: {
    alignItems: 'center',
    backgroundColor: Colors.GREY_20,
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    height: 'auto',
    '& span': {
      whiteSpace: 'unset',
    },
    padding: '5px 11px', // Match MUI outlined button padding.
    textAlign: 'left',
    width: '100%',
  },
  typography: { marginLeft: theme.spacing(2), marginRight: 'auto' },
}));
