import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/InfoOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { v4 as uuid } from 'uuid';

import AutocompleteWithParameterValues from 'client/app/components/Parameters/AutocompleteWithParameterValues';
import {
  Constant,
  DoubleMapParameterValue,
  DoubleMapRules,
  Factor,
  FactorType,
  formatDoubleMapValue,
  getFactorName,
  getNameValueFactorType,
  getNameValueSetFactorType,
  getValuesType,
  NameValueFactor,
  NameValueSetFactor,
  parseDoubleMapValue,
  ValueFactor,
} from 'client/app/components/Parameters/DOEDoubleMap/doeDoubleMapUtils';
import { InlineMapEditor } from 'client/app/components/Parameters/MapEditor';
import ParameterEditor from 'client/app/components/Parameters/ParameterEditor';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { getDefaultPlaceholder } from 'common/elementConfiguration/parameterUtils';
import { pluralize } from 'common/lib/format';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import Button from 'common/ui/components/Button';
import IconButton from 'common/ui/components/IconButton';
import ArrayEditor from 'common/ui/components/ParameterEditors/ArrayEditor';
import SelectFromDialogButton from 'common/ui/components/SelectFromDialogButton';
import Tooltip from 'common/ui/components/Tooltip';
import Dropdown, { Option } from 'common/ui/filaments/Dropdown';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';
import DOEIcon from 'common/ui/icons/DOEIcon';

type DoubleMapConfig = {
  title: string;
  constantSectionTitle: string;
  constantSectionInfo: string;
  factorSectionTitle: string;
  factorSectionInfo: string;
  factorTypes: Partial<Record<FactorType, string>>;
  /**
   * The key of the outer map
   * map[primaryKey][secondaryKey]Value
   */
  primaryKeyType: string;
  /**
   * Key type of the inner map
   */
  secondaryKeyType: string;
  valueType: string;
  nameValueSetFactorName: string;
  factorTooltip: ReactElement;
  constantNameLabel: string;
  constantValueLabel: string;
  valuesLabel: string;
  nameValuesLabel: string;
  nameValueSetsLabel: string;
};

type DOEDoubleMapEditorButton = DoubleMapConfig & {
  instanceName?: string;
  isDisabled?: boolean;
  onChange: (param: DoubleMapParameterValue | undefined, instanceName?: string) => void;
  value: DoubleMapParameterValue;
};

export function DOEDoubleMapEditorButton({
  instanceName,
  isDisabled,
  onChange,
  value,
  ...doubleMapConfig
}: DOEDoubleMapEditorButton) {
  const handleChange = useCallback(
    (rules?: DoubleMapRules) => {
      if (!rules) {
        return;
      }
      onChange(
        formatDoubleMapValue(
          rules,
          doubleMapConfig.primaryKeyType,
          doubleMapConfig.secondaryKeyType,
          doubleMapConfig.valueType,
        ),
        instanceName,
      );
    },
    [doubleMapConfig, instanceName, onChange],
  );
  const rules = useMemo(() => parseDoubleMapValue(value), [value]);
  const label = useMemo(() => getParameterLabel(rules.constants, rules.factors), [rules]);
  const dialogProps = useMemo(
    () => ({ doubleMapConfig, rules }),
    [doubleMapConfig, rules],
  );

  return (
    <SelectFromDialogButton
      selectedValueLabel={label}
      value={value}
      icon={<DOEIcon />}
      dialog={DoubleMapDefinitionsDialog}
      dialogProps={dialogProps}
      placeholder="Configure"
      tooltipContent={
        <DoubleMapTooltip doubleMapConfig={doubleMapConfig} rules={rules} />
      }
      onChange={handleChange}
      isDisabled={isDisabled}
    />
  );
}

type DoubleMapTooltipProps = {
  doubleMapConfig: DoubleMapConfig;
  rules: DoubleMapRules;
};

function DoubleMapTooltip({ doubleMapConfig, rules }: DoubleMapTooltipProps) {
  const classes = useStyles();
  const { constants, factors } = rules;
  return (
    <>
      {constants.length > 0 && (
        <>
          <strong>{doubleMapConfig.constantSectionTitle}</strong>
          <ul className={classes.tooltipList}>
            {constants.map(constant => (
              <li key={constant.id}>{constant.componentName}</li>
            ))}
          </ul>
        </>
      )}
      {factors.length > 0 && (
        <>
          <strong>{doubleMapConfig.factorSectionTitle}</strong>
          <ul className={classes.tooltipList}>
            {factors.map(factor => (
              <li key={factor.id}>
                {getFactorName(factor, doubleMapConfig.nameValueSetFactorName)}
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
}

type DoubleMapDialogProps = DialogProps<DoubleMapRules> & {
  doubleMapConfig: DoubleMapConfig;
  rules: DoubleMapRules;
};

function DoubleMapDefinitionsDialog({
  doubleMapConfig,
  rules,
  onClose,
  isOpen,
}: DoubleMapDialogProps) {
  const classes = useStyles();

  const [constants, setConstants] = useState<Constant[]>([]);
  const [factors, setFactors] = useState<Factor[]>([]);

  // Update state when passed in mix rules change
  useEffect(() => {
    setConstants(rules.constants);
    setFactors(rules.factors);
  }, [rules]);

  const handleAddConstant = useCallback(
    () => setConstants(constants => [...constants, createEmptyConstant()]),
    [],
  );
  const handleAddFactor = useCallback(
    () => setFactors(factors => [...factors, createEmptyFactor()]),
    [],
  );
  const handleConstantChange = useCallback(
    (changed: Constant) =>
      setConstants(constants =>
        constants.map(current => (current.id === changed.id ? changed : current)),
      ),
    [setConstants],
  );
  const handleFactorChange = useCallback(
    (changed: Factor) => {
      setFactors(factors =>
        factors.map(current => (current.id === changed.id ? changed : current)),
      );
    },
    [setFactors],
  );
  const handleConstantDelete = useCallback(
    (deleted: Constant) =>
      setConstants(constants => constants.filter(constant => constant.id !== deleted.id)),
    [],
  );
  const handleFactorDelete = useCallback(
    (deleted: Factor) =>
      setFactors(factors => factors.filter(factor => factor.id !== deleted.id)),
    [],
  );
  const handleClose = useCallback(
    () => onClose({ constants, factors }),
    [constants, factors, onClose],
  );

  const summary = useMemo(
    () => getParameterLabel(constants, factors),
    [constants, factors],
  );

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose}>
      <DialogTitle>{doubleMapConfig.title}</DialogTitle>
      <DialogContent dividers>
        <>
          <Typography variant="h2" gutterBottom>
            {doubleMapConfig.constantSectionTitle}
          </Typography>
          <Typography variant="body1" paragraph>
            {doubleMapConfig.constantSectionInfo}
          </Typography>
          {constants.map(constant => (
            <ConstantEditor
              key={constant.id}
              doubleMapConfig={doubleMapConfig}
              constant={constant}
              constants={constants}
              onChange={handleConstantChange}
              onDelete={handleConstantDelete}
            />
          ))}
          <Button variant="tertiary" startIcon={<AddIcon />} onClick={handleAddConstant}>
            Add Constant
          </Button>
        </>
        <Typography variant="h2" gutterBottom className={classes.factorsHeader}>
          {doubleMapConfig.factorSectionTitle}
        </Typography>
        <Typography variant="body1" paragraph>
          {doubleMapConfig.factorSectionInfo}
        </Typography>
        {factors.map(factor => (
          <FactorEditor
            key={factor.id}
            doubleMapConfig={doubleMapConfig}
            factor={factor}
            factors={factors}
            onChange={handleFactorChange}
            onDelete={handleFactorDelete}
          />
        ))}
        <Button variant="tertiary" startIcon={<AddIcon />} onClick={handleAddFactor}>
          Add Factor
        </Button>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography>{summary}</Typography>
          </Grid>
          <Grid item>
            <Button variant="primary" onClick={handleClose}>
              Done
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

type ConstantEditorProps = {
  doubleMapConfig: DoubleMapConfig;
  constant: Constant;
  constants: Constant[];
  onChange: (rule: Constant) => void;
  onDelete: (rule: Constant) => void;
};

/**
 * Each constant comprises a component name and a value. Constants remain the same for all
 * DOE runs.
 */
function ConstantEditor({
  doubleMapConfig,
  constant,
  constants,
  onChange,
  onDelete,
}: ConstantEditorProps) {
  const handleNameChange = useCallback(
    componentName => onChange({ ...constant, componentName }),
    [constant, onChange],
  );
  const handleValueChange = useCallback(
    value => onChange({ ...constant, value }),
    [constant, onChange],
  );
  const handleDelete = useCallback(() => onDelete(constant), [constant, onDelete]);

  const otherConstants = useMemo(
    () => constants.filter(c => c.id !== constant.id),
    [constant.id, constants],
  );

  // Check if this name has already been used for another constant.
  const isDuplicate = useMemo<boolean>(() => {
    const thisConstantName = constant.componentName?.toLowerCase();
    return otherConstants.some(c => thisConstantName === c.componentName?.toLowerCase());
  }, [constant.componentName, otherConstants]);

  return (
    <RuleCard onDelete={handleDelete}>
      <Grid container spacing={7}>
        <Grid item xs={4}>
          <InputLabel shrink>{doubleMapConfig.constantNameLabel}</InputLabel>
          <>
            <AutocompleteWithParameterValues
              anthaType={doubleMapConfig.secondaryKeyType}
              valueLabel={constant.componentName}
              acceptCustomValues
              placeholder={getDefaultPlaceholder(doubleMapConfig.secondaryKeyType)}
              onChange={handleNameChange}
              hasError={isDuplicate}
            />
            {isDuplicate && (
              <FormHelperText error>This constant is already in use</FormHelperText>
            )}
          </>
        </Grid>
        <Grid item>
          <InputLabel shrink>{doubleMapConfig.constantValueLabel}</InputLabel>
          <ParameterEditor
            anthaType={doubleMapConfig.valueType}
            value={constant.value}
            onChange={handleValueChange}
          />
        </Grid>
      </Grid>
    </RuleCard>
  );
}

type FactorEditorProps = {
  doubleMapConfig: DoubleMapConfig;
  factor: Factor;
  factors: Factor[];
  onChange: (rule: Factor) => void;
  onDelete: (rule: Factor) => void;
};

function FactorEditor({
  doubleMapConfig,
  factor,
  onChange,
  onDelete,
  factors,
}: FactorEditorProps) {
  const classes = useStyles();
  // When changing factor type, rewrite the factor using the new type and copy over the
  // original factor's ID.
  const handleFactorTypeChange = useCallback(
    (newType?: FactorType) => onChange(createEmptyFactor(newType, factor.id)),
    [factor, onChange],
  );
  const handleDelete = useCallback(() => onDelete(factor), [factor, onDelete]);

  const otherFactors = useMemo(
    () => factors.filter(f => f.id !== factor.id),
    [factor.id, factors],
  );

  const factorTypeDropdownOptions = useMemo<Option<FactorType>[]>(() => {
    const nameValueSetFactorExists = otherFactors.some(
      factor => factor.factorType === FactorType.NAMEVALUESET,
    );
    return (Object.keys(doubleMapConfig.factorTypes) as FactorType[])
      .map(factorType => ({
        label: doubleMapConfig.factorTypes[factorType],
        value: factorType,
      }))
      .filter((option): option is Option<FactorType> => {
        // Do not allow changing to Set of Names & Values factor if one already exists. We
        // should show it if this is that factor.
        if (nameValueSetFactorExists && option.value === FactorType.NAMEVALUESET) {
          return false;
        }
        if (!option.label) {
          return false;
        }
        return true;
      });
  }, [doubleMapConfig.factorTypes, otherFactors]);

  // Check if this name has already been used for another factor (including the Set of
  // Names & Values factor which always has the name 'Mixture Definitions').
  const isDuplicate = useMemo<boolean>(() => {
    const thisFactorName = getFactorName(
      factor,
      doubleMapConfig.nameValueSetFactorName,
    )?.toLowerCase();
    return (
      thisFactorName === doubleMapConfig.nameValueSetFactorName?.toLowerCase() ||
      otherFactors.some(
        f =>
          thisFactorName ===
          getFactorName(f, doubleMapConfig.nameValueSetFactorName)?.toLowerCase(),
      )
    );
  }, [doubleMapConfig.nameValueSetFactorName, factor, otherFactors]);

  return (
    <RuleCard onDelete={handleDelete}>
      <Grid container spacing={7}>
        <Grid item xs={4}>
          <InputLabel shrink>Level Type</InputLabel>
          <div className={classes.factorTypeSelect}>
            <Grid container>
              <Grid item xs={10}>
                <Dropdown
                  valueLabel={doubleMapConfig.factorTypes[factor.factorType] || ''}
                  options={factorTypeDropdownOptions}
                  onChange={handleFactorTypeChange}
                />
              </Grid>
              <Grid item>
                <Tooltip title={doubleMapConfig.factorTooltip}>
                  <HelpIcon className={classes.factorTypeInfo} />
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={8}>
          <FactorEditorBase
            doubleMapConfig={doubleMapConfig}
            factor={factor}
            onChange={onChange}
            isDuplicate={isDuplicate}
          />
        </Grid>
      </Grid>
    </RuleCard>
  );
}

type FactorEditorBaseProps<T extends Factor> = {
  doubleMapConfig: DoubleMapConfig;
  factor: T;
  onChange: (factor: T) => void;
  isDuplicate: boolean;
};

function FactorEditorBase({
  doubleMapConfig,
  factor,
  onChange,
  isDuplicate,
}: FactorEditorBaseProps<Factor>) {
  switch (factor.factorType) {
    case FactorType.VALUE:
      return (
        <ValueFactorEditor
          doubleMapConfig={doubleMapConfig}
          factor={factor}
          onChange={onChange}
          isDuplicate={isDuplicate}
        />
      );
    case FactorType.NAMEVALUE:
      return (
        <NameValueFactorEditor
          doubleMapConfig={doubleMapConfig}
          factor={factor}
          onChange={onChange}
          isDuplicate={isDuplicate}
        />
      );
    case FactorType.NAMEVALUESET:
      return (
        <NameValueSetFactorEditor
          doubleMapConfig={doubleMapConfig}
          factor={factor}
          onChange={onChange}
        />
      );
  }
}

/**
 * A Value factor has a factor (i.e., component) name and one or more factor
 * values.
 */
function ValueFactorEditor({
  doubleMapConfig,
  factor,
  onChange,
  isDuplicate,
}: FactorEditorBaseProps<ValueFactor>) {
  const handleNameChange = useCallback(
    componentName => onChange({ ...factor, componentName }),
    [factor, onChange],
  );
  const handleValueChange = useCallback(
    value => onChange({ ...factor, value }),
    [factor, onChange],
  );
  return (
    <>
      <ValueFactorNameEditor
        anthaType={doubleMapConfig.secondaryKeyType}
        isDuplicate={isDuplicate}
        value={factor.componentName}
        onChange={handleNameChange}
      />
      <InputLabel shrink>{doubleMapConfig.valuesLabel}</InputLabel>
      <ArrayEditor
        component={ParameterEditor}
        anthaType={getValuesType(doubleMapConfig.valueType)}
        value={factor.value}
        onChange={handleValueChange}
      />
    </>
  );
}

/**
 * A Name & Value factor has a factor name and one or more name-value pairs.
 */
function NameValueFactorEditor({
  doubleMapConfig,
  factor,
  onChange,
  isDuplicate,
}: FactorEditorBaseProps<NameValueFactor>) {
  const handleFactorNameChange = useCallback(
    factorName => onChange({ ...factor, factorName }),
    [factor, onChange],
  );
  const handleValueChange = useCallback(
    valueByComponentName => onChange({ ...factor, valueByComponentName }),
    [factor, onChange],
  );
  const keyEditorProps: ParameterEditorConfigurationSpec = useMemo(
    () => ({
      type: EditorType.AUTOCOMPLETE,
      placeholder: getDefaultPlaceholder(doubleMapConfig.secondaryKeyType),
      additionalProps: {
        editor: EditorType.AUTOCOMPLETE,
        useDynamicOptions: true,
        canAcceptCustomValues: true,
        staticOptions: [],
        provideDefaultKey: false,
      },
    }),
    [doubleMapConfig.secondaryKeyType],
  );
  return (
    <>
      <NameValueFactorNameEditor
        value={factor.factorName}
        onChange={handleFactorNameChange}
        isDuplicate={isDuplicate}
      />
      <InputLabel shrink>{doubleMapConfig.nameValuesLabel}</InputLabel>
      <InlineMapEditor
        anthaType={getNameValueFactorType(
          doubleMapConfig.secondaryKeyType,
          doubleMapConfig.valueType,
        )}
        keyEditorProps={keyEditorProps}
        value={factor.valueByComponentName}
        onChange={handleValueChange}
      />
    </>
  );
}

/**
 * A single, name-value-set DOE factor. Each entry specifies a group of
 * multiple component name-value pairs. Each of these groups represents a
 * categoric level for the same DOE factor. This single factor is the overall
 * element parameter.
 **/
function NameValueSetFactorEditor({
  doubleMapConfig,
  factor,
  onChange,
}: Omit<FactorEditorBaseProps<NameValueSetFactor>, 'isDuplicate'>) {
  const handleValueChange = useCallback(
    newValue => onChange({ ...factor, valueByComponentNames: newValue }),
    [factor, onChange],
  );
  return (
    <>
      <InputLabel shrink>Factor Name</InputLabel>
      <Typography variant="body1" gutterBottom>
        {doubleMapConfig.nameValueSetFactorName}
      </Typography>
      <InputLabel shrink>{doubleMapConfig.nameValueSetsLabel}</InputLabel>
      <ArrayEditor
        anthaType={getNameValueSetFactorType(
          doubleMapConfig.secondaryKeyType,
          doubleMapConfig.valueType,
        )}
        component={InlineMapEditor}
        value={factor.valueByComponentNames}
        onChange={handleValueChange}
      />
    </>
  );
}

type ValueFactorNameEditorProps = {
  anthaType: string;
  value: string;
  onChange: (val: string | undefined) => void;
  isDuplicate: boolean;
};

function ValueFactorNameEditor({
  anthaType,
  value,
  onChange,
  isDuplicate,
}: ValueFactorNameEditorProps) {
  return (
    <>
      <AutocompleteWithParameterValues
        anthaType={anthaType}
        valueLabel={value}
        acceptCustomValues
        placeholder="Factor Name"
        onChange={onChange}
        hasError={isDuplicate}
      />
      {isDuplicate && (
        <FormHelperText error>This factor name is already in use</FormHelperText>
      )}
    </>
  );
}

type NameValueFactorNameEditorProps = {
  value: string;
  onChange: (val: string) => void;
  isDuplicate: boolean;
};

function NameValueFactorNameEditor({
  value,
  onChange,
  isDuplicate,
}: NameValueFactorNameEditorProps) {
  const classes = useStyles();
  const handleChange = useTextFieldChange(onChange);
  return (
    <TextField
      variant="standard"
      type=""
      label="Factor Name"
      value={value}
      onChange={handleChange}
      error={isDuplicate}
      helperText={isDuplicate ? 'This factor name is already in use' : undefined}
      className={classes.factorNameField}
    />
  );
}

type RuleCardProps = { onDelete: () => void; children: ReactNode };

function RuleCard({ onDelete, children }: RuleCardProps) {
  const classes = useStyles();
  return (
    <Paper variant="outlined" square={false} className={classes.section}>
      <Grid container spacing={6} alignContent="flex-start">
        <Grid item xs={11}>
          {children}
        </Grid>
        <Grid item xs className={classes.deleteButton}>
          <IconButton icon={<DeleteIcon />} onClick={onDelete} size="small" />
        </Grid>
      </Grid>
    </Paper>
  );
}

function getParameterLabel(constants: Constant[], factors: Factor[]) {
  return (
    pluralize(constants.length, 'Constant') + ', ' + pluralize(factors.length, 'Factor')
  );
}

function createEmptyConstant(): Constant {
  return { id: uuid(), ruleType: 'constant', componentName: '', value: '' };
}

function createEmptyFactor(
  factorType: FactorType = FactorType.VALUE,
  id = uuid(),
): Factor {
  switch (factorType) {
    case FactorType.VALUE:
      return {
        id,
        ruleType: 'factor',
        factorType,
        componentName: '',
        value: [],
      };
    case FactorType.NAMEVALUE:
      return {
        id,
        ruleType: 'factor',
        factorType,
        factorName: '',
        valueByComponentName: {},
      };
    case FactorType.NAMEVALUESET:
      return { id, ruleType: 'factor', factorType, valueByComponentNames: [] };
  }
}

const useStyles = makeStylesHook(theme => ({
  section: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(5),
  },
  factorsHeader: {
    marginTop: theme.spacing(5),
  },
  deleteButton: {
    textAlign: 'right',
  },
  tooltipList: {
    padding: theme.spacing(0, 0, 0, 5),
  },
  factorTypeSelect: {
    display: 'flex',
  },
  factorTypeInfo: {
    marginLeft: theme.spacing(3),
  },
  factorNameField: {
    marginBottom: theme.spacing(3),
  },
}));
