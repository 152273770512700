import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { LogoColours } from 'common/ui/lib/envs';

export const SynthaceBrandmark = React.memo<SvgIconProps & { logoColour: LogoColours }>(
  function SynthaceBrandmark({ logoColour, ...props }) {
    switch (logoColour) {
      case 'purple':
        return (
          <SvgIcon viewBox="0 0 24 24" {...props}>
            <svg viewBox="0 0 513 432" fill="none">
              <g opacity=".9">
                <path
                  opacity=".9"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M284.463 8.659c-25.124-15.681-58.224-8.04-73.931 17.068L8.169 349.2c-15.707 25.107-8.073 58.173 17.05 73.854 25.124 15.681 58.224 8.039 73.931-17.069L301.514 82.513c15.707-25.108 8.073-58.173-17.05-73.854zm135.079 107.235c-25.124-15.681-58.224-8.039-73.931 17.069L210.333 349.201c-15.707 25.107-8.073 58.173 17.051 73.854 25.123 15.681 58.224 8.039 73.931-17.069l135.277-216.238c15.707-25.107 8.074-58.173-17.05-73.854z"
                  fill="url(#paint0_linear)"
                />
                <g opacity=".9">
                  <path
                    d="M25.22 8.659C50.343-7.022 83.442.619 99.15 25.727L301.514 349.2c15.707 25.107 8.073 58.173-17.05 73.854-25.124 15.681-58.224 8.039-73.931-17.069L8.169 82.513C-7.538 57.405.096 24.34 25.219 8.659z"
                    fill="url(#paint1_linear)"
                  />
                  <path
                    d="M227.544 8.66c25.123-15.681 58.224-8.04 73.931 17.068L503.837 349.2c15.707 25.107 8.074 58.173-17.05 73.854-25.124 15.681-58.224 8.039-73.931-17.069L210.493 82.514C194.786 57.406 202.42 24.34 227.544 8.66z"
                    fill="url(#paint2_linear)"
                  />
                </g>
                <path
                  opacity=".6"
                  d="M53.648 431.315c-29.625 0-53.642-24.015-53.642-53.64 0-29.624 24.017-53.64 53.642-53.64 29.626 0 53.642 24.016 53.642 53.64 0 29.625-24.016 53.64-53.642 53.64zM53.648 107.78C24.022 107.78.006 83.765.006 54.14S24.022.5 53.648.5c29.625 0 53.642 24.015 53.642 53.64s-24.017 53.64-53.642 53.64z"
                  fill="#D07EFF"
                />
                <path
                  d="M458.348 107.794c-29.625 0-53.641-24.016-53.641-53.64 0-29.625 24.016-53.64 53.641-53.64 29.626 0 53.642 24.015 53.642 53.64 0 29.624-24.016 53.64-53.642 53.64z"
                  fill="#9710FF"
                />
                <path
                  opacity=".6"
                  d="M458.314 431.245c-29.625 0-53.642-23.989-53.642-53.58 0-29.591 24.017-53.579 53.642-53.579 29.626 0 53.642 23.988 53.642 53.579s-24.016 53.58-53.642 53.58z"
                  fill="#B33EFF"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="496.003"
                  y1="298.5"
                  x2=".491"
                  y2="314.055"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#9710FF" />
                  <stop offset="1" stopColor="#D07EFF" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear"
                  x1="512.003"
                  y1="242.5"
                  x2="13.677"
                  y2="323.071"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#9710FF" />
                  <stop offset="1" stopColor="#D07EFF" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear"
                  x1="512.003"
                  y1="242.5"
                  x2="13.677"
                  y2="323.071"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#9710FF" />
                  <stop offset="1" stopColor="#D07EFF" />
                </linearGradient>
              </defs>
            </svg>
          </SvgIcon>
        );
      case 'orange':
        return (
          <SvgIcon viewBox="0 0 24 24" {...props}>
            <svg viewBox="0 0 512 432" fill="none">
              <g opacity=".9">
                <path
                  opacity=".9"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M284.459 8.659c-25.123-15.681-58.223-8.04-73.931 17.068L8.166 349.2c-15.707 25.107-8.074 58.173 17.05 73.854 25.124 15.681 58.224 8.039 73.931-17.069L301.51 82.513c15.707-25.108 8.073-58.173-17.051-73.854zm135.08 107.235c-25.124-15.681-58.224-8.039-73.931 17.069L210.33 349.201c-15.707 25.107-8.073 58.173 17.05 73.854 25.124 15.681 58.224 8.039 73.931-17.069l135.278-216.238c15.707-25.107 8.074-58.173-17.05-73.854z"
                  fill="url(#paint0_linear)"
                />
                <g opacity=".9">
                  <path
                    d="M25.216 8.659C50.34-7.022 83.44.619 99.147 25.727L301.51 349.2c15.707 25.107 8.073 58.173-17.051 73.854-25.123 15.681-58.223 8.039-73.931-17.069L8.166 82.513C-7.541 57.405.092 24.34 25.216 8.659z"
                    fill="url(#paint1_linear)"
                  />
                  <path
                    d="M227.54 8.66c25.124-15.681 58.224-8.04 73.932 17.068L503.834 349.2c15.707 25.107 8.074 58.173-17.05 73.854-25.124 15.681-58.224 8.039-73.931-17.069L210.49 82.514c-15.707-25.108-8.073-58.173 17.05-73.854z"
                    fill="url(#paint2_linear)"
                  />
                </g>
                <path
                  opacity=".4"
                  d="M53.645 431.315c-29.626 0-53.642-24.015-53.642-53.64 0-29.624 24.016-53.64 53.642-53.64 29.625 0 53.642 24.016 53.642 53.64 0 29.625-24.017 53.64-53.642 53.64zM53.645 107.78C24.019 107.78.003 83.765.003 54.14S24.02.5 53.645.5c29.625 0 53.642 24.015 53.642 53.64s-24.017 53.64-53.642 53.64z"
                  fill="#F75C03"
                />
                <path
                  d="M458.345 107.794c-29.625 0-53.642-24.016-53.642-53.64 0-29.625 24.017-53.64 53.642-53.64 29.626 0 53.642 24.015 53.642 53.64 0 29.624-24.016 53.64-53.642 53.64z"
                  fill="#F75C03"
                />
                <path
                  opacity=".6"
                  d="M458.311 431.245c-29.626 0-53.642-23.989-53.642-53.58 0-29.591 24.016-53.579 53.642-53.579 29.625 0 53.642 23.988 53.642 53.579s-24.017 53.58-53.642 53.58z"
                  fill="#C6510E"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="496"
                  y1="298.5"
                  x2=".488"
                  y2="314.055"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F75C03" />
                  <stop offset="1" stopColor="#FAB289" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear"
                  x1="512"
                  y1="242.5"
                  x2="13.674"
                  y2="323.071"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F75C03" />
                  <stop offset="1" stopColor="#FAB289" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear"
                  x1="512"
                  y1="242.5"
                  x2="13.674"
                  y2="323.071"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F75C03" />
                  <stop offset="1" stopColor="#FAB289" />
                </linearGradient>
              </defs>
            </svg>
          </SvgIcon>
        );
      case 'blue':
        return (
          <SvgIcon viewBox="0 0 24 24" {...props}>
            <svg viewBox="0 0 513 432" fill="none">
              <g opacity=".9">
                <path
                  opacity=".9"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M285.165 8.659c-25.124-15.681-58.224-8.04-73.931 17.068L8.871 349.2c-15.707 25.107-8.073 58.173 17.05 73.854 25.124 15.681 58.225 8.039 73.932-17.069L302.215 82.513c15.707-25.108 8.074-58.173-17.05-73.854zm135.079 107.235c-25.124-15.681-58.224-8.039-73.931 17.069L211.035 349.201c-15.707 25.107-8.073 58.173 17.051 73.854 25.124 15.681 58.224 8.039 73.931-17.069l135.277-216.238c15.708-25.107 8.074-58.173-17.05-73.854z"
                  fill="url(#paint0_linear)"
                />
                <g opacity=".9">
                  <path
                    d="M25.922 8.659C51.045-7.022 84.146.619 99.852 25.727L302.216 349.2c15.707 25.107 8.074 58.173-17.05 73.854-25.124 15.681-58.224 8.039-73.931-17.069L8.871 82.513C-6.836 57.405.798 24.34 25.921 8.659z"
                    fill="url(#paint1_linear)"
                  />
                  <path
                    d="M228.246 8.66C253.37-7.021 286.47.62 302.177 25.728L504.539 349.2c15.708 25.107 8.074 58.173-17.05 73.854-25.124 15.681-58.224 8.039-73.931-17.069L211.195 82.514c-15.707-25.108-8.073-58.173 17.051-73.854z"
                    fill="url(#paint2_linear)"
                  />
                </g>
                <path
                  opacity=".6"
                  d="M54.35 431.315C24.725 431.315.71 407.3.71 377.675c0-29.624 24.016-53.64 53.641-53.64 29.626 0 53.642 24.016 53.642 53.64 0 29.625-24.016 53.64-53.642 53.64zM54.35 107.78C24.725 107.78.708 83.765.708 54.14S24.725.5 54.35.5c29.626 0 53.642 24.015 53.642 53.64s-24.016 53.64-53.642 53.64z"
                  fill="#8CBAFF"
                />
                <path
                  d="M459.051 107.794c-29.626 0-53.642-24.016-53.642-53.64 0-29.625 24.016-53.64 53.642-53.64 29.625 0 53.641 24.015 53.641 53.64 0 29.624-24.016 53.64-53.641 53.64z"
                  fill="#1670FF"
                />
                <path
                  opacity=".6"
                  d="M459.016 431.245c-29.625 0-53.641-23.989-53.641-53.58 0-29.591 24.016-53.579 53.641-53.579 29.626 0 53.642 23.988 53.642 53.579s-24.016 53.58-53.642 53.58z"
                  fill="#0862ED"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="496.705"
                  y1="298.5"
                  x2="1.194"
                  y2="314.055"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#1670FF" />
                  <stop offset="1" stopColor="#5E9DFF" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear"
                  x1="512.705"
                  y1="242.5"
                  x2="14.379"
                  y2="323.071"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#1670FF" />
                  <stop offset="1" stopColor="#8CBAFF" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear"
                  x1="512.705"
                  y1="242.5"
                  x2="14.379"
                  y2="323.071"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#1670FF" />
                  <stop offset="1" stopColor="#8CBAFF" />
                </linearGradient>
              </defs>
            </svg>
          </SvgIcon>
        );
      default:
        return (
          <SvgIcon viewBox="0 0 24 24" {...props}>
            <svg viewBox="0 0 512 432" fill="none" style={{ isolation: 'isolate' }}>
              <path
                d="M99.147 25.227C83.44.12 50.34-7.523 25.217 8.16.091 23.839-7.542 56.905 8.165 82.013l202.362 323.471c15.708 25.108 48.808 32.75 73.931 17.069 25.124-15.681 32.758-48.747 17.051-73.854L99.147 25.227Z"
                fill="#1670FF"
                style={{ mixBlendMode: 'multiply' }}
                opacity=".7"
              />
              <path
                d="M210.528 25.227C226.236.12 259.336-7.523 284.459 8.16c25.124 15.68 32.758 48.746 17.051 73.854L99.147 405.484c-15.707 25.108-48.807 32.75-73.93 17.069C.091 406.872-7.542 373.806 8.165 348.699L210.528 25.227Z"
                fill="#9710FF"
                style={{ mixBlendMode: 'multiply' }}
                opacity=".85"
              />
              <path
                d="M345.608 132.464c15.707-25.108 48.807-32.75 73.931-17.069 25.124 15.681 32.757 48.747 17.05 73.854L301.311 405.487c-15.707 25.108-48.807 32.749-73.931 17.069-25.123-15.681-32.757-48.747-17.05-73.854l135.278-216.238Z"
                fill="#3AB83F"
                style={{ mixBlendMode: 'multiply' }}
                opacity=".7"
              />
              <path
                d="M301.468 25.23C285.761.121 252.66-7.52 227.537 8.16c-25.124 15.682-32.758 48.747-17.051 73.855l202.363 323.471c15.707 25.108 48.807 32.75 73.931 17.069 25.124-15.681 32.757-48.747 17.05-73.854L301.468 25.229Z"
                fill="#E6D220"
                style={{ mixBlendMode: 'multiply' }}
                opacity=".7"
              />
              <path
                d="M53.642 430.815C24.016 430.815 0 406.8 0 377.175c0-29.624 24.016-53.64 53.642-53.64 29.625 0 53.641 24.016 53.641 53.64 0 29.625-24.016 53.64-53.641 53.64Z"
                fill="#ED3770"
              />
              <path
                d="M53.642 107.28C24.016 107.28 0 83.265 0 53.64S24.016 0 53.642 0c29.625 0 53.641 24.015 53.641 53.64s-24.016 53.64-53.641 53.64Z"
                fill="#1670FF"
              />
              <path
                d="M458.345 107.292c-29.626 0-53.642-24.016-53.642-53.64 0-29.625 24.016-53.64 53.642-53.64 29.625 0 53.642 24.015 53.642 53.64 0 29.624-24.017 53.64-53.642 53.64Z"
                fill="#3AB83F"
              />
              <path
                d="M458.31 430.745c-29.626 0-53.642-23.989-53.642-53.58 0-29.591 24.016-53.579 53.642-53.579 29.625 0 53.641 23.988 53.641 53.579s-24.016 53.58-53.641 53.58Z"
                fill="#F75C03"
              />
            </svg>
          </SvgIcon>
        );
    }
  },
);
