import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { v4 as uuid } from 'uuid';

import { useFiltrationProtocolDesign } from 'client/app/components/Parameters/FiltrationProtocolDesign/FiltrationProtocolDesignState';
import { useLiquidCount } from 'client/app/components/Parameters/FiltrationProtocolDesign/lib/useLiquidCount';
import useLiquidGroupForm from 'client/app/components/Parameters/FiltrationProtocolDesign/lib/useLiquidGroupForm';
import SubComponentsForm from 'client/app/components/Parameters/FiltrationProtocolDesign/SubComponentsForm';
import {
  Factor,
  LiquidGroup,
} from 'client/app/components/Parameters/FiltrationProtocolDesign/types';
import MetaDataForm from 'client/app/components/Parameters/MetaDataForm';
import { pluralize } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import Checkbox from 'common/ui/components/Checkbox';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  selectedLiquidGroup: LiquidGroup | undefined;
  onClose: () => void;
  isReadonly: boolean;
};

export default function LiquidGroupForm({
  selectedLiquidGroup,
  onClose,
  isReadonly,
}: Props) {
  const classes = useStyles();

  const { factors, addLiquidGroup, updateLiquidGroup } = useFiltrationProtocolDesign();
  const form = useLiquidGroupForm(selectedLiquidGroup, factors);
  const liquidCount = useLiquidCount(form.selectedFactors);
  const handleNameChange = useTextFieldChange(form.updateName);

  const isNew = !selectedLiquidGroup;

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (form.state.errors) {
      form.showErrors();
      return;
    }

    const liquidGroupDraft: LiquidGroup = {
      clientId: isNew ? uuid() : selectedLiquidGroup.clientId,
      name: form.state.name ?? '',
      factorIds: form.state.selectedFactorIds,
      constants: {
        subComponents: form.state.subComponents,
        metaData: form.state.metaData,
      },
    };

    if (isNew) {
      addLiquidGroup(liquidGroupDraft);
    } else {
      updateLiquidGroup(liquidGroupDraft);
    }
    onClose();
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <header>
        <Typography component="h5">
          {isNew ? `New` : isReadonly ? `View` : `Edit`} Liquid Group
        </Typography>
      </header>
      <main>
        <section className={classes.liquidGroupName}>
          <Typography variant="caption" className={classes.required}>
            Name
          </Typography>
          <TextField
            placeholder="Liquid group name"
            value={form.state.name}
            onChange={handleNameChange}
            error={form.state.showErrors && !!form.state.errors?.name}
            helperText={form.state.errors?.name}
            disabled={isReadonly}
          />
        </section>
        {factors.length > 0 && (
          <section className={classes.factorList}>
            <Typography variant="caption">Choose factors to apply</Typography>
            <FactorSelectionCheckboxGroup
              factors={factors}
              selectedFactors={form.selectedFactors}
              toggleFactor={form.toggleFactor}
              isReadonly={isReadonly}
            />
          </section>
        )}
        <Typography variant="body1" className={classes.liquidCreateInfo}>
          This selection will create{' '}
          <span className="bold">{liquidCount.equationString}</span>
        </Typography>
        <section className={classes.constants}>
          <div className={classes.constantItems}>
            <Box display="flex" alignItems="center">
              <Typography variant="caption">Sub-component Concentrations</Typography>
            </Box>
            <SubComponentsForm
              subComponents={form.state.subComponents}
              updateSubComponents={form.updateSubComponents}
              showErrors={form.state.showErrors}
              errors={form.state.errors?.subComponents}
              isReadonly={isReadonly}
            />
          </div>
          <div className={classes.constantItems}>
            <Box display="flex" alignItems="center">
              <Typography variant="caption">Metadata</Typography>
            </Box>
            <MetaDataForm
              metaItems={form.state.metaData}
              updateMeta={form.updateMeta}
              showError={form.state.showErrors}
              error={form.state.errors?.meta}
              isReadonly={isReadonly}
            />
          </div>
        </section>
      </main>
      <footer>
        <Button type="button" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="secondary"
          color="primary"
          disabled={!form.state.dirty || isReadonly}
        >
          {isNew ? 'Add' : 'Save'} Liquid Group
        </Button>
      </footer>
    </form>
  );
}

type CheckboxGroupProps = {
  factors: Factor[];
  selectedFactors: Factor[];
  toggleFactor: (factor: Factor) => void;
  isReadonly: boolean;
};

function FactorSelectionCheckboxGroup({
  factors,
  selectedFactors,
  toggleFactor,
  isReadonly,
}: CheckboxGroupProps) {
  const classes = useStyles();
  const factorSelectionCheckboxGroup = useMemo(
    () =>
      factors.map(factor => (
        <FormControlLabel
          key={factor.clientId}
          className="item"
          control={
            <Checkbox
              onChange={() => toggleFactor(factor)}
              checked={selectedFactors.some(f => f.clientId === factor.clientId)}
              disabled={isReadonly}
            />
          }
          label={getFactorCheckboxLabel(factor)}
        />
      )),
    [factors, isReadonly, selectedFactors, toggleFactor],
  );
  return <div className={classes.checkboxGroup}>{factorSelectionCheckboxGroup}</div>;
}

function getFactorCheckboxLabel(factor: Factor): string {
  switch (factor.type) {
    case 'categorical':
    case 'numeric':
      return `${factor.name} (${pluralize(factor.value.length, 'level')})`;
    case 'subComponent':
      return `${factor.name} (${pluralize(
        factor.value.names.length * factor.value.values.length,
        'level',
      )})`;
  }
}

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',

    '& > header': {
      padding: spacing(6),
      color: palette.primary.dark,
    },
    '& > main': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      gap: spacing(5),
      // this is necessary for overflow to scroll while the real size is defined by flexGrow
      height: '1px',
      overflowY: 'auto',
      padding: spacing(0, 6, 6),
    },
    '& > footer': {
      padding: spacing(5, 6),
      display: 'flex',
      gap: spacing(4),
      justifyContent: 'flex-end',
      borderTop: `1px solid ${palette.grey[300]}`,
    },
  },
  required: {
    '&::after': {
      content: "'*'",
      color: palette.error.main,
    },
  },
  liquidGroupName: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
  factorList: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(3),
  },
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${palette.divider}`,
    borderRadius: spacing(2),
    '& .item': {
      margin: 0,
      padding: spacing(0, 4),
      '&:not(:last-child)': {
        borderBottom: `1px solid ${palette.divider}`,
      },
    },
  },
  liquidCreateInfo: {
    background: Colors.GREEN_5,
    padding: spacing(4),
    borderRadius: '6px',
    '& .bold': {
      fontWeight: 700,
    },
  },
  constants: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(3),
  },
  constantItems: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(4, 0),
    gap: spacing(3),
  },
}));
