import React from 'react';

import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { pluralize } from 'common/lib/format';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type SelectionActionsProps = {
  handleClear?: () => void;
  handleCancel?: () => void;
  handleConfirm: () => void;
  /** Show the item count "<number of item> <itemLabel> [Clear]" */
  selectedItems?: number;
  /** Type of the items being selected */
  itemLabel: string;
  /** Copy of the button the the handleConfirm handler is attached to */
  primaryActionLabel: string;
  /** Prevent the user from being able to confirm if no items are selected (i.e. selectedItems === 0) */
  disableEmptySelection?: boolean;
};

export default function SelectionActions(props: SelectionActionsProps) {
  const {
    handleClear,
    handleCancel,
    handleConfirm,
    selectedItems,
    itemLabel,
    primaryActionLabel,
    disableEmptySelection,
  } = props;
  const classes = useDialogActionStyles();

  const shouldShowClearButton = !!handleClear;
  return (
    <div className={classes.selectionActions}>
      <div className={classes.selectionStatus}>
        {typeof selectedItems === 'number' && (
          <>
            <Typography className={cx(classes.rightSpacing, classes.selectionStatusText)}>
              {`${pluralize(selectedItems, itemLabel)} selected`}
            </Typography>
            {shouldShowClearButton && (
              <Button onClick={handleClear} variant="tertiary">
                Clear
              </Button>
            )}
          </>
        )}
      </div>
      <div className={classes.selectionActionButtons}>
        {handleCancel && (
          <Button
            onClick={handleCancel}
            variant="tertiary"
            className={classes.rightSpacing}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={handleConfirm}
          variant="tertiary"
          color="primary"
          disabled={disableEmptySelection && (selectedItems ?? 0) === 0}
        >
          {primaryActionLabel}
        </Button>
      </div>
    </div>
  );
}

const useDialogActionStyles = makeStylesHook(theme => ({
  selectionActions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  selectionStatus: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  selectionActionButtons: {
    display: 'flex',
  },
  selectionStatusText: {
    whiteSpace: 'nowrap',
  },
  rightSpacing: {
    // TODO(T2278); Fix when we have a common way of handling spacing.
    marginRight: theme.spacing(3),
  },
}));
