import { useQuery } from '@apollo/client';
import groupBy from 'lodash/groupBy';

import { QUERY_ORG_USERS } from 'client/app/api/gql/queries';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { Option } from 'common/types/Option';

/**
 * Get list of all users. For use within the workflow list user filter.
 */
export function useUserList(): Option<string>[] {
  const { data } = useQuery(QUERY_ORG_USERS);
  const users = data?.users;

  const currentUser = useUserProfile();

  // Use the list of users; if that's not available, then just show the current user.
  if (users) {
    const usersByName = groupBy(users, user => user.displayName);
    return Object.values(usersByName).flatMap(users =>
      users.map((user, i) => ({
        // Users with the same name should have a number suffixed
        label: users.length === 1 ? user.displayName : `${user.displayName} (${i + 1})`,
        value: user.id,
      })),
    );
  } else {
    return [{ label: currentUser?.displayName ?? '', value: currentUser?.id ?? '' }];
  }
}
