import React, { useCallback, useMemo, useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import DataExport from 'client/app/apps/execution-details/ResultsTab/components/DataExport';
import LabwareCard from 'client/app/apps/execution-details/ResultsTab/components/LabwareCard';
import useDeleteLabwareFile from 'client/app/apps/execution-details/ResultsTab/hooks/useDeleteLabwareFile';
import useDownloadExecutionResults from 'client/app/apps/execution-details/ResultsTab/hooks/useDownloadExecutionResults';
import useDownloadLabwareFile from 'client/app/apps/execution-details/ResultsTab/hooks/useDownloadLabwareFile';
import useLabware, {
  arePlateDataUploaded,
} from 'client/app/apps/execution-details/ResultsTab/hooks/useLabware';
import useShowStructureDataButton from 'client/app/apps/execution-details/ResultsTab/hooks/useShowStructureDataButton';
import useUploadLabwareFile, {
  FileUploadData,
} from 'client/app/apps/execution-details/ResultsTab/hooks/useUploadLabwareFile';
import { Execution, LabwareFile } from 'client/app/apps/execution-details/types';
import StructureDataButton from 'client/app/apps/simulation-details/StructureDataButton';
import VisualiseResultsButton, {
  ANALYSE_CHROMATOGRAPHY_VISUALISATION_NAME,
} from 'client/app/apps/simulation-details/VisualiseResultsButton';
import { SimulationSourceEnum } from 'client/app/gql';
import useIsRobocolumnsSimulation from 'client/app/hooks/useIsRobocolumnsSimulation';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { Deck } from 'common/types/mix';
import Colors from 'common/ui/Colors';

type Props = {
  execution: Execution;
  deck: Deck;
  onRefresh: () => Promise<unknown>;
};

function ResultsTab({ execution, deck, onRefresh }: Props) {
  const isNewDOEEnabled = useFeatureToggle('NEW_DOE');

  /**
   * TODO: we should not use simulation object here as it incorporates a lot of
   * unnecessary payload being transfered through the network.
   * Instead we should figure out what parts of the simulation are used and for what
   * purposes and create dedicated GrapqhQL fields serving those purposes
   */
  const isRobocolumnsSimulation = useIsRobocolumnsSimulation(
    execution.simulation.workflow.workflow,
  );
  const showStructureDataButton = useShowStructureDataButton(execution.simulation);

  const labwareList = useLabware(execution, deck);
  const isLabwareDataUploaded = useMemo(
    () => arePlateDataUploaded(labwareList),
    [labwareList],
  );

  const [uploadingFile, setUploadingFile] = useState(false);
  const [deletingFile, setDeletingFile] = useState(false);
  const [downloadingZip, setDownloadingZip] = useState(false);

  const uploadRemoteLabwareFile = useUploadLabwareFile(execution.id);
  const uploadLabwareFile = useCallback(
    async (data: FileUploadData) => {
      setUploadingFile(true);
      try {
        return await uploadRemoteLabwareFile(data);
      } catch (error) {
        setUploadingFile(false);
        throw error;
      }
    },
    [uploadRemoteLabwareFile],
  );
  const refreshAfterLabwareFileUpload = useCallback(async () => {
    await onRefresh();
    setUploadingFile(false);
  }, [onRefresh]);

  const deleteRemoteLabwareFile = useDeleteLabwareFile();
  const deleteLabwareFile = useCallback(
    async (file: LabwareFile) => {
      try {
        setDeletingFile(true);
        await deleteRemoteLabwareFile(file);
        await onRefresh();
      } finally {
        setDeletingFile(false);
      }
    },
    [deleteRemoteLabwareFile, onRefresh],
  );

  const downloadLabwareFile = useDownloadLabwareFile(execution.simulation.id);
  const downloadDataExportZip = useDownloadExecutionResults(
    execution.id,
    execution.simulation.name,
  );

  return (
    <Container>
      <Header>
        <Typography variant="h1">Results</Typography>
        <Stack direction="row" gap={5}>
          {showStructureDataButton && isNewDOEEnabled && (
            <StructureDataButton
              execution={execution}
              deck={deck}
              isRobocolumnsSimulation={isRobocolumnsSimulation}
            />
          )}
          {execution.simulation.visualisations?.map(visualisation => {
            const isChromatography =
              visualisation.name === ANALYSE_CHROMATOGRAPHY_VISUALISATION_NAME;
            const isNewDOE =
              execution.simulation.source === SimulationSourceEnum.DOE_DESIGN;

            return (isChromatography && isRobocolumnsSimulation) ||
              (!isChromatography && !isNewDOE) ? (
              <VisualiseResultsButton
                key={execution.simulation.id}
                simulationId={execution.simulation.id}
                visualisation={visualisation}
              />
            ) : null;
          })}
        </Stack>
      </Header>
      <CardStack>
        <LabwareAndDataset>
          <Typography variant="h2">Labware & Dataset</Typography>
          {labwareList.map(labware => (
            <LabwareCard
              key={labware.id}
              labware={labware}
              onAddFile={uploadLabwareFile}
              onDeleteFile={deleteLabwareFile}
              onDownloadFile={downloadLabwareFile}
              onRefresh={refreshAfterLabwareFileUpload}
            />
          ))}
        </LabwareAndDataset>
        <DataExport
          onDownload={async () => {
            setDownloadingZip(true);
            await downloadDataExportZip();
            setDownloadingZip(false);
          }}
          loading={downloadingZip}
          disabled={
            !isLabwareDataUploaded || uploadingFile || downloadingZip || deletingFile
          }
        />
      </CardStack>
    </Container>
  );
}

export default React.memo(ResultsTab);

const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(7, 6, 6),
  backgroundColor: Colors.WHITE,
  height: '100%',
}));

const Header = styled('header')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',

  height: 40,
  margin: theme.spacing(0, 3, 5, 3),
}));

const CardStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 3, 8, 3),
  gap: theme.spacing(5),

  flexDirection: 'row',
  alignItems: 'flex-start',
  flexGrow: 1,
  height: 0,

  overflowY: 'auto',
}));

const LabwareAndDataset = styled(Card)(({ theme }) => ({
  maxWidth: 'unset',
  margin: 0,
  padding: theme.spacing(6, 7),

  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(2),
  boxShadow:
    '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px 0px rgba(0, 0, 0, 0.20)',

  gap: theme.spacing(5),
}));
