import { Context, createContext } from 'react';

/**
 * Usually when creating a context, you must to provide a default value. This value is
 * used when the context is used but there is no parent provider.
 *
 * This function allows creating a context without a default values. Attempting to read a
 * value from the context when there is no parent provider will throw an error.
 */
export function createContextWithoutDefault<T extends {}>(): Context<T> {
  return createContext<T>(
    new Proxy<T>({} as T, {
      get: prop => {
        throw new Error(
          `Couldn't read field ${prop}. Context must be used within a provider.`,
        );
      },
    }),
  );
}
