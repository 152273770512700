import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const FileIcon = React.memo<SvgIconProps>(function FileIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg fill="none" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 10H8v2h8v-2zm0 4H8v2h8v-2zM6 20h12V9h-5V4H6v16z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
