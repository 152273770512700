import { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { QUERY_DATA_UPLOAD_STATE_POLL } from 'client/app/api/gql/queries';
import { DataUploadState } from 'client/app/gql';

const POLLING_INTERVAL_MS = 2000;

/**
 * If uploadId is defined, this hook repeatedly polls the state of that uploadId and calls
 * onFinish() if it's not in PROCESSING state.  Generally, the uploadId should be set to undefined
 * or changed to another ID to poll inside onFinish(), otherwise onFinish() will be called on every
 * poll.
 */
export function useDataUploadPolling(
  uploadId: DataUploadId | undefined,
  onFinish: () => void,
) {
  const [pollDataUpload] = useLazyQuery(QUERY_DATA_UPLOAD_STATE_POLL, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data && data.upload.state === DataUploadState.COMPLETE) {
        onFinish();
      }
    },
  });

  useEffect(() => {
    if (!uploadId) {
      return;
    }
    const id = setInterval(async () => {
      void pollDataUpload({ variables: { uploadId } });
    }, POLLING_INTERVAL_MS);

    return () => {
      clearInterval(id);
    };
  }, [pollDataUpload, uploadId]);
}
