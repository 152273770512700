import React, { useCallback, useMemo } from 'react';

import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import DialogContentText from '@mui/material/DialogContentText';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import {
  isPlateBigEnough,
  PlateMinSizeByName,
} from 'client/app/apps/cherry-picker/CherryPickApi';
import {
  AllPlatesByType,
  PlatesByName,
  SetPlatesByName,
  useCherryPickContext,
} from 'client/app/apps/cherry-picker/CherryPickContext';
import { PlateSelection } from 'client/app/components/Parameters/PlateType/PlateSelectionEditor';
import PlateTypeEditor from 'client/app/components/Parameters/PlateType/PlateTypeEditor';
import { ScreenRegistry } from 'client/app/registry';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

export default function PlateSettings() {
  const {
    uniquePlateNames,
    setPlatesByName,
    platesByName,
    allPlatesByType: plates,
    plateMinSizeByName,
    isReadonly,
  } = useCherryPickContext();

  return (
    <Paper>
      <CardHeader title="Select plate type" />
      <CardContent>
        <DialogContentText gutterBottom>
          Please select a plate type for each plate you defined in your CherryPick
        </DialogContentText>
        {uniquePlateNames.length > 0 ? (
          <Table>
            <TableBody>
              {uniquePlateNames.map(plateName => (
                <PlateRow
                  key={plateName}
                  plateName={plateName}
                  onPlateChange={setPlatesByName}
                  platesByName={platesByName}
                  plates={plates}
                  plateMinSizeByName={plateMinSizeByName}
                  isReadonly={isReadonly}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography variant="body1">No plates specified in this CherryPick.</Typography>
        )}
      </CardContent>
    </Paper>
  );
}

type RowProps = {
  plateName: string;
  onPlateChange: SetPlatesByName;
  platesByName: PlatesByName;
  plates: AllPlatesByType;
  plateMinSizeByName: PlateMinSizeByName;
  isReadonly: boolean;
};

const PlateRow = React.memo((props: RowProps) => {
  const {
    plateName,
    onPlateChange,
    platesByName,
    plates,
    plateMinSizeByName,
    isReadonly,
  } = props;
  const snackbarManager = useSnackbarManager();

  const handlePlateChange = useCallback(
    (plateType: PlateSelection) => {
      logEvent('settings-change-plate', ScreenRegistry.CHERRY_PICKER);
      const unselectingPlate = typeof plateType === 'undefined';
      if (typeof plateType === 'string') {
        const plate = plates[plateType];

        const error = isPlateBigEnough(plate, plateName, plateMinSizeByName);
        // Prevent selecting the plate if we know it won't simulate
        if (error) {
          snackbarManager.showError(error);
          return;
        }

        onPlateChange(prev => ({
          ...prev,
          [plateName]: plate,
        }));
      } else if (unselectingPlate) {
        // Delete the property rather than keep it with a value of undefined
        onPlateChange(oldValues => {
          const newValues = { ...oldValues };
          delete newValues[plateName];
          return newValues;
        });
      }
    },
    [onPlateChange, plateMinSizeByName, plateName, plates, snackbarManager],
  );

  const selectedPlate = useMemo(() => platesByName[plateName], [plateName, platesByName]);

  const displayName = useMemo(
    () => plates[selectedPlate?.type]?.name,
    [plates, selectedPlate],
  );

  return (
    <TableRow>
      <TableCell>{plateName}</TableCell>
      <TableCell>
        <PlateTypeEditor
          selectedPlateType={selectedPlate?.type}
          platesByType={plates}
          onChange={handlePlateChange}
          displayValue={displayName}
          isDisabled={isReadonly}
        />
      </TableCell>
    </TableRow>
  );
});
