import React, { useCallback, useEffect, useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import stopPropagation from 'common/lib/stopPropagation';
import { StageDetails } from 'common/types/mixPreview';
import StageDetailsTooltip from 'common/ui/components/simulation-details/StepSlider/components/StageDetailsTooltip';
import { zIndex } from 'common/ui/components/simulation-details/StepSlider/components/styles';
import {
  STAGE_BREAKPOINT_HEIGHT,
  STAGE_BREAKPOINT_WIDTH,
} from 'common/ui/components/simulation-details/StepSlider/helpers';

type Props = {
  position: number;
  index: number;
  showIndex: boolean;
  showPopperArrow: boolean;
  details?: StageDetails;
  activeStage: number;
  popperContents: React.ReactNode;
  onClick: (stage: number) => void;
};

export default function StageBreakpoint({
  position,
  index,
  showIndex,
  showPopperArrow,
  details,
  activeStage,
  popperContents,
  onClick,
}: Props) {
  const popper = usePopper(index, activeStage);

  return (
    <Container
      active={popper.open}
      position={position}
      showPopperArrow={showPopperArrow}
      onClick={() => onClick(index)}
    >
      <StageDetailsTooltip details={details} showStageName={showIndex}>
        <Typography variant="body1" fontWeight={500}>
          {showIndex ? index + 1 : null}
        </Typography>
      </StageDetailsTooltip>
      {showPopperArrow && (
        <IconContainer onClick={popper.toggleOpen}>
          <ArrowIcon open={popper.open} />
        </IconContainer>
      )}
      <Popper
        id={popper.id}
        open={popper.open}
        anchorEl={popper.anchorEl}
        placement="top-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-STAGE_BREAKPOINT_WIDTH, 10],
            },
          },
        ]}
        style={{ zIndex: 2 }}
        onClick={stopPropagation}
      >
        {popperContents}
      </Popper>
    </Container>
  );
}

function usePopper(stageIndex: number, activeStage: number) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? `parallel-transfer-popper-${stageIndex}` : undefined;

  const toggleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      if (open) {
        setAnchorEl(null);
      } else {
        setAnchorEl(event.currentTarget);
      }
    },
    [open],
  );

  useEffect(() => {
    /**
     * Close popper when the active stage changes.
     * But keep it open if the active stage is related to this breakpoint
     */
    if (stageIndex !== activeStage) {
      setAnchorEl(null);
    }
  }, [activeStage, stageIndex]);

  return {
    id,
    anchorEl,
    open,
    toggleOpen,
  };
}

const Container = styled('div')<{
  active: boolean;
  position: number;
  showPopperArrow: boolean;
}>(({ theme, active, position, showPopperArrow }) => {
  let width: number;

  if (showPopperArrow) {
    /**
     * In case of simulation stage with parallel transfer stages the breakpoint
     * is 2 times bigger to contain an arrow button opening the parallel transfer slider.
     * And also the offset is 2 times bigger.
     */
    width = STAGE_BREAKPOINT_WIDTH * 2;
  } else {
    width = STAGE_BREAKPOINT_WIDTH;
  }

  return {
    position: 'absolute',

    top: -4,
    left: position - width,
    width,
    height: STAGE_BREAKPOINT_HEIGHT,

    zIndex: zIndex.breakpoint,
    backgroundColor: active ? theme.palette.grey[400] : theme.palette.divider,
    borderRadius: theme.spacing(2),

    display: 'flex',
    justifyContent: showPopperArrow ? 'space-evenly' : 'center',
    alignItems: 'center',

    cursor: 'pointer',
  };
});

const IconContainer = styled('div')({
  display: 'flex',
  alignItems: 'stretch',
  cursor: 'pointer',
});

const ArrowIcon = styled(ExpandLess, { shouldForwardProp: prop => prop !== 'open' })<{
  open: boolean;
}>(({ open }) => ({
  transition: 'transform 160ms ease-out',
  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  width: 18,
  height: 18,
  cursor: 'pointer',
}));
