import React from 'react';

import { usePlatesByType } from 'client/app/api/PlateTypesApi';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import ExistingPlateSelect, {
  ExistingPlateSelectProps,
} from 'client/app/components/Parameters/PlateType/ExistingPlateSelect';
import { PlateAndCarrier } from 'client/app/components/Parameters/PlateType/PlateAndCarrier';
import {
  PlateTypeSelect,
  PlateTypeSelectProps,
} from 'client/app/components/Parameters/PlateType/PlateTypeSelect';
import { PlateParameterValue } from 'client/app/components/Parameters/PlateType/processPlateParameterValue';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type PlateSelectorConfig =
  | React.ComponentType<PlateTypeSelectProps>
  | {
      Component: typeof PlateTypeSelect;
      additionalProps?: Pick<PlateTypeSelectProps, 'isWorkflowSettingsPanel'>;
    }
  | {
      Component: typeof ExistingPlateSelect;
      additionalProps?: Pick<ExistingPlateSelectProps, 'value' | 'displayValue'>;
    };

type Props = {
  value: PlateParameterValue;
  isDisabled?: boolean;
  isWorkflowSettingsPanel?: boolean;
  plateSelectors: PlateSelectorConfig[];
  onChange: (value?: PlateParameterValue) => void;
};

export type PlateSelection = string | null | undefined | typeof CANCEL_CHOICE;

export default function PlateSelectionEditor({
  onChange,
  value,
  isDisabled,
  isWorkflowSettingsPanel,
  plateSelectors,
}: Props) {
  const classes = useStyles();

  const [platesByType] = usePlatesByType();

  const plateSelectorsToRender = plateSelectors.map((selector, index) => {
    if (typeof selector === 'function') {
      const Component = selector;
      return <Component key={index} isDisabled={isDisabled} onChange={onChange} />;
    }
    const { Component, additionalProps } = selector;
    return (
      <Component
        key={index}
        isDisabled={isDisabled}
        onChange={onChange}
        {...additionalProps}
      />
    );
  });

  return (
    <>
      {!value ? (
        <div className={classes.btnsContainer}>{plateSelectorsToRender}</div>
      ) : (
        <PlateAndCarrier
          isDisabled={isDisabled}
          onChange={onChange}
          value={value}
          platesByType={platesByType}
          isWorkflowSettingsPanel={isWorkflowSettingsPanel}
        />
      )}
    </>
  );
}

const useStyles = makeStylesHook(() => ({
  btnsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
