import { Position2d } from 'common/types/Position';

export const WorkspaceId = 'WorkspaceId' as const;

export type WorkspaceEvents = {
  AlignCenter: {
    centerPosition: Position2d;
  };
  MoveToX: {
    x: number;
  };
};

type EventName = keyof WorkspaceEvents;

type EventData<TEvent extends EventName> = WorkspaceEvents[TEvent];

const WorkspaceObserver = {
  dispatch<TEvent extends EventName>(event: TEvent, data: EventData<TEvent>) {
    const workspaceElement = document.getElementById(WorkspaceId);
    if (workspaceElement) {
      workspaceElement.dispatchEvent(
        new CustomEvent<EventData<TEvent>>(event, { detail: data }),
      );
    } else {
      console.error('[WorkspaceObserver.dispatch]: Workspace element not found');
    }
  },
  subscribe<TEvent extends EventName>(
    event: TEvent,
    listener: (data: EventData<TEvent>) => void,
  ) {
    const workspaceElement = document.getElementById(WorkspaceId);
    if (workspaceElement) {
      const customListener = (event: Event) => {
        const data = (event as CustomEvent<EventData<TEvent>>).detail;
        listener(data);
      };
      workspaceElement.addEventListener(event, customListener);
      return () => workspaceElement.removeEventListener(event, customListener);
    } else {
      console.error('[WorkspaceObserver.subscribe]: Workspace element not found');
      return () => {};
    }
  },
};

export default WorkspaceObserver;
