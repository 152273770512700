import PlateCarriers from 'common/types/plateCarriers';

// The full plate type name is the concatenation of the plate name and the carrier if any.
// To split them we sadly need check if the full name ends with one of the carrier.
export default function splitFullPlateName(fullPlateName = '') {
  const carrier = PlateCarriers.find(
    carrier => fullPlateName.substr(-carrier.id.length) === carrier.id,
  ) || { id: '' };

  return [fullPlateName.substr(0, fullPlateName.length - carrier.id.length), carrier.id];
}

export function splitFullPlateNameWithDefault(
  fullPlateName = '',
  defaultPlateCarrier: string = PlateCarriers[PlateCarriers.length - 1].id,
) {
  if (!fullPlateName) {
    return ['', defaultPlateCarrier];
  }
  return splitFullPlateName(fullPlateName);
}
