import getGATrackingEnvironment from 'common/ui/getGATrackingEnvironment';
import isIntegrationTesting from 'common/ui/lib/IntegrationTesting';

export function getGATrackingID(): string | undefined {
  const env = getGATrackingEnvironment(window.location);
  const TRACKING_ID_DICT: { [env: string]: string } = {
    production: 'UA-25150006-3',
    staging: 'UA-25150006-8',
    dev: 'UA-25150006-5',
    anthahub: 'UA-25150006-10',

    // For testing, you'll want to uncomment the line below
    // localhost: 'UA-25150006-5',
  };

  return TRACKING_ID_DICT[env];
}

// We don't want to log hits from integration testing. Each run
// counts as a unique users, which massively pollutes the analytics.
const isAnIntegrationTest = isIntegrationTesting();

try {
  const trackerID = getGATrackingID();
  // Execute boilerplate Google Analytics initialization code as a side effect
  if (trackerID && !isAnIntegrationTest) {
    const win = window as any;
    win.dataLayer = win.dataLayer || [];
    win.gtag = function () {
      win.dataLayer.push(arguments);
    };
    win.gtag('js', new Date());
    win.gtag('config', trackerID);

    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=' + trackerID;
    scriptTag.async = true;
    document.body.appendChild(scriptTag);
  }
} catch (e) {
  console.warn('Error initialising Google Analytics: ', e);
}

export default null;
