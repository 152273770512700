import React, { ComponentProps, ReactNode } from 'react';

import Help from '@mui/icons-material/Help';
import HelpOutline from '@mui/icons-material/HelpOutline';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';
import Popover from 'common/ui/components/Popover';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  children: NonNullable<ReactNode>;
  classes?: Partial<ReturnType<typeof useStyles>>;
  popoverProps?: Partial<ComponentProps<typeof Popover>>;
  size?: 'large' | 'small';
  heading?: string;
};

const InlineHelp = ({ heading, children, popoverProps, size, ...props }: Props) => {
  const classes = useStyles(props);

  return (
    <Popover
      title={
        <>
          {heading && <Typography variant="subtitle1">{heading}</Typography>}
          <Typography variant="body1" component="div" className={classes.content}>
            {children}
          </Typography>
        </>
      }
      classes={{
        tooltip: classes.tooltip,
      }}
      {...popoverProps}
    >
      {size === 'large' ? (
        <Help className={classes.largeButton} color="primary" fontSize="large" />
      ) : (
        <HelpOutline fontSize="small" className={classes.smallButton} />
      )}
    </Popover>
  );
};

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  largeButton: {
    border: `1px solid ${Colors.BLUE_20}`,
    padding: spacing(2),
    borderRadius: spacing(6),

    '&:hover': {
      padding: spacing(2),
      borderColor: Colors.BLUE_30,
    },
  },
  smallButton: {
    color: Colors.TEXT_PRIMARY,
  },
  tooltip: {
    padding: spacing(4),
    margin: spacing(4, 0),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(3),
    maxWidth: '500px',
    color: palette.text.primary,
  },
  content: {
    lineHeight: `1.5em`,
    '& > p': {
      marginTop: 0,
      marginBottom: spacing(3),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
}));

export default InlineHelp;
