// consts from https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button

export const MOUSE_MAIN_BUTTON = 0;
export const MOUSE_MIDDLE_BUTTON = 1;
export const MOUSE_SECONDARY_BUTTON = 2;

/**
 * Check if users are holding down Shift, Cmd or Ctrl as this
 * means they are trying to  multi-select.
 * We support all to make it easier for users to figure out how
 * to do multi-select.
 */
export function isMultiSelectClick(e: React.MouseEvent) {
  return e.shiftKey || e.ctrlKey || e.metaKey;
}
