/**
 * The main parameter to all the functions in this file needs to be a JS object
 * of some sort.  There are two main categories of objects: those with a known
 * set of keys and those with unrestricted string keys.  This is the base type
 * for all of them.
 */
type BaseObj = { [k: string]: any };

/**
 * `keyof Obj` variant which works around a quirk discussed here:
 * https://stackoverflow.com/questions/51808160/keyof-inferring-string-number-when-key-is-only-a-string
 *
 * Specifically, `keyof {[key: string]: any}` returns `string | number` when the
 * keys are clearly strings.  This is because numbers can be used to **index
 * into** the object (JS coerces numbers to strings).  But when iterating over
 * existing keys in the object, we can of course only get strings out so the
 * string | number type would be surprising for writers of callback functions.
 */
type KnownKeysOrString<Obj extends BaseObj> = keyof Obj & string;

// filter() for objects. Each entry (key, value) is either included, or not.
export function filterObject<Obj extends BaseObj>(
  obj: Obj,
  keyValueFunc: (k: KnownKeysOrString<Obj>, v: Obj[keyof Obj]) => boolean,
): Obj {
  const filtered = {} as Obj;
  for (const [k, v] of Object.entries(obj) as Iterable<[KnownKeysOrString<Obj>, any]>) {
    if (keyValueFunc(k, v)) {
      filtered[k] = v;
    }
  }
  return filtered;
}

// map() for objects. Each entry (key, value) gets a new value.
// WARNING if your callback `keyValueFunc` is async or returns a Promise, the values will be the Promise objects, not the resolved values.
export function mapObject<Obj extends BaseObj, NewV, OldV = Obj[keyof Obj]>(
  obj: Obj,
  keyValueFunc: (k: KnownKeysOrString<Obj>, v: OldV) => NewV,
) {
  const mapped = {} as { [k: string]: NewV };
  for (const [k, v] of Object.entries(obj)) {
    mapped[k] = keyValueFunc(k, v);
  }
  return mapped;
}

/**
 * Given a Map, create a new map with the same keys but new values. Equivalent
 * to mapObject.
 */
export function mapValues<K, V, NewV>(
  map: Map<K, V>,
  keyValueFunc: (k: K, v: V) => NewV,
) {
  return new Map([...map].map(([key, value]) => [key, keyValueFunc(key, value)]));
}

/**
 * Apply a filter to a Map. Equivalent to filterObject
 */
export function filterMap<K, V>(map: Map<K, V>, filterFunc: (k: K, v: V) => boolean) {
  return new Map([...map].filter(([key, value]) => filterFunc(key, value)));
}

/**
 * Get the first value of an iterable (Map or Set)
 */
export function getFirstValue<T>(iterable: Map<unknown, T> | Set<T>): T | undefined {
  return iterable.values().next().value;
}

// infer resolved type of Promise, e.g. Promise<string> ~> string
type ResolvedPromise<T> = T extends Promise<infer I> ? I : T;
/**
 * Like Promise.all() for objects: returns a single Promise which fulfills all Promises contained in values in the given object.
 * The value either:
 * - stays the same if not a Promise
 * - become the resolved value if Promise succees
 *
 * In case of a Promise failure we return the first failure, this is to be consistent with Promise.all.
 */
export async function promiseAllObject<
  Obj extends BaseObj,
  NewObj = {
    [k in keyof Obj]: ResolvedPromise<Obj[keyof Obj]>;
  },
>(o: Obj): Promise<NewObj> {
  const resolvedEntries = await Promise.all(
    Object.entries(o).map(async ([k, v]) => [k, await v]),
  );
  return Object.fromEntries(resolvedEntries);
}

/**
 * async map() for objects. Each entry (key, value) gets a new value.
 * If the callback is async or returns a Promise, it will be resolved first.
 * If any promise fails the whole object will fail.
 */
export function asyncMapObject<
  Obj extends BaseObj,
  NewV,
  OldV = Obj[keyof Obj],
  NewObj = { [key in keyof Obj]: ResolvedPromise<NewV> },
>(obj: Obj, keyValueFunc: (k: KnownKeysOrString<Obj>, v: OldV) => NewV): Promise<NewObj> {
  return promiseAllObject(mapObject(obj, keyValueFunc));
}

/**
 * Creates a new object with keys of the original object mapped by `fn` to new string values.
 * If `fn` returns the same string for two input keys, the value will be overwritten.
 */
export function remapKeys<Obj extends BaseObj>(
  input: Obj,
  fn: (k: KnownKeysOrString<Obj>, v: Obj[keyof Obj]) => string,
) {
  const mapped: { [k in string]: Obj[keyof Obj] } = {};
  for (const [k, v] of Object.entries(input)) {
    mapped[fn(k, v)] = v;
  }
  return mapped;
}

/**
 *  Returns a **copy** of the object with the renamed key.
 *  If both keys are identical, it returns the original object.
 *  @param obj The whole object
 *  @param oldKey The key you want to rename
 *  @param newKey What you want to rename the old key to
 *  @param keepOldValueIfNewKeyExists If we are renaming a key to one that's there and
 *  already has a value, we can choose to keep that value or override it with the new one.
 */
export function renameKey<Obj extends BaseObj>(
  obj: Obj,
  oldKey: keyof Obj,
  newKey: keyof Obj | string,
  keepOldValueIfNewKeyExists: boolean = true,
): Obj {
  const keysAreIdentical = newKey === oldKey;
  const modifyingInvalidKey = !Object.prototype.hasOwnProperty.call(obj, oldKey);
  if (keysAreIdentical || modifyingInvalidKey) {
    return obj;
  }

  const newObj = { ...obj } as { [k in keyof Obj]: any };

  if (!Object.prototype.hasOwnProperty.call(newObj, newKey)) {
    newObj[newKey] = newObj[oldKey];
  } else {
    if (keepOldValueIfNewKeyExists) {
      newObj[newKey] = newObj[oldKey];
    }
  }
  delete newObj[oldKey];

  return newObj;
}
