import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const SplunkIcon = React.memo<SvgIconProps>(function SplunkIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M7.59375 6.09375V8.53125L14.5938 12L7.59375 15.4688V17.9062L17 13.1875V10.8125L7.59375 6.09375Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5ZM5 4C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4H5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
