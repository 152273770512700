import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const AnnotateEmptyIcon = React.memo<SvgIconProps>(function AnnotateEmptyIcon(
  props,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 2H11V4.25H5V20.25H17V12H19V20C19 21.1 18.1 22 17 22H4.99C3.89 22 3 21.1 3 20V4C3 2.9 3.9 2 5 2ZM7 14.75V12.75H15V14.75H7ZM7 18.25V16.25H15V18.25H7Z"
          fill="currentColor"
        />
        <path
          d="M19.346 0C19.1101 0 18.8648 0.0943792 18.6855 0.2737L16.9587 2.00084L20.4972 5.54006L22.224 3.81292C22.592 3.44484 22.592 2.85025 22.224 2.48217L20.016 0.2737C19.8273 0.0849413 19.5914 0 19.346 0ZM15.9491 3.0107L11.25 7.71078V11.25H14.7885L19.4876 6.54992L15.9491 3.0107Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
