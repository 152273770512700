import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const AddSelectedWellsIcon = React.memo<SvgIconProps>(
  function AddSelectedWellsIcon(props) {
    return (
      <SvgIcon viewBox="0 0 24 24" {...props}>
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 5v2h-3v3h-2V7h-3V5h3V2h2v3h3Z"
            fill="currentColor"
          />
          <path
            d="M11.5 5.00012c-4.14214 0-7.5 3.35787-7.5 7.49998 0 4.1422 3.35786 7.5 7.5 7.5 4.1421 0 7.5-3.3578 7.5-7.5 0-.168-.0055-.3347-.0164-.5h2.0035c.0086.1656.0129.3323.0129.5 0 5.2467-4.2533 9.5-9.5 9.5-5.24671 0-9.5-4.2533-9.5-9.5 0-5.24668 4.25329-9.49998 9.5-9.49998.1677 0 .3344.00435.5.01293v2.00348c-.1653-.01088-.332-.01641-.5-.01641Z"
            fill="currentColor"
          />
          <path
            d="M14 12.5001c0 1.3807-1.1193 2.5-2.5 2.5s-2.5-1.1193-2.5-2.5 1.1193-2.5 2.5-2.5 2.5 1.1193 2.5 2.5Z"
            fill="currentColor"
          />
        </svg>
      </SvgIcon>
    );
  },
);
