export default {
  title: 'Execution Preview Help',
  message:
    'Use the slider above to step through the execution of the workflow. ' +
    'For each step, you will see a set of lines. Each line represents a liquid transfer.\n\n' +
    'Hover the cursor over a line to see its properties. ' +
    'Hover the cursor over a well to see its contents. ' +
    'Click a well to see the liquids going in to and out of the well.\n\n' +
    'Tips & tricks:\n\n' +
    'The slider highlights interesting steps, such as tip box replacements and prompts.\n\n' +
    'Next to the slider, you can search for a liquid anywhere on the deck. ' +
    'The search works based on liquid name, as well as the components of the liquid.',
};
