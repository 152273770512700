import { v4 as uuid } from 'uuid';

import {
  Factor,
  LiquidGroup,
} from 'client/app/components/Parameters/FiltrationProtocolDesign/types';
import {
  Factor as SourceFactor,
  LiquidGroup as SourceLiquidGroup,
} from 'common/types/filtration';
import getMeasurementFromString from 'common/ui/components/ParameterEditors/unitRegistry';

export function mapFactorsFromElementParameter(factors: SourceFactor[] = []): Factor[] {
  return factors.map(factor => {
    if ('metaData' in factor) {
      const isNumeric = typeof factor.metaData.values[0] === 'number';
      return {
        clientId: uuid(),
        name: factor.name,
        type: isNumeric ? 'numeric' : 'categorical',
        value: factor.metaData.values,
        metadataName: factor.metaData.name === factor.name ? '' : factor.metaData.name,
      } as Factor;
    } else {
      return {
        clientId: uuid(),
        name: factor.name,
        type: 'subComponent',
        value: factor.subComponent,
      };
    }
  });
}

export function mapFactorsToElementParameter(factors: Factor[]): SourceFactor[] {
  return factors.map(factor => {
    switch (factor.type) {
      default:
      case 'numeric':
      case 'categorical':
        return {
          name: factor.name,
          metaData: {
            name: factor.metadataName === '' ? factor.name : factor.metadataName,
            values: factor.value,
          },
        };
      case 'subComponent':
        return {
          name: factor.name,
          subComponent: factor.value,
        };
    }
  });
}

export function mapLiquidGroupsFromElementParameter(
  liquidGroups: SourceLiquidGroup[] = [],
  factors: Factor[],
): LiquidGroup[] {
  return liquidGroups.map(liquidGroup => ({
    clientId: uuid(),
    name: liquidGroup.name,
    constants: {
      subComponents: Object.entries(liquidGroup.constants.subComponents).map(
        ([name, concentrationString]) => {
          const { originalString: _, ...concentration } =
            getMeasurementFromString(concentrationString);
          return {
            id: uuid(),
            name,
            concentration,
          };
        },
      ),
      metaData: Object.entries(liquidGroup.constants.metaData).map(([key, value]) => ({
        id: uuid(),
        key,
        value: value.toString(),
      })),
    },
    factorIds: factors
      .filter(f => liquidGroup.factorNames.includes(f.name))
      .map(f => f.clientId),
  }));
}

export function mapLiquidGroupsToElementParameter(
  liquidGroups: LiquidGroup[],
  factors: Factor[],
): SourceLiquidGroup[] {
  return liquidGroups.map(liquidGroup => ({
    name: liquidGroup.name,
    factorNames: factors
      .filter(f => liquidGroup.factorIds.includes(f.clientId))
      .map(f => f.name),
    constants: {
      subComponents: liquidGroup.constants.subComponents.reduce(
        (prevItems, nextItem) => ({
          ...prevItems,
          [nextItem.name]: `${nextItem.concentration.value}${nextItem.concentration.unit}`,
        }),
        {},
      ),
      metaData: liquidGroup.constants.metaData.reduce(
        (prevItems, nextItem) => ({
          ...prevItems,
          [nextItem.key]: nextItem.value,
        }),
        {},
      ),
    },
  }));
}
