import { useMemo } from 'react';

import { WritableDraft } from 'immer/dist/internal';
import entries from 'lodash/entries';

import { OutputPreviewState } from 'client/app/apps/workflow-builder/output-preview/types';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { isDefined } from 'common/lib/data';
import { formatMeasurementObj, formatWellPosition, roundNumber } from 'common/lib/format';
import { alphanumericCompare } from 'common/lib/strings';
import { FilterMatrix, Liquid } from 'common/types/bundle';
import { Measurement } from 'common/types/mix';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';

type Row = {
  id: string; // id will be the liquid 'name' + idx on interation
  cells: Cell[];
};

type Cell = {
  id: string; // id will be the parent 'id' and used for uniqueness when iterating.
  content: string;
};

type ColumnHeader = Cell & {
  colSpan?: number;
};

/**
 * Returns true if the input type is a Liquid
 */
export function isLiquidType(input: Liquid | FilterMatrix): input is Liquid {
  return 'id' in input;
}

export function useOutputPreviewTable(
  outputLiquids: (Liquid | FilterMatrix)[],
  plateName: string | undefined,
): {
  rows: Row[];
  columnHeaders: ColumnHeader[][];
} {
  // construct a table with the following structure:
  //
  //                                                [Contents],                            [MetaData]
  //   [Location], Liquid|RoboColumn, [Volume (ul)], Contents1, Contents 2, ..., ContentsN, Meta1, Meta2, ..., MetaM
  //   A1,         Liquid A,          10,            -,         10mg/ml,    ..., -,         38.7,  Sample, ..., -
  //   etc...
  //
  // The columns "Location", "Volume", "Contents", and "MetaData" are skipped if no liquid provides
  // this information
  return useMemo(() => {
    const filteredOutputLiquids = outputLiquids.filter(
      liquid => !plateName || liquid.position?.plateName === plateName,
    );

    const subComponents = filteredOutputLiquids
      .flatMap(liquid => (isLiquidType(liquid) ? entries(liquid.subComponents) : []))
      .filter(isDefined);

    const formatSubcomponentString = (subComponent: [string, Measurement]) => {
      return `${subComponent[0]} (${subComponent[1].unit})`;
    };
    // The uniqueSubComponents are effectively going to be some of the column headers in our table.
    // Each will be the unique combination of subomponent name and concentration unit.
    // We don't want to display duplicates, so we group them here, and use this as a reference
    // for ranging over our liquids.
    const uniqueSubComponents = new Set<string>(
      subComponents.map(formatSubcomponentString).sort(alphanumericCompare),
    );

    // Metadata is additional data (non-subcomponent related) added to the table
    const metaDataLabels = new Set<string>(
      filteredOutputLiquids
        .flatMap(liq => liq.metaData?.map(d => d.label))
        .filter(isDefined)
        .sort(alphanumericCompare),
    );

    const hasLocation = filteredOutputLiquids.some(l => l.position?.wellCoords);
    const hasVolume = filteredOutputLiquids.some(l => l.volume);
    const areLiquids = filteredOutputLiquids[0] && isLiquidType(filteredOutputLiquids[0]);

    const columnHeaders: ColumnHeader[][] = [];
    // the first header row just shows Contents and MetaData, we don't need it if we
    // don't have either
    if (uniqueSubComponents.size || metaDataLabels.size) {
      const headerRow: ColumnHeader[] = [];
      const numEmptyCells = 1 + Number(hasLocation) + Number(hasVolume);
      headerRow.push({
        id: 'empty',
        content: '',
        colSpan: numEmptyCells,
      });
      if (uniqueSubComponents.size) {
        headerRow.push({
          id: 'subcomponents',
          content: 'Sub-components',
          colSpan: uniqueSubComponents.size,
        });
      }
      if (metaDataLabels.size) {
        headerRow.push({
          id: 'metadata',
          content: 'Metadata',
          colSpan: metaDataLabels.size,
        });
      }
      columnHeaders.push(headerRow);
    }

    const headerRow: ColumnHeader[] = [];
    if (hasLocation) {
      headerRow.push({
        id: 'location',
        content: 'Location',
      });
    }
    headerRow.push({
      id: 'liquid-or-robocolumn',
      content: areLiquids ? 'Liquid Name' : 'RoboColumn Name',
    });
    if (hasVolume) {
      headerRow.push({
        id: 'volume',
        content: 'Volume (ul)',
      });
    }

    uniqueSubComponents.forEach((name, idx) => {
      headerRow.push({
        id: `sub-compoment-${idx}`,
        content: name,
      });
    });
    metaDataLabels.forEach((label, idx) => {
      headerRow.push({
        id: `metadata-${idx}`,
        content: label,
      });
    });
    columnHeaders.push(headerRow);

    const rows: Row[] = [];
    filteredOutputLiquids.forEach((liquid, idx) => {
      const cells: Cell[] = [];

      if (hasLocation) {
        const coords = liquid.position?.wellCoords;
        const content = coords ? formatWellPosition(coords.y, coords.x) : '-';
        cells.push({
          id: `liquid-${idx}-loc`,
          content,
        });
      }
      cells.push({
        id: `liquid-${idx}-name`,
        content: liquid.name ?? '',
      });
      if (hasVolume) {
        const content = liquid.volume
          ? formatMeasurementObj(liquid.volume).replace('ul', '')
          : '';
        cells.push({
          id: `liquid-${idx}-volume`,
          content: content,
        });
      }

      // only liquids have subComponents; uniqueSubComponents is empty if false
      if (isLiquidType(liquid)) {
        const subComponentNamesAndConcentrations = new Map(
          entries(liquid.subComponents).map(subComponent => {
            return [formatSubcomponentString(subComponent), subComponent[1]];
          }),
        );

        uniqueSubComponents.forEach(subComponentNameAndConcentration => {
          // If this uniqueSubComponents is not in the current liquid, create an
          // "empty" cell.
          if (!subComponentNamesAndConcentrations.has(subComponentNameAndConcentration)) {
            cells.push({
              id: `${liquid.id}-${subComponentNameAndConcentration}`,
              content: '-',
            });
          } else {
            const subComponentConcentration = subComponentNamesAndConcentrations.get(
              subComponentNameAndConcentration,
            );
            if (subComponentConcentration) {
              cells.push({
                id: `${liquid.id}-${subComponentNameAndConcentration}`,
                content: roundNumber(subComponentConcentration.value),
              });
            }
          }
        });
      }

      metaDataLabels.forEach(label => {
        // deduplication of values should in principle be unnecessary since the
        // metadata should be a set of [label + value] from the backend
        const content = liquid.metaData
          ?.filter(d => d.label === label)
          .map(v => ('value_float' in v ? roundNumber(v.value_float) : v.value_string))
          .sort(alphanumericCompare)
          .join(', ');

        cells.push({
          id: `${idx}-${label}`,
          content: content || '-',
        });
      });

      rows.push({
        id: `${liquid.name}-${idx}`,
        cells: cells,
      });
    });

    return { rows, columnHeaders };
  }, [outputLiquids, plateName]);
}

export function useElementParameterAndColorsForOutputs(
  elementId: string,
  parameterName: string,
): {
  elementInstanceName: string;
  parameterDisplayName: string;
  liquidColors: LiquidColors;
} {
  const liquidColors = useMemo(() => LiquidColors.createAvoidingAllColorCollisions(), []);

  const elementInstances = useWorkflowBuilderSelector(state => state.elementInstances);
  const element = elementInstances.find(el => el.Id === elementId);
  const parameter = element?.element.outputs.find(param => param.name === parameterName);
  if (!element || !parameter) {
    throw new Error(
      `Could not find element with id: ${elementId} and parameter name: ${parameterName}`,
    );
  }
  return {
    elementInstanceName: element.name,
    parameterDisplayName: parameter.configuration?.displayName ?? parameter.name,
    liquidColors,
  };
}

export function resetOutputPreviewState(state: WritableDraft<OutputPreviewState>) {
  state.entityView = undefined;
  state.outputType = undefined;
  state.selectedOutputParameterName = undefined;
}
