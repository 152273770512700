import { useAuth0 } from '@auth0/auth0-react';

import { useAuth0ClientData, useAuth0Config } from 'client/app/apps/authentication/hooks';
import { isLocalDev } from 'common/ui/lib/envs';

export function useLogout() {
  const { logout } = useAuth0();
  const { clearClientData } = useAuth0ClientData();
  const { clearAuth0Config } = useAuth0Config();
  return async () => {
    // Don't return to location.href since the new login might be in another
    // organisation (in that case the URL won't exist) or another user.
    // But keep the search params e.g. 'authconn' if any.
    await logout({ logoutParams: { returnTo: window.location.href.split('#')[0] } });
    clearClientData();
    // Clear Auth0 config on local dev to avoid caching issues e.g. if
    // Auth0 tenant changes.
    if (isLocalDev(window.location)) {
      clearAuth0Config();
    }
  };
}
