import { useCallback } from 'react';

import { useGet } from 'client/app/lib/xhr';

export function useDownloadFile() {
  const get = useGet();

  return useCallback(
    async (url: string) => {
      const res = await get(url);

      const header = res.headers.get('Content-Disposition');
      const parts = header?.split(';');
      const filename = parts?.[1]?.split('=')[1]?.replaceAll('"', '');

      const data = await res.blob();

      if (filename && data) {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    },
    [get],
  );
}
