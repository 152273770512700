import { Theme } from '@mui/material/styles';
import { StyleRules } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import Colors from 'common/ui/Colors';

export const experimentsStyles = (theme: Theme) =>
  createStyles({
    actions: {
      justifyContent: 'space-between',
    },
    chip: {
      '&:nth-of-type(1)': {
        marginLeft: 0,
      },
      margin: theme.spacing(6, 0, 5, 5),
    },
    circularLoadingContainer: {
      // Place the loading circle in the center of its row
      width: 'fit-content',
      margin: '0 auto',
      zIndex: 2,
    },
    filter: {
      display: 'flex',
    },
    list: {
      zIndex: 0,
      display: 'grid',
      gridRowGap: theme.spacing(3),
      marginBottom: theme.spacing(8),
      marginTop: theme.spacing(3),
      width: '100%',
    },
    search: {
      margin: theme.spacing(6, 0, 5, 0),
    },
  });

export const clickableStyle: StyleRules = {
  style: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
};

export const hoverStyle = (theme: Theme) =>
  createStyles({
    background: {
      '&:hover': {
        backgroundColor: Colors.ACTION_PRIMARY_MAIN_HOVER,
        borderColor: 'transparent',
        cursor: 'pointer',
      },
      transition: theme.transitions.easing.easeInOut,
    },
  });

export const activeStyle: StyleRules = {
  style: {
    backgroundColor: Colors.ACTION_PRIMARY_MAIN_ACTIVE,
    borderColor: 'transparent',
    transition: '0s',
  },
};
