import React, { useCallback } from 'react';

import { useQuery } from '@apollo/client';

import { QUERY_TEMPLATE_WORKFLOWS } from 'client/app/api/gql/queries';
import TemplateWorkflowsGrid from 'client/app/apps/experiments/Templates/TemplateWorkflowsGrid';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';

type Props = {
  search: string;
  closeDialog?: () => void;
};

export default React.memo(function TemplateWorkflowsGridContainer(props: Props) {
  const { search, closeDialog } = props;
  const { loading, error, data, refetch, fetchMore } = useQuery(
    QUERY_TEMPLATE_WORKFLOWS,
    {
      variables: { search },
      fetchPolicy: 'network-only',
    },
  );
  const pageInfo = data?.templateWorkflows?.pageInfo;
  const after = pageInfo?.endCursor;

  const nextPage = useCallback(
    () =>
      fetchMore({
        variables: {
          after,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          // this looks complex, but we're really only appending field
          // templateWorkflows.items from previous and latest results
          return {
            ...fetchMoreResult,
            templateWorkflows: {
              ...fetchMoreResult.templateWorkflows,
              items: [
                ...prev.templateWorkflows.items,
                ...fetchMoreResult.templateWorkflows.items,
              ],
            },
          };
        },
      }),
    [after, fetchMore],
  );

  const hasNextPage = pageInfo?.hasNextPage || false;
  const templateWorkflows = data?.templateWorkflows.items || [];

  if (error) {
    return <GraphQLErrorPanel error={error} onRetry={refetch} />;
  }
  return (
    <TemplateWorkflowsGrid
      hasNextPage={hasNextPage}
      isLoading={loading}
      nextPage={nextPage}
      templateWorkflows={templateWorkflows}
      refetch={refetch}
      closeDialog={closeDialog}
    />
  );
});
