import { Parameter } from 'common/types/bundle';

/** Dictionary of element input types which can accept multiple different output types for connections.*/
export const permittedTypeBasedConnections: {
  [inputPortType: string]: string[];
} = {
  'github.com/Synthace/antha/antha/anthalib/wtype.Plates': [
    '[]*github.com/Synthace/antha/antha/anthalib/wtype.Plate',
    '*github.com/Synthace/antha/antha/anthalib/wtype.Plate',
  ],
  'github.com/Synthace/antha/antha/anthalib/wtype.Liquids': [
    '[]*github.com/Synthace/antha/antha/anthalib/wtype.Liquid',
    '*github.com/Synthace/antha/antha/anthalib/wtype.Liquid',
    'github.com/Synthace/antha/antha/anthalib/wtype.Plates',
  ],
  '[]*github.com/Synthace/antha/antha/anthalib/wtype.Liquid': [
    'github.com/Synthace/antha/antha/anthalib/wtype.Liquids',
  ],
  '[]*github.com/Synthace/antha/antha/anthalib/wtype.Plate': [
    'github.com/Synthace/antha/antha/anthalib/wtype.Plates',
  ],
  'map[github.com/Synthace/antha/stdlib/schemas/aliases.RoboColumnName]github.com/Synthace/antha/stdlib/schemas/aliases.LiquidName':
    [
      'map[github.com/Synthace/antha/stdlib/schemas/aliases.RunName]github.com/Synthace/antha/stdlib/schemas/aliases.MixtureName',
    ],
};

/**
  If the connection is not a direct type match then there are some white listed
  exceptions which may be wired together.
  This function checks whether two types are compatible based on some whitelisted
  pairings which are permitted.
  In particular the interface types "Liquids" and "Plates" may accept various output type formats.

  Permitted Relationships are currently:
  "Plates": ["Plate", "[]Plate"],
  "Liquids": ["Plates", "Liquid", "[]Liquid"],
  "[]Liquid": ["Liquids"],
  "[]Plate": ["Plates"],
*/
function isValidAsymmetricConnectionLegacy(
  inputSideType: string,
  outputSideType: string,
) {
  return permittedTypeBasedConnections[inputSideType]?.includes(outputSideType);
}

export function isValidConnection(
  inputPort: Parameter,
  outputPort: Parameter,
  useTypeConfigurationConnectionInfo: boolean,
) {
  if (inputPort.type === outputPort.type) {
    return true;
  }

  if (!useTypeConfigurationConnectionInfo) {
    // Use Chris' hardcoded method.
    return isValidAsymmetricConnectionLegacy(inputPort.type, outputPort.type);
  }

  return inputPort.configuration?.connections?.allowedTypes?.includes(outputPort.type);
}
