import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export const TitleBlockIcon = React.memo(function TitleBlockIcon(
  props: React.PropsWithChildren<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>>,
) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.144 16.2803H11.6675V12.4893H7.85303V16.2803H6.37061V7.74902H7.85303V11.2998H11.6675V7.74902H13.144V16.2803Z"
          fill="currentColor"
        />
        <path
          d="M17.6288 16.2803H16.2108V9.43066L14.119 10.1455V8.94434L17.4472 7.71973H17.6288V16.2803Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.125 4.3125H3.875V19.6875H20.125V4.3125ZM3.875 2.3125C2.77043 2.3125 1.875 3.20793 1.875 4.3125V19.6875C1.875 20.7921 2.77043 21.6875 3.875 21.6875H20.125C21.2296 21.6875 22.125 20.7921 22.125 19.6875V4.3125C22.125 3.20793 21.2296 2.3125 20.125 2.3125H3.875Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
