import React from 'react';

import ExperimentsList from 'client/app/apps/experiments/ExperimentsList';
import { ExperimentsNavigation } from 'client/app/apps/experiments/ExperimentsNavigation';

export default function ExperimentsScreen() {
  return (
    <ExperimentsNavigation title="Experiments">
      <ExperimentsList />
    </ExperimentsNavigation>
  );
}
