import React, { useMemo, useRef } from 'react';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  getSmallDeckPositionTooltipStyles,
  SMALL_DECK_POSITION_SIZE,
  useDeckPositionTooltip,
} from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import {
  formatLabwareTypeName,
  getLabwareIcon,
  LabwareType,
} from 'client/app/state/LabwarePreference';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { Dimensions } from 'common/types/Dimensions';
import { Position2d } from 'common/types/Position';
import Colors from 'common/ui/Colors';
import Popover from 'common/ui/components/Popover';
import { DeckPositionRect } from 'common/ui/components/simulation-details/mix/DeckLayout';
import { isDrag } from 'common/ui/lib/ClickRecognizer';

type Props = {
  deckPosition: DeckPositionRect;
  selectedIndex: number;
  count: number;
  disabled: boolean;
  selected: boolean;
  onSelect: (id: string) => void;
};

export default function MovePlatePosition({
  deckPosition,
  selectedIndex,
  count,
  disabled,
  selected,
  onSelect,
}: Props) {
  const labwarePreferences = useLabwarePreferences(deckPosition);

  const { deckPositionName, absolutePosInDeckPixels } = deckPosition;

  const showPositionNumber = selected && count > 1;
  const {
    isSmallDeckPosition,
    isTooltipOpen,
    deckPositionEvents,
    smallDeckPositionEvents,
  } = useDeckPositionHandle(deckPosition, disabled, onSelect);

  const contentSection = (
    <Section selected={selected}>
      {selected && <SuccessIcon positionDimensions={absolutePosInDeckPixels} />}
    </Section>
  );

  return (
    <SingleDeckPosition style={absolutePosInDeckPixels}>
      {isSmallDeckPosition ? (
        <Popover
          open={isTooltipOpen}
          placement="bottom-start"
          componentsProps={{
            tooltip: {
              sx: getSmallDeckPositionTooltipStyles(!disabled),
            },
          }}
          title={
            <Stack gap={4}>
              {disabled && (
                <Stack>
                  {labwarePreferences.map(labware => (
                    <LabwareChip key={deckPosition.deckPositionName} labware={labware} />
                  ))}
                </Stack>
              )}
              <PositionBadge disabled={disabled} title={deckPositionName}>
                {deckPositionName}
              </PositionBadge>
              {selected && (
                <PositionOrderNumber>
                  {selectedIndex} / {count}
                </PositionOrderNumber>
              )}
            </Stack>
          }
        >
          <Main
            disabled={disabled}
            selected={selected}
            small
            {...smallDeckPositionEvents}
          >
            {contentSection}
          </Main>
        </Popover>
      ) : (
        <Main disabled={disabled} selected={selected} {...deckPositionEvents}>
          {disabled && (
            <Header>
              {labwarePreferences.map(labware => (
                <LabwareChip key={deckPosition.deckPositionName} labware={labware} />
              ))}
            </Header>
          )}
          {contentSection}
          <Footer>
            <PositionBadge disabled={disabled} title={deckPositionName}>
              {deckPositionName}
            </PositionBadge>
            {showPositionNumber && (
              <PositionOrderNumber>
                {selectedIndex} / {count}
              </PositionOrderNumber>
            )}
          </Footer>
        </Main>
      )}
    </SingleDeckPosition>
  );
}

function useLabwarePreferences(deckPosition: DeckPositionRect) {
  const addedOrder = useWorkflowBuilderSelector(
    state => state.labwarePreferencesAddedOrder,
  );
  const labwarePreferences = addedOrder[deckPosition.deckPositionName];
  return useMemo(
    () => (labwarePreferences ? [...labwarePreferences] : []),
    [labwarePreferences],
  );
}

/**
 * Users should be able to drag into their desired positions without any odd
 * behaviour such as selecting a position while dragging.
 * So we block the position selection when user's intent was just to drag the view.
 *
 * Also the tooltip for small deck positions should not be visible during dragging or scrolling
 */
function useDeckPositionHandle(
  { deckPositionName, absolutePosInDeckPixels }: DeckPositionRect,
  disabled: boolean,
  onSelect: (p: string) => void,
) {
  const isSmallDeckPosition =
    Math.min(absolutePosInDeckPixels.width, absolutePosInDeckPixels.height) <=
    SMALL_DECK_POSITION_SIZE;

  const tooltipHandle = useDeckPositionTooltip();

  const pointerPositionRef = useRef<Position2d | null>(null);
  const setPointerPosition = (event: React.PointerEvent) => {
    pointerPositionRef.current = { x: event.clientX, y: event.clientY };
  };
  const selectPosition = (event: React.PointerEvent) => {
    if (!disabled && !isDrag(pointerPositionRef.current, event)) {
      onSelect(deckPositionName);
    }
  };

  return {
    isSmallDeckPosition,
    isTooltipOpen: tooltipHandle.open,
    deckPositionEvents: {
      onPointerDown: setPointerPosition,
      onPointerUp: selectPosition,
    },
    smallDeckPositionEvents: {
      onPointerDown: (event: React.PointerEvent) => {
        setPointerPosition(event);
        tooltipHandle.hide();
        tooltipHandle.lock();
      },
      onPointerUp: (event: React.PointerEvent) => {
        selectPosition(event);
        tooltipHandle.unlock();
      },
      onMouseEnter: tooltipHandle.show,
      onMouseLeave: tooltipHandle.hide,
      onWheel: tooltipHandle.hideThrottled,
    },
  };
}

function LabwareChip({ labware }: { labware: LabwareType }) {
  return (
    <LabwareChipContainer
      avatar={getLabwareIcon(labware)}
      label={
        <Typography variant="body2" noWrap>
          {formatLabwareTypeName(labware)}
        </Typography>
      }
    />
  );
}

// #region Styles

const SingleDeckPosition = styled('div')({
  position: 'absolute',
  userSelect: 'none',
});

const Main = styled('main', {
  shouldForwardProp: prop =>
    !(['disabled', 'selected', 'small'] as PropertyKey[]).includes(prop),
})<{ disabled: boolean; selected: boolean; small?: boolean }>(
  ({ theme: { palette }, disabled, selected, small }) => {
    const disabledStyle = disabled
      ? {
          cursor: 'initial',
          color: palette.text.disabled,
          background: `linear-gradient(to top left,
            ${Colors.GREY_5} 0%,
            ${Colors.GREY_5} calc(50% - 0.8px),
            ${Colors.GREY_30} 50%,
            ${Colors.GREY_5} calc(50% + 0.8px),
            ${Colors.GREY_5} 100%)`,
          border: `1px solid ${Colors.GREY_20}`,

          '&:hover': {
            zIndex: 1,
          },
        }
      : null;

    const selectedStyle = selected
      ? {
          border: `2px solid ${palette.primary.main}`,
        }
      : null;

    const smallStyle = small
      ? {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }
      : null;

    return {
      display: 'grid',
      gridTemplateRows: 'minmax(50px, auto) 1fr 50px',

      color: palette.text.primary,
      backgroundColor: Colors.GREY_0,
      border: '0.5px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '6px',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',

      position: 'relative',
      height: '100%',

      cursor: 'pointer',

      '&:hover': {
        zIndex: 1,
        background: Colors.BLUE_0,
        border: `2px solid ${palette.primary.main}`,
      },

      ...disabledStyle,
      ...selectedStyle,
      ...smallStyle,
    };
  },
);

const Header = styled('header')(({ theme }) => {
  return {
    gridRow: 1,
    alignSelf: 'start',

    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5, 0, 0, 5),
  };
});

const Footer = styled('footer')(({ theme }) => ({
  gridRow: 3,
  alignSelf: 'end',

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 5, 5),
}));

const Section = styled('section', {
  shouldForwardProp: prop => prop !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  gridRow: 2,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 500,
  fontSize: '24px',
  letterSpacing: '0.3px',
  textTransform: 'uppercase',

  color: selected ? theme.palette.text.secondary : 'inherit',
}));

const PositionBadge = styled('span', {
  shouldForwardProp: prop => prop !== 'disabled',
})<{ disabled: boolean }>(({ theme, disabled }) => {
  return {
    padding: theme.spacing(2, 3),

    color: theme.palette.text.secondary,
    backgroundColor: disabled ? Colors.GREY_20 : Colors.GREY_10,
    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '4px',

    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing: '0.1px',

    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',

    'main:hover > footer &': {
      zIndex: 1,
      overflow: 'visible',
      maxWidth: 'fit-content',
      backgroundColor: disabled ? Colors.GREY_20 : Colors.BLUE_5,
    },
  };
});

const PositionOrderNumber = styled('span')(({ theme }) => ({
  alignSelf: 'end',
  color: theme.palette.text.secondary,
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  marginRight: theme.spacing(3),
  whiteSpace: 'nowrap',
}));

const SuccessIcon = styled(CheckCircleRoundedIcon, {
  shouldForwardProp: prop => prop !== 'positionDimensions',
})<{ positionDimensions: Dimensions }>(
  ({ theme, positionDimensions: { width, height } }) => {
    const minDimension = Math.min(width, height);
    return {
      color: Colors.SUCCESS_MAIN,
      fontSize:
        minDimension > SMALL_DECK_POSITION_SIZE
          ? 44
          : minDimension > SMALL_DECK_POSITION_SIZE / 2
          ? 32
          : 16,

      'main:hover &': {
        color: theme.palette.primary.main,
      },
    };
  },
);

const LabwareChipContainer = styled(Chip)(({ theme }) => {
  return {
    color: theme.palette.text.disabled,
    backgroundColor: Colors.GREY_0,

    width: 'fit-content',
    maxWidth: 'fit-content',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),

    border: `1px solid ${Colors.GREY_30}`,
    '&:last-child': {
      marginBottom: 'initial',
    },

    '.MuiChip-avatar': {
      color: 'inherit',
      marginLeft: 0,
      marginRight: 5,
    },
    '.MuiChip-label': {
      padding: 0,
    },
  };
});

// #endregion
