import { v4 as uuid } from 'uuid';

/**
 * Structure of the DOE Double Map parameter when in DOE mode.
 */
export type DoubleMapParameterValue = {
  /**
   * Constants, stored as component name-value dict.
   */
  mapConstant: { anthaType: string; value: Record<string, string> };
  /**
   * Value factors, stored as a list of value for each component name.
   */
  mapSingleConstant: { anthaType: string; value: Record<string, string[]> };
  /**
   * Name & Value factors, stored as component-value pair for each factor
   * name.
   */
  mapSingleVariable: {
    anthaType: string;
    value: Record<string, Record<string, string>>;
  };
  /**
   * Set of Names & Values factor, stored as the value. There can be only one of these
   * factors.
   */
  mapMultiComponent: { anthaType: string; value: Record<string, string>[] };
};

export type DoubleMapRules = { constants: Constant[]; factors: Factor[] };

type DoubleMapRuleBase = {
  /**
   * A unique ID generated just for use within the UI (e.g. for react keys). It does not
   * get stored in the parameter value.
   */
  id: string;
};

/**
 * A constant defines a value to use in every DOE run for a given component name. A
 * constant is not considered to be 'factor'.
 */
export type Constant = DoubleMapRuleBase & {
  ruleType: 'constant';
  componentName: string;
  value: string;
};

/**
 * A factor defines multiple values for a given component name. One of the values will be
 * chosen in every DOE run, as determined by the DOE design. There are multiple kinds of
 * factor rules.
 */
export type Factor = ValueFactor | NameValueFactor | NameValueSetFactor;

export enum FactorType {
  VALUE = 'Name',
  NAMEVALUE = 'NameValue',
  NAMEVALUESET = 'NameValueSet',
}

/**
 * A Value factor is the simplest kind of factor. A list of values can be specified for
 * each component name.
 */
export type ValueFactor = DoubleMapRuleBase & {
  ruleType: 'factor';
  factorType: FactorType.VALUE;
  componentName: string;
  value: string[];
};

/**
 * A Name & Value factor represents multiple component to value pairs. E.g. DTT @ 1mM and
 * EDTA @ 1mM.
 */
export type NameValueFactor = DoubleMapRuleBase & {
  ruleType: 'factor';
  factorType: FactorType.NAMEVALUE;
  factorName: string;
  valueByComponentName: Record<string, string>;
};

/**
 * A Set of Names & Values factor represents different combinations of component-value
 * pairs. For example:
 *
 * ```
 * Set 1:                  | Set 2:
 * Gin @ 25 ul             | Rye Whiskey @ 50 ul
 * Sweet Vermouth @ 25 ul  | Sweet Vermouth @ 25 ul
 * Campari @ 25 ul         | Bitters @ 5 ul
 * ```
 *
 * At most one of this type may exist.
 */
export type NameValueSetFactor = DoubleMapRuleBase & {
  ruleType: 'factor';
  factorType: FactorType.NAMEVALUESET;
  valueByComponentNames: Record<string, string>[];
};

/**
 * The parameter value object is awkward to use, so we convert it to a list of constants
 * and factors. Each is given a unique ID, which can be used for react keys.
 */
export function parseDoubleMapValue({
  mapConstant,
  mapSingleConstant,
  mapSingleVariable,
  mapMultiComponent,
}: DoubleMapParameterValue): DoubleMapRules {
  const constants: Constant[] = [];
  for (const [componentName, value] of Object.entries(mapConstant.value)) {
    constants.push({
      id: uuid(),
      ruleType: 'constant',
      componentName,
      value,
    });
  }
  const factors: Factor[] = [];
  for (const [componentName, value] of Object.entries(mapSingleConstant.value)) {
    factors.push({
      id: uuid(),
      ruleType: 'factor',
      factorType: FactorType.VALUE,
      componentName,
      value,
    });
  }
  for (const [factorName, valueByComponentName] of Object.entries(
    mapSingleVariable.value,
  )) {
    factors.push({
      id: uuid(),
      ruleType: 'factor',
      factorType: FactorType.NAMEVALUE,
      factorName,
      valueByComponentName,
    });
  }
  // There can be at most one multi component factor.
  if (mapMultiComponent.value.length > 0) {
    factors.push({
      id: uuid(),
      ruleType: 'factor',
      factorType: FactorType.NAMEVALUESET,
      valueByComponentNames: mapMultiComponent.value,
    });
  }
  return { constants, factors };
}

export function formatDoubleMapValue(
  rules: DoubleMapRules,
  primaryKeyType: string,
  secondaryKeyType: string,
  valueType: string,
): DoubleMapParameterValue {
  const paramValue: DoubleMapParameterValue = {
    mapConstant: {
      anthaType: getConstantsType(secondaryKeyType, valueType),
      value: {},
    },
    mapSingleConstant: {
      anthaType: getValuesFactorType(secondaryKeyType, valueType),
      value: {},
    },
    mapSingleVariable: {
      anthaType: getNameValueFactorsType(primaryKeyType, secondaryKeyType, valueType),
      value: {},
    },
    mapMultiComponent: {
      anthaType: getNameValueSetFactorType(secondaryKeyType, valueType),
      value: [],
    },
  };
  for (const constant of rules.constants) {
    paramValue.mapConstant.value[constant.componentName] = constant.value;
  }
  for (const factor of rules.factors) {
    switch (factor.factorType) {
      case FactorType.VALUE:
        paramValue.mapSingleConstant.value[factor.componentName] = factor.value;
        break;
      case FactorType.NAMEVALUE:
        paramValue.mapSingleVariable.value[factor.factorName] =
          factor.valueByComponentName;
        break;
      case FactorType.NAMEVALUESET:
        paramValue.mapMultiComponent.value = factor.valueByComponentNames;
        break;
    }
  }
  return paramValue;
}

/**
 * Used for displaying each factor name in the tooltip summary
 */
export function getFactorName(factor: Factor, nameValueSetFactorName: string): string {
  switch (factor.factorType) {
    case FactorType.VALUE:
      return factor.componentName;
    case FactorType.NAMEVALUE:
      return factor.factorName;
    case FactorType.NAMEVALUESET:
      return nameValueSetFactorName;
  }
}

function getConstantsType(secondaryKeyType: string, valueType: string): string {
  return `map[${secondaryKeyType}]${valueType}`;
}

export function getValuesType(valueType: string): string {
  return `[]${valueType}`;
}

function getValuesFactorType(secondaryKeyType: string, valueType: string): string {
  return `map[${secondaryKeyType}][]${valueType}`;
}

export function getNameValueFactorType(
  secondaryKeyType: string,
  valueType: string,
): string {
  return `map[${secondaryKeyType}]${valueType}`;
}

function getNameValueFactorsType(
  primaryKeyType: string,
  secondaryKeyType: string,
  valueType: string,
): string {
  return `map[${primaryKeyType}]${getNameValueFactorType(secondaryKeyType, valueType)}`;
}

export function getNameValueSetFactorType(
  secondaryKeyType: string,
  valueType: string,
): string {
  return `[]map[${secondaryKeyType}]${valueType}`;
}
