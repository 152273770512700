import React, { useMemo } from 'react';

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import {
  countActiveFactors,
  matchFactorPath,
} from 'client/app/components/Parameters/doeUtils';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { pluralize } from 'common/lib/format';
import { FactorItem, Parameter } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  parameter: Parameter;
  instanceName: string;
  workflowName?: string;
  factors?: FactorItem[];
};

const NO_FACTORS: FactorItem[] = [];

export default function FactorEditorButton({
  parameter,
  factors: allFactors = NO_FACTORS,
  workflowName = '',
  instanceName,
}: Props) {
  const classes = useStyles();
  const selectedFactorParameter = useWorkflowBuilderSelector(
    state => state.factorEditing.selectedFactorParameter,
  );

  const factors = useMemo(
    () =>
      allFactors.filter(f =>
        matchFactorPath(f, [workflowName, instanceName, parameter.name]),
      ),
    [allFactors, instanceName, parameter.name, workflowName],
  );
  const buttonCopy = buildBtnCopy(factors);

  const dispatch = useWorkflowBuilderDispatch();
  const handleClick = () => {
    dispatch({
      type: 'toggleFactorEditing',
      payload: {
        selectedFactorElement: instanceName,
        selectedFactorParameter: parameter.name,
      },
    });
  };

  return (
    <Button
      className={cx(classes.toggleFactorEditingBtn, {
        [classes.active]: selectedFactorParameter === parameter.name,
      })}
      variant="secondary"
      onClick={handleClick}
      startIcon={<ChevronLeftOutlinedIcon />}
    >
      <Typography className={classes.buttonText} variant="body2">
        {buttonCopy}
      </Typography>
    </Button>
  );
}

function buildBtnCopy(factors: FactorItem[]): string {
  if (factors.length === 0) {
    return 'No factor';
  }
  if (factors[0].path?.length === 3) {
    return 'Factorised'; // isSimpleFactor
  }
  return pluralize(countActiveFactors(factors), 'factor');
}

const useStyles = makeStylesHook(({ palette }) => ({
  toggleFactorEditingBtn: {
    width: '100%',
    justifyContent: 'center',
  },
  buttonText: {
    textTransform: 'uppercase',
  },
  active: {
    background: Colors.BLUE_5,
    borderColor: palette.primary.main,
    color: palette.primary.main,
  },
}));
