import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const OutputPlateIcon = React.memo<SvgIconProps>(function OutputPlateIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M22.998 9l-4-3v6l4-3z" fill="currentColor" />
        <path
          d="M14.999 8.2v1.6h4V8.2h-4zM9 10.25a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zM13.75 10.25a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zM9 14.5a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zM13.75 14.5a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zM18.5 14.5a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z"
          fill="currentColor"
        />
        <path
          d="M4 5a3 3 0 00-3 3v9a3 3 0 003 3h16a3 3 0 003-3v-4h-2v4a1 1 0 01-1 1H4a1 1 0 01-1-1V8a1 1 0 011-1h11V5H4z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
