import { groupBy } from 'common/lib/data';
import { formatWellPosition } from 'common/lib/format';
import { Tipbox, WellLocationOnDeckItem } from 'common/types/mix';
import { findDeckItemIndex } from 'common/ui/components/simulation-details/mix/deckContents';
import {
  DeckItemState,
  DeckItemStateCommonProps,
} from 'common/ui/components/simulation-details/mix/MixState';

// The display state of a tipbox. This is needed so that we can display
// an accurate representation of which tips are left in the tip box at any
// given step.
// The state has to be computed based on the initial state of the Tipbox,
// and a series of 'load' or 'drop' actions, which pick up and drop tips.
// Tips can be dropped either in the tipwaste, or put back into the tipbox
// for reuse.

export type TipboxState = {
  readonly contents: TipboxContentsMatrix;
  // Track how many times the tipbox has been replaced so far. This is useful
  // to show in the UI.
  readonly tipboxReplacedAtSteps: number[];
} & Tipbox &
  DeckItemStateCommonProps;

// First index is column, second index is row
export type TipboxContentsMatrix = readonly (readonly TipState[])[];

export type TipState = {
  // - If null, the tip has never been picked up
  // - If the tip has been picked up, stores the step when it was picked up
  readonly usedAtStep: number | null;
};

// Init the UI state of a tipbox based on backend representation of the tipbox
export function initTipbox(tipbox: Tipbox, deckPositionName: string): TipboxState {
  const tipboxContents = Array.from({ length: tipbox.columns }, () =>
    Array.from({ length: tipbox.rows }, () => ({
      // A tip that hasn't been used yet
      usedAtStep: null,
    })),
  );
  return {
    ...tipbox,
    contents: tipboxContents,
    tipboxReplacedAtSteps: [],
    currentDeckPositionName: deckPositionName,
    currentRotationDegrees: 0,
  };
}

// Return state of a tip in a given tipbox
export function getTipState(tipbox: TipboxState, col: number, row: number): TipState {
  let tip: TipState | undefined;
  const column = tipbox.contents[col];
  if (column) {
    tip = column[row];
  }
  if (!tip) {
    throw new Error(
      `Trying to access a tip that's not present in tipbbox ${
        tipbox.name
      }: ${formatWellPosition(row, col)}`,
    );
  }
  return tip;
}

// Update the display state of tipboxes
// The step picks up several tips, potentially from several different tipboxes
// (but in practice it always takes all tips from one box) so we want to record
// the fact the tips have been used.
export function markTipsUsed(
  deckItems: readonly DeckItemState[],
  tipLocations: readonly WellLocationOnDeckItem[],
  currentStep: number,
): readonly DeckItemState[] {
  if (tipLocations.length === 0) {
    return deckItems;
  }

  // On most robots all channels will be from the same tipbox (deck_item_id),
  // so this object will have just one entry
  const locationsForTipbox = groupBy(tipLocations, 'deck_item_id');
  const tipbboxIds = Object.keys(locationsForTipbox);
  const newDeckItems = [...deckItems];

  for (const tipboxId of tipbboxIds) {
    const tipboxIndex = findDeckItemIndex(deckItems, tipboxId);
    const tipbox = deckItems[tipboxIndex];

    if (tipbox.kind !== 'tipbox') {
      // There are some very old job that used to have a bug:
      // Try loading tips from a tip waste. People still need to look at these
      // historical jobs, mostly just to access the bundle. Ingore this buggy
      // action, and don't crash.
      console.warn(
        `Expected deck item ${tipbox.name} to be a tipbox, but it is a ${tipbox.kind}.`,
      );
      continue;
    }

    const locationsUsedInTipbox = locationsForTipbox[tipboxId];
    newDeckItems[tipboxIndex] = _markTipsUsedAtStep(
      tipbox,
      locationsUsedInTipbox,
      currentStep,
    );
  }

  return newDeckItems;
}

/**
 * Refresh tip boxes by ids and return refreshed state of changed tip boxes.
 *
 * @param deckItems state of a deck
 * @param tipboxIds ids of tip boxes that need to be refreshed
 * @param currentStep step number in which this refresh appeared
 */
export function refreshTipboxes(
  deckItems: readonly DeckItemState[],
  tipboxIds: readonly string[],
  currentStep: number,
): readonly DeckItemState[] {
  const newDeckItems = [...deckItems];

  for (const tipboxId of tipboxIds) {
    const tipboxIndex = findDeckItemIndex(deckItems, tipboxId);
    const tipbox = newDeckItems[tipboxIndex];

    if (tipbox.kind !== 'tipbox') {
      throw new Error(
        `Expected deck item ${tipbox.name} to be a tipbox, but it is a ${tipbox.kind}.`,
      );
    }

    const freshTipbox = {
      ...initTipbox(tipbox, tipbox.currentDeckPositionName),
      // Track how many times the tipbox has been replaced so far. This is
      // useful to show in the UI.
      tipboxReplacedAtSteps: [...tipbox.tipboxReplacedAtSteps, currentStep],
    };
    newDeckItems[tipboxIndex] = freshTipbox;
  }

  return newDeckItems;
}

// Update state of tips inside a specific tipbox.
// Use structural sharing (do the minimum amount of copying).
function _markTipsUsedAtStep(
  tipbox: TipboxState,
  tipLocations: readonly WellLocationOnDeckItem[],
  currentStep: number,
): TipboxState {
  const newMatrix = [...tipbox.contents];
  for (const loc of tipLocations) {
    const { col, row } = loc;
    const existingCol = newMatrix[col];
    if (!existingCol) {
      throw new Error(
        `Trying to update a tip that's not present in tipbbox ${
          tipbox.name
        }: ${formatWellPosition(row, col)}`,
      );
    } else {
      const newCol = [...existingCol];
      newCol[row] = { usedAtStep: currentStep };
      newMatrix[col] = newCol;
    }
  }
  return { ...tipbox, contents: newMatrix };
}
