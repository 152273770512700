import React, { useCallback, useMemo, useState } from 'react';

import Button from '@mui/material/Button';

import { CherryPickElementSchema } from 'client/app/apps/cherry-picker/CherryPickApi';
import { ParameterMode } from 'client/app/apps/cherry-picker/CherryPickLayout';
import CherryPickerEditorDialog from 'client/app/components/Parameters/CherryPicker/CherryPickerEditorDialog';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import CherryPickerIcon from 'common/ui/icons/CherryPickerIcon';

type Props = {
  onChange: (transferData: CherryPickElementSchema) => void;
} & ParameterEditorBaseProps<CherryPickElementSchema>;

export default function CherryPickerEditor(props: Props) {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { onChange, value, isDisabled } = props;

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleClose = useCallback(
    (transferData: CherryPickElementSchema) => {
      onChange(transferData);
      setDialogOpen(false);
    },
    [onChange],
  );

  const parameterMode: ParameterMode = useMemo(
    () => ({
      onCancel: setDialogOpen,
      transferData: value,
      onUpdateTransferData: handleClose,
    }),
    [handleClose, value],
  );

  return (
    <>
      <Button
        color="inherit"
        component="span"
        onClick={handleOpenDialog}
        disabled={isDisabled}
      >
        <CherryPickerIcon />
        <span className={classes.btnCaption}>Edit with Cherry Picker</span>
      </Button>
      {dialogOpen && <CherryPickerEditorDialog parameterMode={parameterMode} />}
    </>
  );
}

const useStyles = makeStylesHook({
  btnCaption: {
    marginLeft: '12px',
  },
});
