import React, { useCallback, useEffect, useMemo, useState } from 'react';

import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import groupBy from 'lodash/groupBy';
import xor from 'lodash/xor';

import BasePlateContentsEditorWellGroupListItem from 'client/app/components/Parameters/PlateContents/BasePlateContentsEditorWellGroupListItem';
import { WellParametersProps } from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import { formatWellRange, pluralizeWord } from 'common/lib/format';
import { filterMap, getFirstValue } from 'common/object';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export const EMPTY_WELL_GROUP_ID = Symbol('EMPTY');

export type BasePlateContentsEditorWellGroupListProps<T> = {
  /**
   * Contents of each well, indexed by well address (e.g. A1, B1)
   */
  contentsByWell: Map<string, T>;
  /**
   * List of wells that the user has currently selected. Summaries will only be
   * shown about selected wells, unless no wells are selected, in which case a
   * summary of all wells will be shown.
   */
  selectedWells: string[];
  plateName?: string;
  /**
   * Wells are grouped on the right hand side of the dialog, such that all wells
   * in a group have the same contents. This function should return a string
   * which determines the group the well should be a part of.
   */
  groupID: (content: T | undefined) => string | typeof EMPTY_WELL_GROUP_ID;
  /**
   * This function should return a title (a human-readable summary of the
   * contents of the well) and color for the group. The colour is used for the
   * well icon next to each list item and should be consistent with the wells on
   * the plate.
   */
  groupProps: (
    content: T | undefined,
    wellsInGroup: string[],
  ) => { title: string; subtitle?: string; color: string };
  /**
   * Function to generate initial well contents when the user starts configuring
   * empty wells.
   */
  generateWellContents: (
    wellLocations: string[],
    contentsByWell: Map<string, T>,
    contentsToCopy?: T,
  ) => Map<string, T>;
  /**
   * Callback which generates the component on the right hand side of the
   * dialog, which should contain fields for modifying currently selected wells.
   */
  wellParameters: (params: WellParametersProps<T>) => JSX.Element;
  onChange: (newContentsByWell: Map<string, T>) => void;
  onSelectionChange: (locations: string[]) => void;
  /**
   * Disables user input (e.g. deleting groups). The user can still click on
   * summaries to view well contents.
   */
  isDisabled?: boolean;
};

/**
 * A group of well which have identical contents, and can therefore be collapsed
 * into a single list item.
 */
export type WellGroup<T> = {
  id: string;
  contentsByWell: Map<string, T>;
  color: string;
  title: string;
  subtitle?: string;
  isEmpty?: boolean;
};

/**
 * List of well contents on the plate, grouped such that all wells with
 * identical contents are in the same group.
 */
export default function BasePlateContentsEditorWellGroupList<T>({
  contentsByWell,
  selectedWells,
  groupID,
  groupProps,
  wellParameters,
  isDisabled,
  onChange,
  onSelectionChange,
  generateWellContents,
  plateName,
}: BasePlateContentsEditorWellGroupListProps<T>) {
  // Group wells by their contents,
  const [groups, setGroups] = useState<WellGroup<T>[]>([]);
  const [editingGroupId, setEditingGroupId] = useState<string | undefined>();

  // Get the selected well addresses which have no well contents
  const selectedEmptyWells = useMemo<string[]>(
    () => selectedWells.filter(wellLocation => !contentsByWell.has(wellLocation)),
    [contentsByWell, selectedWells],
  );

  // If the selection changes then check that the selection matches the wells in the
  // group. If it does not then stop editing the group.
  useEffect(() => {
    setEditingGroupId(groupId => {
      const group = groups.find(group => group.id === groupId);
      if (!group) {
        return undefined;
      }
      const groupSelectionDiff = xor(selectedWells, [...group.contentsByWell.keys()]);
      return groupSelectionDiff.length === 0 ? groupId : undefined;
    });
  }, [groups, selectedWells]);

  // Re-group wells:
  // * on initialisation
  // * when well contents change
  // * when selected wells change (this handles case where cancel is pressed or
  //   user has selected something)
  useEffect(() => {
    // Take the Map (wellLocation => wellContents) and subdivide using the
    // groupID prop.
    const groups = Object.values(
      groupBy([...contentsByWell], ([_, wellContents]) => groupID(wellContents)),
    )
      // For each of the groups of wells, generate the group title, color, etc.
      .map((wellLocationContentPairs): WellGroup<T> => {
        const groupContentsByWell = new Map(wellLocationContentPairs);
        const wellsInGroup = [...groupContentsByWell.keys()];
        // Get any well's contents from within the group (doesn't matter which,
        // since they should all be the same). This will be used to generate
        // title and color of the group.
        const wellContents = groupContentsByWell.get(wellsInGroup[0]);
        const { title, color, subtitle } = groupProps(wellContents, wellsInGroup);

        // Generate a unique react key for this group by concatenating the group
        // title and well locations within the group. This means that when the
        // content is changed, the list will re-render.
        //
        // We could just use title, and that will initially work. However, if you
        // added or removed wells from the group then the list will not update
        // because the title is still the same.
        //
        // Therefore we also concatenate each well location in the group, so that
        // when adding to the group the key will change and the list will update.
        const id = [plateName ?? '', title, ...wellsInGroup].join('');

        return {
          id,
          contentsByWell: groupContentsByWell,
          color,
          title,
          subtitle,
        };
      });

    // Show an entry indicating the empty wells the user has selected. When this
    // is pressed, the user can set up new contents in those wells. The inputs
    // will be pre-filled with the output from generateWellContents.
    if (selectedEmptyWells.length > 0 && !isDisabled) {
      groups.push({
        ...groupProps(undefined, selectedEmptyWells),
        id: 'empty-' + selectedEmptyWells.join(''),
        contentsByWell: generateWellContents(selectedEmptyWells, contentsByWell),
        isEmpty: true,
      });
    }

    setGroups(groups);
  }, [
    contentsByWell,
    selectedWells,
    groupProps,
    groupID,
    selectedEmptyWells,
    generateWellContents,
    isDisabled,
    plateName,
  ]);

  // Figure out which groups are included in the user's selection. If nothing is
  // selected, return undefined; the dialog will show a summary of all plate
  // contents.
  const selectedGroups = useMemo<WellGroup<T>[] | undefined>(
    () =>
      selectedWells.length > 0
        ? // Get all groups which have at least one selected well
          groups.filter(group =>
            selectedWells.some(wellLocation => group.contentsByWell.has(wellLocation)),
          )
        : undefined,
    [groups, selectedWells],
  );

  const handleDelete = useCallback(
    (deletedWells: Set<string>) =>
      onChange(
        filterMap(contentsByWell, wellLocation => !deletedWells.has(wellLocation)),
      ),
    [contentsByWell, onChange],
  );

  // If the user has selected a subset of a single group, then allow the user to
  // split those wells from the group and edit them.
  const contentsForGroupSubset = useMemo<Map<string, T> | undefined>(() => {
    if (
      selectedGroups?.length === 1 &&
      selectedWells.length < selectedGroups[0].contentsByWell.size &&
      selectedEmptyWells.length === 0
    ) {
      return filterMap(selectedGroups[0].contentsByWell, wellLocation =>
        selectedWells.includes(wellLocation),
      );
    }
    return undefined;
  }, [selectedEmptyWells, selectedGroups, selectedWells]);

  const handleSave = useCallback(
    (changedContentsByWell: Map<string, T>) => {
      setEditingGroupId(undefined);
      onChange(new Map([...contentsByWell, ...changedContentsByWell]));
    },
    [onChange, contentsByWell],
  );

  // When user clicks cancel in the editor, reset the selection. This will also
  // cause the parameters editor to close, bringing the user back to the plate
  // summary.
  const handleCancel = useCallback(() => {
    setEditingGroupId(undefined);
    onSelectionChange([]);
  }, [onSelectionChange]);

  const handleReplicate = useCallback(() => {
    // Pick the first well of the selected group to copy. The replicate button
    // only appears when one group is selected, so we can assume selectedGroups
    // has just one element.
    const contentsToCopy =
      selectedGroups && getFirstValue(selectedGroups[0].contentsByWell);
    onChange(
      new Map([
        ...contentsByWell,
        ...generateWellContents(selectedEmptyWells, contentsByWell, contentsToCopy),
      ]),
    );
  }, [
    contentsByWell,
    generateWellContents,
    onChange,
    selectedEmptyWells,
    selectedGroups,
  ]);

  // When the user starts editing a group of wells, select all wells of that
  // group on the plate.
  const handleEditGroup = useCallback(
    (groupId: string) => {
      const group = groups.find(group => group.id === groupId);
      if (!group) {
        return;
      }
      setEditingGroupId(groupId);
      onSelectionChange([...group.contentsByWell.keys()]);
    },
    [groups, onSelectionChange],
  );

  const handleDeleteGroupSubset = useCallback(
    () =>
      onChange(
        filterMap(contentsByWell, wellLocation => !selectedWells.includes(wellLocation)),
      ),
    [contentsByWell, onChange, selectedWells],
  );

  // If the user has selected a group with content and a group of empty wells, allow them
  // to copy the contents of the group into the empty wells.
  const showReplicate = selectedEmptyWells.length > 0 && selectedGroups?.length === 2;

  // When the user has selected a subset of a group, show an Edit button. The
  // edit button shouldn't show when the user has selected an entire group,
  // because clicking it would be the same as clicking the list item itself. The
  // user might be unsure if the buttons do different things.
  const handleEditGroupSubset = useCallback(() => {
    if (!selectedGroups) {
      return;
    }
    const copiedGroup = selectedGroups[0];

    // Split the group into two groups, such that all selected wells are part of
    // the new group
    const existingGroupWithoutSelectedWells: WellGroup<T> = {
      ...copiedGroup,
      contentsByWell: filterMap(
        copiedGroup.contentsByWell,
        wellLocation => !selectedWells.includes(wellLocation),
      ),
    };
    const newGroup: WellGroup<T> = {
      ...copiedGroup,
      id: copiedGroup.id + '-copy',
      contentsByWell: filterMap(copiedGroup.contentsByWell, wellLocation =>
        selectedWells.includes(wellLocation),
      ),
    };
    setGroups(oldGroups => [
      ...oldGroups.filter(group => group !== copiedGroup),
      existingGroupWithoutSelectedWells,
      newGroup,
    ]);
    setEditingGroupId(newGroup.id);
  }, [selectedGroups, selectedWells]);

  const selectedWellsFormatted = useMemo(
    () => formatWellRange(selectedWells),
    [selectedWells],
  );

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography color="textSecondary" variant="h5">
        {selectedWells.length > 0
          ? `${pluralizeWord(selectedWells.length, 'Location')} Selected`
          : 'Plate Summary'}
      </Typography>
      <List dense className={classes.list}>
        {(selectedGroups || groups).map(group => (
          <BasePlateContentsEditorWellGroupListItem
            key={group.id}
            wellGroup={group}
            isDeletable={!isDisabled}
            isReadOnly={isDisabled}
            wellParameters={wellParameters}
            isEditing={group.id === editingGroupId}
            onEdit={handleEditGroup}
            onSave={handleSave}
            onDelete={handleDelete}
            onCancel={handleCancel}
          />
        ))}
        {!isDisabled && showReplicate && (
          <Button variant="secondary" onClick={handleReplicate}>
            Replicate
          </Button>
        )}
        {!isDisabled && contentsForGroupSubset && (
          <>
            <Button variant="secondary" onClick={handleEditGroupSubset}>
              Edit {selectedWellsFormatted}
            </Button>
            <Button variant="secondary" onClick={handleDeleteGroupSubset}>
              Delete {selectedWellsFormatted}
            </Button>
          </>
        )}
      </List>
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: theme.spacing(4),
  },
  list: { marginTop: theme.spacing(3), flex: 1, overflowY: 'auto' },
}));
