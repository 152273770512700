import React, { useCallback, useContext, useMemo, useState } from 'react';

import 'react-markdown-editor-lite/lib/index.css';

import { useMutation } from '@apollo/client';
import { ClassNames } from '@emotion/react';
import { css, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MdEditor from 'react-markdown-editor-lite';

import { TextWithLinks } from 'client/app/apps/experiments/blocks/TextWithLinks';
import { ExperimentDetailContext } from 'client/app/apps/experiments/ExperimentDetailScreen';
import { MUTATION_UPDATE_EXPERIMENT_MODULE } from 'client/app/apps/experiments/gql/mutations';
import { QUERY_EXPERIMENT } from 'client/app/apps/experiments/gql/queries';
import { TextBlock as GraphQLTextBlock } from 'client/app/gql';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { Markdown, renderMarkdownSafe } from 'common/lib/markdown';
import useDebounce from 'common/ui/hooks/useDebounce';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  experimentId: ExperimentId;
  block: GraphQLTextBlock;
};

type EditorProps = {
  value: Markdown;
  onChange: (markdown: Markdown) => void;
};

const EDITOR_STYLE = { minHeight: '200px', maxHeight: '80vh', width: '100%' };
const EDITOR_CONFIG = {
  view: {
    menu: true,
    md: true,
    html: true,
  },
  table: {
    maxRow: 5,
    maxCol: 6,
  },
  syncScrollMode: ['leftFollowRight', 'rightFollowLeft'],
};

function MarkdownEditor({ value, onChange }: EditorProps) {
  const onEditorChange = useCallback(
    (data: { text: string; html: string }) => onChange(data.text as Markdown),
    [onChange],
  );
  const renderHTML = useCallback(
    (text: string) => renderMarkdownSafe(text as Markdown),
    [],
  );

  /* We must explicitly pass in the plugins we want to use for the editor. Not doing this
   * results in multiple instances of our custom nameLink plugin.
   *
   * Note: 'fonts' is an umbrella term for all the default formatting plugins that exist
   * in the editor (e.g 'font-bold', 'font-italic') and we only need to pass this in to
   * include all of them.
   */
  const plugins = [
    'header',
    'fonts',
    'table',
    // Disable image upload until we have a robust way to host files.
    // 'image',
    'link',
    'clear',
    'logger',
    'mode-toggle',
    'full-screen',
  ];

  return (
    <ClassNames>
      {({ css }) => (
        <MdEditor
          value={value}
          renderHTML={renderHTML}
          style={EDITOR_STYLE}
          config={{ ...EDITOR_CONFIG, htmlClass: css(previewCss) }}
          onChange={onEditorChange}
          plugins={plugins}
        />
      )}
    </ClassNames>
  );
}

type PreviewProps = {
  markdown: Markdown;
  className?: string;
};

function MarkdownPreview(props: PreviewProps) {
  const { markdown, className } = props;
  const html = useMemo(() => renderMarkdownSafe(markdown), [markdown]);
  return (
    <Preview
      className={className}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
}

function PlainTextBlock({ block, experimentId }: Props) {
  const { isEditing } = useContext(ExperimentDetailContext);
  const [updateModule] = useMutation(MUTATION_UPDATE_EXPERIMENT_MODULE);

  const [description, setDescription] = useState<string | null>(block.text);

  // Debounce the request so we don't flood the server with mutations as the user types
  const updateBlockDebounced = useDebounce(() => {
    void updateModule({
      variables: { id: block.id, description },
      refetchQueries: [{ query: QUERY_EXPERIMENT, variables: { id: experimentId } }],
    });
  }, 1000);

  const handleDescriptionChange = useTextFieldChange(newDescription => {
    setDescription(newDescription);
    updateBlockDebounced();
  });

  return (
    <>
      {isEditing ? (
        <TextField
          variant="standard"
          value={description ?? ''}
          onChange={handleDescriptionChange}
          placeholder="Share your thoughts..."
          multiline
          fullWidth
        />
      ) : (
        description && (
          <PlainText variant="h6" color="textSecondary">
            <TextWithLinks text={description ?? ''} />
          </PlainText>
        )
      )}
    </>
  );
}

function MarkdownTextBlock({ block, experimentId }: Props) {
  const { isEditing } = useContext(ExperimentDetailContext);

  const [updateModule] = useMutation(MUTATION_UPDATE_EXPERIMENT_MODULE);

  const [description, setDescription] = useState<Markdown | null>(block.text as Markdown);

  // Debounce the request so we don't flood the server with mutations as the user types
  const updateBlockDebounced = useDebounce(() => {
    void updateModule({
      variables: { id: block.id, description },
      refetchQueries: [{ query: QUERY_EXPERIMENT, variables: { id: experimentId } }],
    });
  }, 1000);

  const handleDescriptionChange = useCallback(
    (newDescription: Markdown) => {
      setDescription(newDescription);
      updateBlockDebounced();
    },
    [updateBlockDebounced],
  );

  return (
    <>
      {isEditing ? (
        <MarkdownEditor
          value={(description ?? '') as Markdown}
          onChange={handleDescriptionChange}
        />
      ) : (
        description && <MarkdownPreview markdown={description ?? ('' as Markdown)} />
      )}
    </>
  );
}

export default function TextBlock(props: Props) {
  const isMarkdownEnabled = useFeatureToggle('EXPERIMENT_TEXT_MARKDOWN');

  return isMarkdownEnabled ? (
    <MarkdownTextBlock {...props} />
  ) : (
    <PlainTextBlock {...props} />
  );
}

const previewCss = css`
  font-size: 14px;
  line-height: 1.4;
  & h1 {
    font-size: 32px;
    padding: 0px;
    border: none;
    font-weight: 700;
    margin: 32px 0;
  }

  & h2 {
    font-size: 24px;
    padding: 0px 0;
    border: none;
    font-weight: 700;
    margin: 24px 0;
  }

  & h3 {
    font-size: 18px;
    margin: 18px 0;
    padding: 0px 0;
    border: none;
  }

  & p {
    margin: 8px 0;
  }

  & a {
    color: #0052d9;
  }

  & a:hover {
    text-decoration: none;
  }

  & strong {
    font-weight: 700;
  }

  & ol,
  & ul {
    padding-left: 36px;
  }

  & li {
    margin-bottom: 4px;
  }

  & hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
  }

  & pre {
    display: block;
    background-color: #f5f5f5;
    padding: 20px;
    line-height: 28px;
    border-radius: 0;
    overflow-x: auto;
    word-break: break-word;
  }

  & code {
    background-color: #f5f5f5;
    border-radius: 0;
    padding: 3px 0;
    margin: 0;
    overflow-x: auto;
    word-break: normal;
  }

  & code:after,
  & code:before {
    letter-spacing: 0;
  }

  & blockquote {
    position: relative;
    margin: 16px 0;
    padding: 5px 8px 5px 30px;
    background: none repeat scroll 0 0 rgba(102, 128, 153, 0.05);
    border: none;
    color: #333;
    border-left: 10px solid #d6dbdf;
  }

  & img,
  & video {
    max-width: 100%;
  }

  & table {
    line-height: 1.7;
    max-width: 100%;
    overflow: auto;
    border: 1px solid #f6f6f6;
    border-collapse: collapse;
    border-spacing: 0;
    box-sizing: border-box;
  }

  & table td,
  & table th {
    word-break: break-all;
    word-wrap: break-word;
    white-space: normal;
  }

  & table tr {
    border: 1px solid #efefef;
  }

  & table tr:nth-child(2n) {
    background-color: transparent;
  }

  & table th {
    text-align: center;
    font-weight: 700;
    border: 1px solid #efefef;
    padding: 10px 6px;
    background-color: #f5f7fa;
    word-break: break-word;
  }

  & table td {
    border: 1px solid #efefef;
    text-align: left;
    padding: 10px 15px;
    word-break: break-word;
    min-width: 60px;
  }
`;

const Preview = styled('div')(previewCss);

const PlainText = styled(Typography)({
  whiteSpace: 'pre-wrap',
});
