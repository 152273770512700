import React, { useEffect, useRef, useState } from 'react';

import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type NestedMenuItemProps = MenuItemProps & {
  isParentMenuOpen: boolean;
  label: string;
  leftIcon?: React.ReactElement;
};

/**
 * NestedMenuItem is designed to be used in cases where a nested or sub menu is required.
 * Children should be wrapped in a NestedMenuItem to allow those children to be rendered
 * within a nested menu.
 *
 * NestedMenuItems can be nested within other NestedMenuItems to create deeply nested menus.
 *
 * e.g.
 *
 * <NestedMenuItem label="My Nested Menu Items" isParentMenuOpen={isPopoverOpen}>
 *    <MenuItemWithIcon text="My Nested Item"/>
 *    <NestedMenuItem label="My Nested Nested Menu Items">
 *        <MenuItemWithIcon text="My Nested Nested Item"/>
 *    </NestedMenuItem>
 * </NestedMenuItem>
 */
export default function NestedMenuItem(props: NestedMenuItemProps) {
  const classes = useStyles();
  const { isParentMenuOpen, label, leftIcon } = props;
  const menuItemRef = useRef<HTMLLIElement>(null);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  // We use the mouseenter and mouseleave handlers to detect whether the submenu should be open
  // because these handlers do not bubble up to the parent, and because they simulate a hover event.
  // This works great for pointer devices and in some browsers (e.g. Chrome, iOS Safari) we can
  // also rely on the mouseenter and mouseleave handlers when using touch devices, because those
  // are co-erced into mouseevents. But on some (e.g. Firefox) using touch devices, these are not.
  // Therefore, a workaround is to use a specific onclick handler for touch devices.
  const isTouchScreenDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  const handleMouseEnter = () => {
    setIsSubMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsSubMenuOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsSubMenuOpen(prev => !prev);
  };

  useEffect(() => {
    if (!isParentMenuOpen) {
      setIsSubMenuOpen(false);
    }
  }, [isParentMenuOpen]);

  return (
    <div
      className={classes.container}
      onMouseEnter={isTouchScreenDevice ? undefined : handleMouseEnter}
      onMouseLeave={isTouchScreenDevice ? undefined : handleMouseLeave}
      onClick={isTouchScreenDevice ? handleClick : undefined}
    >
      <MenuItem className={classes.menuItem} ref={menuItemRef}>
        {leftIcon && <ListItemIcon className={classes.listIcon}>{leftIcon}</ListItemIcon>}
        <Typography variant="subtitle2"> {label}</Typography>
        <ArrowRightOutlinedIcon className={classes.arrowIcon} />
      </MenuItem>
      <Menu
        className={classes.menu}
        open={isSubMenuOpen && isParentMenuOpen}
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{ square: false }}
      >
        <div className={classes.menuChildren}>{props.children}</div>
      </Menu>
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  menuChildren: {
    pointerEvents: 'auto',
  },
  // We need to disable mouse events for the menu and menu item, because we are controlling the state of our sub-menu
  // visibility, and style of our items, by capturing events on the container div.
  menu: {
    pointerEvents: 'none',
  },
  menuItem: {
    pointerEvents: 'none',
  },
  listIcon: {
    color: 'inherit',
    // We need to reset the minWidth since ListItemIcon has a default minWidth.
    minWidth: 'auto',
    paddingRight: '8px',
  },
  arrowIcon: {
    marginLeft: 'auto',
  },
  container: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: Colors.GREY_20,
    },
  },
}));

export type NestedMenuItemType<T> = {
  folder: string | undefined;
  item?: T;
  children?: NestedMenuItemType<T>[];
};

/**
 * Organises an array of items into a nested menu structure.
 *
 * @param items List of items to organise. To be organised into nested menus, they
 * should contain a path field (e.g. 'MyFolder/MySubFolder/MySubSubFolder'); If no path
 * is specified, we assume these are top-level.
 *
 * The path must be a desired nesting structure, separated by forward slashes.
 * The caller must ensure the path for each item is valid, as we do not validate this
 * in the function.
 *
 * @returns Array of items in a nested structure.
 */
export function generateNestedMenu<T extends { path?: string | undefined | null }>(
  items: T[],
): NestedMenuItemType<T>[] {
  const result: NestedMenuItemType<T>[] = [];
  items.forEach(item => {
    if (!item.path) {
      // If no path is specified, put this item at the top-level
      result.push({ folder: undefined, item });
      return;
    }
    const splitPath = item.path.split('/');
    splitPath.reduce((acc, folder, index) => {
      const existingFolderIndex = acc.findIndex(res => res.folder === folder);
      const existingFolder =
        existingFolderIndex >= 0 ? acc[existingFolderIndex] : undefined;
      const children: NestedMenuItemType<T>[] = existingFolder?.children
        ? [...existingFolder.children]
        : [];
      // If we reach the end part of the path, we know this will
      // be the item itself (i.e. not a sub-menu), so we attach the item
      if (index === splitPath.length - 1) {
        children.push({ folder: undefined, item });
      }
      // If we don't have this folder yet, add it in
      if (existingFolderIndex < 0) {
        acc.push({ folder, children });
      } else {
        // If we do have it, replace it with the updated children
        acc.splice(existingFolderIndex, 1, {
          folder,
          children,
        });
      }
      return children;
    }, result);
  });
  return result;
}
