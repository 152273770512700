import React from 'react';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import * as FilesApi from 'client/app/api/FilesApi';
import { FileMetadata } from 'client/app/api/FilesApi';
import FileSegmentView from 'client/app/components/FileBrowser/FileSegmentView';
import getPathObject from 'client/app/components/FileBrowser/getPathObject';
import PathInput from 'client/app/components/FileBrowser/PathInput';
import UIErrorBox from 'client/app/components/UIErrorBox';
import convertFileSelectionToTree from 'client/app/lib/file-browser/convertFileSelectionToTree';
import getSegmentsFromPath from 'client/app/lib/file-browser/getSegmentsFromPath';
import { PATH_SEGMENT_SEPARATOR } from 'client/app/lib/file-browser/normalizePath';
import {
  FileBrowserFileSelection,
  FileBrowserFileSingleSelection,
  FileBrowserValueTree,
} from 'client/app/lib/file-browser/types';
import { isUUID } from 'common/lib/strings';

function getSaneValue(value: FileBrowserFileSelection, selectMultiple: boolean) {
  if (selectMultiple) {
    if (!value) {
      return [];
    }

    // Guard against the case where someone incorrectly sets the value as a
    // string when we're in multi-select mode.
    if (!Array.isArray(value)) {
      return [value];
    }
  }
  return value;
}

type Props = {
  // `basePath` is the top-most path the user should be allowed to navigate to.
  // Our data repo includes many partial paths which aren't valid. For instance,
  // to browse the recent files of a device, we use a path like:
  //
  //   /device/tttttttttttttttttttttttttt/recent
  //
  // However, /device and /device/tttttttttttttttttttttttttt/ are not valid
  // paths and will cause errors if we allow the user to request. For this
  // reason, setting `basePath` will restrict navigation to just this
  // top-most directory and its subdirectories.
  basePath?: string;

  value: FileBrowserFileSelection;

  onChange: (value: FileBrowserFileSelection) => void;

  selectMultiple?: boolean;

  // Allow for the ability to skip showing a certain path in the picker.
  skipDisplayPath?: string;
} & WithStyles<typeof styles>;

type State = {
  value: FileBrowserFileSelection;
  valueTree: FileBrowserValueTree;
  basePath: string;
  // The value might be a massive list of file paths from different folders,
  // so we'll have to keep track of a particular folder that we're looking at
  // right now.
  displayPath: string;

  selectMultiple?: boolean;
};

class FilePicker extends React.PureComponent<Props, State> {
  readonly state: State;

  constructor(props: Props) {
    super(props);
    const value = getSaneValue(this.props.value, !!this.props.selectMultiple);

    const basePath = props.basePath || PATH_SEGMENT_SEPARATOR;
    const displayPath = basePath;
    this.state = {
      value,
      valueTree: convertFileSelectionToTree(value),
      basePath,
      displayPath,
    };
  }

  deriveState = (value: FileBrowserFileSelection) => {
    const v = getSaneValue(value, !!this.props.selectMultiple);
    const valueTree = convertFileSelectionToTree(
      v || { localPath: '/', pathWithScheme: '' },
    );
    const topLevelPaths = Object.keys(valueTree);

    // Cases in which we need to overwrite the display path:
    //   - If value isn't null and  we're not in selectMultiple mode
    //   - Any time the value changes and the current displayPath isn't showing
    //     something within the value (i.e. something else changed the value
    //     and the user might not see it)
    //   - If the current basePath is the root but the value changes, we want
    //     to adjust the display path to show as much of the selected
    //     path as possible. Since a lot of the time the entire selection will
    //     be within the same folder, this should mean we usually show the
    //     user exactly what they expect.
    let { displayPath } = this.state;
    const { selectMultiple } = this.props;
    if (!selectMultiple && value) {
      // we know for sure it is a single value as we have `!this.props.selectMultiple`
      displayPath = (value as FileBrowserFileSingleSelection).localPath;
    } else if (selectMultiple && displayPath === '/') {
      let longestCommonPath = '';
      let node = valueTree;
      let currentLevelKeys = Object.keys(node);
      while (currentLevelKeys.length === 1) {
        longestCommonPath += currentLevelKeys[0];
        if (typeof node[currentLevelKeys[0]] !== 'string') {
          // `true` is a flag value meaning this level is just a file, not a dir
          node = node[currentLevelKeys[0]] as FileBrowserValueTree;
          currentLevelKeys = Object.keys(node);
        } else {
          currentLevelKeys = [];
        }
      }
      if (longestCommonPath !== '') {
        displayPath = longestCommonPath;
      }
    }

    let basePath = this.state.basePath;
    if (topLevelPaths.length === 1) {
      basePath = topLevelPaths[0];
    } else {
      // If we're in this case, there are multiple paths from root in the current
      // value, which means we can't have a more tightly scoped basePath than the
      // root itself
      basePath = '/';
    }

    this.setState(state => ({
      ...state,
      value: v,
      valueTree,
      basePath,
      displayPath,
    }));
  };

  componentDidMount() {
    this.deriveState(this.props.value);
  }

  componentDidUpdate(prevProps: Props) {
    // This check / update is necessary because if the value has changed,
    // we're very, very likely to need to adjust the basePath to be the
    // correct/safe/non-erroring basePath for the particular device.
    if (this.props.value !== prevProps.value) {
      this.deriveState(this.props.value);
    }
  }

  validate(value: FileBrowserFileSelection, basePath: string): string {
    if (value === null) {
      return '';
    }

    let v: FileBrowserFileSingleSelection[] = [];
    if (!Array.isArray(value)) {
      v = [value];
    } else {
      v = value;
    }

    for (let ii = 0; ii < v.length; ii++) {
      if (!v[ii].localPath.startsWith(basePath)) {
        return 'Value(s) must be within the specified directory';
      }
    }

    return '';
  }

  onManualPathChange = (newPath: string) => {
    // We do not know the scheme and it is OK, as manual path is supporting folder only.
    this.props.onChange({ localPath: newPath, pathWithScheme: '' });
  };

  onItemClick = (item: FileMetadata, path: string) => {
    let localPath = path + item.name;
    if (item.type === FilesApi.DIRECTORY) {
      localPath += PATH_SEGMENT_SEPARATOR;
    }
    const newValue = getPathObject(path, item);

    // If we're in single-path selection mode, any click on a file path segment
    // should be treated as a new value. For multiple selection, we can't assume
    // that just because the user is clicking around that they wanted to mutate
    // the actual value. They could just be navigating around hunting for
    // files they actually want.

    if (!this.props.selectMultiple && item.type === FilesApi.FILE) {
      this.props.onChange(newValue);
    } else {
      this.setState(state => {
        return { ...state, displayPath: localPath };
      });
    }
  };

  onToggleItemSelected = (filePath: FileBrowserFileSingleSelection) => {
    let value: FileBrowserFileSingleSelection[] = [];
    if (this.state.value) {
      value = value.concat(this.state.value);
    }

    const idx = value.findIndex(file => file.pathWithScheme === filePath.pathWithScheme);
    if (idx === -1) {
      value.push(filePath);
    } else {
      value.splice(idx, 1);
    }
    this.props.onChange(value);
  };

  setSelection = (paths: FileBrowserFileSingleSelection[] | null) => {
    this.props.onChange(paths);
  };

  render() {
    const { classes, skipDisplayPath } = this.props;
    const { value, valueTree, basePath, displayPath } = this.state;

    let errorDisplay = null;

    const validationMessage = this.validate(value, basePath);
    if (validationMessage !== '') {
      errorDisplay = <UIErrorBox>{validationMessage}</UIErrorBox>;
    }

    const skipDisplayPathSegments = skipDisplayPath
      ? getSegmentsFromPath(skipDisplayPath)
      : [];
    let displayPathSegments = getSegmentsFromPath(displayPath);
    // If we come up empty in parsing out segments, let's default to the root
    // path so that we at least show the user the top-level pseudo-directories
    // "Latest Jobs" and "Latest Simulations".
    if (!displayPathSegments.length) {
      displayPathSegments = ['/'];
    }

    // T2260: The path received from Simulation Details has the OrgID in it.
    // The File Browser cannot render it. Thus, we need to drop the OrgID altogether.
    // *All* valid paths start with `/job/<UUID>`, or `/device/<UUID>` etc.
    // So, if a path starts with `/<UUID>/`, we know it's invalid.
    const possibleOrgId = displayPathSegments?.[1];
    if (possibleOrgId) {
      const isActuallyOrgId = isUUID(possibleOrgId.replace('/', ''));

      // Path starts with a UUID, hence we remove it.
      if (isActuallyOrgId) {
        displayPathSegments.splice(1, 1);
      }
    }

    return (
      <div className={classes.shell}>
        {errorDisplay}
        <PathInput
          path={displayPathSegments.join('')}
          onPathChange={this.onManualPathChange}
          disabled={false}
        />
        <FileSegmentView
          valueTree={valueTree}
          displayPathSegments={displayPathSegments}
          selectMultiple={!!this.props.selectMultiple}
          onItemClick={this.onItemClick}
          onToggleItemSelected={this.onToggleItemSelected}
          setSelection={this.setSelection}
          skipDisplayPathSegments={skipDisplayPathSegments}
        />
      </div>
    );
  }
}

const styles = createStyles({
  shell: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
});

export default withStyles(styles)(FilePicker);
