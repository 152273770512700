import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { Dimensions } from 'common/types/Dimensions';
import { MouseModeControlContext } from 'common/ui/components/Workspace/MouseModeControl';

export default function useElementGroupSelection(
  setInSelectionMode: Dispatch<SetStateAction<boolean>>,
) {
  const [isSelectingElementGroup, setIsSelectingElementGroup] = useState(false);

  /**
   * When settings panel on the left is set to ElementGroup selection mode
   * we enable group selection and change the cursor to 'select'.
   */
  usePanelChangeEffect(setInSelectionMode, setIsSelectingElementGroup);
  /**
   * If during group selection user performs any kind of action that changes
   * the cursor mode we turn off ElementGroup selection.
   */
  useCursorModeChangeEffect(isSelectingElementGroup, setIsSelectingElementGroup);
}

function usePanelChangeEffect(
  setInSelectionMode: Dispatch<SetStateAction<boolean>>,
  setIsSelectingElementGroup: Dispatch<SetStateAction<boolean>>,
) {
  const { isElementGroupModeActive } = useElementGroupMode();
  useEffect(() => {
    /**
     * Here setInSelectionMode() toggles visual mouse selection on WorkflowLayout.
     * And setIsSelectingElementGroup() toggles state of group selection mode.
     */
    setInSelectionMode(isElementGroupModeActive);
    setIsSelectingElementGroup(isElementGroupModeActive);
  }, [isElementGroupModeActive, setInSelectionMode, setIsSelectingElementGroup]);
}

function useCursorModeChangeEffect(
  isSelectingElementGroup: boolean,
  setIsSelectingElementGroup: Dispatch<SetStateAction<boolean>>,
) {
  const dispatch = useWorkflowBuilderDispatch();
  const { mode } = useContext(MouseModeControlContext);

  useEffect(() => {
    if (isSelectingElementGroup && mode !== 'select') {
      /**
       * If user was selecting a group and performed any of the following actions:
       * - selected a single element or a connection
       * - changed a cursor to 'pan' or 'zoom'
       * - finished selecting a group with mouse (finishing selection changes cursor back to 'pan')
       * We stop group selection and turn off ElementGroup mode on settings panel.
       */
      setIsSelectingElementGroup(false);
      dispatch({ type: 'setActivePanel', payload: undefined });
    }
  }, [dispatch, isSelectingElementGroup, mode, setIsSelectingElementGroup]);
}

export function useElementGroupMode() {
  const activePanel = useWorkflowBuilderSelector(state => state.activePanel);
  const isElementGroupModeActive = activePanel === 'ElementGroup';
  return { isElementGroupModeActive };
}

export function useCreateElementGroupFromMouseArea() {
  const dispatch = useWorkflowBuilderDispatch();
  return useCallback(
    (selectionDimensions: Dimensions) => {
      dispatch({ type: 'createElementGroupFromMouseArea', payload: selectionDimensions });
    },
    [dispatch],
  );
}
