import React, { useCallback } from 'react';

/**
 * Helper hook to make it easier to work with TextField. TextField's callback
 * is of type React.ChangeEvent<HTMLInputElement>, but we usually want just the
 * string.
 *
 * Old usage without this hook (6 non-empty lines):
 *
 *   const [name, setName] = useState('');
 *   const onNameChange = useCallback(
 *     (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value),
 *     [setName],
 *   );
 *
 *   <TextField name={name} onChange={onNameChange} />
 *
 * Typical usage with this hook (3 non-empty lines):
 *
 *   const [name, setName] = useState('');
 *   const onNameChange = useTextFieldChange(setName);
 *
 *   <TextField name={name} onChange={onNameChange} />
 */
export default function useTextFieldChange(setter: (s: string) => void) {
  return useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setter(e.target.value),
    [setter],
  );
}
